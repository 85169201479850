/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import ReactFlow from 'reactflow';
import { colorType, colorTypeForStyledComponents } from '@paradime-io/pragma-ui-kit/lib/components/styles/styleTypes';
import * as Color from '@paradime-io/pragma-ui-kit/lib/components/styles/color.styles';

const NodeContainer = styled.div<{
  color: colorType,
  selected: boolean,
}>`
  ${(props) => Color[props.color === 'default' ? 'default_' : props.color as colorTypeForStyledComponents]}
  background: var(--white);
  border: 1px solid var(--color70);
  border-radius: 8px;
  width: 240px;
  padding: 4px;
  box-shadow: var(--greyShadow2dp);
`;

const NodeLogo = styled.div`
  display: flex;
  width: 16px;
  height: 16px;
  align-items: center;
  justify-items: center;
`;

const FlowWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: ${(props) => (props.theme.darkMode ? 'var(--dark-slategrey)' : 'var(--grey0)')};
  position: relative;
  &::backdrop {
    background-color: ${(props) => (props.theme.darkMode ? 'var(--dark-slategrey)' : 'var(--grey0)')};
  }
`;

const ReactFlowNoAttribution = styled(ReactFlow)`
  .react-flow__attribution {
    display: none;
  }
`;

export {
  NodeContainer, NodeLogo, FlowWrapper,
  ReactFlowNoAttribution,
};
