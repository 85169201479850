import styled, { createGlobalStyle } from 'styled-components';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import DefaultInput from '@paradime-io/pragma-ui-kit/lib/components/DefaultInput';

interface LeftPanelProps {
  'imageWidth'?: number,
  'maxWidth': number,
}

const AppStartBackground = createGlobalStyle`
  #root {
    background: var(--violet0);
    height: 100vh;
    width: 100vw;
  }
`;

const LeftPanel = styled.div<LeftPanelProps>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: calc(100vw - 408px);

  img {
    width: ${(props) => (props.imageWidth ? `${props.imageWidth}px !important` : '100%')} ;
    max-width: ${(props) => `${props.maxWidth}px`};
  }

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const RightPanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  position: absolute;
  right: 0;
  width: 408px;
  height: 100vh;
  background-color: var(--white);

  .no-margin {
    margin: 0;
  }
`;

export const PrimaryLandingPageButton = styled(DefaultButton)`
  height: 56px;
  padding: 16px;
  border-radius: 8px;
  font-size: var(--font_size_body);
  font-weight: var(--font_weight_body);
  letter-spacing: var(--letter_spacing_body);
  text-transform: none;
  color: var(--white);
  background: linear-gradient(54deg, #3d2eff 0%, #efbcd5 98.52%) !important;
  box-shadow: none !important;

  &:hover {
    background: linear-gradient(54deg, #3d2eff 0%, #efbcd5 88.52%) !important;
  }
`;

export const SecondaryLandingPageButton = styled(DefaultButton)`
  height: 56px;
  padding: 16px;
  border-radius: 8px;
  font-size: var(--font_size_body);
  font-weight: var(--font_weight_body);
  letter-spacing: var(--letter_spacing_body);
  text-transform: none;
`;

const Accent = styled.span`
  background: linear-gradient(270deg, #1EC4F8 32.77%, #2AE7CA 76.03%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
`;

const OrganisationNameInput = styled(DefaultInput)`
  & > input {
    text-transform: lowercase;
  }

  padding: 0 4px;

  & > input::placeholder {
    text-transform: none;
  }
`;

const CustomerTestimonialWrapper = styled.div`
  display: flex;
  justify-self: center;

  & span {
    color: var(--grey30);
  }
`;

const CarouselItem = styled.div<{ active: boolean}>`
  position: absolute;
  top: 0;
  transition: opacity 0.5s ease-in-out;
  opacity: ${(props) => (props.active ? 1 : 0)};
`;

export {
  OrganisationNameInput,
  CustomerTestimonialWrapper,
  CarouselItem,
  AppStartBackground,
  LeftPanel,
  RightPanel,
  Accent,
};
