import React, {
  FC, useEffect, useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import Loader from '@paradime-io/pragma-ui-kit/lib/components/Loader';
import { AppLocalStorageKeys } from '@paradime/common/lib/common/localStorageKeys';
import { useGithubLinkUserIdMutation } from '../../../../client/generated/service';
import { graphQLStore } from '../../../../stores';
import IntegrationStatusPage from '../../../../utilis/components/IntegrationStatusPage';
import IntegrationSuccess from '../../../../utilis/components/IntegrationSuccess';

const GithubOauthCallback: FC = () => {
  // query strings
  const { search: urlParams } = useLocation();
  const searchParams = new URLSearchParams(urlParams);
  const githubCode = searchParams.get('github_code');
  const uid = searchParams.get('uid');

  const [showSuccess, setShowSuccess] = useState(false);

  const apolloClientIsAuthorized = graphQLStore((state) => state.apolloClientIsAuthorized);

  const checkLocalStorage = () => {
    const oauthHasBeenConnected = localStorage.getItem(AppLocalStorageKeys.USER_GITHUB_OAUTH_RECONNECTED) === 'true';
    setShowSuccess(oauthHasBeenConnected);
  };

  const [setGithubInstallationId, { loading }] = useGithubLinkUserIdMutation({
    onCompleted: ({ githubLinkUserId }) => {
      // If the user was already connected, the BE returns "null"
      if (githubLinkUserId?.ok || githubLinkUserId === null) {
        setShowSuccess(true);
        localStorage.setItem(AppLocalStorageKeys.USER_GITHUB_OAUTH_RECONNECTED, 'true');
      }
    },
  });

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    // As below, this page refreshes a bunch of times, causing multiple calls to this endpoint
    // The setTimeout allows us to detect when the page is still reloading and not spam the endpoint
    // Because if the page has been disposed of within a second, the graphql call won't be made
    if (githubCode && uid && apolloClientIsAuthorized && !showSuccess && !loading) {
      timeout = setTimeout(() => {
        setGithubInstallationId({
          variables: {
            githubCode,
            uid,
          },
        });
      }, 1000);
    } else {
      checkLocalStorage();
    }

    return () => clearTimeout(timeout);
  }, [githubCode, uid, apolloClientIsAuthorized, showSuccess, loading]);

  // This page refreshes several times for unknown reasons.
  // The graphql endpoint takes on average a second to respond.
  // I think what's going on, is by the time we get a response, the page
  // has reloaded and the data/state variables get lost.
  // So, this solution is convoluted, but the only thing I've found to reliably
  // work is querying localStorage every second like this
  useEffect(() => {
    let intervalId: any;
    if (!showSuccess) {
      intervalId = setInterval(checkLocalStorage, 1000);
    } else {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [showSuccess]);

  if (!showSuccess) {
    return (
      <IntegrationStatusPage
        content={<Loader />}
      />
    );
  }

  return (
    <IntegrationStatusPage
      content={
        <IntegrationSuccess text="GitHub user successfully linked!" />
      }
    />
  );
};

export default GithubOauthCallback;
