import React, { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Icon } from '@blueprintjs/core';
import PageTemplate from '../PageTemplate';
import EnterWorkspaceName from './EnterWorkspaceName';
import GettingStarted from './GettingStarted';
import WorkspaceSettings from './WorkspaceSettings';
import ConnectRepo from '../../../Common/ConnectRepo';
import RepoProvider from '../../../Common/ConnectRepo/RepoProvider';
import Warehouse, { WareHouseModalSource } from '../../../Common/Warehouses';
import { useGetDevProdWarehousesButtons, devProdWarehouseOptions, WarehouseEnv } from '../../../Common/Warehouses/utils';
import PageTitle, { PageTitles } from '../../../PageTitle';
import { FakeModal } from '../../../Onboarding/MainApp/Utils/PageTemplate';
import { userAuthStore } from '../../../../stores';

export const createWorkspaceRoutePrefix = 'create-workspace';

export enum CreateWorkspaceRoutes {
  'ENTER_WORKSPACE_NAME' = 'enter-workspace-name',
  'GETTING_STARTED' = 'getting-started',
  'WAREHOUSE' = 'connect-warehouse',
  'WORKSPACE_SETTINGS' = 'workspace-settings',
  'CONNECT_REPO' = 'choose-repo-provider',
  'REPO_PROVIDER' = 'connect-repo',
}

const CreateWorkspace: FC = () => {
  const { pageRef } = useParams<{ pageRef: CreateWorkspaceRoutes }>();
  const history = useHistory();

  const { accessLevel } = userAuthStore((s) => s.currentUser);

  const getStep = () => {
    switch (pageRef) {
      case CreateWorkspaceRoutes.ENTER_WORKSPACE_NAME:
        return <EnterWorkspaceName />;
      case CreateWorkspaceRoutes.GETTING_STARTED:
        return <GettingStarted />;
      case CreateWorkspaceRoutes.WORKSPACE_SETTINGS:
        return <WorkspaceSettings />;
      case CreateWorkspaceRoutes.CONNECT_REPO:
        return (
          <ConnectRepo
            connectRepoNextStepUrl={`/${createWorkspaceRoutePrefix}/${CreateWorkspaceRoutes.REPO_PROVIDER}`}
          />
        );
      case CreateWorkspaceRoutes.REPO_PROVIDER:
        return (
          <RepoProvider
            repoProviderNextStepUrl={`/${createWorkspaceRoutePrefix}/${CreateWorkspaceRoutes.WAREHOUSE}`}
            noRepoChosenUrl={`/${createWorkspaceRoutePrefix}/${CreateWorkspaceRoutes.CONNECT_REPO}`}
          />
        );
      case CreateWorkspaceRoutes.WAREHOUSE:
        return (
          <div style={{ width: '100%' }}>
            <Warehouse
              bigButtons={useGetDevProdWarehousesButtons()}
              env={WarehouseEnv.DEV}
              warehouseOptions={devProdWarehouseOptions}
              newConnection
              source={WareHouseModalSource.CREATE_WORKSPACE}
              accessLevel={accessLevel}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <PageTemplate>
      <PageTitle title={PageTitles.WORKSPACE} />
      <FakeModal>
        {getStep()}
        {pageRef === CreateWorkspaceRoutes.REPO_PROVIDER && (
          <Icon
            icon="chevron-left"
            size={16}
            color="var(--icon)"
            onClick={() => history.push(`/${createWorkspaceRoutePrefix}/${CreateWorkspaceRoutes.CONNECT_REPO}`)}
            style={{
              position: 'fixed', left: '16px', bottom: '16px', cursor: 'pointer',
            }}
          />
        )}
      </FakeModal>
    </PageTemplate>
  );
};

export default CreateWorkspace;
