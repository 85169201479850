interface validationItem {
  rule: string,
  message: string,
}

export interface checkValidationProps {
  value?: string | number,
  rules: validationItem[],
}

export enum ValidationRules {
  'NO_SPACES' = '^\\S*$',
  'NO_SPECIAL_CHARS' = '^([a-zA-Z0-9 ]*)$',
  'NOT_EMPTY' = '^(.|\n)+$',
  'ONLY_NUMBERS' = '^([^0-9]*)$',
  'ONLY_LETTERS' = '^([a-zA-Z]*)$',
  'ONLY_LETTERS_AND_NUMBERS' = '^([a-zA-Z0-9]*)$',
  'ONLY_ALPHANUMERIC_HYPHEN_UNDERSCORE' = '^([a-zA-Z0-9-_]*)$',
  'ORGANISATION_RULE' = '^([a-zA-Z0-9][a-zA-Z0-9_-]{1,62}[a-zA-Z0-9])$',
  'VALID_EMAIL' = '^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,10}$',
}

export const useCheckValidation = () => {
  // This function takes a value, and an array of regex rules
  // It will return `hasError: true` if one or more rules do not match the value
  // and an array of the associated error messages
  const checkValidation = ({ value, rules }: checkValidationProps) => {
    const results = rules.map(({ rule, message }) => {
      const regex = new RegExp(rule);
      if (regex.test(value?.toString() || '')) {
        // The regex passed; no error
        return null;
      }
      // The regex failed; there is an error
      return message;
    });

    const errorMessages = results.filter((val) => val !== null);
    return { hasError: errorMessages.length > 0, errorMessages };
  };

  return { checkValidation };
};
