import React, { FC } from 'react';

const Hightouch: FC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="14" height="14" rx="7" fill="white" />
    <g clipPath="url(#clip0_2304_397034)">
      <rect width="10" height="10" transform="translate(3 3)" fill="white" />
      <path d="M12.0181 9.56331H6.43555V3.98022C6.43555 3.89869 6.50147 3.83276 6.583 3.83276H12.0186C12.1002 3.83276 12.1661 3.89869 12.1661 3.98022V9.41585C12.1656 9.49738 12.0997 9.56331 12.0181 9.56331Z" fill="#CAF23A" />
      <path d="M6.28841 12.1672H3.97948C3.89796 12.1672 3.83203 12.1013 3.83203 12.0197V9.71081C3.83203 9.62928 3.89796 9.56335 3.97948 9.56335H6.43586V12.0197C6.43586 12.1013 6.36994 12.1672 6.28841 12.1672Z" fill="#4FC26B" />
    </g>
    <rect x="1" y="1" width="14" height="14" rx="7" stroke="#CAF23A" strokeWidth="2" />
    <defs>
      <clipPath id="clip0_2304_397034">
        <rect width="10" height="10" fill="white" transform="translate(3 3)" />
      </clipPath>
    </defs>
  </svg>
);

export default Hightouch;
