/* eslint-disable react/destructuring-assignment */
import { IntegrationHandler } from '..';
import { lineageContextMenu } from './LineageContextMenu';
import { lineageFilterNodes } from './LineageFilterNodes';
import { lineageNodeDisplay } from './LineageNodeDisplay';
import { catalogueSearchResultItem } from './CatalogueSearchResultItem';
import { catalogueDetailsCard } from './CatalogueDetailsCard';
import { catalogueNameAndIcon } from './CatalogueNameAndIcon';
import { catalogueHeaderButtons } from './CatalogueHeaderButtons';
import { catalogueLogo } from './CatalogueLogo';
import { catalogueSimpleType } from './CatalogueSimpleType';
import { catalogueDescription } from './CatalogueDescription';
import { catalogueItemTabsVisibility } from './CatalogueItemTabsVisibility';
import { catalogueDependenciesTab } from './CatalogueDependenciesTab';
import { catalogueSearchFilters } from './CatalogueSearchFilters';
import { lineageSearchInputDropdown } from './LineageSearchInputDropdown';
import { catalogueTreeIcons } from './CatalogueTreeIcons';
import { catalogueAssetItemId } from './CatalogueAssetItemId';

export enum DbtHandlerAcceptedTypes {
  'DBT' = 'DBT',
  'DBTModelNode' = 'Model',
  'DBTSeedNode' = 'Seed',
  'DBTTestNode' = 'Test',
  'DBTSnapshotNode' = 'Snapshot',
  'DBTSourceNode' = 'Source',
  'DBTAnalysisNode' = 'Analysis',
  'DBTExposureNode' = 'Exposure',
  'DBTMetricNode' = 'Metric',
  'GQLCatalogueDBTExposureItem' = 'GQLCatalogueDBTExposureItem',
  'dbt' = 'dbt',
  'exposure' = 'exposure',
  'exposures' = 'exposures',
  'exposureType' = 'exposureType',
  'models' = 'models',
  'seeds' = 'seeds',
  'sources' = 'sources',
  'model' = 'model',
  'modelType' = 'modelType',
  'seed' = 'seed',
  'seedType' = 'seedType',
  'source' = 'source',
  'sourceType' = 'sourceType',
  'snapshot' = 'snapshot',
  'snapshots' = 'snapshots',
}

export enum DbtSubTypes {
  'DBT_EXPOSURES' = 'exposures'
}

export const DbtIntegrationHandler: IntegrationHandler = ({
  type: DbtHandlerAcceptedTypes.DBT,
  subTypes: Object.values(DbtSubTypes),
  handlers: {
    // lineageNodeRenderer: (data: any) => <div>{data}</div>,
    // catalogueCardRenderer: (data: any) => <div>{data}</div>,
    lineageContextMenu,
    lineageSearchInputDropdown,
    lineageFilterNodes,
    catalogueSearchResultItem,
    catalogueDetailsCard,
    catalogueNameAndIcon,
    catalogueHeaderButtons,
    catalogueClassificationData: () => ({
      keyList: [],
      tableClassificationOptions: [],
    }),
    catalogueLogo,
    catalogueSimpleType,
    catalogueDescription,
    lineageNodeDisplay,
    catalogueAssetColumns: () => [],
    catalogueAssetItemId,
    catalogueSearchFilters,
    catalogueItemTabsVisibility,
    catalogueFieldsTab: () => ({
      columnHeadings: [],
      data: [],
    }),
    catalogueDependenciesTab,
    catalogueSchemaTab: () => [{
      tableName: '',
      columnData: [],
    }],
    catalogueDbtModelTab: () => undefined,
    catalogueTreeIcons,
    catalogueShowClassificationsCard: () => true,
  },
});
