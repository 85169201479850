import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {"context":{"operationType":"db"}} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A floating point number that requires more precision than IEEE 754 binary 64 */
  BigFloat: any;
  /**
   * A signed eight-byte integer. The upper big integer values are greater than the
   * max value for a JavaScript number. Therefore all big integers will be output as
   * strings and not numbers.
   */
  BigInt: any;
  /** A location in a connection that can be used for resuming pagination. */
  Cursor: any;
  /**
   * A point in time as described by the [ISO
   * 8601](https://en.wikipedia.org/wiki/ISO_8601) standard. May or may not include a timezone.
   */
  Datetime: any;
};

export enum Actors {
  Api = 'API',
  Compile = 'COMPILE',
  Scheduler = 'SCHEDULER',
  TurboCi = 'TURBO_CI',
  User = 'USER'
}

export type AuditLog = Node & {
  __typename?: 'AuditLog';
  actorEmail?: Maybe<Scalars['String']>;
  actorType: Scalars['String'];
  actorUserId: Scalars['Int'];
  createdDttm: Scalars['Datetime'];
  eventId: Scalars['Int'];
  eventSource: Scalars['String'];
  eventSourceId: Scalars['Int'];
  eventType: Scalars['String'];
  id: Scalars['Int'];
  metadataJson?: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  updatedDttm: Scalars['Datetime'];
  workspaceId: Scalars['Int'];
  workspaceName: Scalars['String'];
};

export type AuditLogAggregates = {
  __typename?: 'AuditLogAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<AuditLogAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<AuditLogDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<AuditLogMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<AuditLogMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<AuditLogStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<AuditLogStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<AuditLogSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<AuditLogVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<AuditLogVarianceSampleAggregates>;
};

export type AuditLogAverageAggregates = {
  __typename?: 'AuditLogAverageAggregates';
  /** Mean average of actorUserId across the matching connection */
  actorUserId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of eventId across the matching connection */
  eventId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of eventSourceId across the matching connection */
  eventSourceId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `AuditLog` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type AuditLogCondition = {
  /** Checks for equality with the object’s `actorEmail` field. */
  actorEmail?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `actorType` field. */
  actorType?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `actorUserId` field. */
  actorUserId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdDttm` field. */
  createdDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `eventId` field. */
  eventId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `eventSource` field. */
  eventSource?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `eventSourceId` field. */
  eventSourceId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `eventType` field. */
  eventType?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `metadataJson` field. */
  metadataJson?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedDttm` field. */
  updatedDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `workspaceId` field. */
  workspaceId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `workspaceName` field. */
  workspaceName?: InputMaybe<Scalars['String']>;
};

export type AuditLogDistinctCountAggregates = {
  __typename?: 'AuditLogDistinctCountAggregates';
  /** Distinct count of actorEmail across the matching connection */
  actorEmail?: Maybe<Scalars['BigInt']>;
  /** Distinct count of actorType across the matching connection */
  actorType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of actorUserId across the matching connection */
  actorUserId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdDttm across the matching connection */
  createdDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of eventId across the matching connection */
  eventId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of eventSource across the matching connection */
  eventSource?: Maybe<Scalars['BigInt']>;
  /** Distinct count of eventSourceId across the matching connection */
  eventSourceId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of eventType across the matching connection */
  eventType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of metadataJson across the matching connection */
  metadataJson?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedDttm across the matching connection */
  updatedDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of workspaceName across the matching connection */
  workspaceName?: Maybe<Scalars['BigInt']>;
};

/** Grouping methods for `AuditLog` for usage during aggregation. */
export enum AuditLogGroupBy {
  ActorEmail = 'ACTOR_EMAIL',
  ActorType = 'ACTOR_TYPE',
  ActorUserId = 'ACTOR_USER_ID',
  CreatedDttm = 'CREATED_DTTM',
  CreatedDttmTruncatedToDay = 'CREATED_DTTM_TRUNCATED_TO_DAY',
  CreatedDttmTruncatedToHour = 'CREATED_DTTM_TRUNCATED_TO_HOUR',
  EventId = 'EVENT_ID',
  EventSource = 'EVENT_SOURCE',
  EventSourceId = 'EVENT_SOURCE_ID',
  EventType = 'EVENT_TYPE',
  MetadataJson = 'METADATA_JSON',
  UpdatedDttm = 'UPDATED_DTTM',
  UpdatedDttmTruncatedToDay = 'UPDATED_DTTM_TRUNCATED_TO_DAY',
  UpdatedDttmTruncatedToHour = 'UPDATED_DTTM_TRUNCATED_TO_HOUR',
  WorkspaceId = 'WORKSPACE_ID',
  WorkspaceName = 'WORKSPACE_NAME'
}

export type AuditLogHavingAverageInput = {
  actorUserId?: InputMaybe<HavingIntFilter>;
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  eventId?: InputMaybe<HavingIntFilter>;
  eventSourceId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type AuditLogHavingDistinctCountInput = {
  actorUserId?: InputMaybe<HavingIntFilter>;
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  eventId?: InputMaybe<HavingIntFilter>;
  eventSourceId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `AuditLog` aggregates. */
export type AuditLogHavingInput = {
  AND?: InputMaybe<Array<AuditLogHavingInput>>;
  OR?: InputMaybe<Array<AuditLogHavingInput>>;
  average?: InputMaybe<AuditLogHavingAverageInput>;
  distinctCount?: InputMaybe<AuditLogHavingDistinctCountInput>;
  max?: InputMaybe<AuditLogHavingMaxInput>;
  min?: InputMaybe<AuditLogHavingMinInput>;
  stddevPopulation?: InputMaybe<AuditLogHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<AuditLogHavingStddevSampleInput>;
  sum?: InputMaybe<AuditLogHavingSumInput>;
  variancePopulation?: InputMaybe<AuditLogHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<AuditLogHavingVarianceSampleInput>;
};

export type AuditLogHavingMaxInput = {
  actorUserId?: InputMaybe<HavingIntFilter>;
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  eventId?: InputMaybe<HavingIntFilter>;
  eventSourceId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type AuditLogHavingMinInput = {
  actorUserId?: InputMaybe<HavingIntFilter>;
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  eventId?: InputMaybe<HavingIntFilter>;
  eventSourceId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type AuditLogHavingStddevPopulationInput = {
  actorUserId?: InputMaybe<HavingIntFilter>;
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  eventId?: InputMaybe<HavingIntFilter>;
  eventSourceId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type AuditLogHavingStddevSampleInput = {
  actorUserId?: InputMaybe<HavingIntFilter>;
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  eventId?: InputMaybe<HavingIntFilter>;
  eventSourceId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type AuditLogHavingSumInput = {
  actorUserId?: InputMaybe<HavingIntFilter>;
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  eventId?: InputMaybe<HavingIntFilter>;
  eventSourceId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type AuditLogHavingVariancePopulationInput = {
  actorUserId?: InputMaybe<HavingIntFilter>;
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  eventId?: InputMaybe<HavingIntFilter>;
  eventSourceId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type AuditLogHavingVarianceSampleInput = {
  actorUserId?: InputMaybe<HavingIntFilter>;
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  eventId?: InputMaybe<HavingIntFilter>;
  eventSourceId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type AuditLogMaxAggregates = {
  __typename?: 'AuditLogMaxAggregates';
  /** Maximum of actorUserId across the matching connection */
  actorUserId?: Maybe<Scalars['Int']>;
  /** Maximum of eventId across the matching connection */
  eventId?: Maybe<Scalars['Int']>;
  /** Maximum of eventSourceId across the matching connection */
  eventSourceId?: Maybe<Scalars['Int']>;
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['Int']>;
};

export type AuditLogMinAggregates = {
  __typename?: 'AuditLogMinAggregates';
  /** Minimum of actorUserId across the matching connection */
  actorUserId?: Maybe<Scalars['Int']>;
  /** Minimum of eventId across the matching connection */
  eventId?: Maybe<Scalars['Int']>;
  /** Minimum of eventSourceId across the matching connection */
  eventSourceId?: Maybe<Scalars['Int']>;
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['Int']>;
};

export type AuditLogStddevPopulationAggregates = {
  __typename?: 'AuditLogStddevPopulationAggregates';
  /** Population standard deviation of actorUserId across the matching connection */
  actorUserId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of eventId across the matching connection */
  eventId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of eventSourceId across the matching connection */
  eventSourceId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type AuditLogStddevSampleAggregates = {
  __typename?: 'AuditLogStddevSampleAggregates';
  /** Sample standard deviation of actorUserId across the matching connection */
  actorUserId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of eventId across the matching connection */
  eventId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of eventSourceId across the matching connection */
  eventSourceId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type AuditLogSumAggregates = {
  __typename?: 'AuditLogSumAggregates';
  /** Sum of actorUserId across the matching connection */
  actorUserId: Scalars['BigInt'];
  /** Sum of eventId across the matching connection */
  eventId: Scalars['BigInt'];
  /** Sum of eventSourceId across the matching connection */
  eventSourceId: Scalars['BigInt'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of workspaceId across the matching connection */
  workspaceId: Scalars['BigInt'];
};

export type AuditLogVariancePopulationAggregates = {
  __typename?: 'AuditLogVariancePopulationAggregates';
  /** Population variance of actorUserId across the matching connection */
  actorUserId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of eventId across the matching connection */
  eventId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of eventSourceId across the matching connection */
  eventSourceId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type AuditLogVarianceSampleAggregates = {
  __typename?: 'AuditLogVarianceSampleAggregates';
  /** Sample variance of actorUserId across the matching connection */
  actorUserId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of eventId across the matching connection */
  eventId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of eventSourceId across the matching connection */
  eventSourceId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `AuditLog` values. */
export type AuditLogsConnection = {
  __typename?: 'AuditLogsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<AuditLogAggregates>;
  /** A list of edges which contains the `AuditLog` and cursor to aid in pagination. */
  edges: Array<AuditLogsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<AuditLogAggregates>>;
  /** A list of `AuditLog` objects. */
  nodes: Array<AuditLog>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AuditLog` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `AuditLog` values. */
export type AuditLogsConnectionGroupedAggregatesArgs = {
  groupBy: Array<AuditLogGroupBy>;
  having?: InputMaybe<AuditLogHavingInput>;
};

/** A `AuditLog` edge in the connection. */
export type AuditLogsEdge = {
  __typename?: 'AuditLogsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AuditLog` at the end of the edge. */
  node: AuditLog;
};

/** Methods to use when ordering `AuditLog`. */
export enum AuditLogsOrderBy {
  ActorEmailAsc = 'ACTOR_EMAIL_ASC',
  ActorEmailDesc = 'ACTOR_EMAIL_DESC',
  ActorTypeAsc = 'ACTOR_TYPE_ASC',
  ActorTypeDesc = 'ACTOR_TYPE_DESC',
  ActorUserIdAsc = 'ACTOR_USER_ID_ASC',
  ActorUserIdDesc = 'ACTOR_USER_ID_DESC',
  CreatedDttmAsc = 'CREATED_DTTM_ASC',
  CreatedDttmDesc = 'CREATED_DTTM_DESC',
  EventIdAsc = 'EVENT_ID_ASC',
  EventIdDesc = 'EVENT_ID_DESC',
  EventSourceAsc = 'EVENT_SOURCE_ASC',
  EventSourceDesc = 'EVENT_SOURCE_DESC',
  EventSourceIdAsc = 'EVENT_SOURCE_ID_ASC',
  EventSourceIdDesc = 'EVENT_SOURCE_ID_DESC',
  EventTypeAsc = 'EVENT_TYPE_ASC',
  EventTypeDesc = 'EVENT_TYPE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  MetadataJsonAsc = 'METADATA_JSON_ASC',
  MetadataJsonDesc = 'METADATA_JSON_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedDttmAsc = 'UPDATED_DTTM_ASC',
  UpdatedDttmDesc = 'UPDATED_DTTM_DESC',
  WorkspaceIdAsc = 'WORKSPACE_ID_ASC',
  WorkspaceIdDesc = 'WORKSPACE_ID_DESC',
  WorkspaceNameAsc = 'WORKSPACE_NAME_ASC',
  WorkspaceNameDesc = 'WORKSPACE_NAME_DESC'
}

export type CommitHash = Node & {
  __typename?: 'CommitHash';
  /** Reads and enables pagination through a set of `CommitHashRelation`. */
  childCommitHashRelations: CommitHashRelationsConnection;
  commitHash: Scalars['String'];
  /** Reads and enables pagination through a set of `File`. */
  files: FilesConnection;
  id: Scalars['BigInt'];
  importPhase: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `CommitHashRelation`. */
  parentCommitHashRelations: CommitHashRelationsConnection;
};


export type CommitHashChildCommitHashRelationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CommitHashRelationCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CommitHashRelationsOrderBy>>;
};


export type CommitHashFilesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FileCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};


export type CommitHashParentCommitHashRelationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CommitHashRelationCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CommitHashRelationsOrderBy>>;
};

export type CommitHashAggregates = {
  __typename?: 'CommitHashAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<CommitHashAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CommitHashDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<CommitHashMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<CommitHashMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<CommitHashStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<CommitHashStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<CommitHashSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<CommitHashVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<CommitHashVarianceSampleAggregates>;
};

export type CommitHashAverageAggregates = {
  __typename?: 'CommitHashAverageAggregates';
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of importPhase across the matching connection */
  importPhase?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `CommitHash` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type CommitHashCondition = {
  /** Checks for equality with the object’s `commitHash` field. */
  commitHash?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `importPhase` field. */
  importPhase?: InputMaybe<Scalars['Int']>;
};

export type CommitHashDistinctCountAggregates = {
  __typename?: 'CommitHashDistinctCountAggregates';
  /** Distinct count of commitHash across the matching connection */
  commitHash?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of importPhase across the matching connection */
  importPhase?: Maybe<Scalars['BigInt']>;
};

export type CommitHashMaxAggregates = {
  __typename?: 'CommitHashMaxAggregates';
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Maximum of importPhase across the matching connection */
  importPhase?: Maybe<Scalars['Int']>;
};

export type CommitHashMinAggregates = {
  __typename?: 'CommitHashMinAggregates';
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Minimum of importPhase across the matching connection */
  importPhase?: Maybe<Scalars['Int']>;
};

export type CommitHashRelation = Node & {
  __typename?: 'CommitHashRelation';
  /** Reads a single `CommitHash` that is related to this `CommitHashRelation`. */
  child?: Maybe<CommitHash>;
  childId: Scalars['BigInt'];
  id: Scalars['BigInt'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `CommitHash` that is related to this `CommitHashRelation`. */
  parent?: Maybe<CommitHash>;
  parentId: Scalars['BigInt'];
};

export type CommitHashRelationAggregates = {
  __typename?: 'CommitHashRelationAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<CommitHashRelationAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CommitHashRelationDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<CommitHashRelationMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<CommitHashRelationMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<CommitHashRelationStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<CommitHashRelationStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<CommitHashRelationSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<CommitHashRelationVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<CommitHashRelationVarianceSampleAggregates>;
};

export type CommitHashRelationAverageAggregates = {
  __typename?: 'CommitHashRelationAverageAggregates';
  /** Mean average of childId across the matching connection */
  childId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `CommitHashRelation` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CommitHashRelationCondition = {
  /** Checks for equality with the object’s `childId` field. */
  childId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `parentId` field. */
  parentId?: InputMaybe<Scalars['BigInt']>;
};

export type CommitHashRelationDistinctCountAggregates = {
  __typename?: 'CommitHashRelationDistinctCountAggregates';
  /** Distinct count of childId across the matching connection */
  childId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigInt']>;
};

export type CommitHashRelationMaxAggregates = {
  __typename?: 'CommitHashRelationMaxAggregates';
  /** Maximum of childId across the matching connection */
  childId?: Maybe<Scalars['BigInt']>;
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Maximum of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigInt']>;
};

export type CommitHashRelationMinAggregates = {
  __typename?: 'CommitHashRelationMinAggregates';
  /** Minimum of childId across the matching connection */
  childId?: Maybe<Scalars['BigInt']>;
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Minimum of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigInt']>;
};

export type CommitHashRelationStddevPopulationAggregates = {
  __typename?: 'CommitHashRelationStddevPopulationAggregates';
  /** Population standard deviation of childId across the matching connection */
  childId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigFloat']>;
};

export type CommitHashRelationStddevSampleAggregates = {
  __typename?: 'CommitHashRelationStddevSampleAggregates';
  /** Sample standard deviation of childId across the matching connection */
  childId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigFloat']>;
};

export type CommitHashRelationSumAggregates = {
  __typename?: 'CommitHashRelationSumAggregates';
  /** Sum of childId across the matching connection */
  childId: Scalars['BigFloat'];
  /** Sum of id across the matching connection */
  id: Scalars['BigFloat'];
  /** Sum of parentId across the matching connection */
  parentId: Scalars['BigFloat'];
};

export type CommitHashRelationVariancePopulationAggregates = {
  __typename?: 'CommitHashRelationVariancePopulationAggregates';
  /** Population variance of childId across the matching connection */
  childId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigFloat']>;
};

export type CommitHashRelationVarianceSampleAggregates = {
  __typename?: 'CommitHashRelationVarianceSampleAggregates';
  /** Sample variance of childId across the matching connection */
  childId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `CommitHashRelation` values. */
export type CommitHashRelationsConnection = {
  __typename?: 'CommitHashRelationsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CommitHashRelationAggregates>;
  /** A list of edges which contains the `CommitHashRelation` and cursor to aid in pagination. */
  edges: Array<CommitHashRelationsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CommitHashRelationAggregates>>;
  /** A list of `CommitHashRelation` objects. */
  nodes: Array<CommitHashRelation>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CommitHashRelation` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `CommitHashRelation` values. */
export type CommitHashRelationsConnectionGroupedAggregatesArgs = {
  groupBy: Array<CommitHashRelationsGroupBy>;
  having?: InputMaybe<CommitHashRelationsHavingInput>;
};

/** A `CommitHashRelation` edge in the connection. */
export type CommitHashRelationsEdge = {
  __typename?: 'CommitHashRelationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CommitHashRelation` at the end of the edge. */
  node: CommitHashRelation;
};

/** Grouping methods for `CommitHashRelation` for usage during aggregation. */
export enum CommitHashRelationsGroupBy {
  ChildId = 'CHILD_ID',
  ParentId = 'PARENT_ID'
}

export type CommitHashRelationsHavingAverageInput = {
  childId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  parentId?: InputMaybe<HavingBigintFilter>;
};

export type CommitHashRelationsHavingDistinctCountInput = {
  childId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  parentId?: InputMaybe<HavingBigintFilter>;
};

/** Conditions for `CommitHashRelation` aggregates. */
export type CommitHashRelationsHavingInput = {
  AND?: InputMaybe<Array<CommitHashRelationsHavingInput>>;
  OR?: InputMaybe<Array<CommitHashRelationsHavingInput>>;
  average?: InputMaybe<CommitHashRelationsHavingAverageInput>;
  distinctCount?: InputMaybe<CommitHashRelationsHavingDistinctCountInput>;
  max?: InputMaybe<CommitHashRelationsHavingMaxInput>;
  min?: InputMaybe<CommitHashRelationsHavingMinInput>;
  stddevPopulation?: InputMaybe<CommitHashRelationsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<CommitHashRelationsHavingStddevSampleInput>;
  sum?: InputMaybe<CommitHashRelationsHavingSumInput>;
  variancePopulation?: InputMaybe<CommitHashRelationsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<CommitHashRelationsHavingVarianceSampleInput>;
};

export type CommitHashRelationsHavingMaxInput = {
  childId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  parentId?: InputMaybe<HavingBigintFilter>;
};

export type CommitHashRelationsHavingMinInput = {
  childId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  parentId?: InputMaybe<HavingBigintFilter>;
};

export type CommitHashRelationsHavingStddevPopulationInput = {
  childId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  parentId?: InputMaybe<HavingBigintFilter>;
};

export type CommitHashRelationsHavingStddevSampleInput = {
  childId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  parentId?: InputMaybe<HavingBigintFilter>;
};

export type CommitHashRelationsHavingSumInput = {
  childId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  parentId?: InputMaybe<HavingBigintFilter>;
};

export type CommitHashRelationsHavingVariancePopulationInput = {
  childId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  parentId?: InputMaybe<HavingBigintFilter>;
};

export type CommitHashRelationsHavingVarianceSampleInput = {
  childId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  parentId?: InputMaybe<HavingBigintFilter>;
};

/** Methods to use when ordering `CommitHashRelation`. */
export enum CommitHashRelationsOrderBy {
  ChildIdAsc = 'CHILD_ID_ASC',
  ChildIdDesc = 'CHILD_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  ParentIdAsc = 'PARENT_ID_ASC',
  ParentIdDesc = 'PARENT_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type CommitHashStddevPopulationAggregates = {
  __typename?: 'CommitHashStddevPopulationAggregates';
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of importPhase across the matching connection */
  importPhase?: Maybe<Scalars['BigFloat']>;
};

export type CommitHashStddevSampleAggregates = {
  __typename?: 'CommitHashStddevSampleAggregates';
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of importPhase across the matching connection */
  importPhase?: Maybe<Scalars['BigFloat']>;
};

export type CommitHashSumAggregates = {
  __typename?: 'CommitHashSumAggregates';
  /** Sum of id across the matching connection */
  id: Scalars['BigFloat'];
  /** Sum of importPhase across the matching connection */
  importPhase: Scalars['BigInt'];
};

export type CommitHashVariancePopulationAggregates = {
  __typename?: 'CommitHashVariancePopulationAggregates';
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of importPhase across the matching connection */
  importPhase?: Maybe<Scalars['BigFloat']>;
};

export type CommitHashVarianceSampleAggregates = {
  __typename?: 'CommitHashVarianceSampleAggregates';
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of importPhase across the matching connection */
  importPhase?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `CommitHash` values. */
export type CommitHashesConnection = {
  __typename?: 'CommitHashesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CommitHashAggregates>;
  /** A list of edges which contains the `CommitHash` and cursor to aid in pagination. */
  edges: Array<CommitHashesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CommitHashAggregates>>;
  /** A list of `CommitHash` objects. */
  nodes: Array<CommitHash>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CommitHash` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `CommitHash` values. */
export type CommitHashesConnectionGroupedAggregatesArgs = {
  groupBy: Array<CommitHashesGroupBy>;
  having?: InputMaybe<CommitHashesHavingInput>;
};

/** A `CommitHash` edge in the connection. */
export type CommitHashesEdge = {
  __typename?: 'CommitHashesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CommitHash` at the end of the edge. */
  node: CommitHash;
};

/** Grouping methods for `CommitHash` for usage during aggregation. */
export enum CommitHashesGroupBy {
  ImportPhase = 'IMPORT_PHASE'
}

export type CommitHashesHavingAverageInput = {
  id?: InputMaybe<HavingBigintFilter>;
  importPhase?: InputMaybe<HavingIntFilter>;
};

export type CommitHashesHavingDistinctCountInput = {
  id?: InputMaybe<HavingBigintFilter>;
  importPhase?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `CommitHash` aggregates. */
export type CommitHashesHavingInput = {
  AND?: InputMaybe<Array<CommitHashesHavingInput>>;
  OR?: InputMaybe<Array<CommitHashesHavingInput>>;
  average?: InputMaybe<CommitHashesHavingAverageInput>;
  distinctCount?: InputMaybe<CommitHashesHavingDistinctCountInput>;
  max?: InputMaybe<CommitHashesHavingMaxInput>;
  min?: InputMaybe<CommitHashesHavingMinInput>;
  stddevPopulation?: InputMaybe<CommitHashesHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<CommitHashesHavingStddevSampleInput>;
  sum?: InputMaybe<CommitHashesHavingSumInput>;
  variancePopulation?: InputMaybe<CommitHashesHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<CommitHashesHavingVarianceSampleInput>;
};

export type CommitHashesHavingMaxInput = {
  id?: InputMaybe<HavingBigintFilter>;
  importPhase?: InputMaybe<HavingIntFilter>;
};

export type CommitHashesHavingMinInput = {
  id?: InputMaybe<HavingBigintFilter>;
  importPhase?: InputMaybe<HavingIntFilter>;
};

export type CommitHashesHavingStddevPopulationInput = {
  id?: InputMaybe<HavingBigintFilter>;
  importPhase?: InputMaybe<HavingIntFilter>;
};

export type CommitHashesHavingStddevSampleInput = {
  id?: InputMaybe<HavingBigintFilter>;
  importPhase?: InputMaybe<HavingIntFilter>;
};

export type CommitHashesHavingSumInput = {
  id?: InputMaybe<HavingBigintFilter>;
  importPhase?: InputMaybe<HavingIntFilter>;
};

export type CommitHashesHavingVariancePopulationInput = {
  id?: InputMaybe<HavingBigintFilter>;
  importPhase?: InputMaybe<HavingIntFilter>;
};

export type CommitHashesHavingVarianceSampleInput = {
  id?: InputMaybe<HavingBigintFilter>;
  importPhase?: InputMaybe<HavingIntFilter>;
};

/** Methods to use when ordering `CommitHash`. */
export enum CommitHashesOrderBy {
  ChildCommitHashRelationsAverageChildIdAsc = 'CHILD_COMMIT_HASH_RELATIONS_AVERAGE_CHILD_ID_ASC',
  ChildCommitHashRelationsAverageChildIdDesc = 'CHILD_COMMIT_HASH_RELATIONS_AVERAGE_CHILD_ID_DESC',
  ChildCommitHashRelationsAverageIdAsc = 'CHILD_COMMIT_HASH_RELATIONS_AVERAGE_ID_ASC',
  ChildCommitHashRelationsAverageIdDesc = 'CHILD_COMMIT_HASH_RELATIONS_AVERAGE_ID_DESC',
  ChildCommitHashRelationsAverageParentIdAsc = 'CHILD_COMMIT_HASH_RELATIONS_AVERAGE_PARENT_ID_ASC',
  ChildCommitHashRelationsAverageParentIdDesc = 'CHILD_COMMIT_HASH_RELATIONS_AVERAGE_PARENT_ID_DESC',
  ChildCommitHashRelationsCountAsc = 'CHILD_COMMIT_HASH_RELATIONS_COUNT_ASC',
  ChildCommitHashRelationsCountDesc = 'CHILD_COMMIT_HASH_RELATIONS_COUNT_DESC',
  ChildCommitHashRelationsDistinctCountChildIdAsc = 'CHILD_COMMIT_HASH_RELATIONS_DISTINCT_COUNT_CHILD_ID_ASC',
  ChildCommitHashRelationsDistinctCountChildIdDesc = 'CHILD_COMMIT_HASH_RELATIONS_DISTINCT_COUNT_CHILD_ID_DESC',
  ChildCommitHashRelationsDistinctCountIdAsc = 'CHILD_COMMIT_HASH_RELATIONS_DISTINCT_COUNT_ID_ASC',
  ChildCommitHashRelationsDistinctCountIdDesc = 'CHILD_COMMIT_HASH_RELATIONS_DISTINCT_COUNT_ID_DESC',
  ChildCommitHashRelationsDistinctCountParentIdAsc = 'CHILD_COMMIT_HASH_RELATIONS_DISTINCT_COUNT_PARENT_ID_ASC',
  ChildCommitHashRelationsDistinctCountParentIdDesc = 'CHILD_COMMIT_HASH_RELATIONS_DISTINCT_COUNT_PARENT_ID_DESC',
  ChildCommitHashRelationsMaxChildIdAsc = 'CHILD_COMMIT_HASH_RELATIONS_MAX_CHILD_ID_ASC',
  ChildCommitHashRelationsMaxChildIdDesc = 'CHILD_COMMIT_HASH_RELATIONS_MAX_CHILD_ID_DESC',
  ChildCommitHashRelationsMaxIdAsc = 'CHILD_COMMIT_HASH_RELATIONS_MAX_ID_ASC',
  ChildCommitHashRelationsMaxIdDesc = 'CHILD_COMMIT_HASH_RELATIONS_MAX_ID_DESC',
  ChildCommitHashRelationsMaxParentIdAsc = 'CHILD_COMMIT_HASH_RELATIONS_MAX_PARENT_ID_ASC',
  ChildCommitHashRelationsMaxParentIdDesc = 'CHILD_COMMIT_HASH_RELATIONS_MAX_PARENT_ID_DESC',
  ChildCommitHashRelationsMinChildIdAsc = 'CHILD_COMMIT_HASH_RELATIONS_MIN_CHILD_ID_ASC',
  ChildCommitHashRelationsMinChildIdDesc = 'CHILD_COMMIT_HASH_RELATIONS_MIN_CHILD_ID_DESC',
  ChildCommitHashRelationsMinIdAsc = 'CHILD_COMMIT_HASH_RELATIONS_MIN_ID_ASC',
  ChildCommitHashRelationsMinIdDesc = 'CHILD_COMMIT_HASH_RELATIONS_MIN_ID_DESC',
  ChildCommitHashRelationsMinParentIdAsc = 'CHILD_COMMIT_HASH_RELATIONS_MIN_PARENT_ID_ASC',
  ChildCommitHashRelationsMinParentIdDesc = 'CHILD_COMMIT_HASH_RELATIONS_MIN_PARENT_ID_DESC',
  ChildCommitHashRelationsStddevPopulationChildIdAsc = 'CHILD_COMMIT_HASH_RELATIONS_STDDEV_POPULATION_CHILD_ID_ASC',
  ChildCommitHashRelationsStddevPopulationChildIdDesc = 'CHILD_COMMIT_HASH_RELATIONS_STDDEV_POPULATION_CHILD_ID_DESC',
  ChildCommitHashRelationsStddevPopulationIdAsc = 'CHILD_COMMIT_HASH_RELATIONS_STDDEV_POPULATION_ID_ASC',
  ChildCommitHashRelationsStddevPopulationIdDesc = 'CHILD_COMMIT_HASH_RELATIONS_STDDEV_POPULATION_ID_DESC',
  ChildCommitHashRelationsStddevPopulationParentIdAsc = 'CHILD_COMMIT_HASH_RELATIONS_STDDEV_POPULATION_PARENT_ID_ASC',
  ChildCommitHashRelationsStddevPopulationParentIdDesc = 'CHILD_COMMIT_HASH_RELATIONS_STDDEV_POPULATION_PARENT_ID_DESC',
  ChildCommitHashRelationsStddevSampleChildIdAsc = 'CHILD_COMMIT_HASH_RELATIONS_STDDEV_SAMPLE_CHILD_ID_ASC',
  ChildCommitHashRelationsStddevSampleChildIdDesc = 'CHILD_COMMIT_HASH_RELATIONS_STDDEV_SAMPLE_CHILD_ID_DESC',
  ChildCommitHashRelationsStddevSampleIdAsc = 'CHILD_COMMIT_HASH_RELATIONS_STDDEV_SAMPLE_ID_ASC',
  ChildCommitHashRelationsStddevSampleIdDesc = 'CHILD_COMMIT_HASH_RELATIONS_STDDEV_SAMPLE_ID_DESC',
  ChildCommitHashRelationsStddevSampleParentIdAsc = 'CHILD_COMMIT_HASH_RELATIONS_STDDEV_SAMPLE_PARENT_ID_ASC',
  ChildCommitHashRelationsStddevSampleParentIdDesc = 'CHILD_COMMIT_HASH_RELATIONS_STDDEV_SAMPLE_PARENT_ID_DESC',
  ChildCommitHashRelationsSumChildIdAsc = 'CHILD_COMMIT_HASH_RELATIONS_SUM_CHILD_ID_ASC',
  ChildCommitHashRelationsSumChildIdDesc = 'CHILD_COMMIT_HASH_RELATIONS_SUM_CHILD_ID_DESC',
  ChildCommitHashRelationsSumIdAsc = 'CHILD_COMMIT_HASH_RELATIONS_SUM_ID_ASC',
  ChildCommitHashRelationsSumIdDesc = 'CHILD_COMMIT_HASH_RELATIONS_SUM_ID_DESC',
  ChildCommitHashRelationsSumParentIdAsc = 'CHILD_COMMIT_HASH_RELATIONS_SUM_PARENT_ID_ASC',
  ChildCommitHashRelationsSumParentIdDesc = 'CHILD_COMMIT_HASH_RELATIONS_SUM_PARENT_ID_DESC',
  ChildCommitHashRelationsVariancePopulationChildIdAsc = 'CHILD_COMMIT_HASH_RELATIONS_VARIANCE_POPULATION_CHILD_ID_ASC',
  ChildCommitHashRelationsVariancePopulationChildIdDesc = 'CHILD_COMMIT_HASH_RELATIONS_VARIANCE_POPULATION_CHILD_ID_DESC',
  ChildCommitHashRelationsVariancePopulationIdAsc = 'CHILD_COMMIT_HASH_RELATIONS_VARIANCE_POPULATION_ID_ASC',
  ChildCommitHashRelationsVariancePopulationIdDesc = 'CHILD_COMMIT_HASH_RELATIONS_VARIANCE_POPULATION_ID_DESC',
  ChildCommitHashRelationsVariancePopulationParentIdAsc = 'CHILD_COMMIT_HASH_RELATIONS_VARIANCE_POPULATION_PARENT_ID_ASC',
  ChildCommitHashRelationsVariancePopulationParentIdDesc = 'CHILD_COMMIT_HASH_RELATIONS_VARIANCE_POPULATION_PARENT_ID_DESC',
  ChildCommitHashRelationsVarianceSampleChildIdAsc = 'CHILD_COMMIT_HASH_RELATIONS_VARIANCE_SAMPLE_CHILD_ID_ASC',
  ChildCommitHashRelationsVarianceSampleChildIdDesc = 'CHILD_COMMIT_HASH_RELATIONS_VARIANCE_SAMPLE_CHILD_ID_DESC',
  ChildCommitHashRelationsVarianceSampleIdAsc = 'CHILD_COMMIT_HASH_RELATIONS_VARIANCE_SAMPLE_ID_ASC',
  ChildCommitHashRelationsVarianceSampleIdDesc = 'CHILD_COMMIT_HASH_RELATIONS_VARIANCE_SAMPLE_ID_DESC',
  ChildCommitHashRelationsVarianceSampleParentIdAsc = 'CHILD_COMMIT_HASH_RELATIONS_VARIANCE_SAMPLE_PARENT_ID_ASC',
  ChildCommitHashRelationsVarianceSampleParentIdDesc = 'CHILD_COMMIT_HASH_RELATIONS_VARIANCE_SAMPLE_PARENT_ID_DESC',
  CommitHashAsc = 'COMMIT_HASH_ASC',
  CommitHashDesc = 'COMMIT_HASH_DESC',
  FilesAverageCommitHashIdAsc = 'FILES_AVERAGE_COMMIT_HASH_ID_ASC',
  FilesAverageCommitHashIdDesc = 'FILES_AVERAGE_COMMIT_HASH_ID_DESC',
  FilesAverageFileContentIdAsc = 'FILES_AVERAGE_FILE_CONTENT_ID_ASC',
  FilesAverageFileContentIdDesc = 'FILES_AVERAGE_FILE_CONTENT_ID_DESC',
  FilesAverageIdAsc = 'FILES_AVERAGE_ID_ASC',
  FilesAverageIdDesc = 'FILES_AVERAGE_ID_DESC',
  FilesAveragePathAsc = 'FILES_AVERAGE_PATH_ASC',
  FilesAveragePathDesc = 'FILES_AVERAGE_PATH_DESC',
  FilesCountAsc = 'FILES_COUNT_ASC',
  FilesCountDesc = 'FILES_COUNT_DESC',
  FilesDistinctCountCommitHashIdAsc = 'FILES_DISTINCT_COUNT_COMMIT_HASH_ID_ASC',
  FilesDistinctCountCommitHashIdDesc = 'FILES_DISTINCT_COUNT_COMMIT_HASH_ID_DESC',
  FilesDistinctCountFileContentIdAsc = 'FILES_DISTINCT_COUNT_FILE_CONTENT_ID_ASC',
  FilesDistinctCountFileContentIdDesc = 'FILES_DISTINCT_COUNT_FILE_CONTENT_ID_DESC',
  FilesDistinctCountIdAsc = 'FILES_DISTINCT_COUNT_ID_ASC',
  FilesDistinctCountIdDesc = 'FILES_DISTINCT_COUNT_ID_DESC',
  FilesDistinctCountPathAsc = 'FILES_DISTINCT_COUNT_PATH_ASC',
  FilesDistinctCountPathDesc = 'FILES_DISTINCT_COUNT_PATH_DESC',
  FilesMaxCommitHashIdAsc = 'FILES_MAX_COMMIT_HASH_ID_ASC',
  FilesMaxCommitHashIdDesc = 'FILES_MAX_COMMIT_HASH_ID_DESC',
  FilesMaxFileContentIdAsc = 'FILES_MAX_FILE_CONTENT_ID_ASC',
  FilesMaxFileContentIdDesc = 'FILES_MAX_FILE_CONTENT_ID_DESC',
  FilesMaxIdAsc = 'FILES_MAX_ID_ASC',
  FilesMaxIdDesc = 'FILES_MAX_ID_DESC',
  FilesMaxPathAsc = 'FILES_MAX_PATH_ASC',
  FilesMaxPathDesc = 'FILES_MAX_PATH_DESC',
  FilesMinCommitHashIdAsc = 'FILES_MIN_COMMIT_HASH_ID_ASC',
  FilesMinCommitHashIdDesc = 'FILES_MIN_COMMIT_HASH_ID_DESC',
  FilesMinFileContentIdAsc = 'FILES_MIN_FILE_CONTENT_ID_ASC',
  FilesMinFileContentIdDesc = 'FILES_MIN_FILE_CONTENT_ID_DESC',
  FilesMinIdAsc = 'FILES_MIN_ID_ASC',
  FilesMinIdDesc = 'FILES_MIN_ID_DESC',
  FilesMinPathAsc = 'FILES_MIN_PATH_ASC',
  FilesMinPathDesc = 'FILES_MIN_PATH_DESC',
  FilesStddevPopulationCommitHashIdAsc = 'FILES_STDDEV_POPULATION_COMMIT_HASH_ID_ASC',
  FilesStddevPopulationCommitHashIdDesc = 'FILES_STDDEV_POPULATION_COMMIT_HASH_ID_DESC',
  FilesStddevPopulationFileContentIdAsc = 'FILES_STDDEV_POPULATION_FILE_CONTENT_ID_ASC',
  FilesStddevPopulationFileContentIdDesc = 'FILES_STDDEV_POPULATION_FILE_CONTENT_ID_DESC',
  FilesStddevPopulationIdAsc = 'FILES_STDDEV_POPULATION_ID_ASC',
  FilesStddevPopulationIdDesc = 'FILES_STDDEV_POPULATION_ID_DESC',
  FilesStddevPopulationPathAsc = 'FILES_STDDEV_POPULATION_PATH_ASC',
  FilesStddevPopulationPathDesc = 'FILES_STDDEV_POPULATION_PATH_DESC',
  FilesStddevSampleCommitHashIdAsc = 'FILES_STDDEV_SAMPLE_COMMIT_HASH_ID_ASC',
  FilesStddevSampleCommitHashIdDesc = 'FILES_STDDEV_SAMPLE_COMMIT_HASH_ID_DESC',
  FilesStddevSampleFileContentIdAsc = 'FILES_STDDEV_SAMPLE_FILE_CONTENT_ID_ASC',
  FilesStddevSampleFileContentIdDesc = 'FILES_STDDEV_SAMPLE_FILE_CONTENT_ID_DESC',
  FilesStddevSampleIdAsc = 'FILES_STDDEV_SAMPLE_ID_ASC',
  FilesStddevSampleIdDesc = 'FILES_STDDEV_SAMPLE_ID_DESC',
  FilesStddevSamplePathAsc = 'FILES_STDDEV_SAMPLE_PATH_ASC',
  FilesStddevSamplePathDesc = 'FILES_STDDEV_SAMPLE_PATH_DESC',
  FilesSumCommitHashIdAsc = 'FILES_SUM_COMMIT_HASH_ID_ASC',
  FilesSumCommitHashIdDesc = 'FILES_SUM_COMMIT_HASH_ID_DESC',
  FilesSumFileContentIdAsc = 'FILES_SUM_FILE_CONTENT_ID_ASC',
  FilesSumFileContentIdDesc = 'FILES_SUM_FILE_CONTENT_ID_DESC',
  FilesSumIdAsc = 'FILES_SUM_ID_ASC',
  FilesSumIdDesc = 'FILES_SUM_ID_DESC',
  FilesSumPathAsc = 'FILES_SUM_PATH_ASC',
  FilesSumPathDesc = 'FILES_SUM_PATH_DESC',
  FilesVariancePopulationCommitHashIdAsc = 'FILES_VARIANCE_POPULATION_COMMIT_HASH_ID_ASC',
  FilesVariancePopulationCommitHashIdDesc = 'FILES_VARIANCE_POPULATION_COMMIT_HASH_ID_DESC',
  FilesVariancePopulationFileContentIdAsc = 'FILES_VARIANCE_POPULATION_FILE_CONTENT_ID_ASC',
  FilesVariancePopulationFileContentIdDesc = 'FILES_VARIANCE_POPULATION_FILE_CONTENT_ID_DESC',
  FilesVariancePopulationIdAsc = 'FILES_VARIANCE_POPULATION_ID_ASC',
  FilesVariancePopulationIdDesc = 'FILES_VARIANCE_POPULATION_ID_DESC',
  FilesVariancePopulationPathAsc = 'FILES_VARIANCE_POPULATION_PATH_ASC',
  FilesVariancePopulationPathDesc = 'FILES_VARIANCE_POPULATION_PATH_DESC',
  FilesVarianceSampleCommitHashIdAsc = 'FILES_VARIANCE_SAMPLE_COMMIT_HASH_ID_ASC',
  FilesVarianceSampleCommitHashIdDesc = 'FILES_VARIANCE_SAMPLE_COMMIT_HASH_ID_DESC',
  FilesVarianceSampleFileContentIdAsc = 'FILES_VARIANCE_SAMPLE_FILE_CONTENT_ID_ASC',
  FilesVarianceSampleFileContentIdDesc = 'FILES_VARIANCE_SAMPLE_FILE_CONTENT_ID_DESC',
  FilesVarianceSampleIdAsc = 'FILES_VARIANCE_SAMPLE_ID_ASC',
  FilesVarianceSampleIdDesc = 'FILES_VARIANCE_SAMPLE_ID_DESC',
  FilesVarianceSamplePathAsc = 'FILES_VARIANCE_SAMPLE_PATH_ASC',
  FilesVarianceSamplePathDesc = 'FILES_VARIANCE_SAMPLE_PATH_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ImportPhaseAsc = 'IMPORT_PHASE_ASC',
  ImportPhaseDesc = 'IMPORT_PHASE_DESC',
  Natural = 'NATURAL',
  ParentCommitHashRelationsAverageChildIdAsc = 'PARENT_COMMIT_HASH_RELATIONS_AVERAGE_CHILD_ID_ASC',
  ParentCommitHashRelationsAverageChildIdDesc = 'PARENT_COMMIT_HASH_RELATIONS_AVERAGE_CHILD_ID_DESC',
  ParentCommitHashRelationsAverageIdAsc = 'PARENT_COMMIT_HASH_RELATIONS_AVERAGE_ID_ASC',
  ParentCommitHashRelationsAverageIdDesc = 'PARENT_COMMIT_HASH_RELATIONS_AVERAGE_ID_DESC',
  ParentCommitHashRelationsAverageParentIdAsc = 'PARENT_COMMIT_HASH_RELATIONS_AVERAGE_PARENT_ID_ASC',
  ParentCommitHashRelationsAverageParentIdDesc = 'PARENT_COMMIT_HASH_RELATIONS_AVERAGE_PARENT_ID_DESC',
  ParentCommitHashRelationsCountAsc = 'PARENT_COMMIT_HASH_RELATIONS_COUNT_ASC',
  ParentCommitHashRelationsCountDesc = 'PARENT_COMMIT_HASH_RELATIONS_COUNT_DESC',
  ParentCommitHashRelationsDistinctCountChildIdAsc = 'PARENT_COMMIT_HASH_RELATIONS_DISTINCT_COUNT_CHILD_ID_ASC',
  ParentCommitHashRelationsDistinctCountChildIdDesc = 'PARENT_COMMIT_HASH_RELATIONS_DISTINCT_COUNT_CHILD_ID_DESC',
  ParentCommitHashRelationsDistinctCountIdAsc = 'PARENT_COMMIT_HASH_RELATIONS_DISTINCT_COUNT_ID_ASC',
  ParentCommitHashRelationsDistinctCountIdDesc = 'PARENT_COMMIT_HASH_RELATIONS_DISTINCT_COUNT_ID_DESC',
  ParentCommitHashRelationsDistinctCountParentIdAsc = 'PARENT_COMMIT_HASH_RELATIONS_DISTINCT_COUNT_PARENT_ID_ASC',
  ParentCommitHashRelationsDistinctCountParentIdDesc = 'PARENT_COMMIT_HASH_RELATIONS_DISTINCT_COUNT_PARENT_ID_DESC',
  ParentCommitHashRelationsMaxChildIdAsc = 'PARENT_COMMIT_HASH_RELATIONS_MAX_CHILD_ID_ASC',
  ParentCommitHashRelationsMaxChildIdDesc = 'PARENT_COMMIT_HASH_RELATIONS_MAX_CHILD_ID_DESC',
  ParentCommitHashRelationsMaxIdAsc = 'PARENT_COMMIT_HASH_RELATIONS_MAX_ID_ASC',
  ParentCommitHashRelationsMaxIdDesc = 'PARENT_COMMIT_HASH_RELATIONS_MAX_ID_DESC',
  ParentCommitHashRelationsMaxParentIdAsc = 'PARENT_COMMIT_HASH_RELATIONS_MAX_PARENT_ID_ASC',
  ParentCommitHashRelationsMaxParentIdDesc = 'PARENT_COMMIT_HASH_RELATIONS_MAX_PARENT_ID_DESC',
  ParentCommitHashRelationsMinChildIdAsc = 'PARENT_COMMIT_HASH_RELATIONS_MIN_CHILD_ID_ASC',
  ParentCommitHashRelationsMinChildIdDesc = 'PARENT_COMMIT_HASH_RELATIONS_MIN_CHILD_ID_DESC',
  ParentCommitHashRelationsMinIdAsc = 'PARENT_COMMIT_HASH_RELATIONS_MIN_ID_ASC',
  ParentCommitHashRelationsMinIdDesc = 'PARENT_COMMIT_HASH_RELATIONS_MIN_ID_DESC',
  ParentCommitHashRelationsMinParentIdAsc = 'PARENT_COMMIT_HASH_RELATIONS_MIN_PARENT_ID_ASC',
  ParentCommitHashRelationsMinParentIdDesc = 'PARENT_COMMIT_HASH_RELATIONS_MIN_PARENT_ID_DESC',
  ParentCommitHashRelationsStddevPopulationChildIdAsc = 'PARENT_COMMIT_HASH_RELATIONS_STDDEV_POPULATION_CHILD_ID_ASC',
  ParentCommitHashRelationsStddevPopulationChildIdDesc = 'PARENT_COMMIT_HASH_RELATIONS_STDDEV_POPULATION_CHILD_ID_DESC',
  ParentCommitHashRelationsStddevPopulationIdAsc = 'PARENT_COMMIT_HASH_RELATIONS_STDDEV_POPULATION_ID_ASC',
  ParentCommitHashRelationsStddevPopulationIdDesc = 'PARENT_COMMIT_HASH_RELATIONS_STDDEV_POPULATION_ID_DESC',
  ParentCommitHashRelationsStddevPopulationParentIdAsc = 'PARENT_COMMIT_HASH_RELATIONS_STDDEV_POPULATION_PARENT_ID_ASC',
  ParentCommitHashRelationsStddevPopulationParentIdDesc = 'PARENT_COMMIT_HASH_RELATIONS_STDDEV_POPULATION_PARENT_ID_DESC',
  ParentCommitHashRelationsStddevSampleChildIdAsc = 'PARENT_COMMIT_HASH_RELATIONS_STDDEV_SAMPLE_CHILD_ID_ASC',
  ParentCommitHashRelationsStddevSampleChildIdDesc = 'PARENT_COMMIT_HASH_RELATIONS_STDDEV_SAMPLE_CHILD_ID_DESC',
  ParentCommitHashRelationsStddevSampleIdAsc = 'PARENT_COMMIT_HASH_RELATIONS_STDDEV_SAMPLE_ID_ASC',
  ParentCommitHashRelationsStddevSampleIdDesc = 'PARENT_COMMIT_HASH_RELATIONS_STDDEV_SAMPLE_ID_DESC',
  ParentCommitHashRelationsStddevSampleParentIdAsc = 'PARENT_COMMIT_HASH_RELATIONS_STDDEV_SAMPLE_PARENT_ID_ASC',
  ParentCommitHashRelationsStddevSampleParentIdDesc = 'PARENT_COMMIT_HASH_RELATIONS_STDDEV_SAMPLE_PARENT_ID_DESC',
  ParentCommitHashRelationsSumChildIdAsc = 'PARENT_COMMIT_HASH_RELATIONS_SUM_CHILD_ID_ASC',
  ParentCommitHashRelationsSumChildIdDesc = 'PARENT_COMMIT_HASH_RELATIONS_SUM_CHILD_ID_DESC',
  ParentCommitHashRelationsSumIdAsc = 'PARENT_COMMIT_HASH_RELATIONS_SUM_ID_ASC',
  ParentCommitHashRelationsSumIdDesc = 'PARENT_COMMIT_HASH_RELATIONS_SUM_ID_DESC',
  ParentCommitHashRelationsSumParentIdAsc = 'PARENT_COMMIT_HASH_RELATIONS_SUM_PARENT_ID_ASC',
  ParentCommitHashRelationsSumParentIdDesc = 'PARENT_COMMIT_HASH_RELATIONS_SUM_PARENT_ID_DESC',
  ParentCommitHashRelationsVariancePopulationChildIdAsc = 'PARENT_COMMIT_HASH_RELATIONS_VARIANCE_POPULATION_CHILD_ID_ASC',
  ParentCommitHashRelationsVariancePopulationChildIdDesc = 'PARENT_COMMIT_HASH_RELATIONS_VARIANCE_POPULATION_CHILD_ID_DESC',
  ParentCommitHashRelationsVariancePopulationIdAsc = 'PARENT_COMMIT_HASH_RELATIONS_VARIANCE_POPULATION_ID_ASC',
  ParentCommitHashRelationsVariancePopulationIdDesc = 'PARENT_COMMIT_HASH_RELATIONS_VARIANCE_POPULATION_ID_DESC',
  ParentCommitHashRelationsVariancePopulationParentIdAsc = 'PARENT_COMMIT_HASH_RELATIONS_VARIANCE_POPULATION_PARENT_ID_ASC',
  ParentCommitHashRelationsVariancePopulationParentIdDesc = 'PARENT_COMMIT_HASH_RELATIONS_VARIANCE_POPULATION_PARENT_ID_DESC',
  ParentCommitHashRelationsVarianceSampleChildIdAsc = 'PARENT_COMMIT_HASH_RELATIONS_VARIANCE_SAMPLE_CHILD_ID_ASC',
  ParentCommitHashRelationsVarianceSampleChildIdDesc = 'PARENT_COMMIT_HASH_RELATIONS_VARIANCE_SAMPLE_CHILD_ID_DESC',
  ParentCommitHashRelationsVarianceSampleIdAsc = 'PARENT_COMMIT_HASH_RELATIONS_VARIANCE_SAMPLE_ID_ASC',
  ParentCommitHashRelationsVarianceSampleIdDesc = 'PARENT_COMMIT_HASH_RELATIONS_VARIANCE_SAMPLE_ID_DESC',
  ParentCommitHashRelationsVarianceSampleParentIdAsc = 'PARENT_COMMIT_HASH_RELATIONS_VARIANCE_SAMPLE_PARENT_ID_ASC',
  ParentCommitHashRelationsVarianceSampleParentIdDesc = 'PARENT_COMMIT_HASH_RELATIONS_VARIANCE_SAMPLE_PARENT_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type DataCatalogueSnapshotV8 = Node & {
  __typename?: 'DataCatalogueSnapshotV8';
  /** Reads a single `DataCatalogueSnapshotV8` that is related to this `DataCatalogueSnapshotV8`. */
  base?: Maybe<DataCatalogueSnapshotV8>;
  baseId?: Maybe<Scalars['BigInt']>;
  catalogueVersion: Scalars['Int'];
  commitHashId?: Maybe<Scalars['BigInt']>;
  createdDttm: Scalars['Datetime'];
  /** Reads and enables pagination through a set of `DataCatalogueSnapshotV8`. */
  dataCatalogueSnapshotV8SByBaseId: DataCatalogueSnapshotV8sConnection;
  dbtCatalogueInvocationId?: Maybe<Scalars['String']>;
  fivetranSnapshotId?: Maybe<Scalars['BigInt']>;
  hightouchSnapshotId?: Maybe<Scalars['BigInt']>;
  id: Scalars['BigInt'];
  informationSchemaSnapshotId?: Maybe<Scalars['BigInt']>;
  integrationSnapshots?: Maybe<Array<Maybe<Scalars['Int']>>>;
  lookerRepositorySnapshotId?: Maybe<Scalars['BigInt']>;
  lookerSnapshotId?: Maybe<Scalars['BigInt']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  tableauSnapshotId?: Maybe<Scalars['BigInt']>;
  testLogId?: Maybe<Scalars['BigInt']>;
};


export type DataCatalogueSnapshotV8DataCatalogueSnapshotV8SByBaseIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<DataCatalogueSnapshotV8Condition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DataCatalogueSnapshotV8sOrderBy>>;
};

export type DataCatalogueSnapshotV8Aggregates = {
  __typename?: 'DataCatalogueSnapshotV8Aggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<DataCatalogueSnapshotV8AverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<DataCatalogueSnapshotV8DistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<DataCatalogueSnapshotV8MaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<DataCatalogueSnapshotV8MinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<DataCatalogueSnapshotV8StddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<DataCatalogueSnapshotV8StddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<DataCatalogueSnapshotV8SumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<DataCatalogueSnapshotV8VariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<DataCatalogueSnapshotV8VarianceSampleAggregates>;
};

export type DataCatalogueSnapshotV8AverageAggregates = {
  __typename?: 'DataCatalogueSnapshotV8AverageAggregates';
  /** Mean average of baseId across the matching connection */
  baseId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of catalogueVersion across the matching connection */
  catalogueVersion?: Maybe<Scalars['BigFloat']>;
  /** Mean average of commitHashId across the matching connection */
  commitHashId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of fivetranSnapshotId across the matching connection */
  fivetranSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of hightouchSnapshotId across the matching connection */
  hightouchSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of informationSchemaSnapshotId across the matching connection */
  informationSchemaSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of lookerRepositorySnapshotId across the matching connection */
  lookerRepositorySnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of lookerSnapshotId across the matching connection */
  lookerSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of tableauSnapshotId across the matching connection */
  tableauSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of testLogId across the matching connection */
  testLogId?: Maybe<Scalars['BigFloat']>;
};

export type DataCatalogueSnapshotV8ChildItem = Node & {
  __typename?: 'DataCatalogueSnapshotV8ChildItem';
  catalogueSnapshotId: Scalars['BigInt'];
  childIndex: Scalars['Int'];
  excerptDescription: Scalars['String'];
  excerptName: Scalars['String'];
  excerptTags: Scalars['String'];
  id: Scalars['BigInt'];
  itemType: Scalars['Int'];
  metaFields: Array<Maybe<Scalars['String']>>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  parentId: Scalars['BigInt'];
};

export type DataCatalogueSnapshotV8ChildItemAggregates = {
  __typename?: 'DataCatalogueSnapshotV8ChildItemAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<DataCatalogueSnapshotV8ChildItemAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<DataCatalogueSnapshotV8ChildItemDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<DataCatalogueSnapshotV8ChildItemMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<DataCatalogueSnapshotV8ChildItemMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<DataCatalogueSnapshotV8ChildItemStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<DataCatalogueSnapshotV8ChildItemStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<DataCatalogueSnapshotV8ChildItemSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<DataCatalogueSnapshotV8ChildItemVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<DataCatalogueSnapshotV8ChildItemVarianceSampleAggregates>;
};

export type DataCatalogueSnapshotV8ChildItemAverageAggregates = {
  __typename?: 'DataCatalogueSnapshotV8ChildItemAverageAggregates';
  /** Mean average of catalogueSnapshotId across the matching connection */
  catalogueSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of childIndex across the matching connection */
  childIndex?: Maybe<Scalars['BigFloat']>;
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of itemType across the matching connection */
  itemType?: Maybe<Scalars['BigFloat']>;
  /** Mean average of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `DataCatalogueSnapshotV8ChildItem` object types.
 * All fields are tested for equality and combined with a logical ‘and.’
 */
export type DataCatalogueSnapshotV8ChildItemCondition = {
  /** Checks for equality with the object’s `catalogueSnapshotId` field. */
  catalogueSnapshotId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `childIndex` field. */
  childIndex?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `excerptDescription` field. */
  excerptDescription?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `excerptName` field. */
  excerptName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `excerptTags` field. */
  excerptTags?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `itemType` field. */
  itemType?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `metaFields` field. */
  metaFields?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `parentId` field. */
  parentId?: InputMaybe<Scalars['BigInt']>;
};

export type DataCatalogueSnapshotV8ChildItemDistinctCountAggregates = {
  __typename?: 'DataCatalogueSnapshotV8ChildItemDistinctCountAggregates';
  /** Distinct count of catalogueSnapshotId across the matching connection */
  catalogueSnapshotId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of childIndex across the matching connection */
  childIndex?: Maybe<Scalars['BigInt']>;
  /** Distinct count of excerptDescription across the matching connection */
  excerptDescription?: Maybe<Scalars['BigInt']>;
  /** Distinct count of excerptName across the matching connection */
  excerptName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of excerptTags across the matching connection */
  excerptTags?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of itemType across the matching connection */
  itemType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of metaFields across the matching connection */
  metaFields?: Maybe<Scalars['BigInt']>;
  /** Distinct count of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigInt']>;
};

/** Grouping methods for `DataCatalogueSnapshotV8ChildItem` for usage during aggregation. */
export enum DataCatalogueSnapshotV8ChildItemGroupBy {
  CatalogueSnapshotId = 'CATALOGUE_SNAPSHOT_ID',
  ChildIndex = 'CHILD_INDEX',
  ExcerptDescription = 'EXCERPT_DESCRIPTION',
  ExcerptName = 'EXCERPT_NAME',
  ExcerptTags = 'EXCERPT_TAGS',
  ItemType = 'ITEM_TYPE',
  MetaFields = 'META_FIELDS',
  ParentId = 'PARENT_ID'
}

export type DataCatalogueSnapshotV8ChildItemHavingAverageInput = {
  catalogueSnapshotId?: InputMaybe<HavingBigintFilter>;
  childIndex?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  itemType?: InputMaybe<HavingIntFilter>;
  parentId?: InputMaybe<HavingBigintFilter>;
};

export type DataCatalogueSnapshotV8ChildItemHavingDistinctCountInput = {
  catalogueSnapshotId?: InputMaybe<HavingBigintFilter>;
  childIndex?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  itemType?: InputMaybe<HavingIntFilter>;
  parentId?: InputMaybe<HavingBigintFilter>;
};

/** Conditions for `DataCatalogueSnapshotV8ChildItem` aggregates. */
export type DataCatalogueSnapshotV8ChildItemHavingInput = {
  AND?: InputMaybe<Array<DataCatalogueSnapshotV8ChildItemHavingInput>>;
  OR?: InputMaybe<Array<DataCatalogueSnapshotV8ChildItemHavingInput>>;
  average?: InputMaybe<DataCatalogueSnapshotV8ChildItemHavingAverageInput>;
  distinctCount?: InputMaybe<DataCatalogueSnapshotV8ChildItemHavingDistinctCountInput>;
  max?: InputMaybe<DataCatalogueSnapshotV8ChildItemHavingMaxInput>;
  min?: InputMaybe<DataCatalogueSnapshotV8ChildItemHavingMinInput>;
  stddevPopulation?: InputMaybe<DataCatalogueSnapshotV8ChildItemHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<DataCatalogueSnapshotV8ChildItemHavingStddevSampleInput>;
  sum?: InputMaybe<DataCatalogueSnapshotV8ChildItemHavingSumInput>;
  variancePopulation?: InputMaybe<DataCatalogueSnapshotV8ChildItemHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<DataCatalogueSnapshotV8ChildItemHavingVarianceSampleInput>;
};

export type DataCatalogueSnapshotV8ChildItemHavingMaxInput = {
  catalogueSnapshotId?: InputMaybe<HavingBigintFilter>;
  childIndex?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  itemType?: InputMaybe<HavingIntFilter>;
  parentId?: InputMaybe<HavingBigintFilter>;
};

export type DataCatalogueSnapshotV8ChildItemHavingMinInput = {
  catalogueSnapshotId?: InputMaybe<HavingBigintFilter>;
  childIndex?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  itemType?: InputMaybe<HavingIntFilter>;
  parentId?: InputMaybe<HavingBigintFilter>;
};

export type DataCatalogueSnapshotV8ChildItemHavingStddevPopulationInput = {
  catalogueSnapshotId?: InputMaybe<HavingBigintFilter>;
  childIndex?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  itemType?: InputMaybe<HavingIntFilter>;
  parentId?: InputMaybe<HavingBigintFilter>;
};

export type DataCatalogueSnapshotV8ChildItemHavingStddevSampleInput = {
  catalogueSnapshotId?: InputMaybe<HavingBigintFilter>;
  childIndex?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  itemType?: InputMaybe<HavingIntFilter>;
  parentId?: InputMaybe<HavingBigintFilter>;
};

export type DataCatalogueSnapshotV8ChildItemHavingSumInput = {
  catalogueSnapshotId?: InputMaybe<HavingBigintFilter>;
  childIndex?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  itemType?: InputMaybe<HavingIntFilter>;
  parentId?: InputMaybe<HavingBigintFilter>;
};

export type DataCatalogueSnapshotV8ChildItemHavingVariancePopulationInput = {
  catalogueSnapshotId?: InputMaybe<HavingBigintFilter>;
  childIndex?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  itemType?: InputMaybe<HavingIntFilter>;
  parentId?: InputMaybe<HavingBigintFilter>;
};

export type DataCatalogueSnapshotV8ChildItemHavingVarianceSampleInput = {
  catalogueSnapshotId?: InputMaybe<HavingBigintFilter>;
  childIndex?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  itemType?: InputMaybe<HavingIntFilter>;
  parentId?: InputMaybe<HavingBigintFilter>;
};

export type DataCatalogueSnapshotV8ChildItemMaxAggregates = {
  __typename?: 'DataCatalogueSnapshotV8ChildItemMaxAggregates';
  /** Maximum of catalogueSnapshotId across the matching connection */
  catalogueSnapshotId?: Maybe<Scalars['BigInt']>;
  /** Maximum of childIndex across the matching connection */
  childIndex?: Maybe<Scalars['Int']>;
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Maximum of itemType across the matching connection */
  itemType?: Maybe<Scalars['Int']>;
  /** Maximum of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigInt']>;
};

export type DataCatalogueSnapshotV8ChildItemMinAggregates = {
  __typename?: 'DataCatalogueSnapshotV8ChildItemMinAggregates';
  /** Minimum of catalogueSnapshotId across the matching connection */
  catalogueSnapshotId?: Maybe<Scalars['BigInt']>;
  /** Minimum of childIndex across the matching connection */
  childIndex?: Maybe<Scalars['Int']>;
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Minimum of itemType across the matching connection */
  itemType?: Maybe<Scalars['Int']>;
  /** Minimum of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigInt']>;
};

export type DataCatalogueSnapshotV8ChildItemStddevPopulationAggregates = {
  __typename?: 'DataCatalogueSnapshotV8ChildItemStddevPopulationAggregates';
  /** Population standard deviation of catalogueSnapshotId across the matching connection */
  catalogueSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of childIndex across the matching connection */
  childIndex?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of itemType across the matching connection */
  itemType?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigFloat']>;
};

export type DataCatalogueSnapshotV8ChildItemStddevSampleAggregates = {
  __typename?: 'DataCatalogueSnapshotV8ChildItemStddevSampleAggregates';
  /** Sample standard deviation of catalogueSnapshotId across the matching connection */
  catalogueSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of childIndex across the matching connection */
  childIndex?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of itemType across the matching connection */
  itemType?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigFloat']>;
};

export type DataCatalogueSnapshotV8ChildItemSumAggregates = {
  __typename?: 'DataCatalogueSnapshotV8ChildItemSumAggregates';
  /** Sum of catalogueSnapshotId across the matching connection */
  catalogueSnapshotId: Scalars['BigFloat'];
  /** Sum of childIndex across the matching connection */
  childIndex: Scalars['BigInt'];
  /** Sum of id across the matching connection */
  id: Scalars['BigFloat'];
  /** Sum of itemType across the matching connection */
  itemType: Scalars['BigInt'];
  /** Sum of parentId across the matching connection */
  parentId: Scalars['BigFloat'];
};

export type DataCatalogueSnapshotV8ChildItemVariancePopulationAggregates = {
  __typename?: 'DataCatalogueSnapshotV8ChildItemVariancePopulationAggregates';
  /** Population variance of catalogueSnapshotId across the matching connection */
  catalogueSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of childIndex across the matching connection */
  childIndex?: Maybe<Scalars['BigFloat']>;
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of itemType across the matching connection */
  itemType?: Maybe<Scalars['BigFloat']>;
  /** Population variance of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigFloat']>;
};

export type DataCatalogueSnapshotV8ChildItemVarianceSampleAggregates = {
  __typename?: 'DataCatalogueSnapshotV8ChildItemVarianceSampleAggregates';
  /** Sample variance of catalogueSnapshotId across the matching connection */
  catalogueSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of childIndex across the matching connection */
  childIndex?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of itemType across the matching connection */
  itemType?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `DataCatalogueSnapshotV8ChildItem` values. */
export type DataCatalogueSnapshotV8ChildItemsConnection = {
  __typename?: 'DataCatalogueSnapshotV8ChildItemsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<DataCatalogueSnapshotV8ChildItemAggregates>;
  /** A list of edges which contains the `DataCatalogueSnapshotV8ChildItem` and cursor to aid in pagination. */
  edges: Array<DataCatalogueSnapshotV8ChildItemsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<DataCatalogueSnapshotV8ChildItemAggregates>>;
  /** A list of `DataCatalogueSnapshotV8ChildItem` objects. */
  nodes: Array<DataCatalogueSnapshotV8ChildItem>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DataCatalogueSnapshotV8ChildItem` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `DataCatalogueSnapshotV8ChildItem` values. */
export type DataCatalogueSnapshotV8ChildItemsConnectionGroupedAggregatesArgs = {
  groupBy: Array<DataCatalogueSnapshotV8ChildItemGroupBy>;
  having?: InputMaybe<DataCatalogueSnapshotV8ChildItemHavingInput>;
};

/** A `DataCatalogueSnapshotV8ChildItem` edge in the connection. */
export type DataCatalogueSnapshotV8ChildItemsEdge = {
  __typename?: 'DataCatalogueSnapshotV8ChildItemsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `DataCatalogueSnapshotV8ChildItem` at the end of the edge. */
  node: DataCatalogueSnapshotV8ChildItem;
};

/** Methods to use when ordering `DataCatalogueSnapshotV8ChildItem`. */
export enum DataCatalogueSnapshotV8ChildItemsOrderBy {
  CatalogueSnapshotIdAsc = 'CATALOGUE_SNAPSHOT_ID_ASC',
  CatalogueSnapshotIdDesc = 'CATALOGUE_SNAPSHOT_ID_DESC',
  ChildIndexAsc = 'CHILD_INDEX_ASC',
  ChildIndexDesc = 'CHILD_INDEX_DESC',
  ExcerptDescriptionAsc = 'EXCERPT_DESCRIPTION_ASC',
  ExcerptDescriptionDesc = 'EXCERPT_DESCRIPTION_DESC',
  ExcerptNameAsc = 'EXCERPT_NAME_ASC',
  ExcerptNameDesc = 'EXCERPT_NAME_DESC',
  ExcerptTagsAsc = 'EXCERPT_TAGS_ASC',
  ExcerptTagsDesc = 'EXCERPT_TAGS_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ItemTypeAsc = 'ITEM_TYPE_ASC',
  ItemTypeDesc = 'ITEM_TYPE_DESC',
  MetaFieldsAsc = 'META_FIELDS_ASC',
  MetaFieldsDesc = 'META_FIELDS_DESC',
  Natural = 'NATURAL',
  ParentIdAsc = 'PARENT_ID_ASC',
  ParentIdDesc = 'PARENT_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `DataCatalogueSnapshotV8` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type DataCatalogueSnapshotV8Condition = {
  /** Checks for equality with the object’s `baseId` field. */
  baseId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `catalogueVersion` field. */
  catalogueVersion?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `commitHashId` field. */
  commitHashId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `createdDttm` field. */
  createdDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `dbtCatalogueInvocationId` field. */
  dbtCatalogueInvocationId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `fivetranSnapshotId` field. */
  fivetranSnapshotId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `hightouchSnapshotId` field. */
  hightouchSnapshotId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `informationSchemaSnapshotId` field. */
  informationSchemaSnapshotId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `integrationSnapshots` field. */
  integrationSnapshots?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** Checks for equality with the object’s `lookerRepositorySnapshotId` field. */
  lookerRepositorySnapshotId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `lookerSnapshotId` field. */
  lookerSnapshotId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `tableauSnapshotId` field. */
  tableauSnapshotId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `testLogId` field. */
  testLogId?: InputMaybe<Scalars['BigInt']>;
};

export type DataCatalogueSnapshotV8DistinctCountAggregates = {
  __typename?: 'DataCatalogueSnapshotV8DistinctCountAggregates';
  /** Distinct count of baseId across the matching connection */
  baseId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of catalogueVersion across the matching connection */
  catalogueVersion?: Maybe<Scalars['BigInt']>;
  /** Distinct count of commitHashId across the matching connection */
  commitHashId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdDttm across the matching connection */
  createdDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of dbtCatalogueInvocationId across the matching connection */
  dbtCatalogueInvocationId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of fivetranSnapshotId across the matching connection */
  fivetranSnapshotId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of hightouchSnapshotId across the matching connection */
  hightouchSnapshotId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of informationSchemaSnapshotId across the matching connection */
  informationSchemaSnapshotId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of integrationSnapshots across the matching connection */
  integrationSnapshots?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lookerRepositorySnapshotId across the matching connection */
  lookerRepositorySnapshotId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lookerSnapshotId across the matching connection */
  lookerSnapshotId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of tableauSnapshotId across the matching connection */
  tableauSnapshotId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of testLogId across the matching connection */
  testLogId?: Maybe<Scalars['BigInt']>;
};

/** Grouping methods for `DataCatalogueSnapshotV8` for usage during aggregation. */
export enum DataCatalogueSnapshotV8GroupBy {
  BaseId = 'BASE_ID',
  CatalogueVersion = 'CATALOGUE_VERSION',
  CommitHashId = 'COMMIT_HASH_ID',
  CreatedDttm = 'CREATED_DTTM',
  CreatedDttmTruncatedToDay = 'CREATED_DTTM_TRUNCATED_TO_DAY',
  CreatedDttmTruncatedToHour = 'CREATED_DTTM_TRUNCATED_TO_HOUR',
  DbtCatalogueInvocationId = 'DBT_CATALOGUE_INVOCATION_ID',
  FivetranSnapshotId = 'FIVETRAN_SNAPSHOT_ID',
  HightouchSnapshotId = 'HIGHTOUCH_SNAPSHOT_ID',
  InformationSchemaSnapshotId = 'INFORMATION_SCHEMA_SNAPSHOT_ID',
  IntegrationSnapshots = 'INTEGRATION_SNAPSHOTS',
  LookerRepositorySnapshotId = 'LOOKER_REPOSITORY_SNAPSHOT_ID',
  LookerSnapshotId = 'LOOKER_SNAPSHOT_ID',
  TableauSnapshotId = 'TABLEAU_SNAPSHOT_ID',
  TestLogId = 'TEST_LOG_ID'
}

export type DataCatalogueSnapshotV8HavingAverageInput = {
  baseId?: InputMaybe<HavingBigintFilter>;
  catalogueVersion?: InputMaybe<HavingIntFilter>;
  commitHashId?: InputMaybe<HavingBigintFilter>;
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  fivetranSnapshotId?: InputMaybe<HavingBigintFilter>;
  hightouchSnapshotId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  informationSchemaSnapshotId?: InputMaybe<HavingBigintFilter>;
  lookerRepositorySnapshotId?: InputMaybe<HavingBigintFilter>;
  lookerSnapshotId?: InputMaybe<HavingBigintFilter>;
  tableauSnapshotId?: InputMaybe<HavingBigintFilter>;
  testLogId?: InputMaybe<HavingBigintFilter>;
};

export type DataCatalogueSnapshotV8HavingDistinctCountInput = {
  baseId?: InputMaybe<HavingBigintFilter>;
  catalogueVersion?: InputMaybe<HavingIntFilter>;
  commitHashId?: InputMaybe<HavingBigintFilter>;
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  fivetranSnapshotId?: InputMaybe<HavingBigintFilter>;
  hightouchSnapshotId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  informationSchemaSnapshotId?: InputMaybe<HavingBigintFilter>;
  lookerRepositorySnapshotId?: InputMaybe<HavingBigintFilter>;
  lookerSnapshotId?: InputMaybe<HavingBigintFilter>;
  tableauSnapshotId?: InputMaybe<HavingBigintFilter>;
  testLogId?: InputMaybe<HavingBigintFilter>;
};

/** Conditions for `DataCatalogueSnapshotV8` aggregates. */
export type DataCatalogueSnapshotV8HavingInput = {
  AND?: InputMaybe<Array<DataCatalogueSnapshotV8HavingInput>>;
  OR?: InputMaybe<Array<DataCatalogueSnapshotV8HavingInput>>;
  average?: InputMaybe<DataCatalogueSnapshotV8HavingAverageInput>;
  distinctCount?: InputMaybe<DataCatalogueSnapshotV8HavingDistinctCountInput>;
  max?: InputMaybe<DataCatalogueSnapshotV8HavingMaxInput>;
  min?: InputMaybe<DataCatalogueSnapshotV8HavingMinInput>;
  stddevPopulation?: InputMaybe<DataCatalogueSnapshotV8HavingStddevPopulationInput>;
  stddevSample?: InputMaybe<DataCatalogueSnapshotV8HavingStddevSampleInput>;
  sum?: InputMaybe<DataCatalogueSnapshotV8HavingSumInput>;
  variancePopulation?: InputMaybe<DataCatalogueSnapshotV8HavingVariancePopulationInput>;
  varianceSample?: InputMaybe<DataCatalogueSnapshotV8HavingVarianceSampleInput>;
};

export type DataCatalogueSnapshotV8HavingMaxInput = {
  baseId?: InputMaybe<HavingBigintFilter>;
  catalogueVersion?: InputMaybe<HavingIntFilter>;
  commitHashId?: InputMaybe<HavingBigintFilter>;
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  fivetranSnapshotId?: InputMaybe<HavingBigintFilter>;
  hightouchSnapshotId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  informationSchemaSnapshotId?: InputMaybe<HavingBigintFilter>;
  lookerRepositorySnapshotId?: InputMaybe<HavingBigintFilter>;
  lookerSnapshotId?: InputMaybe<HavingBigintFilter>;
  tableauSnapshotId?: InputMaybe<HavingBigintFilter>;
  testLogId?: InputMaybe<HavingBigintFilter>;
};

export type DataCatalogueSnapshotV8HavingMinInput = {
  baseId?: InputMaybe<HavingBigintFilter>;
  catalogueVersion?: InputMaybe<HavingIntFilter>;
  commitHashId?: InputMaybe<HavingBigintFilter>;
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  fivetranSnapshotId?: InputMaybe<HavingBigintFilter>;
  hightouchSnapshotId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  informationSchemaSnapshotId?: InputMaybe<HavingBigintFilter>;
  lookerRepositorySnapshotId?: InputMaybe<HavingBigintFilter>;
  lookerSnapshotId?: InputMaybe<HavingBigintFilter>;
  tableauSnapshotId?: InputMaybe<HavingBigintFilter>;
  testLogId?: InputMaybe<HavingBigintFilter>;
};

export type DataCatalogueSnapshotV8HavingStddevPopulationInput = {
  baseId?: InputMaybe<HavingBigintFilter>;
  catalogueVersion?: InputMaybe<HavingIntFilter>;
  commitHashId?: InputMaybe<HavingBigintFilter>;
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  fivetranSnapshotId?: InputMaybe<HavingBigintFilter>;
  hightouchSnapshotId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  informationSchemaSnapshotId?: InputMaybe<HavingBigintFilter>;
  lookerRepositorySnapshotId?: InputMaybe<HavingBigintFilter>;
  lookerSnapshotId?: InputMaybe<HavingBigintFilter>;
  tableauSnapshotId?: InputMaybe<HavingBigintFilter>;
  testLogId?: InputMaybe<HavingBigintFilter>;
};

export type DataCatalogueSnapshotV8HavingStddevSampleInput = {
  baseId?: InputMaybe<HavingBigintFilter>;
  catalogueVersion?: InputMaybe<HavingIntFilter>;
  commitHashId?: InputMaybe<HavingBigintFilter>;
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  fivetranSnapshotId?: InputMaybe<HavingBigintFilter>;
  hightouchSnapshotId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  informationSchemaSnapshotId?: InputMaybe<HavingBigintFilter>;
  lookerRepositorySnapshotId?: InputMaybe<HavingBigintFilter>;
  lookerSnapshotId?: InputMaybe<HavingBigintFilter>;
  tableauSnapshotId?: InputMaybe<HavingBigintFilter>;
  testLogId?: InputMaybe<HavingBigintFilter>;
};

export type DataCatalogueSnapshotV8HavingSumInput = {
  baseId?: InputMaybe<HavingBigintFilter>;
  catalogueVersion?: InputMaybe<HavingIntFilter>;
  commitHashId?: InputMaybe<HavingBigintFilter>;
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  fivetranSnapshotId?: InputMaybe<HavingBigintFilter>;
  hightouchSnapshotId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  informationSchemaSnapshotId?: InputMaybe<HavingBigintFilter>;
  lookerRepositorySnapshotId?: InputMaybe<HavingBigintFilter>;
  lookerSnapshotId?: InputMaybe<HavingBigintFilter>;
  tableauSnapshotId?: InputMaybe<HavingBigintFilter>;
  testLogId?: InputMaybe<HavingBigintFilter>;
};

export type DataCatalogueSnapshotV8HavingVariancePopulationInput = {
  baseId?: InputMaybe<HavingBigintFilter>;
  catalogueVersion?: InputMaybe<HavingIntFilter>;
  commitHashId?: InputMaybe<HavingBigintFilter>;
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  fivetranSnapshotId?: InputMaybe<HavingBigintFilter>;
  hightouchSnapshotId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  informationSchemaSnapshotId?: InputMaybe<HavingBigintFilter>;
  lookerRepositorySnapshotId?: InputMaybe<HavingBigintFilter>;
  lookerSnapshotId?: InputMaybe<HavingBigintFilter>;
  tableauSnapshotId?: InputMaybe<HavingBigintFilter>;
  testLogId?: InputMaybe<HavingBigintFilter>;
};

export type DataCatalogueSnapshotV8HavingVarianceSampleInput = {
  baseId?: InputMaybe<HavingBigintFilter>;
  catalogueVersion?: InputMaybe<HavingIntFilter>;
  commitHashId?: InputMaybe<HavingBigintFilter>;
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  fivetranSnapshotId?: InputMaybe<HavingBigintFilter>;
  hightouchSnapshotId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  informationSchemaSnapshotId?: InputMaybe<HavingBigintFilter>;
  lookerRepositorySnapshotId?: InputMaybe<HavingBigintFilter>;
  lookerSnapshotId?: InputMaybe<HavingBigintFilter>;
  tableauSnapshotId?: InputMaybe<HavingBigintFilter>;
  testLogId?: InputMaybe<HavingBigintFilter>;
};

export type DataCatalogueSnapshotV8Item = Node & {
  __typename?: 'DataCatalogueSnapshotV8Item';
  catalogueSnapshotId: Scalars['BigInt'];
  data: Scalars['String'];
  detailLevel: Scalars['Int'];
  excerptDescription: Scalars['String'];
  excerptName: Scalars['String'];
  excerptTags: Scalars['String'];
  id: Scalars['BigInt'];
  itemId: Scalars['String'];
  itemType: Scalars['Int'];
  lastModifiedTime?: Maybe<Scalars['BigInt']>;
  lineageNodeIdHash: Scalars['BigInt'];
  metaFields: Array<Maybe<Scalars['String']>>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  popularityScore?: Maybe<Scalars['Float']>;
  urlHash: Scalars['BigInt'];
};

export type DataCatalogueSnapshotV8ItemAggregates = {
  __typename?: 'DataCatalogueSnapshotV8ItemAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<DataCatalogueSnapshotV8ItemAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<DataCatalogueSnapshotV8ItemDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<DataCatalogueSnapshotV8ItemMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<DataCatalogueSnapshotV8ItemMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<DataCatalogueSnapshotV8ItemStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<DataCatalogueSnapshotV8ItemStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<DataCatalogueSnapshotV8ItemSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<DataCatalogueSnapshotV8ItemVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<DataCatalogueSnapshotV8ItemVarianceSampleAggregates>;
};

export type DataCatalogueSnapshotV8ItemAverageAggregates = {
  __typename?: 'DataCatalogueSnapshotV8ItemAverageAggregates';
  /** Mean average of catalogueSnapshotId across the matching connection */
  catalogueSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of detailLevel across the matching connection */
  detailLevel?: Maybe<Scalars['BigFloat']>;
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of itemType across the matching connection */
  itemType?: Maybe<Scalars['BigFloat']>;
  /** Mean average of lastModifiedTime across the matching connection */
  lastModifiedTime?: Maybe<Scalars['BigFloat']>;
  /** Mean average of lineageNodeIdHash across the matching connection */
  lineageNodeIdHash?: Maybe<Scalars['BigFloat']>;
  /** Mean average of popularityScore across the matching connection */
  popularityScore?: Maybe<Scalars['Float']>;
  /** Mean average of urlHash across the matching connection */
  urlHash?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `DataCatalogueSnapshotV8Item` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type DataCatalogueSnapshotV8ItemCondition = {
  /** Checks for equality with the object’s `catalogueSnapshotId` field. */
  catalogueSnapshotId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `data` field. */
  data?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `detailLevel` field. */
  detailLevel?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `excerptDescription` field. */
  excerptDescription?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `excerptName` field. */
  excerptName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `excerptTags` field. */
  excerptTags?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `itemId` field. */
  itemId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `itemType` field. */
  itemType?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `lastModifiedTime` field. */
  lastModifiedTime?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `lineageNodeIdHash` field. */
  lineageNodeIdHash?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `metaFields` field. */
  metaFields?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `popularityScore` field. */
  popularityScore?: InputMaybe<Scalars['Float']>;
  /** Checks for equality with the object’s `urlHash` field. */
  urlHash?: InputMaybe<Scalars['BigInt']>;
};

export type DataCatalogueSnapshotV8ItemDistinctCountAggregates = {
  __typename?: 'DataCatalogueSnapshotV8ItemDistinctCountAggregates';
  /** Distinct count of catalogueSnapshotId across the matching connection */
  catalogueSnapshotId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of data across the matching connection */
  data?: Maybe<Scalars['BigInt']>;
  /** Distinct count of detailLevel across the matching connection */
  detailLevel?: Maybe<Scalars['BigInt']>;
  /** Distinct count of excerptDescription across the matching connection */
  excerptDescription?: Maybe<Scalars['BigInt']>;
  /** Distinct count of excerptName across the matching connection */
  excerptName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of excerptTags across the matching connection */
  excerptTags?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of itemId across the matching connection */
  itemId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of itemType across the matching connection */
  itemType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lastModifiedTime across the matching connection */
  lastModifiedTime?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lineageNodeIdHash across the matching connection */
  lineageNodeIdHash?: Maybe<Scalars['BigInt']>;
  /** Distinct count of metaFields across the matching connection */
  metaFields?: Maybe<Scalars['BigInt']>;
  /** Distinct count of popularityScore across the matching connection */
  popularityScore?: Maybe<Scalars['BigInt']>;
  /** Distinct count of urlHash across the matching connection */
  urlHash?: Maybe<Scalars['BigInt']>;
};

/** Grouping methods for `DataCatalogueSnapshotV8Item` for usage during aggregation. */
export enum DataCatalogueSnapshotV8ItemGroupBy {
  CatalogueSnapshotId = 'CATALOGUE_SNAPSHOT_ID',
  Data = 'DATA',
  DetailLevel = 'DETAIL_LEVEL',
  ExcerptDescription = 'EXCERPT_DESCRIPTION',
  ExcerptName = 'EXCERPT_NAME',
  ExcerptTags = 'EXCERPT_TAGS',
  ItemId = 'ITEM_ID',
  ItemType = 'ITEM_TYPE',
  LastModifiedTime = 'LAST_MODIFIED_TIME',
  LineageNodeIdHash = 'LINEAGE_NODE_ID_HASH',
  MetaFields = 'META_FIELDS',
  PopularityScore = 'POPULARITY_SCORE',
  UrlHash = 'URL_HASH'
}

export type DataCatalogueSnapshotV8ItemHavingAverageInput = {
  catalogueSnapshotId?: InputMaybe<HavingBigintFilter>;
  detailLevel?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  itemType?: InputMaybe<HavingIntFilter>;
  lastModifiedTime?: InputMaybe<HavingBigintFilter>;
  lineageNodeIdHash?: InputMaybe<HavingBigintFilter>;
  popularityScore?: InputMaybe<HavingFloatFilter>;
  urlHash?: InputMaybe<HavingBigintFilter>;
};

export type DataCatalogueSnapshotV8ItemHavingDistinctCountInput = {
  catalogueSnapshotId?: InputMaybe<HavingBigintFilter>;
  detailLevel?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  itemType?: InputMaybe<HavingIntFilter>;
  lastModifiedTime?: InputMaybe<HavingBigintFilter>;
  lineageNodeIdHash?: InputMaybe<HavingBigintFilter>;
  popularityScore?: InputMaybe<HavingFloatFilter>;
  urlHash?: InputMaybe<HavingBigintFilter>;
};

/** Conditions for `DataCatalogueSnapshotV8Item` aggregates. */
export type DataCatalogueSnapshotV8ItemHavingInput = {
  AND?: InputMaybe<Array<DataCatalogueSnapshotV8ItemHavingInput>>;
  OR?: InputMaybe<Array<DataCatalogueSnapshotV8ItemHavingInput>>;
  average?: InputMaybe<DataCatalogueSnapshotV8ItemHavingAverageInput>;
  distinctCount?: InputMaybe<DataCatalogueSnapshotV8ItemHavingDistinctCountInput>;
  max?: InputMaybe<DataCatalogueSnapshotV8ItemHavingMaxInput>;
  min?: InputMaybe<DataCatalogueSnapshotV8ItemHavingMinInput>;
  stddevPopulation?: InputMaybe<DataCatalogueSnapshotV8ItemHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<DataCatalogueSnapshotV8ItemHavingStddevSampleInput>;
  sum?: InputMaybe<DataCatalogueSnapshotV8ItemHavingSumInput>;
  variancePopulation?: InputMaybe<DataCatalogueSnapshotV8ItemHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<DataCatalogueSnapshotV8ItemHavingVarianceSampleInput>;
};

export type DataCatalogueSnapshotV8ItemHavingMaxInput = {
  catalogueSnapshotId?: InputMaybe<HavingBigintFilter>;
  detailLevel?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  itemType?: InputMaybe<HavingIntFilter>;
  lastModifiedTime?: InputMaybe<HavingBigintFilter>;
  lineageNodeIdHash?: InputMaybe<HavingBigintFilter>;
  popularityScore?: InputMaybe<HavingFloatFilter>;
  urlHash?: InputMaybe<HavingBigintFilter>;
};

export type DataCatalogueSnapshotV8ItemHavingMinInput = {
  catalogueSnapshotId?: InputMaybe<HavingBigintFilter>;
  detailLevel?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  itemType?: InputMaybe<HavingIntFilter>;
  lastModifiedTime?: InputMaybe<HavingBigintFilter>;
  lineageNodeIdHash?: InputMaybe<HavingBigintFilter>;
  popularityScore?: InputMaybe<HavingFloatFilter>;
  urlHash?: InputMaybe<HavingBigintFilter>;
};

export type DataCatalogueSnapshotV8ItemHavingStddevPopulationInput = {
  catalogueSnapshotId?: InputMaybe<HavingBigintFilter>;
  detailLevel?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  itemType?: InputMaybe<HavingIntFilter>;
  lastModifiedTime?: InputMaybe<HavingBigintFilter>;
  lineageNodeIdHash?: InputMaybe<HavingBigintFilter>;
  popularityScore?: InputMaybe<HavingFloatFilter>;
  urlHash?: InputMaybe<HavingBigintFilter>;
};

export type DataCatalogueSnapshotV8ItemHavingStddevSampleInput = {
  catalogueSnapshotId?: InputMaybe<HavingBigintFilter>;
  detailLevel?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  itemType?: InputMaybe<HavingIntFilter>;
  lastModifiedTime?: InputMaybe<HavingBigintFilter>;
  lineageNodeIdHash?: InputMaybe<HavingBigintFilter>;
  popularityScore?: InputMaybe<HavingFloatFilter>;
  urlHash?: InputMaybe<HavingBigintFilter>;
};

export type DataCatalogueSnapshotV8ItemHavingSumInput = {
  catalogueSnapshotId?: InputMaybe<HavingBigintFilter>;
  detailLevel?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  itemType?: InputMaybe<HavingIntFilter>;
  lastModifiedTime?: InputMaybe<HavingBigintFilter>;
  lineageNodeIdHash?: InputMaybe<HavingBigintFilter>;
  popularityScore?: InputMaybe<HavingFloatFilter>;
  urlHash?: InputMaybe<HavingBigintFilter>;
};

export type DataCatalogueSnapshotV8ItemHavingVariancePopulationInput = {
  catalogueSnapshotId?: InputMaybe<HavingBigintFilter>;
  detailLevel?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  itemType?: InputMaybe<HavingIntFilter>;
  lastModifiedTime?: InputMaybe<HavingBigintFilter>;
  lineageNodeIdHash?: InputMaybe<HavingBigintFilter>;
  popularityScore?: InputMaybe<HavingFloatFilter>;
  urlHash?: InputMaybe<HavingBigintFilter>;
};

export type DataCatalogueSnapshotV8ItemHavingVarianceSampleInput = {
  catalogueSnapshotId?: InputMaybe<HavingBigintFilter>;
  detailLevel?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  itemType?: InputMaybe<HavingIntFilter>;
  lastModifiedTime?: InputMaybe<HavingBigintFilter>;
  lineageNodeIdHash?: InputMaybe<HavingBigintFilter>;
  popularityScore?: InputMaybe<HavingFloatFilter>;
  urlHash?: InputMaybe<HavingBigintFilter>;
};

export type DataCatalogueSnapshotV8ItemMaxAggregates = {
  __typename?: 'DataCatalogueSnapshotV8ItemMaxAggregates';
  /** Maximum of catalogueSnapshotId across the matching connection */
  catalogueSnapshotId?: Maybe<Scalars['BigInt']>;
  /** Maximum of detailLevel across the matching connection */
  detailLevel?: Maybe<Scalars['Int']>;
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Maximum of itemType across the matching connection */
  itemType?: Maybe<Scalars['Int']>;
  /** Maximum of lastModifiedTime across the matching connection */
  lastModifiedTime?: Maybe<Scalars['BigInt']>;
  /** Maximum of lineageNodeIdHash across the matching connection */
  lineageNodeIdHash?: Maybe<Scalars['BigInt']>;
  /** Maximum of popularityScore across the matching connection */
  popularityScore?: Maybe<Scalars['Float']>;
  /** Maximum of urlHash across the matching connection */
  urlHash?: Maybe<Scalars['BigInt']>;
};

export type DataCatalogueSnapshotV8ItemMinAggregates = {
  __typename?: 'DataCatalogueSnapshotV8ItemMinAggregates';
  /** Minimum of catalogueSnapshotId across the matching connection */
  catalogueSnapshotId?: Maybe<Scalars['BigInt']>;
  /** Minimum of detailLevel across the matching connection */
  detailLevel?: Maybe<Scalars['Int']>;
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Minimum of itemType across the matching connection */
  itemType?: Maybe<Scalars['Int']>;
  /** Minimum of lastModifiedTime across the matching connection */
  lastModifiedTime?: Maybe<Scalars['BigInt']>;
  /** Minimum of lineageNodeIdHash across the matching connection */
  lineageNodeIdHash?: Maybe<Scalars['BigInt']>;
  /** Minimum of popularityScore across the matching connection */
  popularityScore?: Maybe<Scalars['Float']>;
  /** Minimum of urlHash across the matching connection */
  urlHash?: Maybe<Scalars['BigInt']>;
};

export type DataCatalogueSnapshotV8ItemStddevPopulationAggregates = {
  __typename?: 'DataCatalogueSnapshotV8ItemStddevPopulationAggregates';
  /** Population standard deviation of catalogueSnapshotId across the matching connection */
  catalogueSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of detailLevel across the matching connection */
  detailLevel?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of itemType across the matching connection */
  itemType?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of lastModifiedTime across the matching connection */
  lastModifiedTime?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of lineageNodeIdHash across the matching connection */
  lineageNodeIdHash?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of popularityScore across the matching connection */
  popularityScore?: Maybe<Scalars['Float']>;
  /** Population standard deviation of urlHash across the matching connection */
  urlHash?: Maybe<Scalars['BigFloat']>;
};

export type DataCatalogueSnapshotV8ItemStddevSampleAggregates = {
  __typename?: 'DataCatalogueSnapshotV8ItemStddevSampleAggregates';
  /** Sample standard deviation of catalogueSnapshotId across the matching connection */
  catalogueSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of detailLevel across the matching connection */
  detailLevel?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of itemType across the matching connection */
  itemType?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of lastModifiedTime across the matching connection */
  lastModifiedTime?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of lineageNodeIdHash across the matching connection */
  lineageNodeIdHash?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of popularityScore across the matching connection */
  popularityScore?: Maybe<Scalars['Float']>;
  /** Sample standard deviation of urlHash across the matching connection */
  urlHash?: Maybe<Scalars['BigFloat']>;
};

export type DataCatalogueSnapshotV8ItemSumAggregates = {
  __typename?: 'DataCatalogueSnapshotV8ItemSumAggregates';
  /** Sum of catalogueSnapshotId across the matching connection */
  catalogueSnapshotId: Scalars['BigFloat'];
  /** Sum of detailLevel across the matching connection */
  detailLevel: Scalars['BigInt'];
  /** Sum of id across the matching connection */
  id: Scalars['BigFloat'];
  /** Sum of itemType across the matching connection */
  itemType: Scalars['BigInt'];
  /** Sum of lastModifiedTime across the matching connection */
  lastModifiedTime: Scalars['BigFloat'];
  /** Sum of lineageNodeIdHash across the matching connection */
  lineageNodeIdHash: Scalars['BigFloat'];
  /** Sum of popularityScore across the matching connection */
  popularityScore: Scalars['Float'];
  /** Sum of urlHash across the matching connection */
  urlHash: Scalars['BigFloat'];
};

export type DataCatalogueSnapshotV8ItemVariancePopulationAggregates = {
  __typename?: 'DataCatalogueSnapshotV8ItemVariancePopulationAggregates';
  /** Population variance of catalogueSnapshotId across the matching connection */
  catalogueSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of detailLevel across the matching connection */
  detailLevel?: Maybe<Scalars['BigFloat']>;
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of itemType across the matching connection */
  itemType?: Maybe<Scalars['BigFloat']>;
  /** Population variance of lastModifiedTime across the matching connection */
  lastModifiedTime?: Maybe<Scalars['BigFloat']>;
  /** Population variance of lineageNodeIdHash across the matching connection */
  lineageNodeIdHash?: Maybe<Scalars['BigFloat']>;
  /** Population variance of popularityScore across the matching connection */
  popularityScore?: Maybe<Scalars['Float']>;
  /** Population variance of urlHash across the matching connection */
  urlHash?: Maybe<Scalars['BigFloat']>;
};

export type DataCatalogueSnapshotV8ItemVarianceSampleAggregates = {
  __typename?: 'DataCatalogueSnapshotV8ItemVarianceSampleAggregates';
  /** Sample variance of catalogueSnapshotId across the matching connection */
  catalogueSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of detailLevel across the matching connection */
  detailLevel?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of itemType across the matching connection */
  itemType?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of lastModifiedTime across the matching connection */
  lastModifiedTime?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of lineageNodeIdHash across the matching connection */
  lineageNodeIdHash?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of popularityScore across the matching connection */
  popularityScore?: Maybe<Scalars['Float']>;
  /** Sample variance of urlHash across the matching connection */
  urlHash?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `DataCatalogueSnapshotV8Item` values. */
export type DataCatalogueSnapshotV8ItemsConnection = {
  __typename?: 'DataCatalogueSnapshotV8ItemsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<DataCatalogueSnapshotV8ItemAggregates>;
  /** A list of edges which contains the `DataCatalogueSnapshotV8Item` and cursor to aid in pagination. */
  edges: Array<DataCatalogueSnapshotV8ItemsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<DataCatalogueSnapshotV8ItemAggregates>>;
  /** A list of `DataCatalogueSnapshotV8Item` objects. */
  nodes: Array<DataCatalogueSnapshotV8Item>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DataCatalogueSnapshotV8Item` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `DataCatalogueSnapshotV8Item` values. */
export type DataCatalogueSnapshotV8ItemsConnectionGroupedAggregatesArgs = {
  groupBy: Array<DataCatalogueSnapshotV8ItemGroupBy>;
  having?: InputMaybe<DataCatalogueSnapshotV8ItemHavingInput>;
};

/** A `DataCatalogueSnapshotV8Item` edge in the connection. */
export type DataCatalogueSnapshotV8ItemsEdge = {
  __typename?: 'DataCatalogueSnapshotV8ItemsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `DataCatalogueSnapshotV8Item` at the end of the edge. */
  node: DataCatalogueSnapshotV8Item;
};

/** Methods to use when ordering `DataCatalogueSnapshotV8Item`. */
export enum DataCatalogueSnapshotV8ItemsOrderBy {
  CatalogueSnapshotIdAsc = 'CATALOGUE_SNAPSHOT_ID_ASC',
  CatalogueSnapshotIdDesc = 'CATALOGUE_SNAPSHOT_ID_DESC',
  DataAsc = 'DATA_ASC',
  DataDesc = 'DATA_DESC',
  DetailLevelAsc = 'DETAIL_LEVEL_ASC',
  DetailLevelDesc = 'DETAIL_LEVEL_DESC',
  ExcerptDescriptionAsc = 'EXCERPT_DESCRIPTION_ASC',
  ExcerptDescriptionDesc = 'EXCERPT_DESCRIPTION_DESC',
  ExcerptNameAsc = 'EXCERPT_NAME_ASC',
  ExcerptNameDesc = 'EXCERPT_NAME_DESC',
  ExcerptTagsAsc = 'EXCERPT_TAGS_ASC',
  ExcerptTagsDesc = 'EXCERPT_TAGS_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ItemIdAsc = 'ITEM_ID_ASC',
  ItemIdDesc = 'ITEM_ID_DESC',
  ItemTypeAsc = 'ITEM_TYPE_ASC',
  ItemTypeDesc = 'ITEM_TYPE_DESC',
  LastModifiedTimeAsc = 'LAST_MODIFIED_TIME_ASC',
  LastModifiedTimeDesc = 'LAST_MODIFIED_TIME_DESC',
  LineageNodeIdHashAsc = 'LINEAGE_NODE_ID_HASH_ASC',
  LineageNodeIdHashDesc = 'LINEAGE_NODE_ID_HASH_DESC',
  MetaFieldsAsc = 'META_FIELDS_ASC',
  MetaFieldsDesc = 'META_FIELDS_DESC',
  Natural = 'NATURAL',
  PopularityScoreAsc = 'POPULARITY_SCORE_ASC',
  PopularityScoreDesc = 'POPULARITY_SCORE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UrlHashAsc = 'URL_HASH_ASC',
  UrlHashDesc = 'URL_HASH_DESC'
}

export type DataCatalogueSnapshotV8MaxAggregates = {
  __typename?: 'DataCatalogueSnapshotV8MaxAggregates';
  /** Maximum of baseId across the matching connection */
  baseId?: Maybe<Scalars['BigInt']>;
  /** Maximum of catalogueVersion across the matching connection */
  catalogueVersion?: Maybe<Scalars['Int']>;
  /** Maximum of commitHashId across the matching connection */
  commitHashId?: Maybe<Scalars['BigInt']>;
  /** Maximum of fivetranSnapshotId across the matching connection */
  fivetranSnapshotId?: Maybe<Scalars['BigInt']>;
  /** Maximum of hightouchSnapshotId across the matching connection */
  hightouchSnapshotId?: Maybe<Scalars['BigInt']>;
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Maximum of informationSchemaSnapshotId across the matching connection */
  informationSchemaSnapshotId?: Maybe<Scalars['BigInt']>;
  /** Maximum of lookerRepositorySnapshotId across the matching connection */
  lookerRepositorySnapshotId?: Maybe<Scalars['BigInt']>;
  /** Maximum of lookerSnapshotId across the matching connection */
  lookerSnapshotId?: Maybe<Scalars['BigInt']>;
  /** Maximum of tableauSnapshotId across the matching connection */
  tableauSnapshotId?: Maybe<Scalars['BigInt']>;
  /** Maximum of testLogId across the matching connection */
  testLogId?: Maybe<Scalars['BigInt']>;
};

export type DataCatalogueSnapshotV8MetaField = Node & {
  __typename?: 'DataCatalogueSnapshotV8MetaField';
  catalogueSnapshotId: Scalars['BigInt'];
  detailLevel: Scalars['Int'];
  id: Scalars['BigInt'];
  key: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  value: Scalars['String'];
};

export type DataCatalogueSnapshotV8MetaFieldAggregates = {
  __typename?: 'DataCatalogueSnapshotV8MetaFieldAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<DataCatalogueSnapshotV8MetaFieldAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<DataCatalogueSnapshotV8MetaFieldDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<DataCatalogueSnapshotV8MetaFieldMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<DataCatalogueSnapshotV8MetaFieldMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<DataCatalogueSnapshotV8MetaFieldStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<DataCatalogueSnapshotV8MetaFieldStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<DataCatalogueSnapshotV8MetaFieldSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<DataCatalogueSnapshotV8MetaFieldVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<DataCatalogueSnapshotV8MetaFieldVarianceSampleAggregates>;
};

export type DataCatalogueSnapshotV8MetaFieldAverageAggregates = {
  __typename?: 'DataCatalogueSnapshotV8MetaFieldAverageAggregates';
  /** Mean average of catalogueSnapshotId across the matching connection */
  catalogueSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of detailLevel across the matching connection */
  detailLevel?: Maybe<Scalars['BigFloat']>;
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `DataCatalogueSnapshotV8MetaField` object types.
 * All fields are tested for equality and combined with a logical ‘and.’
 */
export type DataCatalogueSnapshotV8MetaFieldCondition = {
  /** Checks for equality with the object’s `catalogueSnapshotId` field. */
  catalogueSnapshotId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `detailLevel` field. */
  detailLevel?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `key` field. */
  key?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `value` field. */
  value?: InputMaybe<Scalars['String']>;
};

export type DataCatalogueSnapshotV8MetaFieldDistinctCountAggregates = {
  __typename?: 'DataCatalogueSnapshotV8MetaFieldDistinctCountAggregates';
  /** Distinct count of catalogueSnapshotId across the matching connection */
  catalogueSnapshotId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of detailLevel across the matching connection */
  detailLevel?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of key across the matching connection */
  key?: Maybe<Scalars['BigInt']>;
  /** Distinct count of value across the matching connection */
  value?: Maybe<Scalars['BigInt']>;
};

export type DataCatalogueSnapshotV8MetaFieldMaxAggregates = {
  __typename?: 'DataCatalogueSnapshotV8MetaFieldMaxAggregates';
  /** Maximum of catalogueSnapshotId across the matching connection */
  catalogueSnapshotId?: Maybe<Scalars['BigInt']>;
  /** Maximum of detailLevel across the matching connection */
  detailLevel?: Maybe<Scalars['Int']>;
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
};

export type DataCatalogueSnapshotV8MetaFieldMinAggregates = {
  __typename?: 'DataCatalogueSnapshotV8MetaFieldMinAggregates';
  /** Minimum of catalogueSnapshotId across the matching connection */
  catalogueSnapshotId?: Maybe<Scalars['BigInt']>;
  /** Minimum of detailLevel across the matching connection */
  detailLevel?: Maybe<Scalars['Int']>;
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
};

export type DataCatalogueSnapshotV8MetaFieldStddevPopulationAggregates = {
  __typename?: 'DataCatalogueSnapshotV8MetaFieldStddevPopulationAggregates';
  /** Population standard deviation of catalogueSnapshotId across the matching connection */
  catalogueSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of detailLevel across the matching connection */
  detailLevel?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type DataCatalogueSnapshotV8MetaFieldStddevSampleAggregates = {
  __typename?: 'DataCatalogueSnapshotV8MetaFieldStddevSampleAggregates';
  /** Sample standard deviation of catalogueSnapshotId across the matching connection */
  catalogueSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of detailLevel across the matching connection */
  detailLevel?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type DataCatalogueSnapshotV8MetaFieldSumAggregates = {
  __typename?: 'DataCatalogueSnapshotV8MetaFieldSumAggregates';
  /** Sum of catalogueSnapshotId across the matching connection */
  catalogueSnapshotId: Scalars['BigFloat'];
  /** Sum of detailLevel across the matching connection */
  detailLevel: Scalars['BigInt'];
  /** Sum of id across the matching connection */
  id: Scalars['BigFloat'];
};

export type DataCatalogueSnapshotV8MetaFieldVariancePopulationAggregates = {
  __typename?: 'DataCatalogueSnapshotV8MetaFieldVariancePopulationAggregates';
  /** Population variance of catalogueSnapshotId across the matching connection */
  catalogueSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of detailLevel across the matching connection */
  detailLevel?: Maybe<Scalars['BigFloat']>;
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type DataCatalogueSnapshotV8MetaFieldVarianceSampleAggregates = {
  __typename?: 'DataCatalogueSnapshotV8MetaFieldVarianceSampleAggregates';
  /** Sample variance of catalogueSnapshotId across the matching connection */
  catalogueSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of detailLevel across the matching connection */
  detailLevel?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `DataCatalogueSnapshotV8MetaField` values. */
export type DataCatalogueSnapshotV8MetaFieldsConnection = {
  __typename?: 'DataCatalogueSnapshotV8MetaFieldsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<DataCatalogueSnapshotV8MetaFieldAggregates>;
  /** A list of edges which contains the `DataCatalogueSnapshotV8MetaField` and cursor to aid in pagination. */
  edges: Array<DataCatalogueSnapshotV8MetaFieldsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<DataCatalogueSnapshotV8MetaFieldAggregates>>;
  /** A list of `DataCatalogueSnapshotV8MetaField` objects. */
  nodes: Array<DataCatalogueSnapshotV8MetaField>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DataCatalogueSnapshotV8MetaField` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `DataCatalogueSnapshotV8MetaField` values. */
export type DataCatalogueSnapshotV8MetaFieldsConnectionGroupedAggregatesArgs = {
  groupBy: Array<DataCatalogueSnapshotV8MetaFieldsGroupBy>;
  having?: InputMaybe<DataCatalogueSnapshotV8MetaFieldsHavingInput>;
};

/** A `DataCatalogueSnapshotV8MetaField` edge in the connection. */
export type DataCatalogueSnapshotV8MetaFieldsEdge = {
  __typename?: 'DataCatalogueSnapshotV8MetaFieldsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `DataCatalogueSnapshotV8MetaField` at the end of the edge. */
  node: DataCatalogueSnapshotV8MetaField;
};

/** Grouping methods for `DataCatalogueSnapshotV8MetaField` for usage during aggregation. */
export enum DataCatalogueSnapshotV8MetaFieldsGroupBy {
  CatalogueSnapshotId = 'CATALOGUE_SNAPSHOT_ID',
  DetailLevel = 'DETAIL_LEVEL',
  Key = 'KEY',
  Value = 'VALUE'
}

export type DataCatalogueSnapshotV8MetaFieldsHavingAverageInput = {
  catalogueSnapshotId?: InputMaybe<HavingBigintFilter>;
  detailLevel?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type DataCatalogueSnapshotV8MetaFieldsHavingDistinctCountInput = {
  catalogueSnapshotId?: InputMaybe<HavingBigintFilter>;
  detailLevel?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

/** Conditions for `DataCatalogueSnapshotV8MetaField` aggregates. */
export type DataCatalogueSnapshotV8MetaFieldsHavingInput = {
  AND?: InputMaybe<Array<DataCatalogueSnapshotV8MetaFieldsHavingInput>>;
  OR?: InputMaybe<Array<DataCatalogueSnapshotV8MetaFieldsHavingInput>>;
  average?: InputMaybe<DataCatalogueSnapshotV8MetaFieldsHavingAverageInput>;
  distinctCount?: InputMaybe<DataCatalogueSnapshotV8MetaFieldsHavingDistinctCountInput>;
  max?: InputMaybe<DataCatalogueSnapshotV8MetaFieldsHavingMaxInput>;
  min?: InputMaybe<DataCatalogueSnapshotV8MetaFieldsHavingMinInput>;
  stddevPopulation?: InputMaybe<DataCatalogueSnapshotV8MetaFieldsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<DataCatalogueSnapshotV8MetaFieldsHavingStddevSampleInput>;
  sum?: InputMaybe<DataCatalogueSnapshotV8MetaFieldsHavingSumInput>;
  variancePopulation?: InputMaybe<DataCatalogueSnapshotV8MetaFieldsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<DataCatalogueSnapshotV8MetaFieldsHavingVarianceSampleInput>;
};

export type DataCatalogueSnapshotV8MetaFieldsHavingMaxInput = {
  catalogueSnapshotId?: InputMaybe<HavingBigintFilter>;
  detailLevel?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type DataCatalogueSnapshotV8MetaFieldsHavingMinInput = {
  catalogueSnapshotId?: InputMaybe<HavingBigintFilter>;
  detailLevel?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type DataCatalogueSnapshotV8MetaFieldsHavingStddevPopulationInput = {
  catalogueSnapshotId?: InputMaybe<HavingBigintFilter>;
  detailLevel?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type DataCatalogueSnapshotV8MetaFieldsHavingStddevSampleInput = {
  catalogueSnapshotId?: InputMaybe<HavingBigintFilter>;
  detailLevel?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type DataCatalogueSnapshotV8MetaFieldsHavingSumInput = {
  catalogueSnapshotId?: InputMaybe<HavingBigintFilter>;
  detailLevel?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type DataCatalogueSnapshotV8MetaFieldsHavingVariancePopulationInput = {
  catalogueSnapshotId?: InputMaybe<HavingBigintFilter>;
  detailLevel?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type DataCatalogueSnapshotV8MetaFieldsHavingVarianceSampleInput = {
  catalogueSnapshotId?: InputMaybe<HavingBigintFilter>;
  detailLevel?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

/** Methods to use when ordering `DataCatalogueSnapshotV8MetaField`. */
export enum DataCatalogueSnapshotV8MetaFieldsOrderBy {
  CatalogueSnapshotIdAsc = 'CATALOGUE_SNAPSHOT_ID_ASC',
  CatalogueSnapshotIdDesc = 'CATALOGUE_SNAPSHOT_ID_DESC',
  DetailLevelAsc = 'DETAIL_LEVEL_ASC',
  DetailLevelDesc = 'DETAIL_LEVEL_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  KeyAsc = 'KEY_ASC',
  KeyDesc = 'KEY_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ValueAsc = 'VALUE_ASC',
  ValueDesc = 'VALUE_DESC'
}

export type DataCatalogueSnapshotV8MinAggregates = {
  __typename?: 'DataCatalogueSnapshotV8MinAggregates';
  /** Minimum of baseId across the matching connection */
  baseId?: Maybe<Scalars['BigInt']>;
  /** Minimum of catalogueVersion across the matching connection */
  catalogueVersion?: Maybe<Scalars['Int']>;
  /** Minimum of commitHashId across the matching connection */
  commitHashId?: Maybe<Scalars['BigInt']>;
  /** Minimum of fivetranSnapshotId across the matching connection */
  fivetranSnapshotId?: Maybe<Scalars['BigInt']>;
  /** Minimum of hightouchSnapshotId across the matching connection */
  hightouchSnapshotId?: Maybe<Scalars['BigInt']>;
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Minimum of informationSchemaSnapshotId across the matching connection */
  informationSchemaSnapshotId?: Maybe<Scalars['BigInt']>;
  /** Minimum of lookerRepositorySnapshotId across the matching connection */
  lookerRepositorySnapshotId?: Maybe<Scalars['BigInt']>;
  /** Minimum of lookerSnapshotId across the matching connection */
  lookerSnapshotId?: Maybe<Scalars['BigInt']>;
  /** Minimum of tableauSnapshotId across the matching connection */
  tableauSnapshotId?: Maybe<Scalars['BigInt']>;
  /** Minimum of testLogId across the matching connection */
  testLogId?: Maybe<Scalars['BigInt']>;
};

export type DataCatalogueSnapshotV8StddevPopulationAggregates = {
  __typename?: 'DataCatalogueSnapshotV8StddevPopulationAggregates';
  /** Population standard deviation of baseId across the matching connection */
  baseId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of catalogueVersion across the matching connection */
  catalogueVersion?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of commitHashId across the matching connection */
  commitHashId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of fivetranSnapshotId across the matching connection */
  fivetranSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of hightouchSnapshotId across the matching connection */
  hightouchSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of informationSchemaSnapshotId across the matching connection */
  informationSchemaSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of lookerRepositorySnapshotId across the matching connection */
  lookerRepositorySnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of lookerSnapshotId across the matching connection */
  lookerSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of tableauSnapshotId across the matching connection */
  tableauSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of testLogId across the matching connection */
  testLogId?: Maybe<Scalars['BigFloat']>;
};

export type DataCatalogueSnapshotV8StddevSampleAggregates = {
  __typename?: 'DataCatalogueSnapshotV8StddevSampleAggregates';
  /** Sample standard deviation of baseId across the matching connection */
  baseId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of catalogueVersion across the matching connection */
  catalogueVersion?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of commitHashId across the matching connection */
  commitHashId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of fivetranSnapshotId across the matching connection */
  fivetranSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of hightouchSnapshotId across the matching connection */
  hightouchSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of informationSchemaSnapshotId across the matching connection */
  informationSchemaSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of lookerRepositorySnapshotId across the matching connection */
  lookerRepositorySnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of lookerSnapshotId across the matching connection */
  lookerSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of tableauSnapshotId across the matching connection */
  tableauSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of testLogId across the matching connection */
  testLogId?: Maybe<Scalars['BigFloat']>;
};

export type DataCatalogueSnapshotV8SumAggregates = {
  __typename?: 'DataCatalogueSnapshotV8SumAggregates';
  /** Sum of baseId across the matching connection */
  baseId: Scalars['BigFloat'];
  /** Sum of catalogueVersion across the matching connection */
  catalogueVersion: Scalars['BigInt'];
  /** Sum of commitHashId across the matching connection */
  commitHashId: Scalars['BigFloat'];
  /** Sum of fivetranSnapshotId across the matching connection */
  fivetranSnapshotId: Scalars['BigFloat'];
  /** Sum of hightouchSnapshotId across the matching connection */
  hightouchSnapshotId: Scalars['BigFloat'];
  /** Sum of id across the matching connection */
  id: Scalars['BigFloat'];
  /** Sum of informationSchemaSnapshotId across the matching connection */
  informationSchemaSnapshotId: Scalars['BigFloat'];
  /** Sum of lookerRepositorySnapshotId across the matching connection */
  lookerRepositorySnapshotId: Scalars['BigFloat'];
  /** Sum of lookerSnapshotId across the matching connection */
  lookerSnapshotId: Scalars['BigFloat'];
  /** Sum of tableauSnapshotId across the matching connection */
  tableauSnapshotId: Scalars['BigFloat'];
  /** Sum of testLogId across the matching connection */
  testLogId: Scalars['BigFloat'];
};

export type DataCatalogueSnapshotV8Tree = Node & {
  __typename?: 'DataCatalogueSnapshotV8Tree';
  catalogueItemId?: Maybe<Scalars['String']>;
  catalogueSnapshotId: Scalars['BigInt'];
  hasChildren: Scalars['Boolean'];
  icon?: Maybe<Scalars['String']>;
  id: Scalars['BigInt'];
  itemType: Scalars['String'];
  logoBase64?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  parentTreeId: Scalars['String'];
  treeId: Scalars['String'];
};

export type DataCatalogueSnapshotV8TreeAggregates = {
  __typename?: 'DataCatalogueSnapshotV8TreeAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<DataCatalogueSnapshotV8TreeAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<DataCatalogueSnapshotV8TreeDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<DataCatalogueSnapshotV8TreeMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<DataCatalogueSnapshotV8TreeMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<DataCatalogueSnapshotV8TreeStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<DataCatalogueSnapshotV8TreeStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<DataCatalogueSnapshotV8TreeSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<DataCatalogueSnapshotV8TreeVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<DataCatalogueSnapshotV8TreeVarianceSampleAggregates>;
};

export type DataCatalogueSnapshotV8TreeAverageAggregates = {
  __typename?: 'DataCatalogueSnapshotV8TreeAverageAggregates';
  /** Mean average of catalogueSnapshotId across the matching connection */
  catalogueSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `DataCatalogueSnapshotV8Tree` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type DataCatalogueSnapshotV8TreeCondition = {
  /** Checks for equality with the object’s `catalogueItemId` field. */
  catalogueItemId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `catalogueSnapshotId` field. */
  catalogueSnapshotId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `hasChildren` field. */
  hasChildren?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `icon` field. */
  icon?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `itemType` field. */
  itemType?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `logoBase64` field. */
  logoBase64?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `parentTreeId` field. */
  parentTreeId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `treeId` field. */
  treeId?: InputMaybe<Scalars['String']>;
};

export type DataCatalogueSnapshotV8TreeDistinctCountAggregates = {
  __typename?: 'DataCatalogueSnapshotV8TreeDistinctCountAggregates';
  /** Distinct count of catalogueItemId across the matching connection */
  catalogueItemId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of catalogueSnapshotId across the matching connection */
  catalogueSnapshotId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of hasChildren across the matching connection */
  hasChildren?: Maybe<Scalars['BigInt']>;
  /** Distinct count of icon across the matching connection */
  icon?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of itemType across the matching connection */
  itemType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of logoBase64 across the matching connection */
  logoBase64?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of parentTreeId across the matching connection */
  parentTreeId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of treeId across the matching connection */
  treeId?: Maybe<Scalars['BigInt']>;
};

/** Grouping methods for `DataCatalogueSnapshotV8Tree` for usage during aggregation. */
export enum DataCatalogueSnapshotV8TreeGroupBy {
  CatalogueItemId = 'CATALOGUE_ITEM_ID',
  CatalogueSnapshotId = 'CATALOGUE_SNAPSHOT_ID',
  HasChildren = 'HAS_CHILDREN',
  Icon = 'ICON',
  ItemType = 'ITEM_TYPE',
  LogoBase64 = 'LOGO_BASE64',
  Name = 'NAME',
  ParentTreeId = 'PARENT_TREE_ID',
  TreeId = 'TREE_ID'
}

export type DataCatalogueSnapshotV8TreeHavingAverageInput = {
  catalogueSnapshotId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type DataCatalogueSnapshotV8TreeHavingDistinctCountInput = {
  catalogueSnapshotId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

/** Conditions for `DataCatalogueSnapshotV8Tree` aggregates. */
export type DataCatalogueSnapshotV8TreeHavingInput = {
  AND?: InputMaybe<Array<DataCatalogueSnapshotV8TreeHavingInput>>;
  OR?: InputMaybe<Array<DataCatalogueSnapshotV8TreeHavingInput>>;
  average?: InputMaybe<DataCatalogueSnapshotV8TreeHavingAverageInput>;
  distinctCount?: InputMaybe<DataCatalogueSnapshotV8TreeHavingDistinctCountInput>;
  max?: InputMaybe<DataCatalogueSnapshotV8TreeHavingMaxInput>;
  min?: InputMaybe<DataCatalogueSnapshotV8TreeHavingMinInput>;
  stddevPopulation?: InputMaybe<DataCatalogueSnapshotV8TreeHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<DataCatalogueSnapshotV8TreeHavingStddevSampleInput>;
  sum?: InputMaybe<DataCatalogueSnapshotV8TreeHavingSumInput>;
  variancePopulation?: InputMaybe<DataCatalogueSnapshotV8TreeHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<DataCatalogueSnapshotV8TreeHavingVarianceSampleInput>;
};

export type DataCatalogueSnapshotV8TreeHavingMaxInput = {
  catalogueSnapshotId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type DataCatalogueSnapshotV8TreeHavingMinInput = {
  catalogueSnapshotId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type DataCatalogueSnapshotV8TreeHavingStddevPopulationInput = {
  catalogueSnapshotId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type DataCatalogueSnapshotV8TreeHavingStddevSampleInput = {
  catalogueSnapshotId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type DataCatalogueSnapshotV8TreeHavingSumInput = {
  catalogueSnapshotId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type DataCatalogueSnapshotV8TreeHavingVariancePopulationInput = {
  catalogueSnapshotId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type DataCatalogueSnapshotV8TreeHavingVarianceSampleInput = {
  catalogueSnapshotId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type DataCatalogueSnapshotV8TreeMaxAggregates = {
  __typename?: 'DataCatalogueSnapshotV8TreeMaxAggregates';
  /** Maximum of catalogueSnapshotId across the matching connection */
  catalogueSnapshotId?: Maybe<Scalars['BigInt']>;
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
};

export type DataCatalogueSnapshotV8TreeMinAggregates = {
  __typename?: 'DataCatalogueSnapshotV8TreeMinAggregates';
  /** Minimum of catalogueSnapshotId across the matching connection */
  catalogueSnapshotId?: Maybe<Scalars['BigInt']>;
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
};

export type DataCatalogueSnapshotV8TreeStddevPopulationAggregates = {
  __typename?: 'DataCatalogueSnapshotV8TreeStddevPopulationAggregates';
  /** Population standard deviation of catalogueSnapshotId across the matching connection */
  catalogueSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type DataCatalogueSnapshotV8TreeStddevSampleAggregates = {
  __typename?: 'DataCatalogueSnapshotV8TreeStddevSampleAggregates';
  /** Sample standard deviation of catalogueSnapshotId across the matching connection */
  catalogueSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type DataCatalogueSnapshotV8TreeSumAggregates = {
  __typename?: 'DataCatalogueSnapshotV8TreeSumAggregates';
  /** Sum of catalogueSnapshotId across the matching connection */
  catalogueSnapshotId: Scalars['BigFloat'];
  /** Sum of id across the matching connection */
  id: Scalars['BigFloat'];
};

export type DataCatalogueSnapshotV8TreeVariancePopulationAggregates = {
  __typename?: 'DataCatalogueSnapshotV8TreeVariancePopulationAggregates';
  /** Population variance of catalogueSnapshotId across the matching connection */
  catalogueSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type DataCatalogueSnapshotV8TreeVarianceSampleAggregates = {
  __typename?: 'DataCatalogueSnapshotV8TreeVarianceSampleAggregates';
  /** Sample variance of catalogueSnapshotId across the matching connection */
  catalogueSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `DataCatalogueSnapshotV8Tree` values. */
export type DataCatalogueSnapshotV8TreesConnection = {
  __typename?: 'DataCatalogueSnapshotV8TreesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<DataCatalogueSnapshotV8TreeAggregates>;
  /** A list of edges which contains the `DataCatalogueSnapshotV8Tree` and cursor to aid in pagination. */
  edges: Array<DataCatalogueSnapshotV8TreesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<DataCatalogueSnapshotV8TreeAggregates>>;
  /** A list of `DataCatalogueSnapshotV8Tree` objects. */
  nodes: Array<DataCatalogueSnapshotV8Tree>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DataCatalogueSnapshotV8Tree` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `DataCatalogueSnapshotV8Tree` values. */
export type DataCatalogueSnapshotV8TreesConnectionGroupedAggregatesArgs = {
  groupBy: Array<DataCatalogueSnapshotV8TreeGroupBy>;
  having?: InputMaybe<DataCatalogueSnapshotV8TreeHavingInput>;
};

/** A `DataCatalogueSnapshotV8Tree` edge in the connection. */
export type DataCatalogueSnapshotV8TreesEdge = {
  __typename?: 'DataCatalogueSnapshotV8TreesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `DataCatalogueSnapshotV8Tree` at the end of the edge. */
  node: DataCatalogueSnapshotV8Tree;
};

/** Methods to use when ordering `DataCatalogueSnapshotV8Tree`. */
export enum DataCatalogueSnapshotV8TreesOrderBy {
  CatalogueItemIdAsc = 'CATALOGUE_ITEM_ID_ASC',
  CatalogueItemIdDesc = 'CATALOGUE_ITEM_ID_DESC',
  CatalogueSnapshotIdAsc = 'CATALOGUE_SNAPSHOT_ID_ASC',
  CatalogueSnapshotIdDesc = 'CATALOGUE_SNAPSHOT_ID_DESC',
  HasChildrenAsc = 'HAS_CHILDREN_ASC',
  HasChildrenDesc = 'HAS_CHILDREN_DESC',
  IconAsc = 'ICON_ASC',
  IconDesc = 'ICON_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ItemTypeAsc = 'ITEM_TYPE_ASC',
  ItemTypeDesc = 'ITEM_TYPE_DESC',
  LogoBase64Asc = 'LOGO_BASE64_ASC',
  LogoBase64Desc = 'LOGO_BASE64_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  ParentTreeIdAsc = 'PARENT_TREE_ID_ASC',
  ParentTreeIdDesc = 'PARENT_TREE_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TreeIdAsc = 'TREE_ID_ASC',
  TreeIdDesc = 'TREE_ID_DESC'
}

export type DataCatalogueSnapshotV8VariancePopulationAggregates = {
  __typename?: 'DataCatalogueSnapshotV8VariancePopulationAggregates';
  /** Population variance of baseId across the matching connection */
  baseId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of catalogueVersion across the matching connection */
  catalogueVersion?: Maybe<Scalars['BigFloat']>;
  /** Population variance of commitHashId across the matching connection */
  commitHashId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of fivetranSnapshotId across the matching connection */
  fivetranSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of hightouchSnapshotId across the matching connection */
  hightouchSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of informationSchemaSnapshotId across the matching connection */
  informationSchemaSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of lookerRepositorySnapshotId across the matching connection */
  lookerRepositorySnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of lookerSnapshotId across the matching connection */
  lookerSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of tableauSnapshotId across the matching connection */
  tableauSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of testLogId across the matching connection */
  testLogId?: Maybe<Scalars['BigFloat']>;
};

export type DataCatalogueSnapshotV8VarianceSampleAggregates = {
  __typename?: 'DataCatalogueSnapshotV8VarianceSampleAggregates';
  /** Sample variance of baseId across the matching connection */
  baseId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of catalogueVersion across the matching connection */
  catalogueVersion?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of commitHashId across the matching connection */
  commitHashId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of fivetranSnapshotId across the matching connection */
  fivetranSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of hightouchSnapshotId across the matching connection */
  hightouchSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of informationSchemaSnapshotId across the matching connection */
  informationSchemaSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of lookerRepositorySnapshotId across the matching connection */
  lookerRepositorySnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of lookerSnapshotId across the matching connection */
  lookerSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of tableauSnapshotId across the matching connection */
  tableauSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of testLogId across the matching connection */
  testLogId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `DataCatalogueSnapshotV8` values. */
export type DataCatalogueSnapshotV8sConnection = {
  __typename?: 'DataCatalogueSnapshotV8sConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<DataCatalogueSnapshotV8Aggregates>;
  /** A list of edges which contains the `DataCatalogueSnapshotV8` and cursor to aid in pagination. */
  edges: Array<DataCatalogueSnapshotV8sEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<DataCatalogueSnapshotV8Aggregates>>;
  /** A list of `DataCatalogueSnapshotV8` objects. */
  nodes: Array<DataCatalogueSnapshotV8>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DataCatalogueSnapshotV8` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `DataCatalogueSnapshotV8` values. */
export type DataCatalogueSnapshotV8sConnectionGroupedAggregatesArgs = {
  groupBy: Array<DataCatalogueSnapshotV8GroupBy>;
  having?: InputMaybe<DataCatalogueSnapshotV8HavingInput>;
};

/** A `DataCatalogueSnapshotV8` edge in the connection. */
export type DataCatalogueSnapshotV8sEdge = {
  __typename?: 'DataCatalogueSnapshotV8sEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `DataCatalogueSnapshotV8` at the end of the edge. */
  node: DataCatalogueSnapshotV8;
};

/** Methods to use when ordering `DataCatalogueSnapshotV8`. */
export enum DataCatalogueSnapshotV8sOrderBy {
  BaseIdAsc = 'BASE_ID_ASC',
  BaseIdDesc = 'BASE_ID_DESC',
  CatalogueVersionAsc = 'CATALOGUE_VERSION_ASC',
  CatalogueVersionDesc = 'CATALOGUE_VERSION_DESC',
  CommitHashIdAsc = 'COMMIT_HASH_ID_ASC',
  CommitHashIdDesc = 'COMMIT_HASH_ID_DESC',
  CreatedDttmAsc = 'CREATED_DTTM_ASC',
  CreatedDttmDesc = 'CREATED_DTTM_DESC',
  DataCatalogueSnapshotV8SbyBaseIdAverageBaseIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_AVERAGE_BASE_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdAverageBaseIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_AVERAGE_BASE_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdAverageCatalogueVersionAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_AVERAGE_CATALOGUE_VERSION_ASC',
  DataCatalogueSnapshotV8SbyBaseIdAverageCatalogueVersionDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_AVERAGE_CATALOGUE_VERSION_DESC',
  DataCatalogueSnapshotV8SbyBaseIdAverageCommitHashIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_AVERAGE_COMMIT_HASH_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdAverageCommitHashIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_AVERAGE_COMMIT_HASH_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdAverageCreatedDttmAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_AVERAGE_CREATED_DTTM_ASC',
  DataCatalogueSnapshotV8SbyBaseIdAverageCreatedDttmDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_AVERAGE_CREATED_DTTM_DESC',
  DataCatalogueSnapshotV8SbyBaseIdAverageDbtCatalogueInvocationIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_AVERAGE_DBT_CATALOGUE_INVOCATION_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdAverageDbtCatalogueInvocationIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_AVERAGE_DBT_CATALOGUE_INVOCATION_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdAverageFivetranSnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_AVERAGE_FIVETRAN_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdAverageFivetranSnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_AVERAGE_FIVETRAN_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdAverageHightouchSnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_AVERAGE_HIGHTOUCH_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdAverageHightouchSnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_AVERAGE_HIGHTOUCH_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdAverageIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_AVERAGE_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdAverageIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_AVERAGE_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdAverageInformationSchemaSnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_AVERAGE_INFORMATION_SCHEMA_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdAverageInformationSchemaSnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_AVERAGE_INFORMATION_SCHEMA_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdAverageIntegrationSnapshotsAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_AVERAGE_INTEGRATION_SNAPSHOTS_ASC',
  DataCatalogueSnapshotV8SbyBaseIdAverageIntegrationSnapshotsDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_AVERAGE_INTEGRATION_SNAPSHOTS_DESC',
  DataCatalogueSnapshotV8SbyBaseIdAverageLookerRepositorySnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_AVERAGE_LOOKER_REPOSITORY_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdAverageLookerRepositorySnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_AVERAGE_LOOKER_REPOSITORY_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdAverageLookerSnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_AVERAGE_LOOKER_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdAverageLookerSnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_AVERAGE_LOOKER_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdAverageTableauSnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_AVERAGE_TABLEAU_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdAverageTableauSnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_AVERAGE_TABLEAU_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdAverageTestLogIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_AVERAGE_TEST_LOG_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdAverageTestLogIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_AVERAGE_TEST_LOG_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdCountAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_COUNT_ASC',
  DataCatalogueSnapshotV8SbyBaseIdCountDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_COUNT_DESC',
  DataCatalogueSnapshotV8SbyBaseIdDistinctCountBaseIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_DISTINCT_COUNT_BASE_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdDistinctCountBaseIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_DISTINCT_COUNT_BASE_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdDistinctCountCatalogueVersionAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_DISTINCT_COUNT_CATALOGUE_VERSION_ASC',
  DataCatalogueSnapshotV8SbyBaseIdDistinctCountCatalogueVersionDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_DISTINCT_COUNT_CATALOGUE_VERSION_DESC',
  DataCatalogueSnapshotV8SbyBaseIdDistinctCountCommitHashIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_DISTINCT_COUNT_COMMIT_HASH_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdDistinctCountCommitHashIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_DISTINCT_COUNT_COMMIT_HASH_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdDistinctCountCreatedDttmAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_DISTINCT_COUNT_CREATED_DTTM_ASC',
  DataCatalogueSnapshotV8SbyBaseIdDistinctCountCreatedDttmDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_DISTINCT_COUNT_CREATED_DTTM_DESC',
  DataCatalogueSnapshotV8SbyBaseIdDistinctCountDbtCatalogueInvocationIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_DISTINCT_COUNT_DBT_CATALOGUE_INVOCATION_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdDistinctCountDbtCatalogueInvocationIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_DISTINCT_COUNT_DBT_CATALOGUE_INVOCATION_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdDistinctCountFivetranSnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_DISTINCT_COUNT_FIVETRAN_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdDistinctCountFivetranSnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_DISTINCT_COUNT_FIVETRAN_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdDistinctCountHightouchSnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_DISTINCT_COUNT_HIGHTOUCH_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdDistinctCountHightouchSnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_DISTINCT_COUNT_HIGHTOUCH_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdDistinctCountIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_DISTINCT_COUNT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdDistinctCountIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_DISTINCT_COUNT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdDistinctCountInformationSchemaSnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_DISTINCT_COUNT_INFORMATION_SCHEMA_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdDistinctCountInformationSchemaSnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_DISTINCT_COUNT_INFORMATION_SCHEMA_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdDistinctCountIntegrationSnapshotsAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_DISTINCT_COUNT_INTEGRATION_SNAPSHOTS_ASC',
  DataCatalogueSnapshotV8SbyBaseIdDistinctCountIntegrationSnapshotsDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_DISTINCT_COUNT_INTEGRATION_SNAPSHOTS_DESC',
  DataCatalogueSnapshotV8SbyBaseIdDistinctCountLookerRepositorySnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_DISTINCT_COUNT_LOOKER_REPOSITORY_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdDistinctCountLookerRepositorySnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_DISTINCT_COUNT_LOOKER_REPOSITORY_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdDistinctCountLookerSnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_DISTINCT_COUNT_LOOKER_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdDistinctCountLookerSnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_DISTINCT_COUNT_LOOKER_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdDistinctCountTableauSnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_DISTINCT_COUNT_TABLEAU_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdDistinctCountTableauSnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_DISTINCT_COUNT_TABLEAU_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdDistinctCountTestLogIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_DISTINCT_COUNT_TEST_LOG_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdDistinctCountTestLogIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_DISTINCT_COUNT_TEST_LOG_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdMaxBaseIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MAX_BASE_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdMaxBaseIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MAX_BASE_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdMaxCatalogueVersionAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MAX_CATALOGUE_VERSION_ASC',
  DataCatalogueSnapshotV8SbyBaseIdMaxCatalogueVersionDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MAX_CATALOGUE_VERSION_DESC',
  DataCatalogueSnapshotV8SbyBaseIdMaxCommitHashIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MAX_COMMIT_HASH_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdMaxCommitHashIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MAX_COMMIT_HASH_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdMaxCreatedDttmAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MAX_CREATED_DTTM_ASC',
  DataCatalogueSnapshotV8SbyBaseIdMaxCreatedDttmDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MAX_CREATED_DTTM_DESC',
  DataCatalogueSnapshotV8SbyBaseIdMaxDbtCatalogueInvocationIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MAX_DBT_CATALOGUE_INVOCATION_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdMaxDbtCatalogueInvocationIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MAX_DBT_CATALOGUE_INVOCATION_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdMaxFivetranSnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MAX_FIVETRAN_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdMaxFivetranSnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MAX_FIVETRAN_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdMaxHightouchSnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MAX_HIGHTOUCH_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdMaxHightouchSnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MAX_HIGHTOUCH_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdMaxIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MAX_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdMaxIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MAX_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdMaxInformationSchemaSnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MAX_INFORMATION_SCHEMA_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdMaxInformationSchemaSnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MAX_INFORMATION_SCHEMA_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdMaxIntegrationSnapshotsAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MAX_INTEGRATION_SNAPSHOTS_ASC',
  DataCatalogueSnapshotV8SbyBaseIdMaxIntegrationSnapshotsDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MAX_INTEGRATION_SNAPSHOTS_DESC',
  DataCatalogueSnapshotV8SbyBaseIdMaxLookerRepositorySnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MAX_LOOKER_REPOSITORY_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdMaxLookerRepositorySnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MAX_LOOKER_REPOSITORY_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdMaxLookerSnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MAX_LOOKER_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdMaxLookerSnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MAX_LOOKER_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdMaxTableauSnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MAX_TABLEAU_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdMaxTableauSnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MAX_TABLEAU_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdMaxTestLogIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MAX_TEST_LOG_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdMaxTestLogIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MAX_TEST_LOG_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdMinBaseIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MIN_BASE_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdMinBaseIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MIN_BASE_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdMinCatalogueVersionAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MIN_CATALOGUE_VERSION_ASC',
  DataCatalogueSnapshotV8SbyBaseIdMinCatalogueVersionDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MIN_CATALOGUE_VERSION_DESC',
  DataCatalogueSnapshotV8SbyBaseIdMinCommitHashIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MIN_COMMIT_HASH_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdMinCommitHashIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MIN_COMMIT_HASH_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdMinCreatedDttmAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MIN_CREATED_DTTM_ASC',
  DataCatalogueSnapshotV8SbyBaseIdMinCreatedDttmDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MIN_CREATED_DTTM_DESC',
  DataCatalogueSnapshotV8SbyBaseIdMinDbtCatalogueInvocationIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MIN_DBT_CATALOGUE_INVOCATION_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdMinDbtCatalogueInvocationIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MIN_DBT_CATALOGUE_INVOCATION_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdMinFivetranSnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MIN_FIVETRAN_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdMinFivetranSnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MIN_FIVETRAN_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdMinHightouchSnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MIN_HIGHTOUCH_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdMinHightouchSnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MIN_HIGHTOUCH_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdMinIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MIN_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdMinIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MIN_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdMinInformationSchemaSnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MIN_INFORMATION_SCHEMA_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdMinInformationSchemaSnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MIN_INFORMATION_SCHEMA_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdMinIntegrationSnapshotsAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MIN_INTEGRATION_SNAPSHOTS_ASC',
  DataCatalogueSnapshotV8SbyBaseIdMinIntegrationSnapshotsDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MIN_INTEGRATION_SNAPSHOTS_DESC',
  DataCatalogueSnapshotV8SbyBaseIdMinLookerRepositorySnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MIN_LOOKER_REPOSITORY_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdMinLookerRepositorySnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MIN_LOOKER_REPOSITORY_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdMinLookerSnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MIN_LOOKER_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdMinLookerSnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MIN_LOOKER_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdMinTableauSnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MIN_TABLEAU_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdMinTableauSnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MIN_TABLEAU_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdMinTestLogIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MIN_TEST_LOG_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdMinTestLogIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_MIN_TEST_LOG_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdStddevPopulationBaseIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_POPULATION_BASE_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdStddevPopulationBaseIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_POPULATION_BASE_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdStddevPopulationCatalogueVersionAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_POPULATION_CATALOGUE_VERSION_ASC',
  DataCatalogueSnapshotV8SbyBaseIdStddevPopulationCatalogueVersionDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_POPULATION_CATALOGUE_VERSION_DESC',
  DataCatalogueSnapshotV8SbyBaseIdStddevPopulationCommitHashIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_POPULATION_COMMIT_HASH_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdStddevPopulationCommitHashIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_POPULATION_COMMIT_HASH_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdStddevPopulationCreatedDttmAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_POPULATION_CREATED_DTTM_ASC',
  DataCatalogueSnapshotV8SbyBaseIdStddevPopulationCreatedDttmDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_POPULATION_CREATED_DTTM_DESC',
  DataCatalogueSnapshotV8SbyBaseIdStddevPopulationDbtCatalogueInvocationIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_POPULATION_DBT_CATALOGUE_INVOCATION_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdStddevPopulationDbtCatalogueInvocationIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_POPULATION_DBT_CATALOGUE_INVOCATION_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdStddevPopulationFivetranSnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_POPULATION_FIVETRAN_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdStddevPopulationFivetranSnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_POPULATION_FIVETRAN_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdStddevPopulationHightouchSnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_POPULATION_HIGHTOUCH_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdStddevPopulationHightouchSnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_POPULATION_HIGHTOUCH_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdStddevPopulationIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_POPULATION_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdStddevPopulationIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_POPULATION_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdStddevPopulationInformationSchemaSnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_POPULATION_INFORMATION_SCHEMA_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdStddevPopulationInformationSchemaSnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_POPULATION_INFORMATION_SCHEMA_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdStddevPopulationIntegrationSnapshotsAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_POPULATION_INTEGRATION_SNAPSHOTS_ASC',
  DataCatalogueSnapshotV8SbyBaseIdStddevPopulationIntegrationSnapshotsDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_POPULATION_INTEGRATION_SNAPSHOTS_DESC',
  DataCatalogueSnapshotV8SbyBaseIdStddevPopulationLookerRepositorySnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_POPULATION_LOOKER_REPOSITORY_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdStddevPopulationLookerRepositorySnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_POPULATION_LOOKER_REPOSITORY_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdStddevPopulationLookerSnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_POPULATION_LOOKER_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdStddevPopulationLookerSnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_POPULATION_LOOKER_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdStddevPopulationTableauSnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_POPULATION_TABLEAU_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdStddevPopulationTableauSnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_POPULATION_TABLEAU_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdStddevPopulationTestLogIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_POPULATION_TEST_LOG_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdStddevPopulationTestLogIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_POPULATION_TEST_LOG_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdStddevSampleBaseIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_SAMPLE_BASE_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdStddevSampleBaseIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_SAMPLE_BASE_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdStddevSampleCatalogueVersionAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_SAMPLE_CATALOGUE_VERSION_ASC',
  DataCatalogueSnapshotV8SbyBaseIdStddevSampleCatalogueVersionDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_SAMPLE_CATALOGUE_VERSION_DESC',
  DataCatalogueSnapshotV8SbyBaseIdStddevSampleCommitHashIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_SAMPLE_COMMIT_HASH_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdStddevSampleCommitHashIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_SAMPLE_COMMIT_HASH_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdStddevSampleCreatedDttmAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_SAMPLE_CREATED_DTTM_ASC',
  DataCatalogueSnapshotV8SbyBaseIdStddevSampleCreatedDttmDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_SAMPLE_CREATED_DTTM_DESC',
  DataCatalogueSnapshotV8SbyBaseIdStddevSampleDbtCatalogueInvocationIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_SAMPLE_DBT_CATALOGUE_INVOCATION_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdStddevSampleDbtCatalogueInvocationIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_SAMPLE_DBT_CATALOGUE_INVOCATION_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdStddevSampleFivetranSnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_SAMPLE_FIVETRAN_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdStddevSampleFivetranSnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_SAMPLE_FIVETRAN_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdStddevSampleHightouchSnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_SAMPLE_HIGHTOUCH_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdStddevSampleHightouchSnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_SAMPLE_HIGHTOUCH_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdStddevSampleIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_SAMPLE_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdStddevSampleIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_SAMPLE_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdStddevSampleInformationSchemaSnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_SAMPLE_INFORMATION_SCHEMA_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdStddevSampleInformationSchemaSnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_SAMPLE_INFORMATION_SCHEMA_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdStddevSampleIntegrationSnapshotsAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_SAMPLE_INTEGRATION_SNAPSHOTS_ASC',
  DataCatalogueSnapshotV8SbyBaseIdStddevSampleIntegrationSnapshotsDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_SAMPLE_INTEGRATION_SNAPSHOTS_DESC',
  DataCatalogueSnapshotV8SbyBaseIdStddevSampleLookerRepositorySnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_SAMPLE_LOOKER_REPOSITORY_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdStddevSampleLookerRepositorySnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_SAMPLE_LOOKER_REPOSITORY_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdStddevSampleLookerSnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_SAMPLE_LOOKER_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdStddevSampleLookerSnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_SAMPLE_LOOKER_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdStddevSampleTableauSnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_SAMPLE_TABLEAU_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdStddevSampleTableauSnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_SAMPLE_TABLEAU_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdStddevSampleTestLogIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_SAMPLE_TEST_LOG_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdStddevSampleTestLogIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_STDDEV_SAMPLE_TEST_LOG_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdSumBaseIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_SUM_BASE_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdSumBaseIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_SUM_BASE_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdSumCatalogueVersionAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_SUM_CATALOGUE_VERSION_ASC',
  DataCatalogueSnapshotV8SbyBaseIdSumCatalogueVersionDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_SUM_CATALOGUE_VERSION_DESC',
  DataCatalogueSnapshotV8SbyBaseIdSumCommitHashIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_SUM_COMMIT_HASH_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdSumCommitHashIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_SUM_COMMIT_HASH_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdSumCreatedDttmAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_SUM_CREATED_DTTM_ASC',
  DataCatalogueSnapshotV8SbyBaseIdSumCreatedDttmDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_SUM_CREATED_DTTM_DESC',
  DataCatalogueSnapshotV8SbyBaseIdSumDbtCatalogueInvocationIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_SUM_DBT_CATALOGUE_INVOCATION_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdSumDbtCatalogueInvocationIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_SUM_DBT_CATALOGUE_INVOCATION_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdSumFivetranSnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_SUM_FIVETRAN_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdSumFivetranSnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_SUM_FIVETRAN_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdSumHightouchSnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_SUM_HIGHTOUCH_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdSumHightouchSnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_SUM_HIGHTOUCH_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdSumIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_SUM_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdSumIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_SUM_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdSumInformationSchemaSnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_SUM_INFORMATION_SCHEMA_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdSumInformationSchemaSnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_SUM_INFORMATION_SCHEMA_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdSumIntegrationSnapshotsAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_SUM_INTEGRATION_SNAPSHOTS_ASC',
  DataCatalogueSnapshotV8SbyBaseIdSumIntegrationSnapshotsDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_SUM_INTEGRATION_SNAPSHOTS_DESC',
  DataCatalogueSnapshotV8SbyBaseIdSumLookerRepositorySnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_SUM_LOOKER_REPOSITORY_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdSumLookerRepositorySnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_SUM_LOOKER_REPOSITORY_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdSumLookerSnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_SUM_LOOKER_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdSumLookerSnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_SUM_LOOKER_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdSumTableauSnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_SUM_TABLEAU_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdSumTableauSnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_SUM_TABLEAU_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdSumTestLogIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_SUM_TEST_LOG_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdSumTestLogIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_SUM_TEST_LOG_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdVariancePopulationBaseIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_POPULATION_BASE_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdVariancePopulationBaseIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_POPULATION_BASE_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdVariancePopulationCatalogueVersionAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_POPULATION_CATALOGUE_VERSION_ASC',
  DataCatalogueSnapshotV8SbyBaseIdVariancePopulationCatalogueVersionDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_POPULATION_CATALOGUE_VERSION_DESC',
  DataCatalogueSnapshotV8SbyBaseIdVariancePopulationCommitHashIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_POPULATION_COMMIT_HASH_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdVariancePopulationCommitHashIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_POPULATION_COMMIT_HASH_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdVariancePopulationCreatedDttmAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_POPULATION_CREATED_DTTM_ASC',
  DataCatalogueSnapshotV8SbyBaseIdVariancePopulationCreatedDttmDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_POPULATION_CREATED_DTTM_DESC',
  DataCatalogueSnapshotV8SbyBaseIdVariancePopulationDbtCatalogueInvocationIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_POPULATION_DBT_CATALOGUE_INVOCATION_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdVariancePopulationDbtCatalogueInvocationIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_POPULATION_DBT_CATALOGUE_INVOCATION_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdVariancePopulationFivetranSnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_POPULATION_FIVETRAN_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdVariancePopulationFivetranSnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_POPULATION_FIVETRAN_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdVariancePopulationHightouchSnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_POPULATION_HIGHTOUCH_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdVariancePopulationHightouchSnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_POPULATION_HIGHTOUCH_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdVariancePopulationIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_POPULATION_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdVariancePopulationIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_POPULATION_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdVariancePopulationInformationSchemaSnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_POPULATION_INFORMATION_SCHEMA_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdVariancePopulationInformationSchemaSnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_POPULATION_INFORMATION_SCHEMA_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdVariancePopulationIntegrationSnapshotsAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_POPULATION_INTEGRATION_SNAPSHOTS_ASC',
  DataCatalogueSnapshotV8SbyBaseIdVariancePopulationIntegrationSnapshotsDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_POPULATION_INTEGRATION_SNAPSHOTS_DESC',
  DataCatalogueSnapshotV8SbyBaseIdVariancePopulationLookerRepositorySnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_POPULATION_LOOKER_REPOSITORY_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdVariancePopulationLookerRepositorySnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_POPULATION_LOOKER_REPOSITORY_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdVariancePopulationLookerSnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_POPULATION_LOOKER_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdVariancePopulationLookerSnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_POPULATION_LOOKER_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdVariancePopulationTableauSnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_POPULATION_TABLEAU_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdVariancePopulationTableauSnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_POPULATION_TABLEAU_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdVariancePopulationTestLogIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_POPULATION_TEST_LOG_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdVariancePopulationTestLogIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_POPULATION_TEST_LOG_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdVarianceSampleBaseIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_SAMPLE_BASE_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdVarianceSampleBaseIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_SAMPLE_BASE_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdVarianceSampleCatalogueVersionAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_SAMPLE_CATALOGUE_VERSION_ASC',
  DataCatalogueSnapshotV8SbyBaseIdVarianceSampleCatalogueVersionDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_SAMPLE_CATALOGUE_VERSION_DESC',
  DataCatalogueSnapshotV8SbyBaseIdVarianceSampleCommitHashIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_SAMPLE_COMMIT_HASH_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdVarianceSampleCommitHashIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_SAMPLE_COMMIT_HASH_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdVarianceSampleCreatedDttmAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_SAMPLE_CREATED_DTTM_ASC',
  DataCatalogueSnapshotV8SbyBaseIdVarianceSampleCreatedDttmDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_SAMPLE_CREATED_DTTM_DESC',
  DataCatalogueSnapshotV8SbyBaseIdVarianceSampleDbtCatalogueInvocationIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_SAMPLE_DBT_CATALOGUE_INVOCATION_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdVarianceSampleDbtCatalogueInvocationIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_SAMPLE_DBT_CATALOGUE_INVOCATION_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdVarianceSampleFivetranSnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_SAMPLE_FIVETRAN_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdVarianceSampleFivetranSnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_SAMPLE_FIVETRAN_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdVarianceSampleHightouchSnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_SAMPLE_HIGHTOUCH_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdVarianceSampleHightouchSnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_SAMPLE_HIGHTOUCH_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdVarianceSampleIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_SAMPLE_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdVarianceSampleIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_SAMPLE_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdVarianceSampleInformationSchemaSnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_SAMPLE_INFORMATION_SCHEMA_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdVarianceSampleInformationSchemaSnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_SAMPLE_INFORMATION_SCHEMA_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdVarianceSampleIntegrationSnapshotsAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_SAMPLE_INTEGRATION_SNAPSHOTS_ASC',
  DataCatalogueSnapshotV8SbyBaseIdVarianceSampleIntegrationSnapshotsDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_SAMPLE_INTEGRATION_SNAPSHOTS_DESC',
  DataCatalogueSnapshotV8SbyBaseIdVarianceSampleLookerRepositorySnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_SAMPLE_LOOKER_REPOSITORY_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdVarianceSampleLookerRepositorySnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_SAMPLE_LOOKER_REPOSITORY_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdVarianceSampleLookerSnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_SAMPLE_LOOKER_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdVarianceSampleLookerSnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_SAMPLE_LOOKER_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdVarianceSampleTableauSnapshotIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_SAMPLE_TABLEAU_SNAPSHOT_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdVarianceSampleTableauSnapshotIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_SAMPLE_TABLEAU_SNAPSHOT_ID_DESC',
  DataCatalogueSnapshotV8SbyBaseIdVarianceSampleTestLogIdAsc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_SAMPLE_TEST_LOG_ID_ASC',
  DataCatalogueSnapshotV8SbyBaseIdVarianceSampleTestLogIdDesc = 'DATA_CATALOGUE_SNAPSHOT_V8_SBY_BASE_ID_VARIANCE_SAMPLE_TEST_LOG_ID_DESC',
  DbtCatalogueInvocationIdAsc = 'DBT_CATALOGUE_INVOCATION_ID_ASC',
  DbtCatalogueInvocationIdDesc = 'DBT_CATALOGUE_INVOCATION_ID_DESC',
  FivetranSnapshotIdAsc = 'FIVETRAN_SNAPSHOT_ID_ASC',
  FivetranSnapshotIdDesc = 'FIVETRAN_SNAPSHOT_ID_DESC',
  HightouchSnapshotIdAsc = 'HIGHTOUCH_SNAPSHOT_ID_ASC',
  HightouchSnapshotIdDesc = 'HIGHTOUCH_SNAPSHOT_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  InformationSchemaSnapshotIdAsc = 'INFORMATION_SCHEMA_SNAPSHOT_ID_ASC',
  InformationSchemaSnapshotIdDesc = 'INFORMATION_SCHEMA_SNAPSHOT_ID_DESC',
  IntegrationSnapshotsAsc = 'INTEGRATION_SNAPSHOTS_ASC',
  IntegrationSnapshotsDesc = 'INTEGRATION_SNAPSHOTS_DESC',
  LookerRepositorySnapshotIdAsc = 'LOOKER_REPOSITORY_SNAPSHOT_ID_ASC',
  LookerRepositorySnapshotIdDesc = 'LOOKER_REPOSITORY_SNAPSHOT_ID_DESC',
  LookerSnapshotIdAsc = 'LOOKER_SNAPSHOT_ID_ASC',
  LookerSnapshotIdDesc = 'LOOKER_SNAPSHOT_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TableauSnapshotIdAsc = 'TABLEAU_SNAPSHOT_ID_ASC',
  TableauSnapshotIdDesc = 'TABLEAU_SNAPSHOT_ID_DESC',
  TestLogIdAsc = 'TEST_LOG_ID_ASC',
  TestLogIdDesc = 'TEST_LOG_ID_DESC'
}

export type DbtArtifactCatalogueColumn = Node & {
  __typename?: 'DbtArtifactCatalogueColumn';
  columnComment?: Maybe<Scalars['String']>;
  columnIndex: Scalars['Int'];
  columnName: Scalars['String'];
  columnType: Scalars['String'];
  createdDttm: Scalars['Datetime'];
  dbtArtifactCatalogueId: Scalars['BigInt'];
  id: Scalars['BigInt'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  updatedDttm: Scalars['Datetime'];
  userId: Scalars['Int'];
  workspaceId: Scalars['Int'];
};

export type DbtArtifactCatalogueColumnAggregates = {
  __typename?: 'DbtArtifactCatalogueColumnAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<DbtArtifactCatalogueColumnAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<DbtArtifactCatalogueColumnDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<DbtArtifactCatalogueColumnMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<DbtArtifactCatalogueColumnMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<DbtArtifactCatalogueColumnStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<DbtArtifactCatalogueColumnStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<DbtArtifactCatalogueColumnSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<DbtArtifactCatalogueColumnVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<DbtArtifactCatalogueColumnVarianceSampleAggregates>;
};

export type DbtArtifactCatalogueColumnAverageAggregates = {
  __typename?: 'DbtArtifactCatalogueColumnAverageAggregates';
  /** Mean average of columnIndex across the matching connection */
  columnIndex?: Maybe<Scalars['BigFloat']>;
  /** Mean average of dbtArtifactCatalogueId across the matching connection */
  dbtArtifactCatalogueId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of userId across the matching connection */
  userId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `DbtArtifactCatalogueColumn` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type DbtArtifactCatalogueColumnCondition = {
  /** Checks for equality with the object’s `columnComment` field. */
  columnComment?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `columnIndex` field. */
  columnIndex?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `columnName` field. */
  columnName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `columnType` field. */
  columnType?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdDttm` field. */
  createdDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `dbtArtifactCatalogueId` field. */
  dbtArtifactCatalogueId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `updatedDttm` field. */
  updatedDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `workspaceId` field. */
  workspaceId?: InputMaybe<Scalars['Int']>;
};

export type DbtArtifactCatalogueColumnDistinctCountAggregates = {
  __typename?: 'DbtArtifactCatalogueColumnDistinctCountAggregates';
  /** Distinct count of columnComment across the matching connection */
  columnComment?: Maybe<Scalars['BigInt']>;
  /** Distinct count of columnIndex across the matching connection */
  columnIndex?: Maybe<Scalars['BigInt']>;
  /** Distinct count of columnName across the matching connection */
  columnName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of columnType across the matching connection */
  columnType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdDttm across the matching connection */
  createdDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of dbtArtifactCatalogueId across the matching connection */
  dbtArtifactCatalogueId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedDttm across the matching connection */
  updatedDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of userId across the matching connection */
  userId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigInt']>;
};

export type DbtArtifactCatalogueColumnMaxAggregates = {
  __typename?: 'DbtArtifactCatalogueColumnMaxAggregates';
  /** Maximum of columnIndex across the matching connection */
  columnIndex?: Maybe<Scalars['Int']>;
  /** Maximum of dbtArtifactCatalogueId across the matching connection */
  dbtArtifactCatalogueId?: Maybe<Scalars['BigInt']>;
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Maximum of userId across the matching connection */
  userId?: Maybe<Scalars['Int']>;
  /** Maximum of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['Int']>;
};

export type DbtArtifactCatalogueColumnMinAggregates = {
  __typename?: 'DbtArtifactCatalogueColumnMinAggregates';
  /** Minimum of columnIndex across the matching connection */
  columnIndex?: Maybe<Scalars['Int']>;
  /** Minimum of dbtArtifactCatalogueId across the matching connection */
  dbtArtifactCatalogueId?: Maybe<Scalars['BigInt']>;
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Minimum of userId across the matching connection */
  userId?: Maybe<Scalars['Int']>;
  /** Minimum of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['Int']>;
};

export type DbtArtifactCatalogueColumnStddevPopulationAggregates = {
  __typename?: 'DbtArtifactCatalogueColumnStddevPopulationAggregates';
  /** Population standard deviation of columnIndex across the matching connection */
  columnIndex?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of dbtArtifactCatalogueId across the matching connection */
  dbtArtifactCatalogueId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of userId across the matching connection */
  userId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type DbtArtifactCatalogueColumnStddevSampleAggregates = {
  __typename?: 'DbtArtifactCatalogueColumnStddevSampleAggregates';
  /** Sample standard deviation of columnIndex across the matching connection */
  columnIndex?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of dbtArtifactCatalogueId across the matching connection */
  dbtArtifactCatalogueId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of userId across the matching connection */
  userId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type DbtArtifactCatalogueColumnSumAggregates = {
  __typename?: 'DbtArtifactCatalogueColumnSumAggregates';
  /** Sum of columnIndex across the matching connection */
  columnIndex: Scalars['BigInt'];
  /** Sum of dbtArtifactCatalogueId across the matching connection */
  dbtArtifactCatalogueId: Scalars['BigFloat'];
  /** Sum of id across the matching connection */
  id: Scalars['BigFloat'];
  /** Sum of userId across the matching connection */
  userId: Scalars['BigInt'];
  /** Sum of workspaceId across the matching connection */
  workspaceId: Scalars['BigInt'];
};

export type DbtArtifactCatalogueColumnVariancePopulationAggregates = {
  __typename?: 'DbtArtifactCatalogueColumnVariancePopulationAggregates';
  /** Population variance of columnIndex across the matching connection */
  columnIndex?: Maybe<Scalars['BigFloat']>;
  /** Population variance of dbtArtifactCatalogueId across the matching connection */
  dbtArtifactCatalogueId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of userId across the matching connection */
  userId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type DbtArtifactCatalogueColumnVarianceSampleAggregates = {
  __typename?: 'DbtArtifactCatalogueColumnVarianceSampleAggregates';
  /** Sample variance of columnIndex across the matching connection */
  columnIndex?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of dbtArtifactCatalogueId across the matching connection */
  dbtArtifactCatalogueId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of userId across the matching connection */
  userId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `DbtArtifactCatalogueColumn` values. */
export type DbtArtifactCatalogueColumnsConnection = {
  __typename?: 'DbtArtifactCatalogueColumnsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<DbtArtifactCatalogueColumnAggregates>;
  /** A list of edges which contains the `DbtArtifactCatalogueColumn` and cursor to aid in pagination. */
  edges: Array<DbtArtifactCatalogueColumnsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<DbtArtifactCatalogueColumnAggregates>>;
  /** A list of `DbtArtifactCatalogueColumn` objects. */
  nodes: Array<DbtArtifactCatalogueColumn>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DbtArtifactCatalogueColumn` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `DbtArtifactCatalogueColumn` values. */
export type DbtArtifactCatalogueColumnsConnectionGroupedAggregatesArgs = {
  groupBy: Array<DbtArtifactCatalogueColumnsGroupBy>;
  having?: InputMaybe<DbtArtifactCatalogueColumnsHavingInput>;
};

/** A `DbtArtifactCatalogueColumn` edge in the connection. */
export type DbtArtifactCatalogueColumnsEdge = {
  __typename?: 'DbtArtifactCatalogueColumnsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `DbtArtifactCatalogueColumn` at the end of the edge. */
  node: DbtArtifactCatalogueColumn;
};

/** Grouping methods for `DbtArtifactCatalogueColumn` for usage during aggregation. */
export enum DbtArtifactCatalogueColumnsGroupBy {
  ColumnComment = 'COLUMN_COMMENT',
  ColumnIndex = 'COLUMN_INDEX',
  ColumnName = 'COLUMN_NAME',
  ColumnType = 'COLUMN_TYPE',
  CreatedDttm = 'CREATED_DTTM',
  CreatedDttmTruncatedToDay = 'CREATED_DTTM_TRUNCATED_TO_DAY',
  CreatedDttmTruncatedToHour = 'CREATED_DTTM_TRUNCATED_TO_HOUR',
  DbtArtifactCatalogueId = 'DBT_ARTIFACT_CATALOGUE_ID',
  UpdatedDttm = 'UPDATED_DTTM',
  UpdatedDttmTruncatedToDay = 'UPDATED_DTTM_TRUNCATED_TO_DAY',
  UpdatedDttmTruncatedToHour = 'UPDATED_DTTM_TRUNCATED_TO_HOUR',
  UserId = 'USER_ID',
  WorkspaceId = 'WORKSPACE_ID'
}

export type DbtArtifactCatalogueColumnsHavingAverageInput = {
  columnIndex?: InputMaybe<HavingIntFilter>;
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  dbtArtifactCatalogueId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  userId?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type DbtArtifactCatalogueColumnsHavingDistinctCountInput = {
  columnIndex?: InputMaybe<HavingIntFilter>;
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  dbtArtifactCatalogueId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  userId?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `DbtArtifactCatalogueColumn` aggregates. */
export type DbtArtifactCatalogueColumnsHavingInput = {
  AND?: InputMaybe<Array<DbtArtifactCatalogueColumnsHavingInput>>;
  OR?: InputMaybe<Array<DbtArtifactCatalogueColumnsHavingInput>>;
  average?: InputMaybe<DbtArtifactCatalogueColumnsHavingAverageInput>;
  distinctCount?: InputMaybe<DbtArtifactCatalogueColumnsHavingDistinctCountInput>;
  max?: InputMaybe<DbtArtifactCatalogueColumnsHavingMaxInput>;
  min?: InputMaybe<DbtArtifactCatalogueColumnsHavingMinInput>;
  stddevPopulation?: InputMaybe<DbtArtifactCatalogueColumnsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<DbtArtifactCatalogueColumnsHavingStddevSampleInput>;
  sum?: InputMaybe<DbtArtifactCatalogueColumnsHavingSumInput>;
  variancePopulation?: InputMaybe<DbtArtifactCatalogueColumnsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<DbtArtifactCatalogueColumnsHavingVarianceSampleInput>;
};

export type DbtArtifactCatalogueColumnsHavingMaxInput = {
  columnIndex?: InputMaybe<HavingIntFilter>;
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  dbtArtifactCatalogueId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  userId?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type DbtArtifactCatalogueColumnsHavingMinInput = {
  columnIndex?: InputMaybe<HavingIntFilter>;
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  dbtArtifactCatalogueId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  userId?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type DbtArtifactCatalogueColumnsHavingStddevPopulationInput = {
  columnIndex?: InputMaybe<HavingIntFilter>;
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  dbtArtifactCatalogueId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  userId?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type DbtArtifactCatalogueColumnsHavingStddevSampleInput = {
  columnIndex?: InputMaybe<HavingIntFilter>;
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  dbtArtifactCatalogueId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  userId?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type DbtArtifactCatalogueColumnsHavingSumInput = {
  columnIndex?: InputMaybe<HavingIntFilter>;
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  dbtArtifactCatalogueId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  userId?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type DbtArtifactCatalogueColumnsHavingVariancePopulationInput = {
  columnIndex?: InputMaybe<HavingIntFilter>;
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  dbtArtifactCatalogueId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  userId?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type DbtArtifactCatalogueColumnsHavingVarianceSampleInput = {
  columnIndex?: InputMaybe<HavingIntFilter>;
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  dbtArtifactCatalogueId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  userId?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

/** Methods to use when ordering `DbtArtifactCatalogueColumn`. */
export enum DbtArtifactCatalogueColumnsOrderBy {
  ColumnCommentAsc = 'COLUMN_COMMENT_ASC',
  ColumnCommentDesc = 'COLUMN_COMMENT_DESC',
  ColumnIndexAsc = 'COLUMN_INDEX_ASC',
  ColumnIndexDesc = 'COLUMN_INDEX_DESC',
  ColumnNameAsc = 'COLUMN_NAME_ASC',
  ColumnNameDesc = 'COLUMN_NAME_DESC',
  ColumnTypeAsc = 'COLUMN_TYPE_ASC',
  ColumnTypeDesc = 'COLUMN_TYPE_DESC',
  CreatedDttmAsc = 'CREATED_DTTM_ASC',
  CreatedDttmDesc = 'CREATED_DTTM_DESC',
  DbtArtifactCatalogueIdAsc = 'DBT_ARTIFACT_CATALOGUE_ID_ASC',
  DbtArtifactCatalogueIdDesc = 'DBT_ARTIFACT_CATALOGUE_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedDttmAsc = 'UPDATED_DTTM_ASC',
  UpdatedDttmDesc = 'UPDATED_DTTM_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  WorkspaceIdAsc = 'WORKSPACE_ID_ASC',
  WorkspaceIdDesc = 'WORKSPACE_ID_DESC'
}

export type DbtArtifactCatalogueObject = Node & {
  __typename?: 'DbtArtifactCatalogueObject';
  createdDttm: Scalars['Datetime'];
  id: Scalars['BigInt'];
  metadataComment?: Maybe<Scalars['String']>;
  metadataDatabase?: Maybe<Scalars['String']>;
  metadataDbtVersion?: Maybe<Scalars['String']>;
  metadataDwhType: Scalars['String'];
  metadataGeneratedAt?: Maybe<Scalars['Datetime']>;
  metadataInvocationId?: Maybe<Scalars['String']>;
  metadataName: Scalars['String'];
  metadataOwner?: Maybe<Scalars['String']>;
  metadataSchema?: Maybe<Scalars['String']>;
  metadataType: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  statisticsAllJson: Scalars['String'];
  statisticsByteCount?: Maybe<Scalars['BigInt']>;
  statisticsHasStats?: Maybe<Scalars['Boolean']>;
  statisticsLastModified?: Maybe<Scalars['String']>;
  statisticsRowCount?: Maybe<Scalars['BigInt']>;
  uniqueId?: Maybe<Scalars['String']>;
  updatedDttm: Scalars['Datetime'];
  userId: Scalars['Int'];
  workspaceId: Scalars['Int'];
};

export type DbtArtifactCatalogueObjectAggregates = {
  __typename?: 'DbtArtifactCatalogueObjectAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<DbtArtifactCatalogueObjectAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<DbtArtifactCatalogueObjectDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<DbtArtifactCatalogueObjectMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<DbtArtifactCatalogueObjectMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<DbtArtifactCatalogueObjectStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<DbtArtifactCatalogueObjectStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<DbtArtifactCatalogueObjectSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<DbtArtifactCatalogueObjectVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<DbtArtifactCatalogueObjectVarianceSampleAggregates>;
};

export type DbtArtifactCatalogueObjectAverageAggregates = {
  __typename?: 'DbtArtifactCatalogueObjectAverageAggregates';
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of statisticsByteCount across the matching connection */
  statisticsByteCount?: Maybe<Scalars['BigFloat']>;
  /** Mean average of statisticsRowCount across the matching connection */
  statisticsRowCount?: Maybe<Scalars['BigFloat']>;
  /** Mean average of userId across the matching connection */
  userId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `DbtArtifactCatalogueObject` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type DbtArtifactCatalogueObjectCondition = {
  /** Checks for equality with the object’s `createdDttm` field. */
  createdDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `metadataComment` field. */
  metadataComment?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `metadataDatabase` field. */
  metadataDatabase?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `metadataDbtVersion` field. */
  metadataDbtVersion?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `metadataDwhType` field. */
  metadataDwhType?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `metadataGeneratedAt` field. */
  metadataGeneratedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `metadataInvocationId` field. */
  metadataInvocationId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `metadataName` field. */
  metadataName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `metadataOwner` field. */
  metadataOwner?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `metadataSchema` field. */
  metadataSchema?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `metadataType` field. */
  metadataType?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `statisticsAllJson` field. */
  statisticsAllJson?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `statisticsByteCount` field. */
  statisticsByteCount?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `statisticsHasStats` field. */
  statisticsHasStats?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `statisticsLastModified` field. */
  statisticsLastModified?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `statisticsRowCount` field. */
  statisticsRowCount?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `uniqueId` field. */
  uniqueId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedDttm` field. */
  updatedDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `workspaceId` field. */
  workspaceId?: InputMaybe<Scalars['Int']>;
};

export type DbtArtifactCatalogueObjectDistinctCountAggregates = {
  __typename?: 'DbtArtifactCatalogueObjectDistinctCountAggregates';
  /** Distinct count of createdDttm across the matching connection */
  createdDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of metadataComment across the matching connection */
  metadataComment?: Maybe<Scalars['BigInt']>;
  /** Distinct count of metadataDatabase across the matching connection */
  metadataDatabase?: Maybe<Scalars['BigInt']>;
  /** Distinct count of metadataDbtVersion across the matching connection */
  metadataDbtVersion?: Maybe<Scalars['BigInt']>;
  /** Distinct count of metadataDwhType across the matching connection */
  metadataDwhType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of metadataGeneratedAt across the matching connection */
  metadataGeneratedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of metadataInvocationId across the matching connection */
  metadataInvocationId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of metadataName across the matching connection */
  metadataName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of metadataOwner across the matching connection */
  metadataOwner?: Maybe<Scalars['BigInt']>;
  /** Distinct count of metadataSchema across the matching connection */
  metadataSchema?: Maybe<Scalars['BigInt']>;
  /** Distinct count of metadataType across the matching connection */
  metadataType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of statisticsAllJson across the matching connection */
  statisticsAllJson?: Maybe<Scalars['BigInt']>;
  /** Distinct count of statisticsByteCount across the matching connection */
  statisticsByteCount?: Maybe<Scalars['BigInt']>;
  /** Distinct count of statisticsHasStats across the matching connection */
  statisticsHasStats?: Maybe<Scalars['BigInt']>;
  /** Distinct count of statisticsLastModified across the matching connection */
  statisticsLastModified?: Maybe<Scalars['BigInt']>;
  /** Distinct count of statisticsRowCount across the matching connection */
  statisticsRowCount?: Maybe<Scalars['BigInt']>;
  /** Distinct count of uniqueId across the matching connection */
  uniqueId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedDttm across the matching connection */
  updatedDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of userId across the matching connection */
  userId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigInt']>;
};

export type DbtArtifactCatalogueObjectMaxAggregates = {
  __typename?: 'DbtArtifactCatalogueObjectMaxAggregates';
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Maximum of statisticsByteCount across the matching connection */
  statisticsByteCount?: Maybe<Scalars['BigInt']>;
  /** Maximum of statisticsRowCount across the matching connection */
  statisticsRowCount?: Maybe<Scalars['BigInt']>;
  /** Maximum of userId across the matching connection */
  userId?: Maybe<Scalars['Int']>;
  /** Maximum of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['Int']>;
};

export type DbtArtifactCatalogueObjectMinAggregates = {
  __typename?: 'DbtArtifactCatalogueObjectMinAggregates';
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Minimum of statisticsByteCount across the matching connection */
  statisticsByteCount?: Maybe<Scalars['BigInt']>;
  /** Minimum of statisticsRowCount across the matching connection */
  statisticsRowCount?: Maybe<Scalars['BigInt']>;
  /** Minimum of userId across the matching connection */
  userId?: Maybe<Scalars['Int']>;
  /** Minimum of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['Int']>;
};

export type DbtArtifactCatalogueObjectStddevPopulationAggregates = {
  __typename?: 'DbtArtifactCatalogueObjectStddevPopulationAggregates';
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of statisticsByteCount across the matching connection */
  statisticsByteCount?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of statisticsRowCount across the matching connection */
  statisticsRowCount?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of userId across the matching connection */
  userId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type DbtArtifactCatalogueObjectStddevSampleAggregates = {
  __typename?: 'DbtArtifactCatalogueObjectStddevSampleAggregates';
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of statisticsByteCount across the matching connection */
  statisticsByteCount?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of statisticsRowCount across the matching connection */
  statisticsRowCount?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of userId across the matching connection */
  userId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type DbtArtifactCatalogueObjectSumAggregates = {
  __typename?: 'DbtArtifactCatalogueObjectSumAggregates';
  /** Sum of id across the matching connection */
  id: Scalars['BigFloat'];
  /** Sum of statisticsByteCount across the matching connection */
  statisticsByteCount: Scalars['BigFloat'];
  /** Sum of statisticsRowCount across the matching connection */
  statisticsRowCount: Scalars['BigFloat'];
  /** Sum of userId across the matching connection */
  userId: Scalars['BigInt'];
  /** Sum of workspaceId across the matching connection */
  workspaceId: Scalars['BigInt'];
};

export type DbtArtifactCatalogueObjectVariancePopulationAggregates = {
  __typename?: 'DbtArtifactCatalogueObjectVariancePopulationAggregates';
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of statisticsByteCount across the matching connection */
  statisticsByteCount?: Maybe<Scalars['BigFloat']>;
  /** Population variance of statisticsRowCount across the matching connection */
  statisticsRowCount?: Maybe<Scalars['BigFloat']>;
  /** Population variance of userId across the matching connection */
  userId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type DbtArtifactCatalogueObjectVarianceSampleAggregates = {
  __typename?: 'DbtArtifactCatalogueObjectVarianceSampleAggregates';
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of statisticsByteCount across the matching connection */
  statisticsByteCount?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of statisticsRowCount across the matching connection */
  statisticsRowCount?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of userId across the matching connection */
  userId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `DbtArtifactCatalogueObject` values. */
export type DbtArtifactCatalogueObjectsConnection = {
  __typename?: 'DbtArtifactCatalogueObjectsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<DbtArtifactCatalogueObjectAggregates>;
  /** A list of edges which contains the `DbtArtifactCatalogueObject` and cursor to aid in pagination. */
  edges: Array<DbtArtifactCatalogueObjectsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<DbtArtifactCatalogueObjectAggregates>>;
  /** A list of `DbtArtifactCatalogueObject` objects. */
  nodes: Array<DbtArtifactCatalogueObject>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DbtArtifactCatalogueObject` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `DbtArtifactCatalogueObject` values. */
export type DbtArtifactCatalogueObjectsConnectionGroupedAggregatesArgs = {
  groupBy: Array<DbtArtifactCatalogueObjectsGroupBy>;
  having?: InputMaybe<DbtArtifactCatalogueObjectsHavingInput>;
};

/** A `DbtArtifactCatalogueObject` edge in the connection. */
export type DbtArtifactCatalogueObjectsEdge = {
  __typename?: 'DbtArtifactCatalogueObjectsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `DbtArtifactCatalogueObject` at the end of the edge. */
  node: DbtArtifactCatalogueObject;
};

/** Grouping methods for `DbtArtifactCatalogueObject` for usage during aggregation. */
export enum DbtArtifactCatalogueObjectsGroupBy {
  CreatedDttm = 'CREATED_DTTM',
  CreatedDttmTruncatedToDay = 'CREATED_DTTM_TRUNCATED_TO_DAY',
  CreatedDttmTruncatedToHour = 'CREATED_DTTM_TRUNCATED_TO_HOUR',
  MetadataComment = 'METADATA_COMMENT',
  MetadataDatabase = 'METADATA_DATABASE',
  MetadataDbtVersion = 'METADATA_DBT_VERSION',
  MetadataDwhType = 'METADATA_DWH_TYPE',
  MetadataGeneratedAt = 'METADATA_GENERATED_AT',
  MetadataGeneratedAtTruncatedToDay = 'METADATA_GENERATED_AT_TRUNCATED_TO_DAY',
  MetadataGeneratedAtTruncatedToHour = 'METADATA_GENERATED_AT_TRUNCATED_TO_HOUR',
  MetadataInvocationId = 'METADATA_INVOCATION_ID',
  MetadataName = 'METADATA_NAME',
  MetadataOwner = 'METADATA_OWNER',
  MetadataSchema = 'METADATA_SCHEMA',
  MetadataType = 'METADATA_TYPE',
  StatisticsAllJson = 'STATISTICS_ALL_JSON',
  StatisticsByteCount = 'STATISTICS_BYTE_COUNT',
  StatisticsHasStats = 'STATISTICS_HAS_STATS',
  StatisticsLastModified = 'STATISTICS_LAST_MODIFIED',
  StatisticsRowCount = 'STATISTICS_ROW_COUNT',
  UniqueId = 'UNIQUE_ID',
  UpdatedDttm = 'UPDATED_DTTM',
  UpdatedDttmTruncatedToDay = 'UPDATED_DTTM_TRUNCATED_TO_DAY',
  UpdatedDttmTruncatedToHour = 'UPDATED_DTTM_TRUNCATED_TO_HOUR',
  UserId = 'USER_ID',
  WorkspaceId = 'WORKSPACE_ID'
}

export type DbtArtifactCatalogueObjectsHavingAverageInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  metadataGeneratedAt?: InputMaybe<HavingDatetimeFilter>;
  statisticsByteCount?: InputMaybe<HavingBigintFilter>;
  statisticsRowCount?: InputMaybe<HavingBigintFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  userId?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type DbtArtifactCatalogueObjectsHavingDistinctCountInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  metadataGeneratedAt?: InputMaybe<HavingDatetimeFilter>;
  statisticsByteCount?: InputMaybe<HavingBigintFilter>;
  statisticsRowCount?: InputMaybe<HavingBigintFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  userId?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `DbtArtifactCatalogueObject` aggregates. */
export type DbtArtifactCatalogueObjectsHavingInput = {
  AND?: InputMaybe<Array<DbtArtifactCatalogueObjectsHavingInput>>;
  OR?: InputMaybe<Array<DbtArtifactCatalogueObjectsHavingInput>>;
  average?: InputMaybe<DbtArtifactCatalogueObjectsHavingAverageInput>;
  distinctCount?: InputMaybe<DbtArtifactCatalogueObjectsHavingDistinctCountInput>;
  max?: InputMaybe<DbtArtifactCatalogueObjectsHavingMaxInput>;
  min?: InputMaybe<DbtArtifactCatalogueObjectsHavingMinInput>;
  stddevPopulation?: InputMaybe<DbtArtifactCatalogueObjectsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<DbtArtifactCatalogueObjectsHavingStddevSampleInput>;
  sum?: InputMaybe<DbtArtifactCatalogueObjectsHavingSumInput>;
  variancePopulation?: InputMaybe<DbtArtifactCatalogueObjectsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<DbtArtifactCatalogueObjectsHavingVarianceSampleInput>;
};

export type DbtArtifactCatalogueObjectsHavingMaxInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  metadataGeneratedAt?: InputMaybe<HavingDatetimeFilter>;
  statisticsByteCount?: InputMaybe<HavingBigintFilter>;
  statisticsRowCount?: InputMaybe<HavingBigintFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  userId?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type DbtArtifactCatalogueObjectsHavingMinInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  metadataGeneratedAt?: InputMaybe<HavingDatetimeFilter>;
  statisticsByteCount?: InputMaybe<HavingBigintFilter>;
  statisticsRowCount?: InputMaybe<HavingBigintFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  userId?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type DbtArtifactCatalogueObjectsHavingStddevPopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  metadataGeneratedAt?: InputMaybe<HavingDatetimeFilter>;
  statisticsByteCount?: InputMaybe<HavingBigintFilter>;
  statisticsRowCount?: InputMaybe<HavingBigintFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  userId?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type DbtArtifactCatalogueObjectsHavingStddevSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  metadataGeneratedAt?: InputMaybe<HavingDatetimeFilter>;
  statisticsByteCount?: InputMaybe<HavingBigintFilter>;
  statisticsRowCount?: InputMaybe<HavingBigintFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  userId?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type DbtArtifactCatalogueObjectsHavingSumInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  metadataGeneratedAt?: InputMaybe<HavingDatetimeFilter>;
  statisticsByteCount?: InputMaybe<HavingBigintFilter>;
  statisticsRowCount?: InputMaybe<HavingBigintFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  userId?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type DbtArtifactCatalogueObjectsHavingVariancePopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  metadataGeneratedAt?: InputMaybe<HavingDatetimeFilter>;
  statisticsByteCount?: InputMaybe<HavingBigintFilter>;
  statisticsRowCount?: InputMaybe<HavingBigintFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  userId?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type DbtArtifactCatalogueObjectsHavingVarianceSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  metadataGeneratedAt?: InputMaybe<HavingDatetimeFilter>;
  statisticsByteCount?: InputMaybe<HavingBigintFilter>;
  statisticsRowCount?: InputMaybe<HavingBigintFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  userId?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

/** Methods to use when ordering `DbtArtifactCatalogueObject`. */
export enum DbtArtifactCatalogueObjectsOrderBy {
  CreatedDttmAsc = 'CREATED_DTTM_ASC',
  CreatedDttmDesc = 'CREATED_DTTM_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  MetadataCommentAsc = 'METADATA_COMMENT_ASC',
  MetadataCommentDesc = 'METADATA_COMMENT_DESC',
  MetadataDatabaseAsc = 'METADATA_DATABASE_ASC',
  MetadataDatabaseDesc = 'METADATA_DATABASE_DESC',
  MetadataDbtVersionAsc = 'METADATA_DBT_VERSION_ASC',
  MetadataDbtVersionDesc = 'METADATA_DBT_VERSION_DESC',
  MetadataDwhTypeAsc = 'METADATA_DWH_TYPE_ASC',
  MetadataDwhTypeDesc = 'METADATA_DWH_TYPE_DESC',
  MetadataGeneratedAtAsc = 'METADATA_GENERATED_AT_ASC',
  MetadataGeneratedAtDesc = 'METADATA_GENERATED_AT_DESC',
  MetadataInvocationIdAsc = 'METADATA_INVOCATION_ID_ASC',
  MetadataInvocationIdDesc = 'METADATA_INVOCATION_ID_DESC',
  MetadataNameAsc = 'METADATA_NAME_ASC',
  MetadataNameDesc = 'METADATA_NAME_DESC',
  MetadataOwnerAsc = 'METADATA_OWNER_ASC',
  MetadataOwnerDesc = 'METADATA_OWNER_DESC',
  MetadataSchemaAsc = 'METADATA_SCHEMA_ASC',
  MetadataSchemaDesc = 'METADATA_SCHEMA_DESC',
  MetadataTypeAsc = 'METADATA_TYPE_ASC',
  MetadataTypeDesc = 'METADATA_TYPE_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  StatisticsAllJsonAsc = 'STATISTICS_ALL_JSON_ASC',
  StatisticsAllJsonDesc = 'STATISTICS_ALL_JSON_DESC',
  StatisticsByteCountAsc = 'STATISTICS_BYTE_COUNT_ASC',
  StatisticsByteCountDesc = 'STATISTICS_BYTE_COUNT_DESC',
  StatisticsHasStatsAsc = 'STATISTICS_HAS_STATS_ASC',
  StatisticsHasStatsDesc = 'STATISTICS_HAS_STATS_DESC',
  StatisticsLastModifiedAsc = 'STATISTICS_LAST_MODIFIED_ASC',
  StatisticsLastModifiedDesc = 'STATISTICS_LAST_MODIFIED_DESC',
  StatisticsRowCountAsc = 'STATISTICS_ROW_COUNT_ASC',
  StatisticsRowCountDesc = 'STATISTICS_ROW_COUNT_DESC',
  UniqueIdAsc = 'UNIQUE_ID_ASC',
  UniqueIdDesc = 'UNIQUE_ID_DESC',
  UpdatedDttmAsc = 'UPDATED_DTTM_ASC',
  UpdatedDttmDesc = 'UPDATED_DTTM_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  WorkspaceIdAsc = 'WORKSPACE_ID_ASC',
  WorkspaceIdDesc = 'WORKSPACE_ID_DESC'
}

export type DbtArtifactError = Node & {
  __typename?: 'DbtArtifactError';
  createdDttm: Scalars['Datetime'];
  errorMessage?: Maybe<Scalars['String']>;
  errorMessageContext?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  updatedDttm: Scalars['Datetime'];
  userId: Scalars['Int'];
  workspaceId: Scalars['Int'];
};

export type DbtArtifactErrorAggregates = {
  __typename?: 'DbtArtifactErrorAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<DbtArtifactErrorAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<DbtArtifactErrorDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<DbtArtifactErrorMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<DbtArtifactErrorMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<DbtArtifactErrorStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<DbtArtifactErrorStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<DbtArtifactErrorSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<DbtArtifactErrorVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<DbtArtifactErrorVarianceSampleAggregates>;
};

export type DbtArtifactErrorAverageAggregates = {
  __typename?: 'DbtArtifactErrorAverageAggregates';
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of userId across the matching connection */
  userId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `DbtArtifactError` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type DbtArtifactErrorCondition = {
  /** Checks for equality with the object’s `createdDttm` field. */
  createdDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `errorMessage` field. */
  errorMessage?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `errorMessageContext` field. */
  errorMessageContext?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `updatedDttm` field. */
  updatedDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `workspaceId` field. */
  workspaceId?: InputMaybe<Scalars['Int']>;
};

export type DbtArtifactErrorDistinctCountAggregates = {
  __typename?: 'DbtArtifactErrorDistinctCountAggregates';
  /** Distinct count of createdDttm across the matching connection */
  createdDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of errorMessage across the matching connection */
  errorMessage?: Maybe<Scalars['BigInt']>;
  /** Distinct count of errorMessageContext across the matching connection */
  errorMessageContext?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedDttm across the matching connection */
  updatedDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of userId across the matching connection */
  userId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigInt']>;
};

export type DbtArtifactErrorMaxAggregates = {
  __typename?: 'DbtArtifactErrorMaxAggregates';
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of userId across the matching connection */
  userId?: Maybe<Scalars['Int']>;
  /** Maximum of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['Int']>;
};

export type DbtArtifactErrorMinAggregates = {
  __typename?: 'DbtArtifactErrorMinAggregates';
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of userId across the matching connection */
  userId?: Maybe<Scalars['Int']>;
  /** Minimum of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['Int']>;
};

export type DbtArtifactErrorStddevPopulationAggregates = {
  __typename?: 'DbtArtifactErrorStddevPopulationAggregates';
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of userId across the matching connection */
  userId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type DbtArtifactErrorStddevSampleAggregates = {
  __typename?: 'DbtArtifactErrorStddevSampleAggregates';
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of userId across the matching connection */
  userId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type DbtArtifactErrorSumAggregates = {
  __typename?: 'DbtArtifactErrorSumAggregates';
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of userId across the matching connection */
  userId: Scalars['BigInt'];
  /** Sum of workspaceId across the matching connection */
  workspaceId: Scalars['BigInt'];
};

export type DbtArtifactErrorVariancePopulationAggregates = {
  __typename?: 'DbtArtifactErrorVariancePopulationAggregates';
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of userId across the matching connection */
  userId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type DbtArtifactErrorVarianceSampleAggregates = {
  __typename?: 'DbtArtifactErrorVarianceSampleAggregates';
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of userId across the matching connection */
  userId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `DbtArtifactError` values. */
export type DbtArtifactErrorsConnection = {
  __typename?: 'DbtArtifactErrorsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<DbtArtifactErrorAggregates>;
  /** A list of edges which contains the `DbtArtifactError` and cursor to aid in pagination. */
  edges: Array<DbtArtifactErrorsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<DbtArtifactErrorAggregates>>;
  /** A list of `DbtArtifactError` objects. */
  nodes: Array<DbtArtifactError>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DbtArtifactError` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `DbtArtifactError` values. */
export type DbtArtifactErrorsConnectionGroupedAggregatesArgs = {
  groupBy: Array<DbtArtifactErrorsGroupBy>;
  having?: InputMaybe<DbtArtifactErrorsHavingInput>;
};

/** A `DbtArtifactError` edge in the connection. */
export type DbtArtifactErrorsEdge = {
  __typename?: 'DbtArtifactErrorsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `DbtArtifactError` at the end of the edge. */
  node: DbtArtifactError;
};

/** Grouping methods for `DbtArtifactError` for usage during aggregation. */
export enum DbtArtifactErrorsGroupBy {
  CreatedDttm = 'CREATED_DTTM',
  CreatedDttmTruncatedToDay = 'CREATED_DTTM_TRUNCATED_TO_DAY',
  CreatedDttmTruncatedToHour = 'CREATED_DTTM_TRUNCATED_TO_HOUR',
  ErrorMessage = 'ERROR_MESSAGE',
  ErrorMessageContext = 'ERROR_MESSAGE_CONTEXT',
  UpdatedDttm = 'UPDATED_DTTM',
  UpdatedDttmTruncatedToDay = 'UPDATED_DTTM_TRUNCATED_TO_DAY',
  UpdatedDttmTruncatedToHour = 'UPDATED_DTTM_TRUNCATED_TO_HOUR',
  UserId = 'USER_ID',
  WorkspaceId = 'WORKSPACE_ID'
}

export type DbtArtifactErrorsHavingAverageInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  userId?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type DbtArtifactErrorsHavingDistinctCountInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  userId?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `DbtArtifactError` aggregates. */
export type DbtArtifactErrorsHavingInput = {
  AND?: InputMaybe<Array<DbtArtifactErrorsHavingInput>>;
  OR?: InputMaybe<Array<DbtArtifactErrorsHavingInput>>;
  average?: InputMaybe<DbtArtifactErrorsHavingAverageInput>;
  distinctCount?: InputMaybe<DbtArtifactErrorsHavingDistinctCountInput>;
  max?: InputMaybe<DbtArtifactErrorsHavingMaxInput>;
  min?: InputMaybe<DbtArtifactErrorsHavingMinInput>;
  stddevPopulation?: InputMaybe<DbtArtifactErrorsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<DbtArtifactErrorsHavingStddevSampleInput>;
  sum?: InputMaybe<DbtArtifactErrorsHavingSumInput>;
  variancePopulation?: InputMaybe<DbtArtifactErrorsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<DbtArtifactErrorsHavingVarianceSampleInput>;
};

export type DbtArtifactErrorsHavingMaxInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  userId?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type DbtArtifactErrorsHavingMinInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  userId?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type DbtArtifactErrorsHavingStddevPopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  userId?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type DbtArtifactErrorsHavingStddevSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  userId?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type DbtArtifactErrorsHavingSumInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  userId?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type DbtArtifactErrorsHavingVariancePopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  userId?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type DbtArtifactErrorsHavingVarianceSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  userId?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

/** Methods to use when ordering `DbtArtifactError`. */
export enum DbtArtifactErrorsOrderBy {
  CreatedDttmAsc = 'CREATED_DTTM_ASC',
  CreatedDttmDesc = 'CREATED_DTTM_DESC',
  ErrorMessageAsc = 'ERROR_MESSAGE_ASC',
  ErrorMessageContextAsc = 'ERROR_MESSAGE_CONTEXT_ASC',
  ErrorMessageContextDesc = 'ERROR_MESSAGE_CONTEXT_DESC',
  ErrorMessageDesc = 'ERROR_MESSAGE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedDttmAsc = 'UPDATED_DTTM_ASC',
  UpdatedDttmDesc = 'UPDATED_DTTM_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  WorkspaceIdAsc = 'WORKSPACE_ID_ASC',
  WorkspaceIdDesc = 'WORKSPACE_ID_DESC'
}

export type DbtNode = Node & {
  __typename?: 'DbtNode';
  aliases: Scalars['String'];
  attributes: Scalars['String'];
  dbtType: Scalars['String'];
  /** Reads a single `FileContent` that is related to this `DbtNode`. */
  fileContent?: Maybe<FileContent>;
  fileContentId: Scalars['BigInt'];
  id: Scalars['BigInt'];
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  references: Scalars['String'];
  tags: Scalars['String'];
};

export type DbtNodeAggregates = {
  __typename?: 'DbtNodeAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<DbtNodeAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<DbtNodeDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<DbtNodeMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<DbtNodeMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<DbtNodeStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<DbtNodeStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<DbtNodeSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<DbtNodeVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<DbtNodeVarianceSampleAggregates>;
};

export type DbtNodeAverageAggregates = {
  __typename?: 'DbtNodeAverageAggregates';
  /** Mean average of fileContentId across the matching connection */
  fileContentId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A condition to be used against `DbtNode` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type DbtNodeCondition = {
  /** Checks for equality with the object’s `aliases` field. */
  aliases?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `attributes` field. */
  attributes?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `dbtType` field. */
  dbtType?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `fileContentId` field. */
  fileContentId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `references` field. */
  references?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `tags` field. */
  tags?: InputMaybe<Scalars['String']>;
};

export type DbtNodeDistinctCountAggregates = {
  __typename?: 'DbtNodeDistinctCountAggregates';
  /** Distinct count of aliases across the matching connection */
  aliases?: Maybe<Scalars['BigInt']>;
  /** Distinct count of attributes across the matching connection */
  attributes?: Maybe<Scalars['BigInt']>;
  /** Distinct count of dbtType across the matching connection */
  dbtType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of fileContentId across the matching connection */
  fileContentId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of references across the matching connection */
  references?: Maybe<Scalars['BigInt']>;
  /** Distinct count of tags across the matching connection */
  tags?: Maybe<Scalars['BigInt']>;
};

export type DbtNodeMaxAggregates = {
  __typename?: 'DbtNodeMaxAggregates';
  /** Maximum of fileContentId across the matching connection */
  fileContentId?: Maybe<Scalars['BigInt']>;
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
};

export type DbtNodeMinAggregates = {
  __typename?: 'DbtNodeMinAggregates';
  /** Minimum of fileContentId across the matching connection */
  fileContentId?: Maybe<Scalars['BigInt']>;
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
};

export type DbtNodeStddevPopulationAggregates = {
  __typename?: 'DbtNodeStddevPopulationAggregates';
  /** Population standard deviation of fileContentId across the matching connection */
  fileContentId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type DbtNodeStddevSampleAggregates = {
  __typename?: 'DbtNodeStddevSampleAggregates';
  /** Sample standard deviation of fileContentId across the matching connection */
  fileContentId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type DbtNodeSumAggregates = {
  __typename?: 'DbtNodeSumAggregates';
  /** Sum of fileContentId across the matching connection */
  fileContentId: Scalars['BigFloat'];
  /** Sum of id across the matching connection */
  id: Scalars['BigFloat'];
};

export type DbtNodeVariancePopulationAggregates = {
  __typename?: 'DbtNodeVariancePopulationAggregates';
  /** Population variance of fileContentId across the matching connection */
  fileContentId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type DbtNodeVarianceSampleAggregates = {
  __typename?: 'DbtNodeVarianceSampleAggregates';
  /** Sample variance of fileContentId across the matching connection */
  fileContentId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `DbtNode` values. */
export type DbtNodesConnection = {
  __typename?: 'DbtNodesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<DbtNodeAggregates>;
  /** A list of edges which contains the `DbtNode` and cursor to aid in pagination. */
  edges: Array<DbtNodesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<DbtNodeAggregates>>;
  /** A list of `DbtNode` objects. */
  nodes: Array<DbtNode>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DbtNode` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `DbtNode` values. */
export type DbtNodesConnectionGroupedAggregatesArgs = {
  groupBy: Array<DbtNodesGroupBy>;
  having?: InputMaybe<DbtNodesHavingInput>;
};

/** A `DbtNode` edge in the connection. */
export type DbtNodesEdge = {
  __typename?: 'DbtNodesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `DbtNode` at the end of the edge. */
  node: DbtNode;
};

/** Grouping methods for `DbtNode` for usage during aggregation. */
export enum DbtNodesGroupBy {
  Aliases = 'ALIASES',
  Attributes = 'ATTRIBUTES',
  DbtType = 'DBT_TYPE',
  FileContentId = 'FILE_CONTENT_ID',
  Name = 'NAME',
  References = 'REFERENCES',
  Tags = 'TAGS'
}

export type DbtNodesHavingAverageInput = {
  fileContentId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type DbtNodesHavingDistinctCountInput = {
  fileContentId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

/** Conditions for `DbtNode` aggregates. */
export type DbtNodesHavingInput = {
  AND?: InputMaybe<Array<DbtNodesHavingInput>>;
  OR?: InputMaybe<Array<DbtNodesHavingInput>>;
  average?: InputMaybe<DbtNodesHavingAverageInput>;
  distinctCount?: InputMaybe<DbtNodesHavingDistinctCountInput>;
  max?: InputMaybe<DbtNodesHavingMaxInput>;
  min?: InputMaybe<DbtNodesHavingMinInput>;
  stddevPopulation?: InputMaybe<DbtNodesHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<DbtNodesHavingStddevSampleInput>;
  sum?: InputMaybe<DbtNodesHavingSumInput>;
  variancePopulation?: InputMaybe<DbtNodesHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<DbtNodesHavingVarianceSampleInput>;
};

export type DbtNodesHavingMaxInput = {
  fileContentId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type DbtNodesHavingMinInput = {
  fileContentId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type DbtNodesHavingStddevPopulationInput = {
  fileContentId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type DbtNodesHavingStddevSampleInput = {
  fileContentId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type DbtNodesHavingSumInput = {
  fileContentId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type DbtNodesHavingVariancePopulationInput = {
  fileContentId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type DbtNodesHavingVarianceSampleInput = {
  fileContentId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

/** Methods to use when ordering `DbtNode`. */
export enum DbtNodesOrderBy {
  AliasesAsc = 'ALIASES_ASC',
  AliasesDesc = 'ALIASES_DESC',
  AttributesAsc = 'ATTRIBUTES_ASC',
  AttributesDesc = 'ATTRIBUTES_DESC',
  DbtTypeAsc = 'DBT_TYPE_ASC',
  DbtTypeDesc = 'DBT_TYPE_DESC',
  FileContentIdAsc = 'FILE_CONTENT_ID_ASC',
  FileContentIdDesc = 'FILE_CONTENT_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ReferencesAsc = 'REFERENCES_ASC',
  ReferencesDesc = 'REFERENCES_DESC',
  TagsAsc = 'TAGS_ASC',
  TagsDesc = 'TAGS_DESC'
}

export type File = Node & {
  __typename?: 'File';
  /** Reads and enables pagination through a set of `FilesRelation`. */
  childFilesRelations: FilesRelationsConnection;
  /** Reads a single `CommitHash` that is related to this `File`. */
  commitHash?: Maybe<CommitHash>;
  commitHashId: Scalars['BigInt'];
  /** Reads a single `FileContent` that is related to this `File`. */
  fileContent?: Maybe<FileContent>;
  fileContentId: Scalars['BigInt'];
  /** Reads and enables pagination through a set of `FileLine`. */
  fileLinesByLastActiveId: FileLinesConnection;
  id: Scalars['BigInt'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `FilesRelation`. */
  parentFilesRelations: FilesRelationsConnection;
  path: Scalars['String'];
};


export type FileChildFilesRelationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FilesRelationCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FilesRelationsOrderBy>>;
};


export type FileFileLinesByLastActiveIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FileLineCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FileLinesOrderBy>>;
};


export type FileParentFilesRelationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FilesRelationCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FilesRelationsOrderBy>>;
};

export type FileAggregates = {
  __typename?: 'FileAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<FileAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<FileDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<FileMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<FileMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<FileStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<FileStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<FileSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<FileVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<FileVarianceSampleAggregates>;
};

export type FileAverageAggregates = {
  __typename?: 'FileAverageAggregates';
  /** Mean average of commitHashId across the matching connection */
  commitHashId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of fileContentId across the matching connection */
  fileContentId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A condition to be used against `File` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type FileCondition = {
  /** Checks for equality with the object’s `commitHashId` field. */
  commitHashId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `fileContentId` field. */
  fileContentId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `path` field. */
  path?: InputMaybe<Scalars['String']>;
};

export type FileContent = Node & {
  __typename?: 'FileContent';
  contentHash: Scalars['String'];
  contentSize: Scalars['Int'];
  /** Reads and enables pagination through a set of `DbtNode`. */
  dbtNodes: DbtNodesConnection;
  /** Reads and enables pagination through a set of `FileLine`. */
  fileLines: FileLinesConnection;
  /** Reads and enables pagination through a set of `File`. */
  files: FilesConnection;
  id: Scalars['BigInt'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};


export type FileContentDbtNodesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<DbtNodeCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbtNodesOrderBy>>;
};


export type FileContentFileLinesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FileLineCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FileLinesOrderBy>>;
};


export type FileContentFilesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FileCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};

export type FileContentAggregates = {
  __typename?: 'FileContentAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<FileContentAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<FileContentDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<FileContentMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<FileContentMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<FileContentStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<FileContentStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<FileContentSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<FileContentVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<FileContentVarianceSampleAggregates>;
};

export type FileContentAverageAggregates = {
  __typename?: 'FileContentAverageAggregates';
  /** Mean average of contentSize across the matching connection */
  contentSize?: Maybe<Scalars['BigFloat']>;
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `FileContent` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type FileContentCondition = {
  /** Checks for equality with the object’s `contentHash` field. */
  contentHash?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `contentSize` field. */
  contentSize?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']>;
};

export type FileContentDistinctCountAggregates = {
  __typename?: 'FileContentDistinctCountAggregates';
  /** Distinct count of contentHash across the matching connection */
  contentHash?: Maybe<Scalars['BigInt']>;
  /** Distinct count of contentSize across the matching connection */
  contentSize?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
};

/** Grouping methods for `FileContent` for usage during aggregation. */
export enum FileContentGroupBy {
  ContentHash = 'CONTENT_HASH',
  ContentSize = 'CONTENT_SIZE'
}

export type FileContentHavingAverageInput = {
  contentSize?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type FileContentHavingDistinctCountInput = {
  contentSize?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

/** Conditions for `FileContent` aggregates. */
export type FileContentHavingInput = {
  AND?: InputMaybe<Array<FileContentHavingInput>>;
  OR?: InputMaybe<Array<FileContentHavingInput>>;
  average?: InputMaybe<FileContentHavingAverageInput>;
  distinctCount?: InputMaybe<FileContentHavingDistinctCountInput>;
  max?: InputMaybe<FileContentHavingMaxInput>;
  min?: InputMaybe<FileContentHavingMinInput>;
  stddevPopulation?: InputMaybe<FileContentHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<FileContentHavingStddevSampleInput>;
  sum?: InputMaybe<FileContentHavingSumInput>;
  variancePopulation?: InputMaybe<FileContentHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<FileContentHavingVarianceSampleInput>;
};

export type FileContentHavingMaxInput = {
  contentSize?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type FileContentHavingMinInput = {
  contentSize?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type FileContentHavingStddevPopulationInput = {
  contentSize?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type FileContentHavingStddevSampleInput = {
  contentSize?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type FileContentHavingSumInput = {
  contentSize?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type FileContentHavingVariancePopulationInput = {
  contentSize?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type FileContentHavingVarianceSampleInput = {
  contentSize?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type FileContentMaxAggregates = {
  __typename?: 'FileContentMaxAggregates';
  /** Maximum of contentSize across the matching connection */
  contentSize?: Maybe<Scalars['Int']>;
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
};

export type FileContentMinAggregates = {
  __typename?: 'FileContentMinAggregates';
  /** Minimum of contentSize across the matching connection */
  contentSize?: Maybe<Scalars['Int']>;
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
};

export type FileContentStddevPopulationAggregates = {
  __typename?: 'FileContentStddevPopulationAggregates';
  /** Population standard deviation of contentSize across the matching connection */
  contentSize?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type FileContentStddevSampleAggregates = {
  __typename?: 'FileContentStddevSampleAggregates';
  /** Sample standard deviation of contentSize across the matching connection */
  contentSize?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type FileContentSumAggregates = {
  __typename?: 'FileContentSumAggregates';
  /** Sum of contentSize across the matching connection */
  contentSize: Scalars['BigInt'];
  /** Sum of id across the matching connection */
  id: Scalars['BigFloat'];
};

export type FileContentVariancePopulationAggregates = {
  __typename?: 'FileContentVariancePopulationAggregates';
  /** Population variance of contentSize across the matching connection */
  contentSize?: Maybe<Scalars['BigFloat']>;
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type FileContentVarianceSampleAggregates = {
  __typename?: 'FileContentVarianceSampleAggregates';
  /** Sample variance of contentSize across the matching connection */
  contentSize?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `FileContent` values. */
export type FileContentsConnection = {
  __typename?: 'FileContentsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<FileContentAggregates>;
  /** A list of edges which contains the `FileContent` and cursor to aid in pagination. */
  edges: Array<FileContentsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<FileContentAggregates>>;
  /** A list of `FileContent` objects. */
  nodes: Array<FileContent>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FileContent` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `FileContent` values. */
export type FileContentsConnectionGroupedAggregatesArgs = {
  groupBy: Array<FileContentGroupBy>;
  having?: InputMaybe<FileContentHavingInput>;
};

/** A `FileContent` edge in the connection. */
export type FileContentsEdge = {
  __typename?: 'FileContentsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `FileContent` at the end of the edge. */
  node: FileContent;
};

/** Methods to use when ordering `FileContent`. */
export enum FileContentsOrderBy {
  ContentHashAsc = 'CONTENT_HASH_ASC',
  ContentHashDesc = 'CONTENT_HASH_DESC',
  ContentSizeAsc = 'CONTENT_SIZE_ASC',
  ContentSizeDesc = 'CONTENT_SIZE_DESC',
  DbtNodesAverageAliasesAsc = 'DBT_NODES_AVERAGE_ALIASES_ASC',
  DbtNodesAverageAliasesDesc = 'DBT_NODES_AVERAGE_ALIASES_DESC',
  DbtNodesAverageAttributesAsc = 'DBT_NODES_AVERAGE_ATTRIBUTES_ASC',
  DbtNodesAverageAttributesDesc = 'DBT_NODES_AVERAGE_ATTRIBUTES_DESC',
  DbtNodesAverageDbtTypeAsc = 'DBT_NODES_AVERAGE_DBT_TYPE_ASC',
  DbtNodesAverageDbtTypeDesc = 'DBT_NODES_AVERAGE_DBT_TYPE_DESC',
  DbtNodesAverageFileContentIdAsc = 'DBT_NODES_AVERAGE_FILE_CONTENT_ID_ASC',
  DbtNodesAverageFileContentIdDesc = 'DBT_NODES_AVERAGE_FILE_CONTENT_ID_DESC',
  DbtNodesAverageIdAsc = 'DBT_NODES_AVERAGE_ID_ASC',
  DbtNodesAverageIdDesc = 'DBT_NODES_AVERAGE_ID_DESC',
  DbtNodesAverageNameAsc = 'DBT_NODES_AVERAGE_NAME_ASC',
  DbtNodesAverageNameDesc = 'DBT_NODES_AVERAGE_NAME_DESC',
  DbtNodesAverageReferencesAsc = 'DBT_NODES_AVERAGE_REFERENCES_ASC',
  DbtNodesAverageReferencesDesc = 'DBT_NODES_AVERAGE_REFERENCES_DESC',
  DbtNodesAverageTagsAsc = 'DBT_NODES_AVERAGE_TAGS_ASC',
  DbtNodesAverageTagsDesc = 'DBT_NODES_AVERAGE_TAGS_DESC',
  DbtNodesCountAsc = 'DBT_NODES_COUNT_ASC',
  DbtNodesCountDesc = 'DBT_NODES_COUNT_DESC',
  DbtNodesDistinctCountAliasesAsc = 'DBT_NODES_DISTINCT_COUNT_ALIASES_ASC',
  DbtNodesDistinctCountAliasesDesc = 'DBT_NODES_DISTINCT_COUNT_ALIASES_DESC',
  DbtNodesDistinctCountAttributesAsc = 'DBT_NODES_DISTINCT_COUNT_ATTRIBUTES_ASC',
  DbtNodesDistinctCountAttributesDesc = 'DBT_NODES_DISTINCT_COUNT_ATTRIBUTES_DESC',
  DbtNodesDistinctCountDbtTypeAsc = 'DBT_NODES_DISTINCT_COUNT_DBT_TYPE_ASC',
  DbtNodesDistinctCountDbtTypeDesc = 'DBT_NODES_DISTINCT_COUNT_DBT_TYPE_DESC',
  DbtNodesDistinctCountFileContentIdAsc = 'DBT_NODES_DISTINCT_COUNT_FILE_CONTENT_ID_ASC',
  DbtNodesDistinctCountFileContentIdDesc = 'DBT_NODES_DISTINCT_COUNT_FILE_CONTENT_ID_DESC',
  DbtNodesDistinctCountIdAsc = 'DBT_NODES_DISTINCT_COUNT_ID_ASC',
  DbtNodesDistinctCountIdDesc = 'DBT_NODES_DISTINCT_COUNT_ID_DESC',
  DbtNodesDistinctCountNameAsc = 'DBT_NODES_DISTINCT_COUNT_NAME_ASC',
  DbtNodesDistinctCountNameDesc = 'DBT_NODES_DISTINCT_COUNT_NAME_DESC',
  DbtNodesDistinctCountReferencesAsc = 'DBT_NODES_DISTINCT_COUNT_REFERENCES_ASC',
  DbtNodesDistinctCountReferencesDesc = 'DBT_NODES_DISTINCT_COUNT_REFERENCES_DESC',
  DbtNodesDistinctCountTagsAsc = 'DBT_NODES_DISTINCT_COUNT_TAGS_ASC',
  DbtNodesDistinctCountTagsDesc = 'DBT_NODES_DISTINCT_COUNT_TAGS_DESC',
  DbtNodesMaxAliasesAsc = 'DBT_NODES_MAX_ALIASES_ASC',
  DbtNodesMaxAliasesDesc = 'DBT_NODES_MAX_ALIASES_DESC',
  DbtNodesMaxAttributesAsc = 'DBT_NODES_MAX_ATTRIBUTES_ASC',
  DbtNodesMaxAttributesDesc = 'DBT_NODES_MAX_ATTRIBUTES_DESC',
  DbtNodesMaxDbtTypeAsc = 'DBT_NODES_MAX_DBT_TYPE_ASC',
  DbtNodesMaxDbtTypeDesc = 'DBT_NODES_MAX_DBT_TYPE_DESC',
  DbtNodesMaxFileContentIdAsc = 'DBT_NODES_MAX_FILE_CONTENT_ID_ASC',
  DbtNodesMaxFileContentIdDesc = 'DBT_NODES_MAX_FILE_CONTENT_ID_DESC',
  DbtNodesMaxIdAsc = 'DBT_NODES_MAX_ID_ASC',
  DbtNodesMaxIdDesc = 'DBT_NODES_MAX_ID_DESC',
  DbtNodesMaxNameAsc = 'DBT_NODES_MAX_NAME_ASC',
  DbtNodesMaxNameDesc = 'DBT_NODES_MAX_NAME_DESC',
  DbtNodesMaxReferencesAsc = 'DBT_NODES_MAX_REFERENCES_ASC',
  DbtNodesMaxReferencesDesc = 'DBT_NODES_MAX_REFERENCES_DESC',
  DbtNodesMaxTagsAsc = 'DBT_NODES_MAX_TAGS_ASC',
  DbtNodesMaxTagsDesc = 'DBT_NODES_MAX_TAGS_DESC',
  DbtNodesMinAliasesAsc = 'DBT_NODES_MIN_ALIASES_ASC',
  DbtNodesMinAliasesDesc = 'DBT_NODES_MIN_ALIASES_DESC',
  DbtNodesMinAttributesAsc = 'DBT_NODES_MIN_ATTRIBUTES_ASC',
  DbtNodesMinAttributesDesc = 'DBT_NODES_MIN_ATTRIBUTES_DESC',
  DbtNodesMinDbtTypeAsc = 'DBT_NODES_MIN_DBT_TYPE_ASC',
  DbtNodesMinDbtTypeDesc = 'DBT_NODES_MIN_DBT_TYPE_DESC',
  DbtNodesMinFileContentIdAsc = 'DBT_NODES_MIN_FILE_CONTENT_ID_ASC',
  DbtNodesMinFileContentIdDesc = 'DBT_NODES_MIN_FILE_CONTENT_ID_DESC',
  DbtNodesMinIdAsc = 'DBT_NODES_MIN_ID_ASC',
  DbtNodesMinIdDesc = 'DBT_NODES_MIN_ID_DESC',
  DbtNodesMinNameAsc = 'DBT_NODES_MIN_NAME_ASC',
  DbtNodesMinNameDesc = 'DBT_NODES_MIN_NAME_DESC',
  DbtNodesMinReferencesAsc = 'DBT_NODES_MIN_REFERENCES_ASC',
  DbtNodesMinReferencesDesc = 'DBT_NODES_MIN_REFERENCES_DESC',
  DbtNodesMinTagsAsc = 'DBT_NODES_MIN_TAGS_ASC',
  DbtNodesMinTagsDesc = 'DBT_NODES_MIN_TAGS_DESC',
  DbtNodesStddevPopulationAliasesAsc = 'DBT_NODES_STDDEV_POPULATION_ALIASES_ASC',
  DbtNodesStddevPopulationAliasesDesc = 'DBT_NODES_STDDEV_POPULATION_ALIASES_DESC',
  DbtNodesStddevPopulationAttributesAsc = 'DBT_NODES_STDDEV_POPULATION_ATTRIBUTES_ASC',
  DbtNodesStddevPopulationAttributesDesc = 'DBT_NODES_STDDEV_POPULATION_ATTRIBUTES_DESC',
  DbtNodesStddevPopulationDbtTypeAsc = 'DBT_NODES_STDDEV_POPULATION_DBT_TYPE_ASC',
  DbtNodesStddevPopulationDbtTypeDesc = 'DBT_NODES_STDDEV_POPULATION_DBT_TYPE_DESC',
  DbtNodesStddevPopulationFileContentIdAsc = 'DBT_NODES_STDDEV_POPULATION_FILE_CONTENT_ID_ASC',
  DbtNodesStddevPopulationFileContentIdDesc = 'DBT_NODES_STDDEV_POPULATION_FILE_CONTENT_ID_DESC',
  DbtNodesStddevPopulationIdAsc = 'DBT_NODES_STDDEV_POPULATION_ID_ASC',
  DbtNodesStddevPopulationIdDesc = 'DBT_NODES_STDDEV_POPULATION_ID_DESC',
  DbtNodesStddevPopulationNameAsc = 'DBT_NODES_STDDEV_POPULATION_NAME_ASC',
  DbtNodesStddevPopulationNameDesc = 'DBT_NODES_STDDEV_POPULATION_NAME_DESC',
  DbtNodesStddevPopulationReferencesAsc = 'DBT_NODES_STDDEV_POPULATION_REFERENCES_ASC',
  DbtNodesStddevPopulationReferencesDesc = 'DBT_NODES_STDDEV_POPULATION_REFERENCES_DESC',
  DbtNodesStddevPopulationTagsAsc = 'DBT_NODES_STDDEV_POPULATION_TAGS_ASC',
  DbtNodesStddevPopulationTagsDesc = 'DBT_NODES_STDDEV_POPULATION_TAGS_DESC',
  DbtNodesStddevSampleAliasesAsc = 'DBT_NODES_STDDEV_SAMPLE_ALIASES_ASC',
  DbtNodesStddevSampleAliasesDesc = 'DBT_NODES_STDDEV_SAMPLE_ALIASES_DESC',
  DbtNodesStddevSampleAttributesAsc = 'DBT_NODES_STDDEV_SAMPLE_ATTRIBUTES_ASC',
  DbtNodesStddevSampleAttributesDesc = 'DBT_NODES_STDDEV_SAMPLE_ATTRIBUTES_DESC',
  DbtNodesStddevSampleDbtTypeAsc = 'DBT_NODES_STDDEV_SAMPLE_DBT_TYPE_ASC',
  DbtNodesStddevSampleDbtTypeDesc = 'DBT_NODES_STDDEV_SAMPLE_DBT_TYPE_DESC',
  DbtNodesStddevSampleFileContentIdAsc = 'DBT_NODES_STDDEV_SAMPLE_FILE_CONTENT_ID_ASC',
  DbtNodesStddevSampleFileContentIdDesc = 'DBT_NODES_STDDEV_SAMPLE_FILE_CONTENT_ID_DESC',
  DbtNodesStddevSampleIdAsc = 'DBT_NODES_STDDEV_SAMPLE_ID_ASC',
  DbtNodesStddevSampleIdDesc = 'DBT_NODES_STDDEV_SAMPLE_ID_DESC',
  DbtNodesStddevSampleNameAsc = 'DBT_NODES_STDDEV_SAMPLE_NAME_ASC',
  DbtNodesStddevSampleNameDesc = 'DBT_NODES_STDDEV_SAMPLE_NAME_DESC',
  DbtNodesStddevSampleReferencesAsc = 'DBT_NODES_STDDEV_SAMPLE_REFERENCES_ASC',
  DbtNodesStddevSampleReferencesDesc = 'DBT_NODES_STDDEV_SAMPLE_REFERENCES_DESC',
  DbtNodesStddevSampleTagsAsc = 'DBT_NODES_STDDEV_SAMPLE_TAGS_ASC',
  DbtNodesStddevSampleTagsDesc = 'DBT_NODES_STDDEV_SAMPLE_TAGS_DESC',
  DbtNodesSumAliasesAsc = 'DBT_NODES_SUM_ALIASES_ASC',
  DbtNodesSumAliasesDesc = 'DBT_NODES_SUM_ALIASES_DESC',
  DbtNodesSumAttributesAsc = 'DBT_NODES_SUM_ATTRIBUTES_ASC',
  DbtNodesSumAttributesDesc = 'DBT_NODES_SUM_ATTRIBUTES_DESC',
  DbtNodesSumDbtTypeAsc = 'DBT_NODES_SUM_DBT_TYPE_ASC',
  DbtNodesSumDbtTypeDesc = 'DBT_NODES_SUM_DBT_TYPE_DESC',
  DbtNodesSumFileContentIdAsc = 'DBT_NODES_SUM_FILE_CONTENT_ID_ASC',
  DbtNodesSumFileContentIdDesc = 'DBT_NODES_SUM_FILE_CONTENT_ID_DESC',
  DbtNodesSumIdAsc = 'DBT_NODES_SUM_ID_ASC',
  DbtNodesSumIdDesc = 'DBT_NODES_SUM_ID_DESC',
  DbtNodesSumNameAsc = 'DBT_NODES_SUM_NAME_ASC',
  DbtNodesSumNameDesc = 'DBT_NODES_SUM_NAME_DESC',
  DbtNodesSumReferencesAsc = 'DBT_NODES_SUM_REFERENCES_ASC',
  DbtNodesSumReferencesDesc = 'DBT_NODES_SUM_REFERENCES_DESC',
  DbtNodesSumTagsAsc = 'DBT_NODES_SUM_TAGS_ASC',
  DbtNodesSumTagsDesc = 'DBT_NODES_SUM_TAGS_DESC',
  DbtNodesVariancePopulationAliasesAsc = 'DBT_NODES_VARIANCE_POPULATION_ALIASES_ASC',
  DbtNodesVariancePopulationAliasesDesc = 'DBT_NODES_VARIANCE_POPULATION_ALIASES_DESC',
  DbtNodesVariancePopulationAttributesAsc = 'DBT_NODES_VARIANCE_POPULATION_ATTRIBUTES_ASC',
  DbtNodesVariancePopulationAttributesDesc = 'DBT_NODES_VARIANCE_POPULATION_ATTRIBUTES_DESC',
  DbtNodesVariancePopulationDbtTypeAsc = 'DBT_NODES_VARIANCE_POPULATION_DBT_TYPE_ASC',
  DbtNodesVariancePopulationDbtTypeDesc = 'DBT_NODES_VARIANCE_POPULATION_DBT_TYPE_DESC',
  DbtNodesVariancePopulationFileContentIdAsc = 'DBT_NODES_VARIANCE_POPULATION_FILE_CONTENT_ID_ASC',
  DbtNodesVariancePopulationFileContentIdDesc = 'DBT_NODES_VARIANCE_POPULATION_FILE_CONTENT_ID_DESC',
  DbtNodesVariancePopulationIdAsc = 'DBT_NODES_VARIANCE_POPULATION_ID_ASC',
  DbtNodesVariancePopulationIdDesc = 'DBT_NODES_VARIANCE_POPULATION_ID_DESC',
  DbtNodesVariancePopulationNameAsc = 'DBT_NODES_VARIANCE_POPULATION_NAME_ASC',
  DbtNodesVariancePopulationNameDesc = 'DBT_NODES_VARIANCE_POPULATION_NAME_DESC',
  DbtNodesVariancePopulationReferencesAsc = 'DBT_NODES_VARIANCE_POPULATION_REFERENCES_ASC',
  DbtNodesVariancePopulationReferencesDesc = 'DBT_NODES_VARIANCE_POPULATION_REFERENCES_DESC',
  DbtNodesVariancePopulationTagsAsc = 'DBT_NODES_VARIANCE_POPULATION_TAGS_ASC',
  DbtNodesVariancePopulationTagsDesc = 'DBT_NODES_VARIANCE_POPULATION_TAGS_DESC',
  DbtNodesVarianceSampleAliasesAsc = 'DBT_NODES_VARIANCE_SAMPLE_ALIASES_ASC',
  DbtNodesVarianceSampleAliasesDesc = 'DBT_NODES_VARIANCE_SAMPLE_ALIASES_DESC',
  DbtNodesVarianceSampleAttributesAsc = 'DBT_NODES_VARIANCE_SAMPLE_ATTRIBUTES_ASC',
  DbtNodesVarianceSampleAttributesDesc = 'DBT_NODES_VARIANCE_SAMPLE_ATTRIBUTES_DESC',
  DbtNodesVarianceSampleDbtTypeAsc = 'DBT_NODES_VARIANCE_SAMPLE_DBT_TYPE_ASC',
  DbtNodesVarianceSampleDbtTypeDesc = 'DBT_NODES_VARIANCE_SAMPLE_DBT_TYPE_DESC',
  DbtNodesVarianceSampleFileContentIdAsc = 'DBT_NODES_VARIANCE_SAMPLE_FILE_CONTENT_ID_ASC',
  DbtNodesVarianceSampleFileContentIdDesc = 'DBT_NODES_VARIANCE_SAMPLE_FILE_CONTENT_ID_DESC',
  DbtNodesVarianceSampleIdAsc = 'DBT_NODES_VARIANCE_SAMPLE_ID_ASC',
  DbtNodesVarianceSampleIdDesc = 'DBT_NODES_VARIANCE_SAMPLE_ID_DESC',
  DbtNodesVarianceSampleNameAsc = 'DBT_NODES_VARIANCE_SAMPLE_NAME_ASC',
  DbtNodesVarianceSampleNameDesc = 'DBT_NODES_VARIANCE_SAMPLE_NAME_DESC',
  DbtNodesVarianceSampleReferencesAsc = 'DBT_NODES_VARIANCE_SAMPLE_REFERENCES_ASC',
  DbtNodesVarianceSampleReferencesDesc = 'DBT_NODES_VARIANCE_SAMPLE_REFERENCES_DESC',
  DbtNodesVarianceSampleTagsAsc = 'DBT_NODES_VARIANCE_SAMPLE_TAGS_ASC',
  DbtNodesVarianceSampleTagsDesc = 'DBT_NODES_VARIANCE_SAMPLE_TAGS_DESC',
  FilesAverageCommitHashIdAsc = 'FILES_AVERAGE_COMMIT_HASH_ID_ASC',
  FilesAverageCommitHashIdDesc = 'FILES_AVERAGE_COMMIT_HASH_ID_DESC',
  FilesAverageFileContentIdAsc = 'FILES_AVERAGE_FILE_CONTENT_ID_ASC',
  FilesAverageFileContentIdDesc = 'FILES_AVERAGE_FILE_CONTENT_ID_DESC',
  FilesAverageIdAsc = 'FILES_AVERAGE_ID_ASC',
  FilesAverageIdDesc = 'FILES_AVERAGE_ID_DESC',
  FilesAveragePathAsc = 'FILES_AVERAGE_PATH_ASC',
  FilesAveragePathDesc = 'FILES_AVERAGE_PATH_DESC',
  FilesCountAsc = 'FILES_COUNT_ASC',
  FilesCountDesc = 'FILES_COUNT_DESC',
  FilesDistinctCountCommitHashIdAsc = 'FILES_DISTINCT_COUNT_COMMIT_HASH_ID_ASC',
  FilesDistinctCountCommitHashIdDesc = 'FILES_DISTINCT_COUNT_COMMIT_HASH_ID_DESC',
  FilesDistinctCountFileContentIdAsc = 'FILES_DISTINCT_COUNT_FILE_CONTENT_ID_ASC',
  FilesDistinctCountFileContentIdDesc = 'FILES_DISTINCT_COUNT_FILE_CONTENT_ID_DESC',
  FilesDistinctCountIdAsc = 'FILES_DISTINCT_COUNT_ID_ASC',
  FilesDistinctCountIdDesc = 'FILES_DISTINCT_COUNT_ID_DESC',
  FilesDistinctCountPathAsc = 'FILES_DISTINCT_COUNT_PATH_ASC',
  FilesDistinctCountPathDesc = 'FILES_DISTINCT_COUNT_PATH_DESC',
  FilesMaxCommitHashIdAsc = 'FILES_MAX_COMMIT_HASH_ID_ASC',
  FilesMaxCommitHashIdDesc = 'FILES_MAX_COMMIT_HASH_ID_DESC',
  FilesMaxFileContentIdAsc = 'FILES_MAX_FILE_CONTENT_ID_ASC',
  FilesMaxFileContentIdDesc = 'FILES_MAX_FILE_CONTENT_ID_DESC',
  FilesMaxIdAsc = 'FILES_MAX_ID_ASC',
  FilesMaxIdDesc = 'FILES_MAX_ID_DESC',
  FilesMaxPathAsc = 'FILES_MAX_PATH_ASC',
  FilesMaxPathDesc = 'FILES_MAX_PATH_DESC',
  FilesMinCommitHashIdAsc = 'FILES_MIN_COMMIT_HASH_ID_ASC',
  FilesMinCommitHashIdDesc = 'FILES_MIN_COMMIT_HASH_ID_DESC',
  FilesMinFileContentIdAsc = 'FILES_MIN_FILE_CONTENT_ID_ASC',
  FilesMinFileContentIdDesc = 'FILES_MIN_FILE_CONTENT_ID_DESC',
  FilesMinIdAsc = 'FILES_MIN_ID_ASC',
  FilesMinIdDesc = 'FILES_MIN_ID_DESC',
  FilesMinPathAsc = 'FILES_MIN_PATH_ASC',
  FilesMinPathDesc = 'FILES_MIN_PATH_DESC',
  FilesStddevPopulationCommitHashIdAsc = 'FILES_STDDEV_POPULATION_COMMIT_HASH_ID_ASC',
  FilesStddevPopulationCommitHashIdDesc = 'FILES_STDDEV_POPULATION_COMMIT_HASH_ID_DESC',
  FilesStddevPopulationFileContentIdAsc = 'FILES_STDDEV_POPULATION_FILE_CONTENT_ID_ASC',
  FilesStddevPopulationFileContentIdDesc = 'FILES_STDDEV_POPULATION_FILE_CONTENT_ID_DESC',
  FilesStddevPopulationIdAsc = 'FILES_STDDEV_POPULATION_ID_ASC',
  FilesStddevPopulationIdDesc = 'FILES_STDDEV_POPULATION_ID_DESC',
  FilesStddevPopulationPathAsc = 'FILES_STDDEV_POPULATION_PATH_ASC',
  FilesStddevPopulationPathDesc = 'FILES_STDDEV_POPULATION_PATH_DESC',
  FilesStddevSampleCommitHashIdAsc = 'FILES_STDDEV_SAMPLE_COMMIT_HASH_ID_ASC',
  FilesStddevSampleCommitHashIdDesc = 'FILES_STDDEV_SAMPLE_COMMIT_HASH_ID_DESC',
  FilesStddevSampleFileContentIdAsc = 'FILES_STDDEV_SAMPLE_FILE_CONTENT_ID_ASC',
  FilesStddevSampleFileContentIdDesc = 'FILES_STDDEV_SAMPLE_FILE_CONTENT_ID_DESC',
  FilesStddevSampleIdAsc = 'FILES_STDDEV_SAMPLE_ID_ASC',
  FilesStddevSampleIdDesc = 'FILES_STDDEV_SAMPLE_ID_DESC',
  FilesStddevSamplePathAsc = 'FILES_STDDEV_SAMPLE_PATH_ASC',
  FilesStddevSamplePathDesc = 'FILES_STDDEV_SAMPLE_PATH_DESC',
  FilesSumCommitHashIdAsc = 'FILES_SUM_COMMIT_HASH_ID_ASC',
  FilesSumCommitHashIdDesc = 'FILES_SUM_COMMIT_HASH_ID_DESC',
  FilesSumFileContentIdAsc = 'FILES_SUM_FILE_CONTENT_ID_ASC',
  FilesSumFileContentIdDesc = 'FILES_SUM_FILE_CONTENT_ID_DESC',
  FilesSumIdAsc = 'FILES_SUM_ID_ASC',
  FilesSumIdDesc = 'FILES_SUM_ID_DESC',
  FilesSumPathAsc = 'FILES_SUM_PATH_ASC',
  FilesSumPathDesc = 'FILES_SUM_PATH_DESC',
  FilesVariancePopulationCommitHashIdAsc = 'FILES_VARIANCE_POPULATION_COMMIT_HASH_ID_ASC',
  FilesVariancePopulationCommitHashIdDesc = 'FILES_VARIANCE_POPULATION_COMMIT_HASH_ID_DESC',
  FilesVariancePopulationFileContentIdAsc = 'FILES_VARIANCE_POPULATION_FILE_CONTENT_ID_ASC',
  FilesVariancePopulationFileContentIdDesc = 'FILES_VARIANCE_POPULATION_FILE_CONTENT_ID_DESC',
  FilesVariancePopulationIdAsc = 'FILES_VARIANCE_POPULATION_ID_ASC',
  FilesVariancePopulationIdDesc = 'FILES_VARIANCE_POPULATION_ID_DESC',
  FilesVariancePopulationPathAsc = 'FILES_VARIANCE_POPULATION_PATH_ASC',
  FilesVariancePopulationPathDesc = 'FILES_VARIANCE_POPULATION_PATH_DESC',
  FilesVarianceSampleCommitHashIdAsc = 'FILES_VARIANCE_SAMPLE_COMMIT_HASH_ID_ASC',
  FilesVarianceSampleCommitHashIdDesc = 'FILES_VARIANCE_SAMPLE_COMMIT_HASH_ID_DESC',
  FilesVarianceSampleFileContentIdAsc = 'FILES_VARIANCE_SAMPLE_FILE_CONTENT_ID_ASC',
  FilesVarianceSampleFileContentIdDesc = 'FILES_VARIANCE_SAMPLE_FILE_CONTENT_ID_DESC',
  FilesVarianceSampleIdAsc = 'FILES_VARIANCE_SAMPLE_ID_ASC',
  FilesVarianceSampleIdDesc = 'FILES_VARIANCE_SAMPLE_ID_DESC',
  FilesVarianceSamplePathAsc = 'FILES_VARIANCE_SAMPLE_PATH_ASC',
  FilesVarianceSamplePathDesc = 'FILES_VARIANCE_SAMPLE_PATH_DESC',
  FileLinesAverageAnnotationIdAsc = 'FILE_LINES_AVERAGE_ANNOTATION_ID_ASC',
  FileLinesAverageAnnotationIdDesc = 'FILE_LINES_AVERAGE_ANNOTATION_ID_DESC',
  FileLinesAverageEndLineNumberAsc = 'FILE_LINES_AVERAGE_END_LINE_NUMBER_ASC',
  FileLinesAverageEndLineNumberDesc = 'FILE_LINES_AVERAGE_END_LINE_NUMBER_DESC',
  FileLinesAverageFileContentIdAsc = 'FILE_LINES_AVERAGE_FILE_CONTENT_ID_ASC',
  FileLinesAverageFileContentIdDesc = 'FILE_LINES_AVERAGE_FILE_CONTENT_ID_DESC',
  FileLinesAverageIdAsc = 'FILE_LINES_AVERAGE_ID_ASC',
  FileLinesAverageIdDesc = 'FILE_LINES_AVERAGE_ID_DESC',
  FileLinesAverageLastActiveIdAsc = 'FILE_LINES_AVERAGE_LAST_ACTIVE_ID_ASC',
  FileLinesAverageLastActiveIdDesc = 'FILE_LINES_AVERAGE_LAST_ACTIVE_ID_DESC',
  FileLinesAverageStartLineNumberAsc = 'FILE_LINES_AVERAGE_START_LINE_NUMBER_ASC',
  FileLinesAverageStartLineNumberDesc = 'FILE_LINES_AVERAGE_START_LINE_NUMBER_DESC',
  FileLinesCountAsc = 'FILE_LINES_COUNT_ASC',
  FileLinesCountDesc = 'FILE_LINES_COUNT_DESC',
  FileLinesDistinctCountAnnotationIdAsc = 'FILE_LINES_DISTINCT_COUNT_ANNOTATION_ID_ASC',
  FileLinesDistinctCountAnnotationIdDesc = 'FILE_LINES_DISTINCT_COUNT_ANNOTATION_ID_DESC',
  FileLinesDistinctCountEndLineNumberAsc = 'FILE_LINES_DISTINCT_COUNT_END_LINE_NUMBER_ASC',
  FileLinesDistinctCountEndLineNumberDesc = 'FILE_LINES_DISTINCT_COUNT_END_LINE_NUMBER_DESC',
  FileLinesDistinctCountFileContentIdAsc = 'FILE_LINES_DISTINCT_COUNT_FILE_CONTENT_ID_ASC',
  FileLinesDistinctCountFileContentIdDesc = 'FILE_LINES_DISTINCT_COUNT_FILE_CONTENT_ID_DESC',
  FileLinesDistinctCountIdAsc = 'FILE_LINES_DISTINCT_COUNT_ID_ASC',
  FileLinesDistinctCountIdDesc = 'FILE_LINES_DISTINCT_COUNT_ID_DESC',
  FileLinesDistinctCountLastActiveIdAsc = 'FILE_LINES_DISTINCT_COUNT_LAST_ACTIVE_ID_ASC',
  FileLinesDistinctCountLastActiveIdDesc = 'FILE_LINES_DISTINCT_COUNT_LAST_ACTIVE_ID_DESC',
  FileLinesDistinctCountStartLineNumberAsc = 'FILE_LINES_DISTINCT_COUNT_START_LINE_NUMBER_ASC',
  FileLinesDistinctCountStartLineNumberDesc = 'FILE_LINES_DISTINCT_COUNT_START_LINE_NUMBER_DESC',
  FileLinesMaxAnnotationIdAsc = 'FILE_LINES_MAX_ANNOTATION_ID_ASC',
  FileLinesMaxAnnotationIdDesc = 'FILE_LINES_MAX_ANNOTATION_ID_DESC',
  FileLinesMaxEndLineNumberAsc = 'FILE_LINES_MAX_END_LINE_NUMBER_ASC',
  FileLinesMaxEndLineNumberDesc = 'FILE_LINES_MAX_END_LINE_NUMBER_DESC',
  FileLinesMaxFileContentIdAsc = 'FILE_LINES_MAX_FILE_CONTENT_ID_ASC',
  FileLinesMaxFileContentIdDesc = 'FILE_LINES_MAX_FILE_CONTENT_ID_DESC',
  FileLinesMaxIdAsc = 'FILE_LINES_MAX_ID_ASC',
  FileLinesMaxIdDesc = 'FILE_LINES_MAX_ID_DESC',
  FileLinesMaxLastActiveIdAsc = 'FILE_LINES_MAX_LAST_ACTIVE_ID_ASC',
  FileLinesMaxLastActiveIdDesc = 'FILE_LINES_MAX_LAST_ACTIVE_ID_DESC',
  FileLinesMaxStartLineNumberAsc = 'FILE_LINES_MAX_START_LINE_NUMBER_ASC',
  FileLinesMaxStartLineNumberDesc = 'FILE_LINES_MAX_START_LINE_NUMBER_DESC',
  FileLinesMinAnnotationIdAsc = 'FILE_LINES_MIN_ANNOTATION_ID_ASC',
  FileLinesMinAnnotationIdDesc = 'FILE_LINES_MIN_ANNOTATION_ID_DESC',
  FileLinesMinEndLineNumberAsc = 'FILE_LINES_MIN_END_LINE_NUMBER_ASC',
  FileLinesMinEndLineNumberDesc = 'FILE_LINES_MIN_END_LINE_NUMBER_DESC',
  FileLinesMinFileContentIdAsc = 'FILE_LINES_MIN_FILE_CONTENT_ID_ASC',
  FileLinesMinFileContentIdDesc = 'FILE_LINES_MIN_FILE_CONTENT_ID_DESC',
  FileLinesMinIdAsc = 'FILE_LINES_MIN_ID_ASC',
  FileLinesMinIdDesc = 'FILE_LINES_MIN_ID_DESC',
  FileLinesMinLastActiveIdAsc = 'FILE_LINES_MIN_LAST_ACTIVE_ID_ASC',
  FileLinesMinLastActiveIdDesc = 'FILE_LINES_MIN_LAST_ACTIVE_ID_DESC',
  FileLinesMinStartLineNumberAsc = 'FILE_LINES_MIN_START_LINE_NUMBER_ASC',
  FileLinesMinStartLineNumberDesc = 'FILE_LINES_MIN_START_LINE_NUMBER_DESC',
  FileLinesStddevPopulationAnnotationIdAsc = 'FILE_LINES_STDDEV_POPULATION_ANNOTATION_ID_ASC',
  FileLinesStddevPopulationAnnotationIdDesc = 'FILE_LINES_STDDEV_POPULATION_ANNOTATION_ID_DESC',
  FileLinesStddevPopulationEndLineNumberAsc = 'FILE_LINES_STDDEV_POPULATION_END_LINE_NUMBER_ASC',
  FileLinesStddevPopulationEndLineNumberDesc = 'FILE_LINES_STDDEV_POPULATION_END_LINE_NUMBER_DESC',
  FileLinesStddevPopulationFileContentIdAsc = 'FILE_LINES_STDDEV_POPULATION_FILE_CONTENT_ID_ASC',
  FileLinesStddevPopulationFileContentIdDesc = 'FILE_LINES_STDDEV_POPULATION_FILE_CONTENT_ID_DESC',
  FileLinesStddevPopulationIdAsc = 'FILE_LINES_STDDEV_POPULATION_ID_ASC',
  FileLinesStddevPopulationIdDesc = 'FILE_LINES_STDDEV_POPULATION_ID_DESC',
  FileLinesStddevPopulationLastActiveIdAsc = 'FILE_LINES_STDDEV_POPULATION_LAST_ACTIVE_ID_ASC',
  FileLinesStddevPopulationLastActiveIdDesc = 'FILE_LINES_STDDEV_POPULATION_LAST_ACTIVE_ID_DESC',
  FileLinesStddevPopulationStartLineNumberAsc = 'FILE_LINES_STDDEV_POPULATION_START_LINE_NUMBER_ASC',
  FileLinesStddevPopulationStartLineNumberDesc = 'FILE_LINES_STDDEV_POPULATION_START_LINE_NUMBER_DESC',
  FileLinesStddevSampleAnnotationIdAsc = 'FILE_LINES_STDDEV_SAMPLE_ANNOTATION_ID_ASC',
  FileLinesStddevSampleAnnotationIdDesc = 'FILE_LINES_STDDEV_SAMPLE_ANNOTATION_ID_DESC',
  FileLinesStddevSampleEndLineNumberAsc = 'FILE_LINES_STDDEV_SAMPLE_END_LINE_NUMBER_ASC',
  FileLinesStddevSampleEndLineNumberDesc = 'FILE_LINES_STDDEV_SAMPLE_END_LINE_NUMBER_DESC',
  FileLinesStddevSampleFileContentIdAsc = 'FILE_LINES_STDDEV_SAMPLE_FILE_CONTENT_ID_ASC',
  FileLinesStddevSampleFileContentIdDesc = 'FILE_LINES_STDDEV_SAMPLE_FILE_CONTENT_ID_DESC',
  FileLinesStddevSampleIdAsc = 'FILE_LINES_STDDEV_SAMPLE_ID_ASC',
  FileLinesStddevSampleIdDesc = 'FILE_LINES_STDDEV_SAMPLE_ID_DESC',
  FileLinesStddevSampleLastActiveIdAsc = 'FILE_LINES_STDDEV_SAMPLE_LAST_ACTIVE_ID_ASC',
  FileLinesStddevSampleLastActiveIdDesc = 'FILE_LINES_STDDEV_SAMPLE_LAST_ACTIVE_ID_DESC',
  FileLinesStddevSampleStartLineNumberAsc = 'FILE_LINES_STDDEV_SAMPLE_START_LINE_NUMBER_ASC',
  FileLinesStddevSampleStartLineNumberDesc = 'FILE_LINES_STDDEV_SAMPLE_START_LINE_NUMBER_DESC',
  FileLinesSumAnnotationIdAsc = 'FILE_LINES_SUM_ANNOTATION_ID_ASC',
  FileLinesSumAnnotationIdDesc = 'FILE_LINES_SUM_ANNOTATION_ID_DESC',
  FileLinesSumEndLineNumberAsc = 'FILE_LINES_SUM_END_LINE_NUMBER_ASC',
  FileLinesSumEndLineNumberDesc = 'FILE_LINES_SUM_END_LINE_NUMBER_DESC',
  FileLinesSumFileContentIdAsc = 'FILE_LINES_SUM_FILE_CONTENT_ID_ASC',
  FileLinesSumFileContentIdDesc = 'FILE_LINES_SUM_FILE_CONTENT_ID_DESC',
  FileLinesSumIdAsc = 'FILE_LINES_SUM_ID_ASC',
  FileLinesSumIdDesc = 'FILE_LINES_SUM_ID_DESC',
  FileLinesSumLastActiveIdAsc = 'FILE_LINES_SUM_LAST_ACTIVE_ID_ASC',
  FileLinesSumLastActiveIdDesc = 'FILE_LINES_SUM_LAST_ACTIVE_ID_DESC',
  FileLinesSumStartLineNumberAsc = 'FILE_LINES_SUM_START_LINE_NUMBER_ASC',
  FileLinesSumStartLineNumberDesc = 'FILE_LINES_SUM_START_LINE_NUMBER_DESC',
  FileLinesVariancePopulationAnnotationIdAsc = 'FILE_LINES_VARIANCE_POPULATION_ANNOTATION_ID_ASC',
  FileLinesVariancePopulationAnnotationIdDesc = 'FILE_LINES_VARIANCE_POPULATION_ANNOTATION_ID_DESC',
  FileLinesVariancePopulationEndLineNumberAsc = 'FILE_LINES_VARIANCE_POPULATION_END_LINE_NUMBER_ASC',
  FileLinesVariancePopulationEndLineNumberDesc = 'FILE_LINES_VARIANCE_POPULATION_END_LINE_NUMBER_DESC',
  FileLinesVariancePopulationFileContentIdAsc = 'FILE_LINES_VARIANCE_POPULATION_FILE_CONTENT_ID_ASC',
  FileLinesVariancePopulationFileContentIdDesc = 'FILE_LINES_VARIANCE_POPULATION_FILE_CONTENT_ID_DESC',
  FileLinesVariancePopulationIdAsc = 'FILE_LINES_VARIANCE_POPULATION_ID_ASC',
  FileLinesVariancePopulationIdDesc = 'FILE_LINES_VARIANCE_POPULATION_ID_DESC',
  FileLinesVariancePopulationLastActiveIdAsc = 'FILE_LINES_VARIANCE_POPULATION_LAST_ACTIVE_ID_ASC',
  FileLinesVariancePopulationLastActiveIdDesc = 'FILE_LINES_VARIANCE_POPULATION_LAST_ACTIVE_ID_DESC',
  FileLinesVariancePopulationStartLineNumberAsc = 'FILE_LINES_VARIANCE_POPULATION_START_LINE_NUMBER_ASC',
  FileLinesVariancePopulationStartLineNumberDesc = 'FILE_LINES_VARIANCE_POPULATION_START_LINE_NUMBER_DESC',
  FileLinesVarianceSampleAnnotationIdAsc = 'FILE_LINES_VARIANCE_SAMPLE_ANNOTATION_ID_ASC',
  FileLinesVarianceSampleAnnotationIdDesc = 'FILE_LINES_VARIANCE_SAMPLE_ANNOTATION_ID_DESC',
  FileLinesVarianceSampleEndLineNumberAsc = 'FILE_LINES_VARIANCE_SAMPLE_END_LINE_NUMBER_ASC',
  FileLinesVarianceSampleEndLineNumberDesc = 'FILE_LINES_VARIANCE_SAMPLE_END_LINE_NUMBER_DESC',
  FileLinesVarianceSampleFileContentIdAsc = 'FILE_LINES_VARIANCE_SAMPLE_FILE_CONTENT_ID_ASC',
  FileLinesVarianceSampleFileContentIdDesc = 'FILE_LINES_VARIANCE_SAMPLE_FILE_CONTENT_ID_DESC',
  FileLinesVarianceSampleIdAsc = 'FILE_LINES_VARIANCE_SAMPLE_ID_ASC',
  FileLinesVarianceSampleIdDesc = 'FILE_LINES_VARIANCE_SAMPLE_ID_DESC',
  FileLinesVarianceSampleLastActiveIdAsc = 'FILE_LINES_VARIANCE_SAMPLE_LAST_ACTIVE_ID_ASC',
  FileLinesVarianceSampleLastActiveIdDesc = 'FILE_LINES_VARIANCE_SAMPLE_LAST_ACTIVE_ID_DESC',
  FileLinesVarianceSampleStartLineNumberAsc = 'FILE_LINES_VARIANCE_SAMPLE_START_LINE_NUMBER_ASC',
  FileLinesVarianceSampleStartLineNumberDesc = 'FILE_LINES_VARIANCE_SAMPLE_START_LINE_NUMBER_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type FileDistinctCountAggregates = {
  __typename?: 'FileDistinctCountAggregates';
  /** Distinct count of commitHashId across the matching connection */
  commitHashId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of fileContentId across the matching connection */
  fileContentId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of path across the matching connection */
  path?: Maybe<Scalars['BigInt']>;
};

export type FileLine = Node & {
  __typename?: 'FileLine';
  annotationId: Scalars['BigInt'];
  endLineNumber: Scalars['Int'];
  /** Reads a single `FileContent` that is related to this `FileLine`. */
  fileContent?: Maybe<FileContent>;
  fileContentId: Scalars['BigInt'];
  id: Scalars['BigInt'];
  /** Reads a single `File` that is related to this `FileLine`. */
  lastActive?: Maybe<File>;
  lastActiveId?: Maybe<Scalars['BigInt']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  startLineNumber: Scalars['Int'];
};

export type FileLineAggregates = {
  __typename?: 'FileLineAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<FileLineAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<FileLineDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<FileLineMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<FileLineMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<FileLineStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<FileLineStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<FileLineSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<FileLineVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<FileLineVarianceSampleAggregates>;
};

export type FileLineAverageAggregates = {
  __typename?: 'FileLineAverageAggregates';
  /** Mean average of annotationId across the matching connection */
  annotationId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of endLineNumber across the matching connection */
  endLineNumber?: Maybe<Scalars['BigFloat']>;
  /** Mean average of fileContentId across the matching connection */
  fileContentId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of lastActiveId across the matching connection */
  lastActiveId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of startLineNumber across the matching connection */
  startLineNumber?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `FileLine` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type FileLineCondition = {
  /** Checks for equality with the object’s `annotationId` field. */
  annotationId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `endLineNumber` field. */
  endLineNumber?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `fileContentId` field. */
  fileContentId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `lastActiveId` field. */
  lastActiveId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `startLineNumber` field. */
  startLineNumber?: InputMaybe<Scalars['Int']>;
};

export type FileLineDistinctCountAggregates = {
  __typename?: 'FileLineDistinctCountAggregates';
  /** Distinct count of annotationId across the matching connection */
  annotationId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of endLineNumber across the matching connection */
  endLineNumber?: Maybe<Scalars['BigInt']>;
  /** Distinct count of fileContentId across the matching connection */
  fileContentId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lastActiveId across the matching connection */
  lastActiveId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of startLineNumber across the matching connection */
  startLineNumber?: Maybe<Scalars['BigInt']>;
};

export type FileLineMaxAggregates = {
  __typename?: 'FileLineMaxAggregates';
  /** Maximum of annotationId across the matching connection */
  annotationId?: Maybe<Scalars['BigInt']>;
  /** Maximum of endLineNumber across the matching connection */
  endLineNumber?: Maybe<Scalars['Int']>;
  /** Maximum of fileContentId across the matching connection */
  fileContentId?: Maybe<Scalars['BigInt']>;
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Maximum of lastActiveId across the matching connection */
  lastActiveId?: Maybe<Scalars['BigInt']>;
  /** Maximum of startLineNumber across the matching connection */
  startLineNumber?: Maybe<Scalars['Int']>;
};

export type FileLineMinAggregates = {
  __typename?: 'FileLineMinAggregates';
  /** Minimum of annotationId across the matching connection */
  annotationId?: Maybe<Scalars['BigInt']>;
  /** Minimum of endLineNumber across the matching connection */
  endLineNumber?: Maybe<Scalars['Int']>;
  /** Minimum of fileContentId across the matching connection */
  fileContentId?: Maybe<Scalars['BigInt']>;
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Minimum of lastActiveId across the matching connection */
  lastActiveId?: Maybe<Scalars['BigInt']>;
  /** Minimum of startLineNumber across the matching connection */
  startLineNumber?: Maybe<Scalars['Int']>;
};

export type FileLineStddevPopulationAggregates = {
  __typename?: 'FileLineStddevPopulationAggregates';
  /** Population standard deviation of annotationId across the matching connection */
  annotationId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of endLineNumber across the matching connection */
  endLineNumber?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of fileContentId across the matching connection */
  fileContentId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of lastActiveId across the matching connection */
  lastActiveId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of startLineNumber across the matching connection */
  startLineNumber?: Maybe<Scalars['BigFloat']>;
};

export type FileLineStddevSampleAggregates = {
  __typename?: 'FileLineStddevSampleAggregates';
  /** Sample standard deviation of annotationId across the matching connection */
  annotationId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of endLineNumber across the matching connection */
  endLineNumber?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of fileContentId across the matching connection */
  fileContentId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of lastActiveId across the matching connection */
  lastActiveId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of startLineNumber across the matching connection */
  startLineNumber?: Maybe<Scalars['BigFloat']>;
};

export type FileLineSumAggregates = {
  __typename?: 'FileLineSumAggregates';
  /** Sum of annotationId across the matching connection */
  annotationId: Scalars['BigFloat'];
  /** Sum of endLineNumber across the matching connection */
  endLineNumber: Scalars['BigInt'];
  /** Sum of fileContentId across the matching connection */
  fileContentId: Scalars['BigFloat'];
  /** Sum of id across the matching connection */
  id: Scalars['BigFloat'];
  /** Sum of lastActiveId across the matching connection */
  lastActiveId: Scalars['BigFloat'];
  /** Sum of startLineNumber across the matching connection */
  startLineNumber: Scalars['BigInt'];
};

export type FileLineVariancePopulationAggregates = {
  __typename?: 'FileLineVariancePopulationAggregates';
  /** Population variance of annotationId across the matching connection */
  annotationId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of endLineNumber across the matching connection */
  endLineNumber?: Maybe<Scalars['BigFloat']>;
  /** Population variance of fileContentId across the matching connection */
  fileContentId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of lastActiveId across the matching connection */
  lastActiveId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of startLineNumber across the matching connection */
  startLineNumber?: Maybe<Scalars['BigFloat']>;
};

export type FileLineVarianceSampleAggregates = {
  __typename?: 'FileLineVarianceSampleAggregates';
  /** Sample variance of annotationId across the matching connection */
  annotationId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of endLineNumber across the matching connection */
  endLineNumber?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of fileContentId across the matching connection */
  fileContentId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of lastActiveId across the matching connection */
  lastActiveId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of startLineNumber across the matching connection */
  startLineNumber?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `FileLine` values. */
export type FileLinesConnection = {
  __typename?: 'FileLinesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<FileLineAggregates>;
  /** A list of edges which contains the `FileLine` and cursor to aid in pagination. */
  edges: Array<FileLinesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<FileLineAggregates>>;
  /** A list of `FileLine` objects. */
  nodes: Array<FileLine>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FileLine` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `FileLine` values. */
export type FileLinesConnectionGroupedAggregatesArgs = {
  groupBy: Array<FileLinesGroupBy>;
  having?: InputMaybe<FileLinesHavingInput>;
};

/** A `FileLine` edge in the connection. */
export type FileLinesEdge = {
  __typename?: 'FileLinesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `FileLine` at the end of the edge. */
  node: FileLine;
};

/** Grouping methods for `FileLine` for usage during aggregation. */
export enum FileLinesGroupBy {
  AnnotationId = 'ANNOTATION_ID',
  EndLineNumber = 'END_LINE_NUMBER',
  FileContentId = 'FILE_CONTENT_ID',
  LastActiveId = 'LAST_ACTIVE_ID',
  StartLineNumber = 'START_LINE_NUMBER'
}

export type FileLinesHavingAverageInput = {
  annotationId?: InputMaybe<HavingBigintFilter>;
  endLineNumber?: InputMaybe<HavingIntFilter>;
  fileContentId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  lastActiveId?: InputMaybe<HavingBigintFilter>;
  startLineNumber?: InputMaybe<HavingIntFilter>;
};

export type FileLinesHavingDistinctCountInput = {
  annotationId?: InputMaybe<HavingBigintFilter>;
  endLineNumber?: InputMaybe<HavingIntFilter>;
  fileContentId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  lastActiveId?: InputMaybe<HavingBigintFilter>;
  startLineNumber?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `FileLine` aggregates. */
export type FileLinesHavingInput = {
  AND?: InputMaybe<Array<FileLinesHavingInput>>;
  OR?: InputMaybe<Array<FileLinesHavingInput>>;
  average?: InputMaybe<FileLinesHavingAverageInput>;
  distinctCount?: InputMaybe<FileLinesHavingDistinctCountInput>;
  max?: InputMaybe<FileLinesHavingMaxInput>;
  min?: InputMaybe<FileLinesHavingMinInput>;
  stddevPopulation?: InputMaybe<FileLinesHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<FileLinesHavingStddevSampleInput>;
  sum?: InputMaybe<FileLinesHavingSumInput>;
  variancePopulation?: InputMaybe<FileLinesHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<FileLinesHavingVarianceSampleInput>;
};

export type FileLinesHavingMaxInput = {
  annotationId?: InputMaybe<HavingBigintFilter>;
  endLineNumber?: InputMaybe<HavingIntFilter>;
  fileContentId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  lastActiveId?: InputMaybe<HavingBigintFilter>;
  startLineNumber?: InputMaybe<HavingIntFilter>;
};

export type FileLinesHavingMinInput = {
  annotationId?: InputMaybe<HavingBigintFilter>;
  endLineNumber?: InputMaybe<HavingIntFilter>;
  fileContentId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  lastActiveId?: InputMaybe<HavingBigintFilter>;
  startLineNumber?: InputMaybe<HavingIntFilter>;
};

export type FileLinesHavingStddevPopulationInput = {
  annotationId?: InputMaybe<HavingBigintFilter>;
  endLineNumber?: InputMaybe<HavingIntFilter>;
  fileContentId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  lastActiveId?: InputMaybe<HavingBigintFilter>;
  startLineNumber?: InputMaybe<HavingIntFilter>;
};

export type FileLinesHavingStddevSampleInput = {
  annotationId?: InputMaybe<HavingBigintFilter>;
  endLineNumber?: InputMaybe<HavingIntFilter>;
  fileContentId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  lastActiveId?: InputMaybe<HavingBigintFilter>;
  startLineNumber?: InputMaybe<HavingIntFilter>;
};

export type FileLinesHavingSumInput = {
  annotationId?: InputMaybe<HavingBigintFilter>;
  endLineNumber?: InputMaybe<HavingIntFilter>;
  fileContentId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  lastActiveId?: InputMaybe<HavingBigintFilter>;
  startLineNumber?: InputMaybe<HavingIntFilter>;
};

export type FileLinesHavingVariancePopulationInput = {
  annotationId?: InputMaybe<HavingBigintFilter>;
  endLineNumber?: InputMaybe<HavingIntFilter>;
  fileContentId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  lastActiveId?: InputMaybe<HavingBigintFilter>;
  startLineNumber?: InputMaybe<HavingIntFilter>;
};

export type FileLinesHavingVarianceSampleInput = {
  annotationId?: InputMaybe<HavingBigintFilter>;
  endLineNumber?: InputMaybe<HavingIntFilter>;
  fileContentId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  lastActiveId?: InputMaybe<HavingBigintFilter>;
  startLineNumber?: InputMaybe<HavingIntFilter>;
};

/** Methods to use when ordering `FileLine`. */
export enum FileLinesOrderBy {
  AnnotationIdAsc = 'ANNOTATION_ID_ASC',
  AnnotationIdDesc = 'ANNOTATION_ID_DESC',
  EndLineNumberAsc = 'END_LINE_NUMBER_ASC',
  EndLineNumberDesc = 'END_LINE_NUMBER_DESC',
  FileContentIdAsc = 'FILE_CONTENT_ID_ASC',
  FileContentIdDesc = 'FILE_CONTENT_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LastActiveIdAsc = 'LAST_ACTIVE_ID_ASC',
  LastActiveIdDesc = 'LAST_ACTIVE_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  StartLineNumberAsc = 'START_LINE_NUMBER_ASC',
  StartLineNumberDesc = 'START_LINE_NUMBER_DESC'
}

export type FileMaxAggregates = {
  __typename?: 'FileMaxAggregates';
  /** Maximum of commitHashId across the matching connection */
  commitHashId?: Maybe<Scalars['BigInt']>;
  /** Maximum of fileContentId across the matching connection */
  fileContentId?: Maybe<Scalars['BigInt']>;
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
};

export type FileMinAggregates = {
  __typename?: 'FileMinAggregates';
  /** Minimum of commitHashId across the matching connection */
  commitHashId?: Maybe<Scalars['BigInt']>;
  /** Minimum of fileContentId across the matching connection */
  fileContentId?: Maybe<Scalars['BigInt']>;
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
};

export type FileStddevPopulationAggregates = {
  __typename?: 'FileStddevPopulationAggregates';
  /** Population standard deviation of commitHashId across the matching connection */
  commitHashId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of fileContentId across the matching connection */
  fileContentId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type FileStddevSampleAggregates = {
  __typename?: 'FileStddevSampleAggregates';
  /** Sample standard deviation of commitHashId across the matching connection */
  commitHashId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of fileContentId across the matching connection */
  fileContentId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type FileSumAggregates = {
  __typename?: 'FileSumAggregates';
  /** Sum of commitHashId across the matching connection */
  commitHashId: Scalars['BigFloat'];
  /** Sum of fileContentId across the matching connection */
  fileContentId: Scalars['BigFloat'];
  /** Sum of id across the matching connection */
  id: Scalars['BigFloat'];
};

export type FileVariancePopulationAggregates = {
  __typename?: 'FileVariancePopulationAggregates';
  /** Population variance of commitHashId across the matching connection */
  commitHashId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of fileContentId across the matching connection */
  fileContentId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type FileVarianceSampleAggregates = {
  __typename?: 'FileVarianceSampleAggregates';
  /** Sample variance of commitHashId across the matching connection */
  commitHashId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of fileContentId across the matching connection */
  fileContentId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `File` values. */
export type FilesConnection = {
  __typename?: 'FilesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<FileAggregates>;
  /** A list of edges which contains the `File` and cursor to aid in pagination. */
  edges: Array<FilesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<FileAggregates>>;
  /** A list of `File` objects. */
  nodes: Array<File>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `File` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `File` values. */
export type FilesConnectionGroupedAggregatesArgs = {
  groupBy: Array<FilesGroupBy>;
  having?: InputMaybe<FilesHavingInput>;
};

/** A `File` edge in the connection. */
export type FilesEdge = {
  __typename?: 'FilesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `File` at the end of the edge. */
  node: File;
};

/** Grouping methods for `File` for usage during aggregation. */
export enum FilesGroupBy {
  CommitHashId = 'COMMIT_HASH_ID',
  FileContentId = 'FILE_CONTENT_ID',
  Path = 'PATH'
}

export type FilesHavingAverageInput = {
  commitHashId?: InputMaybe<HavingBigintFilter>;
  fileContentId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type FilesHavingDistinctCountInput = {
  commitHashId?: InputMaybe<HavingBigintFilter>;
  fileContentId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

/** Conditions for `File` aggregates. */
export type FilesHavingInput = {
  AND?: InputMaybe<Array<FilesHavingInput>>;
  OR?: InputMaybe<Array<FilesHavingInput>>;
  average?: InputMaybe<FilesHavingAverageInput>;
  distinctCount?: InputMaybe<FilesHavingDistinctCountInput>;
  max?: InputMaybe<FilesHavingMaxInput>;
  min?: InputMaybe<FilesHavingMinInput>;
  stddevPopulation?: InputMaybe<FilesHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<FilesHavingStddevSampleInput>;
  sum?: InputMaybe<FilesHavingSumInput>;
  variancePopulation?: InputMaybe<FilesHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<FilesHavingVarianceSampleInput>;
};

export type FilesHavingMaxInput = {
  commitHashId?: InputMaybe<HavingBigintFilter>;
  fileContentId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type FilesHavingMinInput = {
  commitHashId?: InputMaybe<HavingBigintFilter>;
  fileContentId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type FilesHavingStddevPopulationInput = {
  commitHashId?: InputMaybe<HavingBigintFilter>;
  fileContentId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type FilesHavingStddevSampleInput = {
  commitHashId?: InputMaybe<HavingBigintFilter>;
  fileContentId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type FilesHavingSumInput = {
  commitHashId?: InputMaybe<HavingBigintFilter>;
  fileContentId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type FilesHavingVariancePopulationInput = {
  commitHashId?: InputMaybe<HavingBigintFilter>;
  fileContentId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type FilesHavingVarianceSampleInput = {
  commitHashId?: InputMaybe<HavingBigintFilter>;
  fileContentId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

/** Methods to use when ordering `File`. */
export enum FilesOrderBy {
  AnnotationsByArchivedAtIdAverageAnnotationTypeAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_AVERAGE_ANNOTATION_TYPE_ASC',
  AnnotationsByArchivedAtIdAverageAnnotationTypeDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_AVERAGE_ANNOTATION_TYPE_DESC',
  AnnotationsByArchivedAtIdAverageArchivedAtIdAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_AVERAGE_ARCHIVED_AT_ID_ASC',
  AnnotationsByArchivedAtIdAverageArchivedAtIdDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_AVERAGE_ARCHIVED_AT_ID_DESC',
  AnnotationsByArchivedAtIdAverageIdAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_AVERAGE_ID_ASC',
  AnnotationsByArchivedAtIdAverageIdDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_AVERAGE_ID_DESC',
  AnnotationsByArchivedAtIdAverageIsDeletedAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_AVERAGE_IS_DELETED_ASC',
  AnnotationsByArchivedAtIdAverageIsDeletedDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_AVERAGE_IS_DELETED_DESC',
  AnnotationsByArchivedAtIdAverageResourceIdAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_AVERAGE_RESOURCE_ID_ASC',
  AnnotationsByArchivedAtIdAverageResourceIdDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_AVERAGE_RESOURCE_ID_DESC',
  AnnotationsByArchivedAtIdAverageWorkspaceIdAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_AVERAGE_WORKSPACE_ID_ASC',
  AnnotationsByArchivedAtIdAverageWorkspaceIdDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_AVERAGE_WORKSPACE_ID_DESC',
  AnnotationsByArchivedAtIdCountAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_COUNT_ASC',
  AnnotationsByArchivedAtIdCountDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_COUNT_DESC',
  AnnotationsByArchivedAtIdDistinctCountAnnotationTypeAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_DISTINCT_COUNT_ANNOTATION_TYPE_ASC',
  AnnotationsByArchivedAtIdDistinctCountAnnotationTypeDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_DISTINCT_COUNT_ANNOTATION_TYPE_DESC',
  AnnotationsByArchivedAtIdDistinctCountArchivedAtIdAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_DISTINCT_COUNT_ARCHIVED_AT_ID_ASC',
  AnnotationsByArchivedAtIdDistinctCountArchivedAtIdDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_DISTINCT_COUNT_ARCHIVED_AT_ID_DESC',
  AnnotationsByArchivedAtIdDistinctCountIdAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_DISTINCT_COUNT_ID_ASC',
  AnnotationsByArchivedAtIdDistinctCountIdDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_DISTINCT_COUNT_ID_DESC',
  AnnotationsByArchivedAtIdDistinctCountIsDeletedAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_DISTINCT_COUNT_IS_DELETED_ASC',
  AnnotationsByArchivedAtIdDistinctCountIsDeletedDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_DISTINCT_COUNT_IS_DELETED_DESC',
  AnnotationsByArchivedAtIdDistinctCountResourceIdAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_DISTINCT_COUNT_RESOURCE_ID_ASC',
  AnnotationsByArchivedAtIdDistinctCountResourceIdDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_DISTINCT_COUNT_RESOURCE_ID_DESC',
  AnnotationsByArchivedAtIdDistinctCountWorkspaceIdAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_DISTINCT_COUNT_WORKSPACE_ID_ASC',
  AnnotationsByArchivedAtIdDistinctCountWorkspaceIdDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_DISTINCT_COUNT_WORKSPACE_ID_DESC',
  AnnotationsByArchivedAtIdMaxAnnotationTypeAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_MAX_ANNOTATION_TYPE_ASC',
  AnnotationsByArchivedAtIdMaxAnnotationTypeDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_MAX_ANNOTATION_TYPE_DESC',
  AnnotationsByArchivedAtIdMaxArchivedAtIdAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_MAX_ARCHIVED_AT_ID_ASC',
  AnnotationsByArchivedAtIdMaxArchivedAtIdDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_MAX_ARCHIVED_AT_ID_DESC',
  AnnotationsByArchivedAtIdMaxIdAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_MAX_ID_ASC',
  AnnotationsByArchivedAtIdMaxIdDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_MAX_ID_DESC',
  AnnotationsByArchivedAtIdMaxIsDeletedAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_MAX_IS_DELETED_ASC',
  AnnotationsByArchivedAtIdMaxIsDeletedDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_MAX_IS_DELETED_DESC',
  AnnotationsByArchivedAtIdMaxResourceIdAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_MAX_RESOURCE_ID_ASC',
  AnnotationsByArchivedAtIdMaxResourceIdDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_MAX_RESOURCE_ID_DESC',
  AnnotationsByArchivedAtIdMaxWorkspaceIdAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_MAX_WORKSPACE_ID_ASC',
  AnnotationsByArchivedAtIdMaxWorkspaceIdDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_MAX_WORKSPACE_ID_DESC',
  AnnotationsByArchivedAtIdMinAnnotationTypeAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_MIN_ANNOTATION_TYPE_ASC',
  AnnotationsByArchivedAtIdMinAnnotationTypeDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_MIN_ANNOTATION_TYPE_DESC',
  AnnotationsByArchivedAtIdMinArchivedAtIdAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_MIN_ARCHIVED_AT_ID_ASC',
  AnnotationsByArchivedAtIdMinArchivedAtIdDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_MIN_ARCHIVED_AT_ID_DESC',
  AnnotationsByArchivedAtIdMinIdAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_MIN_ID_ASC',
  AnnotationsByArchivedAtIdMinIdDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_MIN_ID_DESC',
  AnnotationsByArchivedAtIdMinIsDeletedAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_MIN_IS_DELETED_ASC',
  AnnotationsByArchivedAtIdMinIsDeletedDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_MIN_IS_DELETED_DESC',
  AnnotationsByArchivedAtIdMinResourceIdAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_MIN_RESOURCE_ID_ASC',
  AnnotationsByArchivedAtIdMinResourceIdDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_MIN_RESOURCE_ID_DESC',
  AnnotationsByArchivedAtIdMinWorkspaceIdAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_MIN_WORKSPACE_ID_ASC',
  AnnotationsByArchivedAtIdMinWorkspaceIdDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_MIN_WORKSPACE_ID_DESC',
  AnnotationsByArchivedAtIdStddevPopulationAnnotationTypeAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_STDDEV_POPULATION_ANNOTATION_TYPE_ASC',
  AnnotationsByArchivedAtIdStddevPopulationAnnotationTypeDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_STDDEV_POPULATION_ANNOTATION_TYPE_DESC',
  AnnotationsByArchivedAtIdStddevPopulationArchivedAtIdAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_STDDEV_POPULATION_ARCHIVED_AT_ID_ASC',
  AnnotationsByArchivedAtIdStddevPopulationArchivedAtIdDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_STDDEV_POPULATION_ARCHIVED_AT_ID_DESC',
  AnnotationsByArchivedAtIdStddevPopulationIdAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_STDDEV_POPULATION_ID_ASC',
  AnnotationsByArchivedAtIdStddevPopulationIdDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_STDDEV_POPULATION_ID_DESC',
  AnnotationsByArchivedAtIdStddevPopulationIsDeletedAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_STDDEV_POPULATION_IS_DELETED_ASC',
  AnnotationsByArchivedAtIdStddevPopulationIsDeletedDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_STDDEV_POPULATION_IS_DELETED_DESC',
  AnnotationsByArchivedAtIdStddevPopulationResourceIdAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_STDDEV_POPULATION_RESOURCE_ID_ASC',
  AnnotationsByArchivedAtIdStddevPopulationResourceIdDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_STDDEV_POPULATION_RESOURCE_ID_DESC',
  AnnotationsByArchivedAtIdStddevPopulationWorkspaceIdAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_STDDEV_POPULATION_WORKSPACE_ID_ASC',
  AnnotationsByArchivedAtIdStddevPopulationWorkspaceIdDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_STDDEV_POPULATION_WORKSPACE_ID_DESC',
  AnnotationsByArchivedAtIdStddevSampleAnnotationTypeAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_STDDEV_SAMPLE_ANNOTATION_TYPE_ASC',
  AnnotationsByArchivedAtIdStddevSampleAnnotationTypeDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_STDDEV_SAMPLE_ANNOTATION_TYPE_DESC',
  AnnotationsByArchivedAtIdStddevSampleArchivedAtIdAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_STDDEV_SAMPLE_ARCHIVED_AT_ID_ASC',
  AnnotationsByArchivedAtIdStddevSampleArchivedAtIdDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_STDDEV_SAMPLE_ARCHIVED_AT_ID_DESC',
  AnnotationsByArchivedAtIdStddevSampleIdAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_STDDEV_SAMPLE_ID_ASC',
  AnnotationsByArchivedAtIdStddevSampleIdDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_STDDEV_SAMPLE_ID_DESC',
  AnnotationsByArchivedAtIdStddevSampleIsDeletedAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_STDDEV_SAMPLE_IS_DELETED_ASC',
  AnnotationsByArchivedAtIdStddevSampleIsDeletedDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_STDDEV_SAMPLE_IS_DELETED_DESC',
  AnnotationsByArchivedAtIdStddevSampleResourceIdAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_STDDEV_SAMPLE_RESOURCE_ID_ASC',
  AnnotationsByArchivedAtIdStddevSampleResourceIdDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_STDDEV_SAMPLE_RESOURCE_ID_DESC',
  AnnotationsByArchivedAtIdStddevSampleWorkspaceIdAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_STDDEV_SAMPLE_WORKSPACE_ID_ASC',
  AnnotationsByArchivedAtIdStddevSampleWorkspaceIdDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_STDDEV_SAMPLE_WORKSPACE_ID_DESC',
  AnnotationsByArchivedAtIdSumAnnotationTypeAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_SUM_ANNOTATION_TYPE_ASC',
  AnnotationsByArchivedAtIdSumAnnotationTypeDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_SUM_ANNOTATION_TYPE_DESC',
  AnnotationsByArchivedAtIdSumArchivedAtIdAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_SUM_ARCHIVED_AT_ID_ASC',
  AnnotationsByArchivedAtIdSumArchivedAtIdDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_SUM_ARCHIVED_AT_ID_DESC',
  AnnotationsByArchivedAtIdSumIdAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_SUM_ID_ASC',
  AnnotationsByArchivedAtIdSumIdDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_SUM_ID_DESC',
  AnnotationsByArchivedAtIdSumIsDeletedAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_SUM_IS_DELETED_ASC',
  AnnotationsByArchivedAtIdSumIsDeletedDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_SUM_IS_DELETED_DESC',
  AnnotationsByArchivedAtIdSumResourceIdAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_SUM_RESOURCE_ID_ASC',
  AnnotationsByArchivedAtIdSumResourceIdDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_SUM_RESOURCE_ID_DESC',
  AnnotationsByArchivedAtIdSumWorkspaceIdAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_SUM_WORKSPACE_ID_ASC',
  AnnotationsByArchivedAtIdSumWorkspaceIdDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_SUM_WORKSPACE_ID_DESC',
  AnnotationsByArchivedAtIdVariancePopulationAnnotationTypeAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_VARIANCE_POPULATION_ANNOTATION_TYPE_ASC',
  AnnotationsByArchivedAtIdVariancePopulationAnnotationTypeDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_VARIANCE_POPULATION_ANNOTATION_TYPE_DESC',
  AnnotationsByArchivedAtIdVariancePopulationArchivedAtIdAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_VARIANCE_POPULATION_ARCHIVED_AT_ID_ASC',
  AnnotationsByArchivedAtIdVariancePopulationArchivedAtIdDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_VARIANCE_POPULATION_ARCHIVED_AT_ID_DESC',
  AnnotationsByArchivedAtIdVariancePopulationIdAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_VARIANCE_POPULATION_ID_ASC',
  AnnotationsByArchivedAtIdVariancePopulationIdDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_VARIANCE_POPULATION_ID_DESC',
  AnnotationsByArchivedAtIdVariancePopulationIsDeletedAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_VARIANCE_POPULATION_IS_DELETED_ASC',
  AnnotationsByArchivedAtIdVariancePopulationIsDeletedDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_VARIANCE_POPULATION_IS_DELETED_DESC',
  AnnotationsByArchivedAtIdVariancePopulationResourceIdAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_VARIANCE_POPULATION_RESOURCE_ID_ASC',
  AnnotationsByArchivedAtIdVariancePopulationResourceIdDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_VARIANCE_POPULATION_RESOURCE_ID_DESC',
  AnnotationsByArchivedAtIdVariancePopulationWorkspaceIdAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_VARIANCE_POPULATION_WORKSPACE_ID_ASC',
  AnnotationsByArchivedAtIdVariancePopulationWorkspaceIdDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_VARIANCE_POPULATION_WORKSPACE_ID_DESC',
  AnnotationsByArchivedAtIdVarianceSampleAnnotationTypeAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_VARIANCE_SAMPLE_ANNOTATION_TYPE_ASC',
  AnnotationsByArchivedAtIdVarianceSampleAnnotationTypeDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_VARIANCE_SAMPLE_ANNOTATION_TYPE_DESC',
  AnnotationsByArchivedAtIdVarianceSampleArchivedAtIdAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_VARIANCE_SAMPLE_ARCHIVED_AT_ID_ASC',
  AnnotationsByArchivedAtIdVarianceSampleArchivedAtIdDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_VARIANCE_SAMPLE_ARCHIVED_AT_ID_DESC',
  AnnotationsByArchivedAtIdVarianceSampleIdAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_VARIANCE_SAMPLE_ID_ASC',
  AnnotationsByArchivedAtIdVarianceSampleIdDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_VARIANCE_SAMPLE_ID_DESC',
  AnnotationsByArchivedAtIdVarianceSampleIsDeletedAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_VARIANCE_SAMPLE_IS_DELETED_ASC',
  AnnotationsByArchivedAtIdVarianceSampleIsDeletedDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_VARIANCE_SAMPLE_IS_DELETED_DESC',
  AnnotationsByArchivedAtIdVarianceSampleResourceIdAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_VARIANCE_SAMPLE_RESOURCE_ID_ASC',
  AnnotationsByArchivedAtIdVarianceSampleResourceIdDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_VARIANCE_SAMPLE_RESOURCE_ID_DESC',
  AnnotationsByArchivedAtIdVarianceSampleWorkspaceIdAsc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_VARIANCE_SAMPLE_WORKSPACE_ID_ASC',
  AnnotationsByArchivedAtIdVarianceSampleWorkspaceIdDesc = 'ANNOTATIONS_BY_ARCHIVED_AT_ID_VARIANCE_SAMPLE_WORKSPACE_ID_DESC',
  ChildFilesRelationsAverageChildIdAsc = 'CHILD_FILES_RELATIONS_AVERAGE_CHILD_ID_ASC',
  ChildFilesRelationsAverageChildIdDesc = 'CHILD_FILES_RELATIONS_AVERAGE_CHILD_ID_DESC',
  ChildFilesRelationsAverageIdAsc = 'CHILD_FILES_RELATIONS_AVERAGE_ID_ASC',
  ChildFilesRelationsAverageIdDesc = 'CHILD_FILES_RELATIONS_AVERAGE_ID_DESC',
  ChildFilesRelationsAverageParentIdAsc = 'CHILD_FILES_RELATIONS_AVERAGE_PARENT_ID_ASC',
  ChildFilesRelationsAverageParentIdDesc = 'CHILD_FILES_RELATIONS_AVERAGE_PARENT_ID_DESC',
  ChildFilesRelationsCountAsc = 'CHILD_FILES_RELATIONS_COUNT_ASC',
  ChildFilesRelationsCountDesc = 'CHILD_FILES_RELATIONS_COUNT_DESC',
  ChildFilesRelationsDistinctCountChildIdAsc = 'CHILD_FILES_RELATIONS_DISTINCT_COUNT_CHILD_ID_ASC',
  ChildFilesRelationsDistinctCountChildIdDesc = 'CHILD_FILES_RELATIONS_DISTINCT_COUNT_CHILD_ID_DESC',
  ChildFilesRelationsDistinctCountIdAsc = 'CHILD_FILES_RELATIONS_DISTINCT_COUNT_ID_ASC',
  ChildFilesRelationsDistinctCountIdDesc = 'CHILD_FILES_RELATIONS_DISTINCT_COUNT_ID_DESC',
  ChildFilesRelationsDistinctCountParentIdAsc = 'CHILD_FILES_RELATIONS_DISTINCT_COUNT_PARENT_ID_ASC',
  ChildFilesRelationsDistinctCountParentIdDesc = 'CHILD_FILES_RELATIONS_DISTINCT_COUNT_PARENT_ID_DESC',
  ChildFilesRelationsMaxChildIdAsc = 'CHILD_FILES_RELATIONS_MAX_CHILD_ID_ASC',
  ChildFilesRelationsMaxChildIdDesc = 'CHILD_FILES_RELATIONS_MAX_CHILD_ID_DESC',
  ChildFilesRelationsMaxIdAsc = 'CHILD_FILES_RELATIONS_MAX_ID_ASC',
  ChildFilesRelationsMaxIdDesc = 'CHILD_FILES_RELATIONS_MAX_ID_DESC',
  ChildFilesRelationsMaxParentIdAsc = 'CHILD_FILES_RELATIONS_MAX_PARENT_ID_ASC',
  ChildFilesRelationsMaxParentIdDesc = 'CHILD_FILES_RELATIONS_MAX_PARENT_ID_DESC',
  ChildFilesRelationsMinChildIdAsc = 'CHILD_FILES_RELATIONS_MIN_CHILD_ID_ASC',
  ChildFilesRelationsMinChildIdDesc = 'CHILD_FILES_RELATIONS_MIN_CHILD_ID_DESC',
  ChildFilesRelationsMinIdAsc = 'CHILD_FILES_RELATIONS_MIN_ID_ASC',
  ChildFilesRelationsMinIdDesc = 'CHILD_FILES_RELATIONS_MIN_ID_DESC',
  ChildFilesRelationsMinParentIdAsc = 'CHILD_FILES_RELATIONS_MIN_PARENT_ID_ASC',
  ChildFilesRelationsMinParentIdDesc = 'CHILD_FILES_RELATIONS_MIN_PARENT_ID_DESC',
  ChildFilesRelationsStddevPopulationChildIdAsc = 'CHILD_FILES_RELATIONS_STDDEV_POPULATION_CHILD_ID_ASC',
  ChildFilesRelationsStddevPopulationChildIdDesc = 'CHILD_FILES_RELATIONS_STDDEV_POPULATION_CHILD_ID_DESC',
  ChildFilesRelationsStddevPopulationIdAsc = 'CHILD_FILES_RELATIONS_STDDEV_POPULATION_ID_ASC',
  ChildFilesRelationsStddevPopulationIdDesc = 'CHILD_FILES_RELATIONS_STDDEV_POPULATION_ID_DESC',
  ChildFilesRelationsStddevPopulationParentIdAsc = 'CHILD_FILES_RELATIONS_STDDEV_POPULATION_PARENT_ID_ASC',
  ChildFilesRelationsStddevPopulationParentIdDesc = 'CHILD_FILES_RELATIONS_STDDEV_POPULATION_PARENT_ID_DESC',
  ChildFilesRelationsStddevSampleChildIdAsc = 'CHILD_FILES_RELATIONS_STDDEV_SAMPLE_CHILD_ID_ASC',
  ChildFilesRelationsStddevSampleChildIdDesc = 'CHILD_FILES_RELATIONS_STDDEV_SAMPLE_CHILD_ID_DESC',
  ChildFilesRelationsStddevSampleIdAsc = 'CHILD_FILES_RELATIONS_STDDEV_SAMPLE_ID_ASC',
  ChildFilesRelationsStddevSampleIdDesc = 'CHILD_FILES_RELATIONS_STDDEV_SAMPLE_ID_DESC',
  ChildFilesRelationsStddevSampleParentIdAsc = 'CHILD_FILES_RELATIONS_STDDEV_SAMPLE_PARENT_ID_ASC',
  ChildFilesRelationsStddevSampleParentIdDesc = 'CHILD_FILES_RELATIONS_STDDEV_SAMPLE_PARENT_ID_DESC',
  ChildFilesRelationsSumChildIdAsc = 'CHILD_FILES_RELATIONS_SUM_CHILD_ID_ASC',
  ChildFilesRelationsSumChildIdDesc = 'CHILD_FILES_RELATIONS_SUM_CHILD_ID_DESC',
  ChildFilesRelationsSumIdAsc = 'CHILD_FILES_RELATIONS_SUM_ID_ASC',
  ChildFilesRelationsSumIdDesc = 'CHILD_FILES_RELATIONS_SUM_ID_DESC',
  ChildFilesRelationsSumParentIdAsc = 'CHILD_FILES_RELATIONS_SUM_PARENT_ID_ASC',
  ChildFilesRelationsSumParentIdDesc = 'CHILD_FILES_RELATIONS_SUM_PARENT_ID_DESC',
  ChildFilesRelationsVariancePopulationChildIdAsc = 'CHILD_FILES_RELATIONS_VARIANCE_POPULATION_CHILD_ID_ASC',
  ChildFilesRelationsVariancePopulationChildIdDesc = 'CHILD_FILES_RELATIONS_VARIANCE_POPULATION_CHILD_ID_DESC',
  ChildFilesRelationsVariancePopulationIdAsc = 'CHILD_FILES_RELATIONS_VARIANCE_POPULATION_ID_ASC',
  ChildFilesRelationsVariancePopulationIdDesc = 'CHILD_FILES_RELATIONS_VARIANCE_POPULATION_ID_DESC',
  ChildFilesRelationsVariancePopulationParentIdAsc = 'CHILD_FILES_RELATIONS_VARIANCE_POPULATION_PARENT_ID_ASC',
  ChildFilesRelationsVariancePopulationParentIdDesc = 'CHILD_FILES_RELATIONS_VARIANCE_POPULATION_PARENT_ID_DESC',
  ChildFilesRelationsVarianceSampleChildIdAsc = 'CHILD_FILES_RELATIONS_VARIANCE_SAMPLE_CHILD_ID_ASC',
  ChildFilesRelationsVarianceSampleChildIdDesc = 'CHILD_FILES_RELATIONS_VARIANCE_SAMPLE_CHILD_ID_DESC',
  ChildFilesRelationsVarianceSampleIdAsc = 'CHILD_FILES_RELATIONS_VARIANCE_SAMPLE_ID_ASC',
  ChildFilesRelationsVarianceSampleIdDesc = 'CHILD_FILES_RELATIONS_VARIANCE_SAMPLE_ID_DESC',
  ChildFilesRelationsVarianceSampleParentIdAsc = 'CHILD_FILES_RELATIONS_VARIANCE_SAMPLE_PARENT_ID_ASC',
  ChildFilesRelationsVarianceSampleParentIdDesc = 'CHILD_FILES_RELATIONS_VARIANCE_SAMPLE_PARENT_ID_DESC',
  CommitHashIdAsc = 'COMMIT_HASH_ID_ASC',
  CommitHashIdDesc = 'COMMIT_HASH_ID_DESC',
  FileContentIdAsc = 'FILE_CONTENT_ID_ASC',
  FileContentIdDesc = 'FILE_CONTENT_ID_DESC',
  FileLinesByLastActiveIdAverageAnnotationIdAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_AVERAGE_ANNOTATION_ID_ASC',
  FileLinesByLastActiveIdAverageAnnotationIdDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_AVERAGE_ANNOTATION_ID_DESC',
  FileLinesByLastActiveIdAverageEndLineNumberAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_AVERAGE_END_LINE_NUMBER_ASC',
  FileLinesByLastActiveIdAverageEndLineNumberDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_AVERAGE_END_LINE_NUMBER_DESC',
  FileLinesByLastActiveIdAverageFileContentIdAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_AVERAGE_FILE_CONTENT_ID_ASC',
  FileLinesByLastActiveIdAverageFileContentIdDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_AVERAGE_FILE_CONTENT_ID_DESC',
  FileLinesByLastActiveIdAverageIdAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_AVERAGE_ID_ASC',
  FileLinesByLastActiveIdAverageIdDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_AVERAGE_ID_DESC',
  FileLinesByLastActiveIdAverageLastActiveIdAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_AVERAGE_LAST_ACTIVE_ID_ASC',
  FileLinesByLastActiveIdAverageLastActiveIdDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_AVERAGE_LAST_ACTIVE_ID_DESC',
  FileLinesByLastActiveIdAverageStartLineNumberAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_AVERAGE_START_LINE_NUMBER_ASC',
  FileLinesByLastActiveIdAverageStartLineNumberDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_AVERAGE_START_LINE_NUMBER_DESC',
  FileLinesByLastActiveIdCountAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_COUNT_ASC',
  FileLinesByLastActiveIdCountDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_COUNT_DESC',
  FileLinesByLastActiveIdDistinctCountAnnotationIdAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_DISTINCT_COUNT_ANNOTATION_ID_ASC',
  FileLinesByLastActiveIdDistinctCountAnnotationIdDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_DISTINCT_COUNT_ANNOTATION_ID_DESC',
  FileLinesByLastActiveIdDistinctCountEndLineNumberAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_DISTINCT_COUNT_END_LINE_NUMBER_ASC',
  FileLinesByLastActiveIdDistinctCountEndLineNumberDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_DISTINCT_COUNT_END_LINE_NUMBER_DESC',
  FileLinesByLastActiveIdDistinctCountFileContentIdAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_DISTINCT_COUNT_FILE_CONTENT_ID_ASC',
  FileLinesByLastActiveIdDistinctCountFileContentIdDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_DISTINCT_COUNT_FILE_CONTENT_ID_DESC',
  FileLinesByLastActiveIdDistinctCountIdAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_DISTINCT_COUNT_ID_ASC',
  FileLinesByLastActiveIdDistinctCountIdDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_DISTINCT_COUNT_ID_DESC',
  FileLinesByLastActiveIdDistinctCountLastActiveIdAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_DISTINCT_COUNT_LAST_ACTIVE_ID_ASC',
  FileLinesByLastActiveIdDistinctCountLastActiveIdDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_DISTINCT_COUNT_LAST_ACTIVE_ID_DESC',
  FileLinesByLastActiveIdDistinctCountStartLineNumberAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_DISTINCT_COUNT_START_LINE_NUMBER_ASC',
  FileLinesByLastActiveIdDistinctCountStartLineNumberDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_DISTINCT_COUNT_START_LINE_NUMBER_DESC',
  FileLinesByLastActiveIdMaxAnnotationIdAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_MAX_ANNOTATION_ID_ASC',
  FileLinesByLastActiveIdMaxAnnotationIdDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_MAX_ANNOTATION_ID_DESC',
  FileLinesByLastActiveIdMaxEndLineNumberAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_MAX_END_LINE_NUMBER_ASC',
  FileLinesByLastActiveIdMaxEndLineNumberDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_MAX_END_LINE_NUMBER_DESC',
  FileLinesByLastActiveIdMaxFileContentIdAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_MAX_FILE_CONTENT_ID_ASC',
  FileLinesByLastActiveIdMaxFileContentIdDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_MAX_FILE_CONTENT_ID_DESC',
  FileLinesByLastActiveIdMaxIdAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_MAX_ID_ASC',
  FileLinesByLastActiveIdMaxIdDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_MAX_ID_DESC',
  FileLinesByLastActiveIdMaxLastActiveIdAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_MAX_LAST_ACTIVE_ID_ASC',
  FileLinesByLastActiveIdMaxLastActiveIdDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_MAX_LAST_ACTIVE_ID_DESC',
  FileLinesByLastActiveIdMaxStartLineNumberAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_MAX_START_LINE_NUMBER_ASC',
  FileLinesByLastActiveIdMaxStartLineNumberDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_MAX_START_LINE_NUMBER_DESC',
  FileLinesByLastActiveIdMinAnnotationIdAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_MIN_ANNOTATION_ID_ASC',
  FileLinesByLastActiveIdMinAnnotationIdDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_MIN_ANNOTATION_ID_DESC',
  FileLinesByLastActiveIdMinEndLineNumberAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_MIN_END_LINE_NUMBER_ASC',
  FileLinesByLastActiveIdMinEndLineNumberDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_MIN_END_LINE_NUMBER_DESC',
  FileLinesByLastActiveIdMinFileContentIdAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_MIN_FILE_CONTENT_ID_ASC',
  FileLinesByLastActiveIdMinFileContentIdDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_MIN_FILE_CONTENT_ID_DESC',
  FileLinesByLastActiveIdMinIdAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_MIN_ID_ASC',
  FileLinesByLastActiveIdMinIdDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_MIN_ID_DESC',
  FileLinesByLastActiveIdMinLastActiveIdAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_MIN_LAST_ACTIVE_ID_ASC',
  FileLinesByLastActiveIdMinLastActiveIdDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_MIN_LAST_ACTIVE_ID_DESC',
  FileLinesByLastActiveIdMinStartLineNumberAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_MIN_START_LINE_NUMBER_ASC',
  FileLinesByLastActiveIdMinStartLineNumberDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_MIN_START_LINE_NUMBER_DESC',
  FileLinesByLastActiveIdStddevPopulationAnnotationIdAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_STDDEV_POPULATION_ANNOTATION_ID_ASC',
  FileLinesByLastActiveIdStddevPopulationAnnotationIdDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_STDDEV_POPULATION_ANNOTATION_ID_DESC',
  FileLinesByLastActiveIdStddevPopulationEndLineNumberAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_STDDEV_POPULATION_END_LINE_NUMBER_ASC',
  FileLinesByLastActiveIdStddevPopulationEndLineNumberDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_STDDEV_POPULATION_END_LINE_NUMBER_DESC',
  FileLinesByLastActiveIdStddevPopulationFileContentIdAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_STDDEV_POPULATION_FILE_CONTENT_ID_ASC',
  FileLinesByLastActiveIdStddevPopulationFileContentIdDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_STDDEV_POPULATION_FILE_CONTENT_ID_DESC',
  FileLinesByLastActiveIdStddevPopulationIdAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_STDDEV_POPULATION_ID_ASC',
  FileLinesByLastActiveIdStddevPopulationIdDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_STDDEV_POPULATION_ID_DESC',
  FileLinesByLastActiveIdStddevPopulationLastActiveIdAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_STDDEV_POPULATION_LAST_ACTIVE_ID_ASC',
  FileLinesByLastActiveIdStddevPopulationLastActiveIdDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_STDDEV_POPULATION_LAST_ACTIVE_ID_DESC',
  FileLinesByLastActiveIdStddevPopulationStartLineNumberAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_STDDEV_POPULATION_START_LINE_NUMBER_ASC',
  FileLinesByLastActiveIdStddevPopulationStartLineNumberDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_STDDEV_POPULATION_START_LINE_NUMBER_DESC',
  FileLinesByLastActiveIdStddevSampleAnnotationIdAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_STDDEV_SAMPLE_ANNOTATION_ID_ASC',
  FileLinesByLastActiveIdStddevSampleAnnotationIdDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_STDDEV_SAMPLE_ANNOTATION_ID_DESC',
  FileLinesByLastActiveIdStddevSampleEndLineNumberAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_STDDEV_SAMPLE_END_LINE_NUMBER_ASC',
  FileLinesByLastActiveIdStddevSampleEndLineNumberDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_STDDEV_SAMPLE_END_LINE_NUMBER_DESC',
  FileLinesByLastActiveIdStddevSampleFileContentIdAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_STDDEV_SAMPLE_FILE_CONTENT_ID_ASC',
  FileLinesByLastActiveIdStddevSampleFileContentIdDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_STDDEV_SAMPLE_FILE_CONTENT_ID_DESC',
  FileLinesByLastActiveIdStddevSampleIdAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_STDDEV_SAMPLE_ID_ASC',
  FileLinesByLastActiveIdStddevSampleIdDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_STDDEV_SAMPLE_ID_DESC',
  FileLinesByLastActiveIdStddevSampleLastActiveIdAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_STDDEV_SAMPLE_LAST_ACTIVE_ID_ASC',
  FileLinesByLastActiveIdStddevSampleLastActiveIdDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_STDDEV_SAMPLE_LAST_ACTIVE_ID_DESC',
  FileLinesByLastActiveIdStddevSampleStartLineNumberAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_STDDEV_SAMPLE_START_LINE_NUMBER_ASC',
  FileLinesByLastActiveIdStddevSampleStartLineNumberDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_STDDEV_SAMPLE_START_LINE_NUMBER_DESC',
  FileLinesByLastActiveIdSumAnnotationIdAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_SUM_ANNOTATION_ID_ASC',
  FileLinesByLastActiveIdSumAnnotationIdDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_SUM_ANNOTATION_ID_DESC',
  FileLinesByLastActiveIdSumEndLineNumberAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_SUM_END_LINE_NUMBER_ASC',
  FileLinesByLastActiveIdSumEndLineNumberDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_SUM_END_LINE_NUMBER_DESC',
  FileLinesByLastActiveIdSumFileContentIdAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_SUM_FILE_CONTENT_ID_ASC',
  FileLinesByLastActiveIdSumFileContentIdDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_SUM_FILE_CONTENT_ID_DESC',
  FileLinesByLastActiveIdSumIdAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_SUM_ID_ASC',
  FileLinesByLastActiveIdSumIdDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_SUM_ID_DESC',
  FileLinesByLastActiveIdSumLastActiveIdAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_SUM_LAST_ACTIVE_ID_ASC',
  FileLinesByLastActiveIdSumLastActiveIdDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_SUM_LAST_ACTIVE_ID_DESC',
  FileLinesByLastActiveIdSumStartLineNumberAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_SUM_START_LINE_NUMBER_ASC',
  FileLinesByLastActiveIdSumStartLineNumberDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_SUM_START_LINE_NUMBER_DESC',
  FileLinesByLastActiveIdVariancePopulationAnnotationIdAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_VARIANCE_POPULATION_ANNOTATION_ID_ASC',
  FileLinesByLastActiveIdVariancePopulationAnnotationIdDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_VARIANCE_POPULATION_ANNOTATION_ID_DESC',
  FileLinesByLastActiveIdVariancePopulationEndLineNumberAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_VARIANCE_POPULATION_END_LINE_NUMBER_ASC',
  FileLinesByLastActiveIdVariancePopulationEndLineNumberDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_VARIANCE_POPULATION_END_LINE_NUMBER_DESC',
  FileLinesByLastActiveIdVariancePopulationFileContentIdAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_VARIANCE_POPULATION_FILE_CONTENT_ID_ASC',
  FileLinesByLastActiveIdVariancePopulationFileContentIdDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_VARIANCE_POPULATION_FILE_CONTENT_ID_DESC',
  FileLinesByLastActiveIdVariancePopulationIdAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_VARIANCE_POPULATION_ID_ASC',
  FileLinesByLastActiveIdVariancePopulationIdDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_VARIANCE_POPULATION_ID_DESC',
  FileLinesByLastActiveIdVariancePopulationLastActiveIdAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_VARIANCE_POPULATION_LAST_ACTIVE_ID_ASC',
  FileLinesByLastActiveIdVariancePopulationLastActiveIdDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_VARIANCE_POPULATION_LAST_ACTIVE_ID_DESC',
  FileLinesByLastActiveIdVariancePopulationStartLineNumberAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_VARIANCE_POPULATION_START_LINE_NUMBER_ASC',
  FileLinesByLastActiveIdVariancePopulationStartLineNumberDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_VARIANCE_POPULATION_START_LINE_NUMBER_DESC',
  FileLinesByLastActiveIdVarianceSampleAnnotationIdAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_VARIANCE_SAMPLE_ANNOTATION_ID_ASC',
  FileLinesByLastActiveIdVarianceSampleAnnotationIdDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_VARIANCE_SAMPLE_ANNOTATION_ID_DESC',
  FileLinesByLastActiveIdVarianceSampleEndLineNumberAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_VARIANCE_SAMPLE_END_LINE_NUMBER_ASC',
  FileLinesByLastActiveIdVarianceSampleEndLineNumberDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_VARIANCE_SAMPLE_END_LINE_NUMBER_DESC',
  FileLinesByLastActiveIdVarianceSampleFileContentIdAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_VARIANCE_SAMPLE_FILE_CONTENT_ID_ASC',
  FileLinesByLastActiveIdVarianceSampleFileContentIdDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_VARIANCE_SAMPLE_FILE_CONTENT_ID_DESC',
  FileLinesByLastActiveIdVarianceSampleIdAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_VARIANCE_SAMPLE_ID_ASC',
  FileLinesByLastActiveIdVarianceSampleIdDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_VARIANCE_SAMPLE_ID_DESC',
  FileLinesByLastActiveIdVarianceSampleLastActiveIdAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_VARIANCE_SAMPLE_LAST_ACTIVE_ID_ASC',
  FileLinesByLastActiveIdVarianceSampleLastActiveIdDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_VARIANCE_SAMPLE_LAST_ACTIVE_ID_DESC',
  FileLinesByLastActiveIdVarianceSampleStartLineNumberAsc = 'FILE_LINES_BY_LAST_ACTIVE_ID_VARIANCE_SAMPLE_START_LINE_NUMBER_ASC',
  FileLinesByLastActiveIdVarianceSampleStartLineNumberDesc = 'FILE_LINES_BY_LAST_ACTIVE_ID_VARIANCE_SAMPLE_START_LINE_NUMBER_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  ParentFilesRelationsAverageChildIdAsc = 'PARENT_FILES_RELATIONS_AVERAGE_CHILD_ID_ASC',
  ParentFilesRelationsAverageChildIdDesc = 'PARENT_FILES_RELATIONS_AVERAGE_CHILD_ID_DESC',
  ParentFilesRelationsAverageIdAsc = 'PARENT_FILES_RELATIONS_AVERAGE_ID_ASC',
  ParentFilesRelationsAverageIdDesc = 'PARENT_FILES_RELATIONS_AVERAGE_ID_DESC',
  ParentFilesRelationsAverageParentIdAsc = 'PARENT_FILES_RELATIONS_AVERAGE_PARENT_ID_ASC',
  ParentFilesRelationsAverageParentIdDesc = 'PARENT_FILES_RELATIONS_AVERAGE_PARENT_ID_DESC',
  ParentFilesRelationsCountAsc = 'PARENT_FILES_RELATIONS_COUNT_ASC',
  ParentFilesRelationsCountDesc = 'PARENT_FILES_RELATIONS_COUNT_DESC',
  ParentFilesRelationsDistinctCountChildIdAsc = 'PARENT_FILES_RELATIONS_DISTINCT_COUNT_CHILD_ID_ASC',
  ParentFilesRelationsDistinctCountChildIdDesc = 'PARENT_FILES_RELATIONS_DISTINCT_COUNT_CHILD_ID_DESC',
  ParentFilesRelationsDistinctCountIdAsc = 'PARENT_FILES_RELATIONS_DISTINCT_COUNT_ID_ASC',
  ParentFilesRelationsDistinctCountIdDesc = 'PARENT_FILES_RELATIONS_DISTINCT_COUNT_ID_DESC',
  ParentFilesRelationsDistinctCountParentIdAsc = 'PARENT_FILES_RELATIONS_DISTINCT_COUNT_PARENT_ID_ASC',
  ParentFilesRelationsDistinctCountParentIdDesc = 'PARENT_FILES_RELATIONS_DISTINCT_COUNT_PARENT_ID_DESC',
  ParentFilesRelationsMaxChildIdAsc = 'PARENT_FILES_RELATIONS_MAX_CHILD_ID_ASC',
  ParentFilesRelationsMaxChildIdDesc = 'PARENT_FILES_RELATIONS_MAX_CHILD_ID_DESC',
  ParentFilesRelationsMaxIdAsc = 'PARENT_FILES_RELATIONS_MAX_ID_ASC',
  ParentFilesRelationsMaxIdDesc = 'PARENT_FILES_RELATIONS_MAX_ID_DESC',
  ParentFilesRelationsMaxParentIdAsc = 'PARENT_FILES_RELATIONS_MAX_PARENT_ID_ASC',
  ParentFilesRelationsMaxParentIdDesc = 'PARENT_FILES_RELATIONS_MAX_PARENT_ID_DESC',
  ParentFilesRelationsMinChildIdAsc = 'PARENT_FILES_RELATIONS_MIN_CHILD_ID_ASC',
  ParentFilesRelationsMinChildIdDesc = 'PARENT_FILES_RELATIONS_MIN_CHILD_ID_DESC',
  ParentFilesRelationsMinIdAsc = 'PARENT_FILES_RELATIONS_MIN_ID_ASC',
  ParentFilesRelationsMinIdDesc = 'PARENT_FILES_RELATIONS_MIN_ID_DESC',
  ParentFilesRelationsMinParentIdAsc = 'PARENT_FILES_RELATIONS_MIN_PARENT_ID_ASC',
  ParentFilesRelationsMinParentIdDesc = 'PARENT_FILES_RELATIONS_MIN_PARENT_ID_DESC',
  ParentFilesRelationsStddevPopulationChildIdAsc = 'PARENT_FILES_RELATIONS_STDDEV_POPULATION_CHILD_ID_ASC',
  ParentFilesRelationsStddevPopulationChildIdDesc = 'PARENT_FILES_RELATIONS_STDDEV_POPULATION_CHILD_ID_DESC',
  ParentFilesRelationsStddevPopulationIdAsc = 'PARENT_FILES_RELATIONS_STDDEV_POPULATION_ID_ASC',
  ParentFilesRelationsStddevPopulationIdDesc = 'PARENT_FILES_RELATIONS_STDDEV_POPULATION_ID_DESC',
  ParentFilesRelationsStddevPopulationParentIdAsc = 'PARENT_FILES_RELATIONS_STDDEV_POPULATION_PARENT_ID_ASC',
  ParentFilesRelationsStddevPopulationParentIdDesc = 'PARENT_FILES_RELATIONS_STDDEV_POPULATION_PARENT_ID_DESC',
  ParentFilesRelationsStddevSampleChildIdAsc = 'PARENT_FILES_RELATIONS_STDDEV_SAMPLE_CHILD_ID_ASC',
  ParentFilesRelationsStddevSampleChildIdDesc = 'PARENT_FILES_RELATIONS_STDDEV_SAMPLE_CHILD_ID_DESC',
  ParentFilesRelationsStddevSampleIdAsc = 'PARENT_FILES_RELATIONS_STDDEV_SAMPLE_ID_ASC',
  ParentFilesRelationsStddevSampleIdDesc = 'PARENT_FILES_RELATIONS_STDDEV_SAMPLE_ID_DESC',
  ParentFilesRelationsStddevSampleParentIdAsc = 'PARENT_FILES_RELATIONS_STDDEV_SAMPLE_PARENT_ID_ASC',
  ParentFilesRelationsStddevSampleParentIdDesc = 'PARENT_FILES_RELATIONS_STDDEV_SAMPLE_PARENT_ID_DESC',
  ParentFilesRelationsSumChildIdAsc = 'PARENT_FILES_RELATIONS_SUM_CHILD_ID_ASC',
  ParentFilesRelationsSumChildIdDesc = 'PARENT_FILES_RELATIONS_SUM_CHILD_ID_DESC',
  ParentFilesRelationsSumIdAsc = 'PARENT_FILES_RELATIONS_SUM_ID_ASC',
  ParentFilesRelationsSumIdDesc = 'PARENT_FILES_RELATIONS_SUM_ID_DESC',
  ParentFilesRelationsSumParentIdAsc = 'PARENT_FILES_RELATIONS_SUM_PARENT_ID_ASC',
  ParentFilesRelationsSumParentIdDesc = 'PARENT_FILES_RELATIONS_SUM_PARENT_ID_DESC',
  ParentFilesRelationsVariancePopulationChildIdAsc = 'PARENT_FILES_RELATIONS_VARIANCE_POPULATION_CHILD_ID_ASC',
  ParentFilesRelationsVariancePopulationChildIdDesc = 'PARENT_FILES_RELATIONS_VARIANCE_POPULATION_CHILD_ID_DESC',
  ParentFilesRelationsVariancePopulationIdAsc = 'PARENT_FILES_RELATIONS_VARIANCE_POPULATION_ID_ASC',
  ParentFilesRelationsVariancePopulationIdDesc = 'PARENT_FILES_RELATIONS_VARIANCE_POPULATION_ID_DESC',
  ParentFilesRelationsVariancePopulationParentIdAsc = 'PARENT_FILES_RELATIONS_VARIANCE_POPULATION_PARENT_ID_ASC',
  ParentFilesRelationsVariancePopulationParentIdDesc = 'PARENT_FILES_RELATIONS_VARIANCE_POPULATION_PARENT_ID_DESC',
  ParentFilesRelationsVarianceSampleChildIdAsc = 'PARENT_FILES_RELATIONS_VARIANCE_SAMPLE_CHILD_ID_ASC',
  ParentFilesRelationsVarianceSampleChildIdDesc = 'PARENT_FILES_RELATIONS_VARIANCE_SAMPLE_CHILD_ID_DESC',
  ParentFilesRelationsVarianceSampleIdAsc = 'PARENT_FILES_RELATIONS_VARIANCE_SAMPLE_ID_ASC',
  ParentFilesRelationsVarianceSampleIdDesc = 'PARENT_FILES_RELATIONS_VARIANCE_SAMPLE_ID_DESC',
  ParentFilesRelationsVarianceSampleParentIdAsc = 'PARENT_FILES_RELATIONS_VARIANCE_SAMPLE_PARENT_ID_ASC',
  ParentFilesRelationsVarianceSampleParentIdDesc = 'PARENT_FILES_RELATIONS_VARIANCE_SAMPLE_PARENT_ID_DESC',
  PathAsc = 'PATH_ASC',
  PathDesc = 'PATH_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type FilesRelation = Node & {
  __typename?: 'FilesRelation';
  /** Reads a single `File` that is related to this `FilesRelation`. */
  child?: Maybe<File>;
  childId: Scalars['BigInt'];
  id: Scalars['BigInt'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `File` that is related to this `FilesRelation`. */
  parent?: Maybe<File>;
  parentId: Scalars['BigInt'];
};

export type FilesRelationAggregates = {
  __typename?: 'FilesRelationAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<FilesRelationAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<FilesRelationDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<FilesRelationMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<FilesRelationMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<FilesRelationStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<FilesRelationStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<FilesRelationSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<FilesRelationVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<FilesRelationVarianceSampleAggregates>;
};

export type FilesRelationAverageAggregates = {
  __typename?: 'FilesRelationAverageAggregates';
  /** Mean average of childId across the matching connection */
  childId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `FilesRelation` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type FilesRelationCondition = {
  /** Checks for equality with the object’s `childId` field. */
  childId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `parentId` field. */
  parentId?: InputMaybe<Scalars['BigInt']>;
};

export type FilesRelationDistinctCountAggregates = {
  __typename?: 'FilesRelationDistinctCountAggregates';
  /** Distinct count of childId across the matching connection */
  childId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigInt']>;
};

export type FilesRelationMaxAggregates = {
  __typename?: 'FilesRelationMaxAggregates';
  /** Maximum of childId across the matching connection */
  childId?: Maybe<Scalars['BigInt']>;
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Maximum of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigInt']>;
};

export type FilesRelationMinAggregates = {
  __typename?: 'FilesRelationMinAggregates';
  /** Minimum of childId across the matching connection */
  childId?: Maybe<Scalars['BigInt']>;
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Minimum of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigInt']>;
};

export type FilesRelationStddevPopulationAggregates = {
  __typename?: 'FilesRelationStddevPopulationAggregates';
  /** Population standard deviation of childId across the matching connection */
  childId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigFloat']>;
};

export type FilesRelationStddevSampleAggregates = {
  __typename?: 'FilesRelationStddevSampleAggregates';
  /** Sample standard deviation of childId across the matching connection */
  childId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigFloat']>;
};

export type FilesRelationSumAggregates = {
  __typename?: 'FilesRelationSumAggregates';
  /** Sum of childId across the matching connection */
  childId: Scalars['BigFloat'];
  /** Sum of id across the matching connection */
  id: Scalars['BigFloat'];
  /** Sum of parentId across the matching connection */
  parentId: Scalars['BigFloat'];
};

export type FilesRelationVariancePopulationAggregates = {
  __typename?: 'FilesRelationVariancePopulationAggregates';
  /** Population variance of childId across the matching connection */
  childId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigFloat']>;
};

export type FilesRelationVarianceSampleAggregates = {
  __typename?: 'FilesRelationVarianceSampleAggregates';
  /** Sample variance of childId across the matching connection */
  childId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of parentId across the matching connection */
  parentId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `FilesRelation` values. */
export type FilesRelationsConnection = {
  __typename?: 'FilesRelationsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<FilesRelationAggregates>;
  /** A list of edges which contains the `FilesRelation` and cursor to aid in pagination. */
  edges: Array<FilesRelationsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<FilesRelationAggregates>>;
  /** A list of `FilesRelation` objects. */
  nodes: Array<FilesRelation>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FilesRelation` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `FilesRelation` values. */
export type FilesRelationsConnectionGroupedAggregatesArgs = {
  groupBy: Array<FilesRelationsGroupBy>;
  having?: InputMaybe<FilesRelationsHavingInput>;
};

/** A `FilesRelation` edge in the connection. */
export type FilesRelationsEdge = {
  __typename?: 'FilesRelationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `FilesRelation` at the end of the edge. */
  node: FilesRelation;
};

/** Grouping methods for `FilesRelation` for usage during aggregation. */
export enum FilesRelationsGroupBy {
  ChildId = 'CHILD_ID',
  ParentId = 'PARENT_ID'
}

export type FilesRelationsHavingAverageInput = {
  childId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  parentId?: InputMaybe<HavingBigintFilter>;
};

export type FilesRelationsHavingDistinctCountInput = {
  childId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  parentId?: InputMaybe<HavingBigintFilter>;
};

/** Conditions for `FilesRelation` aggregates. */
export type FilesRelationsHavingInput = {
  AND?: InputMaybe<Array<FilesRelationsHavingInput>>;
  OR?: InputMaybe<Array<FilesRelationsHavingInput>>;
  average?: InputMaybe<FilesRelationsHavingAverageInput>;
  distinctCount?: InputMaybe<FilesRelationsHavingDistinctCountInput>;
  max?: InputMaybe<FilesRelationsHavingMaxInput>;
  min?: InputMaybe<FilesRelationsHavingMinInput>;
  stddevPopulation?: InputMaybe<FilesRelationsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<FilesRelationsHavingStddevSampleInput>;
  sum?: InputMaybe<FilesRelationsHavingSumInput>;
  variancePopulation?: InputMaybe<FilesRelationsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<FilesRelationsHavingVarianceSampleInput>;
};

export type FilesRelationsHavingMaxInput = {
  childId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  parentId?: InputMaybe<HavingBigintFilter>;
};

export type FilesRelationsHavingMinInput = {
  childId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  parentId?: InputMaybe<HavingBigintFilter>;
};

export type FilesRelationsHavingStddevPopulationInput = {
  childId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  parentId?: InputMaybe<HavingBigintFilter>;
};

export type FilesRelationsHavingStddevSampleInput = {
  childId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  parentId?: InputMaybe<HavingBigintFilter>;
};

export type FilesRelationsHavingSumInput = {
  childId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  parentId?: InputMaybe<HavingBigintFilter>;
};

export type FilesRelationsHavingVariancePopulationInput = {
  childId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  parentId?: InputMaybe<HavingBigintFilter>;
};

export type FilesRelationsHavingVarianceSampleInput = {
  childId?: InputMaybe<HavingBigintFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  parentId?: InputMaybe<HavingBigintFilter>;
};

/** Methods to use when ordering `FilesRelation`. */
export enum FilesRelationsOrderBy {
  ChildIdAsc = 'CHILD_ID_ASC',
  ChildIdDesc = 'CHILD_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  ParentIdAsc = 'PARENT_ID_ASC',
  ParentIdDesc = 'PARENT_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type FivetranNode = Node & {
  __typename?: 'FivetranNode';
  attributes: Scalars['String'];
  createdDttm: Scalars['Datetime'];
  downstreamReferences: Scalars['String'];
  fivetranId: Scalars['String'];
  /** Reads a single `FivetranSnapshot` that is related to this `FivetranNode`. */
  fivetranSnapshot?: Maybe<FivetranSnapshot>;
  fivetranSnapshotId: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  nodeType?: Maybe<Scalars['String']>;
  tags: Scalars['String'];
};

export type FivetranNodeAggregates = {
  __typename?: 'FivetranNodeAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<FivetranNodeAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<FivetranNodeDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<FivetranNodeMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<FivetranNodeMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<FivetranNodeStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<FivetranNodeStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<FivetranNodeSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<FivetranNodeVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<FivetranNodeVarianceSampleAggregates>;
};

export type FivetranNodeAverageAggregates = {
  __typename?: 'FivetranNodeAverageAggregates';
  /** Mean average of fivetranSnapshotId across the matching connection */
  fivetranSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `FivetranNode` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type FivetranNodeCondition = {
  /** Checks for equality with the object’s `attributes` field. */
  attributes?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdDttm` field. */
  createdDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `downstreamReferences` field. */
  downstreamReferences?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `fivetranId` field. */
  fivetranId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `fivetranSnapshotId` field. */
  fivetranSnapshotId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `nodeType` field. */
  nodeType?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `tags` field. */
  tags?: InputMaybe<Scalars['String']>;
};

export type FivetranNodeDistinctCountAggregates = {
  __typename?: 'FivetranNodeDistinctCountAggregates';
  /** Distinct count of attributes across the matching connection */
  attributes?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdDttm across the matching connection */
  createdDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of downstreamReferences across the matching connection */
  downstreamReferences?: Maybe<Scalars['BigInt']>;
  /** Distinct count of fivetranId across the matching connection */
  fivetranId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of fivetranSnapshotId across the matching connection */
  fivetranSnapshotId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of nodeType across the matching connection */
  nodeType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of tags across the matching connection */
  tags?: Maybe<Scalars['BigInt']>;
};

export type FivetranNodeMaxAggregates = {
  __typename?: 'FivetranNodeMaxAggregates';
  /** Maximum of fivetranSnapshotId across the matching connection */
  fivetranSnapshotId?: Maybe<Scalars['Int']>;
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

export type FivetranNodeMinAggregates = {
  __typename?: 'FivetranNodeMinAggregates';
  /** Minimum of fivetranSnapshotId across the matching connection */
  fivetranSnapshotId?: Maybe<Scalars['Int']>;
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

export type FivetranNodeStddevPopulationAggregates = {
  __typename?: 'FivetranNodeStddevPopulationAggregates';
  /** Population standard deviation of fivetranSnapshotId across the matching connection */
  fivetranSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type FivetranNodeStddevSampleAggregates = {
  __typename?: 'FivetranNodeStddevSampleAggregates';
  /** Sample standard deviation of fivetranSnapshotId across the matching connection */
  fivetranSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type FivetranNodeSumAggregates = {
  __typename?: 'FivetranNodeSumAggregates';
  /** Sum of fivetranSnapshotId across the matching connection */
  fivetranSnapshotId: Scalars['BigInt'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
};

export type FivetranNodeVariancePopulationAggregates = {
  __typename?: 'FivetranNodeVariancePopulationAggregates';
  /** Population variance of fivetranSnapshotId across the matching connection */
  fivetranSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type FivetranNodeVarianceSampleAggregates = {
  __typename?: 'FivetranNodeVarianceSampleAggregates';
  /** Sample variance of fivetranSnapshotId across the matching connection */
  fivetranSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `FivetranNode` values. */
export type FivetranNodesConnection = {
  __typename?: 'FivetranNodesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<FivetranNodeAggregates>;
  /** A list of edges which contains the `FivetranNode` and cursor to aid in pagination. */
  edges: Array<FivetranNodesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<FivetranNodeAggregates>>;
  /** A list of `FivetranNode` objects. */
  nodes: Array<FivetranNode>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FivetranNode` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `FivetranNode` values. */
export type FivetranNodesConnectionGroupedAggregatesArgs = {
  groupBy: Array<FivetranNodesGroupBy>;
  having?: InputMaybe<FivetranNodesHavingInput>;
};

/** A `FivetranNode` edge in the connection. */
export type FivetranNodesEdge = {
  __typename?: 'FivetranNodesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `FivetranNode` at the end of the edge. */
  node: FivetranNode;
};

/** Grouping methods for `FivetranNode` for usage during aggregation. */
export enum FivetranNodesGroupBy {
  Attributes = 'ATTRIBUTES',
  CreatedDttm = 'CREATED_DTTM',
  CreatedDttmTruncatedToDay = 'CREATED_DTTM_TRUNCATED_TO_DAY',
  CreatedDttmTruncatedToHour = 'CREATED_DTTM_TRUNCATED_TO_HOUR',
  DownstreamReferences = 'DOWNSTREAM_REFERENCES',
  FivetranId = 'FIVETRAN_ID',
  FivetranSnapshotId = 'FIVETRAN_SNAPSHOT_ID',
  Name = 'NAME',
  NodeType = 'NODE_TYPE',
  Tags = 'TAGS'
}

export type FivetranNodesHavingAverageInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  fivetranSnapshotId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
};

export type FivetranNodesHavingDistinctCountInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  fivetranSnapshotId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `FivetranNode` aggregates. */
export type FivetranNodesHavingInput = {
  AND?: InputMaybe<Array<FivetranNodesHavingInput>>;
  OR?: InputMaybe<Array<FivetranNodesHavingInput>>;
  average?: InputMaybe<FivetranNodesHavingAverageInput>;
  distinctCount?: InputMaybe<FivetranNodesHavingDistinctCountInput>;
  max?: InputMaybe<FivetranNodesHavingMaxInput>;
  min?: InputMaybe<FivetranNodesHavingMinInput>;
  stddevPopulation?: InputMaybe<FivetranNodesHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<FivetranNodesHavingStddevSampleInput>;
  sum?: InputMaybe<FivetranNodesHavingSumInput>;
  variancePopulation?: InputMaybe<FivetranNodesHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<FivetranNodesHavingVarianceSampleInput>;
};

export type FivetranNodesHavingMaxInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  fivetranSnapshotId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
};

export type FivetranNodesHavingMinInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  fivetranSnapshotId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
};

export type FivetranNodesHavingStddevPopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  fivetranSnapshotId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
};

export type FivetranNodesHavingStddevSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  fivetranSnapshotId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
};

export type FivetranNodesHavingSumInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  fivetranSnapshotId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
};

export type FivetranNodesHavingVariancePopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  fivetranSnapshotId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
};

export type FivetranNodesHavingVarianceSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  fivetranSnapshotId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
};

/** Methods to use when ordering `FivetranNode`. */
export enum FivetranNodesOrderBy {
  AttributesAsc = 'ATTRIBUTES_ASC',
  AttributesDesc = 'ATTRIBUTES_DESC',
  CreatedDttmAsc = 'CREATED_DTTM_ASC',
  CreatedDttmDesc = 'CREATED_DTTM_DESC',
  DownstreamReferencesAsc = 'DOWNSTREAM_REFERENCES_ASC',
  DownstreamReferencesDesc = 'DOWNSTREAM_REFERENCES_DESC',
  FivetranIdAsc = 'FIVETRAN_ID_ASC',
  FivetranIdDesc = 'FIVETRAN_ID_DESC',
  FivetranSnapshotIdAsc = 'FIVETRAN_SNAPSHOT_ID_ASC',
  FivetranSnapshotIdDesc = 'FIVETRAN_SNAPSHOT_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  NodeTypeAsc = 'NODE_TYPE_ASC',
  NodeTypeDesc = 'NODE_TYPE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TagsAsc = 'TAGS_ASC',
  TagsDesc = 'TAGS_DESC'
}

export type FivetranSnapshot = Node & {
  __typename?: 'FivetranSnapshot';
  createdDttm: Scalars['Datetime'];
  /** Reads and enables pagination through a set of `FivetranNode`. */
  fivetranNodes: FivetranNodesConnection;
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  workspaceId: Scalars['Int'];
};


export type FivetranSnapshotFivetranNodesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FivetranNodeCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FivetranNodesOrderBy>>;
};

export type FivetranSnapshotAggregates = {
  __typename?: 'FivetranSnapshotAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<FivetranSnapshotAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<FivetranSnapshotDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<FivetranSnapshotMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<FivetranSnapshotMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<FivetranSnapshotStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<FivetranSnapshotStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<FivetranSnapshotSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<FivetranSnapshotVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<FivetranSnapshotVarianceSampleAggregates>;
};

export type FivetranSnapshotAverageAggregates = {
  __typename?: 'FivetranSnapshotAverageAggregates';
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `FivetranSnapshot` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type FivetranSnapshotCondition = {
  /** Checks for equality with the object’s `createdDttm` field. */
  createdDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `workspaceId` field. */
  workspaceId?: InputMaybe<Scalars['Int']>;
};

export type FivetranSnapshotDistinctCountAggregates = {
  __typename?: 'FivetranSnapshotDistinctCountAggregates';
  /** Distinct count of createdDttm across the matching connection */
  createdDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigInt']>;
};

/** Grouping methods for `FivetranSnapshot` for usage during aggregation. */
export enum FivetranSnapshotGroupBy {
  CreatedDttm = 'CREATED_DTTM',
  CreatedDttmTruncatedToDay = 'CREATED_DTTM_TRUNCATED_TO_DAY',
  CreatedDttmTruncatedToHour = 'CREATED_DTTM_TRUNCATED_TO_HOUR',
  WorkspaceId = 'WORKSPACE_ID'
}

export type FivetranSnapshotHavingAverageInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type FivetranSnapshotHavingDistinctCountInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `FivetranSnapshot` aggregates. */
export type FivetranSnapshotHavingInput = {
  AND?: InputMaybe<Array<FivetranSnapshotHavingInput>>;
  OR?: InputMaybe<Array<FivetranSnapshotHavingInput>>;
  average?: InputMaybe<FivetranSnapshotHavingAverageInput>;
  distinctCount?: InputMaybe<FivetranSnapshotHavingDistinctCountInput>;
  max?: InputMaybe<FivetranSnapshotHavingMaxInput>;
  min?: InputMaybe<FivetranSnapshotHavingMinInput>;
  stddevPopulation?: InputMaybe<FivetranSnapshotHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<FivetranSnapshotHavingStddevSampleInput>;
  sum?: InputMaybe<FivetranSnapshotHavingSumInput>;
  variancePopulation?: InputMaybe<FivetranSnapshotHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<FivetranSnapshotHavingVarianceSampleInput>;
};

export type FivetranSnapshotHavingMaxInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type FivetranSnapshotHavingMinInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type FivetranSnapshotHavingStddevPopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type FivetranSnapshotHavingStddevSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type FivetranSnapshotHavingSumInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type FivetranSnapshotHavingVariancePopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type FivetranSnapshotHavingVarianceSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type FivetranSnapshotMaxAggregates = {
  __typename?: 'FivetranSnapshotMaxAggregates';
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['Int']>;
};

export type FivetranSnapshotMinAggregates = {
  __typename?: 'FivetranSnapshotMinAggregates';
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['Int']>;
};

export type FivetranSnapshotStddevPopulationAggregates = {
  __typename?: 'FivetranSnapshotStddevPopulationAggregates';
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type FivetranSnapshotStddevSampleAggregates = {
  __typename?: 'FivetranSnapshotStddevSampleAggregates';
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type FivetranSnapshotSumAggregates = {
  __typename?: 'FivetranSnapshotSumAggregates';
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of workspaceId across the matching connection */
  workspaceId: Scalars['BigInt'];
};

export type FivetranSnapshotVariancePopulationAggregates = {
  __typename?: 'FivetranSnapshotVariancePopulationAggregates';
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type FivetranSnapshotVarianceSampleAggregates = {
  __typename?: 'FivetranSnapshotVarianceSampleAggregates';
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `FivetranSnapshot` values. */
export type FivetranSnapshotsConnection = {
  __typename?: 'FivetranSnapshotsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<FivetranSnapshotAggregates>;
  /** A list of edges which contains the `FivetranSnapshot` and cursor to aid in pagination. */
  edges: Array<FivetranSnapshotsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<FivetranSnapshotAggregates>>;
  /** A list of `FivetranSnapshot` objects. */
  nodes: Array<FivetranSnapshot>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FivetranSnapshot` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `FivetranSnapshot` values. */
export type FivetranSnapshotsConnectionGroupedAggregatesArgs = {
  groupBy: Array<FivetranSnapshotGroupBy>;
  having?: InputMaybe<FivetranSnapshotHavingInput>;
};

/** A `FivetranSnapshot` edge in the connection. */
export type FivetranSnapshotsEdge = {
  __typename?: 'FivetranSnapshotsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `FivetranSnapshot` at the end of the edge. */
  node: FivetranSnapshot;
};

/** Methods to use when ordering `FivetranSnapshot`. */
export enum FivetranSnapshotsOrderBy {
  CreatedDttmAsc = 'CREATED_DTTM_ASC',
  CreatedDttmDesc = 'CREATED_DTTM_DESC',
  FivetranNodesAverageAttributesAsc = 'FIVETRAN_NODES_AVERAGE_ATTRIBUTES_ASC',
  FivetranNodesAverageAttributesDesc = 'FIVETRAN_NODES_AVERAGE_ATTRIBUTES_DESC',
  FivetranNodesAverageCreatedDttmAsc = 'FIVETRAN_NODES_AVERAGE_CREATED_DTTM_ASC',
  FivetranNodesAverageCreatedDttmDesc = 'FIVETRAN_NODES_AVERAGE_CREATED_DTTM_DESC',
  FivetranNodesAverageDownstreamReferencesAsc = 'FIVETRAN_NODES_AVERAGE_DOWNSTREAM_REFERENCES_ASC',
  FivetranNodesAverageDownstreamReferencesDesc = 'FIVETRAN_NODES_AVERAGE_DOWNSTREAM_REFERENCES_DESC',
  FivetranNodesAverageFivetranIdAsc = 'FIVETRAN_NODES_AVERAGE_FIVETRAN_ID_ASC',
  FivetranNodesAverageFivetranIdDesc = 'FIVETRAN_NODES_AVERAGE_FIVETRAN_ID_DESC',
  FivetranNodesAverageFivetranSnapshotIdAsc = 'FIVETRAN_NODES_AVERAGE_FIVETRAN_SNAPSHOT_ID_ASC',
  FivetranNodesAverageFivetranSnapshotIdDesc = 'FIVETRAN_NODES_AVERAGE_FIVETRAN_SNAPSHOT_ID_DESC',
  FivetranNodesAverageIdAsc = 'FIVETRAN_NODES_AVERAGE_ID_ASC',
  FivetranNodesAverageIdDesc = 'FIVETRAN_NODES_AVERAGE_ID_DESC',
  FivetranNodesAverageNameAsc = 'FIVETRAN_NODES_AVERAGE_NAME_ASC',
  FivetranNodesAverageNameDesc = 'FIVETRAN_NODES_AVERAGE_NAME_DESC',
  FivetranNodesAverageNodeTypeAsc = 'FIVETRAN_NODES_AVERAGE_NODE_TYPE_ASC',
  FivetranNodesAverageNodeTypeDesc = 'FIVETRAN_NODES_AVERAGE_NODE_TYPE_DESC',
  FivetranNodesAverageTagsAsc = 'FIVETRAN_NODES_AVERAGE_TAGS_ASC',
  FivetranNodesAverageTagsDesc = 'FIVETRAN_NODES_AVERAGE_TAGS_DESC',
  FivetranNodesCountAsc = 'FIVETRAN_NODES_COUNT_ASC',
  FivetranNodesCountDesc = 'FIVETRAN_NODES_COUNT_DESC',
  FivetranNodesDistinctCountAttributesAsc = 'FIVETRAN_NODES_DISTINCT_COUNT_ATTRIBUTES_ASC',
  FivetranNodesDistinctCountAttributesDesc = 'FIVETRAN_NODES_DISTINCT_COUNT_ATTRIBUTES_DESC',
  FivetranNodesDistinctCountCreatedDttmAsc = 'FIVETRAN_NODES_DISTINCT_COUNT_CREATED_DTTM_ASC',
  FivetranNodesDistinctCountCreatedDttmDesc = 'FIVETRAN_NODES_DISTINCT_COUNT_CREATED_DTTM_DESC',
  FivetranNodesDistinctCountDownstreamReferencesAsc = 'FIVETRAN_NODES_DISTINCT_COUNT_DOWNSTREAM_REFERENCES_ASC',
  FivetranNodesDistinctCountDownstreamReferencesDesc = 'FIVETRAN_NODES_DISTINCT_COUNT_DOWNSTREAM_REFERENCES_DESC',
  FivetranNodesDistinctCountFivetranIdAsc = 'FIVETRAN_NODES_DISTINCT_COUNT_FIVETRAN_ID_ASC',
  FivetranNodesDistinctCountFivetranIdDesc = 'FIVETRAN_NODES_DISTINCT_COUNT_FIVETRAN_ID_DESC',
  FivetranNodesDistinctCountFivetranSnapshotIdAsc = 'FIVETRAN_NODES_DISTINCT_COUNT_FIVETRAN_SNAPSHOT_ID_ASC',
  FivetranNodesDistinctCountFivetranSnapshotIdDesc = 'FIVETRAN_NODES_DISTINCT_COUNT_FIVETRAN_SNAPSHOT_ID_DESC',
  FivetranNodesDistinctCountIdAsc = 'FIVETRAN_NODES_DISTINCT_COUNT_ID_ASC',
  FivetranNodesDistinctCountIdDesc = 'FIVETRAN_NODES_DISTINCT_COUNT_ID_DESC',
  FivetranNodesDistinctCountNameAsc = 'FIVETRAN_NODES_DISTINCT_COUNT_NAME_ASC',
  FivetranNodesDistinctCountNameDesc = 'FIVETRAN_NODES_DISTINCT_COUNT_NAME_DESC',
  FivetranNodesDistinctCountNodeTypeAsc = 'FIVETRAN_NODES_DISTINCT_COUNT_NODE_TYPE_ASC',
  FivetranNodesDistinctCountNodeTypeDesc = 'FIVETRAN_NODES_DISTINCT_COUNT_NODE_TYPE_DESC',
  FivetranNodesDistinctCountTagsAsc = 'FIVETRAN_NODES_DISTINCT_COUNT_TAGS_ASC',
  FivetranNodesDistinctCountTagsDesc = 'FIVETRAN_NODES_DISTINCT_COUNT_TAGS_DESC',
  FivetranNodesMaxAttributesAsc = 'FIVETRAN_NODES_MAX_ATTRIBUTES_ASC',
  FivetranNodesMaxAttributesDesc = 'FIVETRAN_NODES_MAX_ATTRIBUTES_DESC',
  FivetranNodesMaxCreatedDttmAsc = 'FIVETRAN_NODES_MAX_CREATED_DTTM_ASC',
  FivetranNodesMaxCreatedDttmDesc = 'FIVETRAN_NODES_MAX_CREATED_DTTM_DESC',
  FivetranNodesMaxDownstreamReferencesAsc = 'FIVETRAN_NODES_MAX_DOWNSTREAM_REFERENCES_ASC',
  FivetranNodesMaxDownstreamReferencesDesc = 'FIVETRAN_NODES_MAX_DOWNSTREAM_REFERENCES_DESC',
  FivetranNodesMaxFivetranIdAsc = 'FIVETRAN_NODES_MAX_FIVETRAN_ID_ASC',
  FivetranNodesMaxFivetranIdDesc = 'FIVETRAN_NODES_MAX_FIVETRAN_ID_DESC',
  FivetranNodesMaxFivetranSnapshotIdAsc = 'FIVETRAN_NODES_MAX_FIVETRAN_SNAPSHOT_ID_ASC',
  FivetranNodesMaxFivetranSnapshotIdDesc = 'FIVETRAN_NODES_MAX_FIVETRAN_SNAPSHOT_ID_DESC',
  FivetranNodesMaxIdAsc = 'FIVETRAN_NODES_MAX_ID_ASC',
  FivetranNodesMaxIdDesc = 'FIVETRAN_NODES_MAX_ID_DESC',
  FivetranNodesMaxNameAsc = 'FIVETRAN_NODES_MAX_NAME_ASC',
  FivetranNodesMaxNameDesc = 'FIVETRAN_NODES_MAX_NAME_DESC',
  FivetranNodesMaxNodeTypeAsc = 'FIVETRAN_NODES_MAX_NODE_TYPE_ASC',
  FivetranNodesMaxNodeTypeDesc = 'FIVETRAN_NODES_MAX_NODE_TYPE_DESC',
  FivetranNodesMaxTagsAsc = 'FIVETRAN_NODES_MAX_TAGS_ASC',
  FivetranNodesMaxTagsDesc = 'FIVETRAN_NODES_MAX_TAGS_DESC',
  FivetranNodesMinAttributesAsc = 'FIVETRAN_NODES_MIN_ATTRIBUTES_ASC',
  FivetranNodesMinAttributesDesc = 'FIVETRAN_NODES_MIN_ATTRIBUTES_DESC',
  FivetranNodesMinCreatedDttmAsc = 'FIVETRAN_NODES_MIN_CREATED_DTTM_ASC',
  FivetranNodesMinCreatedDttmDesc = 'FIVETRAN_NODES_MIN_CREATED_DTTM_DESC',
  FivetranNodesMinDownstreamReferencesAsc = 'FIVETRAN_NODES_MIN_DOWNSTREAM_REFERENCES_ASC',
  FivetranNodesMinDownstreamReferencesDesc = 'FIVETRAN_NODES_MIN_DOWNSTREAM_REFERENCES_DESC',
  FivetranNodesMinFivetranIdAsc = 'FIVETRAN_NODES_MIN_FIVETRAN_ID_ASC',
  FivetranNodesMinFivetranIdDesc = 'FIVETRAN_NODES_MIN_FIVETRAN_ID_DESC',
  FivetranNodesMinFivetranSnapshotIdAsc = 'FIVETRAN_NODES_MIN_FIVETRAN_SNAPSHOT_ID_ASC',
  FivetranNodesMinFivetranSnapshotIdDesc = 'FIVETRAN_NODES_MIN_FIVETRAN_SNAPSHOT_ID_DESC',
  FivetranNodesMinIdAsc = 'FIVETRAN_NODES_MIN_ID_ASC',
  FivetranNodesMinIdDesc = 'FIVETRAN_NODES_MIN_ID_DESC',
  FivetranNodesMinNameAsc = 'FIVETRAN_NODES_MIN_NAME_ASC',
  FivetranNodesMinNameDesc = 'FIVETRAN_NODES_MIN_NAME_DESC',
  FivetranNodesMinNodeTypeAsc = 'FIVETRAN_NODES_MIN_NODE_TYPE_ASC',
  FivetranNodesMinNodeTypeDesc = 'FIVETRAN_NODES_MIN_NODE_TYPE_DESC',
  FivetranNodesMinTagsAsc = 'FIVETRAN_NODES_MIN_TAGS_ASC',
  FivetranNodesMinTagsDesc = 'FIVETRAN_NODES_MIN_TAGS_DESC',
  FivetranNodesStddevPopulationAttributesAsc = 'FIVETRAN_NODES_STDDEV_POPULATION_ATTRIBUTES_ASC',
  FivetranNodesStddevPopulationAttributesDesc = 'FIVETRAN_NODES_STDDEV_POPULATION_ATTRIBUTES_DESC',
  FivetranNodesStddevPopulationCreatedDttmAsc = 'FIVETRAN_NODES_STDDEV_POPULATION_CREATED_DTTM_ASC',
  FivetranNodesStddevPopulationCreatedDttmDesc = 'FIVETRAN_NODES_STDDEV_POPULATION_CREATED_DTTM_DESC',
  FivetranNodesStddevPopulationDownstreamReferencesAsc = 'FIVETRAN_NODES_STDDEV_POPULATION_DOWNSTREAM_REFERENCES_ASC',
  FivetranNodesStddevPopulationDownstreamReferencesDesc = 'FIVETRAN_NODES_STDDEV_POPULATION_DOWNSTREAM_REFERENCES_DESC',
  FivetranNodesStddevPopulationFivetranIdAsc = 'FIVETRAN_NODES_STDDEV_POPULATION_FIVETRAN_ID_ASC',
  FivetranNodesStddevPopulationFivetranIdDesc = 'FIVETRAN_NODES_STDDEV_POPULATION_FIVETRAN_ID_DESC',
  FivetranNodesStddevPopulationFivetranSnapshotIdAsc = 'FIVETRAN_NODES_STDDEV_POPULATION_FIVETRAN_SNAPSHOT_ID_ASC',
  FivetranNodesStddevPopulationFivetranSnapshotIdDesc = 'FIVETRAN_NODES_STDDEV_POPULATION_FIVETRAN_SNAPSHOT_ID_DESC',
  FivetranNodesStddevPopulationIdAsc = 'FIVETRAN_NODES_STDDEV_POPULATION_ID_ASC',
  FivetranNodesStddevPopulationIdDesc = 'FIVETRAN_NODES_STDDEV_POPULATION_ID_DESC',
  FivetranNodesStddevPopulationNameAsc = 'FIVETRAN_NODES_STDDEV_POPULATION_NAME_ASC',
  FivetranNodesStddevPopulationNameDesc = 'FIVETRAN_NODES_STDDEV_POPULATION_NAME_DESC',
  FivetranNodesStddevPopulationNodeTypeAsc = 'FIVETRAN_NODES_STDDEV_POPULATION_NODE_TYPE_ASC',
  FivetranNodesStddevPopulationNodeTypeDesc = 'FIVETRAN_NODES_STDDEV_POPULATION_NODE_TYPE_DESC',
  FivetranNodesStddevPopulationTagsAsc = 'FIVETRAN_NODES_STDDEV_POPULATION_TAGS_ASC',
  FivetranNodesStddevPopulationTagsDesc = 'FIVETRAN_NODES_STDDEV_POPULATION_TAGS_DESC',
  FivetranNodesStddevSampleAttributesAsc = 'FIVETRAN_NODES_STDDEV_SAMPLE_ATTRIBUTES_ASC',
  FivetranNodesStddevSampleAttributesDesc = 'FIVETRAN_NODES_STDDEV_SAMPLE_ATTRIBUTES_DESC',
  FivetranNodesStddevSampleCreatedDttmAsc = 'FIVETRAN_NODES_STDDEV_SAMPLE_CREATED_DTTM_ASC',
  FivetranNodesStddevSampleCreatedDttmDesc = 'FIVETRAN_NODES_STDDEV_SAMPLE_CREATED_DTTM_DESC',
  FivetranNodesStddevSampleDownstreamReferencesAsc = 'FIVETRAN_NODES_STDDEV_SAMPLE_DOWNSTREAM_REFERENCES_ASC',
  FivetranNodesStddevSampleDownstreamReferencesDesc = 'FIVETRAN_NODES_STDDEV_SAMPLE_DOWNSTREAM_REFERENCES_DESC',
  FivetranNodesStddevSampleFivetranIdAsc = 'FIVETRAN_NODES_STDDEV_SAMPLE_FIVETRAN_ID_ASC',
  FivetranNodesStddevSampleFivetranIdDesc = 'FIVETRAN_NODES_STDDEV_SAMPLE_FIVETRAN_ID_DESC',
  FivetranNodesStddevSampleFivetranSnapshotIdAsc = 'FIVETRAN_NODES_STDDEV_SAMPLE_FIVETRAN_SNAPSHOT_ID_ASC',
  FivetranNodesStddevSampleFivetranSnapshotIdDesc = 'FIVETRAN_NODES_STDDEV_SAMPLE_FIVETRAN_SNAPSHOT_ID_DESC',
  FivetranNodesStddevSampleIdAsc = 'FIVETRAN_NODES_STDDEV_SAMPLE_ID_ASC',
  FivetranNodesStddevSampleIdDesc = 'FIVETRAN_NODES_STDDEV_SAMPLE_ID_DESC',
  FivetranNodesStddevSampleNameAsc = 'FIVETRAN_NODES_STDDEV_SAMPLE_NAME_ASC',
  FivetranNodesStddevSampleNameDesc = 'FIVETRAN_NODES_STDDEV_SAMPLE_NAME_DESC',
  FivetranNodesStddevSampleNodeTypeAsc = 'FIVETRAN_NODES_STDDEV_SAMPLE_NODE_TYPE_ASC',
  FivetranNodesStddevSampleNodeTypeDesc = 'FIVETRAN_NODES_STDDEV_SAMPLE_NODE_TYPE_DESC',
  FivetranNodesStddevSampleTagsAsc = 'FIVETRAN_NODES_STDDEV_SAMPLE_TAGS_ASC',
  FivetranNodesStddevSampleTagsDesc = 'FIVETRAN_NODES_STDDEV_SAMPLE_TAGS_DESC',
  FivetranNodesSumAttributesAsc = 'FIVETRAN_NODES_SUM_ATTRIBUTES_ASC',
  FivetranNodesSumAttributesDesc = 'FIVETRAN_NODES_SUM_ATTRIBUTES_DESC',
  FivetranNodesSumCreatedDttmAsc = 'FIVETRAN_NODES_SUM_CREATED_DTTM_ASC',
  FivetranNodesSumCreatedDttmDesc = 'FIVETRAN_NODES_SUM_CREATED_DTTM_DESC',
  FivetranNodesSumDownstreamReferencesAsc = 'FIVETRAN_NODES_SUM_DOWNSTREAM_REFERENCES_ASC',
  FivetranNodesSumDownstreamReferencesDesc = 'FIVETRAN_NODES_SUM_DOWNSTREAM_REFERENCES_DESC',
  FivetranNodesSumFivetranIdAsc = 'FIVETRAN_NODES_SUM_FIVETRAN_ID_ASC',
  FivetranNodesSumFivetranIdDesc = 'FIVETRAN_NODES_SUM_FIVETRAN_ID_DESC',
  FivetranNodesSumFivetranSnapshotIdAsc = 'FIVETRAN_NODES_SUM_FIVETRAN_SNAPSHOT_ID_ASC',
  FivetranNodesSumFivetranSnapshotIdDesc = 'FIVETRAN_NODES_SUM_FIVETRAN_SNAPSHOT_ID_DESC',
  FivetranNodesSumIdAsc = 'FIVETRAN_NODES_SUM_ID_ASC',
  FivetranNodesSumIdDesc = 'FIVETRAN_NODES_SUM_ID_DESC',
  FivetranNodesSumNameAsc = 'FIVETRAN_NODES_SUM_NAME_ASC',
  FivetranNodesSumNameDesc = 'FIVETRAN_NODES_SUM_NAME_DESC',
  FivetranNodesSumNodeTypeAsc = 'FIVETRAN_NODES_SUM_NODE_TYPE_ASC',
  FivetranNodesSumNodeTypeDesc = 'FIVETRAN_NODES_SUM_NODE_TYPE_DESC',
  FivetranNodesSumTagsAsc = 'FIVETRAN_NODES_SUM_TAGS_ASC',
  FivetranNodesSumTagsDesc = 'FIVETRAN_NODES_SUM_TAGS_DESC',
  FivetranNodesVariancePopulationAttributesAsc = 'FIVETRAN_NODES_VARIANCE_POPULATION_ATTRIBUTES_ASC',
  FivetranNodesVariancePopulationAttributesDesc = 'FIVETRAN_NODES_VARIANCE_POPULATION_ATTRIBUTES_DESC',
  FivetranNodesVariancePopulationCreatedDttmAsc = 'FIVETRAN_NODES_VARIANCE_POPULATION_CREATED_DTTM_ASC',
  FivetranNodesVariancePopulationCreatedDttmDesc = 'FIVETRAN_NODES_VARIANCE_POPULATION_CREATED_DTTM_DESC',
  FivetranNodesVariancePopulationDownstreamReferencesAsc = 'FIVETRAN_NODES_VARIANCE_POPULATION_DOWNSTREAM_REFERENCES_ASC',
  FivetranNodesVariancePopulationDownstreamReferencesDesc = 'FIVETRAN_NODES_VARIANCE_POPULATION_DOWNSTREAM_REFERENCES_DESC',
  FivetranNodesVariancePopulationFivetranIdAsc = 'FIVETRAN_NODES_VARIANCE_POPULATION_FIVETRAN_ID_ASC',
  FivetranNodesVariancePopulationFivetranIdDesc = 'FIVETRAN_NODES_VARIANCE_POPULATION_FIVETRAN_ID_DESC',
  FivetranNodesVariancePopulationFivetranSnapshotIdAsc = 'FIVETRAN_NODES_VARIANCE_POPULATION_FIVETRAN_SNAPSHOT_ID_ASC',
  FivetranNodesVariancePopulationFivetranSnapshotIdDesc = 'FIVETRAN_NODES_VARIANCE_POPULATION_FIVETRAN_SNAPSHOT_ID_DESC',
  FivetranNodesVariancePopulationIdAsc = 'FIVETRAN_NODES_VARIANCE_POPULATION_ID_ASC',
  FivetranNodesVariancePopulationIdDesc = 'FIVETRAN_NODES_VARIANCE_POPULATION_ID_DESC',
  FivetranNodesVariancePopulationNameAsc = 'FIVETRAN_NODES_VARIANCE_POPULATION_NAME_ASC',
  FivetranNodesVariancePopulationNameDesc = 'FIVETRAN_NODES_VARIANCE_POPULATION_NAME_DESC',
  FivetranNodesVariancePopulationNodeTypeAsc = 'FIVETRAN_NODES_VARIANCE_POPULATION_NODE_TYPE_ASC',
  FivetranNodesVariancePopulationNodeTypeDesc = 'FIVETRAN_NODES_VARIANCE_POPULATION_NODE_TYPE_DESC',
  FivetranNodesVariancePopulationTagsAsc = 'FIVETRAN_NODES_VARIANCE_POPULATION_TAGS_ASC',
  FivetranNodesVariancePopulationTagsDesc = 'FIVETRAN_NODES_VARIANCE_POPULATION_TAGS_DESC',
  FivetranNodesVarianceSampleAttributesAsc = 'FIVETRAN_NODES_VARIANCE_SAMPLE_ATTRIBUTES_ASC',
  FivetranNodesVarianceSampleAttributesDesc = 'FIVETRAN_NODES_VARIANCE_SAMPLE_ATTRIBUTES_DESC',
  FivetranNodesVarianceSampleCreatedDttmAsc = 'FIVETRAN_NODES_VARIANCE_SAMPLE_CREATED_DTTM_ASC',
  FivetranNodesVarianceSampleCreatedDttmDesc = 'FIVETRAN_NODES_VARIANCE_SAMPLE_CREATED_DTTM_DESC',
  FivetranNodesVarianceSampleDownstreamReferencesAsc = 'FIVETRAN_NODES_VARIANCE_SAMPLE_DOWNSTREAM_REFERENCES_ASC',
  FivetranNodesVarianceSampleDownstreamReferencesDesc = 'FIVETRAN_NODES_VARIANCE_SAMPLE_DOWNSTREAM_REFERENCES_DESC',
  FivetranNodesVarianceSampleFivetranIdAsc = 'FIVETRAN_NODES_VARIANCE_SAMPLE_FIVETRAN_ID_ASC',
  FivetranNodesVarianceSampleFivetranIdDesc = 'FIVETRAN_NODES_VARIANCE_SAMPLE_FIVETRAN_ID_DESC',
  FivetranNodesVarianceSampleFivetranSnapshotIdAsc = 'FIVETRAN_NODES_VARIANCE_SAMPLE_FIVETRAN_SNAPSHOT_ID_ASC',
  FivetranNodesVarianceSampleFivetranSnapshotIdDesc = 'FIVETRAN_NODES_VARIANCE_SAMPLE_FIVETRAN_SNAPSHOT_ID_DESC',
  FivetranNodesVarianceSampleIdAsc = 'FIVETRAN_NODES_VARIANCE_SAMPLE_ID_ASC',
  FivetranNodesVarianceSampleIdDesc = 'FIVETRAN_NODES_VARIANCE_SAMPLE_ID_DESC',
  FivetranNodesVarianceSampleNameAsc = 'FIVETRAN_NODES_VARIANCE_SAMPLE_NAME_ASC',
  FivetranNodesVarianceSampleNameDesc = 'FIVETRAN_NODES_VARIANCE_SAMPLE_NAME_DESC',
  FivetranNodesVarianceSampleNodeTypeAsc = 'FIVETRAN_NODES_VARIANCE_SAMPLE_NODE_TYPE_ASC',
  FivetranNodesVarianceSampleNodeTypeDesc = 'FIVETRAN_NODES_VARIANCE_SAMPLE_NODE_TYPE_DESC',
  FivetranNodesVarianceSampleTagsAsc = 'FIVETRAN_NODES_VARIANCE_SAMPLE_TAGS_ASC',
  FivetranNodesVarianceSampleTagsDesc = 'FIVETRAN_NODES_VARIANCE_SAMPLE_TAGS_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  WorkspaceIdAsc = 'WORKSPACE_ID_ASC',
  WorkspaceIdDesc = 'WORKSPACE_ID_DESC'
}

export type GdbImport = Node & {
  __typename?: 'GdbImport';
  createdDttm: Scalars['Datetime'];
  id: Scalars['BigInt'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

export type GdbImportAggregates = {
  __typename?: 'GdbImportAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<GdbImportAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<GdbImportDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<GdbImportMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<GdbImportMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<GdbImportStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<GdbImportStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<GdbImportSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<GdbImportVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<GdbImportVarianceSampleAggregates>;
};

export type GdbImportAverageAggregates = {
  __typename?: 'GdbImportAverageAggregates';
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `GdbImport` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type GdbImportCondition = {
  /** Checks for equality with the object’s `createdDttm` field. */
  createdDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']>;
};

export type GdbImportDistinctCountAggregates = {
  __typename?: 'GdbImportDistinctCountAggregates';
  /** Distinct count of createdDttm across the matching connection */
  createdDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
};

export type GdbImportMaxAggregates = {
  __typename?: 'GdbImportMaxAggregates';
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
};

export type GdbImportMinAggregates = {
  __typename?: 'GdbImportMinAggregates';
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
};

export type GdbImportStddevPopulationAggregates = {
  __typename?: 'GdbImportStddevPopulationAggregates';
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type GdbImportStddevSampleAggregates = {
  __typename?: 'GdbImportStddevSampleAggregates';
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type GdbImportSumAggregates = {
  __typename?: 'GdbImportSumAggregates';
  /** Sum of id across the matching connection */
  id: Scalars['BigFloat'];
};

export type GdbImportVariancePopulationAggregates = {
  __typename?: 'GdbImportVariancePopulationAggregates';
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type GdbImportVarianceSampleAggregates = {
  __typename?: 'GdbImportVarianceSampleAggregates';
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `GdbImport` values. */
export type GdbImportsConnection = {
  __typename?: 'GdbImportsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<GdbImportAggregates>;
  /** A list of edges which contains the `GdbImport` and cursor to aid in pagination. */
  edges: Array<GdbImportsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<GdbImportAggregates>>;
  /** A list of `GdbImport` objects. */
  nodes: Array<GdbImport>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GdbImport` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `GdbImport` values. */
export type GdbImportsConnectionGroupedAggregatesArgs = {
  groupBy: Array<GdbImportsGroupBy>;
  having?: InputMaybe<GdbImportsHavingInput>;
};

/** A `GdbImport` edge in the connection. */
export type GdbImportsEdge = {
  __typename?: 'GdbImportsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `GdbImport` at the end of the edge. */
  node: GdbImport;
};

/** Grouping methods for `GdbImport` for usage during aggregation. */
export enum GdbImportsGroupBy {
  CreatedDttm = 'CREATED_DTTM',
  CreatedDttmTruncatedToDay = 'CREATED_DTTM_TRUNCATED_TO_DAY',
  CreatedDttmTruncatedToHour = 'CREATED_DTTM_TRUNCATED_TO_HOUR'
}

export type GdbImportsHavingAverageInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type GdbImportsHavingDistinctCountInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

/** Conditions for `GdbImport` aggregates. */
export type GdbImportsHavingInput = {
  AND?: InputMaybe<Array<GdbImportsHavingInput>>;
  OR?: InputMaybe<Array<GdbImportsHavingInput>>;
  average?: InputMaybe<GdbImportsHavingAverageInput>;
  distinctCount?: InputMaybe<GdbImportsHavingDistinctCountInput>;
  max?: InputMaybe<GdbImportsHavingMaxInput>;
  min?: InputMaybe<GdbImportsHavingMinInput>;
  stddevPopulation?: InputMaybe<GdbImportsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<GdbImportsHavingStddevSampleInput>;
  sum?: InputMaybe<GdbImportsHavingSumInput>;
  variancePopulation?: InputMaybe<GdbImportsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<GdbImportsHavingVarianceSampleInput>;
};

export type GdbImportsHavingMaxInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type GdbImportsHavingMinInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type GdbImportsHavingStddevPopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type GdbImportsHavingStddevSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type GdbImportsHavingSumInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type GdbImportsHavingVariancePopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

export type GdbImportsHavingVarianceSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
};

/** Methods to use when ordering `GdbImport`. */
export enum GdbImportsOrderBy {
  CreatedDttmAsc = 'CREATED_DTTM_ASC',
  CreatedDttmDesc = 'CREATED_DTTM_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type HavingBigintFilter = {
  equalTo?: InputMaybe<Scalars['BigInt']>;
  greaterThan?: InputMaybe<Scalars['BigInt']>;
  greaterThanOrEqualTo?: InputMaybe<Scalars['BigInt']>;
  lessThan?: InputMaybe<Scalars['BigInt']>;
  lessThanOrEqualTo?: InputMaybe<Scalars['BigInt']>;
  notEqualTo?: InputMaybe<Scalars['BigInt']>;
};

export type HavingDatetimeFilter = {
  equalTo?: InputMaybe<Scalars['Datetime']>;
  greaterThan?: InputMaybe<Scalars['Datetime']>;
  greaterThanOrEqualTo?: InputMaybe<Scalars['Datetime']>;
  lessThan?: InputMaybe<Scalars['Datetime']>;
  lessThanOrEqualTo?: InputMaybe<Scalars['Datetime']>;
  notEqualTo?: InputMaybe<Scalars['Datetime']>;
};

export type HavingFloatFilter = {
  equalTo?: InputMaybe<Scalars['Float']>;
  greaterThan?: InputMaybe<Scalars['Float']>;
  greaterThanOrEqualTo?: InputMaybe<Scalars['Float']>;
  lessThan?: InputMaybe<Scalars['Float']>;
  lessThanOrEqualTo?: InputMaybe<Scalars['Float']>;
  notEqualTo?: InputMaybe<Scalars['Float']>;
};

export type HavingIntFilter = {
  equalTo?: InputMaybe<Scalars['Int']>;
  greaterThan?: InputMaybe<Scalars['Int']>;
  greaterThanOrEqualTo?: InputMaybe<Scalars['Int']>;
  lessThan?: InputMaybe<Scalars['Int']>;
  lessThanOrEqualTo?: InputMaybe<Scalars['Int']>;
  notEqualTo?: InputMaybe<Scalars['Int']>;
};

export type HightouchNode = Node & {
  __typename?: 'HightouchNode';
  attributes: Scalars['String'];
  createdDttm: Scalars['Datetime'];
  hightouchId: Scalars['String'];
  /** Reads a single `HightouchSnapshot` that is related to this `HightouchNode`. */
  hightouchSnapshot?: Maybe<HightouchSnapshot>;
  hightouchSnapshotId: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  nodeType?: Maybe<Scalars['String']>;
  references: Scalars['String'];
  tags: Scalars['String'];
};

export type HightouchNodeAggregates = {
  __typename?: 'HightouchNodeAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<HightouchNodeAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<HightouchNodeDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<HightouchNodeMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<HightouchNodeMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<HightouchNodeStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<HightouchNodeStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<HightouchNodeSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<HightouchNodeVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<HightouchNodeVarianceSampleAggregates>;
};

export type HightouchNodeAverageAggregates = {
  __typename?: 'HightouchNodeAverageAggregates';
  /** Mean average of hightouchSnapshotId across the matching connection */
  hightouchSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `HightouchNode` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type HightouchNodeCondition = {
  /** Checks for equality with the object’s `attributes` field. */
  attributes?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdDttm` field. */
  createdDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `hightouchId` field. */
  hightouchId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `hightouchSnapshotId` field. */
  hightouchSnapshotId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `nodeType` field. */
  nodeType?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `references` field. */
  references?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `tags` field. */
  tags?: InputMaybe<Scalars['String']>;
};

export type HightouchNodeDistinctCountAggregates = {
  __typename?: 'HightouchNodeDistinctCountAggregates';
  /** Distinct count of attributes across the matching connection */
  attributes?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdDttm across the matching connection */
  createdDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of hightouchId across the matching connection */
  hightouchId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of hightouchSnapshotId across the matching connection */
  hightouchSnapshotId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of nodeType across the matching connection */
  nodeType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of references across the matching connection */
  references?: Maybe<Scalars['BigInt']>;
  /** Distinct count of tags across the matching connection */
  tags?: Maybe<Scalars['BigInt']>;
};

export type HightouchNodeMaxAggregates = {
  __typename?: 'HightouchNodeMaxAggregates';
  /** Maximum of hightouchSnapshotId across the matching connection */
  hightouchSnapshotId?: Maybe<Scalars['Int']>;
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

export type HightouchNodeMinAggregates = {
  __typename?: 'HightouchNodeMinAggregates';
  /** Minimum of hightouchSnapshotId across the matching connection */
  hightouchSnapshotId?: Maybe<Scalars['Int']>;
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

export type HightouchNodeStddevPopulationAggregates = {
  __typename?: 'HightouchNodeStddevPopulationAggregates';
  /** Population standard deviation of hightouchSnapshotId across the matching connection */
  hightouchSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type HightouchNodeStddevSampleAggregates = {
  __typename?: 'HightouchNodeStddevSampleAggregates';
  /** Sample standard deviation of hightouchSnapshotId across the matching connection */
  hightouchSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type HightouchNodeSumAggregates = {
  __typename?: 'HightouchNodeSumAggregates';
  /** Sum of hightouchSnapshotId across the matching connection */
  hightouchSnapshotId: Scalars['BigInt'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
};

export type HightouchNodeVariancePopulationAggregates = {
  __typename?: 'HightouchNodeVariancePopulationAggregates';
  /** Population variance of hightouchSnapshotId across the matching connection */
  hightouchSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type HightouchNodeVarianceSampleAggregates = {
  __typename?: 'HightouchNodeVarianceSampleAggregates';
  /** Sample variance of hightouchSnapshotId across the matching connection */
  hightouchSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `HightouchNode` values. */
export type HightouchNodesConnection = {
  __typename?: 'HightouchNodesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<HightouchNodeAggregates>;
  /** A list of edges which contains the `HightouchNode` and cursor to aid in pagination. */
  edges: Array<HightouchNodesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<HightouchNodeAggregates>>;
  /** A list of `HightouchNode` objects. */
  nodes: Array<HightouchNode>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `HightouchNode` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `HightouchNode` values. */
export type HightouchNodesConnectionGroupedAggregatesArgs = {
  groupBy: Array<HightouchNodesGroupBy>;
  having?: InputMaybe<HightouchNodesHavingInput>;
};

/** A `HightouchNode` edge in the connection. */
export type HightouchNodesEdge = {
  __typename?: 'HightouchNodesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `HightouchNode` at the end of the edge. */
  node: HightouchNode;
};

/** Grouping methods for `HightouchNode` for usage during aggregation. */
export enum HightouchNodesGroupBy {
  Attributes = 'ATTRIBUTES',
  CreatedDttm = 'CREATED_DTTM',
  CreatedDttmTruncatedToDay = 'CREATED_DTTM_TRUNCATED_TO_DAY',
  CreatedDttmTruncatedToHour = 'CREATED_DTTM_TRUNCATED_TO_HOUR',
  HightouchId = 'HIGHTOUCH_ID',
  HightouchSnapshotId = 'HIGHTOUCH_SNAPSHOT_ID',
  Name = 'NAME',
  NodeType = 'NODE_TYPE',
  References = 'REFERENCES',
  Tags = 'TAGS'
}

export type HightouchNodesHavingAverageInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  hightouchSnapshotId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
};

export type HightouchNodesHavingDistinctCountInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  hightouchSnapshotId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `HightouchNode` aggregates. */
export type HightouchNodesHavingInput = {
  AND?: InputMaybe<Array<HightouchNodesHavingInput>>;
  OR?: InputMaybe<Array<HightouchNodesHavingInput>>;
  average?: InputMaybe<HightouchNodesHavingAverageInput>;
  distinctCount?: InputMaybe<HightouchNodesHavingDistinctCountInput>;
  max?: InputMaybe<HightouchNodesHavingMaxInput>;
  min?: InputMaybe<HightouchNodesHavingMinInput>;
  stddevPopulation?: InputMaybe<HightouchNodesHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<HightouchNodesHavingStddevSampleInput>;
  sum?: InputMaybe<HightouchNodesHavingSumInput>;
  variancePopulation?: InputMaybe<HightouchNodesHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<HightouchNodesHavingVarianceSampleInput>;
};

export type HightouchNodesHavingMaxInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  hightouchSnapshotId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
};

export type HightouchNodesHavingMinInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  hightouchSnapshotId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
};

export type HightouchNodesHavingStddevPopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  hightouchSnapshotId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
};

export type HightouchNodesHavingStddevSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  hightouchSnapshotId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
};

export type HightouchNodesHavingSumInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  hightouchSnapshotId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
};

export type HightouchNodesHavingVariancePopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  hightouchSnapshotId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
};

export type HightouchNodesHavingVarianceSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  hightouchSnapshotId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
};

/** Methods to use when ordering `HightouchNode`. */
export enum HightouchNodesOrderBy {
  AttributesAsc = 'ATTRIBUTES_ASC',
  AttributesDesc = 'ATTRIBUTES_DESC',
  CreatedDttmAsc = 'CREATED_DTTM_ASC',
  CreatedDttmDesc = 'CREATED_DTTM_DESC',
  HightouchIdAsc = 'HIGHTOUCH_ID_ASC',
  HightouchIdDesc = 'HIGHTOUCH_ID_DESC',
  HightouchSnapshotIdAsc = 'HIGHTOUCH_SNAPSHOT_ID_ASC',
  HightouchSnapshotIdDesc = 'HIGHTOUCH_SNAPSHOT_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  NodeTypeAsc = 'NODE_TYPE_ASC',
  NodeTypeDesc = 'NODE_TYPE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ReferencesAsc = 'REFERENCES_ASC',
  ReferencesDesc = 'REFERENCES_DESC',
  TagsAsc = 'TAGS_ASC',
  TagsDesc = 'TAGS_DESC'
}

export type HightouchSnapshot = Node & {
  __typename?: 'HightouchSnapshot';
  createdDttm: Scalars['Datetime'];
  /** Reads and enables pagination through a set of `HightouchNode`. */
  hightouchNodes: HightouchNodesConnection;
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  workspaceId: Scalars['Int'];
};


export type HightouchSnapshotHightouchNodesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<HightouchNodeCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<HightouchNodesOrderBy>>;
};

export type HightouchSnapshotAggregates = {
  __typename?: 'HightouchSnapshotAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<HightouchSnapshotAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<HightouchSnapshotDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<HightouchSnapshotMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<HightouchSnapshotMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<HightouchSnapshotStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<HightouchSnapshotStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<HightouchSnapshotSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<HightouchSnapshotVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<HightouchSnapshotVarianceSampleAggregates>;
};

export type HightouchSnapshotAverageAggregates = {
  __typename?: 'HightouchSnapshotAverageAggregates';
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `HightouchSnapshot` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type HightouchSnapshotCondition = {
  /** Checks for equality with the object’s `createdDttm` field. */
  createdDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `workspaceId` field. */
  workspaceId?: InputMaybe<Scalars['Int']>;
};

export type HightouchSnapshotDistinctCountAggregates = {
  __typename?: 'HightouchSnapshotDistinctCountAggregates';
  /** Distinct count of createdDttm across the matching connection */
  createdDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigInt']>;
};

/** Grouping methods for `HightouchSnapshot` for usage during aggregation. */
export enum HightouchSnapshotGroupBy {
  CreatedDttm = 'CREATED_DTTM',
  CreatedDttmTruncatedToDay = 'CREATED_DTTM_TRUNCATED_TO_DAY',
  CreatedDttmTruncatedToHour = 'CREATED_DTTM_TRUNCATED_TO_HOUR',
  WorkspaceId = 'WORKSPACE_ID'
}

export type HightouchSnapshotHavingAverageInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type HightouchSnapshotHavingDistinctCountInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `HightouchSnapshot` aggregates. */
export type HightouchSnapshotHavingInput = {
  AND?: InputMaybe<Array<HightouchSnapshotHavingInput>>;
  OR?: InputMaybe<Array<HightouchSnapshotHavingInput>>;
  average?: InputMaybe<HightouchSnapshotHavingAverageInput>;
  distinctCount?: InputMaybe<HightouchSnapshotHavingDistinctCountInput>;
  max?: InputMaybe<HightouchSnapshotHavingMaxInput>;
  min?: InputMaybe<HightouchSnapshotHavingMinInput>;
  stddevPopulation?: InputMaybe<HightouchSnapshotHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<HightouchSnapshotHavingStddevSampleInput>;
  sum?: InputMaybe<HightouchSnapshotHavingSumInput>;
  variancePopulation?: InputMaybe<HightouchSnapshotHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<HightouchSnapshotHavingVarianceSampleInput>;
};

export type HightouchSnapshotHavingMaxInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type HightouchSnapshotHavingMinInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type HightouchSnapshotHavingStddevPopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type HightouchSnapshotHavingStddevSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type HightouchSnapshotHavingSumInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type HightouchSnapshotHavingVariancePopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type HightouchSnapshotHavingVarianceSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type HightouchSnapshotMaxAggregates = {
  __typename?: 'HightouchSnapshotMaxAggregates';
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['Int']>;
};

export type HightouchSnapshotMinAggregates = {
  __typename?: 'HightouchSnapshotMinAggregates';
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['Int']>;
};

export type HightouchSnapshotStddevPopulationAggregates = {
  __typename?: 'HightouchSnapshotStddevPopulationAggregates';
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type HightouchSnapshotStddevSampleAggregates = {
  __typename?: 'HightouchSnapshotStddevSampleAggregates';
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type HightouchSnapshotSumAggregates = {
  __typename?: 'HightouchSnapshotSumAggregates';
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of workspaceId across the matching connection */
  workspaceId: Scalars['BigInt'];
};

export type HightouchSnapshotVariancePopulationAggregates = {
  __typename?: 'HightouchSnapshotVariancePopulationAggregates';
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type HightouchSnapshotVarianceSampleAggregates = {
  __typename?: 'HightouchSnapshotVarianceSampleAggregates';
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `HightouchSnapshot` values. */
export type HightouchSnapshotsConnection = {
  __typename?: 'HightouchSnapshotsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<HightouchSnapshotAggregates>;
  /** A list of edges which contains the `HightouchSnapshot` and cursor to aid in pagination. */
  edges: Array<HightouchSnapshotsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<HightouchSnapshotAggregates>>;
  /** A list of `HightouchSnapshot` objects. */
  nodes: Array<HightouchSnapshot>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `HightouchSnapshot` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `HightouchSnapshot` values. */
export type HightouchSnapshotsConnectionGroupedAggregatesArgs = {
  groupBy: Array<HightouchSnapshotGroupBy>;
  having?: InputMaybe<HightouchSnapshotHavingInput>;
};

/** A `HightouchSnapshot` edge in the connection. */
export type HightouchSnapshotsEdge = {
  __typename?: 'HightouchSnapshotsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `HightouchSnapshot` at the end of the edge. */
  node: HightouchSnapshot;
};

/** Methods to use when ordering `HightouchSnapshot`. */
export enum HightouchSnapshotsOrderBy {
  CreatedDttmAsc = 'CREATED_DTTM_ASC',
  CreatedDttmDesc = 'CREATED_DTTM_DESC',
  HightouchNodesAverageAttributesAsc = 'HIGHTOUCH_NODES_AVERAGE_ATTRIBUTES_ASC',
  HightouchNodesAverageAttributesDesc = 'HIGHTOUCH_NODES_AVERAGE_ATTRIBUTES_DESC',
  HightouchNodesAverageCreatedDttmAsc = 'HIGHTOUCH_NODES_AVERAGE_CREATED_DTTM_ASC',
  HightouchNodesAverageCreatedDttmDesc = 'HIGHTOUCH_NODES_AVERAGE_CREATED_DTTM_DESC',
  HightouchNodesAverageHightouchIdAsc = 'HIGHTOUCH_NODES_AVERAGE_HIGHTOUCH_ID_ASC',
  HightouchNodesAverageHightouchIdDesc = 'HIGHTOUCH_NODES_AVERAGE_HIGHTOUCH_ID_DESC',
  HightouchNodesAverageHightouchSnapshotIdAsc = 'HIGHTOUCH_NODES_AVERAGE_HIGHTOUCH_SNAPSHOT_ID_ASC',
  HightouchNodesAverageHightouchSnapshotIdDesc = 'HIGHTOUCH_NODES_AVERAGE_HIGHTOUCH_SNAPSHOT_ID_DESC',
  HightouchNodesAverageIdAsc = 'HIGHTOUCH_NODES_AVERAGE_ID_ASC',
  HightouchNodesAverageIdDesc = 'HIGHTOUCH_NODES_AVERAGE_ID_DESC',
  HightouchNodesAverageNameAsc = 'HIGHTOUCH_NODES_AVERAGE_NAME_ASC',
  HightouchNodesAverageNameDesc = 'HIGHTOUCH_NODES_AVERAGE_NAME_DESC',
  HightouchNodesAverageNodeTypeAsc = 'HIGHTOUCH_NODES_AVERAGE_NODE_TYPE_ASC',
  HightouchNodesAverageNodeTypeDesc = 'HIGHTOUCH_NODES_AVERAGE_NODE_TYPE_DESC',
  HightouchNodesAverageReferencesAsc = 'HIGHTOUCH_NODES_AVERAGE_REFERENCES_ASC',
  HightouchNodesAverageReferencesDesc = 'HIGHTOUCH_NODES_AVERAGE_REFERENCES_DESC',
  HightouchNodesAverageTagsAsc = 'HIGHTOUCH_NODES_AVERAGE_TAGS_ASC',
  HightouchNodesAverageTagsDesc = 'HIGHTOUCH_NODES_AVERAGE_TAGS_DESC',
  HightouchNodesCountAsc = 'HIGHTOUCH_NODES_COUNT_ASC',
  HightouchNodesCountDesc = 'HIGHTOUCH_NODES_COUNT_DESC',
  HightouchNodesDistinctCountAttributesAsc = 'HIGHTOUCH_NODES_DISTINCT_COUNT_ATTRIBUTES_ASC',
  HightouchNodesDistinctCountAttributesDesc = 'HIGHTOUCH_NODES_DISTINCT_COUNT_ATTRIBUTES_DESC',
  HightouchNodesDistinctCountCreatedDttmAsc = 'HIGHTOUCH_NODES_DISTINCT_COUNT_CREATED_DTTM_ASC',
  HightouchNodesDistinctCountCreatedDttmDesc = 'HIGHTOUCH_NODES_DISTINCT_COUNT_CREATED_DTTM_DESC',
  HightouchNodesDistinctCountHightouchIdAsc = 'HIGHTOUCH_NODES_DISTINCT_COUNT_HIGHTOUCH_ID_ASC',
  HightouchNodesDistinctCountHightouchIdDesc = 'HIGHTOUCH_NODES_DISTINCT_COUNT_HIGHTOUCH_ID_DESC',
  HightouchNodesDistinctCountHightouchSnapshotIdAsc = 'HIGHTOUCH_NODES_DISTINCT_COUNT_HIGHTOUCH_SNAPSHOT_ID_ASC',
  HightouchNodesDistinctCountHightouchSnapshotIdDesc = 'HIGHTOUCH_NODES_DISTINCT_COUNT_HIGHTOUCH_SNAPSHOT_ID_DESC',
  HightouchNodesDistinctCountIdAsc = 'HIGHTOUCH_NODES_DISTINCT_COUNT_ID_ASC',
  HightouchNodesDistinctCountIdDesc = 'HIGHTOUCH_NODES_DISTINCT_COUNT_ID_DESC',
  HightouchNodesDistinctCountNameAsc = 'HIGHTOUCH_NODES_DISTINCT_COUNT_NAME_ASC',
  HightouchNodesDistinctCountNameDesc = 'HIGHTOUCH_NODES_DISTINCT_COUNT_NAME_DESC',
  HightouchNodesDistinctCountNodeTypeAsc = 'HIGHTOUCH_NODES_DISTINCT_COUNT_NODE_TYPE_ASC',
  HightouchNodesDistinctCountNodeTypeDesc = 'HIGHTOUCH_NODES_DISTINCT_COUNT_NODE_TYPE_DESC',
  HightouchNodesDistinctCountReferencesAsc = 'HIGHTOUCH_NODES_DISTINCT_COUNT_REFERENCES_ASC',
  HightouchNodesDistinctCountReferencesDesc = 'HIGHTOUCH_NODES_DISTINCT_COUNT_REFERENCES_DESC',
  HightouchNodesDistinctCountTagsAsc = 'HIGHTOUCH_NODES_DISTINCT_COUNT_TAGS_ASC',
  HightouchNodesDistinctCountTagsDesc = 'HIGHTOUCH_NODES_DISTINCT_COUNT_TAGS_DESC',
  HightouchNodesMaxAttributesAsc = 'HIGHTOUCH_NODES_MAX_ATTRIBUTES_ASC',
  HightouchNodesMaxAttributesDesc = 'HIGHTOUCH_NODES_MAX_ATTRIBUTES_DESC',
  HightouchNodesMaxCreatedDttmAsc = 'HIGHTOUCH_NODES_MAX_CREATED_DTTM_ASC',
  HightouchNodesMaxCreatedDttmDesc = 'HIGHTOUCH_NODES_MAX_CREATED_DTTM_DESC',
  HightouchNodesMaxHightouchIdAsc = 'HIGHTOUCH_NODES_MAX_HIGHTOUCH_ID_ASC',
  HightouchNodesMaxHightouchIdDesc = 'HIGHTOUCH_NODES_MAX_HIGHTOUCH_ID_DESC',
  HightouchNodesMaxHightouchSnapshotIdAsc = 'HIGHTOUCH_NODES_MAX_HIGHTOUCH_SNAPSHOT_ID_ASC',
  HightouchNodesMaxHightouchSnapshotIdDesc = 'HIGHTOUCH_NODES_MAX_HIGHTOUCH_SNAPSHOT_ID_DESC',
  HightouchNodesMaxIdAsc = 'HIGHTOUCH_NODES_MAX_ID_ASC',
  HightouchNodesMaxIdDesc = 'HIGHTOUCH_NODES_MAX_ID_DESC',
  HightouchNodesMaxNameAsc = 'HIGHTOUCH_NODES_MAX_NAME_ASC',
  HightouchNodesMaxNameDesc = 'HIGHTOUCH_NODES_MAX_NAME_DESC',
  HightouchNodesMaxNodeTypeAsc = 'HIGHTOUCH_NODES_MAX_NODE_TYPE_ASC',
  HightouchNodesMaxNodeTypeDesc = 'HIGHTOUCH_NODES_MAX_NODE_TYPE_DESC',
  HightouchNodesMaxReferencesAsc = 'HIGHTOUCH_NODES_MAX_REFERENCES_ASC',
  HightouchNodesMaxReferencesDesc = 'HIGHTOUCH_NODES_MAX_REFERENCES_DESC',
  HightouchNodesMaxTagsAsc = 'HIGHTOUCH_NODES_MAX_TAGS_ASC',
  HightouchNodesMaxTagsDesc = 'HIGHTOUCH_NODES_MAX_TAGS_DESC',
  HightouchNodesMinAttributesAsc = 'HIGHTOUCH_NODES_MIN_ATTRIBUTES_ASC',
  HightouchNodesMinAttributesDesc = 'HIGHTOUCH_NODES_MIN_ATTRIBUTES_DESC',
  HightouchNodesMinCreatedDttmAsc = 'HIGHTOUCH_NODES_MIN_CREATED_DTTM_ASC',
  HightouchNodesMinCreatedDttmDesc = 'HIGHTOUCH_NODES_MIN_CREATED_DTTM_DESC',
  HightouchNodesMinHightouchIdAsc = 'HIGHTOUCH_NODES_MIN_HIGHTOUCH_ID_ASC',
  HightouchNodesMinHightouchIdDesc = 'HIGHTOUCH_NODES_MIN_HIGHTOUCH_ID_DESC',
  HightouchNodesMinHightouchSnapshotIdAsc = 'HIGHTOUCH_NODES_MIN_HIGHTOUCH_SNAPSHOT_ID_ASC',
  HightouchNodesMinHightouchSnapshotIdDesc = 'HIGHTOUCH_NODES_MIN_HIGHTOUCH_SNAPSHOT_ID_DESC',
  HightouchNodesMinIdAsc = 'HIGHTOUCH_NODES_MIN_ID_ASC',
  HightouchNodesMinIdDesc = 'HIGHTOUCH_NODES_MIN_ID_DESC',
  HightouchNodesMinNameAsc = 'HIGHTOUCH_NODES_MIN_NAME_ASC',
  HightouchNodesMinNameDesc = 'HIGHTOUCH_NODES_MIN_NAME_DESC',
  HightouchNodesMinNodeTypeAsc = 'HIGHTOUCH_NODES_MIN_NODE_TYPE_ASC',
  HightouchNodesMinNodeTypeDesc = 'HIGHTOUCH_NODES_MIN_NODE_TYPE_DESC',
  HightouchNodesMinReferencesAsc = 'HIGHTOUCH_NODES_MIN_REFERENCES_ASC',
  HightouchNodesMinReferencesDesc = 'HIGHTOUCH_NODES_MIN_REFERENCES_DESC',
  HightouchNodesMinTagsAsc = 'HIGHTOUCH_NODES_MIN_TAGS_ASC',
  HightouchNodesMinTagsDesc = 'HIGHTOUCH_NODES_MIN_TAGS_DESC',
  HightouchNodesStddevPopulationAttributesAsc = 'HIGHTOUCH_NODES_STDDEV_POPULATION_ATTRIBUTES_ASC',
  HightouchNodesStddevPopulationAttributesDesc = 'HIGHTOUCH_NODES_STDDEV_POPULATION_ATTRIBUTES_DESC',
  HightouchNodesStddevPopulationCreatedDttmAsc = 'HIGHTOUCH_NODES_STDDEV_POPULATION_CREATED_DTTM_ASC',
  HightouchNodesStddevPopulationCreatedDttmDesc = 'HIGHTOUCH_NODES_STDDEV_POPULATION_CREATED_DTTM_DESC',
  HightouchNodesStddevPopulationHightouchIdAsc = 'HIGHTOUCH_NODES_STDDEV_POPULATION_HIGHTOUCH_ID_ASC',
  HightouchNodesStddevPopulationHightouchIdDesc = 'HIGHTOUCH_NODES_STDDEV_POPULATION_HIGHTOUCH_ID_DESC',
  HightouchNodesStddevPopulationHightouchSnapshotIdAsc = 'HIGHTOUCH_NODES_STDDEV_POPULATION_HIGHTOUCH_SNAPSHOT_ID_ASC',
  HightouchNodesStddevPopulationHightouchSnapshotIdDesc = 'HIGHTOUCH_NODES_STDDEV_POPULATION_HIGHTOUCH_SNAPSHOT_ID_DESC',
  HightouchNodesStddevPopulationIdAsc = 'HIGHTOUCH_NODES_STDDEV_POPULATION_ID_ASC',
  HightouchNodesStddevPopulationIdDesc = 'HIGHTOUCH_NODES_STDDEV_POPULATION_ID_DESC',
  HightouchNodesStddevPopulationNameAsc = 'HIGHTOUCH_NODES_STDDEV_POPULATION_NAME_ASC',
  HightouchNodesStddevPopulationNameDesc = 'HIGHTOUCH_NODES_STDDEV_POPULATION_NAME_DESC',
  HightouchNodesStddevPopulationNodeTypeAsc = 'HIGHTOUCH_NODES_STDDEV_POPULATION_NODE_TYPE_ASC',
  HightouchNodesStddevPopulationNodeTypeDesc = 'HIGHTOUCH_NODES_STDDEV_POPULATION_NODE_TYPE_DESC',
  HightouchNodesStddevPopulationReferencesAsc = 'HIGHTOUCH_NODES_STDDEV_POPULATION_REFERENCES_ASC',
  HightouchNodesStddevPopulationReferencesDesc = 'HIGHTOUCH_NODES_STDDEV_POPULATION_REFERENCES_DESC',
  HightouchNodesStddevPopulationTagsAsc = 'HIGHTOUCH_NODES_STDDEV_POPULATION_TAGS_ASC',
  HightouchNodesStddevPopulationTagsDesc = 'HIGHTOUCH_NODES_STDDEV_POPULATION_TAGS_DESC',
  HightouchNodesStddevSampleAttributesAsc = 'HIGHTOUCH_NODES_STDDEV_SAMPLE_ATTRIBUTES_ASC',
  HightouchNodesStddevSampleAttributesDesc = 'HIGHTOUCH_NODES_STDDEV_SAMPLE_ATTRIBUTES_DESC',
  HightouchNodesStddevSampleCreatedDttmAsc = 'HIGHTOUCH_NODES_STDDEV_SAMPLE_CREATED_DTTM_ASC',
  HightouchNodesStddevSampleCreatedDttmDesc = 'HIGHTOUCH_NODES_STDDEV_SAMPLE_CREATED_DTTM_DESC',
  HightouchNodesStddevSampleHightouchIdAsc = 'HIGHTOUCH_NODES_STDDEV_SAMPLE_HIGHTOUCH_ID_ASC',
  HightouchNodesStddevSampleHightouchIdDesc = 'HIGHTOUCH_NODES_STDDEV_SAMPLE_HIGHTOUCH_ID_DESC',
  HightouchNodesStddevSampleHightouchSnapshotIdAsc = 'HIGHTOUCH_NODES_STDDEV_SAMPLE_HIGHTOUCH_SNAPSHOT_ID_ASC',
  HightouchNodesStddevSampleHightouchSnapshotIdDesc = 'HIGHTOUCH_NODES_STDDEV_SAMPLE_HIGHTOUCH_SNAPSHOT_ID_DESC',
  HightouchNodesStddevSampleIdAsc = 'HIGHTOUCH_NODES_STDDEV_SAMPLE_ID_ASC',
  HightouchNodesStddevSampleIdDesc = 'HIGHTOUCH_NODES_STDDEV_SAMPLE_ID_DESC',
  HightouchNodesStddevSampleNameAsc = 'HIGHTOUCH_NODES_STDDEV_SAMPLE_NAME_ASC',
  HightouchNodesStddevSampleNameDesc = 'HIGHTOUCH_NODES_STDDEV_SAMPLE_NAME_DESC',
  HightouchNodesStddevSampleNodeTypeAsc = 'HIGHTOUCH_NODES_STDDEV_SAMPLE_NODE_TYPE_ASC',
  HightouchNodesStddevSampleNodeTypeDesc = 'HIGHTOUCH_NODES_STDDEV_SAMPLE_NODE_TYPE_DESC',
  HightouchNodesStddevSampleReferencesAsc = 'HIGHTOUCH_NODES_STDDEV_SAMPLE_REFERENCES_ASC',
  HightouchNodesStddevSampleReferencesDesc = 'HIGHTOUCH_NODES_STDDEV_SAMPLE_REFERENCES_DESC',
  HightouchNodesStddevSampleTagsAsc = 'HIGHTOUCH_NODES_STDDEV_SAMPLE_TAGS_ASC',
  HightouchNodesStddevSampleTagsDesc = 'HIGHTOUCH_NODES_STDDEV_SAMPLE_TAGS_DESC',
  HightouchNodesSumAttributesAsc = 'HIGHTOUCH_NODES_SUM_ATTRIBUTES_ASC',
  HightouchNodesSumAttributesDesc = 'HIGHTOUCH_NODES_SUM_ATTRIBUTES_DESC',
  HightouchNodesSumCreatedDttmAsc = 'HIGHTOUCH_NODES_SUM_CREATED_DTTM_ASC',
  HightouchNodesSumCreatedDttmDesc = 'HIGHTOUCH_NODES_SUM_CREATED_DTTM_DESC',
  HightouchNodesSumHightouchIdAsc = 'HIGHTOUCH_NODES_SUM_HIGHTOUCH_ID_ASC',
  HightouchNodesSumHightouchIdDesc = 'HIGHTOUCH_NODES_SUM_HIGHTOUCH_ID_DESC',
  HightouchNodesSumHightouchSnapshotIdAsc = 'HIGHTOUCH_NODES_SUM_HIGHTOUCH_SNAPSHOT_ID_ASC',
  HightouchNodesSumHightouchSnapshotIdDesc = 'HIGHTOUCH_NODES_SUM_HIGHTOUCH_SNAPSHOT_ID_DESC',
  HightouchNodesSumIdAsc = 'HIGHTOUCH_NODES_SUM_ID_ASC',
  HightouchNodesSumIdDesc = 'HIGHTOUCH_NODES_SUM_ID_DESC',
  HightouchNodesSumNameAsc = 'HIGHTOUCH_NODES_SUM_NAME_ASC',
  HightouchNodesSumNameDesc = 'HIGHTOUCH_NODES_SUM_NAME_DESC',
  HightouchNodesSumNodeTypeAsc = 'HIGHTOUCH_NODES_SUM_NODE_TYPE_ASC',
  HightouchNodesSumNodeTypeDesc = 'HIGHTOUCH_NODES_SUM_NODE_TYPE_DESC',
  HightouchNodesSumReferencesAsc = 'HIGHTOUCH_NODES_SUM_REFERENCES_ASC',
  HightouchNodesSumReferencesDesc = 'HIGHTOUCH_NODES_SUM_REFERENCES_DESC',
  HightouchNodesSumTagsAsc = 'HIGHTOUCH_NODES_SUM_TAGS_ASC',
  HightouchNodesSumTagsDesc = 'HIGHTOUCH_NODES_SUM_TAGS_DESC',
  HightouchNodesVariancePopulationAttributesAsc = 'HIGHTOUCH_NODES_VARIANCE_POPULATION_ATTRIBUTES_ASC',
  HightouchNodesVariancePopulationAttributesDesc = 'HIGHTOUCH_NODES_VARIANCE_POPULATION_ATTRIBUTES_DESC',
  HightouchNodesVariancePopulationCreatedDttmAsc = 'HIGHTOUCH_NODES_VARIANCE_POPULATION_CREATED_DTTM_ASC',
  HightouchNodesVariancePopulationCreatedDttmDesc = 'HIGHTOUCH_NODES_VARIANCE_POPULATION_CREATED_DTTM_DESC',
  HightouchNodesVariancePopulationHightouchIdAsc = 'HIGHTOUCH_NODES_VARIANCE_POPULATION_HIGHTOUCH_ID_ASC',
  HightouchNodesVariancePopulationHightouchIdDesc = 'HIGHTOUCH_NODES_VARIANCE_POPULATION_HIGHTOUCH_ID_DESC',
  HightouchNodesVariancePopulationHightouchSnapshotIdAsc = 'HIGHTOUCH_NODES_VARIANCE_POPULATION_HIGHTOUCH_SNAPSHOT_ID_ASC',
  HightouchNodesVariancePopulationHightouchSnapshotIdDesc = 'HIGHTOUCH_NODES_VARIANCE_POPULATION_HIGHTOUCH_SNAPSHOT_ID_DESC',
  HightouchNodesVariancePopulationIdAsc = 'HIGHTOUCH_NODES_VARIANCE_POPULATION_ID_ASC',
  HightouchNodesVariancePopulationIdDesc = 'HIGHTOUCH_NODES_VARIANCE_POPULATION_ID_DESC',
  HightouchNodesVariancePopulationNameAsc = 'HIGHTOUCH_NODES_VARIANCE_POPULATION_NAME_ASC',
  HightouchNodesVariancePopulationNameDesc = 'HIGHTOUCH_NODES_VARIANCE_POPULATION_NAME_DESC',
  HightouchNodesVariancePopulationNodeTypeAsc = 'HIGHTOUCH_NODES_VARIANCE_POPULATION_NODE_TYPE_ASC',
  HightouchNodesVariancePopulationNodeTypeDesc = 'HIGHTOUCH_NODES_VARIANCE_POPULATION_NODE_TYPE_DESC',
  HightouchNodesVariancePopulationReferencesAsc = 'HIGHTOUCH_NODES_VARIANCE_POPULATION_REFERENCES_ASC',
  HightouchNodesVariancePopulationReferencesDesc = 'HIGHTOUCH_NODES_VARIANCE_POPULATION_REFERENCES_DESC',
  HightouchNodesVariancePopulationTagsAsc = 'HIGHTOUCH_NODES_VARIANCE_POPULATION_TAGS_ASC',
  HightouchNodesVariancePopulationTagsDesc = 'HIGHTOUCH_NODES_VARIANCE_POPULATION_TAGS_DESC',
  HightouchNodesVarianceSampleAttributesAsc = 'HIGHTOUCH_NODES_VARIANCE_SAMPLE_ATTRIBUTES_ASC',
  HightouchNodesVarianceSampleAttributesDesc = 'HIGHTOUCH_NODES_VARIANCE_SAMPLE_ATTRIBUTES_DESC',
  HightouchNodesVarianceSampleCreatedDttmAsc = 'HIGHTOUCH_NODES_VARIANCE_SAMPLE_CREATED_DTTM_ASC',
  HightouchNodesVarianceSampleCreatedDttmDesc = 'HIGHTOUCH_NODES_VARIANCE_SAMPLE_CREATED_DTTM_DESC',
  HightouchNodesVarianceSampleHightouchIdAsc = 'HIGHTOUCH_NODES_VARIANCE_SAMPLE_HIGHTOUCH_ID_ASC',
  HightouchNodesVarianceSampleHightouchIdDesc = 'HIGHTOUCH_NODES_VARIANCE_SAMPLE_HIGHTOUCH_ID_DESC',
  HightouchNodesVarianceSampleHightouchSnapshotIdAsc = 'HIGHTOUCH_NODES_VARIANCE_SAMPLE_HIGHTOUCH_SNAPSHOT_ID_ASC',
  HightouchNodesVarianceSampleHightouchSnapshotIdDesc = 'HIGHTOUCH_NODES_VARIANCE_SAMPLE_HIGHTOUCH_SNAPSHOT_ID_DESC',
  HightouchNodesVarianceSampleIdAsc = 'HIGHTOUCH_NODES_VARIANCE_SAMPLE_ID_ASC',
  HightouchNodesVarianceSampleIdDesc = 'HIGHTOUCH_NODES_VARIANCE_SAMPLE_ID_DESC',
  HightouchNodesVarianceSampleNameAsc = 'HIGHTOUCH_NODES_VARIANCE_SAMPLE_NAME_ASC',
  HightouchNodesVarianceSampleNameDesc = 'HIGHTOUCH_NODES_VARIANCE_SAMPLE_NAME_DESC',
  HightouchNodesVarianceSampleNodeTypeAsc = 'HIGHTOUCH_NODES_VARIANCE_SAMPLE_NODE_TYPE_ASC',
  HightouchNodesVarianceSampleNodeTypeDesc = 'HIGHTOUCH_NODES_VARIANCE_SAMPLE_NODE_TYPE_DESC',
  HightouchNodesVarianceSampleReferencesAsc = 'HIGHTOUCH_NODES_VARIANCE_SAMPLE_REFERENCES_ASC',
  HightouchNodesVarianceSampleReferencesDesc = 'HIGHTOUCH_NODES_VARIANCE_SAMPLE_REFERENCES_DESC',
  HightouchNodesVarianceSampleTagsAsc = 'HIGHTOUCH_NODES_VARIANCE_SAMPLE_TAGS_ASC',
  HightouchNodesVarianceSampleTagsDesc = 'HIGHTOUCH_NODES_VARIANCE_SAMPLE_TAGS_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  WorkspaceIdAsc = 'WORKSPACE_ID_ASC',
  WorkspaceIdDesc = 'WORKSPACE_ID_DESC'
}

export type InformationSchemaSnapshotV2 = Node & {
  __typename?: 'InformationSchemaSnapshotV2';
  createdDttm: Scalars['Datetime'];
  environmentName: Scalars['String'];
  id: Scalars['Int'];
  /** Reads and enables pagination through a set of `InformationSchemaTableV2`. */
  informationSchemaTableV2SByInformationSchemaSnapshotId: InformationSchemaTableV2sConnection;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  workspaceId: Scalars['Int'];
};


export type InformationSchemaSnapshotV2InformationSchemaTableV2SByInformationSchemaSnapshotIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<InformationSchemaTableV2Condition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InformationSchemaTableV2sOrderBy>>;
};

export type InformationSchemaSnapshotV2Aggregates = {
  __typename?: 'InformationSchemaSnapshotV2Aggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<InformationSchemaSnapshotV2AverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<InformationSchemaSnapshotV2DistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<InformationSchemaSnapshotV2MaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<InformationSchemaSnapshotV2MinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<InformationSchemaSnapshotV2StddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<InformationSchemaSnapshotV2StddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<InformationSchemaSnapshotV2SumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<InformationSchemaSnapshotV2VariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<InformationSchemaSnapshotV2VarianceSampleAggregates>;
};

export type InformationSchemaSnapshotV2AverageAggregates = {
  __typename?: 'InformationSchemaSnapshotV2AverageAggregates';
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `InformationSchemaSnapshotV2` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type InformationSchemaSnapshotV2Condition = {
  /** Checks for equality with the object’s `createdDttm` field. */
  createdDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `environmentName` field. */
  environmentName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `workspaceId` field. */
  workspaceId?: InputMaybe<Scalars['Int']>;
};

export type InformationSchemaSnapshotV2DistinctCountAggregates = {
  __typename?: 'InformationSchemaSnapshotV2DistinctCountAggregates';
  /** Distinct count of createdDttm across the matching connection */
  createdDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of environmentName across the matching connection */
  environmentName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigInt']>;
};

/** Grouping methods for `InformationSchemaSnapshotV2` for usage during aggregation. */
export enum InformationSchemaSnapshotV2GroupBy {
  CreatedDttm = 'CREATED_DTTM',
  CreatedDttmTruncatedToDay = 'CREATED_DTTM_TRUNCATED_TO_DAY',
  CreatedDttmTruncatedToHour = 'CREATED_DTTM_TRUNCATED_TO_HOUR',
  EnvironmentName = 'ENVIRONMENT_NAME',
  WorkspaceId = 'WORKSPACE_ID'
}

export type InformationSchemaSnapshotV2HavingAverageInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type InformationSchemaSnapshotV2HavingDistinctCountInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `InformationSchemaSnapshotV2` aggregates. */
export type InformationSchemaSnapshotV2HavingInput = {
  AND?: InputMaybe<Array<InformationSchemaSnapshotV2HavingInput>>;
  OR?: InputMaybe<Array<InformationSchemaSnapshotV2HavingInput>>;
  average?: InputMaybe<InformationSchemaSnapshotV2HavingAverageInput>;
  distinctCount?: InputMaybe<InformationSchemaSnapshotV2HavingDistinctCountInput>;
  max?: InputMaybe<InformationSchemaSnapshotV2HavingMaxInput>;
  min?: InputMaybe<InformationSchemaSnapshotV2HavingMinInput>;
  stddevPopulation?: InputMaybe<InformationSchemaSnapshotV2HavingStddevPopulationInput>;
  stddevSample?: InputMaybe<InformationSchemaSnapshotV2HavingStddevSampleInput>;
  sum?: InputMaybe<InformationSchemaSnapshotV2HavingSumInput>;
  variancePopulation?: InputMaybe<InformationSchemaSnapshotV2HavingVariancePopulationInput>;
  varianceSample?: InputMaybe<InformationSchemaSnapshotV2HavingVarianceSampleInput>;
};

export type InformationSchemaSnapshotV2HavingMaxInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type InformationSchemaSnapshotV2HavingMinInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type InformationSchemaSnapshotV2HavingStddevPopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type InformationSchemaSnapshotV2HavingStddevSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type InformationSchemaSnapshotV2HavingSumInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type InformationSchemaSnapshotV2HavingVariancePopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type InformationSchemaSnapshotV2HavingVarianceSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type InformationSchemaSnapshotV2MaxAggregates = {
  __typename?: 'InformationSchemaSnapshotV2MaxAggregates';
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['Int']>;
};

export type InformationSchemaSnapshotV2MinAggregates = {
  __typename?: 'InformationSchemaSnapshotV2MinAggregates';
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['Int']>;
};

export type InformationSchemaSnapshotV2StddevPopulationAggregates = {
  __typename?: 'InformationSchemaSnapshotV2StddevPopulationAggregates';
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type InformationSchemaSnapshotV2StddevSampleAggregates = {
  __typename?: 'InformationSchemaSnapshotV2StddevSampleAggregates';
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type InformationSchemaSnapshotV2SumAggregates = {
  __typename?: 'InformationSchemaSnapshotV2SumAggregates';
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of workspaceId across the matching connection */
  workspaceId: Scalars['BigInt'];
};

export type InformationSchemaSnapshotV2VariancePopulationAggregates = {
  __typename?: 'InformationSchemaSnapshotV2VariancePopulationAggregates';
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type InformationSchemaSnapshotV2VarianceSampleAggregates = {
  __typename?: 'InformationSchemaSnapshotV2VarianceSampleAggregates';
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `InformationSchemaSnapshotV2` values. */
export type InformationSchemaSnapshotV2sConnection = {
  __typename?: 'InformationSchemaSnapshotV2sConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<InformationSchemaSnapshotV2Aggregates>;
  /** A list of edges which contains the `InformationSchemaSnapshotV2` and cursor to aid in pagination. */
  edges: Array<InformationSchemaSnapshotV2sEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<InformationSchemaSnapshotV2Aggregates>>;
  /** A list of `InformationSchemaSnapshotV2` objects. */
  nodes: Array<InformationSchemaSnapshotV2>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `InformationSchemaSnapshotV2` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `InformationSchemaSnapshotV2` values. */
export type InformationSchemaSnapshotV2sConnectionGroupedAggregatesArgs = {
  groupBy: Array<InformationSchemaSnapshotV2GroupBy>;
  having?: InputMaybe<InformationSchemaSnapshotV2HavingInput>;
};

/** A `InformationSchemaSnapshotV2` edge in the connection. */
export type InformationSchemaSnapshotV2sEdge = {
  __typename?: 'InformationSchemaSnapshotV2sEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `InformationSchemaSnapshotV2` at the end of the edge. */
  node: InformationSchemaSnapshotV2;
};

/** Methods to use when ordering `InformationSchemaSnapshotV2`. */
export enum InformationSchemaSnapshotV2sOrderBy {
  CreatedDttmAsc = 'CREATED_DTTM_ASC',
  CreatedDttmDesc = 'CREATED_DTTM_DESC',
  EnvironmentNameAsc = 'ENVIRONMENT_NAME_ASC',
  EnvironmentNameDesc = 'ENVIRONMENT_NAME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdAverageAdditionalDataAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_AVERAGE_ADDITIONAL_DATA_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdAverageAdditionalDataDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_AVERAGE_ADDITIONAL_DATA_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdAverageCreatedDttmAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_AVERAGE_CREATED_DTTM_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdAverageCreatedDttmDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_AVERAGE_CREATED_DTTM_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdAverageCreatedOnDttmAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_AVERAGE_CREATED_ON_DTTM_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdAverageCreatedOnDttmDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_AVERAGE_CREATED_ON_DTTM_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdAverageDatabaseAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_AVERAGE_DATABASE_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdAverageDatabaseDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_AVERAGE_DATABASE_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdAverageDescriptionAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_AVERAGE_DESCRIPTION_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdAverageDescriptionDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_AVERAGE_DESCRIPTION_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdAverageDwhFlavourAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_AVERAGE_DWH_FLAVOUR_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdAverageDwhFlavourDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_AVERAGE_DWH_FLAVOUR_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdAverageEncodedColumnsAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_AVERAGE_ENCODED_COLUMNS_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdAverageEncodedColumnsDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_AVERAGE_ENCODED_COLUMNS_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdAverageIdAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_AVERAGE_ID_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdAverageIdDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_AVERAGE_ID_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdAverageInformationSchemaSnapshotIdAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_AVERAGE_INFORMATION_SCHEMA_SNAPSHOT_ID_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdAverageInformationSchemaSnapshotIdDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_AVERAGE_INFORMATION_SCHEMA_SNAPSHOT_ID_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdAverageLastModifiedDttmAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_AVERAGE_LAST_MODIFIED_DTTM_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdAverageLastModifiedDttmDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_AVERAGE_LAST_MODIFIED_DTTM_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdAverageNameAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_AVERAGE_NAME_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdAverageNameDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_AVERAGE_NAME_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdAverageRowCountAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_AVERAGE_ROW_COUNT_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdAverageRowCountDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_AVERAGE_ROW_COUNT_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdAverageSchemaAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_AVERAGE_SCHEMA_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdAverageSchemaDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_AVERAGE_SCHEMA_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdAverageSizeInBytesAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_AVERAGE_SIZE_IN_BYTES_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdAverageSizeInBytesDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_AVERAGE_SIZE_IN_BYTES_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdAverageTableTypeAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_AVERAGE_TABLE_TYPE_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdAverageTableTypeDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_AVERAGE_TABLE_TYPE_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdCountAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_COUNT_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdCountDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_COUNT_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdDistinctCountAdditionalDataAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_DISTINCT_COUNT_ADDITIONAL_DATA_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdDistinctCountAdditionalDataDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_DISTINCT_COUNT_ADDITIONAL_DATA_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdDistinctCountCreatedDttmAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_DISTINCT_COUNT_CREATED_DTTM_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdDistinctCountCreatedDttmDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_DISTINCT_COUNT_CREATED_DTTM_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdDistinctCountCreatedOnDttmAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_DISTINCT_COUNT_CREATED_ON_DTTM_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdDistinctCountCreatedOnDttmDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_DISTINCT_COUNT_CREATED_ON_DTTM_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdDistinctCountDatabaseAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_DISTINCT_COUNT_DATABASE_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdDistinctCountDatabaseDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_DISTINCT_COUNT_DATABASE_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdDistinctCountDescriptionAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_DISTINCT_COUNT_DESCRIPTION_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdDistinctCountDescriptionDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_DISTINCT_COUNT_DESCRIPTION_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdDistinctCountDwhFlavourAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_DISTINCT_COUNT_DWH_FLAVOUR_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdDistinctCountDwhFlavourDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_DISTINCT_COUNT_DWH_FLAVOUR_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdDistinctCountEncodedColumnsAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_DISTINCT_COUNT_ENCODED_COLUMNS_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdDistinctCountEncodedColumnsDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_DISTINCT_COUNT_ENCODED_COLUMNS_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdDistinctCountIdAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_DISTINCT_COUNT_ID_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdDistinctCountIdDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_DISTINCT_COUNT_ID_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdDistinctCountInformationSchemaSnapshotIdAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_DISTINCT_COUNT_INFORMATION_SCHEMA_SNAPSHOT_ID_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdDistinctCountInformationSchemaSnapshotIdDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_DISTINCT_COUNT_INFORMATION_SCHEMA_SNAPSHOT_ID_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdDistinctCountLastModifiedDttmAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_DISTINCT_COUNT_LAST_MODIFIED_DTTM_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdDistinctCountLastModifiedDttmDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_DISTINCT_COUNT_LAST_MODIFIED_DTTM_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdDistinctCountNameAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_DISTINCT_COUNT_NAME_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdDistinctCountNameDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_DISTINCT_COUNT_NAME_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdDistinctCountRowCountAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_DISTINCT_COUNT_ROW_COUNT_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdDistinctCountRowCountDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_DISTINCT_COUNT_ROW_COUNT_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdDistinctCountSchemaAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_DISTINCT_COUNT_SCHEMA_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdDistinctCountSchemaDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_DISTINCT_COUNT_SCHEMA_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdDistinctCountSizeInBytesAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_DISTINCT_COUNT_SIZE_IN_BYTES_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdDistinctCountSizeInBytesDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_DISTINCT_COUNT_SIZE_IN_BYTES_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdDistinctCountTableTypeAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_DISTINCT_COUNT_TABLE_TYPE_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdDistinctCountTableTypeDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_DISTINCT_COUNT_TABLE_TYPE_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMaxAdditionalDataAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MAX_ADDITIONAL_DATA_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMaxAdditionalDataDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MAX_ADDITIONAL_DATA_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMaxCreatedDttmAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MAX_CREATED_DTTM_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMaxCreatedDttmDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MAX_CREATED_DTTM_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMaxCreatedOnDttmAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MAX_CREATED_ON_DTTM_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMaxCreatedOnDttmDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MAX_CREATED_ON_DTTM_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMaxDatabaseAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MAX_DATABASE_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMaxDatabaseDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MAX_DATABASE_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMaxDescriptionAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MAX_DESCRIPTION_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMaxDescriptionDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MAX_DESCRIPTION_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMaxDwhFlavourAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MAX_DWH_FLAVOUR_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMaxDwhFlavourDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MAX_DWH_FLAVOUR_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMaxEncodedColumnsAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MAX_ENCODED_COLUMNS_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMaxEncodedColumnsDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MAX_ENCODED_COLUMNS_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMaxIdAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MAX_ID_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMaxIdDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MAX_ID_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMaxInformationSchemaSnapshotIdAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MAX_INFORMATION_SCHEMA_SNAPSHOT_ID_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMaxInformationSchemaSnapshotIdDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MAX_INFORMATION_SCHEMA_SNAPSHOT_ID_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMaxLastModifiedDttmAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MAX_LAST_MODIFIED_DTTM_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMaxLastModifiedDttmDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MAX_LAST_MODIFIED_DTTM_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMaxNameAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MAX_NAME_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMaxNameDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MAX_NAME_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMaxRowCountAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MAX_ROW_COUNT_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMaxRowCountDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MAX_ROW_COUNT_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMaxSchemaAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MAX_SCHEMA_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMaxSchemaDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MAX_SCHEMA_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMaxSizeInBytesAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MAX_SIZE_IN_BYTES_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMaxSizeInBytesDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MAX_SIZE_IN_BYTES_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMaxTableTypeAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MAX_TABLE_TYPE_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMaxTableTypeDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MAX_TABLE_TYPE_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMinAdditionalDataAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MIN_ADDITIONAL_DATA_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMinAdditionalDataDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MIN_ADDITIONAL_DATA_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMinCreatedDttmAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MIN_CREATED_DTTM_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMinCreatedDttmDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MIN_CREATED_DTTM_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMinCreatedOnDttmAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MIN_CREATED_ON_DTTM_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMinCreatedOnDttmDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MIN_CREATED_ON_DTTM_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMinDatabaseAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MIN_DATABASE_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMinDatabaseDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MIN_DATABASE_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMinDescriptionAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MIN_DESCRIPTION_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMinDescriptionDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MIN_DESCRIPTION_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMinDwhFlavourAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MIN_DWH_FLAVOUR_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMinDwhFlavourDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MIN_DWH_FLAVOUR_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMinEncodedColumnsAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MIN_ENCODED_COLUMNS_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMinEncodedColumnsDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MIN_ENCODED_COLUMNS_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMinIdAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MIN_ID_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMinIdDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MIN_ID_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMinInformationSchemaSnapshotIdAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MIN_INFORMATION_SCHEMA_SNAPSHOT_ID_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMinInformationSchemaSnapshotIdDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MIN_INFORMATION_SCHEMA_SNAPSHOT_ID_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMinLastModifiedDttmAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MIN_LAST_MODIFIED_DTTM_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMinLastModifiedDttmDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MIN_LAST_MODIFIED_DTTM_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMinNameAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MIN_NAME_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMinNameDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MIN_NAME_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMinRowCountAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MIN_ROW_COUNT_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMinRowCountDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MIN_ROW_COUNT_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMinSchemaAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MIN_SCHEMA_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMinSchemaDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MIN_SCHEMA_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMinSizeInBytesAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MIN_SIZE_IN_BYTES_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMinSizeInBytesDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MIN_SIZE_IN_BYTES_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMinTableTypeAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MIN_TABLE_TYPE_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdMinTableTypeDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_MIN_TABLE_TYPE_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevPopulationAdditionalDataAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_POPULATION_ADDITIONAL_DATA_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevPopulationAdditionalDataDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_POPULATION_ADDITIONAL_DATA_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevPopulationCreatedDttmAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_POPULATION_CREATED_DTTM_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevPopulationCreatedDttmDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_POPULATION_CREATED_DTTM_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevPopulationCreatedOnDttmAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_POPULATION_CREATED_ON_DTTM_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevPopulationCreatedOnDttmDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_POPULATION_CREATED_ON_DTTM_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevPopulationDatabaseAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_POPULATION_DATABASE_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevPopulationDatabaseDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_POPULATION_DATABASE_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevPopulationDescriptionAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_POPULATION_DESCRIPTION_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevPopulationDescriptionDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_POPULATION_DESCRIPTION_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevPopulationDwhFlavourAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_POPULATION_DWH_FLAVOUR_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevPopulationDwhFlavourDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_POPULATION_DWH_FLAVOUR_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevPopulationEncodedColumnsAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_POPULATION_ENCODED_COLUMNS_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevPopulationEncodedColumnsDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_POPULATION_ENCODED_COLUMNS_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevPopulationIdAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_POPULATION_ID_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevPopulationIdDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_POPULATION_ID_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevPopulationInformationSchemaSnapshotIdAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_POPULATION_INFORMATION_SCHEMA_SNAPSHOT_ID_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevPopulationInformationSchemaSnapshotIdDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_POPULATION_INFORMATION_SCHEMA_SNAPSHOT_ID_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevPopulationLastModifiedDttmAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_POPULATION_LAST_MODIFIED_DTTM_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevPopulationLastModifiedDttmDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_POPULATION_LAST_MODIFIED_DTTM_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevPopulationNameAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_POPULATION_NAME_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevPopulationNameDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_POPULATION_NAME_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevPopulationRowCountAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_POPULATION_ROW_COUNT_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevPopulationRowCountDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_POPULATION_ROW_COUNT_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevPopulationSchemaAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_POPULATION_SCHEMA_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevPopulationSchemaDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_POPULATION_SCHEMA_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevPopulationSizeInBytesAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_POPULATION_SIZE_IN_BYTES_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevPopulationSizeInBytesDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_POPULATION_SIZE_IN_BYTES_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevPopulationTableTypeAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_POPULATION_TABLE_TYPE_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevPopulationTableTypeDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_POPULATION_TABLE_TYPE_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevSampleAdditionalDataAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_SAMPLE_ADDITIONAL_DATA_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevSampleAdditionalDataDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_SAMPLE_ADDITIONAL_DATA_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevSampleCreatedDttmAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_SAMPLE_CREATED_DTTM_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevSampleCreatedDttmDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_SAMPLE_CREATED_DTTM_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevSampleCreatedOnDttmAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_SAMPLE_CREATED_ON_DTTM_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevSampleCreatedOnDttmDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_SAMPLE_CREATED_ON_DTTM_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevSampleDatabaseAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_SAMPLE_DATABASE_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevSampleDatabaseDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_SAMPLE_DATABASE_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevSampleDescriptionAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_SAMPLE_DESCRIPTION_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevSampleDescriptionDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_SAMPLE_DESCRIPTION_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevSampleDwhFlavourAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_SAMPLE_DWH_FLAVOUR_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevSampleDwhFlavourDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_SAMPLE_DWH_FLAVOUR_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevSampleEncodedColumnsAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_SAMPLE_ENCODED_COLUMNS_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevSampleEncodedColumnsDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_SAMPLE_ENCODED_COLUMNS_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevSampleIdAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_SAMPLE_ID_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevSampleIdDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_SAMPLE_ID_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevSampleInformationSchemaSnapshotIdAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_SAMPLE_INFORMATION_SCHEMA_SNAPSHOT_ID_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevSampleInformationSchemaSnapshotIdDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_SAMPLE_INFORMATION_SCHEMA_SNAPSHOT_ID_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevSampleLastModifiedDttmAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_SAMPLE_LAST_MODIFIED_DTTM_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevSampleLastModifiedDttmDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_SAMPLE_LAST_MODIFIED_DTTM_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevSampleNameAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_SAMPLE_NAME_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevSampleNameDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_SAMPLE_NAME_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevSampleRowCountAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_SAMPLE_ROW_COUNT_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevSampleRowCountDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_SAMPLE_ROW_COUNT_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevSampleSchemaAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_SAMPLE_SCHEMA_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevSampleSchemaDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_SAMPLE_SCHEMA_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevSampleSizeInBytesAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_SAMPLE_SIZE_IN_BYTES_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevSampleSizeInBytesDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_SAMPLE_SIZE_IN_BYTES_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevSampleTableTypeAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_SAMPLE_TABLE_TYPE_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdStddevSampleTableTypeDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_STDDEV_SAMPLE_TABLE_TYPE_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdSumAdditionalDataAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_SUM_ADDITIONAL_DATA_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdSumAdditionalDataDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_SUM_ADDITIONAL_DATA_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdSumCreatedDttmAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_SUM_CREATED_DTTM_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdSumCreatedDttmDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_SUM_CREATED_DTTM_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdSumCreatedOnDttmAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_SUM_CREATED_ON_DTTM_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdSumCreatedOnDttmDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_SUM_CREATED_ON_DTTM_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdSumDatabaseAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_SUM_DATABASE_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdSumDatabaseDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_SUM_DATABASE_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdSumDescriptionAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_SUM_DESCRIPTION_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdSumDescriptionDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_SUM_DESCRIPTION_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdSumDwhFlavourAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_SUM_DWH_FLAVOUR_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdSumDwhFlavourDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_SUM_DWH_FLAVOUR_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdSumEncodedColumnsAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_SUM_ENCODED_COLUMNS_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdSumEncodedColumnsDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_SUM_ENCODED_COLUMNS_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdSumIdAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_SUM_ID_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdSumIdDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_SUM_ID_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdSumInformationSchemaSnapshotIdAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_SUM_INFORMATION_SCHEMA_SNAPSHOT_ID_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdSumInformationSchemaSnapshotIdDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_SUM_INFORMATION_SCHEMA_SNAPSHOT_ID_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdSumLastModifiedDttmAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_SUM_LAST_MODIFIED_DTTM_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdSumLastModifiedDttmDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_SUM_LAST_MODIFIED_DTTM_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdSumNameAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_SUM_NAME_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdSumNameDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_SUM_NAME_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdSumRowCountAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_SUM_ROW_COUNT_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdSumRowCountDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_SUM_ROW_COUNT_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdSumSchemaAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_SUM_SCHEMA_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdSumSchemaDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_SUM_SCHEMA_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdSumSizeInBytesAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_SUM_SIZE_IN_BYTES_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdSumSizeInBytesDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_SUM_SIZE_IN_BYTES_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdSumTableTypeAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_SUM_TABLE_TYPE_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdSumTableTypeDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_SUM_TABLE_TYPE_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVariancePopulationAdditionalDataAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_POPULATION_ADDITIONAL_DATA_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVariancePopulationAdditionalDataDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_POPULATION_ADDITIONAL_DATA_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVariancePopulationCreatedDttmAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_POPULATION_CREATED_DTTM_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVariancePopulationCreatedDttmDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_POPULATION_CREATED_DTTM_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVariancePopulationCreatedOnDttmAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_POPULATION_CREATED_ON_DTTM_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVariancePopulationCreatedOnDttmDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_POPULATION_CREATED_ON_DTTM_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVariancePopulationDatabaseAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_POPULATION_DATABASE_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVariancePopulationDatabaseDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_POPULATION_DATABASE_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVariancePopulationDescriptionAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_POPULATION_DESCRIPTION_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVariancePopulationDescriptionDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_POPULATION_DESCRIPTION_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVariancePopulationDwhFlavourAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_POPULATION_DWH_FLAVOUR_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVariancePopulationDwhFlavourDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_POPULATION_DWH_FLAVOUR_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVariancePopulationEncodedColumnsAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_POPULATION_ENCODED_COLUMNS_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVariancePopulationEncodedColumnsDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_POPULATION_ENCODED_COLUMNS_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVariancePopulationIdAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_POPULATION_ID_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVariancePopulationIdDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_POPULATION_ID_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVariancePopulationInformationSchemaSnapshotIdAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_POPULATION_INFORMATION_SCHEMA_SNAPSHOT_ID_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVariancePopulationInformationSchemaSnapshotIdDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_POPULATION_INFORMATION_SCHEMA_SNAPSHOT_ID_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVariancePopulationLastModifiedDttmAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_POPULATION_LAST_MODIFIED_DTTM_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVariancePopulationLastModifiedDttmDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_POPULATION_LAST_MODIFIED_DTTM_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVariancePopulationNameAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_POPULATION_NAME_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVariancePopulationNameDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_POPULATION_NAME_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVariancePopulationRowCountAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_POPULATION_ROW_COUNT_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVariancePopulationRowCountDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_POPULATION_ROW_COUNT_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVariancePopulationSchemaAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_POPULATION_SCHEMA_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVariancePopulationSchemaDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_POPULATION_SCHEMA_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVariancePopulationSizeInBytesAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_POPULATION_SIZE_IN_BYTES_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVariancePopulationSizeInBytesDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_POPULATION_SIZE_IN_BYTES_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVariancePopulationTableTypeAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_POPULATION_TABLE_TYPE_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVariancePopulationTableTypeDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_POPULATION_TABLE_TYPE_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVarianceSampleAdditionalDataAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_SAMPLE_ADDITIONAL_DATA_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVarianceSampleAdditionalDataDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_SAMPLE_ADDITIONAL_DATA_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVarianceSampleCreatedDttmAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_SAMPLE_CREATED_DTTM_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVarianceSampleCreatedDttmDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_SAMPLE_CREATED_DTTM_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVarianceSampleCreatedOnDttmAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_SAMPLE_CREATED_ON_DTTM_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVarianceSampleCreatedOnDttmDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_SAMPLE_CREATED_ON_DTTM_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVarianceSampleDatabaseAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_SAMPLE_DATABASE_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVarianceSampleDatabaseDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_SAMPLE_DATABASE_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVarianceSampleDescriptionAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVarianceSampleDescriptionDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVarianceSampleDwhFlavourAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_SAMPLE_DWH_FLAVOUR_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVarianceSampleDwhFlavourDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_SAMPLE_DWH_FLAVOUR_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVarianceSampleEncodedColumnsAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_SAMPLE_ENCODED_COLUMNS_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVarianceSampleEncodedColumnsDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_SAMPLE_ENCODED_COLUMNS_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVarianceSampleIdAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_SAMPLE_ID_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVarianceSampleIdDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_SAMPLE_ID_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVarianceSampleInformationSchemaSnapshotIdAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_SAMPLE_INFORMATION_SCHEMA_SNAPSHOT_ID_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVarianceSampleInformationSchemaSnapshotIdDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_SAMPLE_INFORMATION_SCHEMA_SNAPSHOT_ID_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVarianceSampleLastModifiedDttmAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_SAMPLE_LAST_MODIFIED_DTTM_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVarianceSampleLastModifiedDttmDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_SAMPLE_LAST_MODIFIED_DTTM_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVarianceSampleNameAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_SAMPLE_NAME_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVarianceSampleNameDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_SAMPLE_NAME_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVarianceSampleRowCountAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_SAMPLE_ROW_COUNT_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVarianceSampleRowCountDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_SAMPLE_ROW_COUNT_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVarianceSampleSchemaAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_SAMPLE_SCHEMA_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVarianceSampleSchemaDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_SAMPLE_SCHEMA_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVarianceSampleSizeInBytesAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_SAMPLE_SIZE_IN_BYTES_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVarianceSampleSizeInBytesDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_SAMPLE_SIZE_IN_BYTES_DESC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVarianceSampleTableTypeAsc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_SAMPLE_TABLE_TYPE_ASC',
  InformationSchemaTableV2SbyInformationSchemaSnapshotIdVarianceSampleTableTypeDesc = 'INFORMATION_SCHEMA_TABLE_V2_SBY_INFORMATION_SCHEMA_SNAPSHOT_ID_VARIANCE_SAMPLE_TABLE_TYPE_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  WorkspaceIdAsc = 'WORKSPACE_ID_ASC',
  WorkspaceIdDesc = 'WORKSPACE_ID_DESC'
}

export type InformationSchemaTableV2 = Node & {
  __typename?: 'InformationSchemaTableV2';
  additionalData: Scalars['String'];
  createdDttm: Scalars['Datetime'];
  createdOnDttm?: Maybe<Scalars['Datetime']>;
  database?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  dwhFlavour: Scalars['String'];
  encodedColumns: Scalars['String'];
  id: Scalars['BigInt'];
  /** Reads a single `InformationSchemaSnapshotV2` that is related to this `InformationSchemaTableV2`. */
  informationSchemaSnapshot?: Maybe<InformationSchemaSnapshotV2>;
  informationSchemaSnapshotId: Scalars['Int'];
  lastModifiedDttm?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  rowCount: Scalars['BigInt'];
  schema?: Maybe<Scalars['String']>;
  sizeInBytes: Scalars['BigInt'];
  tableType: Scalars['String'];
};

export type InformationSchemaTableV2Aggregates = {
  __typename?: 'InformationSchemaTableV2Aggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<InformationSchemaTableV2AverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<InformationSchemaTableV2DistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<InformationSchemaTableV2MaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<InformationSchemaTableV2MinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<InformationSchemaTableV2StddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<InformationSchemaTableV2StddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<InformationSchemaTableV2SumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<InformationSchemaTableV2VariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<InformationSchemaTableV2VarianceSampleAggregates>;
};

export type InformationSchemaTableV2AverageAggregates = {
  __typename?: 'InformationSchemaTableV2AverageAggregates';
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of informationSchemaSnapshotId across the matching connection */
  informationSchemaSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of rowCount across the matching connection */
  rowCount?: Maybe<Scalars['BigFloat']>;
  /** Mean average of sizeInBytes across the matching connection */
  sizeInBytes?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `InformationSchemaTableV2` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type InformationSchemaTableV2Condition = {
  /** Checks for equality with the object’s `additionalData` field. */
  additionalData?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdDttm` field. */
  createdDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdOnDttm` field. */
  createdOnDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `database` field. */
  database?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `dwhFlavour` field. */
  dwhFlavour?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `encodedColumns` field. */
  encodedColumns?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `informationSchemaSnapshotId` field. */
  informationSchemaSnapshotId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `lastModifiedDttm` field. */
  lastModifiedDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `rowCount` field. */
  rowCount?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `schema` field. */
  schema?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `sizeInBytes` field. */
  sizeInBytes?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `tableType` field. */
  tableType?: InputMaybe<Scalars['String']>;
};

export type InformationSchemaTableV2DistinctCountAggregates = {
  __typename?: 'InformationSchemaTableV2DistinctCountAggregates';
  /** Distinct count of additionalData across the matching connection */
  additionalData?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdDttm across the matching connection */
  createdDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdOnDttm across the matching connection */
  createdOnDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of database across the matching connection */
  database?: Maybe<Scalars['BigInt']>;
  /** Distinct count of description across the matching connection */
  description?: Maybe<Scalars['BigInt']>;
  /** Distinct count of dwhFlavour across the matching connection */
  dwhFlavour?: Maybe<Scalars['BigInt']>;
  /** Distinct count of encodedColumns across the matching connection */
  encodedColumns?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of informationSchemaSnapshotId across the matching connection */
  informationSchemaSnapshotId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lastModifiedDttm across the matching connection */
  lastModifiedDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of rowCount across the matching connection */
  rowCount?: Maybe<Scalars['BigInt']>;
  /** Distinct count of schema across the matching connection */
  schema?: Maybe<Scalars['BigInt']>;
  /** Distinct count of sizeInBytes across the matching connection */
  sizeInBytes?: Maybe<Scalars['BigInt']>;
  /** Distinct count of tableType across the matching connection */
  tableType?: Maybe<Scalars['BigInt']>;
};

/** Grouping methods for `InformationSchemaTableV2` for usage during aggregation. */
export enum InformationSchemaTableV2GroupBy {
  AdditionalData = 'ADDITIONAL_DATA',
  CreatedDttm = 'CREATED_DTTM',
  CreatedDttmTruncatedToDay = 'CREATED_DTTM_TRUNCATED_TO_DAY',
  CreatedDttmTruncatedToHour = 'CREATED_DTTM_TRUNCATED_TO_HOUR',
  CreatedOnDttm = 'CREATED_ON_DTTM',
  CreatedOnDttmTruncatedToDay = 'CREATED_ON_DTTM_TRUNCATED_TO_DAY',
  CreatedOnDttmTruncatedToHour = 'CREATED_ON_DTTM_TRUNCATED_TO_HOUR',
  Database = 'DATABASE',
  Description = 'DESCRIPTION',
  DwhFlavour = 'DWH_FLAVOUR',
  EncodedColumns = 'ENCODED_COLUMNS',
  InformationSchemaSnapshotId = 'INFORMATION_SCHEMA_SNAPSHOT_ID',
  LastModifiedDttm = 'LAST_MODIFIED_DTTM',
  LastModifiedDttmTruncatedToDay = 'LAST_MODIFIED_DTTM_TRUNCATED_TO_DAY',
  LastModifiedDttmTruncatedToHour = 'LAST_MODIFIED_DTTM_TRUNCATED_TO_HOUR',
  Name = 'NAME',
  RowCount = 'ROW_COUNT',
  Schema = 'SCHEMA',
  SizeInBytes = 'SIZE_IN_BYTES',
  TableType = 'TABLE_TYPE'
}

export type InformationSchemaTableV2HavingAverageInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  createdOnDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  informationSchemaSnapshotId?: InputMaybe<HavingIntFilter>;
  lastModifiedDttm?: InputMaybe<HavingDatetimeFilter>;
  rowCount?: InputMaybe<HavingBigintFilter>;
  sizeInBytes?: InputMaybe<HavingBigintFilter>;
};

export type InformationSchemaTableV2HavingDistinctCountInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  createdOnDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  informationSchemaSnapshotId?: InputMaybe<HavingIntFilter>;
  lastModifiedDttm?: InputMaybe<HavingDatetimeFilter>;
  rowCount?: InputMaybe<HavingBigintFilter>;
  sizeInBytes?: InputMaybe<HavingBigintFilter>;
};

/** Conditions for `InformationSchemaTableV2` aggregates. */
export type InformationSchemaTableV2HavingInput = {
  AND?: InputMaybe<Array<InformationSchemaTableV2HavingInput>>;
  OR?: InputMaybe<Array<InformationSchemaTableV2HavingInput>>;
  average?: InputMaybe<InformationSchemaTableV2HavingAverageInput>;
  distinctCount?: InputMaybe<InformationSchemaTableV2HavingDistinctCountInput>;
  max?: InputMaybe<InformationSchemaTableV2HavingMaxInput>;
  min?: InputMaybe<InformationSchemaTableV2HavingMinInput>;
  stddevPopulation?: InputMaybe<InformationSchemaTableV2HavingStddevPopulationInput>;
  stddevSample?: InputMaybe<InformationSchemaTableV2HavingStddevSampleInput>;
  sum?: InputMaybe<InformationSchemaTableV2HavingSumInput>;
  variancePopulation?: InputMaybe<InformationSchemaTableV2HavingVariancePopulationInput>;
  varianceSample?: InputMaybe<InformationSchemaTableV2HavingVarianceSampleInput>;
};

export type InformationSchemaTableV2HavingMaxInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  createdOnDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  informationSchemaSnapshotId?: InputMaybe<HavingIntFilter>;
  lastModifiedDttm?: InputMaybe<HavingDatetimeFilter>;
  rowCount?: InputMaybe<HavingBigintFilter>;
  sizeInBytes?: InputMaybe<HavingBigintFilter>;
};

export type InformationSchemaTableV2HavingMinInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  createdOnDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  informationSchemaSnapshotId?: InputMaybe<HavingIntFilter>;
  lastModifiedDttm?: InputMaybe<HavingDatetimeFilter>;
  rowCount?: InputMaybe<HavingBigintFilter>;
  sizeInBytes?: InputMaybe<HavingBigintFilter>;
};

export type InformationSchemaTableV2HavingStddevPopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  createdOnDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  informationSchemaSnapshotId?: InputMaybe<HavingIntFilter>;
  lastModifiedDttm?: InputMaybe<HavingDatetimeFilter>;
  rowCount?: InputMaybe<HavingBigintFilter>;
  sizeInBytes?: InputMaybe<HavingBigintFilter>;
};

export type InformationSchemaTableV2HavingStddevSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  createdOnDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  informationSchemaSnapshotId?: InputMaybe<HavingIntFilter>;
  lastModifiedDttm?: InputMaybe<HavingDatetimeFilter>;
  rowCount?: InputMaybe<HavingBigintFilter>;
  sizeInBytes?: InputMaybe<HavingBigintFilter>;
};

export type InformationSchemaTableV2HavingSumInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  createdOnDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  informationSchemaSnapshotId?: InputMaybe<HavingIntFilter>;
  lastModifiedDttm?: InputMaybe<HavingDatetimeFilter>;
  rowCount?: InputMaybe<HavingBigintFilter>;
  sizeInBytes?: InputMaybe<HavingBigintFilter>;
};

export type InformationSchemaTableV2HavingVariancePopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  createdOnDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  informationSchemaSnapshotId?: InputMaybe<HavingIntFilter>;
  lastModifiedDttm?: InputMaybe<HavingDatetimeFilter>;
  rowCount?: InputMaybe<HavingBigintFilter>;
  sizeInBytes?: InputMaybe<HavingBigintFilter>;
};

export type InformationSchemaTableV2HavingVarianceSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  createdOnDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingBigintFilter>;
  informationSchemaSnapshotId?: InputMaybe<HavingIntFilter>;
  lastModifiedDttm?: InputMaybe<HavingDatetimeFilter>;
  rowCount?: InputMaybe<HavingBigintFilter>;
  sizeInBytes?: InputMaybe<HavingBigintFilter>;
};

export type InformationSchemaTableV2MaxAggregates = {
  __typename?: 'InformationSchemaTableV2MaxAggregates';
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Maximum of informationSchemaSnapshotId across the matching connection */
  informationSchemaSnapshotId?: Maybe<Scalars['Int']>;
  /** Maximum of rowCount across the matching connection */
  rowCount?: Maybe<Scalars['BigInt']>;
  /** Maximum of sizeInBytes across the matching connection */
  sizeInBytes?: Maybe<Scalars['BigInt']>;
};

export type InformationSchemaTableV2MinAggregates = {
  __typename?: 'InformationSchemaTableV2MinAggregates';
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Minimum of informationSchemaSnapshotId across the matching connection */
  informationSchemaSnapshotId?: Maybe<Scalars['Int']>;
  /** Minimum of rowCount across the matching connection */
  rowCount?: Maybe<Scalars['BigInt']>;
  /** Minimum of sizeInBytes across the matching connection */
  sizeInBytes?: Maybe<Scalars['BigInt']>;
};

export type InformationSchemaTableV2StddevPopulationAggregates = {
  __typename?: 'InformationSchemaTableV2StddevPopulationAggregates';
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of informationSchemaSnapshotId across the matching connection */
  informationSchemaSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of rowCount across the matching connection */
  rowCount?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of sizeInBytes across the matching connection */
  sizeInBytes?: Maybe<Scalars['BigFloat']>;
};

export type InformationSchemaTableV2StddevSampleAggregates = {
  __typename?: 'InformationSchemaTableV2StddevSampleAggregates';
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of informationSchemaSnapshotId across the matching connection */
  informationSchemaSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of rowCount across the matching connection */
  rowCount?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of sizeInBytes across the matching connection */
  sizeInBytes?: Maybe<Scalars['BigFloat']>;
};

export type InformationSchemaTableV2SumAggregates = {
  __typename?: 'InformationSchemaTableV2SumAggregates';
  /** Sum of id across the matching connection */
  id: Scalars['BigFloat'];
  /** Sum of informationSchemaSnapshotId across the matching connection */
  informationSchemaSnapshotId: Scalars['BigInt'];
  /** Sum of rowCount across the matching connection */
  rowCount: Scalars['BigFloat'];
  /** Sum of sizeInBytes across the matching connection */
  sizeInBytes: Scalars['BigFloat'];
};

export type InformationSchemaTableV2VariancePopulationAggregates = {
  __typename?: 'InformationSchemaTableV2VariancePopulationAggregates';
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of informationSchemaSnapshotId across the matching connection */
  informationSchemaSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of rowCount across the matching connection */
  rowCount?: Maybe<Scalars['BigFloat']>;
  /** Population variance of sizeInBytes across the matching connection */
  sizeInBytes?: Maybe<Scalars['BigFloat']>;
};

export type InformationSchemaTableV2VarianceSampleAggregates = {
  __typename?: 'InformationSchemaTableV2VarianceSampleAggregates';
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of informationSchemaSnapshotId across the matching connection */
  informationSchemaSnapshotId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of rowCount across the matching connection */
  rowCount?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of sizeInBytes across the matching connection */
  sizeInBytes?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `InformationSchemaTableV2` values. */
export type InformationSchemaTableV2sConnection = {
  __typename?: 'InformationSchemaTableV2sConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<InformationSchemaTableV2Aggregates>;
  /** A list of edges which contains the `InformationSchemaTableV2` and cursor to aid in pagination. */
  edges: Array<InformationSchemaTableV2sEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<InformationSchemaTableV2Aggregates>>;
  /** A list of `InformationSchemaTableV2` objects. */
  nodes: Array<InformationSchemaTableV2>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `InformationSchemaTableV2` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `InformationSchemaTableV2` values. */
export type InformationSchemaTableV2sConnectionGroupedAggregatesArgs = {
  groupBy: Array<InformationSchemaTableV2GroupBy>;
  having?: InputMaybe<InformationSchemaTableV2HavingInput>;
};

/** A `InformationSchemaTableV2` edge in the connection. */
export type InformationSchemaTableV2sEdge = {
  __typename?: 'InformationSchemaTableV2sEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `InformationSchemaTableV2` at the end of the edge. */
  node: InformationSchemaTableV2;
};

/** Methods to use when ordering `InformationSchemaTableV2`. */
export enum InformationSchemaTableV2sOrderBy {
  AdditionalDataAsc = 'ADDITIONAL_DATA_ASC',
  AdditionalDataDesc = 'ADDITIONAL_DATA_DESC',
  CreatedDttmAsc = 'CREATED_DTTM_ASC',
  CreatedDttmDesc = 'CREATED_DTTM_DESC',
  CreatedOnDttmAsc = 'CREATED_ON_DTTM_ASC',
  CreatedOnDttmDesc = 'CREATED_ON_DTTM_DESC',
  DatabaseAsc = 'DATABASE_ASC',
  DatabaseDesc = 'DATABASE_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  DwhFlavourAsc = 'DWH_FLAVOUR_ASC',
  DwhFlavourDesc = 'DWH_FLAVOUR_DESC',
  EncodedColumnsAsc = 'ENCODED_COLUMNS_ASC',
  EncodedColumnsDesc = 'ENCODED_COLUMNS_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  InformationSchemaSnapshotIdAsc = 'INFORMATION_SCHEMA_SNAPSHOT_ID_ASC',
  InformationSchemaSnapshotIdDesc = 'INFORMATION_SCHEMA_SNAPSHOT_ID_DESC',
  LastModifiedDttmAsc = 'LAST_MODIFIED_DTTM_ASC',
  LastModifiedDttmDesc = 'LAST_MODIFIED_DTTM_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RowCountAsc = 'ROW_COUNT_ASC',
  RowCountDesc = 'ROW_COUNT_DESC',
  SchemaAsc = 'SCHEMA_ASC',
  SchemaDesc = 'SCHEMA_DESC',
  SizeInBytesAsc = 'SIZE_IN_BYTES_ASC',
  SizeInBytesDesc = 'SIZE_IN_BYTES_DESC',
  TableTypeAsc = 'TABLE_TYPE_ASC',
  TableTypeDesc = 'TABLE_TYPE_DESC'
}

export type Integration = Node & {
  __typename?: 'Integration';
  createdDttm: Scalars['Datetime'];
  id: Scalars['Int'];
  /** Reads and enables pagination through a set of `IntegrationConfig`. */
  integrationConfigs: IntegrationConfigsConnection;
  /** Reads and enables pagination through a set of `IntegrationSnapshot`. */
  integrationSnapshots: IntegrationSnapshotsConnection;
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  type: Scalars['String'];
  uid: Scalars['String'];
  updatedDttm: Scalars['Datetime'];
  workspaceId: Scalars['Int'];
};


export type IntegrationIntegrationConfigsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<IntegrationConfigCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IntegrationConfigsOrderBy>>;
};


export type IntegrationIntegrationSnapshotsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<IntegrationSnapshotCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IntegrationSnapshotsOrderBy>>;
};

export type IntegrationAggregates = {
  __typename?: 'IntegrationAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<IntegrationAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<IntegrationDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<IntegrationMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<IntegrationMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<IntegrationStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<IntegrationStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<IntegrationSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<IntegrationVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<IntegrationVarianceSampleAggregates>;
};

export type IntegrationAverageAggregates = {
  __typename?: 'IntegrationAverageAggregates';
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `Integration` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type IntegrationCondition = {
  /** Checks for equality with the object’s `createdDttm` field. */
  createdDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `isActive` field. */
  isActive?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `uid` field. */
  uid?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedDttm` field. */
  updatedDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `workspaceId` field. */
  workspaceId?: InputMaybe<Scalars['Int']>;
};

export type IntegrationConfig = Node & {
  __typename?: 'IntegrationConfig';
  createdDttm: Scalars['Datetime'];
  id: Scalars['Int'];
  /** Reads a single `Integration` that is related to this `IntegrationConfig`. */
  integration?: Maybe<Integration>;
  integrationId: Scalars['Int'];
  key: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  updatedDttm: Scalars['Datetime'];
  value?: Maybe<Scalars['String']>;
};

export type IntegrationConfigAggregates = {
  __typename?: 'IntegrationConfigAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<IntegrationConfigAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<IntegrationConfigDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<IntegrationConfigMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<IntegrationConfigMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<IntegrationConfigStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<IntegrationConfigStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<IntegrationConfigSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<IntegrationConfigVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<IntegrationConfigVarianceSampleAggregates>;
};

export type IntegrationConfigAverageAggregates = {
  __typename?: 'IntegrationConfigAverageAggregates';
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `IntegrationConfig` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type IntegrationConfigCondition = {
  /** Checks for equality with the object’s `createdDttm` field. */
  createdDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `integrationId` field. */
  integrationId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `key` field. */
  key?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedDttm` field. */
  updatedDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `value` field. */
  value?: InputMaybe<Scalars['String']>;
};

export type IntegrationConfigDistinctCountAggregates = {
  __typename?: 'IntegrationConfigDistinctCountAggregates';
  /** Distinct count of createdDttm across the matching connection */
  createdDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of key across the matching connection */
  key?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedDttm across the matching connection */
  updatedDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of value across the matching connection */
  value?: Maybe<Scalars['BigInt']>;
};

/** Grouping methods for `IntegrationConfig` for usage during aggregation. */
export enum IntegrationConfigGroupBy {
  CreatedDttm = 'CREATED_DTTM',
  CreatedDttmTruncatedToDay = 'CREATED_DTTM_TRUNCATED_TO_DAY',
  CreatedDttmTruncatedToHour = 'CREATED_DTTM_TRUNCATED_TO_HOUR',
  IntegrationId = 'INTEGRATION_ID',
  Key = 'KEY',
  UpdatedDttm = 'UPDATED_DTTM',
  UpdatedDttmTruncatedToDay = 'UPDATED_DTTM_TRUNCATED_TO_DAY',
  UpdatedDttmTruncatedToHour = 'UPDATED_DTTM_TRUNCATED_TO_HOUR',
  Value = 'VALUE'
}

export type IntegrationConfigHavingAverageInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  integrationId?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type IntegrationConfigHavingDistinctCountInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  integrationId?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `IntegrationConfig` aggregates. */
export type IntegrationConfigHavingInput = {
  AND?: InputMaybe<Array<IntegrationConfigHavingInput>>;
  OR?: InputMaybe<Array<IntegrationConfigHavingInput>>;
  average?: InputMaybe<IntegrationConfigHavingAverageInput>;
  distinctCount?: InputMaybe<IntegrationConfigHavingDistinctCountInput>;
  max?: InputMaybe<IntegrationConfigHavingMaxInput>;
  min?: InputMaybe<IntegrationConfigHavingMinInput>;
  stddevPopulation?: InputMaybe<IntegrationConfigHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<IntegrationConfigHavingStddevSampleInput>;
  sum?: InputMaybe<IntegrationConfigHavingSumInput>;
  variancePopulation?: InputMaybe<IntegrationConfigHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<IntegrationConfigHavingVarianceSampleInput>;
};

export type IntegrationConfigHavingMaxInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  integrationId?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type IntegrationConfigHavingMinInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  integrationId?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type IntegrationConfigHavingStddevPopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  integrationId?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type IntegrationConfigHavingStddevSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  integrationId?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type IntegrationConfigHavingSumInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  integrationId?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type IntegrationConfigHavingVariancePopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  integrationId?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type IntegrationConfigHavingVarianceSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  integrationId?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type IntegrationConfigMaxAggregates = {
  __typename?: 'IntegrationConfigMaxAggregates';
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['Int']>;
};

export type IntegrationConfigMinAggregates = {
  __typename?: 'IntegrationConfigMinAggregates';
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['Int']>;
};

export type IntegrationConfigStddevPopulationAggregates = {
  __typename?: 'IntegrationConfigStddevPopulationAggregates';
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigFloat']>;
};

export type IntegrationConfigStddevSampleAggregates = {
  __typename?: 'IntegrationConfigStddevSampleAggregates';
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigFloat']>;
};

export type IntegrationConfigSumAggregates = {
  __typename?: 'IntegrationConfigSumAggregates';
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of integrationId across the matching connection */
  integrationId: Scalars['BigInt'];
};

export type IntegrationConfigVariancePopulationAggregates = {
  __typename?: 'IntegrationConfigVariancePopulationAggregates';
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigFloat']>;
};

export type IntegrationConfigVarianceSampleAggregates = {
  __typename?: 'IntegrationConfigVarianceSampleAggregates';
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `IntegrationConfig` values. */
export type IntegrationConfigsConnection = {
  __typename?: 'IntegrationConfigsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<IntegrationConfigAggregates>;
  /** A list of edges which contains the `IntegrationConfig` and cursor to aid in pagination. */
  edges: Array<IntegrationConfigsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<IntegrationConfigAggregates>>;
  /** A list of `IntegrationConfig` objects. */
  nodes: Array<IntegrationConfig>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `IntegrationConfig` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `IntegrationConfig` values. */
export type IntegrationConfigsConnectionGroupedAggregatesArgs = {
  groupBy: Array<IntegrationConfigGroupBy>;
  having?: InputMaybe<IntegrationConfigHavingInput>;
};

/** A `IntegrationConfig` edge in the connection. */
export type IntegrationConfigsEdge = {
  __typename?: 'IntegrationConfigsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `IntegrationConfig` at the end of the edge. */
  node: IntegrationConfig;
};

/** Methods to use when ordering `IntegrationConfig`. */
export enum IntegrationConfigsOrderBy {
  CreatedDttmAsc = 'CREATED_DTTM_ASC',
  CreatedDttmDesc = 'CREATED_DTTM_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IntegrationIdAsc = 'INTEGRATION_ID_ASC',
  IntegrationIdDesc = 'INTEGRATION_ID_DESC',
  KeyAsc = 'KEY_ASC',
  KeyDesc = 'KEY_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedDttmAsc = 'UPDATED_DTTM_ASC',
  UpdatedDttmDesc = 'UPDATED_DTTM_DESC',
  ValueAsc = 'VALUE_ASC',
  ValueDesc = 'VALUE_DESC'
}

export type IntegrationDistinctCountAggregates = {
  __typename?: 'IntegrationDistinctCountAggregates';
  /** Distinct count of createdDttm across the matching connection */
  createdDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of isActive across the matching connection */
  isActive?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of type across the matching connection */
  type?: Maybe<Scalars['BigInt']>;
  /** Distinct count of uid across the matching connection */
  uid?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedDttm across the matching connection */
  updatedDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigInt']>;
};

export type IntegrationMaxAggregates = {
  __typename?: 'IntegrationMaxAggregates';
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['Int']>;
};

export type IntegrationMinAggregates = {
  __typename?: 'IntegrationMinAggregates';
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['Int']>;
};

export type IntegrationNode = Node & {
  __typename?: 'IntegrationNode';
  attributes: Scalars['String'];
  createdDttm: Scalars['Datetime'];
  downstreamReferences: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  nodeType: Scalars['String'];
  /** Reads a single `IntegrationSnapshot` that is related to this `IntegrationNode`. */
  snapshot?: Maybe<IntegrationSnapshot>;
  snapshotId: Scalars['Int'];
  stableId: Scalars['String'];
  updatedDttm: Scalars['Datetime'];
  upstreamReferences: Scalars['String'];
};

export type IntegrationNodeAggregates = {
  __typename?: 'IntegrationNodeAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<IntegrationNodeAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<IntegrationNodeDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<IntegrationNodeMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<IntegrationNodeMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<IntegrationNodeStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<IntegrationNodeStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<IntegrationNodeSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<IntegrationNodeVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<IntegrationNodeVarianceSampleAggregates>;
};

export type IntegrationNodeAverageAggregates = {
  __typename?: 'IntegrationNodeAverageAggregates';
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of snapshotId across the matching connection */
  snapshotId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `IntegrationNode` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type IntegrationNodeCondition = {
  /** Checks for equality with the object’s `attributes` field. */
  attributes?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdDttm` field. */
  createdDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `downstreamReferences` field. */
  downstreamReferences?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `nodeType` field. */
  nodeType?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `snapshotId` field. */
  snapshotId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `stableId` field. */
  stableId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedDttm` field. */
  updatedDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `upstreamReferences` field. */
  upstreamReferences?: InputMaybe<Scalars['String']>;
};

export type IntegrationNodeDistinctCountAggregates = {
  __typename?: 'IntegrationNodeDistinctCountAggregates';
  /** Distinct count of attributes across the matching connection */
  attributes?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdDttm across the matching connection */
  createdDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of downstreamReferences across the matching connection */
  downstreamReferences?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of nodeType across the matching connection */
  nodeType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of snapshotId across the matching connection */
  snapshotId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of stableId across the matching connection */
  stableId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedDttm across the matching connection */
  updatedDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of upstreamReferences across the matching connection */
  upstreamReferences?: Maybe<Scalars['BigInt']>;
};

export type IntegrationNodeMaxAggregates = {
  __typename?: 'IntegrationNodeMaxAggregates';
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of snapshotId across the matching connection */
  snapshotId?: Maybe<Scalars['Int']>;
};

export type IntegrationNodeMinAggregates = {
  __typename?: 'IntegrationNodeMinAggregates';
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of snapshotId across the matching connection */
  snapshotId?: Maybe<Scalars['Int']>;
};

export type IntegrationNodeStddevPopulationAggregates = {
  __typename?: 'IntegrationNodeStddevPopulationAggregates';
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of snapshotId across the matching connection */
  snapshotId?: Maybe<Scalars['BigFloat']>;
};

export type IntegrationNodeStddevSampleAggregates = {
  __typename?: 'IntegrationNodeStddevSampleAggregates';
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of snapshotId across the matching connection */
  snapshotId?: Maybe<Scalars['BigFloat']>;
};

export type IntegrationNodeSumAggregates = {
  __typename?: 'IntegrationNodeSumAggregates';
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of snapshotId across the matching connection */
  snapshotId: Scalars['BigInt'];
};

export type IntegrationNodeVariancePopulationAggregates = {
  __typename?: 'IntegrationNodeVariancePopulationAggregates';
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of snapshotId across the matching connection */
  snapshotId?: Maybe<Scalars['BigFloat']>;
};

export type IntegrationNodeVarianceSampleAggregates = {
  __typename?: 'IntegrationNodeVarianceSampleAggregates';
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of snapshotId across the matching connection */
  snapshotId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `IntegrationNode` values. */
export type IntegrationNodesConnection = {
  __typename?: 'IntegrationNodesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<IntegrationNodeAggregates>;
  /** A list of edges which contains the `IntegrationNode` and cursor to aid in pagination. */
  edges: Array<IntegrationNodesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<IntegrationNodeAggregates>>;
  /** A list of `IntegrationNode` objects. */
  nodes: Array<IntegrationNode>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `IntegrationNode` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `IntegrationNode` values. */
export type IntegrationNodesConnectionGroupedAggregatesArgs = {
  groupBy: Array<IntegrationNodesGroupBy>;
  having?: InputMaybe<IntegrationNodesHavingInput>;
};

/** A `IntegrationNode` edge in the connection. */
export type IntegrationNodesEdge = {
  __typename?: 'IntegrationNodesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `IntegrationNode` at the end of the edge. */
  node: IntegrationNode;
};

/** Grouping methods for `IntegrationNode` for usage during aggregation. */
export enum IntegrationNodesGroupBy {
  Attributes = 'ATTRIBUTES',
  CreatedDttm = 'CREATED_DTTM',
  CreatedDttmTruncatedToDay = 'CREATED_DTTM_TRUNCATED_TO_DAY',
  CreatedDttmTruncatedToHour = 'CREATED_DTTM_TRUNCATED_TO_HOUR',
  DownstreamReferences = 'DOWNSTREAM_REFERENCES',
  Name = 'NAME',
  NodeType = 'NODE_TYPE',
  SnapshotId = 'SNAPSHOT_ID',
  StableId = 'STABLE_ID',
  UpdatedDttm = 'UPDATED_DTTM',
  UpdatedDttmTruncatedToDay = 'UPDATED_DTTM_TRUNCATED_TO_DAY',
  UpdatedDttmTruncatedToHour = 'UPDATED_DTTM_TRUNCATED_TO_HOUR',
  UpstreamReferences = 'UPSTREAM_REFERENCES'
}

export type IntegrationNodesHavingAverageInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  snapshotId?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type IntegrationNodesHavingDistinctCountInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  snapshotId?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `IntegrationNode` aggregates. */
export type IntegrationNodesHavingInput = {
  AND?: InputMaybe<Array<IntegrationNodesHavingInput>>;
  OR?: InputMaybe<Array<IntegrationNodesHavingInput>>;
  average?: InputMaybe<IntegrationNodesHavingAverageInput>;
  distinctCount?: InputMaybe<IntegrationNodesHavingDistinctCountInput>;
  max?: InputMaybe<IntegrationNodesHavingMaxInput>;
  min?: InputMaybe<IntegrationNodesHavingMinInput>;
  stddevPopulation?: InputMaybe<IntegrationNodesHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<IntegrationNodesHavingStddevSampleInput>;
  sum?: InputMaybe<IntegrationNodesHavingSumInput>;
  variancePopulation?: InputMaybe<IntegrationNodesHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<IntegrationNodesHavingVarianceSampleInput>;
};

export type IntegrationNodesHavingMaxInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  snapshotId?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type IntegrationNodesHavingMinInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  snapshotId?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type IntegrationNodesHavingStddevPopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  snapshotId?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type IntegrationNodesHavingStddevSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  snapshotId?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type IntegrationNodesHavingSumInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  snapshotId?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type IntegrationNodesHavingVariancePopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  snapshotId?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type IntegrationNodesHavingVarianceSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  snapshotId?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `IntegrationNode`. */
export enum IntegrationNodesOrderBy {
  AttributesAsc = 'ATTRIBUTES_ASC',
  AttributesDesc = 'ATTRIBUTES_DESC',
  CreatedDttmAsc = 'CREATED_DTTM_ASC',
  CreatedDttmDesc = 'CREATED_DTTM_DESC',
  DownstreamReferencesAsc = 'DOWNSTREAM_REFERENCES_ASC',
  DownstreamReferencesDesc = 'DOWNSTREAM_REFERENCES_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  NodeTypeAsc = 'NODE_TYPE_ASC',
  NodeTypeDesc = 'NODE_TYPE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SnapshotIdAsc = 'SNAPSHOT_ID_ASC',
  SnapshotIdDesc = 'SNAPSHOT_ID_DESC',
  StableIdAsc = 'STABLE_ID_ASC',
  StableIdDesc = 'STABLE_ID_DESC',
  UpdatedDttmAsc = 'UPDATED_DTTM_ASC',
  UpdatedDttmDesc = 'UPDATED_DTTM_DESC',
  UpstreamReferencesAsc = 'UPSTREAM_REFERENCES_ASC',
  UpstreamReferencesDesc = 'UPSTREAM_REFERENCES_DESC'
}

export type IntegrationSnapshot = Node & {
  __typename?: 'IntegrationSnapshot';
  createdDttm: Scalars['Datetime'];
  id: Scalars['Int'];
  /** Reads a single `Integration` that is related to this `IntegrationSnapshot`. */
  integration?: Maybe<Integration>;
  integrationId: Scalars['Int'];
  /** Reads and enables pagination through a set of `IntegrationNode`. */
  integrationNodesBySnapshotId: IntegrationNodesConnection;
  isComplete: Scalars['Boolean'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  updatedDttm: Scalars['Datetime'];
};


export type IntegrationSnapshotIntegrationNodesBySnapshotIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<IntegrationNodeCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IntegrationNodesOrderBy>>;
};

export type IntegrationSnapshotAggregates = {
  __typename?: 'IntegrationSnapshotAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<IntegrationSnapshotAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<IntegrationSnapshotDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<IntegrationSnapshotMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<IntegrationSnapshotMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<IntegrationSnapshotStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<IntegrationSnapshotStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<IntegrationSnapshotSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<IntegrationSnapshotVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<IntegrationSnapshotVarianceSampleAggregates>;
};

export type IntegrationSnapshotAverageAggregates = {
  __typename?: 'IntegrationSnapshotAverageAggregates';
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `IntegrationSnapshot` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type IntegrationSnapshotCondition = {
  /** Checks for equality with the object’s `createdDttm` field. */
  createdDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `integrationId` field. */
  integrationId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `isComplete` field. */
  isComplete?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `updatedDttm` field. */
  updatedDttm?: InputMaybe<Scalars['Datetime']>;
};

export type IntegrationSnapshotDistinctCountAggregates = {
  __typename?: 'IntegrationSnapshotDistinctCountAggregates';
  /** Distinct count of createdDttm across the matching connection */
  createdDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of isComplete across the matching connection */
  isComplete?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedDttm across the matching connection */
  updatedDttm?: Maybe<Scalars['BigInt']>;
};

export type IntegrationSnapshotMaxAggregates = {
  __typename?: 'IntegrationSnapshotMaxAggregates';
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['Int']>;
};

export type IntegrationSnapshotMinAggregates = {
  __typename?: 'IntegrationSnapshotMinAggregates';
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['Int']>;
};

export type IntegrationSnapshotStddevPopulationAggregates = {
  __typename?: 'IntegrationSnapshotStddevPopulationAggregates';
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigFloat']>;
};

export type IntegrationSnapshotStddevSampleAggregates = {
  __typename?: 'IntegrationSnapshotStddevSampleAggregates';
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigFloat']>;
};

export type IntegrationSnapshotSumAggregates = {
  __typename?: 'IntegrationSnapshotSumAggregates';
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of integrationId across the matching connection */
  integrationId: Scalars['BigInt'];
};

export type IntegrationSnapshotVariancePopulationAggregates = {
  __typename?: 'IntegrationSnapshotVariancePopulationAggregates';
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigFloat']>;
};

export type IntegrationSnapshotVarianceSampleAggregates = {
  __typename?: 'IntegrationSnapshotVarianceSampleAggregates';
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of integrationId across the matching connection */
  integrationId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `IntegrationSnapshot` values. */
export type IntegrationSnapshotsConnection = {
  __typename?: 'IntegrationSnapshotsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<IntegrationSnapshotAggregates>;
  /** A list of edges which contains the `IntegrationSnapshot` and cursor to aid in pagination. */
  edges: Array<IntegrationSnapshotsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<IntegrationSnapshotAggregates>>;
  /** A list of `IntegrationSnapshot` objects. */
  nodes: Array<IntegrationSnapshot>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `IntegrationSnapshot` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `IntegrationSnapshot` values. */
export type IntegrationSnapshotsConnectionGroupedAggregatesArgs = {
  groupBy: Array<IntegrationSnapshotsGroupBy>;
  having?: InputMaybe<IntegrationSnapshotsHavingInput>;
};

/** A `IntegrationSnapshot` edge in the connection. */
export type IntegrationSnapshotsEdge = {
  __typename?: 'IntegrationSnapshotsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `IntegrationSnapshot` at the end of the edge. */
  node: IntegrationSnapshot;
};

/** Grouping methods for `IntegrationSnapshot` for usage during aggregation. */
export enum IntegrationSnapshotsGroupBy {
  CreatedDttm = 'CREATED_DTTM',
  CreatedDttmTruncatedToDay = 'CREATED_DTTM_TRUNCATED_TO_DAY',
  CreatedDttmTruncatedToHour = 'CREATED_DTTM_TRUNCATED_TO_HOUR',
  IntegrationId = 'INTEGRATION_ID',
  IsComplete = 'IS_COMPLETE',
  UpdatedDttm = 'UPDATED_DTTM',
  UpdatedDttmTruncatedToDay = 'UPDATED_DTTM_TRUNCATED_TO_DAY',
  UpdatedDttmTruncatedToHour = 'UPDATED_DTTM_TRUNCATED_TO_HOUR'
}

export type IntegrationSnapshotsHavingAverageInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  integrationId?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type IntegrationSnapshotsHavingDistinctCountInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  integrationId?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `IntegrationSnapshot` aggregates. */
export type IntegrationSnapshotsHavingInput = {
  AND?: InputMaybe<Array<IntegrationSnapshotsHavingInput>>;
  OR?: InputMaybe<Array<IntegrationSnapshotsHavingInput>>;
  average?: InputMaybe<IntegrationSnapshotsHavingAverageInput>;
  distinctCount?: InputMaybe<IntegrationSnapshotsHavingDistinctCountInput>;
  max?: InputMaybe<IntegrationSnapshotsHavingMaxInput>;
  min?: InputMaybe<IntegrationSnapshotsHavingMinInput>;
  stddevPopulation?: InputMaybe<IntegrationSnapshotsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<IntegrationSnapshotsHavingStddevSampleInput>;
  sum?: InputMaybe<IntegrationSnapshotsHavingSumInput>;
  variancePopulation?: InputMaybe<IntegrationSnapshotsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<IntegrationSnapshotsHavingVarianceSampleInput>;
};

export type IntegrationSnapshotsHavingMaxInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  integrationId?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type IntegrationSnapshotsHavingMinInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  integrationId?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type IntegrationSnapshotsHavingStddevPopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  integrationId?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type IntegrationSnapshotsHavingStddevSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  integrationId?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type IntegrationSnapshotsHavingSumInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  integrationId?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type IntegrationSnapshotsHavingVariancePopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  integrationId?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type IntegrationSnapshotsHavingVarianceSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  integrationId?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `IntegrationSnapshot`. */
export enum IntegrationSnapshotsOrderBy {
  CreatedDttmAsc = 'CREATED_DTTM_ASC',
  CreatedDttmDesc = 'CREATED_DTTM_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IntegrationIdAsc = 'INTEGRATION_ID_ASC',
  IntegrationIdDesc = 'INTEGRATION_ID_DESC',
  IntegrationNodesBySnapshotIdAverageAttributesAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_AVERAGE_ATTRIBUTES_ASC',
  IntegrationNodesBySnapshotIdAverageAttributesDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_AVERAGE_ATTRIBUTES_DESC',
  IntegrationNodesBySnapshotIdAverageCreatedDttmAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_AVERAGE_CREATED_DTTM_ASC',
  IntegrationNodesBySnapshotIdAverageCreatedDttmDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_AVERAGE_CREATED_DTTM_DESC',
  IntegrationNodesBySnapshotIdAverageDownstreamReferencesAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_AVERAGE_DOWNSTREAM_REFERENCES_ASC',
  IntegrationNodesBySnapshotIdAverageDownstreamReferencesDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_AVERAGE_DOWNSTREAM_REFERENCES_DESC',
  IntegrationNodesBySnapshotIdAverageIdAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_AVERAGE_ID_ASC',
  IntegrationNodesBySnapshotIdAverageIdDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_AVERAGE_ID_DESC',
  IntegrationNodesBySnapshotIdAverageNameAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_AVERAGE_NAME_ASC',
  IntegrationNodesBySnapshotIdAverageNameDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_AVERAGE_NAME_DESC',
  IntegrationNodesBySnapshotIdAverageNodeTypeAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_AVERAGE_NODE_TYPE_ASC',
  IntegrationNodesBySnapshotIdAverageNodeTypeDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_AVERAGE_NODE_TYPE_DESC',
  IntegrationNodesBySnapshotIdAverageSnapshotIdAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_AVERAGE_SNAPSHOT_ID_ASC',
  IntegrationNodesBySnapshotIdAverageSnapshotIdDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_AVERAGE_SNAPSHOT_ID_DESC',
  IntegrationNodesBySnapshotIdAverageStableIdAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_AVERAGE_STABLE_ID_ASC',
  IntegrationNodesBySnapshotIdAverageStableIdDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_AVERAGE_STABLE_ID_DESC',
  IntegrationNodesBySnapshotIdAverageUpdatedDttmAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_AVERAGE_UPDATED_DTTM_ASC',
  IntegrationNodesBySnapshotIdAverageUpdatedDttmDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_AVERAGE_UPDATED_DTTM_DESC',
  IntegrationNodesBySnapshotIdAverageUpstreamReferencesAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_AVERAGE_UPSTREAM_REFERENCES_ASC',
  IntegrationNodesBySnapshotIdAverageUpstreamReferencesDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_AVERAGE_UPSTREAM_REFERENCES_DESC',
  IntegrationNodesBySnapshotIdCountAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_COUNT_ASC',
  IntegrationNodesBySnapshotIdCountDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_COUNT_DESC',
  IntegrationNodesBySnapshotIdDistinctCountAttributesAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_DISTINCT_COUNT_ATTRIBUTES_ASC',
  IntegrationNodesBySnapshotIdDistinctCountAttributesDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_DISTINCT_COUNT_ATTRIBUTES_DESC',
  IntegrationNodesBySnapshotIdDistinctCountCreatedDttmAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_DISTINCT_COUNT_CREATED_DTTM_ASC',
  IntegrationNodesBySnapshotIdDistinctCountCreatedDttmDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_DISTINCT_COUNT_CREATED_DTTM_DESC',
  IntegrationNodesBySnapshotIdDistinctCountDownstreamReferencesAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_DISTINCT_COUNT_DOWNSTREAM_REFERENCES_ASC',
  IntegrationNodesBySnapshotIdDistinctCountDownstreamReferencesDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_DISTINCT_COUNT_DOWNSTREAM_REFERENCES_DESC',
  IntegrationNodesBySnapshotIdDistinctCountIdAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_DISTINCT_COUNT_ID_ASC',
  IntegrationNodesBySnapshotIdDistinctCountIdDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_DISTINCT_COUNT_ID_DESC',
  IntegrationNodesBySnapshotIdDistinctCountNameAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_DISTINCT_COUNT_NAME_ASC',
  IntegrationNodesBySnapshotIdDistinctCountNameDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_DISTINCT_COUNT_NAME_DESC',
  IntegrationNodesBySnapshotIdDistinctCountNodeTypeAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_DISTINCT_COUNT_NODE_TYPE_ASC',
  IntegrationNodesBySnapshotIdDistinctCountNodeTypeDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_DISTINCT_COUNT_NODE_TYPE_DESC',
  IntegrationNodesBySnapshotIdDistinctCountSnapshotIdAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_DISTINCT_COUNT_SNAPSHOT_ID_ASC',
  IntegrationNodesBySnapshotIdDistinctCountSnapshotIdDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_DISTINCT_COUNT_SNAPSHOT_ID_DESC',
  IntegrationNodesBySnapshotIdDistinctCountStableIdAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_DISTINCT_COUNT_STABLE_ID_ASC',
  IntegrationNodesBySnapshotIdDistinctCountStableIdDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_DISTINCT_COUNT_STABLE_ID_DESC',
  IntegrationNodesBySnapshotIdDistinctCountUpdatedDttmAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_DISTINCT_COUNT_UPDATED_DTTM_ASC',
  IntegrationNodesBySnapshotIdDistinctCountUpdatedDttmDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_DISTINCT_COUNT_UPDATED_DTTM_DESC',
  IntegrationNodesBySnapshotIdDistinctCountUpstreamReferencesAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_DISTINCT_COUNT_UPSTREAM_REFERENCES_ASC',
  IntegrationNodesBySnapshotIdDistinctCountUpstreamReferencesDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_DISTINCT_COUNT_UPSTREAM_REFERENCES_DESC',
  IntegrationNodesBySnapshotIdMaxAttributesAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_MAX_ATTRIBUTES_ASC',
  IntegrationNodesBySnapshotIdMaxAttributesDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_MAX_ATTRIBUTES_DESC',
  IntegrationNodesBySnapshotIdMaxCreatedDttmAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_MAX_CREATED_DTTM_ASC',
  IntegrationNodesBySnapshotIdMaxCreatedDttmDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_MAX_CREATED_DTTM_DESC',
  IntegrationNodesBySnapshotIdMaxDownstreamReferencesAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_MAX_DOWNSTREAM_REFERENCES_ASC',
  IntegrationNodesBySnapshotIdMaxDownstreamReferencesDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_MAX_DOWNSTREAM_REFERENCES_DESC',
  IntegrationNodesBySnapshotIdMaxIdAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_MAX_ID_ASC',
  IntegrationNodesBySnapshotIdMaxIdDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_MAX_ID_DESC',
  IntegrationNodesBySnapshotIdMaxNameAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_MAX_NAME_ASC',
  IntegrationNodesBySnapshotIdMaxNameDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_MAX_NAME_DESC',
  IntegrationNodesBySnapshotIdMaxNodeTypeAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_MAX_NODE_TYPE_ASC',
  IntegrationNodesBySnapshotIdMaxNodeTypeDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_MAX_NODE_TYPE_DESC',
  IntegrationNodesBySnapshotIdMaxSnapshotIdAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_MAX_SNAPSHOT_ID_ASC',
  IntegrationNodesBySnapshotIdMaxSnapshotIdDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_MAX_SNAPSHOT_ID_DESC',
  IntegrationNodesBySnapshotIdMaxStableIdAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_MAX_STABLE_ID_ASC',
  IntegrationNodesBySnapshotIdMaxStableIdDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_MAX_STABLE_ID_DESC',
  IntegrationNodesBySnapshotIdMaxUpdatedDttmAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_MAX_UPDATED_DTTM_ASC',
  IntegrationNodesBySnapshotIdMaxUpdatedDttmDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_MAX_UPDATED_DTTM_DESC',
  IntegrationNodesBySnapshotIdMaxUpstreamReferencesAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_MAX_UPSTREAM_REFERENCES_ASC',
  IntegrationNodesBySnapshotIdMaxUpstreamReferencesDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_MAX_UPSTREAM_REFERENCES_DESC',
  IntegrationNodesBySnapshotIdMinAttributesAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_MIN_ATTRIBUTES_ASC',
  IntegrationNodesBySnapshotIdMinAttributesDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_MIN_ATTRIBUTES_DESC',
  IntegrationNodesBySnapshotIdMinCreatedDttmAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_MIN_CREATED_DTTM_ASC',
  IntegrationNodesBySnapshotIdMinCreatedDttmDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_MIN_CREATED_DTTM_DESC',
  IntegrationNodesBySnapshotIdMinDownstreamReferencesAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_MIN_DOWNSTREAM_REFERENCES_ASC',
  IntegrationNodesBySnapshotIdMinDownstreamReferencesDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_MIN_DOWNSTREAM_REFERENCES_DESC',
  IntegrationNodesBySnapshotIdMinIdAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_MIN_ID_ASC',
  IntegrationNodesBySnapshotIdMinIdDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_MIN_ID_DESC',
  IntegrationNodesBySnapshotIdMinNameAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_MIN_NAME_ASC',
  IntegrationNodesBySnapshotIdMinNameDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_MIN_NAME_DESC',
  IntegrationNodesBySnapshotIdMinNodeTypeAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_MIN_NODE_TYPE_ASC',
  IntegrationNodesBySnapshotIdMinNodeTypeDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_MIN_NODE_TYPE_DESC',
  IntegrationNodesBySnapshotIdMinSnapshotIdAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_MIN_SNAPSHOT_ID_ASC',
  IntegrationNodesBySnapshotIdMinSnapshotIdDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_MIN_SNAPSHOT_ID_DESC',
  IntegrationNodesBySnapshotIdMinStableIdAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_MIN_STABLE_ID_ASC',
  IntegrationNodesBySnapshotIdMinStableIdDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_MIN_STABLE_ID_DESC',
  IntegrationNodesBySnapshotIdMinUpdatedDttmAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_MIN_UPDATED_DTTM_ASC',
  IntegrationNodesBySnapshotIdMinUpdatedDttmDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_MIN_UPDATED_DTTM_DESC',
  IntegrationNodesBySnapshotIdMinUpstreamReferencesAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_MIN_UPSTREAM_REFERENCES_ASC',
  IntegrationNodesBySnapshotIdMinUpstreamReferencesDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_MIN_UPSTREAM_REFERENCES_DESC',
  IntegrationNodesBySnapshotIdStddevPopulationAttributesAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_STDDEV_POPULATION_ATTRIBUTES_ASC',
  IntegrationNodesBySnapshotIdStddevPopulationAttributesDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_STDDEV_POPULATION_ATTRIBUTES_DESC',
  IntegrationNodesBySnapshotIdStddevPopulationCreatedDttmAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_STDDEV_POPULATION_CREATED_DTTM_ASC',
  IntegrationNodesBySnapshotIdStddevPopulationCreatedDttmDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_STDDEV_POPULATION_CREATED_DTTM_DESC',
  IntegrationNodesBySnapshotIdStddevPopulationDownstreamReferencesAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_STDDEV_POPULATION_DOWNSTREAM_REFERENCES_ASC',
  IntegrationNodesBySnapshotIdStddevPopulationDownstreamReferencesDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_STDDEV_POPULATION_DOWNSTREAM_REFERENCES_DESC',
  IntegrationNodesBySnapshotIdStddevPopulationIdAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_STDDEV_POPULATION_ID_ASC',
  IntegrationNodesBySnapshotIdStddevPopulationIdDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_STDDEV_POPULATION_ID_DESC',
  IntegrationNodesBySnapshotIdStddevPopulationNameAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_STDDEV_POPULATION_NAME_ASC',
  IntegrationNodesBySnapshotIdStddevPopulationNameDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_STDDEV_POPULATION_NAME_DESC',
  IntegrationNodesBySnapshotIdStddevPopulationNodeTypeAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_STDDEV_POPULATION_NODE_TYPE_ASC',
  IntegrationNodesBySnapshotIdStddevPopulationNodeTypeDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_STDDEV_POPULATION_NODE_TYPE_DESC',
  IntegrationNodesBySnapshotIdStddevPopulationSnapshotIdAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_STDDEV_POPULATION_SNAPSHOT_ID_ASC',
  IntegrationNodesBySnapshotIdStddevPopulationSnapshotIdDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_STDDEV_POPULATION_SNAPSHOT_ID_DESC',
  IntegrationNodesBySnapshotIdStddevPopulationStableIdAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_STDDEV_POPULATION_STABLE_ID_ASC',
  IntegrationNodesBySnapshotIdStddevPopulationStableIdDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_STDDEV_POPULATION_STABLE_ID_DESC',
  IntegrationNodesBySnapshotIdStddevPopulationUpdatedDttmAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_STDDEV_POPULATION_UPDATED_DTTM_ASC',
  IntegrationNodesBySnapshotIdStddevPopulationUpdatedDttmDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_STDDEV_POPULATION_UPDATED_DTTM_DESC',
  IntegrationNodesBySnapshotIdStddevPopulationUpstreamReferencesAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_STDDEV_POPULATION_UPSTREAM_REFERENCES_ASC',
  IntegrationNodesBySnapshotIdStddevPopulationUpstreamReferencesDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_STDDEV_POPULATION_UPSTREAM_REFERENCES_DESC',
  IntegrationNodesBySnapshotIdStddevSampleAttributesAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_STDDEV_SAMPLE_ATTRIBUTES_ASC',
  IntegrationNodesBySnapshotIdStddevSampleAttributesDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_STDDEV_SAMPLE_ATTRIBUTES_DESC',
  IntegrationNodesBySnapshotIdStddevSampleCreatedDttmAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_STDDEV_SAMPLE_CREATED_DTTM_ASC',
  IntegrationNodesBySnapshotIdStddevSampleCreatedDttmDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_STDDEV_SAMPLE_CREATED_DTTM_DESC',
  IntegrationNodesBySnapshotIdStddevSampleDownstreamReferencesAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_STDDEV_SAMPLE_DOWNSTREAM_REFERENCES_ASC',
  IntegrationNodesBySnapshotIdStddevSampleDownstreamReferencesDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_STDDEV_SAMPLE_DOWNSTREAM_REFERENCES_DESC',
  IntegrationNodesBySnapshotIdStddevSampleIdAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_STDDEV_SAMPLE_ID_ASC',
  IntegrationNodesBySnapshotIdStddevSampleIdDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_STDDEV_SAMPLE_ID_DESC',
  IntegrationNodesBySnapshotIdStddevSampleNameAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_STDDEV_SAMPLE_NAME_ASC',
  IntegrationNodesBySnapshotIdStddevSampleNameDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_STDDEV_SAMPLE_NAME_DESC',
  IntegrationNodesBySnapshotIdStddevSampleNodeTypeAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_STDDEV_SAMPLE_NODE_TYPE_ASC',
  IntegrationNodesBySnapshotIdStddevSampleNodeTypeDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_STDDEV_SAMPLE_NODE_TYPE_DESC',
  IntegrationNodesBySnapshotIdStddevSampleSnapshotIdAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_STDDEV_SAMPLE_SNAPSHOT_ID_ASC',
  IntegrationNodesBySnapshotIdStddevSampleSnapshotIdDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_STDDEV_SAMPLE_SNAPSHOT_ID_DESC',
  IntegrationNodesBySnapshotIdStddevSampleStableIdAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_STDDEV_SAMPLE_STABLE_ID_ASC',
  IntegrationNodesBySnapshotIdStddevSampleStableIdDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_STDDEV_SAMPLE_STABLE_ID_DESC',
  IntegrationNodesBySnapshotIdStddevSampleUpdatedDttmAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_STDDEV_SAMPLE_UPDATED_DTTM_ASC',
  IntegrationNodesBySnapshotIdStddevSampleUpdatedDttmDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_STDDEV_SAMPLE_UPDATED_DTTM_DESC',
  IntegrationNodesBySnapshotIdStddevSampleUpstreamReferencesAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_STDDEV_SAMPLE_UPSTREAM_REFERENCES_ASC',
  IntegrationNodesBySnapshotIdStddevSampleUpstreamReferencesDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_STDDEV_SAMPLE_UPSTREAM_REFERENCES_DESC',
  IntegrationNodesBySnapshotIdSumAttributesAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_SUM_ATTRIBUTES_ASC',
  IntegrationNodesBySnapshotIdSumAttributesDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_SUM_ATTRIBUTES_DESC',
  IntegrationNodesBySnapshotIdSumCreatedDttmAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_SUM_CREATED_DTTM_ASC',
  IntegrationNodesBySnapshotIdSumCreatedDttmDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_SUM_CREATED_DTTM_DESC',
  IntegrationNodesBySnapshotIdSumDownstreamReferencesAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_SUM_DOWNSTREAM_REFERENCES_ASC',
  IntegrationNodesBySnapshotIdSumDownstreamReferencesDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_SUM_DOWNSTREAM_REFERENCES_DESC',
  IntegrationNodesBySnapshotIdSumIdAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_SUM_ID_ASC',
  IntegrationNodesBySnapshotIdSumIdDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_SUM_ID_DESC',
  IntegrationNodesBySnapshotIdSumNameAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_SUM_NAME_ASC',
  IntegrationNodesBySnapshotIdSumNameDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_SUM_NAME_DESC',
  IntegrationNodesBySnapshotIdSumNodeTypeAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_SUM_NODE_TYPE_ASC',
  IntegrationNodesBySnapshotIdSumNodeTypeDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_SUM_NODE_TYPE_DESC',
  IntegrationNodesBySnapshotIdSumSnapshotIdAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_SUM_SNAPSHOT_ID_ASC',
  IntegrationNodesBySnapshotIdSumSnapshotIdDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_SUM_SNAPSHOT_ID_DESC',
  IntegrationNodesBySnapshotIdSumStableIdAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_SUM_STABLE_ID_ASC',
  IntegrationNodesBySnapshotIdSumStableIdDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_SUM_STABLE_ID_DESC',
  IntegrationNodesBySnapshotIdSumUpdatedDttmAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_SUM_UPDATED_DTTM_ASC',
  IntegrationNodesBySnapshotIdSumUpdatedDttmDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_SUM_UPDATED_DTTM_DESC',
  IntegrationNodesBySnapshotIdSumUpstreamReferencesAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_SUM_UPSTREAM_REFERENCES_ASC',
  IntegrationNodesBySnapshotIdSumUpstreamReferencesDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_SUM_UPSTREAM_REFERENCES_DESC',
  IntegrationNodesBySnapshotIdVariancePopulationAttributesAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_VARIANCE_POPULATION_ATTRIBUTES_ASC',
  IntegrationNodesBySnapshotIdVariancePopulationAttributesDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_VARIANCE_POPULATION_ATTRIBUTES_DESC',
  IntegrationNodesBySnapshotIdVariancePopulationCreatedDttmAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_VARIANCE_POPULATION_CREATED_DTTM_ASC',
  IntegrationNodesBySnapshotIdVariancePopulationCreatedDttmDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_VARIANCE_POPULATION_CREATED_DTTM_DESC',
  IntegrationNodesBySnapshotIdVariancePopulationDownstreamReferencesAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_VARIANCE_POPULATION_DOWNSTREAM_REFERENCES_ASC',
  IntegrationNodesBySnapshotIdVariancePopulationDownstreamReferencesDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_VARIANCE_POPULATION_DOWNSTREAM_REFERENCES_DESC',
  IntegrationNodesBySnapshotIdVariancePopulationIdAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_VARIANCE_POPULATION_ID_ASC',
  IntegrationNodesBySnapshotIdVariancePopulationIdDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_VARIANCE_POPULATION_ID_DESC',
  IntegrationNodesBySnapshotIdVariancePopulationNameAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_VARIANCE_POPULATION_NAME_ASC',
  IntegrationNodesBySnapshotIdVariancePopulationNameDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_VARIANCE_POPULATION_NAME_DESC',
  IntegrationNodesBySnapshotIdVariancePopulationNodeTypeAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_VARIANCE_POPULATION_NODE_TYPE_ASC',
  IntegrationNodesBySnapshotIdVariancePopulationNodeTypeDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_VARIANCE_POPULATION_NODE_TYPE_DESC',
  IntegrationNodesBySnapshotIdVariancePopulationSnapshotIdAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_VARIANCE_POPULATION_SNAPSHOT_ID_ASC',
  IntegrationNodesBySnapshotIdVariancePopulationSnapshotIdDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_VARIANCE_POPULATION_SNAPSHOT_ID_DESC',
  IntegrationNodesBySnapshotIdVariancePopulationStableIdAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_VARIANCE_POPULATION_STABLE_ID_ASC',
  IntegrationNodesBySnapshotIdVariancePopulationStableIdDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_VARIANCE_POPULATION_STABLE_ID_DESC',
  IntegrationNodesBySnapshotIdVariancePopulationUpdatedDttmAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_VARIANCE_POPULATION_UPDATED_DTTM_ASC',
  IntegrationNodesBySnapshotIdVariancePopulationUpdatedDttmDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_VARIANCE_POPULATION_UPDATED_DTTM_DESC',
  IntegrationNodesBySnapshotIdVariancePopulationUpstreamReferencesAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_VARIANCE_POPULATION_UPSTREAM_REFERENCES_ASC',
  IntegrationNodesBySnapshotIdVariancePopulationUpstreamReferencesDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_VARIANCE_POPULATION_UPSTREAM_REFERENCES_DESC',
  IntegrationNodesBySnapshotIdVarianceSampleAttributesAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_VARIANCE_SAMPLE_ATTRIBUTES_ASC',
  IntegrationNodesBySnapshotIdVarianceSampleAttributesDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_VARIANCE_SAMPLE_ATTRIBUTES_DESC',
  IntegrationNodesBySnapshotIdVarianceSampleCreatedDttmAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_VARIANCE_SAMPLE_CREATED_DTTM_ASC',
  IntegrationNodesBySnapshotIdVarianceSampleCreatedDttmDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_VARIANCE_SAMPLE_CREATED_DTTM_DESC',
  IntegrationNodesBySnapshotIdVarianceSampleDownstreamReferencesAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_VARIANCE_SAMPLE_DOWNSTREAM_REFERENCES_ASC',
  IntegrationNodesBySnapshotIdVarianceSampleDownstreamReferencesDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_VARIANCE_SAMPLE_DOWNSTREAM_REFERENCES_DESC',
  IntegrationNodesBySnapshotIdVarianceSampleIdAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_VARIANCE_SAMPLE_ID_ASC',
  IntegrationNodesBySnapshotIdVarianceSampleIdDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_VARIANCE_SAMPLE_ID_DESC',
  IntegrationNodesBySnapshotIdVarianceSampleNameAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_VARIANCE_SAMPLE_NAME_ASC',
  IntegrationNodesBySnapshotIdVarianceSampleNameDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_VARIANCE_SAMPLE_NAME_DESC',
  IntegrationNodesBySnapshotIdVarianceSampleNodeTypeAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_VARIANCE_SAMPLE_NODE_TYPE_ASC',
  IntegrationNodesBySnapshotIdVarianceSampleNodeTypeDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_VARIANCE_SAMPLE_NODE_TYPE_DESC',
  IntegrationNodesBySnapshotIdVarianceSampleSnapshotIdAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_VARIANCE_SAMPLE_SNAPSHOT_ID_ASC',
  IntegrationNodesBySnapshotIdVarianceSampleSnapshotIdDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_VARIANCE_SAMPLE_SNAPSHOT_ID_DESC',
  IntegrationNodesBySnapshotIdVarianceSampleStableIdAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_VARIANCE_SAMPLE_STABLE_ID_ASC',
  IntegrationNodesBySnapshotIdVarianceSampleStableIdDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_VARIANCE_SAMPLE_STABLE_ID_DESC',
  IntegrationNodesBySnapshotIdVarianceSampleUpdatedDttmAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_VARIANCE_SAMPLE_UPDATED_DTTM_ASC',
  IntegrationNodesBySnapshotIdVarianceSampleUpdatedDttmDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_VARIANCE_SAMPLE_UPDATED_DTTM_DESC',
  IntegrationNodesBySnapshotIdVarianceSampleUpstreamReferencesAsc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_VARIANCE_SAMPLE_UPSTREAM_REFERENCES_ASC',
  IntegrationNodesBySnapshotIdVarianceSampleUpstreamReferencesDesc = 'INTEGRATION_NODES_BY_SNAPSHOT_ID_VARIANCE_SAMPLE_UPSTREAM_REFERENCES_DESC',
  IsCompleteAsc = 'IS_COMPLETE_ASC',
  IsCompleteDesc = 'IS_COMPLETE_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedDttmAsc = 'UPDATED_DTTM_ASC',
  UpdatedDttmDesc = 'UPDATED_DTTM_DESC'
}

export type IntegrationStddevPopulationAggregates = {
  __typename?: 'IntegrationStddevPopulationAggregates';
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type IntegrationStddevSampleAggregates = {
  __typename?: 'IntegrationStddevSampleAggregates';
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type IntegrationSumAggregates = {
  __typename?: 'IntegrationSumAggregates';
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of workspaceId across the matching connection */
  workspaceId: Scalars['BigInt'];
};

export type IntegrationVariancePopulationAggregates = {
  __typename?: 'IntegrationVariancePopulationAggregates';
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type IntegrationVarianceSampleAggregates = {
  __typename?: 'IntegrationVarianceSampleAggregates';
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Integration` values. */
export type IntegrationsConnection = {
  __typename?: 'IntegrationsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<IntegrationAggregates>;
  /** A list of edges which contains the `Integration` and cursor to aid in pagination. */
  edges: Array<IntegrationsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<IntegrationAggregates>>;
  /** A list of `Integration` objects. */
  nodes: Array<Integration>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Integration` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `Integration` values. */
export type IntegrationsConnectionGroupedAggregatesArgs = {
  groupBy: Array<IntegrationsGroupBy>;
  having?: InputMaybe<IntegrationsHavingInput>;
};

/** A `Integration` edge in the connection. */
export type IntegrationsEdge = {
  __typename?: 'IntegrationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Integration` at the end of the edge. */
  node: Integration;
};

/** Grouping methods for `Integration` for usage during aggregation. */
export enum IntegrationsGroupBy {
  CreatedDttm = 'CREATED_DTTM',
  CreatedDttmTruncatedToDay = 'CREATED_DTTM_TRUNCATED_TO_DAY',
  CreatedDttmTruncatedToHour = 'CREATED_DTTM_TRUNCATED_TO_HOUR',
  IsActive = 'IS_ACTIVE',
  Name = 'NAME',
  Type = 'TYPE',
  Uid = 'UID',
  UpdatedDttm = 'UPDATED_DTTM',
  UpdatedDttmTruncatedToDay = 'UPDATED_DTTM_TRUNCATED_TO_DAY',
  UpdatedDttmTruncatedToHour = 'UPDATED_DTTM_TRUNCATED_TO_HOUR',
  WorkspaceId = 'WORKSPACE_ID'
}

export type IntegrationsHavingAverageInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type IntegrationsHavingDistinctCountInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `Integration` aggregates. */
export type IntegrationsHavingInput = {
  AND?: InputMaybe<Array<IntegrationsHavingInput>>;
  OR?: InputMaybe<Array<IntegrationsHavingInput>>;
  average?: InputMaybe<IntegrationsHavingAverageInput>;
  distinctCount?: InputMaybe<IntegrationsHavingDistinctCountInput>;
  max?: InputMaybe<IntegrationsHavingMaxInput>;
  min?: InputMaybe<IntegrationsHavingMinInput>;
  stddevPopulation?: InputMaybe<IntegrationsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<IntegrationsHavingStddevSampleInput>;
  sum?: InputMaybe<IntegrationsHavingSumInput>;
  variancePopulation?: InputMaybe<IntegrationsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<IntegrationsHavingVarianceSampleInput>;
};

export type IntegrationsHavingMaxInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type IntegrationsHavingMinInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type IntegrationsHavingStddevPopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type IntegrationsHavingStddevSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type IntegrationsHavingSumInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type IntegrationsHavingVariancePopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type IntegrationsHavingVarianceSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

/** Methods to use when ordering `Integration`. */
export enum IntegrationsOrderBy {
  CreatedDttmAsc = 'CREATED_DTTM_ASC',
  CreatedDttmDesc = 'CREATED_DTTM_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IntegrationConfigsAverageCreatedDttmAsc = 'INTEGRATION_CONFIGS_AVERAGE_CREATED_DTTM_ASC',
  IntegrationConfigsAverageCreatedDttmDesc = 'INTEGRATION_CONFIGS_AVERAGE_CREATED_DTTM_DESC',
  IntegrationConfigsAverageIdAsc = 'INTEGRATION_CONFIGS_AVERAGE_ID_ASC',
  IntegrationConfigsAverageIdDesc = 'INTEGRATION_CONFIGS_AVERAGE_ID_DESC',
  IntegrationConfigsAverageIntegrationIdAsc = 'INTEGRATION_CONFIGS_AVERAGE_INTEGRATION_ID_ASC',
  IntegrationConfigsAverageIntegrationIdDesc = 'INTEGRATION_CONFIGS_AVERAGE_INTEGRATION_ID_DESC',
  IntegrationConfigsAverageKeyAsc = 'INTEGRATION_CONFIGS_AVERAGE_KEY_ASC',
  IntegrationConfigsAverageKeyDesc = 'INTEGRATION_CONFIGS_AVERAGE_KEY_DESC',
  IntegrationConfigsAverageUpdatedDttmAsc = 'INTEGRATION_CONFIGS_AVERAGE_UPDATED_DTTM_ASC',
  IntegrationConfigsAverageUpdatedDttmDesc = 'INTEGRATION_CONFIGS_AVERAGE_UPDATED_DTTM_DESC',
  IntegrationConfigsAverageValueAsc = 'INTEGRATION_CONFIGS_AVERAGE_VALUE_ASC',
  IntegrationConfigsAverageValueDesc = 'INTEGRATION_CONFIGS_AVERAGE_VALUE_DESC',
  IntegrationConfigsCountAsc = 'INTEGRATION_CONFIGS_COUNT_ASC',
  IntegrationConfigsCountDesc = 'INTEGRATION_CONFIGS_COUNT_DESC',
  IntegrationConfigsDistinctCountCreatedDttmAsc = 'INTEGRATION_CONFIGS_DISTINCT_COUNT_CREATED_DTTM_ASC',
  IntegrationConfigsDistinctCountCreatedDttmDesc = 'INTEGRATION_CONFIGS_DISTINCT_COUNT_CREATED_DTTM_DESC',
  IntegrationConfigsDistinctCountIdAsc = 'INTEGRATION_CONFIGS_DISTINCT_COUNT_ID_ASC',
  IntegrationConfigsDistinctCountIdDesc = 'INTEGRATION_CONFIGS_DISTINCT_COUNT_ID_DESC',
  IntegrationConfigsDistinctCountIntegrationIdAsc = 'INTEGRATION_CONFIGS_DISTINCT_COUNT_INTEGRATION_ID_ASC',
  IntegrationConfigsDistinctCountIntegrationIdDesc = 'INTEGRATION_CONFIGS_DISTINCT_COUNT_INTEGRATION_ID_DESC',
  IntegrationConfigsDistinctCountKeyAsc = 'INTEGRATION_CONFIGS_DISTINCT_COUNT_KEY_ASC',
  IntegrationConfigsDistinctCountKeyDesc = 'INTEGRATION_CONFIGS_DISTINCT_COUNT_KEY_DESC',
  IntegrationConfigsDistinctCountUpdatedDttmAsc = 'INTEGRATION_CONFIGS_DISTINCT_COUNT_UPDATED_DTTM_ASC',
  IntegrationConfigsDistinctCountUpdatedDttmDesc = 'INTEGRATION_CONFIGS_DISTINCT_COUNT_UPDATED_DTTM_DESC',
  IntegrationConfigsDistinctCountValueAsc = 'INTEGRATION_CONFIGS_DISTINCT_COUNT_VALUE_ASC',
  IntegrationConfigsDistinctCountValueDesc = 'INTEGRATION_CONFIGS_DISTINCT_COUNT_VALUE_DESC',
  IntegrationConfigsMaxCreatedDttmAsc = 'INTEGRATION_CONFIGS_MAX_CREATED_DTTM_ASC',
  IntegrationConfigsMaxCreatedDttmDesc = 'INTEGRATION_CONFIGS_MAX_CREATED_DTTM_DESC',
  IntegrationConfigsMaxIdAsc = 'INTEGRATION_CONFIGS_MAX_ID_ASC',
  IntegrationConfigsMaxIdDesc = 'INTEGRATION_CONFIGS_MAX_ID_DESC',
  IntegrationConfigsMaxIntegrationIdAsc = 'INTEGRATION_CONFIGS_MAX_INTEGRATION_ID_ASC',
  IntegrationConfigsMaxIntegrationIdDesc = 'INTEGRATION_CONFIGS_MAX_INTEGRATION_ID_DESC',
  IntegrationConfigsMaxKeyAsc = 'INTEGRATION_CONFIGS_MAX_KEY_ASC',
  IntegrationConfigsMaxKeyDesc = 'INTEGRATION_CONFIGS_MAX_KEY_DESC',
  IntegrationConfigsMaxUpdatedDttmAsc = 'INTEGRATION_CONFIGS_MAX_UPDATED_DTTM_ASC',
  IntegrationConfigsMaxUpdatedDttmDesc = 'INTEGRATION_CONFIGS_MAX_UPDATED_DTTM_DESC',
  IntegrationConfigsMaxValueAsc = 'INTEGRATION_CONFIGS_MAX_VALUE_ASC',
  IntegrationConfigsMaxValueDesc = 'INTEGRATION_CONFIGS_MAX_VALUE_DESC',
  IntegrationConfigsMinCreatedDttmAsc = 'INTEGRATION_CONFIGS_MIN_CREATED_DTTM_ASC',
  IntegrationConfigsMinCreatedDttmDesc = 'INTEGRATION_CONFIGS_MIN_CREATED_DTTM_DESC',
  IntegrationConfigsMinIdAsc = 'INTEGRATION_CONFIGS_MIN_ID_ASC',
  IntegrationConfigsMinIdDesc = 'INTEGRATION_CONFIGS_MIN_ID_DESC',
  IntegrationConfigsMinIntegrationIdAsc = 'INTEGRATION_CONFIGS_MIN_INTEGRATION_ID_ASC',
  IntegrationConfigsMinIntegrationIdDesc = 'INTEGRATION_CONFIGS_MIN_INTEGRATION_ID_DESC',
  IntegrationConfigsMinKeyAsc = 'INTEGRATION_CONFIGS_MIN_KEY_ASC',
  IntegrationConfigsMinKeyDesc = 'INTEGRATION_CONFIGS_MIN_KEY_DESC',
  IntegrationConfigsMinUpdatedDttmAsc = 'INTEGRATION_CONFIGS_MIN_UPDATED_DTTM_ASC',
  IntegrationConfigsMinUpdatedDttmDesc = 'INTEGRATION_CONFIGS_MIN_UPDATED_DTTM_DESC',
  IntegrationConfigsMinValueAsc = 'INTEGRATION_CONFIGS_MIN_VALUE_ASC',
  IntegrationConfigsMinValueDesc = 'INTEGRATION_CONFIGS_MIN_VALUE_DESC',
  IntegrationConfigsStddevPopulationCreatedDttmAsc = 'INTEGRATION_CONFIGS_STDDEV_POPULATION_CREATED_DTTM_ASC',
  IntegrationConfigsStddevPopulationCreatedDttmDesc = 'INTEGRATION_CONFIGS_STDDEV_POPULATION_CREATED_DTTM_DESC',
  IntegrationConfigsStddevPopulationIdAsc = 'INTEGRATION_CONFIGS_STDDEV_POPULATION_ID_ASC',
  IntegrationConfigsStddevPopulationIdDesc = 'INTEGRATION_CONFIGS_STDDEV_POPULATION_ID_DESC',
  IntegrationConfigsStddevPopulationIntegrationIdAsc = 'INTEGRATION_CONFIGS_STDDEV_POPULATION_INTEGRATION_ID_ASC',
  IntegrationConfigsStddevPopulationIntegrationIdDesc = 'INTEGRATION_CONFIGS_STDDEV_POPULATION_INTEGRATION_ID_DESC',
  IntegrationConfigsStddevPopulationKeyAsc = 'INTEGRATION_CONFIGS_STDDEV_POPULATION_KEY_ASC',
  IntegrationConfigsStddevPopulationKeyDesc = 'INTEGRATION_CONFIGS_STDDEV_POPULATION_KEY_DESC',
  IntegrationConfigsStddevPopulationUpdatedDttmAsc = 'INTEGRATION_CONFIGS_STDDEV_POPULATION_UPDATED_DTTM_ASC',
  IntegrationConfigsStddevPopulationUpdatedDttmDesc = 'INTEGRATION_CONFIGS_STDDEV_POPULATION_UPDATED_DTTM_DESC',
  IntegrationConfigsStddevPopulationValueAsc = 'INTEGRATION_CONFIGS_STDDEV_POPULATION_VALUE_ASC',
  IntegrationConfigsStddevPopulationValueDesc = 'INTEGRATION_CONFIGS_STDDEV_POPULATION_VALUE_DESC',
  IntegrationConfigsStddevSampleCreatedDttmAsc = 'INTEGRATION_CONFIGS_STDDEV_SAMPLE_CREATED_DTTM_ASC',
  IntegrationConfigsStddevSampleCreatedDttmDesc = 'INTEGRATION_CONFIGS_STDDEV_SAMPLE_CREATED_DTTM_DESC',
  IntegrationConfigsStddevSampleIdAsc = 'INTEGRATION_CONFIGS_STDDEV_SAMPLE_ID_ASC',
  IntegrationConfigsStddevSampleIdDesc = 'INTEGRATION_CONFIGS_STDDEV_SAMPLE_ID_DESC',
  IntegrationConfigsStddevSampleIntegrationIdAsc = 'INTEGRATION_CONFIGS_STDDEV_SAMPLE_INTEGRATION_ID_ASC',
  IntegrationConfigsStddevSampleIntegrationIdDesc = 'INTEGRATION_CONFIGS_STDDEV_SAMPLE_INTEGRATION_ID_DESC',
  IntegrationConfigsStddevSampleKeyAsc = 'INTEGRATION_CONFIGS_STDDEV_SAMPLE_KEY_ASC',
  IntegrationConfigsStddevSampleKeyDesc = 'INTEGRATION_CONFIGS_STDDEV_SAMPLE_KEY_DESC',
  IntegrationConfigsStddevSampleUpdatedDttmAsc = 'INTEGRATION_CONFIGS_STDDEV_SAMPLE_UPDATED_DTTM_ASC',
  IntegrationConfigsStddevSampleUpdatedDttmDesc = 'INTEGRATION_CONFIGS_STDDEV_SAMPLE_UPDATED_DTTM_DESC',
  IntegrationConfigsStddevSampleValueAsc = 'INTEGRATION_CONFIGS_STDDEV_SAMPLE_VALUE_ASC',
  IntegrationConfigsStddevSampleValueDesc = 'INTEGRATION_CONFIGS_STDDEV_SAMPLE_VALUE_DESC',
  IntegrationConfigsSumCreatedDttmAsc = 'INTEGRATION_CONFIGS_SUM_CREATED_DTTM_ASC',
  IntegrationConfigsSumCreatedDttmDesc = 'INTEGRATION_CONFIGS_SUM_CREATED_DTTM_DESC',
  IntegrationConfigsSumIdAsc = 'INTEGRATION_CONFIGS_SUM_ID_ASC',
  IntegrationConfigsSumIdDesc = 'INTEGRATION_CONFIGS_SUM_ID_DESC',
  IntegrationConfigsSumIntegrationIdAsc = 'INTEGRATION_CONFIGS_SUM_INTEGRATION_ID_ASC',
  IntegrationConfigsSumIntegrationIdDesc = 'INTEGRATION_CONFIGS_SUM_INTEGRATION_ID_DESC',
  IntegrationConfigsSumKeyAsc = 'INTEGRATION_CONFIGS_SUM_KEY_ASC',
  IntegrationConfigsSumKeyDesc = 'INTEGRATION_CONFIGS_SUM_KEY_DESC',
  IntegrationConfigsSumUpdatedDttmAsc = 'INTEGRATION_CONFIGS_SUM_UPDATED_DTTM_ASC',
  IntegrationConfigsSumUpdatedDttmDesc = 'INTEGRATION_CONFIGS_SUM_UPDATED_DTTM_DESC',
  IntegrationConfigsSumValueAsc = 'INTEGRATION_CONFIGS_SUM_VALUE_ASC',
  IntegrationConfigsSumValueDesc = 'INTEGRATION_CONFIGS_SUM_VALUE_DESC',
  IntegrationConfigsVariancePopulationCreatedDttmAsc = 'INTEGRATION_CONFIGS_VARIANCE_POPULATION_CREATED_DTTM_ASC',
  IntegrationConfigsVariancePopulationCreatedDttmDesc = 'INTEGRATION_CONFIGS_VARIANCE_POPULATION_CREATED_DTTM_DESC',
  IntegrationConfigsVariancePopulationIdAsc = 'INTEGRATION_CONFIGS_VARIANCE_POPULATION_ID_ASC',
  IntegrationConfigsVariancePopulationIdDesc = 'INTEGRATION_CONFIGS_VARIANCE_POPULATION_ID_DESC',
  IntegrationConfigsVariancePopulationIntegrationIdAsc = 'INTEGRATION_CONFIGS_VARIANCE_POPULATION_INTEGRATION_ID_ASC',
  IntegrationConfigsVariancePopulationIntegrationIdDesc = 'INTEGRATION_CONFIGS_VARIANCE_POPULATION_INTEGRATION_ID_DESC',
  IntegrationConfigsVariancePopulationKeyAsc = 'INTEGRATION_CONFIGS_VARIANCE_POPULATION_KEY_ASC',
  IntegrationConfigsVariancePopulationKeyDesc = 'INTEGRATION_CONFIGS_VARIANCE_POPULATION_KEY_DESC',
  IntegrationConfigsVariancePopulationUpdatedDttmAsc = 'INTEGRATION_CONFIGS_VARIANCE_POPULATION_UPDATED_DTTM_ASC',
  IntegrationConfigsVariancePopulationUpdatedDttmDesc = 'INTEGRATION_CONFIGS_VARIANCE_POPULATION_UPDATED_DTTM_DESC',
  IntegrationConfigsVariancePopulationValueAsc = 'INTEGRATION_CONFIGS_VARIANCE_POPULATION_VALUE_ASC',
  IntegrationConfigsVariancePopulationValueDesc = 'INTEGRATION_CONFIGS_VARIANCE_POPULATION_VALUE_DESC',
  IntegrationConfigsVarianceSampleCreatedDttmAsc = 'INTEGRATION_CONFIGS_VARIANCE_SAMPLE_CREATED_DTTM_ASC',
  IntegrationConfigsVarianceSampleCreatedDttmDesc = 'INTEGRATION_CONFIGS_VARIANCE_SAMPLE_CREATED_DTTM_DESC',
  IntegrationConfigsVarianceSampleIdAsc = 'INTEGRATION_CONFIGS_VARIANCE_SAMPLE_ID_ASC',
  IntegrationConfigsVarianceSampleIdDesc = 'INTEGRATION_CONFIGS_VARIANCE_SAMPLE_ID_DESC',
  IntegrationConfigsVarianceSampleIntegrationIdAsc = 'INTEGRATION_CONFIGS_VARIANCE_SAMPLE_INTEGRATION_ID_ASC',
  IntegrationConfigsVarianceSampleIntegrationIdDesc = 'INTEGRATION_CONFIGS_VARIANCE_SAMPLE_INTEGRATION_ID_DESC',
  IntegrationConfigsVarianceSampleKeyAsc = 'INTEGRATION_CONFIGS_VARIANCE_SAMPLE_KEY_ASC',
  IntegrationConfigsVarianceSampleKeyDesc = 'INTEGRATION_CONFIGS_VARIANCE_SAMPLE_KEY_DESC',
  IntegrationConfigsVarianceSampleUpdatedDttmAsc = 'INTEGRATION_CONFIGS_VARIANCE_SAMPLE_UPDATED_DTTM_ASC',
  IntegrationConfigsVarianceSampleUpdatedDttmDesc = 'INTEGRATION_CONFIGS_VARIANCE_SAMPLE_UPDATED_DTTM_DESC',
  IntegrationConfigsVarianceSampleValueAsc = 'INTEGRATION_CONFIGS_VARIANCE_SAMPLE_VALUE_ASC',
  IntegrationConfigsVarianceSampleValueDesc = 'INTEGRATION_CONFIGS_VARIANCE_SAMPLE_VALUE_DESC',
  IntegrationSnapshotsAverageCreatedDttmAsc = 'INTEGRATION_SNAPSHOTS_AVERAGE_CREATED_DTTM_ASC',
  IntegrationSnapshotsAverageCreatedDttmDesc = 'INTEGRATION_SNAPSHOTS_AVERAGE_CREATED_DTTM_DESC',
  IntegrationSnapshotsAverageIdAsc = 'INTEGRATION_SNAPSHOTS_AVERAGE_ID_ASC',
  IntegrationSnapshotsAverageIdDesc = 'INTEGRATION_SNAPSHOTS_AVERAGE_ID_DESC',
  IntegrationSnapshotsAverageIntegrationIdAsc = 'INTEGRATION_SNAPSHOTS_AVERAGE_INTEGRATION_ID_ASC',
  IntegrationSnapshotsAverageIntegrationIdDesc = 'INTEGRATION_SNAPSHOTS_AVERAGE_INTEGRATION_ID_DESC',
  IntegrationSnapshotsAverageIsCompleteAsc = 'INTEGRATION_SNAPSHOTS_AVERAGE_IS_COMPLETE_ASC',
  IntegrationSnapshotsAverageIsCompleteDesc = 'INTEGRATION_SNAPSHOTS_AVERAGE_IS_COMPLETE_DESC',
  IntegrationSnapshotsAverageUpdatedDttmAsc = 'INTEGRATION_SNAPSHOTS_AVERAGE_UPDATED_DTTM_ASC',
  IntegrationSnapshotsAverageUpdatedDttmDesc = 'INTEGRATION_SNAPSHOTS_AVERAGE_UPDATED_DTTM_DESC',
  IntegrationSnapshotsCountAsc = 'INTEGRATION_SNAPSHOTS_COUNT_ASC',
  IntegrationSnapshotsCountDesc = 'INTEGRATION_SNAPSHOTS_COUNT_DESC',
  IntegrationSnapshotsDistinctCountCreatedDttmAsc = 'INTEGRATION_SNAPSHOTS_DISTINCT_COUNT_CREATED_DTTM_ASC',
  IntegrationSnapshotsDistinctCountCreatedDttmDesc = 'INTEGRATION_SNAPSHOTS_DISTINCT_COUNT_CREATED_DTTM_DESC',
  IntegrationSnapshotsDistinctCountIdAsc = 'INTEGRATION_SNAPSHOTS_DISTINCT_COUNT_ID_ASC',
  IntegrationSnapshotsDistinctCountIdDesc = 'INTEGRATION_SNAPSHOTS_DISTINCT_COUNT_ID_DESC',
  IntegrationSnapshotsDistinctCountIntegrationIdAsc = 'INTEGRATION_SNAPSHOTS_DISTINCT_COUNT_INTEGRATION_ID_ASC',
  IntegrationSnapshotsDistinctCountIntegrationIdDesc = 'INTEGRATION_SNAPSHOTS_DISTINCT_COUNT_INTEGRATION_ID_DESC',
  IntegrationSnapshotsDistinctCountIsCompleteAsc = 'INTEGRATION_SNAPSHOTS_DISTINCT_COUNT_IS_COMPLETE_ASC',
  IntegrationSnapshotsDistinctCountIsCompleteDesc = 'INTEGRATION_SNAPSHOTS_DISTINCT_COUNT_IS_COMPLETE_DESC',
  IntegrationSnapshotsDistinctCountUpdatedDttmAsc = 'INTEGRATION_SNAPSHOTS_DISTINCT_COUNT_UPDATED_DTTM_ASC',
  IntegrationSnapshotsDistinctCountUpdatedDttmDesc = 'INTEGRATION_SNAPSHOTS_DISTINCT_COUNT_UPDATED_DTTM_DESC',
  IntegrationSnapshotsMaxCreatedDttmAsc = 'INTEGRATION_SNAPSHOTS_MAX_CREATED_DTTM_ASC',
  IntegrationSnapshotsMaxCreatedDttmDesc = 'INTEGRATION_SNAPSHOTS_MAX_CREATED_DTTM_DESC',
  IntegrationSnapshotsMaxIdAsc = 'INTEGRATION_SNAPSHOTS_MAX_ID_ASC',
  IntegrationSnapshotsMaxIdDesc = 'INTEGRATION_SNAPSHOTS_MAX_ID_DESC',
  IntegrationSnapshotsMaxIntegrationIdAsc = 'INTEGRATION_SNAPSHOTS_MAX_INTEGRATION_ID_ASC',
  IntegrationSnapshotsMaxIntegrationIdDesc = 'INTEGRATION_SNAPSHOTS_MAX_INTEGRATION_ID_DESC',
  IntegrationSnapshotsMaxIsCompleteAsc = 'INTEGRATION_SNAPSHOTS_MAX_IS_COMPLETE_ASC',
  IntegrationSnapshotsMaxIsCompleteDesc = 'INTEGRATION_SNAPSHOTS_MAX_IS_COMPLETE_DESC',
  IntegrationSnapshotsMaxUpdatedDttmAsc = 'INTEGRATION_SNAPSHOTS_MAX_UPDATED_DTTM_ASC',
  IntegrationSnapshotsMaxUpdatedDttmDesc = 'INTEGRATION_SNAPSHOTS_MAX_UPDATED_DTTM_DESC',
  IntegrationSnapshotsMinCreatedDttmAsc = 'INTEGRATION_SNAPSHOTS_MIN_CREATED_DTTM_ASC',
  IntegrationSnapshotsMinCreatedDttmDesc = 'INTEGRATION_SNAPSHOTS_MIN_CREATED_DTTM_DESC',
  IntegrationSnapshotsMinIdAsc = 'INTEGRATION_SNAPSHOTS_MIN_ID_ASC',
  IntegrationSnapshotsMinIdDesc = 'INTEGRATION_SNAPSHOTS_MIN_ID_DESC',
  IntegrationSnapshotsMinIntegrationIdAsc = 'INTEGRATION_SNAPSHOTS_MIN_INTEGRATION_ID_ASC',
  IntegrationSnapshotsMinIntegrationIdDesc = 'INTEGRATION_SNAPSHOTS_MIN_INTEGRATION_ID_DESC',
  IntegrationSnapshotsMinIsCompleteAsc = 'INTEGRATION_SNAPSHOTS_MIN_IS_COMPLETE_ASC',
  IntegrationSnapshotsMinIsCompleteDesc = 'INTEGRATION_SNAPSHOTS_MIN_IS_COMPLETE_DESC',
  IntegrationSnapshotsMinUpdatedDttmAsc = 'INTEGRATION_SNAPSHOTS_MIN_UPDATED_DTTM_ASC',
  IntegrationSnapshotsMinUpdatedDttmDesc = 'INTEGRATION_SNAPSHOTS_MIN_UPDATED_DTTM_DESC',
  IntegrationSnapshotsStddevPopulationCreatedDttmAsc = 'INTEGRATION_SNAPSHOTS_STDDEV_POPULATION_CREATED_DTTM_ASC',
  IntegrationSnapshotsStddevPopulationCreatedDttmDesc = 'INTEGRATION_SNAPSHOTS_STDDEV_POPULATION_CREATED_DTTM_DESC',
  IntegrationSnapshotsStddevPopulationIdAsc = 'INTEGRATION_SNAPSHOTS_STDDEV_POPULATION_ID_ASC',
  IntegrationSnapshotsStddevPopulationIdDesc = 'INTEGRATION_SNAPSHOTS_STDDEV_POPULATION_ID_DESC',
  IntegrationSnapshotsStddevPopulationIntegrationIdAsc = 'INTEGRATION_SNAPSHOTS_STDDEV_POPULATION_INTEGRATION_ID_ASC',
  IntegrationSnapshotsStddevPopulationIntegrationIdDesc = 'INTEGRATION_SNAPSHOTS_STDDEV_POPULATION_INTEGRATION_ID_DESC',
  IntegrationSnapshotsStddevPopulationIsCompleteAsc = 'INTEGRATION_SNAPSHOTS_STDDEV_POPULATION_IS_COMPLETE_ASC',
  IntegrationSnapshotsStddevPopulationIsCompleteDesc = 'INTEGRATION_SNAPSHOTS_STDDEV_POPULATION_IS_COMPLETE_DESC',
  IntegrationSnapshotsStddevPopulationUpdatedDttmAsc = 'INTEGRATION_SNAPSHOTS_STDDEV_POPULATION_UPDATED_DTTM_ASC',
  IntegrationSnapshotsStddevPopulationUpdatedDttmDesc = 'INTEGRATION_SNAPSHOTS_STDDEV_POPULATION_UPDATED_DTTM_DESC',
  IntegrationSnapshotsStddevSampleCreatedDttmAsc = 'INTEGRATION_SNAPSHOTS_STDDEV_SAMPLE_CREATED_DTTM_ASC',
  IntegrationSnapshotsStddevSampleCreatedDttmDesc = 'INTEGRATION_SNAPSHOTS_STDDEV_SAMPLE_CREATED_DTTM_DESC',
  IntegrationSnapshotsStddevSampleIdAsc = 'INTEGRATION_SNAPSHOTS_STDDEV_SAMPLE_ID_ASC',
  IntegrationSnapshotsStddevSampleIdDesc = 'INTEGRATION_SNAPSHOTS_STDDEV_SAMPLE_ID_DESC',
  IntegrationSnapshotsStddevSampleIntegrationIdAsc = 'INTEGRATION_SNAPSHOTS_STDDEV_SAMPLE_INTEGRATION_ID_ASC',
  IntegrationSnapshotsStddevSampleIntegrationIdDesc = 'INTEGRATION_SNAPSHOTS_STDDEV_SAMPLE_INTEGRATION_ID_DESC',
  IntegrationSnapshotsStddevSampleIsCompleteAsc = 'INTEGRATION_SNAPSHOTS_STDDEV_SAMPLE_IS_COMPLETE_ASC',
  IntegrationSnapshotsStddevSampleIsCompleteDesc = 'INTEGRATION_SNAPSHOTS_STDDEV_SAMPLE_IS_COMPLETE_DESC',
  IntegrationSnapshotsStddevSampleUpdatedDttmAsc = 'INTEGRATION_SNAPSHOTS_STDDEV_SAMPLE_UPDATED_DTTM_ASC',
  IntegrationSnapshotsStddevSampleUpdatedDttmDesc = 'INTEGRATION_SNAPSHOTS_STDDEV_SAMPLE_UPDATED_DTTM_DESC',
  IntegrationSnapshotsSumCreatedDttmAsc = 'INTEGRATION_SNAPSHOTS_SUM_CREATED_DTTM_ASC',
  IntegrationSnapshotsSumCreatedDttmDesc = 'INTEGRATION_SNAPSHOTS_SUM_CREATED_DTTM_DESC',
  IntegrationSnapshotsSumIdAsc = 'INTEGRATION_SNAPSHOTS_SUM_ID_ASC',
  IntegrationSnapshotsSumIdDesc = 'INTEGRATION_SNAPSHOTS_SUM_ID_DESC',
  IntegrationSnapshotsSumIntegrationIdAsc = 'INTEGRATION_SNAPSHOTS_SUM_INTEGRATION_ID_ASC',
  IntegrationSnapshotsSumIntegrationIdDesc = 'INTEGRATION_SNAPSHOTS_SUM_INTEGRATION_ID_DESC',
  IntegrationSnapshotsSumIsCompleteAsc = 'INTEGRATION_SNAPSHOTS_SUM_IS_COMPLETE_ASC',
  IntegrationSnapshotsSumIsCompleteDesc = 'INTEGRATION_SNAPSHOTS_SUM_IS_COMPLETE_DESC',
  IntegrationSnapshotsSumUpdatedDttmAsc = 'INTEGRATION_SNAPSHOTS_SUM_UPDATED_DTTM_ASC',
  IntegrationSnapshotsSumUpdatedDttmDesc = 'INTEGRATION_SNAPSHOTS_SUM_UPDATED_DTTM_DESC',
  IntegrationSnapshotsVariancePopulationCreatedDttmAsc = 'INTEGRATION_SNAPSHOTS_VARIANCE_POPULATION_CREATED_DTTM_ASC',
  IntegrationSnapshotsVariancePopulationCreatedDttmDesc = 'INTEGRATION_SNAPSHOTS_VARIANCE_POPULATION_CREATED_DTTM_DESC',
  IntegrationSnapshotsVariancePopulationIdAsc = 'INTEGRATION_SNAPSHOTS_VARIANCE_POPULATION_ID_ASC',
  IntegrationSnapshotsVariancePopulationIdDesc = 'INTEGRATION_SNAPSHOTS_VARIANCE_POPULATION_ID_DESC',
  IntegrationSnapshotsVariancePopulationIntegrationIdAsc = 'INTEGRATION_SNAPSHOTS_VARIANCE_POPULATION_INTEGRATION_ID_ASC',
  IntegrationSnapshotsVariancePopulationIntegrationIdDesc = 'INTEGRATION_SNAPSHOTS_VARIANCE_POPULATION_INTEGRATION_ID_DESC',
  IntegrationSnapshotsVariancePopulationIsCompleteAsc = 'INTEGRATION_SNAPSHOTS_VARIANCE_POPULATION_IS_COMPLETE_ASC',
  IntegrationSnapshotsVariancePopulationIsCompleteDesc = 'INTEGRATION_SNAPSHOTS_VARIANCE_POPULATION_IS_COMPLETE_DESC',
  IntegrationSnapshotsVariancePopulationUpdatedDttmAsc = 'INTEGRATION_SNAPSHOTS_VARIANCE_POPULATION_UPDATED_DTTM_ASC',
  IntegrationSnapshotsVariancePopulationUpdatedDttmDesc = 'INTEGRATION_SNAPSHOTS_VARIANCE_POPULATION_UPDATED_DTTM_DESC',
  IntegrationSnapshotsVarianceSampleCreatedDttmAsc = 'INTEGRATION_SNAPSHOTS_VARIANCE_SAMPLE_CREATED_DTTM_ASC',
  IntegrationSnapshotsVarianceSampleCreatedDttmDesc = 'INTEGRATION_SNAPSHOTS_VARIANCE_SAMPLE_CREATED_DTTM_DESC',
  IntegrationSnapshotsVarianceSampleIdAsc = 'INTEGRATION_SNAPSHOTS_VARIANCE_SAMPLE_ID_ASC',
  IntegrationSnapshotsVarianceSampleIdDesc = 'INTEGRATION_SNAPSHOTS_VARIANCE_SAMPLE_ID_DESC',
  IntegrationSnapshotsVarianceSampleIntegrationIdAsc = 'INTEGRATION_SNAPSHOTS_VARIANCE_SAMPLE_INTEGRATION_ID_ASC',
  IntegrationSnapshotsVarianceSampleIntegrationIdDesc = 'INTEGRATION_SNAPSHOTS_VARIANCE_SAMPLE_INTEGRATION_ID_DESC',
  IntegrationSnapshotsVarianceSampleIsCompleteAsc = 'INTEGRATION_SNAPSHOTS_VARIANCE_SAMPLE_IS_COMPLETE_ASC',
  IntegrationSnapshotsVarianceSampleIsCompleteDesc = 'INTEGRATION_SNAPSHOTS_VARIANCE_SAMPLE_IS_COMPLETE_DESC',
  IntegrationSnapshotsVarianceSampleUpdatedDttmAsc = 'INTEGRATION_SNAPSHOTS_VARIANCE_SAMPLE_UPDATED_DTTM_ASC',
  IntegrationSnapshotsVarianceSampleUpdatedDttmDesc = 'INTEGRATION_SNAPSHOTS_VARIANCE_SAMPLE_UPDATED_DTTM_DESC',
  IsActiveAsc = 'IS_ACTIVE_ASC',
  IsActiveDesc = 'IS_ACTIVE_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UidAsc = 'UID_ASC',
  UidDesc = 'UID_DESC',
  UpdatedDttmAsc = 'UPDATED_DTTM_ASC',
  UpdatedDttmDesc = 'UPDATED_DTTM_DESC',
  WorkspaceIdAsc = 'WORKSPACE_ID_ASC',
  WorkspaceIdDesc = 'WORKSPACE_ID_DESC'
}

export type LookerImportError = Node & {
  __typename?: 'LookerImportError';
  createdDttm: Scalars['Datetime'];
  description?: Maybe<Scalars['String']>;
  errorType: Scalars['String'];
  id: Scalars['Int'];
  /** Reads a single `LookerSnapshot` that is related to this `LookerImportError`. */
  lookerSnapshot?: Maybe<LookerSnapshot>;
  lookerSnapshotId: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

export type LookerImportErrorAggregates = {
  __typename?: 'LookerImportErrorAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<LookerImportErrorAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LookerImportErrorDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<LookerImportErrorMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<LookerImportErrorMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<LookerImportErrorStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<LookerImportErrorStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<LookerImportErrorSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<LookerImportErrorVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<LookerImportErrorVarianceSampleAggregates>;
};

export type LookerImportErrorAverageAggregates = {
  __typename?: 'LookerImportErrorAverageAggregates';
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of lookerSnapshotId across the matching connection */
  lookerSnapshotId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `LookerImportError` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LookerImportErrorCondition = {
  /** Checks for equality with the object’s `createdDttm` field. */
  createdDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `errorType` field. */
  errorType?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `lookerSnapshotId` field. */
  lookerSnapshotId?: InputMaybe<Scalars['Int']>;
};

export type LookerImportErrorDistinctCountAggregates = {
  __typename?: 'LookerImportErrorDistinctCountAggregates';
  /** Distinct count of createdDttm across the matching connection */
  createdDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of description across the matching connection */
  description?: Maybe<Scalars['BigInt']>;
  /** Distinct count of errorType across the matching connection */
  errorType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lookerSnapshotId across the matching connection */
  lookerSnapshotId?: Maybe<Scalars['BigInt']>;
};

export type LookerImportErrorMaxAggregates = {
  __typename?: 'LookerImportErrorMaxAggregates';
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of lookerSnapshotId across the matching connection */
  lookerSnapshotId?: Maybe<Scalars['Int']>;
};

export type LookerImportErrorMinAggregates = {
  __typename?: 'LookerImportErrorMinAggregates';
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of lookerSnapshotId across the matching connection */
  lookerSnapshotId?: Maybe<Scalars['Int']>;
};

export type LookerImportErrorStddevPopulationAggregates = {
  __typename?: 'LookerImportErrorStddevPopulationAggregates';
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of lookerSnapshotId across the matching connection */
  lookerSnapshotId?: Maybe<Scalars['BigFloat']>;
};

export type LookerImportErrorStddevSampleAggregates = {
  __typename?: 'LookerImportErrorStddevSampleAggregates';
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of lookerSnapshotId across the matching connection */
  lookerSnapshotId?: Maybe<Scalars['BigFloat']>;
};

export type LookerImportErrorSumAggregates = {
  __typename?: 'LookerImportErrorSumAggregates';
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of lookerSnapshotId across the matching connection */
  lookerSnapshotId: Scalars['BigInt'];
};

export type LookerImportErrorVariancePopulationAggregates = {
  __typename?: 'LookerImportErrorVariancePopulationAggregates';
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of lookerSnapshotId across the matching connection */
  lookerSnapshotId?: Maybe<Scalars['BigFloat']>;
};

export type LookerImportErrorVarianceSampleAggregates = {
  __typename?: 'LookerImportErrorVarianceSampleAggregates';
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of lookerSnapshotId across the matching connection */
  lookerSnapshotId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `LookerImportError` values. */
export type LookerImportErrorsConnection = {
  __typename?: 'LookerImportErrorsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LookerImportErrorAggregates>;
  /** A list of edges which contains the `LookerImportError` and cursor to aid in pagination. */
  edges: Array<LookerImportErrorsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LookerImportErrorAggregates>>;
  /** A list of `LookerImportError` objects. */
  nodes: Array<LookerImportError>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LookerImportError` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `LookerImportError` values. */
export type LookerImportErrorsConnectionGroupedAggregatesArgs = {
  groupBy: Array<LookerImportErrorsGroupBy>;
  having?: InputMaybe<LookerImportErrorsHavingInput>;
};

/** A `LookerImportError` edge in the connection. */
export type LookerImportErrorsEdge = {
  __typename?: 'LookerImportErrorsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LookerImportError` at the end of the edge. */
  node: LookerImportError;
};

/** Grouping methods for `LookerImportError` for usage during aggregation. */
export enum LookerImportErrorsGroupBy {
  CreatedDttm = 'CREATED_DTTM',
  CreatedDttmTruncatedToDay = 'CREATED_DTTM_TRUNCATED_TO_DAY',
  CreatedDttmTruncatedToHour = 'CREATED_DTTM_TRUNCATED_TO_HOUR',
  Description = 'DESCRIPTION',
  ErrorType = 'ERROR_TYPE',
  LookerSnapshotId = 'LOOKER_SNAPSHOT_ID'
}

export type LookerImportErrorsHavingAverageInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lookerSnapshotId?: InputMaybe<HavingIntFilter>;
};

export type LookerImportErrorsHavingDistinctCountInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lookerSnapshotId?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `LookerImportError` aggregates. */
export type LookerImportErrorsHavingInput = {
  AND?: InputMaybe<Array<LookerImportErrorsHavingInput>>;
  OR?: InputMaybe<Array<LookerImportErrorsHavingInput>>;
  average?: InputMaybe<LookerImportErrorsHavingAverageInput>;
  distinctCount?: InputMaybe<LookerImportErrorsHavingDistinctCountInput>;
  max?: InputMaybe<LookerImportErrorsHavingMaxInput>;
  min?: InputMaybe<LookerImportErrorsHavingMinInput>;
  stddevPopulation?: InputMaybe<LookerImportErrorsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<LookerImportErrorsHavingStddevSampleInput>;
  sum?: InputMaybe<LookerImportErrorsHavingSumInput>;
  variancePopulation?: InputMaybe<LookerImportErrorsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<LookerImportErrorsHavingVarianceSampleInput>;
};

export type LookerImportErrorsHavingMaxInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lookerSnapshotId?: InputMaybe<HavingIntFilter>;
};

export type LookerImportErrorsHavingMinInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lookerSnapshotId?: InputMaybe<HavingIntFilter>;
};

export type LookerImportErrorsHavingStddevPopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lookerSnapshotId?: InputMaybe<HavingIntFilter>;
};

export type LookerImportErrorsHavingStddevSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lookerSnapshotId?: InputMaybe<HavingIntFilter>;
};

export type LookerImportErrorsHavingSumInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lookerSnapshotId?: InputMaybe<HavingIntFilter>;
};

export type LookerImportErrorsHavingVariancePopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lookerSnapshotId?: InputMaybe<HavingIntFilter>;
};

export type LookerImportErrorsHavingVarianceSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lookerSnapshotId?: InputMaybe<HavingIntFilter>;
};

/** Methods to use when ordering `LookerImportError`. */
export enum LookerImportErrorsOrderBy {
  CreatedDttmAsc = 'CREATED_DTTM_ASC',
  CreatedDttmDesc = 'CREATED_DTTM_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  ErrorTypeAsc = 'ERROR_TYPE_ASC',
  ErrorTypeDesc = 'ERROR_TYPE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LookerSnapshotIdAsc = 'LOOKER_SNAPSHOT_ID_ASC',
  LookerSnapshotIdDesc = 'LOOKER_SNAPSHOT_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type LookerNode = Node & {
  __typename?: 'LookerNode';
  attributes: Scalars['String'];
  createdDttm: Scalars['Datetime'];
  id: Scalars['Int'];
  lookerId: Scalars['String'];
  /** Reads a single `LookerSnapshot` that is related to this `LookerNode`. */
  lookerSnapshot?: Maybe<LookerSnapshot>;
  lookerSnapshotId: Scalars['Int'];
  modelName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  nodeType: Scalars['String'];
  references: Scalars['String'];
  tags: Scalars['String'];
};

export type LookerNodeAggregates = {
  __typename?: 'LookerNodeAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<LookerNodeAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LookerNodeDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<LookerNodeMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<LookerNodeMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<LookerNodeStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<LookerNodeStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<LookerNodeSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<LookerNodeVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<LookerNodeVarianceSampleAggregates>;
};

export type LookerNodeAverageAggregates = {
  __typename?: 'LookerNodeAverageAggregates';
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of lookerSnapshotId across the matching connection */
  lookerSnapshotId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `LookerNode` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type LookerNodeCondition = {
  /** Checks for equality with the object’s `attributes` field. */
  attributes?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdDttm` field. */
  createdDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `lookerId` field. */
  lookerId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `lookerSnapshotId` field. */
  lookerSnapshotId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `modelName` field. */
  modelName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `nodeType` field. */
  nodeType?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `references` field. */
  references?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `tags` field. */
  tags?: InputMaybe<Scalars['String']>;
};

export type LookerNodeDistinctCountAggregates = {
  __typename?: 'LookerNodeDistinctCountAggregates';
  /** Distinct count of attributes across the matching connection */
  attributes?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdDttm across the matching connection */
  createdDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lookerId across the matching connection */
  lookerId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lookerSnapshotId across the matching connection */
  lookerSnapshotId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of modelName across the matching connection */
  modelName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of nodeType across the matching connection */
  nodeType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of references across the matching connection */
  references?: Maybe<Scalars['BigInt']>;
  /** Distinct count of tags across the matching connection */
  tags?: Maybe<Scalars['BigInt']>;
};

export type LookerNodeMaxAggregates = {
  __typename?: 'LookerNodeMaxAggregates';
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of lookerSnapshotId across the matching connection */
  lookerSnapshotId?: Maybe<Scalars['Int']>;
};

export type LookerNodeMinAggregates = {
  __typename?: 'LookerNodeMinAggregates';
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of lookerSnapshotId across the matching connection */
  lookerSnapshotId?: Maybe<Scalars['Int']>;
};

export type LookerNodeStddevPopulationAggregates = {
  __typename?: 'LookerNodeStddevPopulationAggregates';
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of lookerSnapshotId across the matching connection */
  lookerSnapshotId?: Maybe<Scalars['BigFloat']>;
};

export type LookerNodeStddevSampleAggregates = {
  __typename?: 'LookerNodeStddevSampleAggregates';
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of lookerSnapshotId across the matching connection */
  lookerSnapshotId?: Maybe<Scalars['BigFloat']>;
};

export type LookerNodeSumAggregates = {
  __typename?: 'LookerNodeSumAggregates';
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of lookerSnapshotId across the matching connection */
  lookerSnapshotId: Scalars['BigInt'];
};

export type LookerNodeVariancePopulationAggregates = {
  __typename?: 'LookerNodeVariancePopulationAggregates';
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of lookerSnapshotId across the matching connection */
  lookerSnapshotId?: Maybe<Scalars['BigFloat']>;
};

export type LookerNodeVarianceSampleAggregates = {
  __typename?: 'LookerNodeVarianceSampleAggregates';
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of lookerSnapshotId across the matching connection */
  lookerSnapshotId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `LookerNode` values. */
export type LookerNodesConnection = {
  __typename?: 'LookerNodesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LookerNodeAggregates>;
  /** A list of edges which contains the `LookerNode` and cursor to aid in pagination. */
  edges: Array<LookerNodesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LookerNodeAggregates>>;
  /** A list of `LookerNode` objects. */
  nodes: Array<LookerNode>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LookerNode` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `LookerNode` values. */
export type LookerNodesConnectionGroupedAggregatesArgs = {
  groupBy: Array<LookerNodesGroupBy>;
  having?: InputMaybe<LookerNodesHavingInput>;
};

/** A `LookerNode` edge in the connection. */
export type LookerNodesEdge = {
  __typename?: 'LookerNodesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LookerNode` at the end of the edge. */
  node: LookerNode;
};

/** Grouping methods for `LookerNode` for usage during aggregation. */
export enum LookerNodesGroupBy {
  Attributes = 'ATTRIBUTES',
  CreatedDttm = 'CREATED_DTTM',
  CreatedDttmTruncatedToDay = 'CREATED_DTTM_TRUNCATED_TO_DAY',
  CreatedDttmTruncatedToHour = 'CREATED_DTTM_TRUNCATED_TO_HOUR',
  LookerId = 'LOOKER_ID',
  LookerSnapshotId = 'LOOKER_SNAPSHOT_ID',
  ModelName = 'MODEL_NAME',
  Name = 'NAME',
  NodeType = 'NODE_TYPE',
  References = 'REFERENCES',
  Tags = 'TAGS'
}

export type LookerNodesHavingAverageInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lookerSnapshotId?: InputMaybe<HavingIntFilter>;
};

export type LookerNodesHavingDistinctCountInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lookerSnapshotId?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `LookerNode` aggregates. */
export type LookerNodesHavingInput = {
  AND?: InputMaybe<Array<LookerNodesHavingInput>>;
  OR?: InputMaybe<Array<LookerNodesHavingInput>>;
  average?: InputMaybe<LookerNodesHavingAverageInput>;
  distinctCount?: InputMaybe<LookerNodesHavingDistinctCountInput>;
  max?: InputMaybe<LookerNodesHavingMaxInput>;
  min?: InputMaybe<LookerNodesHavingMinInput>;
  stddevPopulation?: InputMaybe<LookerNodesHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<LookerNodesHavingStddevSampleInput>;
  sum?: InputMaybe<LookerNodesHavingSumInput>;
  variancePopulation?: InputMaybe<LookerNodesHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<LookerNodesHavingVarianceSampleInput>;
};

export type LookerNodesHavingMaxInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lookerSnapshotId?: InputMaybe<HavingIntFilter>;
};

export type LookerNodesHavingMinInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lookerSnapshotId?: InputMaybe<HavingIntFilter>;
};

export type LookerNodesHavingStddevPopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lookerSnapshotId?: InputMaybe<HavingIntFilter>;
};

export type LookerNodesHavingStddevSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lookerSnapshotId?: InputMaybe<HavingIntFilter>;
};

export type LookerNodesHavingSumInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lookerSnapshotId?: InputMaybe<HavingIntFilter>;
};

export type LookerNodesHavingVariancePopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lookerSnapshotId?: InputMaybe<HavingIntFilter>;
};

export type LookerNodesHavingVarianceSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lookerSnapshotId?: InputMaybe<HavingIntFilter>;
};

/** Methods to use when ordering `LookerNode`. */
export enum LookerNodesOrderBy {
  AttributesAsc = 'ATTRIBUTES_ASC',
  AttributesDesc = 'ATTRIBUTES_DESC',
  CreatedDttmAsc = 'CREATED_DTTM_ASC',
  CreatedDttmDesc = 'CREATED_DTTM_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LookerIdAsc = 'LOOKER_ID_ASC',
  LookerIdDesc = 'LOOKER_ID_DESC',
  LookerSnapshotIdAsc = 'LOOKER_SNAPSHOT_ID_ASC',
  LookerSnapshotIdDesc = 'LOOKER_SNAPSHOT_ID_DESC',
  ModelNameAsc = 'MODEL_NAME_ASC',
  ModelNameDesc = 'MODEL_NAME_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  NodeTypeAsc = 'NODE_TYPE_ASC',
  NodeTypeDesc = 'NODE_TYPE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ReferencesAsc = 'REFERENCES_ASC',
  ReferencesDesc = 'REFERENCES_DESC',
  TagsAsc = 'TAGS_ASC',
  TagsDesc = 'TAGS_DESC'
}

export type LookerRepositoryNode = Node & {
  __typename?: 'LookerRepositoryNode';
  aliases: Scalars['String'];
  attributes: Scalars['String'];
  createdDttm: Scalars['Datetime'];
  id: Scalars['Int'];
  lookerName: Scalars['String'];
  lookerRepositoryId: Scalars['String'];
  /** Reads a single `LookerRepositorySnapshot` that is related to this `LookerRepositoryNode`. */
  lookerRepositorySnapshot?: Maybe<LookerRepositorySnapshot>;
  lookerRepositorySnapshotId: Scalars['Int'];
  modelName?: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  nodeType: Scalars['String'];
  references: Scalars['String'];
  tags: Scalars['String'];
};

export type LookerRepositoryNodeAggregates = {
  __typename?: 'LookerRepositoryNodeAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<LookerRepositoryNodeAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LookerRepositoryNodeDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<LookerRepositoryNodeMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<LookerRepositoryNodeMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<LookerRepositoryNodeStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<LookerRepositoryNodeStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<LookerRepositoryNodeSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<LookerRepositoryNodeVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<LookerRepositoryNodeVarianceSampleAggregates>;
};

export type LookerRepositoryNodeAverageAggregates = {
  __typename?: 'LookerRepositoryNodeAverageAggregates';
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of lookerRepositorySnapshotId across the matching connection */
  lookerRepositorySnapshotId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `LookerRepositoryNode` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type LookerRepositoryNodeCondition = {
  /** Checks for equality with the object’s `aliases` field. */
  aliases?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `attributes` field. */
  attributes?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdDttm` field. */
  createdDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `lookerName` field. */
  lookerName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `lookerRepositoryId` field. */
  lookerRepositoryId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `lookerRepositorySnapshotId` field. */
  lookerRepositorySnapshotId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `modelName` field. */
  modelName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `nodeType` field. */
  nodeType?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `references` field. */
  references?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `tags` field. */
  tags?: InputMaybe<Scalars['String']>;
};

export type LookerRepositoryNodeDistinctCountAggregates = {
  __typename?: 'LookerRepositoryNodeDistinctCountAggregates';
  /** Distinct count of aliases across the matching connection */
  aliases?: Maybe<Scalars['BigInt']>;
  /** Distinct count of attributes across the matching connection */
  attributes?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdDttm across the matching connection */
  createdDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lookerName across the matching connection */
  lookerName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lookerRepositoryId across the matching connection */
  lookerRepositoryId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lookerRepositorySnapshotId across the matching connection */
  lookerRepositorySnapshotId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of modelName across the matching connection */
  modelName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of nodeType across the matching connection */
  nodeType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of references across the matching connection */
  references?: Maybe<Scalars['BigInt']>;
  /** Distinct count of tags across the matching connection */
  tags?: Maybe<Scalars['BigInt']>;
};

export type LookerRepositoryNodeMaxAggregates = {
  __typename?: 'LookerRepositoryNodeMaxAggregates';
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of lookerRepositorySnapshotId across the matching connection */
  lookerRepositorySnapshotId?: Maybe<Scalars['Int']>;
};

export type LookerRepositoryNodeMinAggregates = {
  __typename?: 'LookerRepositoryNodeMinAggregates';
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of lookerRepositorySnapshotId across the matching connection */
  lookerRepositorySnapshotId?: Maybe<Scalars['Int']>;
};

export type LookerRepositoryNodeStddevPopulationAggregates = {
  __typename?: 'LookerRepositoryNodeStddevPopulationAggregates';
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of lookerRepositorySnapshotId across the matching connection */
  lookerRepositorySnapshotId?: Maybe<Scalars['BigFloat']>;
};

export type LookerRepositoryNodeStddevSampleAggregates = {
  __typename?: 'LookerRepositoryNodeStddevSampleAggregates';
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of lookerRepositorySnapshotId across the matching connection */
  lookerRepositorySnapshotId?: Maybe<Scalars['BigFloat']>;
};

export type LookerRepositoryNodeSumAggregates = {
  __typename?: 'LookerRepositoryNodeSumAggregates';
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of lookerRepositorySnapshotId across the matching connection */
  lookerRepositorySnapshotId: Scalars['BigInt'];
};

export type LookerRepositoryNodeVariancePopulationAggregates = {
  __typename?: 'LookerRepositoryNodeVariancePopulationAggregates';
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of lookerRepositorySnapshotId across the matching connection */
  lookerRepositorySnapshotId?: Maybe<Scalars['BigFloat']>;
};

export type LookerRepositoryNodeVarianceSampleAggregates = {
  __typename?: 'LookerRepositoryNodeVarianceSampleAggregates';
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of lookerRepositorySnapshotId across the matching connection */
  lookerRepositorySnapshotId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `LookerRepositoryNode` values. */
export type LookerRepositoryNodesConnection = {
  __typename?: 'LookerRepositoryNodesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LookerRepositoryNodeAggregates>;
  /** A list of edges which contains the `LookerRepositoryNode` and cursor to aid in pagination. */
  edges: Array<LookerRepositoryNodesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LookerRepositoryNodeAggregates>>;
  /** A list of `LookerRepositoryNode` objects. */
  nodes: Array<LookerRepositoryNode>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LookerRepositoryNode` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `LookerRepositoryNode` values. */
export type LookerRepositoryNodesConnectionGroupedAggregatesArgs = {
  groupBy: Array<LookerRepositoryNodesGroupBy>;
  having?: InputMaybe<LookerRepositoryNodesHavingInput>;
};

/** A `LookerRepositoryNode` edge in the connection. */
export type LookerRepositoryNodesEdge = {
  __typename?: 'LookerRepositoryNodesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LookerRepositoryNode` at the end of the edge. */
  node: LookerRepositoryNode;
};

/** Grouping methods for `LookerRepositoryNode` for usage during aggregation. */
export enum LookerRepositoryNodesGroupBy {
  Aliases = 'ALIASES',
  Attributes = 'ATTRIBUTES',
  CreatedDttm = 'CREATED_DTTM',
  CreatedDttmTruncatedToDay = 'CREATED_DTTM_TRUNCATED_TO_DAY',
  CreatedDttmTruncatedToHour = 'CREATED_DTTM_TRUNCATED_TO_HOUR',
  LookerName = 'LOOKER_NAME',
  LookerRepositoryId = 'LOOKER_REPOSITORY_ID',
  LookerRepositorySnapshotId = 'LOOKER_REPOSITORY_SNAPSHOT_ID',
  ModelName = 'MODEL_NAME',
  NodeType = 'NODE_TYPE',
  References = 'REFERENCES',
  Tags = 'TAGS'
}

export type LookerRepositoryNodesHavingAverageInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lookerRepositorySnapshotId?: InputMaybe<HavingIntFilter>;
};

export type LookerRepositoryNodesHavingDistinctCountInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lookerRepositorySnapshotId?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `LookerRepositoryNode` aggregates. */
export type LookerRepositoryNodesHavingInput = {
  AND?: InputMaybe<Array<LookerRepositoryNodesHavingInput>>;
  OR?: InputMaybe<Array<LookerRepositoryNodesHavingInput>>;
  average?: InputMaybe<LookerRepositoryNodesHavingAverageInput>;
  distinctCount?: InputMaybe<LookerRepositoryNodesHavingDistinctCountInput>;
  max?: InputMaybe<LookerRepositoryNodesHavingMaxInput>;
  min?: InputMaybe<LookerRepositoryNodesHavingMinInput>;
  stddevPopulation?: InputMaybe<LookerRepositoryNodesHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<LookerRepositoryNodesHavingStddevSampleInput>;
  sum?: InputMaybe<LookerRepositoryNodesHavingSumInput>;
  variancePopulation?: InputMaybe<LookerRepositoryNodesHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<LookerRepositoryNodesHavingVarianceSampleInput>;
};

export type LookerRepositoryNodesHavingMaxInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lookerRepositorySnapshotId?: InputMaybe<HavingIntFilter>;
};

export type LookerRepositoryNodesHavingMinInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lookerRepositorySnapshotId?: InputMaybe<HavingIntFilter>;
};

export type LookerRepositoryNodesHavingStddevPopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lookerRepositorySnapshotId?: InputMaybe<HavingIntFilter>;
};

export type LookerRepositoryNodesHavingStddevSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lookerRepositorySnapshotId?: InputMaybe<HavingIntFilter>;
};

export type LookerRepositoryNodesHavingSumInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lookerRepositorySnapshotId?: InputMaybe<HavingIntFilter>;
};

export type LookerRepositoryNodesHavingVariancePopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lookerRepositorySnapshotId?: InputMaybe<HavingIntFilter>;
};

export type LookerRepositoryNodesHavingVarianceSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  lookerRepositorySnapshotId?: InputMaybe<HavingIntFilter>;
};

/** Methods to use when ordering `LookerRepositoryNode`. */
export enum LookerRepositoryNodesOrderBy {
  AliasesAsc = 'ALIASES_ASC',
  AliasesDesc = 'ALIASES_DESC',
  AttributesAsc = 'ATTRIBUTES_ASC',
  AttributesDesc = 'ATTRIBUTES_DESC',
  CreatedDttmAsc = 'CREATED_DTTM_ASC',
  CreatedDttmDesc = 'CREATED_DTTM_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LookerNameAsc = 'LOOKER_NAME_ASC',
  LookerNameDesc = 'LOOKER_NAME_DESC',
  LookerRepositoryIdAsc = 'LOOKER_REPOSITORY_ID_ASC',
  LookerRepositoryIdDesc = 'LOOKER_REPOSITORY_ID_DESC',
  LookerRepositorySnapshotIdAsc = 'LOOKER_REPOSITORY_SNAPSHOT_ID_ASC',
  LookerRepositorySnapshotIdDesc = 'LOOKER_REPOSITORY_SNAPSHOT_ID_DESC',
  ModelNameAsc = 'MODEL_NAME_ASC',
  ModelNameDesc = 'MODEL_NAME_DESC',
  Natural = 'NATURAL',
  NodeTypeAsc = 'NODE_TYPE_ASC',
  NodeTypeDesc = 'NODE_TYPE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ReferencesAsc = 'REFERENCES_ASC',
  ReferencesDesc = 'REFERENCES_DESC',
  TagsAsc = 'TAGS_ASC',
  TagsDesc = 'TAGS_DESC'
}

export type LookerRepositorySnapshot = Node & {
  __typename?: 'LookerRepositorySnapshot';
  commitHash: Scalars['String'];
  createdDttm: Scalars['Datetime'];
  id: Scalars['Int'];
  /** Reads and enables pagination through a set of `LookerRepositoryNode`. */
  lookerRepositoryNodes: LookerRepositoryNodesConnection;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  workspaceId: Scalars['Int'];
};


export type LookerRepositorySnapshotLookerRepositoryNodesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LookerRepositoryNodeCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LookerRepositoryNodesOrderBy>>;
};

export type LookerRepositorySnapshotAggregates = {
  __typename?: 'LookerRepositorySnapshotAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<LookerRepositorySnapshotAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LookerRepositorySnapshotDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<LookerRepositorySnapshotMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<LookerRepositorySnapshotMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<LookerRepositorySnapshotStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<LookerRepositorySnapshotStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<LookerRepositorySnapshotSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<LookerRepositorySnapshotVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<LookerRepositorySnapshotVarianceSampleAggregates>;
};

export type LookerRepositorySnapshotAverageAggregates = {
  __typename?: 'LookerRepositorySnapshotAverageAggregates';
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `LookerRepositorySnapshot` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type LookerRepositorySnapshotCondition = {
  /** Checks for equality with the object’s `commitHash` field. */
  commitHash?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdDttm` field. */
  createdDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `workspaceId` field. */
  workspaceId?: InputMaybe<Scalars['Int']>;
};

export type LookerRepositorySnapshotDistinctCountAggregates = {
  __typename?: 'LookerRepositorySnapshotDistinctCountAggregates';
  /** Distinct count of commitHash across the matching connection */
  commitHash?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdDttm across the matching connection */
  createdDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigInt']>;
};

/** Grouping methods for `LookerRepositorySnapshot` for usage during aggregation. */
export enum LookerRepositorySnapshotGroupBy {
  CommitHash = 'COMMIT_HASH',
  CreatedDttm = 'CREATED_DTTM',
  CreatedDttmTruncatedToDay = 'CREATED_DTTM_TRUNCATED_TO_DAY',
  CreatedDttmTruncatedToHour = 'CREATED_DTTM_TRUNCATED_TO_HOUR',
  WorkspaceId = 'WORKSPACE_ID'
}

export type LookerRepositorySnapshotHavingAverageInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type LookerRepositorySnapshotHavingDistinctCountInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `LookerRepositorySnapshot` aggregates. */
export type LookerRepositorySnapshotHavingInput = {
  AND?: InputMaybe<Array<LookerRepositorySnapshotHavingInput>>;
  OR?: InputMaybe<Array<LookerRepositorySnapshotHavingInput>>;
  average?: InputMaybe<LookerRepositorySnapshotHavingAverageInput>;
  distinctCount?: InputMaybe<LookerRepositorySnapshotHavingDistinctCountInput>;
  max?: InputMaybe<LookerRepositorySnapshotHavingMaxInput>;
  min?: InputMaybe<LookerRepositorySnapshotHavingMinInput>;
  stddevPopulation?: InputMaybe<LookerRepositorySnapshotHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<LookerRepositorySnapshotHavingStddevSampleInput>;
  sum?: InputMaybe<LookerRepositorySnapshotHavingSumInput>;
  variancePopulation?: InputMaybe<LookerRepositorySnapshotHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<LookerRepositorySnapshotHavingVarianceSampleInput>;
};

export type LookerRepositorySnapshotHavingMaxInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type LookerRepositorySnapshotHavingMinInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type LookerRepositorySnapshotHavingStddevPopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type LookerRepositorySnapshotHavingStddevSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type LookerRepositorySnapshotHavingSumInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type LookerRepositorySnapshotHavingVariancePopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type LookerRepositorySnapshotHavingVarianceSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type LookerRepositorySnapshotMaxAggregates = {
  __typename?: 'LookerRepositorySnapshotMaxAggregates';
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['Int']>;
};

export type LookerRepositorySnapshotMinAggregates = {
  __typename?: 'LookerRepositorySnapshotMinAggregates';
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['Int']>;
};

export type LookerRepositorySnapshotStddevPopulationAggregates = {
  __typename?: 'LookerRepositorySnapshotStddevPopulationAggregates';
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type LookerRepositorySnapshotStddevSampleAggregates = {
  __typename?: 'LookerRepositorySnapshotStddevSampleAggregates';
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type LookerRepositorySnapshotSumAggregates = {
  __typename?: 'LookerRepositorySnapshotSumAggregates';
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of workspaceId across the matching connection */
  workspaceId: Scalars['BigInt'];
};

export type LookerRepositorySnapshotVariancePopulationAggregates = {
  __typename?: 'LookerRepositorySnapshotVariancePopulationAggregates';
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type LookerRepositorySnapshotVarianceSampleAggregates = {
  __typename?: 'LookerRepositorySnapshotVarianceSampleAggregates';
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `LookerRepositorySnapshot` values. */
export type LookerRepositorySnapshotsConnection = {
  __typename?: 'LookerRepositorySnapshotsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LookerRepositorySnapshotAggregates>;
  /** A list of edges which contains the `LookerRepositorySnapshot` and cursor to aid in pagination. */
  edges: Array<LookerRepositorySnapshotsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LookerRepositorySnapshotAggregates>>;
  /** A list of `LookerRepositorySnapshot` objects. */
  nodes: Array<LookerRepositorySnapshot>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LookerRepositorySnapshot` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `LookerRepositorySnapshot` values. */
export type LookerRepositorySnapshotsConnectionGroupedAggregatesArgs = {
  groupBy: Array<LookerRepositorySnapshotGroupBy>;
  having?: InputMaybe<LookerRepositorySnapshotHavingInput>;
};

/** A `LookerRepositorySnapshot` edge in the connection. */
export type LookerRepositorySnapshotsEdge = {
  __typename?: 'LookerRepositorySnapshotsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LookerRepositorySnapshot` at the end of the edge. */
  node: LookerRepositorySnapshot;
};

/** Methods to use when ordering `LookerRepositorySnapshot`. */
export enum LookerRepositorySnapshotsOrderBy {
  CommitHashAsc = 'COMMIT_HASH_ASC',
  CommitHashDesc = 'COMMIT_HASH_DESC',
  CreatedDttmAsc = 'CREATED_DTTM_ASC',
  CreatedDttmDesc = 'CREATED_DTTM_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LookerRepositoryNodesAverageAliasesAsc = 'LOOKER_REPOSITORY_NODES_AVERAGE_ALIASES_ASC',
  LookerRepositoryNodesAverageAliasesDesc = 'LOOKER_REPOSITORY_NODES_AVERAGE_ALIASES_DESC',
  LookerRepositoryNodesAverageAttributesAsc = 'LOOKER_REPOSITORY_NODES_AVERAGE_ATTRIBUTES_ASC',
  LookerRepositoryNodesAverageAttributesDesc = 'LOOKER_REPOSITORY_NODES_AVERAGE_ATTRIBUTES_DESC',
  LookerRepositoryNodesAverageCreatedDttmAsc = 'LOOKER_REPOSITORY_NODES_AVERAGE_CREATED_DTTM_ASC',
  LookerRepositoryNodesAverageCreatedDttmDesc = 'LOOKER_REPOSITORY_NODES_AVERAGE_CREATED_DTTM_DESC',
  LookerRepositoryNodesAverageIdAsc = 'LOOKER_REPOSITORY_NODES_AVERAGE_ID_ASC',
  LookerRepositoryNodesAverageIdDesc = 'LOOKER_REPOSITORY_NODES_AVERAGE_ID_DESC',
  LookerRepositoryNodesAverageLookerNameAsc = 'LOOKER_REPOSITORY_NODES_AVERAGE_LOOKER_NAME_ASC',
  LookerRepositoryNodesAverageLookerNameDesc = 'LOOKER_REPOSITORY_NODES_AVERAGE_LOOKER_NAME_DESC',
  LookerRepositoryNodesAverageLookerRepositoryIdAsc = 'LOOKER_REPOSITORY_NODES_AVERAGE_LOOKER_REPOSITORY_ID_ASC',
  LookerRepositoryNodesAverageLookerRepositoryIdDesc = 'LOOKER_REPOSITORY_NODES_AVERAGE_LOOKER_REPOSITORY_ID_DESC',
  LookerRepositoryNodesAverageLookerRepositorySnapshotIdAsc = 'LOOKER_REPOSITORY_NODES_AVERAGE_LOOKER_REPOSITORY_SNAPSHOT_ID_ASC',
  LookerRepositoryNodesAverageLookerRepositorySnapshotIdDesc = 'LOOKER_REPOSITORY_NODES_AVERAGE_LOOKER_REPOSITORY_SNAPSHOT_ID_DESC',
  LookerRepositoryNodesAverageModelNameAsc = 'LOOKER_REPOSITORY_NODES_AVERAGE_MODEL_NAME_ASC',
  LookerRepositoryNodesAverageModelNameDesc = 'LOOKER_REPOSITORY_NODES_AVERAGE_MODEL_NAME_DESC',
  LookerRepositoryNodesAverageNodeTypeAsc = 'LOOKER_REPOSITORY_NODES_AVERAGE_NODE_TYPE_ASC',
  LookerRepositoryNodesAverageNodeTypeDesc = 'LOOKER_REPOSITORY_NODES_AVERAGE_NODE_TYPE_DESC',
  LookerRepositoryNodesAverageReferencesAsc = 'LOOKER_REPOSITORY_NODES_AVERAGE_REFERENCES_ASC',
  LookerRepositoryNodesAverageReferencesDesc = 'LOOKER_REPOSITORY_NODES_AVERAGE_REFERENCES_DESC',
  LookerRepositoryNodesAverageTagsAsc = 'LOOKER_REPOSITORY_NODES_AVERAGE_TAGS_ASC',
  LookerRepositoryNodesAverageTagsDesc = 'LOOKER_REPOSITORY_NODES_AVERAGE_TAGS_DESC',
  LookerRepositoryNodesCountAsc = 'LOOKER_REPOSITORY_NODES_COUNT_ASC',
  LookerRepositoryNodesCountDesc = 'LOOKER_REPOSITORY_NODES_COUNT_DESC',
  LookerRepositoryNodesDistinctCountAliasesAsc = 'LOOKER_REPOSITORY_NODES_DISTINCT_COUNT_ALIASES_ASC',
  LookerRepositoryNodesDistinctCountAliasesDesc = 'LOOKER_REPOSITORY_NODES_DISTINCT_COUNT_ALIASES_DESC',
  LookerRepositoryNodesDistinctCountAttributesAsc = 'LOOKER_REPOSITORY_NODES_DISTINCT_COUNT_ATTRIBUTES_ASC',
  LookerRepositoryNodesDistinctCountAttributesDesc = 'LOOKER_REPOSITORY_NODES_DISTINCT_COUNT_ATTRIBUTES_DESC',
  LookerRepositoryNodesDistinctCountCreatedDttmAsc = 'LOOKER_REPOSITORY_NODES_DISTINCT_COUNT_CREATED_DTTM_ASC',
  LookerRepositoryNodesDistinctCountCreatedDttmDesc = 'LOOKER_REPOSITORY_NODES_DISTINCT_COUNT_CREATED_DTTM_DESC',
  LookerRepositoryNodesDistinctCountIdAsc = 'LOOKER_REPOSITORY_NODES_DISTINCT_COUNT_ID_ASC',
  LookerRepositoryNodesDistinctCountIdDesc = 'LOOKER_REPOSITORY_NODES_DISTINCT_COUNT_ID_DESC',
  LookerRepositoryNodesDistinctCountLookerNameAsc = 'LOOKER_REPOSITORY_NODES_DISTINCT_COUNT_LOOKER_NAME_ASC',
  LookerRepositoryNodesDistinctCountLookerNameDesc = 'LOOKER_REPOSITORY_NODES_DISTINCT_COUNT_LOOKER_NAME_DESC',
  LookerRepositoryNodesDistinctCountLookerRepositoryIdAsc = 'LOOKER_REPOSITORY_NODES_DISTINCT_COUNT_LOOKER_REPOSITORY_ID_ASC',
  LookerRepositoryNodesDistinctCountLookerRepositoryIdDesc = 'LOOKER_REPOSITORY_NODES_DISTINCT_COUNT_LOOKER_REPOSITORY_ID_DESC',
  LookerRepositoryNodesDistinctCountLookerRepositorySnapshotIdAsc = 'LOOKER_REPOSITORY_NODES_DISTINCT_COUNT_LOOKER_REPOSITORY_SNAPSHOT_ID_ASC',
  LookerRepositoryNodesDistinctCountLookerRepositorySnapshotIdDesc = 'LOOKER_REPOSITORY_NODES_DISTINCT_COUNT_LOOKER_REPOSITORY_SNAPSHOT_ID_DESC',
  LookerRepositoryNodesDistinctCountModelNameAsc = 'LOOKER_REPOSITORY_NODES_DISTINCT_COUNT_MODEL_NAME_ASC',
  LookerRepositoryNodesDistinctCountModelNameDesc = 'LOOKER_REPOSITORY_NODES_DISTINCT_COUNT_MODEL_NAME_DESC',
  LookerRepositoryNodesDistinctCountNodeTypeAsc = 'LOOKER_REPOSITORY_NODES_DISTINCT_COUNT_NODE_TYPE_ASC',
  LookerRepositoryNodesDistinctCountNodeTypeDesc = 'LOOKER_REPOSITORY_NODES_DISTINCT_COUNT_NODE_TYPE_DESC',
  LookerRepositoryNodesDistinctCountReferencesAsc = 'LOOKER_REPOSITORY_NODES_DISTINCT_COUNT_REFERENCES_ASC',
  LookerRepositoryNodesDistinctCountReferencesDesc = 'LOOKER_REPOSITORY_NODES_DISTINCT_COUNT_REFERENCES_DESC',
  LookerRepositoryNodesDistinctCountTagsAsc = 'LOOKER_REPOSITORY_NODES_DISTINCT_COUNT_TAGS_ASC',
  LookerRepositoryNodesDistinctCountTagsDesc = 'LOOKER_REPOSITORY_NODES_DISTINCT_COUNT_TAGS_DESC',
  LookerRepositoryNodesMaxAliasesAsc = 'LOOKER_REPOSITORY_NODES_MAX_ALIASES_ASC',
  LookerRepositoryNodesMaxAliasesDesc = 'LOOKER_REPOSITORY_NODES_MAX_ALIASES_DESC',
  LookerRepositoryNodesMaxAttributesAsc = 'LOOKER_REPOSITORY_NODES_MAX_ATTRIBUTES_ASC',
  LookerRepositoryNodesMaxAttributesDesc = 'LOOKER_REPOSITORY_NODES_MAX_ATTRIBUTES_DESC',
  LookerRepositoryNodesMaxCreatedDttmAsc = 'LOOKER_REPOSITORY_NODES_MAX_CREATED_DTTM_ASC',
  LookerRepositoryNodesMaxCreatedDttmDesc = 'LOOKER_REPOSITORY_NODES_MAX_CREATED_DTTM_DESC',
  LookerRepositoryNodesMaxIdAsc = 'LOOKER_REPOSITORY_NODES_MAX_ID_ASC',
  LookerRepositoryNodesMaxIdDesc = 'LOOKER_REPOSITORY_NODES_MAX_ID_DESC',
  LookerRepositoryNodesMaxLookerNameAsc = 'LOOKER_REPOSITORY_NODES_MAX_LOOKER_NAME_ASC',
  LookerRepositoryNodesMaxLookerNameDesc = 'LOOKER_REPOSITORY_NODES_MAX_LOOKER_NAME_DESC',
  LookerRepositoryNodesMaxLookerRepositoryIdAsc = 'LOOKER_REPOSITORY_NODES_MAX_LOOKER_REPOSITORY_ID_ASC',
  LookerRepositoryNodesMaxLookerRepositoryIdDesc = 'LOOKER_REPOSITORY_NODES_MAX_LOOKER_REPOSITORY_ID_DESC',
  LookerRepositoryNodesMaxLookerRepositorySnapshotIdAsc = 'LOOKER_REPOSITORY_NODES_MAX_LOOKER_REPOSITORY_SNAPSHOT_ID_ASC',
  LookerRepositoryNodesMaxLookerRepositorySnapshotIdDesc = 'LOOKER_REPOSITORY_NODES_MAX_LOOKER_REPOSITORY_SNAPSHOT_ID_DESC',
  LookerRepositoryNodesMaxModelNameAsc = 'LOOKER_REPOSITORY_NODES_MAX_MODEL_NAME_ASC',
  LookerRepositoryNodesMaxModelNameDesc = 'LOOKER_REPOSITORY_NODES_MAX_MODEL_NAME_DESC',
  LookerRepositoryNodesMaxNodeTypeAsc = 'LOOKER_REPOSITORY_NODES_MAX_NODE_TYPE_ASC',
  LookerRepositoryNodesMaxNodeTypeDesc = 'LOOKER_REPOSITORY_NODES_MAX_NODE_TYPE_DESC',
  LookerRepositoryNodesMaxReferencesAsc = 'LOOKER_REPOSITORY_NODES_MAX_REFERENCES_ASC',
  LookerRepositoryNodesMaxReferencesDesc = 'LOOKER_REPOSITORY_NODES_MAX_REFERENCES_DESC',
  LookerRepositoryNodesMaxTagsAsc = 'LOOKER_REPOSITORY_NODES_MAX_TAGS_ASC',
  LookerRepositoryNodesMaxTagsDesc = 'LOOKER_REPOSITORY_NODES_MAX_TAGS_DESC',
  LookerRepositoryNodesMinAliasesAsc = 'LOOKER_REPOSITORY_NODES_MIN_ALIASES_ASC',
  LookerRepositoryNodesMinAliasesDesc = 'LOOKER_REPOSITORY_NODES_MIN_ALIASES_DESC',
  LookerRepositoryNodesMinAttributesAsc = 'LOOKER_REPOSITORY_NODES_MIN_ATTRIBUTES_ASC',
  LookerRepositoryNodesMinAttributesDesc = 'LOOKER_REPOSITORY_NODES_MIN_ATTRIBUTES_DESC',
  LookerRepositoryNodesMinCreatedDttmAsc = 'LOOKER_REPOSITORY_NODES_MIN_CREATED_DTTM_ASC',
  LookerRepositoryNodesMinCreatedDttmDesc = 'LOOKER_REPOSITORY_NODES_MIN_CREATED_DTTM_DESC',
  LookerRepositoryNodesMinIdAsc = 'LOOKER_REPOSITORY_NODES_MIN_ID_ASC',
  LookerRepositoryNodesMinIdDesc = 'LOOKER_REPOSITORY_NODES_MIN_ID_DESC',
  LookerRepositoryNodesMinLookerNameAsc = 'LOOKER_REPOSITORY_NODES_MIN_LOOKER_NAME_ASC',
  LookerRepositoryNodesMinLookerNameDesc = 'LOOKER_REPOSITORY_NODES_MIN_LOOKER_NAME_DESC',
  LookerRepositoryNodesMinLookerRepositoryIdAsc = 'LOOKER_REPOSITORY_NODES_MIN_LOOKER_REPOSITORY_ID_ASC',
  LookerRepositoryNodesMinLookerRepositoryIdDesc = 'LOOKER_REPOSITORY_NODES_MIN_LOOKER_REPOSITORY_ID_DESC',
  LookerRepositoryNodesMinLookerRepositorySnapshotIdAsc = 'LOOKER_REPOSITORY_NODES_MIN_LOOKER_REPOSITORY_SNAPSHOT_ID_ASC',
  LookerRepositoryNodesMinLookerRepositorySnapshotIdDesc = 'LOOKER_REPOSITORY_NODES_MIN_LOOKER_REPOSITORY_SNAPSHOT_ID_DESC',
  LookerRepositoryNodesMinModelNameAsc = 'LOOKER_REPOSITORY_NODES_MIN_MODEL_NAME_ASC',
  LookerRepositoryNodesMinModelNameDesc = 'LOOKER_REPOSITORY_NODES_MIN_MODEL_NAME_DESC',
  LookerRepositoryNodesMinNodeTypeAsc = 'LOOKER_REPOSITORY_NODES_MIN_NODE_TYPE_ASC',
  LookerRepositoryNodesMinNodeTypeDesc = 'LOOKER_REPOSITORY_NODES_MIN_NODE_TYPE_DESC',
  LookerRepositoryNodesMinReferencesAsc = 'LOOKER_REPOSITORY_NODES_MIN_REFERENCES_ASC',
  LookerRepositoryNodesMinReferencesDesc = 'LOOKER_REPOSITORY_NODES_MIN_REFERENCES_DESC',
  LookerRepositoryNodesMinTagsAsc = 'LOOKER_REPOSITORY_NODES_MIN_TAGS_ASC',
  LookerRepositoryNodesMinTagsDesc = 'LOOKER_REPOSITORY_NODES_MIN_TAGS_DESC',
  LookerRepositoryNodesStddevPopulationAliasesAsc = 'LOOKER_REPOSITORY_NODES_STDDEV_POPULATION_ALIASES_ASC',
  LookerRepositoryNodesStddevPopulationAliasesDesc = 'LOOKER_REPOSITORY_NODES_STDDEV_POPULATION_ALIASES_DESC',
  LookerRepositoryNodesStddevPopulationAttributesAsc = 'LOOKER_REPOSITORY_NODES_STDDEV_POPULATION_ATTRIBUTES_ASC',
  LookerRepositoryNodesStddevPopulationAttributesDesc = 'LOOKER_REPOSITORY_NODES_STDDEV_POPULATION_ATTRIBUTES_DESC',
  LookerRepositoryNodesStddevPopulationCreatedDttmAsc = 'LOOKER_REPOSITORY_NODES_STDDEV_POPULATION_CREATED_DTTM_ASC',
  LookerRepositoryNodesStddevPopulationCreatedDttmDesc = 'LOOKER_REPOSITORY_NODES_STDDEV_POPULATION_CREATED_DTTM_DESC',
  LookerRepositoryNodesStddevPopulationIdAsc = 'LOOKER_REPOSITORY_NODES_STDDEV_POPULATION_ID_ASC',
  LookerRepositoryNodesStddevPopulationIdDesc = 'LOOKER_REPOSITORY_NODES_STDDEV_POPULATION_ID_DESC',
  LookerRepositoryNodesStddevPopulationLookerNameAsc = 'LOOKER_REPOSITORY_NODES_STDDEV_POPULATION_LOOKER_NAME_ASC',
  LookerRepositoryNodesStddevPopulationLookerNameDesc = 'LOOKER_REPOSITORY_NODES_STDDEV_POPULATION_LOOKER_NAME_DESC',
  LookerRepositoryNodesStddevPopulationLookerRepositoryIdAsc = 'LOOKER_REPOSITORY_NODES_STDDEV_POPULATION_LOOKER_REPOSITORY_ID_ASC',
  LookerRepositoryNodesStddevPopulationLookerRepositoryIdDesc = 'LOOKER_REPOSITORY_NODES_STDDEV_POPULATION_LOOKER_REPOSITORY_ID_DESC',
  LookerRepositoryNodesStddevPopulationLookerRepositorySnapshotIdAsc = 'LOOKER_REPOSITORY_NODES_STDDEV_POPULATION_LOOKER_REPOSITORY_SNAPSHOT_ID_ASC',
  LookerRepositoryNodesStddevPopulationLookerRepositorySnapshotIdDesc = 'LOOKER_REPOSITORY_NODES_STDDEV_POPULATION_LOOKER_REPOSITORY_SNAPSHOT_ID_DESC',
  LookerRepositoryNodesStddevPopulationModelNameAsc = 'LOOKER_REPOSITORY_NODES_STDDEV_POPULATION_MODEL_NAME_ASC',
  LookerRepositoryNodesStddevPopulationModelNameDesc = 'LOOKER_REPOSITORY_NODES_STDDEV_POPULATION_MODEL_NAME_DESC',
  LookerRepositoryNodesStddevPopulationNodeTypeAsc = 'LOOKER_REPOSITORY_NODES_STDDEV_POPULATION_NODE_TYPE_ASC',
  LookerRepositoryNodesStddevPopulationNodeTypeDesc = 'LOOKER_REPOSITORY_NODES_STDDEV_POPULATION_NODE_TYPE_DESC',
  LookerRepositoryNodesStddevPopulationReferencesAsc = 'LOOKER_REPOSITORY_NODES_STDDEV_POPULATION_REFERENCES_ASC',
  LookerRepositoryNodesStddevPopulationReferencesDesc = 'LOOKER_REPOSITORY_NODES_STDDEV_POPULATION_REFERENCES_DESC',
  LookerRepositoryNodesStddevPopulationTagsAsc = 'LOOKER_REPOSITORY_NODES_STDDEV_POPULATION_TAGS_ASC',
  LookerRepositoryNodesStddevPopulationTagsDesc = 'LOOKER_REPOSITORY_NODES_STDDEV_POPULATION_TAGS_DESC',
  LookerRepositoryNodesStddevSampleAliasesAsc = 'LOOKER_REPOSITORY_NODES_STDDEV_SAMPLE_ALIASES_ASC',
  LookerRepositoryNodesStddevSampleAliasesDesc = 'LOOKER_REPOSITORY_NODES_STDDEV_SAMPLE_ALIASES_DESC',
  LookerRepositoryNodesStddevSampleAttributesAsc = 'LOOKER_REPOSITORY_NODES_STDDEV_SAMPLE_ATTRIBUTES_ASC',
  LookerRepositoryNodesStddevSampleAttributesDesc = 'LOOKER_REPOSITORY_NODES_STDDEV_SAMPLE_ATTRIBUTES_DESC',
  LookerRepositoryNodesStddevSampleCreatedDttmAsc = 'LOOKER_REPOSITORY_NODES_STDDEV_SAMPLE_CREATED_DTTM_ASC',
  LookerRepositoryNodesStddevSampleCreatedDttmDesc = 'LOOKER_REPOSITORY_NODES_STDDEV_SAMPLE_CREATED_DTTM_DESC',
  LookerRepositoryNodesStddevSampleIdAsc = 'LOOKER_REPOSITORY_NODES_STDDEV_SAMPLE_ID_ASC',
  LookerRepositoryNodesStddevSampleIdDesc = 'LOOKER_REPOSITORY_NODES_STDDEV_SAMPLE_ID_DESC',
  LookerRepositoryNodesStddevSampleLookerNameAsc = 'LOOKER_REPOSITORY_NODES_STDDEV_SAMPLE_LOOKER_NAME_ASC',
  LookerRepositoryNodesStddevSampleLookerNameDesc = 'LOOKER_REPOSITORY_NODES_STDDEV_SAMPLE_LOOKER_NAME_DESC',
  LookerRepositoryNodesStddevSampleLookerRepositoryIdAsc = 'LOOKER_REPOSITORY_NODES_STDDEV_SAMPLE_LOOKER_REPOSITORY_ID_ASC',
  LookerRepositoryNodesStddevSampleLookerRepositoryIdDesc = 'LOOKER_REPOSITORY_NODES_STDDEV_SAMPLE_LOOKER_REPOSITORY_ID_DESC',
  LookerRepositoryNodesStddevSampleLookerRepositorySnapshotIdAsc = 'LOOKER_REPOSITORY_NODES_STDDEV_SAMPLE_LOOKER_REPOSITORY_SNAPSHOT_ID_ASC',
  LookerRepositoryNodesStddevSampleLookerRepositorySnapshotIdDesc = 'LOOKER_REPOSITORY_NODES_STDDEV_SAMPLE_LOOKER_REPOSITORY_SNAPSHOT_ID_DESC',
  LookerRepositoryNodesStddevSampleModelNameAsc = 'LOOKER_REPOSITORY_NODES_STDDEV_SAMPLE_MODEL_NAME_ASC',
  LookerRepositoryNodesStddevSampleModelNameDesc = 'LOOKER_REPOSITORY_NODES_STDDEV_SAMPLE_MODEL_NAME_DESC',
  LookerRepositoryNodesStddevSampleNodeTypeAsc = 'LOOKER_REPOSITORY_NODES_STDDEV_SAMPLE_NODE_TYPE_ASC',
  LookerRepositoryNodesStddevSampleNodeTypeDesc = 'LOOKER_REPOSITORY_NODES_STDDEV_SAMPLE_NODE_TYPE_DESC',
  LookerRepositoryNodesStddevSampleReferencesAsc = 'LOOKER_REPOSITORY_NODES_STDDEV_SAMPLE_REFERENCES_ASC',
  LookerRepositoryNodesStddevSampleReferencesDesc = 'LOOKER_REPOSITORY_NODES_STDDEV_SAMPLE_REFERENCES_DESC',
  LookerRepositoryNodesStddevSampleTagsAsc = 'LOOKER_REPOSITORY_NODES_STDDEV_SAMPLE_TAGS_ASC',
  LookerRepositoryNodesStddevSampleTagsDesc = 'LOOKER_REPOSITORY_NODES_STDDEV_SAMPLE_TAGS_DESC',
  LookerRepositoryNodesSumAliasesAsc = 'LOOKER_REPOSITORY_NODES_SUM_ALIASES_ASC',
  LookerRepositoryNodesSumAliasesDesc = 'LOOKER_REPOSITORY_NODES_SUM_ALIASES_DESC',
  LookerRepositoryNodesSumAttributesAsc = 'LOOKER_REPOSITORY_NODES_SUM_ATTRIBUTES_ASC',
  LookerRepositoryNodesSumAttributesDesc = 'LOOKER_REPOSITORY_NODES_SUM_ATTRIBUTES_DESC',
  LookerRepositoryNodesSumCreatedDttmAsc = 'LOOKER_REPOSITORY_NODES_SUM_CREATED_DTTM_ASC',
  LookerRepositoryNodesSumCreatedDttmDesc = 'LOOKER_REPOSITORY_NODES_SUM_CREATED_DTTM_DESC',
  LookerRepositoryNodesSumIdAsc = 'LOOKER_REPOSITORY_NODES_SUM_ID_ASC',
  LookerRepositoryNodesSumIdDesc = 'LOOKER_REPOSITORY_NODES_SUM_ID_DESC',
  LookerRepositoryNodesSumLookerNameAsc = 'LOOKER_REPOSITORY_NODES_SUM_LOOKER_NAME_ASC',
  LookerRepositoryNodesSumLookerNameDesc = 'LOOKER_REPOSITORY_NODES_SUM_LOOKER_NAME_DESC',
  LookerRepositoryNodesSumLookerRepositoryIdAsc = 'LOOKER_REPOSITORY_NODES_SUM_LOOKER_REPOSITORY_ID_ASC',
  LookerRepositoryNodesSumLookerRepositoryIdDesc = 'LOOKER_REPOSITORY_NODES_SUM_LOOKER_REPOSITORY_ID_DESC',
  LookerRepositoryNodesSumLookerRepositorySnapshotIdAsc = 'LOOKER_REPOSITORY_NODES_SUM_LOOKER_REPOSITORY_SNAPSHOT_ID_ASC',
  LookerRepositoryNodesSumLookerRepositorySnapshotIdDesc = 'LOOKER_REPOSITORY_NODES_SUM_LOOKER_REPOSITORY_SNAPSHOT_ID_DESC',
  LookerRepositoryNodesSumModelNameAsc = 'LOOKER_REPOSITORY_NODES_SUM_MODEL_NAME_ASC',
  LookerRepositoryNodesSumModelNameDesc = 'LOOKER_REPOSITORY_NODES_SUM_MODEL_NAME_DESC',
  LookerRepositoryNodesSumNodeTypeAsc = 'LOOKER_REPOSITORY_NODES_SUM_NODE_TYPE_ASC',
  LookerRepositoryNodesSumNodeTypeDesc = 'LOOKER_REPOSITORY_NODES_SUM_NODE_TYPE_DESC',
  LookerRepositoryNodesSumReferencesAsc = 'LOOKER_REPOSITORY_NODES_SUM_REFERENCES_ASC',
  LookerRepositoryNodesSumReferencesDesc = 'LOOKER_REPOSITORY_NODES_SUM_REFERENCES_DESC',
  LookerRepositoryNodesSumTagsAsc = 'LOOKER_REPOSITORY_NODES_SUM_TAGS_ASC',
  LookerRepositoryNodesSumTagsDesc = 'LOOKER_REPOSITORY_NODES_SUM_TAGS_DESC',
  LookerRepositoryNodesVariancePopulationAliasesAsc = 'LOOKER_REPOSITORY_NODES_VARIANCE_POPULATION_ALIASES_ASC',
  LookerRepositoryNodesVariancePopulationAliasesDesc = 'LOOKER_REPOSITORY_NODES_VARIANCE_POPULATION_ALIASES_DESC',
  LookerRepositoryNodesVariancePopulationAttributesAsc = 'LOOKER_REPOSITORY_NODES_VARIANCE_POPULATION_ATTRIBUTES_ASC',
  LookerRepositoryNodesVariancePopulationAttributesDesc = 'LOOKER_REPOSITORY_NODES_VARIANCE_POPULATION_ATTRIBUTES_DESC',
  LookerRepositoryNodesVariancePopulationCreatedDttmAsc = 'LOOKER_REPOSITORY_NODES_VARIANCE_POPULATION_CREATED_DTTM_ASC',
  LookerRepositoryNodesVariancePopulationCreatedDttmDesc = 'LOOKER_REPOSITORY_NODES_VARIANCE_POPULATION_CREATED_DTTM_DESC',
  LookerRepositoryNodesVariancePopulationIdAsc = 'LOOKER_REPOSITORY_NODES_VARIANCE_POPULATION_ID_ASC',
  LookerRepositoryNodesVariancePopulationIdDesc = 'LOOKER_REPOSITORY_NODES_VARIANCE_POPULATION_ID_DESC',
  LookerRepositoryNodesVariancePopulationLookerNameAsc = 'LOOKER_REPOSITORY_NODES_VARIANCE_POPULATION_LOOKER_NAME_ASC',
  LookerRepositoryNodesVariancePopulationLookerNameDesc = 'LOOKER_REPOSITORY_NODES_VARIANCE_POPULATION_LOOKER_NAME_DESC',
  LookerRepositoryNodesVariancePopulationLookerRepositoryIdAsc = 'LOOKER_REPOSITORY_NODES_VARIANCE_POPULATION_LOOKER_REPOSITORY_ID_ASC',
  LookerRepositoryNodesVariancePopulationLookerRepositoryIdDesc = 'LOOKER_REPOSITORY_NODES_VARIANCE_POPULATION_LOOKER_REPOSITORY_ID_DESC',
  LookerRepositoryNodesVariancePopulationLookerRepositorySnapshotIdAsc = 'LOOKER_REPOSITORY_NODES_VARIANCE_POPULATION_LOOKER_REPOSITORY_SNAPSHOT_ID_ASC',
  LookerRepositoryNodesVariancePopulationLookerRepositorySnapshotIdDesc = 'LOOKER_REPOSITORY_NODES_VARIANCE_POPULATION_LOOKER_REPOSITORY_SNAPSHOT_ID_DESC',
  LookerRepositoryNodesVariancePopulationModelNameAsc = 'LOOKER_REPOSITORY_NODES_VARIANCE_POPULATION_MODEL_NAME_ASC',
  LookerRepositoryNodesVariancePopulationModelNameDesc = 'LOOKER_REPOSITORY_NODES_VARIANCE_POPULATION_MODEL_NAME_DESC',
  LookerRepositoryNodesVariancePopulationNodeTypeAsc = 'LOOKER_REPOSITORY_NODES_VARIANCE_POPULATION_NODE_TYPE_ASC',
  LookerRepositoryNodesVariancePopulationNodeTypeDesc = 'LOOKER_REPOSITORY_NODES_VARIANCE_POPULATION_NODE_TYPE_DESC',
  LookerRepositoryNodesVariancePopulationReferencesAsc = 'LOOKER_REPOSITORY_NODES_VARIANCE_POPULATION_REFERENCES_ASC',
  LookerRepositoryNodesVariancePopulationReferencesDesc = 'LOOKER_REPOSITORY_NODES_VARIANCE_POPULATION_REFERENCES_DESC',
  LookerRepositoryNodesVariancePopulationTagsAsc = 'LOOKER_REPOSITORY_NODES_VARIANCE_POPULATION_TAGS_ASC',
  LookerRepositoryNodesVariancePopulationTagsDesc = 'LOOKER_REPOSITORY_NODES_VARIANCE_POPULATION_TAGS_DESC',
  LookerRepositoryNodesVarianceSampleAliasesAsc = 'LOOKER_REPOSITORY_NODES_VARIANCE_SAMPLE_ALIASES_ASC',
  LookerRepositoryNodesVarianceSampleAliasesDesc = 'LOOKER_REPOSITORY_NODES_VARIANCE_SAMPLE_ALIASES_DESC',
  LookerRepositoryNodesVarianceSampleAttributesAsc = 'LOOKER_REPOSITORY_NODES_VARIANCE_SAMPLE_ATTRIBUTES_ASC',
  LookerRepositoryNodesVarianceSampleAttributesDesc = 'LOOKER_REPOSITORY_NODES_VARIANCE_SAMPLE_ATTRIBUTES_DESC',
  LookerRepositoryNodesVarianceSampleCreatedDttmAsc = 'LOOKER_REPOSITORY_NODES_VARIANCE_SAMPLE_CREATED_DTTM_ASC',
  LookerRepositoryNodesVarianceSampleCreatedDttmDesc = 'LOOKER_REPOSITORY_NODES_VARIANCE_SAMPLE_CREATED_DTTM_DESC',
  LookerRepositoryNodesVarianceSampleIdAsc = 'LOOKER_REPOSITORY_NODES_VARIANCE_SAMPLE_ID_ASC',
  LookerRepositoryNodesVarianceSampleIdDesc = 'LOOKER_REPOSITORY_NODES_VARIANCE_SAMPLE_ID_DESC',
  LookerRepositoryNodesVarianceSampleLookerNameAsc = 'LOOKER_REPOSITORY_NODES_VARIANCE_SAMPLE_LOOKER_NAME_ASC',
  LookerRepositoryNodesVarianceSampleLookerNameDesc = 'LOOKER_REPOSITORY_NODES_VARIANCE_SAMPLE_LOOKER_NAME_DESC',
  LookerRepositoryNodesVarianceSampleLookerRepositoryIdAsc = 'LOOKER_REPOSITORY_NODES_VARIANCE_SAMPLE_LOOKER_REPOSITORY_ID_ASC',
  LookerRepositoryNodesVarianceSampleLookerRepositoryIdDesc = 'LOOKER_REPOSITORY_NODES_VARIANCE_SAMPLE_LOOKER_REPOSITORY_ID_DESC',
  LookerRepositoryNodesVarianceSampleLookerRepositorySnapshotIdAsc = 'LOOKER_REPOSITORY_NODES_VARIANCE_SAMPLE_LOOKER_REPOSITORY_SNAPSHOT_ID_ASC',
  LookerRepositoryNodesVarianceSampleLookerRepositorySnapshotIdDesc = 'LOOKER_REPOSITORY_NODES_VARIANCE_SAMPLE_LOOKER_REPOSITORY_SNAPSHOT_ID_DESC',
  LookerRepositoryNodesVarianceSampleModelNameAsc = 'LOOKER_REPOSITORY_NODES_VARIANCE_SAMPLE_MODEL_NAME_ASC',
  LookerRepositoryNodesVarianceSampleModelNameDesc = 'LOOKER_REPOSITORY_NODES_VARIANCE_SAMPLE_MODEL_NAME_DESC',
  LookerRepositoryNodesVarianceSampleNodeTypeAsc = 'LOOKER_REPOSITORY_NODES_VARIANCE_SAMPLE_NODE_TYPE_ASC',
  LookerRepositoryNodesVarianceSampleNodeTypeDesc = 'LOOKER_REPOSITORY_NODES_VARIANCE_SAMPLE_NODE_TYPE_DESC',
  LookerRepositoryNodesVarianceSampleReferencesAsc = 'LOOKER_REPOSITORY_NODES_VARIANCE_SAMPLE_REFERENCES_ASC',
  LookerRepositoryNodesVarianceSampleReferencesDesc = 'LOOKER_REPOSITORY_NODES_VARIANCE_SAMPLE_REFERENCES_DESC',
  LookerRepositoryNodesVarianceSampleTagsAsc = 'LOOKER_REPOSITORY_NODES_VARIANCE_SAMPLE_TAGS_ASC',
  LookerRepositoryNodesVarianceSampleTagsDesc = 'LOOKER_REPOSITORY_NODES_VARIANCE_SAMPLE_TAGS_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  WorkspaceIdAsc = 'WORKSPACE_ID_ASC',
  WorkspaceIdDesc = 'WORKSPACE_ID_DESC'
}

export type LookerSnapshot = Node & {
  __typename?: 'LookerSnapshot';
  createdDttm: Scalars['Datetime'];
  id: Scalars['Int'];
  /** Reads and enables pagination through a set of `LookerImportError`. */
  lookerImportErrors: LookerImportErrorsConnection;
  /** Reads and enables pagination through a set of `LookerNode`. */
  lookerNodes: LookerNodesConnection;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  workspaceId: Scalars['Int'];
};


export type LookerSnapshotLookerImportErrorsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LookerImportErrorCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LookerImportErrorsOrderBy>>;
};


export type LookerSnapshotLookerNodesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LookerNodeCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LookerNodesOrderBy>>;
};

export type LookerSnapshotAggregates = {
  __typename?: 'LookerSnapshotAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<LookerSnapshotAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LookerSnapshotDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<LookerSnapshotMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<LookerSnapshotMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<LookerSnapshotStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<LookerSnapshotStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<LookerSnapshotSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<LookerSnapshotVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<LookerSnapshotVarianceSampleAggregates>;
};

export type LookerSnapshotAverageAggregates = {
  __typename?: 'LookerSnapshotAverageAggregates';
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `LookerSnapshot` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LookerSnapshotCondition = {
  /** Checks for equality with the object’s `createdDttm` field. */
  createdDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `workspaceId` field. */
  workspaceId?: InputMaybe<Scalars['Int']>;
};

export type LookerSnapshotDistinctCountAggregates = {
  __typename?: 'LookerSnapshotDistinctCountAggregates';
  /** Distinct count of createdDttm across the matching connection */
  createdDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigInt']>;
};

/** Grouping methods for `LookerSnapshot` for usage during aggregation. */
export enum LookerSnapshotGroupBy {
  CreatedDttm = 'CREATED_DTTM',
  CreatedDttmTruncatedToDay = 'CREATED_DTTM_TRUNCATED_TO_DAY',
  CreatedDttmTruncatedToHour = 'CREATED_DTTM_TRUNCATED_TO_HOUR',
  WorkspaceId = 'WORKSPACE_ID'
}

export type LookerSnapshotHavingAverageInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type LookerSnapshotHavingDistinctCountInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `LookerSnapshot` aggregates. */
export type LookerSnapshotHavingInput = {
  AND?: InputMaybe<Array<LookerSnapshotHavingInput>>;
  OR?: InputMaybe<Array<LookerSnapshotHavingInput>>;
  average?: InputMaybe<LookerSnapshotHavingAverageInput>;
  distinctCount?: InputMaybe<LookerSnapshotHavingDistinctCountInput>;
  max?: InputMaybe<LookerSnapshotHavingMaxInput>;
  min?: InputMaybe<LookerSnapshotHavingMinInput>;
  stddevPopulation?: InputMaybe<LookerSnapshotHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<LookerSnapshotHavingStddevSampleInput>;
  sum?: InputMaybe<LookerSnapshotHavingSumInput>;
  variancePopulation?: InputMaybe<LookerSnapshotHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<LookerSnapshotHavingVarianceSampleInput>;
};

export type LookerSnapshotHavingMaxInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type LookerSnapshotHavingMinInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type LookerSnapshotHavingStddevPopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type LookerSnapshotHavingStddevSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type LookerSnapshotHavingSumInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type LookerSnapshotHavingVariancePopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type LookerSnapshotHavingVarianceSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type LookerSnapshotMaxAggregates = {
  __typename?: 'LookerSnapshotMaxAggregates';
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['Int']>;
};

export type LookerSnapshotMinAggregates = {
  __typename?: 'LookerSnapshotMinAggregates';
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['Int']>;
};

export type LookerSnapshotStddevPopulationAggregates = {
  __typename?: 'LookerSnapshotStddevPopulationAggregates';
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type LookerSnapshotStddevSampleAggregates = {
  __typename?: 'LookerSnapshotStddevSampleAggregates';
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type LookerSnapshotSumAggregates = {
  __typename?: 'LookerSnapshotSumAggregates';
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of workspaceId across the matching connection */
  workspaceId: Scalars['BigInt'];
};

export type LookerSnapshotVariancePopulationAggregates = {
  __typename?: 'LookerSnapshotVariancePopulationAggregates';
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type LookerSnapshotVarianceSampleAggregates = {
  __typename?: 'LookerSnapshotVarianceSampleAggregates';
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `LookerSnapshot` values. */
export type LookerSnapshotsConnection = {
  __typename?: 'LookerSnapshotsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LookerSnapshotAggregates>;
  /** A list of edges which contains the `LookerSnapshot` and cursor to aid in pagination. */
  edges: Array<LookerSnapshotsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LookerSnapshotAggregates>>;
  /** A list of `LookerSnapshot` objects. */
  nodes: Array<LookerSnapshot>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LookerSnapshot` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `LookerSnapshot` values. */
export type LookerSnapshotsConnectionGroupedAggregatesArgs = {
  groupBy: Array<LookerSnapshotGroupBy>;
  having?: InputMaybe<LookerSnapshotHavingInput>;
};

/** A `LookerSnapshot` edge in the connection. */
export type LookerSnapshotsEdge = {
  __typename?: 'LookerSnapshotsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LookerSnapshot` at the end of the edge. */
  node: LookerSnapshot;
};

/** Methods to use when ordering `LookerSnapshot`. */
export enum LookerSnapshotsOrderBy {
  CreatedDttmAsc = 'CREATED_DTTM_ASC',
  CreatedDttmDesc = 'CREATED_DTTM_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LookerImportErrorsAverageCreatedDttmAsc = 'LOOKER_IMPORT_ERRORS_AVERAGE_CREATED_DTTM_ASC',
  LookerImportErrorsAverageCreatedDttmDesc = 'LOOKER_IMPORT_ERRORS_AVERAGE_CREATED_DTTM_DESC',
  LookerImportErrorsAverageDescriptionAsc = 'LOOKER_IMPORT_ERRORS_AVERAGE_DESCRIPTION_ASC',
  LookerImportErrorsAverageDescriptionDesc = 'LOOKER_IMPORT_ERRORS_AVERAGE_DESCRIPTION_DESC',
  LookerImportErrorsAverageErrorTypeAsc = 'LOOKER_IMPORT_ERRORS_AVERAGE_ERROR_TYPE_ASC',
  LookerImportErrorsAverageErrorTypeDesc = 'LOOKER_IMPORT_ERRORS_AVERAGE_ERROR_TYPE_DESC',
  LookerImportErrorsAverageIdAsc = 'LOOKER_IMPORT_ERRORS_AVERAGE_ID_ASC',
  LookerImportErrorsAverageIdDesc = 'LOOKER_IMPORT_ERRORS_AVERAGE_ID_DESC',
  LookerImportErrorsAverageLookerSnapshotIdAsc = 'LOOKER_IMPORT_ERRORS_AVERAGE_LOOKER_SNAPSHOT_ID_ASC',
  LookerImportErrorsAverageLookerSnapshotIdDesc = 'LOOKER_IMPORT_ERRORS_AVERAGE_LOOKER_SNAPSHOT_ID_DESC',
  LookerImportErrorsCountAsc = 'LOOKER_IMPORT_ERRORS_COUNT_ASC',
  LookerImportErrorsCountDesc = 'LOOKER_IMPORT_ERRORS_COUNT_DESC',
  LookerImportErrorsDistinctCountCreatedDttmAsc = 'LOOKER_IMPORT_ERRORS_DISTINCT_COUNT_CREATED_DTTM_ASC',
  LookerImportErrorsDistinctCountCreatedDttmDesc = 'LOOKER_IMPORT_ERRORS_DISTINCT_COUNT_CREATED_DTTM_DESC',
  LookerImportErrorsDistinctCountDescriptionAsc = 'LOOKER_IMPORT_ERRORS_DISTINCT_COUNT_DESCRIPTION_ASC',
  LookerImportErrorsDistinctCountDescriptionDesc = 'LOOKER_IMPORT_ERRORS_DISTINCT_COUNT_DESCRIPTION_DESC',
  LookerImportErrorsDistinctCountErrorTypeAsc = 'LOOKER_IMPORT_ERRORS_DISTINCT_COUNT_ERROR_TYPE_ASC',
  LookerImportErrorsDistinctCountErrorTypeDesc = 'LOOKER_IMPORT_ERRORS_DISTINCT_COUNT_ERROR_TYPE_DESC',
  LookerImportErrorsDistinctCountIdAsc = 'LOOKER_IMPORT_ERRORS_DISTINCT_COUNT_ID_ASC',
  LookerImportErrorsDistinctCountIdDesc = 'LOOKER_IMPORT_ERRORS_DISTINCT_COUNT_ID_DESC',
  LookerImportErrorsDistinctCountLookerSnapshotIdAsc = 'LOOKER_IMPORT_ERRORS_DISTINCT_COUNT_LOOKER_SNAPSHOT_ID_ASC',
  LookerImportErrorsDistinctCountLookerSnapshotIdDesc = 'LOOKER_IMPORT_ERRORS_DISTINCT_COUNT_LOOKER_SNAPSHOT_ID_DESC',
  LookerImportErrorsMaxCreatedDttmAsc = 'LOOKER_IMPORT_ERRORS_MAX_CREATED_DTTM_ASC',
  LookerImportErrorsMaxCreatedDttmDesc = 'LOOKER_IMPORT_ERRORS_MAX_CREATED_DTTM_DESC',
  LookerImportErrorsMaxDescriptionAsc = 'LOOKER_IMPORT_ERRORS_MAX_DESCRIPTION_ASC',
  LookerImportErrorsMaxDescriptionDesc = 'LOOKER_IMPORT_ERRORS_MAX_DESCRIPTION_DESC',
  LookerImportErrorsMaxErrorTypeAsc = 'LOOKER_IMPORT_ERRORS_MAX_ERROR_TYPE_ASC',
  LookerImportErrorsMaxErrorTypeDesc = 'LOOKER_IMPORT_ERRORS_MAX_ERROR_TYPE_DESC',
  LookerImportErrorsMaxIdAsc = 'LOOKER_IMPORT_ERRORS_MAX_ID_ASC',
  LookerImportErrorsMaxIdDesc = 'LOOKER_IMPORT_ERRORS_MAX_ID_DESC',
  LookerImportErrorsMaxLookerSnapshotIdAsc = 'LOOKER_IMPORT_ERRORS_MAX_LOOKER_SNAPSHOT_ID_ASC',
  LookerImportErrorsMaxLookerSnapshotIdDesc = 'LOOKER_IMPORT_ERRORS_MAX_LOOKER_SNAPSHOT_ID_DESC',
  LookerImportErrorsMinCreatedDttmAsc = 'LOOKER_IMPORT_ERRORS_MIN_CREATED_DTTM_ASC',
  LookerImportErrorsMinCreatedDttmDesc = 'LOOKER_IMPORT_ERRORS_MIN_CREATED_DTTM_DESC',
  LookerImportErrorsMinDescriptionAsc = 'LOOKER_IMPORT_ERRORS_MIN_DESCRIPTION_ASC',
  LookerImportErrorsMinDescriptionDesc = 'LOOKER_IMPORT_ERRORS_MIN_DESCRIPTION_DESC',
  LookerImportErrorsMinErrorTypeAsc = 'LOOKER_IMPORT_ERRORS_MIN_ERROR_TYPE_ASC',
  LookerImportErrorsMinErrorTypeDesc = 'LOOKER_IMPORT_ERRORS_MIN_ERROR_TYPE_DESC',
  LookerImportErrorsMinIdAsc = 'LOOKER_IMPORT_ERRORS_MIN_ID_ASC',
  LookerImportErrorsMinIdDesc = 'LOOKER_IMPORT_ERRORS_MIN_ID_DESC',
  LookerImportErrorsMinLookerSnapshotIdAsc = 'LOOKER_IMPORT_ERRORS_MIN_LOOKER_SNAPSHOT_ID_ASC',
  LookerImportErrorsMinLookerSnapshotIdDesc = 'LOOKER_IMPORT_ERRORS_MIN_LOOKER_SNAPSHOT_ID_DESC',
  LookerImportErrorsStddevPopulationCreatedDttmAsc = 'LOOKER_IMPORT_ERRORS_STDDEV_POPULATION_CREATED_DTTM_ASC',
  LookerImportErrorsStddevPopulationCreatedDttmDesc = 'LOOKER_IMPORT_ERRORS_STDDEV_POPULATION_CREATED_DTTM_DESC',
  LookerImportErrorsStddevPopulationDescriptionAsc = 'LOOKER_IMPORT_ERRORS_STDDEV_POPULATION_DESCRIPTION_ASC',
  LookerImportErrorsStddevPopulationDescriptionDesc = 'LOOKER_IMPORT_ERRORS_STDDEV_POPULATION_DESCRIPTION_DESC',
  LookerImportErrorsStddevPopulationErrorTypeAsc = 'LOOKER_IMPORT_ERRORS_STDDEV_POPULATION_ERROR_TYPE_ASC',
  LookerImportErrorsStddevPopulationErrorTypeDesc = 'LOOKER_IMPORT_ERRORS_STDDEV_POPULATION_ERROR_TYPE_DESC',
  LookerImportErrorsStddevPopulationIdAsc = 'LOOKER_IMPORT_ERRORS_STDDEV_POPULATION_ID_ASC',
  LookerImportErrorsStddevPopulationIdDesc = 'LOOKER_IMPORT_ERRORS_STDDEV_POPULATION_ID_DESC',
  LookerImportErrorsStddevPopulationLookerSnapshotIdAsc = 'LOOKER_IMPORT_ERRORS_STDDEV_POPULATION_LOOKER_SNAPSHOT_ID_ASC',
  LookerImportErrorsStddevPopulationLookerSnapshotIdDesc = 'LOOKER_IMPORT_ERRORS_STDDEV_POPULATION_LOOKER_SNAPSHOT_ID_DESC',
  LookerImportErrorsStddevSampleCreatedDttmAsc = 'LOOKER_IMPORT_ERRORS_STDDEV_SAMPLE_CREATED_DTTM_ASC',
  LookerImportErrorsStddevSampleCreatedDttmDesc = 'LOOKER_IMPORT_ERRORS_STDDEV_SAMPLE_CREATED_DTTM_DESC',
  LookerImportErrorsStddevSampleDescriptionAsc = 'LOOKER_IMPORT_ERRORS_STDDEV_SAMPLE_DESCRIPTION_ASC',
  LookerImportErrorsStddevSampleDescriptionDesc = 'LOOKER_IMPORT_ERRORS_STDDEV_SAMPLE_DESCRIPTION_DESC',
  LookerImportErrorsStddevSampleErrorTypeAsc = 'LOOKER_IMPORT_ERRORS_STDDEV_SAMPLE_ERROR_TYPE_ASC',
  LookerImportErrorsStddevSampleErrorTypeDesc = 'LOOKER_IMPORT_ERRORS_STDDEV_SAMPLE_ERROR_TYPE_DESC',
  LookerImportErrorsStddevSampleIdAsc = 'LOOKER_IMPORT_ERRORS_STDDEV_SAMPLE_ID_ASC',
  LookerImportErrorsStddevSampleIdDesc = 'LOOKER_IMPORT_ERRORS_STDDEV_SAMPLE_ID_DESC',
  LookerImportErrorsStddevSampleLookerSnapshotIdAsc = 'LOOKER_IMPORT_ERRORS_STDDEV_SAMPLE_LOOKER_SNAPSHOT_ID_ASC',
  LookerImportErrorsStddevSampleLookerSnapshotIdDesc = 'LOOKER_IMPORT_ERRORS_STDDEV_SAMPLE_LOOKER_SNAPSHOT_ID_DESC',
  LookerImportErrorsSumCreatedDttmAsc = 'LOOKER_IMPORT_ERRORS_SUM_CREATED_DTTM_ASC',
  LookerImportErrorsSumCreatedDttmDesc = 'LOOKER_IMPORT_ERRORS_SUM_CREATED_DTTM_DESC',
  LookerImportErrorsSumDescriptionAsc = 'LOOKER_IMPORT_ERRORS_SUM_DESCRIPTION_ASC',
  LookerImportErrorsSumDescriptionDesc = 'LOOKER_IMPORT_ERRORS_SUM_DESCRIPTION_DESC',
  LookerImportErrorsSumErrorTypeAsc = 'LOOKER_IMPORT_ERRORS_SUM_ERROR_TYPE_ASC',
  LookerImportErrorsSumErrorTypeDesc = 'LOOKER_IMPORT_ERRORS_SUM_ERROR_TYPE_DESC',
  LookerImportErrorsSumIdAsc = 'LOOKER_IMPORT_ERRORS_SUM_ID_ASC',
  LookerImportErrorsSumIdDesc = 'LOOKER_IMPORT_ERRORS_SUM_ID_DESC',
  LookerImportErrorsSumLookerSnapshotIdAsc = 'LOOKER_IMPORT_ERRORS_SUM_LOOKER_SNAPSHOT_ID_ASC',
  LookerImportErrorsSumLookerSnapshotIdDesc = 'LOOKER_IMPORT_ERRORS_SUM_LOOKER_SNAPSHOT_ID_DESC',
  LookerImportErrorsVariancePopulationCreatedDttmAsc = 'LOOKER_IMPORT_ERRORS_VARIANCE_POPULATION_CREATED_DTTM_ASC',
  LookerImportErrorsVariancePopulationCreatedDttmDesc = 'LOOKER_IMPORT_ERRORS_VARIANCE_POPULATION_CREATED_DTTM_DESC',
  LookerImportErrorsVariancePopulationDescriptionAsc = 'LOOKER_IMPORT_ERRORS_VARIANCE_POPULATION_DESCRIPTION_ASC',
  LookerImportErrorsVariancePopulationDescriptionDesc = 'LOOKER_IMPORT_ERRORS_VARIANCE_POPULATION_DESCRIPTION_DESC',
  LookerImportErrorsVariancePopulationErrorTypeAsc = 'LOOKER_IMPORT_ERRORS_VARIANCE_POPULATION_ERROR_TYPE_ASC',
  LookerImportErrorsVariancePopulationErrorTypeDesc = 'LOOKER_IMPORT_ERRORS_VARIANCE_POPULATION_ERROR_TYPE_DESC',
  LookerImportErrorsVariancePopulationIdAsc = 'LOOKER_IMPORT_ERRORS_VARIANCE_POPULATION_ID_ASC',
  LookerImportErrorsVariancePopulationIdDesc = 'LOOKER_IMPORT_ERRORS_VARIANCE_POPULATION_ID_DESC',
  LookerImportErrorsVariancePopulationLookerSnapshotIdAsc = 'LOOKER_IMPORT_ERRORS_VARIANCE_POPULATION_LOOKER_SNAPSHOT_ID_ASC',
  LookerImportErrorsVariancePopulationLookerSnapshotIdDesc = 'LOOKER_IMPORT_ERRORS_VARIANCE_POPULATION_LOOKER_SNAPSHOT_ID_DESC',
  LookerImportErrorsVarianceSampleCreatedDttmAsc = 'LOOKER_IMPORT_ERRORS_VARIANCE_SAMPLE_CREATED_DTTM_ASC',
  LookerImportErrorsVarianceSampleCreatedDttmDesc = 'LOOKER_IMPORT_ERRORS_VARIANCE_SAMPLE_CREATED_DTTM_DESC',
  LookerImportErrorsVarianceSampleDescriptionAsc = 'LOOKER_IMPORT_ERRORS_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  LookerImportErrorsVarianceSampleDescriptionDesc = 'LOOKER_IMPORT_ERRORS_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  LookerImportErrorsVarianceSampleErrorTypeAsc = 'LOOKER_IMPORT_ERRORS_VARIANCE_SAMPLE_ERROR_TYPE_ASC',
  LookerImportErrorsVarianceSampleErrorTypeDesc = 'LOOKER_IMPORT_ERRORS_VARIANCE_SAMPLE_ERROR_TYPE_DESC',
  LookerImportErrorsVarianceSampleIdAsc = 'LOOKER_IMPORT_ERRORS_VARIANCE_SAMPLE_ID_ASC',
  LookerImportErrorsVarianceSampleIdDesc = 'LOOKER_IMPORT_ERRORS_VARIANCE_SAMPLE_ID_DESC',
  LookerImportErrorsVarianceSampleLookerSnapshotIdAsc = 'LOOKER_IMPORT_ERRORS_VARIANCE_SAMPLE_LOOKER_SNAPSHOT_ID_ASC',
  LookerImportErrorsVarianceSampleLookerSnapshotIdDesc = 'LOOKER_IMPORT_ERRORS_VARIANCE_SAMPLE_LOOKER_SNAPSHOT_ID_DESC',
  LookerNodesAverageAttributesAsc = 'LOOKER_NODES_AVERAGE_ATTRIBUTES_ASC',
  LookerNodesAverageAttributesDesc = 'LOOKER_NODES_AVERAGE_ATTRIBUTES_DESC',
  LookerNodesAverageCreatedDttmAsc = 'LOOKER_NODES_AVERAGE_CREATED_DTTM_ASC',
  LookerNodesAverageCreatedDttmDesc = 'LOOKER_NODES_AVERAGE_CREATED_DTTM_DESC',
  LookerNodesAverageIdAsc = 'LOOKER_NODES_AVERAGE_ID_ASC',
  LookerNodesAverageIdDesc = 'LOOKER_NODES_AVERAGE_ID_DESC',
  LookerNodesAverageLookerIdAsc = 'LOOKER_NODES_AVERAGE_LOOKER_ID_ASC',
  LookerNodesAverageLookerIdDesc = 'LOOKER_NODES_AVERAGE_LOOKER_ID_DESC',
  LookerNodesAverageLookerSnapshotIdAsc = 'LOOKER_NODES_AVERAGE_LOOKER_SNAPSHOT_ID_ASC',
  LookerNodesAverageLookerSnapshotIdDesc = 'LOOKER_NODES_AVERAGE_LOOKER_SNAPSHOT_ID_DESC',
  LookerNodesAverageModelNameAsc = 'LOOKER_NODES_AVERAGE_MODEL_NAME_ASC',
  LookerNodesAverageModelNameDesc = 'LOOKER_NODES_AVERAGE_MODEL_NAME_DESC',
  LookerNodesAverageNameAsc = 'LOOKER_NODES_AVERAGE_NAME_ASC',
  LookerNodesAverageNameDesc = 'LOOKER_NODES_AVERAGE_NAME_DESC',
  LookerNodesAverageNodeTypeAsc = 'LOOKER_NODES_AVERAGE_NODE_TYPE_ASC',
  LookerNodesAverageNodeTypeDesc = 'LOOKER_NODES_AVERAGE_NODE_TYPE_DESC',
  LookerNodesAverageReferencesAsc = 'LOOKER_NODES_AVERAGE_REFERENCES_ASC',
  LookerNodesAverageReferencesDesc = 'LOOKER_NODES_AVERAGE_REFERENCES_DESC',
  LookerNodesAverageTagsAsc = 'LOOKER_NODES_AVERAGE_TAGS_ASC',
  LookerNodesAverageTagsDesc = 'LOOKER_NODES_AVERAGE_TAGS_DESC',
  LookerNodesCountAsc = 'LOOKER_NODES_COUNT_ASC',
  LookerNodesCountDesc = 'LOOKER_NODES_COUNT_DESC',
  LookerNodesDistinctCountAttributesAsc = 'LOOKER_NODES_DISTINCT_COUNT_ATTRIBUTES_ASC',
  LookerNodesDistinctCountAttributesDesc = 'LOOKER_NODES_DISTINCT_COUNT_ATTRIBUTES_DESC',
  LookerNodesDistinctCountCreatedDttmAsc = 'LOOKER_NODES_DISTINCT_COUNT_CREATED_DTTM_ASC',
  LookerNodesDistinctCountCreatedDttmDesc = 'LOOKER_NODES_DISTINCT_COUNT_CREATED_DTTM_DESC',
  LookerNodesDistinctCountIdAsc = 'LOOKER_NODES_DISTINCT_COUNT_ID_ASC',
  LookerNodesDistinctCountIdDesc = 'LOOKER_NODES_DISTINCT_COUNT_ID_DESC',
  LookerNodesDistinctCountLookerIdAsc = 'LOOKER_NODES_DISTINCT_COUNT_LOOKER_ID_ASC',
  LookerNodesDistinctCountLookerIdDesc = 'LOOKER_NODES_DISTINCT_COUNT_LOOKER_ID_DESC',
  LookerNodesDistinctCountLookerSnapshotIdAsc = 'LOOKER_NODES_DISTINCT_COUNT_LOOKER_SNAPSHOT_ID_ASC',
  LookerNodesDistinctCountLookerSnapshotIdDesc = 'LOOKER_NODES_DISTINCT_COUNT_LOOKER_SNAPSHOT_ID_DESC',
  LookerNodesDistinctCountModelNameAsc = 'LOOKER_NODES_DISTINCT_COUNT_MODEL_NAME_ASC',
  LookerNodesDistinctCountModelNameDesc = 'LOOKER_NODES_DISTINCT_COUNT_MODEL_NAME_DESC',
  LookerNodesDistinctCountNameAsc = 'LOOKER_NODES_DISTINCT_COUNT_NAME_ASC',
  LookerNodesDistinctCountNameDesc = 'LOOKER_NODES_DISTINCT_COUNT_NAME_DESC',
  LookerNodesDistinctCountNodeTypeAsc = 'LOOKER_NODES_DISTINCT_COUNT_NODE_TYPE_ASC',
  LookerNodesDistinctCountNodeTypeDesc = 'LOOKER_NODES_DISTINCT_COUNT_NODE_TYPE_DESC',
  LookerNodesDistinctCountReferencesAsc = 'LOOKER_NODES_DISTINCT_COUNT_REFERENCES_ASC',
  LookerNodesDistinctCountReferencesDesc = 'LOOKER_NODES_DISTINCT_COUNT_REFERENCES_DESC',
  LookerNodesDistinctCountTagsAsc = 'LOOKER_NODES_DISTINCT_COUNT_TAGS_ASC',
  LookerNodesDistinctCountTagsDesc = 'LOOKER_NODES_DISTINCT_COUNT_TAGS_DESC',
  LookerNodesMaxAttributesAsc = 'LOOKER_NODES_MAX_ATTRIBUTES_ASC',
  LookerNodesMaxAttributesDesc = 'LOOKER_NODES_MAX_ATTRIBUTES_DESC',
  LookerNodesMaxCreatedDttmAsc = 'LOOKER_NODES_MAX_CREATED_DTTM_ASC',
  LookerNodesMaxCreatedDttmDesc = 'LOOKER_NODES_MAX_CREATED_DTTM_DESC',
  LookerNodesMaxIdAsc = 'LOOKER_NODES_MAX_ID_ASC',
  LookerNodesMaxIdDesc = 'LOOKER_NODES_MAX_ID_DESC',
  LookerNodesMaxLookerIdAsc = 'LOOKER_NODES_MAX_LOOKER_ID_ASC',
  LookerNodesMaxLookerIdDesc = 'LOOKER_NODES_MAX_LOOKER_ID_DESC',
  LookerNodesMaxLookerSnapshotIdAsc = 'LOOKER_NODES_MAX_LOOKER_SNAPSHOT_ID_ASC',
  LookerNodesMaxLookerSnapshotIdDesc = 'LOOKER_NODES_MAX_LOOKER_SNAPSHOT_ID_DESC',
  LookerNodesMaxModelNameAsc = 'LOOKER_NODES_MAX_MODEL_NAME_ASC',
  LookerNodesMaxModelNameDesc = 'LOOKER_NODES_MAX_MODEL_NAME_DESC',
  LookerNodesMaxNameAsc = 'LOOKER_NODES_MAX_NAME_ASC',
  LookerNodesMaxNameDesc = 'LOOKER_NODES_MAX_NAME_DESC',
  LookerNodesMaxNodeTypeAsc = 'LOOKER_NODES_MAX_NODE_TYPE_ASC',
  LookerNodesMaxNodeTypeDesc = 'LOOKER_NODES_MAX_NODE_TYPE_DESC',
  LookerNodesMaxReferencesAsc = 'LOOKER_NODES_MAX_REFERENCES_ASC',
  LookerNodesMaxReferencesDesc = 'LOOKER_NODES_MAX_REFERENCES_DESC',
  LookerNodesMaxTagsAsc = 'LOOKER_NODES_MAX_TAGS_ASC',
  LookerNodesMaxTagsDesc = 'LOOKER_NODES_MAX_TAGS_DESC',
  LookerNodesMinAttributesAsc = 'LOOKER_NODES_MIN_ATTRIBUTES_ASC',
  LookerNodesMinAttributesDesc = 'LOOKER_NODES_MIN_ATTRIBUTES_DESC',
  LookerNodesMinCreatedDttmAsc = 'LOOKER_NODES_MIN_CREATED_DTTM_ASC',
  LookerNodesMinCreatedDttmDesc = 'LOOKER_NODES_MIN_CREATED_DTTM_DESC',
  LookerNodesMinIdAsc = 'LOOKER_NODES_MIN_ID_ASC',
  LookerNodesMinIdDesc = 'LOOKER_NODES_MIN_ID_DESC',
  LookerNodesMinLookerIdAsc = 'LOOKER_NODES_MIN_LOOKER_ID_ASC',
  LookerNodesMinLookerIdDesc = 'LOOKER_NODES_MIN_LOOKER_ID_DESC',
  LookerNodesMinLookerSnapshotIdAsc = 'LOOKER_NODES_MIN_LOOKER_SNAPSHOT_ID_ASC',
  LookerNodesMinLookerSnapshotIdDesc = 'LOOKER_NODES_MIN_LOOKER_SNAPSHOT_ID_DESC',
  LookerNodesMinModelNameAsc = 'LOOKER_NODES_MIN_MODEL_NAME_ASC',
  LookerNodesMinModelNameDesc = 'LOOKER_NODES_MIN_MODEL_NAME_DESC',
  LookerNodesMinNameAsc = 'LOOKER_NODES_MIN_NAME_ASC',
  LookerNodesMinNameDesc = 'LOOKER_NODES_MIN_NAME_DESC',
  LookerNodesMinNodeTypeAsc = 'LOOKER_NODES_MIN_NODE_TYPE_ASC',
  LookerNodesMinNodeTypeDesc = 'LOOKER_NODES_MIN_NODE_TYPE_DESC',
  LookerNodesMinReferencesAsc = 'LOOKER_NODES_MIN_REFERENCES_ASC',
  LookerNodesMinReferencesDesc = 'LOOKER_NODES_MIN_REFERENCES_DESC',
  LookerNodesMinTagsAsc = 'LOOKER_NODES_MIN_TAGS_ASC',
  LookerNodesMinTagsDesc = 'LOOKER_NODES_MIN_TAGS_DESC',
  LookerNodesStddevPopulationAttributesAsc = 'LOOKER_NODES_STDDEV_POPULATION_ATTRIBUTES_ASC',
  LookerNodesStddevPopulationAttributesDesc = 'LOOKER_NODES_STDDEV_POPULATION_ATTRIBUTES_DESC',
  LookerNodesStddevPopulationCreatedDttmAsc = 'LOOKER_NODES_STDDEV_POPULATION_CREATED_DTTM_ASC',
  LookerNodesStddevPopulationCreatedDttmDesc = 'LOOKER_NODES_STDDEV_POPULATION_CREATED_DTTM_DESC',
  LookerNodesStddevPopulationIdAsc = 'LOOKER_NODES_STDDEV_POPULATION_ID_ASC',
  LookerNodesStddevPopulationIdDesc = 'LOOKER_NODES_STDDEV_POPULATION_ID_DESC',
  LookerNodesStddevPopulationLookerIdAsc = 'LOOKER_NODES_STDDEV_POPULATION_LOOKER_ID_ASC',
  LookerNodesStddevPopulationLookerIdDesc = 'LOOKER_NODES_STDDEV_POPULATION_LOOKER_ID_DESC',
  LookerNodesStddevPopulationLookerSnapshotIdAsc = 'LOOKER_NODES_STDDEV_POPULATION_LOOKER_SNAPSHOT_ID_ASC',
  LookerNodesStddevPopulationLookerSnapshotIdDesc = 'LOOKER_NODES_STDDEV_POPULATION_LOOKER_SNAPSHOT_ID_DESC',
  LookerNodesStddevPopulationModelNameAsc = 'LOOKER_NODES_STDDEV_POPULATION_MODEL_NAME_ASC',
  LookerNodesStddevPopulationModelNameDesc = 'LOOKER_NODES_STDDEV_POPULATION_MODEL_NAME_DESC',
  LookerNodesStddevPopulationNameAsc = 'LOOKER_NODES_STDDEV_POPULATION_NAME_ASC',
  LookerNodesStddevPopulationNameDesc = 'LOOKER_NODES_STDDEV_POPULATION_NAME_DESC',
  LookerNodesStddevPopulationNodeTypeAsc = 'LOOKER_NODES_STDDEV_POPULATION_NODE_TYPE_ASC',
  LookerNodesStddevPopulationNodeTypeDesc = 'LOOKER_NODES_STDDEV_POPULATION_NODE_TYPE_DESC',
  LookerNodesStddevPopulationReferencesAsc = 'LOOKER_NODES_STDDEV_POPULATION_REFERENCES_ASC',
  LookerNodesStddevPopulationReferencesDesc = 'LOOKER_NODES_STDDEV_POPULATION_REFERENCES_DESC',
  LookerNodesStddevPopulationTagsAsc = 'LOOKER_NODES_STDDEV_POPULATION_TAGS_ASC',
  LookerNodesStddevPopulationTagsDesc = 'LOOKER_NODES_STDDEV_POPULATION_TAGS_DESC',
  LookerNodesStddevSampleAttributesAsc = 'LOOKER_NODES_STDDEV_SAMPLE_ATTRIBUTES_ASC',
  LookerNodesStddevSampleAttributesDesc = 'LOOKER_NODES_STDDEV_SAMPLE_ATTRIBUTES_DESC',
  LookerNodesStddevSampleCreatedDttmAsc = 'LOOKER_NODES_STDDEV_SAMPLE_CREATED_DTTM_ASC',
  LookerNodesStddevSampleCreatedDttmDesc = 'LOOKER_NODES_STDDEV_SAMPLE_CREATED_DTTM_DESC',
  LookerNodesStddevSampleIdAsc = 'LOOKER_NODES_STDDEV_SAMPLE_ID_ASC',
  LookerNodesStddevSampleIdDesc = 'LOOKER_NODES_STDDEV_SAMPLE_ID_DESC',
  LookerNodesStddevSampleLookerIdAsc = 'LOOKER_NODES_STDDEV_SAMPLE_LOOKER_ID_ASC',
  LookerNodesStddevSampleLookerIdDesc = 'LOOKER_NODES_STDDEV_SAMPLE_LOOKER_ID_DESC',
  LookerNodesStddevSampleLookerSnapshotIdAsc = 'LOOKER_NODES_STDDEV_SAMPLE_LOOKER_SNAPSHOT_ID_ASC',
  LookerNodesStddevSampleLookerSnapshotIdDesc = 'LOOKER_NODES_STDDEV_SAMPLE_LOOKER_SNAPSHOT_ID_DESC',
  LookerNodesStddevSampleModelNameAsc = 'LOOKER_NODES_STDDEV_SAMPLE_MODEL_NAME_ASC',
  LookerNodesStddevSampleModelNameDesc = 'LOOKER_NODES_STDDEV_SAMPLE_MODEL_NAME_DESC',
  LookerNodesStddevSampleNameAsc = 'LOOKER_NODES_STDDEV_SAMPLE_NAME_ASC',
  LookerNodesStddevSampleNameDesc = 'LOOKER_NODES_STDDEV_SAMPLE_NAME_DESC',
  LookerNodesStddevSampleNodeTypeAsc = 'LOOKER_NODES_STDDEV_SAMPLE_NODE_TYPE_ASC',
  LookerNodesStddevSampleNodeTypeDesc = 'LOOKER_NODES_STDDEV_SAMPLE_NODE_TYPE_DESC',
  LookerNodesStddevSampleReferencesAsc = 'LOOKER_NODES_STDDEV_SAMPLE_REFERENCES_ASC',
  LookerNodesStddevSampleReferencesDesc = 'LOOKER_NODES_STDDEV_SAMPLE_REFERENCES_DESC',
  LookerNodesStddevSampleTagsAsc = 'LOOKER_NODES_STDDEV_SAMPLE_TAGS_ASC',
  LookerNodesStddevSampleTagsDesc = 'LOOKER_NODES_STDDEV_SAMPLE_TAGS_DESC',
  LookerNodesSumAttributesAsc = 'LOOKER_NODES_SUM_ATTRIBUTES_ASC',
  LookerNodesSumAttributesDesc = 'LOOKER_NODES_SUM_ATTRIBUTES_DESC',
  LookerNodesSumCreatedDttmAsc = 'LOOKER_NODES_SUM_CREATED_DTTM_ASC',
  LookerNodesSumCreatedDttmDesc = 'LOOKER_NODES_SUM_CREATED_DTTM_DESC',
  LookerNodesSumIdAsc = 'LOOKER_NODES_SUM_ID_ASC',
  LookerNodesSumIdDesc = 'LOOKER_NODES_SUM_ID_DESC',
  LookerNodesSumLookerIdAsc = 'LOOKER_NODES_SUM_LOOKER_ID_ASC',
  LookerNodesSumLookerIdDesc = 'LOOKER_NODES_SUM_LOOKER_ID_DESC',
  LookerNodesSumLookerSnapshotIdAsc = 'LOOKER_NODES_SUM_LOOKER_SNAPSHOT_ID_ASC',
  LookerNodesSumLookerSnapshotIdDesc = 'LOOKER_NODES_SUM_LOOKER_SNAPSHOT_ID_DESC',
  LookerNodesSumModelNameAsc = 'LOOKER_NODES_SUM_MODEL_NAME_ASC',
  LookerNodesSumModelNameDesc = 'LOOKER_NODES_SUM_MODEL_NAME_DESC',
  LookerNodesSumNameAsc = 'LOOKER_NODES_SUM_NAME_ASC',
  LookerNodesSumNameDesc = 'LOOKER_NODES_SUM_NAME_DESC',
  LookerNodesSumNodeTypeAsc = 'LOOKER_NODES_SUM_NODE_TYPE_ASC',
  LookerNodesSumNodeTypeDesc = 'LOOKER_NODES_SUM_NODE_TYPE_DESC',
  LookerNodesSumReferencesAsc = 'LOOKER_NODES_SUM_REFERENCES_ASC',
  LookerNodesSumReferencesDesc = 'LOOKER_NODES_SUM_REFERENCES_DESC',
  LookerNodesSumTagsAsc = 'LOOKER_NODES_SUM_TAGS_ASC',
  LookerNodesSumTagsDesc = 'LOOKER_NODES_SUM_TAGS_DESC',
  LookerNodesVariancePopulationAttributesAsc = 'LOOKER_NODES_VARIANCE_POPULATION_ATTRIBUTES_ASC',
  LookerNodesVariancePopulationAttributesDesc = 'LOOKER_NODES_VARIANCE_POPULATION_ATTRIBUTES_DESC',
  LookerNodesVariancePopulationCreatedDttmAsc = 'LOOKER_NODES_VARIANCE_POPULATION_CREATED_DTTM_ASC',
  LookerNodesVariancePopulationCreatedDttmDesc = 'LOOKER_NODES_VARIANCE_POPULATION_CREATED_DTTM_DESC',
  LookerNodesVariancePopulationIdAsc = 'LOOKER_NODES_VARIANCE_POPULATION_ID_ASC',
  LookerNodesVariancePopulationIdDesc = 'LOOKER_NODES_VARIANCE_POPULATION_ID_DESC',
  LookerNodesVariancePopulationLookerIdAsc = 'LOOKER_NODES_VARIANCE_POPULATION_LOOKER_ID_ASC',
  LookerNodesVariancePopulationLookerIdDesc = 'LOOKER_NODES_VARIANCE_POPULATION_LOOKER_ID_DESC',
  LookerNodesVariancePopulationLookerSnapshotIdAsc = 'LOOKER_NODES_VARIANCE_POPULATION_LOOKER_SNAPSHOT_ID_ASC',
  LookerNodesVariancePopulationLookerSnapshotIdDesc = 'LOOKER_NODES_VARIANCE_POPULATION_LOOKER_SNAPSHOT_ID_DESC',
  LookerNodesVariancePopulationModelNameAsc = 'LOOKER_NODES_VARIANCE_POPULATION_MODEL_NAME_ASC',
  LookerNodesVariancePopulationModelNameDesc = 'LOOKER_NODES_VARIANCE_POPULATION_MODEL_NAME_DESC',
  LookerNodesVariancePopulationNameAsc = 'LOOKER_NODES_VARIANCE_POPULATION_NAME_ASC',
  LookerNodesVariancePopulationNameDesc = 'LOOKER_NODES_VARIANCE_POPULATION_NAME_DESC',
  LookerNodesVariancePopulationNodeTypeAsc = 'LOOKER_NODES_VARIANCE_POPULATION_NODE_TYPE_ASC',
  LookerNodesVariancePopulationNodeTypeDesc = 'LOOKER_NODES_VARIANCE_POPULATION_NODE_TYPE_DESC',
  LookerNodesVariancePopulationReferencesAsc = 'LOOKER_NODES_VARIANCE_POPULATION_REFERENCES_ASC',
  LookerNodesVariancePopulationReferencesDesc = 'LOOKER_NODES_VARIANCE_POPULATION_REFERENCES_DESC',
  LookerNodesVariancePopulationTagsAsc = 'LOOKER_NODES_VARIANCE_POPULATION_TAGS_ASC',
  LookerNodesVariancePopulationTagsDesc = 'LOOKER_NODES_VARIANCE_POPULATION_TAGS_DESC',
  LookerNodesVarianceSampleAttributesAsc = 'LOOKER_NODES_VARIANCE_SAMPLE_ATTRIBUTES_ASC',
  LookerNodesVarianceSampleAttributesDesc = 'LOOKER_NODES_VARIANCE_SAMPLE_ATTRIBUTES_DESC',
  LookerNodesVarianceSampleCreatedDttmAsc = 'LOOKER_NODES_VARIANCE_SAMPLE_CREATED_DTTM_ASC',
  LookerNodesVarianceSampleCreatedDttmDesc = 'LOOKER_NODES_VARIANCE_SAMPLE_CREATED_DTTM_DESC',
  LookerNodesVarianceSampleIdAsc = 'LOOKER_NODES_VARIANCE_SAMPLE_ID_ASC',
  LookerNodesVarianceSampleIdDesc = 'LOOKER_NODES_VARIANCE_SAMPLE_ID_DESC',
  LookerNodesVarianceSampleLookerIdAsc = 'LOOKER_NODES_VARIANCE_SAMPLE_LOOKER_ID_ASC',
  LookerNodesVarianceSampleLookerIdDesc = 'LOOKER_NODES_VARIANCE_SAMPLE_LOOKER_ID_DESC',
  LookerNodesVarianceSampleLookerSnapshotIdAsc = 'LOOKER_NODES_VARIANCE_SAMPLE_LOOKER_SNAPSHOT_ID_ASC',
  LookerNodesVarianceSampleLookerSnapshotIdDesc = 'LOOKER_NODES_VARIANCE_SAMPLE_LOOKER_SNAPSHOT_ID_DESC',
  LookerNodesVarianceSampleModelNameAsc = 'LOOKER_NODES_VARIANCE_SAMPLE_MODEL_NAME_ASC',
  LookerNodesVarianceSampleModelNameDesc = 'LOOKER_NODES_VARIANCE_SAMPLE_MODEL_NAME_DESC',
  LookerNodesVarianceSampleNameAsc = 'LOOKER_NODES_VARIANCE_SAMPLE_NAME_ASC',
  LookerNodesVarianceSampleNameDesc = 'LOOKER_NODES_VARIANCE_SAMPLE_NAME_DESC',
  LookerNodesVarianceSampleNodeTypeAsc = 'LOOKER_NODES_VARIANCE_SAMPLE_NODE_TYPE_ASC',
  LookerNodesVarianceSampleNodeTypeDesc = 'LOOKER_NODES_VARIANCE_SAMPLE_NODE_TYPE_DESC',
  LookerNodesVarianceSampleReferencesAsc = 'LOOKER_NODES_VARIANCE_SAMPLE_REFERENCES_ASC',
  LookerNodesVarianceSampleReferencesDesc = 'LOOKER_NODES_VARIANCE_SAMPLE_REFERENCES_DESC',
  LookerNodesVarianceSampleTagsAsc = 'LOOKER_NODES_VARIANCE_SAMPLE_TAGS_ASC',
  LookerNodesVarianceSampleTagsDesc = 'LOOKER_NODES_VARIANCE_SAMPLE_TAGS_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  WorkspaceIdAsc = 'WORKSPACE_ID_ASC',
  WorkspaceIdDesc = 'WORKSPACE_ID_DESC'
}

/** An object with a globally unique `ID`. */
export type Node = {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['Cursor']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['Cursor']>;
};

export type ParadimeAppConfig = Node & {
  __typename?: 'ParadimeAppConfig';
  createdDttm: Scalars['Datetime'];
  id: Scalars['Int'];
  key: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  updatedDttm: Scalars['Datetime'];
  value?: Maybe<Scalars['String']>;
  workspaceId: Scalars['Int'];
};

export type ParadimeAppConfigAggregates = {
  __typename?: 'ParadimeAppConfigAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<ParadimeAppConfigAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ParadimeAppConfigDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<ParadimeAppConfigMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<ParadimeAppConfigMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<ParadimeAppConfigStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<ParadimeAppConfigStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<ParadimeAppConfigSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<ParadimeAppConfigVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<ParadimeAppConfigVarianceSampleAggregates>;
};

export type ParadimeAppConfigAverageAggregates = {
  __typename?: 'ParadimeAppConfigAverageAggregates';
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `ParadimeAppConfig` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ParadimeAppConfigCondition = {
  /** Checks for equality with the object’s `createdDttm` field. */
  createdDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `key` field. */
  key?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedDttm` field. */
  updatedDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `value` field. */
  value?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `workspaceId` field. */
  workspaceId?: InputMaybe<Scalars['Int']>;
};

export type ParadimeAppConfigDistinctCountAggregates = {
  __typename?: 'ParadimeAppConfigDistinctCountAggregates';
  /** Distinct count of createdDttm across the matching connection */
  createdDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of key across the matching connection */
  key?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedDttm across the matching connection */
  updatedDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of value across the matching connection */
  value?: Maybe<Scalars['BigInt']>;
  /** Distinct count of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigInt']>;
};

/** Grouping methods for `ParadimeAppConfig` for usage during aggregation. */
export enum ParadimeAppConfigGroupBy {
  CreatedDttm = 'CREATED_DTTM',
  CreatedDttmTruncatedToDay = 'CREATED_DTTM_TRUNCATED_TO_DAY',
  CreatedDttmTruncatedToHour = 'CREATED_DTTM_TRUNCATED_TO_HOUR',
  Key = 'KEY',
  UpdatedDttm = 'UPDATED_DTTM',
  UpdatedDttmTruncatedToDay = 'UPDATED_DTTM_TRUNCATED_TO_DAY',
  UpdatedDttmTruncatedToHour = 'UPDATED_DTTM_TRUNCATED_TO_HOUR',
  Value = 'VALUE',
  WorkspaceId = 'WORKSPACE_ID'
}

export type ParadimeAppConfigHavingAverageInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type ParadimeAppConfigHavingDistinctCountInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `ParadimeAppConfig` aggregates. */
export type ParadimeAppConfigHavingInput = {
  AND?: InputMaybe<Array<ParadimeAppConfigHavingInput>>;
  OR?: InputMaybe<Array<ParadimeAppConfigHavingInput>>;
  average?: InputMaybe<ParadimeAppConfigHavingAverageInput>;
  distinctCount?: InputMaybe<ParadimeAppConfigHavingDistinctCountInput>;
  max?: InputMaybe<ParadimeAppConfigHavingMaxInput>;
  min?: InputMaybe<ParadimeAppConfigHavingMinInput>;
  stddevPopulation?: InputMaybe<ParadimeAppConfigHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<ParadimeAppConfigHavingStddevSampleInput>;
  sum?: InputMaybe<ParadimeAppConfigHavingSumInput>;
  variancePopulation?: InputMaybe<ParadimeAppConfigHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<ParadimeAppConfigHavingVarianceSampleInput>;
};

export type ParadimeAppConfigHavingMaxInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type ParadimeAppConfigHavingMinInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type ParadimeAppConfigHavingStddevPopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type ParadimeAppConfigHavingStddevSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type ParadimeAppConfigHavingSumInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type ParadimeAppConfigHavingVariancePopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type ParadimeAppConfigHavingVarianceSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type ParadimeAppConfigMaxAggregates = {
  __typename?: 'ParadimeAppConfigMaxAggregates';
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['Int']>;
};

export type ParadimeAppConfigMinAggregates = {
  __typename?: 'ParadimeAppConfigMinAggregates';
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['Int']>;
};

export type ParadimeAppConfigStddevPopulationAggregates = {
  __typename?: 'ParadimeAppConfigStddevPopulationAggregates';
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type ParadimeAppConfigStddevSampleAggregates = {
  __typename?: 'ParadimeAppConfigStddevSampleAggregates';
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type ParadimeAppConfigSumAggregates = {
  __typename?: 'ParadimeAppConfigSumAggregates';
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of workspaceId across the matching connection */
  workspaceId: Scalars['BigInt'];
};

export type ParadimeAppConfigVariancePopulationAggregates = {
  __typename?: 'ParadimeAppConfigVariancePopulationAggregates';
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type ParadimeAppConfigVarianceSampleAggregates = {
  __typename?: 'ParadimeAppConfigVarianceSampleAggregates';
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `ParadimeAppConfig` values. */
export type ParadimeAppConfigsConnection = {
  __typename?: 'ParadimeAppConfigsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ParadimeAppConfigAggregates>;
  /** A list of edges which contains the `ParadimeAppConfig` and cursor to aid in pagination. */
  edges: Array<ParadimeAppConfigsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ParadimeAppConfigAggregates>>;
  /** A list of `ParadimeAppConfig` objects. */
  nodes: Array<ParadimeAppConfig>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ParadimeAppConfig` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `ParadimeAppConfig` values. */
export type ParadimeAppConfigsConnectionGroupedAggregatesArgs = {
  groupBy: Array<ParadimeAppConfigGroupBy>;
  having?: InputMaybe<ParadimeAppConfigHavingInput>;
};

/** A `ParadimeAppConfig` edge in the connection. */
export type ParadimeAppConfigsEdge = {
  __typename?: 'ParadimeAppConfigsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ParadimeAppConfig` at the end of the edge. */
  node: ParadimeAppConfig;
};

/** Methods to use when ordering `ParadimeAppConfig`. */
export enum ParadimeAppConfigsOrderBy {
  CreatedDttmAsc = 'CREATED_DTTM_ASC',
  CreatedDttmDesc = 'CREATED_DTTM_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  KeyAsc = 'KEY_ASC',
  KeyDesc = 'KEY_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedDttmAsc = 'UPDATED_DTTM_ASC',
  UpdatedDttmDesc = 'UPDATED_DTTM_DESC',
  ValueAsc = 'VALUE_ASC',
  ValueDesc = 'VALUE_DESC',
  WorkspaceIdAsc = 'WORKSPACE_ID_ASC',
  WorkspaceIdDesc = 'WORKSPACE_ID_DESC'
}

/** The root query type which gives access points into the data universe. */
export type Query = Node & {
  __typename?: 'Query';
  auditLog?: Maybe<AuditLog>;
  /** Reads a single `AuditLog` using its globally unique `ID`. */
  auditLogByNodeId?: Maybe<AuditLog>;
  /** Reads and enables pagination through a set of `AuditLog`. */
  auditLogs?: Maybe<AuditLogsConnection>;
  commitHash?: Maybe<CommitHash>;
  /** Reads a single `CommitHash` using its globally unique `ID`. */
  commitHashByNodeId?: Maybe<CommitHash>;
  commitHashRelation?: Maybe<CommitHashRelation>;
  /** Reads a single `CommitHashRelation` using its globally unique `ID`. */
  commitHashRelationByNodeId?: Maybe<CommitHashRelation>;
  /** Reads and enables pagination through a set of `CommitHashRelation`. */
  commitHashRelations?: Maybe<CommitHashRelationsConnection>;
  /** Reads and enables pagination through a set of `CommitHash`. */
  commitHashes?: Maybe<CommitHashesConnection>;
  dataCatalogueSnapshotV8?: Maybe<DataCatalogueSnapshotV8>;
  /** Reads a single `DataCatalogueSnapshotV8` using its globally unique `ID`. */
  dataCatalogueSnapshotV8ByNodeId?: Maybe<DataCatalogueSnapshotV8>;
  dataCatalogueSnapshotV8ChildItem?: Maybe<DataCatalogueSnapshotV8ChildItem>;
  /** Reads a single `DataCatalogueSnapshotV8ChildItem` using its globally unique `ID`. */
  dataCatalogueSnapshotV8ChildItemByNodeId?: Maybe<DataCatalogueSnapshotV8ChildItem>;
  /** Reads and enables pagination through a set of `DataCatalogueSnapshotV8ChildItem`. */
  dataCatalogueSnapshotV8ChildItems?: Maybe<DataCatalogueSnapshotV8ChildItemsConnection>;
  dataCatalogueSnapshotV8Item?: Maybe<DataCatalogueSnapshotV8Item>;
  /** Reads a single `DataCatalogueSnapshotV8Item` using its globally unique `ID`. */
  dataCatalogueSnapshotV8ItemByNodeId?: Maybe<DataCatalogueSnapshotV8Item>;
  /** Reads and enables pagination through a set of `DataCatalogueSnapshotV8Item`. */
  dataCatalogueSnapshotV8Items?: Maybe<DataCatalogueSnapshotV8ItemsConnection>;
  dataCatalogueSnapshotV8MetaField?: Maybe<DataCatalogueSnapshotV8MetaField>;
  /** Reads a single `DataCatalogueSnapshotV8MetaField` using its globally unique `ID`. */
  dataCatalogueSnapshotV8MetaFieldByNodeId?: Maybe<DataCatalogueSnapshotV8MetaField>;
  /** Reads and enables pagination through a set of `DataCatalogueSnapshotV8MetaField`. */
  dataCatalogueSnapshotV8MetaFields?: Maybe<DataCatalogueSnapshotV8MetaFieldsConnection>;
  dataCatalogueSnapshotV8Tree?: Maybe<DataCatalogueSnapshotV8Tree>;
  /** Reads a single `DataCatalogueSnapshotV8Tree` using its globally unique `ID`. */
  dataCatalogueSnapshotV8TreeByNodeId?: Maybe<DataCatalogueSnapshotV8Tree>;
  /** Reads and enables pagination through a set of `DataCatalogueSnapshotV8Tree`. */
  dataCatalogueSnapshotV8Trees?: Maybe<DataCatalogueSnapshotV8TreesConnection>;
  /** Reads and enables pagination through a set of `DataCatalogueSnapshotV8`. */
  dataCatalogueSnapshotV8s?: Maybe<DataCatalogueSnapshotV8sConnection>;
  dbtArtifactCatalogueColumn?: Maybe<DbtArtifactCatalogueColumn>;
  /** Reads a single `DbtArtifactCatalogueColumn` using its globally unique `ID`. */
  dbtArtifactCatalogueColumnByNodeId?: Maybe<DbtArtifactCatalogueColumn>;
  /** Reads and enables pagination through a set of `DbtArtifactCatalogueColumn`. */
  dbtArtifactCatalogueColumns?: Maybe<DbtArtifactCatalogueColumnsConnection>;
  dbtArtifactCatalogueObject?: Maybe<DbtArtifactCatalogueObject>;
  /** Reads a single `DbtArtifactCatalogueObject` using its globally unique `ID`. */
  dbtArtifactCatalogueObjectByNodeId?: Maybe<DbtArtifactCatalogueObject>;
  /** Reads and enables pagination through a set of `DbtArtifactCatalogueObject`. */
  dbtArtifactCatalogueObjects?: Maybe<DbtArtifactCatalogueObjectsConnection>;
  dbtArtifactError?: Maybe<DbtArtifactError>;
  /** Reads a single `DbtArtifactError` using its globally unique `ID`. */
  dbtArtifactErrorByNodeId?: Maybe<DbtArtifactError>;
  dbtArtifactErrorByUserIdAndWorkspaceId?: Maybe<DbtArtifactError>;
  /** Reads and enables pagination through a set of `DbtArtifactError`. */
  dbtArtifactErrors?: Maybe<DbtArtifactErrorsConnection>;
  dbtNode?: Maybe<DbtNode>;
  /** Reads a single `DbtNode` using its globally unique `ID`. */
  dbtNodeByNodeId?: Maybe<DbtNode>;
  /** Reads and enables pagination through a set of `DbtNode`. */
  dbtNodes?: Maybe<DbtNodesConnection>;
  file?: Maybe<File>;
  /** Reads a single `File` using its globally unique `ID`. */
  fileByNodeId?: Maybe<File>;
  fileContent?: Maybe<FileContent>;
  /** Reads a single `FileContent` using its globally unique `ID`. */
  fileContentByNodeId?: Maybe<FileContent>;
  /** Reads and enables pagination through a set of `FileContent`. */
  fileContents?: Maybe<FileContentsConnection>;
  fileLine?: Maybe<FileLine>;
  /** Reads a single `FileLine` using its globally unique `ID`. */
  fileLineByNodeId?: Maybe<FileLine>;
  /** Reads and enables pagination through a set of `FileLine`. */
  fileLines?: Maybe<FileLinesConnection>;
  /** Reads and enables pagination through a set of `File`. */
  files?: Maybe<FilesConnection>;
  filesRelation?: Maybe<FilesRelation>;
  /** Reads a single `FilesRelation` using its globally unique `ID`. */
  filesRelationByNodeId?: Maybe<FilesRelation>;
  /** Reads and enables pagination through a set of `FilesRelation`. */
  filesRelations?: Maybe<FilesRelationsConnection>;
  fivetranNode?: Maybe<FivetranNode>;
  /** Reads a single `FivetranNode` using its globally unique `ID`. */
  fivetranNodeByNodeId?: Maybe<FivetranNode>;
  /** Reads and enables pagination through a set of `FivetranNode`. */
  fivetranNodes?: Maybe<FivetranNodesConnection>;
  fivetranSnapshot?: Maybe<FivetranSnapshot>;
  /** Reads a single `FivetranSnapshot` using its globally unique `ID`. */
  fivetranSnapshotByNodeId?: Maybe<FivetranSnapshot>;
  /** Reads and enables pagination through a set of `FivetranSnapshot`. */
  fivetranSnapshots?: Maybe<FivetranSnapshotsConnection>;
  gdbImport?: Maybe<GdbImport>;
  /** Reads a single `GdbImport` using its globally unique `ID`. */
  gdbImportByNodeId?: Maybe<GdbImport>;
  /** Reads and enables pagination through a set of `GdbImport`. */
  gdbImports?: Maybe<GdbImportsConnection>;
  hightouchNode?: Maybe<HightouchNode>;
  /** Reads a single `HightouchNode` using its globally unique `ID`. */
  hightouchNodeByNodeId?: Maybe<HightouchNode>;
  /** Reads and enables pagination through a set of `HightouchNode`. */
  hightouchNodes?: Maybe<HightouchNodesConnection>;
  hightouchSnapshot?: Maybe<HightouchSnapshot>;
  /** Reads a single `HightouchSnapshot` using its globally unique `ID`. */
  hightouchSnapshotByNodeId?: Maybe<HightouchSnapshot>;
  /** Reads and enables pagination through a set of `HightouchSnapshot`. */
  hightouchSnapshots?: Maybe<HightouchSnapshotsConnection>;
  informationSchemaSnapshotV2?: Maybe<InformationSchemaSnapshotV2>;
  /** Reads a single `InformationSchemaSnapshotV2` using its globally unique `ID`. */
  informationSchemaSnapshotV2ByNodeId?: Maybe<InformationSchemaSnapshotV2>;
  /** Reads and enables pagination through a set of `InformationSchemaSnapshotV2`. */
  informationSchemaSnapshotV2s?: Maybe<InformationSchemaSnapshotV2sConnection>;
  informationSchemaTableV2?: Maybe<InformationSchemaTableV2>;
  /** Reads a single `InformationSchemaTableV2` using its globally unique `ID`. */
  informationSchemaTableV2ByNodeId?: Maybe<InformationSchemaTableV2>;
  /** Reads and enables pagination through a set of `InformationSchemaTableV2`. */
  informationSchemaTableV2s?: Maybe<InformationSchemaTableV2sConnection>;
  integration?: Maybe<Integration>;
  /** Reads a single `Integration` using its globally unique `ID`. */
  integrationByNodeId?: Maybe<Integration>;
  integrationByWorkspaceIdAndUid?: Maybe<Integration>;
  integrationConfig?: Maybe<IntegrationConfig>;
  integrationConfigByIntegrationIdAndKey?: Maybe<IntegrationConfig>;
  /** Reads a single `IntegrationConfig` using its globally unique `ID`. */
  integrationConfigByNodeId?: Maybe<IntegrationConfig>;
  /** Reads and enables pagination through a set of `IntegrationConfig`. */
  integrationConfigs?: Maybe<IntegrationConfigsConnection>;
  integrationNode?: Maybe<IntegrationNode>;
  /** Reads a single `IntegrationNode` using its globally unique `ID`. */
  integrationNodeByNodeId?: Maybe<IntegrationNode>;
  /** Reads and enables pagination through a set of `IntegrationNode`. */
  integrationNodes?: Maybe<IntegrationNodesConnection>;
  integrationSnapshot?: Maybe<IntegrationSnapshot>;
  /** Reads a single `IntegrationSnapshot` using its globally unique `ID`. */
  integrationSnapshotByNodeId?: Maybe<IntegrationSnapshot>;
  /** Reads and enables pagination through a set of `IntegrationSnapshot`. */
  integrationSnapshots?: Maybe<IntegrationSnapshotsConnection>;
  /** Reads and enables pagination through a set of `Integration`. */
  integrations?: Maybe<IntegrationsConnection>;
  lookerImportError?: Maybe<LookerImportError>;
  /** Reads a single `LookerImportError` using its globally unique `ID`. */
  lookerImportErrorByNodeId?: Maybe<LookerImportError>;
  /** Reads and enables pagination through a set of `LookerImportError`. */
  lookerImportErrors?: Maybe<LookerImportErrorsConnection>;
  lookerNode?: Maybe<LookerNode>;
  /** Reads a single `LookerNode` using its globally unique `ID`. */
  lookerNodeByNodeId?: Maybe<LookerNode>;
  /** Reads and enables pagination through a set of `LookerNode`. */
  lookerNodes?: Maybe<LookerNodesConnection>;
  lookerRepositoryNode?: Maybe<LookerRepositoryNode>;
  /** Reads a single `LookerRepositoryNode` using its globally unique `ID`. */
  lookerRepositoryNodeByNodeId?: Maybe<LookerRepositoryNode>;
  /** Reads and enables pagination through a set of `LookerRepositoryNode`. */
  lookerRepositoryNodes?: Maybe<LookerRepositoryNodesConnection>;
  lookerRepositorySnapshot?: Maybe<LookerRepositorySnapshot>;
  /** Reads a single `LookerRepositorySnapshot` using its globally unique `ID`. */
  lookerRepositorySnapshotByNodeId?: Maybe<LookerRepositorySnapshot>;
  /** Reads and enables pagination through a set of `LookerRepositorySnapshot`. */
  lookerRepositorySnapshots?: Maybe<LookerRepositorySnapshotsConnection>;
  lookerSnapshot?: Maybe<LookerSnapshot>;
  /** Reads a single `LookerSnapshot` using its globally unique `ID`. */
  lookerSnapshotByNodeId?: Maybe<LookerSnapshot>;
  /** Reads and enables pagination through a set of `LookerSnapshot`. */
  lookerSnapshots?: Maybe<LookerSnapshotsConnection>;
  /** Fetches an object given its globally unique `ID`. */
  node?: Maybe<Node>;
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. */
  nodeId: Scalars['ID'];
  paradimeAppConfig?: Maybe<ParadimeAppConfig>;
  /** Reads a single `ParadimeAppConfig` using its globally unique `ID`. */
  paradimeAppConfigByNodeId?: Maybe<ParadimeAppConfig>;
  paradimeAppConfigByWorkspaceIdAndKey?: Maybe<ParadimeAppConfig>;
  /** Reads and enables pagination through a set of `ParadimeAppConfig`. */
  paradimeAppConfigs?: Maybe<ParadimeAppConfigsConnection>;
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query;
  scheduleCommand?: Maybe<ScheduleCommand>;
  /** Reads a single `ScheduleCommand` using its globally unique `ID`. */
  scheduleCommandByNodeId?: Maybe<ScheduleCommand>;
  scheduleCommandResource?: Maybe<ScheduleCommandResource>;
  /** Reads a single `ScheduleCommandResource` using its globally unique `ID`. */
  scheduleCommandResourceByNodeId?: Maybe<ScheduleCommandResource>;
  /** Reads and enables pagination through a set of `ScheduleCommandResource`. */
  scheduleCommandResources?: Maybe<ScheduleCommandResourcesConnection>;
  scheduleCommandSourceFreshness?: Maybe<ScheduleCommandSourceFreshness>;
  /** Reads a single `ScheduleCommandSourceFreshness` using its globally unique `ID`. */
  scheduleCommandSourceFreshnessByNodeId?: Maybe<ScheduleCommandSourceFreshness>;
  /** Reads and enables pagination through a set of `ScheduleCommandSourceFreshness`. */
  scheduleCommandSourceFreshnesses?: Maybe<ScheduleCommandSourceFreshnessesConnection>;
  /** Reads and enables pagination through a set of `ScheduleCommand`. */
  scheduleCommands?: Maybe<ScheduleCommandsConnection>;
  scheduleLog?: Maybe<ScheduleLog>;
  /** Reads a single `ScheduleLog` using its globally unique `ID`. */
  scheduleLogByNodeId?: Maybe<ScheduleLog>;
  /** Reads and enables pagination through a set of `ScheduleLog`. */
  scheduleLogs?: Maybe<ScheduleLogsConnection>;
  scheduleName?: Maybe<ScheduleName>;
  /** Reads a single `ScheduleName` using its globally unique `ID`. */
  scheduleNameByNodeId?: Maybe<ScheduleName>;
  /** Reads and enables pagination through a set of `ScheduleNameEntry`. */
  scheduleNameEntries?: Maybe<ScheduleNameEntriesConnection>;
  scheduleNameEntry?: Maybe<ScheduleNameEntry>;
  /** Reads a single `ScheduleNameEntry` using its globally unique `ID`. */
  scheduleNameEntryByNodeId?: Maybe<ScheduleNameEntry>;
  scheduleNameEntryByWorkspaceIdAndName?: Maybe<ScheduleNameEntry>;
  /** Reads and enables pagination through a set of `ScheduleName`. */
  scheduleNames?: Maybe<ScheduleNamesConnection>;
  scheduleParse?: Maybe<ScheduleParse>;
  /** Reads a single `ScheduleParse` using its globally unique `ID`. */
  scheduleParseByNodeId?: Maybe<ScheduleParse>;
  /** Reads and enables pagination through a set of `ScheduleParse`. */
  scheduleParses?: Maybe<ScheduleParsesConnection>;
  scheduleRun?: Maybe<ScheduleRun>;
  /** Reads a single `ScheduleRun` using its globally unique `ID`. */
  scheduleRunByNodeId?: Maybe<ScheduleRun>;
  scheduleRunCancelled?: Maybe<ScheduleRunCancelled>;
  /** Reads a single `ScheduleRunCancelled` using its globally unique `ID`. */
  scheduleRunCancelledByNodeId?: Maybe<ScheduleRunCancelled>;
  /** Reads and enables pagination through a set of `ScheduleRunCancelled`. */
  scheduleRunCancelleds?: Maybe<ScheduleRunCancelledsConnection>;
  scheduleRunDeferred?: Maybe<ScheduleRunDeferred>;
  /** Reads a single `ScheduleRunDeferred` using its globally unique `ID`. */
  scheduleRunDeferredByNodeId?: Maybe<ScheduleRunDeferred>;
  /** Reads and enables pagination through a set of `ScheduleRunDeferred`. */
  scheduleRunDeferreds?: Maybe<ScheduleRunDeferredsConnection>;
  /** Reads and enables pagination through a set of `ScheduleRun`. */
  scheduleRuns?: Maybe<ScheduleRunsConnection>;
  tableauNode?: Maybe<TableauNode>;
  /** Reads a single `TableauNode` using its globally unique `ID`. */
  tableauNodeByNodeId?: Maybe<TableauNode>;
  /** Reads and enables pagination through a set of `TableauNode`. */
  tableauNodes?: Maybe<TableauNodesConnection>;
  tableauSnapshot?: Maybe<TableauSnapshot>;
  /** Reads a single `TableauSnapshot` using its globally unique `ID`. */
  tableauSnapshotByNodeId?: Maybe<TableauSnapshot>;
  /** Reads and enables pagination through a set of `TableauSnapshot`. */
  tableauSnapshots?: Maybe<TableauSnapshotsConnection>;
  workspace?: Maybe<Workspace>;
  /** Reads a single `Workspace` using its globally unique `ID`. */
  workspaceByNodeId?: Maybe<Workspace>;
  workspaceMembership?: Maybe<WorkspaceMembership>;
  /** Reads a single `WorkspaceMembership` using its globally unique `ID`. */
  workspaceMembershipByNodeId?: Maybe<WorkspaceMembership>;
  workspaceMembershipByUserIdAndWorkspaceId?: Maybe<WorkspaceMembership>;
  /** Reads and enables pagination through a set of `WorkspaceMembership`. */
  workspaceMemberships?: Maybe<WorkspaceMembershipsConnection>;
  /** Reads and enables pagination through a set of `Workspace`. */
  workspaces?: Maybe<WorkspacesConnection>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAuditLogArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAuditLogByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAuditLogsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AuditLogCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AuditLogsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCommitHashArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCommitHashByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCommitHashRelationArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCommitHashRelationByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCommitHashRelationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CommitHashRelationCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CommitHashRelationsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCommitHashesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CommitHashCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CommitHashesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryDataCatalogueSnapshotV8Args = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDataCatalogueSnapshotV8ByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDataCatalogueSnapshotV8ChildItemArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDataCatalogueSnapshotV8ChildItemByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDataCatalogueSnapshotV8ChildItemsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<DataCatalogueSnapshotV8ChildItemCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DataCatalogueSnapshotV8ChildItemsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryDataCatalogueSnapshotV8ItemArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDataCatalogueSnapshotV8ItemByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDataCatalogueSnapshotV8ItemsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<DataCatalogueSnapshotV8ItemCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DataCatalogueSnapshotV8ItemsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryDataCatalogueSnapshotV8MetaFieldArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDataCatalogueSnapshotV8MetaFieldByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDataCatalogueSnapshotV8MetaFieldsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<DataCatalogueSnapshotV8MetaFieldCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DataCatalogueSnapshotV8MetaFieldsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryDataCatalogueSnapshotV8TreeArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDataCatalogueSnapshotV8TreeByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDataCatalogueSnapshotV8TreesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<DataCatalogueSnapshotV8TreeCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DataCatalogueSnapshotV8TreesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryDataCatalogueSnapshotV8sArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<DataCatalogueSnapshotV8Condition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DataCatalogueSnapshotV8sOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryDbtArtifactCatalogueColumnArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDbtArtifactCatalogueColumnByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDbtArtifactCatalogueColumnsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<DbtArtifactCatalogueColumnCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbtArtifactCatalogueColumnsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryDbtArtifactCatalogueObjectArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDbtArtifactCatalogueObjectByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDbtArtifactCatalogueObjectsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<DbtArtifactCatalogueObjectCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbtArtifactCatalogueObjectsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryDbtArtifactErrorArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDbtArtifactErrorByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDbtArtifactErrorByUserIdAndWorkspaceIdArgs = {
  userId: Scalars['Int'];
  workspaceId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDbtArtifactErrorsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<DbtArtifactErrorCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbtArtifactErrorsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryDbtNodeArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDbtNodeByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDbtNodesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<DbtNodeCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DbtNodesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFileArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFileByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFileContentArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFileContentByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFileContentsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FileContentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FileContentsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFileLineArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFileLineByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFileLinesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FileLineCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FileLinesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFilesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FileCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FilesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFilesRelationArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFilesRelationByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFilesRelationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FilesRelationCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FilesRelationsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFivetranNodeArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFivetranNodeByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFivetranNodesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FivetranNodeCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FivetranNodesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFivetranSnapshotArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFivetranSnapshotByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFivetranSnapshotsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FivetranSnapshotCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FivetranSnapshotsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGdbImportArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGdbImportByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGdbImportsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<GdbImportCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GdbImportsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryHightouchNodeArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryHightouchNodeByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryHightouchNodesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<HightouchNodeCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<HightouchNodesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryHightouchSnapshotArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryHightouchSnapshotByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryHightouchSnapshotsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<HightouchSnapshotCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<HightouchSnapshotsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryInformationSchemaSnapshotV2Args = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryInformationSchemaSnapshotV2ByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryInformationSchemaSnapshotV2sArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<InformationSchemaSnapshotV2Condition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InformationSchemaSnapshotV2sOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryInformationSchemaTableV2Args = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryInformationSchemaTableV2ByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryInformationSchemaTableV2sArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<InformationSchemaTableV2Condition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InformationSchemaTableV2sOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryIntegrationArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIntegrationByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIntegrationByWorkspaceIdAndUidArgs = {
  uid: Scalars['String'];
  workspaceId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIntegrationConfigArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIntegrationConfigByIntegrationIdAndKeyArgs = {
  integrationId: Scalars['Int'];
  key: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIntegrationConfigByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIntegrationConfigsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<IntegrationConfigCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IntegrationConfigsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryIntegrationNodeArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIntegrationNodeByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIntegrationNodesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<IntegrationNodeCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IntegrationNodesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryIntegrationSnapshotArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIntegrationSnapshotByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIntegrationSnapshotsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<IntegrationSnapshotCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IntegrationSnapshotsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryIntegrationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<IntegrationCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IntegrationsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLookerImportErrorArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLookerImportErrorByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLookerImportErrorsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LookerImportErrorCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LookerImportErrorsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLookerNodeArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLookerNodeByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLookerNodesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LookerNodeCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LookerNodesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLookerRepositoryNodeArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLookerRepositoryNodeByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLookerRepositoryNodesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LookerRepositoryNodeCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LookerRepositoryNodesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLookerRepositorySnapshotArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLookerRepositorySnapshotByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLookerRepositorySnapshotsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LookerRepositorySnapshotCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LookerRepositorySnapshotsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLookerSnapshotArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLookerSnapshotByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLookerSnapshotsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LookerSnapshotCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LookerSnapshotsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryNodeArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryParadimeAppConfigArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryParadimeAppConfigByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryParadimeAppConfigByWorkspaceIdAndKeyArgs = {
  key: Scalars['String'];
  workspaceId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryParadimeAppConfigsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ParadimeAppConfigCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ParadimeAppConfigsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryScheduleCommandArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryScheduleCommandByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryScheduleCommandResourceArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryScheduleCommandResourceByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryScheduleCommandResourcesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ScheduleCommandResourceCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ScheduleCommandResourcesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryScheduleCommandSourceFreshnessArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryScheduleCommandSourceFreshnessByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryScheduleCommandSourceFreshnessesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ScheduleCommandSourceFreshnessCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ScheduleCommandSourceFreshnessesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryScheduleCommandsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ScheduleCommandCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ScheduleCommandsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryScheduleLogArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryScheduleLogByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryScheduleLogsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ScheduleLogCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ScheduleLogsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryScheduleNameArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryScheduleNameByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryScheduleNameEntriesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ScheduleNameEntryCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ScheduleNameEntriesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryScheduleNameEntryArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryScheduleNameEntryByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryScheduleNameEntryByWorkspaceIdAndNameArgs = {
  name: Scalars['String'];
  workspaceId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryScheduleNamesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ScheduleNameCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ScheduleNamesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryScheduleParseArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryScheduleParseByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryScheduleParsesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ScheduleParseCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ScheduleParsesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryScheduleRunArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryScheduleRunByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryScheduleRunCancelledArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryScheduleRunCancelledByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryScheduleRunCancelledsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ScheduleRunCancelledCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ScheduleRunCancelledsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryScheduleRunDeferredArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryScheduleRunDeferredByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryScheduleRunDeferredsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ScheduleRunDeferredCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ScheduleRunDeferredsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryScheduleRunsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ScheduleRunCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ScheduleRunsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTableauNodeArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTableauNodeByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTableauNodesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TableauNodeCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TableauNodesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTableauSnapshotArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTableauSnapshotByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTableauSnapshotsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TableauSnapshotCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TableauSnapshotsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryWorkspaceArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryWorkspaceByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryWorkspaceMembershipArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryWorkspaceMembershipByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryWorkspaceMembershipByUserIdAndWorkspaceIdArgs = {
  userId: Scalars['Int'];
  workspaceId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryWorkspaceMembershipsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<WorkspaceMembershipCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WorkspaceMembershipsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryWorkspacesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<WorkspaceCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WorkspacesOrderBy>>;
};

export type ScheduleCommand = Node & {
  __typename?: 'ScheduleCommand';
  command: Scalars['String'];
  endDttm: Scalars['Datetime'];
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  returnCode?: Maybe<Scalars['Int']>;
  /** Reads and enables pagination through a set of `ScheduleCommandResource`. */
  scheduleCommandResources: ScheduleCommandResourcesConnection;
  /** Reads and enables pagination through a set of `ScheduleCommandSourceFreshness`. */
  scheduleCommandSourceFreshnesses: ScheduleCommandSourceFreshnessesConnection;
  /** Reads a single `ScheduleRun` that is related to this `ScheduleCommand`. */
  scheduleRun?: Maybe<ScheduleRun>;
  scheduleRunId: Scalars['Int'];
  startDttm: Scalars['Datetime'];
  stderr?: Maybe<Scalars['String']>;
  stdout?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
};


export type ScheduleCommandScheduleCommandResourcesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ScheduleCommandResourceCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ScheduleCommandResourcesOrderBy>>;
};


export type ScheduleCommandScheduleCommandSourceFreshnessesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ScheduleCommandSourceFreshnessCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ScheduleCommandSourceFreshnessesOrderBy>>;
};

export type ScheduleCommandAggregates = {
  __typename?: 'ScheduleCommandAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<ScheduleCommandAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ScheduleCommandDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<ScheduleCommandMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<ScheduleCommandMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<ScheduleCommandStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<ScheduleCommandStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<ScheduleCommandSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<ScheduleCommandVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<ScheduleCommandVarianceSampleAggregates>;
};

export type ScheduleCommandAverageAggregates = {
  __typename?: 'ScheduleCommandAverageAggregates';
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of returnCode across the matching connection */
  returnCode?: Maybe<Scalars['BigFloat']>;
  /** Mean average of scheduleRunId across the matching connection */
  scheduleRunId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `ScheduleCommand` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ScheduleCommandCondition = {
  /** Checks for equality with the object’s `command` field. */
  command?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `endDttm` field. */
  endDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `returnCode` field. */
  returnCode?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `scheduleRunId` field. */
  scheduleRunId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `startDttm` field. */
  startDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `stderr` field. */
  stderr?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `stdout` field. */
  stdout?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `uuid` field. */
  uuid?: InputMaybe<Scalars['String']>;
};

export type ScheduleCommandDistinctCountAggregates = {
  __typename?: 'ScheduleCommandDistinctCountAggregates';
  /** Distinct count of command across the matching connection */
  command?: Maybe<Scalars['BigInt']>;
  /** Distinct count of endDttm across the matching connection */
  endDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of returnCode across the matching connection */
  returnCode?: Maybe<Scalars['BigInt']>;
  /** Distinct count of scheduleRunId across the matching connection */
  scheduleRunId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of startDttm across the matching connection */
  startDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of stderr across the matching connection */
  stderr?: Maybe<Scalars['BigInt']>;
  /** Distinct count of stdout across the matching connection */
  stdout?: Maybe<Scalars['BigInt']>;
  /** Distinct count of uuid across the matching connection */
  uuid?: Maybe<Scalars['BigInt']>;
};

/** Grouping methods for `ScheduleCommand` for usage during aggregation. */
export enum ScheduleCommandGroupBy {
  Command = 'COMMAND',
  EndDttm = 'END_DTTM',
  EndDttmTruncatedToDay = 'END_DTTM_TRUNCATED_TO_DAY',
  EndDttmTruncatedToHour = 'END_DTTM_TRUNCATED_TO_HOUR',
  ReturnCode = 'RETURN_CODE',
  ScheduleRunId = 'SCHEDULE_RUN_ID',
  StartDttm = 'START_DTTM',
  StartDttmTruncatedToDay = 'START_DTTM_TRUNCATED_TO_DAY',
  StartDttmTruncatedToHour = 'START_DTTM_TRUNCATED_TO_HOUR',
  Stderr = 'STDERR',
  Stdout = 'STDOUT'
}

export type ScheduleCommandHavingAverageInput = {
  endDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  returnCode?: InputMaybe<HavingIntFilter>;
  scheduleRunId?: InputMaybe<HavingIntFilter>;
  startDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type ScheduleCommandHavingDistinctCountInput = {
  endDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  returnCode?: InputMaybe<HavingIntFilter>;
  scheduleRunId?: InputMaybe<HavingIntFilter>;
  startDttm?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `ScheduleCommand` aggregates. */
export type ScheduleCommandHavingInput = {
  AND?: InputMaybe<Array<ScheduleCommandHavingInput>>;
  OR?: InputMaybe<Array<ScheduleCommandHavingInput>>;
  average?: InputMaybe<ScheduleCommandHavingAverageInput>;
  distinctCount?: InputMaybe<ScheduleCommandHavingDistinctCountInput>;
  max?: InputMaybe<ScheduleCommandHavingMaxInput>;
  min?: InputMaybe<ScheduleCommandHavingMinInput>;
  stddevPopulation?: InputMaybe<ScheduleCommandHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<ScheduleCommandHavingStddevSampleInput>;
  sum?: InputMaybe<ScheduleCommandHavingSumInput>;
  variancePopulation?: InputMaybe<ScheduleCommandHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<ScheduleCommandHavingVarianceSampleInput>;
};

export type ScheduleCommandHavingMaxInput = {
  endDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  returnCode?: InputMaybe<HavingIntFilter>;
  scheduleRunId?: InputMaybe<HavingIntFilter>;
  startDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type ScheduleCommandHavingMinInput = {
  endDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  returnCode?: InputMaybe<HavingIntFilter>;
  scheduleRunId?: InputMaybe<HavingIntFilter>;
  startDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type ScheduleCommandHavingStddevPopulationInput = {
  endDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  returnCode?: InputMaybe<HavingIntFilter>;
  scheduleRunId?: InputMaybe<HavingIntFilter>;
  startDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type ScheduleCommandHavingStddevSampleInput = {
  endDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  returnCode?: InputMaybe<HavingIntFilter>;
  scheduleRunId?: InputMaybe<HavingIntFilter>;
  startDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type ScheduleCommandHavingSumInput = {
  endDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  returnCode?: InputMaybe<HavingIntFilter>;
  scheduleRunId?: InputMaybe<HavingIntFilter>;
  startDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type ScheduleCommandHavingVariancePopulationInput = {
  endDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  returnCode?: InputMaybe<HavingIntFilter>;
  scheduleRunId?: InputMaybe<HavingIntFilter>;
  startDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type ScheduleCommandHavingVarianceSampleInput = {
  endDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  returnCode?: InputMaybe<HavingIntFilter>;
  scheduleRunId?: InputMaybe<HavingIntFilter>;
  startDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type ScheduleCommandMaxAggregates = {
  __typename?: 'ScheduleCommandMaxAggregates';
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of returnCode across the matching connection */
  returnCode?: Maybe<Scalars['Int']>;
  /** Maximum of scheduleRunId across the matching connection */
  scheduleRunId?: Maybe<Scalars['Int']>;
};

export type ScheduleCommandMinAggregates = {
  __typename?: 'ScheduleCommandMinAggregates';
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of returnCode across the matching connection */
  returnCode?: Maybe<Scalars['Int']>;
  /** Minimum of scheduleRunId across the matching connection */
  scheduleRunId?: Maybe<Scalars['Int']>;
};

export type ScheduleCommandResource = Node & {
  __typename?: 'ScheduleCommandResource';
  compressedData?: Maybe<Scalars['String']>;
  createdDttm: Scalars['Datetime'];
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  path: Scalars['String'];
  s3Url?: Maybe<Scalars['String']>;
  /** Reads a single `ScheduleCommand` that is related to this `ScheduleCommandResource`. */
  scheduleCommand?: Maybe<ScheduleCommand>;
  scheduleCommandId: Scalars['Int'];
  /** Reads and enables pagination through a set of `ScheduleRunDeferred`. */
  scheduleRunDeferreds: ScheduleRunDeferredsConnection;
};


export type ScheduleCommandResourceScheduleRunDeferredsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ScheduleRunDeferredCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ScheduleRunDeferredsOrderBy>>;
};

export type ScheduleCommandResourceAggregates = {
  __typename?: 'ScheduleCommandResourceAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<ScheduleCommandResourceAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ScheduleCommandResourceDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<ScheduleCommandResourceMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<ScheduleCommandResourceMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<ScheduleCommandResourceStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<ScheduleCommandResourceStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<ScheduleCommandResourceSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<ScheduleCommandResourceVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<ScheduleCommandResourceVarianceSampleAggregates>;
};

export type ScheduleCommandResourceAverageAggregates = {
  __typename?: 'ScheduleCommandResourceAverageAggregates';
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of scheduleCommandId across the matching connection */
  scheduleCommandId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `ScheduleCommandResource` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type ScheduleCommandResourceCondition = {
  /** Checks for equality with the object’s `compressedData` field. */
  compressedData?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdDttm` field. */
  createdDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `path` field. */
  path?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `s3Url` field. */
  s3Url?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `scheduleCommandId` field. */
  scheduleCommandId?: InputMaybe<Scalars['Int']>;
};

export type ScheduleCommandResourceDistinctCountAggregates = {
  __typename?: 'ScheduleCommandResourceDistinctCountAggregates';
  /** Distinct count of compressedData across the matching connection */
  compressedData?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdDttm across the matching connection */
  createdDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of path across the matching connection */
  path?: Maybe<Scalars['BigInt']>;
  /** Distinct count of s3Url across the matching connection */
  s3Url?: Maybe<Scalars['BigInt']>;
  /** Distinct count of scheduleCommandId across the matching connection */
  scheduleCommandId?: Maybe<Scalars['BigInt']>;
};

/** Grouping methods for `ScheduleCommandResource` for usage during aggregation. */
export enum ScheduleCommandResourceGroupBy {
  CompressedData = 'COMPRESSED_DATA',
  CreatedDttm = 'CREATED_DTTM',
  CreatedDttmTruncatedToDay = 'CREATED_DTTM_TRUNCATED_TO_DAY',
  CreatedDttmTruncatedToHour = 'CREATED_DTTM_TRUNCATED_TO_HOUR',
  Path = 'PATH',
  S3Url = 'S3_URL',
  ScheduleCommandId = 'SCHEDULE_COMMAND_ID'
}

export type ScheduleCommandResourceHavingAverageInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  scheduleCommandId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleCommandResourceHavingDistinctCountInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  scheduleCommandId?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `ScheduleCommandResource` aggregates. */
export type ScheduleCommandResourceHavingInput = {
  AND?: InputMaybe<Array<ScheduleCommandResourceHavingInput>>;
  OR?: InputMaybe<Array<ScheduleCommandResourceHavingInput>>;
  average?: InputMaybe<ScheduleCommandResourceHavingAverageInput>;
  distinctCount?: InputMaybe<ScheduleCommandResourceHavingDistinctCountInput>;
  max?: InputMaybe<ScheduleCommandResourceHavingMaxInput>;
  min?: InputMaybe<ScheduleCommandResourceHavingMinInput>;
  stddevPopulation?: InputMaybe<ScheduleCommandResourceHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<ScheduleCommandResourceHavingStddevSampleInput>;
  sum?: InputMaybe<ScheduleCommandResourceHavingSumInput>;
  variancePopulation?: InputMaybe<ScheduleCommandResourceHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<ScheduleCommandResourceHavingVarianceSampleInput>;
};

export type ScheduleCommandResourceHavingMaxInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  scheduleCommandId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleCommandResourceHavingMinInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  scheduleCommandId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleCommandResourceHavingStddevPopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  scheduleCommandId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleCommandResourceHavingStddevSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  scheduleCommandId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleCommandResourceHavingSumInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  scheduleCommandId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleCommandResourceHavingVariancePopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  scheduleCommandId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleCommandResourceHavingVarianceSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  scheduleCommandId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleCommandResourceMaxAggregates = {
  __typename?: 'ScheduleCommandResourceMaxAggregates';
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of scheduleCommandId across the matching connection */
  scheduleCommandId?: Maybe<Scalars['Int']>;
};

export type ScheduleCommandResourceMinAggregates = {
  __typename?: 'ScheduleCommandResourceMinAggregates';
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of scheduleCommandId across the matching connection */
  scheduleCommandId?: Maybe<Scalars['Int']>;
};

export type ScheduleCommandResourceStddevPopulationAggregates = {
  __typename?: 'ScheduleCommandResourceStddevPopulationAggregates';
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of scheduleCommandId across the matching connection */
  scheduleCommandId?: Maybe<Scalars['BigFloat']>;
};

export type ScheduleCommandResourceStddevSampleAggregates = {
  __typename?: 'ScheduleCommandResourceStddevSampleAggregates';
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of scheduleCommandId across the matching connection */
  scheduleCommandId?: Maybe<Scalars['BigFloat']>;
};

export type ScheduleCommandResourceSumAggregates = {
  __typename?: 'ScheduleCommandResourceSumAggregates';
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of scheduleCommandId across the matching connection */
  scheduleCommandId: Scalars['BigInt'];
};

export type ScheduleCommandResourceVariancePopulationAggregates = {
  __typename?: 'ScheduleCommandResourceVariancePopulationAggregates';
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of scheduleCommandId across the matching connection */
  scheduleCommandId?: Maybe<Scalars['BigFloat']>;
};

export type ScheduleCommandResourceVarianceSampleAggregates = {
  __typename?: 'ScheduleCommandResourceVarianceSampleAggregates';
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of scheduleCommandId across the matching connection */
  scheduleCommandId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `ScheduleCommandResource` values. */
export type ScheduleCommandResourcesConnection = {
  __typename?: 'ScheduleCommandResourcesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ScheduleCommandResourceAggregates>;
  /** A list of edges which contains the `ScheduleCommandResource` and cursor to aid in pagination. */
  edges: Array<ScheduleCommandResourcesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ScheduleCommandResourceAggregates>>;
  /** A list of `ScheduleCommandResource` objects. */
  nodes: Array<ScheduleCommandResource>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ScheduleCommandResource` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `ScheduleCommandResource` values. */
export type ScheduleCommandResourcesConnectionGroupedAggregatesArgs = {
  groupBy: Array<ScheduleCommandResourceGroupBy>;
  having?: InputMaybe<ScheduleCommandResourceHavingInput>;
};

/** A `ScheduleCommandResource` edge in the connection. */
export type ScheduleCommandResourcesEdge = {
  __typename?: 'ScheduleCommandResourcesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ScheduleCommandResource` at the end of the edge. */
  node: ScheduleCommandResource;
};

/** Methods to use when ordering `ScheduleCommandResource`. */
export enum ScheduleCommandResourcesOrderBy {
  CompressedDataAsc = 'COMPRESSED_DATA_ASC',
  CompressedDataDesc = 'COMPRESSED_DATA_DESC',
  CreatedDttmAsc = 'CREATED_DTTM_ASC',
  CreatedDttmDesc = 'CREATED_DTTM_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PathAsc = 'PATH_ASC',
  PathDesc = 'PATH_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  S3UrlAsc = 'S3_URL_ASC',
  S3UrlDesc = 'S3_URL_DESC',
  ScheduleCommandIdAsc = 'SCHEDULE_COMMAND_ID_ASC',
  ScheduleCommandIdDesc = 'SCHEDULE_COMMAND_ID_DESC',
  ScheduleRunDeferredsAverageDeferredScheduleRunIdAsc = 'SCHEDULE_RUN_DEFERREDS_AVERAGE_DEFERRED_SCHEDULE_RUN_ID_ASC',
  ScheduleRunDeferredsAverageDeferredScheduleRunIdDesc = 'SCHEDULE_RUN_DEFERREDS_AVERAGE_DEFERRED_SCHEDULE_RUN_ID_DESC',
  ScheduleRunDeferredsAverageIdAsc = 'SCHEDULE_RUN_DEFERREDS_AVERAGE_ID_ASC',
  ScheduleRunDeferredsAverageIdDesc = 'SCHEDULE_RUN_DEFERREDS_AVERAGE_ID_DESC',
  ScheduleRunDeferredsAverageScheduleCommandResourceIdAsc = 'SCHEDULE_RUN_DEFERREDS_AVERAGE_SCHEDULE_COMMAND_RESOURCE_ID_ASC',
  ScheduleRunDeferredsAverageScheduleCommandResourceIdDesc = 'SCHEDULE_RUN_DEFERREDS_AVERAGE_SCHEDULE_COMMAND_RESOURCE_ID_DESC',
  ScheduleRunDeferredsCountAsc = 'SCHEDULE_RUN_DEFERREDS_COUNT_ASC',
  ScheduleRunDeferredsCountDesc = 'SCHEDULE_RUN_DEFERREDS_COUNT_DESC',
  ScheduleRunDeferredsDistinctCountDeferredScheduleRunIdAsc = 'SCHEDULE_RUN_DEFERREDS_DISTINCT_COUNT_DEFERRED_SCHEDULE_RUN_ID_ASC',
  ScheduleRunDeferredsDistinctCountDeferredScheduleRunIdDesc = 'SCHEDULE_RUN_DEFERREDS_DISTINCT_COUNT_DEFERRED_SCHEDULE_RUN_ID_DESC',
  ScheduleRunDeferredsDistinctCountIdAsc = 'SCHEDULE_RUN_DEFERREDS_DISTINCT_COUNT_ID_ASC',
  ScheduleRunDeferredsDistinctCountIdDesc = 'SCHEDULE_RUN_DEFERREDS_DISTINCT_COUNT_ID_DESC',
  ScheduleRunDeferredsDistinctCountScheduleCommandResourceIdAsc = 'SCHEDULE_RUN_DEFERREDS_DISTINCT_COUNT_SCHEDULE_COMMAND_RESOURCE_ID_ASC',
  ScheduleRunDeferredsDistinctCountScheduleCommandResourceIdDesc = 'SCHEDULE_RUN_DEFERREDS_DISTINCT_COUNT_SCHEDULE_COMMAND_RESOURCE_ID_DESC',
  ScheduleRunDeferredsMaxDeferredScheduleRunIdAsc = 'SCHEDULE_RUN_DEFERREDS_MAX_DEFERRED_SCHEDULE_RUN_ID_ASC',
  ScheduleRunDeferredsMaxDeferredScheduleRunIdDesc = 'SCHEDULE_RUN_DEFERREDS_MAX_DEFERRED_SCHEDULE_RUN_ID_DESC',
  ScheduleRunDeferredsMaxIdAsc = 'SCHEDULE_RUN_DEFERREDS_MAX_ID_ASC',
  ScheduleRunDeferredsMaxIdDesc = 'SCHEDULE_RUN_DEFERREDS_MAX_ID_DESC',
  ScheduleRunDeferredsMaxScheduleCommandResourceIdAsc = 'SCHEDULE_RUN_DEFERREDS_MAX_SCHEDULE_COMMAND_RESOURCE_ID_ASC',
  ScheduleRunDeferredsMaxScheduleCommandResourceIdDesc = 'SCHEDULE_RUN_DEFERREDS_MAX_SCHEDULE_COMMAND_RESOURCE_ID_DESC',
  ScheduleRunDeferredsMinDeferredScheduleRunIdAsc = 'SCHEDULE_RUN_DEFERREDS_MIN_DEFERRED_SCHEDULE_RUN_ID_ASC',
  ScheduleRunDeferredsMinDeferredScheduleRunIdDesc = 'SCHEDULE_RUN_DEFERREDS_MIN_DEFERRED_SCHEDULE_RUN_ID_DESC',
  ScheduleRunDeferredsMinIdAsc = 'SCHEDULE_RUN_DEFERREDS_MIN_ID_ASC',
  ScheduleRunDeferredsMinIdDesc = 'SCHEDULE_RUN_DEFERREDS_MIN_ID_DESC',
  ScheduleRunDeferredsMinScheduleCommandResourceIdAsc = 'SCHEDULE_RUN_DEFERREDS_MIN_SCHEDULE_COMMAND_RESOURCE_ID_ASC',
  ScheduleRunDeferredsMinScheduleCommandResourceIdDesc = 'SCHEDULE_RUN_DEFERREDS_MIN_SCHEDULE_COMMAND_RESOURCE_ID_DESC',
  ScheduleRunDeferredsStddevPopulationDeferredScheduleRunIdAsc = 'SCHEDULE_RUN_DEFERREDS_STDDEV_POPULATION_DEFERRED_SCHEDULE_RUN_ID_ASC',
  ScheduleRunDeferredsStddevPopulationDeferredScheduleRunIdDesc = 'SCHEDULE_RUN_DEFERREDS_STDDEV_POPULATION_DEFERRED_SCHEDULE_RUN_ID_DESC',
  ScheduleRunDeferredsStddevPopulationIdAsc = 'SCHEDULE_RUN_DEFERREDS_STDDEV_POPULATION_ID_ASC',
  ScheduleRunDeferredsStddevPopulationIdDesc = 'SCHEDULE_RUN_DEFERREDS_STDDEV_POPULATION_ID_DESC',
  ScheduleRunDeferredsStddevPopulationScheduleCommandResourceIdAsc = 'SCHEDULE_RUN_DEFERREDS_STDDEV_POPULATION_SCHEDULE_COMMAND_RESOURCE_ID_ASC',
  ScheduleRunDeferredsStddevPopulationScheduleCommandResourceIdDesc = 'SCHEDULE_RUN_DEFERREDS_STDDEV_POPULATION_SCHEDULE_COMMAND_RESOURCE_ID_DESC',
  ScheduleRunDeferredsStddevSampleDeferredScheduleRunIdAsc = 'SCHEDULE_RUN_DEFERREDS_STDDEV_SAMPLE_DEFERRED_SCHEDULE_RUN_ID_ASC',
  ScheduleRunDeferredsStddevSampleDeferredScheduleRunIdDesc = 'SCHEDULE_RUN_DEFERREDS_STDDEV_SAMPLE_DEFERRED_SCHEDULE_RUN_ID_DESC',
  ScheduleRunDeferredsStddevSampleIdAsc = 'SCHEDULE_RUN_DEFERREDS_STDDEV_SAMPLE_ID_ASC',
  ScheduleRunDeferredsStddevSampleIdDesc = 'SCHEDULE_RUN_DEFERREDS_STDDEV_SAMPLE_ID_DESC',
  ScheduleRunDeferredsStddevSampleScheduleCommandResourceIdAsc = 'SCHEDULE_RUN_DEFERREDS_STDDEV_SAMPLE_SCHEDULE_COMMAND_RESOURCE_ID_ASC',
  ScheduleRunDeferredsStddevSampleScheduleCommandResourceIdDesc = 'SCHEDULE_RUN_DEFERREDS_STDDEV_SAMPLE_SCHEDULE_COMMAND_RESOURCE_ID_DESC',
  ScheduleRunDeferredsSumDeferredScheduleRunIdAsc = 'SCHEDULE_RUN_DEFERREDS_SUM_DEFERRED_SCHEDULE_RUN_ID_ASC',
  ScheduleRunDeferredsSumDeferredScheduleRunIdDesc = 'SCHEDULE_RUN_DEFERREDS_SUM_DEFERRED_SCHEDULE_RUN_ID_DESC',
  ScheduleRunDeferredsSumIdAsc = 'SCHEDULE_RUN_DEFERREDS_SUM_ID_ASC',
  ScheduleRunDeferredsSumIdDesc = 'SCHEDULE_RUN_DEFERREDS_SUM_ID_DESC',
  ScheduleRunDeferredsSumScheduleCommandResourceIdAsc = 'SCHEDULE_RUN_DEFERREDS_SUM_SCHEDULE_COMMAND_RESOURCE_ID_ASC',
  ScheduleRunDeferredsSumScheduleCommandResourceIdDesc = 'SCHEDULE_RUN_DEFERREDS_SUM_SCHEDULE_COMMAND_RESOURCE_ID_DESC',
  ScheduleRunDeferredsVariancePopulationDeferredScheduleRunIdAsc = 'SCHEDULE_RUN_DEFERREDS_VARIANCE_POPULATION_DEFERRED_SCHEDULE_RUN_ID_ASC',
  ScheduleRunDeferredsVariancePopulationDeferredScheduleRunIdDesc = 'SCHEDULE_RUN_DEFERREDS_VARIANCE_POPULATION_DEFERRED_SCHEDULE_RUN_ID_DESC',
  ScheduleRunDeferredsVariancePopulationIdAsc = 'SCHEDULE_RUN_DEFERREDS_VARIANCE_POPULATION_ID_ASC',
  ScheduleRunDeferredsVariancePopulationIdDesc = 'SCHEDULE_RUN_DEFERREDS_VARIANCE_POPULATION_ID_DESC',
  ScheduleRunDeferredsVariancePopulationScheduleCommandResourceIdAsc = 'SCHEDULE_RUN_DEFERREDS_VARIANCE_POPULATION_SCHEDULE_COMMAND_RESOURCE_ID_ASC',
  ScheduleRunDeferredsVariancePopulationScheduleCommandResourceIdDesc = 'SCHEDULE_RUN_DEFERREDS_VARIANCE_POPULATION_SCHEDULE_COMMAND_RESOURCE_ID_DESC',
  ScheduleRunDeferredsVarianceSampleDeferredScheduleRunIdAsc = 'SCHEDULE_RUN_DEFERREDS_VARIANCE_SAMPLE_DEFERRED_SCHEDULE_RUN_ID_ASC',
  ScheduleRunDeferredsVarianceSampleDeferredScheduleRunIdDesc = 'SCHEDULE_RUN_DEFERREDS_VARIANCE_SAMPLE_DEFERRED_SCHEDULE_RUN_ID_DESC',
  ScheduleRunDeferredsVarianceSampleIdAsc = 'SCHEDULE_RUN_DEFERREDS_VARIANCE_SAMPLE_ID_ASC',
  ScheduleRunDeferredsVarianceSampleIdDesc = 'SCHEDULE_RUN_DEFERREDS_VARIANCE_SAMPLE_ID_DESC',
  ScheduleRunDeferredsVarianceSampleScheduleCommandResourceIdAsc = 'SCHEDULE_RUN_DEFERREDS_VARIANCE_SAMPLE_SCHEDULE_COMMAND_RESOURCE_ID_ASC',
  ScheduleRunDeferredsVarianceSampleScheduleCommandResourceIdDesc = 'SCHEDULE_RUN_DEFERREDS_VARIANCE_SAMPLE_SCHEDULE_COMMAND_RESOURCE_ID_DESC'
}

export type ScheduleCommandSourceFreshness = Node & {
  __typename?: 'ScheduleCommandSourceFreshness';
  createdDttm: Scalars['Datetime'];
  criteria?: Maybe<Scalars['String']>;
  errorString?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  maxLoadedAt?: Maybe<Scalars['Datetime']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `ScheduleCommand` that is related to this `ScheduleCommandSourceFreshness`. */
  scheduleCommand?: Maybe<ScheduleCommand>;
  scheduleCommandId: Scalars['Int'];
  snapshottedAt?: Maybe<Scalars['Datetime']>;
  status: Scalars['String'];
  uniqueId: Scalars['String'];
};

export type ScheduleCommandSourceFreshnessAggregates = {
  __typename?: 'ScheduleCommandSourceFreshnessAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<ScheduleCommandSourceFreshnessAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ScheduleCommandSourceFreshnessDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<ScheduleCommandSourceFreshnessMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<ScheduleCommandSourceFreshnessMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<ScheduleCommandSourceFreshnessStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<ScheduleCommandSourceFreshnessStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<ScheduleCommandSourceFreshnessSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<ScheduleCommandSourceFreshnessVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<ScheduleCommandSourceFreshnessVarianceSampleAggregates>;
};

export type ScheduleCommandSourceFreshnessAverageAggregates = {
  __typename?: 'ScheduleCommandSourceFreshnessAverageAggregates';
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of scheduleCommandId across the matching connection */
  scheduleCommandId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `ScheduleCommandSourceFreshness` object types.
 * All fields are tested for equality and combined with a logical ‘and.’
 */
export type ScheduleCommandSourceFreshnessCondition = {
  /** Checks for equality with the object’s `createdDttm` field. */
  createdDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `criteria` field. */
  criteria?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `errorString` field. */
  errorString?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `maxLoadedAt` field. */
  maxLoadedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `scheduleCommandId` field. */
  scheduleCommandId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `snapshottedAt` field. */
  snapshottedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `uniqueId` field. */
  uniqueId?: InputMaybe<Scalars['String']>;
};

export type ScheduleCommandSourceFreshnessDistinctCountAggregates = {
  __typename?: 'ScheduleCommandSourceFreshnessDistinctCountAggregates';
  /** Distinct count of createdDttm across the matching connection */
  createdDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of criteria across the matching connection */
  criteria?: Maybe<Scalars['BigInt']>;
  /** Distinct count of errorString across the matching connection */
  errorString?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of maxLoadedAt across the matching connection */
  maxLoadedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of scheduleCommandId across the matching connection */
  scheduleCommandId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of snapshottedAt across the matching connection */
  snapshottedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of status across the matching connection */
  status?: Maybe<Scalars['BigInt']>;
  /** Distinct count of uniqueId across the matching connection */
  uniqueId?: Maybe<Scalars['BigInt']>;
};

/** Grouping methods for `ScheduleCommandSourceFreshness` for usage during aggregation. */
export enum ScheduleCommandSourceFreshnessGroupBy {
  CreatedDttm = 'CREATED_DTTM',
  CreatedDttmTruncatedToDay = 'CREATED_DTTM_TRUNCATED_TO_DAY',
  CreatedDttmTruncatedToHour = 'CREATED_DTTM_TRUNCATED_TO_HOUR',
  Criteria = 'CRITERIA',
  ErrorString = 'ERROR_STRING',
  MaxLoadedAt = 'MAX_LOADED_AT',
  MaxLoadedAtTruncatedToDay = 'MAX_LOADED_AT_TRUNCATED_TO_DAY',
  MaxLoadedAtTruncatedToHour = 'MAX_LOADED_AT_TRUNCATED_TO_HOUR',
  ScheduleCommandId = 'SCHEDULE_COMMAND_ID',
  SnapshottedAt = 'SNAPSHOTTED_AT',
  SnapshottedAtTruncatedToDay = 'SNAPSHOTTED_AT_TRUNCATED_TO_DAY',
  SnapshottedAtTruncatedToHour = 'SNAPSHOTTED_AT_TRUNCATED_TO_HOUR',
  Status = 'STATUS',
  UniqueId = 'UNIQUE_ID'
}

export type ScheduleCommandSourceFreshnessHavingAverageInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  maxLoadedAt?: InputMaybe<HavingDatetimeFilter>;
  scheduleCommandId?: InputMaybe<HavingIntFilter>;
  snapshottedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ScheduleCommandSourceFreshnessHavingDistinctCountInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  maxLoadedAt?: InputMaybe<HavingDatetimeFilter>;
  scheduleCommandId?: InputMaybe<HavingIntFilter>;
  snapshottedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `ScheduleCommandSourceFreshness` aggregates. */
export type ScheduleCommandSourceFreshnessHavingInput = {
  AND?: InputMaybe<Array<ScheduleCommandSourceFreshnessHavingInput>>;
  OR?: InputMaybe<Array<ScheduleCommandSourceFreshnessHavingInput>>;
  average?: InputMaybe<ScheduleCommandSourceFreshnessHavingAverageInput>;
  distinctCount?: InputMaybe<ScheduleCommandSourceFreshnessHavingDistinctCountInput>;
  max?: InputMaybe<ScheduleCommandSourceFreshnessHavingMaxInput>;
  min?: InputMaybe<ScheduleCommandSourceFreshnessHavingMinInput>;
  stddevPopulation?: InputMaybe<ScheduleCommandSourceFreshnessHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<ScheduleCommandSourceFreshnessHavingStddevSampleInput>;
  sum?: InputMaybe<ScheduleCommandSourceFreshnessHavingSumInput>;
  variancePopulation?: InputMaybe<ScheduleCommandSourceFreshnessHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<ScheduleCommandSourceFreshnessHavingVarianceSampleInput>;
};

export type ScheduleCommandSourceFreshnessHavingMaxInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  maxLoadedAt?: InputMaybe<HavingDatetimeFilter>;
  scheduleCommandId?: InputMaybe<HavingIntFilter>;
  snapshottedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ScheduleCommandSourceFreshnessHavingMinInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  maxLoadedAt?: InputMaybe<HavingDatetimeFilter>;
  scheduleCommandId?: InputMaybe<HavingIntFilter>;
  snapshottedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ScheduleCommandSourceFreshnessHavingStddevPopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  maxLoadedAt?: InputMaybe<HavingDatetimeFilter>;
  scheduleCommandId?: InputMaybe<HavingIntFilter>;
  snapshottedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ScheduleCommandSourceFreshnessHavingStddevSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  maxLoadedAt?: InputMaybe<HavingDatetimeFilter>;
  scheduleCommandId?: InputMaybe<HavingIntFilter>;
  snapshottedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ScheduleCommandSourceFreshnessHavingSumInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  maxLoadedAt?: InputMaybe<HavingDatetimeFilter>;
  scheduleCommandId?: InputMaybe<HavingIntFilter>;
  snapshottedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ScheduleCommandSourceFreshnessHavingVariancePopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  maxLoadedAt?: InputMaybe<HavingDatetimeFilter>;
  scheduleCommandId?: InputMaybe<HavingIntFilter>;
  snapshottedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ScheduleCommandSourceFreshnessHavingVarianceSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  maxLoadedAt?: InputMaybe<HavingDatetimeFilter>;
  scheduleCommandId?: InputMaybe<HavingIntFilter>;
  snapshottedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ScheduleCommandSourceFreshnessMaxAggregates = {
  __typename?: 'ScheduleCommandSourceFreshnessMaxAggregates';
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of scheduleCommandId across the matching connection */
  scheduleCommandId?: Maybe<Scalars['Int']>;
};

export type ScheduleCommandSourceFreshnessMinAggregates = {
  __typename?: 'ScheduleCommandSourceFreshnessMinAggregates';
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of scheduleCommandId across the matching connection */
  scheduleCommandId?: Maybe<Scalars['Int']>;
};

export type ScheduleCommandSourceFreshnessStddevPopulationAggregates = {
  __typename?: 'ScheduleCommandSourceFreshnessStddevPopulationAggregates';
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of scheduleCommandId across the matching connection */
  scheduleCommandId?: Maybe<Scalars['BigFloat']>;
};

export type ScheduleCommandSourceFreshnessStddevSampleAggregates = {
  __typename?: 'ScheduleCommandSourceFreshnessStddevSampleAggregates';
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of scheduleCommandId across the matching connection */
  scheduleCommandId?: Maybe<Scalars['BigFloat']>;
};

export type ScheduleCommandSourceFreshnessSumAggregates = {
  __typename?: 'ScheduleCommandSourceFreshnessSumAggregates';
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of scheduleCommandId across the matching connection */
  scheduleCommandId: Scalars['BigInt'];
};

export type ScheduleCommandSourceFreshnessVariancePopulationAggregates = {
  __typename?: 'ScheduleCommandSourceFreshnessVariancePopulationAggregates';
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of scheduleCommandId across the matching connection */
  scheduleCommandId?: Maybe<Scalars['BigFloat']>;
};

export type ScheduleCommandSourceFreshnessVarianceSampleAggregates = {
  __typename?: 'ScheduleCommandSourceFreshnessVarianceSampleAggregates';
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of scheduleCommandId across the matching connection */
  scheduleCommandId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `ScheduleCommandSourceFreshness` values. */
export type ScheduleCommandSourceFreshnessesConnection = {
  __typename?: 'ScheduleCommandSourceFreshnessesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ScheduleCommandSourceFreshnessAggregates>;
  /** A list of edges which contains the `ScheduleCommandSourceFreshness` and cursor to aid in pagination. */
  edges: Array<ScheduleCommandSourceFreshnessesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ScheduleCommandSourceFreshnessAggregates>>;
  /** A list of `ScheduleCommandSourceFreshness` objects. */
  nodes: Array<ScheduleCommandSourceFreshness>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ScheduleCommandSourceFreshness` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `ScheduleCommandSourceFreshness` values. */
export type ScheduleCommandSourceFreshnessesConnectionGroupedAggregatesArgs = {
  groupBy: Array<ScheduleCommandSourceFreshnessGroupBy>;
  having?: InputMaybe<ScheduleCommandSourceFreshnessHavingInput>;
};

/** A `ScheduleCommandSourceFreshness` edge in the connection. */
export type ScheduleCommandSourceFreshnessesEdge = {
  __typename?: 'ScheduleCommandSourceFreshnessesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ScheduleCommandSourceFreshness` at the end of the edge. */
  node: ScheduleCommandSourceFreshness;
};

/** Methods to use when ordering `ScheduleCommandSourceFreshness`. */
export enum ScheduleCommandSourceFreshnessesOrderBy {
  CreatedDttmAsc = 'CREATED_DTTM_ASC',
  CreatedDttmDesc = 'CREATED_DTTM_DESC',
  CriteriaAsc = 'CRITERIA_ASC',
  CriteriaDesc = 'CRITERIA_DESC',
  ErrorStringAsc = 'ERROR_STRING_ASC',
  ErrorStringDesc = 'ERROR_STRING_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  MaxLoadedAtAsc = 'MAX_LOADED_AT_ASC',
  MaxLoadedAtDesc = 'MAX_LOADED_AT_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ScheduleCommandIdAsc = 'SCHEDULE_COMMAND_ID_ASC',
  ScheduleCommandIdDesc = 'SCHEDULE_COMMAND_ID_DESC',
  SnapshottedAtAsc = 'SNAPSHOTTED_AT_ASC',
  SnapshottedAtDesc = 'SNAPSHOTTED_AT_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  UniqueIdAsc = 'UNIQUE_ID_ASC',
  UniqueIdDesc = 'UNIQUE_ID_DESC'
}

export type ScheduleCommandStddevPopulationAggregates = {
  __typename?: 'ScheduleCommandStddevPopulationAggregates';
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of returnCode across the matching connection */
  returnCode?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of scheduleRunId across the matching connection */
  scheduleRunId?: Maybe<Scalars['BigFloat']>;
};

export type ScheduleCommandStddevSampleAggregates = {
  __typename?: 'ScheduleCommandStddevSampleAggregates';
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of returnCode across the matching connection */
  returnCode?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of scheduleRunId across the matching connection */
  scheduleRunId?: Maybe<Scalars['BigFloat']>;
};

export type ScheduleCommandSumAggregates = {
  __typename?: 'ScheduleCommandSumAggregates';
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of returnCode across the matching connection */
  returnCode: Scalars['BigInt'];
  /** Sum of scheduleRunId across the matching connection */
  scheduleRunId: Scalars['BigInt'];
};

export type ScheduleCommandVariancePopulationAggregates = {
  __typename?: 'ScheduleCommandVariancePopulationAggregates';
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of returnCode across the matching connection */
  returnCode?: Maybe<Scalars['BigFloat']>;
  /** Population variance of scheduleRunId across the matching connection */
  scheduleRunId?: Maybe<Scalars['BigFloat']>;
};

export type ScheduleCommandVarianceSampleAggregates = {
  __typename?: 'ScheduleCommandVarianceSampleAggregates';
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of returnCode across the matching connection */
  returnCode?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of scheduleRunId across the matching connection */
  scheduleRunId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `ScheduleCommand` values. */
export type ScheduleCommandsConnection = {
  __typename?: 'ScheduleCommandsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ScheduleCommandAggregates>;
  /** A list of edges which contains the `ScheduleCommand` and cursor to aid in pagination. */
  edges: Array<ScheduleCommandsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ScheduleCommandAggregates>>;
  /** A list of `ScheduleCommand` objects. */
  nodes: Array<ScheduleCommand>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ScheduleCommand` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `ScheduleCommand` values. */
export type ScheduleCommandsConnectionGroupedAggregatesArgs = {
  groupBy: Array<ScheduleCommandGroupBy>;
  having?: InputMaybe<ScheduleCommandHavingInput>;
};

/** A `ScheduleCommand` edge in the connection. */
export type ScheduleCommandsEdge = {
  __typename?: 'ScheduleCommandsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ScheduleCommand` at the end of the edge. */
  node: ScheduleCommand;
};

/** Methods to use when ordering `ScheduleCommand`. */
export enum ScheduleCommandsOrderBy {
  CommandAsc = 'COMMAND_ASC',
  CommandDesc = 'COMMAND_DESC',
  EndDttmAsc = 'END_DTTM_ASC',
  EndDttmDesc = 'END_DTTM_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ReturnCodeAsc = 'RETURN_CODE_ASC',
  ReturnCodeDesc = 'RETURN_CODE_DESC',
  ScheduleCommandResourcesAverageCompressedDataAsc = 'SCHEDULE_COMMAND_RESOURCES_AVERAGE_COMPRESSED_DATA_ASC',
  ScheduleCommandResourcesAverageCompressedDataDesc = 'SCHEDULE_COMMAND_RESOURCES_AVERAGE_COMPRESSED_DATA_DESC',
  ScheduleCommandResourcesAverageCreatedDttmAsc = 'SCHEDULE_COMMAND_RESOURCES_AVERAGE_CREATED_DTTM_ASC',
  ScheduleCommandResourcesAverageCreatedDttmDesc = 'SCHEDULE_COMMAND_RESOURCES_AVERAGE_CREATED_DTTM_DESC',
  ScheduleCommandResourcesAverageIdAsc = 'SCHEDULE_COMMAND_RESOURCES_AVERAGE_ID_ASC',
  ScheduleCommandResourcesAverageIdDesc = 'SCHEDULE_COMMAND_RESOURCES_AVERAGE_ID_DESC',
  ScheduleCommandResourcesAveragePathAsc = 'SCHEDULE_COMMAND_RESOURCES_AVERAGE_PATH_ASC',
  ScheduleCommandResourcesAveragePathDesc = 'SCHEDULE_COMMAND_RESOURCES_AVERAGE_PATH_DESC',
  ScheduleCommandResourcesAverageS3UrlAsc = 'SCHEDULE_COMMAND_RESOURCES_AVERAGE_S3_URL_ASC',
  ScheduleCommandResourcesAverageS3UrlDesc = 'SCHEDULE_COMMAND_RESOURCES_AVERAGE_S3_URL_DESC',
  ScheduleCommandResourcesAverageScheduleCommandIdAsc = 'SCHEDULE_COMMAND_RESOURCES_AVERAGE_SCHEDULE_COMMAND_ID_ASC',
  ScheduleCommandResourcesAverageScheduleCommandIdDesc = 'SCHEDULE_COMMAND_RESOURCES_AVERAGE_SCHEDULE_COMMAND_ID_DESC',
  ScheduleCommandResourcesCountAsc = 'SCHEDULE_COMMAND_RESOURCES_COUNT_ASC',
  ScheduleCommandResourcesCountDesc = 'SCHEDULE_COMMAND_RESOURCES_COUNT_DESC',
  ScheduleCommandResourcesDistinctCountCompressedDataAsc = 'SCHEDULE_COMMAND_RESOURCES_DISTINCT_COUNT_COMPRESSED_DATA_ASC',
  ScheduleCommandResourcesDistinctCountCompressedDataDesc = 'SCHEDULE_COMMAND_RESOURCES_DISTINCT_COUNT_COMPRESSED_DATA_DESC',
  ScheduleCommandResourcesDistinctCountCreatedDttmAsc = 'SCHEDULE_COMMAND_RESOURCES_DISTINCT_COUNT_CREATED_DTTM_ASC',
  ScheduleCommandResourcesDistinctCountCreatedDttmDesc = 'SCHEDULE_COMMAND_RESOURCES_DISTINCT_COUNT_CREATED_DTTM_DESC',
  ScheduleCommandResourcesDistinctCountIdAsc = 'SCHEDULE_COMMAND_RESOURCES_DISTINCT_COUNT_ID_ASC',
  ScheduleCommandResourcesDistinctCountIdDesc = 'SCHEDULE_COMMAND_RESOURCES_DISTINCT_COUNT_ID_DESC',
  ScheduleCommandResourcesDistinctCountPathAsc = 'SCHEDULE_COMMAND_RESOURCES_DISTINCT_COUNT_PATH_ASC',
  ScheduleCommandResourcesDistinctCountPathDesc = 'SCHEDULE_COMMAND_RESOURCES_DISTINCT_COUNT_PATH_DESC',
  ScheduleCommandResourcesDistinctCountS3UrlAsc = 'SCHEDULE_COMMAND_RESOURCES_DISTINCT_COUNT_S3_URL_ASC',
  ScheduleCommandResourcesDistinctCountS3UrlDesc = 'SCHEDULE_COMMAND_RESOURCES_DISTINCT_COUNT_S3_URL_DESC',
  ScheduleCommandResourcesDistinctCountScheduleCommandIdAsc = 'SCHEDULE_COMMAND_RESOURCES_DISTINCT_COUNT_SCHEDULE_COMMAND_ID_ASC',
  ScheduleCommandResourcesDistinctCountScheduleCommandIdDesc = 'SCHEDULE_COMMAND_RESOURCES_DISTINCT_COUNT_SCHEDULE_COMMAND_ID_DESC',
  ScheduleCommandResourcesMaxCompressedDataAsc = 'SCHEDULE_COMMAND_RESOURCES_MAX_COMPRESSED_DATA_ASC',
  ScheduleCommandResourcesMaxCompressedDataDesc = 'SCHEDULE_COMMAND_RESOURCES_MAX_COMPRESSED_DATA_DESC',
  ScheduleCommandResourcesMaxCreatedDttmAsc = 'SCHEDULE_COMMAND_RESOURCES_MAX_CREATED_DTTM_ASC',
  ScheduleCommandResourcesMaxCreatedDttmDesc = 'SCHEDULE_COMMAND_RESOURCES_MAX_CREATED_DTTM_DESC',
  ScheduleCommandResourcesMaxIdAsc = 'SCHEDULE_COMMAND_RESOURCES_MAX_ID_ASC',
  ScheduleCommandResourcesMaxIdDesc = 'SCHEDULE_COMMAND_RESOURCES_MAX_ID_DESC',
  ScheduleCommandResourcesMaxPathAsc = 'SCHEDULE_COMMAND_RESOURCES_MAX_PATH_ASC',
  ScheduleCommandResourcesMaxPathDesc = 'SCHEDULE_COMMAND_RESOURCES_MAX_PATH_DESC',
  ScheduleCommandResourcesMaxS3UrlAsc = 'SCHEDULE_COMMAND_RESOURCES_MAX_S3_URL_ASC',
  ScheduleCommandResourcesMaxS3UrlDesc = 'SCHEDULE_COMMAND_RESOURCES_MAX_S3_URL_DESC',
  ScheduleCommandResourcesMaxScheduleCommandIdAsc = 'SCHEDULE_COMMAND_RESOURCES_MAX_SCHEDULE_COMMAND_ID_ASC',
  ScheduleCommandResourcesMaxScheduleCommandIdDesc = 'SCHEDULE_COMMAND_RESOURCES_MAX_SCHEDULE_COMMAND_ID_DESC',
  ScheduleCommandResourcesMinCompressedDataAsc = 'SCHEDULE_COMMAND_RESOURCES_MIN_COMPRESSED_DATA_ASC',
  ScheduleCommandResourcesMinCompressedDataDesc = 'SCHEDULE_COMMAND_RESOURCES_MIN_COMPRESSED_DATA_DESC',
  ScheduleCommandResourcesMinCreatedDttmAsc = 'SCHEDULE_COMMAND_RESOURCES_MIN_CREATED_DTTM_ASC',
  ScheduleCommandResourcesMinCreatedDttmDesc = 'SCHEDULE_COMMAND_RESOURCES_MIN_CREATED_DTTM_DESC',
  ScheduleCommandResourcesMinIdAsc = 'SCHEDULE_COMMAND_RESOURCES_MIN_ID_ASC',
  ScheduleCommandResourcesMinIdDesc = 'SCHEDULE_COMMAND_RESOURCES_MIN_ID_DESC',
  ScheduleCommandResourcesMinPathAsc = 'SCHEDULE_COMMAND_RESOURCES_MIN_PATH_ASC',
  ScheduleCommandResourcesMinPathDesc = 'SCHEDULE_COMMAND_RESOURCES_MIN_PATH_DESC',
  ScheduleCommandResourcesMinS3UrlAsc = 'SCHEDULE_COMMAND_RESOURCES_MIN_S3_URL_ASC',
  ScheduleCommandResourcesMinS3UrlDesc = 'SCHEDULE_COMMAND_RESOURCES_MIN_S3_URL_DESC',
  ScheduleCommandResourcesMinScheduleCommandIdAsc = 'SCHEDULE_COMMAND_RESOURCES_MIN_SCHEDULE_COMMAND_ID_ASC',
  ScheduleCommandResourcesMinScheduleCommandIdDesc = 'SCHEDULE_COMMAND_RESOURCES_MIN_SCHEDULE_COMMAND_ID_DESC',
  ScheduleCommandResourcesStddevPopulationCompressedDataAsc = 'SCHEDULE_COMMAND_RESOURCES_STDDEV_POPULATION_COMPRESSED_DATA_ASC',
  ScheduleCommandResourcesStddevPopulationCompressedDataDesc = 'SCHEDULE_COMMAND_RESOURCES_STDDEV_POPULATION_COMPRESSED_DATA_DESC',
  ScheduleCommandResourcesStddevPopulationCreatedDttmAsc = 'SCHEDULE_COMMAND_RESOURCES_STDDEV_POPULATION_CREATED_DTTM_ASC',
  ScheduleCommandResourcesStddevPopulationCreatedDttmDesc = 'SCHEDULE_COMMAND_RESOURCES_STDDEV_POPULATION_CREATED_DTTM_DESC',
  ScheduleCommandResourcesStddevPopulationIdAsc = 'SCHEDULE_COMMAND_RESOURCES_STDDEV_POPULATION_ID_ASC',
  ScheduleCommandResourcesStddevPopulationIdDesc = 'SCHEDULE_COMMAND_RESOURCES_STDDEV_POPULATION_ID_DESC',
  ScheduleCommandResourcesStddevPopulationPathAsc = 'SCHEDULE_COMMAND_RESOURCES_STDDEV_POPULATION_PATH_ASC',
  ScheduleCommandResourcesStddevPopulationPathDesc = 'SCHEDULE_COMMAND_RESOURCES_STDDEV_POPULATION_PATH_DESC',
  ScheduleCommandResourcesStddevPopulationS3UrlAsc = 'SCHEDULE_COMMAND_RESOURCES_STDDEV_POPULATION_S3_URL_ASC',
  ScheduleCommandResourcesStddevPopulationS3UrlDesc = 'SCHEDULE_COMMAND_RESOURCES_STDDEV_POPULATION_S3_URL_DESC',
  ScheduleCommandResourcesStddevPopulationScheduleCommandIdAsc = 'SCHEDULE_COMMAND_RESOURCES_STDDEV_POPULATION_SCHEDULE_COMMAND_ID_ASC',
  ScheduleCommandResourcesStddevPopulationScheduleCommandIdDesc = 'SCHEDULE_COMMAND_RESOURCES_STDDEV_POPULATION_SCHEDULE_COMMAND_ID_DESC',
  ScheduleCommandResourcesStddevSampleCompressedDataAsc = 'SCHEDULE_COMMAND_RESOURCES_STDDEV_SAMPLE_COMPRESSED_DATA_ASC',
  ScheduleCommandResourcesStddevSampleCompressedDataDesc = 'SCHEDULE_COMMAND_RESOURCES_STDDEV_SAMPLE_COMPRESSED_DATA_DESC',
  ScheduleCommandResourcesStddevSampleCreatedDttmAsc = 'SCHEDULE_COMMAND_RESOURCES_STDDEV_SAMPLE_CREATED_DTTM_ASC',
  ScheduleCommandResourcesStddevSampleCreatedDttmDesc = 'SCHEDULE_COMMAND_RESOURCES_STDDEV_SAMPLE_CREATED_DTTM_DESC',
  ScheduleCommandResourcesStddevSampleIdAsc = 'SCHEDULE_COMMAND_RESOURCES_STDDEV_SAMPLE_ID_ASC',
  ScheduleCommandResourcesStddevSampleIdDesc = 'SCHEDULE_COMMAND_RESOURCES_STDDEV_SAMPLE_ID_DESC',
  ScheduleCommandResourcesStddevSamplePathAsc = 'SCHEDULE_COMMAND_RESOURCES_STDDEV_SAMPLE_PATH_ASC',
  ScheduleCommandResourcesStddevSamplePathDesc = 'SCHEDULE_COMMAND_RESOURCES_STDDEV_SAMPLE_PATH_DESC',
  ScheduleCommandResourcesStddevSampleS3UrlAsc = 'SCHEDULE_COMMAND_RESOURCES_STDDEV_SAMPLE_S3_URL_ASC',
  ScheduleCommandResourcesStddevSampleS3UrlDesc = 'SCHEDULE_COMMAND_RESOURCES_STDDEV_SAMPLE_S3_URL_DESC',
  ScheduleCommandResourcesStddevSampleScheduleCommandIdAsc = 'SCHEDULE_COMMAND_RESOURCES_STDDEV_SAMPLE_SCHEDULE_COMMAND_ID_ASC',
  ScheduleCommandResourcesStddevSampleScheduleCommandIdDesc = 'SCHEDULE_COMMAND_RESOURCES_STDDEV_SAMPLE_SCHEDULE_COMMAND_ID_DESC',
  ScheduleCommandResourcesSumCompressedDataAsc = 'SCHEDULE_COMMAND_RESOURCES_SUM_COMPRESSED_DATA_ASC',
  ScheduleCommandResourcesSumCompressedDataDesc = 'SCHEDULE_COMMAND_RESOURCES_SUM_COMPRESSED_DATA_DESC',
  ScheduleCommandResourcesSumCreatedDttmAsc = 'SCHEDULE_COMMAND_RESOURCES_SUM_CREATED_DTTM_ASC',
  ScheduleCommandResourcesSumCreatedDttmDesc = 'SCHEDULE_COMMAND_RESOURCES_SUM_CREATED_DTTM_DESC',
  ScheduleCommandResourcesSumIdAsc = 'SCHEDULE_COMMAND_RESOURCES_SUM_ID_ASC',
  ScheduleCommandResourcesSumIdDesc = 'SCHEDULE_COMMAND_RESOURCES_SUM_ID_DESC',
  ScheduleCommandResourcesSumPathAsc = 'SCHEDULE_COMMAND_RESOURCES_SUM_PATH_ASC',
  ScheduleCommandResourcesSumPathDesc = 'SCHEDULE_COMMAND_RESOURCES_SUM_PATH_DESC',
  ScheduleCommandResourcesSumS3UrlAsc = 'SCHEDULE_COMMAND_RESOURCES_SUM_S3_URL_ASC',
  ScheduleCommandResourcesSumS3UrlDesc = 'SCHEDULE_COMMAND_RESOURCES_SUM_S3_URL_DESC',
  ScheduleCommandResourcesSumScheduleCommandIdAsc = 'SCHEDULE_COMMAND_RESOURCES_SUM_SCHEDULE_COMMAND_ID_ASC',
  ScheduleCommandResourcesSumScheduleCommandIdDesc = 'SCHEDULE_COMMAND_RESOURCES_SUM_SCHEDULE_COMMAND_ID_DESC',
  ScheduleCommandResourcesVariancePopulationCompressedDataAsc = 'SCHEDULE_COMMAND_RESOURCES_VARIANCE_POPULATION_COMPRESSED_DATA_ASC',
  ScheduleCommandResourcesVariancePopulationCompressedDataDesc = 'SCHEDULE_COMMAND_RESOURCES_VARIANCE_POPULATION_COMPRESSED_DATA_DESC',
  ScheduleCommandResourcesVariancePopulationCreatedDttmAsc = 'SCHEDULE_COMMAND_RESOURCES_VARIANCE_POPULATION_CREATED_DTTM_ASC',
  ScheduleCommandResourcesVariancePopulationCreatedDttmDesc = 'SCHEDULE_COMMAND_RESOURCES_VARIANCE_POPULATION_CREATED_DTTM_DESC',
  ScheduleCommandResourcesVariancePopulationIdAsc = 'SCHEDULE_COMMAND_RESOURCES_VARIANCE_POPULATION_ID_ASC',
  ScheduleCommandResourcesVariancePopulationIdDesc = 'SCHEDULE_COMMAND_RESOURCES_VARIANCE_POPULATION_ID_DESC',
  ScheduleCommandResourcesVariancePopulationPathAsc = 'SCHEDULE_COMMAND_RESOURCES_VARIANCE_POPULATION_PATH_ASC',
  ScheduleCommandResourcesVariancePopulationPathDesc = 'SCHEDULE_COMMAND_RESOURCES_VARIANCE_POPULATION_PATH_DESC',
  ScheduleCommandResourcesVariancePopulationS3UrlAsc = 'SCHEDULE_COMMAND_RESOURCES_VARIANCE_POPULATION_S3_URL_ASC',
  ScheduleCommandResourcesVariancePopulationS3UrlDesc = 'SCHEDULE_COMMAND_RESOURCES_VARIANCE_POPULATION_S3_URL_DESC',
  ScheduleCommandResourcesVariancePopulationScheduleCommandIdAsc = 'SCHEDULE_COMMAND_RESOURCES_VARIANCE_POPULATION_SCHEDULE_COMMAND_ID_ASC',
  ScheduleCommandResourcesVariancePopulationScheduleCommandIdDesc = 'SCHEDULE_COMMAND_RESOURCES_VARIANCE_POPULATION_SCHEDULE_COMMAND_ID_DESC',
  ScheduleCommandResourcesVarianceSampleCompressedDataAsc = 'SCHEDULE_COMMAND_RESOURCES_VARIANCE_SAMPLE_COMPRESSED_DATA_ASC',
  ScheduleCommandResourcesVarianceSampleCompressedDataDesc = 'SCHEDULE_COMMAND_RESOURCES_VARIANCE_SAMPLE_COMPRESSED_DATA_DESC',
  ScheduleCommandResourcesVarianceSampleCreatedDttmAsc = 'SCHEDULE_COMMAND_RESOURCES_VARIANCE_SAMPLE_CREATED_DTTM_ASC',
  ScheduleCommandResourcesVarianceSampleCreatedDttmDesc = 'SCHEDULE_COMMAND_RESOURCES_VARIANCE_SAMPLE_CREATED_DTTM_DESC',
  ScheduleCommandResourcesVarianceSampleIdAsc = 'SCHEDULE_COMMAND_RESOURCES_VARIANCE_SAMPLE_ID_ASC',
  ScheduleCommandResourcesVarianceSampleIdDesc = 'SCHEDULE_COMMAND_RESOURCES_VARIANCE_SAMPLE_ID_DESC',
  ScheduleCommandResourcesVarianceSamplePathAsc = 'SCHEDULE_COMMAND_RESOURCES_VARIANCE_SAMPLE_PATH_ASC',
  ScheduleCommandResourcesVarianceSamplePathDesc = 'SCHEDULE_COMMAND_RESOURCES_VARIANCE_SAMPLE_PATH_DESC',
  ScheduleCommandResourcesVarianceSampleS3UrlAsc = 'SCHEDULE_COMMAND_RESOURCES_VARIANCE_SAMPLE_S3_URL_ASC',
  ScheduleCommandResourcesVarianceSampleS3UrlDesc = 'SCHEDULE_COMMAND_RESOURCES_VARIANCE_SAMPLE_S3_URL_DESC',
  ScheduleCommandResourcesVarianceSampleScheduleCommandIdAsc = 'SCHEDULE_COMMAND_RESOURCES_VARIANCE_SAMPLE_SCHEDULE_COMMAND_ID_ASC',
  ScheduleCommandResourcesVarianceSampleScheduleCommandIdDesc = 'SCHEDULE_COMMAND_RESOURCES_VARIANCE_SAMPLE_SCHEDULE_COMMAND_ID_DESC',
  ScheduleCommandSourceFreshnessesAverageCreatedDttmAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_AVERAGE_CREATED_DTTM_ASC',
  ScheduleCommandSourceFreshnessesAverageCreatedDttmDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_AVERAGE_CREATED_DTTM_DESC',
  ScheduleCommandSourceFreshnessesAverageCriteriaAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_AVERAGE_CRITERIA_ASC',
  ScheduleCommandSourceFreshnessesAverageCriteriaDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_AVERAGE_CRITERIA_DESC',
  ScheduleCommandSourceFreshnessesAverageErrorStringAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_AVERAGE_ERROR_STRING_ASC',
  ScheduleCommandSourceFreshnessesAverageErrorStringDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_AVERAGE_ERROR_STRING_DESC',
  ScheduleCommandSourceFreshnessesAverageIdAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_AVERAGE_ID_ASC',
  ScheduleCommandSourceFreshnessesAverageIdDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_AVERAGE_ID_DESC',
  ScheduleCommandSourceFreshnessesAverageMaxLoadedAtAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_AVERAGE_MAX_LOADED_AT_ASC',
  ScheduleCommandSourceFreshnessesAverageMaxLoadedAtDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_AVERAGE_MAX_LOADED_AT_DESC',
  ScheduleCommandSourceFreshnessesAverageScheduleCommandIdAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_AVERAGE_SCHEDULE_COMMAND_ID_ASC',
  ScheduleCommandSourceFreshnessesAverageScheduleCommandIdDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_AVERAGE_SCHEDULE_COMMAND_ID_DESC',
  ScheduleCommandSourceFreshnessesAverageSnapshottedAtAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_AVERAGE_SNAPSHOTTED_AT_ASC',
  ScheduleCommandSourceFreshnessesAverageSnapshottedAtDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_AVERAGE_SNAPSHOTTED_AT_DESC',
  ScheduleCommandSourceFreshnessesAverageStatusAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_AVERAGE_STATUS_ASC',
  ScheduleCommandSourceFreshnessesAverageStatusDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_AVERAGE_STATUS_DESC',
  ScheduleCommandSourceFreshnessesAverageUniqueIdAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_AVERAGE_UNIQUE_ID_ASC',
  ScheduleCommandSourceFreshnessesAverageUniqueIdDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_AVERAGE_UNIQUE_ID_DESC',
  ScheduleCommandSourceFreshnessesCountAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_COUNT_ASC',
  ScheduleCommandSourceFreshnessesCountDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_COUNT_DESC',
  ScheduleCommandSourceFreshnessesDistinctCountCreatedDttmAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_DISTINCT_COUNT_CREATED_DTTM_ASC',
  ScheduleCommandSourceFreshnessesDistinctCountCreatedDttmDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_DISTINCT_COUNT_CREATED_DTTM_DESC',
  ScheduleCommandSourceFreshnessesDistinctCountCriteriaAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_DISTINCT_COUNT_CRITERIA_ASC',
  ScheduleCommandSourceFreshnessesDistinctCountCriteriaDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_DISTINCT_COUNT_CRITERIA_DESC',
  ScheduleCommandSourceFreshnessesDistinctCountErrorStringAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_DISTINCT_COUNT_ERROR_STRING_ASC',
  ScheduleCommandSourceFreshnessesDistinctCountErrorStringDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_DISTINCT_COUNT_ERROR_STRING_DESC',
  ScheduleCommandSourceFreshnessesDistinctCountIdAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_DISTINCT_COUNT_ID_ASC',
  ScheduleCommandSourceFreshnessesDistinctCountIdDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_DISTINCT_COUNT_ID_DESC',
  ScheduleCommandSourceFreshnessesDistinctCountMaxLoadedAtAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_DISTINCT_COUNT_MAX_LOADED_AT_ASC',
  ScheduleCommandSourceFreshnessesDistinctCountMaxLoadedAtDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_DISTINCT_COUNT_MAX_LOADED_AT_DESC',
  ScheduleCommandSourceFreshnessesDistinctCountScheduleCommandIdAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_DISTINCT_COUNT_SCHEDULE_COMMAND_ID_ASC',
  ScheduleCommandSourceFreshnessesDistinctCountScheduleCommandIdDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_DISTINCT_COUNT_SCHEDULE_COMMAND_ID_DESC',
  ScheduleCommandSourceFreshnessesDistinctCountSnapshottedAtAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_DISTINCT_COUNT_SNAPSHOTTED_AT_ASC',
  ScheduleCommandSourceFreshnessesDistinctCountSnapshottedAtDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_DISTINCT_COUNT_SNAPSHOTTED_AT_DESC',
  ScheduleCommandSourceFreshnessesDistinctCountStatusAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_DISTINCT_COUNT_STATUS_ASC',
  ScheduleCommandSourceFreshnessesDistinctCountStatusDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_DISTINCT_COUNT_STATUS_DESC',
  ScheduleCommandSourceFreshnessesDistinctCountUniqueIdAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_DISTINCT_COUNT_UNIQUE_ID_ASC',
  ScheduleCommandSourceFreshnessesDistinctCountUniqueIdDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_DISTINCT_COUNT_UNIQUE_ID_DESC',
  ScheduleCommandSourceFreshnessesMaxCreatedDttmAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_MAX_CREATED_DTTM_ASC',
  ScheduleCommandSourceFreshnessesMaxCreatedDttmDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_MAX_CREATED_DTTM_DESC',
  ScheduleCommandSourceFreshnessesMaxCriteriaAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_MAX_CRITERIA_ASC',
  ScheduleCommandSourceFreshnessesMaxCriteriaDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_MAX_CRITERIA_DESC',
  ScheduleCommandSourceFreshnessesMaxErrorStringAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_MAX_ERROR_STRING_ASC',
  ScheduleCommandSourceFreshnessesMaxErrorStringDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_MAX_ERROR_STRING_DESC',
  ScheduleCommandSourceFreshnessesMaxIdAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_MAX_ID_ASC',
  ScheduleCommandSourceFreshnessesMaxIdDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_MAX_ID_DESC',
  ScheduleCommandSourceFreshnessesMaxMaxLoadedAtAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_MAX_MAX_LOADED_AT_ASC',
  ScheduleCommandSourceFreshnessesMaxMaxLoadedAtDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_MAX_MAX_LOADED_AT_DESC',
  ScheduleCommandSourceFreshnessesMaxScheduleCommandIdAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_MAX_SCHEDULE_COMMAND_ID_ASC',
  ScheduleCommandSourceFreshnessesMaxScheduleCommandIdDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_MAX_SCHEDULE_COMMAND_ID_DESC',
  ScheduleCommandSourceFreshnessesMaxSnapshottedAtAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_MAX_SNAPSHOTTED_AT_ASC',
  ScheduleCommandSourceFreshnessesMaxSnapshottedAtDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_MAX_SNAPSHOTTED_AT_DESC',
  ScheduleCommandSourceFreshnessesMaxStatusAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_MAX_STATUS_ASC',
  ScheduleCommandSourceFreshnessesMaxStatusDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_MAX_STATUS_DESC',
  ScheduleCommandSourceFreshnessesMaxUniqueIdAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_MAX_UNIQUE_ID_ASC',
  ScheduleCommandSourceFreshnessesMaxUniqueIdDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_MAX_UNIQUE_ID_DESC',
  ScheduleCommandSourceFreshnessesMinCreatedDttmAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_MIN_CREATED_DTTM_ASC',
  ScheduleCommandSourceFreshnessesMinCreatedDttmDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_MIN_CREATED_DTTM_DESC',
  ScheduleCommandSourceFreshnessesMinCriteriaAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_MIN_CRITERIA_ASC',
  ScheduleCommandSourceFreshnessesMinCriteriaDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_MIN_CRITERIA_DESC',
  ScheduleCommandSourceFreshnessesMinErrorStringAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_MIN_ERROR_STRING_ASC',
  ScheduleCommandSourceFreshnessesMinErrorStringDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_MIN_ERROR_STRING_DESC',
  ScheduleCommandSourceFreshnessesMinIdAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_MIN_ID_ASC',
  ScheduleCommandSourceFreshnessesMinIdDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_MIN_ID_DESC',
  ScheduleCommandSourceFreshnessesMinMaxLoadedAtAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_MIN_MAX_LOADED_AT_ASC',
  ScheduleCommandSourceFreshnessesMinMaxLoadedAtDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_MIN_MAX_LOADED_AT_DESC',
  ScheduleCommandSourceFreshnessesMinScheduleCommandIdAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_MIN_SCHEDULE_COMMAND_ID_ASC',
  ScheduleCommandSourceFreshnessesMinScheduleCommandIdDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_MIN_SCHEDULE_COMMAND_ID_DESC',
  ScheduleCommandSourceFreshnessesMinSnapshottedAtAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_MIN_SNAPSHOTTED_AT_ASC',
  ScheduleCommandSourceFreshnessesMinSnapshottedAtDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_MIN_SNAPSHOTTED_AT_DESC',
  ScheduleCommandSourceFreshnessesMinStatusAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_MIN_STATUS_ASC',
  ScheduleCommandSourceFreshnessesMinStatusDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_MIN_STATUS_DESC',
  ScheduleCommandSourceFreshnessesMinUniqueIdAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_MIN_UNIQUE_ID_ASC',
  ScheduleCommandSourceFreshnessesMinUniqueIdDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_MIN_UNIQUE_ID_DESC',
  ScheduleCommandSourceFreshnessesStddevPopulationCreatedDttmAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_STDDEV_POPULATION_CREATED_DTTM_ASC',
  ScheduleCommandSourceFreshnessesStddevPopulationCreatedDttmDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_STDDEV_POPULATION_CREATED_DTTM_DESC',
  ScheduleCommandSourceFreshnessesStddevPopulationCriteriaAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_STDDEV_POPULATION_CRITERIA_ASC',
  ScheduleCommandSourceFreshnessesStddevPopulationCriteriaDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_STDDEV_POPULATION_CRITERIA_DESC',
  ScheduleCommandSourceFreshnessesStddevPopulationErrorStringAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_STDDEV_POPULATION_ERROR_STRING_ASC',
  ScheduleCommandSourceFreshnessesStddevPopulationErrorStringDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_STDDEV_POPULATION_ERROR_STRING_DESC',
  ScheduleCommandSourceFreshnessesStddevPopulationIdAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_STDDEV_POPULATION_ID_ASC',
  ScheduleCommandSourceFreshnessesStddevPopulationIdDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_STDDEV_POPULATION_ID_DESC',
  ScheduleCommandSourceFreshnessesStddevPopulationMaxLoadedAtAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_STDDEV_POPULATION_MAX_LOADED_AT_ASC',
  ScheduleCommandSourceFreshnessesStddevPopulationMaxLoadedAtDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_STDDEV_POPULATION_MAX_LOADED_AT_DESC',
  ScheduleCommandSourceFreshnessesStddevPopulationScheduleCommandIdAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_STDDEV_POPULATION_SCHEDULE_COMMAND_ID_ASC',
  ScheduleCommandSourceFreshnessesStddevPopulationScheduleCommandIdDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_STDDEV_POPULATION_SCHEDULE_COMMAND_ID_DESC',
  ScheduleCommandSourceFreshnessesStddevPopulationSnapshottedAtAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_STDDEV_POPULATION_SNAPSHOTTED_AT_ASC',
  ScheduleCommandSourceFreshnessesStddevPopulationSnapshottedAtDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_STDDEV_POPULATION_SNAPSHOTTED_AT_DESC',
  ScheduleCommandSourceFreshnessesStddevPopulationStatusAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_STDDEV_POPULATION_STATUS_ASC',
  ScheduleCommandSourceFreshnessesStddevPopulationStatusDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_STDDEV_POPULATION_STATUS_DESC',
  ScheduleCommandSourceFreshnessesStddevPopulationUniqueIdAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_STDDEV_POPULATION_UNIQUE_ID_ASC',
  ScheduleCommandSourceFreshnessesStddevPopulationUniqueIdDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_STDDEV_POPULATION_UNIQUE_ID_DESC',
  ScheduleCommandSourceFreshnessesStddevSampleCreatedDttmAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_STDDEV_SAMPLE_CREATED_DTTM_ASC',
  ScheduleCommandSourceFreshnessesStddevSampleCreatedDttmDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_STDDEV_SAMPLE_CREATED_DTTM_DESC',
  ScheduleCommandSourceFreshnessesStddevSampleCriteriaAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_STDDEV_SAMPLE_CRITERIA_ASC',
  ScheduleCommandSourceFreshnessesStddevSampleCriteriaDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_STDDEV_SAMPLE_CRITERIA_DESC',
  ScheduleCommandSourceFreshnessesStddevSampleErrorStringAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_STDDEV_SAMPLE_ERROR_STRING_ASC',
  ScheduleCommandSourceFreshnessesStddevSampleErrorStringDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_STDDEV_SAMPLE_ERROR_STRING_DESC',
  ScheduleCommandSourceFreshnessesStddevSampleIdAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_STDDEV_SAMPLE_ID_ASC',
  ScheduleCommandSourceFreshnessesStddevSampleIdDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_STDDEV_SAMPLE_ID_DESC',
  ScheduleCommandSourceFreshnessesStddevSampleMaxLoadedAtAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_STDDEV_SAMPLE_MAX_LOADED_AT_ASC',
  ScheduleCommandSourceFreshnessesStddevSampleMaxLoadedAtDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_STDDEV_SAMPLE_MAX_LOADED_AT_DESC',
  ScheduleCommandSourceFreshnessesStddevSampleScheduleCommandIdAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_STDDEV_SAMPLE_SCHEDULE_COMMAND_ID_ASC',
  ScheduleCommandSourceFreshnessesStddevSampleScheduleCommandIdDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_STDDEV_SAMPLE_SCHEDULE_COMMAND_ID_DESC',
  ScheduleCommandSourceFreshnessesStddevSampleSnapshottedAtAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_STDDEV_SAMPLE_SNAPSHOTTED_AT_ASC',
  ScheduleCommandSourceFreshnessesStddevSampleSnapshottedAtDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_STDDEV_SAMPLE_SNAPSHOTTED_AT_DESC',
  ScheduleCommandSourceFreshnessesStddevSampleStatusAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_STDDEV_SAMPLE_STATUS_ASC',
  ScheduleCommandSourceFreshnessesStddevSampleStatusDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_STDDEV_SAMPLE_STATUS_DESC',
  ScheduleCommandSourceFreshnessesStddevSampleUniqueIdAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_STDDEV_SAMPLE_UNIQUE_ID_ASC',
  ScheduleCommandSourceFreshnessesStddevSampleUniqueIdDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_STDDEV_SAMPLE_UNIQUE_ID_DESC',
  ScheduleCommandSourceFreshnessesSumCreatedDttmAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_SUM_CREATED_DTTM_ASC',
  ScheduleCommandSourceFreshnessesSumCreatedDttmDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_SUM_CREATED_DTTM_DESC',
  ScheduleCommandSourceFreshnessesSumCriteriaAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_SUM_CRITERIA_ASC',
  ScheduleCommandSourceFreshnessesSumCriteriaDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_SUM_CRITERIA_DESC',
  ScheduleCommandSourceFreshnessesSumErrorStringAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_SUM_ERROR_STRING_ASC',
  ScheduleCommandSourceFreshnessesSumErrorStringDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_SUM_ERROR_STRING_DESC',
  ScheduleCommandSourceFreshnessesSumIdAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_SUM_ID_ASC',
  ScheduleCommandSourceFreshnessesSumIdDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_SUM_ID_DESC',
  ScheduleCommandSourceFreshnessesSumMaxLoadedAtAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_SUM_MAX_LOADED_AT_ASC',
  ScheduleCommandSourceFreshnessesSumMaxLoadedAtDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_SUM_MAX_LOADED_AT_DESC',
  ScheduleCommandSourceFreshnessesSumScheduleCommandIdAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_SUM_SCHEDULE_COMMAND_ID_ASC',
  ScheduleCommandSourceFreshnessesSumScheduleCommandIdDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_SUM_SCHEDULE_COMMAND_ID_DESC',
  ScheduleCommandSourceFreshnessesSumSnapshottedAtAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_SUM_SNAPSHOTTED_AT_ASC',
  ScheduleCommandSourceFreshnessesSumSnapshottedAtDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_SUM_SNAPSHOTTED_AT_DESC',
  ScheduleCommandSourceFreshnessesSumStatusAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_SUM_STATUS_ASC',
  ScheduleCommandSourceFreshnessesSumStatusDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_SUM_STATUS_DESC',
  ScheduleCommandSourceFreshnessesSumUniqueIdAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_SUM_UNIQUE_ID_ASC',
  ScheduleCommandSourceFreshnessesSumUniqueIdDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_SUM_UNIQUE_ID_DESC',
  ScheduleCommandSourceFreshnessesVariancePopulationCreatedDttmAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_VARIANCE_POPULATION_CREATED_DTTM_ASC',
  ScheduleCommandSourceFreshnessesVariancePopulationCreatedDttmDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_VARIANCE_POPULATION_CREATED_DTTM_DESC',
  ScheduleCommandSourceFreshnessesVariancePopulationCriteriaAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_VARIANCE_POPULATION_CRITERIA_ASC',
  ScheduleCommandSourceFreshnessesVariancePopulationCriteriaDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_VARIANCE_POPULATION_CRITERIA_DESC',
  ScheduleCommandSourceFreshnessesVariancePopulationErrorStringAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_VARIANCE_POPULATION_ERROR_STRING_ASC',
  ScheduleCommandSourceFreshnessesVariancePopulationErrorStringDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_VARIANCE_POPULATION_ERROR_STRING_DESC',
  ScheduleCommandSourceFreshnessesVariancePopulationIdAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_VARIANCE_POPULATION_ID_ASC',
  ScheduleCommandSourceFreshnessesVariancePopulationIdDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_VARIANCE_POPULATION_ID_DESC',
  ScheduleCommandSourceFreshnessesVariancePopulationMaxLoadedAtAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_VARIANCE_POPULATION_MAX_LOADED_AT_ASC',
  ScheduleCommandSourceFreshnessesVariancePopulationMaxLoadedAtDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_VARIANCE_POPULATION_MAX_LOADED_AT_DESC',
  ScheduleCommandSourceFreshnessesVariancePopulationScheduleCommandIdAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_VARIANCE_POPULATION_SCHEDULE_COMMAND_ID_ASC',
  ScheduleCommandSourceFreshnessesVariancePopulationScheduleCommandIdDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_VARIANCE_POPULATION_SCHEDULE_COMMAND_ID_DESC',
  ScheduleCommandSourceFreshnessesVariancePopulationSnapshottedAtAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_VARIANCE_POPULATION_SNAPSHOTTED_AT_ASC',
  ScheduleCommandSourceFreshnessesVariancePopulationSnapshottedAtDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_VARIANCE_POPULATION_SNAPSHOTTED_AT_DESC',
  ScheduleCommandSourceFreshnessesVariancePopulationStatusAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_VARIANCE_POPULATION_STATUS_ASC',
  ScheduleCommandSourceFreshnessesVariancePopulationStatusDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_VARIANCE_POPULATION_STATUS_DESC',
  ScheduleCommandSourceFreshnessesVariancePopulationUniqueIdAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_VARIANCE_POPULATION_UNIQUE_ID_ASC',
  ScheduleCommandSourceFreshnessesVariancePopulationUniqueIdDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_VARIANCE_POPULATION_UNIQUE_ID_DESC',
  ScheduleCommandSourceFreshnessesVarianceSampleCreatedDttmAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_VARIANCE_SAMPLE_CREATED_DTTM_ASC',
  ScheduleCommandSourceFreshnessesVarianceSampleCreatedDttmDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_VARIANCE_SAMPLE_CREATED_DTTM_DESC',
  ScheduleCommandSourceFreshnessesVarianceSampleCriteriaAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_VARIANCE_SAMPLE_CRITERIA_ASC',
  ScheduleCommandSourceFreshnessesVarianceSampleCriteriaDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_VARIANCE_SAMPLE_CRITERIA_DESC',
  ScheduleCommandSourceFreshnessesVarianceSampleErrorStringAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_VARIANCE_SAMPLE_ERROR_STRING_ASC',
  ScheduleCommandSourceFreshnessesVarianceSampleErrorStringDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_VARIANCE_SAMPLE_ERROR_STRING_DESC',
  ScheduleCommandSourceFreshnessesVarianceSampleIdAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_VARIANCE_SAMPLE_ID_ASC',
  ScheduleCommandSourceFreshnessesVarianceSampleIdDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_VARIANCE_SAMPLE_ID_DESC',
  ScheduleCommandSourceFreshnessesVarianceSampleMaxLoadedAtAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_VARIANCE_SAMPLE_MAX_LOADED_AT_ASC',
  ScheduleCommandSourceFreshnessesVarianceSampleMaxLoadedAtDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_VARIANCE_SAMPLE_MAX_LOADED_AT_DESC',
  ScheduleCommandSourceFreshnessesVarianceSampleScheduleCommandIdAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_VARIANCE_SAMPLE_SCHEDULE_COMMAND_ID_ASC',
  ScheduleCommandSourceFreshnessesVarianceSampleScheduleCommandIdDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_VARIANCE_SAMPLE_SCHEDULE_COMMAND_ID_DESC',
  ScheduleCommandSourceFreshnessesVarianceSampleSnapshottedAtAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_VARIANCE_SAMPLE_SNAPSHOTTED_AT_ASC',
  ScheduleCommandSourceFreshnessesVarianceSampleSnapshottedAtDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_VARIANCE_SAMPLE_SNAPSHOTTED_AT_DESC',
  ScheduleCommandSourceFreshnessesVarianceSampleStatusAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_VARIANCE_SAMPLE_STATUS_ASC',
  ScheduleCommandSourceFreshnessesVarianceSampleStatusDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_VARIANCE_SAMPLE_STATUS_DESC',
  ScheduleCommandSourceFreshnessesVarianceSampleUniqueIdAsc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_VARIANCE_SAMPLE_UNIQUE_ID_ASC',
  ScheduleCommandSourceFreshnessesVarianceSampleUniqueIdDesc = 'SCHEDULE_COMMAND_SOURCE_FRESHNESSES_VARIANCE_SAMPLE_UNIQUE_ID_DESC',
  ScheduleRunIdAsc = 'SCHEDULE_RUN_ID_ASC',
  ScheduleRunIdDesc = 'SCHEDULE_RUN_ID_DESC',
  StartDttmAsc = 'START_DTTM_ASC',
  StartDttmDesc = 'START_DTTM_DESC',
  StderrAsc = 'STDERR_ASC',
  StderrDesc = 'STDERR_DESC',
  StdoutAsc = 'STDOUT_ASC',
  StdoutDesc = 'STDOUT_DESC',
  UuidAsc = 'UUID_ASC',
  UuidDesc = 'UUID_DESC'
}

export type ScheduleLog = Node & {
  __typename?: 'ScheduleLog';
  commitHash?: Maybe<Scalars['String']>;
  createdDttm: Scalars['Datetime'];
  endDttm: Scalars['Datetime'];
  id: Scalars['Int'];
  jobName: Scalars['String'];
  logUserId?: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  owner?: Maybe<Scalars['String']>;
  startDttm: Scalars['Datetime'];
  status: Scalars['String'];
  updatedDttm: Scalars['Datetime'];
  workspaceId: Scalars['Int'];
};

export type ScheduleLogAggregates = {
  __typename?: 'ScheduleLogAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<ScheduleLogAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ScheduleLogDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<ScheduleLogMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<ScheduleLogMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<ScheduleLogStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<ScheduleLogStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<ScheduleLogSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<ScheduleLogVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<ScheduleLogVarianceSampleAggregates>;
};

export type ScheduleLogAverageAggregates = {
  __typename?: 'ScheduleLogAverageAggregates';
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `ScheduleLog` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ScheduleLogCondition = {
  /** Checks for equality with the object’s `commitHash` field. */
  commitHash?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdDttm` field. */
  createdDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `endDttm` field. */
  endDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `jobName` field. */
  jobName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `logUserId` field. */
  logUserId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `owner` field. */
  owner?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `startDttm` field. */
  startDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedDttm` field. */
  updatedDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `workspaceId` field. */
  workspaceId?: InputMaybe<Scalars['Int']>;
};

export type ScheduleLogDistinctCountAggregates = {
  __typename?: 'ScheduleLogDistinctCountAggregates';
  /** Distinct count of commitHash across the matching connection */
  commitHash?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdDttm across the matching connection */
  createdDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of endDttm across the matching connection */
  endDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of jobName across the matching connection */
  jobName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of logUserId across the matching connection */
  logUserId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of owner across the matching connection */
  owner?: Maybe<Scalars['BigInt']>;
  /** Distinct count of startDttm across the matching connection */
  startDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of status across the matching connection */
  status?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedDttm across the matching connection */
  updatedDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigInt']>;
};

/** Grouping methods for `ScheduleLog` for usage during aggregation. */
export enum ScheduleLogGroupBy {
  CommitHash = 'COMMIT_HASH',
  CreatedDttm = 'CREATED_DTTM',
  CreatedDttmTruncatedToDay = 'CREATED_DTTM_TRUNCATED_TO_DAY',
  CreatedDttmTruncatedToHour = 'CREATED_DTTM_TRUNCATED_TO_HOUR',
  EndDttm = 'END_DTTM',
  EndDttmTruncatedToDay = 'END_DTTM_TRUNCATED_TO_DAY',
  EndDttmTruncatedToHour = 'END_DTTM_TRUNCATED_TO_HOUR',
  JobName = 'JOB_NAME',
  LogUserId = 'LOG_USER_ID',
  Owner = 'OWNER',
  StartDttm = 'START_DTTM',
  StartDttmTruncatedToDay = 'START_DTTM_TRUNCATED_TO_DAY',
  StartDttmTruncatedToHour = 'START_DTTM_TRUNCATED_TO_HOUR',
  Status = 'STATUS',
  UpdatedDttm = 'UPDATED_DTTM',
  UpdatedDttmTruncatedToDay = 'UPDATED_DTTM_TRUNCATED_TO_DAY',
  UpdatedDttmTruncatedToHour = 'UPDATED_DTTM_TRUNCATED_TO_HOUR',
  WorkspaceId = 'WORKSPACE_ID'
}

export type ScheduleLogHavingAverageInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  endDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  startDttm?: InputMaybe<HavingDatetimeFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleLogHavingDistinctCountInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  endDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  startDttm?: InputMaybe<HavingDatetimeFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `ScheduleLog` aggregates. */
export type ScheduleLogHavingInput = {
  AND?: InputMaybe<Array<ScheduleLogHavingInput>>;
  OR?: InputMaybe<Array<ScheduleLogHavingInput>>;
  average?: InputMaybe<ScheduleLogHavingAverageInput>;
  distinctCount?: InputMaybe<ScheduleLogHavingDistinctCountInput>;
  max?: InputMaybe<ScheduleLogHavingMaxInput>;
  min?: InputMaybe<ScheduleLogHavingMinInput>;
  stddevPopulation?: InputMaybe<ScheduleLogHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<ScheduleLogHavingStddevSampleInput>;
  sum?: InputMaybe<ScheduleLogHavingSumInput>;
  variancePopulation?: InputMaybe<ScheduleLogHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<ScheduleLogHavingVarianceSampleInput>;
};

export type ScheduleLogHavingMaxInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  endDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  startDttm?: InputMaybe<HavingDatetimeFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleLogHavingMinInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  endDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  startDttm?: InputMaybe<HavingDatetimeFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleLogHavingStddevPopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  endDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  startDttm?: InputMaybe<HavingDatetimeFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleLogHavingStddevSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  endDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  startDttm?: InputMaybe<HavingDatetimeFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleLogHavingSumInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  endDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  startDttm?: InputMaybe<HavingDatetimeFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleLogHavingVariancePopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  endDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  startDttm?: InputMaybe<HavingDatetimeFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleLogHavingVarianceSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  endDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  startDttm?: InputMaybe<HavingDatetimeFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleLogMaxAggregates = {
  __typename?: 'ScheduleLogMaxAggregates';
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['Int']>;
};

export type ScheduleLogMinAggregates = {
  __typename?: 'ScheduleLogMinAggregates';
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['Int']>;
};

export type ScheduleLogStddevPopulationAggregates = {
  __typename?: 'ScheduleLogStddevPopulationAggregates';
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type ScheduleLogStddevSampleAggregates = {
  __typename?: 'ScheduleLogStddevSampleAggregates';
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type ScheduleLogSumAggregates = {
  __typename?: 'ScheduleLogSumAggregates';
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of workspaceId across the matching connection */
  workspaceId: Scalars['BigInt'];
};

export type ScheduleLogVariancePopulationAggregates = {
  __typename?: 'ScheduleLogVariancePopulationAggregates';
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type ScheduleLogVarianceSampleAggregates = {
  __typename?: 'ScheduleLogVarianceSampleAggregates';
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `ScheduleLog` values. */
export type ScheduleLogsConnection = {
  __typename?: 'ScheduleLogsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ScheduleLogAggregates>;
  /** A list of edges which contains the `ScheduleLog` and cursor to aid in pagination. */
  edges: Array<ScheduleLogsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ScheduleLogAggregates>>;
  /** A list of `ScheduleLog` objects. */
  nodes: Array<ScheduleLog>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ScheduleLog` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `ScheduleLog` values. */
export type ScheduleLogsConnectionGroupedAggregatesArgs = {
  groupBy: Array<ScheduleLogGroupBy>;
  having?: InputMaybe<ScheduleLogHavingInput>;
};

/** A `ScheduleLog` edge in the connection. */
export type ScheduleLogsEdge = {
  __typename?: 'ScheduleLogsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ScheduleLog` at the end of the edge. */
  node: ScheduleLog;
};

/** Methods to use when ordering `ScheduleLog`. */
export enum ScheduleLogsOrderBy {
  CommitHashAsc = 'COMMIT_HASH_ASC',
  CommitHashDesc = 'COMMIT_HASH_DESC',
  CreatedDttmAsc = 'CREATED_DTTM_ASC',
  CreatedDttmDesc = 'CREATED_DTTM_DESC',
  EndDttmAsc = 'END_DTTM_ASC',
  EndDttmDesc = 'END_DTTM_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  JobNameAsc = 'JOB_NAME_ASC',
  JobNameDesc = 'JOB_NAME_DESC',
  LogUserIdAsc = 'LOG_USER_ID_ASC',
  LogUserIdDesc = 'LOG_USER_ID_DESC',
  Natural = 'NATURAL',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  StartDttmAsc = 'START_DTTM_ASC',
  StartDttmDesc = 'START_DTTM_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  UpdatedDttmAsc = 'UPDATED_DTTM_ASC',
  UpdatedDttmDesc = 'UPDATED_DTTM_DESC',
  WorkspaceIdAsc = 'WORKSPACE_ID_ASC',
  WorkspaceIdDesc = 'WORKSPACE_ID_DESC'
}

export type ScheduleName = Node & {
  __typename?: 'ScheduleName';
  branch?: Maybe<Scalars['String']>;
  commandsJson?: Maybe<Scalars['String']>;
  commitHash?: Maybe<Scalars['String']>;
  createdDttm: Scalars['Datetime'];
  dbtVersion?: Maybe<Scalars['String']>;
  deferredScheduleJson?: Maybe<Scalars['String']>;
  emailOn?: Maybe<Array<Maybe<Scalars['String']>>>;
  emailsJson?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['String']>;
  errorString?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  owner?: Maybe<Scalars['String']>;
  scheduleCron?: Maybe<Scalars['String']>;
  /** Reads a single `ScheduleParse` that is related to this `ScheduleName`. */
  scheduleParse?: Maybe<ScheduleParse>;
  scheduleParseId: Scalars['Int'];
  /** Reads and enables pagination through a set of `ScheduleRun`. */
  scheduleRuns: ScheduleRunsConnection;
  slackChannelsJson?: Maybe<Scalars['String']>;
  slackOn?: Maybe<Array<Maybe<Scalars['String']>>>;
  source: Scalars['String'];
  turboCiJson?: Maybe<Scalars['String']>;
  updatedDttm?: Maybe<Scalars['Datetime']>;
  uuid: Scalars['String'];
};


export type ScheduleNameScheduleRunsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ScheduleRunCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ScheduleRunsOrderBy>>;
};

export type ScheduleNameAggregates = {
  __typename?: 'ScheduleNameAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<ScheduleNameAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ScheduleNameDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<ScheduleNameMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<ScheduleNameMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<ScheduleNameStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<ScheduleNameStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<ScheduleNameSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<ScheduleNameVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<ScheduleNameVarianceSampleAggregates>;
};

export type ScheduleNameAverageAggregates = {
  __typename?: 'ScheduleNameAverageAggregates';
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of scheduleParseId across the matching connection */
  scheduleParseId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `ScheduleName` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ScheduleNameCondition = {
  /** Checks for equality with the object’s `branch` field. */
  branch?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `commandsJson` field. */
  commandsJson?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `commitHash` field. */
  commitHash?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdDttm` field. */
  createdDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `dbtVersion` field. */
  dbtVersion?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `deferredScheduleJson` field. */
  deferredScheduleJson?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `emailOn` field. */
  emailOn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `emailsJson` field. */
  emailsJson?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `environment` field. */
  environment?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `errorString` field. */
  errorString?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `owner` field. */
  owner?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `scheduleCron` field. */
  scheduleCron?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `scheduleParseId` field. */
  scheduleParseId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `slackChannelsJson` field. */
  slackChannelsJson?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `slackOn` field. */
  slackOn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `source` field. */
  source?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `turboCiJson` field. */
  turboCiJson?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedDttm` field. */
  updatedDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `uuid` field. */
  uuid?: InputMaybe<Scalars['String']>;
};

export type ScheduleNameDistinctCountAggregates = {
  __typename?: 'ScheduleNameDistinctCountAggregates';
  /** Distinct count of branch across the matching connection */
  branch?: Maybe<Scalars['BigInt']>;
  /** Distinct count of commandsJson across the matching connection */
  commandsJson?: Maybe<Scalars['BigInt']>;
  /** Distinct count of commitHash across the matching connection */
  commitHash?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdDttm across the matching connection */
  createdDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of dbtVersion across the matching connection */
  dbtVersion?: Maybe<Scalars['BigInt']>;
  /** Distinct count of deferredScheduleJson across the matching connection */
  deferredScheduleJson?: Maybe<Scalars['BigInt']>;
  /** Distinct count of emailOn across the matching connection */
  emailOn?: Maybe<Scalars['BigInt']>;
  /** Distinct count of emailsJson across the matching connection */
  emailsJson?: Maybe<Scalars['BigInt']>;
  /** Distinct count of environment across the matching connection */
  environment?: Maybe<Scalars['BigInt']>;
  /** Distinct count of errorString across the matching connection */
  errorString?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of owner across the matching connection */
  owner?: Maybe<Scalars['BigInt']>;
  /** Distinct count of scheduleCron across the matching connection */
  scheduleCron?: Maybe<Scalars['BigInt']>;
  /** Distinct count of scheduleParseId across the matching connection */
  scheduleParseId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of slackChannelsJson across the matching connection */
  slackChannelsJson?: Maybe<Scalars['BigInt']>;
  /** Distinct count of slackOn across the matching connection */
  slackOn?: Maybe<Scalars['BigInt']>;
  /** Distinct count of source across the matching connection */
  source?: Maybe<Scalars['BigInt']>;
  /** Distinct count of turboCiJson across the matching connection */
  turboCiJson?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedDttm across the matching connection */
  updatedDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of uuid across the matching connection */
  uuid?: Maybe<Scalars['BigInt']>;
};

/** A connection to a list of `ScheduleNameEntry` values. */
export type ScheduleNameEntriesConnection = {
  __typename?: 'ScheduleNameEntriesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ScheduleNameEntryAggregates>;
  /** A list of edges which contains the `ScheduleNameEntry` and cursor to aid in pagination. */
  edges: Array<ScheduleNameEntriesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ScheduleNameEntryAggregates>>;
  /** A list of `ScheduleNameEntry` objects. */
  nodes: Array<ScheduleNameEntry>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ScheduleNameEntry` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `ScheduleNameEntry` values. */
export type ScheduleNameEntriesConnectionGroupedAggregatesArgs = {
  groupBy: Array<ScheduleNameEntryGroupBy>;
  having?: InputMaybe<ScheduleNameEntryHavingInput>;
};

/** A `ScheduleNameEntry` edge in the connection. */
export type ScheduleNameEntriesEdge = {
  __typename?: 'ScheduleNameEntriesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ScheduleNameEntry` at the end of the edge. */
  node: ScheduleNameEntry;
};

/** Methods to use when ordering `ScheduleNameEntry`. */
export enum ScheduleNameEntriesOrderBy {
  BranchAsc = 'BRANCH_ASC',
  BranchDesc = 'BRANCH_DESC',
  CommandsJsonAsc = 'COMMANDS_JSON_ASC',
  CommandsJsonDesc = 'COMMANDS_JSON_DESC',
  CreatedDttmAsc = 'CREATED_DTTM_ASC',
  CreatedDttmDesc = 'CREATED_DTTM_DESC',
  DbtVersionAsc = 'DBT_VERSION_ASC',
  DbtVersionDesc = 'DBT_VERSION_DESC',
  DeferredScheduleJsonAsc = 'DEFERRED_SCHEDULE_JSON_ASC',
  DeferredScheduleJsonDesc = 'DEFERRED_SCHEDULE_JSON_DESC',
  DeletedDttmAsc = 'DELETED_DTTM_ASC',
  DeletedDttmDesc = 'DELETED_DTTM_DESC',
  EmailsJsonAsc = 'EMAILS_JSON_ASC',
  EmailsJsonDesc = 'EMAILS_JSON_DESC',
  EmailOnAsc = 'EMAIL_ON_ASC',
  EmailOnDesc = 'EMAIL_ON_DESC',
  EnvironmentAsc = 'ENVIRONMENT_ASC',
  EnvironmentDesc = 'ENVIRONMENT_DESC',
  ErrorStringAsc = 'ERROR_STRING_ASC',
  ErrorStringDesc = 'ERROR_STRING_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ScheduleCronAsc = 'SCHEDULE_CRON_ASC',
  ScheduleCronDesc = 'SCHEDULE_CRON_DESC',
  SlackChannelsJsonAsc = 'SLACK_CHANNELS_JSON_ASC',
  SlackChannelsJsonDesc = 'SLACK_CHANNELS_JSON_DESC',
  SlackOnAsc = 'SLACK_ON_ASC',
  SlackOnDesc = 'SLACK_ON_DESC',
  TurboCiJsonAsc = 'TURBO_CI_JSON_ASC',
  TurboCiJsonDesc = 'TURBO_CI_JSON_DESC',
  UpdatedDttmAsc = 'UPDATED_DTTM_ASC',
  UpdatedDttmDesc = 'UPDATED_DTTM_DESC',
  UuidAsc = 'UUID_ASC',
  UuidDesc = 'UUID_DESC',
  WorkspaceIdAsc = 'WORKSPACE_ID_ASC',
  WorkspaceIdDesc = 'WORKSPACE_ID_DESC'
}

export type ScheduleNameEntry = Node & {
  __typename?: 'ScheduleNameEntry';
  branch?: Maybe<Scalars['String']>;
  commandsJson?: Maybe<Scalars['String']>;
  createdDttm: Scalars['Datetime'];
  dbtVersion?: Maybe<Scalars['String']>;
  deferredScheduleJson?: Maybe<Scalars['String']>;
  deletedDttm?: Maybe<Scalars['Datetime']>;
  emailOn?: Maybe<Array<Maybe<Scalars['String']>>>;
  emailsJson?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['String']>;
  errorString?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  owner?: Maybe<Scalars['String']>;
  scheduleCron?: Maybe<Scalars['String']>;
  slackChannelsJson?: Maybe<Scalars['String']>;
  slackOn?: Maybe<Array<Maybe<Scalars['String']>>>;
  turboCiJson?: Maybe<Scalars['String']>;
  updatedDttm?: Maybe<Scalars['Datetime']>;
  uuid: Scalars['String'];
  workspaceId: Scalars['Int'];
};

export type ScheduleNameEntryAggregates = {
  __typename?: 'ScheduleNameEntryAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<ScheduleNameEntryAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ScheduleNameEntryDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<ScheduleNameEntryMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<ScheduleNameEntryMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<ScheduleNameEntryStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<ScheduleNameEntryStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<ScheduleNameEntrySumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<ScheduleNameEntryVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<ScheduleNameEntryVarianceSampleAggregates>;
};

export type ScheduleNameEntryAverageAggregates = {
  __typename?: 'ScheduleNameEntryAverageAggregates';
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `ScheduleNameEntry` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ScheduleNameEntryCondition = {
  /** Checks for equality with the object’s `branch` field. */
  branch?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `commandsJson` field. */
  commandsJson?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdDttm` field. */
  createdDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `dbtVersion` field. */
  dbtVersion?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `deferredScheduleJson` field. */
  deferredScheduleJson?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `deletedDttm` field. */
  deletedDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `emailOn` field. */
  emailOn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `emailsJson` field. */
  emailsJson?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `environment` field. */
  environment?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `errorString` field. */
  errorString?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `owner` field. */
  owner?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `scheduleCron` field. */
  scheduleCron?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `slackChannelsJson` field. */
  slackChannelsJson?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `slackOn` field. */
  slackOn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `turboCiJson` field. */
  turboCiJson?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedDttm` field. */
  updatedDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `uuid` field. */
  uuid?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `workspaceId` field. */
  workspaceId?: InputMaybe<Scalars['Int']>;
};

export type ScheduleNameEntryDistinctCountAggregates = {
  __typename?: 'ScheduleNameEntryDistinctCountAggregates';
  /** Distinct count of branch across the matching connection */
  branch?: Maybe<Scalars['BigInt']>;
  /** Distinct count of commandsJson across the matching connection */
  commandsJson?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdDttm across the matching connection */
  createdDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of dbtVersion across the matching connection */
  dbtVersion?: Maybe<Scalars['BigInt']>;
  /** Distinct count of deferredScheduleJson across the matching connection */
  deferredScheduleJson?: Maybe<Scalars['BigInt']>;
  /** Distinct count of deletedDttm across the matching connection */
  deletedDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of emailOn across the matching connection */
  emailOn?: Maybe<Scalars['BigInt']>;
  /** Distinct count of emailsJson across the matching connection */
  emailsJson?: Maybe<Scalars['BigInt']>;
  /** Distinct count of environment across the matching connection */
  environment?: Maybe<Scalars['BigInt']>;
  /** Distinct count of errorString across the matching connection */
  errorString?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of owner across the matching connection */
  owner?: Maybe<Scalars['BigInt']>;
  /** Distinct count of scheduleCron across the matching connection */
  scheduleCron?: Maybe<Scalars['BigInt']>;
  /** Distinct count of slackChannelsJson across the matching connection */
  slackChannelsJson?: Maybe<Scalars['BigInt']>;
  /** Distinct count of slackOn across the matching connection */
  slackOn?: Maybe<Scalars['BigInt']>;
  /** Distinct count of turboCiJson across the matching connection */
  turboCiJson?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedDttm across the matching connection */
  updatedDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of uuid across the matching connection */
  uuid?: Maybe<Scalars['BigInt']>;
  /** Distinct count of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigInt']>;
};

/** Grouping methods for `ScheduleNameEntry` for usage during aggregation. */
export enum ScheduleNameEntryGroupBy {
  Branch = 'BRANCH',
  CommandsJson = 'COMMANDS_JSON',
  CreatedDttm = 'CREATED_DTTM',
  CreatedDttmTruncatedToDay = 'CREATED_DTTM_TRUNCATED_TO_DAY',
  CreatedDttmTruncatedToHour = 'CREATED_DTTM_TRUNCATED_TO_HOUR',
  DbtVersion = 'DBT_VERSION',
  DeferredScheduleJson = 'DEFERRED_SCHEDULE_JSON',
  DeletedDttm = 'DELETED_DTTM',
  DeletedDttmTruncatedToDay = 'DELETED_DTTM_TRUNCATED_TO_DAY',
  DeletedDttmTruncatedToHour = 'DELETED_DTTM_TRUNCATED_TO_HOUR',
  EmailsJson = 'EMAILS_JSON',
  EmailOn = 'EMAIL_ON',
  Environment = 'ENVIRONMENT',
  ErrorString = 'ERROR_STRING',
  Name = 'NAME',
  Owner = 'OWNER',
  ScheduleCron = 'SCHEDULE_CRON',
  SlackChannelsJson = 'SLACK_CHANNELS_JSON',
  SlackOn = 'SLACK_ON',
  TurboCiJson = 'TURBO_CI_JSON',
  UpdatedDttm = 'UPDATED_DTTM',
  UpdatedDttmTruncatedToDay = 'UPDATED_DTTM_TRUNCATED_TO_DAY',
  UpdatedDttmTruncatedToHour = 'UPDATED_DTTM_TRUNCATED_TO_HOUR',
  Uuid = 'UUID',
  WorkspaceId = 'WORKSPACE_ID'
}

export type ScheduleNameEntryHavingAverageInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  deletedDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleNameEntryHavingDistinctCountInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  deletedDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `ScheduleNameEntry` aggregates. */
export type ScheduleNameEntryHavingInput = {
  AND?: InputMaybe<Array<ScheduleNameEntryHavingInput>>;
  OR?: InputMaybe<Array<ScheduleNameEntryHavingInput>>;
  average?: InputMaybe<ScheduleNameEntryHavingAverageInput>;
  distinctCount?: InputMaybe<ScheduleNameEntryHavingDistinctCountInput>;
  max?: InputMaybe<ScheduleNameEntryHavingMaxInput>;
  min?: InputMaybe<ScheduleNameEntryHavingMinInput>;
  stddevPopulation?: InputMaybe<ScheduleNameEntryHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<ScheduleNameEntryHavingStddevSampleInput>;
  sum?: InputMaybe<ScheduleNameEntryHavingSumInput>;
  variancePopulation?: InputMaybe<ScheduleNameEntryHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<ScheduleNameEntryHavingVarianceSampleInput>;
};

export type ScheduleNameEntryHavingMaxInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  deletedDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleNameEntryHavingMinInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  deletedDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleNameEntryHavingStddevPopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  deletedDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleNameEntryHavingStddevSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  deletedDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleNameEntryHavingSumInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  deletedDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleNameEntryHavingVariancePopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  deletedDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleNameEntryHavingVarianceSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  deletedDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleNameEntryMaxAggregates = {
  __typename?: 'ScheduleNameEntryMaxAggregates';
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['Int']>;
};

export type ScheduleNameEntryMinAggregates = {
  __typename?: 'ScheduleNameEntryMinAggregates';
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['Int']>;
};

export type ScheduleNameEntryStddevPopulationAggregates = {
  __typename?: 'ScheduleNameEntryStddevPopulationAggregates';
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type ScheduleNameEntryStddevSampleAggregates = {
  __typename?: 'ScheduleNameEntryStddevSampleAggregates';
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type ScheduleNameEntrySumAggregates = {
  __typename?: 'ScheduleNameEntrySumAggregates';
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of workspaceId across the matching connection */
  workspaceId: Scalars['BigInt'];
};

export type ScheduleNameEntryVariancePopulationAggregates = {
  __typename?: 'ScheduleNameEntryVariancePopulationAggregates';
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type ScheduleNameEntryVarianceSampleAggregates = {
  __typename?: 'ScheduleNameEntryVarianceSampleAggregates';
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

/** Grouping methods for `ScheduleName` for usage during aggregation. */
export enum ScheduleNameGroupBy {
  Branch = 'BRANCH',
  CommandsJson = 'COMMANDS_JSON',
  CommitHash = 'COMMIT_HASH',
  CreatedDttm = 'CREATED_DTTM',
  CreatedDttmTruncatedToDay = 'CREATED_DTTM_TRUNCATED_TO_DAY',
  CreatedDttmTruncatedToHour = 'CREATED_DTTM_TRUNCATED_TO_HOUR',
  DbtVersion = 'DBT_VERSION',
  DeferredScheduleJson = 'DEFERRED_SCHEDULE_JSON',
  EmailsJson = 'EMAILS_JSON',
  EmailOn = 'EMAIL_ON',
  Environment = 'ENVIRONMENT',
  ErrorString = 'ERROR_STRING',
  Name = 'NAME',
  Owner = 'OWNER',
  ScheduleCron = 'SCHEDULE_CRON',
  ScheduleParseId = 'SCHEDULE_PARSE_ID',
  SlackChannelsJson = 'SLACK_CHANNELS_JSON',
  SlackOn = 'SLACK_ON',
  Source = 'SOURCE',
  TurboCiJson = 'TURBO_CI_JSON',
  UpdatedDttm = 'UPDATED_DTTM',
  UpdatedDttmTruncatedToDay = 'UPDATED_DTTM_TRUNCATED_TO_DAY',
  UpdatedDttmTruncatedToHour = 'UPDATED_DTTM_TRUNCATED_TO_HOUR',
  Uuid = 'UUID'
}

export type ScheduleNameHavingAverageInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  scheduleParseId?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type ScheduleNameHavingDistinctCountInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  scheduleParseId?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `ScheduleName` aggregates. */
export type ScheduleNameHavingInput = {
  AND?: InputMaybe<Array<ScheduleNameHavingInput>>;
  OR?: InputMaybe<Array<ScheduleNameHavingInput>>;
  average?: InputMaybe<ScheduleNameHavingAverageInput>;
  distinctCount?: InputMaybe<ScheduleNameHavingDistinctCountInput>;
  max?: InputMaybe<ScheduleNameHavingMaxInput>;
  min?: InputMaybe<ScheduleNameHavingMinInput>;
  stddevPopulation?: InputMaybe<ScheduleNameHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<ScheduleNameHavingStddevSampleInput>;
  sum?: InputMaybe<ScheduleNameHavingSumInput>;
  variancePopulation?: InputMaybe<ScheduleNameHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<ScheduleNameHavingVarianceSampleInput>;
};

export type ScheduleNameHavingMaxInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  scheduleParseId?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type ScheduleNameHavingMinInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  scheduleParseId?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type ScheduleNameHavingStddevPopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  scheduleParseId?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type ScheduleNameHavingStddevSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  scheduleParseId?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type ScheduleNameHavingSumInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  scheduleParseId?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type ScheduleNameHavingVariancePopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  scheduleParseId?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type ScheduleNameHavingVarianceSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  scheduleParseId?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type ScheduleNameMaxAggregates = {
  __typename?: 'ScheduleNameMaxAggregates';
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of scheduleParseId across the matching connection */
  scheduleParseId?: Maybe<Scalars['Int']>;
};

export type ScheduleNameMinAggregates = {
  __typename?: 'ScheduleNameMinAggregates';
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of scheduleParseId across the matching connection */
  scheduleParseId?: Maybe<Scalars['Int']>;
};

export type ScheduleNameStddevPopulationAggregates = {
  __typename?: 'ScheduleNameStddevPopulationAggregates';
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of scheduleParseId across the matching connection */
  scheduleParseId?: Maybe<Scalars['BigFloat']>;
};

export type ScheduleNameStddevSampleAggregates = {
  __typename?: 'ScheduleNameStddevSampleAggregates';
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of scheduleParseId across the matching connection */
  scheduleParseId?: Maybe<Scalars['BigFloat']>;
};

export type ScheduleNameSumAggregates = {
  __typename?: 'ScheduleNameSumAggregates';
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of scheduleParseId across the matching connection */
  scheduleParseId: Scalars['BigInt'];
};

export type ScheduleNameVariancePopulationAggregates = {
  __typename?: 'ScheduleNameVariancePopulationAggregates';
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of scheduleParseId across the matching connection */
  scheduleParseId?: Maybe<Scalars['BigFloat']>;
};

export type ScheduleNameVarianceSampleAggregates = {
  __typename?: 'ScheduleNameVarianceSampleAggregates';
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of scheduleParseId across the matching connection */
  scheduleParseId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `ScheduleName` values. */
export type ScheduleNamesConnection = {
  __typename?: 'ScheduleNamesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ScheduleNameAggregates>;
  /** A list of edges which contains the `ScheduleName` and cursor to aid in pagination. */
  edges: Array<ScheduleNamesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ScheduleNameAggregates>>;
  /** A list of `ScheduleName` objects. */
  nodes: Array<ScheduleName>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ScheduleName` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `ScheduleName` values. */
export type ScheduleNamesConnectionGroupedAggregatesArgs = {
  groupBy: Array<ScheduleNameGroupBy>;
  having?: InputMaybe<ScheduleNameHavingInput>;
};

/** A `ScheduleName` edge in the connection. */
export type ScheduleNamesEdge = {
  __typename?: 'ScheduleNamesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ScheduleName` at the end of the edge. */
  node: ScheduleName;
};

/** Methods to use when ordering `ScheduleName`. */
export enum ScheduleNamesOrderBy {
  BranchAsc = 'BRANCH_ASC',
  BranchDesc = 'BRANCH_DESC',
  CommandsJsonAsc = 'COMMANDS_JSON_ASC',
  CommandsJsonDesc = 'COMMANDS_JSON_DESC',
  CommitHashAsc = 'COMMIT_HASH_ASC',
  CommitHashDesc = 'COMMIT_HASH_DESC',
  CreatedDttmAsc = 'CREATED_DTTM_ASC',
  CreatedDttmDesc = 'CREATED_DTTM_DESC',
  DbtVersionAsc = 'DBT_VERSION_ASC',
  DbtVersionDesc = 'DBT_VERSION_DESC',
  DeferredScheduleJsonAsc = 'DEFERRED_SCHEDULE_JSON_ASC',
  DeferredScheduleJsonDesc = 'DEFERRED_SCHEDULE_JSON_DESC',
  EmailsJsonAsc = 'EMAILS_JSON_ASC',
  EmailsJsonDesc = 'EMAILS_JSON_DESC',
  EmailOnAsc = 'EMAIL_ON_ASC',
  EmailOnDesc = 'EMAIL_ON_DESC',
  EnvironmentAsc = 'ENVIRONMENT_ASC',
  EnvironmentDesc = 'ENVIRONMENT_DESC',
  ErrorStringAsc = 'ERROR_STRING_ASC',
  ErrorStringDesc = 'ERROR_STRING_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  OwnerAsc = 'OWNER_ASC',
  OwnerDesc = 'OWNER_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ScheduleCronAsc = 'SCHEDULE_CRON_ASC',
  ScheduleCronDesc = 'SCHEDULE_CRON_DESC',
  ScheduleParseIdAsc = 'SCHEDULE_PARSE_ID_ASC',
  ScheduleParseIdDesc = 'SCHEDULE_PARSE_ID_DESC',
  ScheduleRunsAverageActorAsc = 'SCHEDULE_RUNS_AVERAGE_ACTOR_ASC',
  ScheduleRunsAverageActorDesc = 'SCHEDULE_RUNS_AVERAGE_ACTOR_DESC',
  ScheduleRunsAverageBranchAsc = 'SCHEDULE_RUNS_AVERAGE_BRANCH_ASC',
  ScheduleRunsAverageBranchDesc = 'SCHEDULE_RUNS_AVERAGE_BRANCH_DESC',
  ScheduleRunsAverageCommitHashAsc = 'SCHEDULE_RUNS_AVERAGE_COMMIT_HASH_ASC',
  ScheduleRunsAverageCommitHashDesc = 'SCHEDULE_RUNS_AVERAGE_COMMIT_HASH_DESC',
  ScheduleRunsAverageEndDttmAsc = 'SCHEDULE_RUNS_AVERAGE_END_DTTM_ASC',
  ScheduleRunsAverageEndDttmDesc = 'SCHEDULE_RUNS_AVERAGE_END_DTTM_DESC',
  ScheduleRunsAverageErrorStringAsc = 'SCHEDULE_RUNS_AVERAGE_ERROR_STRING_ASC',
  ScheduleRunsAverageErrorStringDesc = 'SCHEDULE_RUNS_AVERAGE_ERROR_STRING_DESC',
  ScheduleRunsAverageIdAsc = 'SCHEDULE_RUNS_AVERAGE_ID_ASC',
  ScheduleRunsAverageIdDesc = 'SCHEDULE_RUNS_AVERAGE_ID_DESC',
  ScheduleRunsAveragePodNameAsc = 'SCHEDULE_RUNS_AVERAGE_POD_NAME_ASC',
  ScheduleRunsAveragePodNameDesc = 'SCHEDULE_RUNS_AVERAGE_POD_NAME_DESC',
  ScheduleRunsAveragePullRequestIdAsc = 'SCHEDULE_RUNS_AVERAGE_PULL_REQUEST_ID_ASC',
  ScheduleRunsAveragePullRequestIdDesc = 'SCHEDULE_RUNS_AVERAGE_PULL_REQUEST_ID_DESC',
  ScheduleRunsAverageRunManuallyByEmailAsc = 'SCHEDULE_RUNS_AVERAGE_RUN_MANUALLY_BY_EMAIL_ASC',
  ScheduleRunsAverageRunManuallyByEmailDesc = 'SCHEDULE_RUNS_AVERAGE_RUN_MANUALLY_BY_EMAIL_DESC',
  ScheduleRunsAverageScheduleNameIdAsc = 'SCHEDULE_RUNS_AVERAGE_SCHEDULE_NAME_ID_ASC',
  ScheduleRunsAverageScheduleNameIdDesc = 'SCHEDULE_RUNS_AVERAGE_SCHEDULE_NAME_ID_DESC',
  ScheduleRunsAverageScheduleNameUuidAsc = 'SCHEDULE_RUNS_AVERAGE_SCHEDULE_NAME_UUID_ASC',
  ScheduleRunsAverageScheduleNameUuidDesc = 'SCHEDULE_RUNS_AVERAGE_SCHEDULE_NAME_UUID_DESC',
  ScheduleRunsAverageStartDttmAsc = 'SCHEDULE_RUNS_AVERAGE_START_DTTM_ASC',
  ScheduleRunsAverageStartDttmDesc = 'SCHEDULE_RUNS_AVERAGE_START_DTTM_DESC',
  ScheduleRunsAverageUuidAsc = 'SCHEDULE_RUNS_AVERAGE_UUID_ASC',
  ScheduleRunsAverageUuidDesc = 'SCHEDULE_RUNS_AVERAGE_UUID_DESC',
  ScheduleRunsCountAsc = 'SCHEDULE_RUNS_COUNT_ASC',
  ScheduleRunsCountDesc = 'SCHEDULE_RUNS_COUNT_DESC',
  ScheduleRunsDistinctCountActorAsc = 'SCHEDULE_RUNS_DISTINCT_COUNT_ACTOR_ASC',
  ScheduleRunsDistinctCountActorDesc = 'SCHEDULE_RUNS_DISTINCT_COUNT_ACTOR_DESC',
  ScheduleRunsDistinctCountBranchAsc = 'SCHEDULE_RUNS_DISTINCT_COUNT_BRANCH_ASC',
  ScheduleRunsDistinctCountBranchDesc = 'SCHEDULE_RUNS_DISTINCT_COUNT_BRANCH_DESC',
  ScheduleRunsDistinctCountCommitHashAsc = 'SCHEDULE_RUNS_DISTINCT_COUNT_COMMIT_HASH_ASC',
  ScheduleRunsDistinctCountCommitHashDesc = 'SCHEDULE_RUNS_DISTINCT_COUNT_COMMIT_HASH_DESC',
  ScheduleRunsDistinctCountEndDttmAsc = 'SCHEDULE_RUNS_DISTINCT_COUNT_END_DTTM_ASC',
  ScheduleRunsDistinctCountEndDttmDesc = 'SCHEDULE_RUNS_DISTINCT_COUNT_END_DTTM_DESC',
  ScheduleRunsDistinctCountErrorStringAsc = 'SCHEDULE_RUNS_DISTINCT_COUNT_ERROR_STRING_ASC',
  ScheduleRunsDistinctCountErrorStringDesc = 'SCHEDULE_RUNS_DISTINCT_COUNT_ERROR_STRING_DESC',
  ScheduleRunsDistinctCountIdAsc = 'SCHEDULE_RUNS_DISTINCT_COUNT_ID_ASC',
  ScheduleRunsDistinctCountIdDesc = 'SCHEDULE_RUNS_DISTINCT_COUNT_ID_DESC',
  ScheduleRunsDistinctCountPodNameAsc = 'SCHEDULE_RUNS_DISTINCT_COUNT_POD_NAME_ASC',
  ScheduleRunsDistinctCountPodNameDesc = 'SCHEDULE_RUNS_DISTINCT_COUNT_POD_NAME_DESC',
  ScheduleRunsDistinctCountPullRequestIdAsc = 'SCHEDULE_RUNS_DISTINCT_COUNT_PULL_REQUEST_ID_ASC',
  ScheduleRunsDistinctCountPullRequestIdDesc = 'SCHEDULE_RUNS_DISTINCT_COUNT_PULL_REQUEST_ID_DESC',
  ScheduleRunsDistinctCountRunManuallyByEmailAsc = 'SCHEDULE_RUNS_DISTINCT_COUNT_RUN_MANUALLY_BY_EMAIL_ASC',
  ScheduleRunsDistinctCountRunManuallyByEmailDesc = 'SCHEDULE_RUNS_DISTINCT_COUNT_RUN_MANUALLY_BY_EMAIL_DESC',
  ScheduleRunsDistinctCountScheduleNameIdAsc = 'SCHEDULE_RUNS_DISTINCT_COUNT_SCHEDULE_NAME_ID_ASC',
  ScheduleRunsDistinctCountScheduleNameIdDesc = 'SCHEDULE_RUNS_DISTINCT_COUNT_SCHEDULE_NAME_ID_DESC',
  ScheduleRunsDistinctCountScheduleNameUuidAsc = 'SCHEDULE_RUNS_DISTINCT_COUNT_SCHEDULE_NAME_UUID_ASC',
  ScheduleRunsDistinctCountScheduleNameUuidDesc = 'SCHEDULE_RUNS_DISTINCT_COUNT_SCHEDULE_NAME_UUID_DESC',
  ScheduleRunsDistinctCountStartDttmAsc = 'SCHEDULE_RUNS_DISTINCT_COUNT_START_DTTM_ASC',
  ScheduleRunsDistinctCountStartDttmDesc = 'SCHEDULE_RUNS_DISTINCT_COUNT_START_DTTM_DESC',
  ScheduleRunsDistinctCountUuidAsc = 'SCHEDULE_RUNS_DISTINCT_COUNT_UUID_ASC',
  ScheduleRunsDistinctCountUuidDesc = 'SCHEDULE_RUNS_DISTINCT_COUNT_UUID_DESC',
  ScheduleRunsMaxActorAsc = 'SCHEDULE_RUNS_MAX_ACTOR_ASC',
  ScheduleRunsMaxActorDesc = 'SCHEDULE_RUNS_MAX_ACTOR_DESC',
  ScheduleRunsMaxBranchAsc = 'SCHEDULE_RUNS_MAX_BRANCH_ASC',
  ScheduleRunsMaxBranchDesc = 'SCHEDULE_RUNS_MAX_BRANCH_DESC',
  ScheduleRunsMaxCommitHashAsc = 'SCHEDULE_RUNS_MAX_COMMIT_HASH_ASC',
  ScheduleRunsMaxCommitHashDesc = 'SCHEDULE_RUNS_MAX_COMMIT_HASH_DESC',
  ScheduleRunsMaxEndDttmAsc = 'SCHEDULE_RUNS_MAX_END_DTTM_ASC',
  ScheduleRunsMaxEndDttmDesc = 'SCHEDULE_RUNS_MAX_END_DTTM_DESC',
  ScheduleRunsMaxErrorStringAsc = 'SCHEDULE_RUNS_MAX_ERROR_STRING_ASC',
  ScheduleRunsMaxErrorStringDesc = 'SCHEDULE_RUNS_MAX_ERROR_STRING_DESC',
  ScheduleRunsMaxIdAsc = 'SCHEDULE_RUNS_MAX_ID_ASC',
  ScheduleRunsMaxIdDesc = 'SCHEDULE_RUNS_MAX_ID_DESC',
  ScheduleRunsMaxPodNameAsc = 'SCHEDULE_RUNS_MAX_POD_NAME_ASC',
  ScheduleRunsMaxPodNameDesc = 'SCHEDULE_RUNS_MAX_POD_NAME_DESC',
  ScheduleRunsMaxPullRequestIdAsc = 'SCHEDULE_RUNS_MAX_PULL_REQUEST_ID_ASC',
  ScheduleRunsMaxPullRequestIdDesc = 'SCHEDULE_RUNS_MAX_PULL_REQUEST_ID_DESC',
  ScheduleRunsMaxRunManuallyByEmailAsc = 'SCHEDULE_RUNS_MAX_RUN_MANUALLY_BY_EMAIL_ASC',
  ScheduleRunsMaxRunManuallyByEmailDesc = 'SCHEDULE_RUNS_MAX_RUN_MANUALLY_BY_EMAIL_DESC',
  ScheduleRunsMaxScheduleNameIdAsc = 'SCHEDULE_RUNS_MAX_SCHEDULE_NAME_ID_ASC',
  ScheduleRunsMaxScheduleNameIdDesc = 'SCHEDULE_RUNS_MAX_SCHEDULE_NAME_ID_DESC',
  ScheduleRunsMaxScheduleNameUuidAsc = 'SCHEDULE_RUNS_MAX_SCHEDULE_NAME_UUID_ASC',
  ScheduleRunsMaxScheduleNameUuidDesc = 'SCHEDULE_RUNS_MAX_SCHEDULE_NAME_UUID_DESC',
  ScheduleRunsMaxStartDttmAsc = 'SCHEDULE_RUNS_MAX_START_DTTM_ASC',
  ScheduleRunsMaxStartDttmDesc = 'SCHEDULE_RUNS_MAX_START_DTTM_DESC',
  ScheduleRunsMaxUuidAsc = 'SCHEDULE_RUNS_MAX_UUID_ASC',
  ScheduleRunsMaxUuidDesc = 'SCHEDULE_RUNS_MAX_UUID_DESC',
  ScheduleRunsMinActorAsc = 'SCHEDULE_RUNS_MIN_ACTOR_ASC',
  ScheduleRunsMinActorDesc = 'SCHEDULE_RUNS_MIN_ACTOR_DESC',
  ScheduleRunsMinBranchAsc = 'SCHEDULE_RUNS_MIN_BRANCH_ASC',
  ScheduleRunsMinBranchDesc = 'SCHEDULE_RUNS_MIN_BRANCH_DESC',
  ScheduleRunsMinCommitHashAsc = 'SCHEDULE_RUNS_MIN_COMMIT_HASH_ASC',
  ScheduleRunsMinCommitHashDesc = 'SCHEDULE_RUNS_MIN_COMMIT_HASH_DESC',
  ScheduleRunsMinEndDttmAsc = 'SCHEDULE_RUNS_MIN_END_DTTM_ASC',
  ScheduleRunsMinEndDttmDesc = 'SCHEDULE_RUNS_MIN_END_DTTM_DESC',
  ScheduleRunsMinErrorStringAsc = 'SCHEDULE_RUNS_MIN_ERROR_STRING_ASC',
  ScheduleRunsMinErrorStringDesc = 'SCHEDULE_RUNS_MIN_ERROR_STRING_DESC',
  ScheduleRunsMinIdAsc = 'SCHEDULE_RUNS_MIN_ID_ASC',
  ScheduleRunsMinIdDesc = 'SCHEDULE_RUNS_MIN_ID_DESC',
  ScheduleRunsMinPodNameAsc = 'SCHEDULE_RUNS_MIN_POD_NAME_ASC',
  ScheduleRunsMinPodNameDesc = 'SCHEDULE_RUNS_MIN_POD_NAME_DESC',
  ScheduleRunsMinPullRequestIdAsc = 'SCHEDULE_RUNS_MIN_PULL_REQUEST_ID_ASC',
  ScheduleRunsMinPullRequestIdDesc = 'SCHEDULE_RUNS_MIN_PULL_REQUEST_ID_DESC',
  ScheduleRunsMinRunManuallyByEmailAsc = 'SCHEDULE_RUNS_MIN_RUN_MANUALLY_BY_EMAIL_ASC',
  ScheduleRunsMinRunManuallyByEmailDesc = 'SCHEDULE_RUNS_MIN_RUN_MANUALLY_BY_EMAIL_DESC',
  ScheduleRunsMinScheduleNameIdAsc = 'SCHEDULE_RUNS_MIN_SCHEDULE_NAME_ID_ASC',
  ScheduleRunsMinScheduleNameIdDesc = 'SCHEDULE_RUNS_MIN_SCHEDULE_NAME_ID_DESC',
  ScheduleRunsMinScheduleNameUuidAsc = 'SCHEDULE_RUNS_MIN_SCHEDULE_NAME_UUID_ASC',
  ScheduleRunsMinScheduleNameUuidDesc = 'SCHEDULE_RUNS_MIN_SCHEDULE_NAME_UUID_DESC',
  ScheduleRunsMinStartDttmAsc = 'SCHEDULE_RUNS_MIN_START_DTTM_ASC',
  ScheduleRunsMinStartDttmDesc = 'SCHEDULE_RUNS_MIN_START_DTTM_DESC',
  ScheduleRunsMinUuidAsc = 'SCHEDULE_RUNS_MIN_UUID_ASC',
  ScheduleRunsMinUuidDesc = 'SCHEDULE_RUNS_MIN_UUID_DESC',
  ScheduleRunsStddevPopulationActorAsc = 'SCHEDULE_RUNS_STDDEV_POPULATION_ACTOR_ASC',
  ScheduleRunsStddevPopulationActorDesc = 'SCHEDULE_RUNS_STDDEV_POPULATION_ACTOR_DESC',
  ScheduleRunsStddevPopulationBranchAsc = 'SCHEDULE_RUNS_STDDEV_POPULATION_BRANCH_ASC',
  ScheduleRunsStddevPopulationBranchDesc = 'SCHEDULE_RUNS_STDDEV_POPULATION_BRANCH_DESC',
  ScheduleRunsStddevPopulationCommitHashAsc = 'SCHEDULE_RUNS_STDDEV_POPULATION_COMMIT_HASH_ASC',
  ScheduleRunsStddevPopulationCommitHashDesc = 'SCHEDULE_RUNS_STDDEV_POPULATION_COMMIT_HASH_DESC',
  ScheduleRunsStddevPopulationEndDttmAsc = 'SCHEDULE_RUNS_STDDEV_POPULATION_END_DTTM_ASC',
  ScheduleRunsStddevPopulationEndDttmDesc = 'SCHEDULE_RUNS_STDDEV_POPULATION_END_DTTM_DESC',
  ScheduleRunsStddevPopulationErrorStringAsc = 'SCHEDULE_RUNS_STDDEV_POPULATION_ERROR_STRING_ASC',
  ScheduleRunsStddevPopulationErrorStringDesc = 'SCHEDULE_RUNS_STDDEV_POPULATION_ERROR_STRING_DESC',
  ScheduleRunsStddevPopulationIdAsc = 'SCHEDULE_RUNS_STDDEV_POPULATION_ID_ASC',
  ScheduleRunsStddevPopulationIdDesc = 'SCHEDULE_RUNS_STDDEV_POPULATION_ID_DESC',
  ScheduleRunsStddevPopulationPodNameAsc = 'SCHEDULE_RUNS_STDDEV_POPULATION_POD_NAME_ASC',
  ScheduleRunsStddevPopulationPodNameDesc = 'SCHEDULE_RUNS_STDDEV_POPULATION_POD_NAME_DESC',
  ScheduleRunsStddevPopulationPullRequestIdAsc = 'SCHEDULE_RUNS_STDDEV_POPULATION_PULL_REQUEST_ID_ASC',
  ScheduleRunsStddevPopulationPullRequestIdDesc = 'SCHEDULE_RUNS_STDDEV_POPULATION_PULL_REQUEST_ID_DESC',
  ScheduleRunsStddevPopulationRunManuallyByEmailAsc = 'SCHEDULE_RUNS_STDDEV_POPULATION_RUN_MANUALLY_BY_EMAIL_ASC',
  ScheduleRunsStddevPopulationRunManuallyByEmailDesc = 'SCHEDULE_RUNS_STDDEV_POPULATION_RUN_MANUALLY_BY_EMAIL_DESC',
  ScheduleRunsStddevPopulationScheduleNameIdAsc = 'SCHEDULE_RUNS_STDDEV_POPULATION_SCHEDULE_NAME_ID_ASC',
  ScheduleRunsStddevPopulationScheduleNameIdDesc = 'SCHEDULE_RUNS_STDDEV_POPULATION_SCHEDULE_NAME_ID_DESC',
  ScheduleRunsStddevPopulationScheduleNameUuidAsc = 'SCHEDULE_RUNS_STDDEV_POPULATION_SCHEDULE_NAME_UUID_ASC',
  ScheduleRunsStddevPopulationScheduleNameUuidDesc = 'SCHEDULE_RUNS_STDDEV_POPULATION_SCHEDULE_NAME_UUID_DESC',
  ScheduleRunsStddevPopulationStartDttmAsc = 'SCHEDULE_RUNS_STDDEV_POPULATION_START_DTTM_ASC',
  ScheduleRunsStddevPopulationStartDttmDesc = 'SCHEDULE_RUNS_STDDEV_POPULATION_START_DTTM_DESC',
  ScheduleRunsStddevPopulationUuidAsc = 'SCHEDULE_RUNS_STDDEV_POPULATION_UUID_ASC',
  ScheduleRunsStddevPopulationUuidDesc = 'SCHEDULE_RUNS_STDDEV_POPULATION_UUID_DESC',
  ScheduleRunsStddevSampleActorAsc = 'SCHEDULE_RUNS_STDDEV_SAMPLE_ACTOR_ASC',
  ScheduleRunsStddevSampleActorDesc = 'SCHEDULE_RUNS_STDDEV_SAMPLE_ACTOR_DESC',
  ScheduleRunsStddevSampleBranchAsc = 'SCHEDULE_RUNS_STDDEV_SAMPLE_BRANCH_ASC',
  ScheduleRunsStddevSampleBranchDesc = 'SCHEDULE_RUNS_STDDEV_SAMPLE_BRANCH_DESC',
  ScheduleRunsStddevSampleCommitHashAsc = 'SCHEDULE_RUNS_STDDEV_SAMPLE_COMMIT_HASH_ASC',
  ScheduleRunsStddevSampleCommitHashDesc = 'SCHEDULE_RUNS_STDDEV_SAMPLE_COMMIT_HASH_DESC',
  ScheduleRunsStddevSampleEndDttmAsc = 'SCHEDULE_RUNS_STDDEV_SAMPLE_END_DTTM_ASC',
  ScheduleRunsStddevSampleEndDttmDesc = 'SCHEDULE_RUNS_STDDEV_SAMPLE_END_DTTM_DESC',
  ScheduleRunsStddevSampleErrorStringAsc = 'SCHEDULE_RUNS_STDDEV_SAMPLE_ERROR_STRING_ASC',
  ScheduleRunsStddevSampleErrorStringDesc = 'SCHEDULE_RUNS_STDDEV_SAMPLE_ERROR_STRING_DESC',
  ScheduleRunsStddevSampleIdAsc = 'SCHEDULE_RUNS_STDDEV_SAMPLE_ID_ASC',
  ScheduleRunsStddevSampleIdDesc = 'SCHEDULE_RUNS_STDDEV_SAMPLE_ID_DESC',
  ScheduleRunsStddevSamplePodNameAsc = 'SCHEDULE_RUNS_STDDEV_SAMPLE_POD_NAME_ASC',
  ScheduleRunsStddevSamplePodNameDesc = 'SCHEDULE_RUNS_STDDEV_SAMPLE_POD_NAME_DESC',
  ScheduleRunsStddevSamplePullRequestIdAsc = 'SCHEDULE_RUNS_STDDEV_SAMPLE_PULL_REQUEST_ID_ASC',
  ScheduleRunsStddevSamplePullRequestIdDesc = 'SCHEDULE_RUNS_STDDEV_SAMPLE_PULL_REQUEST_ID_DESC',
  ScheduleRunsStddevSampleRunManuallyByEmailAsc = 'SCHEDULE_RUNS_STDDEV_SAMPLE_RUN_MANUALLY_BY_EMAIL_ASC',
  ScheduleRunsStddevSampleRunManuallyByEmailDesc = 'SCHEDULE_RUNS_STDDEV_SAMPLE_RUN_MANUALLY_BY_EMAIL_DESC',
  ScheduleRunsStddevSampleScheduleNameIdAsc = 'SCHEDULE_RUNS_STDDEV_SAMPLE_SCHEDULE_NAME_ID_ASC',
  ScheduleRunsStddevSampleScheduleNameIdDesc = 'SCHEDULE_RUNS_STDDEV_SAMPLE_SCHEDULE_NAME_ID_DESC',
  ScheduleRunsStddevSampleScheduleNameUuidAsc = 'SCHEDULE_RUNS_STDDEV_SAMPLE_SCHEDULE_NAME_UUID_ASC',
  ScheduleRunsStddevSampleScheduleNameUuidDesc = 'SCHEDULE_RUNS_STDDEV_SAMPLE_SCHEDULE_NAME_UUID_DESC',
  ScheduleRunsStddevSampleStartDttmAsc = 'SCHEDULE_RUNS_STDDEV_SAMPLE_START_DTTM_ASC',
  ScheduleRunsStddevSampleStartDttmDesc = 'SCHEDULE_RUNS_STDDEV_SAMPLE_START_DTTM_DESC',
  ScheduleRunsStddevSampleUuidAsc = 'SCHEDULE_RUNS_STDDEV_SAMPLE_UUID_ASC',
  ScheduleRunsStddevSampleUuidDesc = 'SCHEDULE_RUNS_STDDEV_SAMPLE_UUID_DESC',
  ScheduleRunsSumActorAsc = 'SCHEDULE_RUNS_SUM_ACTOR_ASC',
  ScheduleRunsSumActorDesc = 'SCHEDULE_RUNS_SUM_ACTOR_DESC',
  ScheduleRunsSumBranchAsc = 'SCHEDULE_RUNS_SUM_BRANCH_ASC',
  ScheduleRunsSumBranchDesc = 'SCHEDULE_RUNS_SUM_BRANCH_DESC',
  ScheduleRunsSumCommitHashAsc = 'SCHEDULE_RUNS_SUM_COMMIT_HASH_ASC',
  ScheduleRunsSumCommitHashDesc = 'SCHEDULE_RUNS_SUM_COMMIT_HASH_DESC',
  ScheduleRunsSumEndDttmAsc = 'SCHEDULE_RUNS_SUM_END_DTTM_ASC',
  ScheduleRunsSumEndDttmDesc = 'SCHEDULE_RUNS_SUM_END_DTTM_DESC',
  ScheduleRunsSumErrorStringAsc = 'SCHEDULE_RUNS_SUM_ERROR_STRING_ASC',
  ScheduleRunsSumErrorStringDesc = 'SCHEDULE_RUNS_SUM_ERROR_STRING_DESC',
  ScheduleRunsSumIdAsc = 'SCHEDULE_RUNS_SUM_ID_ASC',
  ScheduleRunsSumIdDesc = 'SCHEDULE_RUNS_SUM_ID_DESC',
  ScheduleRunsSumPodNameAsc = 'SCHEDULE_RUNS_SUM_POD_NAME_ASC',
  ScheduleRunsSumPodNameDesc = 'SCHEDULE_RUNS_SUM_POD_NAME_DESC',
  ScheduleRunsSumPullRequestIdAsc = 'SCHEDULE_RUNS_SUM_PULL_REQUEST_ID_ASC',
  ScheduleRunsSumPullRequestIdDesc = 'SCHEDULE_RUNS_SUM_PULL_REQUEST_ID_DESC',
  ScheduleRunsSumRunManuallyByEmailAsc = 'SCHEDULE_RUNS_SUM_RUN_MANUALLY_BY_EMAIL_ASC',
  ScheduleRunsSumRunManuallyByEmailDesc = 'SCHEDULE_RUNS_SUM_RUN_MANUALLY_BY_EMAIL_DESC',
  ScheduleRunsSumScheduleNameIdAsc = 'SCHEDULE_RUNS_SUM_SCHEDULE_NAME_ID_ASC',
  ScheduleRunsSumScheduleNameIdDesc = 'SCHEDULE_RUNS_SUM_SCHEDULE_NAME_ID_DESC',
  ScheduleRunsSumScheduleNameUuidAsc = 'SCHEDULE_RUNS_SUM_SCHEDULE_NAME_UUID_ASC',
  ScheduleRunsSumScheduleNameUuidDesc = 'SCHEDULE_RUNS_SUM_SCHEDULE_NAME_UUID_DESC',
  ScheduleRunsSumStartDttmAsc = 'SCHEDULE_RUNS_SUM_START_DTTM_ASC',
  ScheduleRunsSumStartDttmDesc = 'SCHEDULE_RUNS_SUM_START_DTTM_DESC',
  ScheduleRunsSumUuidAsc = 'SCHEDULE_RUNS_SUM_UUID_ASC',
  ScheduleRunsSumUuidDesc = 'SCHEDULE_RUNS_SUM_UUID_DESC',
  ScheduleRunsVariancePopulationActorAsc = 'SCHEDULE_RUNS_VARIANCE_POPULATION_ACTOR_ASC',
  ScheduleRunsVariancePopulationActorDesc = 'SCHEDULE_RUNS_VARIANCE_POPULATION_ACTOR_DESC',
  ScheduleRunsVariancePopulationBranchAsc = 'SCHEDULE_RUNS_VARIANCE_POPULATION_BRANCH_ASC',
  ScheduleRunsVariancePopulationBranchDesc = 'SCHEDULE_RUNS_VARIANCE_POPULATION_BRANCH_DESC',
  ScheduleRunsVariancePopulationCommitHashAsc = 'SCHEDULE_RUNS_VARIANCE_POPULATION_COMMIT_HASH_ASC',
  ScheduleRunsVariancePopulationCommitHashDesc = 'SCHEDULE_RUNS_VARIANCE_POPULATION_COMMIT_HASH_DESC',
  ScheduleRunsVariancePopulationEndDttmAsc = 'SCHEDULE_RUNS_VARIANCE_POPULATION_END_DTTM_ASC',
  ScheduleRunsVariancePopulationEndDttmDesc = 'SCHEDULE_RUNS_VARIANCE_POPULATION_END_DTTM_DESC',
  ScheduleRunsVariancePopulationErrorStringAsc = 'SCHEDULE_RUNS_VARIANCE_POPULATION_ERROR_STRING_ASC',
  ScheduleRunsVariancePopulationErrorStringDesc = 'SCHEDULE_RUNS_VARIANCE_POPULATION_ERROR_STRING_DESC',
  ScheduleRunsVariancePopulationIdAsc = 'SCHEDULE_RUNS_VARIANCE_POPULATION_ID_ASC',
  ScheduleRunsVariancePopulationIdDesc = 'SCHEDULE_RUNS_VARIANCE_POPULATION_ID_DESC',
  ScheduleRunsVariancePopulationPodNameAsc = 'SCHEDULE_RUNS_VARIANCE_POPULATION_POD_NAME_ASC',
  ScheduleRunsVariancePopulationPodNameDesc = 'SCHEDULE_RUNS_VARIANCE_POPULATION_POD_NAME_DESC',
  ScheduleRunsVariancePopulationPullRequestIdAsc = 'SCHEDULE_RUNS_VARIANCE_POPULATION_PULL_REQUEST_ID_ASC',
  ScheduleRunsVariancePopulationPullRequestIdDesc = 'SCHEDULE_RUNS_VARIANCE_POPULATION_PULL_REQUEST_ID_DESC',
  ScheduleRunsVariancePopulationRunManuallyByEmailAsc = 'SCHEDULE_RUNS_VARIANCE_POPULATION_RUN_MANUALLY_BY_EMAIL_ASC',
  ScheduleRunsVariancePopulationRunManuallyByEmailDesc = 'SCHEDULE_RUNS_VARIANCE_POPULATION_RUN_MANUALLY_BY_EMAIL_DESC',
  ScheduleRunsVariancePopulationScheduleNameIdAsc = 'SCHEDULE_RUNS_VARIANCE_POPULATION_SCHEDULE_NAME_ID_ASC',
  ScheduleRunsVariancePopulationScheduleNameIdDesc = 'SCHEDULE_RUNS_VARIANCE_POPULATION_SCHEDULE_NAME_ID_DESC',
  ScheduleRunsVariancePopulationScheduleNameUuidAsc = 'SCHEDULE_RUNS_VARIANCE_POPULATION_SCHEDULE_NAME_UUID_ASC',
  ScheduleRunsVariancePopulationScheduleNameUuidDesc = 'SCHEDULE_RUNS_VARIANCE_POPULATION_SCHEDULE_NAME_UUID_DESC',
  ScheduleRunsVariancePopulationStartDttmAsc = 'SCHEDULE_RUNS_VARIANCE_POPULATION_START_DTTM_ASC',
  ScheduleRunsVariancePopulationStartDttmDesc = 'SCHEDULE_RUNS_VARIANCE_POPULATION_START_DTTM_DESC',
  ScheduleRunsVariancePopulationUuidAsc = 'SCHEDULE_RUNS_VARIANCE_POPULATION_UUID_ASC',
  ScheduleRunsVariancePopulationUuidDesc = 'SCHEDULE_RUNS_VARIANCE_POPULATION_UUID_DESC',
  ScheduleRunsVarianceSampleActorAsc = 'SCHEDULE_RUNS_VARIANCE_SAMPLE_ACTOR_ASC',
  ScheduleRunsVarianceSampleActorDesc = 'SCHEDULE_RUNS_VARIANCE_SAMPLE_ACTOR_DESC',
  ScheduleRunsVarianceSampleBranchAsc = 'SCHEDULE_RUNS_VARIANCE_SAMPLE_BRANCH_ASC',
  ScheduleRunsVarianceSampleBranchDesc = 'SCHEDULE_RUNS_VARIANCE_SAMPLE_BRANCH_DESC',
  ScheduleRunsVarianceSampleCommitHashAsc = 'SCHEDULE_RUNS_VARIANCE_SAMPLE_COMMIT_HASH_ASC',
  ScheduleRunsVarianceSampleCommitHashDesc = 'SCHEDULE_RUNS_VARIANCE_SAMPLE_COMMIT_HASH_DESC',
  ScheduleRunsVarianceSampleEndDttmAsc = 'SCHEDULE_RUNS_VARIANCE_SAMPLE_END_DTTM_ASC',
  ScheduleRunsVarianceSampleEndDttmDesc = 'SCHEDULE_RUNS_VARIANCE_SAMPLE_END_DTTM_DESC',
  ScheduleRunsVarianceSampleErrorStringAsc = 'SCHEDULE_RUNS_VARIANCE_SAMPLE_ERROR_STRING_ASC',
  ScheduleRunsVarianceSampleErrorStringDesc = 'SCHEDULE_RUNS_VARIANCE_SAMPLE_ERROR_STRING_DESC',
  ScheduleRunsVarianceSampleIdAsc = 'SCHEDULE_RUNS_VARIANCE_SAMPLE_ID_ASC',
  ScheduleRunsVarianceSampleIdDesc = 'SCHEDULE_RUNS_VARIANCE_SAMPLE_ID_DESC',
  ScheduleRunsVarianceSamplePodNameAsc = 'SCHEDULE_RUNS_VARIANCE_SAMPLE_POD_NAME_ASC',
  ScheduleRunsVarianceSamplePodNameDesc = 'SCHEDULE_RUNS_VARIANCE_SAMPLE_POD_NAME_DESC',
  ScheduleRunsVarianceSamplePullRequestIdAsc = 'SCHEDULE_RUNS_VARIANCE_SAMPLE_PULL_REQUEST_ID_ASC',
  ScheduleRunsVarianceSamplePullRequestIdDesc = 'SCHEDULE_RUNS_VARIANCE_SAMPLE_PULL_REQUEST_ID_DESC',
  ScheduleRunsVarianceSampleRunManuallyByEmailAsc = 'SCHEDULE_RUNS_VARIANCE_SAMPLE_RUN_MANUALLY_BY_EMAIL_ASC',
  ScheduleRunsVarianceSampleRunManuallyByEmailDesc = 'SCHEDULE_RUNS_VARIANCE_SAMPLE_RUN_MANUALLY_BY_EMAIL_DESC',
  ScheduleRunsVarianceSampleScheduleNameIdAsc = 'SCHEDULE_RUNS_VARIANCE_SAMPLE_SCHEDULE_NAME_ID_ASC',
  ScheduleRunsVarianceSampleScheduleNameIdDesc = 'SCHEDULE_RUNS_VARIANCE_SAMPLE_SCHEDULE_NAME_ID_DESC',
  ScheduleRunsVarianceSampleScheduleNameUuidAsc = 'SCHEDULE_RUNS_VARIANCE_SAMPLE_SCHEDULE_NAME_UUID_ASC',
  ScheduleRunsVarianceSampleScheduleNameUuidDesc = 'SCHEDULE_RUNS_VARIANCE_SAMPLE_SCHEDULE_NAME_UUID_DESC',
  ScheduleRunsVarianceSampleStartDttmAsc = 'SCHEDULE_RUNS_VARIANCE_SAMPLE_START_DTTM_ASC',
  ScheduleRunsVarianceSampleStartDttmDesc = 'SCHEDULE_RUNS_VARIANCE_SAMPLE_START_DTTM_DESC',
  ScheduleRunsVarianceSampleUuidAsc = 'SCHEDULE_RUNS_VARIANCE_SAMPLE_UUID_ASC',
  ScheduleRunsVarianceSampleUuidDesc = 'SCHEDULE_RUNS_VARIANCE_SAMPLE_UUID_DESC',
  SlackChannelsJsonAsc = 'SLACK_CHANNELS_JSON_ASC',
  SlackChannelsJsonDesc = 'SLACK_CHANNELS_JSON_DESC',
  SlackOnAsc = 'SLACK_ON_ASC',
  SlackOnDesc = 'SLACK_ON_DESC',
  SourceAsc = 'SOURCE_ASC',
  SourceDesc = 'SOURCE_DESC',
  TurboCiJsonAsc = 'TURBO_CI_JSON_ASC',
  TurboCiJsonDesc = 'TURBO_CI_JSON_DESC',
  UpdatedDttmAsc = 'UPDATED_DTTM_ASC',
  UpdatedDttmDesc = 'UPDATED_DTTM_DESC',
  UuidAsc = 'UUID_ASC',
  UuidDesc = 'UUID_DESC'
}

export type ScheduleParse = Node & {
  __typename?: 'ScheduleParse';
  completedParseDttm?: Maybe<Scalars['Datetime']>;
  createdDttm: Scalars['Datetime'];
  errorString?: Maybe<Scalars['String']>;
  gitHash?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `ScheduleName`. */
  scheduleNames: ScheduleNamesConnection;
  workspaceId: Scalars['Int'];
};


export type ScheduleParseScheduleNamesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ScheduleNameCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ScheduleNamesOrderBy>>;
};

export type ScheduleParseAggregates = {
  __typename?: 'ScheduleParseAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<ScheduleParseAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ScheduleParseDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<ScheduleParseMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<ScheduleParseMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<ScheduleParseStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<ScheduleParseStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<ScheduleParseSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<ScheduleParseVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<ScheduleParseVarianceSampleAggregates>;
};

export type ScheduleParseAverageAggregates = {
  __typename?: 'ScheduleParseAverageAggregates';
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `ScheduleParse` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ScheduleParseCondition = {
  /** Checks for equality with the object’s `completedParseDttm` field. */
  completedParseDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdDttm` field. */
  createdDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `errorString` field. */
  errorString?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `gitHash` field. */
  gitHash?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `workspaceId` field. */
  workspaceId?: InputMaybe<Scalars['Int']>;
};

export type ScheduleParseDistinctCountAggregates = {
  __typename?: 'ScheduleParseDistinctCountAggregates';
  /** Distinct count of completedParseDttm across the matching connection */
  completedParseDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdDttm across the matching connection */
  createdDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of errorString across the matching connection */
  errorString?: Maybe<Scalars['BigInt']>;
  /** Distinct count of gitHash across the matching connection */
  gitHash?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigInt']>;
};

/** Grouping methods for `ScheduleParse` for usage during aggregation. */
export enum ScheduleParseGroupBy {
  CompletedParseDttm = 'COMPLETED_PARSE_DTTM',
  CompletedParseDttmTruncatedToDay = 'COMPLETED_PARSE_DTTM_TRUNCATED_TO_DAY',
  CompletedParseDttmTruncatedToHour = 'COMPLETED_PARSE_DTTM_TRUNCATED_TO_HOUR',
  CreatedDttm = 'CREATED_DTTM',
  CreatedDttmTruncatedToDay = 'CREATED_DTTM_TRUNCATED_TO_DAY',
  CreatedDttmTruncatedToHour = 'CREATED_DTTM_TRUNCATED_TO_HOUR',
  ErrorString = 'ERROR_STRING',
  GitHash = 'GIT_HASH',
  WorkspaceId = 'WORKSPACE_ID'
}

export type ScheduleParseHavingAverageInput = {
  completedParseDttm?: InputMaybe<HavingDatetimeFilter>;
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleParseHavingDistinctCountInput = {
  completedParseDttm?: InputMaybe<HavingDatetimeFilter>;
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `ScheduleParse` aggregates. */
export type ScheduleParseHavingInput = {
  AND?: InputMaybe<Array<ScheduleParseHavingInput>>;
  OR?: InputMaybe<Array<ScheduleParseHavingInput>>;
  average?: InputMaybe<ScheduleParseHavingAverageInput>;
  distinctCount?: InputMaybe<ScheduleParseHavingDistinctCountInput>;
  max?: InputMaybe<ScheduleParseHavingMaxInput>;
  min?: InputMaybe<ScheduleParseHavingMinInput>;
  stddevPopulation?: InputMaybe<ScheduleParseHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<ScheduleParseHavingStddevSampleInput>;
  sum?: InputMaybe<ScheduleParseHavingSumInput>;
  variancePopulation?: InputMaybe<ScheduleParseHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<ScheduleParseHavingVarianceSampleInput>;
};

export type ScheduleParseHavingMaxInput = {
  completedParseDttm?: InputMaybe<HavingDatetimeFilter>;
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleParseHavingMinInput = {
  completedParseDttm?: InputMaybe<HavingDatetimeFilter>;
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleParseHavingStddevPopulationInput = {
  completedParseDttm?: InputMaybe<HavingDatetimeFilter>;
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleParseHavingStddevSampleInput = {
  completedParseDttm?: InputMaybe<HavingDatetimeFilter>;
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleParseHavingSumInput = {
  completedParseDttm?: InputMaybe<HavingDatetimeFilter>;
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleParseHavingVariancePopulationInput = {
  completedParseDttm?: InputMaybe<HavingDatetimeFilter>;
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleParseHavingVarianceSampleInput = {
  completedParseDttm?: InputMaybe<HavingDatetimeFilter>;
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleParseMaxAggregates = {
  __typename?: 'ScheduleParseMaxAggregates';
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['Int']>;
};

export type ScheduleParseMinAggregates = {
  __typename?: 'ScheduleParseMinAggregates';
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['Int']>;
};

export type ScheduleParseStddevPopulationAggregates = {
  __typename?: 'ScheduleParseStddevPopulationAggregates';
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type ScheduleParseStddevSampleAggregates = {
  __typename?: 'ScheduleParseStddevSampleAggregates';
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type ScheduleParseSumAggregates = {
  __typename?: 'ScheduleParseSumAggregates';
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of workspaceId across the matching connection */
  workspaceId: Scalars['BigInt'];
};

export type ScheduleParseVariancePopulationAggregates = {
  __typename?: 'ScheduleParseVariancePopulationAggregates';
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type ScheduleParseVarianceSampleAggregates = {
  __typename?: 'ScheduleParseVarianceSampleAggregates';
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `ScheduleParse` values. */
export type ScheduleParsesConnection = {
  __typename?: 'ScheduleParsesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ScheduleParseAggregates>;
  /** A list of edges which contains the `ScheduleParse` and cursor to aid in pagination. */
  edges: Array<ScheduleParsesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ScheduleParseAggregates>>;
  /** A list of `ScheduleParse` objects. */
  nodes: Array<ScheduleParse>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ScheduleParse` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `ScheduleParse` values. */
export type ScheduleParsesConnectionGroupedAggregatesArgs = {
  groupBy: Array<ScheduleParseGroupBy>;
  having?: InputMaybe<ScheduleParseHavingInput>;
};

/** A `ScheduleParse` edge in the connection. */
export type ScheduleParsesEdge = {
  __typename?: 'ScheduleParsesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ScheduleParse` at the end of the edge. */
  node: ScheduleParse;
};

/** Methods to use when ordering `ScheduleParse`. */
export enum ScheduleParsesOrderBy {
  CompletedParseDttmAsc = 'COMPLETED_PARSE_DTTM_ASC',
  CompletedParseDttmDesc = 'COMPLETED_PARSE_DTTM_DESC',
  CreatedDttmAsc = 'CREATED_DTTM_ASC',
  CreatedDttmDesc = 'CREATED_DTTM_DESC',
  ErrorStringAsc = 'ERROR_STRING_ASC',
  ErrorStringDesc = 'ERROR_STRING_DESC',
  GitHashAsc = 'GIT_HASH_ASC',
  GitHashDesc = 'GIT_HASH_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ScheduleNamesAverageBranchAsc = 'SCHEDULE_NAMES_AVERAGE_BRANCH_ASC',
  ScheduleNamesAverageBranchDesc = 'SCHEDULE_NAMES_AVERAGE_BRANCH_DESC',
  ScheduleNamesAverageCommandsJsonAsc = 'SCHEDULE_NAMES_AVERAGE_COMMANDS_JSON_ASC',
  ScheduleNamesAverageCommandsJsonDesc = 'SCHEDULE_NAMES_AVERAGE_COMMANDS_JSON_DESC',
  ScheduleNamesAverageCommitHashAsc = 'SCHEDULE_NAMES_AVERAGE_COMMIT_HASH_ASC',
  ScheduleNamesAverageCommitHashDesc = 'SCHEDULE_NAMES_AVERAGE_COMMIT_HASH_DESC',
  ScheduleNamesAverageCreatedDttmAsc = 'SCHEDULE_NAMES_AVERAGE_CREATED_DTTM_ASC',
  ScheduleNamesAverageCreatedDttmDesc = 'SCHEDULE_NAMES_AVERAGE_CREATED_DTTM_DESC',
  ScheduleNamesAverageDbtVersionAsc = 'SCHEDULE_NAMES_AVERAGE_DBT_VERSION_ASC',
  ScheduleNamesAverageDbtVersionDesc = 'SCHEDULE_NAMES_AVERAGE_DBT_VERSION_DESC',
  ScheduleNamesAverageDeferredScheduleJsonAsc = 'SCHEDULE_NAMES_AVERAGE_DEFERRED_SCHEDULE_JSON_ASC',
  ScheduleNamesAverageDeferredScheduleJsonDesc = 'SCHEDULE_NAMES_AVERAGE_DEFERRED_SCHEDULE_JSON_DESC',
  ScheduleNamesAverageEmailsJsonAsc = 'SCHEDULE_NAMES_AVERAGE_EMAILS_JSON_ASC',
  ScheduleNamesAverageEmailsJsonDesc = 'SCHEDULE_NAMES_AVERAGE_EMAILS_JSON_DESC',
  ScheduleNamesAverageEmailOnAsc = 'SCHEDULE_NAMES_AVERAGE_EMAIL_ON_ASC',
  ScheduleNamesAverageEmailOnDesc = 'SCHEDULE_NAMES_AVERAGE_EMAIL_ON_DESC',
  ScheduleNamesAverageEnvironmentAsc = 'SCHEDULE_NAMES_AVERAGE_ENVIRONMENT_ASC',
  ScheduleNamesAverageEnvironmentDesc = 'SCHEDULE_NAMES_AVERAGE_ENVIRONMENT_DESC',
  ScheduleNamesAverageErrorStringAsc = 'SCHEDULE_NAMES_AVERAGE_ERROR_STRING_ASC',
  ScheduleNamesAverageErrorStringDesc = 'SCHEDULE_NAMES_AVERAGE_ERROR_STRING_DESC',
  ScheduleNamesAverageIdAsc = 'SCHEDULE_NAMES_AVERAGE_ID_ASC',
  ScheduleNamesAverageIdDesc = 'SCHEDULE_NAMES_AVERAGE_ID_DESC',
  ScheduleNamesAverageNameAsc = 'SCHEDULE_NAMES_AVERAGE_NAME_ASC',
  ScheduleNamesAverageNameDesc = 'SCHEDULE_NAMES_AVERAGE_NAME_DESC',
  ScheduleNamesAverageOwnerAsc = 'SCHEDULE_NAMES_AVERAGE_OWNER_ASC',
  ScheduleNamesAverageOwnerDesc = 'SCHEDULE_NAMES_AVERAGE_OWNER_DESC',
  ScheduleNamesAverageScheduleCronAsc = 'SCHEDULE_NAMES_AVERAGE_SCHEDULE_CRON_ASC',
  ScheduleNamesAverageScheduleCronDesc = 'SCHEDULE_NAMES_AVERAGE_SCHEDULE_CRON_DESC',
  ScheduleNamesAverageScheduleParseIdAsc = 'SCHEDULE_NAMES_AVERAGE_SCHEDULE_PARSE_ID_ASC',
  ScheduleNamesAverageScheduleParseIdDesc = 'SCHEDULE_NAMES_AVERAGE_SCHEDULE_PARSE_ID_DESC',
  ScheduleNamesAverageSlackChannelsJsonAsc = 'SCHEDULE_NAMES_AVERAGE_SLACK_CHANNELS_JSON_ASC',
  ScheduleNamesAverageSlackChannelsJsonDesc = 'SCHEDULE_NAMES_AVERAGE_SLACK_CHANNELS_JSON_DESC',
  ScheduleNamesAverageSlackOnAsc = 'SCHEDULE_NAMES_AVERAGE_SLACK_ON_ASC',
  ScheduleNamesAverageSlackOnDesc = 'SCHEDULE_NAMES_AVERAGE_SLACK_ON_DESC',
  ScheduleNamesAverageSourceAsc = 'SCHEDULE_NAMES_AVERAGE_SOURCE_ASC',
  ScheduleNamesAverageSourceDesc = 'SCHEDULE_NAMES_AVERAGE_SOURCE_DESC',
  ScheduleNamesAverageTurboCiJsonAsc = 'SCHEDULE_NAMES_AVERAGE_TURBO_CI_JSON_ASC',
  ScheduleNamesAverageTurboCiJsonDesc = 'SCHEDULE_NAMES_AVERAGE_TURBO_CI_JSON_DESC',
  ScheduleNamesAverageUpdatedDttmAsc = 'SCHEDULE_NAMES_AVERAGE_UPDATED_DTTM_ASC',
  ScheduleNamesAverageUpdatedDttmDesc = 'SCHEDULE_NAMES_AVERAGE_UPDATED_DTTM_DESC',
  ScheduleNamesAverageUuidAsc = 'SCHEDULE_NAMES_AVERAGE_UUID_ASC',
  ScheduleNamesAverageUuidDesc = 'SCHEDULE_NAMES_AVERAGE_UUID_DESC',
  ScheduleNamesCountAsc = 'SCHEDULE_NAMES_COUNT_ASC',
  ScheduleNamesCountDesc = 'SCHEDULE_NAMES_COUNT_DESC',
  ScheduleNamesDistinctCountBranchAsc = 'SCHEDULE_NAMES_DISTINCT_COUNT_BRANCH_ASC',
  ScheduleNamesDistinctCountBranchDesc = 'SCHEDULE_NAMES_DISTINCT_COUNT_BRANCH_DESC',
  ScheduleNamesDistinctCountCommandsJsonAsc = 'SCHEDULE_NAMES_DISTINCT_COUNT_COMMANDS_JSON_ASC',
  ScheduleNamesDistinctCountCommandsJsonDesc = 'SCHEDULE_NAMES_DISTINCT_COUNT_COMMANDS_JSON_DESC',
  ScheduleNamesDistinctCountCommitHashAsc = 'SCHEDULE_NAMES_DISTINCT_COUNT_COMMIT_HASH_ASC',
  ScheduleNamesDistinctCountCommitHashDesc = 'SCHEDULE_NAMES_DISTINCT_COUNT_COMMIT_HASH_DESC',
  ScheduleNamesDistinctCountCreatedDttmAsc = 'SCHEDULE_NAMES_DISTINCT_COUNT_CREATED_DTTM_ASC',
  ScheduleNamesDistinctCountCreatedDttmDesc = 'SCHEDULE_NAMES_DISTINCT_COUNT_CREATED_DTTM_DESC',
  ScheduleNamesDistinctCountDbtVersionAsc = 'SCHEDULE_NAMES_DISTINCT_COUNT_DBT_VERSION_ASC',
  ScheduleNamesDistinctCountDbtVersionDesc = 'SCHEDULE_NAMES_DISTINCT_COUNT_DBT_VERSION_DESC',
  ScheduleNamesDistinctCountDeferredScheduleJsonAsc = 'SCHEDULE_NAMES_DISTINCT_COUNT_DEFERRED_SCHEDULE_JSON_ASC',
  ScheduleNamesDistinctCountDeferredScheduleJsonDesc = 'SCHEDULE_NAMES_DISTINCT_COUNT_DEFERRED_SCHEDULE_JSON_DESC',
  ScheduleNamesDistinctCountEmailsJsonAsc = 'SCHEDULE_NAMES_DISTINCT_COUNT_EMAILS_JSON_ASC',
  ScheduleNamesDistinctCountEmailsJsonDesc = 'SCHEDULE_NAMES_DISTINCT_COUNT_EMAILS_JSON_DESC',
  ScheduleNamesDistinctCountEmailOnAsc = 'SCHEDULE_NAMES_DISTINCT_COUNT_EMAIL_ON_ASC',
  ScheduleNamesDistinctCountEmailOnDesc = 'SCHEDULE_NAMES_DISTINCT_COUNT_EMAIL_ON_DESC',
  ScheduleNamesDistinctCountEnvironmentAsc = 'SCHEDULE_NAMES_DISTINCT_COUNT_ENVIRONMENT_ASC',
  ScheduleNamesDistinctCountEnvironmentDesc = 'SCHEDULE_NAMES_DISTINCT_COUNT_ENVIRONMENT_DESC',
  ScheduleNamesDistinctCountErrorStringAsc = 'SCHEDULE_NAMES_DISTINCT_COUNT_ERROR_STRING_ASC',
  ScheduleNamesDistinctCountErrorStringDesc = 'SCHEDULE_NAMES_DISTINCT_COUNT_ERROR_STRING_DESC',
  ScheduleNamesDistinctCountIdAsc = 'SCHEDULE_NAMES_DISTINCT_COUNT_ID_ASC',
  ScheduleNamesDistinctCountIdDesc = 'SCHEDULE_NAMES_DISTINCT_COUNT_ID_DESC',
  ScheduleNamesDistinctCountNameAsc = 'SCHEDULE_NAMES_DISTINCT_COUNT_NAME_ASC',
  ScheduleNamesDistinctCountNameDesc = 'SCHEDULE_NAMES_DISTINCT_COUNT_NAME_DESC',
  ScheduleNamesDistinctCountOwnerAsc = 'SCHEDULE_NAMES_DISTINCT_COUNT_OWNER_ASC',
  ScheduleNamesDistinctCountOwnerDesc = 'SCHEDULE_NAMES_DISTINCT_COUNT_OWNER_DESC',
  ScheduleNamesDistinctCountScheduleCronAsc = 'SCHEDULE_NAMES_DISTINCT_COUNT_SCHEDULE_CRON_ASC',
  ScheduleNamesDistinctCountScheduleCronDesc = 'SCHEDULE_NAMES_DISTINCT_COUNT_SCHEDULE_CRON_DESC',
  ScheduleNamesDistinctCountScheduleParseIdAsc = 'SCHEDULE_NAMES_DISTINCT_COUNT_SCHEDULE_PARSE_ID_ASC',
  ScheduleNamesDistinctCountScheduleParseIdDesc = 'SCHEDULE_NAMES_DISTINCT_COUNT_SCHEDULE_PARSE_ID_DESC',
  ScheduleNamesDistinctCountSlackChannelsJsonAsc = 'SCHEDULE_NAMES_DISTINCT_COUNT_SLACK_CHANNELS_JSON_ASC',
  ScheduleNamesDistinctCountSlackChannelsJsonDesc = 'SCHEDULE_NAMES_DISTINCT_COUNT_SLACK_CHANNELS_JSON_DESC',
  ScheduleNamesDistinctCountSlackOnAsc = 'SCHEDULE_NAMES_DISTINCT_COUNT_SLACK_ON_ASC',
  ScheduleNamesDistinctCountSlackOnDesc = 'SCHEDULE_NAMES_DISTINCT_COUNT_SLACK_ON_DESC',
  ScheduleNamesDistinctCountSourceAsc = 'SCHEDULE_NAMES_DISTINCT_COUNT_SOURCE_ASC',
  ScheduleNamesDistinctCountSourceDesc = 'SCHEDULE_NAMES_DISTINCT_COUNT_SOURCE_DESC',
  ScheduleNamesDistinctCountTurboCiJsonAsc = 'SCHEDULE_NAMES_DISTINCT_COUNT_TURBO_CI_JSON_ASC',
  ScheduleNamesDistinctCountTurboCiJsonDesc = 'SCHEDULE_NAMES_DISTINCT_COUNT_TURBO_CI_JSON_DESC',
  ScheduleNamesDistinctCountUpdatedDttmAsc = 'SCHEDULE_NAMES_DISTINCT_COUNT_UPDATED_DTTM_ASC',
  ScheduleNamesDistinctCountUpdatedDttmDesc = 'SCHEDULE_NAMES_DISTINCT_COUNT_UPDATED_DTTM_DESC',
  ScheduleNamesDistinctCountUuidAsc = 'SCHEDULE_NAMES_DISTINCT_COUNT_UUID_ASC',
  ScheduleNamesDistinctCountUuidDesc = 'SCHEDULE_NAMES_DISTINCT_COUNT_UUID_DESC',
  ScheduleNamesMaxBranchAsc = 'SCHEDULE_NAMES_MAX_BRANCH_ASC',
  ScheduleNamesMaxBranchDesc = 'SCHEDULE_NAMES_MAX_BRANCH_DESC',
  ScheduleNamesMaxCommandsJsonAsc = 'SCHEDULE_NAMES_MAX_COMMANDS_JSON_ASC',
  ScheduleNamesMaxCommandsJsonDesc = 'SCHEDULE_NAMES_MAX_COMMANDS_JSON_DESC',
  ScheduleNamesMaxCommitHashAsc = 'SCHEDULE_NAMES_MAX_COMMIT_HASH_ASC',
  ScheduleNamesMaxCommitHashDesc = 'SCHEDULE_NAMES_MAX_COMMIT_HASH_DESC',
  ScheduleNamesMaxCreatedDttmAsc = 'SCHEDULE_NAMES_MAX_CREATED_DTTM_ASC',
  ScheduleNamesMaxCreatedDttmDesc = 'SCHEDULE_NAMES_MAX_CREATED_DTTM_DESC',
  ScheduleNamesMaxDbtVersionAsc = 'SCHEDULE_NAMES_MAX_DBT_VERSION_ASC',
  ScheduleNamesMaxDbtVersionDesc = 'SCHEDULE_NAMES_MAX_DBT_VERSION_DESC',
  ScheduleNamesMaxDeferredScheduleJsonAsc = 'SCHEDULE_NAMES_MAX_DEFERRED_SCHEDULE_JSON_ASC',
  ScheduleNamesMaxDeferredScheduleJsonDesc = 'SCHEDULE_NAMES_MAX_DEFERRED_SCHEDULE_JSON_DESC',
  ScheduleNamesMaxEmailsJsonAsc = 'SCHEDULE_NAMES_MAX_EMAILS_JSON_ASC',
  ScheduleNamesMaxEmailsJsonDesc = 'SCHEDULE_NAMES_MAX_EMAILS_JSON_DESC',
  ScheduleNamesMaxEmailOnAsc = 'SCHEDULE_NAMES_MAX_EMAIL_ON_ASC',
  ScheduleNamesMaxEmailOnDesc = 'SCHEDULE_NAMES_MAX_EMAIL_ON_DESC',
  ScheduleNamesMaxEnvironmentAsc = 'SCHEDULE_NAMES_MAX_ENVIRONMENT_ASC',
  ScheduleNamesMaxEnvironmentDesc = 'SCHEDULE_NAMES_MAX_ENVIRONMENT_DESC',
  ScheduleNamesMaxErrorStringAsc = 'SCHEDULE_NAMES_MAX_ERROR_STRING_ASC',
  ScheduleNamesMaxErrorStringDesc = 'SCHEDULE_NAMES_MAX_ERROR_STRING_DESC',
  ScheduleNamesMaxIdAsc = 'SCHEDULE_NAMES_MAX_ID_ASC',
  ScheduleNamesMaxIdDesc = 'SCHEDULE_NAMES_MAX_ID_DESC',
  ScheduleNamesMaxNameAsc = 'SCHEDULE_NAMES_MAX_NAME_ASC',
  ScheduleNamesMaxNameDesc = 'SCHEDULE_NAMES_MAX_NAME_DESC',
  ScheduleNamesMaxOwnerAsc = 'SCHEDULE_NAMES_MAX_OWNER_ASC',
  ScheduleNamesMaxOwnerDesc = 'SCHEDULE_NAMES_MAX_OWNER_DESC',
  ScheduleNamesMaxScheduleCronAsc = 'SCHEDULE_NAMES_MAX_SCHEDULE_CRON_ASC',
  ScheduleNamesMaxScheduleCronDesc = 'SCHEDULE_NAMES_MAX_SCHEDULE_CRON_DESC',
  ScheduleNamesMaxScheduleParseIdAsc = 'SCHEDULE_NAMES_MAX_SCHEDULE_PARSE_ID_ASC',
  ScheduleNamesMaxScheduleParseIdDesc = 'SCHEDULE_NAMES_MAX_SCHEDULE_PARSE_ID_DESC',
  ScheduleNamesMaxSlackChannelsJsonAsc = 'SCHEDULE_NAMES_MAX_SLACK_CHANNELS_JSON_ASC',
  ScheduleNamesMaxSlackChannelsJsonDesc = 'SCHEDULE_NAMES_MAX_SLACK_CHANNELS_JSON_DESC',
  ScheduleNamesMaxSlackOnAsc = 'SCHEDULE_NAMES_MAX_SLACK_ON_ASC',
  ScheduleNamesMaxSlackOnDesc = 'SCHEDULE_NAMES_MAX_SLACK_ON_DESC',
  ScheduleNamesMaxSourceAsc = 'SCHEDULE_NAMES_MAX_SOURCE_ASC',
  ScheduleNamesMaxSourceDesc = 'SCHEDULE_NAMES_MAX_SOURCE_DESC',
  ScheduleNamesMaxTurboCiJsonAsc = 'SCHEDULE_NAMES_MAX_TURBO_CI_JSON_ASC',
  ScheduleNamesMaxTurboCiJsonDesc = 'SCHEDULE_NAMES_MAX_TURBO_CI_JSON_DESC',
  ScheduleNamesMaxUpdatedDttmAsc = 'SCHEDULE_NAMES_MAX_UPDATED_DTTM_ASC',
  ScheduleNamesMaxUpdatedDttmDesc = 'SCHEDULE_NAMES_MAX_UPDATED_DTTM_DESC',
  ScheduleNamesMaxUuidAsc = 'SCHEDULE_NAMES_MAX_UUID_ASC',
  ScheduleNamesMaxUuidDesc = 'SCHEDULE_NAMES_MAX_UUID_DESC',
  ScheduleNamesMinBranchAsc = 'SCHEDULE_NAMES_MIN_BRANCH_ASC',
  ScheduleNamesMinBranchDesc = 'SCHEDULE_NAMES_MIN_BRANCH_DESC',
  ScheduleNamesMinCommandsJsonAsc = 'SCHEDULE_NAMES_MIN_COMMANDS_JSON_ASC',
  ScheduleNamesMinCommandsJsonDesc = 'SCHEDULE_NAMES_MIN_COMMANDS_JSON_DESC',
  ScheduleNamesMinCommitHashAsc = 'SCHEDULE_NAMES_MIN_COMMIT_HASH_ASC',
  ScheduleNamesMinCommitHashDesc = 'SCHEDULE_NAMES_MIN_COMMIT_HASH_DESC',
  ScheduleNamesMinCreatedDttmAsc = 'SCHEDULE_NAMES_MIN_CREATED_DTTM_ASC',
  ScheduleNamesMinCreatedDttmDesc = 'SCHEDULE_NAMES_MIN_CREATED_DTTM_DESC',
  ScheduleNamesMinDbtVersionAsc = 'SCHEDULE_NAMES_MIN_DBT_VERSION_ASC',
  ScheduleNamesMinDbtVersionDesc = 'SCHEDULE_NAMES_MIN_DBT_VERSION_DESC',
  ScheduleNamesMinDeferredScheduleJsonAsc = 'SCHEDULE_NAMES_MIN_DEFERRED_SCHEDULE_JSON_ASC',
  ScheduleNamesMinDeferredScheduleJsonDesc = 'SCHEDULE_NAMES_MIN_DEFERRED_SCHEDULE_JSON_DESC',
  ScheduleNamesMinEmailsJsonAsc = 'SCHEDULE_NAMES_MIN_EMAILS_JSON_ASC',
  ScheduleNamesMinEmailsJsonDesc = 'SCHEDULE_NAMES_MIN_EMAILS_JSON_DESC',
  ScheduleNamesMinEmailOnAsc = 'SCHEDULE_NAMES_MIN_EMAIL_ON_ASC',
  ScheduleNamesMinEmailOnDesc = 'SCHEDULE_NAMES_MIN_EMAIL_ON_DESC',
  ScheduleNamesMinEnvironmentAsc = 'SCHEDULE_NAMES_MIN_ENVIRONMENT_ASC',
  ScheduleNamesMinEnvironmentDesc = 'SCHEDULE_NAMES_MIN_ENVIRONMENT_DESC',
  ScheduleNamesMinErrorStringAsc = 'SCHEDULE_NAMES_MIN_ERROR_STRING_ASC',
  ScheduleNamesMinErrorStringDesc = 'SCHEDULE_NAMES_MIN_ERROR_STRING_DESC',
  ScheduleNamesMinIdAsc = 'SCHEDULE_NAMES_MIN_ID_ASC',
  ScheduleNamesMinIdDesc = 'SCHEDULE_NAMES_MIN_ID_DESC',
  ScheduleNamesMinNameAsc = 'SCHEDULE_NAMES_MIN_NAME_ASC',
  ScheduleNamesMinNameDesc = 'SCHEDULE_NAMES_MIN_NAME_DESC',
  ScheduleNamesMinOwnerAsc = 'SCHEDULE_NAMES_MIN_OWNER_ASC',
  ScheduleNamesMinOwnerDesc = 'SCHEDULE_NAMES_MIN_OWNER_DESC',
  ScheduleNamesMinScheduleCronAsc = 'SCHEDULE_NAMES_MIN_SCHEDULE_CRON_ASC',
  ScheduleNamesMinScheduleCronDesc = 'SCHEDULE_NAMES_MIN_SCHEDULE_CRON_DESC',
  ScheduleNamesMinScheduleParseIdAsc = 'SCHEDULE_NAMES_MIN_SCHEDULE_PARSE_ID_ASC',
  ScheduleNamesMinScheduleParseIdDesc = 'SCHEDULE_NAMES_MIN_SCHEDULE_PARSE_ID_DESC',
  ScheduleNamesMinSlackChannelsJsonAsc = 'SCHEDULE_NAMES_MIN_SLACK_CHANNELS_JSON_ASC',
  ScheduleNamesMinSlackChannelsJsonDesc = 'SCHEDULE_NAMES_MIN_SLACK_CHANNELS_JSON_DESC',
  ScheduleNamesMinSlackOnAsc = 'SCHEDULE_NAMES_MIN_SLACK_ON_ASC',
  ScheduleNamesMinSlackOnDesc = 'SCHEDULE_NAMES_MIN_SLACK_ON_DESC',
  ScheduleNamesMinSourceAsc = 'SCHEDULE_NAMES_MIN_SOURCE_ASC',
  ScheduleNamesMinSourceDesc = 'SCHEDULE_NAMES_MIN_SOURCE_DESC',
  ScheduleNamesMinTurboCiJsonAsc = 'SCHEDULE_NAMES_MIN_TURBO_CI_JSON_ASC',
  ScheduleNamesMinTurboCiJsonDesc = 'SCHEDULE_NAMES_MIN_TURBO_CI_JSON_DESC',
  ScheduleNamesMinUpdatedDttmAsc = 'SCHEDULE_NAMES_MIN_UPDATED_DTTM_ASC',
  ScheduleNamesMinUpdatedDttmDesc = 'SCHEDULE_NAMES_MIN_UPDATED_DTTM_DESC',
  ScheduleNamesMinUuidAsc = 'SCHEDULE_NAMES_MIN_UUID_ASC',
  ScheduleNamesMinUuidDesc = 'SCHEDULE_NAMES_MIN_UUID_DESC',
  ScheduleNamesStddevPopulationBranchAsc = 'SCHEDULE_NAMES_STDDEV_POPULATION_BRANCH_ASC',
  ScheduleNamesStddevPopulationBranchDesc = 'SCHEDULE_NAMES_STDDEV_POPULATION_BRANCH_DESC',
  ScheduleNamesStddevPopulationCommandsJsonAsc = 'SCHEDULE_NAMES_STDDEV_POPULATION_COMMANDS_JSON_ASC',
  ScheduleNamesStddevPopulationCommandsJsonDesc = 'SCHEDULE_NAMES_STDDEV_POPULATION_COMMANDS_JSON_DESC',
  ScheduleNamesStddevPopulationCommitHashAsc = 'SCHEDULE_NAMES_STDDEV_POPULATION_COMMIT_HASH_ASC',
  ScheduleNamesStddevPopulationCommitHashDesc = 'SCHEDULE_NAMES_STDDEV_POPULATION_COMMIT_HASH_DESC',
  ScheduleNamesStddevPopulationCreatedDttmAsc = 'SCHEDULE_NAMES_STDDEV_POPULATION_CREATED_DTTM_ASC',
  ScheduleNamesStddevPopulationCreatedDttmDesc = 'SCHEDULE_NAMES_STDDEV_POPULATION_CREATED_DTTM_DESC',
  ScheduleNamesStddevPopulationDbtVersionAsc = 'SCHEDULE_NAMES_STDDEV_POPULATION_DBT_VERSION_ASC',
  ScheduleNamesStddevPopulationDbtVersionDesc = 'SCHEDULE_NAMES_STDDEV_POPULATION_DBT_VERSION_DESC',
  ScheduleNamesStddevPopulationDeferredScheduleJsonAsc = 'SCHEDULE_NAMES_STDDEV_POPULATION_DEFERRED_SCHEDULE_JSON_ASC',
  ScheduleNamesStddevPopulationDeferredScheduleJsonDesc = 'SCHEDULE_NAMES_STDDEV_POPULATION_DEFERRED_SCHEDULE_JSON_DESC',
  ScheduleNamesStddevPopulationEmailsJsonAsc = 'SCHEDULE_NAMES_STDDEV_POPULATION_EMAILS_JSON_ASC',
  ScheduleNamesStddevPopulationEmailsJsonDesc = 'SCHEDULE_NAMES_STDDEV_POPULATION_EMAILS_JSON_DESC',
  ScheduleNamesStddevPopulationEmailOnAsc = 'SCHEDULE_NAMES_STDDEV_POPULATION_EMAIL_ON_ASC',
  ScheduleNamesStddevPopulationEmailOnDesc = 'SCHEDULE_NAMES_STDDEV_POPULATION_EMAIL_ON_DESC',
  ScheduleNamesStddevPopulationEnvironmentAsc = 'SCHEDULE_NAMES_STDDEV_POPULATION_ENVIRONMENT_ASC',
  ScheduleNamesStddevPopulationEnvironmentDesc = 'SCHEDULE_NAMES_STDDEV_POPULATION_ENVIRONMENT_DESC',
  ScheduleNamesStddevPopulationErrorStringAsc = 'SCHEDULE_NAMES_STDDEV_POPULATION_ERROR_STRING_ASC',
  ScheduleNamesStddevPopulationErrorStringDesc = 'SCHEDULE_NAMES_STDDEV_POPULATION_ERROR_STRING_DESC',
  ScheduleNamesStddevPopulationIdAsc = 'SCHEDULE_NAMES_STDDEV_POPULATION_ID_ASC',
  ScheduleNamesStddevPopulationIdDesc = 'SCHEDULE_NAMES_STDDEV_POPULATION_ID_DESC',
  ScheduleNamesStddevPopulationNameAsc = 'SCHEDULE_NAMES_STDDEV_POPULATION_NAME_ASC',
  ScheduleNamesStddevPopulationNameDesc = 'SCHEDULE_NAMES_STDDEV_POPULATION_NAME_DESC',
  ScheduleNamesStddevPopulationOwnerAsc = 'SCHEDULE_NAMES_STDDEV_POPULATION_OWNER_ASC',
  ScheduleNamesStddevPopulationOwnerDesc = 'SCHEDULE_NAMES_STDDEV_POPULATION_OWNER_DESC',
  ScheduleNamesStddevPopulationScheduleCronAsc = 'SCHEDULE_NAMES_STDDEV_POPULATION_SCHEDULE_CRON_ASC',
  ScheduleNamesStddevPopulationScheduleCronDesc = 'SCHEDULE_NAMES_STDDEV_POPULATION_SCHEDULE_CRON_DESC',
  ScheduleNamesStddevPopulationScheduleParseIdAsc = 'SCHEDULE_NAMES_STDDEV_POPULATION_SCHEDULE_PARSE_ID_ASC',
  ScheduleNamesStddevPopulationScheduleParseIdDesc = 'SCHEDULE_NAMES_STDDEV_POPULATION_SCHEDULE_PARSE_ID_DESC',
  ScheduleNamesStddevPopulationSlackChannelsJsonAsc = 'SCHEDULE_NAMES_STDDEV_POPULATION_SLACK_CHANNELS_JSON_ASC',
  ScheduleNamesStddevPopulationSlackChannelsJsonDesc = 'SCHEDULE_NAMES_STDDEV_POPULATION_SLACK_CHANNELS_JSON_DESC',
  ScheduleNamesStddevPopulationSlackOnAsc = 'SCHEDULE_NAMES_STDDEV_POPULATION_SLACK_ON_ASC',
  ScheduleNamesStddevPopulationSlackOnDesc = 'SCHEDULE_NAMES_STDDEV_POPULATION_SLACK_ON_DESC',
  ScheduleNamesStddevPopulationSourceAsc = 'SCHEDULE_NAMES_STDDEV_POPULATION_SOURCE_ASC',
  ScheduleNamesStddevPopulationSourceDesc = 'SCHEDULE_NAMES_STDDEV_POPULATION_SOURCE_DESC',
  ScheduleNamesStddevPopulationTurboCiJsonAsc = 'SCHEDULE_NAMES_STDDEV_POPULATION_TURBO_CI_JSON_ASC',
  ScheduleNamesStddevPopulationTurboCiJsonDesc = 'SCHEDULE_NAMES_STDDEV_POPULATION_TURBO_CI_JSON_DESC',
  ScheduleNamesStddevPopulationUpdatedDttmAsc = 'SCHEDULE_NAMES_STDDEV_POPULATION_UPDATED_DTTM_ASC',
  ScheduleNamesStddevPopulationUpdatedDttmDesc = 'SCHEDULE_NAMES_STDDEV_POPULATION_UPDATED_DTTM_DESC',
  ScheduleNamesStddevPopulationUuidAsc = 'SCHEDULE_NAMES_STDDEV_POPULATION_UUID_ASC',
  ScheduleNamesStddevPopulationUuidDesc = 'SCHEDULE_NAMES_STDDEV_POPULATION_UUID_DESC',
  ScheduleNamesStddevSampleBranchAsc = 'SCHEDULE_NAMES_STDDEV_SAMPLE_BRANCH_ASC',
  ScheduleNamesStddevSampleBranchDesc = 'SCHEDULE_NAMES_STDDEV_SAMPLE_BRANCH_DESC',
  ScheduleNamesStddevSampleCommandsJsonAsc = 'SCHEDULE_NAMES_STDDEV_SAMPLE_COMMANDS_JSON_ASC',
  ScheduleNamesStddevSampleCommandsJsonDesc = 'SCHEDULE_NAMES_STDDEV_SAMPLE_COMMANDS_JSON_DESC',
  ScheduleNamesStddevSampleCommitHashAsc = 'SCHEDULE_NAMES_STDDEV_SAMPLE_COMMIT_HASH_ASC',
  ScheduleNamesStddevSampleCommitHashDesc = 'SCHEDULE_NAMES_STDDEV_SAMPLE_COMMIT_HASH_DESC',
  ScheduleNamesStddevSampleCreatedDttmAsc = 'SCHEDULE_NAMES_STDDEV_SAMPLE_CREATED_DTTM_ASC',
  ScheduleNamesStddevSampleCreatedDttmDesc = 'SCHEDULE_NAMES_STDDEV_SAMPLE_CREATED_DTTM_DESC',
  ScheduleNamesStddevSampleDbtVersionAsc = 'SCHEDULE_NAMES_STDDEV_SAMPLE_DBT_VERSION_ASC',
  ScheduleNamesStddevSampleDbtVersionDesc = 'SCHEDULE_NAMES_STDDEV_SAMPLE_DBT_VERSION_DESC',
  ScheduleNamesStddevSampleDeferredScheduleJsonAsc = 'SCHEDULE_NAMES_STDDEV_SAMPLE_DEFERRED_SCHEDULE_JSON_ASC',
  ScheduleNamesStddevSampleDeferredScheduleJsonDesc = 'SCHEDULE_NAMES_STDDEV_SAMPLE_DEFERRED_SCHEDULE_JSON_DESC',
  ScheduleNamesStddevSampleEmailsJsonAsc = 'SCHEDULE_NAMES_STDDEV_SAMPLE_EMAILS_JSON_ASC',
  ScheduleNamesStddevSampleEmailsJsonDesc = 'SCHEDULE_NAMES_STDDEV_SAMPLE_EMAILS_JSON_DESC',
  ScheduleNamesStddevSampleEmailOnAsc = 'SCHEDULE_NAMES_STDDEV_SAMPLE_EMAIL_ON_ASC',
  ScheduleNamesStddevSampleEmailOnDesc = 'SCHEDULE_NAMES_STDDEV_SAMPLE_EMAIL_ON_DESC',
  ScheduleNamesStddevSampleEnvironmentAsc = 'SCHEDULE_NAMES_STDDEV_SAMPLE_ENVIRONMENT_ASC',
  ScheduleNamesStddevSampleEnvironmentDesc = 'SCHEDULE_NAMES_STDDEV_SAMPLE_ENVIRONMENT_DESC',
  ScheduleNamesStddevSampleErrorStringAsc = 'SCHEDULE_NAMES_STDDEV_SAMPLE_ERROR_STRING_ASC',
  ScheduleNamesStddevSampleErrorStringDesc = 'SCHEDULE_NAMES_STDDEV_SAMPLE_ERROR_STRING_DESC',
  ScheduleNamesStddevSampleIdAsc = 'SCHEDULE_NAMES_STDDEV_SAMPLE_ID_ASC',
  ScheduleNamesStddevSampleIdDesc = 'SCHEDULE_NAMES_STDDEV_SAMPLE_ID_DESC',
  ScheduleNamesStddevSampleNameAsc = 'SCHEDULE_NAMES_STDDEV_SAMPLE_NAME_ASC',
  ScheduleNamesStddevSampleNameDesc = 'SCHEDULE_NAMES_STDDEV_SAMPLE_NAME_DESC',
  ScheduleNamesStddevSampleOwnerAsc = 'SCHEDULE_NAMES_STDDEV_SAMPLE_OWNER_ASC',
  ScheduleNamesStddevSampleOwnerDesc = 'SCHEDULE_NAMES_STDDEV_SAMPLE_OWNER_DESC',
  ScheduleNamesStddevSampleScheduleCronAsc = 'SCHEDULE_NAMES_STDDEV_SAMPLE_SCHEDULE_CRON_ASC',
  ScheduleNamesStddevSampleScheduleCronDesc = 'SCHEDULE_NAMES_STDDEV_SAMPLE_SCHEDULE_CRON_DESC',
  ScheduleNamesStddevSampleScheduleParseIdAsc = 'SCHEDULE_NAMES_STDDEV_SAMPLE_SCHEDULE_PARSE_ID_ASC',
  ScheduleNamesStddevSampleScheduleParseIdDesc = 'SCHEDULE_NAMES_STDDEV_SAMPLE_SCHEDULE_PARSE_ID_DESC',
  ScheduleNamesStddevSampleSlackChannelsJsonAsc = 'SCHEDULE_NAMES_STDDEV_SAMPLE_SLACK_CHANNELS_JSON_ASC',
  ScheduleNamesStddevSampleSlackChannelsJsonDesc = 'SCHEDULE_NAMES_STDDEV_SAMPLE_SLACK_CHANNELS_JSON_DESC',
  ScheduleNamesStddevSampleSlackOnAsc = 'SCHEDULE_NAMES_STDDEV_SAMPLE_SLACK_ON_ASC',
  ScheduleNamesStddevSampleSlackOnDesc = 'SCHEDULE_NAMES_STDDEV_SAMPLE_SLACK_ON_DESC',
  ScheduleNamesStddevSampleSourceAsc = 'SCHEDULE_NAMES_STDDEV_SAMPLE_SOURCE_ASC',
  ScheduleNamesStddevSampleSourceDesc = 'SCHEDULE_NAMES_STDDEV_SAMPLE_SOURCE_DESC',
  ScheduleNamesStddevSampleTurboCiJsonAsc = 'SCHEDULE_NAMES_STDDEV_SAMPLE_TURBO_CI_JSON_ASC',
  ScheduleNamesStddevSampleTurboCiJsonDesc = 'SCHEDULE_NAMES_STDDEV_SAMPLE_TURBO_CI_JSON_DESC',
  ScheduleNamesStddevSampleUpdatedDttmAsc = 'SCHEDULE_NAMES_STDDEV_SAMPLE_UPDATED_DTTM_ASC',
  ScheduleNamesStddevSampleUpdatedDttmDesc = 'SCHEDULE_NAMES_STDDEV_SAMPLE_UPDATED_DTTM_DESC',
  ScheduleNamesStddevSampleUuidAsc = 'SCHEDULE_NAMES_STDDEV_SAMPLE_UUID_ASC',
  ScheduleNamesStddevSampleUuidDesc = 'SCHEDULE_NAMES_STDDEV_SAMPLE_UUID_DESC',
  ScheduleNamesSumBranchAsc = 'SCHEDULE_NAMES_SUM_BRANCH_ASC',
  ScheduleNamesSumBranchDesc = 'SCHEDULE_NAMES_SUM_BRANCH_DESC',
  ScheduleNamesSumCommandsJsonAsc = 'SCHEDULE_NAMES_SUM_COMMANDS_JSON_ASC',
  ScheduleNamesSumCommandsJsonDesc = 'SCHEDULE_NAMES_SUM_COMMANDS_JSON_DESC',
  ScheduleNamesSumCommitHashAsc = 'SCHEDULE_NAMES_SUM_COMMIT_HASH_ASC',
  ScheduleNamesSumCommitHashDesc = 'SCHEDULE_NAMES_SUM_COMMIT_HASH_DESC',
  ScheduleNamesSumCreatedDttmAsc = 'SCHEDULE_NAMES_SUM_CREATED_DTTM_ASC',
  ScheduleNamesSumCreatedDttmDesc = 'SCHEDULE_NAMES_SUM_CREATED_DTTM_DESC',
  ScheduleNamesSumDbtVersionAsc = 'SCHEDULE_NAMES_SUM_DBT_VERSION_ASC',
  ScheduleNamesSumDbtVersionDesc = 'SCHEDULE_NAMES_SUM_DBT_VERSION_DESC',
  ScheduleNamesSumDeferredScheduleJsonAsc = 'SCHEDULE_NAMES_SUM_DEFERRED_SCHEDULE_JSON_ASC',
  ScheduleNamesSumDeferredScheduleJsonDesc = 'SCHEDULE_NAMES_SUM_DEFERRED_SCHEDULE_JSON_DESC',
  ScheduleNamesSumEmailsJsonAsc = 'SCHEDULE_NAMES_SUM_EMAILS_JSON_ASC',
  ScheduleNamesSumEmailsJsonDesc = 'SCHEDULE_NAMES_SUM_EMAILS_JSON_DESC',
  ScheduleNamesSumEmailOnAsc = 'SCHEDULE_NAMES_SUM_EMAIL_ON_ASC',
  ScheduleNamesSumEmailOnDesc = 'SCHEDULE_NAMES_SUM_EMAIL_ON_DESC',
  ScheduleNamesSumEnvironmentAsc = 'SCHEDULE_NAMES_SUM_ENVIRONMENT_ASC',
  ScheduleNamesSumEnvironmentDesc = 'SCHEDULE_NAMES_SUM_ENVIRONMENT_DESC',
  ScheduleNamesSumErrorStringAsc = 'SCHEDULE_NAMES_SUM_ERROR_STRING_ASC',
  ScheduleNamesSumErrorStringDesc = 'SCHEDULE_NAMES_SUM_ERROR_STRING_DESC',
  ScheduleNamesSumIdAsc = 'SCHEDULE_NAMES_SUM_ID_ASC',
  ScheduleNamesSumIdDesc = 'SCHEDULE_NAMES_SUM_ID_DESC',
  ScheduleNamesSumNameAsc = 'SCHEDULE_NAMES_SUM_NAME_ASC',
  ScheduleNamesSumNameDesc = 'SCHEDULE_NAMES_SUM_NAME_DESC',
  ScheduleNamesSumOwnerAsc = 'SCHEDULE_NAMES_SUM_OWNER_ASC',
  ScheduleNamesSumOwnerDesc = 'SCHEDULE_NAMES_SUM_OWNER_DESC',
  ScheduleNamesSumScheduleCronAsc = 'SCHEDULE_NAMES_SUM_SCHEDULE_CRON_ASC',
  ScheduleNamesSumScheduleCronDesc = 'SCHEDULE_NAMES_SUM_SCHEDULE_CRON_DESC',
  ScheduleNamesSumScheduleParseIdAsc = 'SCHEDULE_NAMES_SUM_SCHEDULE_PARSE_ID_ASC',
  ScheduleNamesSumScheduleParseIdDesc = 'SCHEDULE_NAMES_SUM_SCHEDULE_PARSE_ID_DESC',
  ScheduleNamesSumSlackChannelsJsonAsc = 'SCHEDULE_NAMES_SUM_SLACK_CHANNELS_JSON_ASC',
  ScheduleNamesSumSlackChannelsJsonDesc = 'SCHEDULE_NAMES_SUM_SLACK_CHANNELS_JSON_DESC',
  ScheduleNamesSumSlackOnAsc = 'SCHEDULE_NAMES_SUM_SLACK_ON_ASC',
  ScheduleNamesSumSlackOnDesc = 'SCHEDULE_NAMES_SUM_SLACK_ON_DESC',
  ScheduleNamesSumSourceAsc = 'SCHEDULE_NAMES_SUM_SOURCE_ASC',
  ScheduleNamesSumSourceDesc = 'SCHEDULE_NAMES_SUM_SOURCE_DESC',
  ScheduleNamesSumTurboCiJsonAsc = 'SCHEDULE_NAMES_SUM_TURBO_CI_JSON_ASC',
  ScheduleNamesSumTurboCiJsonDesc = 'SCHEDULE_NAMES_SUM_TURBO_CI_JSON_DESC',
  ScheduleNamesSumUpdatedDttmAsc = 'SCHEDULE_NAMES_SUM_UPDATED_DTTM_ASC',
  ScheduleNamesSumUpdatedDttmDesc = 'SCHEDULE_NAMES_SUM_UPDATED_DTTM_DESC',
  ScheduleNamesSumUuidAsc = 'SCHEDULE_NAMES_SUM_UUID_ASC',
  ScheduleNamesSumUuidDesc = 'SCHEDULE_NAMES_SUM_UUID_DESC',
  ScheduleNamesVariancePopulationBranchAsc = 'SCHEDULE_NAMES_VARIANCE_POPULATION_BRANCH_ASC',
  ScheduleNamesVariancePopulationBranchDesc = 'SCHEDULE_NAMES_VARIANCE_POPULATION_BRANCH_DESC',
  ScheduleNamesVariancePopulationCommandsJsonAsc = 'SCHEDULE_NAMES_VARIANCE_POPULATION_COMMANDS_JSON_ASC',
  ScheduleNamesVariancePopulationCommandsJsonDesc = 'SCHEDULE_NAMES_VARIANCE_POPULATION_COMMANDS_JSON_DESC',
  ScheduleNamesVariancePopulationCommitHashAsc = 'SCHEDULE_NAMES_VARIANCE_POPULATION_COMMIT_HASH_ASC',
  ScheduleNamesVariancePopulationCommitHashDesc = 'SCHEDULE_NAMES_VARIANCE_POPULATION_COMMIT_HASH_DESC',
  ScheduleNamesVariancePopulationCreatedDttmAsc = 'SCHEDULE_NAMES_VARIANCE_POPULATION_CREATED_DTTM_ASC',
  ScheduleNamesVariancePopulationCreatedDttmDesc = 'SCHEDULE_NAMES_VARIANCE_POPULATION_CREATED_DTTM_DESC',
  ScheduleNamesVariancePopulationDbtVersionAsc = 'SCHEDULE_NAMES_VARIANCE_POPULATION_DBT_VERSION_ASC',
  ScheduleNamesVariancePopulationDbtVersionDesc = 'SCHEDULE_NAMES_VARIANCE_POPULATION_DBT_VERSION_DESC',
  ScheduleNamesVariancePopulationDeferredScheduleJsonAsc = 'SCHEDULE_NAMES_VARIANCE_POPULATION_DEFERRED_SCHEDULE_JSON_ASC',
  ScheduleNamesVariancePopulationDeferredScheduleJsonDesc = 'SCHEDULE_NAMES_VARIANCE_POPULATION_DEFERRED_SCHEDULE_JSON_DESC',
  ScheduleNamesVariancePopulationEmailsJsonAsc = 'SCHEDULE_NAMES_VARIANCE_POPULATION_EMAILS_JSON_ASC',
  ScheduleNamesVariancePopulationEmailsJsonDesc = 'SCHEDULE_NAMES_VARIANCE_POPULATION_EMAILS_JSON_DESC',
  ScheduleNamesVariancePopulationEmailOnAsc = 'SCHEDULE_NAMES_VARIANCE_POPULATION_EMAIL_ON_ASC',
  ScheduleNamesVariancePopulationEmailOnDesc = 'SCHEDULE_NAMES_VARIANCE_POPULATION_EMAIL_ON_DESC',
  ScheduleNamesVariancePopulationEnvironmentAsc = 'SCHEDULE_NAMES_VARIANCE_POPULATION_ENVIRONMENT_ASC',
  ScheduleNamesVariancePopulationEnvironmentDesc = 'SCHEDULE_NAMES_VARIANCE_POPULATION_ENVIRONMENT_DESC',
  ScheduleNamesVariancePopulationErrorStringAsc = 'SCHEDULE_NAMES_VARIANCE_POPULATION_ERROR_STRING_ASC',
  ScheduleNamesVariancePopulationErrorStringDesc = 'SCHEDULE_NAMES_VARIANCE_POPULATION_ERROR_STRING_DESC',
  ScheduleNamesVariancePopulationIdAsc = 'SCHEDULE_NAMES_VARIANCE_POPULATION_ID_ASC',
  ScheduleNamesVariancePopulationIdDesc = 'SCHEDULE_NAMES_VARIANCE_POPULATION_ID_DESC',
  ScheduleNamesVariancePopulationNameAsc = 'SCHEDULE_NAMES_VARIANCE_POPULATION_NAME_ASC',
  ScheduleNamesVariancePopulationNameDesc = 'SCHEDULE_NAMES_VARIANCE_POPULATION_NAME_DESC',
  ScheduleNamesVariancePopulationOwnerAsc = 'SCHEDULE_NAMES_VARIANCE_POPULATION_OWNER_ASC',
  ScheduleNamesVariancePopulationOwnerDesc = 'SCHEDULE_NAMES_VARIANCE_POPULATION_OWNER_DESC',
  ScheduleNamesVariancePopulationScheduleCronAsc = 'SCHEDULE_NAMES_VARIANCE_POPULATION_SCHEDULE_CRON_ASC',
  ScheduleNamesVariancePopulationScheduleCronDesc = 'SCHEDULE_NAMES_VARIANCE_POPULATION_SCHEDULE_CRON_DESC',
  ScheduleNamesVariancePopulationScheduleParseIdAsc = 'SCHEDULE_NAMES_VARIANCE_POPULATION_SCHEDULE_PARSE_ID_ASC',
  ScheduleNamesVariancePopulationScheduleParseIdDesc = 'SCHEDULE_NAMES_VARIANCE_POPULATION_SCHEDULE_PARSE_ID_DESC',
  ScheduleNamesVariancePopulationSlackChannelsJsonAsc = 'SCHEDULE_NAMES_VARIANCE_POPULATION_SLACK_CHANNELS_JSON_ASC',
  ScheduleNamesVariancePopulationSlackChannelsJsonDesc = 'SCHEDULE_NAMES_VARIANCE_POPULATION_SLACK_CHANNELS_JSON_DESC',
  ScheduleNamesVariancePopulationSlackOnAsc = 'SCHEDULE_NAMES_VARIANCE_POPULATION_SLACK_ON_ASC',
  ScheduleNamesVariancePopulationSlackOnDesc = 'SCHEDULE_NAMES_VARIANCE_POPULATION_SLACK_ON_DESC',
  ScheduleNamesVariancePopulationSourceAsc = 'SCHEDULE_NAMES_VARIANCE_POPULATION_SOURCE_ASC',
  ScheduleNamesVariancePopulationSourceDesc = 'SCHEDULE_NAMES_VARIANCE_POPULATION_SOURCE_DESC',
  ScheduleNamesVariancePopulationTurboCiJsonAsc = 'SCHEDULE_NAMES_VARIANCE_POPULATION_TURBO_CI_JSON_ASC',
  ScheduleNamesVariancePopulationTurboCiJsonDesc = 'SCHEDULE_NAMES_VARIANCE_POPULATION_TURBO_CI_JSON_DESC',
  ScheduleNamesVariancePopulationUpdatedDttmAsc = 'SCHEDULE_NAMES_VARIANCE_POPULATION_UPDATED_DTTM_ASC',
  ScheduleNamesVariancePopulationUpdatedDttmDesc = 'SCHEDULE_NAMES_VARIANCE_POPULATION_UPDATED_DTTM_DESC',
  ScheduleNamesVariancePopulationUuidAsc = 'SCHEDULE_NAMES_VARIANCE_POPULATION_UUID_ASC',
  ScheduleNamesVariancePopulationUuidDesc = 'SCHEDULE_NAMES_VARIANCE_POPULATION_UUID_DESC',
  ScheduleNamesVarianceSampleBranchAsc = 'SCHEDULE_NAMES_VARIANCE_SAMPLE_BRANCH_ASC',
  ScheduleNamesVarianceSampleBranchDesc = 'SCHEDULE_NAMES_VARIANCE_SAMPLE_BRANCH_DESC',
  ScheduleNamesVarianceSampleCommandsJsonAsc = 'SCHEDULE_NAMES_VARIANCE_SAMPLE_COMMANDS_JSON_ASC',
  ScheduleNamesVarianceSampleCommandsJsonDesc = 'SCHEDULE_NAMES_VARIANCE_SAMPLE_COMMANDS_JSON_DESC',
  ScheduleNamesVarianceSampleCommitHashAsc = 'SCHEDULE_NAMES_VARIANCE_SAMPLE_COMMIT_HASH_ASC',
  ScheduleNamesVarianceSampleCommitHashDesc = 'SCHEDULE_NAMES_VARIANCE_SAMPLE_COMMIT_HASH_DESC',
  ScheduleNamesVarianceSampleCreatedDttmAsc = 'SCHEDULE_NAMES_VARIANCE_SAMPLE_CREATED_DTTM_ASC',
  ScheduleNamesVarianceSampleCreatedDttmDesc = 'SCHEDULE_NAMES_VARIANCE_SAMPLE_CREATED_DTTM_DESC',
  ScheduleNamesVarianceSampleDbtVersionAsc = 'SCHEDULE_NAMES_VARIANCE_SAMPLE_DBT_VERSION_ASC',
  ScheduleNamesVarianceSampleDbtVersionDesc = 'SCHEDULE_NAMES_VARIANCE_SAMPLE_DBT_VERSION_DESC',
  ScheduleNamesVarianceSampleDeferredScheduleJsonAsc = 'SCHEDULE_NAMES_VARIANCE_SAMPLE_DEFERRED_SCHEDULE_JSON_ASC',
  ScheduleNamesVarianceSampleDeferredScheduleJsonDesc = 'SCHEDULE_NAMES_VARIANCE_SAMPLE_DEFERRED_SCHEDULE_JSON_DESC',
  ScheduleNamesVarianceSampleEmailsJsonAsc = 'SCHEDULE_NAMES_VARIANCE_SAMPLE_EMAILS_JSON_ASC',
  ScheduleNamesVarianceSampleEmailsJsonDesc = 'SCHEDULE_NAMES_VARIANCE_SAMPLE_EMAILS_JSON_DESC',
  ScheduleNamesVarianceSampleEmailOnAsc = 'SCHEDULE_NAMES_VARIANCE_SAMPLE_EMAIL_ON_ASC',
  ScheduleNamesVarianceSampleEmailOnDesc = 'SCHEDULE_NAMES_VARIANCE_SAMPLE_EMAIL_ON_DESC',
  ScheduleNamesVarianceSampleEnvironmentAsc = 'SCHEDULE_NAMES_VARIANCE_SAMPLE_ENVIRONMENT_ASC',
  ScheduleNamesVarianceSampleEnvironmentDesc = 'SCHEDULE_NAMES_VARIANCE_SAMPLE_ENVIRONMENT_DESC',
  ScheduleNamesVarianceSampleErrorStringAsc = 'SCHEDULE_NAMES_VARIANCE_SAMPLE_ERROR_STRING_ASC',
  ScheduleNamesVarianceSampleErrorStringDesc = 'SCHEDULE_NAMES_VARIANCE_SAMPLE_ERROR_STRING_DESC',
  ScheduleNamesVarianceSampleIdAsc = 'SCHEDULE_NAMES_VARIANCE_SAMPLE_ID_ASC',
  ScheduleNamesVarianceSampleIdDesc = 'SCHEDULE_NAMES_VARIANCE_SAMPLE_ID_DESC',
  ScheduleNamesVarianceSampleNameAsc = 'SCHEDULE_NAMES_VARIANCE_SAMPLE_NAME_ASC',
  ScheduleNamesVarianceSampleNameDesc = 'SCHEDULE_NAMES_VARIANCE_SAMPLE_NAME_DESC',
  ScheduleNamesVarianceSampleOwnerAsc = 'SCHEDULE_NAMES_VARIANCE_SAMPLE_OWNER_ASC',
  ScheduleNamesVarianceSampleOwnerDesc = 'SCHEDULE_NAMES_VARIANCE_SAMPLE_OWNER_DESC',
  ScheduleNamesVarianceSampleScheduleCronAsc = 'SCHEDULE_NAMES_VARIANCE_SAMPLE_SCHEDULE_CRON_ASC',
  ScheduleNamesVarianceSampleScheduleCronDesc = 'SCHEDULE_NAMES_VARIANCE_SAMPLE_SCHEDULE_CRON_DESC',
  ScheduleNamesVarianceSampleScheduleParseIdAsc = 'SCHEDULE_NAMES_VARIANCE_SAMPLE_SCHEDULE_PARSE_ID_ASC',
  ScheduleNamesVarianceSampleScheduleParseIdDesc = 'SCHEDULE_NAMES_VARIANCE_SAMPLE_SCHEDULE_PARSE_ID_DESC',
  ScheduleNamesVarianceSampleSlackChannelsJsonAsc = 'SCHEDULE_NAMES_VARIANCE_SAMPLE_SLACK_CHANNELS_JSON_ASC',
  ScheduleNamesVarianceSampleSlackChannelsJsonDesc = 'SCHEDULE_NAMES_VARIANCE_SAMPLE_SLACK_CHANNELS_JSON_DESC',
  ScheduleNamesVarianceSampleSlackOnAsc = 'SCHEDULE_NAMES_VARIANCE_SAMPLE_SLACK_ON_ASC',
  ScheduleNamesVarianceSampleSlackOnDesc = 'SCHEDULE_NAMES_VARIANCE_SAMPLE_SLACK_ON_DESC',
  ScheduleNamesVarianceSampleSourceAsc = 'SCHEDULE_NAMES_VARIANCE_SAMPLE_SOURCE_ASC',
  ScheduleNamesVarianceSampleSourceDesc = 'SCHEDULE_NAMES_VARIANCE_SAMPLE_SOURCE_DESC',
  ScheduleNamesVarianceSampleTurboCiJsonAsc = 'SCHEDULE_NAMES_VARIANCE_SAMPLE_TURBO_CI_JSON_ASC',
  ScheduleNamesVarianceSampleTurboCiJsonDesc = 'SCHEDULE_NAMES_VARIANCE_SAMPLE_TURBO_CI_JSON_DESC',
  ScheduleNamesVarianceSampleUpdatedDttmAsc = 'SCHEDULE_NAMES_VARIANCE_SAMPLE_UPDATED_DTTM_ASC',
  ScheduleNamesVarianceSampleUpdatedDttmDesc = 'SCHEDULE_NAMES_VARIANCE_SAMPLE_UPDATED_DTTM_DESC',
  ScheduleNamesVarianceSampleUuidAsc = 'SCHEDULE_NAMES_VARIANCE_SAMPLE_UUID_ASC',
  ScheduleNamesVarianceSampleUuidDesc = 'SCHEDULE_NAMES_VARIANCE_SAMPLE_UUID_DESC',
  WorkspaceIdAsc = 'WORKSPACE_ID_ASC',
  WorkspaceIdDesc = 'WORKSPACE_ID_DESC'
}

export type ScheduleRun = Node & {
  __typename?: 'ScheduleRun';
  actor: Actors;
  branch?: Maybe<Scalars['String']>;
  commitHash?: Maybe<Scalars['String']>;
  endDttm?: Maybe<Scalars['Datetime']>;
  errorString?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  podName?: Maybe<Scalars['String']>;
  pullRequestId?: Maybe<Scalars['String']>;
  runManuallyByEmail?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `ScheduleCommand`. */
  scheduleCommands: ScheduleCommandsConnection;
  /** Reads a single `ScheduleName` that is related to this `ScheduleRun`. */
  scheduleName?: Maybe<ScheduleName>;
  scheduleNameId: Scalars['Int'];
  scheduleNameUuid?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `ScheduleRunCancelled`. */
  scheduleRunCancelledsByRunId: ScheduleRunCancelledsConnection;
  /** Reads and enables pagination through a set of `ScheduleRunDeferred`. */
  scheduleRunDeferredsByDeferredScheduleRunId: ScheduleRunDeferredsConnection;
  startDttm: Scalars['Datetime'];
  uuid?: Maybe<Scalars['String']>;
};


export type ScheduleRunScheduleCommandsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ScheduleCommandCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ScheduleCommandsOrderBy>>;
};


export type ScheduleRunScheduleRunCancelledsByRunIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ScheduleRunCancelledCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ScheduleRunCancelledsOrderBy>>;
};


export type ScheduleRunScheduleRunDeferredsByDeferredScheduleRunIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ScheduleRunDeferredCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ScheduleRunDeferredsOrderBy>>;
};

export type ScheduleRunAggregates = {
  __typename?: 'ScheduleRunAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<ScheduleRunAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ScheduleRunDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<ScheduleRunMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<ScheduleRunMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<ScheduleRunStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<ScheduleRunStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<ScheduleRunSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<ScheduleRunVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<ScheduleRunVarianceSampleAggregates>;
};

export type ScheduleRunAverageAggregates = {
  __typename?: 'ScheduleRunAverageAggregates';
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of scheduleNameId across the matching connection */
  scheduleNameId?: Maybe<Scalars['BigFloat']>;
};

export type ScheduleRunCancelled = Node & {
  __typename?: 'ScheduleRunCancelled';
  actor?: Maybe<Scalars['String']>;
  datetime: Scalars['Datetime'];
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `ScheduleRun` that is related to this `ScheduleRunCancelled`. */
  run?: Maybe<ScheduleRun>;
  runId: Scalars['Int'];
  userEmail?: Maybe<Scalars['String']>;
};

export type ScheduleRunCancelledAggregates = {
  __typename?: 'ScheduleRunCancelledAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<ScheduleRunCancelledAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ScheduleRunCancelledDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<ScheduleRunCancelledMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<ScheduleRunCancelledMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<ScheduleRunCancelledStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<ScheduleRunCancelledStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<ScheduleRunCancelledSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<ScheduleRunCancelledVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<ScheduleRunCancelledVarianceSampleAggregates>;
};

export type ScheduleRunCancelledAverageAggregates = {
  __typename?: 'ScheduleRunCancelledAverageAggregates';
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of runId across the matching connection */
  runId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `ScheduleRunCancelled` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type ScheduleRunCancelledCondition = {
  /** Checks for equality with the object’s `actor` field. */
  actor?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `datetime` field. */
  datetime?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `runId` field. */
  runId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `userEmail` field. */
  userEmail?: InputMaybe<Scalars['String']>;
};

export type ScheduleRunCancelledDistinctCountAggregates = {
  __typename?: 'ScheduleRunCancelledDistinctCountAggregates';
  /** Distinct count of actor across the matching connection */
  actor?: Maybe<Scalars['BigInt']>;
  /** Distinct count of datetime across the matching connection */
  datetime?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of runId across the matching connection */
  runId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of userEmail across the matching connection */
  userEmail?: Maybe<Scalars['BigInt']>;
};

/** Grouping methods for `ScheduleRunCancelled` for usage during aggregation. */
export enum ScheduleRunCancelledGroupBy {
  Actor = 'ACTOR',
  Datetime = 'DATETIME',
  DatetimeTruncatedToDay = 'DATETIME_TRUNCATED_TO_DAY',
  DatetimeTruncatedToHour = 'DATETIME_TRUNCATED_TO_HOUR',
  RunId = 'RUN_ID',
  UserEmail = 'USER_EMAIL'
}

export type ScheduleRunCancelledHavingAverageInput = {
  datetime?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  runId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleRunCancelledHavingDistinctCountInput = {
  datetime?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  runId?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `ScheduleRunCancelled` aggregates. */
export type ScheduleRunCancelledHavingInput = {
  AND?: InputMaybe<Array<ScheduleRunCancelledHavingInput>>;
  OR?: InputMaybe<Array<ScheduleRunCancelledHavingInput>>;
  average?: InputMaybe<ScheduleRunCancelledHavingAverageInput>;
  distinctCount?: InputMaybe<ScheduleRunCancelledHavingDistinctCountInput>;
  max?: InputMaybe<ScheduleRunCancelledHavingMaxInput>;
  min?: InputMaybe<ScheduleRunCancelledHavingMinInput>;
  stddevPopulation?: InputMaybe<ScheduleRunCancelledHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<ScheduleRunCancelledHavingStddevSampleInput>;
  sum?: InputMaybe<ScheduleRunCancelledHavingSumInput>;
  variancePopulation?: InputMaybe<ScheduleRunCancelledHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<ScheduleRunCancelledHavingVarianceSampleInput>;
};

export type ScheduleRunCancelledHavingMaxInput = {
  datetime?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  runId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleRunCancelledHavingMinInput = {
  datetime?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  runId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleRunCancelledHavingStddevPopulationInput = {
  datetime?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  runId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleRunCancelledHavingStddevSampleInput = {
  datetime?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  runId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleRunCancelledHavingSumInput = {
  datetime?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  runId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleRunCancelledHavingVariancePopulationInput = {
  datetime?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  runId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleRunCancelledHavingVarianceSampleInput = {
  datetime?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  runId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleRunCancelledMaxAggregates = {
  __typename?: 'ScheduleRunCancelledMaxAggregates';
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of runId across the matching connection */
  runId?: Maybe<Scalars['Int']>;
};

export type ScheduleRunCancelledMinAggregates = {
  __typename?: 'ScheduleRunCancelledMinAggregates';
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of runId across the matching connection */
  runId?: Maybe<Scalars['Int']>;
};

export type ScheduleRunCancelledStddevPopulationAggregates = {
  __typename?: 'ScheduleRunCancelledStddevPopulationAggregates';
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of runId across the matching connection */
  runId?: Maybe<Scalars['BigFloat']>;
};

export type ScheduleRunCancelledStddevSampleAggregates = {
  __typename?: 'ScheduleRunCancelledStddevSampleAggregates';
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of runId across the matching connection */
  runId?: Maybe<Scalars['BigFloat']>;
};

export type ScheduleRunCancelledSumAggregates = {
  __typename?: 'ScheduleRunCancelledSumAggregates';
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of runId across the matching connection */
  runId: Scalars['BigInt'];
};

export type ScheduleRunCancelledVariancePopulationAggregates = {
  __typename?: 'ScheduleRunCancelledVariancePopulationAggregates';
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of runId across the matching connection */
  runId?: Maybe<Scalars['BigFloat']>;
};

export type ScheduleRunCancelledVarianceSampleAggregates = {
  __typename?: 'ScheduleRunCancelledVarianceSampleAggregates';
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of runId across the matching connection */
  runId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `ScheduleRunCancelled` values. */
export type ScheduleRunCancelledsConnection = {
  __typename?: 'ScheduleRunCancelledsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ScheduleRunCancelledAggregates>;
  /** A list of edges which contains the `ScheduleRunCancelled` and cursor to aid in pagination. */
  edges: Array<ScheduleRunCancelledsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ScheduleRunCancelledAggregates>>;
  /** A list of `ScheduleRunCancelled` objects. */
  nodes: Array<ScheduleRunCancelled>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ScheduleRunCancelled` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `ScheduleRunCancelled` values. */
export type ScheduleRunCancelledsConnectionGroupedAggregatesArgs = {
  groupBy: Array<ScheduleRunCancelledGroupBy>;
  having?: InputMaybe<ScheduleRunCancelledHavingInput>;
};

/** A `ScheduleRunCancelled` edge in the connection. */
export type ScheduleRunCancelledsEdge = {
  __typename?: 'ScheduleRunCancelledsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ScheduleRunCancelled` at the end of the edge. */
  node: ScheduleRunCancelled;
};

/** Methods to use when ordering `ScheduleRunCancelled`. */
export enum ScheduleRunCancelledsOrderBy {
  ActorAsc = 'ACTOR_ASC',
  ActorDesc = 'ACTOR_DESC',
  DatetimeAsc = 'DATETIME_ASC',
  DatetimeDesc = 'DATETIME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RunIdAsc = 'RUN_ID_ASC',
  RunIdDesc = 'RUN_ID_DESC',
  UserEmailAsc = 'USER_EMAIL_ASC',
  UserEmailDesc = 'USER_EMAIL_DESC'
}

/**
 * A condition to be used against `ScheduleRun` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ScheduleRunCondition = {
  /** Checks for equality with the object’s `actor` field. */
  actor?: InputMaybe<Actors>;
  /** Checks for equality with the object’s `branch` field. */
  branch?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `commitHash` field. */
  commitHash?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `endDttm` field. */
  endDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `errorString` field. */
  errorString?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `podName` field. */
  podName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `pullRequestId` field. */
  pullRequestId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `runManuallyByEmail` field. */
  runManuallyByEmail?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `scheduleNameId` field. */
  scheduleNameId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `scheduleNameUuid` field. */
  scheduleNameUuid?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `startDttm` field. */
  startDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `uuid` field. */
  uuid?: InputMaybe<Scalars['String']>;
};

export type ScheduleRunDeferred = Node & {
  __typename?: 'ScheduleRunDeferred';
  /** Reads a single `ScheduleRun` that is related to this `ScheduleRunDeferred`. */
  deferredScheduleRun?: Maybe<ScheduleRun>;
  deferredScheduleRunId: Scalars['Int'];
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `ScheduleCommandResource` that is related to this `ScheduleRunDeferred`. */
  scheduleCommandResource?: Maybe<ScheduleCommandResource>;
  scheduleCommandResourceId: Scalars['Int'];
};

export type ScheduleRunDeferredAggregates = {
  __typename?: 'ScheduleRunDeferredAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<ScheduleRunDeferredAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ScheduleRunDeferredDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<ScheduleRunDeferredMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<ScheduleRunDeferredMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<ScheduleRunDeferredStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<ScheduleRunDeferredStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<ScheduleRunDeferredSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<ScheduleRunDeferredVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<ScheduleRunDeferredVarianceSampleAggregates>;
};

export type ScheduleRunDeferredAverageAggregates = {
  __typename?: 'ScheduleRunDeferredAverageAggregates';
  /** Mean average of deferredScheduleRunId across the matching connection */
  deferredScheduleRunId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of scheduleCommandResourceId across the matching connection */
  scheduleCommandResourceId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `ScheduleRunDeferred` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type ScheduleRunDeferredCondition = {
  /** Checks for equality with the object’s `deferredScheduleRunId` field. */
  deferredScheduleRunId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `scheduleCommandResourceId` field. */
  scheduleCommandResourceId?: InputMaybe<Scalars['Int']>;
};

export type ScheduleRunDeferredDistinctCountAggregates = {
  __typename?: 'ScheduleRunDeferredDistinctCountAggregates';
  /** Distinct count of deferredScheduleRunId across the matching connection */
  deferredScheduleRunId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of scheduleCommandResourceId across the matching connection */
  scheduleCommandResourceId?: Maybe<Scalars['BigInt']>;
};

/** Grouping methods for `ScheduleRunDeferred` for usage during aggregation. */
export enum ScheduleRunDeferredGroupBy {
  DeferredScheduleRunId = 'DEFERRED_SCHEDULE_RUN_ID',
  ScheduleCommandResourceId = 'SCHEDULE_COMMAND_RESOURCE_ID'
}

export type ScheduleRunDeferredHavingAverageInput = {
  deferredScheduleRunId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  scheduleCommandResourceId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleRunDeferredHavingDistinctCountInput = {
  deferredScheduleRunId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  scheduleCommandResourceId?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `ScheduleRunDeferred` aggregates. */
export type ScheduleRunDeferredHavingInput = {
  AND?: InputMaybe<Array<ScheduleRunDeferredHavingInput>>;
  OR?: InputMaybe<Array<ScheduleRunDeferredHavingInput>>;
  average?: InputMaybe<ScheduleRunDeferredHavingAverageInput>;
  distinctCount?: InputMaybe<ScheduleRunDeferredHavingDistinctCountInput>;
  max?: InputMaybe<ScheduleRunDeferredHavingMaxInput>;
  min?: InputMaybe<ScheduleRunDeferredHavingMinInput>;
  stddevPopulation?: InputMaybe<ScheduleRunDeferredHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<ScheduleRunDeferredHavingStddevSampleInput>;
  sum?: InputMaybe<ScheduleRunDeferredHavingSumInput>;
  variancePopulation?: InputMaybe<ScheduleRunDeferredHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<ScheduleRunDeferredHavingVarianceSampleInput>;
};

export type ScheduleRunDeferredHavingMaxInput = {
  deferredScheduleRunId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  scheduleCommandResourceId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleRunDeferredHavingMinInput = {
  deferredScheduleRunId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  scheduleCommandResourceId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleRunDeferredHavingStddevPopulationInput = {
  deferredScheduleRunId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  scheduleCommandResourceId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleRunDeferredHavingStddevSampleInput = {
  deferredScheduleRunId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  scheduleCommandResourceId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleRunDeferredHavingSumInput = {
  deferredScheduleRunId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  scheduleCommandResourceId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleRunDeferredHavingVariancePopulationInput = {
  deferredScheduleRunId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  scheduleCommandResourceId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleRunDeferredHavingVarianceSampleInput = {
  deferredScheduleRunId?: InputMaybe<HavingIntFilter>;
  id?: InputMaybe<HavingIntFilter>;
  scheduleCommandResourceId?: InputMaybe<HavingIntFilter>;
};

export type ScheduleRunDeferredMaxAggregates = {
  __typename?: 'ScheduleRunDeferredMaxAggregates';
  /** Maximum of deferredScheduleRunId across the matching connection */
  deferredScheduleRunId?: Maybe<Scalars['Int']>;
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of scheduleCommandResourceId across the matching connection */
  scheduleCommandResourceId?: Maybe<Scalars['Int']>;
};

export type ScheduleRunDeferredMinAggregates = {
  __typename?: 'ScheduleRunDeferredMinAggregates';
  /** Minimum of deferredScheduleRunId across the matching connection */
  deferredScheduleRunId?: Maybe<Scalars['Int']>;
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of scheduleCommandResourceId across the matching connection */
  scheduleCommandResourceId?: Maybe<Scalars['Int']>;
};

export type ScheduleRunDeferredStddevPopulationAggregates = {
  __typename?: 'ScheduleRunDeferredStddevPopulationAggregates';
  /** Population standard deviation of deferredScheduleRunId across the matching connection */
  deferredScheduleRunId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of scheduleCommandResourceId across the matching connection */
  scheduleCommandResourceId?: Maybe<Scalars['BigFloat']>;
};

export type ScheduleRunDeferredStddevSampleAggregates = {
  __typename?: 'ScheduleRunDeferredStddevSampleAggregates';
  /** Sample standard deviation of deferredScheduleRunId across the matching connection */
  deferredScheduleRunId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of scheduleCommandResourceId across the matching connection */
  scheduleCommandResourceId?: Maybe<Scalars['BigFloat']>;
};

export type ScheduleRunDeferredSumAggregates = {
  __typename?: 'ScheduleRunDeferredSumAggregates';
  /** Sum of deferredScheduleRunId across the matching connection */
  deferredScheduleRunId: Scalars['BigInt'];
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of scheduleCommandResourceId across the matching connection */
  scheduleCommandResourceId: Scalars['BigInt'];
};

export type ScheduleRunDeferredVariancePopulationAggregates = {
  __typename?: 'ScheduleRunDeferredVariancePopulationAggregates';
  /** Population variance of deferredScheduleRunId across the matching connection */
  deferredScheduleRunId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of scheduleCommandResourceId across the matching connection */
  scheduleCommandResourceId?: Maybe<Scalars['BigFloat']>;
};

export type ScheduleRunDeferredVarianceSampleAggregates = {
  __typename?: 'ScheduleRunDeferredVarianceSampleAggregates';
  /** Sample variance of deferredScheduleRunId across the matching connection */
  deferredScheduleRunId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of scheduleCommandResourceId across the matching connection */
  scheduleCommandResourceId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `ScheduleRunDeferred` values. */
export type ScheduleRunDeferredsConnection = {
  __typename?: 'ScheduleRunDeferredsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ScheduleRunDeferredAggregates>;
  /** A list of edges which contains the `ScheduleRunDeferred` and cursor to aid in pagination. */
  edges: Array<ScheduleRunDeferredsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ScheduleRunDeferredAggregates>>;
  /** A list of `ScheduleRunDeferred` objects. */
  nodes: Array<ScheduleRunDeferred>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ScheduleRunDeferred` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `ScheduleRunDeferred` values. */
export type ScheduleRunDeferredsConnectionGroupedAggregatesArgs = {
  groupBy: Array<ScheduleRunDeferredGroupBy>;
  having?: InputMaybe<ScheduleRunDeferredHavingInput>;
};

/** A `ScheduleRunDeferred` edge in the connection. */
export type ScheduleRunDeferredsEdge = {
  __typename?: 'ScheduleRunDeferredsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ScheduleRunDeferred` at the end of the edge. */
  node: ScheduleRunDeferred;
};

/** Methods to use when ordering `ScheduleRunDeferred`. */
export enum ScheduleRunDeferredsOrderBy {
  DeferredScheduleRunIdAsc = 'DEFERRED_SCHEDULE_RUN_ID_ASC',
  DeferredScheduleRunIdDesc = 'DEFERRED_SCHEDULE_RUN_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ScheduleCommandResourceIdAsc = 'SCHEDULE_COMMAND_RESOURCE_ID_ASC',
  ScheduleCommandResourceIdDesc = 'SCHEDULE_COMMAND_RESOURCE_ID_DESC'
}

export type ScheduleRunDistinctCountAggregates = {
  __typename?: 'ScheduleRunDistinctCountAggregates';
  /** Distinct count of actor across the matching connection */
  actor?: Maybe<Scalars['BigInt']>;
  /** Distinct count of branch across the matching connection */
  branch?: Maybe<Scalars['BigInt']>;
  /** Distinct count of commitHash across the matching connection */
  commitHash?: Maybe<Scalars['BigInt']>;
  /** Distinct count of endDttm across the matching connection */
  endDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of errorString across the matching connection */
  errorString?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of podName across the matching connection */
  podName?: Maybe<Scalars['BigInt']>;
  /** Distinct count of pullRequestId across the matching connection */
  pullRequestId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of runManuallyByEmail across the matching connection */
  runManuallyByEmail?: Maybe<Scalars['BigInt']>;
  /** Distinct count of scheduleNameId across the matching connection */
  scheduleNameId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of scheduleNameUuid across the matching connection */
  scheduleNameUuid?: Maybe<Scalars['BigInt']>;
  /** Distinct count of startDttm across the matching connection */
  startDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of uuid across the matching connection */
  uuid?: Maybe<Scalars['BigInt']>;
};

/** Grouping methods for `ScheduleRun` for usage during aggregation. */
export enum ScheduleRunGroupBy {
  Actor = 'ACTOR',
  Branch = 'BRANCH',
  CommitHash = 'COMMIT_HASH',
  EndDttm = 'END_DTTM',
  EndDttmTruncatedToDay = 'END_DTTM_TRUNCATED_TO_DAY',
  EndDttmTruncatedToHour = 'END_DTTM_TRUNCATED_TO_HOUR',
  ErrorString = 'ERROR_STRING',
  PodName = 'POD_NAME',
  PullRequestId = 'PULL_REQUEST_ID',
  RunManuallyByEmail = 'RUN_MANUALLY_BY_EMAIL',
  ScheduleNameId = 'SCHEDULE_NAME_ID',
  ScheduleNameUuid = 'SCHEDULE_NAME_UUID',
  StartDttm = 'START_DTTM',
  StartDttmTruncatedToDay = 'START_DTTM_TRUNCATED_TO_DAY',
  StartDttmTruncatedToHour = 'START_DTTM_TRUNCATED_TO_HOUR',
  Uuid = 'UUID'
}

export type ScheduleRunHavingAverageInput = {
  endDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  scheduleNameId?: InputMaybe<HavingIntFilter>;
  startDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type ScheduleRunHavingDistinctCountInput = {
  endDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  scheduleNameId?: InputMaybe<HavingIntFilter>;
  startDttm?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `ScheduleRun` aggregates. */
export type ScheduleRunHavingInput = {
  AND?: InputMaybe<Array<ScheduleRunHavingInput>>;
  OR?: InputMaybe<Array<ScheduleRunHavingInput>>;
  average?: InputMaybe<ScheduleRunHavingAverageInput>;
  distinctCount?: InputMaybe<ScheduleRunHavingDistinctCountInput>;
  max?: InputMaybe<ScheduleRunHavingMaxInput>;
  min?: InputMaybe<ScheduleRunHavingMinInput>;
  stddevPopulation?: InputMaybe<ScheduleRunHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<ScheduleRunHavingStddevSampleInput>;
  sum?: InputMaybe<ScheduleRunHavingSumInput>;
  variancePopulation?: InputMaybe<ScheduleRunHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<ScheduleRunHavingVarianceSampleInput>;
};

export type ScheduleRunHavingMaxInput = {
  endDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  scheduleNameId?: InputMaybe<HavingIntFilter>;
  startDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type ScheduleRunHavingMinInput = {
  endDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  scheduleNameId?: InputMaybe<HavingIntFilter>;
  startDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type ScheduleRunHavingStddevPopulationInput = {
  endDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  scheduleNameId?: InputMaybe<HavingIntFilter>;
  startDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type ScheduleRunHavingStddevSampleInput = {
  endDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  scheduleNameId?: InputMaybe<HavingIntFilter>;
  startDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type ScheduleRunHavingSumInput = {
  endDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  scheduleNameId?: InputMaybe<HavingIntFilter>;
  startDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type ScheduleRunHavingVariancePopulationInput = {
  endDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  scheduleNameId?: InputMaybe<HavingIntFilter>;
  startDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type ScheduleRunHavingVarianceSampleInput = {
  endDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  scheduleNameId?: InputMaybe<HavingIntFilter>;
  startDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type ScheduleRunMaxAggregates = {
  __typename?: 'ScheduleRunMaxAggregates';
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of scheduleNameId across the matching connection */
  scheduleNameId?: Maybe<Scalars['Int']>;
};

export type ScheduleRunMinAggregates = {
  __typename?: 'ScheduleRunMinAggregates';
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of scheduleNameId across the matching connection */
  scheduleNameId?: Maybe<Scalars['Int']>;
};

export type ScheduleRunStddevPopulationAggregates = {
  __typename?: 'ScheduleRunStddevPopulationAggregates';
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of scheduleNameId across the matching connection */
  scheduleNameId?: Maybe<Scalars['BigFloat']>;
};

export type ScheduleRunStddevSampleAggregates = {
  __typename?: 'ScheduleRunStddevSampleAggregates';
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of scheduleNameId across the matching connection */
  scheduleNameId?: Maybe<Scalars['BigFloat']>;
};

export type ScheduleRunSumAggregates = {
  __typename?: 'ScheduleRunSumAggregates';
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of scheduleNameId across the matching connection */
  scheduleNameId: Scalars['BigInt'];
};

export type ScheduleRunVariancePopulationAggregates = {
  __typename?: 'ScheduleRunVariancePopulationAggregates';
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of scheduleNameId across the matching connection */
  scheduleNameId?: Maybe<Scalars['BigFloat']>;
};

export type ScheduleRunVarianceSampleAggregates = {
  __typename?: 'ScheduleRunVarianceSampleAggregates';
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of scheduleNameId across the matching connection */
  scheduleNameId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `ScheduleRun` values. */
export type ScheduleRunsConnection = {
  __typename?: 'ScheduleRunsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ScheduleRunAggregates>;
  /** A list of edges which contains the `ScheduleRun` and cursor to aid in pagination. */
  edges: Array<ScheduleRunsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ScheduleRunAggregates>>;
  /** A list of `ScheduleRun` objects. */
  nodes: Array<ScheduleRun>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ScheduleRun` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `ScheduleRun` values. */
export type ScheduleRunsConnectionGroupedAggregatesArgs = {
  groupBy: Array<ScheduleRunGroupBy>;
  having?: InputMaybe<ScheduleRunHavingInput>;
};

/** A `ScheduleRun` edge in the connection. */
export type ScheduleRunsEdge = {
  __typename?: 'ScheduleRunsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ScheduleRun` at the end of the edge. */
  node: ScheduleRun;
};

/** Methods to use when ordering `ScheduleRun`. */
export enum ScheduleRunsOrderBy {
  ActorAsc = 'ACTOR_ASC',
  ActorDesc = 'ACTOR_DESC',
  BranchAsc = 'BRANCH_ASC',
  BranchDesc = 'BRANCH_DESC',
  CommitHashAsc = 'COMMIT_HASH_ASC',
  CommitHashDesc = 'COMMIT_HASH_DESC',
  EndDttmAsc = 'END_DTTM_ASC',
  EndDttmDesc = 'END_DTTM_DESC',
  ErrorStringAsc = 'ERROR_STRING_ASC',
  ErrorStringDesc = 'ERROR_STRING_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PodNameAsc = 'POD_NAME_ASC',
  PodNameDesc = 'POD_NAME_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PullRequestIdAsc = 'PULL_REQUEST_ID_ASC',
  PullRequestIdDesc = 'PULL_REQUEST_ID_DESC',
  RunManuallyByEmailAsc = 'RUN_MANUALLY_BY_EMAIL_ASC',
  RunManuallyByEmailDesc = 'RUN_MANUALLY_BY_EMAIL_DESC',
  ScheduleCommandsAverageCommandAsc = 'SCHEDULE_COMMANDS_AVERAGE_COMMAND_ASC',
  ScheduleCommandsAverageCommandDesc = 'SCHEDULE_COMMANDS_AVERAGE_COMMAND_DESC',
  ScheduleCommandsAverageEndDttmAsc = 'SCHEDULE_COMMANDS_AVERAGE_END_DTTM_ASC',
  ScheduleCommandsAverageEndDttmDesc = 'SCHEDULE_COMMANDS_AVERAGE_END_DTTM_DESC',
  ScheduleCommandsAverageIdAsc = 'SCHEDULE_COMMANDS_AVERAGE_ID_ASC',
  ScheduleCommandsAverageIdDesc = 'SCHEDULE_COMMANDS_AVERAGE_ID_DESC',
  ScheduleCommandsAverageReturnCodeAsc = 'SCHEDULE_COMMANDS_AVERAGE_RETURN_CODE_ASC',
  ScheduleCommandsAverageReturnCodeDesc = 'SCHEDULE_COMMANDS_AVERAGE_RETURN_CODE_DESC',
  ScheduleCommandsAverageScheduleRunIdAsc = 'SCHEDULE_COMMANDS_AVERAGE_SCHEDULE_RUN_ID_ASC',
  ScheduleCommandsAverageScheduleRunIdDesc = 'SCHEDULE_COMMANDS_AVERAGE_SCHEDULE_RUN_ID_DESC',
  ScheduleCommandsAverageStartDttmAsc = 'SCHEDULE_COMMANDS_AVERAGE_START_DTTM_ASC',
  ScheduleCommandsAverageStartDttmDesc = 'SCHEDULE_COMMANDS_AVERAGE_START_DTTM_DESC',
  ScheduleCommandsAverageStderrAsc = 'SCHEDULE_COMMANDS_AVERAGE_STDERR_ASC',
  ScheduleCommandsAverageStderrDesc = 'SCHEDULE_COMMANDS_AVERAGE_STDERR_DESC',
  ScheduleCommandsAverageStdoutAsc = 'SCHEDULE_COMMANDS_AVERAGE_STDOUT_ASC',
  ScheduleCommandsAverageStdoutDesc = 'SCHEDULE_COMMANDS_AVERAGE_STDOUT_DESC',
  ScheduleCommandsAverageUuidAsc = 'SCHEDULE_COMMANDS_AVERAGE_UUID_ASC',
  ScheduleCommandsAverageUuidDesc = 'SCHEDULE_COMMANDS_AVERAGE_UUID_DESC',
  ScheduleCommandsCountAsc = 'SCHEDULE_COMMANDS_COUNT_ASC',
  ScheduleCommandsCountDesc = 'SCHEDULE_COMMANDS_COUNT_DESC',
  ScheduleCommandsDistinctCountCommandAsc = 'SCHEDULE_COMMANDS_DISTINCT_COUNT_COMMAND_ASC',
  ScheduleCommandsDistinctCountCommandDesc = 'SCHEDULE_COMMANDS_DISTINCT_COUNT_COMMAND_DESC',
  ScheduleCommandsDistinctCountEndDttmAsc = 'SCHEDULE_COMMANDS_DISTINCT_COUNT_END_DTTM_ASC',
  ScheduleCommandsDistinctCountEndDttmDesc = 'SCHEDULE_COMMANDS_DISTINCT_COUNT_END_DTTM_DESC',
  ScheduleCommandsDistinctCountIdAsc = 'SCHEDULE_COMMANDS_DISTINCT_COUNT_ID_ASC',
  ScheduleCommandsDistinctCountIdDesc = 'SCHEDULE_COMMANDS_DISTINCT_COUNT_ID_DESC',
  ScheduleCommandsDistinctCountReturnCodeAsc = 'SCHEDULE_COMMANDS_DISTINCT_COUNT_RETURN_CODE_ASC',
  ScheduleCommandsDistinctCountReturnCodeDesc = 'SCHEDULE_COMMANDS_DISTINCT_COUNT_RETURN_CODE_DESC',
  ScheduleCommandsDistinctCountScheduleRunIdAsc = 'SCHEDULE_COMMANDS_DISTINCT_COUNT_SCHEDULE_RUN_ID_ASC',
  ScheduleCommandsDistinctCountScheduleRunIdDesc = 'SCHEDULE_COMMANDS_DISTINCT_COUNT_SCHEDULE_RUN_ID_DESC',
  ScheduleCommandsDistinctCountStartDttmAsc = 'SCHEDULE_COMMANDS_DISTINCT_COUNT_START_DTTM_ASC',
  ScheduleCommandsDistinctCountStartDttmDesc = 'SCHEDULE_COMMANDS_DISTINCT_COUNT_START_DTTM_DESC',
  ScheduleCommandsDistinctCountStderrAsc = 'SCHEDULE_COMMANDS_DISTINCT_COUNT_STDERR_ASC',
  ScheduleCommandsDistinctCountStderrDesc = 'SCHEDULE_COMMANDS_DISTINCT_COUNT_STDERR_DESC',
  ScheduleCommandsDistinctCountStdoutAsc = 'SCHEDULE_COMMANDS_DISTINCT_COUNT_STDOUT_ASC',
  ScheduleCommandsDistinctCountStdoutDesc = 'SCHEDULE_COMMANDS_DISTINCT_COUNT_STDOUT_DESC',
  ScheduleCommandsDistinctCountUuidAsc = 'SCHEDULE_COMMANDS_DISTINCT_COUNT_UUID_ASC',
  ScheduleCommandsDistinctCountUuidDesc = 'SCHEDULE_COMMANDS_DISTINCT_COUNT_UUID_DESC',
  ScheduleCommandsMaxCommandAsc = 'SCHEDULE_COMMANDS_MAX_COMMAND_ASC',
  ScheduleCommandsMaxCommandDesc = 'SCHEDULE_COMMANDS_MAX_COMMAND_DESC',
  ScheduleCommandsMaxEndDttmAsc = 'SCHEDULE_COMMANDS_MAX_END_DTTM_ASC',
  ScheduleCommandsMaxEndDttmDesc = 'SCHEDULE_COMMANDS_MAX_END_DTTM_DESC',
  ScheduleCommandsMaxIdAsc = 'SCHEDULE_COMMANDS_MAX_ID_ASC',
  ScheduleCommandsMaxIdDesc = 'SCHEDULE_COMMANDS_MAX_ID_DESC',
  ScheduleCommandsMaxReturnCodeAsc = 'SCHEDULE_COMMANDS_MAX_RETURN_CODE_ASC',
  ScheduleCommandsMaxReturnCodeDesc = 'SCHEDULE_COMMANDS_MAX_RETURN_CODE_DESC',
  ScheduleCommandsMaxScheduleRunIdAsc = 'SCHEDULE_COMMANDS_MAX_SCHEDULE_RUN_ID_ASC',
  ScheduleCommandsMaxScheduleRunIdDesc = 'SCHEDULE_COMMANDS_MAX_SCHEDULE_RUN_ID_DESC',
  ScheduleCommandsMaxStartDttmAsc = 'SCHEDULE_COMMANDS_MAX_START_DTTM_ASC',
  ScheduleCommandsMaxStartDttmDesc = 'SCHEDULE_COMMANDS_MAX_START_DTTM_DESC',
  ScheduleCommandsMaxStderrAsc = 'SCHEDULE_COMMANDS_MAX_STDERR_ASC',
  ScheduleCommandsMaxStderrDesc = 'SCHEDULE_COMMANDS_MAX_STDERR_DESC',
  ScheduleCommandsMaxStdoutAsc = 'SCHEDULE_COMMANDS_MAX_STDOUT_ASC',
  ScheduleCommandsMaxStdoutDesc = 'SCHEDULE_COMMANDS_MAX_STDOUT_DESC',
  ScheduleCommandsMaxUuidAsc = 'SCHEDULE_COMMANDS_MAX_UUID_ASC',
  ScheduleCommandsMaxUuidDesc = 'SCHEDULE_COMMANDS_MAX_UUID_DESC',
  ScheduleCommandsMinCommandAsc = 'SCHEDULE_COMMANDS_MIN_COMMAND_ASC',
  ScheduleCommandsMinCommandDesc = 'SCHEDULE_COMMANDS_MIN_COMMAND_DESC',
  ScheduleCommandsMinEndDttmAsc = 'SCHEDULE_COMMANDS_MIN_END_DTTM_ASC',
  ScheduleCommandsMinEndDttmDesc = 'SCHEDULE_COMMANDS_MIN_END_DTTM_DESC',
  ScheduleCommandsMinIdAsc = 'SCHEDULE_COMMANDS_MIN_ID_ASC',
  ScheduleCommandsMinIdDesc = 'SCHEDULE_COMMANDS_MIN_ID_DESC',
  ScheduleCommandsMinReturnCodeAsc = 'SCHEDULE_COMMANDS_MIN_RETURN_CODE_ASC',
  ScheduleCommandsMinReturnCodeDesc = 'SCHEDULE_COMMANDS_MIN_RETURN_CODE_DESC',
  ScheduleCommandsMinScheduleRunIdAsc = 'SCHEDULE_COMMANDS_MIN_SCHEDULE_RUN_ID_ASC',
  ScheduleCommandsMinScheduleRunIdDesc = 'SCHEDULE_COMMANDS_MIN_SCHEDULE_RUN_ID_DESC',
  ScheduleCommandsMinStartDttmAsc = 'SCHEDULE_COMMANDS_MIN_START_DTTM_ASC',
  ScheduleCommandsMinStartDttmDesc = 'SCHEDULE_COMMANDS_MIN_START_DTTM_DESC',
  ScheduleCommandsMinStderrAsc = 'SCHEDULE_COMMANDS_MIN_STDERR_ASC',
  ScheduleCommandsMinStderrDesc = 'SCHEDULE_COMMANDS_MIN_STDERR_DESC',
  ScheduleCommandsMinStdoutAsc = 'SCHEDULE_COMMANDS_MIN_STDOUT_ASC',
  ScheduleCommandsMinStdoutDesc = 'SCHEDULE_COMMANDS_MIN_STDOUT_DESC',
  ScheduleCommandsMinUuidAsc = 'SCHEDULE_COMMANDS_MIN_UUID_ASC',
  ScheduleCommandsMinUuidDesc = 'SCHEDULE_COMMANDS_MIN_UUID_DESC',
  ScheduleCommandsStddevPopulationCommandAsc = 'SCHEDULE_COMMANDS_STDDEV_POPULATION_COMMAND_ASC',
  ScheduleCommandsStddevPopulationCommandDesc = 'SCHEDULE_COMMANDS_STDDEV_POPULATION_COMMAND_DESC',
  ScheduleCommandsStddevPopulationEndDttmAsc = 'SCHEDULE_COMMANDS_STDDEV_POPULATION_END_DTTM_ASC',
  ScheduleCommandsStddevPopulationEndDttmDesc = 'SCHEDULE_COMMANDS_STDDEV_POPULATION_END_DTTM_DESC',
  ScheduleCommandsStddevPopulationIdAsc = 'SCHEDULE_COMMANDS_STDDEV_POPULATION_ID_ASC',
  ScheduleCommandsStddevPopulationIdDesc = 'SCHEDULE_COMMANDS_STDDEV_POPULATION_ID_DESC',
  ScheduleCommandsStddevPopulationReturnCodeAsc = 'SCHEDULE_COMMANDS_STDDEV_POPULATION_RETURN_CODE_ASC',
  ScheduleCommandsStddevPopulationReturnCodeDesc = 'SCHEDULE_COMMANDS_STDDEV_POPULATION_RETURN_CODE_DESC',
  ScheduleCommandsStddevPopulationScheduleRunIdAsc = 'SCHEDULE_COMMANDS_STDDEV_POPULATION_SCHEDULE_RUN_ID_ASC',
  ScheduleCommandsStddevPopulationScheduleRunIdDesc = 'SCHEDULE_COMMANDS_STDDEV_POPULATION_SCHEDULE_RUN_ID_DESC',
  ScheduleCommandsStddevPopulationStartDttmAsc = 'SCHEDULE_COMMANDS_STDDEV_POPULATION_START_DTTM_ASC',
  ScheduleCommandsStddevPopulationStartDttmDesc = 'SCHEDULE_COMMANDS_STDDEV_POPULATION_START_DTTM_DESC',
  ScheduleCommandsStddevPopulationStderrAsc = 'SCHEDULE_COMMANDS_STDDEV_POPULATION_STDERR_ASC',
  ScheduleCommandsStddevPopulationStderrDesc = 'SCHEDULE_COMMANDS_STDDEV_POPULATION_STDERR_DESC',
  ScheduleCommandsStddevPopulationStdoutAsc = 'SCHEDULE_COMMANDS_STDDEV_POPULATION_STDOUT_ASC',
  ScheduleCommandsStddevPopulationStdoutDesc = 'SCHEDULE_COMMANDS_STDDEV_POPULATION_STDOUT_DESC',
  ScheduleCommandsStddevPopulationUuidAsc = 'SCHEDULE_COMMANDS_STDDEV_POPULATION_UUID_ASC',
  ScheduleCommandsStddevPopulationUuidDesc = 'SCHEDULE_COMMANDS_STDDEV_POPULATION_UUID_DESC',
  ScheduleCommandsStddevSampleCommandAsc = 'SCHEDULE_COMMANDS_STDDEV_SAMPLE_COMMAND_ASC',
  ScheduleCommandsStddevSampleCommandDesc = 'SCHEDULE_COMMANDS_STDDEV_SAMPLE_COMMAND_DESC',
  ScheduleCommandsStddevSampleEndDttmAsc = 'SCHEDULE_COMMANDS_STDDEV_SAMPLE_END_DTTM_ASC',
  ScheduleCommandsStddevSampleEndDttmDesc = 'SCHEDULE_COMMANDS_STDDEV_SAMPLE_END_DTTM_DESC',
  ScheduleCommandsStddevSampleIdAsc = 'SCHEDULE_COMMANDS_STDDEV_SAMPLE_ID_ASC',
  ScheduleCommandsStddevSampleIdDesc = 'SCHEDULE_COMMANDS_STDDEV_SAMPLE_ID_DESC',
  ScheduleCommandsStddevSampleReturnCodeAsc = 'SCHEDULE_COMMANDS_STDDEV_SAMPLE_RETURN_CODE_ASC',
  ScheduleCommandsStddevSampleReturnCodeDesc = 'SCHEDULE_COMMANDS_STDDEV_SAMPLE_RETURN_CODE_DESC',
  ScheduleCommandsStddevSampleScheduleRunIdAsc = 'SCHEDULE_COMMANDS_STDDEV_SAMPLE_SCHEDULE_RUN_ID_ASC',
  ScheduleCommandsStddevSampleScheduleRunIdDesc = 'SCHEDULE_COMMANDS_STDDEV_SAMPLE_SCHEDULE_RUN_ID_DESC',
  ScheduleCommandsStddevSampleStartDttmAsc = 'SCHEDULE_COMMANDS_STDDEV_SAMPLE_START_DTTM_ASC',
  ScheduleCommandsStddevSampleStartDttmDesc = 'SCHEDULE_COMMANDS_STDDEV_SAMPLE_START_DTTM_DESC',
  ScheduleCommandsStddevSampleStderrAsc = 'SCHEDULE_COMMANDS_STDDEV_SAMPLE_STDERR_ASC',
  ScheduleCommandsStddevSampleStderrDesc = 'SCHEDULE_COMMANDS_STDDEV_SAMPLE_STDERR_DESC',
  ScheduleCommandsStddevSampleStdoutAsc = 'SCHEDULE_COMMANDS_STDDEV_SAMPLE_STDOUT_ASC',
  ScheduleCommandsStddevSampleStdoutDesc = 'SCHEDULE_COMMANDS_STDDEV_SAMPLE_STDOUT_DESC',
  ScheduleCommandsStddevSampleUuidAsc = 'SCHEDULE_COMMANDS_STDDEV_SAMPLE_UUID_ASC',
  ScheduleCommandsStddevSampleUuidDesc = 'SCHEDULE_COMMANDS_STDDEV_SAMPLE_UUID_DESC',
  ScheduleCommandsSumCommandAsc = 'SCHEDULE_COMMANDS_SUM_COMMAND_ASC',
  ScheduleCommandsSumCommandDesc = 'SCHEDULE_COMMANDS_SUM_COMMAND_DESC',
  ScheduleCommandsSumEndDttmAsc = 'SCHEDULE_COMMANDS_SUM_END_DTTM_ASC',
  ScheduleCommandsSumEndDttmDesc = 'SCHEDULE_COMMANDS_SUM_END_DTTM_DESC',
  ScheduleCommandsSumIdAsc = 'SCHEDULE_COMMANDS_SUM_ID_ASC',
  ScheduleCommandsSumIdDesc = 'SCHEDULE_COMMANDS_SUM_ID_DESC',
  ScheduleCommandsSumReturnCodeAsc = 'SCHEDULE_COMMANDS_SUM_RETURN_CODE_ASC',
  ScheduleCommandsSumReturnCodeDesc = 'SCHEDULE_COMMANDS_SUM_RETURN_CODE_DESC',
  ScheduleCommandsSumScheduleRunIdAsc = 'SCHEDULE_COMMANDS_SUM_SCHEDULE_RUN_ID_ASC',
  ScheduleCommandsSumScheduleRunIdDesc = 'SCHEDULE_COMMANDS_SUM_SCHEDULE_RUN_ID_DESC',
  ScheduleCommandsSumStartDttmAsc = 'SCHEDULE_COMMANDS_SUM_START_DTTM_ASC',
  ScheduleCommandsSumStartDttmDesc = 'SCHEDULE_COMMANDS_SUM_START_DTTM_DESC',
  ScheduleCommandsSumStderrAsc = 'SCHEDULE_COMMANDS_SUM_STDERR_ASC',
  ScheduleCommandsSumStderrDesc = 'SCHEDULE_COMMANDS_SUM_STDERR_DESC',
  ScheduleCommandsSumStdoutAsc = 'SCHEDULE_COMMANDS_SUM_STDOUT_ASC',
  ScheduleCommandsSumStdoutDesc = 'SCHEDULE_COMMANDS_SUM_STDOUT_DESC',
  ScheduleCommandsSumUuidAsc = 'SCHEDULE_COMMANDS_SUM_UUID_ASC',
  ScheduleCommandsSumUuidDesc = 'SCHEDULE_COMMANDS_SUM_UUID_DESC',
  ScheduleCommandsVariancePopulationCommandAsc = 'SCHEDULE_COMMANDS_VARIANCE_POPULATION_COMMAND_ASC',
  ScheduleCommandsVariancePopulationCommandDesc = 'SCHEDULE_COMMANDS_VARIANCE_POPULATION_COMMAND_DESC',
  ScheduleCommandsVariancePopulationEndDttmAsc = 'SCHEDULE_COMMANDS_VARIANCE_POPULATION_END_DTTM_ASC',
  ScheduleCommandsVariancePopulationEndDttmDesc = 'SCHEDULE_COMMANDS_VARIANCE_POPULATION_END_DTTM_DESC',
  ScheduleCommandsVariancePopulationIdAsc = 'SCHEDULE_COMMANDS_VARIANCE_POPULATION_ID_ASC',
  ScheduleCommandsVariancePopulationIdDesc = 'SCHEDULE_COMMANDS_VARIANCE_POPULATION_ID_DESC',
  ScheduleCommandsVariancePopulationReturnCodeAsc = 'SCHEDULE_COMMANDS_VARIANCE_POPULATION_RETURN_CODE_ASC',
  ScheduleCommandsVariancePopulationReturnCodeDesc = 'SCHEDULE_COMMANDS_VARIANCE_POPULATION_RETURN_CODE_DESC',
  ScheduleCommandsVariancePopulationScheduleRunIdAsc = 'SCHEDULE_COMMANDS_VARIANCE_POPULATION_SCHEDULE_RUN_ID_ASC',
  ScheduleCommandsVariancePopulationScheduleRunIdDesc = 'SCHEDULE_COMMANDS_VARIANCE_POPULATION_SCHEDULE_RUN_ID_DESC',
  ScheduleCommandsVariancePopulationStartDttmAsc = 'SCHEDULE_COMMANDS_VARIANCE_POPULATION_START_DTTM_ASC',
  ScheduleCommandsVariancePopulationStartDttmDesc = 'SCHEDULE_COMMANDS_VARIANCE_POPULATION_START_DTTM_DESC',
  ScheduleCommandsVariancePopulationStderrAsc = 'SCHEDULE_COMMANDS_VARIANCE_POPULATION_STDERR_ASC',
  ScheduleCommandsVariancePopulationStderrDesc = 'SCHEDULE_COMMANDS_VARIANCE_POPULATION_STDERR_DESC',
  ScheduleCommandsVariancePopulationStdoutAsc = 'SCHEDULE_COMMANDS_VARIANCE_POPULATION_STDOUT_ASC',
  ScheduleCommandsVariancePopulationStdoutDesc = 'SCHEDULE_COMMANDS_VARIANCE_POPULATION_STDOUT_DESC',
  ScheduleCommandsVariancePopulationUuidAsc = 'SCHEDULE_COMMANDS_VARIANCE_POPULATION_UUID_ASC',
  ScheduleCommandsVariancePopulationUuidDesc = 'SCHEDULE_COMMANDS_VARIANCE_POPULATION_UUID_DESC',
  ScheduleCommandsVarianceSampleCommandAsc = 'SCHEDULE_COMMANDS_VARIANCE_SAMPLE_COMMAND_ASC',
  ScheduleCommandsVarianceSampleCommandDesc = 'SCHEDULE_COMMANDS_VARIANCE_SAMPLE_COMMAND_DESC',
  ScheduleCommandsVarianceSampleEndDttmAsc = 'SCHEDULE_COMMANDS_VARIANCE_SAMPLE_END_DTTM_ASC',
  ScheduleCommandsVarianceSampleEndDttmDesc = 'SCHEDULE_COMMANDS_VARIANCE_SAMPLE_END_DTTM_DESC',
  ScheduleCommandsVarianceSampleIdAsc = 'SCHEDULE_COMMANDS_VARIANCE_SAMPLE_ID_ASC',
  ScheduleCommandsVarianceSampleIdDesc = 'SCHEDULE_COMMANDS_VARIANCE_SAMPLE_ID_DESC',
  ScheduleCommandsVarianceSampleReturnCodeAsc = 'SCHEDULE_COMMANDS_VARIANCE_SAMPLE_RETURN_CODE_ASC',
  ScheduleCommandsVarianceSampleReturnCodeDesc = 'SCHEDULE_COMMANDS_VARIANCE_SAMPLE_RETURN_CODE_DESC',
  ScheduleCommandsVarianceSampleScheduleRunIdAsc = 'SCHEDULE_COMMANDS_VARIANCE_SAMPLE_SCHEDULE_RUN_ID_ASC',
  ScheduleCommandsVarianceSampleScheduleRunIdDesc = 'SCHEDULE_COMMANDS_VARIANCE_SAMPLE_SCHEDULE_RUN_ID_DESC',
  ScheduleCommandsVarianceSampleStartDttmAsc = 'SCHEDULE_COMMANDS_VARIANCE_SAMPLE_START_DTTM_ASC',
  ScheduleCommandsVarianceSampleStartDttmDesc = 'SCHEDULE_COMMANDS_VARIANCE_SAMPLE_START_DTTM_DESC',
  ScheduleCommandsVarianceSampleStderrAsc = 'SCHEDULE_COMMANDS_VARIANCE_SAMPLE_STDERR_ASC',
  ScheduleCommandsVarianceSampleStderrDesc = 'SCHEDULE_COMMANDS_VARIANCE_SAMPLE_STDERR_DESC',
  ScheduleCommandsVarianceSampleStdoutAsc = 'SCHEDULE_COMMANDS_VARIANCE_SAMPLE_STDOUT_ASC',
  ScheduleCommandsVarianceSampleStdoutDesc = 'SCHEDULE_COMMANDS_VARIANCE_SAMPLE_STDOUT_DESC',
  ScheduleCommandsVarianceSampleUuidAsc = 'SCHEDULE_COMMANDS_VARIANCE_SAMPLE_UUID_ASC',
  ScheduleCommandsVarianceSampleUuidDesc = 'SCHEDULE_COMMANDS_VARIANCE_SAMPLE_UUID_DESC',
  ScheduleNameIdAsc = 'SCHEDULE_NAME_ID_ASC',
  ScheduleNameIdDesc = 'SCHEDULE_NAME_ID_DESC',
  ScheduleNameUuidAsc = 'SCHEDULE_NAME_UUID_ASC',
  ScheduleNameUuidDesc = 'SCHEDULE_NAME_UUID_DESC',
  ScheduleRunCancelledsByRunIdAverageActorAsc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_AVERAGE_ACTOR_ASC',
  ScheduleRunCancelledsByRunIdAverageActorDesc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_AVERAGE_ACTOR_DESC',
  ScheduleRunCancelledsByRunIdAverageDatetimeAsc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_AVERAGE_DATETIME_ASC',
  ScheduleRunCancelledsByRunIdAverageDatetimeDesc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_AVERAGE_DATETIME_DESC',
  ScheduleRunCancelledsByRunIdAverageIdAsc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_AVERAGE_ID_ASC',
  ScheduleRunCancelledsByRunIdAverageIdDesc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_AVERAGE_ID_DESC',
  ScheduleRunCancelledsByRunIdAverageRunIdAsc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_AVERAGE_RUN_ID_ASC',
  ScheduleRunCancelledsByRunIdAverageRunIdDesc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_AVERAGE_RUN_ID_DESC',
  ScheduleRunCancelledsByRunIdAverageUserEmailAsc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_AVERAGE_USER_EMAIL_ASC',
  ScheduleRunCancelledsByRunIdAverageUserEmailDesc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_AVERAGE_USER_EMAIL_DESC',
  ScheduleRunCancelledsByRunIdCountAsc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_COUNT_ASC',
  ScheduleRunCancelledsByRunIdCountDesc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_COUNT_DESC',
  ScheduleRunCancelledsByRunIdDistinctCountActorAsc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_DISTINCT_COUNT_ACTOR_ASC',
  ScheduleRunCancelledsByRunIdDistinctCountActorDesc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_DISTINCT_COUNT_ACTOR_DESC',
  ScheduleRunCancelledsByRunIdDistinctCountDatetimeAsc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_DISTINCT_COUNT_DATETIME_ASC',
  ScheduleRunCancelledsByRunIdDistinctCountDatetimeDesc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_DISTINCT_COUNT_DATETIME_DESC',
  ScheduleRunCancelledsByRunIdDistinctCountIdAsc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_DISTINCT_COUNT_ID_ASC',
  ScheduleRunCancelledsByRunIdDistinctCountIdDesc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_DISTINCT_COUNT_ID_DESC',
  ScheduleRunCancelledsByRunIdDistinctCountRunIdAsc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_DISTINCT_COUNT_RUN_ID_ASC',
  ScheduleRunCancelledsByRunIdDistinctCountRunIdDesc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_DISTINCT_COUNT_RUN_ID_DESC',
  ScheduleRunCancelledsByRunIdDistinctCountUserEmailAsc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_DISTINCT_COUNT_USER_EMAIL_ASC',
  ScheduleRunCancelledsByRunIdDistinctCountUserEmailDesc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_DISTINCT_COUNT_USER_EMAIL_DESC',
  ScheduleRunCancelledsByRunIdMaxActorAsc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_MAX_ACTOR_ASC',
  ScheduleRunCancelledsByRunIdMaxActorDesc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_MAX_ACTOR_DESC',
  ScheduleRunCancelledsByRunIdMaxDatetimeAsc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_MAX_DATETIME_ASC',
  ScheduleRunCancelledsByRunIdMaxDatetimeDesc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_MAX_DATETIME_DESC',
  ScheduleRunCancelledsByRunIdMaxIdAsc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_MAX_ID_ASC',
  ScheduleRunCancelledsByRunIdMaxIdDesc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_MAX_ID_DESC',
  ScheduleRunCancelledsByRunIdMaxRunIdAsc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_MAX_RUN_ID_ASC',
  ScheduleRunCancelledsByRunIdMaxRunIdDesc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_MAX_RUN_ID_DESC',
  ScheduleRunCancelledsByRunIdMaxUserEmailAsc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_MAX_USER_EMAIL_ASC',
  ScheduleRunCancelledsByRunIdMaxUserEmailDesc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_MAX_USER_EMAIL_DESC',
  ScheduleRunCancelledsByRunIdMinActorAsc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_MIN_ACTOR_ASC',
  ScheduleRunCancelledsByRunIdMinActorDesc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_MIN_ACTOR_DESC',
  ScheduleRunCancelledsByRunIdMinDatetimeAsc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_MIN_DATETIME_ASC',
  ScheduleRunCancelledsByRunIdMinDatetimeDesc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_MIN_DATETIME_DESC',
  ScheduleRunCancelledsByRunIdMinIdAsc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_MIN_ID_ASC',
  ScheduleRunCancelledsByRunIdMinIdDesc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_MIN_ID_DESC',
  ScheduleRunCancelledsByRunIdMinRunIdAsc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_MIN_RUN_ID_ASC',
  ScheduleRunCancelledsByRunIdMinRunIdDesc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_MIN_RUN_ID_DESC',
  ScheduleRunCancelledsByRunIdMinUserEmailAsc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_MIN_USER_EMAIL_ASC',
  ScheduleRunCancelledsByRunIdMinUserEmailDesc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_MIN_USER_EMAIL_DESC',
  ScheduleRunCancelledsByRunIdStddevPopulationActorAsc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_STDDEV_POPULATION_ACTOR_ASC',
  ScheduleRunCancelledsByRunIdStddevPopulationActorDesc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_STDDEV_POPULATION_ACTOR_DESC',
  ScheduleRunCancelledsByRunIdStddevPopulationDatetimeAsc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_STDDEV_POPULATION_DATETIME_ASC',
  ScheduleRunCancelledsByRunIdStddevPopulationDatetimeDesc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_STDDEV_POPULATION_DATETIME_DESC',
  ScheduleRunCancelledsByRunIdStddevPopulationIdAsc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_STDDEV_POPULATION_ID_ASC',
  ScheduleRunCancelledsByRunIdStddevPopulationIdDesc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_STDDEV_POPULATION_ID_DESC',
  ScheduleRunCancelledsByRunIdStddevPopulationRunIdAsc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_STDDEV_POPULATION_RUN_ID_ASC',
  ScheduleRunCancelledsByRunIdStddevPopulationRunIdDesc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_STDDEV_POPULATION_RUN_ID_DESC',
  ScheduleRunCancelledsByRunIdStddevPopulationUserEmailAsc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_STDDEV_POPULATION_USER_EMAIL_ASC',
  ScheduleRunCancelledsByRunIdStddevPopulationUserEmailDesc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_STDDEV_POPULATION_USER_EMAIL_DESC',
  ScheduleRunCancelledsByRunIdStddevSampleActorAsc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_STDDEV_SAMPLE_ACTOR_ASC',
  ScheduleRunCancelledsByRunIdStddevSampleActorDesc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_STDDEV_SAMPLE_ACTOR_DESC',
  ScheduleRunCancelledsByRunIdStddevSampleDatetimeAsc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_STDDEV_SAMPLE_DATETIME_ASC',
  ScheduleRunCancelledsByRunIdStddevSampleDatetimeDesc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_STDDEV_SAMPLE_DATETIME_DESC',
  ScheduleRunCancelledsByRunIdStddevSampleIdAsc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_STDDEV_SAMPLE_ID_ASC',
  ScheduleRunCancelledsByRunIdStddevSampleIdDesc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_STDDEV_SAMPLE_ID_DESC',
  ScheduleRunCancelledsByRunIdStddevSampleRunIdAsc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_STDDEV_SAMPLE_RUN_ID_ASC',
  ScheduleRunCancelledsByRunIdStddevSampleRunIdDesc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_STDDEV_SAMPLE_RUN_ID_DESC',
  ScheduleRunCancelledsByRunIdStddevSampleUserEmailAsc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_STDDEV_SAMPLE_USER_EMAIL_ASC',
  ScheduleRunCancelledsByRunIdStddevSampleUserEmailDesc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_STDDEV_SAMPLE_USER_EMAIL_DESC',
  ScheduleRunCancelledsByRunIdSumActorAsc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_SUM_ACTOR_ASC',
  ScheduleRunCancelledsByRunIdSumActorDesc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_SUM_ACTOR_DESC',
  ScheduleRunCancelledsByRunIdSumDatetimeAsc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_SUM_DATETIME_ASC',
  ScheduleRunCancelledsByRunIdSumDatetimeDesc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_SUM_DATETIME_DESC',
  ScheduleRunCancelledsByRunIdSumIdAsc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_SUM_ID_ASC',
  ScheduleRunCancelledsByRunIdSumIdDesc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_SUM_ID_DESC',
  ScheduleRunCancelledsByRunIdSumRunIdAsc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_SUM_RUN_ID_ASC',
  ScheduleRunCancelledsByRunIdSumRunIdDesc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_SUM_RUN_ID_DESC',
  ScheduleRunCancelledsByRunIdSumUserEmailAsc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_SUM_USER_EMAIL_ASC',
  ScheduleRunCancelledsByRunIdSumUserEmailDesc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_SUM_USER_EMAIL_DESC',
  ScheduleRunCancelledsByRunIdVariancePopulationActorAsc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_VARIANCE_POPULATION_ACTOR_ASC',
  ScheduleRunCancelledsByRunIdVariancePopulationActorDesc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_VARIANCE_POPULATION_ACTOR_DESC',
  ScheduleRunCancelledsByRunIdVariancePopulationDatetimeAsc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_VARIANCE_POPULATION_DATETIME_ASC',
  ScheduleRunCancelledsByRunIdVariancePopulationDatetimeDesc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_VARIANCE_POPULATION_DATETIME_DESC',
  ScheduleRunCancelledsByRunIdVariancePopulationIdAsc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_VARIANCE_POPULATION_ID_ASC',
  ScheduleRunCancelledsByRunIdVariancePopulationIdDesc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_VARIANCE_POPULATION_ID_DESC',
  ScheduleRunCancelledsByRunIdVariancePopulationRunIdAsc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_VARIANCE_POPULATION_RUN_ID_ASC',
  ScheduleRunCancelledsByRunIdVariancePopulationRunIdDesc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_VARIANCE_POPULATION_RUN_ID_DESC',
  ScheduleRunCancelledsByRunIdVariancePopulationUserEmailAsc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_VARIANCE_POPULATION_USER_EMAIL_ASC',
  ScheduleRunCancelledsByRunIdVariancePopulationUserEmailDesc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_VARIANCE_POPULATION_USER_EMAIL_DESC',
  ScheduleRunCancelledsByRunIdVarianceSampleActorAsc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_VARIANCE_SAMPLE_ACTOR_ASC',
  ScheduleRunCancelledsByRunIdVarianceSampleActorDesc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_VARIANCE_SAMPLE_ACTOR_DESC',
  ScheduleRunCancelledsByRunIdVarianceSampleDatetimeAsc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_VARIANCE_SAMPLE_DATETIME_ASC',
  ScheduleRunCancelledsByRunIdVarianceSampleDatetimeDesc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_VARIANCE_SAMPLE_DATETIME_DESC',
  ScheduleRunCancelledsByRunIdVarianceSampleIdAsc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_VARIANCE_SAMPLE_ID_ASC',
  ScheduleRunCancelledsByRunIdVarianceSampleIdDesc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_VARIANCE_SAMPLE_ID_DESC',
  ScheduleRunCancelledsByRunIdVarianceSampleRunIdAsc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_VARIANCE_SAMPLE_RUN_ID_ASC',
  ScheduleRunCancelledsByRunIdVarianceSampleRunIdDesc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_VARIANCE_SAMPLE_RUN_ID_DESC',
  ScheduleRunCancelledsByRunIdVarianceSampleUserEmailAsc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_VARIANCE_SAMPLE_USER_EMAIL_ASC',
  ScheduleRunCancelledsByRunIdVarianceSampleUserEmailDesc = 'SCHEDULE_RUN_CANCELLEDS_BY_RUN_ID_VARIANCE_SAMPLE_USER_EMAIL_DESC',
  ScheduleRunDeferredsByDeferredScheduleRunIdAverageDeferredScheduleRunIdAsc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_AVERAGE_DEFERRED_SCHEDULE_RUN_ID_ASC',
  ScheduleRunDeferredsByDeferredScheduleRunIdAverageDeferredScheduleRunIdDesc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_AVERAGE_DEFERRED_SCHEDULE_RUN_ID_DESC',
  ScheduleRunDeferredsByDeferredScheduleRunIdAverageIdAsc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_AVERAGE_ID_ASC',
  ScheduleRunDeferredsByDeferredScheduleRunIdAverageIdDesc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_AVERAGE_ID_DESC',
  ScheduleRunDeferredsByDeferredScheduleRunIdAverageScheduleCommandResourceIdAsc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_AVERAGE_SCHEDULE_COMMAND_RESOURCE_ID_ASC',
  ScheduleRunDeferredsByDeferredScheduleRunIdAverageScheduleCommandResourceIdDesc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_AVERAGE_SCHEDULE_COMMAND_RESOURCE_ID_DESC',
  ScheduleRunDeferredsByDeferredScheduleRunIdCountAsc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_COUNT_ASC',
  ScheduleRunDeferredsByDeferredScheduleRunIdCountDesc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_COUNT_DESC',
  ScheduleRunDeferredsByDeferredScheduleRunIdDistinctCountDeferredScheduleRunIdAsc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_DISTINCT_COUNT_DEFERRED_SCHEDULE_RUN_ID_ASC',
  ScheduleRunDeferredsByDeferredScheduleRunIdDistinctCountDeferredScheduleRunIdDesc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_DISTINCT_COUNT_DEFERRED_SCHEDULE_RUN_ID_DESC',
  ScheduleRunDeferredsByDeferredScheduleRunIdDistinctCountIdAsc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_DISTINCT_COUNT_ID_ASC',
  ScheduleRunDeferredsByDeferredScheduleRunIdDistinctCountIdDesc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_DISTINCT_COUNT_ID_DESC',
  ScheduleRunDeferredsByDeferredScheduleRunIdDistinctCountScheduleCommandResourceIdAsc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_DISTINCT_COUNT_SCHEDULE_COMMAND_RESOURCE_ID_ASC',
  ScheduleRunDeferredsByDeferredScheduleRunIdDistinctCountScheduleCommandResourceIdDesc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_DISTINCT_COUNT_SCHEDULE_COMMAND_RESOURCE_ID_DESC',
  ScheduleRunDeferredsByDeferredScheduleRunIdMaxDeferredScheduleRunIdAsc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_MAX_DEFERRED_SCHEDULE_RUN_ID_ASC',
  ScheduleRunDeferredsByDeferredScheduleRunIdMaxDeferredScheduleRunIdDesc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_MAX_DEFERRED_SCHEDULE_RUN_ID_DESC',
  ScheduleRunDeferredsByDeferredScheduleRunIdMaxIdAsc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_MAX_ID_ASC',
  ScheduleRunDeferredsByDeferredScheduleRunIdMaxIdDesc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_MAX_ID_DESC',
  ScheduleRunDeferredsByDeferredScheduleRunIdMaxScheduleCommandResourceIdAsc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_MAX_SCHEDULE_COMMAND_RESOURCE_ID_ASC',
  ScheduleRunDeferredsByDeferredScheduleRunIdMaxScheduleCommandResourceIdDesc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_MAX_SCHEDULE_COMMAND_RESOURCE_ID_DESC',
  ScheduleRunDeferredsByDeferredScheduleRunIdMinDeferredScheduleRunIdAsc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_MIN_DEFERRED_SCHEDULE_RUN_ID_ASC',
  ScheduleRunDeferredsByDeferredScheduleRunIdMinDeferredScheduleRunIdDesc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_MIN_DEFERRED_SCHEDULE_RUN_ID_DESC',
  ScheduleRunDeferredsByDeferredScheduleRunIdMinIdAsc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_MIN_ID_ASC',
  ScheduleRunDeferredsByDeferredScheduleRunIdMinIdDesc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_MIN_ID_DESC',
  ScheduleRunDeferredsByDeferredScheduleRunIdMinScheduleCommandResourceIdAsc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_MIN_SCHEDULE_COMMAND_RESOURCE_ID_ASC',
  ScheduleRunDeferredsByDeferredScheduleRunIdMinScheduleCommandResourceIdDesc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_MIN_SCHEDULE_COMMAND_RESOURCE_ID_DESC',
  ScheduleRunDeferredsByDeferredScheduleRunIdStddevPopulationDeferredScheduleRunIdAsc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_STDDEV_POPULATION_DEFERRED_SCHEDULE_RUN_ID_ASC',
  ScheduleRunDeferredsByDeferredScheduleRunIdStddevPopulationDeferredScheduleRunIdDesc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_STDDEV_POPULATION_DEFERRED_SCHEDULE_RUN_ID_DESC',
  ScheduleRunDeferredsByDeferredScheduleRunIdStddevPopulationIdAsc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_STDDEV_POPULATION_ID_ASC',
  ScheduleRunDeferredsByDeferredScheduleRunIdStddevPopulationIdDesc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_STDDEV_POPULATION_ID_DESC',
  ScheduleRunDeferredsByDeferredScheduleRunIdStddevPopulationScheduleCommandResourceIdAsc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_STDDEV_POPULATION_SCHEDULE_COMMAND_RESOURCE_ID_ASC',
  ScheduleRunDeferredsByDeferredScheduleRunIdStddevPopulationScheduleCommandResourceIdDesc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_STDDEV_POPULATION_SCHEDULE_COMMAND_RESOURCE_ID_DESC',
  ScheduleRunDeferredsByDeferredScheduleRunIdStddevSampleDeferredScheduleRunIdAsc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_STDDEV_SAMPLE_DEFERRED_SCHEDULE_RUN_ID_ASC',
  ScheduleRunDeferredsByDeferredScheduleRunIdStddevSampleDeferredScheduleRunIdDesc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_STDDEV_SAMPLE_DEFERRED_SCHEDULE_RUN_ID_DESC',
  ScheduleRunDeferredsByDeferredScheduleRunIdStddevSampleIdAsc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_STDDEV_SAMPLE_ID_ASC',
  ScheduleRunDeferredsByDeferredScheduleRunIdStddevSampleIdDesc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_STDDEV_SAMPLE_ID_DESC',
  ScheduleRunDeferredsByDeferredScheduleRunIdStddevSampleScheduleCommandResourceIdAsc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_STDDEV_SAMPLE_SCHEDULE_COMMAND_RESOURCE_ID_ASC',
  ScheduleRunDeferredsByDeferredScheduleRunIdStddevSampleScheduleCommandResourceIdDesc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_STDDEV_SAMPLE_SCHEDULE_COMMAND_RESOURCE_ID_DESC',
  ScheduleRunDeferredsByDeferredScheduleRunIdSumDeferredScheduleRunIdAsc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_SUM_DEFERRED_SCHEDULE_RUN_ID_ASC',
  ScheduleRunDeferredsByDeferredScheduleRunIdSumDeferredScheduleRunIdDesc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_SUM_DEFERRED_SCHEDULE_RUN_ID_DESC',
  ScheduleRunDeferredsByDeferredScheduleRunIdSumIdAsc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_SUM_ID_ASC',
  ScheduleRunDeferredsByDeferredScheduleRunIdSumIdDesc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_SUM_ID_DESC',
  ScheduleRunDeferredsByDeferredScheduleRunIdSumScheduleCommandResourceIdAsc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_SUM_SCHEDULE_COMMAND_RESOURCE_ID_ASC',
  ScheduleRunDeferredsByDeferredScheduleRunIdSumScheduleCommandResourceIdDesc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_SUM_SCHEDULE_COMMAND_RESOURCE_ID_DESC',
  ScheduleRunDeferredsByDeferredScheduleRunIdVariancePopulationDeferredScheduleRunIdAsc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_VARIANCE_POPULATION_DEFERRED_SCHEDULE_RUN_ID_ASC',
  ScheduleRunDeferredsByDeferredScheduleRunIdVariancePopulationDeferredScheduleRunIdDesc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_VARIANCE_POPULATION_DEFERRED_SCHEDULE_RUN_ID_DESC',
  ScheduleRunDeferredsByDeferredScheduleRunIdVariancePopulationIdAsc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_VARIANCE_POPULATION_ID_ASC',
  ScheduleRunDeferredsByDeferredScheduleRunIdVariancePopulationIdDesc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_VARIANCE_POPULATION_ID_DESC',
  ScheduleRunDeferredsByDeferredScheduleRunIdVariancePopulationScheduleCommandResourceIdAsc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_VARIANCE_POPULATION_SCHEDULE_COMMAND_RESOURCE_ID_ASC',
  ScheduleRunDeferredsByDeferredScheduleRunIdVariancePopulationScheduleCommandResourceIdDesc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_VARIANCE_POPULATION_SCHEDULE_COMMAND_RESOURCE_ID_DESC',
  ScheduleRunDeferredsByDeferredScheduleRunIdVarianceSampleDeferredScheduleRunIdAsc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_VARIANCE_SAMPLE_DEFERRED_SCHEDULE_RUN_ID_ASC',
  ScheduleRunDeferredsByDeferredScheduleRunIdVarianceSampleDeferredScheduleRunIdDesc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_VARIANCE_SAMPLE_DEFERRED_SCHEDULE_RUN_ID_DESC',
  ScheduleRunDeferredsByDeferredScheduleRunIdVarianceSampleIdAsc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_VARIANCE_SAMPLE_ID_ASC',
  ScheduleRunDeferredsByDeferredScheduleRunIdVarianceSampleIdDesc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_VARIANCE_SAMPLE_ID_DESC',
  ScheduleRunDeferredsByDeferredScheduleRunIdVarianceSampleScheduleCommandResourceIdAsc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_VARIANCE_SAMPLE_SCHEDULE_COMMAND_RESOURCE_ID_ASC',
  ScheduleRunDeferredsByDeferredScheduleRunIdVarianceSampleScheduleCommandResourceIdDesc = 'SCHEDULE_RUN_DEFERREDS_BY_DEFERRED_SCHEDULE_RUN_ID_VARIANCE_SAMPLE_SCHEDULE_COMMAND_RESOURCE_ID_DESC',
  StartDttmAsc = 'START_DTTM_ASC',
  StartDttmDesc = 'START_DTTM_DESC',
  UuidAsc = 'UUID_ASC',
  UuidDesc = 'UUID_DESC'
}

export type TableauNode = Node & {
  __typename?: 'TableauNode';
  attributes: Scalars['String'];
  createdDttm: Scalars['Datetime'];
  id: Scalars['Int'];
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  nodeType: Scalars['String'];
  references: Scalars['String'];
  tableauIds: Array<Maybe<Scalars['String']>>;
  /** Reads a single `TableauSnapshot` that is related to this `TableauNode`. */
  tableauSnapshot?: Maybe<TableauSnapshot>;
  tableauSnapshotId: Scalars['Int'];
  tags: Scalars['String'];
};

export type TableauNodeAggregates = {
  __typename?: 'TableauNodeAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<TableauNodeAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<TableauNodeDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<TableauNodeMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<TableauNodeMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<TableauNodeStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<TableauNodeStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<TableauNodeSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<TableauNodeVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<TableauNodeVarianceSampleAggregates>;
};

export type TableauNodeAverageAggregates = {
  __typename?: 'TableauNodeAverageAggregates';
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of tableauSnapshotId across the matching connection */
  tableauSnapshotId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `TableauNode` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type TableauNodeCondition = {
  /** Checks for equality with the object’s `attributes` field. */
  attributes?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdDttm` field. */
  createdDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `nodeType` field. */
  nodeType?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `references` field. */
  references?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `tableauIds` field. */
  tableauIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `tableauSnapshotId` field. */
  tableauSnapshotId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `tags` field. */
  tags?: InputMaybe<Scalars['String']>;
};

export type TableauNodeDistinctCountAggregates = {
  __typename?: 'TableauNodeDistinctCountAggregates';
  /** Distinct count of attributes across the matching connection */
  attributes?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdDttm across the matching connection */
  createdDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of nodeType across the matching connection */
  nodeType?: Maybe<Scalars['BigInt']>;
  /** Distinct count of references across the matching connection */
  references?: Maybe<Scalars['BigInt']>;
  /** Distinct count of tableauIds across the matching connection */
  tableauIds?: Maybe<Scalars['BigInt']>;
  /** Distinct count of tableauSnapshotId across the matching connection */
  tableauSnapshotId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of tags across the matching connection */
  tags?: Maybe<Scalars['BigInt']>;
};

export type TableauNodeMaxAggregates = {
  __typename?: 'TableauNodeMaxAggregates';
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of tableauSnapshotId across the matching connection */
  tableauSnapshotId?: Maybe<Scalars['Int']>;
};

export type TableauNodeMinAggregates = {
  __typename?: 'TableauNodeMinAggregates';
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of tableauSnapshotId across the matching connection */
  tableauSnapshotId?: Maybe<Scalars['Int']>;
};

export type TableauNodeStddevPopulationAggregates = {
  __typename?: 'TableauNodeStddevPopulationAggregates';
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of tableauSnapshotId across the matching connection */
  tableauSnapshotId?: Maybe<Scalars['BigFloat']>;
};

export type TableauNodeStddevSampleAggregates = {
  __typename?: 'TableauNodeStddevSampleAggregates';
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of tableauSnapshotId across the matching connection */
  tableauSnapshotId?: Maybe<Scalars['BigFloat']>;
};

export type TableauNodeSumAggregates = {
  __typename?: 'TableauNodeSumAggregates';
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of tableauSnapshotId across the matching connection */
  tableauSnapshotId: Scalars['BigInt'];
};

export type TableauNodeVariancePopulationAggregates = {
  __typename?: 'TableauNodeVariancePopulationAggregates';
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of tableauSnapshotId across the matching connection */
  tableauSnapshotId?: Maybe<Scalars['BigFloat']>;
};

export type TableauNodeVarianceSampleAggregates = {
  __typename?: 'TableauNodeVarianceSampleAggregates';
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of tableauSnapshotId across the matching connection */
  tableauSnapshotId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `TableauNode` values. */
export type TableauNodesConnection = {
  __typename?: 'TableauNodesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<TableauNodeAggregates>;
  /** A list of edges which contains the `TableauNode` and cursor to aid in pagination. */
  edges: Array<TableauNodesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<TableauNodeAggregates>>;
  /** A list of `TableauNode` objects. */
  nodes: Array<TableauNode>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TableauNode` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `TableauNode` values. */
export type TableauNodesConnectionGroupedAggregatesArgs = {
  groupBy: Array<TableauNodesGroupBy>;
  having?: InputMaybe<TableauNodesHavingInput>;
};

/** A `TableauNode` edge in the connection. */
export type TableauNodesEdge = {
  __typename?: 'TableauNodesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `TableauNode` at the end of the edge. */
  node: TableauNode;
};

/** Grouping methods for `TableauNode` for usage during aggregation. */
export enum TableauNodesGroupBy {
  Attributes = 'ATTRIBUTES',
  CreatedDttm = 'CREATED_DTTM',
  CreatedDttmTruncatedToDay = 'CREATED_DTTM_TRUNCATED_TO_DAY',
  CreatedDttmTruncatedToHour = 'CREATED_DTTM_TRUNCATED_TO_HOUR',
  Name = 'NAME',
  NodeType = 'NODE_TYPE',
  References = 'REFERENCES',
  TableauIds = 'TABLEAU_IDS',
  TableauSnapshotId = 'TABLEAU_SNAPSHOT_ID',
  Tags = 'TAGS'
}

export type TableauNodesHavingAverageInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  tableauSnapshotId?: InputMaybe<HavingIntFilter>;
};

export type TableauNodesHavingDistinctCountInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  tableauSnapshotId?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `TableauNode` aggregates. */
export type TableauNodesHavingInput = {
  AND?: InputMaybe<Array<TableauNodesHavingInput>>;
  OR?: InputMaybe<Array<TableauNodesHavingInput>>;
  average?: InputMaybe<TableauNodesHavingAverageInput>;
  distinctCount?: InputMaybe<TableauNodesHavingDistinctCountInput>;
  max?: InputMaybe<TableauNodesHavingMaxInput>;
  min?: InputMaybe<TableauNodesHavingMinInput>;
  stddevPopulation?: InputMaybe<TableauNodesHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<TableauNodesHavingStddevSampleInput>;
  sum?: InputMaybe<TableauNodesHavingSumInput>;
  variancePopulation?: InputMaybe<TableauNodesHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<TableauNodesHavingVarianceSampleInput>;
};

export type TableauNodesHavingMaxInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  tableauSnapshotId?: InputMaybe<HavingIntFilter>;
};

export type TableauNodesHavingMinInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  tableauSnapshotId?: InputMaybe<HavingIntFilter>;
};

export type TableauNodesHavingStddevPopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  tableauSnapshotId?: InputMaybe<HavingIntFilter>;
};

export type TableauNodesHavingStddevSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  tableauSnapshotId?: InputMaybe<HavingIntFilter>;
};

export type TableauNodesHavingSumInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  tableauSnapshotId?: InputMaybe<HavingIntFilter>;
};

export type TableauNodesHavingVariancePopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  tableauSnapshotId?: InputMaybe<HavingIntFilter>;
};

export type TableauNodesHavingVarianceSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  tableauSnapshotId?: InputMaybe<HavingIntFilter>;
};

/** Methods to use when ordering `TableauNode`. */
export enum TableauNodesOrderBy {
  AttributesAsc = 'ATTRIBUTES_ASC',
  AttributesDesc = 'ATTRIBUTES_DESC',
  CreatedDttmAsc = 'CREATED_DTTM_ASC',
  CreatedDttmDesc = 'CREATED_DTTM_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  NodeTypeAsc = 'NODE_TYPE_ASC',
  NodeTypeDesc = 'NODE_TYPE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ReferencesAsc = 'REFERENCES_ASC',
  ReferencesDesc = 'REFERENCES_DESC',
  TableauIdsAsc = 'TABLEAU_IDS_ASC',
  TableauIdsDesc = 'TABLEAU_IDS_DESC',
  TableauSnapshotIdAsc = 'TABLEAU_SNAPSHOT_ID_ASC',
  TableauSnapshotIdDesc = 'TABLEAU_SNAPSHOT_ID_DESC',
  TagsAsc = 'TAGS_ASC',
  TagsDesc = 'TAGS_DESC'
}

export type TableauSnapshot = Node & {
  __typename?: 'TableauSnapshot';
  createdDttm: Scalars['Datetime'];
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `TableauNode`. */
  tableauNodes: TableauNodesConnection;
  workspaceId: Scalars['Int'];
};


export type TableauSnapshotTableauNodesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TableauNodeCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TableauNodesOrderBy>>;
};

export type TableauSnapshotAggregates = {
  __typename?: 'TableauSnapshotAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<TableauSnapshotAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<TableauSnapshotDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<TableauSnapshotMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<TableauSnapshotMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<TableauSnapshotStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<TableauSnapshotStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<TableauSnapshotSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<TableauSnapshotVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<TableauSnapshotVarianceSampleAggregates>;
};

export type TableauSnapshotAverageAggregates = {
  __typename?: 'TableauSnapshotAverageAggregates';
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `TableauSnapshot` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type TableauSnapshotCondition = {
  /** Checks for equality with the object’s `createdDttm` field. */
  createdDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `workspaceId` field. */
  workspaceId?: InputMaybe<Scalars['Int']>;
};

export type TableauSnapshotDistinctCountAggregates = {
  __typename?: 'TableauSnapshotDistinctCountAggregates';
  /** Distinct count of createdDttm across the matching connection */
  createdDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigInt']>;
};

/** Grouping methods for `TableauSnapshot` for usage during aggregation. */
export enum TableauSnapshotGroupBy {
  CreatedDttm = 'CREATED_DTTM',
  CreatedDttmTruncatedToDay = 'CREATED_DTTM_TRUNCATED_TO_DAY',
  CreatedDttmTruncatedToHour = 'CREATED_DTTM_TRUNCATED_TO_HOUR',
  WorkspaceId = 'WORKSPACE_ID'
}

export type TableauSnapshotHavingAverageInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type TableauSnapshotHavingDistinctCountInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `TableauSnapshot` aggregates. */
export type TableauSnapshotHavingInput = {
  AND?: InputMaybe<Array<TableauSnapshotHavingInput>>;
  OR?: InputMaybe<Array<TableauSnapshotHavingInput>>;
  average?: InputMaybe<TableauSnapshotHavingAverageInput>;
  distinctCount?: InputMaybe<TableauSnapshotHavingDistinctCountInput>;
  max?: InputMaybe<TableauSnapshotHavingMaxInput>;
  min?: InputMaybe<TableauSnapshotHavingMinInput>;
  stddevPopulation?: InputMaybe<TableauSnapshotHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<TableauSnapshotHavingStddevSampleInput>;
  sum?: InputMaybe<TableauSnapshotHavingSumInput>;
  variancePopulation?: InputMaybe<TableauSnapshotHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<TableauSnapshotHavingVarianceSampleInput>;
};

export type TableauSnapshotHavingMaxInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type TableauSnapshotHavingMinInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type TableauSnapshotHavingStddevPopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type TableauSnapshotHavingStddevSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type TableauSnapshotHavingSumInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type TableauSnapshotHavingVariancePopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type TableauSnapshotHavingVarianceSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type TableauSnapshotMaxAggregates = {
  __typename?: 'TableauSnapshotMaxAggregates';
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['Int']>;
};

export type TableauSnapshotMinAggregates = {
  __typename?: 'TableauSnapshotMinAggregates';
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['Int']>;
};

export type TableauSnapshotStddevPopulationAggregates = {
  __typename?: 'TableauSnapshotStddevPopulationAggregates';
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type TableauSnapshotStddevSampleAggregates = {
  __typename?: 'TableauSnapshotStddevSampleAggregates';
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type TableauSnapshotSumAggregates = {
  __typename?: 'TableauSnapshotSumAggregates';
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of workspaceId across the matching connection */
  workspaceId: Scalars['BigInt'];
};

export type TableauSnapshotVariancePopulationAggregates = {
  __typename?: 'TableauSnapshotVariancePopulationAggregates';
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type TableauSnapshotVarianceSampleAggregates = {
  __typename?: 'TableauSnapshotVarianceSampleAggregates';
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `TableauSnapshot` values. */
export type TableauSnapshotsConnection = {
  __typename?: 'TableauSnapshotsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<TableauSnapshotAggregates>;
  /** A list of edges which contains the `TableauSnapshot` and cursor to aid in pagination. */
  edges: Array<TableauSnapshotsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<TableauSnapshotAggregates>>;
  /** A list of `TableauSnapshot` objects. */
  nodes: Array<TableauSnapshot>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TableauSnapshot` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `TableauSnapshot` values. */
export type TableauSnapshotsConnectionGroupedAggregatesArgs = {
  groupBy: Array<TableauSnapshotGroupBy>;
  having?: InputMaybe<TableauSnapshotHavingInput>;
};

/** A `TableauSnapshot` edge in the connection. */
export type TableauSnapshotsEdge = {
  __typename?: 'TableauSnapshotsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `TableauSnapshot` at the end of the edge. */
  node: TableauSnapshot;
};

/** Methods to use when ordering `TableauSnapshot`. */
export enum TableauSnapshotsOrderBy {
  CreatedDttmAsc = 'CREATED_DTTM_ASC',
  CreatedDttmDesc = 'CREATED_DTTM_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TableauNodesAverageAttributesAsc = 'TABLEAU_NODES_AVERAGE_ATTRIBUTES_ASC',
  TableauNodesAverageAttributesDesc = 'TABLEAU_NODES_AVERAGE_ATTRIBUTES_DESC',
  TableauNodesAverageCreatedDttmAsc = 'TABLEAU_NODES_AVERAGE_CREATED_DTTM_ASC',
  TableauNodesAverageCreatedDttmDesc = 'TABLEAU_NODES_AVERAGE_CREATED_DTTM_DESC',
  TableauNodesAverageIdAsc = 'TABLEAU_NODES_AVERAGE_ID_ASC',
  TableauNodesAverageIdDesc = 'TABLEAU_NODES_AVERAGE_ID_DESC',
  TableauNodesAverageNameAsc = 'TABLEAU_NODES_AVERAGE_NAME_ASC',
  TableauNodesAverageNameDesc = 'TABLEAU_NODES_AVERAGE_NAME_DESC',
  TableauNodesAverageNodeTypeAsc = 'TABLEAU_NODES_AVERAGE_NODE_TYPE_ASC',
  TableauNodesAverageNodeTypeDesc = 'TABLEAU_NODES_AVERAGE_NODE_TYPE_DESC',
  TableauNodesAverageReferencesAsc = 'TABLEAU_NODES_AVERAGE_REFERENCES_ASC',
  TableauNodesAverageReferencesDesc = 'TABLEAU_NODES_AVERAGE_REFERENCES_DESC',
  TableauNodesAverageTableauIdsAsc = 'TABLEAU_NODES_AVERAGE_TABLEAU_IDS_ASC',
  TableauNodesAverageTableauIdsDesc = 'TABLEAU_NODES_AVERAGE_TABLEAU_IDS_DESC',
  TableauNodesAverageTableauSnapshotIdAsc = 'TABLEAU_NODES_AVERAGE_TABLEAU_SNAPSHOT_ID_ASC',
  TableauNodesAverageTableauSnapshotIdDesc = 'TABLEAU_NODES_AVERAGE_TABLEAU_SNAPSHOT_ID_DESC',
  TableauNodesAverageTagsAsc = 'TABLEAU_NODES_AVERAGE_TAGS_ASC',
  TableauNodesAverageTagsDesc = 'TABLEAU_NODES_AVERAGE_TAGS_DESC',
  TableauNodesCountAsc = 'TABLEAU_NODES_COUNT_ASC',
  TableauNodesCountDesc = 'TABLEAU_NODES_COUNT_DESC',
  TableauNodesDistinctCountAttributesAsc = 'TABLEAU_NODES_DISTINCT_COUNT_ATTRIBUTES_ASC',
  TableauNodesDistinctCountAttributesDesc = 'TABLEAU_NODES_DISTINCT_COUNT_ATTRIBUTES_DESC',
  TableauNodesDistinctCountCreatedDttmAsc = 'TABLEAU_NODES_DISTINCT_COUNT_CREATED_DTTM_ASC',
  TableauNodesDistinctCountCreatedDttmDesc = 'TABLEAU_NODES_DISTINCT_COUNT_CREATED_DTTM_DESC',
  TableauNodesDistinctCountIdAsc = 'TABLEAU_NODES_DISTINCT_COUNT_ID_ASC',
  TableauNodesDistinctCountIdDesc = 'TABLEAU_NODES_DISTINCT_COUNT_ID_DESC',
  TableauNodesDistinctCountNameAsc = 'TABLEAU_NODES_DISTINCT_COUNT_NAME_ASC',
  TableauNodesDistinctCountNameDesc = 'TABLEAU_NODES_DISTINCT_COUNT_NAME_DESC',
  TableauNodesDistinctCountNodeTypeAsc = 'TABLEAU_NODES_DISTINCT_COUNT_NODE_TYPE_ASC',
  TableauNodesDistinctCountNodeTypeDesc = 'TABLEAU_NODES_DISTINCT_COUNT_NODE_TYPE_DESC',
  TableauNodesDistinctCountReferencesAsc = 'TABLEAU_NODES_DISTINCT_COUNT_REFERENCES_ASC',
  TableauNodesDistinctCountReferencesDesc = 'TABLEAU_NODES_DISTINCT_COUNT_REFERENCES_DESC',
  TableauNodesDistinctCountTableauIdsAsc = 'TABLEAU_NODES_DISTINCT_COUNT_TABLEAU_IDS_ASC',
  TableauNodesDistinctCountTableauIdsDesc = 'TABLEAU_NODES_DISTINCT_COUNT_TABLEAU_IDS_DESC',
  TableauNodesDistinctCountTableauSnapshotIdAsc = 'TABLEAU_NODES_DISTINCT_COUNT_TABLEAU_SNAPSHOT_ID_ASC',
  TableauNodesDistinctCountTableauSnapshotIdDesc = 'TABLEAU_NODES_DISTINCT_COUNT_TABLEAU_SNAPSHOT_ID_DESC',
  TableauNodesDistinctCountTagsAsc = 'TABLEAU_NODES_DISTINCT_COUNT_TAGS_ASC',
  TableauNodesDistinctCountTagsDesc = 'TABLEAU_NODES_DISTINCT_COUNT_TAGS_DESC',
  TableauNodesMaxAttributesAsc = 'TABLEAU_NODES_MAX_ATTRIBUTES_ASC',
  TableauNodesMaxAttributesDesc = 'TABLEAU_NODES_MAX_ATTRIBUTES_DESC',
  TableauNodesMaxCreatedDttmAsc = 'TABLEAU_NODES_MAX_CREATED_DTTM_ASC',
  TableauNodesMaxCreatedDttmDesc = 'TABLEAU_NODES_MAX_CREATED_DTTM_DESC',
  TableauNodesMaxIdAsc = 'TABLEAU_NODES_MAX_ID_ASC',
  TableauNodesMaxIdDesc = 'TABLEAU_NODES_MAX_ID_DESC',
  TableauNodesMaxNameAsc = 'TABLEAU_NODES_MAX_NAME_ASC',
  TableauNodesMaxNameDesc = 'TABLEAU_NODES_MAX_NAME_DESC',
  TableauNodesMaxNodeTypeAsc = 'TABLEAU_NODES_MAX_NODE_TYPE_ASC',
  TableauNodesMaxNodeTypeDesc = 'TABLEAU_NODES_MAX_NODE_TYPE_DESC',
  TableauNodesMaxReferencesAsc = 'TABLEAU_NODES_MAX_REFERENCES_ASC',
  TableauNodesMaxReferencesDesc = 'TABLEAU_NODES_MAX_REFERENCES_DESC',
  TableauNodesMaxTableauIdsAsc = 'TABLEAU_NODES_MAX_TABLEAU_IDS_ASC',
  TableauNodesMaxTableauIdsDesc = 'TABLEAU_NODES_MAX_TABLEAU_IDS_DESC',
  TableauNodesMaxTableauSnapshotIdAsc = 'TABLEAU_NODES_MAX_TABLEAU_SNAPSHOT_ID_ASC',
  TableauNodesMaxTableauSnapshotIdDesc = 'TABLEAU_NODES_MAX_TABLEAU_SNAPSHOT_ID_DESC',
  TableauNodesMaxTagsAsc = 'TABLEAU_NODES_MAX_TAGS_ASC',
  TableauNodesMaxTagsDesc = 'TABLEAU_NODES_MAX_TAGS_DESC',
  TableauNodesMinAttributesAsc = 'TABLEAU_NODES_MIN_ATTRIBUTES_ASC',
  TableauNodesMinAttributesDesc = 'TABLEAU_NODES_MIN_ATTRIBUTES_DESC',
  TableauNodesMinCreatedDttmAsc = 'TABLEAU_NODES_MIN_CREATED_DTTM_ASC',
  TableauNodesMinCreatedDttmDesc = 'TABLEAU_NODES_MIN_CREATED_DTTM_DESC',
  TableauNodesMinIdAsc = 'TABLEAU_NODES_MIN_ID_ASC',
  TableauNodesMinIdDesc = 'TABLEAU_NODES_MIN_ID_DESC',
  TableauNodesMinNameAsc = 'TABLEAU_NODES_MIN_NAME_ASC',
  TableauNodesMinNameDesc = 'TABLEAU_NODES_MIN_NAME_DESC',
  TableauNodesMinNodeTypeAsc = 'TABLEAU_NODES_MIN_NODE_TYPE_ASC',
  TableauNodesMinNodeTypeDesc = 'TABLEAU_NODES_MIN_NODE_TYPE_DESC',
  TableauNodesMinReferencesAsc = 'TABLEAU_NODES_MIN_REFERENCES_ASC',
  TableauNodesMinReferencesDesc = 'TABLEAU_NODES_MIN_REFERENCES_DESC',
  TableauNodesMinTableauIdsAsc = 'TABLEAU_NODES_MIN_TABLEAU_IDS_ASC',
  TableauNodesMinTableauIdsDesc = 'TABLEAU_NODES_MIN_TABLEAU_IDS_DESC',
  TableauNodesMinTableauSnapshotIdAsc = 'TABLEAU_NODES_MIN_TABLEAU_SNAPSHOT_ID_ASC',
  TableauNodesMinTableauSnapshotIdDesc = 'TABLEAU_NODES_MIN_TABLEAU_SNAPSHOT_ID_DESC',
  TableauNodesMinTagsAsc = 'TABLEAU_NODES_MIN_TAGS_ASC',
  TableauNodesMinTagsDesc = 'TABLEAU_NODES_MIN_TAGS_DESC',
  TableauNodesStddevPopulationAttributesAsc = 'TABLEAU_NODES_STDDEV_POPULATION_ATTRIBUTES_ASC',
  TableauNodesStddevPopulationAttributesDesc = 'TABLEAU_NODES_STDDEV_POPULATION_ATTRIBUTES_DESC',
  TableauNodesStddevPopulationCreatedDttmAsc = 'TABLEAU_NODES_STDDEV_POPULATION_CREATED_DTTM_ASC',
  TableauNodesStddevPopulationCreatedDttmDesc = 'TABLEAU_NODES_STDDEV_POPULATION_CREATED_DTTM_DESC',
  TableauNodesStddevPopulationIdAsc = 'TABLEAU_NODES_STDDEV_POPULATION_ID_ASC',
  TableauNodesStddevPopulationIdDesc = 'TABLEAU_NODES_STDDEV_POPULATION_ID_DESC',
  TableauNodesStddevPopulationNameAsc = 'TABLEAU_NODES_STDDEV_POPULATION_NAME_ASC',
  TableauNodesStddevPopulationNameDesc = 'TABLEAU_NODES_STDDEV_POPULATION_NAME_DESC',
  TableauNodesStddevPopulationNodeTypeAsc = 'TABLEAU_NODES_STDDEV_POPULATION_NODE_TYPE_ASC',
  TableauNodesStddevPopulationNodeTypeDesc = 'TABLEAU_NODES_STDDEV_POPULATION_NODE_TYPE_DESC',
  TableauNodesStddevPopulationReferencesAsc = 'TABLEAU_NODES_STDDEV_POPULATION_REFERENCES_ASC',
  TableauNodesStddevPopulationReferencesDesc = 'TABLEAU_NODES_STDDEV_POPULATION_REFERENCES_DESC',
  TableauNodesStddevPopulationTableauIdsAsc = 'TABLEAU_NODES_STDDEV_POPULATION_TABLEAU_IDS_ASC',
  TableauNodesStddevPopulationTableauIdsDesc = 'TABLEAU_NODES_STDDEV_POPULATION_TABLEAU_IDS_DESC',
  TableauNodesStddevPopulationTableauSnapshotIdAsc = 'TABLEAU_NODES_STDDEV_POPULATION_TABLEAU_SNAPSHOT_ID_ASC',
  TableauNodesStddevPopulationTableauSnapshotIdDesc = 'TABLEAU_NODES_STDDEV_POPULATION_TABLEAU_SNAPSHOT_ID_DESC',
  TableauNodesStddevPopulationTagsAsc = 'TABLEAU_NODES_STDDEV_POPULATION_TAGS_ASC',
  TableauNodesStddevPopulationTagsDesc = 'TABLEAU_NODES_STDDEV_POPULATION_TAGS_DESC',
  TableauNodesStddevSampleAttributesAsc = 'TABLEAU_NODES_STDDEV_SAMPLE_ATTRIBUTES_ASC',
  TableauNodesStddevSampleAttributesDesc = 'TABLEAU_NODES_STDDEV_SAMPLE_ATTRIBUTES_DESC',
  TableauNodesStddevSampleCreatedDttmAsc = 'TABLEAU_NODES_STDDEV_SAMPLE_CREATED_DTTM_ASC',
  TableauNodesStddevSampleCreatedDttmDesc = 'TABLEAU_NODES_STDDEV_SAMPLE_CREATED_DTTM_DESC',
  TableauNodesStddevSampleIdAsc = 'TABLEAU_NODES_STDDEV_SAMPLE_ID_ASC',
  TableauNodesStddevSampleIdDesc = 'TABLEAU_NODES_STDDEV_SAMPLE_ID_DESC',
  TableauNodesStddevSampleNameAsc = 'TABLEAU_NODES_STDDEV_SAMPLE_NAME_ASC',
  TableauNodesStddevSampleNameDesc = 'TABLEAU_NODES_STDDEV_SAMPLE_NAME_DESC',
  TableauNodesStddevSampleNodeTypeAsc = 'TABLEAU_NODES_STDDEV_SAMPLE_NODE_TYPE_ASC',
  TableauNodesStddevSampleNodeTypeDesc = 'TABLEAU_NODES_STDDEV_SAMPLE_NODE_TYPE_DESC',
  TableauNodesStddevSampleReferencesAsc = 'TABLEAU_NODES_STDDEV_SAMPLE_REFERENCES_ASC',
  TableauNodesStddevSampleReferencesDesc = 'TABLEAU_NODES_STDDEV_SAMPLE_REFERENCES_DESC',
  TableauNodesStddevSampleTableauIdsAsc = 'TABLEAU_NODES_STDDEV_SAMPLE_TABLEAU_IDS_ASC',
  TableauNodesStddevSampleTableauIdsDesc = 'TABLEAU_NODES_STDDEV_SAMPLE_TABLEAU_IDS_DESC',
  TableauNodesStddevSampleTableauSnapshotIdAsc = 'TABLEAU_NODES_STDDEV_SAMPLE_TABLEAU_SNAPSHOT_ID_ASC',
  TableauNodesStddevSampleTableauSnapshotIdDesc = 'TABLEAU_NODES_STDDEV_SAMPLE_TABLEAU_SNAPSHOT_ID_DESC',
  TableauNodesStddevSampleTagsAsc = 'TABLEAU_NODES_STDDEV_SAMPLE_TAGS_ASC',
  TableauNodesStddevSampleTagsDesc = 'TABLEAU_NODES_STDDEV_SAMPLE_TAGS_DESC',
  TableauNodesSumAttributesAsc = 'TABLEAU_NODES_SUM_ATTRIBUTES_ASC',
  TableauNodesSumAttributesDesc = 'TABLEAU_NODES_SUM_ATTRIBUTES_DESC',
  TableauNodesSumCreatedDttmAsc = 'TABLEAU_NODES_SUM_CREATED_DTTM_ASC',
  TableauNodesSumCreatedDttmDesc = 'TABLEAU_NODES_SUM_CREATED_DTTM_DESC',
  TableauNodesSumIdAsc = 'TABLEAU_NODES_SUM_ID_ASC',
  TableauNodesSumIdDesc = 'TABLEAU_NODES_SUM_ID_DESC',
  TableauNodesSumNameAsc = 'TABLEAU_NODES_SUM_NAME_ASC',
  TableauNodesSumNameDesc = 'TABLEAU_NODES_SUM_NAME_DESC',
  TableauNodesSumNodeTypeAsc = 'TABLEAU_NODES_SUM_NODE_TYPE_ASC',
  TableauNodesSumNodeTypeDesc = 'TABLEAU_NODES_SUM_NODE_TYPE_DESC',
  TableauNodesSumReferencesAsc = 'TABLEAU_NODES_SUM_REFERENCES_ASC',
  TableauNodesSumReferencesDesc = 'TABLEAU_NODES_SUM_REFERENCES_DESC',
  TableauNodesSumTableauIdsAsc = 'TABLEAU_NODES_SUM_TABLEAU_IDS_ASC',
  TableauNodesSumTableauIdsDesc = 'TABLEAU_NODES_SUM_TABLEAU_IDS_DESC',
  TableauNodesSumTableauSnapshotIdAsc = 'TABLEAU_NODES_SUM_TABLEAU_SNAPSHOT_ID_ASC',
  TableauNodesSumTableauSnapshotIdDesc = 'TABLEAU_NODES_SUM_TABLEAU_SNAPSHOT_ID_DESC',
  TableauNodesSumTagsAsc = 'TABLEAU_NODES_SUM_TAGS_ASC',
  TableauNodesSumTagsDesc = 'TABLEAU_NODES_SUM_TAGS_DESC',
  TableauNodesVariancePopulationAttributesAsc = 'TABLEAU_NODES_VARIANCE_POPULATION_ATTRIBUTES_ASC',
  TableauNodesVariancePopulationAttributesDesc = 'TABLEAU_NODES_VARIANCE_POPULATION_ATTRIBUTES_DESC',
  TableauNodesVariancePopulationCreatedDttmAsc = 'TABLEAU_NODES_VARIANCE_POPULATION_CREATED_DTTM_ASC',
  TableauNodesVariancePopulationCreatedDttmDesc = 'TABLEAU_NODES_VARIANCE_POPULATION_CREATED_DTTM_DESC',
  TableauNodesVariancePopulationIdAsc = 'TABLEAU_NODES_VARIANCE_POPULATION_ID_ASC',
  TableauNodesVariancePopulationIdDesc = 'TABLEAU_NODES_VARIANCE_POPULATION_ID_DESC',
  TableauNodesVariancePopulationNameAsc = 'TABLEAU_NODES_VARIANCE_POPULATION_NAME_ASC',
  TableauNodesVariancePopulationNameDesc = 'TABLEAU_NODES_VARIANCE_POPULATION_NAME_DESC',
  TableauNodesVariancePopulationNodeTypeAsc = 'TABLEAU_NODES_VARIANCE_POPULATION_NODE_TYPE_ASC',
  TableauNodesVariancePopulationNodeTypeDesc = 'TABLEAU_NODES_VARIANCE_POPULATION_NODE_TYPE_DESC',
  TableauNodesVariancePopulationReferencesAsc = 'TABLEAU_NODES_VARIANCE_POPULATION_REFERENCES_ASC',
  TableauNodesVariancePopulationReferencesDesc = 'TABLEAU_NODES_VARIANCE_POPULATION_REFERENCES_DESC',
  TableauNodesVariancePopulationTableauIdsAsc = 'TABLEAU_NODES_VARIANCE_POPULATION_TABLEAU_IDS_ASC',
  TableauNodesVariancePopulationTableauIdsDesc = 'TABLEAU_NODES_VARIANCE_POPULATION_TABLEAU_IDS_DESC',
  TableauNodesVariancePopulationTableauSnapshotIdAsc = 'TABLEAU_NODES_VARIANCE_POPULATION_TABLEAU_SNAPSHOT_ID_ASC',
  TableauNodesVariancePopulationTableauSnapshotIdDesc = 'TABLEAU_NODES_VARIANCE_POPULATION_TABLEAU_SNAPSHOT_ID_DESC',
  TableauNodesVariancePopulationTagsAsc = 'TABLEAU_NODES_VARIANCE_POPULATION_TAGS_ASC',
  TableauNodesVariancePopulationTagsDesc = 'TABLEAU_NODES_VARIANCE_POPULATION_TAGS_DESC',
  TableauNodesVarianceSampleAttributesAsc = 'TABLEAU_NODES_VARIANCE_SAMPLE_ATTRIBUTES_ASC',
  TableauNodesVarianceSampleAttributesDesc = 'TABLEAU_NODES_VARIANCE_SAMPLE_ATTRIBUTES_DESC',
  TableauNodesVarianceSampleCreatedDttmAsc = 'TABLEAU_NODES_VARIANCE_SAMPLE_CREATED_DTTM_ASC',
  TableauNodesVarianceSampleCreatedDttmDesc = 'TABLEAU_NODES_VARIANCE_SAMPLE_CREATED_DTTM_DESC',
  TableauNodesVarianceSampleIdAsc = 'TABLEAU_NODES_VARIANCE_SAMPLE_ID_ASC',
  TableauNodesVarianceSampleIdDesc = 'TABLEAU_NODES_VARIANCE_SAMPLE_ID_DESC',
  TableauNodesVarianceSampleNameAsc = 'TABLEAU_NODES_VARIANCE_SAMPLE_NAME_ASC',
  TableauNodesVarianceSampleNameDesc = 'TABLEAU_NODES_VARIANCE_SAMPLE_NAME_DESC',
  TableauNodesVarianceSampleNodeTypeAsc = 'TABLEAU_NODES_VARIANCE_SAMPLE_NODE_TYPE_ASC',
  TableauNodesVarianceSampleNodeTypeDesc = 'TABLEAU_NODES_VARIANCE_SAMPLE_NODE_TYPE_DESC',
  TableauNodesVarianceSampleReferencesAsc = 'TABLEAU_NODES_VARIANCE_SAMPLE_REFERENCES_ASC',
  TableauNodesVarianceSampleReferencesDesc = 'TABLEAU_NODES_VARIANCE_SAMPLE_REFERENCES_DESC',
  TableauNodesVarianceSampleTableauIdsAsc = 'TABLEAU_NODES_VARIANCE_SAMPLE_TABLEAU_IDS_ASC',
  TableauNodesVarianceSampleTableauIdsDesc = 'TABLEAU_NODES_VARIANCE_SAMPLE_TABLEAU_IDS_DESC',
  TableauNodesVarianceSampleTableauSnapshotIdAsc = 'TABLEAU_NODES_VARIANCE_SAMPLE_TABLEAU_SNAPSHOT_ID_ASC',
  TableauNodesVarianceSampleTableauSnapshotIdDesc = 'TABLEAU_NODES_VARIANCE_SAMPLE_TABLEAU_SNAPSHOT_ID_DESC',
  TableauNodesVarianceSampleTagsAsc = 'TABLEAU_NODES_VARIANCE_SAMPLE_TAGS_ASC',
  TableauNodesVarianceSampleTagsDesc = 'TABLEAU_NODES_VARIANCE_SAMPLE_TAGS_DESC',
  WorkspaceIdAsc = 'WORKSPACE_ID_ASC',
  WorkspaceIdDesc = 'WORKSPACE_ID_DESC'
}

export type Workspace = Node & {
  __typename?: 'Workspace';
  createdDttm: Scalars['Datetime'];
  deletedDttm?: Maybe<Scalars['Datetime']>;
  id: Scalars['Int'];
  isSandbox: Scalars['Boolean'];
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  uid: Scalars['String'];
  updatedDttm: Scalars['Datetime'];
};

export type WorkspaceAggregates = {
  __typename?: 'WorkspaceAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<WorkspaceAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<WorkspaceDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<WorkspaceMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<WorkspaceMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<WorkspaceStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<WorkspaceStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<WorkspaceSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<WorkspaceVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<WorkspaceVarianceSampleAggregates>;
};

export type WorkspaceAverageAggregates = {
  __typename?: 'WorkspaceAverageAggregates';
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `Workspace` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type WorkspaceCondition = {
  /** Checks for equality with the object’s `createdDttm` field. */
  createdDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedDttm` field. */
  deletedDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `isSandbox` field. */
  isSandbox?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `uid` field. */
  uid?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedDttm` field. */
  updatedDttm?: InputMaybe<Scalars['Datetime']>;
};

export type WorkspaceDistinctCountAggregates = {
  __typename?: 'WorkspaceDistinctCountAggregates';
  /** Distinct count of createdDttm across the matching connection */
  createdDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of deletedDttm across the matching connection */
  deletedDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of isSandbox across the matching connection */
  isSandbox?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of uid across the matching connection */
  uid?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedDttm across the matching connection */
  updatedDttm?: Maybe<Scalars['BigInt']>;
};

export type WorkspaceMaxAggregates = {
  __typename?: 'WorkspaceMaxAggregates';
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

export type WorkspaceMembership = Node & {
  __typename?: 'WorkspaceMembership';
  accessLevel: Scalars['String'];
  createdDttm: Scalars['Datetime'];
  deletedDttm?: Maybe<Scalars['Datetime']>;
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  updatedDttm: Scalars['Datetime'];
  userId: Scalars['Int'];
  workspaceId: Scalars['Int'];
};

export type WorkspaceMembershipAggregates = {
  __typename?: 'WorkspaceMembershipAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<WorkspaceMembershipAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<WorkspaceMembershipDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<WorkspaceMembershipMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<WorkspaceMembershipMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<WorkspaceMembershipStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<WorkspaceMembershipStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<WorkspaceMembershipSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<WorkspaceMembershipVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<WorkspaceMembershipVarianceSampleAggregates>;
};

export type WorkspaceMembershipAverageAggregates = {
  __typename?: 'WorkspaceMembershipAverageAggregates';
  /** Mean average of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Mean average of userId across the matching connection */
  userId?: Maybe<Scalars['BigFloat']>;
  /** Mean average of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `WorkspaceMembership` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type WorkspaceMembershipCondition = {
  /** Checks for equality with the object’s `accessLevel` field. */
  accessLevel?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdDttm` field. */
  createdDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedDttm` field. */
  deletedDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `updatedDttm` field. */
  updatedDttm?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `workspaceId` field. */
  workspaceId?: InputMaybe<Scalars['Int']>;
};

export type WorkspaceMembershipDistinctCountAggregates = {
  __typename?: 'WorkspaceMembershipDistinctCountAggregates';
  /** Distinct count of accessLevel across the matching connection */
  accessLevel?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdDttm across the matching connection */
  createdDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of deletedDttm across the matching connection */
  deletedDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedDttm across the matching connection */
  updatedDttm?: Maybe<Scalars['BigInt']>;
  /** Distinct count of userId across the matching connection */
  userId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigInt']>;
};

export type WorkspaceMembershipMaxAggregates = {
  __typename?: 'WorkspaceMembershipMaxAggregates';
  /** Maximum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Maximum of userId across the matching connection */
  userId?: Maybe<Scalars['Int']>;
  /** Maximum of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['Int']>;
};

export type WorkspaceMembershipMinAggregates = {
  __typename?: 'WorkspaceMembershipMinAggregates';
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
  /** Minimum of userId across the matching connection */
  userId?: Maybe<Scalars['Int']>;
  /** Minimum of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['Int']>;
};

export type WorkspaceMembershipStddevPopulationAggregates = {
  __typename?: 'WorkspaceMembershipStddevPopulationAggregates';
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of userId across the matching connection */
  userId?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type WorkspaceMembershipStddevSampleAggregates = {
  __typename?: 'WorkspaceMembershipStddevSampleAggregates';
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of userId across the matching connection */
  userId?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type WorkspaceMembershipSumAggregates = {
  __typename?: 'WorkspaceMembershipSumAggregates';
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
  /** Sum of userId across the matching connection */
  userId: Scalars['BigInt'];
  /** Sum of workspaceId across the matching connection */
  workspaceId: Scalars['BigInt'];
};

export type WorkspaceMembershipVariancePopulationAggregates = {
  __typename?: 'WorkspaceMembershipVariancePopulationAggregates';
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Population variance of userId across the matching connection */
  userId?: Maybe<Scalars['BigFloat']>;
  /** Population variance of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

export type WorkspaceMembershipVarianceSampleAggregates = {
  __typename?: 'WorkspaceMembershipVarianceSampleAggregates';
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of userId across the matching connection */
  userId?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of workspaceId across the matching connection */
  workspaceId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `WorkspaceMembership` values. */
export type WorkspaceMembershipsConnection = {
  __typename?: 'WorkspaceMembershipsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<WorkspaceMembershipAggregates>;
  /** A list of edges which contains the `WorkspaceMembership` and cursor to aid in pagination. */
  edges: Array<WorkspaceMembershipsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<WorkspaceMembershipAggregates>>;
  /** A list of `WorkspaceMembership` objects. */
  nodes: Array<WorkspaceMembership>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `WorkspaceMembership` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `WorkspaceMembership` values. */
export type WorkspaceMembershipsConnectionGroupedAggregatesArgs = {
  groupBy: Array<WorkspaceMembershipsGroupBy>;
  having?: InputMaybe<WorkspaceMembershipsHavingInput>;
};

/** A `WorkspaceMembership` edge in the connection. */
export type WorkspaceMembershipsEdge = {
  __typename?: 'WorkspaceMembershipsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `WorkspaceMembership` at the end of the edge. */
  node: WorkspaceMembership;
};

/** Grouping methods for `WorkspaceMembership` for usage during aggregation. */
export enum WorkspaceMembershipsGroupBy {
  AccessLevel = 'ACCESS_LEVEL',
  CreatedDttm = 'CREATED_DTTM',
  CreatedDttmTruncatedToDay = 'CREATED_DTTM_TRUNCATED_TO_DAY',
  CreatedDttmTruncatedToHour = 'CREATED_DTTM_TRUNCATED_TO_HOUR',
  DeletedDttm = 'DELETED_DTTM',
  DeletedDttmTruncatedToDay = 'DELETED_DTTM_TRUNCATED_TO_DAY',
  DeletedDttmTruncatedToHour = 'DELETED_DTTM_TRUNCATED_TO_HOUR',
  UpdatedDttm = 'UPDATED_DTTM',
  UpdatedDttmTruncatedToDay = 'UPDATED_DTTM_TRUNCATED_TO_DAY',
  UpdatedDttmTruncatedToHour = 'UPDATED_DTTM_TRUNCATED_TO_HOUR',
  UserId = 'USER_ID',
  WorkspaceId = 'WORKSPACE_ID'
}

export type WorkspaceMembershipsHavingAverageInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  deletedDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  userId?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type WorkspaceMembershipsHavingDistinctCountInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  deletedDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  userId?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `WorkspaceMembership` aggregates. */
export type WorkspaceMembershipsHavingInput = {
  AND?: InputMaybe<Array<WorkspaceMembershipsHavingInput>>;
  OR?: InputMaybe<Array<WorkspaceMembershipsHavingInput>>;
  average?: InputMaybe<WorkspaceMembershipsHavingAverageInput>;
  distinctCount?: InputMaybe<WorkspaceMembershipsHavingDistinctCountInput>;
  max?: InputMaybe<WorkspaceMembershipsHavingMaxInput>;
  min?: InputMaybe<WorkspaceMembershipsHavingMinInput>;
  stddevPopulation?: InputMaybe<WorkspaceMembershipsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<WorkspaceMembershipsHavingStddevSampleInput>;
  sum?: InputMaybe<WorkspaceMembershipsHavingSumInput>;
  variancePopulation?: InputMaybe<WorkspaceMembershipsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<WorkspaceMembershipsHavingVarianceSampleInput>;
};

export type WorkspaceMembershipsHavingMaxInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  deletedDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  userId?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type WorkspaceMembershipsHavingMinInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  deletedDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  userId?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type WorkspaceMembershipsHavingStddevPopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  deletedDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  userId?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type WorkspaceMembershipsHavingStddevSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  deletedDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  userId?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type WorkspaceMembershipsHavingSumInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  deletedDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  userId?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type WorkspaceMembershipsHavingVariancePopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  deletedDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  userId?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

export type WorkspaceMembershipsHavingVarianceSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  deletedDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
  userId?: InputMaybe<HavingIntFilter>;
  workspaceId?: InputMaybe<HavingIntFilter>;
};

/** Methods to use when ordering `WorkspaceMembership`. */
export enum WorkspaceMembershipsOrderBy {
  AccessLevelAsc = 'ACCESS_LEVEL_ASC',
  AccessLevelDesc = 'ACCESS_LEVEL_DESC',
  CreatedDttmAsc = 'CREATED_DTTM_ASC',
  CreatedDttmDesc = 'CREATED_DTTM_DESC',
  DeletedDttmAsc = 'DELETED_DTTM_ASC',
  DeletedDttmDesc = 'DELETED_DTTM_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedDttmAsc = 'UPDATED_DTTM_ASC',
  UpdatedDttmDesc = 'UPDATED_DTTM_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  WorkspaceIdAsc = 'WORKSPACE_ID_ASC',
  WorkspaceIdDesc = 'WORKSPACE_ID_DESC'
}

export type WorkspaceMinAggregates = {
  __typename?: 'WorkspaceMinAggregates';
  /** Minimum of id across the matching connection */
  id?: Maybe<Scalars['Int']>;
};

export type WorkspaceStddevPopulationAggregates = {
  __typename?: 'WorkspaceStddevPopulationAggregates';
  /** Population standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type WorkspaceStddevSampleAggregates = {
  __typename?: 'WorkspaceStddevSampleAggregates';
  /** Sample standard deviation of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type WorkspaceSumAggregates = {
  __typename?: 'WorkspaceSumAggregates';
  /** Sum of id across the matching connection */
  id: Scalars['BigInt'];
};

export type WorkspaceVariancePopulationAggregates = {
  __typename?: 'WorkspaceVariancePopulationAggregates';
  /** Population variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

export type WorkspaceVarianceSampleAggregates = {
  __typename?: 'WorkspaceVarianceSampleAggregates';
  /** Sample variance of id across the matching connection */
  id?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Workspace` values. */
export type WorkspacesConnection = {
  __typename?: 'WorkspacesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<WorkspaceAggregates>;
  /** A list of edges which contains the `Workspace` and cursor to aid in pagination. */
  edges: Array<WorkspacesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<WorkspaceAggregates>>;
  /** A list of `Workspace` objects. */
  nodes: Array<Workspace>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Workspace` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `Workspace` values. */
export type WorkspacesConnectionGroupedAggregatesArgs = {
  groupBy: Array<WorkspacesGroupBy>;
  having?: InputMaybe<WorkspacesHavingInput>;
};

/** A `Workspace` edge in the connection. */
export type WorkspacesEdge = {
  __typename?: 'WorkspacesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Workspace` at the end of the edge. */
  node: Workspace;
};

/** Grouping methods for `Workspace` for usage during aggregation. */
export enum WorkspacesGroupBy {
  CreatedDttm = 'CREATED_DTTM',
  CreatedDttmTruncatedToDay = 'CREATED_DTTM_TRUNCATED_TO_DAY',
  CreatedDttmTruncatedToHour = 'CREATED_DTTM_TRUNCATED_TO_HOUR',
  DeletedDttm = 'DELETED_DTTM',
  DeletedDttmTruncatedToDay = 'DELETED_DTTM_TRUNCATED_TO_DAY',
  DeletedDttmTruncatedToHour = 'DELETED_DTTM_TRUNCATED_TO_HOUR',
  IsSandbox = 'IS_SANDBOX',
  Name = 'NAME',
  UpdatedDttm = 'UPDATED_DTTM',
  UpdatedDttmTruncatedToDay = 'UPDATED_DTTM_TRUNCATED_TO_DAY',
  UpdatedDttmTruncatedToHour = 'UPDATED_DTTM_TRUNCATED_TO_HOUR'
}

export type WorkspacesHavingAverageInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  deletedDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type WorkspacesHavingDistinctCountInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  deletedDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Workspace` aggregates. */
export type WorkspacesHavingInput = {
  AND?: InputMaybe<Array<WorkspacesHavingInput>>;
  OR?: InputMaybe<Array<WorkspacesHavingInput>>;
  average?: InputMaybe<WorkspacesHavingAverageInput>;
  distinctCount?: InputMaybe<WorkspacesHavingDistinctCountInput>;
  max?: InputMaybe<WorkspacesHavingMaxInput>;
  min?: InputMaybe<WorkspacesHavingMinInput>;
  stddevPopulation?: InputMaybe<WorkspacesHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<WorkspacesHavingStddevSampleInput>;
  sum?: InputMaybe<WorkspacesHavingSumInput>;
  variancePopulation?: InputMaybe<WorkspacesHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<WorkspacesHavingVarianceSampleInput>;
};

export type WorkspacesHavingMaxInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  deletedDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type WorkspacesHavingMinInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  deletedDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type WorkspacesHavingStddevPopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  deletedDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type WorkspacesHavingStddevSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  deletedDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type WorkspacesHavingSumInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  deletedDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type WorkspacesHavingVariancePopulationInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  deletedDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
};

export type WorkspacesHavingVarianceSampleInput = {
  createdDttm?: InputMaybe<HavingDatetimeFilter>;
  deletedDttm?: InputMaybe<HavingDatetimeFilter>;
  id?: InputMaybe<HavingIntFilter>;
  updatedDttm?: InputMaybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `Workspace`. */
export enum WorkspacesOrderBy {
  CreatedDttmAsc = 'CREATED_DTTM_ASC',
  CreatedDttmDesc = 'CREATED_DTTM_DESC',
  CurrentWorkspacesAverageCreatedDttmAsc = 'CURRENT_WORKSPACES_AVERAGE_CREATED_DTTM_ASC',
  CurrentWorkspacesAverageCreatedDttmDesc = 'CURRENT_WORKSPACES_AVERAGE_CREATED_DTTM_DESC',
  CurrentWorkspacesAverageIdAsc = 'CURRENT_WORKSPACES_AVERAGE_ID_ASC',
  CurrentWorkspacesAverageIdDesc = 'CURRENT_WORKSPACES_AVERAGE_ID_DESC',
  CurrentWorkspacesAverageUpdatedDttmAsc = 'CURRENT_WORKSPACES_AVERAGE_UPDATED_DTTM_ASC',
  CurrentWorkspacesAverageUpdatedDttmDesc = 'CURRENT_WORKSPACES_AVERAGE_UPDATED_DTTM_DESC',
  CurrentWorkspacesAverageUserIdAsc = 'CURRENT_WORKSPACES_AVERAGE_USER_ID_ASC',
  CurrentWorkspacesAverageUserIdDesc = 'CURRENT_WORKSPACES_AVERAGE_USER_ID_DESC',
  CurrentWorkspacesAverageWorkspaceIdAsc = 'CURRENT_WORKSPACES_AVERAGE_WORKSPACE_ID_ASC',
  CurrentWorkspacesAverageWorkspaceIdDesc = 'CURRENT_WORKSPACES_AVERAGE_WORKSPACE_ID_DESC',
  CurrentWorkspacesCountAsc = 'CURRENT_WORKSPACES_COUNT_ASC',
  CurrentWorkspacesCountDesc = 'CURRENT_WORKSPACES_COUNT_DESC',
  CurrentWorkspacesDistinctCountCreatedDttmAsc = 'CURRENT_WORKSPACES_DISTINCT_COUNT_CREATED_DTTM_ASC',
  CurrentWorkspacesDistinctCountCreatedDttmDesc = 'CURRENT_WORKSPACES_DISTINCT_COUNT_CREATED_DTTM_DESC',
  CurrentWorkspacesDistinctCountIdAsc = 'CURRENT_WORKSPACES_DISTINCT_COUNT_ID_ASC',
  CurrentWorkspacesDistinctCountIdDesc = 'CURRENT_WORKSPACES_DISTINCT_COUNT_ID_DESC',
  CurrentWorkspacesDistinctCountUpdatedDttmAsc = 'CURRENT_WORKSPACES_DISTINCT_COUNT_UPDATED_DTTM_ASC',
  CurrentWorkspacesDistinctCountUpdatedDttmDesc = 'CURRENT_WORKSPACES_DISTINCT_COUNT_UPDATED_DTTM_DESC',
  CurrentWorkspacesDistinctCountUserIdAsc = 'CURRENT_WORKSPACES_DISTINCT_COUNT_USER_ID_ASC',
  CurrentWorkspacesDistinctCountUserIdDesc = 'CURRENT_WORKSPACES_DISTINCT_COUNT_USER_ID_DESC',
  CurrentWorkspacesDistinctCountWorkspaceIdAsc = 'CURRENT_WORKSPACES_DISTINCT_COUNT_WORKSPACE_ID_ASC',
  CurrentWorkspacesDistinctCountWorkspaceIdDesc = 'CURRENT_WORKSPACES_DISTINCT_COUNT_WORKSPACE_ID_DESC',
  CurrentWorkspacesMaxCreatedDttmAsc = 'CURRENT_WORKSPACES_MAX_CREATED_DTTM_ASC',
  CurrentWorkspacesMaxCreatedDttmDesc = 'CURRENT_WORKSPACES_MAX_CREATED_DTTM_DESC',
  CurrentWorkspacesMaxIdAsc = 'CURRENT_WORKSPACES_MAX_ID_ASC',
  CurrentWorkspacesMaxIdDesc = 'CURRENT_WORKSPACES_MAX_ID_DESC',
  CurrentWorkspacesMaxUpdatedDttmAsc = 'CURRENT_WORKSPACES_MAX_UPDATED_DTTM_ASC',
  CurrentWorkspacesMaxUpdatedDttmDesc = 'CURRENT_WORKSPACES_MAX_UPDATED_DTTM_DESC',
  CurrentWorkspacesMaxUserIdAsc = 'CURRENT_WORKSPACES_MAX_USER_ID_ASC',
  CurrentWorkspacesMaxUserIdDesc = 'CURRENT_WORKSPACES_MAX_USER_ID_DESC',
  CurrentWorkspacesMaxWorkspaceIdAsc = 'CURRENT_WORKSPACES_MAX_WORKSPACE_ID_ASC',
  CurrentWorkspacesMaxWorkspaceIdDesc = 'CURRENT_WORKSPACES_MAX_WORKSPACE_ID_DESC',
  CurrentWorkspacesMinCreatedDttmAsc = 'CURRENT_WORKSPACES_MIN_CREATED_DTTM_ASC',
  CurrentWorkspacesMinCreatedDttmDesc = 'CURRENT_WORKSPACES_MIN_CREATED_DTTM_DESC',
  CurrentWorkspacesMinIdAsc = 'CURRENT_WORKSPACES_MIN_ID_ASC',
  CurrentWorkspacesMinIdDesc = 'CURRENT_WORKSPACES_MIN_ID_DESC',
  CurrentWorkspacesMinUpdatedDttmAsc = 'CURRENT_WORKSPACES_MIN_UPDATED_DTTM_ASC',
  CurrentWorkspacesMinUpdatedDttmDesc = 'CURRENT_WORKSPACES_MIN_UPDATED_DTTM_DESC',
  CurrentWorkspacesMinUserIdAsc = 'CURRENT_WORKSPACES_MIN_USER_ID_ASC',
  CurrentWorkspacesMinUserIdDesc = 'CURRENT_WORKSPACES_MIN_USER_ID_DESC',
  CurrentWorkspacesMinWorkspaceIdAsc = 'CURRENT_WORKSPACES_MIN_WORKSPACE_ID_ASC',
  CurrentWorkspacesMinWorkspaceIdDesc = 'CURRENT_WORKSPACES_MIN_WORKSPACE_ID_DESC',
  CurrentWorkspacesStddevPopulationCreatedDttmAsc = 'CURRENT_WORKSPACES_STDDEV_POPULATION_CREATED_DTTM_ASC',
  CurrentWorkspacesStddevPopulationCreatedDttmDesc = 'CURRENT_WORKSPACES_STDDEV_POPULATION_CREATED_DTTM_DESC',
  CurrentWorkspacesStddevPopulationIdAsc = 'CURRENT_WORKSPACES_STDDEV_POPULATION_ID_ASC',
  CurrentWorkspacesStddevPopulationIdDesc = 'CURRENT_WORKSPACES_STDDEV_POPULATION_ID_DESC',
  CurrentWorkspacesStddevPopulationUpdatedDttmAsc = 'CURRENT_WORKSPACES_STDDEV_POPULATION_UPDATED_DTTM_ASC',
  CurrentWorkspacesStddevPopulationUpdatedDttmDesc = 'CURRENT_WORKSPACES_STDDEV_POPULATION_UPDATED_DTTM_DESC',
  CurrentWorkspacesStddevPopulationUserIdAsc = 'CURRENT_WORKSPACES_STDDEV_POPULATION_USER_ID_ASC',
  CurrentWorkspacesStddevPopulationUserIdDesc = 'CURRENT_WORKSPACES_STDDEV_POPULATION_USER_ID_DESC',
  CurrentWorkspacesStddevPopulationWorkspaceIdAsc = 'CURRENT_WORKSPACES_STDDEV_POPULATION_WORKSPACE_ID_ASC',
  CurrentWorkspacesStddevPopulationWorkspaceIdDesc = 'CURRENT_WORKSPACES_STDDEV_POPULATION_WORKSPACE_ID_DESC',
  CurrentWorkspacesStddevSampleCreatedDttmAsc = 'CURRENT_WORKSPACES_STDDEV_SAMPLE_CREATED_DTTM_ASC',
  CurrentWorkspacesStddevSampleCreatedDttmDesc = 'CURRENT_WORKSPACES_STDDEV_SAMPLE_CREATED_DTTM_DESC',
  CurrentWorkspacesStddevSampleIdAsc = 'CURRENT_WORKSPACES_STDDEV_SAMPLE_ID_ASC',
  CurrentWorkspacesStddevSampleIdDesc = 'CURRENT_WORKSPACES_STDDEV_SAMPLE_ID_DESC',
  CurrentWorkspacesStddevSampleUpdatedDttmAsc = 'CURRENT_WORKSPACES_STDDEV_SAMPLE_UPDATED_DTTM_ASC',
  CurrentWorkspacesStddevSampleUpdatedDttmDesc = 'CURRENT_WORKSPACES_STDDEV_SAMPLE_UPDATED_DTTM_DESC',
  CurrentWorkspacesStddevSampleUserIdAsc = 'CURRENT_WORKSPACES_STDDEV_SAMPLE_USER_ID_ASC',
  CurrentWorkspacesStddevSampleUserIdDesc = 'CURRENT_WORKSPACES_STDDEV_SAMPLE_USER_ID_DESC',
  CurrentWorkspacesStddevSampleWorkspaceIdAsc = 'CURRENT_WORKSPACES_STDDEV_SAMPLE_WORKSPACE_ID_ASC',
  CurrentWorkspacesStddevSampleWorkspaceIdDesc = 'CURRENT_WORKSPACES_STDDEV_SAMPLE_WORKSPACE_ID_DESC',
  CurrentWorkspacesSumCreatedDttmAsc = 'CURRENT_WORKSPACES_SUM_CREATED_DTTM_ASC',
  CurrentWorkspacesSumCreatedDttmDesc = 'CURRENT_WORKSPACES_SUM_CREATED_DTTM_DESC',
  CurrentWorkspacesSumIdAsc = 'CURRENT_WORKSPACES_SUM_ID_ASC',
  CurrentWorkspacesSumIdDesc = 'CURRENT_WORKSPACES_SUM_ID_DESC',
  CurrentWorkspacesSumUpdatedDttmAsc = 'CURRENT_WORKSPACES_SUM_UPDATED_DTTM_ASC',
  CurrentWorkspacesSumUpdatedDttmDesc = 'CURRENT_WORKSPACES_SUM_UPDATED_DTTM_DESC',
  CurrentWorkspacesSumUserIdAsc = 'CURRENT_WORKSPACES_SUM_USER_ID_ASC',
  CurrentWorkspacesSumUserIdDesc = 'CURRENT_WORKSPACES_SUM_USER_ID_DESC',
  CurrentWorkspacesSumWorkspaceIdAsc = 'CURRENT_WORKSPACES_SUM_WORKSPACE_ID_ASC',
  CurrentWorkspacesSumWorkspaceIdDesc = 'CURRENT_WORKSPACES_SUM_WORKSPACE_ID_DESC',
  CurrentWorkspacesVariancePopulationCreatedDttmAsc = 'CURRENT_WORKSPACES_VARIANCE_POPULATION_CREATED_DTTM_ASC',
  CurrentWorkspacesVariancePopulationCreatedDttmDesc = 'CURRENT_WORKSPACES_VARIANCE_POPULATION_CREATED_DTTM_DESC',
  CurrentWorkspacesVariancePopulationIdAsc = 'CURRENT_WORKSPACES_VARIANCE_POPULATION_ID_ASC',
  CurrentWorkspacesVariancePopulationIdDesc = 'CURRENT_WORKSPACES_VARIANCE_POPULATION_ID_DESC',
  CurrentWorkspacesVariancePopulationUpdatedDttmAsc = 'CURRENT_WORKSPACES_VARIANCE_POPULATION_UPDATED_DTTM_ASC',
  CurrentWorkspacesVariancePopulationUpdatedDttmDesc = 'CURRENT_WORKSPACES_VARIANCE_POPULATION_UPDATED_DTTM_DESC',
  CurrentWorkspacesVariancePopulationUserIdAsc = 'CURRENT_WORKSPACES_VARIANCE_POPULATION_USER_ID_ASC',
  CurrentWorkspacesVariancePopulationUserIdDesc = 'CURRENT_WORKSPACES_VARIANCE_POPULATION_USER_ID_DESC',
  CurrentWorkspacesVariancePopulationWorkspaceIdAsc = 'CURRENT_WORKSPACES_VARIANCE_POPULATION_WORKSPACE_ID_ASC',
  CurrentWorkspacesVariancePopulationWorkspaceIdDesc = 'CURRENT_WORKSPACES_VARIANCE_POPULATION_WORKSPACE_ID_DESC',
  CurrentWorkspacesVarianceSampleCreatedDttmAsc = 'CURRENT_WORKSPACES_VARIANCE_SAMPLE_CREATED_DTTM_ASC',
  CurrentWorkspacesVarianceSampleCreatedDttmDesc = 'CURRENT_WORKSPACES_VARIANCE_SAMPLE_CREATED_DTTM_DESC',
  CurrentWorkspacesVarianceSampleIdAsc = 'CURRENT_WORKSPACES_VARIANCE_SAMPLE_ID_ASC',
  CurrentWorkspacesVarianceSampleIdDesc = 'CURRENT_WORKSPACES_VARIANCE_SAMPLE_ID_DESC',
  CurrentWorkspacesVarianceSampleUpdatedDttmAsc = 'CURRENT_WORKSPACES_VARIANCE_SAMPLE_UPDATED_DTTM_ASC',
  CurrentWorkspacesVarianceSampleUpdatedDttmDesc = 'CURRENT_WORKSPACES_VARIANCE_SAMPLE_UPDATED_DTTM_DESC',
  CurrentWorkspacesVarianceSampleUserIdAsc = 'CURRENT_WORKSPACES_VARIANCE_SAMPLE_USER_ID_ASC',
  CurrentWorkspacesVarianceSampleUserIdDesc = 'CURRENT_WORKSPACES_VARIANCE_SAMPLE_USER_ID_DESC',
  CurrentWorkspacesVarianceSampleWorkspaceIdAsc = 'CURRENT_WORKSPACES_VARIANCE_SAMPLE_WORKSPACE_ID_ASC',
  CurrentWorkspacesVarianceSampleWorkspaceIdDesc = 'CURRENT_WORKSPACES_VARIANCE_SAMPLE_WORKSPACE_ID_DESC',
  DeletedDttmAsc = 'DELETED_DTTM_ASC',
  DeletedDttmDesc = 'DELETED_DTTM_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsSandboxAsc = 'IS_SANDBOX_ASC',
  IsSandboxDesc = 'IS_SANDBOX_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UidAsc = 'UID_ASC',
  UidDesc = 'UID_DESC',
  UpdatedDttmAsc = 'UPDATED_DTTM_ASC',
  UpdatedDttmDesc = 'UPDATED_DTTM_DESC'
}

export type WorkspaceMetadataQueryVariables = Exact<{ [key: string]: never; }>;


export type WorkspaceMetadataQuery = { __typename?: 'Query', workspaceMemberships?: { __typename?: 'WorkspaceMembershipsConnection', groupedAggregates?: Array<{ __typename?: 'WorkspaceMembershipAggregates', keys?: Array<string> | null, distinctCount?: { __typename?: 'WorkspaceMembershipDistinctCountAggregates', userId?: any | null } | null }> | null } | null, workspaces?: { __typename?: 'WorkspacesConnection', nodes: Array<{ __typename?: 'Workspace', id: number, uid: string, name: string, createdDttm: any }> } | null, paradimeAppConfigs?: { __typename?: 'ParadimeAppConfigsConnection', edges: Array<{ __typename?: 'ParadimeAppConfigsEdge', node: { __typename?: 'ParadimeAppConfig', workspaceId: number, value?: string | null } }> } | null };

export type AuditLogsQueryVariables = Exact<{ [key: string]: never; }>;


export type AuditLogsQuery = { __typename?: 'Query', auditLogs?: { __typename?: 'AuditLogsConnection', totalCount: number, nodes: Array<{ __typename?: 'AuditLog', id: number, createdDttm: any, updatedDttm: any, workspaceName: string, actorType: string, actorEmail?: string | null, eventSource: string, eventType: string, metadataJson?: string | null }> } | null };

export type DbtNodesQueryVariables = Exact<{
  workspaceId: Scalars['Int'];
}>;


export type DbtNodesQuery = { __typename?: 'Query', scheduleParses?: { __typename?: 'ScheduleParsesConnection', nodes: Array<{ __typename?: 'ScheduleParse', scheduleNames: { __typename?: 'ScheduleNamesConnection', totalCount: number } }> } | null, dbtArtifactCatalogueObjects?: { __typename?: 'DbtArtifactCatalogueObjectsConnection', totalCount: number } | null, dbtArtifactCatalogueColumns?: { __typename?: 'DbtArtifactCatalogueColumnsConnection', totalCount: number } | null };


export const WorkspaceMetadataDocument = gql`
    query workspaceMetadata {
  workspaceMemberships(condition: {deletedDttm: null}) {
    groupedAggregates(groupBy: WORKSPACE_ID) {
      keys
      distinctCount {
        userId
      }
    }
  }
  workspaces {
    nodes {
      id
      uid
      name
      createdDttm
    }
  }
  paradimeAppConfigs(condition: {key: "dbt_version"}) {
    edges {
      node {
        workspaceId
        value
      }
    }
  }
}
    `;

/**
 * __useWorkspaceMetadataQuery__
 *
 * To run a query within a React component, call `useWorkspaceMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceMetadataQuery({
 *   variables: {
 *   },
 * });
 */
export function useWorkspaceMetadataQuery(baseOptions?: Apollo.QueryHookOptions<WorkspaceMetadataQuery, WorkspaceMetadataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkspaceMetadataQuery, WorkspaceMetadataQueryVariables>(WorkspaceMetadataDocument, options);
      }
export function useWorkspaceMetadataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkspaceMetadataQuery, WorkspaceMetadataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkspaceMetadataQuery, WorkspaceMetadataQueryVariables>(WorkspaceMetadataDocument, options);
        }
export type WorkspaceMetadataQueryHookResult = ReturnType<typeof useWorkspaceMetadataQuery>;
export type WorkspaceMetadataLazyQueryHookResult = ReturnType<typeof useWorkspaceMetadataLazyQuery>;
export type WorkspaceMetadataQueryResult = Apollo.QueryResult<WorkspaceMetadataQuery, WorkspaceMetadataQueryVariables>;
export const AuditLogsDocument = gql`
    query AuditLogs {
  auditLogs {
    totalCount
    nodes {
      id
      createdDttm
      updatedDttm
      workspaceName
      actorType
      actorEmail
      eventSource
      eventType
      metadataJson
    }
  }
}
    `;

/**
 * __useAuditLogsQuery__
 *
 * To run a query within a React component, call `useAuditLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuditLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuditLogsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuditLogsQuery(baseOptions?: Apollo.QueryHookOptions<AuditLogsQuery, AuditLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AuditLogsQuery, AuditLogsQueryVariables>(AuditLogsDocument, options);
      }
export function useAuditLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuditLogsQuery, AuditLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AuditLogsQuery, AuditLogsQueryVariables>(AuditLogsDocument, options);
        }
export type AuditLogsQueryHookResult = ReturnType<typeof useAuditLogsQuery>;
export type AuditLogsLazyQueryHookResult = ReturnType<typeof useAuditLogsLazyQuery>;
export type AuditLogsQueryResult = Apollo.QueryResult<AuditLogsQuery, AuditLogsQueryVariables>;
export const DbtNodesDocument = gql`
    query dbtNodes($workspaceId: Int!) {
  scheduleParses(
    orderBy: CREATED_DTTM_DESC
    first: 1
    condition: {workspaceId: $workspaceId}
  ) {
    nodes {
      scheduleNames {
        totalCount
      }
    }
  }
  dbtArtifactCatalogueObjects(condition: {workspaceId: $workspaceId, userId: -1}) {
    totalCount
  }
  dbtArtifactCatalogueColumns(condition: {userId: -1, workspaceId: $workspaceId}) {
    totalCount
  }
}
    `;

/**
 * __useDbtNodesQuery__
 *
 * To run a query within a React component, call `useDbtNodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDbtNodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDbtNodesQuery({
 *   variables: {
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useDbtNodesQuery(baseOptions: Apollo.QueryHookOptions<DbtNodesQuery, DbtNodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DbtNodesQuery, DbtNodesQueryVariables>(DbtNodesDocument, options);
      }
export function useDbtNodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DbtNodesQuery, DbtNodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DbtNodesQuery, DbtNodesQueryVariables>(DbtNodesDocument, options);
        }
export type DbtNodesQueryHookResult = ReturnType<typeof useDbtNodesQuery>;
export type DbtNodesLazyQueryHookResult = ReturnType<typeof useDbtNodesLazyQuery>;
export type DbtNodesQueryResult = Apollo.QueryResult<DbtNodesQuery, DbtNodesQueryVariables>;