import React, { FC, ReactNode } from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';

export interface PageTemplateProps {
  children: ReactNode,
}

const PageTemplate: FC<PageTemplateProps> = ({ children }) => (
  <AutoLayout
    direction="vertical"
    verticalGap="dense"
    distribution="packed"
    style={{
      width: '100%',
      height: '100%',
      backgroundColor: 'var(--grey0)',
    }}
  >
    {children}
  </AutoLayout>
);

export default PageTemplate;
