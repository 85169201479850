import styled from 'styled-components';

export const OnboardingStepsList = styled.ul`
margin: 0 auto 0 auto;

li {
  margin-bottom: 3rem;
}
`;

export const OnboardingStepsListItem = styled.li<{ num: number }>`
  position: relative;
  margin-top: 2rem;
  list-style: none;

  &: before {
    content: "${(props) => props.num}";
    font-size: var(--font_size_body_bold);
    font-weight: var(--font_weight_body_bold);
    line-height: var(--line_height_body_bold);
    color: var(--violet0);
    position: absolute;
    left: -37px;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 10;
  }

  &: after {
    content: "";
    position: absolute;
    left: -53px;
    top: 50%;
    transform: translate(0, -50%);
    height: 2.5rem;
    width: 2.5rem;
    background: linear-gradient(90deg, var(--gradViolet));
    border-radius: var(--s8);
    display: inline-block;
  }
`;
