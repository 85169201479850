import React, {
  FC,
  useCallback,
  useEffect,
} from 'react';
import ReactDOM from 'react-dom';
import {
  Node,
  ReactFlowProvider,
  useReactFlow,
  useNodesState,
  useEdgesState,
  Background,
  BackgroundVariant,
  Controls,
  ControlButton,
} from 'reactflow';
import styled, { CSSProperties } from 'styled-components';
import { Icon } from '@blueprintjs/core';
import Loader from '@paradime-io/pragma-ui-kit/lib/components/Loader';
import { Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events/Contexts';
import LineageMiniMap from './LineageMiniMap';
import {
  allOutgoingEdges,
  filterNodesByString,
  highlightEdges,
} from './utils';
import { ComputeLineageQuery, BranchDiffLineageQuery } from '../../client/generated/service-dataEndpoint';
import { lineageStore } from '../../stores';
import {
  useFormatGraphForFlow, nodeTypes, parseDagreToFlow, LineageMode,
} from './hooks';
import { createGraphFromFlow } from './dagre';
import CustomNodeDepths from './CustomNodeDepths';
import * as S from './Lineage.styles';
import 'reactflow/dist/style.css';

export interface FlowProps {
  filePath?: string,
  nodeId?: string,
  nodesFilter: string[],
  packagesFilter: string[],
  tagsFilter: string[],
  lineageData: ComputeLineageQuery | BranchDiffLineageQuery,
  context: Contexts,
  onlyNeighbors?: boolean,
  position?: 'bottom-center' | 'bottom-right',
  mode?: LineageMode,
  showMiniMap?: boolean,
  showControls?: boolean,
  controlsStyle?: CSSProperties,
  showCustomNodeDepths?: boolean,
  onOpenInLineageClick?: () => void,
  onHelpClick?: () => void,
  cleanState: boolean,
}

const Flow: FC<FlowProps> = ({
  lineageData,
  nodesFilter,
  packagesFilter,
  tagsFilter,
  nodeId,
  filePath,
  context,
  onlyNeighbors,
  position,
  showMiniMap = true,
  showControls = true,
  controlsStyle,
  showCustomNodeDepths = false,
  onHelpClick,
  cleanState,
}) => {
  const lineageNodes = lineageStore((s) => s.lineageNodes);
  const lineageEdges = lineageStore((s) => s.lineageEdges);
  const lineageLoading = lineageStore((s) => s.lineageLoading);
  const edgesToUpdate = lineageStore((s) => s.edgesToUpdate);
  const {
    nodes: formattedNodes,
    edges: formattedEdges,
  } = useFormatGraphForFlow(lineageData, context, nodeId, filePath, onlyNeighbors);
  const [rfNodes, setrfNodes, onrfNodesChange] = useNodesState(lineageNodes || []);
  const [rfEdges, setrfEdges, onrfEdgesChange] = useEdgesState(lineageEdges || []);

  const {
    fitView,
  } = useReactFlow();

  useEffect(() => {
    setrfEdges(edgesToUpdate);
  }, [edgesToUpdate]);

  const regenerateFlow = useCallback(() => {
    if (rfNodes && rfEdges) {
      const newFlow = createGraphFromFlow({ nodes: rfNodes, edges: rfEdges });
      const lineageType = (lineageData as ComputeLineageQuery).computeLineage?.ok ? 'search' : 'compare';
      const {
        nodes,
        links,
      } = parseDagreToFlow({
        graphFromDagre: newFlow,
        lineageType,
        nodeId,
        filePath,
        context,
        onlyNeighbors,
        lineageData,
      });
      setrfNodes(nodes);
      setrfEdges(links);
      setTimeout(fitView, 1);
    }
  }, [
    rfNodes,
    rfEdges,
    lineageData,
    filePath,
    nodeId,
    onlyNeighbors,
    fitView,
  ]);

  useEffect(() => {
    const reFitView = (e: MessageEvent) => {
      const { data: { type } } = e;
      if (type === 'fit-lineage') {
        fitView();
      }
    };
    window.addEventListener('message', reFitView);

    return () => {
      window.removeEventListener('message', reFitView, false);
    };
  }, [fitView]);

  useEffect(() => {
    const [nodesFilterString] = nodesFilter;
    const nodeFilteredNodes = filterNodesByString({
      nodes: formattedNodes,
      edges: formattedEdges,
      filter: nodesFilterString,
      filterType: 'nodeType',
    });

    const [packageFilterString] = packagesFilter;
    const packageFilteredPackages = filterNodesByString({
      ...nodeFilteredNodes,
      filter: packageFilterString,
      filterType: 'packageName',
    });

    const [tagsFilterString] = tagsFilter;
    const tagsFilteredPackages = filterNodesByString({
      ...packageFilteredPackages,
      filter: tagsFilterString,
      filterType: 'tags',
    });

    setrfNodes(tagsFilteredPackages.nodes || []);
    setrfEdges(tagsFilteredPackages.edges || []);

    setTimeout(fitView, 1);
  }, [formattedNodes, formattedEdges, nodesFilter, packagesFilter, tagsFilter]);

  const handleNodeClick = (
    _: React.MouseEvent<Element, globalThis.MouseEvent>,
    element: Node,
  ) => {
    const targetEdges = allOutgoingEdges({ edges: rfEdges, node: element });
    const highlightedEdges = highlightEdges({ edges: rfEdges, edgesToHighlight: targetEdges });
    setrfEdges(highlightedEdges);
  };

  return (rfNodes && rfEdges)
    ? (
      <div
        data-testid="lineage"
        // This is important, ReactFlow won't load if the parent element doesn't have width & height
        style={{ width: '100%', height: '100%' }}
      >
        {lineageLoading && <Loader />}
        <S.ReactFlowWithoutAttribution
          nodes={rfNodes}
          edges={rfEdges}
          onNodesChange={onrfNodesChange}
          onEdgesChange={onrfEdgesChange}
          nodeTypes={nodeTypes}
          maxZoom={6}
          minZoom={0.1}
          onNodeClick={handleNodeClick}
        >
          {showCustomNodeDepths && (
            <CustomNodeDepths
              platformArea="editor"
              onHelpClick={onHelpClick}
              cleanState={cleanState}
            />
          )}
          {showMiniMap && <LineageMiniMap position={position || 'bottom-right'} />}
          <Background variant={BackgroundVariant.Dots} size={1} />
          {showControls && (
            <Controls
              position="bottom-right"
              showInteractive={false}
              style={controlsStyle}
            >
              <ControlButton
                onClick={regenerateFlow}
                title="Refocus lineage"
              >
                <Icon icon="layout-hierarchy" />
              </ControlButton>
            </Controls>
          )}
        </S.ReactFlowWithoutAttribution>
      </div>
    )
    : <Loader />;
};
export default Flow;

declare global {
  interface Window { // @ts-ignore - somehow the types get
    // defined a second time inside the micro-frontend
    renderLineage: (props: RenderProps) => void,
    unmountLineage: (props: UnmountProps) => void,
  }
}

type RenderProps = {
  containerId: string,
  darkMode: boolean,
} & FlowProps

window.renderLineage = ({
  containerId, darkMode, ...rest
}: RenderProps) => {
  const FlowWrapper = styled.div`
    width: 100%;
    height: 100%;
    background: ${darkMode ? 'var(--dark-slategrey)' : 'var(--grey0)'};
    position: relative;
    &::backdrop {
      background-color: ${darkMode ? 'var(--dark-slategrey)' : 'var(--grey0)'};
    }
  `;

  ReactDOM.render(
    <ReactFlowProvider>
      <FlowWrapper id="lineage-fullscreen-editor">
        <Flow {...rest} />
      </FlowWrapper>
    </ReactFlowProvider>,
    document.getElementById(containerId),
  );
};

interface UnmountProps {
  containerId: string,
}

window.unmountLineage = ({ containerId }: UnmountProps) => {
  ReactDOM.unmountComponentAtNode(document.getElementById(containerId)!);
};
