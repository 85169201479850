import React, { FC } from 'react';
import Divider from '@paradime-io/pragma-ui-kit/lib/components/Divider';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import ChecklistItem, { ChecklistItemProps } from './ChecklistItem';
import { RadarSetupSteps } from '../Setup';

export interface AccordionInlineChecklistProps {
  steps?: ChecklistItemProps[],
  onClick: (step: RadarSetupSteps, isPrimaryButton: boolean) => void,
}

const AccordionInlineChecklist: FC<AccordionInlineChecklistProps> = ({
  steps,
  onClick,
}) => (
  <AutoLayout
    direction="vertical"
    padding="none"
    verticalGap="dense"
    distribution="packed"
  >
    {steps?.map((step) => {
      if (step.type === 'divider') {
        return (
          <Divider text={step.title} size={1} colorStep="10" textBackgroundColor="var(--grey0)" />
        );
      }

      return (
        <ChecklistItem
          key={step.id}
          {...step}
          onClick={onClick}
        />
      );
    })}
  </AutoLayout>
);

export default AccordionInlineChecklist;
