import React, { FC } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ModalContainer from '@paradime-io/pragma-ui-kit/lib/components/ModalContainer';
import InviteRoles from './InviteRoles';
import Invitations from './Invitations';
import { appStore } from '../../../stores';

export interface InviteUsersProps {
  show: boolean,
}

const InviteUsers: FC<InviteUsersProps> = ({
  show,
}) => {
  const setShowInviteUser = appStore((s) => s.setShowInviteUser);
  const { flagAllowUserManagement } = useFlags();
  const inviteUserViews = flagAllowUserManagement
  && [
    { node: Invitations, hasPrevious: false },
    { node: InviteRoles, hasPrevious: true },
  ];

  return (
    <>
      {show && (
        <ModalContainer
          states={inviteUserViews || []}
          showModal={show}
          usePortal
          canOutsideClickClose
          style={{ transform: 'translate(-50%, 20%)' }}
          onClose={() => (setShowInviteUser(false))}
        />
      )}
    </>
  );
};

export default InviteUsers;
