import React, {
  Dispatch, FC, SetStateAction,
} from 'react';
import { strip } from 'ansicolor';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { Chips } from '@paradime-io/pragma-ui-kit';
import { colorType } from '@paradime-io/pragma-ui-kit/lib/components/styles/styleTypes';
import { RuntimeStatsProblemLineIndices } from './LogsParser';
import PreviousNextButton from './PreviousNextButton';
import { pluralizeText } from '../../../../utilis/dates';

export interface CommandLogsHeaderProps {
  command: string,
  logLines: string[],
  problemLines?: RuntimeStatsProblemLineIndices,
  scrollToLineIndex: number,
  setScrollToLineIndex: Dispatch<SetStateAction<number>>,
  setPreviousScrollToLineIndex: Dispatch<SetStateAction<number>>,
  setHighlightClass: Dispatch<SetStateAction<string>>,
}

const CommandLogsHeader:FC<CommandLogsHeaderProps> = ({
  command,
  logLines,
  problemLines,
  scrollToLineIndex,
  setScrollToLineIndex,
  setPreviousScrollToLineIndex,
  setHighlightClass,
}) => {
  const handleDownloadLogs = () => {
    const cleanedLogLines = logLines.map((line) => strip(line));
    const a = document.createElement('a');
    a.href = window.URL.createObjectURL(new Blob(cleanedLogLines.map((t) => `${t}\n`), { type: 'text/plain' }));
    a.download = `${command}_logs.txt`;
    a.click();
  };

  const generateButtonText = (text: string, number: number, color: colorType) => (
    <AutoLayout
      direction="horizontal"
      distribution="packed"
      padding="none"
      horizontalGap="ultra-dense"
    >
      <Chips
        color={color}
        round={false}
        tag={number.toString()}
        type="dense"
        view="dark-mode"
      />
      {text}
    </AutoLayout>
  );

  return (
    <AutoLayout
      direction="horizontal"
      distribution="packed"
      padding="very-dense"
      horizontalGap="very-dense"
      style={{ justifyContent: 'flex-end' }}
    >
      {
        (problemLines && problemLines?.total > 0) && (
          <>
            {problemLines.error.length > 0 && (
              <PreviousNextButton
                selectionArray={problemLines.error}
                color="danger"
                text={generateButtonText(
                  pluralizeText(problemLines.error.length, 'Error'),
                  problemLines.error.length, 'danger',
                )}
                currentSelection={scrollToLineIndex}
                setCurrentSelection={setScrollToLineIndex}
                setPreviousSelection={setPreviousScrollToLineIndex}
                onSelectionChanged={() => setHighlightClass('highlight-error')}
              />
            )}
            {problemLines.warn.length > 0 && (
              <PreviousNextButton
                selectionArray={problemLines.warn}
                color="warning_alt"
                text={generateButtonText(
                  pluralizeText(problemLines.warn.length, 'Warn'),
                  problemLines.warn.length, 'warning_alt',
                )}
                currentSelection={scrollToLineIndex}
                setCurrentSelection={setScrollToLineIndex}
                setPreviousSelection={setPreviousScrollToLineIndex}
                onSelectionChanged={() => setHighlightClass('highlight-warn')}
              />
            )}
            {problemLines.skip.length > 0 && (
              <PreviousNextButton
                selectionArray={problemLines.skip}
                color="warning"
                text={generateButtonText(
                  pluralizeText(problemLines.skip.length, 'Skip'),
                  problemLines.skip.length, 'warning',
                )}
                currentSelection={scrollToLineIndex}
                setCurrentSelection={setScrollToLineIndex}
                setPreviousSelection={setPreviousScrollToLineIndex}
                onSelectionChanged={() => setHighlightClass('highlight-skip')}
              />
            )}
          </>
        )
      }
      <DefaultButton
        icon="download"
        text="Download"
        eventAction={Actions.CLICKED}
        eventContext={Contexts.BOLT}
        eventObject="saveCsvButtonClicked"
        color="default"
        view="smooth"
        dense
        onClick={() => handleDownloadLogs()}
      />
    </AutoLayout>
  );
};

export default CommandLogsHeader;
