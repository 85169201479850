import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {"context":{"operationType":"control-plane"}} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type AppIdentity = {
  __typename?: 'AppIdentity';
  dialect?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type CompanyIdentity = {
  __typename?: 'CompanyIdentity';
  /** @deprecated Unused endpoint */
  annotationsGraphqlSubscriptionUrl?: Maybe<Scalars['String']>;
  /** @deprecated Unused endpoint */
  annotationsGraphqlUrl?: Maybe<Scalars['String']>;
  apiHost?: Maybe<Scalars['String']>;
  bigqueryCallbackUrl?: Maybe<Scalars['String']>;
  creationInProgress?: Maybe<Scalars['Boolean']>;
  dataGraphqlUrl?: Maybe<Scalars['String']>;
  dbGraphqlUrl?: Maybe<Scalars['String']>;
  dinoaiAgentStreamUrl?: Maybe<Scalars['String']>;
  editorGraphqlUrl?: Maybe<Scalars['String']>;
  isDeactivated?: Maybe<Scalars['Boolean']>;
  /** @deprecated Stripe is not mandatory anymore for the onboarding flow */
  needsStripe?: Maybe<Scalars['Boolean']>;
  /** @deprecated Unused endpoint */
  notificationsGraphqlSubscriptionUrl?: Maybe<Scalars['String']>;
  /** @deprecated Unused endpoint */
  notificationsGraphqlUrl?: Maybe<Scalars['String']>;
  organisationName?: Maybe<Scalars['String']>;
  paradimeDocsUrl?: Maybe<Scalars['String']>;
  /** @deprecated Unused endpoint */
  paradimeLogsGraphqlUrl?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  serviceGraphqlUrl?: Maybe<Scalars['String']>;
  snowflakeCallbackUrl?: Maybe<Scalars['String']>;
  supervisingControlPlaneUrl?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

/**
 * Onboard a company into the paradime infrastructure.
 *
 * As described in:
 *   https://www.notion.so/paradime/Microservice-Interfaces-bd9b0db5cc424d7784e95e582f64f381#3f28954f8f4e44f78bdedbef8a98fcb6
 */
export type CompanyOnboarding = {
  __typename?: 'CompanyOnboarding';
  annotationsGraphqlSubscriptionUrl?: Maybe<Scalars['String']>;
  annotationsGraphqlUrl?: Maybe<Scalars['String']>;
  bigqueryCallbackUrl?: Maybe<Scalars['String']>;
  dataGraphqlUrl?: Maybe<Scalars['String']>;
  dbGraphqlUrl?: Maybe<Scalars['String']>;
  editorGraphqlUrl?: Maybe<Scalars['String']>;
  notificationsGraphqlSubscriptionUrl?: Maybe<Scalars['String']>;
  notificationsGraphqlUrl?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  organisationName?: Maybe<Scalars['String']>;
  paradimeDocsUrl?: Maybe<Scalars['String']>;
  paradimeLogsGraphqlUrl?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  serviceGraphqlUrl?: Maybe<Scalars['String']>;
  servicePath?: Maybe<Scalars['String']>;
  snowflakeCallbackUrl?: Maybe<Scalars['String']>;
  sshPublicKey?: Maybe<Scalars['String']>;
  supervisingControlPlaneUrl?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

/** Save some company data. */
export type CompanyOnboardingInitial = {
  __typename?: 'CompanyOnboardingInitial';
  ok: Scalars['Boolean'];
};

/** Save the initial user. */
export type CompanyOnboardingInitialUser = {
  __typename?: 'CompanyOnboardingInitialUser';
  ok: Scalars['Boolean'];
  onboardingToken?: Maybe<Scalars['String']>;
};

export type GqlMutation = {
  __typename?: 'GQLMutation';
  /**
   * Onboard a company into the paradime infrastructure.
   *
   * As described in:
   *   https://www.notion.so/paradime/Microservice-Interfaces-bd9b0db5cc424d7784e95e582f64f381#3f28954f8f4e44f78bdedbef8a98fcb6
   */
  companyOnboarding?: Maybe<CompanyOnboarding>;
  /** Save some company data. */
  companyOnboardingInitial?: Maybe<CompanyOnboardingInitial>;
  /** Save the initial user. */
  companyOnboardingInitialUser?: Maybe<CompanyOnboardingInitialUser>;
  /** Save the user's org name for self-service onboarding. */
  getStripeClientReferenceId?: Maybe<GetStripeClientReferenceId>;
  /** Start the company in a specific region. */
  setGithubInstallationId?: Maybe<SetGithubInstallationId>;
};


export type GqlMutationCompanyOnboardingArgs = {
  dbtPartialParsing?: InputMaybe<Scalars['Boolean']>;
  dbtVersion: Scalars['String'];
  onboardingToken: Scalars['String'];
  region?: InputMaybe<Scalars['String']>;
  wait: Scalars['Boolean'];
};


export type GqlMutationCompanyOnboardingInitialArgs = {
  companyName: Scalars['String'];
  dbtLevel?: InputMaybe<Scalars['String']>;
  enableAutoSignUp?: InputMaybe<Scalars['Boolean']>;
  onboardingToken: Scalars['String'];
  peopleCount?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  roleName: Scalars['String'];
  teamPurpose: Scalars['String'];
  userName: Scalars['String'];
};


export type GqlMutationCompanyOnboardingInitialUserArgs = {
  email: Scalars['String'];
};


export type GqlMutationGetStripeClientReferenceIdArgs = {
  orgName: Scalars['String'];
};


export type GqlMutationSetGithubInstallationIdArgs = {
  githubCode: Scalars['String'];
  installationId: Scalars['Int'];
  organisationName: Scalars['String'];
};

export type GqlQuery = {
  __typename?: 'GQLQuery';
  /** The app identity */
  appIdentity?: Maybe<AppIdentity>;
  /** The company identity */
  companyIdentity?: Maybe<CompanyIdentity>;
  /**
   * Find the company which allows the automatic sign up of the user
   * @deprecated Unused endpoint
   */
  findCompanyWhichAllowsAutomaticUserSignUp?: Maybe<UserIdentity>;
  /** Check if the company is active */
  isCompanyActive?: Maybe<IsCompanyActive>;
  /** Check if an organization name is available */
  isOrganizationNameAvailable?: Maybe<IsOrganizationNameAvailable>;
  /** Your identity */
  userIdentity?: Maybe<UserIdentity>;
};


export type GqlQueryCompanyIdentityArgs = {
  region?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
};


export type GqlQueryIsCompanyActiveArgs = {
  token: Scalars['String'];
};


export type GqlQueryIsOrganizationNameAvailableArgs = {
  organizationName: Scalars['String'];
};

/** Save the user's org name for self-service onboarding. */
export type GetStripeClientReferenceId = {
  __typename?: 'GetStripeClientReferenceID';
  errorString?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  stripeClientReferenceId: Scalars['String'];
};

export type IsCompanyActive = {
  __typename?: 'IsCompanyActive';
  annotationsGraphqlSubscriptionUrl?: Maybe<Scalars['String']>;
  annotationsGraphqlUrl?: Maybe<Scalars['String']>;
  bigqueryCallbackUrl?: Maybe<Scalars['String']>;
  dataGraphqlUrl?: Maybe<Scalars['String']>;
  dbGraphqlUrl?: Maybe<Scalars['String']>;
  editorGraphqlUrl?: Maybe<Scalars['String']>;
  isStarted: Scalars['Boolean'];
  isStarting: Scalars['Boolean'];
  notificationsGraphqlSubscriptionUrl?: Maybe<Scalars['String']>;
  notificationsGraphqlUrl?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  organisationName?: Maybe<Scalars['String']>;
  paradimeDocsUrl?: Maybe<Scalars['String']>;
  paradimeLogsGraphqlUrl?: Maybe<Scalars['String']>;
  progress?: Maybe<Scalars['Float']>;
  region?: Maybe<Scalars['String']>;
  serviceGraphqlUrl?: Maybe<Scalars['String']>;
  snowflakeCallbackUrl?: Maybe<Scalars['String']>;
  supervisingControlPlaneUrl?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type IsOrganizationNameAvailable = {
  __typename?: 'IsOrganizationNameAvailable';
  isAvailable: Scalars['Boolean'];
  ok: Scalars['Boolean'];
};

/** Start the company in a specific region. */
export type SetGithubInstallationId = {
  __typename?: 'SetGithubInstallationID';
  ok: Scalars['Boolean'];
};

export type UserIdentity = {
  __typename?: 'UserIdentity';
  company?: Maybe<CompanyIdentity>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type UserIdentityQueryVariables = Exact<{ [key: string]: never; }>;


export type UserIdentityQuery = { __typename?: 'GQLQuery', userIdentity?: { __typename?: 'UserIdentity', name?: string | null, email?: string | null, company?: { __typename?: 'CompanyIdentity', region?: string | null, serviceGraphqlUrl?: string | null, editorGraphqlUrl?: string | null, dataGraphqlUrl?: string | null, dbGraphqlUrl?: string | null, token?: string | null, snowflakeCallbackUrl?: string | null, organisationName?: string | null, bigqueryCallbackUrl?: string | null, creationInProgress?: boolean | null, dinoaiAgentStreamUrl?: string | null } | null } | null };

export type CompanyIdentityQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type CompanyIdentityQuery = { __typename?: 'GQLQuery', companyIdentity?: { __typename?: 'CompanyIdentity', region?: string | null, serviceGraphqlUrl?: string | null, editorGraphqlUrl?: string | null, dataGraphqlUrl?: string | null, dbGraphqlUrl?: string | null, snowflakeCallbackUrl?: string | null, organisationName?: string | null, bigqueryCallbackUrl?: string | null, token?: string | null, dinoaiAgentStreamUrl?: string | null } | null };

export type CompanyOnboardingMutationVariables = Exact<{
  onboardingToken: Scalars['String'];
  dbtVersion: Scalars['String'];
  region: Scalars['String'];
  dbtPartialParsing?: InputMaybe<Scalars['Boolean']>;
}>;


export type CompanyOnboardingMutation = { __typename?: 'GQLMutation', companyOnboarding?: { __typename?: 'CompanyOnboarding', ok: boolean, servicePath?: string | null, sshPublicKey?: string | null, region?: string | null, token?: string | null, supervisingControlPlaneUrl?: string | null, serviceGraphqlUrl?: string | null, editorGraphqlUrl?: string | null, dbGraphqlUrl?: string | null, dataGraphqlUrl?: string | null } | null };

export type CompanyOnboardingInitialUserMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type CompanyOnboardingInitialUserMutation = { __typename?: 'GQLMutation', companyOnboardingInitialUser?: { __typename?: 'CompanyOnboardingInitialUser', ok: boolean, onboardingToken?: string | null } | null };

export type CompanyOnboardingInitialMutationVariables = Exact<{
  onboardingToken: Scalars['String'];
  userName: Scalars['String'];
  companyName: Scalars['String'];
  teamPurpose: Scalars['String'];
  roleName: Scalars['String'];
  enableAutoSignUp?: InputMaybe<Scalars['Boolean']>;
  dbtLevel?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
}>;


export type CompanyOnboardingInitialMutation = { __typename?: 'GQLMutation', companyOnboardingInitial?: { __typename?: 'CompanyOnboardingInitial', ok: boolean } | null };

export type IsCompanyActiveQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type IsCompanyActiveQuery = { __typename?: 'GQLQuery', isCompanyActive?: { __typename?: 'IsCompanyActive', ok: boolean, isStarted: boolean, isStarting: boolean, progress?: number | null, region?: string | null, token?: string | null, supervisingControlPlaneUrl?: string | null, serviceGraphqlUrl?: string | null, editorGraphqlUrl?: string | null, dbGraphqlUrl?: string | null, dataGraphqlUrl?: string | null, snowflakeCallbackUrl?: string | null, organisationName?: string | null, bigqueryCallbackUrl?: string | null } | null };

export type SetGithubInstallationIdMutationVariables = Exact<{
  installationId: Scalars['Int'];
  organisationName: Scalars['String'];
  githubCode: Scalars['String'];
}>;


export type SetGithubInstallationIdMutation = { __typename?: 'GQLMutation', setGithubInstallationId?: { __typename?: 'SetGithubInstallationID', ok: boolean } | null };

export type GetStripeClientReferenceIdMutationVariables = Exact<{
  orgName: Scalars['String'];
}>;


export type GetStripeClientReferenceIdMutation = { __typename?: 'GQLMutation', getStripeClientReferenceId?: { __typename?: 'GetStripeClientReferenceID', ok: boolean, stripeClientReferenceId: string, errorString?: string | null } | null };


export const UserIdentityDocument = gql`
    query userIdentity {
  userIdentity {
    name
    email
    company {
      region
      serviceGraphqlUrl
      editorGraphqlUrl
      dataGraphqlUrl
      dbGraphqlUrl
      token
      snowflakeCallbackUrl
      organisationName
      bigqueryCallbackUrl
      creationInProgress
      dinoaiAgentStreamUrl
    }
  }
}
    `;

/**
 * __useUserIdentityQuery__
 *
 * To run a query within a React component, call `useUserIdentityQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserIdentityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserIdentityQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserIdentityQuery(baseOptions?: Apollo.QueryHookOptions<UserIdentityQuery, UserIdentityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserIdentityQuery, UserIdentityQueryVariables>(UserIdentityDocument, options);
      }
export function useUserIdentityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserIdentityQuery, UserIdentityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserIdentityQuery, UserIdentityQueryVariables>(UserIdentityDocument, options);
        }
export type UserIdentityQueryHookResult = ReturnType<typeof useUserIdentityQuery>;
export type UserIdentityLazyQueryHookResult = ReturnType<typeof useUserIdentityLazyQuery>;
export type UserIdentityQueryResult = Apollo.QueryResult<UserIdentityQuery, UserIdentityQueryVariables>;
export const CompanyIdentityDocument = gql`
    query companyIdentity($token: String!) {
  companyIdentity(token: $token) {
    region
    serviceGraphqlUrl
    editorGraphqlUrl
    dataGraphqlUrl
    dbGraphqlUrl
    snowflakeCallbackUrl
    organisationName
    bigqueryCallbackUrl
    token
    dinoaiAgentStreamUrl
  }
}
    `;

/**
 * __useCompanyIdentityQuery__
 *
 * To run a query within a React component, call `useCompanyIdentityQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyIdentityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyIdentityQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useCompanyIdentityQuery(baseOptions: Apollo.QueryHookOptions<CompanyIdentityQuery, CompanyIdentityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyIdentityQuery, CompanyIdentityQueryVariables>(CompanyIdentityDocument, options);
      }
export function useCompanyIdentityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyIdentityQuery, CompanyIdentityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyIdentityQuery, CompanyIdentityQueryVariables>(CompanyIdentityDocument, options);
        }
export type CompanyIdentityQueryHookResult = ReturnType<typeof useCompanyIdentityQuery>;
export type CompanyIdentityLazyQueryHookResult = ReturnType<typeof useCompanyIdentityLazyQuery>;
export type CompanyIdentityQueryResult = Apollo.QueryResult<CompanyIdentityQuery, CompanyIdentityQueryVariables>;
export const CompanyOnboardingDocument = gql`
    mutation companyOnboarding($onboardingToken: String!, $dbtVersion: String!, $region: String!, $dbtPartialParsing: Boolean) {
  companyOnboarding(
    onboardingToken: $onboardingToken
    dbtVersion: $dbtVersion
    region: $region
    wait: true
    dbtPartialParsing: $dbtPartialParsing
  ) {
    ok
    servicePath
    sshPublicKey
    region
    token
    supervisingControlPlaneUrl
    serviceGraphqlUrl
    editorGraphqlUrl
    dbGraphqlUrl
    dataGraphqlUrl
    token
  }
}
    `;
export type CompanyOnboardingMutationFn = Apollo.MutationFunction<CompanyOnboardingMutation, CompanyOnboardingMutationVariables>;

/**
 * __useCompanyOnboardingMutation__
 *
 * To run a mutation, you first call `useCompanyOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyOnboardingMutation, { data, loading, error }] = useCompanyOnboardingMutation({
 *   variables: {
 *      onboardingToken: // value for 'onboardingToken'
 *      dbtVersion: // value for 'dbtVersion'
 *      region: // value for 'region'
 *      dbtPartialParsing: // value for 'dbtPartialParsing'
 *   },
 * });
 */
export function useCompanyOnboardingMutation(baseOptions?: Apollo.MutationHookOptions<CompanyOnboardingMutation, CompanyOnboardingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompanyOnboardingMutation, CompanyOnboardingMutationVariables>(CompanyOnboardingDocument, options);
      }
export type CompanyOnboardingMutationHookResult = ReturnType<typeof useCompanyOnboardingMutation>;
export type CompanyOnboardingMutationResult = Apollo.MutationResult<CompanyOnboardingMutation>;
export type CompanyOnboardingMutationOptions = Apollo.BaseMutationOptions<CompanyOnboardingMutation, CompanyOnboardingMutationVariables>;
export const CompanyOnboardingInitialUserDocument = gql`
    mutation companyOnboardingInitialUser($email: String!) {
  companyOnboardingInitialUser(email: $email) {
    ok
    onboardingToken
  }
}
    `;
export type CompanyOnboardingInitialUserMutationFn = Apollo.MutationFunction<CompanyOnboardingInitialUserMutation, CompanyOnboardingInitialUserMutationVariables>;

/**
 * __useCompanyOnboardingInitialUserMutation__
 *
 * To run a mutation, you first call `useCompanyOnboardingInitialUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyOnboardingInitialUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyOnboardingInitialUserMutation, { data, loading, error }] = useCompanyOnboardingInitialUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCompanyOnboardingInitialUserMutation(baseOptions?: Apollo.MutationHookOptions<CompanyOnboardingInitialUserMutation, CompanyOnboardingInitialUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompanyOnboardingInitialUserMutation, CompanyOnboardingInitialUserMutationVariables>(CompanyOnboardingInitialUserDocument, options);
      }
export type CompanyOnboardingInitialUserMutationHookResult = ReturnType<typeof useCompanyOnboardingInitialUserMutation>;
export type CompanyOnboardingInitialUserMutationResult = Apollo.MutationResult<CompanyOnboardingInitialUserMutation>;
export type CompanyOnboardingInitialUserMutationOptions = Apollo.BaseMutationOptions<CompanyOnboardingInitialUserMutation, CompanyOnboardingInitialUserMutationVariables>;
export const CompanyOnboardingInitialDocument = gql`
    mutation companyOnboardingInitial($onboardingToken: String!, $userName: String!, $companyName: String!, $teamPurpose: String!, $roleName: String!, $enableAutoSignUp: Boolean, $dbtLevel: String, $region: String) {
  companyOnboardingInitial(
    onboardingToken: $onboardingToken
    userName: $userName
    companyName: $companyName
    teamPurpose: $teamPurpose
    roleName: $roleName
    enableAutoSignUp: $enableAutoSignUp
    dbtLevel: $dbtLevel
    region: $region
  ) {
    ok
  }
}
    `;
export type CompanyOnboardingInitialMutationFn = Apollo.MutationFunction<CompanyOnboardingInitialMutation, CompanyOnboardingInitialMutationVariables>;

/**
 * __useCompanyOnboardingInitialMutation__
 *
 * To run a mutation, you first call `useCompanyOnboardingInitialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyOnboardingInitialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyOnboardingInitialMutation, { data, loading, error }] = useCompanyOnboardingInitialMutation({
 *   variables: {
 *      onboardingToken: // value for 'onboardingToken'
 *      userName: // value for 'userName'
 *      companyName: // value for 'companyName'
 *      teamPurpose: // value for 'teamPurpose'
 *      roleName: // value for 'roleName'
 *      enableAutoSignUp: // value for 'enableAutoSignUp'
 *      dbtLevel: // value for 'dbtLevel'
 *      region: // value for 'region'
 *   },
 * });
 */
export function useCompanyOnboardingInitialMutation(baseOptions?: Apollo.MutationHookOptions<CompanyOnboardingInitialMutation, CompanyOnboardingInitialMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompanyOnboardingInitialMutation, CompanyOnboardingInitialMutationVariables>(CompanyOnboardingInitialDocument, options);
      }
export type CompanyOnboardingInitialMutationHookResult = ReturnType<typeof useCompanyOnboardingInitialMutation>;
export type CompanyOnboardingInitialMutationResult = Apollo.MutationResult<CompanyOnboardingInitialMutation>;
export type CompanyOnboardingInitialMutationOptions = Apollo.BaseMutationOptions<CompanyOnboardingInitialMutation, CompanyOnboardingInitialMutationVariables>;
export const IsCompanyActiveDocument = gql`
    query isCompanyActive($token: String!) {
  isCompanyActive(token: $token) {
    ok
    isStarted
    isStarting
    progress
    region
    token
    supervisingControlPlaneUrl
    serviceGraphqlUrl
    editorGraphqlUrl
    dbGraphqlUrl
    dataGraphqlUrl
    snowflakeCallbackUrl
    organisationName
    bigqueryCallbackUrl
  }
}
    `;

/**
 * __useIsCompanyActiveQuery__
 *
 * To run a query within a React component, call `useIsCompanyActiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsCompanyActiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsCompanyActiveQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useIsCompanyActiveQuery(baseOptions: Apollo.QueryHookOptions<IsCompanyActiveQuery, IsCompanyActiveQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsCompanyActiveQuery, IsCompanyActiveQueryVariables>(IsCompanyActiveDocument, options);
      }
export function useIsCompanyActiveLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsCompanyActiveQuery, IsCompanyActiveQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsCompanyActiveQuery, IsCompanyActiveQueryVariables>(IsCompanyActiveDocument, options);
        }
export type IsCompanyActiveQueryHookResult = ReturnType<typeof useIsCompanyActiveQuery>;
export type IsCompanyActiveLazyQueryHookResult = ReturnType<typeof useIsCompanyActiveLazyQuery>;
export type IsCompanyActiveQueryResult = Apollo.QueryResult<IsCompanyActiveQuery, IsCompanyActiveQueryVariables>;
export const SetGithubInstallationIdDocument = gql`
    mutation setGithubInstallationId($installationId: Int!, $organisationName: String!, $githubCode: String!) {
  setGithubInstallationId(
    installationId: $installationId
    organisationName: $organisationName
    githubCode: $githubCode
  ) {
    ok
  }
}
    `;
export type SetGithubInstallationIdMutationFn = Apollo.MutationFunction<SetGithubInstallationIdMutation, SetGithubInstallationIdMutationVariables>;

/**
 * __useSetGithubInstallationIdMutation__
 *
 * To run a mutation, you first call `useSetGithubInstallationIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetGithubInstallationIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setGithubInstallationIdMutation, { data, loading, error }] = useSetGithubInstallationIdMutation({
 *   variables: {
 *      installationId: // value for 'installationId'
 *      organisationName: // value for 'organisationName'
 *      githubCode: // value for 'githubCode'
 *   },
 * });
 */
export function useSetGithubInstallationIdMutation(baseOptions?: Apollo.MutationHookOptions<SetGithubInstallationIdMutation, SetGithubInstallationIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetGithubInstallationIdMutation, SetGithubInstallationIdMutationVariables>(SetGithubInstallationIdDocument, options);
      }
export type SetGithubInstallationIdMutationHookResult = ReturnType<typeof useSetGithubInstallationIdMutation>;
export type SetGithubInstallationIdMutationResult = Apollo.MutationResult<SetGithubInstallationIdMutation>;
export type SetGithubInstallationIdMutationOptions = Apollo.BaseMutationOptions<SetGithubInstallationIdMutation, SetGithubInstallationIdMutationVariables>;
export const GetStripeClientReferenceIdDocument = gql`
    mutation getStripeClientReferenceId($orgName: String!) {
  getStripeClientReferenceId(orgName: $orgName) {
    ok
    stripeClientReferenceId
    errorString
  }
}
    `;
export type GetStripeClientReferenceIdMutationFn = Apollo.MutationFunction<GetStripeClientReferenceIdMutation, GetStripeClientReferenceIdMutationVariables>;

/**
 * __useGetStripeClientReferenceIdMutation__
 *
 * To run a mutation, you first call `useGetStripeClientReferenceIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetStripeClientReferenceIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getStripeClientReferenceIdMutation, { data, loading, error }] = useGetStripeClientReferenceIdMutation({
 *   variables: {
 *      orgName: // value for 'orgName'
 *   },
 * });
 */
export function useGetStripeClientReferenceIdMutation(baseOptions?: Apollo.MutationHookOptions<GetStripeClientReferenceIdMutation, GetStripeClientReferenceIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetStripeClientReferenceIdMutation, GetStripeClientReferenceIdMutationVariables>(GetStripeClientReferenceIdDocument, options);
      }
export type GetStripeClientReferenceIdMutationHookResult = ReturnType<typeof useGetStripeClientReferenceIdMutation>;
export type GetStripeClientReferenceIdMutationResult = Apollo.MutationResult<GetStripeClientReferenceIdMutation>;
export type GetStripeClientReferenceIdMutationOptions = Apollo.BaseMutationOptions<GetStripeClientReferenceIdMutation, GetStripeClientReferenceIdMutationVariables>;