import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
// import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import { getEnvironment } from '../../../../utilis';

const denyUrlsList = [
  // Facebook flakiness
  /graph\.facebook\.com/i,
  // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i,
  // Woopra flakiness
  /eatdifferent\.com\.woopra-ns\.com/i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  // Other plugins
  /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  /localhost:3005/i, // dont send errors from localhost
];

const SentryOpts: Sentry.BrowserOptions = {
  dsn: 'https://a6628aeb7d791384f5b0325b625a91af@o557100.ingest.us.sentry.io/5692864',
  ignoreErrors: [new RegExp('.*')],
  denyUrls: denyUrlsList,
  allowUrls: [new RegExp('.*paradime.*')],
  // eslint-disable-next-line no-unused-vars
  // beforeSend(event, hint) {
  //   // Check if it is an exception, and if so, show the report dialog
  //   if (event.exception) {
  //     Sentry.showReportDialog({ eventId: event.event_id });
  //   }
  //   return event;
  // },
  integrations: [
    new Integrations.BrowserTracing(),
    // new CaptureConsoleIntegration({
    //   levels: ['error'],
    // }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.05,
  environment: getEnvironment(),
};

export default SentryOpts;
