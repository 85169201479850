/* eslint-disable no-param-reassign */
import { Dispatch, SetStateAction } from 'react';
import { DropdownOnChangeProps, WarehouseFormInputType } from '../../warehouseFactory/types';
import { SnowflakeFormDataType } from './costsSnowflake';
import { AuthMethod } from './CommonConfig';
import { initialFormDataType } from '../../useGetInitialWarehouseData';

const connectionSettingsTitle = 'P P P P P P';
const accountName = 'a a a a a a';
const database = 'd d d d d d';
const authMethod = 'm m m m m m';
const username = 'u u u u u u';
const privateKey = 'k k k k k k';
const passphrase = 'o o o o o o';
const usernameAndPassword = 'u u u p p p';

export const initialValues = {
  account: '',
  database: '',
  username: '',
  password: '',
  useKeyPairAuth: false,
  privateKey: '',
  privateKeyPassphrase: '',
  connectionName: 'dummy_connectionName',
  role: 'dummy_role',
  warehouse: 'dummy_warehouse',
  schema: 'dummy_schema',
  targetName: 'dummy_targetName',
  threads: 1,
} as initialFormDataType;

export const getDefaultEmptyCostAuthValues = (
  authType: AuthMethod,
  formData: SnowflakeFormDataType,
): SnowflakeFormDataType | undefined => {
  switch (authType) {
    case AuthMethod.USERNAMEPASSWORD:
      return {
        ...formData,
        authMethod: AuthMethod.USERNAMEPASSWORD,
        useKeyPairAuth: false,
        username: undefined,
        password: undefined,
      };
    case AuthMethod.KEY_PAIR:
      return {
        ...formData,
        authMethod: AuthMethod.KEY_PAIR,
        useSso: false,
        useKeyPairAuth: true,
        username: undefined,
        privateKey: undefined,
      };
    default:
      return undefined;
  }
};

export const formDistribution = (
  selectedAuthMethod: AuthMethod,
) => {
  switch (selectedAuthMethod) {
    case AuthMethod.USERNAMEPASSWORD:
      return `'${connectionSettingsTitle}'
        '${accountName}'
        '${database}'
        '${authMethod}'
        '${usernameAndPassword}'
      `;
    case AuthMethod.KEY_PAIR:
      return `'${connectionSettingsTitle}'
        '${accountName}'
        '${database}'
        '${authMethod}'
        '${username}'
        '${privateKey}'
        '${passphrase}'
      `;
    default:
      return '';
  }
};

export const formFields = (
  selectedAuthMethod: AuthMethod,
  setSelectedAuthMethod: Dispatch<SetStateAction<AuthMethod>>,
) => {
  const usernamePasswordOption = {
    text: AuthMethod.USERNAMEPASSWORD,
    type: 'item',
    onClick: () => setSelectedAuthMethod(AuthMethod.USERNAMEPASSWORD),
  };
  const keyPairOption = {
    text: AuthMethod.KEY_PAIR,
    type: 'item',
    onClick: () => setSelectedAuthMethod(AuthMethod.KEY_PAIR),
  };

  const authMethodDropdown = [{
    id: 'dropdown-sf-cost-auth-method',
    name: 'authMethod',
    label: 'Auth Method',
    gridArea: 'm',
    type: 'dropdown',
    menuItems: [
      usernamePasswordOption,
      keyPairOption,
    ],
    text: selectedAuthMethod,
    adminOnly: true,
    onChange: ({
      setFormData,
      selectedItem,
    }: DropdownOnChangeProps) => {
      setFormData((currentFormData) => ({
        ...currentFormData,
        ...getDefaultEmptyCostAuthValues(
          selectedItem as AuthMethod,
          currentFormData as SnowflakeFormDataType,
        ) as initialFormDataType,
      }));
    },
  }];

  const usernamePassword = [
    {
      id: 'text-sf-cost-username',
      name: 'username',
      label: 'Username',
      type: WarehouseFormInputType.TEXT,
      gridArea: 'u',
      value: '' as any,
      isPrivate: true,
      disabled: false,
    },
    {
      id: 'text-sf-cost-password',
      name: 'password',
      label: 'Password',
      type: WarehouseFormInputType.PASSWORD,
      gridArea: 'p',
      isPrivate: true,
      disabled: false,
      isSecretField: true,
      adminOnly: true,
    },
  ];

  const keyPair = [
    {
      id: 'text-sf-cost-keypair-username',
      name: 'username',
      label: 'Username',
      type: WarehouseFormInputType.TEXT,
      gridArea: 'u',
      title: 'Production Credentials',
      isPrivate: true,
      adminOnly: false,
      disabled: false,
    },
    {
      id: 'text-sf-cost-keypair-private-key',
      name: 'privateKey',
      label: 'Private Key',
      placeholder: ' ',
      type: WarehouseFormInputType.MULTI_LINE_INPUT,
      gridArea: 'k',
      isPrivate: true,
      adminOnly: false,
      disabled: false,
      isSecretField: true,
    },
    {
      id: 'text-sf-cost-keypair-passphrase',
      name: 'privateKeyPassphrase',
      label: 'Private Key Passphrase (optional)',
      type: WarehouseFormInputType.PASSWORD,
      gridArea: 'o',
      isOptionalField: true,
      disabled: false,
      isSecretField: true,
    },
  ];

  return ([
    {
      title: 'Connection Settings',
      gridArea: 'P',
      type: 'title',
    },
    {
      id: 'text-sf-cost-account-name',
      name: 'account',
      label: 'Account',
      type: WarehouseFormInputType.TEXT,
      gridArea: 'a',
      adminOnly: true,
      isPrivate: true,
    },
    {
      id: 'text-sf-cost-database',
      name: 'database',
      label: 'Database',
      type: WarehouseFormInputType.TEXT,
      gridArea: 'd',
    },
    ...authMethodDropdown,
    ...(selectedAuthMethod === AuthMethod.USERNAMEPASSWORD ? usernamePassword : []),
    ...(selectedAuthMethod === AuthMethod.KEY_PAIR ? keyPair : []),
  ]);
};
