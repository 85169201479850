import { DateTime, Duration } from 'luxon';

const getBaseDate = () => DateTime
  .now()
  .toUTC();

export const today = getBaseDate()
  .toFormat('yyyy-MM-dd');

export const todayWithHour = getBaseDate()
  .toISO({ includeOffset: false });

export const todayMidnight = getBaseDate()
  .set({
    hour: 23, minute: 59, second: 59, millisecond: 0,
  })
  .toISO({ includeOffset: false });

export const todayZeroHours = getBaseDate()
  .set({
    hour: 0, minute: 0, second: 0, millisecond: 1,
  })
  .toISO({ includeOffset: false });

export const fourteenDaysAgo = getBaseDate()
  .minus({ days: 14 })
  .toFormat('yyyy-MM-dd');

export const fourteenDaysAgoWithHour = getBaseDate()
  .minus({ days: 14 })
  .toISO({ includeOffset: false });

export const threeMonthsAgo = getBaseDate()
  .minus({ month: 3 })
  .toISO({ includeOffset: false });

export const calculateStartEndDate = (date: string): [string, string] => [
  DateTime
    .fromFormat(date, 'yyyy-MM-dd')
    .set({ hour: 0, minute: 0, millisecond: 1 })
    .toISO({ includeOffset: false }),
  DateTime
    .fromFormat(date, 'yyyy-MM-dd')
    .set({ hour: 23, minute: 59, second: 59 })
    .toISO({ includeOffset: false }),
];

export const calculateYear = (year: string) => {
  const initialDate = DateTime
    .fromFormat(year, 'yyyy-MM-dd')
    .toUTC();

  return [
    initialDate
      .set({ hour: 23, minute: 59, second: 59 })
      .set({ month: 1, day: 1 })
      .toISO({ includeOffset: false }),
    initialDate
      .set({ hour: 23, minute: 59, second: 59 })
      .set({ month: 12, day: 31 })
      .toISO({ includeOffset: false }),
  ];
};

export const convertToUTCISOString = (ISOTimestamp: string) => {
  const dateTime = DateTime.fromISO(ISOTimestamp, { zone: 'utc' });
  return dateTime.toISO();
};

interface formatDateTimeToLocalStringProps {
  dateTimeString?: string | null | number | undefined,
  placeholder?: string
}

export const formatDateTimeToLocalString = ({
  dateTimeString,
  placeholder = '-',
}: formatDateTimeToLocalStringProps) => {
  if (!dateTimeString || dateTimeString === '-') {
    return placeholder;
  }

  const formattedInputString = dateTimeString?.toString().replace(' ', 'T');
  const isoDateString = DateTime.fromISO(
    formattedInputString, { zone: 'utc' },
  );

  return isoDateString.toLocal().toLocaleString(DateTime.DATETIME_FULL);
};

export const getTodayIsoString = () => getBaseDate().toString();

interface findTimeBetweenProps {
  startDate: formatDateTimeToLocalStringProps['dateTimeString'],
  endDate: formatDateTimeToLocalStringProps['dateTimeString'],
}

export const calculatePlural = (value: number, string: string) => (
  Math.round(value) === 1
    ? `${Math.round(value)} ${string}`
    : `${Math.round(value)} ${string}s`
);

export const pluralizeText = (value: number, string: string) => (
  Math.round(value) === 1
    ? `${string}`
    : `${string}s`
);

export const findTimeBetween = ({
  startDate,
  endDate,
}: findTimeBetweenProps) => {
  if (!startDate || !endDate) return '-';

  const isoStartDate = DateTime.fromISO(startDate.toString(), { zone: 'utc' });
  const isoEndDate = DateTime.fromISO(endDate.toString(), { zone: 'utc' });

  const {
    days, hours, minutes, seconds,
  } = isoEndDate.diff(isoStartDate, ['days', 'hours', 'minutes', 'seconds']).toObject();

  let output = '';
  if (seconds && !days) {
    output = calculatePlural(seconds, 'second');
  }
  if (minutes && !days) {
    output = `${calculatePlural(minutes, 'minute')}${output.length > 0 ? ',' : ''} ${output}`;
  }
  if (hours) {
    output = `${calculatePlural(hours, 'hour')}${output.length > 0 ? ',' : ''} ${output}`;
  }
  if (days) {
    output = `${calculatePlural(days, 'day')}${output.length > 0 ? ',' : ''} ${output}`;
  }
  if (output.length === 0) {
    output = '0 seconds';
  }

  return output;
};

export const findTimeBetweenSeconds = ({
  startDate,
  endDate,
}: findTimeBetweenProps) => {
  if (!startDate || !endDate) return '-';

  const isoStartDate = DateTime.fromISO(startDate.toString(), { zone: 'utc' });
  const isoEndDate = DateTime.fromISO(endDate.toString(), { zone: 'utc' });

  const { seconds } = isoEndDate.diff(isoStartDate, 'seconds').toObject();

  return seconds;
};

export const convertMillisToHumanReadableDuration = (millis: number) => {
  const durationObj = Duration
    .fromMillis(millis)
    .shiftTo('hours', 'minutes', 'seconds');

  const duration = [
    durationObj.hours > 0 && `${durationObj.hours} ${pluralizeText(durationObj.hours, 'hr')}`,
    durationObj.minutes > 0 && `${durationObj.minutes} ${pluralizeText(durationObj.minutes, 'min')}`,
    durationObj.seconds > 0 && `${durationObj.seconds.toFixed(3)}s`,
  ].filter((val) => val).join(', ');

  return duration;
};
