import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { ParadimeIntegrations } from '@paradime-io/pragma-ui-kit/lib/components/utils';

export interface ConnectIntegrationsButtonsProps {
  hasConnectedIntegration: boolean,
  isLoadingConnectedIntegrations: boolean,
}

const ConnectIntegrationsButtons: FC<ConnectIntegrationsButtonsProps> = ({
  hasConnectedIntegration,
  isLoadingConnectedIntegrations,
}) => {
  const history = useHistory();

  const handleClick = () => {
    history.push('/settings/integrations');
  };

  if (hasConnectedIntegration) return null;

  return (
    <AutoLayout
      direction="horizontal"
      padding="none"
      distribution="packed"
      horizontalGap="dense"
      wrap
    >
      <DefaultButton
        type="standard"
        view="outlined"
        integrationIcon="slack"
        text="Connect Slack"
        loading={isLoadingConnectedIntegrations}
        onClick={handleClick}
        eventContext={Contexts.ACCOUNT_SETTINGS}
        eventObject="connectSlack"
        eventAction={Actions.CLICKED}
        style={{ width: '274px' }}
      />
      <DefaultButton
        type="standard"
        view="outlined"
        integrationIcon={ParadimeIntegrations.ms_teams}
        text="Connect Microsoft Teams"
        loading={isLoadingConnectedIntegrations}
        onClick={handleClick}
        eventContext={Contexts.ACCOUNT_SETTINGS}
        eventObject="connectMSTeams"
        eventAction={Actions.CLICKED}
        style={{ width: '274px' }}
      />
    </AutoLayout>
  );
};

export default ConnectIntegrationsButtons;
