import React, { FC } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import PageTitle, { PageTitles } from '../../PageTitle';
import CreateNewCompany from '../ChooseRoute/CreateNewCompany';
import JoinExistingCompany from '../ChooseRoute/JoinExistingCompany';
import EnterOrgName from './CreateNewCompany/EnterOrgName';
import BasicInfo from './OnboardingSteps/BasicInfo';
import ExtensionWasInstalled from './OnboardingSteps/ExtensionWasInstalled';
import ConnectSlack from './OnboardingSteps/ConnectSlack';
import SlackWasConnected from './OnboardingSteps/SlackWasConnected';
import ErrorOrgAlreadyExists from './CreateNewCompany/ErrorOrgAlreadyExists';
import ErrorNoAutoJoin from './OnboardingSteps/ErrorNoAutoJoin';
import ErrorExpiredInviteLink from './OnboardingSteps/ErrorExpiredInviteLink';

export const SynqOnboardingRoutePrefix = 'synq-onboarding';

export enum SYNQ_ONBOARDING_PAGE {
  'CREATE_NEW_COMPANY' = 'create-new-company',
  'JOIN_EXISTING' = 'join-existing-company',
  'ENTER_ORG' = 'enter-org',
  'BASIC_INFO' = 'basic-info',
  'EXTENSION_WAS_INSTALLED' = 'extension-installed-successfully',
  'CONNECT_SLACK' = 'connect-slack',
  'SLACK_WAS_CONNECTED' = 'slack-connected-successfully',
  'ERROR_ORG_ALREADY_EXISTS' = 'org-name-already-taken',
  'ERROR_NO_AUTO_JOIN' = 'auto-join-not-enabled',
  'ERROR_EXPIRED_LINK' = 'invite-link-has-expired',
}

const synqOnboardingPagesLookup: {
  [key in SYNQ_ONBOARDING_PAGE]: FC
} = {
  [SYNQ_ONBOARDING_PAGE.CREATE_NEW_COMPANY]: CreateNewCompany,
  [SYNQ_ONBOARDING_PAGE.JOIN_EXISTING]: JoinExistingCompany,
  [SYNQ_ONBOARDING_PAGE.ENTER_ORG]: EnterOrgName,
  [SYNQ_ONBOARDING_PAGE.BASIC_INFO]: BasicInfo,
  [SYNQ_ONBOARDING_PAGE.EXTENSION_WAS_INSTALLED]: ExtensionWasInstalled,
  [SYNQ_ONBOARDING_PAGE.CONNECT_SLACK]: ConnectSlack,
  [SYNQ_ONBOARDING_PAGE.SLACK_WAS_CONNECTED]: SlackWasConnected,
  [SYNQ_ONBOARDING_PAGE.ERROR_ORG_ALREADY_EXISTS]: ErrorOrgAlreadyExists,
  [SYNQ_ONBOARDING_PAGE.ERROR_NO_AUTO_JOIN]: ErrorNoAutoJoin,
  [SYNQ_ONBOARDING_PAGE.ERROR_EXPIRED_LINK]: ErrorExpiredInviteLink,
};

const Onboarding: FC = () => {
  const { pageRef } = useParams<{ pageRef: SYNQ_ONBOARDING_PAGE }>();

  return (
    <>
      <PageTitle title={PageTitles.ONBOARDING} />
      <Switch>
        <Route
          exact
          path={`/${SynqOnboardingRoutePrefix}/${pageRef}`}
          component={synqOnboardingPagesLookup[pageRef]}
        />
      </Switch>
    </>
  );
};

export default Onboarding;
