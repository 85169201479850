import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Sidebar } from 'primereact/sidebar';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import CodeSnippetCopy from '@paradime/common/lib/common/helpers/CodeSnippetCopy';
import { CodeTypography } from '../../Bolt/Bolt.styles';
import { AccountSettingsTab } from '../../NavBar';

interface ConfigureApiTriggerDrawerProps {
  isVisible: boolean;
  onHide: () => void;
}

const ConfigureApiTriggerDrawer: FC<ConfigureApiTriggerDrawerProps> = ({ isVisible, onHide }) => {
  const history = useHistory();

  const settingEnvVars = `
    export API_KEY = "generated_api_key"
    export API_SECRET = "generated_api_secret"
    export API_ENDPOINT = "generated_api_endpoint"
  `;

  const apiTemplate = `
  import requests
  import os
  
  # fetch the API credentials from the environment variables
  #
  api_key = os.environ.get('API_KEY')
  api_secret = os.environ.get('API_SECRET')
  api_endpoint = os.environ.get('API_ENDPOINT')
  
  graphql_query = """
  query GetAuditLogs {
      getAuditLogs {
          auditLogs {
              id
              createdDttm
              updatedDttm
              workspaceId
              workspaceName
              actorType
              actorUserId
              actorEmail
              eventSourceId
              eventSource
              eventId
              eventType
              metadataJson
          }
      }
  }
  """
  
  response = requests.post(api_endpoint, json={"query": graphql_query}, headers={
      "Content-Type": "application/json",
      "X-API-KEY": api_key,
      "X-API-SECRET": api_secret,
  })
  print(response.json())
  `;

  return (
    <Sidebar
      visible={isVisible}
      position="right"
      onHide={onHide}
      style={{ width: '600px' }}
      closeOnEscape
      header={(
        <AutoLayout
          direction="horizontal"
          padding="none"
          distribution="space-between"
        >
          <Typography type="h6" color="default" tagName="span">
            Configure Audit Logs API
          </Typography>
          <DefaultButton
            dense
            view="flat"
            text="Read docs"
            icon="manual"
            eventContext={Contexts.PLATFORM}
            eventObject="readDocsConfigureAPI"
            eventAction={Actions.CLICKED}
            onClick={() => {
              window.open('https://docs.paradime.io/app-help/app-settings/audit-logs', '_blank');
            }}
          />
        </AutoLayout>
      )}
    >
      <AutoLayout
        direction="vertical"
        distribution="packed"
        padding="none"
        verticalGap="dense"
        wrapperStyle={{ alignItems: 'flex-start' }}
      >
        <AutoLayout
          direction="vertical"
          distribution="packed"
          padding="none"
          verticalGap="dense"
        >
          <Typography type="body-small" color="default" colorStep="50" tagName="span">
            Use the Audit Logs API to retrieve historical logs data beyond the last 30 days.
            The snippet below can be used to set up an API trigger for Audit Logs.
          </Typography>

          <Typography type="body-small" color="default" colorStep="50" tagName="span">
            Generate API credentials in
            {' '}
            <strong>Account Settings &gt; Workspaces.</strong>
            {' '}
            and keep it safe, you will need to use them in code.
          </Typography>

          <DefaultButton
            dense
            view="smooth"
            text="Generate API Key"
            eventContext={Contexts.PLATFORM}
            eventObject="generateApiKey"
            eventAction={Actions.CLICKED}
            eventProperties={{ location: 'audit-logs' }}
            onClick={() => {
              history.push(`/account-settings/${AccountSettingsTab.WORKSPACE}`);
            }}
            style={{ width: 'fit-content' }}
          />

          <CodeTypography type="body-small" color="default" colorStep="50" tagName="span">
            As a best practice, your API credentials should be set as environment variables
            and should not be checked into source control.
            <br />
            <br />
            Use the API credentials generated and set the environment variables for
            {' '}
            <code>API_KEY</code>
            {' '}
            <code>API_SECRET</code>
            {' and '}
            <code>API_ENDPOINT</code>
            .
          </CodeTypography>

          <CodeSnippetCopy codeSnippet={settingEnvVars} language="shell" />
        </AutoLayout>
        <AutoLayout
          direction="vertical"
          distribution="packed"
          padding="none"
          verticalGap="none"
        >
          <Typography type="body-bold-small" color="default" tagName="span">
            Code snippet to fetch audit logs using Paradime API
          </Typography>
          <CodeSnippetCopy codeSnippet={apiTemplate} language="python" />
        </AutoLayout>
      </AutoLayout>
    </Sidebar>
  );
};

export default ConfigureApiTriggerDrawer;
