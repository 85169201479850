import React, {
  FC, MutableRefObject, ReactNode, useState,
} from 'react';
import { IconName } from '@blueprintjs/icons';
import { Icon } from '@blueprintjs/core';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { colorType, fontType } from '@paradime-io/pragma-ui-kit/lib/components/styles/styleTypes';
import { colorStepType } from '@paradime-io/pragma-ui-kit/lib/components/Typography/Typography.styles';

export interface InsightCardProps {
  title?: string,
  titleFontType?: fontType,
  titleColorStep?: colorStepType,
  children: ReactNode,
  childrenRef?: MutableRefObject<any>,
  childrenHeight?: string,
  dense?: boolean,
  cardBackgroundColor: string,
  borderColor: string,
  icon: IconName,
  iconColor: string,
  iconBackgroundColor: string,
  titleColor?: colorType,
  isLoading?: boolean,
}

const InsightCard: FC<InsightCardProps> = ({
  title,
  titleFontType = 'body-bold-small',
  titleColorStep = '60',
  children,
  childrenRef,
  childrenHeight = '160px',
  dense,
  cardBackgroundColor,
  borderColor,
  icon,
  iconColor,
  iconBackgroundColor,
  titleColor,
  isLoading,
}) => {
  const [isScrollable, setIsScrollable] = useState(false);

  if (isLoading) {
    return (
      <div
        className="bp4-skeleton"
        style={{
          width: '100%',
          height: '100%',
          minHeight: '100px',
          borderRadius: '8px',
        }}
      />
    );
  }

  return (
    <div
      onMouseEnter={() => {
        const contentIsTooLong = (
          (childrenRef?.current?.offsetHeight || 1) < (childrenRef?.current?.scrollHeight || 2)
        );
        setIsScrollable(contentIsTooLong);
      }}
      onMouseLeave={() => setIsScrollable(false)}
      style={{
        backgroundColor: cardBackgroundColor,
        border: `1px solid ${borderColor}`,
        borderRadius: '8px',
        padding: '24px',
        width: '100%',
        height: '100%',
      }}
    >
      <AutoLayout
        direction="vertical"
        padding="none"
        verticalGap="dense"
        distribution="packed"
        alignment="top"
        style={{
          gridTemplateRows: dense ? 'auto' : 'min-content auto',
          height: '100%',
        }}
      >
        <AutoLayout
          direction="horizontal"
          padding="none"
          horizontalGap="dense"
          distribution="packed"
        >
          <span
            style={{
              backgroundColor: iconBackgroundColor,
              padding: '8px',
              width: 'fit-content',
              borderRadius: '4px',
            }}
          >
            <Icon icon={icon} size={20} color={iconColor} />
          </span>
          {title && (
            <Typography
              type={titleFontType}
              color={titleColor}
              colorStep={titleColorStep}
            >
              {title}
            </Typography>
          )}
        </AutoLayout>
        <div
          style={{
            height: childrenHeight,
            overflowY: isScrollable ? 'auto' : 'hidden',
            overflowX: 'hidden',
            paddingRight: isScrollable ? 0 : '6px',
          }}
        >
          {children}
        </div>
      </AutoLayout>
    </div>
  );
};

export default InsightCard;
