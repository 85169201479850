import React, {
  Dispatch,
  FC,
  useState,
  SetStateAction,
} from 'react';
import { Icon } from '@blueprintjs/core';
import Dropdown from '@paradime-io/pragma-ui-kit/lib/components/Dropdown';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import { useHistory } from 'react-router-dom';
import { lineageStore } from '../../../stores';
import BranchDropDownContent from './BranchDropDownContent';

export interface CompareProps {
  initialLeftBranch: string,
  initialRightBranch: string,
  branches: Map<string, string>,
  dropDownWidth?: number,
  dropDownContentPosition: 'top' | 'bottom',
  setCurrentBranch: (newBranch: string) => void,
  setIsEmptyComparison?: Dispatch<SetStateAction<boolean>>,
}

const Compare: FC<CompareProps> = ({
  initialRightBranch,
  branches,
  dropDownWidth = 174.5,
  dropDownContentPosition,
  setCurrentBranch,
  initialLeftBranch,
  setIsEmptyComparison,
}) => {
  const [type, view, color] = ['dense', 'raised', 'default'] as any[];
  const currentBranch = lineageStore((s) => s.currentBranch);
  const [currentRightBranch, setCurrentRightBranch] = useState(currentBranch || initialRightBranch);
  const history = useHistory();
  const [currentLeftBranch, setCurrentLeftBranch] = useState(initialLeftBranch);
  const [currentLeftCommitHash, setCurrentLeftCommitHash] = useState(
    branches?.get(initialLeftBranch) || '',
  );

  const handleNewLeftDropDown = ([branch, newCommitHash]: [string, string]) => {
    setCurrentLeftBranch(branch);
    setCurrentLeftCommitHash(newCommitHash);
  };

  const handleNewRightDropDown = ([branch, newCommitHash]: [string, string]) => {
    setCurrentBranch(branch);
    setCurrentRightBranch(branch);
    window.analytics.track('lineage:nodes_compared');
    if (setIsEmptyComparison) setIsEmptyComparison(false);
    history.push(`/lineage/compare/${currentLeftCommitHash}/${newCommitHash}`);
  };

  return (
    <>
      <AutoLayout
        horizontalGap="very-dense"
        padding="very-dense"
        alignment="center"
        direction="horizontal"
        style={{
          padding: '0',
          alignItems: 'center',
        }}
      >
        <Dropdown
          data-testid="branch"
          position={dropDownContentPosition}
          content={(
            <BranchDropDownContent
              color={color}
              type={type}
              view={view}
              branches={branches}
              currentBranch={currentLeftBranch}
              expose={handleNewLeftDropDown}
            />
          )}
          fill
          dense // @ts-ignore
          text={(
            <p style={{ display: 'flex', margin: 0, alignItems: 'center' }}>
              <Typography tagName="span" type="caption-small" color="default" colorStep="60">base: </Typography>
              <Typography
                style={{
                  textOverflow: 'ellipsis',
                  display: 'block',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
                tagName="span"
                type="caption"
              >
                {currentLeftBranch}

              </Typography>
            </p>
          )}
          view="smooth"
          style={{
            width: dropDownWidth,
          }}
        />
        <Icon icon="circle-arrow-left" iconSize={20} color="var(--violet70)" />
        <Dropdown
          data-testid="compareBranch"
          position={dropDownContentPosition}
          content={(
            <BranchDropDownContent
              color={color}
              type={type}
              view={view}
              branches={branches}
              currentBranch={currentRightBranch}
              expose={handleNewRightDropDown}
            />
          )}
          fill
          dense // @ts-ignore
          text={(
            <p style={{ display: 'flex', margin: 0, alignItems: 'center' }}>
              <Typography tagName="span" type="caption-small" color="default" colorStep="60">compare: </Typography>
              <Typography
                style={{
                  textOverflow: 'ellipsis',
                  display: 'block',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
                tagName="span"
                type="caption"
              >
                {currentRightBranch}

              </Typography>
            </p>
          )}
          view="smooth"
          style={{
            width: dropDownWidth,
          }}
        />
      </AutoLayout>
    </>
  );
};

export default Compare;
