import { useUpgradeRequestMutation } from '../client/generated/service';

export enum RequestType {
  PLAN_INTENT = 'plan:intent',
  PLAN_REQUEST = 'plan:request',
  FEATURE_REQUEST = 'feature:request',
  ACCOUNT_UNLOCK_REQUEST = 'account:unlock:request',
  CATALOG_EDIT_REQUEST = 'catalog:edit:request',
  INTEGRATION_REQUEST = 'integration:request',
}

export interface upgradeRequestProps {
  requestType: RequestType,
  feature: string,
}

export const useUpgradeRequest = () => {
  const [
    upgradeRequestMutation, { loading: upgradeRequestInProgress },
  ] = useUpgradeRequestMutation();

  const upgradeRequest = async ({ requestType, feature }: upgradeRequestProps) => (
    upgradeRequestMutation({
      variables: {
        requestType, feature,
      },
    })
  );

  return {
    upgradeRequest,
    upgradeRequestInProgress,
  };
};
