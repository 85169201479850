import React, { FC } from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import HelpCard from './HelpCard';
import { recommendations } from './recommendations';

const AppHelpHome:FC = () => (
  <AutoLayout
    direction="vertical"
    distribution="packed"
    padding="dense"
    verticalGap="dense"
    alignment="top-left"
    style={{ height: 'auto' }}
    wrapperStyle={{ height: 'calc(100% - 153px', overflow: 'auto' }}
  >
    <Typography tagName="span" type="body-bold-small" colorStep="100">
      Recommended
    </Typography>
    <AutoLayout
      direction="horizontal"
      distribution="packed"
      horizontalGap="very-dense"
      padding="none"
      style={{ alignItems: 'center', gridTemplateColumns: '1fr 1fr' }}
    >
      {
          recommendations
            .filter((recommendation) => recommendation.isFeatured)
            .map((recommendation) => (
              <HelpCard
                key={recommendation.title}
                {...recommendation}
              />
            ))
        }
    </AutoLayout>
    <AutoLayout
      direction="vertical"
      distribution="packed"
      padding="none"
      verticalGap="very-dense"
      alignment="top-left"
      style={{ height: 'auto', paddingBottom: '16px' }}
    >
      {
          recommendations
            .filter((recommendation) => !recommendation.isFeatured)
            .map((recommendation) => (
              <HelpCard
                key={recommendation.title}
                {...recommendation}
              />
            ))
        }
    </AutoLayout>
  </AutoLayout>
);

export default AppHelpHome;
