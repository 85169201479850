/* eslint-disable no-script-url */
/* eslint-disable no-underscore-dangle */
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import { colorType } from '@paradime-io/pragma-ui-kit/lib/components/styles/styleTypes';
import React from 'react';
import { GqlMetaItem, GqlTestWithStatus } from '../../../client/generated/service-dataEndpoint';
import { key } from '../../../utilis';
import HoverableChip, { testType } from './HoverableChip';

export enum CatalogAssetTypes {
  'DASHBOARD' = 'dashboard',
  'TABLE' = 'table',
  'COLUMN' = 'column',
  'EXPLORE' = 'explore',
  'LOOK' = 'look',
}

export const getTests = (tests: GqlTestWithStatus[]) => {
  if (tests.length === 0) return '-';

  return (
    <AutoLayout
      direction="horizontal"
      padding="none"
      horizontalGap="super-ultra-dense"
      style={{
        gridTemplateColumns: 'max-content max-content',
        rowGap: 2,
        gridAutoFlow: 'unset',
      }}
    >
      {tests.map((test) => (
        <HoverableChip
          key={key(test.name)}
          test={test as testType}
        />
      ))}
    </AutoLayout>
  );
};

export type metaValue = number | string | string[] | boolean

export const getMetaKeyValuePair = (item: GqlMetaItem) => {
  let keyValuePair: [string, metaValue] | undefined;
  switch (item.__typename) {
    case 'GQLMetaBooleanValuedItem':
      keyValuePair = [item.key, item.boolValue];
      break;
    case 'GQLMetaFloatValuedItem':
      keyValuePair = [item.key, item.floatValue];
      break;
    case 'GQLMetaListOfStringValuedItem':
      keyValuePair = [item.key, item.listOfStringValue];
      break;
    case 'GQLMetaStringValuedItem':
      keyValuePair = [item.key, item.stringValue];
      break;

    default:
      keyValuePair = [item.key, ''];
      break;
  }

  return keyValuePair;
};

export const getClassifications = (metalist: GqlMetaItem[]) => metalist.map((meta) => {
  const [title, tags] = getMetaKeyValuePair(meta);
  return {
    title,
    tags,
  };
});

export const calculateTestLevel = (level: number): colorType => {
  if (level === 1) return 'success';
  if ((level) > 0.33) return 'warning';
  return 'danger';
};

interface checkIfArrayIsEmptyProps {
  attribute?: string[] | null,
}

interface checkIfStringIsEmptyProps {
  attribute?: string | null,
}

export const checkIfArrayIsEmpty = ({
  attribute,
}: checkIfArrayIsEmptyProps) => {
  if (!attribute || attribute.length === 0) {
    return ['-'];
  }
  return attribute;
};

export const checkIfStringIsEmpty = ({
  attribute,
}: checkIfStringIsEmptyProps) => {
  if (!attribute) return '-';
  return attribute;
};

export const checkIfAttributeIsEmpty = <T, >(attribute: T[] | T, isLink?: boolean) => {
  if (attribute instanceof Array) {
    if (attribute.length === 0) {
      if (isLink) {
        return [{ name: '-', url: 'javascript:void(0)' }];
      }
      return ['-'];
    }
    return attribute;
  }

  if (
    attribute === undefined
    || attribute === null
  ) return '-';
  return attribute;
};

export const lookerTypes = ['GQLCatalogueLookerDashboardItem', 'GQLCatalogueLookerExploreItem', 'GQLCatalogueLookerLookItem'];
