import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Sidebar } from 'primereact/sidebar';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import Spinner from '@paradime-io/pragma-ui-kit/lib/components/Spinner';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import StripeEmbeddedPaymentElement, { StripeEmbeddedPaymentElementProps } from './StripeEmbeddedPaymentElement';
import { useCheckStripePaymentOutcome } from './useCheckStripePaymentOutcome';
import PaymentCompleted from './PaymentCompleted';
import { PlatformTab } from '../../../Platform';
import { PurchaseSummaryType } from './PurchaseSummary';
import { PricingProduct } from '../../../Platform/Plans/hooks/usePricingPlans';
import { RequestType, useUpgradeRequest } from '../../../../utilis/useUpgradeRequest';

export interface StripeSidebarProps extends Omit<StripeEmbeddedPaymentElementProps, 'redirectUrl'> {
  isVisible: boolean,
  isLoading?: boolean,
  onHide: () => void,
  onCompletionRedirectUrl: string,
  purchaseSummary?: PurchaseSummaryType[],
  sidebarTitle?: string,
  sidebarSubtitle?: string,
  allProducts?: PricingProduct[],
}

const StripeSidebar: FC<StripeSidebarProps> = ({
  isVisible,
  onHide,
  onCompletionRedirectUrl,
  purchaseSummary,
  sidebarTitle = 'Upgrade your plan',
  sidebarSubtitle,
  prices,
  numberOfUsers,
  selectedPlan,
  allProducts,
}) => {
  const history = useHistory();
  const { upgradeRequest } = useUpgradeRequest();

  const { paymentOutcome, isPositiveOutcome, isChecking } = useCheckStripePaymentOutcome();

  const planName = new URLSearchParams(window.location.search).get('plan_name');
  const selectedProduct = allProducts?.find(({ name }) => name === planName);

  const calculateOpenState = () => isVisible || isChecking || !!planName;
  const [isOpen, setIsOpen] = useState(calculateOpenState());

  useEffect(() => {
    setIsOpen(calculateOpenState());
  }, [isVisible]);

  useEffect(() => {
    if (isChecking) setIsOpen(calculateOpenState());
  }, [isChecking]);

  useEffect(() => {
    if (selectedPlan) {
      upgradeRequest({
        requestType: RequestType.PLAN_INTENT, feature: selectedPlan.name,
      });
    }
  }, [selectedPlan]);

  const handleHide = () => {
    setIsOpen(false);
    onHide();

    // If the payment has been completed, it is important we get rid of the query params in the URL
    if (paymentOutcome) history.push(`/platform/${PlatformTab.PLANS}`);
  };

  const getSubtitle = () => {
    if (sidebarSubtitle) return sidebarSubtitle;

    if (paymentOutcome && selectedProduct) {
      return selectedProduct?.description;
    }

    return undefined;
  };

  const getSidebarContent = () => {
    if (isChecking) return <Spinner />;

    return paymentOutcome
      ? <PaymentCompleted isPositiveOutcome={isPositiveOutcome} selectedProduct={selectedProduct} />
      : (
        <StripeEmbeddedPaymentElement
          redirectUrl={onCompletionRedirectUrl}
          purchaseSummary={purchaseSummary}
          prices={prices}
          numberOfUsers={numberOfUsers}
          selectedPlan={selectedPlan}
        />
      );
  };

  return (
    <Sidebar
      visible={isOpen}
      position="right"
      onHide={handleHide}
      style={{ width: '600px' }}
      header={(
        <AutoLayout
          direction="vertical"
          padding="none"
          distribution="packed"
          verticalGap="none"
        >
          <Typography type="h6" color="default" tagName="span">
            {paymentOutcome && isPositiveOutcome
              ? `${planName} plan activated!`
              : sidebarTitle}
          </Typography>
          {getSubtitle() && (
            <Typography type="body-bold-small" color="default" colorStep="50" tagName="span">
              {getSubtitle()}
            </Typography>
          )}
        </AutoLayout>
      )}
      dismissable={false}
    >
      {getSidebarContent()}
    </Sidebar>
  );
};

export default StripeSidebar;
