import React, { FC } from 'react';
import { KnockProvider, KnockFeedProvider } from '@knocklabs/react';
import NotificationToaster from './NotificationToaster';
import { useGetKnockUser } from './useGetKnockUser';

const KnockToast:FC = () => {
  const { knockUser } = useGetKnockUser();

  if (!knockUser) return null;

  return (
    <KnockProvider
      apiKey={knockUser.publicApiKey}
      userId={knockUser.userId}
      userToken={knockUser.userToken}
    >
      <KnockFeedProvider
        feedId={knockUser.toastChannelId}
        defaultFeedOptions={{
          tenant: knockUser.tenantId,
        }}
      >
        <NotificationToaster />
      </KnockFeedProvider>
    </KnockProvider>
  );
};

export default KnockToast;
