import { Dispatch, SetStateAction } from 'react';
import { WarehouseFormInputType } from '../../warehouseFactory/types';
import { WarehouseEnv } from '../../utils';
import { sendResetSecretsMessage, sendResetSpecificFieldMessage } from '../CommonConfig';
import { initialFormDataType } from '../../useGetInitialWarehouseData';
import { DatabricksFormDataType } from './CommonConfig';
import { DevWarehouseHelp, ProdWarehouseHelp } from '../../../../Common/AppHelp/help';

export const formDistribution = (showEditButton: boolean) => (
  showEditButton
    ? ("'P P P P P P' "
      + "'p p p t t t' "
      + "'d d d d d d' "
      + "'j j j j j j' "
      + "'c c c c c c' "
      + "'n n n n n n' "
      + "'D D D D D D' "
      + "'f f f f f b' "
      + "'s s s h h h' ")
    : ("'P P P P P P' "
      + "'p p p t t t' "
      + "'d d d d d d' "
      + "'j j j j j j' "
      + "'c c c c c c' "
      + "'n n n n n n' "
      + "'D D D D D D' "
      + "'f f f f f f' "
      + "'s s s h h h' ")
);

export const initialValues = (env: WarehouseEnv, formData?: initialFormDataType) => ({
  connectionName: '',
  targetName: env,
  host: '',
  httpPath: '',
  hasUnityCatalog: !!(formData as DatabricksFormDataType | undefined)?.catalog || false,
  catalog: '',
  token: '',
  schema: '',
  threads: 1,
});

export const formFields = (
  env: WarehouseEnv,
  showEditButton: boolean,
  isEditingToken: boolean,
  setIsEditingToken: Dispatch<SetStateAction<boolean>>,
) => {
  const editTokenButton = [{
    name: 'editTokenButton',
    text: isEditingToken ? 'Cancel' : 'Edit',
    type: WarehouseFormInputType.BUTTON,
    gridArea: 'b',
    onClick: () => setIsEditingToken((prev) => {
      if (prev) {
        // User has pressed cancel, undo any changes
        sendResetSecretsMessage();
      }
      return !prev;
    }),
  }];

  return [
    {
      title: 'Connection Settings',
      gridArea: 'P',
      type: 'title',
      helpArticleId: (env === WarehouseEnv.PROD)
        ? ProdWarehouseHelp.DATABRICKS
        : DevWarehouseHelp.DATABRICKS,
    },
    {
      id: 'text-db-profile-name',
      name: 'connectionName',
      label: 'Profile Name',
      type: WarehouseFormInputType.TEXT,
      gridArea: 'p',
      title: 'Profile Name',
      adminOnly: true,
      showHighlight: true,
      highlightDescription: 'You will find this in your <code>dbt_project.yml</code>.<br>Use <code>default</code> if you are getting started with an empty repo.',
    },
    {
      id: 'text-db-target-name',
      name: 'targetName',
      label: 'Target',
      type: WarehouseFormInputType.TEXT,
      value: env || WarehouseEnv.DEV,
      gridArea: 't',
      adminOnly: true,
    },
    {
      id: 'text-db-server-hostname',
      name: 'host',
      label: 'Server Hostname',
      type: WarehouseFormInputType.TEXT,
      firstUserOnly: true,
      gridArea: 'd',
      adminOnly: true,
    },
    {
      id: 'text-db-http-path',
      name: 'httpPath',
      label: 'HTTP Path',
      type: WarehouseFormInputType.TEXT,
      firstUserOnly: true,
      gridArea: 'j',
      adminOnly: true,
    },
    {
      id: 'checkbox-db-unity-catalog',
      name: 'hasUnityCatalog',
      label: 'Configure Unity Catalog',
      helperText: 'Only available in dbt version 1.1 and later',
      type: WarehouseFormInputType.CHECKBOX,
      firstUserOnly: true,
      gridArea: 'c',
      fill: true,
      adminOnly: true,
      onChange: () => sendResetSpecificFieldMessage({ fieldName: 'catalog', useInitialValue: false, customValue: '' }),
      withTogglingInputField: true,
      togglingInputFieldProps: {
        name: 'catalog',
        label: 'Catalog Name',
        type: WarehouseFormInputType.TEXT,
        gridArea: 'n',
      },
    },
    {
      title: (env === WarehouseEnv.PROD) ? 'Production Credentials' : 'Development Credentials',
      gridArea: 'D',
      type: WarehouseFormInputType.TITLE,
    },
    {
      id: 'text-db-personal-access-token',
      name: 'token',
      label: 'Personal Access Token',
      type: WarehouseFormInputType.PASSWORD,
      firstUserOnly: false,
      gridArea: 'f',
      title: (env === WarehouseEnv.PROD) ? 'Production Credentials' : 'Development Credentials' as any,
      disabled: showEditButton ? !isEditingToken : false,
      isSecretField: true,
      isEditingSecretField: isEditingToken,
    },
    ...(showEditButton ? editTokenButton : []),
    {
      id: 'text-db-schema',
      name: 'schema',
      label: 'Schema',
      type: WarehouseFormInputType.TEXT,
      firstUserOnly: false,
      gridArea: 's',
      title: (env === WarehouseEnv.PROD) ? 'Production Credentials' : 'Development Credentials' as any,
    },
    {
      id: 'text-db-threads',
      name: 'threads',
      label: 'Threads',
      type: WarehouseFormInputType.NUMBER,
      value: 8,
      additionalUserPlaceholder: 8,
      gridArea: 'h',
    },
  ];
};
