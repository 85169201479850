import React, { FC, useEffect } from 'react';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { companyStore, graphQLStore } from '../../../stores';
import paradimeLogo from '../../../images/paradime-logo-with-text.svg';
import serverWithCloud from './images/serverWithCloud.svg';
import { UserIdentity, UserIdentityDocument } from '../../../client/generated/control-plane';

const CompanycreationInProgress: FC = () => {
  const companyCreationInProgress = companyStore((s) => s.companyCreationInProgress);
  const setCompanyCreationInProgress = companyStore((s) => s.setCompanyCreationInProgress);

  const jwtToken = graphQLStore((state) => state.jwtToken);
  const paradimeOrganisationFromStore = graphQLStore((state) => state.paradimeOrganisation);
  const controlPlaneUrl = paradimeOrganisationFromStore?.controlPlaneUrl;

  const controlPlaneClient = new ApolloClient({
    uri: controlPlaneUrl,
    cache: new InMemoryCache(),
    headers: {
      authorization: jwtToken ? `Bearer ${jwtToken}` : '',
    },
  });

  const checkIfCompanyHasStartedYet = () => {
    controlPlaneClient
      .query<{ userIdentity: UserIdentity }>({
        query: UserIdentityDocument,
        fetchPolicy: 'no-cache',
        context: { randomNumberToAvoidCaching: Math.random() },
      })
      .then((myIdentity) => {
        const { company } = myIdentity.data.userIdentity;

        setCompanyCreationInProgress(company?.creationInProgress);

        if (company?.creationInProgress?.toString() === 'false') {
          // The company has now been created
          window.location.reload();
        }
      });
  };

  useEffect(() => {
    const intervalId = setInterval(checkIfCompanyHasStartedYet, 60000);

    if (!companyCreationInProgress) clearInterval(intervalId);

    return () => clearInterval(intervalId);
  }, [companyCreationInProgress]);

  if (!companyCreationInProgress) return null;

  return (
    <AutoLayout
      direction="vertical"
      verticalGap="dense"
      distribution="packed"
      style={{
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
        zIndex: 1000,
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: 'var(--grey0)',
      }}
    >
      <img src={paradimeLogo} alt="paradime" />
      <AutoLayout
        direction="vertical"
        padding="none"
        verticalGap="dense"
        distribution="packed"
        alignment="center"
        style={{ maxWidth: '930px', margin: '100px auto' }}
      >
        <img src={serverWithCloud} alt="company not ready" style={{ margin: 'auto' }} />
        <Typography font="inter" type="h4" style={{ textAlign: 'center' }}>Hang tight, magic in progress!</Typography>
        <Typography font="inter" type="body" style={{ textAlign: 'center' }}>
          We&apos;re setting up your Paradime account, it will just take a couple of minutes.
          <br />
          Keep an eye on your email, we&apos;ll notify you as soon as everything&apos;s
          ready to rock!
        </Typography>
      </AutoLayout>
    </AutoLayout>
  );
};

export default CompanycreationInProgress;
