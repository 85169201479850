import {
  GqlMetaBooleanValuedItem,
  GqlMetaFloatValuedItem,
  GqlMetaItem,
  GqlMetaListOfStringValuedItem,
  GqlMetaStringValuedItem,
} from '../../client/generated/service-dataEndpoint';
import { catalogueClassificationDataProps } from '../types';
import { getMetaKeyValuePair } from '../../components/Catalog/SearchRoutes/ResultTransformations';

export const catalogueClassificationData = ({
  resultItem,
}: catalogueClassificationDataProps) => {
  const makeArrayOfMetaTags = (columns: {
            dbtMetaAsList: Array<GqlMetaItem>;
        }[]) => (
    Array.from(
      new Set(
        columns.flatMap((column) => (
          column.dbtMetaAsList.flatMap((meta) => (
            meta.key
          ))
        )),
      ),
    )
  );

  type metaTypes = GqlMetaBooleanValuedItem
    | GqlMetaFloatValuedItem
    | GqlMetaListOfStringValuedItem
    | GqlMetaStringValuedItem;
  const makeArrayOfClassificationOptions = (metaList: metaTypes[]) => (
    Array.from(new Set(metaList.map((meta) => {
      const [option] = getMetaKeyValuePair(meta);
      return option;
    })))
  );

  switch (resultItem.__typename) {
    case 'GQLCatalogueDwhColumnItem':
      return ({
        keyList: makeArrayOfMetaTags(resultItem.table.columns),
        tableClassificationOptions: makeArrayOfClassificationOptions(
          resultItem.table.dbtMetaAsList,
        ),
      });
    case 'GQLCatalogueMaterialisedDbtObjectColumnItem':
      return ({
        keyList: makeArrayOfMetaTags(resultItem.dbtItem.dbtColumns),
        tableClassificationOptions: makeArrayOfClassificationOptions(
          resultItem.dbtItem.dbtMetaAsList,
        ),
      });
    case 'GQLCatalogueDwhTableItem':
      return ({
        keyList: makeArrayOfMetaTags(resultItem.columns),
        tableClassificationOptions: makeArrayOfClassificationOptions(resultItem.dbtMetaAsList),
      });
    case 'GQLCatalogueMaterialisedDbtItem':
      return ({
        keyList: makeArrayOfMetaTags(resultItem.dbtColumns),
        tableClassificationOptions: makeArrayOfClassificationOptions(resultItem.dbtMetaAsList),
      });
    default:
      return ({
        keyList: [],
        tableClassificationOptions: [],
      });
  }
};
