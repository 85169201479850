import { ApolloClient, InMemoryCache } from '@apollo/client';
import { useGetProfileQuery } from '../../client/generated/service';
import { graphQLStore } from '../../stores';

const useSetupHasuraClient = () => {
  const setHasuraClient = graphQLStore((state) => state.setHasuraClient);

  useGetProfileQuery({
    onCompleted: ({ userIdentity }) => {
      const hasuraClient = new ApolloClient({
        uri: userIdentity?.userInformation?.hasura.endpoint,
        cache: new InMemoryCache(),
        headers: {
          authorization: `Bearer ${userIdentity?.userInformation?.hasura.token}`,
        },
      });
      setHasuraClient(hasuraClient);
    },
  });
};

export { useSetupHasuraClient };
