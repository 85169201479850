import React, { FC } from 'react';
import Input from '@paradime-io/pragma-ui-kit/lib/components//DefaultInput';
import { Menu } from '@paradime-io/pragma-ui-kit/lib/components//Menu';
import MenuItem from '@paradime-io/pragma-ui-kit/lib/components//MenuItem';
import Typography from '@paradime-io/pragma-ui-kit/lib/components//Typography';
import { colorType } from '@paradime-io/pragma-ui-kit/lib/components/styles/styleTypes';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import useSearchNodesManager from '../hooks/useSearchNodesManager';
import { integrationHandlerManager } from '../../../integrations';

export interface SearchInputProps {
  type: 'def' | 'dense',
  view: 'smooth' | 'outlined' | 'raised' | 'filled',
  color: colorType,
  commitHash: string,
  position?: 'top' | 'bottom',
  width?: number,
  expose: (nodeId: string) => void // eslint-disable-line
}

const SearchInput: FC<SearchInputProps> = ({
  type,
  view,
  color,
  expose,
  commitHash,
  position = 'top',
  width = 248,
}) => {
  const {
    handleDelayedQuery,
    clearQuery,
    query,
    currentNodes,
    hasDataSuccessfullyLoaded,
    hasNoMatches,
  } = useSearchNodesManager(commitHash);

  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      <Input
        type="text"
        autocomplete="off"
        view="smooth"
        color="default"
        dense
        placeholder="Search by name, tag or node types"
        style={{
          width,
        }}
        value={query}
        expose={(e) => handleDelayedQuery(e.target.value)}
      />
      <div
        style={{
          width,
          position: 'absolute',
          bottom: position === 'top' ? 43 : 'unset',
          top: position === 'bottom' ? 41 : 'unset',
          left: 7,
          display: query ? 'block' : 'none',
        }}
        data-testid="menuWrapper"
      >
        <Menu data-testid="menu" type={type} view={view} color={color} style={{ maxHeight: 292, minHeight: 44, overflow: 'auto' }}>
          {hasDataSuccessfullyLoaded && currentNodes
            ?.slice(0, 100)
            ?.map((node) => {
              const chosenHandler = integrationHandlerManager(node.nodeType);
              if (chosenHandler) {
                const {
                  img, labelElement,
                } = chosenHandler.handlers.lineageSearchInputDropdown({
                  nodeType: node.nodeType,
                  logoBase64: node.logoBase64 || '',
                  nodeTypeLabel: node.nodeTypeLabel || '',
                  labelColour: node.color || '',
                });
                return (
                  <MenuItem
                    data-testid="menuItems"
                    icon={(
                      <img
                        src={img}
                        alt="node-logo"
                        style={{
                          placeSelf: 'center',
                          marginLeft: '8px',
                          marginRight: 0,
                          width: '16px',
                          height: '16px',
                        }}
                      />
                    )}
                    key={`${node.canonicalName.name}-item-${Math.random()}`}
                    type="dense"
                    text={<Typography tagName="span" type="body-small">{node.canonicalName.name}</Typography>}
                    labelElement={labelElement}
                    onItemClick={() => {
                      expose(node.nodeId);
                      clearQuery();
                    }}
                    eventContext={Contexts.LINEAGE}
                    eventObject="nodeSearchMenuItem"
                    eventAction={Actions.CLICKED}
                    eventProperties={{ location: 'lineage-home', nodeName: node.canonicalName.name, nodeType: node.nodeType }}
                  />
                );
              }
              return null;
            })}
          {
            !hasDataSuccessfullyLoaded && (
              <MenuItem
                data-testid="searchingNodesMenuItem"
                text={(
                  <Typography
                    tagName="span"
                    type="body-small"
                  >
                    Loading...
                  </Typography>
                )}
                eventContext={Contexts.LINEAGE}
                eventObject="searchingResults"
                eventAction={Actions.CLICKED}
              />
            )
          }
          {
            hasNoMatches
            && (
              <MenuItem
                data-testid="emptyMenuItem"
                text={(
                  <Typography
                    tagName="span"
                    type="body-small"
                  >
                    No results found
                  </Typography>
                )}
                eventContext={Contexts.LINEAGE}
                eventObject="noResults"
                eventAction={Actions.CLICKED}
              />
            )
          }
        </Menu>
      </div>
    </div>
  );
};

export default SearchInput;
