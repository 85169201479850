/* eslint-disable no-case-declarations */
import { useEffect } from 'react';

export enum NavbarAction {
  'OPEN_INVITATIONS' = 'open-invitations'
}

const useNavbarActionsListener = () => {
  useEffect(() => {
    const eventHandler = (e: MessageEvent) => {
      const { data: { type } } = e;

      switch (type) {
        case NavbarAction.OPEN_INVITATIONS:
          const nav = document.querySelector('nav');

          const inviteButton = nav?.querySelector('button');

          inviteButton?.click();

          break;

        default:
          break;
      }
    };

    window.addEventListener('message', eventHandler);

    return () => window.removeEventListener('message', eventHandler, false);
  }, []);
};

export default useNavbarActionsListener;
