import React, { FC } from 'react';
import NotificationDropdown, { DropdownOption } from './NotificationDropdown';
import { useGetIntegrationsChannelList } from './useGetIntegrationChannelsList';
import { ConnectedNotificatonIntegration } from '.';

export interface IntegrationChannelsDropdownProps {
  selectedChannelName: DropdownOption,
  onSelectNewChannelName: (newChannelName: DropdownOption) => void,
  isLoading?: boolean,
  isDisabled?: boolean,
  title: string,
  placeholder?: string,
  connectedIntegration: ConnectedNotificatonIntegration,
  showRefreshButton?: boolean,
}

const IntegrationChannelsDropdown: FC<IntegrationChannelsDropdownProps> = ({
  selectedChannelName,
  onSelectNewChannelName,
  isLoading,
  isDisabled,
  title,
  placeholder = 'Select channel',
  connectedIntegration,
  showRefreshButton = true,
}) => {
  const {
    channelsList,
    isLoadingChannelsList,
    refreshChannelsList,
  } = useGetIntegrationsChannelList({ connectedIntegration });

  return (
    <NotificationDropdown
      value={selectedChannelName}
      options={channelsList}
      onChange={onSelectNewChannelName}
      isLoading={isLoading || isLoadingChannelsList}
      isDisabled={isDisabled}
      title={title}
      onRefresh={showRefreshButton ? refreshChannelsList : undefined}
      placeholder={placeholder}
      showClearAllButton={selectedChannelName.value !== ''}
      showFilter
    />
  );
};

export default IntegrationChannelsDropdown;
