import React from 'react';
import { colorType } from '@paradime-io/pragma-ui-kit/lib/components/styles/styleTypes';
import { lineageNodeDisplayProps } from '../types';
import LookerLogo from '../../components/Lineage/images/Looker';
import { decodeLogoBase64, translateBEColourToFEColour } from '../utils';

const getNodeColor = (nodeType: string) => {
  switch (nodeType) {
    case 'LookerLookNode':
      return 'success_alt';
    case 'LookerViewNode':
    case 'LookerModelNode':
    case 'LookerExploreNode':
      return 'warning_alt';
    case 'LookerDashboardNode':
      return 'primary';
    case 'LookerScheduleNode':
      return 'danger_alt';
    default:
      return 'primary';
  }
};

export const lineageNodeDisplay = ({
  nodeType,
  lineageData,
}: lineageNodeDisplayProps) => {
  // Strip 'Looker' and 'Node' to get the type
  // e.g. LookerViewNode -> View
  const nodeTypeName = nodeType.replace('Looker', '').replace('Node', '');

  return ({
    nodePrimaryLogo: decodeLogoBase64(lineageData?.logoBase64) || <LookerLogo />,
    nodeColor: translateBEColourToFEColour(lineageData?.color).variantName as colorType
      || getNodeColor(nodeType) as colorType,
    nodeTypeName,
  });
};
