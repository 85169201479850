/* eslint-disable no-case-declarations */
import { catalogueFieldsTabProps } from '../types';
import { replaceNullWithEmptyString } from '../../components/Catalog/SearchRoutes/CatalogItem/Fields';

export const catalogueFieldsTab = ({
  resultItem,
}: catalogueFieldsTabProps) => {
  switch (resultItem?.__typename) {
    case 'GQLCatalogueIntegrationDatasourceItem':
    case 'GQLCatalogueIntegrationChartItem':
      const columnHeadings = [
        { id: 'type', label: 'Type' },
        { id: 'name', label: 'Name' },
        { id: 'description', label: 'Description' },
        { id: 'dataType', label: 'Data Type' },
      ];

      resultItem.fields[0].extraAttributes?.forEach(({ name }) => {
        columnHeadings.push({ id: name, label: name });
      });

      const data = resultItem?.fields?.map((field, i) => ({
        id: i,
        type: replaceNullWithEmptyString(field?.type),
        name: replaceNullWithEmptyString(field?.name),
        description: replaceNullWithEmptyString(field?.description),
        dataType: replaceNullWithEmptyString(field?.dataType),
        ...(field.extraAttributes || []).map(({ name, value }) => ({ [name]: value })),
      }));

      return ({
        columnHeadings,
        data,
      });
    default:
      return ({
        columnHeadings: [],
        data: [],
      });
  }
};
