import { catalogueSearchFiltersProps } from '../types';
import { TableauSubTypes } from '.';

export const catalogueSearchFilters = ({
  checkFilterIsSelected,
}: catalogueSearchFiltersProps) => ({
  includeTableauWorksheets: checkFilterIsSelected(TableauSubTypes.TABLEAU_WORKSHEETS),
  includeTableauDashboards: checkFilterIsSelected(TableauSubTypes.TABLEAU_DASHBOARDS),
  includeTableauDatasources: checkFilterIsSelected(TableauSubTypes.TABLEAU_DATASOURCES),
});
