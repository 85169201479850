/* eslint-disable no-case-declarations */
import { catalogueFieldsTabProps } from '../types';
import { replaceNullWithEmptyString, replaceBooleanWithString } from '../../components/Catalog/SearchRoutes/CatalogItem/Fields';

export const catalogueFieldsTab = ({
  resultItem,
}: catalogueFieldsTabProps) => {
  switch (resultItem?.__typename) {
    case 'GQLCatalogueTableauWorksheetItem':
      return ({
        columnHeadings: [
          { id: 'dataSource', label: 'Data Source' },
          { id: 'name', label: 'Field name' },
          { id: 'description', label: 'Field description' },
          { id: 'folder', label: 'Folder' },
          { id: 'fieldType', label: 'Type' },
        ],
        data: resultItem?.datasourceFields?.map((field, i) => ({
          id: `${i}-${field.fieldId}`,
          dataSource: replaceNullWithEmptyString(field?.datasourceName),
          name: replaceNullWithEmptyString(field?.name),
          description: replaceNullWithEmptyString(field?.description),
          folder: replaceNullWithEmptyString(field?.folderName),
          fieldType: replaceNullWithEmptyString(field?.fieldType),
        })),
      });
    case 'GQLCatalogueTableauDashboardItem':
      return ({
        columnHeadings: [
          { id: 'name', label: 'Field name' },
          { id: 'description', label: 'Field description' },
          { id: 'owner', label: 'Owner' },
          { id: 'createdAt', label: 'Created at' },
          { id: 'updatedAt', label: 'Updated at' },
        ],
        data: resultItem?.referencedByMetrics?.map((field, i) => ({
          id: `${i}-${field.updatedAt}`,
          name: replaceNullWithEmptyString(field?.name),
          description: replaceNullWithEmptyString(field?.description),
          owner: replaceNullWithEmptyString(field?.ownerName),
          createdAt: replaceNullWithEmptyString(field.createdAt),
          updatedAt: replaceNullWithEmptyString(field.updatedAt),
        })),
      });
    case 'GQLCatalogueTableauDatasourceItem':
      return ({
        columnHeadings: [
          { id: 'database', label: 'Database' },
          { id: 'schema', label: 'Schema' },
          { id: 'table', label: 'Table' },
          { id: 'folder', label: 'Folder' },
          { id: 'name', label: 'Name' },
          { id: 'description', label: 'Description' },
          { id: 'isHidden', label: 'Is Hidden' },
        ],
        data: resultItem?.enrichedFields?.map((field, i) => ({
          id: `${i}-${field.fieldId}`,
          database: replaceNullWithEmptyString(field.databaseName),
          schema: replaceNullWithEmptyString(field.tableSchema),
          table: replaceNullWithEmptyString(field.tableName),
          folder: replaceNullWithEmptyString(field.folderName),
          name: replaceNullWithEmptyString(field.name),
          description: replaceNullWithEmptyString(field.description),
          isHidden: replaceBooleanWithString(field.isHidden),
        })),
      });
    default:
      return ({
        columnHeadings: [],
        data: [],
      });
  }
};
