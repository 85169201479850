import React, {
  FC, useState, useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import { BillingFrequencyOptions, Prices } from '@paradime-io/pragma-ui-kit/lib/components/PricingPlan';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import Spinner from '@paradime-io/pragma-ui-kit/lib/components/Spinner';
import Callout from '@paradime-io/pragma-ui-kit/lib/components/Callout';
import PlanCard from '@paradime-io/pragma-ui-kit/lib/components/PlanCard';
import Divider from '@paradime-io/pragma-ui-kit/lib/components/Divider';
import {
  GqlParadimeAccountType,
  ProductPrice,
} from '../../../client/generated/service';
import { genericObject } from '../../../utilis';
import { PlatformTab } from '../../Platform';
import { PricingProduct, usePricingPlans } from './hooks/usePricingPlans';
import { userHasPlatformPlansUpdateAccess } from '../../../utilis/PermissionsService';
import StripeSidebar from '../../Common/Libraries/Stripe';
import { RequestType, useUpgradeRequest } from '../../../utilis/useUpgradeRequest';

export interface HandlePlanChangeProps {
  product: PricingProduct,
  priceId: string,
  priceAmount?: number,
}

interface PricingPlanScreenProps {
  accessLevel: GqlParadimeAccountType,
}

const PricingPlanScreen: FC<PricingPlanScreenProps> = ({
  accessLevel,
}) => {
  const history = useHistory();
  const {
    justPricingPlans, justAddons, failureReason, allProducts,
  } = usePricingPlans();
  const { upgradeRequest } = useUpgradeRequest();
  const [isError, setIsError] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<PricingProduct>();
  const [selectedPrice, setSelectedPrice] = useState<{ id: string, amount?: number }>();
  const [sidebarTitle, setSidebarTitle] = useState<string>();
  const [sidebarSubtitle, setSidebarSubtitle] = useState<string>();

  const parseProductPrice = (prices?: ProductPrice[] | null): Prices => {
    const priceList: Prices = {};
    prices?.forEach((price: genericObject) => {
      const billingFrequency = (price.recurringInterval === 'year')
        ? BillingFrequencyOptions.YEARLY
        : BillingFrequencyOptions.MONTHLY;
      priceList[billingFrequency] = {
        id: price.stripeId,
        value: price.amount / 100,
      };
    });
    return priceList;
  };

  const handlePlanChange = ({
    product,
    priceId,
    priceAmount,
  }: HandlePlanChangeProps) => {
    if (product.current || product.bookACallUrl) {
      const bookACallUrl = product.bookACallUrl || 'https://cal.com/kaustav/paradime-support';
      upgradeRequest({ requestType: RequestType.PLAN_REQUEST, feature: product.name });
      window.open(bookACallUrl, '_blank');
    } else if (userHasPlatformPlansUpdateAccess(accessLevel)) {
      setSelectedPrice({ id: priceId, amount: priceAmount });
      setSelectedPlan(product);
    } else {
      upgradeRequest({ requestType: RequestType.PLAN_REQUEST, feature: product.name });
      history.push(`/platform/${PlatformTab.PLANS}/request-access`, { videoUrl: product.demoVideoUrl });
    }
  };

  useEffect(() => {
    setIsError(failureReason.length > 0);
  }, [failureReason]);

  if (isError) {
    return (
      <AutoLayout
        direction="vertical"
        padding="none"
        verticalGap="expanded"
        alignment="top-left"
      >
        <Callout
          icon="warning-sign"
          content={(
            <Typography tagName="span" type="body-bold-small" color="danger">
              We are having trouble fetching your plans details.
              <br />
              Please check back in a few minutes or reach out to us on support@paradime.io
              if you need urgent help.
            </Typography>
          )}
          color="danger"
          view="smooth"
          dense
        />
      </AutoLayout>
    );
  }

  if (justPricingPlans.length === 0) {
    return <Spinner />;
  }

  return (
    <>
      <StripeSidebar
        isVisible={!!selectedPlan}
        onCompletionRedirectUrl={`https://${window.location.host}/platform/${PlatformTab.PLANS}`}
        onHide={() => {
          setSelectedPlan(undefined);
          setSelectedPrice(undefined);
        }}
        purchaseSummary={[{
          description: selectedPlan?.name || '',
          price: selectedPrice?.amount || -1,
          isMonthly: true,
          isPricePerUser: selectedPlan?.pricedPerUser || false,
          numUsers: selectedPlan?.numChargeableUsers || 1,
        }]}
        sidebarTitle={sidebarTitle}
        sidebarSubtitle={sidebarSubtitle}
        prices={parseProductPrice(selectedPlan?.prices)}
        numberOfUsers={selectedPlan?.numChargeableUsers || 1}
        selectedPlan={selectedPlan}
        allProducts={allProducts}
      />
      <AutoLayout
        direction="horizontal"
        padding="none"
        distribution="packed"
        horizontalGap="dense"
        alignment="top"
        style={{
          maxWidth: '1044px',
          gridTemplateColumns: 'repeat(3, auto)',
          gridAutoFlow: 'dense',
          rowGap: '16px',
        }}
      >
        {justPricingPlans.map((pricingPlan) => {
          const prices = parseProductPrice(pricingPlan.prices);

          return (
            <PlanCard
              orientation="vertical"
              icon="lightning"
              title={pricingPlan.name}
              description={pricingPlan.description}
              isActive={pricingPlan.current}
              price={{
                amount: (
                  prices[BillingFrequencyOptions.YEARLY]?.value
                    ? prices[BillingFrequencyOptions.YEARLY]!.value / 12
                    : -1
                ),
                isPerUser: pricingPlan.pricedPerUser,
                isMonthly: true,
              }}
              features={{
                title: 'Includes:',
                features: pricingPlan?.features,
                seeAllFeaturesLink: 'https://paradime.io/pricing',
              }}
              onButtonClick={() => {
                setSidebarTitle(`Upgrade to ${pricingPlan.name} plan`);
                setSidebarSubtitle(pricingPlan.description);
                handlePlanChange({
                  product: pricingPlan,
                  priceId: prices[BillingFrequencyOptions.YEARLY]?.id || '',
                  priceAmount: prices[BillingFrequencyOptions.YEARLY]?.value,
                });
              }}
              needsSupportToEnable={!!pricingPlan.bookACallUrl}
            />
          );
        })}
      </AutoLayout>
      <AutoLayout
        direction="vertical"
        padding="none"
        verticalGap="none"
        distribution="packed"
        style={{ maxWidth: '1044px' }}
      >
        <Typography
          font="inter"
          type="h6"
        >
          Optional add-ons
        </Typography>
        <Divider />
      </AutoLayout>
      <AutoLayout
        direction="vertical"
        padding="none"
        distribution="packed"
        verticalGap="dense"
      >
        {justAddons.map((addon) => {
          const prices = parseProductPrice(addon.prices);

          return (
            <PlanCard
              orientation="horizontal"
              icon="lightning"
              title={addon.name}
              description={addon.description}
              isActive={addon.current}
              price={{
                amount: prices[BillingFrequencyOptions.YEARLY]?.value,
                isPerUser: addon.pricedPerUser,
                isMonthly: false,
              }}
              features={{
                title: 'Includes:',
                features: addon?.features,
                seeAllFeaturesLink: 'https://paradime.io/pricing',
              }}
              onButtonClick={() => handlePlanChange({
                product: addon,
                priceId: prices[BillingFrequencyOptions.MONTHLY]?.id || '',
                priceAmount: prices[BillingFrequencyOptions.MONTHLY]?.value,
              })}
              needsSupportToEnable={!!addon.bookACallUrl}
            />
          );
        })}
      </AutoLayout>
    </>
  );
};

export default PricingPlanScreen;
