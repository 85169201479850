import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import useListWorkspaces from './hooks/useListWorkspaces';
import useSetWorkspace from './hooks/useSetWorkspace';
import PageTitle, { PageTitles } from '../../PageTitle';
import WorkspaceHeader from './WorkspaceHeader';
import WorkspaceRowItem from './WorkspaceRowItem';
import BookDemo from '../../Common/Libraries/Cal';
import { companyStore, graphQLStore, userAuthStore } from '../../../stores';
import { useOnboardUser } from '../../../utilis/useOnboardUser';
import { useGetWorkspaceMetadata } from './hooks/useGetWorkspaceMetadata';

export const autoJoinSearchParamKey = 'auto_join';
export enum AutoJoinStatus {
  'IN_PROGRESS' = 'in_progress',
}

const Workspaces:FC = () => {
  const history = useHistory();

  const {
    isLoadingWorkspacesData,
    allWorkspacesList,
    canCreateWorkspaces: userCanCreateWorkspace,
  } = useListWorkspaces();
  const { workspaceToken: currentWorkspaceToken } = graphQLStore.getState();
  const { setCurrentWorkspace } = useSetWorkspace({});
  const { onboardUser } = useOnboardUser();
  const { workspaceMetadata, workspaceMetadataLoading } = useGetWorkspaceMetadata();

  const { companyPlan } = companyStore.getState();
  const hasPricingPlan = companyPlan?.hasPricingPlan || false;

  const availableAutoJoinWorkspaces = companyStore((state) => state.availableAutoJoinWorkspaces);
  const { isSandboxWorkspace, name: fullName } = userAuthStore((state) => state.currentUser);

  const url = new URL(window.location.href);
  const isAutoJoin = url.searchParams.get(autoJoinSearchParamKey) === AutoJoinStatus.IN_PROGRESS;

  const workspacesList = isAutoJoin
    ? availableAutoJoinWorkspaces.map((workspace) => ({
      ...workspace, isJoined: false, isSetupComplete: true,
    }))
    : allWorkspacesList;

  const canCreateWorkspaces = (
    userCanCreateWorkspace || isSandboxWorkspace
  ) && hasPricingPlan;

  const handleWorkspaceClick = (workspaceId: string, isJoined: boolean) => {
    if (!isJoined) {
      // If the user is not yet a member of the workspace;
      // Call addUserAutomatically with ID of the workspace
      onboardUser(workspaceId, true);
      return;
    }

    if (workspaceId === currentWorkspaceToken) {
      // If this is the current workspace, just redirect to home
      history.push('/home');
    } else {
      // Switch into a different workspace
      setCurrentWorkspace(workspaceId);
    }
  };

  return (
    <>
      <PageTitle title={PageTitles.WORKSPACE} />
      <AutoLayout
        direction="vertical"
        padding="none"
        verticalGap="normal"
        distribution="packed"
        alignment="top-left"
        width="extra-large"
      >
        <WorkspaceHeader
          isLoading={isLoadingWorkspacesData || workspaceMetadataLoading}
          canCreateWorkspaces={canCreateWorkspaces}
          hasPricingPlan={hasPricingPlan}
        />
        <AutoLayout
          direction="vertical"
          verticalGap="normal"
          distribution="packed"
          alignment="center"
          padding="very-dense"
          style={{ borderBottom: '1px solid var(--grey5)', paddingBottom: '24px' }}
        >
          {workspacesList.map(({
            uid, name, isJoined, isSetupComplete,
          }) => (
            <WorkspaceRowItem
              key={uid}
              name={name}
              description={workspaceMetadata
                ? `${workspaceMetadata[uid].userCount} user${workspaceMetadata[uid].userCount > 1 ? 's' : ''}, dbt v${workspaceMetadata[uid].dbtVersion}, Created on: ${workspaceMetadata[uid].createdDttm}`
                : undefined}
              onClick={() => handleWorkspaceClick(uid, isJoined)}
              isMember={isJoined}
              isCurrentWorkspace={uid === currentWorkspaceToken}
              isIncompleteWorkspace={!isSetupComplete}
            />
          ))}
        </AutoLayout>
        {
          workspacesList.length > 1 && (
            <Typography
              type="body-small"
              color="default"
              colorStep="100"
            >
              Contact us at
              {' '}
              <a
                style={{ display: 'inline-block', color: 'var(--deeplink-blue)' }}
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:support@paradime.io"
              >
                support@paradime.io
              </a>
              {' '}
              to delete a workspace.
            </Typography>
          )
        }
        {
          workspacesList.length === 1 && (
            <AutoLayout
              direction="vertical"
              distribution="packed"
              padding="none"
              verticalGap="normal"
              style={{ padding: '0 8px' }}
            >
              <Typography tagName="span" type="h6">
                Want a walkthrough? 🤩
              </Typography>
              <Typography
                tagName="span"
                type="caption"
                colorStep="50"
                style={{ paddingLeft: '18px' }}
              >
                {`Hi ${fullName.split(' ')[0]},`}
                <br />
                I am Kaustav, one of the co-founders of Paradime.
                <br />
                For a walkthrough of Paradime tailored to your needs,
                you can pick any slot in my calendar below.
                <br />
                <br />
                Looking forward to working with you on this,
                <br />
                <br />
                Regards,
                <br />
                Kaustav.
              </Typography>
              <BookDemo
                bookingLink="kaustav/paradime-intro"
                embedType="inline"
              />
            </AutoLayout>
          )
        }
      </AutoLayout>
    </>
  );
};

export default Workspaces;
