import { IconName } from '@blueprintjs/core';
import { catalogueNameAndIconProps } from '../types';

export const catalogueNameAndIcon = ({
  resultItem,
}: catalogueNameAndIconProps) => {
  switch (resultItem.__typename) {
    case 'GQLCatalogueDBTExposureItem':
      return { icon: 'new-grid-item' as IconName, name: resultItem.name };
    default:
      return { icon: 'error' as IconName, name: 'error' };
  }
};
