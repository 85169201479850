import React, { FC } from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import Card from '@paradime-io/pragma-ui-kit/lib/components/Card';
import Spinner from '@paradime-io/pragma-ui-kit/lib/components/Spinner';
import CommandNode from './CommandNode';
import CommandsFlow from './CommandsFlow';
import { CommandType } from '../../Insights';
import { useGetCommandNodes } from '../../../hooks/useGetCommandNodes';

const CommandNodeType = { CommandNode };

export interface DAGProps {
  commands?: (CommandType | null)[] | null,
}

const DAG: FC<DAGProps> = ({
  commands,
}) => {
  const {
    nodes: commandNodes,
    edges: commandEdges,
    isLoading,
  } = useGetCommandNodes(commands);

  return (
    <Card
      tabIndex={-1}
      padding="very-dense"
      style={{ padding: '0 0 10px 0', backgroundColor: 'var(--grey0)', minHeight: '400px' }}
    >
      <AutoLayout
        direction="vertical"
        padding="none"
        distribution="packed"
        verticalGap="none"
      >
        <Typography
          tagName="span"
          type="overline-small"
          style={{ padding: '10px 8px 10px 8px', borderBottom: '1px solid var(--grey30)' }}
        >
          DAG
        </Typography>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'var(--white)',
          minHeight: '300px',
        }}
        >
          <div style={{ height: '400px', width: '100%' }}>
            {
              isLoading ? (
                <Spinner width={16} height={16} thin />
              ) : (
                <CommandsFlow
                  nodes={commandNodes}
                  edges={commandEdges}
                  nodeType={CommandNodeType}
                />
              )
            }
          </div>
        </div>
      </AutoLayout>
    </Card>
  );
};

export default DAG;
