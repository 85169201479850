import { lineageFilterNodeProps } from '../types';
import { IntegrationAPIItemHandlerAcceptedTypes } from '.';

export const lineageFilterNodes = ({ integration }: lineageFilterNodeProps) => {
  switch (integration) {
    case IntegrationAPIItemHandlerAcceptedTypes.POWER_BI:
      return ({
        title: 'POWER BI NODES',
        items: [
          { label: ['native:powerbi:datasource', 'Datasource'], checked: true },
          { label: ['native:powerbi:dashboard', 'Dashboard'], checked: true },
          { label: ['native:powerbi:chart', 'Chart'], checked: true },
        ],
      });
    case IntegrationAPIItemHandlerAcceptedTypes.THOUGHTSPOT:
      return ({
        title: 'THOUGHTSPOT NODES',
        items: [
          { label: ['native:thoughtspot:worksheet', 'Worksheet'], checked: true },
          { label: ['native:thoughtspot:visualization', 'Visualization'], checked: true },
          { label: ['native:thoughtspot:answer', 'Answer'], checked: true },
          { label: ['native:thoughtspot:liveboard', 'Liveboard'], checked: true },
        ],
      });
    case IntegrationAPIItemHandlerAcceptedTypes.SIGMA:
      return ({
        title: 'SIGMA NODES',
        items: [
          { label: ['native:sigma:element', 'Element'], checked: true },
          { label: ['native:sigma:page', 'Page'], checked: true },
          { label: ['native:sigma:workbook', 'Workbook'], checked: true },
        ],
      });
    default:
      return { title: '', items: [] };
  }
};
