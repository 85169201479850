import { useEffect, createContext, useState } from 'react';
import { EnvName } from '../../../../client/service.graphql';
import {
  useGetOrganisationDbConfigsLazyQuery,
  useGetDwhProductionConfigsLazyQuery,
  useGetDwhCostConfigLazyQuery,
  useGetDwhMetadataConfigLazyQuery,
  GetDwhProductionConfigsQuery,
} from '../../../../client/generated/service';
import { WarehouseEnv } from '../../../Common/Warehouses/utils';
import { companyStore } from '../../../../stores';

export const ConfigurationsContext = createContext({
  configurations: undefined as GetDwhProductionConfigsQuery | undefined,
});

const useGetConnectionsConfig = () => {
  const [isProdWarehouseConnected, setIsProdWarehouseConnected] = useState(false);
  const [isCostWarehouseConnected, setIsCostWarehouseConnected] = useState(false);

  const setIsProdConnectedFromStore = companyStore((s) => s.setIsProdWarehouseConnected);

  const [
    getProductionConfig,
    {
      loading: productionLoading,
      data: productionConfigData,
    },
  ] = useGetDwhProductionConfigsLazyQuery({
    variables: {
      productionEnvName: EnvName.PRODUCTION,
    },
  });

  const [
    getDevelopmentConfig,
    {
      loading: developmentLoading,
      data: developmentConfigData,
    },
  ] = useGetOrganisationDbConfigsLazyQuery();

  const [
    getCostsConfig,
    {
      loading: costsLoading,
      data: costsConfigData,
    },
  ] = useGetDwhCostConfigLazyQuery();

  const [
    getMetadataConfig,
    {
      loading: metadataLoading,
      data: metadataConfigData,
    },
  ] = useGetDwhMetadataConfigLazyQuery();

  useEffect(() => {
    getProductionConfig();
    getDevelopmentConfig();
    getCostsConfig();
    getMetadataConfig();
  }, []);

  const ReloadFunction = {
    [WarehouseEnv.COSTS]: getCostsConfig,
    [WarehouseEnv.DEV]: getDevelopmentConfig,
    [WarehouseEnv.PROD]: getProductionConfig,
    [WarehouseEnv.METADATA]: getMetadataConfig,
  };

  useEffect(() => { // @ts-ignore
    const reloadCallback = (e: MessageEvent) => {
      const { data: { type, payload } } = e;

      if (type === 'reload-connections') {
        ReloadFunction[payload as WarehouseEnv]();
      }
    };
    window.addEventListener('message', reloadCallback);

    return () => window.removeEventListener('message', reloadCallback, false);
  }, []);

  useEffect(() => {
    const isConnected = (productionConfigData?.getDwhProductionConfigs.ok
      && productionConfigData.getDwhProductionConfigs.configurations.length > 0)
      || false;

    setIsProdWarehouseConnected(isConnected);
    setIsProdConnectedFromStore(isConnected);
  }, [productionConfigData]);

  useEffect(() => {
    if (
      costsConfigData?.getDwhCostConfig.databaseType
      && costsConfigData?.getDwhCostConfig.databaseType !== 'unknown'
    ) {
      setIsCostWarehouseConnected(true);
    }
  }, [costsConfigData]);

  return {
    productionLoading,
    productionConfigData,
    getProductionConfig,
    isProdWarehouseConnected,
    developmentLoading,
    developmentConfigData,
    getDevelopmentConfig,
    costsLoading,
    costsConfigData,
    getCostsConfig,
    isCostWarehouseConnected,
    metadataLoading,
    metadataConfigData,
    getMetadataConfig,
  };
};

export default useGetConnectionsConfig;
