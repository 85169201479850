/* eslint-disable no-console */
/* eslint-disable no-sequences */
import {
  ApolloClient,
  InMemoryCache,
  split,
  ApolloLink,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';

interface useClientProps {
  editorGraphqlUrl: string,
  serviceGraphqlUrl: string,
  controlPlaneUrl: string,
  jwtToken: string,
  dataGraphqlUrl: string,
  dbGraphqlUrl: string,
  magnetarUrl: string,
}

const createClient = ({
  editorGraphqlUrl,
  serviceGraphqlUrl,
  controlPlaneUrl,
  jwtToken,
  dataGraphqlUrl,
  dbGraphqlUrl,
  magnetarUrl,
}: useClientProps) => {
  const authorization = `Bearer ${jwtToken}`;

  const createApolloLinkTypeSafe = (
    args: createUploadLink.UploadLinkOptions,
  ) => createUploadLink(args) as unknown as ApolloLink;

  const httpLink = split(
    (operation) => operation.getContext().operationType === 'editor',
    createApolloLinkTypeSafe({ uri: editorGraphqlUrl, headers: { authorization }, credentials: 'include' }),
    split(
      (operation) => operation.getContext().operationType === 'service',
      createApolloLinkTypeSafe({ uri: serviceGraphqlUrl, headers: { authorization }, credentials: 'include' }),
      split(
        (operation) => operation.getContext().operationType === 'service-dataEndpoint',
        createApolloLinkTypeSafe({ uri: dataGraphqlUrl, headers: { authorization }, credentials: 'include' }),
        split(
          (operation) => operation.getContext().operationType === 'db',
          createApolloLinkTypeSafe({ uri: dbGraphqlUrl, headers: { authorization }, credentials: 'include' }),
          split(
            (operation) => operation.getContext().operationType === 'magnetar',
            createApolloLinkTypeSafe({ uri: magnetarUrl, headers: { authorization }, credentials: 'include' }),
            split(
              (operation) => Boolean(operation.getContext().customEndpoint),
              createApolloLinkTypeSafe({ uri: (e) => e.getContext().customEndpoint, headers: { authorization }, credentials: 'include' }),
              createApolloLinkTypeSafe({ uri: controlPlaneUrl, headers: { authorization }, credentials: 'include' }),
            ),
          ),
        ),
      ),
    ),
  );

  const wsLink = new WebSocketLink(new SubscriptionClient(dataGraphqlUrl.replace('http', 'ws'), {
    connectionParams: {
      authorization,
    },
  }));

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition'
        && definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink,
  );

  const postSentryError = (payload: { [key: string]: any }) => {
    window.postMessage({
      type: 'sentry-capture-exception',
      payload: JSON.stringify(payload),
    },
    '*');
  };

  const errorLink = onError(({
    graphQLErrors, networkError, forward, operation, response,
  }) => {
    const responseData = forward(operation);
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        postSentryError({
          message,
          locations,
          path,
          response,
          operation,
          responseData,
          codeArea: 'Platform',
        });
        console.debug(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, codeArea: Platform`,
        );
      });
    }

    if (networkError) {
      postSentryError({ networkError, responseData });
      console.debug(`[Network error]: ${networkError}, codeArea: Platform`);
    }
  });

  const responseHandlerLink = new ApolloLink((operation, forward) => {
    /** add initial time before sending the query */
    operation.setContext({ ...operation.getContext(), initialPerformance: performance.now() });
    return forward(operation)
      .map((response) => {
        const { initialPerformance } = operation.getContext();
        const latency = (performance.now() - initialPerformance).toFixed(2);

        /** get the data inside the response, ignoring __type */
        const keys = Object.keys(response.data || {});
        const [responseKey] = keys.filter((key) => !key.includes('__'));

        /** if returned data has ok = false call sentry */
        if (response.data) {
          const returnedData = response.data[responseKey];
          if (returnedData?.ok === false) {
            postSentryError({
              returnedData,
              response,
              operation,
              forward,
              codeArea: 'Platform',
            });
          }
        }

        /** this logs a round trip from initial
         * call from the frontend to the data
         * actually returning from the backend
         */
        window.analytics.track('api:gql_call', {
          operation: operation.operationName,
          latency,
        });

        return response;
      });
  });

  return new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([splitLink, errorLink, responseHandlerLink]),
    headers: {
      authorization,
    },
    credentials: 'include',
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      mutate: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
    },
    connectToDevTools: true,
  });
};

export default createClient;

export declare namespace queryTypes {
  export type accountType = 'admin' | 'developer' | 'business'
  export type databaseType = 'unknown' | 'bigquery' | 'redshift' | 'snowflake'
}
