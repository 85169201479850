import { GqlParadimeAccountType } from '../../client/generated/service';

type AccountTypes = Omit<GqlParadimeAccountType, 'Unknown' | 'ExtensionBusiness'| 'ExtensionAdmin'>;

type RoleData = { text: string; subtitle: string; };

type RolesWithSubtitle = {
  [key in AccountTypes as string]: RoleData;
};

export const useGetRolesDropdownData = (accessLevels: GqlParadimeAccountType[]) => {
  const ROLES_WITH_SUBTITLE: RolesWithSubtitle = {
    [GqlParadimeAccountType.Admin]: {
      text: 'Admin',
      subtitle: 'Full Paradime access: Develop in IDE, create Bolt schedules, workspaces and manage settings.',
    },
    [GqlParadimeAccountType.Developer]: {
      text: 'Developer',
      subtitle: 'Unrestricted access to Paradime Apps, develop using IDE and create Bolt schedules. Cannot create workspaces or manage settings.',
    },
    [GqlParadimeAccountType.Business]: {
      text: 'Business',
      subtitle: 'Read-only access to the Paradime workspace.',
    },
    [GqlParadimeAccountType.Analyst]: {
      text: 'Analyst',
      subtitle: 'Unrestricted access to Paradime Apps, develop using IDE. Cannot create workspaces, Bolt schedules or manage setting.',
    },
    [GqlParadimeAccountType.WorkspaceSettingsAdmin]: {
      text: 'Workspace Settings Admin',
      subtitle: 'Manage all workspaces settings and create workspaces. Cannot access the IDE or other Paradime Apps. ',
    },
    [GqlParadimeAccountType.WorkspaceAdmin]: {
      text: 'Workspace Admin',
      subtitle: 'Unrestricted access to the Paradime workspace. Develop using IDE, create Bolt schedules, create and manage workspaces.',
    },
    [GqlParadimeAccountType.BillingAdmin]: {
      text: 'Billing Admin',
      subtitle: 'Can manage billing and plans in the Paradime account.',
    },
    [GqlParadimeAccountType.SecurityAdmin]: {
      text: 'Security Admin',
      subtitle: 'Can access Audit logs and security features.',
    },
    [GqlParadimeAccountType.SandboxUser]: {
      text: 'Sandbox User',
      subtitle: 'Can access the the sandbox workspace, develop using the IDE',
    },
  };

  const requiredRoles: RoleData[] = [];

  accessLevels.forEach((accessLevel) => {
    const roleData = ROLES_WITH_SUBTITLE[accessLevel];
    if (roleData) {
      requiredRoles.push(roleData);
    } else { // eslint-disable-next-line no-console
      console.info(`The role ${accessLevel} is missing from the userRolesDropdownData list. Please add it`);
      requiredRoles.push({ text: 'No roles found', subtitle: '' });
    }
  });

  return requiredRoles;
};
