import { useAuth0 } from '@auth0/auth0-react';
import React, {
  FC,
  ReactNode,
  useEffect,
} from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Loader } from '@paradime-io/pragma-ui-kit';
import { graphQLStore, userAuthStore } from '../stores';
import useUpdateJwtTokenPeriodically from './hooks/useUpdateJwtTokenPeriodically';

const LoginRedirect: React.FC = () => {
  const { loginWithRedirect } = useAuth0();
  const clusterConfigFromStore = graphQLStore((state) => state.clusterConfig);
  const { goBackTo } = userAuthStore.getState();

  const { location: { href } } = window;
  const url = new URL(href);
  const userToken = url.searchParams.get('user_token');
  const company = url.searchParams.get('company');

  const type = userToken && company ? 'signup' : 'login';
  const searchParams = `${window.location.search}${window.location.search.length > 0 ? '&' : '?'}is_signup=${type === 'signup'}`;

  const returnTo = goBackTo;
  const redirectPath = `/auth-success${searchParams}`;
  const redirectUri = `https://${clusterConfigFromStore!.appHost}${redirectPath}`;

  useEffect(() => {
    loginWithRedirect({
      appState: { returnTo },
      redirectUri,
    });
  }, []);
  return <Loader />;
};

interface AuthStateManagerProps {
  children: ReactNode
  userHasCompany: boolean
}
const AuthStateManager: FC<AuthStateManagerProps> = ({ children }) => {
  const jwtToken = graphQLStore((state) => state.jwtToken);
  const { pathname } = useLocation();
  const history = useHistory();
  const isAuthSuccess = pathname === '/auth-success';

  const { location: { href } } = window;
  const url = new URL(href);
  const isUnauthorized = url.searchParams.get('error') === 'unauthorized';
  const isAccessDenied = url.searchParams.get('error') === 'access_denied';

  useUpdateJwtTokenPeriodically();

  if (!jwtToken) {
    if (isAuthSuccess) {
      if (isUnauthorized) {
        history.push('/');
      }

      if (isAccessDenied) {
        history.push('/access-denied');
      }

      return <Loader />;
    }
    if (jwtToken === undefined) return <Loader />;
    return <LoginRedirect />;
  }

  return <>{children}</>;
};

export default AuthStateManager;
