import { History } from 'history';
import commandBarCallbacks from './callbacks';
import { triggerToast } from '../../../../utilis';

const EditorCommands = [
  {
    name: 'Create new terminal',
    id: 'terminal:new',
  },
  {
    name: 'Split terminal',
    id: 'terminal:split',
  },
  {
    name: 'Open all active terminals',
    id: 'workbench.action.showAllTerminals',
  },
  {
    name: 'Find in files',
    id: 'search-in-workspace.open',
  },
  {
    name: 'Replace in files',
    id: 'search-in-workspace.replace',
  },
  {
    name: 'Find in folder ...',
    id: 'search-in-workspace.in-folder',
  },
  {
    name: 'Cancel search',
    id: 'search-in-workspace.cancel',
  },
  {
    name: 'Expand all search results',
    id: 'search-in-workspace.expand-all',
  },
  {
    name: 'Collapse all search results',
    id: 'search-in-workspace.collapse-all',
  },
];

const openEditorCommand = (args: any) => {
  const codeEditorIframe = document.getElementById('codeEditorIframe') as HTMLIFrameElement;
  codeEditorIframe?.contentWindow?.postMessage({
    type: 'open-editor-command-shortcut',
    payload: {
      commandId: args.record.id,
    },
  }, '*');
};

interface AddEditorCommandsProps {
  restartEditor: () => Promise<boolean>,
  history: History,
}

const addEditorCommands = ({ restartEditor, history }: AddEditorCommandsProps) => {
  // callback to fire Editor command
  window.CommandBar.addRecords('editor_commands', EditorCommands, { labelKey: 'name' });
  window.CommandBar.addCallback(
    commandBarCallbacks.OPEN_EDITOR_COMMAND,
    openEditorCommand,
  );
  window.CommandBar.addRecordAction(
    'editor_commands',
    {
      name: 'open_command_in_editor',
      text: 'Run in Code IDE',
      template: {
        type: 'callback',
        value: commandBarCallbacks.OPEN_EDITOR_COMMAND,
      },
      category: 'App',
      explanation: 'Run a shortcut in the Code IDE',
      tags: ['dbt', 'ide', 'code'],
      availability_rules: [{
        type: 'url',
        operator: 'includes',
        value: 'editor',
      }],
    },
    true,
    true,
  );

  // toggle git panel
  const handleToggleGitPanel = () => {
    openEditorCommand({
      record: {
        name: 'Toggle Git Panel',
        id: 'scmView:toggle',
      },
    });
  };
  window.CommandBar.addCallback(
    commandBarCallbacks.TOGGLE_GIT_PANEL,
    handleToggleGitPanel,
  );

  // run dbt checks
  const handleRunDbtChecks = () => {
    openEditorCommand({
      record: {
        name: 'Check dbt is working',
        id: 'check.dbt.setup',
      },
    });
  };
  window.CommandBar.addCallback(
    commandBarCallbacks.RUN_DBT_CHECKS,
    handleRunDbtChecks,
  );

  window.CommandBar.addCommand({
    name: 'open_editor',
    text: 'Open Code IDE',
    template: {
      type: 'link',
      value: '/editor',
      operation: 'router',
    },
    category: 'App',
    explanation: 'Build and develop dbt models',
    tags: ['dbt', 'model', 'ide'],
  });

  const handleRestartEditor = () => (
    restartEditor().then((ok) => {
      if (ok) {
        triggerToast({
          header: 'Restarting the editor',
          type: 'positive',
          message: 'We are about to restart your Code IDE - this will take a few seconds. Hang tight!',
        });
        setTimeout(() => {
          history.push('/home');
          window.location.reload();
        }, 3000);
      } else {
        triggerToast({
          header: 'Restarting the editor',
          type: 'attention',
          message: 'We couldn\'t restart the Code IDE. Please try again or contact support.',
        });
      }
    })
  );

  window.CommandBar.addCallback(
    commandBarCallbacks.RESTART_EDITOR_COMMAND,
    () => handleRestartEditor(),
  );

  window.CommandBar.addCommand({
    name: 'restart_code_ide',
    text: 'Restart Code IDE',
    template: {
      type: 'callback',
      value: commandBarCallbacks.RESTART_EDITOR_COMMAND,
    },
    category: 'App',
    explanation: 'Restarts Code IDE in the background',
    tags: ['ide'],
  });
};

export default addEditorCommands;
