/* eslint-disable no-param-reassign */
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { WarehouseEnv } from '../../../Warehouses/utils';
import { useGetJwtToken, checkNoCasesLeft } from '../../../../../utilis';
import { EnvName } from '../../../../../client/service.graphql';
import {
  useDwhCheckSnowflakeLazyQuery,
  useCompanyOnboardingSnowflakeMutation,
  useCheckProfileAddSnowflakeLazyQuery,
  useProfileAddSnowflakeMutation,
  useSetupDwhProductionSnowflakeMutation,
  useSetupDwhSnowflakeMutation,
  useGetProfileLazyQuery,
  useSetupDwhCostSnowflakeMutation,
} from '../../../../../client/generated/service';
import useOnboardIfChecked, { postConnectionCheckTestProps } from '../hooks/useOnboardIfChecked';
import { useHandleClosableWindow } from '../../../../hooks/useHandleClosableWindow';
import {
  getButtonDefinition,
  BUTTON_STATE_TEXT,
  onSubmitButtonClickProps,
  getInitialButtonText,
  useCommonConfigProps,
  isEditButtonShown,
} from '../CommonConfig';
import { WareHouseModalSource } from '../..';
import { companyStore } from '../../../../../stores';
import { initialFormDataType } from '../../useGetInitialWarehouseData';

export interface SnowflakeFormDataType {
  connectionName: string,
  account: string,
  role: string,
  database: string,
  warehouse: string,
  username?: string,
  password?: string,
  schema: string,
  targetName: string,
  threads: number,
  clientId?: string,
  clientSecret?: string,
  useSso?: boolean,
  authMethod: AuthMethod,
  useKeyPairAuth?: boolean,
  privateKey?: string,
  privateKeyPassphrase?: string,
}

export enum AuthMethod {
  'USERNAMEPASSWORD' = 'Username & Password',
  'OAUTH' = 'Snowflake OAuth',
  'KEY_PAIR' = 'Key Pair',
}

export const getDefaultEmptyAuthValues = (
  authType: AuthMethod,
  formData: SnowflakeFormDataType,
): SnowflakeFormDataType | undefined => {
  delete formData?.username;
  delete formData?.password;
  delete formData?.clientId;
  delete formData?.clientSecret;

  switch (authType) {
    case AuthMethod.OAUTH:
      return {
        ...formData,
        authMethod: AuthMethod.OAUTH,
        useSso: true,
        useKeyPairAuth: false,
        clientId: undefined,
        clientSecret: undefined,
        username: '',
      };
    case AuthMethod.USERNAMEPASSWORD:
      return {
        ...formData,
        authMethod: AuthMethod.USERNAMEPASSWORD,
        useSso: false,
        useKeyPairAuth: false,
        username: undefined,
        password: undefined,
        clientId: undefined,
        clientSecret: undefined,
      };
    case AuthMethod.KEY_PAIR:
      return {
        ...formData,
        authMethod: AuthMethod.KEY_PAIR,
        useSso: false,
        useKeyPairAuth: true,
        clientId: undefined,
        clientSecret: undefined,
        username: undefined,
        privateKey: undefined,
      };
    default:
      checkNoCasesLeft(authType);
      return undefined;
  }
};

export interface openOAuthTabProps {
  snowflakeAccount: string,
  clientId: string,
  credentialId: string,
  role: string,
}

export const useCommonConfig = ({
  formData,
  env,
  source,
  isEditable,
  isAdditionalUser,
  isNewConnection,
}: useCommonConfigProps) => {
  const [onboarded, setOnboarded] = useState<boolean>();
  const [errorMessage, setErrorMessage] = useState('');
  const [currentButtonText, setCurrentButtonText] = useState(
    getInitialButtonText({
      source,
      isEditable,
      isAdditionalUser,
    }),
  );
  const [checkInProgress, setCheckInProgress] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isEditingSecret, setIsEditingSecret] = useState(
    isAdditionalUser
      ? false
      : !isEditButtonShown({ source, isNewConnection, env }),
  );

  const snowflakeCallbackUrl = companyStore((state) => state.snowflakeCallbackUrl);

  const history = useHistory();

  const [getUserProfile, { data: userProfileData }] = useGetProfileLazyQuery();

  useEffect(() => {
    getUserProfile();
  }, []);

  const getAuthMethodDropdownValue = () => {
    if ((formData as SnowflakeFormDataType)?.clientId) {
      return AuthMethod.OAUTH;
    }
    if ((formData as SnowflakeFormDataType)?.useKeyPairAuth) {
      return AuthMethod.KEY_PAIR;
    }
    return AuthMethod.USERNAMEPASSWORD;
  };

  const [selectedAuthMethod, setSelectedAuthMethod] = useState(
    getAuthMethodDropdownValue(),
  );

  const { getToken } = useGetJwtToken();
  const { openWindow } = useHandleClosableWindow();

  const encodeUserIdFromJwtToken = async (jwtToken: string) => {
    const { sub } = JSON.parse(jwtToken);
    const encoder = new TextEncoder();
    const data = encoder.encode(sub);
    const hashArrayBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashArrayBuffer));
    const hash = hashArray
      .map((bytes) => bytes.toString(16).padStart(2, '0'))
      .join('');
    return hash;
  };

  const openOAuthTab = async ({
    snowflakeAccount,
    clientId,
    credentialId,
    role,
  }: openOAuthTabProps) => {
    let {
      location: { host },
    } = window;
    if (
      host.includes('localhost')
      || ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(host[0])
    ) {
      // Overwrite the host URL if it is localhost or an ephemeral environment
      host = 'app.dev.paradime.io';
    }

    const jwtToken = await getToken();
    const jwtChunk = jwtToken.split('.')[1];
    // The jwt token was encoded using modified b64 for url's
    // '+' was replaced with '-' and '/' was repalced with '_'
    // atob() expects a standard b64 string, so undo these changes
    // https://en.wikipedia.org/wiki/Base64#URL_applications
    const jwtWithoutUnderscores = jwtChunk.replace(/_/g, '/');
    const jwtWithoutHyphens = jwtWithoutUnderscores.replace(/-/g, '+');
    const decodedJwtToken = atob(jwtWithoutHyphens);

    const baseUrl = `https://${snowflakeAccount.replace('.privatelink', '')}.snowflakecomputing.com/oauth/authorize`;
    const encodedClientId = encodeURIComponent(clientId);

    const userId = await encodeUserIdFromJwtToken(decodedJwtToken);
    const state = {
      'credential-id': credentialId,
      'user-id': userId,
      'company-token': userProfileData?.userIdentity?.userInformation?.companyToken,
      role,
    };
    if (Object.keys(state).length !== 4) {
      console.error(`One of credential id, user id, company token or role is missing: ${JSON.stringify(state)}`); // eslint-disable-line no-console
    }
    const encodedState = btoa(JSON.stringify(state));
    const scope = `refresh_token session:role:${role}`;

    const oAuthUrl = `${baseUrl}?client_id=${encodedClientId}&display=popup&redirect_uri=${snowflakeCallbackUrl}&response_type=code&scope=${scope}&state=${encodedState}=`;

    openWindow({
      url: oAuthUrl,
      launchedFrom: 'snowflakeWarehouse',
    });
  };

  const callOpenOAuthTab = ({ credentialId }: postConnectionCheckTestProps) => (
    openOAuthTab({
      snowflakeAccount: (formData as SnowflakeFormDataType).account,
      clientId: (formData as SnowflakeFormDataType).clientId || '',
      credentialId: credentialId!,
      role: (formData as SnowflakeFormDataType).role,
    })
  );

  const postConnectionCheckTest = (
    selectedAuthMethod === AuthMethod.OAUTH
      ? callOpenOAuthTab
      : undefined
  );

  const getSetCheckInProgress = selectedAuthMethod !== AuthMethod.OAUTH && setCheckInProgress;

  const { checkQueryFunction: checkOnboardSnowflake } = useOnboardIfChecked({ // @ts-ignore
    checkQuery: useDwhCheckSnowflakeLazyQuery,
    triggerQuery: useCompanyOnboardingSnowflakeMutation,
    setErrorMessage,
    setOnboarded,
    variables: { username: '', ...formData },
    setIsChecked,
    setCheckInProgress: getSetCheckInProgress,
    postConnectionCheckTest,
  });

  const { checkQueryFunction: checkUpdateSnowflake } = useOnboardIfChecked({ // @ts-ignore
    checkQuery: useDwhCheckSnowflakeLazyQuery,
    triggerQuery: useSetupDwhSnowflakeMutation,
    setErrorMessage,
    setOnboarded,
    variables: { username: '', ...formData },
    setIsChecked,
    setCheckInProgress: getSetCheckInProgress,
    postConnectionCheckTest,
  });

  const { checkQueryFunction: checkAddSnowflake } = useOnboardIfChecked({
    checkQuery: useCheckProfileAddSnowflakeLazyQuery,
    triggerQuery: useProfileAddSnowflakeMutation,
    setErrorMessage,
    setOnboarded,
    variables: { username: '', ...formData },
    setIsChecked,
    setCheckInProgress: getSetCheckInProgress,
    postConnectionCheckTest,
  });

  const {
    checkQueryFunction: onboardProductionSnowflake,
  } = useOnboardIfChecked({
    checkQuery: useDwhCheckSnowflakeLazyQuery, // @ts-ignore
    triggerQuery: useSetupDwhProductionSnowflakeMutation,
    setErrorMessage,
    setOnboarded,
    variables: { ...formData, productionEnvName: EnvName.PRODUCTION },
    setIsChecked,
    setCheckInProgress,
  });

  const { checkQueryFunction: onboardingCostFunction } = useOnboardIfChecked({ // @ts-ignore
    checkQuery: useDwhCheckSnowflakeLazyQuery, // @ts-ignore
    triggerQuery: useSetupDwhCostSnowflakeMutation,
    setErrorMessage,
    setOnboarded,
    variables: formData as { [key: string]: any },
    setIsChecked,
    setCheckInProgress,
  });

  useEffect(() => {
    const handleSnowAuthSuccess = (event: MessageEvent) => {
      const { type } = event.data;
      if (type === 'snowflake-auth-success') {
        setOnboarded(true);
      }

      if (type === 'snowflake-auth-failed') {
        setErrorMessage(
          'Paradime was not able to connect to your Snowflake database. Check your database credentials and try again.',
        );
        setOnboarded(false);
      }
    };
    window.addEventListener('message', handleSnowAuthSuccess);

    return () => window.removeEventListener('message', handleSnowAuthSuccess);
  }, []);

  const testTheConnection = (
    variables: initialFormDataType,
    credentialId: onSubmitButtonClickProps['credentialId'],
  ) => {
    if (isAdditionalUser) {
      checkAddSnowflake({
        variables: {
          username: '',
          ...variables,
          credentialId: credentialId!,
        },
      });
    } else {
      switch (env) {
        case WarehouseEnv.DEV:
          if (source === WareHouseModalSource.ACCOUNT_SETTINGS) {
            checkUpdateSnowflake({
              variables: {
                username: '',
                ...variables,
              },
            });
          } else {
            checkOnboardSnowflake({
              variables: {
                username: '',
                ...variables,
              },
            });
          }
          break;
        case WarehouseEnv.PROD:
          onboardProductionSnowflake({
            variables: {
              ...variables,
              productionEnvName: EnvName.PRODUCTION,
            },
          });
          break;
        case WarehouseEnv.COSTS:
          onboardingCostFunction({ variables });
          break;

        default:
          break;
      }
    }
    return null;
  };

  const submitButtonDetails = getButtonDefinition({
    state: currentButtonText,
    isAdditionalUser,
    testTheConnection,
    setCurrentButtonText,
    env,
    source,
    isEditable,
    history,
  });

  useEffect(() => {
    if (isChecked && !checkInProgress && selectedAuthMethod !== AuthMethod.OAUTH) {
      if (onboarded) {
        if (submitButtonDetails?.nextState) {
          setCurrentButtonText(submitButtonDetails.nextState.success);
        }
      } else {
        setCurrentButtonText(
          submitButtonDetails?.nextState?.failure || ('' as BUTTON_STATE_TEXT),
        );
      }
    }
  }, [onboarded, submitButtonDetails, checkInProgress]);

  useEffect(() => {
    if (onboarded
        && selectedAuthMethod === AuthMethod.OAUTH
        && submitButtonDetails?.nextState?.success) {
      setCurrentButtonText(submitButtonDetails.nextState.success);
    }
  }, [onboarded, selectedAuthMethod]);

  return {
    onboarded,
    selectedAuthMethod,
    setSelectedAuthMethod,
    errorMessage,
    submitButtonText: submitButtonDetails?.submitButtonText,
    onSubmitButtonClick: submitButtonDetails?.onSubmitButtonClick,
    dataIsBeingChecked: checkInProgress,
    showEditButton: isEditButtonShown({ source, isNewConnection, env }),
    isEditingSecret,
    setIsEditingSecret,
  };
};
