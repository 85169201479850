import { catalogueDependenciesTabProps } from '../types';

export const catalogueDependenciesTab = ({
  resultItem,
}: catalogueDependenciesTabProps) => {
  switch (resultItem?.__typename) {
    case 'GQLCatalogueDBTExposureItem':
      return {
        sources: resultItem?.sources || [],
        nodeId: resultItem?.lineageRef?.nodeId,
      };
    default:
      return { sources: [] };
  }
};
