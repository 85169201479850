/* eslint-disable no-restricted-globals */
import React, { useEffect } from 'react';
import { Typography } from '@paradime-io/pragma-ui-kit';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { AppLocalStorageKeys } from '@paradime/common/lib/common/localStorageKeys';
import { triggerAlert } from '../../../utilis';
import { alertStore, graphQLStore } from '../../../stores';
import {
  useGetGithubAppUrlLazyQuery,
} from '../../../client/generated/service';

const useHandleGithubUserOAuth = () => {
  const [getGithubAppUrl, {
    data: githubData,
  }] = useGetGithubAppUrlLazyQuery();

  const apolloClientIsAuthorized = graphQLStore((state) => state.apolloClientIsAuthorized);
  const { companyToken } = graphQLStore.getState();

  const userGithubOauthAlreadyClosed = window.localStorage.getItem(
    AppLocalStorageKeys.USER_GITHUB_OAUTH_POPUP_CLOSED,
  ) || 'false';

  useEffect(() => {
    // Only launch the popup if user has NOT closed it before.
    // Also, we have to wait for the company-specific apollo client to be ready before
    // making the request.
    if (companyToken && apolloClientIsAuthorized && userGithubOauthAlreadyClosed !== 'true') {
      getGithubAppUrl();
    }
  }, [apolloClientIsAuthorized, userGithubOauthAlreadyClosed, companyToken]);

  useEffect(() => {
    if (githubData?.getGithubAppUrl?.shouldRequestUserOauth) {
      // Trigger the pop-up
      launchGithubUserOauthAlert(githubData?.getGithubAppUrl?.userOauthUrl);
    }
  }, [githubData]);

  const handleClose = () => {
    window.localStorage.setItem(
      AppLocalStorageKeys.USER_GITHUB_OAUTH_POPUP_CLOSED, 'true',
    );
  };

  const launchGithubUserOauthAlert = (userGithubOauthUrl: string) => {
    const { closeAlert } = alertStore.getState();

    triggerAlert({
      title: 'Connect to GitHub!',
      icon: 'info-sign',
      color: 'default', // @ts-ignore - message expects a string not an Element
      message: (
        <div>
          <Typography type="body-small">
            Authenticate your paradime user with your GitHub account.
          </Typography>
        </div>
      ),
      rightButtonText: 'Link',
      rightButtonEventData: {
        eventAction: Actions.CLICKED,
        eventContext: Contexts.EDITOR,
        eventObject: 'linkGithubUser',
      },
      onRightButtonClick: () => {
        window.open(userGithubOauthUrl);
        handleClose();
      },
      onClose: () => {
        closeAlert();
        handleClose();
      },
      view: 'raised',
    });
  };
};

export default useHandleGithubUserOAuth;
