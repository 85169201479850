import create from 'zustand';
import { combine } from 'zustand/middleware';
import { branch } from '../components/Catalog/hooks/useSearchCatalog';
import { GetDataCatalogueItemQuery } from '../client/generated/service-dataEndpoint';

export const catalogueStore = create(
  combine(
    {
      remoteMainBranch: undefined as branch | undefined,
      currentBranch: undefined as branch | undefined,
      lastRefreshTime: '',
      catalogItem: undefined as GetDataCatalogueItemQuery | undefined,
    },
    (set) => ({
      setRemoteMainBranch: (remoteMainBranch?: branch) => set(() => ({ remoteMainBranch })),
      setCurrentBranch: (currentBranch?: branch) => set(() => ({ currentBranch })),
      setLastRefreshTime: (lastRefreshTime: string) => set(() => ({ lastRefreshTime })),
      setCatalogItem: (catalogItem?: GetDataCatalogueItemQuery) => set(() => ({ catalogItem })),
    }),
  ),
);
