import React, { Dispatch, FC, SetStateAction } from 'react';
import DataTable, {
  ColumnProps,
  CustomFilterType,
} from '@paradime-io/pragma-ui-kit/lib/components/PrimeReactDataTable';
import { DataTableValueArray } from 'primereact/datatable';
import { DateTime } from 'luxon';
import csvExport from 'json-to-csv-export';
import { FilterMatchMode } from 'primereact/api';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import { DataRow } from '@paradime-io/pragma-ui-kit/lib/components/Table/Table';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import Spinner from '@paradime-io/pragma-ui-kit/lib/components/Spinner';
import { ApolloError } from '@apollo/client';
import CodeSnippetCopy from '@paradime/common/lib/common/helpers/CodeSnippetCopy';
import { useAuditLogsQuery } from '../../../client/generated/db';

export interface LogsTableProps {
  setError: Dispatch<SetStateAction<ApolloError | undefined>>,
}

const deDuplicateData = (data: any) => [...new Set(data)].filter(Boolean);

const LogsTable:FC<LogsTableProps> = ({ setError }) => {
  const { data, loading } = useAuditLogsQuery({
    onError: (err) => {
      setError(err);
    },
  });
  const tableDataFeeder = data?.auditLogs?.nodes;
  const tableData: DataTableValueArray = [];

  let eventTypeList: any = [];
  let actorEmailList: any = [];
  let workspaceNameList: any = [];

  tableDataFeeder?.forEach((rowData) => {
    const {
      createdDttm, updatedDttm, ...otherData
    } = rowData;
    tableData.push({
      createdDttm,
      createdDttmFormatted: DateTime.fromISO(createdDttm, { zone: 'utc' }).toFormat('dd-LLL-yyyy ttt'),
      updatedDttmFormatted: DateTime.fromISO(updatedDttm, { zone: 'utc' }).toFormat('dd-LLL-yyyy ttt'),
      ...otherData,
    });

    eventTypeList.push(rowData.eventType);
    actorEmailList.push(rowData.actorEmail);
    workspaceNameList.push(rowData.workspaceName);
  });

  eventTypeList = deDuplicateData(eventTypeList);
  actorEmailList = deDuplicateData(actorEmailList);
  workspaceNameList = deDuplicateData(workspaceNameList);

  type ExtendedColumnProps = ColumnProps & {
    csvHeader?: string;
  };

  const AuditTableColumns: ExtendedColumnProps[] = [
    {
      field: 'id',
      header: 'ID',
      csvHeader: 'id',
    },
    {
      field: 'createdDttmFormatted',
      header: 'Created At',
      csvHeader: 'created_at',
    },
    {
      field: 'workspaceName',
      header: 'Workspace Name',
      csvHeader: 'workspace_name',
      filter: true,
      filterOptions: workspaceNameList,
      customFilterType: CustomFilterType.MULTISELECT,
      filterMatchMode: FilterMatchMode.CUSTOM,
      showFilterMatchModes: false,
    },
    {
      field: 'actorType',
      header: 'Actor Type',
      csvHeader: 'actor_type',
      filter: true,
      customFilterType: CustomFilterType.MULTISELECT,
      filterOptions: ['user', 'system', 'api'],
      filterMatchMode: FilterMatchMode.CUSTOM,
      showFilterMatchModes: false,
    },
    {
      field: 'actorEmail',
      header: 'Actor Email',
      csvHeader: 'actor_email',
      filter: true,
      customFilterType: CustomFilterType.MULTISELECT,
      filterOptions: actorEmailList,
      filterMatchMode: FilterMatchMode.CUSTOM,
      showFilterMatchModes: false,
    },
    {
      field: 'eventSource',
      header: 'Event Source',
      csvHeader: 'event_source',
      filter: true,
      customFilterType: CustomFilterType.MULTISELECT,
      filterOptions: ['integrations', 'connections_management', 'users_management', 'workspace_management', 'bolt', 'api_credentials'],
      filterMatchMode: FilterMatchMode.CUSTOM,
      showFilterMatchModes: false,
    },
    {
      field: 'eventType',
      header: 'Event Type',
      csvHeader: 'event_type',
      filter: true,
      customFilterType: CustomFilterType.MULTISELECT,
      filterOptions: eventTypeList,
      filterMatchMode: FilterMatchMode.CUSTOM,
      showFilterMatchModes: false,
    },
  ];

  const convertToCSVRow = (row: DataRow) => {
    const transformed: {[k: string]: string} = {};
    Object.keys(row).forEach((key: string) => {
      transformed[key] = row[key];
    });
    return transformed;
  };

  const handleDownloadClick = () => {
    const currentDateTime = DateTime.now().toFormat('yyyy-MM-dd_HH-mm-ss');
    const dataToConvert = {
      data: tableDataFeeder!.map((row) => convertToCSVRow(row)),
      filename: `paradime_audit_logs_${currentDateTime}.csv`,
      delimiter: ',',
      headers: [...AuditTableColumns.map((c) => c.csvHeader as string), 'metadata_json'],
    };

    csvExport(dataToConvert);
  };

  const tableHeaderWithDownloadButton = (
    <AutoLayout
      direction="horizontal"
      distribution="packed"
      padding="none"
      alignment="right"
    >
      <DefaultButton
        type="standard"
        view="outlined"
        color="primary"
        icon="download"
        text="Download"
        dense
        eventContext={Contexts.PLATFORM}
        eventObject="downloadAuditLogsCSV"
        eventAction={Actions.CLICKED}
        onClick={handleDownloadClick}
      />
    </AutoLayout>
  );

  if (loading) {
    return (<Spinner width={32} height={32} thin />);
  }

  return (
    <DataTable
      columns={AuditTableColumns.map((col) => ({ ...col, sortable: true }))}
      data={tableData}
      tableHeader={tableHeaderWithDownloadButton}
      eventContext={Contexts.PLATFORM}
      showSearchBar
      usePagination
      showClearFiltersButton
      rowsPerPage={10}
      rowHasExpander={() => true}
      searchBarPlaceholder="Keyword search"
      searchBarFields="all"
      rowExpansionTemplate={
        (row) => <CodeSnippetCopy codeSnippet={row.metadataJson} language="json" />
      }
      defaultSortField="createdDttm"
      defaultSortOrder={-1}
    />
  );
};

export default LogsTable;
