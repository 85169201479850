import React, { FC } from 'react';
import { Icon } from '@blueprintjs/core';
import { IconName } from '@blueprintjs/icons';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import { Contexts, Actions } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import NotificationDropdown from './NotificationDropdown';
import CalloutBox from './CalloutBox';
import { useGetAlertNotifications } from './useGetAlertNotifications';
import { useSetAlertNotifications } from './useSetAlertNotifications';
import { ConnectedNotificatonIntegration, NotificationType } from '.';
import { useGetEmailNotifications } from './useGetEmailNotifications';
import { useSetEmailNotifications } from './useSetEmailNotifications';
import IntegrationChannelsDropdown from './IntegrationChannelsDropdown';

export interface NotificationCardProps {
  icon: IconName,
  title: string,
  description?: string | JSX.Element,
  canEdit: boolean,
  hasAccess: boolean,
  isLoadingConnectedIntegrations: boolean,
  connectedIntegration: ConnectedNotificatonIntegration,
  notificationType: NotificationType,
  cta?: {
    text: string,
    icon: IconName,
    eventObject: string,
    onClick: () => void,
  },
}

const emailsDisabledOption = {
  label: {
    title: 'No email alerts',
    subtitle: 'No emails are sent when this alert is triggered.',
  },
  value: 'disabled',
};

const emailsEnabledOption = {
  label: {
    title: 'Send email alerts',
    subtitle: 'Workspace Admins get email when this alert is triggered.',
  },
  value: 'enabled',
};

const emailDropdownOptions = [emailsDisabledOption, emailsEnabledOption];

const NotificationCard: FC<NotificationCardProps> = ({
  icon,
  title,
  description,
  canEdit,
  hasAccess,
  isLoadingConnectedIntegrations,
  connectedIntegration,
  notificationType,
  cta,
}) => {
  const {
    selectedChannelName,
    setSelectedChannelName,
    getSelectedChannelName,
  } = useGetAlertNotifications({ connectedIntegration, notificationType });

  const { saveAlertNotifications } = useSetAlertNotifications({
    connectedIntegration,
    onCompleted: getSelectedChannelName,
    notificationType,
  });

  const {
    getEmailNotifications,
    emailIsEnabled,
    setEmailIsEnabled,
  } = useGetEmailNotifications({
    notificationType,
  });

  const { saveEmailNotifications } = useSetEmailNotifications({
    onCompleted: getEmailNotifications,
    notificationType,
  });

  const isDisabled = !canEdit || !hasAccess;

  return (
    <AutoLayout
      id={notificationType}
      direction="vertical"
      distribution="space-between"
      padding="none"
      verticalGap="none"
      style={{
        border: '1px var(--grey30) solid',
        borderRadius: '8px',
      }}
      wrapperStyle={{
        alignItems: 'flex-start',
      }}
    >
      <AutoLayout
        direction="vertical"
        padding="dense"
        distribution="packed"
        verticalGap="dense"
        alignment="top-left"
      >
        <AutoLayout
          direction="horizontal"
          padding="none"
          distribution="space-between"
        >
          <AutoLayout
            direction="horizontal"
            padding="none"
            distribution="packed"
            horizontalGap="very-dense"
          >
            <Icon icon={icon} size={20} color="var(--grey70)" />
            <Typography type="body-bold">{title}</Typography>
          </AutoLayout>
          {
            cta && (
              <DefaultButton
                type="standard"
                view="gradient-secondary"
                icon={cta.icon}
                dense
                eventContext={Contexts.ACCOUNT_SETTINGS}
                eventObject={cta.eventObject}
                eventAction={Actions.CLICKED}
                text={cta.text}
                onClick={cta.onClick}
                style={{ textDecoration: 'none', width: '150px' }}
              />
            )
          }
        </AutoLayout>
        {description && <Typography type="body-small" colorStep="50">{description}</Typography>}
      </AutoLayout>
      <AutoLayout
        direction="vertical"
        padding="dense"
        distribution="packed"
        verticalGap="dense"
        alignment="bottom-left"
      >
        {(connectedIntegration !== ConnectedNotificatonIntegration.NONE) && (
          <IntegrationChannelsDropdown
            selectedChannelName={selectedChannelName}
            onSelectNewChannelName={(newChannelName) => {
              setSelectedChannelName(newChannelName);
              saveAlertNotifications(newChannelName);
            }}
            isLoading={isLoadingConnectedIntegrations}
            isDisabled={isDisabled}
            title={`${connectedIntegration} channel`}
            connectedIntegration={connectedIntegration}
          />
        )}
        <NotificationDropdown
          value={emailIsEnabled ? emailsEnabledOption : emailsDisabledOption}
          options={emailDropdownOptions}
          onChange={(dropdownOption) => {
            const newEmailSetting = dropdownOption.value === emailsEnabledOption.value;
            setEmailIsEnabled(newEmailSetting);
            saveEmailNotifications(newEmailSetting);
          }}
          isLoading={false}
          isDisabled={isDisabled}
          title="Email notifications"
          placeholder="Send email notifications"
          showClearAllButton={emailIsEnabled}
        />
        <CalloutBox
          canEdit={canEdit}
          hasAccess={hasAccess}
          notificationType={notificationType}
        />
      </AutoLayout>
    </AutoLayout>
  );
};

export default NotificationCard;
