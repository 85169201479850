import { useGetProfileQuery } from '../../client/generated/service';
import { userAuthStore } from '../../stores';

const useSetupUpdateUser = () => {
  const currentUser = userAuthStore((s) => s.currentUser);
  const setUser = userAuthStore((s) => s.setUser);

  useGetProfileQuery({
    onCompleted: ({ userIdentity }) => {
      if (userIdentity?.userInformation) {
        setUser({ ...currentUser, ...userIdentity.userInformation });
      }
    },
  });
};

export default useSetupUpdateUser;
