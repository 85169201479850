import { catalogueSimpleTypeProps } from '../types';

export const catalogueSimpleType = ({
  resultItem,
}: catalogueSimpleTypeProps) => {
  switch (resultItem.__typename) {
    case 'GQLCatalogueTableauWorksheetItem':
      return {
        simpleType: 'worksheet',
        compoundType: 'Tableau worksheet',
      };
    case 'GQLCatalogueTableauDashboardItem':
      return {
        simpleType: 'dashboard',
        compoundType: 'Tableau dashboard',
      };
    case 'GQLCatalogueTableauDatasourceItem':
      return {
        simpleType: 'data source',
        compoundType: 'Tableau data source',
      };
    default:
      return {
        simpleType: '',
        compoundType: '',
      };
  }
};
