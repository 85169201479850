import React from 'react';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import FeatureCarousel from '@paradime-io/pragma-ui-kit/lib/components/FeatureCarousel';
import { Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { useAuth0 } from '@auth0/auth0-react';
import { getGreeting, usePostLoginToChromeExtension } from '../../../utilis';
import { Features, EndScreen } from './features';
import paradimeLogo from '../../../images/paradime-logo.svg';

const ExtensionOnboardingSuccess = () => {
  usePostLoginToChromeExtension({ skipWindowClose: true });

  const { user } = useAuth0();

  return (
    <>
      <img src={paradimeLogo} alt="paradime logo" style={{ position: 'absolute', top: 16, left: 16 }} />
      <AutoLayout
        padding="expanded"
        direction="vertical"
        verticalGap="expanded"
        width="full"
        style={{ backgroundColor: 'var(--grey0)', height: '100vh' }}
      >
        <AutoLayout
          padding="none"
          direction="vertical"
          verticalGap="expanded"
          style={{ maxWidth: '768px' }}
        >
          <Typography
            font="inter"
            tagName="span"
            type="h4"
          >
            {`${getGreeting()}, ${user?.given_name}!`}
          </Typography>
          <FeatureCarousel
            features={Features}
            direction="one-way"
            eventContext={Contexts.HOME}
            end={{
              item: {
                graphic: EndScreen.graphic,
                title: EndScreen.title,
                slug: EndScreen.slug,
                description: EndScreen.description,
              },
              confetti: true,
            }}
            showEnd
          />
        </AutoLayout>
      </AutoLayout>
    </>
  );
};

export default ExtensionOnboardingSuccess;
