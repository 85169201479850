/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC, useState } from 'react';
import { Icon } from '@blueprintjs/core';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import List, { GraphicTypes } from '@paradime-io/pragma-ui-kit/lib/components/List';
import Spinner from '@paradime-io/pragma-ui-kit/lib/components/Spinner';
import { HelpDocs } from '@paradime-io/pragma-ui-kit/lib/components/utils';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import Chips, { ChipsProps } from '@paradime-io/pragma-ui-kit/lib/components/Chips';

export interface WorkspaceRowItemProps {
  name: string,
  description?: string,
  isMember: boolean,
  onClick: Function,
  help?: HelpDocs,
  isCurrentWorkspace: boolean,
  isIncompleteWorkspace: boolean,
}

const WorkspaceRowItem: FC<WorkspaceRowItemProps> = ({
  name,
  description,
  isMember,
  onClick,
  help,
  isCurrentWorkspace,
  isIncompleteWorkspace,
}) => {
  const [hover, setHover] = useState(false);
  const [loading, setLoading] = useState(false);

  const getChipsProps = (): Pick<ChipsProps, 'color' | 'tag' | 'view'> => {
    if (isIncompleteWorkspace) {
      return { color: 'default', tag: 'Finish setting up workspace', view: 'smooth' };
    }
    if (isCurrentWorkspace) {
      return { color: 'success', tag: 'Current workspace', view: 'smooth' };
    }
    if (isMember) {
      return { color: 'default', tag: 'Switch to workspace', view: 'smooth' };
    }
    return { color: 'default', tag: 'Join workspace', view: 'outlined' };
  };

  return (
    <div
      onPointerEnter={() => setHover(true)}
      onPointerLeave={() => setHover(false)}
      onClick={() => {
        window.analytics?.track(`${Contexts.APP}_${isMember ? 'switchWorkspace' : 'joinWorkspace'}_${Actions.CLICKED}`);
        setLoading(true);
        onClick();
      }}
      style={{
        cursor: 'pointer',
        borderRadius: '4px',
        padding: '8px',
        backgroundColor: hover ? 'var(--grey0)' : 'unset',
      }}
    >
      <AutoLayout
        data-testid={`${name}-row-item`}
        alignment="center"
        direction="horizontal"
        padding="none"
        distribution="space-between"
        style={{ alignItems: 'center' }}
      >
        <List
          graphic={{
            type: GraphicTypes.INITIALS,
            view: 'dense',
            initials: name.slice(0, 2).toUpperCase(),
          }}
          title={name}
          subtitle={description}
          noPadding
          view="dense"
        />
        <AutoLayout
          direction="horizontal"
          distribution="packed"
          padding="none"
          horizontalGap="dense"
          style={{ alignItems: 'center' }}
        >
          {hover && help && (
            <Chips
              color="primary_alt"
              style={{ marginRight: '4px' }}
              onClick={() => help.onClick()}
              round={false}
              tag={help.text}
              type="dense"
              view="smooth"
            />
          )}
          {loading
            ? <div style={{ marginRight: '8px' }}><Spinner thin width={16} height={16} /></div>
            : (
              <Chips
                type="dense"
                round={false}
                style={{ cursor: 'pointer' }}
                {...getChipsProps()}
              />
            )}
          <Icon icon="chevron-right" size={16} color="var(--icon)" style={{ padding: '12px' }} />
        </AutoLayout>
      </AutoLayout>
    </div>
  );
};

export default WorkspaceRowItem;
