import React, { FC } from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { Icon } from '@blueprintjs/core';
import { useHistory } from 'react-router-dom';
import paradimeLogo from '../SynqOnboarding/images/paradimeLogo.svg';

const ErrorAlreadyOnboarded: FC = () => {
  const history = useHistory();

  // Display the screen for 1 second, then push the user to /home
  setTimeout(() => history.push('/home'), 1000);

  return (
    <AutoLayout
      direction="vertical"
      verticalGap="none"
      padding="none"
      distribution="space-between"
      style={{
        width: '100vw',
        height: '100vh',
        backgroundColor: 'var(--grey0)',
      }}
    >
      <img src={paradimeLogo} alt="paradime" />
      <AutoLayout
        direction="vertical"
        verticalGap="dense"
        distribution="packed"
        alignment="center"
        padding="none"
        style={{
          height: 'calc(100vh - 38px)',
        }}
      >
        <Icon
          icon="office"
          size={72}
          color="var(--violet80)"
          style={{ margin: '250px auto auto' }}
        />
        <Typography
          font="poppins"
          type="h4"
          color="default"
          colorStep="100"
          style={{ textAlign: 'center' }}
        >
          You are already part of this organization
        </Typography>
        <Typography
          font="poppins"
          type="body"
          color="default"
          colorStep="100"
          style={{ textAlign: 'center' }}
        >
          Logging you in...
        </Typography>
      </AutoLayout>
    </AutoLayout>
  );
};

export default ErrorAlreadyOnboarded;
