import { MutableRefObject } from 'react';

export const checkNoCasesLeft = (element: never) => {
  console.error(`unexpected case: ${element}`); // eslint-disable-line
};

export const isMutableRefObject = <T>(object: any): object is MutableRefObject<T> => 'current' in object;

export const isNumber = (n: any): n is number => !Number
  .isNaN(parseFloat(n)) && !Number.isNaN(n - 0);

export const isBoolean = (n: any): n is boolean => ['true', 'false'].includes(n?.toString().toLowerCase());
