import React, { FC, useState } from 'react';
import { Icon } from '@blueprintjs/core';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { checkIsMac } from '@paradime-io/pragma-ui-kit';
import { appStore } from '../../../../stores';
import * as S from './CmdK.styles';

interface CmdKLauncherProps {
  isDark: boolean,
}

const CmdKLauncher:FC<CmdKLauncherProps> = ({ isDark }) => {
  const showCommandBar = appStore((s) => s.showCommandBar);
  const setShowCommandBar = appStore((s) => s.setShowCommandBar);
  const [hover, setHover] = useState(false);
  const isMac = checkIsMac();

  const borderBaseColor = isDark ? 'var(--grey60)' : 'var(--grey40)';
  const borderHoverColor = isDark ? 'var(--grey50)' : 'var(--grey60)';

  return (
    <AutoLayout
      direction="horizontal"
      distribution="packed"
      padding="none"
      horizontalGap="ultra-dense"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        height: '30px',
        padding: '4px 8px',
        borderRadius: '4px',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: hover ? borderHoverColor : borderBaseColor,
        transition: 'border 0.2s ease-out',
        cursor: hover ? 'pointer' : 'default',
      }}
      onClick={() => setShowCommandBar(!showCommandBar)}
    >
      <Icon icon="search" size={12} color="var(--grey50)" />
      <Typography
        tagName="span"
        type="caption"
        colorStep={isDark ? '60' : '30'}
      >
        Search ...
      </Typography>
      <S.CodeKbd dark={isDark}>{isMac ? '⌘' : 'Ctrl'}</S.CodeKbd>
      <Typography
        tagName="span"
        type="caption"
        colorStep={isDark ? '30' : '60'}
      >
        +
      </Typography>
      <S.CodeKbd dark={isDark}>K</S.CodeKbd>
    </AutoLayout>
  );
};

export default CmdKLauncher;
