import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import ZeroState from '@paradime-io/pragma-ui-kit/lib/components/ZeroState';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import NotFoundIcon from './../images/NotFoundIcon.svg';

const NotFoundZeroState: FC = () => {
  const history = useHistory();

  return (
    <div style={{ height: '500px' }}>
      <ZeroState
        image={NotFoundIcon}
        text={(
          <AutoLayout
            padding="none"
            direction="vertical"
            verticalGap="ultra-dense"
            alignment="center"
            wrapperStyle={{ justifyContent: 'center' }}
            style={{ width: '300px' }}
          >
            <Typography
              font="inter"
              type="body-bold-small"
              color="default"
              colorStep="100"
              style={{ textAlign: 'center' }}
            >
              Bolt schedule not found
            </Typography>
            <Typography
              font="inter"
              type="caption"
              color="default"
              colorStep="70"
              style={{ textAlign: 'center' }}
            >
              The page you requested could not be found in the current workspace.
            </Typography>
          </AutoLayout>
        )}
        buttons={[{
          text: 'View schedules',
          eventAction: Actions.CLICKED,
          eventContext: Contexts.APP,
          eventObject: 'boltScheduleNotFound',
          dense: true,
          color: 'primary',
          type: 'standard',
          view: 'filled',
          onClick: () => history.push('/bolt'),
        }]}
      />
    </div>
  );
};

export default NotFoundZeroState;
