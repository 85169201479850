/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FC, useState } from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import Input from '@paradime-io/pragma-ui-kit/lib/components/DefaultInput';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import slack from './images/slack.svg';
import magnifier from './images/magnifier.svg';
import SlackUser from './SlackUser';
import { slackUser } from './InviteRoles';

interface SelectorProps {
  inviteTitle: string,
  users: slackUser[],
  setUsers: Function,
}

const Selector: FC<SelectorProps> = ({
  inviteTitle, setUsers, users,
}) => {
  const [selected, setSelected] = useState<slackUser[]>([]);
  const [filter, setFilter] = useState('');

  const handleClick = () => {
    setUsers(selected);
  };

  const getDisabledState = () => {
    if (selected.length > 0) return false;
    return true;
  };

  return (
    <AutoLayout
      direction="vertical"
      padding="expanded"
      verticalGap="normal"
      distribution="packed"
      alignment="top-center"
      style={{ paddingTop: '2rem' }}
    >
      <div className="title">
        <Typography
          tagName="span"
          type="h4"
          color="default"
          colorStep="100"
          style={{ textAlign: 'center', display: 'block' }}
        >
          {inviteTitle}
          <img style={{ marginLeft: '8px' }} src={slack} alt="slack" />
        </Typography>
      </div>
      <div className="selector">
        <label htmlFor="search">
          <Typography tagName="span" type="overline-small" colorStep="40">TEAM MEMBERS</Typography>
          <fieldset style={{ border: 'none', padding: 0 }}>
            <Input
              id="search"
              type="text"
              color="default"
              full
              view="outlined"
              placeholder="Search by name"
              autocomplete="off"
              expose={(e: any) => setFilter(e.target.value)}
              value={filter}
            />
            <img src={magnifier} alt="magnifier" />
          </fieldset>
        </label>
        <div className="list">
          {users
            .filter((user) => !filter
              || user.profileRealNameNormalized?.toLocaleLowerCase()
                .includes(filter.toLocaleLowerCase()))
            .map((user) => (
              <SlackUser
                user={user}
                key={user.slackId}
                initialState={Boolean(selected.find((item) => item.slackId === user.slackId))}
                setSelected={setSelected}
              />
            ))}
        </div>
      </div>
      <DefaultButton
        data-testid="continueButton"
        text="Continue"
        color="primary"
        type="standard"
        className="bp4-intent-6"
        style={{ margin: '0 auto' }}
        onClick={handleClick}
        disabled={getDisabledState()}
        eventContext={Contexts.MODAL}
        eventObject="inviteUsers"
        eventAction={Actions.SELECTED}
        eventProperties={{
          location: 'onboarding',
          type: 'slack',
        }}
      />
    </AutoLayout>
  );
};

export default Selector;
