import { useState } from 'react';
import { ParadimeIntegrationState, useGetMsTeamsWebhooksQuery, useOrganisationIntegrationListQuery } from '../../../client/generated/service';
import { ConnectedNotificatonIntegration } from '.';

export const useCheckConnectedIntegration = () => {
  const [
    connectedIntegration, setConnectedIntegration,
  ] = useState(ConnectedNotificatonIntegration.NONE);

  const { loading: isLoadingSlack } = useOrganisationIntegrationListQuery({
    onCompleted: (data) => {
      const { slackMetadata } = data.organisationIntegrationList;
      if (slackMetadata.state === ParadimeIntegrationState.Active) {
        setConnectedIntegration(ConnectedNotificatonIntegration.SLACK);
      }
    },
  });

  const { loading: isLoadingTeams } = useGetMsTeamsWebhooksQuery({
    onCompleted: (data) => {
      if ((data.getMsTeamsWebhooks?.webhookNames || []).length > 0) {
        setConnectedIntegration(ConnectedNotificatonIntegration.TEAMS);
      }
    },
  });

  return {
    connectedIntegration,
    isLoadingConnectedIntegrations: isLoadingSlack || isLoadingTeams,
  };
};
