import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Command } from 'cmdk';
import { DateTime } from 'luxon';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import Chips from '@paradime-io/pragma-ui-kit/lib/components/Chips';
import { colorType } from '@paradime-io/pragma-ui-kit/lib/components/styles/styleTypes';
import { appStore } from '../../../../stores';

export interface BoltScheduleItemProps {
  uuid: string,
  name: string,
  state: string,
  color: colorType,
  owner: string,
  nextRunTime: string,
}

const BoltScheduleItem:FC<BoltScheduleItemProps> = ({
  uuid,
  name,
  state,
  color,
  owner,
  nextRunTime,
}) => {
  const setShowCommandBar = appStore((s) => s.setShowCommandBar);
  const history = useHistory();

  const getLocalTime = () => {
    const dt = DateTime.fromISO(nextRunTime);
    if (dt.isValid) {
      return dt.toLocaleString(DateTime.DATETIME_FULL);
    }
    return '-';
  };

  return (
    <Command.Item
      onSelect={() => {
        history.push(`/bolt/${uuid}`);
        setShowCommandBar(false);
      }}
    >
      <AutoLayout
        direction="vertical"
        distribution="packed"
        padding="very-dense"
        verticalGap="ultra-dense"
      >
        <AutoLayout
          direction="horizontal"
          distribution="space-between"
          padding="none"
        >
          <Typography tagName="span" type="caption" style={{ fontWeight: 500 }}>
            {name}
          </Typography>
          <Chips
            type="dense"
            view="smooth"
            color={color}
            round={false}
            tag={state}
            style={{ borderRadius: '4px', width: '65px' }}
          />
        </AutoLayout>
        <AutoLayout
          direction="horizontal"
          distribution="packed"
          padding="none"
          horizontalGap="normal"
        >
          <AutoLayout
            direction="horizontal"
            distribution="packed"
            padding="none"
            horizontalGap="ultra-dense"
          >
            <Typography tagName="span" type="caption-small" color="primary" colorStep="50">Owner:</Typography>
            <Typography tagName="span" type="caption-small" colorStep="50">{owner}</Typography>
          </AutoLayout>
          <AutoLayout
            direction="horizontal"
            distribution="packed"
            padding="none"
            horizontalGap="ultra-dense"
          >
            <Typography tagName="span" type="caption-small" color="primary" colorStep="50">Next Run:</Typography>
            <Typography tagName="span" type="caption-small" colorStep="50">{getLocalTime()}</Typography>
          </AutoLayout>
        </AutoLayout>
      </AutoLayout>
    </Command.Item>
  );
};

export default BoltScheduleItem;
