import { useHistory } from 'react-router-dom';
import { CommandItemProps } from '../CommandItem';
import { AccountSettingsTab, PlatformTab } from '../../../../../components/NavBar';
import {
  userHasAccountSettingsConnectionsAccess,
  userHasAccountSettingsIntegrationsAccess,
  userHasAccountSettingsTeamsAccess,
  userHasAccountSettingsWorkspacesAccess,
  userHasAccountSettingsNotificationsEditAccess,
  userHasAccountSettingsEnvVarsAccess,
  userHasPlatformProfileSettingsPrivacyAccess,
  userHasPlatformAuditLogsAccess,
  userHasPlatformBillingUpdateAccess,
  userHasPlatformInsightsAccess,
  userHasPlatformPlansUpdateAccess,
} from '../../../../../utilis/PermissionsService';
import { GqlParadimeAccountType } from '../../../../../client/generated/service';

const useGetSettingsCommands = (accessLevel: GqlParadimeAccountType) => {
  const history = useHistory();

  const settingsCommands = [
    userHasAccountSettingsConnectionsAccess(accessLevel) && {
      icon: 'flow-review-branch',
      children: 'Warehouse Connections',
      nested: false,
      onSelect: () => history.push(`/settings/${AccountSettingsTab.CONNECTIONS}`),
    },
    userHasAccountSettingsWorkspacesAccess(accessLevel) && {
      icon: 'projects',
      children: 'Workspaces',
      nested: false,
      onSelect: () => history.push(`/settings/${AccountSettingsTab.WORKSPACE}`),
    },
    userHasAccountSettingsEnvVarsAccess(accessLevel) && {
      icon: 'variable',
      children: 'Environment Variables',
      nested: false,
      onSelect: () => history.push(`/settings/${AccountSettingsTab.ENV_VARIABLES}`),
    },
    userHasAccountSettingsIntegrationsAccess(accessLevel) && {
      icon: 'intersection',
      children: 'Integrations',
      nested: false,
      onSelect: () => history.push(`/settings/${AccountSettingsTab.INTEGRATIONS}`),
    },
    userHasAccountSettingsTeamsAccess(accessLevel) && {
      icon: 'people',
      children: 'Team',
      nested: false,
      onSelect: () => history.push(`/settings/${AccountSettingsTab.TEAM}`),
    },
    userHasAccountSettingsNotificationsEditAccess(accessLevel) && {
      icon: 'notifications',
      children: 'Notifications',
      nested: false,
      onSelect: () => history.push(`/settings/${AccountSettingsTab.NOTIFICATIONS}`),
    },
    userHasPlatformPlansUpdateAccess(accessLevel) && {
      icon: 'map',
      children: 'Plans',
      nested: false,
      onSelect: () => history.push(`/settings/${PlatformTab.PLANS}`),
    },
    userHasPlatformBillingUpdateAccess(accessLevel) && {
      icon: 'credit-card',
      children: 'Billing',
      nested: false,
      onSelect: () => history.push(`/settings/${PlatformTab.BILLING}`),
    },
    userHasPlatformAuditLogsAccess(accessLevel) && {
      icon: 'manual',
      children: 'Audit Logs',
      nested: false,
      onSelect: () => history.push(`/settings/${PlatformTab.AUDIT_LOGS}`),
    },
    userHasPlatformInsightsAccess(accessLevel) && {
      icon: 'timeline-area-chart',
      children: 'Insights',
      nested: false,
      onSelect: () => history.push(`/settings/${PlatformTab.INSIGHTS}`),
    },
    {
      icon: 'mugshot',
      children: 'Profile',
      nested: false,
      onSelect: () => history.push(`/settings/${AccountSettingsTab.PROFILE}`),
    },
    userHasPlatformProfileSettingsPrivacyAccess(accessLevel) && {
      icon: 'shield',
      children: 'Privacy',
      nested: false,
      onSelect: () => history.push(`/settings/${AccountSettingsTab.PRIVACY}`),
    },
  ] as CommandItemProps[];

  return {
    settingsCommands,
  };
};

export { useGetSettingsCommands };
