/* eslint-disable no-case-declarations */
import { catalogueFieldsTabProps } from '../types';
import { replaceNullWithEmptyString } from '../../components/Catalog/SearchRoutes/CatalogItem/Fields';

export const catalogueFieldsTab = ({
  resultItem,
}: catalogueFieldsTabProps) => {
  switch (resultItem?.__typename) {
    case 'GQLCatalogueLookerDashboardItem':
      return ({
        columnHeadings: [],
        data: [],
      });
    case 'GQLCatalogueLookerExploreItem':
    case 'GQLCatalogueLookerLookItem':
      const columnHeadings = [
        { id: 'viewName', label: 'View name' },
        { id: 'name', label: 'Field name' },
        { id: 'description', label: 'Field description' },
        { id: 'fieldType', label: 'Type' },
        { id: 'dataType', label: 'Data Type' },
      ];

      if (resultItem.__typename === 'GQLCatalogueLookerExploreItem') {
        columnHeadings.push({ id: 'sql', label: 'sql' });
      }

      const data = resultItem?.fields?.map((field, i) => ({
        id: i,
        viewName: replaceNullWithEmptyString(field?.viewName),
        name: replaceNullWithEmptyString(field?.name),
        description: replaceNullWithEmptyString(field?.description),
        fieldType: replaceNullWithEmptyString(field?.fieldType),
        dataType: replaceNullWithEmptyString(field?.dataType),
        sql: replaceNullWithEmptyString(field?.sql),
      }));

      return ({
        columnHeadings,
        data,
      });
    default:
      return ({
        columnHeadings: [],
        data: [],
      });
  }
};
