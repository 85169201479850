import { useEffect, useState } from 'react';
import { IconName } from '@blueprintjs/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { NavbarMenuItem } from '@paradime-io/pragma-ui-kit/lib/components/AppNavbar/NavbarMenu';
import useOrganisation from '../../hooks/useOrganisation';
import {
  userHasAccountSettingsAccess,
  userHasAccountSettingsConnectionsAccess,
} from '../../../utilis/PermissionsService';
import { graphQLStore, userAuthStore } from '../../../stores';
import { AccountSettingsTab } from '../../NavBar';

export const useGetNavbarProfile = (dark: boolean) => {
  const { user, logout } = useAuth0();
  const history = useHistory();

  const [menuItems, setMenuItems] = useState<NavbarMenuItem[]>([]);

  const { clear: clearOrganisation } = useOrganisation();

  const paradimeOrganisation = graphQLStore((state) => state.paradimeOrganisation);
  const clusterConfig = graphQLStore((state) => state.clusterConfig);
  const organisationName = paradimeOrganisation?.name || '-';
  const currentUser = userAuthStore((s) => s.currentUser);

  const handleLogout = () => {
    clearOrganisation();
    logout();
    window.analytics.track(
      `${Contexts.APP}:logout_${Actions.CLICKED}`,
    );
  };

  const getMenuItems = () => [
    {
      text: user?.name || currentUser?.name,
      type: 'item',
      inactive: true,
      eventObject: 'profileMenuItemName',
    },
    {
      text: organisationName || '-',
      type: 'item',
      icon: 'office',
      inactive: true,
      eventObject: 'profileMenuItemOrgName',
      customFontColour: 'var(--grey40)',
    },
    {
      text: 'divider',
      type: 'divider',
      eventObject: 'profileMenuItemDivider',
    },
    userHasAccountSettingsAccess(currentUser.accessLevel) ? {
      icon: 'cog' as IconName,
      text: 'Settings',
      onClick: () => {
        if (window.analytics?.track) window.analytics.track('profileSettings:connectionTab_viewed');
        const page = userHasAccountSettingsConnectionsAccess(currentUser.accessLevel)
          ? AccountSettingsTab.CONNECTIONS : AccountSettingsTab.INTEGRATIONS;
        history.push(`/settings/${page}`);
      },
      eventObject: 'profileMenuItem',
      eventProperties: { name: 'account' },
    } : {},
    {
      text: 'Service status',
      type: 'item',
      icon: 'pulse',
      onClick: () => { window.open('https://paradime.statuspage.io'); },
      eventObject: 'profileMenuItem',
      eventProperties: { name: 'bug-report' },
    },
    {
      text: 'Logout',
      type: 'item',
      icon: 'log-out',
      onClick: handleLogout,
      eventObject: 'logout',
    },
    {
      text: `version ${clusterConfig?.version.substring(0, 10)}`,
      type: 'item',
      inactive: true,
      customFontColour: 'var(--grey30)',
    },
  ] as NavbarMenuItem[];

  useEffect(() => {
    setMenuItems(getMenuItems());
  }, [user, currentUser]);

  return ({
    image: user?.picture || currentUser?.avatarUrl || 'https://via.placeholder.com/32/ededf0/?text=X',
    dark,
    eventObject: 'profileNav',
    menu: menuItems,
  });
};
