import { Dispatch, SetStateAction } from 'react';
import { WarehouseFormInputType } from '../../warehouseFactory/types';

export const formDistribution = (showEditButton: boolean) => (
  showEditButton
    ? ("'P P P P P P' "
      + "'a a a a a a' "
      + "'d d d d d d' "
      + "'u u p p b b' ")
    : ("'P P P P P P' "
      + "'a a a a a a' "
      + "'d d d d d d' "
      + "'u u u p p p' ")
);

export const initialValues = {
  account: '',
  database: '',
  username: '',
  password: '',
  connectionName: 'dummy_connectionName',
  role: 'dummy_role',
  warehouse: 'dummy_warehouse',
  schema: 'dummy_schema',
  targetName: 'dummy_targetName',
  threads: 1,
};

export const formFields = (
  showEditButton: boolean,
  isEditingSecret: boolean,
  setIsEditingSecret: Dispatch<SetStateAction<boolean>>,
) => [
  {
    title: 'Connection Settings',
    gridArea: 'P',
    type: 'title',
  },
  {
    id: 'text-sf-cost-account-name',
    name: 'account',
    label: 'Account',
    type: WarehouseFormInputType.TEXT,
    gridArea: 'a',
    adminOnly: true,
    isPrivate: true,
  },
  {
    id: 'text-sf-cost-database',
    name: 'database',
    label: 'Database',
    type: WarehouseFormInputType.TEXT,
    gridArea: 'd',
  },
  {
    id: 'text-sf-cost-username',
    name: 'username',
    label: 'Username',
    type: WarehouseFormInputType.TEXT,
    gridArea: 'u',
    value: '' as any,
    isPrivate: true,
    disabled: showEditButton ? !isEditingSecret : false,
  },
  {
    id: 'text-sf-cost-password',
    name: 'password',
    label: 'Password',
    type: WarehouseFormInputType.PASSWORD,
    gridArea: 'p',
    isPrivate: true,
    disabled: showEditButton ? !isEditingSecret : false,
    isSecretField: true,
    isEditingSecretField: isEditingSecret,
    adminOnly: true,
  },
  ...(showEditButton
    ? [{
      name: 'editClientSecretButton',
      text: isEditingSecret ? 'Cancel' : 'Edit',
      type: WarehouseFormInputType.BUTTON,
      gridArea: 'b',
      onClick: () => setIsEditingSecret((prev) => !prev),
    }]
    : []
  ),
];
