/* eslint-disable no-unused-expressions,
prefer-rest-params, no-unused-vars, no-underscore-dangle, no-multi-assign */

declare global {
  interface Window {
    Plain: any,
  }
}

const { Plain } = window;

// eslint-disable-next-line import/prefer-default-export
export { Plain };
