import React, { FC } from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import BigButton from '@paradime-io/pragma-ui-kit/lib/components/BigButton';
import { LocationItem } from './OrganisationSelector';
import { LocationType } from '../../hooks/useOrganisation';

export interface LocationSelectorProps {
  locations: LocationItem[],
  onLocationClick: (locationKey: LocationType) => void,
  currentLocation: LocationType | null,
}

const LocationSelector: FC<LocationSelectorProps> = ({
  locations,
  onLocationClick,
  currentLocation,
}) => (
  <>
    {locations.map((location) => (
      <BigButton
        key={location.title}
        onClick={() => onLocationClick(location.key)}
        selected={location.key === currentLocation}
        fill
      >
        <AutoLayout
          direction="horizontal"
          width="full"
          padding="normal"
          distribution="packed"
          horizontalGap="very-dense"
          alignment="left"
          style={{
            paddingLeft: '16.67px',
            paddingTop: '0px',
            paddingBottom: '0px',
          }}
        >
          <img src={location.icon} alt={`flag-${location.key}`} width="24px" />

          <AutoLayout
            direction="vertical"
            width="full"
            padding="normal"
            distribution="packed"
            horizontalGap="dense"
            verticalGap="none"
            alignment="left"
            style={{
              marginLeft: '8.66px',
              paddingLeft: '0px',
              paddingTop: '0px',
              paddingBottom: '0px',
              textAlign: 'left',
            }}
          >
            <Typography font="inter" type="body-bold">
              {location.title}
            </Typography>
            <Typography
              font="inter"
              type="body-small"
              style={{
                color: 'var(--grey60)',
              }}
            >
              {location.subtitle}
            </Typography>
          </AutoLayout>
        </AutoLayout>
      </BigButton>
    ))}
  </>
);

export default LocationSelector;
