import React, { FC, useEffect, useState } from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { DefaultButton } from '@paradime-io/pragma-ui-kit';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { useHistory } from 'react-router-dom';
import { RequestType, useUpgradeRequest } from '../../../utilis/useUpgradeRequest';
import { NotificationType } from '.';

export interface CalloutBoxProps {
  hasAccess: boolean,
  canEdit: boolean,
  notificationType: NotificationType,
}

const CalloutBox: FC<CalloutBoxProps> = ({ hasAccess, canEdit, notificationType }) => {
  const [text, setText] = useState('');
  const [buttonText, setButtonText] = useState<string>();
  const [upgradeWasRequested, setUpgradeWasRequested] = useState(false);

  const history = useHistory();

  const { upgradeRequest, upgradeRequestInProgress: loading } = useUpgradeRequest();

  const getPlanName = () => (
    notificationType[0].toUpperCase() + notificationType.slice(1)
  );

  useEffect(() => {
    if (!canEdit && !hasAccess) {
      setText(`Upgrade to ${getPlanName()} and enable this alert.`);
      setButtonText('Request upgrade');
    }
    if (!canEdit && hasAccess) {
      setText("You don't have permission to edit these settings.");
      setButtonText(undefined);
    }
    if (canEdit && !hasAccess) {
      setText(`Upgrade to ${getPlanName()} and enable this alert.`);
      setButtonText('Upgrade');
    }
  }, [canEdit, hasAccess]);

  if (canEdit && hasAccess) return null;

  return (
    <AutoLayout
      direction="vertical"
      padding="none"
      distribution="packed"
      verticalGap="very-dense"
      style={{
        backgroundColor: 'var(--violet0)',
        borderRadius: '4px',
        padding: '16px',
      }}
    >
      <Typography type="body-small">
        {text}
      </Typography>
      {buttonText && (
        <DefaultButton
          type="standard"
          view="gradient-primary"
          dense
          text={upgradeWasRequested ? 'Upgrade Requested' : buttonText}
          loading={loading}
          icon={upgradeWasRequested ? 'tick-circle' : undefined}
          disabled={upgradeWasRequested}
          onClick={() => {
            if (canEdit) {
              history.push('/settings/plans');
            } else {
              upgradeRequest({ requestType: RequestType.ACCOUNT_UNLOCK_REQUEST, feature: 'account locked' }).then(() => setUpgradeWasRequested(true));
            }
          }}
          eventContext={Contexts.ACCOUNT_SETTINGS}
          eventObject="upgradePlanToAccessNotifications"
          eventAction={Actions.CLICKED}
          eventProperties={{ planToUpgradeTo: notificationType, action: canEdit ? 'view-plans' : 'request-access' }}
        />
      )}
    </AutoLayout>
  );
};

export default CalloutBox;
