import React, { FC } from 'react';
import { Icon } from '@blueprintjs/core';
import { TooltipV2 as Tooltip } from '@paradime-io/pragma-ui-kit/lib/components/Tooltip/Tooltip.styles';

export interface SLAExceededIconProps {
  slaWasExceeded: boolean,
  slaSeconds?: number | null,
}

const SLAExceededIcon: FC<SLAExceededIconProps> = ({
  slaWasExceeded,
  slaSeconds,
}) => {
  if (!slaWasExceeded) return null;

  return (
    <>
      <Tooltip
        target=".bolt-sla-exceeded-icon"
        mouseTrack
        mouseTrackLeft={10}
        style={{ height: 'auto' }}
      />
      <Icon
        icon="warning-sign"
        color="var(--orange60)"
        size={16}
        className="bolt-sla-exceeded-icon"
        data-pr-tooltip={`SLA Breach. Run exceeded ${(slaSeconds || 0) / 60}-minute completion deadline`}
        data-pr-position="top"
        data-pr-at="top"
      />
    </>
  );
};

export default SLAExceededIcon;
