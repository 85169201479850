import { Dispatch, SetStateAction } from 'react';
import { DropdownOnChangeProps, WarehouseFormInputType } from '../../warehouseFactory/types';
import { WarehouseEnv } from '../../utils';
import { AuthMethod, getDefaultEmptyAuthValues, SnowflakeFormDataType } from './CommonConfig';
import { sendResetSecretsMessage } from '../CommonConfig';
import { initialFormDataType } from '../../useGetInitialWarehouseData';
import { checkNoCasesLeft } from '../../../../../utilis';
import { DevWarehouseHelp, ProdWarehouseHelp } from '../../../../Common/AppHelp/help';

export interface formDistributionProps {
  env: WarehouseEnv,
  selectedAuthMethod: AuthMethod,
  showEditButton: boolean,
}

const connectionSettingsTitle = 'P P P P P P P P P P P P';
const profileNameAndTarget = 'p p p p p p t t t t t t';
const accountNameAndAuthMethod = 'a a a a a a A A A A A A';
const clientIdAndClientSecret = 'u u u u u u c c c c c c';
const clientIdClientSecretAndEditButton = 'u u u u u c c c c c b b';
const devCredentialsTitle = 'D D D D D D D D D D D D';
const devCredentialsTitleAndEditButton = 'D D D D D D D D D D b b';
const username = 'u u u u u u u u u u u u';
const privateKey = 'k k k k k k k k k k k k';
const passphrase = 'o o o o o o o o o o o o';
const usernameAndPassword = 'u u u u u u c c c c c c';
const usernamePasswordAndEditButton = 'u u u u u c c c c c b b';
const roleDatabaseAndWarehouse = 'r r r r d d d d w w w w';
const schemaAndThreads = 's s s s s s T T T T T T';

export const formDistribution = ({
  selectedAuthMethod,
  showEditButton,
}: formDistributionProps) => {
  switch (selectedAuthMethod) {
    case AuthMethod.OAUTH:
      return `'${connectionSettingsTitle}'
        '${profileNameAndTarget}'
        '${accountNameAndAuthMethod}'
        '${showEditButton ? clientIdClientSecretAndEditButton : clientIdAndClientSecret}'
        '${devCredentialsTitle}'
        '${roleDatabaseAndWarehouse}'
        '${schemaAndThreads}'
      `;
    case AuthMethod.USERNAMEPASSWORD:
      return `'${connectionSettingsTitle}'
        '${profileNameAndTarget}'
        '${accountNameAndAuthMethod}'
        '${devCredentialsTitle}'
        '${showEditButton ? usernamePasswordAndEditButton : usernameAndPassword}'
        '${roleDatabaseAndWarehouse}'
        '${schemaAndThreads}'
      `;
    case AuthMethod.KEY_PAIR:
      return `'${connectionSettingsTitle}'
        '${profileNameAndTarget}'
        '${accountNameAndAuthMethod}'
        '${showEditButton ? devCredentialsTitleAndEditButton : devCredentialsTitle}'
        '${username}'
        '${privateKey}'
        '${passphrase}'
        '${roleDatabaseAndWarehouse}'
        '${schemaAndThreads}'
      `;
    default:
      checkNoCasesLeft(selectedAuthMethod);
      return '';
  }
};

export const initialValues = (env: WarehouseEnv) => ({
  connectionName: '',
  account: '',
  role: '',
  database: '',
  warehouse: '',
  username: '',
  password: '',
  schema: '',
  useSso: false,
  authMethod: AuthMethod.USERNAMEPASSWORD,
  targetName: env,
  threads: 8,
  clientId: '',
  clientSecret: '',
  useKeyPairAuth: false,
  privateKey: '',
  privateKeyPassphrase: '',
});

export const formFields = (
  selectedAuthMethod: AuthMethod,
  setSelectedAuthMethod: Dispatch<SetStateAction<AuthMethod>>,
  showEditButton: boolean,
  isEditingSecret: boolean,
  setIsEditingSecret: Dispatch<SetStateAction<boolean>>,
  env: WarehouseEnv,
) => {
  const usernamePasswordOption = {
    text: AuthMethod.USERNAMEPASSWORD,
    type: 'item',
    onClick: () => setSelectedAuthMethod(AuthMethod.USERNAMEPASSWORD),
  };
  const keyPairOption = {
    text: AuthMethod.KEY_PAIR,
    type: 'item',
    onClick: () => setSelectedAuthMethod(AuthMethod.KEY_PAIR),
  };
  const devAuthMenuItems = [
    usernamePasswordOption,
    {
      text: AuthMethod.OAUTH,
      type: 'item',
      onClick: () => setSelectedAuthMethod(AuthMethod.OAUTH),
    },
    keyPairOption,
  ];
  const prodAuthMenuItems = [
    usernamePasswordOption,
    keyPairOption,
  ];
  const authMethodDropdown = [{
    id: 'dropdown-sf-auth-method',
    name: 'authMethod',
    label: 'Auth Method',
    gridArea: 'A',
    type: 'dropdown',
    menuItems: env === WarehouseEnv.DEV ? devAuthMenuItems : prodAuthMenuItems,
    text: selectedAuthMethod,
    additionalUserPlaceholder: selectedAuthMethod,
    adminOnly: true,
    onChange: ({
      setFormData,
      selectedItem,
    }: DropdownOnChangeProps) => {
      setFormData((currentFormData) => ({
        ...currentFormData,
        ...getDefaultEmptyAuthValues(
          selectedItem as AuthMethod,
          currentFormData as SnowflakeFormDataType,
        ) as initialFormDataType,
      }));
    },
  }];

  const clientIdClientSecret = [{
    id: 'text-sf-oauth-client-id',
    name: 'clientId',
    label: 'Client ID',
    type: WarehouseFormInputType.TEXT,
    gridArea: 'u',
    title: (env === WarehouseEnv.PROD) ? 'Production Credentials' : 'Development Credentials' as any,
    isPrivate: true,
    adminOnly: true,
    disabled: showEditButton ? !isEditingSecret : false,
    isSecretField: true,
    isEditingSecretField: isEditingSecret,
  },
  {
    id: 'text-sf-oauth-client-secret',
    name: 'clientSecret',
    label: 'Client Secret',
    type: WarehouseFormInputType.PASSWORD,
    gridArea: 'c',
    isPrivate: true,
    adminOnly: true,
    disabled: showEditButton ? !isEditingSecret : false,
    isSecretField: true,
    isEditingSecretField: isEditingSecret,
  }];

  const editSecretButton = [{
    name: 'editClientSecretButton',
    text: isEditingSecret ? 'Cancel' : 'Edit',
    type: WarehouseFormInputType.BUTTON,
    gridArea: 'b',
    onClick: () => setIsEditingSecret((isEditing) => {
      if (isEditing) {
        // User has pressed cancel, undo any changes
        sendResetSecretsMessage();
      }
      return !isEditing;
    }),
    adminOnly: selectedAuthMethod === AuthMethod.OAUTH,
  }];

  const usernamePassword = [{
    id: 'text-sf-username',
    name: 'username',
    label: 'Username',
    type: WarehouseFormInputType.TEXT,
    gridArea: 'u',
    title: (env === WarehouseEnv.PROD) ? 'Production Credentials' : 'Development Credentials' as any,
    isPrivate: true,
    adminOnly: env !== WarehouseEnv.DEV,
    disabled: showEditButton ? !isEditingSecret : false,
  },
  {
    id: 'text-sf-password',
    name: 'password',
    label: 'Password',
    type: WarehouseFormInputType.PASSWORD,
    gridArea: 'c',
    isPrivate: true,
    adminOnly: env !== WarehouseEnv.DEV,
    disabled: showEditButton ? !isEditingSecret : false,
    isSecretField: true,
    isEditingSecretField: showEditButton ? isEditingSecret : true,
  }];

  const keyPair = [{
    id: 'text-sf-keypair-username',
    name: 'username',
    label: 'Username',
    type: WarehouseFormInputType.TEXT,
    gridArea: 'u',
    title: 'Production Credentials',
    isPrivate: true,
    adminOnly: false,
    disabled: showEditButton ? !isEditingSecret : false,
  },
  {
    id: 'text-sf-keypair-private-key',
    name: 'privateKey',
    label: 'Private Key',
    placeholder: ' ',
    type: WarehouseFormInputType.MULTI_LINE_INPUT,
    gridArea: 'k',
    isPrivate: true,
    adminOnly: false,
    disabled: showEditButton ? !isEditingSecret : false,
    isSecretField: true,
    isEditingSecretField: showEditButton ? isEditingSecret : true,
  },
  {
    id: 'text-sf-keypair-passphrase',
    name: 'privateKeyPassphrase',
    label: 'Private Key Passphrase (optional)',
    type: WarehouseFormInputType.PASSWORD,
    gridArea: 'o',
    isOptionalField: true,
    disabled: showEditButton ? !isEditingSecret : false,
    isSecretField: true,
    isEditingSecretField: showEditButton ? isEditingSecret : true,
  },
  ];

  return ([
    {
      title: 'Connection Settings',
      gridArea: 'P',
      type: 'title',
      helpArticleId: (env === WarehouseEnv.PROD)
        ? ProdWarehouseHelp.SNOWFLAKE
        : DevWarehouseHelp.SNOWFLAKE,
    },
    {
      id: 'text-sf-profile-name',
      name: 'connectionName',
      label: 'Profile Name',
      type: WarehouseFormInputType.TEXT,
      gridArea: 'p',
      title: 'Profile Name',
      adminOnly: true,
      showHighlight: true,
      highlightDescription: 'You will find this in your <code>dbt_project.yml</code>.<br>Use <code>default</code> if you are getting started with an empty repo.',
    },
    {
      id: 'text-sf-target-name',
      name: 'targetName',
      label: 'Target',
      type: WarehouseFormInputType.TEXT,
      value: env || WarehouseEnv.DEV,
      gridArea: 't',
      adminOnly: true,
    },
    {
      id: 'text-sf-account-name',
      name: 'account',
      label: 'Account',
      type: WarehouseFormInputType.TEXT,
      gridArea: 'a',
      adminOnly: true,
      isPrivate: true,
      showHighlight: true,
      highlightDescription: 'Your Snowflake <code>account_identifier</code>.<br><br>Find out how to locate your account identifier in the Snowflake docs at https://docs.snowflake.com/en/user-guide/admin-account-identifier.',
    },
    ...authMethodDropdown,
    ...(selectedAuthMethod === AuthMethod.OAUTH ? clientIdClientSecret : []),
    ...(selectedAuthMethod === AuthMethod.OAUTH && showEditButton ? editSecretButton : []),
    ...(selectedAuthMethod === AuthMethod.KEY_PAIR ? keyPair : []),
    {
      title: (env === WarehouseEnv.PROD) ? 'Production Credentials' : 'Development Credentials',
      gridArea: 'D',
      type: WarehouseFormInputType.TITLE,
    },
    ...(selectedAuthMethod === AuthMethod.USERNAMEPASSWORD ? usernamePassword : []),
    ...(selectedAuthMethod !== AuthMethod.OAUTH && showEditButton ? editSecretButton : []),
    {
      id: 'text-sf-role',
      name: 'role',
      label: 'Role',
      type: WarehouseFormInputType.TEXT,
      gridArea: 'r',
    },
    {
      id: 'text-sf-database',
      name: 'database',
      label: 'Database',
      type: WarehouseFormInputType.TEXT,
      gridArea: 'd',
    },
    {
      id: 'text-sf-warehouse',
      name: 'warehouse',
      label: 'Warehouse',
      type: WarehouseFormInputType.TEXT,
      gridArea: 'w',
    },
    {
      id: 'text-sf-schema',
      name: 'schema',
      label: 'Schema',
      type: WarehouseFormInputType.TEXT,
      gridArea: 's',
    },
    {
      id: 'text-sf-threads',
      name: 'threads',
      label: 'Threads',
      type: WarehouseFormInputType.NUMBER,
      value: 8,
      additionalUserPlaceholder: 8,
      gridArea: 'T',
    },
  ]);
};
