import { Dispatch, SetStateAction } from 'react';
import { WarehouseFormInputType, DropdownOnChangeProps } from '../../warehouseFactory/types';
import { AuthMethod, ConnectionType } from './CommonConfig';
import { initialFormDataType } from '../../useGetInitialWarehouseData';
import { WarehouseEnv } from '../../utils';
import { DevWarehouseHelp, ProdWarehouseHelp } from '../../../AppHelp/help';
import { generateRegionsDropdownItems } from './Regions';

export const formDistribution = (showEditButton: boolean) => (
  showEditButton
    ? ("'P P P P P P' "
      + "'a a a a a a' "
      + "'d d d d d j' "
      + "'u u u u u u' "
      + "'p p p p p p' ")
    : ("'P P P P P P' "
      + "'a a a a a a' "
      + "'d d d d d d' "
      + "'u u u u u u' "
      + "'p p p p p p' ")
);

export const initialValues = {
  connectionName: 'dummy_connectionName',
  datasetName: '',
  location: 'EU',
  serviceAccountJson: '',
  targetName: 'dummy_targetName',
  threads: 1,
  authMethod: AuthMethod,
  projectId: '',
  clientId: '',
  clientSecret: '',
  connectionType: ConnectionType.SERVICEACCOUNT,
  costProjectId: '',
  costGcsBucketName: '',
};

export const formFields = (
  env: WarehouseEnv,
  selectedDatasetLocation: string,
  setSelectedDatasetLocation: Dispatch<SetStateAction<string>>,
  showEditButton: boolean,
  isEditingServiceAccountJson: boolean,
  setIsEditingServiceAccountJson: Dispatch<SetStateAction<boolean>>,
) => [
  {
    title: 'Connection Settings',
    gridArea: 'P',
    type: 'title',
    helpArticleId: (
      env === WarehouseEnv.PROD
        ? ProdWarehouseHelp.BIG_QUERY
        : DevWarehouseHelp.BIG_QUERY
    ),
  },
  {
    id: 'file-bq-service-account-json',
    name: 'serviceAccountJson',
    label: 'Service account JSON',
    type: WarehouseFormInputType.FILE,
    gridArea: 'a',
    firstUserOnly: true,
    additionalUserPlaceholder: 'paradime-bigquery.json',
    placeHolder: 'Choose a *.json file ...',
    color: 'primary',
    adminOnly: true,
    isPrivate: true,
    disabled: showEditButton ? !isEditingServiceAccountJson : false,
    isSecretField: true,
    isEditingSecretField: isEditingServiceAccountJson,
  },
  {
    id: 'text-bq-project-id',
    name: 'costProjectId',
    label: 'Project ID',
    type: WarehouseFormInputType.TEXT,
    gridArea: 'd',
    isPrivate: true,
    adminOnly: true,
  },
  {
    id: 'dropdown-bq-dataset-location',
    name: 'location',
    label: 'Dataset location',
    firstUserOnly: true,
    gridArea: 'u',
    adminOnly: true,
    maxHeight: 200,
    type: WarehouseFormInputType.DROPDOWN,
    menuItems: generateRegionsDropdownItems(setSelectedDatasetLocation),
    text: selectedDatasetLocation,
    onChange: ({
      setFormData,
      selectedItem,
    }: DropdownOnChangeProps) => {
      setFormData((currentFormData) => ({
        ...currentFormData,
        ...{
          location: selectedItem,
        } as initialFormDataType,
      }));
    },
    isPrivate: true,
  },
  {
    id: 'text-bq-gcs-bucket-name',
    name: 'costGcsBucketName',
    label: 'GCS Bucket name',
    type: WarehouseFormInputType.TEXT,
    gridArea: 'p',
    isPrivate: true,
    adminOnly: true,
  },
  ...(showEditButton
    ? [{
      name: 'editClientSecretButton',
      text: isEditingServiceAccountJson ? 'Cancel' : 'Edit',
      type: WarehouseFormInputType.BUTTON,
      gridArea: 'j',
      onClick: () => setIsEditingServiceAccountJson((prev) => !prev),
      adminOnly: true,
    }]
    : []
  ),
];
