import React, {
  FC,
  useState,
  useEffect,
} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import Input from '@paradime-io/pragma-ui-kit/lib/components/DefaultInput';
import Dropdown from '@paradime-io/pragma-ui-kit/lib/components/Dropdown';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { MenuTemplate } from '@paradime-io/pragma-ui-kit/lib/components/Menu';
import Loader from '@paradime-io/pragma-ui-kit/lib/components/Loader';
import {
  GqlAvailableWorkspace,
  useChangeUserInfoMutation,
  useGetProfileLazyQuery,
} from '../../../../client/generated/service';
import AutoJoinCheckbox from '../../AutoJoinCheckbox';
import { companyStore, graphQLStore, userAuthStore } from '../../../../stores';
import PageTemplate from '../Utils/PageTemplate';
import { useCompanyOnboardingInitialMutation } from '../../../../client/generated/control-plane';
import { ONBOARDING_PAGE, OnboardingRoutePrefix } from '../..';
import { AllRoles, Teams, mapTeamsToRoleObject } from '../Utils/TeamAndRolesConstants';
import { useOnboardUser } from '../../../../utilis/useOnboardUser';
import {
  userHasAccessLevelSet,
  userHasAccountSettingsConnectionsDevAccess,
} from '../../../../utilis/PermissionsService';

export interface formDataType {
  userName?: string,
  companyName?: string | null,
  teamPurpose?: string,
  peopleCount?: string,
  roleName?: string,
  onboardingToken?: null | string,
  sshPublicKey?: string,
  serviceGraphqlUrl?: string,
  controlPlaneUrl?: string
  dbtLevel?: string,
  enableAutoSignUp?: boolean,
  [key: string]: string | boolean | undefined | null | GqlAvailableWorkspace[]
}

export interface BasicInfoInterface {
  companyName?: BasicInfoElement<string>,
  teamName?: BasicInfoElement<Teams | ''>,
  dbtLevel?: BasicInfoElement<DbtLevels | ''>,
  roleName?: BasicInfoElement<AllRoles | ''>,
  enableAutoSignUp?: BasicInfoElement<boolean>,
}

export interface BasicInfoElement<T> {
  value: T,
  isValid: boolean,
  isRequired: boolean,
}

enum DbtLevels {
  BEGINEER = 'Beginner',
  INTERMEDIATE = 'Intermediate',
  ADVANCED = 'Advanced',
}

const BasicInfo: FC = () => {
  const { user } = useAuth0();
  const paradimeOrganisationFromStore = graphQLStore((state) => state.paradimeOrganisation);
  const { setIsBasicSetupComplete } = companyStore.getState();

  const [formLoading, setFormLoading] = useState(false);

  const { currentUser, setUser } = userAuthStore.getState();
  const { isFirstAdmin, accessLevel } = currentUser;

  const { canOnboardViaInviteToken } = useOnboardUser();

  const url = new URL(window.location.href);
  const origin = url.searchParams.get('origin');
  const onboardingToken = url.searchParams.get('onboarding_token');
  const wasAutoJoined = url.searchParams.get('automatically_onboarded') === 'true';

  const [getUserProfile] = useGetProfileLazyQuery({
    onCompleted: ({ userIdentity }) => {
      if (userIdentity?.userInformation) {
        setUser({ ...currentUser, ...userIdentity.userInformation });
      }
    },
  });

  // First admins do not have a company yet, so we cannot ask for their access level
  const isAccessLevelMissing = !userHasAccessLevelSet(accessLevel) && !isFirstAdmin;

  const isUserWithoutAdminAccess = (!userHasAccountSettingsConnectionsDevAccess(accessLevel))
    || wasAutoJoined
    || isAccessLevelMissing;

  const isCompanyCreation = !!onboardingToken;

  const history = useHistory();

  const [formData, setFormData] = useState<BasicInfoInterface>({
    enableAutoSignUp: { value: true, isValid: true, isRequired: true },
    dbtLevel: { value: '', isValid: false, isRequired: !isUserWithoutAdminAccess },
    teamName: { value: '', isValid: false, isRequired: true },
    roleName: { value: '', isValid: false, isRequired: true },
    companyName: { value: paradimeOrganisationFromStore?.name || '', isValid: true, isRequired: true },
  });

  useEffect(() => {
    if (!currentUser || Object.keys(currentUser).length === 0) {
      getUserProfile();
    }
  }, [currentUser]);

  useEffect(() => {
    if (paradimeOrganisationFromStore?.name) {
      setFormData((currFormData) => (
        {
          ...currFormData,
          companyName: {
            value: paradimeOrganisationFromStore.name,
            isValid: true,
            isRequired: true,
          },
        }
      ));
    }
  }, [paradimeOrganisationFromStore]);

  const [
    saveCompany,
    { data: companyData, loading: companyLoading },
  ] = useCompanyOnboardingInitialMutation({
    variables: {
      companyName: formData.companyName?.value || '',
      dbtLevel: formData.dbtLevel?.value,
      enableAutoSignUp: formData.enableAutoSignUp?.value,
      onboardingToken: onboardingToken || '',
      roleName: formData.teamName?.value || '',
      teamPurpose: formData.roleName?.value as string || '',
      userName: user?.name || '',
    },
  });

  const [
    updateUser,
    { data: userData, loading: userLoadingLoading },
  ] = useChangeUserInfoMutation({
    variables: {
      roleName: formData?.teamName?.value || '',
      teamPurpose: formData?.roleName?.value as string || '',
      dbtLevel: formData?.dbtLevel?.value || '',
    },
  });

  useEffect(() => {
    setFormLoading(companyLoading || userLoadingLoading);
  }, [companyLoading, userLoadingLoading]);

  useEffect(() => {
    if (companyData?.companyOnboardingInitial?.ok) {
      setIsBasicSetupComplete(true);
      history.push(`/${OnboardingRoutePrefix}/${ONBOARDING_PAGE.GETTING_STARTED}${window.location.search}`);
    }
  }, [companyData]);

  useEffect(() => {
    if (userData?.changeUserInfo?.ok) {
      setIsBasicSetupComplete(true);

      // User will have this param in their URL when they got
      // redirected here by the `checkOnboardingStatusOnce` hook.
      // In this case they can just fill in their basic info and
      // go back to whatever they were doing. No need for a full onboarding
      const isAskingForExtraBasicInfo = url.search.includes('extra_info');

      if (origin === 'chrome-extension') {
        history.push('/extension-onboarding-success');
      } else if (isUserWithoutAdminAccess || isAskingForExtraBasicInfo) {
        history.push('/home');
      } else {
        history.push(`/${OnboardingRoutePrefix}/${ONBOARDING_PAGE.GETTING_STARTED}${window.location.search}`);
      }
    }
  }, [userData, origin]);

  const handleClick = () => {
    if (isCompanyCreation) {
      saveCompany();
    } else {
      updateUser();
    }
  };

  const isButtonEnabled = () => {
    const allDataPresent = Object.values(formData)
      .filter(({ isRequired }) => isRequired)
      .every(({ value, isValid }) => !!value?.toString() && isValid);
    return allDataPresent;
  };

  if (canOnboardViaInviteToken && !onboardingToken) {
    return <Loader />;
  }

  return (
    <PageTemplate>
      <AutoLayout
        direction="vertical"
        padding="expanded"
        distribution="packed"
        verticalGap="very-dense"
        alignment="top-center"
        style={{ paddingTop: '2rem' }}
      >
        <Typography
          tagName="p"
          type="h4"
          color="default"
          colorStep="100"
          style={{ textAlign: 'center', margin: '0 auto 1rem auto' }}
        >
          Let&apos;s set up your profile
        </Typography>
        <form style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
        >
          <AutoLayout
            direction="vertical"
            padding="very-dense"
            verticalGap="very-dense"
            distribution="packed"
            alignment="top-center"
            style={{ padding: 0 }}
          >
            <Input
              type="text"
              full
              label="Your organization name"
              view="outlined"
              dataName="companyName"
              value={formData.companyName?.value}
              color="default"
              disabled
              data-testid="companyName"
            />
            {!isUserWithoutAdminAccess
              && (
                <Dropdown
                  content={(
                    <MenuTemplate
                      items={Object.values(DbtLevels).map((dbtLevel) => ({
                        text: dbtLevel,
                        type: 'item',
                        onClick: () => setFormData((current) => (
                          {
                            ...current,
                            dbtLevel: {
                              value: dbtLevel, isValid: true, isRequired: !isUserWithoutAdminAccess,
                            },
                          }
                        )),
                      }))}
                      eventContext={Contexts.MODAL}
                    />
                  )}
                  view={formLoading ? 'smooth' : 'raised'}
                  color="default"
                  fill
                  text={formData.dbtLevel?.value ? formData.dbtLevel.value : 'Choose your dbt level'}
                  label="How familiar are you with dbt?"
                  position="bottom"
                  data-testid="dbtLevel"
                  disabled={formLoading}
                />
              )}
            <Dropdown
              content={(
                <MenuTemplate
                  items={Object.values(Teams).map((team) => ({
                    text: team,
                    type: 'item',
                    onClick: () => setFormData((current) => (
                      {
                        ...current,
                        teamName: { value: team, isValid: true, isRequired: true },
                        roleName: { value: '', isValid: false, isRequired: true },
                      }
                    )),
                  }))}
                  eventContext={Contexts.MODAL}
                />
              )}
              view={formLoading ? 'smooth' : 'raised'}
              color="default"
              fill
              text={formData.teamName?.value.length ? formData.teamName?.value : 'Choose your team'}
              label="What team are you in?"
              position="bottom"
              data-testid="teamPurpose"
              disabled={formLoading}
            />
            <Dropdown
              content={(
                <MenuTemplate
                  items={formData.teamName?.value
                    ? Object.values(mapTeamsToRoleObject[formData.teamName.value])
                      .map((team) => ({
                        text: team,
                        type: 'item',
                        onClick: () => setFormData((current) => (
                          {
                            ...current,
                            roleName: { value: team, isValid: true, isRequired: true },
                          }
                        )),
                      })) : [{ text: 'Please choose a team first', type: 'item', disabled: true }]}
                  eventContext={Contexts.MODAL}
                />
              )}
              view={formLoading ? 'smooth' : 'raised'}
              color="default"
              fill
              text={formData.roleName?.value ? formData.roleName.value : 'Choose your role'}
              label="What is your role?"
              position="bottom"
              data-testid="roleName"
              disabled={formLoading}
            />
            {/* Companies created in retool app cannot choose autoJoin domain here */}
            {isFirstAdmin && isCompanyCreation && (
              <AutoJoinCheckbox
                isDisabled={formLoading}
                onClick={(isChecked) => (
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    enableAutoSignUp: {
                      value: isChecked, isValid: true, isRequired: true,
                    },
                  }))
                )}
              />
            )}
            <DefaultButton
              style={{
                minWidth: '100px',
                margin: '1rem auto 0 auto',
              }}
              view="filled"
              color="primary"
              text="Next"
              onClick={handleClick}
              disabled={!isButtonEnabled()}
              className="bp4-intent-6"
              data-testid="submit"
              eventContext={Contexts.MODAL}
              eventObject="profileInformation"
              eventAction={Actions.FILLED}
              eventProperties={{
                location: 'onboarding',
                company_name: formData.companyName,
                team: formData.teamName,
                role: formData.roleName,
              }}
            />
          </AutoLayout>
        </form>
      </AutoLayout>
    </PageTemplate>
  );
};

export default BasicInfo;
