import React, { FC } from 'react';
import { DateTime } from 'luxon';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import BillingItemRow from './BillingItemRow';
import { BillingItem, CustomerAddress } from './useGetBillingData';
import { key } from '../../../utilis';

interface BillingPageProps {
  billingData: BillingItem,
  index: number,
  hasMoreThanOnePlan: boolean,
}

const BillingPage: FC<BillingPageProps> = ({
  billingData,
  index,
  hasMoreThanOnePlan,
}) => {
  const billingAddress = (addressObject?: CustomerAddress) => [
    addressObject?.line1,
    addressObject?.line2,
    addressObject?.city,
    addressObject?.state,
    addressObject?.postal_code,
    addressObject?.country,
  ].filter((e) => (e !== '' && e !== null && e !== undefined)).join(', ');

  const billingFrequency = (frequency?: string) => {
    switch (frequency) {
      case 'month':
        return 'Monthly';
      case 'year':
        return 'Yearly';
      case 'week':
        return 'Weekly';
      case 'day':
        return 'Daily';
      default:
        return '';
    }
  };

  return (
    <>
      <AutoLayout
        direction="vertical"
        padding="none"
        verticalGap="dense"
        alignment="top-left"
      >
        <Typography tagName="span" type="h6">
          {hasMoreThanOnePlan ? `Plan ${index}` : 'Current plan'}
        </Typography>
        <BillingItemRow
          key={key(billingData?.name)}
          left="Plan"
          right={billingData?.name}
        />
        <BillingItemRow
          key={key(billingData?.description)}
          left="Your features"
          right={billingData?.description}
        />
        <BillingItemRow
          key={key(billingData?.billingInterval)}
          left="Billing frequency"
          right={billingFrequency(billingData?.billingInterval)}
        />
        <BillingItemRow
          key={key(billingData?.customerEmail)}
          left="Email"
          right={billingData.customerEmail}
        />
        <BillingItemRow
          key={key(billingData?.customerAddress?.line1)}
          left="Address"
          right={billingAddress(billingData?.customerAddress)}
        />
        <BillingItemRow
          key={key(billingData?.currentBillEndTimestamp?.toString())}
          left="Next invoice"
          right={(
            billingData?.currentBillEndTimestamp
              ? DateTime.fromSeconds(
                billingData.currentBillEndTimestamp,
              ).toLocaleString(DateTime.DATE_MED)
              : undefined
          )}
        />
      </AutoLayout>
    </>
  );
};

export default BillingPage;
