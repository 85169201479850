import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import {
  AddUser,
  AddUserAutomatically,
  GqlAvailableWorkspace,
  GqlParadimeAccountType,
  useAddUserAutomaticallyMutation,
  useAddUserMutation,
  useGetProfileLazyQuery,
} from '../client/generated/service';
import {
  CompanyOnboardingInitialUser,
  useCompanyOnboardingInitialUserMutation,
} from '../client/generated/control-plane';
import { companyStore, userAuthStore } from '../stores';
import { ONBOARDING_PAGE, OnboardingRoutePrefix } from '../components/Onboarding';
import { autoJoinSearchParamKey, AutoJoinStatus } from '../components/Platform/Workspaces';
import { PARADIME_ACCOUNT_TYPES_WITHOUT_UNKNOWN } from './PermissionsService';

export const useOnboardUser = () => {
  const history = useHistory();

  const [withRefreshAfterOnboarded, setWithRefreshAfterOnboarded] = useState(false);

  const { currentUser, setUser } = userAuthStore.getState();
  const userHasNoCompany = userAuthStore((s) => s.userHasNoCompany);
  const setAvailableAutoJoinWorkspaces = companyStore((s) => s.setAvailableAutoJoinWorkspaces);
  const [addAdditionalUserHasBeenCalled, setAddAdditionalUserHasBeenCalled] = useState(false);

  const setAccessLevel = (accessLevel: string) => (
    setUser({ ...currentUser, accessLevel: accessLevel as GqlParadimeAccountType })
  );

  const url = new URL(window.location.href);
  const inviteToken = url.searchParams.get('user_token');
  const onboardingToken = url.searchParams.get('onboarding_token');

  const refreshIfRequested = () => {
    if (withRefreshAfterOnboarded) window.location.reload();
  };

  const handleOnboardedResponse = (redirectUrl?: string) => {
    if (redirectUrl) {
      history.push(redirectUrl);
      refreshIfRequested();
    }
  };

  const [getUserProfile] = useGetProfileLazyQuery({
    onCompleted: (userProfileData) => {
      if (userProfileData.userIdentity?.userInformation) {
        setUser(userProfileData.userIdentity.userInformation);
      }
    },
  });

  const [onboardFirstAdmin] = useCompanyOnboardingInitialUserMutation({
    onCompleted: ({ companyOnboardingInitialUser }) => {
      const { redirectUrl } = handleOnboardFirstUserResponse({
        companyOnboardingInitialUser,
        setUser,
        currentUser,
      });
      handleOnboardedResponse(redirectUrl);
    },
  });

  const [addAdditionalUser] = useAddUserMutation({
    onCompleted: ({ addUser }) => {
      if (addUser?.userInformation) {
        setUser(addUser.userInformation);
      }

      // @ts-ignore - somehow the addUser types don't add up
      const { redirectUrl } = handleAddAdditionalUserResponse({ addUser, setAccessLevel });
      getUserProfile();
      handleOnboardedResponse(redirectUrl);
    },
  });

  const [autoJoinBusinessUser] = useAddUserAutomaticallyMutation({
    onCompleted: ({ addUserAutomatically }) => {
      if (addUserAutomatically?.userInformation) {
        setUser(addUserAutomatically.userInformation);
      }

      const { redirectUrl } = handleAutoJoinBusinessUserResponse({
        // @ts-ignore - somehow the addUserAutomatically types don't add up
        addUserAutomatically,
        setAvailableAutoJoinWorkspaces,
      });
      getUserProfile();
      handleOnboardedResponse(redirectUrl);
    },
  });

  const onboardUser = (workspaceId?: string, refreshAfterOnboardingComplete?: boolean) => {
    if (refreshAfterOnboardingComplete) setWithRefreshAfterOnboarded(true);

    if (userHasNoCompany) {
      setUser({ ...currentUser, isFirstAdmin: true });
      if (onboardingToken) {
        // The user has already been added to the company, no need to do it again
        // We are probably here again because the user refreshed the page
        console.info('Skipping adding the user as they already have an onboarding token in the URL'); // eslint-disable-line
        return;
      }
      console.info('Onboarding the user as a first admin'); // eslint-disable-line
      onboardFirstAdmin({ variables: { email: currentUser.email } });
      return;
    }

    if (inviteToken) {
      if (!addAdditionalUserHasBeenCalled) {
        console.info('Onboarding the user via invite token'); // eslint-disable-line
        setAddAdditionalUserHasBeenCalled(true);
        addAdditionalUser({ variables: { userToken: inviteToken } });
      }
      return;
    }

    // Try to autoJoin the user
    console.info('Onboarding the user via autoJoin'); // eslint-disable-line
    autoJoinBusinessUser({
      variables: {
        workspaceUid: workspaceId,
      },
    });
  };

  return {
    canOnboardViaInviteToken: !!inviteToken,
    onboardUser,
  };
};

const handleOnboardFirstUserResponse = ({ companyOnboardingInitialUser, setUser, currentUser }: {
  companyOnboardingInitialUser?: CompanyOnboardingInitialUser | null,
  setUser: (newUser: any) => void,
  currentUser: { [key: string]: any },
}) => {
  if (companyOnboardingInitialUser?.ok) {
    const {
      location: {
        pathname: currentPathname,
        search,
      },
    } = window;

    const { onboardingToken } = companyOnboardingInitialUser;

    // Push the onboardingToken into the URL so it can be used again later (even after refresh)
    const searchParams = new URLSearchParams(search);
    searchParams.set('onboarding_token', onboardingToken || '');

    // Overwrite pathname if it doesn't start with /onboarding/
    const pathname = currentPathname.startsWith('/onboarding/') ? currentPathname : '/onboarding/basic-info';

    const redirectUrl = `${pathname}?${searchParams.toString()}`;

    // Possibly need to update JWT token

    console.info('Successfully onboarded as first admin'); // eslint-disable-line
    return { redirectUrl };
  }

  console.info('Failed to onboard as first admin'); // eslint-disable-line
  setUser({ ...currentUser, isFirstAdmin: false });
  return { redirectUrl: '' };
};

const handleAddAdditionalUserResponse = ({ addUser, setAccessLevel }: {
  addUser?: AddUser | null,
  setAccessLevel: (lvl: GqlParadimeAccountType) => void
}) => {
  const {
    setIsDwhSetupComplete, setIsGitSetupComplete, setIsBasicSetupComplete,
  } = companyStore.getState();

  if (addUser) {
    const {
      ok,
      errorIsAlreadyOnboarded,
      errorTokenExpired,
      isBasicSetupIncomplete,
      isGitSetupIncomplete,
      isDwhSetupIncomplete,
      accountType,
    } = addUser;
    setIsDwhSetupComplete(!isDwhSetupIncomplete);
    setIsGitSetupComplete(!isGitSetupIncomplete);
    setIsBasicSetupComplete(!isBasicSetupIncomplete);

    const accessLevel = PARADIME_ACCOUNT_TYPES_WITHOUT_UNKNOWN
      .includes(accountType as GqlParadimeAccountType)
      ? accountType as GqlParadimeAccountType
      : GqlParadimeAccountType.Unknown;
    setAccessLevel(accessLevel);

    if (ok && !errorIsAlreadyOnboarded && !errorTokenExpired) {
      // User was added successfully - Decide where to send them
      let redirectUrl = '';
      if (isGitSetupIncomplete) {
        redirectUrl = `/${OnboardingRoutePrefix}/${ONBOARDING_PAGE.CONNECT_REPO}`;
      } else if (isDwhSetupIncomplete) {
        redirectUrl = `/${OnboardingRoutePrefix}/${ONBOARDING_PAGE.CONNECT_WAREHOUSE}`;
      } else if (!isGitSetupIncomplete && !isDwhSetupIncomplete) {
        // Everything is already done! [business user]
        redirectUrl = '/platform/workspaces';
      }
      console.info('Successfully onboarded via invite'); // eslint-disable-line
      return { redirectUrl };
    }
    if (errorIsAlreadyOnboarded) {
      console.info('Error was already onboarded - pushing to home'); // eslint-disable-line
      return { redirectUrl: '/home' };
    }
    if (errorTokenExpired) {
      console.info('Error invite token has expired'); // eslint-disable-line
      return { redirectUrl: '/invite-expired' };
    }
  }

  console.info('Failed to onboard via invite'); // eslint-disable-line
  return { redirectUrl: '' };
};

const handleAutoJoinBusinessUserResponse = ({
  addUserAutomatically,
  setAvailableAutoJoinWorkspaces,
}: {
  addUserAutomatically?: AddUserAutomatically | null,
  setAvailableAutoJoinWorkspaces: (workspaces: GqlAvailableWorkspace[]) => void,
}) => {
  // User was not able to be onboarded automatically
  if (addUserAutomatically?.onboardedComplete === false) {
    // Reason 1: There were multiple workspaces available
    // User needs to choose which to join
    if (addUserAutomatically.availableWorkspaces.length > 0) {
      setAvailableAutoJoinWorkspaces(addUserAutomatically.availableWorkspaces);
      const redirectUrl = `/platform/workspaces?${autoJoinSearchParamKey}=${AutoJoinStatus.IN_PROGRESS}`;
      console.info('Auto-joining; User needs to choose a workspace'); // eslint-disable-line
      return { redirectUrl };
    }

    // Reason 2: There were no workspaces available
    const {
      noWorkspaceAvailable,
      invalidUser,
    } = addUserAutomatically;
    if (noWorkspaceAvailable || invalidUser) {
      console.info('Failed to autoJoin, no workspaces available'); // eslint-disable-line
      return { redirectUrl: '/no-company' };
    }
  }

  if (addUserAutomatically?.ok) {
    console.info('Successfully auto-joined user to additional workspace'); // eslint-disable-line
    return { redirectUrl: '/platform/workspaces' };
  }

  console.info('Failed to auto-join user'); // eslint-disable-line
  return { redirectUrl: '' };
};
