import styled from 'styled-components';
import ReactFlow from 'reactflow';

const Lineage = styled.div`
  height:  100%;
  width: 100%;
  position: relative;
  overflow: hidden;
`;

const Body = styled.div`
  grid-area: b;
`;

const ReactFlowWrapper = styled.div`
  height: 100%;
`;

const ReactFlowWithoutAttribution = styled(ReactFlow)`
  .react-flow__attribution {
    display: none;
  }
`;

export {
  Lineage,
  Body,
  ReactFlowWrapper,
  ReactFlowWithoutAttribution,
};
