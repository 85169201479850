import React, {
  FC,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import { MenuTemplate } from '@paradime-io/pragma-ui-kit/lib/components/Menu';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import Dropdown from '@paradime-io/pragma-ui-kit/lib/components/Dropdown';
import { Contexts, Actions } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import Callout from '@paradime-io/pragma-ui-kit/lib/components/Callout';
import Checkbox from '@paradime-io/pragma-ui-kit/lib/components/CheckBox';
import { useUpdateUserInfoMutation, useUpdateOrgInfoMutation, useMagnetarUserIdentityQuery } from '../../../../client/generated/magnetar';
import PageTemplate from '../Utils/PageTemplate';
import OrgNameWithIcon from '../Utils/OrgNameWithIcon';
import { SynqOnboardingRoutePrefix, SYNQ_ONBOARDING_PAGE } from '..';
import synqBasicInfoSVG from '../images/synq-enter-org-name.svg';
import { companyStore, graphQLStore, userAuthStore } from '../../../../stores';

interface dropdownFieldType {
  text: string,
  placeholder: string,
  label: string,
  menuItems: string[],
  setterFunction: Dispatch<SetStateAction<string>>,
}

enum Teams {
  FINANCE = 'Finance',
  DATA = 'Data',
  SALES = 'Sales / Customer Success',
  MARKETING = 'Marketing',
  PRODUCT = 'Product / Engineering',
  SUPPORT = 'Customer Support',
  OTHER = 'Other',
}

type RolesType = { [key in Teams]: string[] };

const roleListData: RolesType = {
  [Teams.FINANCE]: [
    'Accountant',
    'Finance Analyst',
    'Treasury',
    'Finance Leader (Head / Director / VP / C-Suite)',
    'Other',
  ],
  [Teams.DATA]: [
    'Analytics Engineer',
    'Product / Data Analyst',
    'Data Engineer',
    'Product Manager',
    'Data Scientist',
    'Data Leader (Head / Director / VP / C-Suite)',
    'Other',
  ],
  [Teams.SALES]: [
    'Customer Success',
    'Sales Operations',
    'Sales Rep (SDR / AE)',
    'Sales Manager',
    'Sales Leader (Head / Director / VP / C-Suite)',
    'Other',
  ],
  [Teams.MARKETING]: [
    'Marketing Operations',
    'Product Marketing',
    'Demand Generation',
    'Marketing Manager',
    'Marketing Leader (Head / Director / VP / C-Suite)',
    'Other',
  ],
  [Teams.PRODUCT]: [
    'Engineer',
    'Researcher',
    'Designer',
    'Product Manager',
    'Product / Engineering Leader (Head / Director / VP / C-Suite)',
    'Other',
  ],
  [Teams.SUPPORT]: [
    'Support Operations',
    'Customer Support',
    'Support Manager',
    'Support Leader (Head / Director / VP / C-Suite)',
    'Other',
  ],
  [Teams.OTHER]: [
    'Data',
    'IT',
    'Legal',
    'Finance',
    'Leader (Head / Director / VP / C-Suite)',
    'Other',
  ],
};

const BasicInfo: FC = () => {
  const [team, setTeam] = useState('');
  const [roleList, setRoleList] = useState<string[]>([]);
  const [role, setRole] = useState('');
  const [orgSize, setOrgSize] = useState('');
  const [hasGQLError, setHasGQLError] = useState(false);
  const [hasFieldValueError, setHasFieldValueError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [userLevelDataIsSaved, setUserLevelDataIsSaved] = useState(false);
  const [orgLevelDataIsSaved, setOrgLevelDataIsSaved] = useState(false);
  const [allowAutoJoin, setAllowAutoJoin] = useState(true);
  const [isFirstAdmin, setIsFirstAdmin] = useState(true);

  const { setIsFirstAdmin: setIsFirstAdminInStore } = companyStore.getState();
  const paradimeOrganisationFromStore = graphQLStore((state) => state.paradimeOrganisation);
  const orgName = paradimeOrganisationFromStore?.name || '';
  const currentUser = userAuthStore((s) => s.currentUser);

  const history = useHistory();
  const { user } = useAuth0();

  useMagnetarUserIdentityQuery({
    onCompleted: (({ userIdentity }) => {
      setIsFirstAdminInStore(userIdentity?.isFirstAdmin || false);
      setIsFirstAdmin(userIdentity?.isFirstAdmin || false);
    }),
  });

  const additionalUserFields: dropdownFieldType[] = [
    {
      text: team,
      placeholder: 'Which team are you in?',
      label: 'Which team are you in?',
      menuItems: [...Object.values(Teams)],
      setterFunction: (teamName) => {
        // update the roles list
        setRoleList(roleListData[teamName as keyof RolesType]);

        // update team name
        setTeam(teamName);
      },
    },
    {
      text: role,
      placeholder: 'What is your role?',
      label: 'What is your role?',
      menuItems: roleList,
      setterFunction: setRole,
    },
  ];
  const firstAdminFields: dropdownFieldType[] = [
    ...additionalUserFields,
    {
      text: orgSize,
      placeholder: 'How big is your company?',
      label: 'How big is your company?',
      menuItems: ['1 - 10', '11 - 50', '51 - 100', '101 - 200', '201 - 500', '500+'],
      setterFunction: setOrgSize,
    },
  ];

  const formFields = isFirstAdmin ? firstAdminFields : additionalUserFields;
  const fieldsToValidate = isFirstAdmin ? [team, role, orgSize] : [team, role];

  const [setUserLevelData, {
    loading: isLoadingUserLevelData,
    error: userLevelError,
  }] = useUpdateUserInfoMutation({
    onCompleted: ({ updateUserInfo }) => {
      const { ok } = updateUserInfo;
      setUserLevelDataIsSaved(ok);
    },
  });

  const [setOrgLevelData, {
    loading: isLoadingOrgLevelData,
    error: orgLevelError,
  }] = useUpdateOrgInfoMutation({
    onCompleted: ({ updateOrgInfo }) => {
      const { ok } = updateOrgInfo;
      setOrgLevelDataIsSaved(ok);
    },
  });

  const handleClick = () => {
    setHasGQLError(false);
    setHasFieldValueError(false);

    if (fieldsToValidate.every((field) => field?.length > 0)) {
      setUserLevelData({ variables: { team, role } });

      if (isFirstAdmin) {
        let autoJoinDomain = '';
        if (allowAutoJoin) autoJoinDomain = getAutoJoinDomain();
        setOrgLevelData({ variables: { orgSize, autoJoinDomain } });
      }
    } else {
      // User is pressing the button without selecting any options
      setHasFieldValueError(true);
    }
  };

  useEffect(() => {
    if (userLevelError || orgLevelError) {
      setHasGQLError(true);
      setErrorMessage("We couldn't update your data");
    }
  }, [userLevelError, orgLevelError]);

  useEffect(() => {
    const dataIsSaved = isFirstAdmin
      ? (userLevelDataIsSaved && orgLevelDataIsSaved)
      : userLevelDataIsSaved;

    if (dataIsSaved) {
      const nextStepUrl = isFirstAdmin
        ? `/${SynqOnboardingRoutePrefix}/${SYNQ_ONBOARDING_PAGE.CONNECT_SLACK}`
        : '/home';
      history.push(nextStepUrl);
    }
  }, [userLevelDataIsSaved, orgLevelDataIsSaved, isFirstAdmin]);

  const getSpacingBeforeButton = () => {
    const spacing = hasGQLError ? 100 : 208;

    // First admin has an additional field (44px) and an additional row gap (32px)
    const reduction = isFirstAdmin ? 44 + 32 : 0;

    return `${spacing - reduction}px`;
  };

  const getAutoJoinDomain = () => {
    let email = '';
    if (user?.email) email = user.email;
    if (currentUser?.email) email = currentUser.email;
    return email.split('@')[1] || '';
  };

  return (
    <PageTemplate
      mainImage={synqBasicInfoSVG}
      title="Welcome to Synq"
      subtitle="Connecting data, analytics and business teams in a single collaborative workflow."
      withLogo
      withBox
      boxOrientation="vertical"
      boxContents={(
        <AutoLayout
          direction="vertical"
          padding="normal"
          distribution="packed"
          verticalGap="none"
          alignment="center"
        >
          <OrgNameWithIcon orgName={orgName} />
          <div style={{ height: isFirstAdmin ? '60px' : '124px' }} />
          <AutoLayout
            direction="vertical"
            padding="none"
            distribution="packed"
            verticalGap="expanded"
          >
            {formFields.map((field) => (
              <Dropdown
                view="simple"
                color="default"
                position="bottom"
                dense
                fill
                text={field.text || field.placeholder}
                isFilled={field.text?.length > 0}
                label={field.label}
                hasError={hasFieldValueError && field.text?.length === 0}
                content={(
                  <MenuTemplate
                    eventContext={Contexts.ONBOARDING}
                    dense
                    items={field.menuItems.map((item) => (
                      { text: item, type: 'item', onClick: () => field.setterFunction(item) }
                    ))}
                  />
                )}
              />
            ))}
            {isFirstAdmin && (
              <Checkbox
                type="dense"
                view="simple"
                label={`Allow users with @${getAutoJoinDomain()} email domain to signup and join my organization.`}
                checked={allowAutoJoin}
                onChange={(e) => setAllowAutoJoin(e.currentTarget.checked)}
                eventContext={Contexts.ONBOARDING}
                eventObject="enableAutoJoin"
                eventAction={Actions.CLICKED}
              />
            )}
          </AutoLayout>
          <div style={{ height: getSpacingBeforeButton() }} />
          {
            hasGQLError ? (
              <Callout
                dense
                view="outlined"
                color="danger"
                title="Oops! Something went wrong"
                content={errorMessage}
                icon="warning-sign"
                style={{ height: '108px', overflowY: 'scroll' }}
              />
            ) : undefined
          }
          <DefaultButton
            type="standard"
            view="simple"
            color="primary"
            text={hasGQLError ? 'Try again' : 'Continue'}
            fill={false}
            onClick={handleClick}
            loading={isLoadingUserLevelData || isLoadingOrgLevelData}
            eventContext={Contexts.ONBOARDING}
            eventObject="submitExtensionOnlyBasicInfoForm"
            eventAction={Actions.CLICKED}
            eventProperties={{
              email: user?.email || currentUser?.email,
              orgName: paradimeOrganisationFromStore?.name,
            }}
            style={{ maxWidth: '125px', margin: 'auto', marginTop: '10px' }}
          />
        </AutoLayout>
      )}
    />
  );
};

export default BasicInfo;
