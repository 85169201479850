/* eslint-disable no-nested-ternary */
import React, {
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ReactFlowProvider as ReactFlowProviderSource } from 'reactflow';
import { ThemeContext } from 'styled-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Loader from '@paradime-io/pragma-ui-kit/lib/components/Loader';
import { Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { lineageStore, userAuthStore } from '../../stores';
import Flow from './Flow';
import * as S from './Lineage.styles';
import Homepage from './HomePage';
import {
  useGetGitBranchInfoQuery, useOrganisationIntegrationListQuery,
} from '../../client/generated/service';
import background from './images/background.svg';
import CanvasControl from './CanvasControl';
import { useLocationState } from '../../utilis';
import { AllChecked } from './utils';
import { useSetBranches } from './hooks';
import { useGetIntegrationsList } from '../../integrations/useGetIntegrationsList';
import PageTitle, { PageTitles } from '../PageTitle';
import LineageUpgrade from '../Common/Upgrade/LineageUpgrade';
import { useWaitForLDToBeReady } from '../hooks/useWaitForLDToBeReady';

const ReactFlowProvider = ReactFlowProviderSource as unknown as FC<{ children: ReactNode }>;

interface LineageProps {
  currentPathname: string,
  initialTab: 'none' | 'search' | 'compare',
}

const Lineage: FC<LineageProps> = ({ currentPathname, initialTab }) => {
  const currentBranch = lineageStore((s) => s.currentBranch);
  const setCurrentBranch = lineageStore((s) => s.setCurrentBranch);
  const dbtPackageNames = lineageStore((s) => s.dbtPackageNames);
  const { currentUser } = userAuthStore.getState();

  const { ldIsReady } = useWaitForLDToBeReady();
  const { flagAppLineage } = useFlags();

  const [nodesFilter, setNodesFilter] = useState(['All selected', '']);
  const [initialLookerStatus, setInitialLookerStatus] = useState(false);
  const [packagesFilter, setPackagesFilter] = useState(['All selected', '']);
  const [tagsFilter, setTagsFilter] = useState(['All selected', '']);
  const [isEmptyComparison, setIsEmptyComparison] = useState(false);

  const { filePath, nodeId, lineageData } = useSetBranches(currentPathname, setIsEmptyComparison);
  const { data: branchesData } = useGetGitBranchInfoQuery();
  const { data: integrationsData } = useOrganisationIntegrationListQuery();

  const { accessLevel } = currentUser;

  const [state] = useLocationState();

  const isOpenedFromCodeEditor = state.previousRoute === 'code-editor';

  useEffect(() => {
    setInitialLookerStatus(integrationsData?.organisationIntegrationList.looker === 'active');
  }, [integrationsData]);

  useEffect(() => {
    const outerLoading = Boolean(branchesData?.getGitBranchInfo?.ok && accessLevel);
    if (outerLoading === false) {
      window.postMessage({
        type: 'lineage-loading-state',
        payload: !outerLoading,
      }, '*');
    }
  }, [branchesData, accessLevel]);

  const { darkMode } = useContext(ThemeContext);

  const integrationsList = useGetIntegrationsList();

  if (ldIsReady && !flagAppLineage) {
    return <LineageUpgrade />;
  }

  return (
    <>
      <PageTitle title={PageTitles.LINEAGE} />
      {(branchesData?.getGitBranchInfo?.ok && accessLevel)
        ? (
          <S.Lineage
            data-testid="lineageWrapper"
            style={{
              backgroundColor: darkMode ? 'var(--dark-slategrey)' : 'var(--grey0)',
              backgroundImage: /home/.test(currentPathname) ? `url(${background})` : 'none',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center 80%',
            }}
          >
            {lineageData && !isEmptyComparison
              ? (
                <S.ReactFlowWrapper>
                  <ReactFlowProvider>
                    <Flow
                      context={Contexts.LINEAGE}
                      lineageData={lineageData}
                      filePath={filePath}
                      nodesFilter={nodesFilter}
                      packagesFilter={packagesFilter}
                      tagsFilter={tagsFilter}
                      nodeId={nodeId}
                      cleanState
                    />
                    <CanvasControl
                      nodesFilter={nodesFilter}
                      setNodesFilter={setNodesFilter}
                      packagesFilter={packagesFilter}
                      setPackagesFilter={setPackagesFilter}
                      branchesData={branchesData}
                      initialLookerStatus={initialLookerStatus}
                      initialPackageChecks={AllChecked(dbtPackageNames)}
                      initialTab={initialTab}
                      lineageData={lineageData}
                      setCurrentBranch={setCurrentBranch}
                      currentBranch={currentBranch}
                      setTagsFilter={setTagsFilter}
                      tagsFilter={tagsFilter}
                      accessLevel={accessLevel}
                      integrationsList={integrationsList}
                    />
                  </ReactFlowProvider>
                </S.ReactFlowWrapper>
              )
              : ((/home/.test(currentPathname) || isEmptyComparison)
                ? (
                  <Homepage
                    initialTab={initialTab}
                    branchesData={branchesData}
                    currentBranch={currentBranch}
                    setCurrentBranch={setCurrentBranch}
                    accessLevel={accessLevel}
                    isOpenedFromCodeEditor={isOpenedFromCodeEditor}
                    isEmptyComparison={isEmptyComparison}
                    setIsEmptyComparison={setIsEmptyComparison}
                  />
                )
                : <Loader />
              )}
          </S.Lineage>
        )
        : <Loader />}
    </>
  );
};

export default Lineage;
