import React, { FC } from 'react';
import styled from 'styled-components';
import ProgressBarSource from '@paradime-io/pragma-ui-kit/lib/components/ProgressBar';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';

export interface ProgressBarItem {
  title: string,
  amount: number,
  label: string,
}

export interface ProgressBarListProps {
  progressBars: ProgressBarItem[],
}

const ProgressBar = styled(ProgressBarSource)`
  background: var(--white) !important;

  & > div[class*="progress-meter"] {
    border-radius: 100px 0 0 100px;
  }
`;

const ProgressBarList: FC<ProgressBarListProps> = ({ progressBars }) => (
  <AutoLayout
    direction="vertical"
    padding="none"
    verticalGap="dense"
    distribution="packed"
    alignment="top"
  >
    {progressBars.map(({ title, amount, label }) => (
      <AutoLayout
        key={`${title}-${amount}`}
        direction="vertical"
        padding="none"
        verticalGap="very-dense"
        distribution="packed"
      >
        <Typography type="caption" colorStep="80">
          {title}
        </Typography>
        <AutoLayout
          direction="horizontal"
          padding="none"
          horizontalGap="dense"
          distribution="packed"
          style={{ gridTemplateColumns: 'calc(100% - 40px - 16px) 40px' }}
        >
          <ProgressBar
            type="def"
            color="success"
            value={amount}
            stripes={false}
            animate={false}
          />
          <Typography type="caption">
            {label}
          </Typography>
        </AutoLayout>
      </AutoLayout>
    ))}
  </AutoLayout>
);

export default ProgressBarList;
