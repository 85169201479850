import { IconName } from '@blueprintjs/core';
import { catalogueNameAndIconProps } from '../types';

export const catalogueNameAndIcon = ({
  resultItem,
}: catalogueNameAndIconProps) => {
  switch (resultItem.__typename) {
    case 'GQLCatalogueLookerDashboardItem':
      return { icon: 'control' as IconName, name: resultItem.name };
    case 'GQLCatalogueLookerExploreItem':
      return { icon: 'compass' as IconName, name: resultItem.name };
    case 'GQLCatalogueLookerLookItem':
      return { icon: 'pie-chart' as IconName, name: resultItem.name };
    default:
      return { icon: 'error' as IconName, name: 'error' };
  }
};
