import React, { FC } from 'react';
import {
  Handle, Position, ReactFlowProvider,
} from 'reactflow';
import { Icon } from '@blueprintjs/core';
import { IconName } from '@blueprintjs/icons';
import { CSSProperties } from 'styled-components';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { colorType } from '@paradime-io/pragma-ui-kit/lib/components/styles/styleTypes';
import 'reactflow/dist/style.css';
import * as S from './DAG.styles';

export interface CommandNodeData {
  id: string,
  nodeName: string,
  nodeColor: colorType,
  icon: IconName,
  x: number,
  y: number,
  focussed: boolean,
  selected: boolean,
  onClick?: (e: CommandNodeData) => void,
  targetHandlePosition?: Position,
  sourceHandlePosition?: Position,
}

const commonHandleStyle: CSSProperties = { background: 'transparent', border: 'unset' };
const leftHandleStyle: CSSProperties = { left: 0 };
const rightHandleStyle: CSSProperties = { right: 0 };

export interface CommandNodeProps {
  data: CommandNodeData,
}

const CommandNode: FC<CommandNodeProps> = ({
  data,
}) => {
  const {
    selected,
    focussed,
    nodeColor,
    icon,
    nodeName,
    onClick,
    sourceHandlePosition,
    targetHandlePosition,
  } = data;

  const handleClick = () => {
    if (onClick) {
      onClick(data);
    }
  };

  return (
    <ReactFlowProvider>
      <S.NodeContainer
        color={nodeColor}
        selected={focussed ? false : selected}
        onClick={() => handleClick()}
      >
        <AutoLayout
          direction="vertical"
          distribution="packed"
          verticalGap="none"
          alignment="top-left"
          padding="none"
          style={{ borderRadius: '4px' }}
        >
          <AutoLayout
            direction="horizontal"
            padding="very-dense"
            distribution="packed"
            horizontalGap="very-dense"
            style={{ gridAutoColumns: 'unset', wordBreak: 'break-word' }}
          >
            <Typography type="caption" tagName="span" color={nodeColor} colorStep="70">
              <Icon icon={icon} size={16} />
            </Typography>
            <AutoLayout
              direction="vertical"
              distribution="packed"
              padding="none"
              verticalGap="very-dense"
              alignment="top-left"
            >
              <Typography type="node-bold" tagName="span">
                Command:
              </Typography>
              <Typography type="caption" tagName="span">
                {nodeName}
              </Typography>
            </AutoLayout>
          </AutoLayout>
        </AutoLayout>
      </S.NodeContainer>
      <Handle
        type="target"
        position={targetHandlePosition || Position.Left}
        style={{
          ...commonHandleStyle,
          ...leftHandleStyle,
        }}
      />
      <Handle
        type="source"
        position={sourceHandlePosition || Position.Right}
        id="a"
        style={{
          ...commonHandleStyle,
          ...sourceHandlePosition === Position.Left ? leftHandleStyle : rightHandleStyle,
        }}
      />
    </ReactFlowProvider>
  );
};

export default CommandNode;
