import React, { useState } from 'react';
import * as S from './Editor.styles';

interface Position {
  x: number;
  y: number;
}

const GlowBackground: React.FC = () => {
  const [mousePos, setMousePos] = useState<Position>({ x: 0, y: 0 });

  const handleMouseMove = (e: React.MouseEvent): void => {
    setMousePos({ x: e.clientX, y: e.clientY });
  };

  const glowPositioningStyle: React.CSSProperties = {
    left: mousePos.x,
    top: mousePos.y,
  };

  return (
    <S.GlowBackgroundContainer
      onMouseMove={handleMouseMove}
    >
      {/* Multiple glow effects */}
      <div
        className="primary-glow"
        style={glowPositioningStyle}
      />
      <div
        className="primary-glow secondary-glow"
        style={glowPositioningStyle}
      />

      {/* Grain texture */}
      <S.Grain />
    </S.GlowBackgroundContainer>
  );
};

export default GlowBackground;
