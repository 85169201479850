import { genericObject } from '@paradime/common/lib/common/genericTypes';

const addRadarCommands = (featureFlags?: genericObject) => {
  // open catalog
  if (featureFlags?.flagAppRadar) {
    window.CommandBar.addCommand({
      name: 'open_radar',
      text: 'Open radar',
      template: {
        type: 'link',
        value: '/radar/schedules',
        operation: 'router',
      },
      category: 'App',
      explanation: 'View all your analytics',
      tags: ['radar', 'analytics'],
    });
  }
};

export default addRadarCommands;
