import React from 'react';
import PageTemplate from '../Utils/PageTemplate';
import RepoProvider from '../../../Common/ConnectRepo/RepoProvider';
import { ONBOARDING_PAGE, OnboardingRoutePrefix } from '../..';

const ChooseRepoProvider = () => (
  <PageTemplate
    routeToPreviousStep={`/${OnboardingRoutePrefix}/${ONBOARDING_PAGE.CHOOSE_REPO_PROVIDER}`}
  >
    <RepoProvider
      repoProviderNextStepUrl={`/${OnboardingRoutePrefix}/${ONBOARDING_PAGE.CONNECT_WAREHOUSE}`}
      noRepoChosenUrl={`/${OnboardingRoutePrefix}/${ONBOARDING_PAGE.CHOOSE_REPO_PROVIDER}`}
    />
  </PageTemplate>
);

export default ChooseRepoProvider;
