/* eslint-disable react/require-default-props */
import Chips from '@paradime-io/pragma-ui-kit/lib/components/Chips';
import CalendarHeatmapMenuItem from '@paradime-io/pragma-ui-kit/lib/components/CalendarHeatmap/CalendarHeatmapMenuItem';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import React, { FC, useMemo, useState } from 'react';
import { Menu } from '@paradime-io/pragma-ui-kit/lib/components/Menu';
import MenuItem from '@paradime-io/pragma-ui-kit/lib/components/MenuItem';
import { colorType } from '@paradime-io/pragma-ui-kit/lib/components/styles/styleTypes';
import { checkNoCasesLeft, key } from '../../../utilis';
import { GqlTestWithStatus } from '../../../client/generated/service-dataEndpoint';

export enum TestStatus {
  'passed' = 'success',
  'success' = 'success',
  'broken' = 'danger',
  'warn' = 'warning',
  'failed' = 'danger',
  'error' = 'danger',
  'skipped' = 'danger',
  'missing' = 'default'
}

type statusKey = keyof typeof TestStatus

export type testType = GqlTestWithStatus & { state: statusKey }

interface itemValueProps {
  text?: string,
  color?: colorType,
  fontWeight?: 'inherit' | 'bold',
  textWidthAware?: boolean
}

export interface HoverableChipProps {
  test: testType,
}

const HoverableChip: FC<HoverableChipProps> = ({
  test: {
    name,
    results,
    state,
    testType,
    lastRun,
  },
}) => {
  const [hover, setHover] = useState(false);

  const ItemValue = ({
    text, color, textWidthAware, fontWeight = 'inherit',
  }: itemValueProps = {}) => {
    if (!text) return '-';

    return (
      <Typography
        type="caption-small"
        color={color}
        colorStep={color && '70'}
        style={{
          textTransform: 'uppercase',
          fontWeight,
          wordBreak: 'break-word',
          whiteSpace: 'break-spaces',
          width: textWidthAware
            ? Math.min(text.length * 6.5, 213)
            : 'unset',
        }}
      >
        {text}
      </Typography>
    );
  };

  const menuItems = useMemo(() => {
    let items: { [key: string]: JSX.Element | string } = {};

    switch (state) {
      case 'broken':
      case 'skipped':
      case 'warn':
      case 'error':
      case 'failed':
        items = {
          name: ItemValue({ text: name }),
          type: ItemValue({ text: testType }),
          'last run': ItemValue({ text: lastRun }),
          state: ItemValue({ text: state === 'broken' ? 'failed' : state, color: TestStatus[state], fontWeight: 'bold' }),
          results: ItemValue({ text: results, textWidthAware: true }),
        };
        break;

      case 'missing':
      case 'success':
      case 'passed':
        items = {
          name: ItemValue({ text: name }),
          type: ItemValue({ text: testType }),
          'last run': ItemValue({ text: lastRun }),
          status: ItemValue({ text: state, color: TestStatus[state], fontWeight: 'bold' }),
          results: ItemValue({ text: results, textWidthAware: true }),
        };
        break;

      default:
        checkNoCasesLeft(state);
        break;
    }

    return items;
  }, [state]);

  return (
    <div
      onPointerEnter={() => setHover(true)}
      onPointerLeave={() => setHover(false)}
      style={{ position: 'relative' }}
    >
      {hover
        && (
          <Menu
            type="dense"
            color="default"
            view="raised"
            style={{
              position: 'absolute',
              right: 0,
              bottom: 0,
              zIndex: 100,
              maxWidth: 300,
            }}
          >
            <MenuItem
              view="raised"
              type="dense"
              text={(
                <Typography
                  type="overline-small"
                  color="default"
                  colorStep="100"
                  style={{ marginBottom: 8 }}
                >
                  TEST DETAILS
                </Typography>
              )}
              eventContext={Contexts.CATALOG}
              eventObject="testDetails"
              eventAction={Actions.VIEWED}
            />
            {
              Object.entries(menuItems).map(([title, value]) => (
                <CalendarHeatmapMenuItem
                  key={key(title)}
                  title={title} // @ts-ignore
                  value={value}
                />
              ))
            }
          </Menu>
        )}
      <Chips
        type="dense"
        view="smooth"
        color={TestStatus[state]}
        tag={name}
        round={false}
        interactive={false}
      />
    </div>
  );
};

export default HoverableChip;
