import { useEffect, useState } from 'react';
import { DropdownOption } from './NotificationDropdown';
import {
  useSetAlertNotificationsMutation,
  KnockAlertChannelId,
  useRemoveAlertNotificationsMutation,
} from '../../../client/generated/service';
import { triggerToast } from '../../../utilis';
import { ConnectedNotificatonIntegration, NotificationType } from '.';

export interface useSetAlertNotificationsProps {
  connectedIntegration: ConnectedNotificatonIntegration,
  onCompleted?: () => void,
  notificationType: NotificationType,
}

export const useSetAlertNotifications = ({
  connectedIntegration,
  onCompleted,
  notificationType,
}: useSetAlertNotificationsProps) => {
  const [channelId, setChannelId] = useState<KnockAlertChannelId>();

  const handleCompleted = (isOk?: boolean) => {
    if (isOk) {
      triggerToast({ header: 'Changes to Bolt system alerts saved', type: 'positive' });
    } else {
      triggerToast({ header: 'Unable to save changes to Bolt system alerts', type: 'neutral' });
    }

    if (onCompleted) onCompleted();
  };

  const [setAlertNotifications] = useSetAlertNotificationsMutation({
    onCompleted: (data) => {
      handleCompleted(data.setAlertNotifications?.ok);
    },
  });

  const [removeAllNotifications] = useRemoveAlertNotificationsMutation({
    onCompleted: (data) => {
      handleCompleted(data.removeAlertNotifications?.ok);
    },
  });

  useEffect(() => {
    switch (connectedIntegration) {
      case ConnectedNotificatonIntegration.SLACK:
        if (notificationType === NotificationType.BOLT) {
          setChannelId(KnockAlertChannelId.SlackBoltAlert);
        }
        if (notificationType === NotificationType.RADAR) {
          setChannelId(KnockAlertChannelId.SlackDataAlert);
        }
        break;
      case ConnectedNotificatonIntegration.TEAMS:
        if (notificationType === NotificationType.BOLT) {
          setChannelId(KnockAlertChannelId.MsTeamsBoltAlert);
        }
        if (notificationType === NotificationType.RADAR) {
          setChannelId(KnockAlertChannelId.MsTeamsDataAlert);
        }
        break;
      default:
        break;
    }
  }, [connectedIntegration]);

  const saveAlertNotifications = (channelName: DropdownOption) => {
    if (!channelId) {
      console.error('Could not save alert notifications because cannot calculate channel ID'); // eslint-disable-line no-console
      return;
    }

    const variables = {
      alertChannel: channelId,
      msTeamsChannelNames: [] as string[],
      slackChannelIds: [] as string[],
      slackUserIds: [],
      emailsEnabled: false,
    };

    if (channelName.value) {
      switch (connectedIntegration) {
        case ConnectedNotificatonIntegration.SLACK:
          variables.slackChannelIds = [channelName.value];
          break;
        case ConnectedNotificatonIntegration.TEAMS:
          variables.msTeamsChannelNames = [channelName.value];
          break;
        default:
          break;
      }

      setAlertNotifications({ variables });
    } else {
      // User pressed "clear all" button
      removeAllNotifications({ variables: { alertChannel: channelId } });
    }
  };

  return {
    saveAlertNotifications,
  };
};
