import { catalogueItemTabsVisibilityProps } from '../types';

export const catalogueItemTabsVisibility = ({
  resultItem,
}: catalogueItemTabsVisibilityProps) => {
  switch (resultItem?.__typename) {
    case 'GQLCatalogueDBTExposureItem':
      return {
        overview: true,
        fields: false,
        metrics: false,
        columns: false,
        dependencies: true,
        recordings: false,
        schema: false,
        model: false,
      };
    default:
      return {
        overview: false,
        fields: false,
        metrics: false,
        columns: false,
        dependencies: false,
        recordings: false,
        schema: false,
        model: false,
      };
  }
};
