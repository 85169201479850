import { catalogueDbtModelTabProps, catalogueDbtModelTabReturnType } from '../types';

export const catalogueDbtModelTab = ({
  resultItem,
}: catalogueDbtModelTabProps): catalogueDbtModelTabReturnType => {
  switch (resultItem.__typename) {
    case 'GQLCatalogueMaterialisedDbtObjectColumnItem':
    case 'GQLCatalogueDwhColumnItem':
    case 'GQLCatalogueDwhTableItem':
    case 'GQLCatalogueMaterialisedDbtItem':
      return {
        dbtModelPath: resultItem.dbtModelPath,
      };

    default:
      return undefined;
  }
};
