import { IconName } from '@blueprintjs/core';
import { GraphicTypes } from '@paradime-io/pragma-ui-kit/lib/components/List';
import { catalogueDetailsCardProps } from '../types';
import {
  CatalogAssetTypes,
  checkIfArrayIsEmpty,
} from '../../components/Catalog/SearchRoutes/ResultTransformations';
import { formatDateTimeToLocalString } from '../../utilis';

export const catalogueDetailsCard = ({
  resultItem,
}: catalogueDetailsCardProps) => {
  const convertTrueFalseToYesNo = (value?: boolean | null) => {
    if (value === true) return 'Yes';
    if (value === false) return 'No';
    return '-';
  };

  switch (resultItem.__typename) {
    case 'GQLCatalogueLookerDashboardItem':
      return {
        components: [
          [
            {
              type: 'textWithHeader' as 'textWithHeader',
              header: 'type',
              text: CatalogAssetTypes.DASHBOARD,
              icon: { iconName: 'control' as IconName, color: 'var(--violet70)' },
            },
          ],
          [
            {
              type: 'textWithHeader' as 'textWithHeader',
              header: 'last viewed at',
              text: resultItem.lastViewedAt || '-',
            },
            {
              type: 'textWithHeader' as 'textWithHeader',
              header: 'updated at',
              text: formatDateTimeToLocalString({ dateTimeString: resultItem.updatedAt }),
            },
          ],
          [
            {
              type: 'textWithHeader' as 'textWithHeader',
              header: 'favorite count',
              text: resultItem.favouriteCount.toString() || '0',
              icon: { iconName: 'heart' as IconName },
            },
            {
              type: 'textWithHeader' as 'textWithHeader',
              header: 'view count',
              text: resultItem.viewCount.toString() || '0',
              icon: { iconName: 'eye-open' as IconName },
            },
          ],
          [
            {
              type: 'textWithHeader' as 'textWithHeader',
              header: 'folder',
              text: resultItem.fsName,
              icon: { iconName: 'box' as IconName },
            },
          ],
          [
            {
              type: 'list' as 'list',
              title: 'created by',
              subtitle: resultItem.fsOwnerName,
              graphic: {
                type: 'image' as GraphicTypes.IMAGE,
                image: resultItem.fsOwnerPicture && `https://via.placeholder.com/32/ededf0/?text=${resultItem.fsOwnerName.slice(0, 1) || 'X'}`,
              },
            },
            {
              type: 'list' as 'list',
              title: 'last updated by',
              subtitle: resultItem.lastUpdaterName || '-',
              graphic: {
                type: 'image' as GraphicTypes.IMAGE,
                image: `https://via.placeholder.com/32/ededf0/?text=${resultItem.lastUpdaterName.slice(0, 1) || 'X'}`,
              },
            },
          ],
        ],
      };
    case 'GQLCatalogueLookerExploreItem':
      return {
        components: [
          [
            {
              type: 'textWithHeader' as 'textWithHeader',
              header: 'type',
              text: CatalogAssetTypes.EXPLORE,
              icon: { iconName: 'compass' as IconName, color: 'var(--violet70)' },
            },
          ],
          [
            {
              type: 'textWithHeader' as 'textWithHeader',
              header: 'model',
              text: resultItem?.modelName || '-',
            },
          ],
          [
            {
              type: 'tags' as 'tags',
              header: 'tags',
              tags: checkIfArrayIsEmpty({ attribute: resultItem.tags }),
            },
          ],
        ],
      };
    case 'GQLCatalogueLookerLookItem':
      return {
        components: [
          [
            {
              type: 'textWithHeader' as 'textWithHeader',
              header: 'type',
              text: CatalogAssetTypes.LOOK,
              icon: { iconName: 'pie-chart' as IconName, color: 'var(--violet70)' },
            },
          ],
          [
            {
              type: 'textWithHeader' as 'textWithHeader',
              header: 'last accessed at',
              text: formatDateTimeToLocalString({ dateTimeString: resultItem.lastAccessedAt }),
            },
            {
              type: 'textWithHeader' as 'textWithHeader',
              header: 'updated at',
              text: formatDateTimeToLocalString({ dateTimeString: resultItem.updatedAt }),
            },
          ],
          [
            {
              type: 'textWithHeader' as 'textWithHeader',
              header: 'folder',
              text: resultItem.folder,
              icon: { iconName: 'box' as IconName },
            },
            {
              type: 'textWithHeader' as 'textWithHeader',
              header: 'explore',
              text: resultItem.exploreName || '-',
              icon: { iconName: 'compass' as IconName },
            },
          ],
          [
            {
              type: 'textWithHeader' as 'textWithHeader',
              header: 'favorite count',
              text: resultItem.favouriteCount.toString() || '0',
              icon: { iconName: 'heart' as IconName },
            },
            {
              type: 'textWithHeader' as 'textWithHeader',
              header: 'view count',
              text: resultItem.viewCount.toString() || '0',
              icon: { iconName: 'eye-open' as IconName },
            },
          ],
          [
            {
              type: 'textWithHeader' as 'textWithHeader',
              header: 'has table calculations',
              text: convertTrueFalseToYesNo(resultItem.hasTableCalculations),
            },
          ],
        ],
      };
    default:
      return { components: [] };
  }
};
