import React from 'react';
import { lineageSearchInputProps } from '../types';
import SearchMenuLabel from '../../components/Lineage/HomePage/SearchMenuLabel';
import { decodeLogoBase64, translateBEColourToFEColour } from '../utils';

export const lineageSearchInputDropdown = ({
  logoBase64,
  nodeTypeLabel,
  labelColour,
}: lineageSearchInputProps) => {
  const labelElement = (
    <SearchMenuLabel
      text={nodeTypeLabel || ''}
      color={translateBEColourToFEColour(labelColour).paradimeVariable || 'var(--yellow50)'}
    />
  );

  return {
    img: decodeLogoBase64(logoBase64) || '',
    labelElement,
  };
};
