import { catalogueSimpleTypeProps } from '../types';

export const catalogueSimpleType = ({
  resultItem,
}: catalogueSimpleTypeProps) => {
  switch (resultItem.__typename) {
    case 'GQLCatalogueMaterialisedDbtObjectColumnItem':
    case 'GQLCatalogueDwhColumnItem':
    case 'GQLCatalogueDwhTableItem':
    case 'GQLCatalogueMaterialisedDbtItem':
      return {
        simpleType: 'table',
        compoundType: 'DWH table',
      };
    default:
      return {
        simpleType: '',
        compoundType: '',
      };
  }
};
