import { IconName } from '@blueprintjs/core';
import { IntegrationHandler } from '..';
import { lineageFilterNodes } from './LineageFilterNodes';
import { lineageSearchInputDropdown } from './LineageSearchInputDropdown';
import { lineageNodeDisplay } from './LineageNodeDisplay';
import { lineageContextMenu } from './LineageContextMenu';

export enum HightouchHandlerAcceptedTypes {
  'HIGHTOUCH' = 'HIGHTOUCH',
  'HightouchSyncNode' = 'HightouchSyncNode',
  'HightouchModelNode' = 'HightouchModelNode',
  'hightouch' = 'hightouch',
}

export enum HightouchSubTypes {
  'HIGHTOUCH_SYNC' = 'Hightouch Sync',
  'HIGHTOUCH_MODEL' = 'Hightouch Model'
}

export enum LineageNodeTypeTranslations {
  'HightouchSyncNode' = 'Hightouch',
  'HightouchModelNode' = 'Hightouch',
}

export const HightouchIntegrationHandler: IntegrationHandler = ({
  type: HightouchHandlerAcceptedTypes.HIGHTOUCH,
  subTypes: Object.values(HightouchSubTypes),
  handlers: {
    lineageContextMenu,
    lineageSearchInputDropdown,
    lineageFilterNodes,
    catalogueSearchResultItem: () => [],
    catalogueDetailsCard: () => ({ components: [] }),
    catalogueNameAndIcon: () => ({ icon: '' as IconName, name: '' }),
    catalogueHeaderButtons: () => [],
    catalogueClassificationData: () => ({
      keyList: [],
      tableClassificationOptions: [],
    }),
    catalogueLogo: () => undefined,
    catalogueSimpleType: () => ({ simpleType: '', compoundType: '' }),
    catalogueDescription: () => '',
    lineageNodeDisplay,
    catalogueAssetColumns: () => [],
    catalogueAssetItemId: () => 'error',
    catalogueSearchFilters: () => undefined,
    catalogueItemTabsVisibility: () => ({
      overview: false,
      fields: false,
      metrics: false,
      columns: false,
      dependencies: false,
      recordings: false,
      schema: false,
      model: false,
    }),
    catalogueFieldsTab: () => ({ columnHeadings: [], data: [] }),
    catalogueDependenciesTab: () => ({
      sources: [],
    }),
    catalogueSchemaTab: () => [{
      tableName: '',
      columnData: [],
    }],
    catalogueDbtModelTab: () => undefined,
    catalogueTreeIcons: () => undefined,
    catalogueShowClassificationsCard: () => true,
  },
});
