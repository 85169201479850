export enum EnvVarHelp {
  PRODUCTION_ENV_VARIABLES = 'https://docs.paradime.io/app-help/documentation/settings/environment-variables/bolt-schedule-env-variables',
  USER_LEVEL_ENV_VARIABLES = 'https://docs.paradime.io/app-help/documentation/settings/environment-variables/code-ide-env-variables',
}

export enum AccountSettingsHelp {
  WORKSPACE_PROJECT_SETTINGS = 'https://docs.paradime.io/app-help/documentation/settings/workspaces',
  WORKSPACE_ENV_VAR = 'https://docs.paradime.io/app-help/documentation/settings/environment-variables/bolt-schedule-env-variables',
  WORKSPACE_REPOSITORY = 'https://docs.paradime.io/app-help/documentation/settings/git-repositories/update-connected-git-repository',
  WORKSPACE_API_KEYS = 'https://docs.paradime.io/app-help/developers/generate-api-keys',
  WORKSPACE_WEBHOOKS = 'https://docs.paradime.io/app-help/developers/webhooks/getting-started',
  WORKSPACE_TEAMS = 'https://docs.paradime.io/app-help/documentation/settings/users/manage-users',
  WORKSPACE_DEV_CONNECTIONS = 'https://docs.paradime.io/app-help/documentation/settings/connections/development-environment',
  WORKSPACE_PROD_CONNECTIONS = 'https://docs.paradime.io/app-help/documentation/settings/connections/scheduler-environment',
  WORKSPACE_COST_CONNECTIONS = 'https://docs.paradime.io/app-help/documentation/settings/connections/cost-connection',
}

export enum LineageHelp {
  SEARCH_LINEAGE = 'https://docs.paradime.io/app-help/documentation/data-catalog/lineage/search-and-discovery',
  COMPARE_LINEAGE = 'https://docs.paradime.io/app-help/documentation/data-catalog/lineage/compare-lineage-version',
  CHANGE_NODE_DEPTH = 'https://docs.paradime.io/app-help/documentation/data-catalog/lineage/filters-and-nodes-interaction',
}

export enum CatalogHelp {
  CATALOG_SEARCH_DISCOVERY = 'https://docs.paradime.io/app-help/documentation/data-catalog/lineage/search-and-discovery#search',
  CATALOG_FILTERING = 'https://docs.paradime.io/app-help/documentation/data-catalog/lineage/search-and-discovery#filter-your-search-results',
  CATALOG_DATA_ASSETS = 'https://docs.paradime.io/app-help/documentation/data-catalog/data-assets',
  CATALOG_DBT_ASSETS = 'https://docs.paradime.io/app-help/documentation/data-catalog/data-assets/dbt-assets',
  CATALOG_LOOKER_ASSETS = 'https://docs.paradime.io/app-help/documentation/data-catalog/data-assets/looker-assets',
  CATALOG_TABLEAU_ASSETS = 'https://docs.paradime.io/app-help/documentation/data-catalog/data-assets/tableau-assets',
  CATALOG_FIVETRAN_ASSETS = 'https://docs.paradime.io/app-help/documentation/data-catalog/data-assets/fivetran-assets',
}

export enum BoltHelp {
  BOLT_WRITING_SCHEDULES = 'https://docs.paradime.io/app-help/documentation/bolt/creating-schedules',
  BOLT_HELP_UI = 'https://docs.paradime.io/app-help/documentation/bolt/managing-schedules',
  THOUGHTSPOT = '363848',
  COMMANDS = 'https://docs.paradime.io/app-help/documentation/bolt/creating-schedules/command-settings',
}

export enum RadarHelp {
  SCHEDULES = 'https://docs.paradime.io/app-help/documentation/radar/dbt-monitoring/schedules-dashboard',
  MODELS = 'https://docs.paradime.io/app-help/documentation/radar/dbt-monitoring/models-dashboard',
  SOURCES = 'https://docs.paradime.io/app-help/documentation/radar/dbt-monitoring/sources-dashboard',
  TESTS = 'https://docs.paradime.io/app-help/documentation/radar/dbt-monitoring/tests-dashboard',
  WORK = 'https://docs.paradime.io/app-help/documentation/radar/team-efficiency-tracking',
  COST = 'https://docs.paradime.io/app-help/documentation/radar/cost-management',
  LOOKER = 'https://docs.paradime.io/app-help/documentation/radar/looker-monitoring',
  OPTIMISATIONS = 'https://docs.paradime.io/app-help/documentation/radar/cost-management/snowflake-cost-optimization',
}

export enum IntegrationsHelp {
  SLACK = 'https://docs.paradime.io/app-help/documentation/integrations/notifications/slack',
  LOOKER = 'https://docs.paradime.io/app-help/documentation/integrations/dashboards/looker',
  TABLEAU = 'https://docs.paradime.io/app-help/documentation/integrations/dashboards/tableau',
  POWER_BI = 'https://docs.paradime.io/app-help/documentation/integrations/dashboards/power-bi',
  FIVETRAN = 'https://docs.paradime.io/app-help/documentation/integrations/etl/fivetran',
  HIGHTOUCH = 'https://docs.paradime.io/app-help/documentation/integrations/reverse-etl/hightouch',
  GITHUB = 'https://docs.paradime.io/app-help/documentation/integrations/ci-cd/github',
  DBT_CLOUD = 'https://docs.paradime.io/app-help/guides/dbt-cloud-tm-importer',
  THOUGHTSPOT = 'https://docs.paradime.io/app-help/documentation/integrations/dashboards/thoughtspot',
  SIGMA = 'https://docs.paradime.io/app-help/documentation/integrations/dashboards/sigma',
  TEAMS = 'https://docs.paradime.io/app-help/documentation/integrations/notifications/microsoft-teams',
}

export enum GitHelp {
  AZURE_DEV_OPS = 'https://docs.paradime.io/app-help/documentation/settings/git-repositories/importing-a-repository/azure-devops',
  BIT_BUCKET = 'https://docs.paradime.io/app-help/documentation/settings/git-repositories/importing-a-repository/bitbucket',
  GITHUB = 'https://docs.paradime.io/app-help/documentation/settings/git-repositories/importing-a-repository/github',
  GITLAB = 'https://docs.paradime.io/app-help/documentation/settings/git-repositories/importing-a-repository/gitlab',
}

export enum DevWarehouseHelp {
  ATHENA = 'https://docs.paradime.io/app-help/documentation/settings/connections/development-environment/amazon-athena',
  BIG_QUERY = 'https://docs.paradime.io/app-help/documentation/settings/connections/development-environment/bigquery162710',
  CLICKHOUSE = 'https://docs.paradime.io/app-help/documentation/settings/connections/development-environment/clickhouse',
  DATABRICKS = 'https://docs.paradime.io/app-help/documentation/settings/connections/development-environment/databricks',
  DREMIO = 'https://docs.paradime.io/app-help/documentation/settings/connections/development-environment/dremio',
  DUCK_DB = 'https://docs.paradime.io/app-help/documentation/settings/connections/development-environment/duckdb',
  FIREBOLT = 'https://docs.paradime.io/app-help/documentation/settings/connections/development-environment/firebolt',
  MS_FABRIC = 'https://docs.paradime.io/app-help/documentation/settings/connections/development-environment/microsoft-fabric',
  MS_SQL_SERVER = 'https://docs.paradime.io/app-help/documentation/settings/connections/development-environment/microsoft-sql-server',
  MOTHERDUCK = 'https://docs.paradime.io/app-help/documentation/settings/connections/development-environment/motherduck',
  POSTGRESQL = 'https://docs.paradime.io/app-help/documentation/settings/connections/development-environment/postgresql',
  REDSHIFT = 'https://docs.paradime.io/app-help/documentation/settings/connections/development-environment/redshift',
  SNOWFLAKE = 'https://docs.paradime.io/app-help/documentation/settings/connections/development-environment/snowflake',
  STARTBURST_TRINO = 'https://docs.paradime.io/app-help/documentation/settings/connections/development-environment/starburst-trino',
  OPTIONAL_CONFIG = 'https://docs.paradime.io/app-help/documentation/settings/connections/optional-connection-attributes',
}

export enum ProdWarehouseHelp {
  ATHENA = 'https://docs.paradime.io/app-help/documentation/settings/connections/scheduler-environment/amazon-athena',
  BIG_QUERY = 'https://docs.paradime.io/app-help/documentation/settings/connections/scheduler-environment/bigquery',
  CLICKHOUSE = 'https://docs.paradime.io/app-help/documentation/settings/connections/scheduler-environment/clickhouse',
  DATABRICKS = 'https://docs.paradime.io/app-help/documentation/settings/connections/scheduler-environment/databricks',
  DREMIO = 'https://docs.paradime.io/app-help/documentation/settings/connections/scheduler-environment/dremio',
  DUCK_DB = 'https://docs.paradime.io/app-help/documentation/settings/connections/scheduler-environment/duckdb',
  FIREBOLT = 'https://docs.paradime.io/app-help/documentation/settings/connections/scheduler-environment/firebolt',
  MS_FABRIC = 'https://docs.paradime.io/app-help/documentation/settings/connections/scheduler-environment/microsoft-fabric',
  MS_SQL_SERVER = 'https://docs.paradime.io/app-help/documentation/settings/connections/scheduler-environment/microsoft-sql-server',
  MOTHERDUCK = 'https://docs.paradime.io/app-help/documentation/settings/connections/scheduler-environment/motherduck',
  POSTGRESQL = 'https://docs.paradime.io/app-help/documentation/settings/connections/scheduler-environment/postgresql',
  REDSHIFT = 'https://docs.paradime.io/app-help/documentation/settings/connections/scheduler-environment/redshift',
  SNOWFLAKE = 'https://docs.paradime.io/app-help/documentation/settings/connections/scheduler-environment/snowflake',
  STARTBURST_TRINO = 'https://docs.paradime.io/app-help/documentation/settings/connections/scheduler-environment/starburst-trino',
}

export enum PlatformSettingsHelp {
  AUDIT_LOGS = 'https://docs.paradime.io/app-help/documentation/security/audit-logs',
}
