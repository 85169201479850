import dagre from 'dagre';
import { NodeData as NodeDataAttributes } from '@paradime-io/pragma-ui-kit/lib/components/LineageNode/ElementTypes';
import { lineageStore } from '../../stores';
import { ComputeLineageQuery, BranchDiffLineageQuery } from '../../client/generated/service-dataEndpoint';
import { NodeType, LinkType } from './utils';
import { DbtHandlerAcceptedTypes } from '../../integrations/Dbt';

const isDbtNode = (nodeType: string) => {
  const dbtNodeTypes = Object.keys(DbtHandlerAcceptedTypes);
  const isDbt = dbtNodeTypes.findIndex((dbtNode) => dbtNode === nodeType);
  return isDbt > -1;
};

interface createGraphFromFlowProps {
  nodes: NodeType[],
  edges: LinkType[],
}
export const createGraphFromFlow = ({
  nodes,
  edges,
}: createGraphFromFlowProps) => {
  const dagreGraph = new dagre.graphlib.Graph<NodeDataAttributes>();

  const graphOptions = getGraphOptions();

  dagreGraph.setGraph(graphOptions);

  dagreGraph.setDefaultEdgeLabel(() => ({}));

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, {
      id: node.id,
      name: node.data?.name,
      filePath: node.data?.filePath,
      url: node.data?.url,
      nodeType: node.data?.nodeType,
      packageName: isDbtNode(node.data.nodeType) ? node.data?.packageName : '',
      tags: node.data?.tags,
      nodeName: node.data?.nodeName,
      nodeChanged: node.data?.selected,
    });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  return dagreGraph;
};

const getGraphOptions = () => ({
  rankdir: 'LR',
  align: 'UR',
  edgesep: 100,
  nodesep: 100,
  ranksep: 600,
});

export const createGraphWithNodeName = (
  lineageData: ComputeLineageQuery | BranchDiffLineageQuery,
) => {
  const dagreGraph = new dagre.graphlib.Graph<NodeDataAttributes>();

  const graphOptions = getGraphOptions();

  dagreGraph.setGraph(graphOptions);
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  if ((lineageData as ComputeLineageQuery).computeLineage) {
    (lineageData as ComputeLineageQuery).computeLineage?.results.forEach((result) => {
      const { nodeName: nodeNameFromStore } = lineageStore.getState();
      const { setNodeName: setNodeNameFromStore } = lineageStore.getState();
      const { setNodeId: setNodeIdFromStore } = lineageStore.getState();
      if (nodeNameFromStore && result.nodeName === nodeNameFromStore) {
        const { setNodeHumanReadableName } = lineageStore.getState();
        setNodeNameFromStore('');
        setNodeHumanReadableName(result.canonicalName.name);
        setNodeIdFromStore(result.nodeId);
      }
      dagreGraph.setNode(result.nodeId, {
        id: result.nodeId,
        name: result.canonicalName.name,
        filePath: result.filePath,
        url: result.url,
        nodeType: result.nodeType,
        packageName: isDbtNode(result.nodeType) ? result.canonicalName.packageName : '',
        tags: result.tags,
        nodeName: result.nodeName,
      });
      if (result.parentNodeIds.length > 0) {
        result.parentNodeIds.forEach((parent) => {
          dagreGraph.setEdge(parent, result.nodeId);
        });
      }
    });
  } else {
    const { results } = (lineageData as BranchDiffLineageQuery).branchDiffLineage!;
    results.forEach((result) => {
      if (result.left) {
        dagreGraph.setNode(result.left.nodeId, {
          id: result.left.nodeId,
          name: result.left.canonicalName.name,
          filePath: result.left.filePath,
          url: result.left.url,
          nodeType: result.left.nodeType,
          nodeChanged: result.nodeChanged,
          packageName: isDbtNode(result.left.nodeType) ? result.left.canonicalName.packageName : '',
          tags: result.left.tags,
          nodeName: result.left.nodeName,
        });
      } else if (result.right) {
        dagreGraph.setNode(result.right.nodeId, {
          id: result.right.nodeId,
          name: result.right.canonicalName.name,
          filePath: result.right.filePath,
          url: result.right.url,
          nodeType: result.right.nodeType,
          nodeChanged: result.nodeChanged,
          packageName: isDbtNode(result.right.nodeType) ? result.right.canonicalName.packageName : '',
          tags: result.right.tags,
          nodeName: result.right.nodeName,
        });
      }
    });
    results.forEach((result) => {
      result.unionParentIds.forEach((parent) => {
        if (dagreGraph.node(parent)) {
          if (result.left) {
            dagreGraph.setEdge(parent, result.left.nodeId);
          } else if (result.right) {
            dagreGraph.setEdge(parent, result.right.nodeId);
          }
        }
      });
    });
  }
  dagre.layout(dagreGraph);

  return dagreGraph;
};
