import { catalogueAssetColumnsProps } from '../types';

export const catalogueAssetColumns = ({
  datum,
}: catalogueAssetColumnsProps) => {
  switch (datum.__typename) {
    case 'GQLCatalogueDwhTableItem':
      return datum.columns;
    case 'GQLCatalogueMaterialisedDbtItem':
      return datum.dbtColumns;
    case 'GQLCatalogueDwhColumnItem':
      return datum.table.columns;
    case 'GQLCatalogueMaterialisedDbtObjectColumnItem':
      return datum.dbtItem.dbtColumns;
    default:
      return [];
  }
};
