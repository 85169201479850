import { Toast } from 'primereact/toast';
import { DialogProps } from '@paradime-io/pragma-ui-kit/lib/components/Dialog';
import { ToasterType } from '@paradime-io/pragma-ui-kit/lib/components/Toaster';
import { MutableRefObject } from 'react';
import create from 'zustand';
import { combine } from 'zustand/middleware';

export const alertStore = create(
  combine(
    {
      currentAlert: undefined as DialogProps | undefined,
      toaster: { current: null } as MutableRefObject<Toast | null>,
      toasterType: 'neutral' as ToasterType,
    },
    (set) => ({
      openAlert: (alert: DialogProps) => set(() => ({
        currentAlert: { ...alert, isOpen: true },
      })),
      closeAlert: () => set((state) => ({
        currentAlert: { ...state.currentAlert, isOpen: false },
      })),
      setToaster: (newToaster: MutableRefObject<Toast | null>) => set(() => ({
        toaster: newToaster,
      })),
      changeToasterType: (newToasterType: ToasterType) => set(() => ({
        toasterType: newToasterType,
      })),
    }),
  ),
);
