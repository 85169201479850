import React from 'react';
import { catalogueTreeIconsProps } from '../types';
import TableauLogo from '../../components/Catalog/images/tableau.svg';

export const catalogueTreeIcons = ({ type }: catalogueTreeIconsProps) => {
  switch (type) {
    case 'tableau':
      return <img src={TableauLogo} alt="" />;
    case 'dashboard':
      return 'control';
    case 'dashboards':
    case 'datasources':
    case 'worksheets':
      return 'folder-close';
    case 'datasource':
      return 'compass';
    case 'worksheet':
      return 'pie-chart';
    default:
      return 'database';
  }
};
