import {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import * as Sentry from '@sentry/react';
import { useHistory } from 'react-router-dom';
import { graphQLStore, userAuthStore } from '../../../stores';
import { useEditorHeartbeatMutation } from '../../../client/generated/editor';
import { userHasAccessLevelSet, userHasEditorAccess } from '../../../utilis/PermissionsService';

export const EditorUrlContext = createContext(
  { url: '', setUrl: (url: string) => {} }, // eslint-disable-line
);

const useSetFirstEditor = (
  onboardingToken?: string,
  accountType?: string,
) => {
  const timesEditorReturnedFalse = useRef(0);
  const [isReady, setIsReady] = useState<boolean>();

  const history = useHistory();

  const apolloClient = graphQLStore((s) => s.apolloClient);
  const { accessLevel } = userAuthStore((s) => s.currentUser);

  const { url: editorUrl, setUrl: setEditorUrl } = useContext(EditorUrlContext);

  const [
    askForEditor,
    { data: editorData },
  ] = useEditorHeartbeatMutation();

  useEffect(() => {
    if (editorData?.editorHeartbeat?.started === true) {
      setEditorUrl(editorData.editorHeartbeat.editorUrl || '');
      setIsReady(true);
    } else if (editorData?.editorHeartbeat?.started === false) {
      setIsReady(false);
      setEditorUrl('');
      const waitAndUpdateCounter = async () => {
        timesEditorReturnedFalse.current += 1;
        console.info(timesEditorReturnedFalse.current, 'Waiting for editor...'); // eslint-disable-line no-console

        await new Promise((resolve) => setTimeout(resolve, 1000));

        if (timesEditorReturnedFalse.current < 1800) {
          askForEditor();
        } else {
          console.error('Editor not found after 1800 times'); // eslint-disable-line no-console
          Sentry.captureMessage('Editor not found after 1800 times');
          history.push('/error');
        }
      };

      waitAndUpdateCounter();
    }
  }, [editorData]); // eslint-disable-line

  useEffect(() => {
    const isNotControlPlane = !(apolloClient?.link as any).options?.uri;
    const isNotAfterOnboarding = !onboardingToken && !accountType;
    const isLocalHost = Boolean(process.env.REACT_APP_DEV_EDITOR_URL);
    const isOnboardingRoute = window.location.pathname.includes('onboarding');

    if (isNotControlPlane && isNotAfterOnboarding && userHasAccessLevelSet(accessLevel)) {
      if (isLocalHost) {
        setIsReady(true);
        setEditorUrl(process.env.REACT_APP_DEV_EDITOR_URL!);
      } else if (userHasEditorAccess(accessLevel!) && !isOnboardingRoute) {
        askForEditor();
      }
    }
  }, [apolloClient, accessLevel, window.location.pathname]);

  return {
    editorUrl,
    setEditorUrl,
    isReady,
  };
};

export default useSetFirstEditor;
