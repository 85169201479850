import React from 'react';
import { colorType } from '@paradime-io/pragma-ui-kit/lib/components/styles/styleTypes';
import { lineageNodeDisplayProps } from '../types';
import { fivetranSourceLogo } from '../Fivetran';
import { decodeLogoBase64, translateBEColourToFEColour } from '../utils';
import { GqlLineageNodeFivetranAttributes } from '../../client/generated/service-dataEndpoint';

export const lineageNodeDisplay = ({ lineageData }: lineageNodeDisplayProps) => ({
  nodePrimaryLogo: (
    <img
      src={decodeLogoBase64(lineageData?.logoBase64) || fivetranSourceLogo}
      alt="source fivetran"
      width={16}
      height={16}
      style={{
        backgroundColor: 'var(--grey0)',
        borderRadius: '50%',
        border: '2px solid #0073FF',
      }}
    />
  ),
  nodeSecondaryLogo: (
    <img
      src={(lineageData?.attributes as GqlLineageNodeFivetranAttributes)?.sourceLogoURL || ''}
      alt="source secondary logo"
      width={16}
      height={16}
      style={{
        backgroundColor: 'var(--grey0)',
        borderRadius: '50%',
        border: '2px solid #0073FF',
      }}
    />
  ),
  nodeColor: translateBEColourToFEColour(lineageData?.color).variantName as colorType || 'success' as colorType,
  nodeTypeName: 'Fivetran',
});
