/* eslint-disable react/require-default-props */
import React, { useContext, useEffect, useState } from 'react';
import { Actions, Contexts, EventContextsType } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import Callout from '@paradime-io/pragma-ui-kit/lib/components/Callout';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import { EditableContext } from '../../../../components/AccountSettings/Connections';
import * as S from './warehouses.styles';
import { WarehouseEnv, WarehouseType } from '../../Warehouses/utils';
import { WareHouseModalSource } from '..';
import useGetWarehouseComponents from './useGetWarehouseComponents';
import { initialFormDataType } from '../useGetInitialWarehouseData';
import { useCheckValidation, ValidationRules } from '../../../../utilis/useCheckValidation';
import { CheckboxType, WarehouseComponentType, WarehouseFormInputType } from './types';
import { BUTTON_STATE_TEXT, ResetSpecificFieldMessageProps } from '../Definitions/CommonConfig';
import { userAuthStore } from '../../../../stores';
import { userHasPlatformWorkspaceCreateAccess } from '../../../../utilis/PermissionsService';
import OptionalConfig from './OptionalConfig';

interface WareHouseFactoryProps<T> {
  expose: Function,
  warehouse: WarehouseType<T>,
  prefilled: boolean,
  viewEditFormData?: { [key: string]: string | number | boolean | undefined },
  env: WarehouseEnv,
  source: WareHouseModalSource,
  loading: boolean,
  eventContext: EventContextsType,
  initialFormData?: initialFormDataType,
  isNewConnection?: boolean,
  closeModal: () => void,
  credentialId?: string,
  warehouseName?: string,
}

export interface onComponentChangeProps {
  value: string | number | boolean,
  name: string,
  cancels?: string,
}

export interface cancelsLogicProps {
  cancels: string,
  value: string | number | boolean,
}

interface getKeyFromDataProps {
  key: keyof initialFormDataType,
  data?: initialFormDataType,
}

const WareHouseFactory = <T, >({
  expose,
  warehouse,
  prefilled,
  viewEditFormData,
  env,
  source,
  loading,
  eventContext,
  initialFormData,
  isNewConnection,
  closeModal,
  credentialId,
  warehouseName,
}: WareHouseFactoryProps<T>) => {
  const { setIsEditable, isEditable } = useContext(EditableContext);
  const { currentUser } = userAuthStore.getState();

  const { getWarehouseComponents } = useGetWarehouseComponents(warehouseName);
  const { accessLevel } = currentUser;
  const { checkValidation } = useCheckValidation();

  const [formData, setFormData] = useState(initialFormData);
  const [validationErrors, setValidationErrors] = useState<{
    name: string, validationMessage: string,
  }[]>();
  const [optionalConfig, setOptionalConfig] = useState(viewEditFormData?.redactedProfile?.toString() || '');

  const isAdditionalUser = prefilled || !userHasPlatformWorkspaceCreateAccess(accessLevel);

  const {
    onboarded,
    errorMessage,
    form,
    formDistribution,
    submitButtonText,
    onSubmitButtonClick,
    dataIsBeingChecked,
    initialFormData: initialBlankData,
    showOptionalConfig,
  } = warehouse.useDatabaseHook({
    formData,
    env,
    source,
    isEditable,
    isAdditionalUser,
    isNewConnection: Object.keys(viewEditFormData || {}).length === 0,
  });

  const [cleanInitialData, setCleanInitialData] = useState({
    ...initialBlankData, ...initialFormData,
  });

  useEffect(() => {
    if (initialFormData) {
      setFormData({ ...initialBlankData, ...initialFormData });
      setCleanInitialData({ ...initialBlankData, ...initialFormData });
    }
  }, [initialFormData]);

  useEffect(() => {
    expose(true, onboarded);

    return () => expose(false);
  }, [formData, expose, isEditable, initialFormData, onboarded]);

  const getKeyFromData = ({ key, data }: getKeyFromDataProps) => {
    if (data && key in data) return data[key];
    return undefined;
  };

  const isEmptyData = ({ data, key }: getKeyFromDataProps) => !!getKeyFromData({ data, key });

  const getValue = (element: WarehouseComponentType): string | string[] => {
    const isExistingConnection = viewEditFormData || isAdditionalUser;
    const isDeveloperViewingUneditableField = element.adminOnly
      && !userHasPlatformWorkspaceCreateAccess(accessLevel);
    const shouldHavePlaceholder = !element?.isEditingSecretField
      || isDeveloperViewingUneditableField;

    if (isExistingConnection && shouldHavePlaceholder) {
    // Generate placeholders for secrets fields when editing/onboarding existing connections
      if (element.type === WarehouseFormInputType.FILE
        && isEmptyData({ data: formData, key: element.name })) {
        return 'service-account.json';
      }

      const isPasswordField = element.type === WarehouseFormInputType.PASSWORD;
      const isMultiLineSecretField = element.type === WarehouseFormInputType.MULTI_LINE_INPUT
        && element.isSecretField;
      if ((isPasswordField || isMultiLineSecretField)
          && isEmptyData({ data: formData, key: element.name })) {
        return '******';
      }
    }

    if (element.type === WarehouseFormInputType.CHECKBOX
      && element?.togglingInputFieldProps?.name) {
      // Here we require 2 values; one for the checkbox, and one for the input field
      return [
        getKeyFromData({ data: formData, key: element.name })?.toString() || '',
        getKeyFromData({ data: formData, key: element.togglingInputFieldProps.name })?.toString() || '',
      ];
    }
    return getKeyFromData({ data: formData, key: element.name })?.toString() || '';
  };

  const tested = onboarded !== undefined;
  const connected = onboarded || false;

  const getDisabledState = (element: WarehouseComponentType) => {
    if (element.disabled) return true;
    if (dataIsBeingChecked) return true; // Disable the form while submission takes place
    if (connected) return true; // Disable the form once the connection has been successful
    if (isAdditionalUser
      && prefilled
      && source === WareHouseModalSource.ACCOUNT_SETTINGS
      && isEditable) {
      return prefilled && element?.adminOnly;
    }

    if (isAdditionalUser
      && prefilled
      && source !== WareHouseModalSource.ACCOUNT_SETTINGS) {
      return prefilled && element?.adminOnly;
    }

    if (source === WareHouseModalSource.ACCOUNT_SETTINGS && !isEditable) return true;

    return prefilled && element?.adminOnly;
  };

  const onComponentChange = ({ value, name }: onComponentChangeProps) => {
    // @ts-ignore
    setFormData((current: initialFormDataType) => ({
      ...current, [name]: value,
    }));
  };

  const checkboxIsChecked = (element: CheckboxType) => (
    (element?.name && formData !== undefined)
      ? Boolean(getKeyFromData({ data: formData, key: element.name }))
      : false
  );

  const isFieldRequired = (
    adminOnly?: boolean,
    isSecretField?: boolean,
    isEditingSecretField?: boolean,
    isOptionalField?: boolean,
  ) => {
    if (isOptionalField) return false;
    if (isNewConnection) return true;
    if (isSecretField && !isEditingSecretField) return false;
    if (adminOnly) return userHasPlatformWorkspaceCreateAccess(accessLevel);
    return true;
  };

  const validateNonEmptyValue = (name: string, value?: string | number, allowSpaces?: boolean) => {
    const noSpacesValidation = [{
      rule: ValidationRules.NO_SPACES,
      message: 'Spaces are not allowed',
    }];
    const rules = [
      ...!allowSpaces ? noSpacesValidation : [],
      {
        rule: ValidationRules.NOT_EMPTY,
        message: 'This field is required',
      },
    ];

    const { hasError, errorMessages } = checkValidation({
      value,
      rules,
    });

    setValidationErrors((currentErrors) => {
      // First remove any existing errors
      const filteredErrors = currentErrors?.filter(({ name: currentName }) => currentName !== name);

      if (hasError) {
        // Add the new validation message
        return [
          ...(filteredErrors || []),
          { name, validationMessage: errorMessages[0] || '' },
        ];
      }

      return filteredErrors || [];
    });

    return validationErrors?.length === 0;
  };

  const testValidationRules = () => {
    if (formData) {
      const userEnteredComponents = [
        WarehouseFormInputType.NUMBER,
        WarehouseFormInputType.PASSWORD,
        WarehouseFormInputType.TEXT,
        WarehouseFormInputType.FILE,
        WarehouseFormInputType.MULTI_LINE_INPUT,
      ];

      form.forEach((formElement) => {
        if (formElement.type !== WarehouseFormInputType.TITLE
          && userEnteredComponents.includes(formElement.type)
          && isFieldRequired(
            formElement?.adminOnly,
            formElement?.isSecretField,
            formElement?.isEditingSecretField,
            formElement?.isOptionalField,
          )) {
          const { name } = formElement;
          const value = getKeyFromData({ data: formData, key: formElement.name });
          const allowSpaces = formElement.type === WarehouseFormInputType.MULTI_LINE_INPUT;
          validateNonEmptyValue(name, value, allowSpaces);
        }

        // Validate input fields which are toggled by checkboxes
        if (formElement.type === WarehouseFormInputType.CHECKBOX
            && formElement.withTogglingInputField
            && formElement.togglingInputFieldProps?.name) {
          const { name } = formElement.togglingInputFieldProps;

          if (checkboxIsChecked(formElement)
            && isFieldRequired(
              formElement?.togglingInputFieldProps?.adminOnly,
              formElement?.togglingInputFieldProps?.isSecretField,
              formElement?.togglingInputFieldProps?.isEditingSecretField,
              formElement?.togglingInputFieldProps?.isOptionalField,
            )) {
            const value = getKeyFromData({
              data: formData,
              key: formElement.togglingInputFieldProps.name,
            });
            validateNonEmptyValue(name, value);
          } else {
            // When unchecked, the field is not in the form so can't have a validation error
            setValidationErrors((current) => (
              current?.filter(({ name: currentName }) => currentName !== name) || []
            ));
          }
        }
        return true;
      });
    }
    return true;
  };

  useEffect(() => {
    if (validationErrors && validationErrors.length === 0) {
      const dataToSubmit = showOptionalConfig
        ? { profile: optionalConfig, ...formData }
        : formData;

      onSubmitButtonClick({
        formData: dataToSubmit,
        setIsEditable,
        closeModal,
        credentialId,
      });
    }
  }, [validationErrors]);

  const handleOnSubmitButtonClick = () => {
    if (submitButtonText === BUTTON_STATE_TEXT.NEXT) {
      // At this point, there's nothing to validate so skip ahead to calling the function
      setValidationErrors([]);
    } else {
      testValidationRules();
    }
  };

  useEffect(() => {
    const messageListener = (e: MessageEvent) => {
      if (e.data === 'RESET_SECRETS') {
        // Reset the validation errors
        setValidationErrors(undefined);

        // Reset the values of the secret fields
        form.forEach((formElement) => {
          if (formElement.isSecretField) { // @ts-ignore
            setFormData((currentFormData) => ({ // @ts-ignore
              ...currentFormData, [formElement?.name]: cleanInitialData[formElement?.name],
            }));
          }
        });
      }
    };

    window.addEventListener('message', messageListener);

    return () => window.removeEventListener('message', messageListener);
  }, []);

  useEffect(() => {
    const messageListener = (e: MessageEvent) => {
      if (e.data?.type === 'RESET_SPECIFIC_FIELD') {
        const {
          fieldName,
          useInitialValue,
          customValue,
        }: ResetSpecificFieldMessageProps = e.data;

        if (useInitialValue) {
          // Reset the named field to its initial value
          setFormData((currentFormData) => {
            if (currentFormData) {
              return ({
                ...currentFormData,
                [fieldName]: cleanInitialData ? cleanInitialData[fieldName as keyof initialFormDataType] : '',
              });
            }
            return currentFormData;
          });
        } else {
          // Reset the named field to the custom value provided
          setFormData((currentFormData) => {
            if (currentFormData) {
              return ({
                ...currentFormData,
                [fieldName]: customValue,
              });
            }
            return currentFormData;
          });
        }
      }
    };

    window.addEventListener('message', messageListener);

    return () => window.removeEventListener('message', messageListener);
  }, []);

  return (
    <>
      <S.WareHouseFactory formDistribution={formDistribution}>
        {form.map((element) => (
          getWarehouseComponents({
            element,
            eventContext,
            onComponentChange,
            loading,
            onFileUpdate: onComponentChange,
            fieldValue: getValue(element),
            disabled: getDisabledState(element),
            checkboxIsChecked: checkboxIsChecked(element as CheckboxType),
            validationErrors: validationErrors || [],
            formData,
            setFormData,
          })
        ))}
      </S.WareHouseFactory>
      {showOptionalConfig && (
        <OptionalConfig
          configValue={optionalConfig}
          onChangeConfig={setOptionalConfig}
          isDisabled={dataIsBeingChecked || connected}
        />
      )}
      {tested && connected && (
        <Callout
          icon="info-sign"
          view="smooth"
          color="success"
          title="You're done!"
          content="You are now connected to your data warehouse."
          dense
          maxHeight="66px"
        />
      )}
      {tested && !connected && (
        <Callout
          icon="warning-sign"
          view="smooth"
          color="danger"
          title="Oops! Something is not right."
          content={errorMessage}
          dense
          maxHeight="66px"
        />
      )}
      <DefaultButton
        data-testid="submitFormButton"
        view="filled"
        type="standard"
        color="primary"
        className="bp4-intent-6"
        loading={dataIsBeingChecked}
        style={{
          margin: '1rem auto 0 auto',
          minWidth: '104px',
        }}
        text={submitButtonText}
        onClick={handleOnSubmitButtonClick}
        eventContext={Contexts.MODAL}
        eventObject="warehouseConnection"
        eventAction={isNewConnection ? Actions.ADDED : Actions.EDITED}
        eventProperties={{
          location: source, // @ts-ignore
          type: formData?.databaseType,
          category: env,
          text: submitButtonText,
          isNewConnection,
        }}
      />
    </>
  );
};

export default WareHouseFactory;
