import { genericObject } from '@paradime/common/lib/common/genericTypes';
import commandBarCallbacks from './callbacks';
import { appStore } from '../../../../stores';

const showAddNewUserDialog = () => {
  const { showInviteUser } = appStore.getState();
  appStore.setState({ showInviteUser: !showInviteUser });
};

const addSettingsCommands = (
  showNewMessage: () => void,
  featureFlags?: genericObject,
  isExtensionOnlyCompany?: boolean,
) => {
  // callback to invite new users
  if (featureFlags?.flagAllowUserManagement) {
    window.CommandBar.addCallback(
      commandBarCallbacks.ADD_NEW_USER,
      showAddNewUserDialog,
    );

    window.CommandBar.addCommand({
      name: 'add_new_user',
      text: 'Add new user',
      template: {
        type: 'callback',
        value: commandBarCallbacks.ADD_NEW_USER,
      },
      category: 'Settings',
      explanation: 'Add new user',
      tags: ['new', 'user'],
    });
  }

  if (!isExtensionOnlyCompany) {
    // Only main-app users can view warehouse settings & env vars
    window.CommandBar.addCommand({
      name: 'open_warehouse_settings',
      text: 'Open warehouse settings',
      template: {
        type: 'link',
        value: '/account-settings/connections',
        operation: 'router',
      },
      category: 'Settings',
      explanation: 'Manage data warehouse connections',
      tags: ['warehouse', 'settings'],
    });

    window.CommandBar.addCommand({
      name: 'open_profile_env_var',
      text: 'Open environment variables',
      template: {
        type: 'link',
        value: '/profile-settings/env-variables',
        operation: 'router',
      },
      category: 'Settings',
      explanation: 'Manage personal environment variables',
      tags: ['personal', 'settings'],
    });
  }

  window.CommandBar.addCommand({
    name: 'open_team_settings',
    text: 'Open team settings',
    template: {
      type: 'link',
      value: '/account-settings/team',
      operation: 'router',
    },
    category: 'Settings',
    explanation: 'Add, remove and manage users',
    tags: ['team', 'settings'],
  });

  window.CommandBar.addCommand({
    name: 'open_integration_settings',
    text: 'Open integrations',
    template: {
      type: 'link',
      value: '/account-settings/integrations',
      operation: 'router',
    },
    category: 'Settings',
    explanation: 'Manage integrations',
    tags: ['integration', 'settings'],
  });

  window.CommandBar.addCommand({
    name: 'open_profile_settings',
    text: 'Open profile settings',
    template: {
      type: 'link',
      value: '/profile-settings/profile',
      operation: 'router',
    },
    category: 'Settings',
    explanation: 'Manage personal settings',
    tags: ['personal', 'settings'],
  });

  const handleOpenInAppChatSupport = () => {
    showNewMessage();
  };
  window.CommandBar.addCallback(
    commandBarCallbacks.OPEN_IN_APP_CHAT,
    handleOpenInAppChatSupport,
  );
};

export default addSettingsCommands;
