import { Dispatch, SetStateAction } from 'react';
import { DropdownOnChangeProps, WarehouseFormInputType } from '../../warehouseFactory/types';
import { WarehouseEnv } from '../../utils';
import { sendResetSecretsMessage, sendResetSpecificFieldMessage } from '../CommonConfig';
import {
  AuthMethod,
  BigqueryFormDataType,
  ConnectionType,
  getDefaultEmptyAuthValues,
} from './CommonConfig';
import { checkNoCasesLeft } from '../../../../../utilis';
import { initialFormDataType } from '../../useGetInitialWarehouseData';
import { DevWarehouseHelp, ProdWarehouseHelp } from '../../../../Common/AppHelp/help';
import { generateRegionsDropdownItems } from './Regions';

export interface formDistributionProps {
  env: WarehouseEnv,
  selectedAuthMethod: AuthMethod,
  showEditButton: boolean,
}

export const formDistribution = ({
  env,
  selectedAuthMethod,
  showEditButton,
}: formDistributionProps) => {
  if (env === WarehouseEnv.DEV) {
    switch (selectedAuthMethod) {
      case AuthMethod.OAUTH:
        if (showEditButton) {
          return ("'P P P P P P P P P P P P' "
            + "'p p p p p p t t t t t t' "
            + "'d d d d d d A A A A A A' "
            + "'a a a a a a a a a a a a' "
            + "'f f f f f f f f f f f f' "
            + "'u u u u u c c c c c b b' "
            + "'q q q q q q q q q q q q' "
            + "'n n n n n n n n n n n n' "
            + "'D D D D D D D D D D D D' "
            + "'s s s s s s h h h h h h' ");
        }
        return ("'P P P P P P' "
          + "'p p p t t t' "
          + "'d d d A A A' "
          + "'a a a a a a' "
          + "'f f f f f f' "
          + "'u u u c c c' "
          + "'q q q q q q' "
          + "'n n n n n n' "
          + "'D D D D D D' "
          + "'s s s h h h' ");
      case AuthMethod.SERVICEACCOUNT:
        if (showEditButton) {
          return ("'P P P P P P P P P P P P' "
            + "'p p p p p p t t t t t t' "
            + "'d d d d d d A A A A A A' "
            + "'j j j j j j j j j j b b' "
            + "'q q q q q q q q q q q q' "
            + "'n n n n n n n n n n n n' "
            + "'D D D D D D D D D D D D' "
            + "'s s s s s s h h h h h h' ");
        }
        return ("'P P P P P P' "
          + "'p p p t t t' "
          + "'d d d A A A' "
          + "'j j j j j j' "
          + "'q q q q q q' "
          + "'n n n n n n' "
          + "'D D D D D D' "
          + "'s s s h h h' ");
      default:
        checkNoCasesLeft(selectedAuthMethod);
        break;
    }
  }

  if (showEditButton) {
    return ("'P P P P P P P P P P P P' "
    + "'p p p p p p t t t t t t' "
    + "'d d d d d d d d d d d d' "
    + "'j j j j j j j j j j b b' "
    + "'q q q q q q q q q q q q' "
    + "'n n n n n n n n n n n n' "
    + "'D D D D D D D D D D D D' "
    + "'s s s s s s h h h h h h' ");
  }
  return ("'P P P P P P' "
  + "'p p p t t t' "
  + "'d d d d d d' "
  + "'j j j j j j' "
  + "'q q q q q q' "
  + "'n n n n n n' "
  + "'D D D D D D' "
  + "'s s s h h h' ");
};

export const initialValues = (env: WarehouseEnv, formData?: initialFormDataType) => ({
  connectionName: '',
  serviceAccountJson: '',
  location: 'EU',
  datasetName: '',
  targetName: env,
  threads: 8,
  projectId: '',
  clientId: '',
  clientSecret: '',
  authMethod: AuthMethod.SERVICEACCOUNT,
  hasExecutionProject: !!(
    formData as BigqueryFormDataType | undefined
  )?.executionProject || false,
  connectionType: ConnectionType.OAUTH,
  executionProject: '',
});

export const formFields = (
  selectedAuthMethod: AuthMethod,
  setSelectedAuthMethod: Dispatch<SetStateAction<AuthMethod>>,
  selectedDatasetLocation: string,
  setSelectedDatasetLocation: Dispatch<SetStateAction<string>>,
  showEditButton: boolean,
  isEditingServiceAccountJson: boolean,
  setIsEditingServiceAccountJson: Dispatch<SetStateAction<boolean>>,
  isEditingClientSecret: boolean,
  setIsEditingClientSecret: Dispatch<SetStateAction<boolean>>,
  env: WarehouseEnv,
) => {
  const authMethodDropdown = [{
    id: 'dropdown-bq-auth-method',
    name: 'authMethod',
    label: 'Auth Method',
    gridArea: 'A',
    type: 'dropdown',
    menuItems: [
      {
        text: AuthMethod.SERVICEACCOUNT,
        type: 'item',
        onClick: () => setSelectedAuthMethod(AuthMethod.SERVICEACCOUNT),
      },
      {
        text: AuthMethod.OAUTH,
        type: 'item',
        onClick: () => setSelectedAuthMethod(AuthMethod.OAUTH),
      },
    ],
    text: selectedAuthMethod,
    additionalUserPlaceholder: selectedAuthMethod,
    adminOnly: true,
    onChange: ({
      setFormData,
      selectedItem,
    }: DropdownOnChangeProps) => {
      setFormData((currentFormData) => ({
        ...currentFormData,
        ...getDefaultEmptyAuthValues(
          selectedItem as AuthMethod,
          currentFormData as BigqueryFormDataType | undefined,
        ) as initialFormDataType,
      }));
    },
  }];

  const locationDropdown = [{
    id: 'dropdown-bq-dataset-location',
    name: 'location',
    label: 'Dataset location',
    firstUserOnly: true,
    gridArea: 'd',
    adminOnly: true,
    maxHeight: 200,
    type: WarehouseFormInputType.DROPDOWN,
    menuItems: generateRegionsDropdownItems(setSelectedDatasetLocation),
    text: selectedDatasetLocation,
    onChange: ({
      setFormData,
      selectedItem,
    }: DropdownOnChangeProps) => {
      setFormData((currentFormData) => ({
        ...currentFormData,
        ...{
          location: selectedItem,
        } as initialFormDataType,
      }));
    },
  }];

  const projectIdClientIdClientSecret = [{
    id: 'text-bq-project-id',
    name: 'projectId',
    label: 'Project ID',
    type: WarehouseFormInputType.TEXT,
    gridArea: 'f',
    isPrivate: true,
    adminOnly: true,
  },
  {
    id: 'text-bq-oauth-client-id',
    name: 'clientId',
    label: 'Client ID',
    type: WarehouseFormInputType.TEXT,
    gridArea: 'u',
    isPrivate: true,
    adminOnly: true,
    disabled: showEditButton ? !isEditingClientSecret : false,
    isSecretField: true,
    isEditingSecretField: isEditingClientSecret,
  },
  {
    id: 'text-bq-oauth-client-secret',
    name: 'clientSecret',
    label: 'Client Secret',
    type: WarehouseFormInputType.PASSWORD,
    gridArea: 'c',
    isPrivate: true,
    adminOnly: true,
    disabled: showEditButton ? !isEditingClientSecret : false,
    isSecretField: true,
    isEditingSecretField: isEditingClientSecret,
  }];

  const editClientSecretButton = [{
    name: 'editClientSecretButton',
    text: isEditingClientSecret ? 'Cancel' : 'Edit',
    type: WarehouseFormInputType.BUTTON,
    gridArea: 'b',
    onClick: () => setIsEditingClientSecret((prev) => {
      if (prev) {
        // User has pressed cancel, undo any changes
        sendResetSecretsMessage();
      }
      return !prev;
    }),
    adminOnly: selectedAuthMethod === AuthMethod.OAUTH,
  }];

  const serviceAccountJson = [{
    id: 'file-bq-service-account-json',
    name: 'serviceAccountJson',
    label: 'Service Account JSON',
    type: WarehouseFormInputType.FILE,
    firstUserOnly: true,
    gridArea: 'j',
    additionalUserPlaceholder: 'paradime-bigquery.json',
    placeHolder: 'Choose a *.json file ...',
    color: 'primary',
    adminOnly: true,
    isPrivate: true,
    disabled: showEditButton ? !isEditingServiceAccountJson : false,
    isSecretField: true,
    isEditingSecretField: isEditingServiceAccountJson,
  }];

  return [
    {
      title: 'Connection Settings',
      gridArea: 'P',
      type: 'title',
      helpArticleId: (
        env === WarehouseEnv.PROD
          ? ProdWarehouseHelp.BIG_QUERY
          : DevWarehouseHelp.BIG_QUERY
      ),
    },
    {
      id: 'text-bq-profile-name',
      name: 'connectionName',
      label: 'Profile Name',
      type: WarehouseFormInputType.TEXT,
      gridArea: 'p',
      title: 'Profile Name',
      adminOnly: true,
      showHighlight: true,
      highlightDescription: 'You will find this in your <code>dbt_project.yml</code>.<br>Use <code>default</code> if you are getting started with an empty repo.',
    },
    {
      id: 'text-bq-target-name',
      name: 'targetName',
      label: 'Target',
      type: WarehouseFormInputType.TEXT,
      value: env || WarehouseEnv.DEV,
      gridArea: 't',
      adminOnly: true,
    },
    ...locationDropdown,
    ...(env === WarehouseEnv.DEV ? authMethodDropdown : []),
    ...(selectedAuthMethod === AuthMethod.OAUTH ? projectIdClientIdClientSecret : []),
    ...(selectedAuthMethod === AuthMethod.OAUTH && showEditButton ? editClientSecretButton : []
    ),
    ...(selectedAuthMethod === AuthMethod.SERVICEACCOUNT
      ? serviceAccountJson
      : []),
    ...(selectedAuthMethod === AuthMethod.SERVICEACCOUNT && showEditButton
      ? [{
        name: 'editClientSecretButton',
        text: isEditingServiceAccountJson ? 'Cancel' : 'Edit',
        type: WarehouseFormInputType.BUTTON,
        gridArea: 'b',
        onClick: () => setIsEditingServiceAccountJson((prev) => {
          if (prev) {
            // User has pressed cancel, undo any changes
            sendResetSecretsMessage();
          }
          return !prev;
        }),
        adminOnly: true,
      }]
      : []
    ),
    {
      id: 'checkbox-bq-execution-project',
      name: 'hasExecutionProject',
      label: 'Configure Execution Project',
      helperText: 'The GCP project that receives the bill for query costs or slot usage',
      type: WarehouseFormInputType.CHECKBOX,
      firstUserOnly: true,
      gridArea: 'q',
      fill: true,
      adminOnly: true,
      onChange: () => sendResetSpecificFieldMessage({ fieldName: 'executionProject', useInitialValue: false, customValue: '' }),
      withTogglingInputField: true,
      togglingInputFieldProps: {
        name: 'executionProject',
        label: 'Execution Project ID',
        type: WarehouseFormInputType.TEXT,
        gridArea: 'n',
      },
    },
    {
      title: (env === WarehouseEnv.PROD) ? 'Production Credentials' : 'Development Credentials',
      gridArea: 'D',
      type: WarehouseFormInputType.TITLE,
    },
    {
      id: 'text-bq-schema',
      name: 'datasetName',
      label: 'Schema',
      type: WarehouseFormInputType.TEXT,
      firstUserOnly: false,
      gridArea: 's',
      title: (env === WarehouseEnv.PROD) ? 'Production Credentials' : 'Development Credentials' as any,
    },
    {
      id: 'text-bq-threads',
      name: 'threads',
      label: 'Threads',
      type: WarehouseFormInputType.NUMBER,
      value: 8,
      additionalUserPlaceholder: 8,
      gridArea: 'h',
    },
  ];
};
