import React, { FC } from 'react';

const Tableau: FC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="14" height="14" rx="7" fill="white" />
    <g clipPath="url(#clip0_2304_397030)">
      <g clipPath="url(#clip1_2304_397030)">
        <path d="M7.80365 9.64642H8.32357V8.22846H9.63125V7.7243H8.32357V6.30634H7.80365V7.7243H6.51172V8.22846H7.80365V9.64642Z" fill="#E8762D" />
        <path d="M5.39245 11.6946H5.8336V10.4342H6.99948V10.0403H5.8336V8.7641H5.39245V10.0403H4.22656V10.4342H5.39245V11.6946Z" fill="#C72037" />
        <path d="M10.2909 7.1414H10.732V5.88098H11.9136V5.50286H10.732V4.22668H10.2909V5.50286H9.125V5.88098H10.2909V7.1414Z" fill="#5B879B" />
        <path d="M7.87957 12.6872H8.2577V11.7892H9.06122V11.4583H8.2577V10.5603H7.87957V11.4583H7.0918V11.7892H7.87957V12.6872Z" fill="#5C6692" />
        <path d="M5.40821 7.1414H5.81784V5.86523H6.99948V5.50286H5.81784V4.22668H5.40821V5.50286H4.22656V5.86523H5.40821V7.1414Z" fill="#EB9129" />
        <path d="M11.6315 9.03198H12.0096V8.14968H12.8132V7.80307H12.0096V6.92078H11.6315V7.80307H10.8438V8.14968H11.6315V9.03198Z" fill="#5C6692" />
        <path fillRule="evenodd" clipRule="evenodd" d="M10.2909 11.6946H10.732V10.4342H11.9136V10.0403H10.732V8.7641H10.2909V10.0403H9.125V10.4342H10.2909V11.6946Z" fill="#1F457E" />
        <path d="M9.00014 4.44717V4.17934H8.21238V3.31281H7.92878V4.17934H7.14102V4.44717H7.92878V5.31371H8.21238V4.44717H9.00014ZM4.10026 8.96889H4.38386V8.10236H5.17162V7.83452H4.38386V6.98375H4.10026V7.83452H3.3125V8.11812L4.10026 8.10236V8.96889Z" fill="#7199A6" />
      </g>
    </g>
    <rect x="1" y="1" width="14" height="14" rx="7" stroke="#E8762D" strokeWidth="2" />
    <defs>
      <clipPath id="clip0_2304_397030">
        <rect width="10" height="10" fill="white" transform="translate(3 3)" />
      </clipPath>
      <clipPath id="clip1_2304_397030">
        <rect width="9.50041" height="9.375" fill="white" transform="translate(3.3125 3.3125)" />
      </clipPath>
    </defs>
  </svg>
);

export default Tableau;
