import { useState } from 'react';
import { gql } from '@apollo/client';
import { useHasuraQuery } from '../../../app/hooks/useHasuraQuery';

export interface GetAppTipsQueryResponseData {
  tip: string,
}

interface GetAppTipsQueryResponse { // eslint-disable-next-line camelcase
  sheets_load_app_tips: GetAppTipsQueryResponseData[],
}

const GetAppTipsQuery = gql`
  query GetTips {
    sheets_load_app_tips(where: {context: {_eq: \"editor\"}}) {
      tip
    }
  }
`;

export const useGetAppTips = () => {
  const [tips, setTips] = useState<string[]>([]);

  const { loading: isLoadingTips } = useHasuraQuery<GetAppTipsQueryResponse>({
    query: GetAppTipsQuery,
    onCompleted: ({ data }) => {
      setTips((data.sheets_load_app_tips || []).map(({ tip }) => tip));
    },
  });

  const getRandomTip = () => (
    tips[Math.floor(Math.random() * tips.length)]
  );

  return {
    tips,
    isLoadingTips,
    getRandomTip,
  };
};
