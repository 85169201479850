import React, {
  FC,
  useEffect,
  useState,
  useContext,
} from 'react';
import { useScheduleCommandLogsSubscription } from '../../../../client/generated/service-dataEndpoint';
import { BoltRunContext } from '../Run';
import { BoltCommandType } from '../LogsAndFreshness';
import Command from '../CommandLogs';

const SubscribedCommand: FC<BoltCommandType> = (command) => {
  const [isFinished, setIsFinished] = useState(true);
  const { refetchRunData } = useContext(BoltRunContext);
  const { meta: commandMetadata } = command;

  const { data: subscriptionData } = useScheduleCommandLogsSubscription({
    variables: {
      commandID: commandMetadata.id,
    },
  });

  useEffect(() => {
    // BE only retains logs and the "finished" state for 2hrs
    // After 2hrs, it will always return { stdout: '', finished: false }
    // So, if there is no stdout, we can assume the command has in fact finished
    if (subscriptionData?.scheduleCommandLogs.finished !== undefined) {
      const outputFromSubscription = Boolean(subscriptionData?.scheduleCommandLogs.stdout);

      const finished = outputFromSubscription
        ? subscriptionData.scheduleCommandLogs.finished
        : true;
      setIsFinished(finished);
    }
  }, [subscriptionData]);

  useEffect(() => {
    if (subscriptionData?.scheduleCommandLogs.finished) {
      setTimeout(refetchRunData, 500);
    }
  }, [subscriptionData]);

  return (
    <Command
      {...command}
      initialIsOpen={!isFinished}
      commandId={commandMetadata.id}
    />
  );
};

export default SubscribedCommand;
