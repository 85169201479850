import React, { createContext, FC } from 'react';
import { Icon } from '@blueprintjs/core';
import { Dialog } from 'primereact/dialog';
import { useLocation } from 'react-router-dom';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { appStore } from '../../../stores';
import * as S from './AppHelp.styles';
import AppHelpHome from './Home';
import HomeBottom from './HomeBottom';

interface AppHelpContextProps {
  location: string,
}

export const AppHelpContext = createContext<AppHelpContextProps>({
  location: '',
});

interface AppHelpProps {
  helpLink?: string,
}

const AppHelp:FC<AppHelpProps> = () => {
  const showAppHelp = appStore((s) => s.showAppHelp);
  const setShowAppHelp = appStore((s) => s.setShowAppHelp);
  const { pathname } = useLocation();

  const DialogHeader = () => (
    <AutoLayout
      direction="horizontal"
      padding="none"
      distribution="space-between"
    >
      <AutoLayout
        direction="horizontal"
        distribution="packed"
        padding="none"
        horizontalGap="very-dense"
      >
        <Icon icon="layout-grid" size={16} color="var(--grey50)" />
        <Typography type="body-bold-small" color="default" tagName="span">
          Paradime Helphub
        </Typography>
      </AutoLayout>
      <S.DialogCloseButton
        dense
        view="flat"
        icon="cross"
        eventContext={Contexts.RADAR}
        eventObject="closeInAppHelp"
        eventAction={Actions.CLICKED}
        onClick={() => setShowAppHelp(false)}
      />
    </AutoLayout>
  );

  return (
    <AppHelpContext.Provider
      value={{
        location: pathname,
      }}
    >
      <S.InAppHelpDialogStyle />
      <Dialog
        header={DialogHeader()}
        headerStyle={{
          paddingBottom: '8px',
        }}
        visible={showAppHelp}
        closeOnEscape
        dismissableMask={false}
        closable={false}
        modal={false}
        position="top-right"
        resizable
        style={{ width: '450px', height: 'calc(100vh - 16px)' }}
        onHide={() => setShowAppHelp(false)}
        className="in-app-help-dialog"
        maskClassName="in-app-help-dialog-mask"
      >
        <AppHelpHome />
        <HomeBottom />
      </Dialog>
    </AppHelpContext.Provider>
  );
};

export default AppHelp;
