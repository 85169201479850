import { catalogueAssetItemIdProps } from '../types';

export const catalogueAssetItemId = ({
  resultItem,
}: catalogueAssetItemIdProps) => {
  switch (resultItem.__typename) {
    case 'GQLCatalogueDwhTableItem':
    case 'GQLCatalogueMaterialisedDbtItem':
      return resultItem.itemId;
    case 'GQLCatalogueDwhColumnItem':
      return resultItem.table.itemId;
    case 'GQLCatalogueMaterialisedDbtObjectColumnItem':
      return resultItem.dbtItem.itemId;
    default:
      return 'error';
  }
};
