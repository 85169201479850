import { useEffect } from 'react';
import { lineageStore } from '../../../stores';

const useOpenNodesOnCodeEditor = () => {
  const nodeToOpen = lineageStore((s) => s.nodeToOpen);
  const clearNodeToOpen = lineageStore((s) => s.clearNodeToOpen);

  useEffect(() => {
    const sendNodeToCodeEditorIframe = () => {
      document.querySelector<HTMLIFrameElement>('iframe[id="codeEditorIframe"]')?.contentWindow?.postMessage({ // eslint-disable-line
        type: 'node-to-open',
        payload: nodeToOpen,
      }, '*');
    };

    if (nodeToOpen) {
      sendNodeToCodeEditorIframe();
      const repeatUntilReceived = setInterval(sendNodeToCodeEditorIframe, 1000);

      const receiveNode = (e: MessageEvent) => {
        const { data: { type } } = e;
        if (type === 'node-recieved') {
          clearInterval(repeatUntilReceived);
          window.removeEventListener('message', receiveNode, false);
          clearNodeToOpen();
        }
      };

      window.addEventListener('message', receiveNode);
    }
  }, [nodeToOpen]);//eslint-disable-line
};

export default useOpenNodesOnCodeEditor;
