/* eslint-disable no-case-declarations */
import { useEffect, useState } from 'react';
import { useGetAlertNotificationsLazyQuery, KnockAlertChannelId } from '../../../client/generated/service';
import { NotificationType } from '.';

export interface useGetEmailNotificationsProps {
  notificationType: NotificationType,
}

export const useGetEmailNotifications = ({ notificationType }: useGetEmailNotificationsProps) => {
  const [emailIsEnabled, setEmailIsEnabled] = useState<boolean>();

  const [getAlertNotifications, { loading }] = useGetAlertNotificationsLazyQuery({
    onCompleted: (data) => {
      setEmailIsEnabled(data.getAlertNotifications?.emailsEnabled || false);
    },
  });

  const getEmailNotifications = () => {
    let channelId: KnockAlertChannelId | null = null;

    switch (notificationType) {
      case NotificationType.BOLT:
        channelId = KnockAlertChannelId.EmailBoltAlert;
        break;
      case NotificationType.RADAR:
        channelId = KnockAlertChannelId.EmailDataAlert;
        break;
      default:
        break;
    }

    if (channelId) {
      getAlertNotifications({ variables: { alertChannel: channelId } });
    }
  };

  useEffect(() => {
    getEmailNotifications();
  }, []);

  return {
    getEmailNotifications,
    emailIsEnabled,
    setEmailIsEnabled,
    isLoadingEmailNotifications: loading,
  };
};
