import { IconName } from '@blueprintjs/core';
import { catalogueNameAndIconProps } from '../types';

export const catalogueNameAndIcon = ({
  resultItem,
}: catalogueNameAndIconProps) => {
  switch (resultItem.__typename) {
    case 'GQLCatalogueDwhTableItem':
      return { icon: 'th' as IconName, name: resultItem.tableName };
    case 'GQLCatalogueMaterialisedDbtItem':
      return { icon: 'th' as IconName, name: resultItem.name };
    case 'GQLCatalogueDwhColumnItem':
      return { icon: 'th-list' as IconName, name: resultItem.table.tableName };
    case 'GQLCatalogueMaterialisedDbtObjectColumnItem':
      return { icon: 'th-list' as IconName, name: resultItem.dbtItem.tableName || resultItem.dbtItem.name };
    default:
      return { icon: 'error' as IconName, name: 'error' };
  }
};
