/* eslint-disable camelcase */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { LocalStorageFlags } from '@paradime/common/lib/common/localStorageKeys';
import { companyStore, userAuthStore, graphQLStore } from '../../../stores';
import { useCurrentProfileSetupStageLazyQuery } from '../../../client/generated/service';
import { isDevEnvironment } from '../../../utilis';
import { ONBOARDING_PAGE, OnboardingRoutePrefix } from '../../Onboarding';
import { createWorkspaceRoutePrefix, CreateWorkspaceRoutes } from '../../Platform/Workspaces/CreateWorkspace';

const routesToIgnore = [
  '/platform/workspaces',
];

const useCheckOnboardingStatusOnce = () => {
  const currentUser = userAuthStore((s) => s.currentUser);
  const currentUserIsSet = currentUser.accessLevel;

  const isDwhSetupComplete = companyStore((s) => s.isDwhSetupComplete);
  const setIsDwhSetupComplete = companyStore((s) => s.setIsDwhSetupComplete);
  const isGitSetupComplete = companyStore((s) => s.isGitSetupComplete);
  const setIsGitSetupComplete = companyStore((s) => s.setIsGitSetupComplete);
  const isBasicSetupComplete = companyStore((s) => s.isBasicSetupComplete);
  const setIsBasicSetupComplete = companyStore((s) => s.setIsBasicSetupComplete);

  const isIncompleteWorkspace = graphQLStore((s) => s.isIncompleteWorkspace);

  const isFirstTime = isDwhSetupComplete === undefined
    && isGitSetupComplete === undefined
    && isBasicSetupComplete === undefined;

  const { pathname } = useLocation();

  const isOnboardingRoute = pathname.startsWith('/onboarding');
  const isRouteToIgnore = routesToIgnore.some((route) => pathname.includes(route));
  const isExemptedRoute = isOnboardingRoute || isRouteToIgnore;

  const gitSteps = [ONBOARDING_PAGE.CONNECT_REPO, ONBOARDING_PAGE.CHOOSE_REPO_PROVIDER];
  const dwhSteps = [ONBOARDING_PAGE.CONNECT_WAREHOUSE];

  const isConnectGitRoute = gitSteps.some((route) => pathname.includes(route));
  const isConnectDwhRoute = dwhSteps.some((route) => pathname.includes(route));

  const [
    getCurrentOnboardingStatus,
    { data: onboardingData },
  ] = useCurrentProfileSetupStageLazyQuery({});

  useEffect(() => {
    if (isFirstTime) {
      getCurrentOnboardingStatus();
    }
  }, [isDwhSetupComplete, isGitSetupComplete, isBasicSetupComplete]);

  useEffect(() => {
    if (onboardingData?.currentProfileSetupStage) {
      const {
        isDwhSetupIncomplete,
        isGitSetupIncomplete,
        isBasicSetupIncomplete,
      } = onboardingData.currentProfileSetupStage;

      setIsDwhSetupComplete(!isDwhSetupIncomplete);
      setIsGitSetupComplete(!isGitSetupIncomplete);
      setIsBasicSetupComplete(!isBasicSetupIncomplete);
    }
  }, [onboardingData]);

  const changeLocationOnce = (path: string) => {
    const { location } = window;

    if (location.pathname !== path) {
      location.href = path;
    }
  };

  useEffect(() => {
    const {
      isDwhSetupComplete: is_dwh_setup_complete,
      isGitSetupComplete: is_git_setup_complete,
    } = companyStore.getState();

    const ignoreOnboardingStatusCheckFlagValue = window.localStorage.getItem(
      LocalStorageFlags.IGNORE_ONBOARDING_STATUS_CHECK,
    );
    const ignoreOnboardingStatusCheck = Boolean(ignoreOnboardingStatusCheckFlagValue);

    if (!ignoreOnboardingStatusCheck && !isExemptedRoute) {
      if (
        !isConnectGitRoute
          && is_git_setup_complete === false
          && currentUserIsSet
      ) {
        const newUrl = isIncompleteWorkspace
          ? `/${createWorkspaceRoutePrefix}/${CreateWorkspaceRoutes.CONNECT_REPO}`
          : `/${OnboardingRoutePrefix}/${ONBOARDING_PAGE.CONNECT_REPO}`;
        changeLocationOnce(newUrl);
      }

      if (
        !isConnectDwhRoute
          && is_git_setup_complete
          && is_dwh_setup_complete === false
          && currentUserIsSet
      ) {
        const newUrl = isIncompleteWorkspace
          ? `/${createWorkspaceRoutePrefix}/${CreateWorkspaceRoutes.WAREHOUSE}`
          : `/${OnboardingRoutePrefix}/${ONBOARDING_PAGE.CONNECT_WAREHOUSE}`;
        changeLocationOnce(newUrl);
      }
    }
  },
  [currentUser, pathname, isDwhSetupComplete, isGitSetupComplete, isBasicSetupComplete]);

  useEffect(() => {
    const isUserAlreadyOnboarded = isDwhSetupComplete && isGitSetupComplete && isBasicSetupComplete;

    const allowAdditionalCompanyFlagValue = window.localStorage.getItem(
      LocalStorageFlags.ALLOW_NEW_COMPANY_AFTER_ALREADY_ONBOARDED,
    );
    const allowAdditionalCompany = isDevEnvironment
      ? Boolean(allowAdditionalCompanyFlagValue)
      : false;

    const { userHasRole } = userAuthStore.getState();

    const url = new URL(window.location.href);
    const isAutoJoin = url.searchParams.get('automatically_onboarded');

    if (
      isOnboardingRoute
      && (isUserAlreadyOnboarded || userHasRole)
      && currentUserIsSet
      && !allowAdditionalCompany
      && !isAutoJoin
    ) {
      const origin = url.searchParams.get('origin');
      if (origin) {
        const extensionParams = origin ? `?origin=${origin}` : '';
        changeLocationOnce(`/home${extensionParams}`);
      } else {
        console.info('isUserAlreadyOnboarded=', isUserAlreadyOnboarded); // eslint-disable-line no-console
        console.info('userHasRole=', userHasRole); // eslint-disable-line no-console
        console.info('currentUserIsSet=', currentUserIsSet); // eslint-disable-line no-console
        console.info('allowNewCompanyAfterAlreadyOnboarded=', allowAdditionalCompany); // eslint-disable-line no-console
      }
    }
  }, [pathname, isDwhSetupComplete, isGitSetupComplete, currentUser, origin, isBasicSetupComplete]);
};

export default useCheckOnboardingStatusOnce;
