import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import Divider from '@paradime-io/pragma-ui-kit/lib/components/Divider';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { Contexts, Actions } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import PageTemplate from '../Utils/PageTemplate';
import connectSlackSVG from '../images/connect-slack.svg';
import useCheckIfSlackIsOnboarded from '../../../hooks/useCheckIfSlackIsOnboarded';
import { companyStore } from '../../../../stores';

const InstallExtension: FC = () => {
  const { isFirstAdmin } = companyStore.getState();

  const history = useHistory();
  const {
    onboardSlackWithoutChecking,
    loading: slackOnboardingIsLoading,
    localSlackOnboarded: slackOnboardingIsComplete,
  } = useCheckIfSlackIsOnboarded(() => {});

  const advanceToNextStep = () => {
    history.push('/home');
  };

  useEffect(() => {
    // Additional users should not see this page
    if (!isFirstAdmin) {
      advanceToNextStep();
    }
  }, []);

  const handleClick = () => {
    // Action one: Open a new tab to connect to slack
    onboardSlackWithoutChecking();
  };

  useEffect(() => {
    if (!slackOnboardingIsLoading && slackOnboardingIsComplete) {
      // Action two: Advance this tab to the next step once Slack has been connected
      advanceToNextStep();
    }
  }, [slackOnboardingIsComplete, slackOnboardingIsLoading]);

  return (
    <PageTemplate
      title="Welcome to Synq"
      subtitle="Connecting data, analytics and business teams in a single collaborative workflow."
      withLogo
      extraSpaceBeforeBox="12px"
      withBox
      boxOrientation="horizontal"
      boxContents={(
        <AutoLayout
          direction="horizontal"
          padding="none"
          distribution="packed"
          horizontalGap="dense"
          style={{ padding: '10px 0' }}
        >
          <AutoLayout
            direction="vertical"
            padding="none"
            distribution="packed"
            verticalGap="dense"
            alignment="left"
            style={{ padding: '44px 64px' }}
          >
            <Typography
              font="poppins"
              type="body-bold-large"
            >
              Slack integration
            </Typography>
            <Typography
              font="poppins"
              type="body"
              style={{ maxWidth: '450px' }}
            >
              Reply to data questions and requests directly from Slack with no context switching.
              No more &quot;which dashboard are you looking at&quot; 🤩
            </Typography>
            <DefaultButton
              type="standard"
              view="simple"
              color="primary_alt"
              integrationIcon="slack" // Pragma change needed to add slack icon
              text="Connect Slack"
              onClick={handleClick}
              fill={false}
              eventContext={Contexts.ONBOARDING}
              eventObject="connectSlack"
              eventAction={Actions.CLICKED}
              style={{ maxWidth: '280px' }}
            />
            <DefaultButton
              type="standard"
              view="flat"
              color="default"
              text="Not now"
              dense
              onClick={advanceToNextStep}
              fill={false}
              eventContext={Contexts.ONBOARDING}
              eventObject="skipConnectSlack"
              eventAction={Actions.CLICKED}
              style={{ maxWidth: '280px' }}
            />
          </AutoLayout>
          <Divider
            vertical
            color="default"
            colorStep="100"
          />
          <img src={connectSlackSVG} alt="" width="552px" />
        </AutoLayout>
      )}
    />
  );
};

export default InstallExtension;
