import React, { FC } from 'react';
import ZeroState from '@paradime-io/pragma-ui-kit/lib/components/ZeroState';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import NotFoundIcon from '../../../images/NotFoundIcon.svg';

interface TimelineRunCompleteZeroStateProps {
  error: boolean,
}

const TimelineRunCompleteZeroState:FC<TimelineRunCompleteZeroStateProps> = ({
  error,
}) => (
  <div style={{ height: '400px' }}>
    <ZeroState
      image={NotFoundIcon}
      text={(
        <AutoLayout
          padding="none"
          direction="vertical"
          verticalGap="ultra-dense"
          alignment="center"
          wrapperStyle={{ justifyContent: 'center' }}
          style={{ width: '300px' }}
        >
          <Typography
            font="inter"
            type="body-bold-small"
            color="default"
            colorStep="100"
            style={{ textAlign: 'center' }}
          >
            No model timeline data.
          </Typography>
          <Typography
            font="inter"
            type="caption"
            color="default"
            colorStep="70"
            style={{ textAlign: 'center' }}
          >
            {
              error ? (
                'This run had an error and timeline data has not been processed.'
              ) : (
                'We could not find timeline data for this run.'
              )
            }
          </Typography>
        </AutoLayout>
        )}
    />
  </div>
);

export default TimelineRunCompleteZeroState;
