import { useCommonConfig, SnowflakeFormDataType, getDefaultEmptyAuthValues } from './CommonConfig';
import { formDistribution, initialValues, formFields } from './dwhFormFields';
import { useDatabaseHookProps } from '../../utils';

export type { SnowflakeFormDataType };

export const useDatabaseHook = ({
  formData,
  env,
  source,
  isEditable,
  isAdditionalUser,
  isNewConnection,
}: useDatabaseHookProps) => {
  const commonConfigs = useCommonConfig({
    formData,
    env,
    source,
    isEditable,
    isAdditionalUser,
    isNewConnection,
  });

  return {
    ...commonConfigs,
    initialFormData: (
      getDefaultEmptyAuthValues(
        commonConfigs.selectedAuthMethod,
        initialValues(env),
      )
    ),
    formDistribution: formDistribution({
      env,
      selectedAuthMethod: commonConfigs.selectedAuthMethod,
      showEditButton: commonConfigs.showEditButton,
    }),
    form: (
      formFields(
        commonConfigs.selectedAuthMethod,
        commonConfigs.setSelectedAuthMethod,
        commonConfigs.showEditButton,
        commonConfigs.isEditingSecret,
        commonConfigs.setIsEditingSecret,
        env,
      )
    ),
    showOptionalConfig: true,
  };
};
