import { useEffect } from 'react';
import { DateTime } from 'luxon';
import { CustomerSubscriptionsResponse, useGetCustomerSubscriptionsLazyQuery, StripeAccountStatus } from '../../client/generated/service';
import { companyStore } from '../../stores';

export const saveSubscriptionInfoToStore = (
  customerSubscriptions?: CustomerSubscriptionsResponse | null,
) => {
  const { setCompanyPlan } = companyStore.getState();

  const accountStatus = customerSubscriptions?.accountStatus || StripeAccountStatus.Other;

  const basePlan = customerSubscriptions?.subscriptions?.[0];

  const trialEndDateSeconds = basePlan?.trialEnd;
  const daysLeftInTrial = trialEndDateSeconds
    ? DateTime.fromSeconds(trialEndDateSeconds).toRelativeCalendar({ unit: 'days' })
    : '';

  setCompanyPlan({
    accountStatus,
    planNames: customerSubscriptions?.subscriptions?.map(({ planName }) => planName) || [],
    trialEndDateSeconds,
    daysLeftInTrialFormattedString: daysLeftInTrial,
    isTrial: accountStatus === StripeAccountStatus.Trialing,
    isActive: accountStatus === StripeAccountStatus.Active,
    hasPricingPlan: customerSubscriptions?.hasSubscription || false,
    trialHasExpired: accountStatus === StripeAccountStatus.TrialExpired,
    userHasCancelled: accountStatus === StripeAccountStatus.UserCancelled,
  });
};

export const useCheckPricingPlan = () => {
  const [getCustomerSubscriptions] = useGetCustomerSubscriptionsLazyQuery({
    onCompleted: ({ customerSubscriptions }) => {
      saveSubscriptionInfoToStore(customerSubscriptions);
    },
  });

  useEffect(() => {
    getCustomerSubscriptions();
  }, []);
};
