import { useHistory } from 'react-router-dom';
import {
  SetCurrentWorkspaceMutation,
  useSetCurrentWorkspaceMutation,
} from '../../../../client/generated/service';

export interface useSetWorkspaceProps {
  onCompleted?: (data: SetCurrentWorkspaceMutation) => void,
}

const useSetWorkspace = ({
  onCompleted,
}: useSetWorkspaceProps) => {
  const history = useHistory();

  const [setWorkspace, { loading }] = useSetCurrentWorkspaceMutation({
    onCompleted: (data) => {
      if (data.setCurrentWorkspace?.ok) {
        if (onCompleted) {
          onCompleted(data);
        } else {
          history.push('/home');
          window.location.reload();
        }
      } else {
        // Error!
        console.error('Was not able to set the current workspace');
      }
    },
  });

  const setCurrentWorkspace = (uid: string) => {
    setWorkspace({ variables: { uid } });
  };

  return {
    setCurrentWorkspace,
    loading,
  };
};

export default useSetWorkspace;
