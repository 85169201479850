import React, {
  FC,
} from 'react';
import Dropdown from '@paradime-io/pragma-ui-kit/lib/components/Dropdown';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { useHistory, useParams } from 'react-router-dom';
import { GqlParadimeAccountType } from '../../../client/generated/service';
import { lineageStore } from '../../../stores';
import SearchInput from './SearchInput';
import BranchDropDownContent from './BranchDropDownContent';
import { lineageParams } from '../hooks';
import { userHasLineageSearchAccess } from '../../../utilis/PermissionsService';

export interface SearchProps {
  branches: Map<string, string>,
  initialBranch: string,
  position?: 'bottom' | 'top',
  inputWidth?: number,
  dropDownContentPosition: 'top' | 'bottom'
  dropdownWidth?: number,
  currentBranch: string,
  setCurrentBranch: (newBranch: string) => void, // eslint-disable-line
  accessLevel: GqlParadimeAccountType
}

const Search: FC<SearchProps> = ({
  branches,
  position = 'bottom',
  inputWidth = 341,
  dropdownWidth = 122,
  dropDownContentPosition,
  currentBranch,
  setCurrentBranch,
  accessLevel,
}) => {
  const [type, view, color] = ['dense', 'raised', 'default'] as any[];
  const currentCommitHash = lineageStore((s) => s.currentCommitHash);
  const setCurrentCommitHash = lineageStore((s) => s.setCurrentCommitHash);
  const history = useHistory();
  const params = useParams<lineageParams>();

  const handleNewDropDown = (e: [string, string]) => {
    const [branch, commitHash] = e;
    setCurrentBranch(branch);
    setCurrentCommitHash(commitHash);

    window.analytics.track('lineage:nodes_searched');
    if (['node', 'model'].includes(params.id)) {
      history.push(`/lineage/${params.id}/${commitHash}/${params.node2Hash}`);
    }
  };

  const exposeInput = (nodeId: string) => {
    window.analytics.track('lineage:nodes_searched');
    history.push(`/lineage/node/${currentCommitHash}/${encodeURIComponent(btoa(nodeId))}`);
  };

  return (
    <>
      <SearchInput
        expose={exposeInput}
        color={color}
        view={view}
        type={type}
        commitHash={currentCommitHash || ''}
        position={position}
        width={inputWidth}
      />
      <div style={{ display: 'grid' }}>
        {
          userHasLineageSearchAccess(accessLevel)
            && (
              <Dropdown
                data-testid="searchDropdown"
                position={dropDownContentPosition}
                style={{
                  width: dropdownWidth,
                }}
                content={(
                  <BranchDropDownContent
                    color={color}
                    type={type}
                    view={view}
                    branches={branches}
                    currentBranch={currentBranch}
                    expose={handleNewDropDown}
                  />
          )}
                fill
                dense // @ts-ignore
                text={(
                  <p style={{ display: 'flex', margin: 0, alignItems: 'center' }}>
                    <Typography tagName="span" type="caption-small" color="default" colorStep="60">base: </Typography>
                    <Typography
                      style={{
                        textOverflow: 'ellipsis',
                        display: 'block',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                      }}
                      tagName="span"
                      type="caption"
                    >
                      {currentBranch}

                    </Typography>
                  </p>
          )}
                view="smooth"
              />
            )
        }

      </div>
    </>
  );
};

export default Search;
