/* eslint-disable no-case-declarations */
import { useEffect, useState } from 'react';
import { DropdownOption } from './NotificationDropdown';
import { useGetAlertNotificationsLazyQuery, KnockAlertChannelId } from '../../../client/generated/service';
import { ConnectedNotificatonIntegration, NotificationType } from '.';

export interface useGetAlertNotificationsProps {
  connectedIntegration: ConnectedNotificatonIntegration,
  notificationType: NotificationType,
}

export const useGetAlertNotifications = ({
  connectedIntegration,
  notificationType,
}: useGetAlertNotificationsProps) => {
  const [selectedChannelName, setSelectedChannelName] = useState<DropdownOption>({ label: '', value: '' });

  const [getAlertNotifications, { loading }] = useGetAlertNotificationsLazyQuery({
    onCompleted: (data) => {
      switch (connectedIntegration) {
        case ConnectedNotificatonIntegration.SLACK:
          const slackChannel = data.getAlertNotifications?.slackChannelIds?.[0];
          if (slackChannel) {
            setSelectedChannelName({ label: `#${slackChannel}`, value: slackChannel });
          } else {
            setSelectedChannelName({ label: '', value: '' });
          }
          break;
        case ConnectedNotificatonIntegration.TEAMS:
          const teamsChannel = data.getAlertNotifications?.msTeamsChannelNames?.[0];
          if (teamsChannel) {
            setSelectedChannelName({ label: teamsChannel, value: teamsChannel });
          } else {
            setSelectedChannelName({ label: '', value: '' });
          }
          break;
        default:
          break;
      }
    },
  });

  const getSelectedChannelName = () => {
    let channelId: KnockAlertChannelId | null = null;

    switch (connectedIntegration) {
      case ConnectedNotificatonIntegration.SLACK:
        if (notificationType === NotificationType.BOLT) {
          channelId = KnockAlertChannelId.SlackBoltAlert;
        }
        if (notificationType === NotificationType.RADAR) {
          channelId = KnockAlertChannelId.SlackDataAlert;
        }
        break;
      case ConnectedNotificatonIntegration.TEAMS:
        if (notificationType === NotificationType.BOLT) {
          channelId = KnockAlertChannelId.MsTeamsBoltAlert;
        }
        if (notificationType === NotificationType.RADAR) {
          channelId = KnockAlertChannelId.MsTeamsDataAlert;
        }
        break;
      default:
        break;
    }

    if (channelId) {
      getAlertNotifications({ variables: { alertChannel: channelId } });
    }
  };

  useEffect(() => {
    getSelectedChannelName();
  }, [connectedIntegration]);

  return {
    selectedChannelName,
    setSelectedChannelName,
    getSelectedChannelName,
    isLoadingChannelName: loading,
  };
};
