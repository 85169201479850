import { DateTime } from 'luxon';
import commandBarCallbacks from './callbacks';
import { getScheduleNames } from '../../../../client/service.graphql';
import { graphQLStore } from '../../../../stores';
import BoltScheduleNameStatusHTML from './bolt.styles';

const getBoltScheduleNames = async (query: string) => {
  const getLocalTime = (timeUTC: string) => {
    const dt = DateTime.fromISO(timeUTC);
    if (dt.isValid) {
      return dt.toLocaleString(DateTime.DATETIME_FULL);
    }
    return '-';
  };
  const { apolloClient } = graphQLStore.getState();
  const scheduleNamesQueryResponse = await apolloClient?.query({
    query: getScheduleNames,
    variables: {
      offset: 0,
      limit: 200,
      showInactive: false,
    },
    context: {
      operationType: 'service',
    },
  });
  const scheduleNames = scheduleNamesQueryResponse?.data?.getScheduleNames?.scheduleNames;
  return scheduleNames
    .filter((s: any) => s.name.includes(query))
    .map((s: any) => ({
      name: s.name,
      uuid: s.meta.uuid,
      __extraHTML: BoltScheduleNameStatusHTML(
        s.lastRunState.text,
        s.owner,
        getLocalTime(s.nextRun),
      ),
    }));
};

const openBoltScheduleCallback = (args: any, context: { history: any; }) => {
  const { history } = context;
  history.push(`/bolt/${args.schedule_name.uuid}`);
};

const addBoltCommands = () => {
  // open bolt
  window.CommandBar.addCommand({
    name: 'open_bolt',
    text: 'View schedules',
    template: {
      type: 'link',
      value: '/bolt',
      operation: 'router',
    },
    category: 'App',
    explanation: 'View status of dbt jobs in Bolt',
    tags: ['dbt', 'prod', 'runs', 'bolt'],
  });

  // open bolt schedules
  window.CommandBar.addRecords('bolt_schedules', [], {
    onInputChange: getBoltScheduleNames,
    labelKey: 'name',
  });
  window.CommandBar.addCallback(
    commandBarCallbacks.OPEN_BOLT_SCHEDULE,
    openBoltScheduleCallback,
  );
};

export default addBoltCommands;
