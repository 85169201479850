import React, {
  FC, ReactNode,
  useCallback,
} from 'react';
import Dropdown from '@paradime-io/pragma-ui-kit/lib/components/Dropdown';
import LineageControlButton from '@paradime-io/pragma-ui-kit/lib/components/LineageControlButton';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { useReactFlow } from 'reactflow';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';

export interface ControllerDropdownResetProps {
  filterComponent: ReactNode,
  currentDropdDown: string[],
  resetChecks: Function,
}

const ControllerDropdownReset: FC<ControllerDropdownResetProps> = ({
  filterComponent,
  currentDropdDown,
  resetChecks,
}) => {
  const { fitView, project } = useReactFlow();

  const handleResetAction = useCallback(() => {
    resetChecks();
    setTimeout(fitView, 1);
  }, [project]);
  return (
    <>
      <Dropdown
        data-testid="filterDropdown"
        position="top"
        content={filterComponent}
        fill
        dense // @ts-ignore
        text={(
          <Typography
            tagName="span"
            type="caption"
            style={{
              textOverflow: 'ellipsis',
              display: 'block',
              overflow: 'hidden',
            }}
          >
            {currentDropdDown[1] || currentDropdDown[0]}
          </Typography>
        )}
        view="smooth"
        style={{
          width: 314,
        }}
      />
      <LineageControlButton
        text="Reset"
        icon="reset"
        action={handleResetAction}
        buttonType="raised"
        eventContext={Contexts.LINEAGE}
        eventObject="resetControllerDropdown"
        eventAction={Actions.CLICKED}
      />
    </>
  );
};

export default ControllerDropdownReset;
