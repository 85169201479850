import React, { FC, useEffect, useState } from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import List, { GraphicTypes } from '@paradime-io/pragma-ui-kit/lib/components/List';
import { key } from '../../../utilis';
import * as S from '../Home.styles';

type TestimonialType = {
  avatar: string,
  testimonial: string,
  avatarName: string,
  avatarTitle: string,
}

interface TestimonialProps {
  feedbacks: TestimonialType[],
}

const TestimonialsCarousel:FC<TestimonialProps> = ({
  feedbacks,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % feedbacks.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [feedbacks.length]);

  return (
    <div style={{ height: '100px', position: 'relative' }}>
      {feedbacks.map((feedback, index) => (
        <S.CarouselItem active={index === activeIndex}>
          <AutoLayout
            key={key(`carousel-item-${index}`)}
            direction="vertical"
            distribution="packed"
            padding="none"
            verticalGap="dense"
            style={{ width: '75%' }}
            wrapperStyle={{ justifyContent: 'center' }}
          >
            <Typography
              tagName="span"
              type="body-small"
              color="primary"
              colorStep="0"
              style={{ textAlign: 'center' }}
            >
              {`"${feedback.testimonial}"`}
            </Typography>
            <S.CustomerTestimonialWrapper>
              <List
                view="dense"
                title={feedback.avatarName}
                graphic={{
                  type: GraphicTypes.IMAGE,
                  image: feedback.avatar,
                  circle: true,
                }}
                subtitle={feedback.avatarTitle}
                style={{
                  color: 'var(--white)',
                  width: '400px',
                }}
              />
            </S.CustomerTestimonialWrapper>
          </AutoLayout>
        </S.CarouselItem>
      ))}
    </div>
  );
};

export default TestimonialsCarousel;
