import React, { FC } from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Dropdown from '@paradime-io/pragma-ui-kit/lib/components/Dropdown';
import { MenuTemplate } from '@paradime-io/pragma-ui-kit/lib/components/Menu';
import { Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { DropdownItem } from '.';

export interface ActionBarProps {
  selectedWorkspace: DropdownItem,
  workspaceList: DropdownItem[],
  onWorkspaceChanged: (newWorkspace: DropdownItem) => void,
  selectedTimeFrame: DropdownItem,
  timeFrameList: DropdownItem[],
  onTimeFrameChanged: (newWorkspace: DropdownItem) => void,
}

const ActionBar: FC<ActionBarProps> = ({
  selectedWorkspace,
  workspaceList,
  onWorkspaceChanged,
  selectedTimeFrame,
  timeFrameList,
  onTimeFrameChanged,
}) => (
  <AutoLayout
    direction="horizontal"
    padding="none"
    horizontalGap="dense"
    distribution="packed"
  >
    <Dropdown
      view="outlined"
      color="default"
      dense
      label="Workspace"
      text={selectedWorkspace.label}
      content={(
        <MenuTemplate
          view="outlined"
          dense
          items={workspaceList
            .map((workspaceItem) => ({
              text: workspaceItem.label,
              type: 'item',
              onClick: () => onWorkspaceChanged(workspaceItem),
            }))}
          eventContext={Contexts.PLATFORM}
          style={{
            maxHeight: '250px',
            overflowY: 'auto',
          }}
        />
      )}
      style={{ width: '165px' }}
    />
    <Dropdown
      view="outlined"
      color="default"
      dense
      label="Time frame"
      text={selectedTimeFrame.label}
      content={(
        <MenuTemplate
          view="outlined"
          dense
          items={timeFrameList
            .map((timeFrameItem) => ({
              text: timeFrameItem.label,
              type: 'item',
              onClick: () => onTimeFrameChanged(timeFrameItem),
            }))}
          eventContext={Contexts.PLATFORM}
          style={{
            maxHeight: '250px',
            overflowY: 'auto',
          }}
        />
      )}
      style={{ width: '165px' }}
    />
  </AutoLayout>
);

export default ActionBar;
