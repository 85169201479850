import React, { FC } from 'react';

const TickCircle: FC = () => (
  <svg
    style={{
      justifySelf: 'center',
    }}
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1515_77407)">
      <path d="M36 72C16.128 72 0 55.872 0 36C0 16.128 16.128 0 36 0C55.872 0 72 16.128 72 36C72 55.872 55.872 72 36 72ZM54 21.6C52.992 21.6 52.092 21.996 51.444 22.644L28.8 45.324L20.556 37.044C19.908 36.396 19.008 36 18 36C16.02 36 14.4 37.62 14.4 39.6C14.4 40.608 14.796 41.508 15.444 42.156L26.244 52.956C26.892 53.604 27.792 54 28.8 54C29.808 54 30.708 53.604 31.356 52.956L56.556 27.756C57.204 27.108 57.6 26.208 57.6 25.2C57.6 23.22 55.98 21.6 54 21.6Z" fill="#1B7943" />
    </g>
    <defs>
      <clipPath id="clip0_1515_77407">
        <rect width="72" height="72" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default TickCircle;
