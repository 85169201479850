import React, {
  ChangeEvent,
  FC,
  useEffect,
  useState,
} from 'react';
import { Icon } from '@blueprintjs/core';
import { useHistory, useLocation } from 'react-router-dom';
import { InMemoryCache, ApolloClient } from '@apollo/client';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import DefaultInput from '@paradime-io/pragma-ui-kit/lib/components/DefaultInput';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import { Contexts, Actions } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import {
  IsOrgNameAvailableDocument,
  IsOrgNameAvailableQueryVariables,
  OnboardOrganizationDocument,
  OnboardOrganizationMutationVariables,
  IsOrgNameAvailableResponse,
  OnboardOrganizationResponse,
} from '../../../../client/generated/magnetar';
import PageTemplate from '../Utils/PageTemplate';
import { ValidationRules } from '../../../../utilis/useCheckValidation';
import synqEnterOrgNameSVG from '../images/synq-enter-org-name.svg';
import { SynqOnboardingRoutePrefix, SYNQ_ONBOARDING_PAGE } from '..';
import { graphQLStore } from '../../../../stores';
import { useSelectParadimeOrg } from './useSelectParadimeOrg';

const EnterOrgName: FC = () => {
  const [orgName, setOrgName] = useState('');
  const [isLoadingOrgNameAvailabilityCheck, setIsLoadingOrgNameAvailabilityCheck] = useState(false);
  const [isValidOrgName, setIsValidOrgName] = useState(true);
  const [orgNameAvailabilityIsChecked, setOrgNameAvailabilityIsChecked] = useState(false);
  const [orgNameIsAvailable, setOrgNameIsAvailable] = useState(false);
  const [nameIsSet, setNameIsSet] = useState(false);
  const [isLoadingOnboardOrg, setIsLoadingOnboardOrg] = useState(false);

  const clusterConfigFromStore = graphQLStore((state) => state.clusterConfig);
  const jwtToken = graphQLStore((state) => state.jwtToken);
  const setParadimeOrg = useSelectParadimeOrg();

  const history = useHistory();
  const { search } = useLocation();

  const magnetarClient = new ApolloClient({
    uri: clusterConfigFromStore?.magnetarUrl,
    cache: new InMemoryCache(),
    headers: {
      authorization: jwtToken ? `Bearer ${jwtToken}` : '',
    },
  });

  const checkOrgNameIsAvailable = async ({
    variables,
  }: { variables: IsOrgNameAvailableQueryVariables }) => {
    setIsLoadingOrgNameAvailabilityCheck(true);

    magnetarClient.query<{
      isOrgNameAvailable: IsOrgNameAvailableResponse
    }>({
      query: IsOrgNameAvailableDocument,
      variables,
    })
      .then(({ data }) => {
        const isOrgNameAvailable = data?.isOrgNameAvailable?.isAvailable;

        if (isOrgNameAvailable) {
          setOrgNameIsAvailable(isOrgNameAvailable);
        } else {
          history.push(`/${SynqOnboardingRoutePrefix}/${SYNQ_ONBOARDING_PAGE.ERROR_ORG_ALREADY_EXISTS}${search}`, { orgName });
        }
        setIsLoadingOrgNameAvailabilityCheck(false);
      });
  };

  const onboardOrgInBackend = async ({
    variables,
  }: { variables: OnboardOrganizationMutationVariables }) => {
    setIsLoadingOnboardOrg(true);

    magnetarClient.mutate<{
      onboardOrganization: OnboardOrganizationResponse
    }>({
      mutation: OnboardOrganizationDocument,
      variables,
    })
      .then(({ data }) => {
        if (!data) {
          setNameIsSet(false);
          setIsLoadingOnboardOrg(false);
          return;
        }

        const { errOrgExistsAlready } = data.onboardOrganization;
        if (errOrgExistsAlready) {
          history.push(`/${SynqOnboardingRoutePrefix}/${SYNQ_ONBOARDING_PAGE.ERROR_ORG_ALREADY_EXISTS}${search}`, { orgName });
        } else {
          setNameIsSet(data.onboardOrganization.ok);
          setParadimeOrg(orgName);
        }
        setIsLoadingOnboardOrg(false);
      });
  };

  const handleOrgNameChange = (e: ChangeEvent<HTMLInputElement>, hasError?: boolean) => {
    const { target: { value } } = e;
    setOrgName(value.toLowerCase());

    setIsValidOrgName(!hasError);
  };

  const handleClick = () => {
    if (isValidOrgName && orgName?.length > 0) {
      checkOrgNameIsAvailable({ variables: { orgName } });
      setOrgNameAvailabilityIsChecked(true);
    } else {
      setIsValidOrgName(false);
    }
  };

  useEffect(() => {
    if (orgNameAvailabilityIsChecked && orgNameIsAvailable && !nameIsSet) {
      onboardOrgInBackend({ variables: { orgName } });
    }
  }, [orgNameAvailabilityIsChecked, orgNameIsAvailable, nameIsSet]);

  return (
    <PageTemplate
      mainImage={synqEnterOrgNameSVG}
      title="Welcome to Paradime Synq"
      subtitle="Connecting data, analytics and business teams in a single collaborative workflow."
      withLogo
      withBox
      boxOrientation="vertical"
      boxContents={(
        <AutoLayout
          direction="vertical"
          padding="normal"
          verticalGap="expanded"
          distribution="space-between"
          alignment="center"
          style={{
            justifyItems: 'center',
            alignItems: 'center',
          }}
        >
          <Icon icon="office" color="var(--violet80)" iconSize={64} />
          <div style={{ height: '100px' }} />
          <AutoLayout
            direction="vertical"
            padding="none"
            verticalGap="expanded"
            distribution="packed"
            alignment="center"
            style={{
              justifyItems: 'center',
              alignItems: 'center',
            }}
          >
            <DefaultInput
              type="text"
              placeholder="Enter your organization name"
              label="Enter your organization name"
              view="simple"
              color="default"
              full
              dense
              value={orgName}
              expose={handleOrgNameChange}
              required
              validation={[
                {
                  rule: ValidationRules.ONLY_ALPHANUMERIC_HYPHEN_UNDERSCORE,
                  message: 'Only numbers, letters, hyphens and underscores',
                },
                {
                  rule: '^([a-zA-Z0-9-_]{3,50})$',
                  message: 'You must enter between 3 and 50 characters',
                },
              ]}
              checkValidationAfterEveryChange
              checkValidationOnBlur
            />
          </AutoLayout>
          <DefaultButton
            view="simple"
            text="Signup"
            onClick={handleClick}
            loading={isLoadingOrgNameAvailabilityCheck || isLoadingOnboardOrg}
            eventContext={Contexts.ONBOARDING}
            eventObject="submitOrgName"
            eventAction={Actions.CLICKED}
            eventProperties={{
              orgName,
            }}
          />
        </AutoLayout>
      )}
    />
  );
};

export default EnterOrgName;
