import React, { FC, useState } from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import WorkspaceCard from '@paradime-io/pragma-ui-kit/lib/components/WorkspaceCard';
import Spinner from '@paradime-io/pragma-ui-kit/lib/components/Spinner';
import flagEU from '../images/flag-eu.svg';
import flagUS from '../images/flag-us.svg';
import flagAU from '../images/flag-au.svg';
import {
  OrganisationData,
  LocationType,
} from '../../hooks/useOrganisation';

export interface KnownOrganisationsProps {
  showLoader: boolean,
  knownOrganisations: OrganisationData[],
  onSelectOrganisation: (name: string) => void,
}

const getOrganisationIcon = (key?: LocationType) => {
  switch (key) {
    case 'eu':
      return <img src={flagEU} alt="flag-eu" />;
    case 'us':
      return <img src={flagUS} alt="flag-us" />;
    case 'ap':
      return <img src={flagAU} alt="flag-ap" />;
    default:
      return null;
  }
};

const KnownOrganisations: FC<KnownOrganisationsProps> = ({
  showLoader,
  knownOrganisations,
  onSelectOrganisation,
}) => {
  const [isLoggingIn, setIsLoggingIn] = useState<boolean[]>(
    Array(knownOrganisations.length).fill(false),
  );

  const handleWorkspaceCardClick = (orgName: string, orgIdx: number) => {
    onSelectOrganisation(orgName);
    const updatedLoggingIn = isLoggingIn.map((_, idx) => ((idx === orgIdx)));
    setIsLoggingIn(updatedLoggingIn);
  };

  return (
    <AutoLayout
      direction="vertical"
      padding="ultra-dense"
      verticalGap="dense"
      distribution="packed"
      style={{ maxHeight: '200px', overflowY: 'auto', overflowX: 'hidden' }}
    >
      {knownOrganisations.map((organisation, orgIdx) => (
        <div key={`${organisation.name}-${organisation.location}`} style={{ position: 'relative' }}>
          <WorkspaceCard
            key={organisation.name}
            onClick={() => handleWorkspaceCardClick(organisation.name, orgIdx)}
            onEnterPressed={() => handleWorkspaceCardClick(organisation.name, orgIdx)}
            workspaceName=""
            showJoinWorkspaceChip={false}
            customElement={(
              <AutoLayout
                direction="horizontal"
                padding="none"
                distribution="packed"
                style={{
                  position: 'absolute',
                  left: '16px',
                }}
              >
                {getOrganisationIcon(organisation.location)}
                <Typography
                  font="inter"
                  type="body-bold"
                  color="default"
                  colorStep="100"
                >
                  {organisation.name}
                </Typography>
                {isLoggingIn[orgIdx] && <Spinner width={20} height={20} thin />}
              </AutoLayout>
            )}
          />
        </div>
      ))}
      {showLoader
        && (
          <>
            <div key="loader" style={{ position: 'relative' }}>
              <WorkspaceCard
                key="loader"
                onClick={() => { }}
                onEnterPressed={() => { }}
                workspaceName=""
                showJoinWorkspaceChip={false}
                customElement={(
                  <AutoLayout
                    direction="horizontal"
                    padding="none"
                    distribution="packed"
                    style={{
                      position: 'absolute',
                      left: '16px',
                    }}
                  >
                    <Spinner width={20} height={20} thin />
                    <Typography
                      font="inter"
                      type="body-bold"
                      color="default"
                      colorStep="100"
                    >
                      Loading...
                    </Typography>
                  </AutoLayout>
                )}
              />
            </div>
            <Typography
              font="inter"
              type="caption"
              color="default"
              colorStep="100"
            >
              Taking too long? Please reach out to support@paradime.io
            </Typography>
          </>
        )}
    </AutoLayout>
  );
};

export default KnownOrganisations;
