import { catalogueDependenciesTabProps } from '../types';

export const catalogueDependenciesTab = ({
  resultItem,
}: catalogueDependenciesTabProps) => {
  switch (resultItem?.__typename) {
    case 'GQLCatalogueTableauDashboardItem':
    case 'GQLCatalogueTableauDatasourceItem':
    case 'GQLCatalogueTableauWorksheetItem':
      return {
        sources: [],
        nodeId: resultItem?.lineageRef?.nodeId,
      };
    default:
      return { sources: [] };
  }
};
