import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {"context":{"operationType":"magnetar"}} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Upload: any;
};

export type Action = ActionThreadResolved | ActionThreadUnresolved;

export type ActionMessage = {
  __typename?: 'ActionMessage';
  action: Action;
  id: Scalars['String'];
  threadID: Scalars['String'];
  timestamp: Scalars['Int'];
};

export type ActionThreadResolved = {
  __typename?: 'ActionThreadResolved';
  resolvedBy: User;
};

export type ActionThreadUnresolved = {
  __typename?: 'ActionThreadUnresolved';
  unresolvedBy: User;
};

export type AlterUserAccountType = {
  __typename?: 'AlterUserAccountType';
  ok: Scalars['Boolean'];
};

export type Annotation = {
  __typename?: 'Annotation';
  fileID: Scalars['String'];
  fileName: Scalars['String'];
  fileURL: Scalars['String'];
  metadata: Scalars['String'];
};

export type AnnotationInput = {
  fileID: Scalars['String'];
  metadata: Scalars['String'];
};

export type AppIdentity = {
  __typename?: 'AppIdentity';
  dialect?: Maybe<Scalars['String']>;
  environment: Scalars['String'];
  name: Scalars['String'];
};

export type AsanaCodeChallenge = {
  __typename?: 'AsanaCodeChallenge';
  codeChallenge: Scalars['String'];
  codeChallengeMethod: Scalars['String'];
  ok: Scalars['Boolean'];
};

export type AsanaInput = {
  projectID: Scalars['String'];
  taskID: Scalars['String'];
};

export type AsanaTask = {
  __typename?: 'AsanaTask';
  taskID: Scalars['String'];
  url: Scalars['String'];
};

export type AsanaTokens = {
  __typename?: 'AsanaTokens';
  accessToken: Scalars['String'];
  expiresIn: Scalars['Int'];
  ok: Scalars['Boolean'];
  refreshToken: Scalars['String'];
};

export type Attachment = {
  __typename?: 'Attachment';
  fileID: Scalars['String'];
  fileName: Scalars['String'];
  fileURL: Scalars['String'];
};

export type CompanyOnboardingSlackCode = {
  __typename?: 'CompanyOnboardingSlackCode';
  ok: Scalars['Boolean'];
};

export type CreateCordSessionToken = {
  __typename?: 'CreateCordSessionToken';
  ok: Scalars['Boolean'];
  sessionToken?: Maybe<Scalars['String']>;
};

export type CreateMessageInThreadResponse = {
  __typename?: 'CreateMessageInThreadResponse';
  messageID: Scalars['String'];
  ok: Scalars['Boolean'];
  threadID: Scalars['String'];
};

export type CreateThreadWithMessageResponse = {
  __typename?: 'CreateThreadWithMessageResponse';
  messageID: Scalars['String'];
  ok: Scalars['Boolean'];
  threadID: Scalars['String'];
};

export type DeleteMessageResponse = {
  __typename?: 'DeleteMessageResponse';
  ok: Scalars['Boolean'];
};

export type DisconnectSlack = {
  __typename?: 'DisconnectSlack';
  ok: Scalars['Boolean'];
};

export type EditMessageResponse = {
  __typename?: 'EditMessageResponse';
  ok: Scalars['Boolean'];
};

export type EmojiReaction = {
  __typename?: 'EmojiReaction';
  emoji: Scalars['String'];
  reactedBy: Array<User>;
};

export type FileAttachmentInput = {
  fileID: Scalars['String'];
};

export type GqlAutomaticSignUpDomainWhitelist = {
  __typename?: 'GQLAutomaticSignUpDomainWhitelist';
  domains?: Maybe<Array<Scalars['String']>>;
  ok: Scalars['Boolean'];
};

export type GqlIntegrationList = {
  __typename?: 'GQLIntegrationList';
  dbtCloud: Scalars['String'];
  dbtCloudMetadata: GqlIntegrationMetadata;
  fivetran: Scalars['String'];
  fivetranMetadata: GqlIntegrationMetadata;
  githubApp: Scalars['String'];
  githubAppMetadata: GqlIntegrationMetadata;
  hightouch: Scalars['String'];
  hightouchMetadata: GqlIntegrationMetadata;
  looker: Scalars['String'];
  lookerMetadata: GqlIntegrationMetadata;
  loom: Scalars['String'];
  loomMetadata: GqlIntegrationMetadata;
  slack: Scalars['String'];
  slackMetadata: GqlIntegrationMetadata;
  tableau: Scalars['String'];
  tableauMetadata: GqlIntegrationMetadata;
};

export type GqlIntegrationMetadata = {
  __typename?: 'GQLIntegrationMetadata';
  progress?: Maybe<Scalars['Float']>;
  progressMessage?: Maybe<Scalars['String']>;
  state: ParadimeIntegrationState;
};

export type GqlListTeam = {
  __typename?: 'GQLListTeam';
  active: Array<GqlSignedUpTeamMember>;
  deleted: Array<GqlSignedUpTeamMember>;
  members: Array<GqlTeamMember>;
  ok: Scalars['Boolean'];
  pending: Array<GqlPendingTeamMember>;
};

export type GqlPendingTeamMember = {
  __typename?: 'GQLPendingTeamMember';
  accountType: Scalars['String'];
  avatarUrl?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  fullNameIfExists?: Maybe<Scalars['String']>;
  inviteId: Scalars['String'];
  inviteStatus: InviteStatus;
};

export type GqlSignedUpTeamMember = {
  __typename?: 'GQLSignedUpTeamMember';
  accountIsDisabled: Scalars['Boolean'];
  accountType: Scalars['String'];
  avatarUrl?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  fullName: Scalars['String'];
  lastLogin?: Maybe<Scalars['String']>;
  memberId: Scalars['Int'];
  role?: Maybe<Scalars['String']>;
  team?: Maybe<Scalars['String']>;
  userId: Scalars['Int'];
  userUid: Scalars['String'];
};

export type GqlSlackUser = {
  __typename?: 'GQLSlackUser';
  profileDisplayName: Scalars['String'];
  profileDisplayNameNormalized: Scalars['String'];
  profileImage24?: Maybe<Scalars['String']>;
  profileImage32?: Maybe<Scalars['String']>;
  profileImage48?: Maybe<Scalars['String']>;
  profileImage72?: Maybe<Scalars['String']>;
  profileImage192?: Maybe<Scalars['String']>;
  profileImage512?: Maybe<Scalars['String']>;
  profileImage1024?: Maybe<Scalars['String']>;
  profileRealName: Scalars['String'];
  profileRealNameNormalized: Scalars['String'];
  slackId: Scalars['String'];
  userName: Scalars['String'];
};

export type GqlTeamMember = GqlPendingTeamMember | GqlSignedUpTeamMember;

export type GetEmailNotificationsPreferenceResponse = {
  __typename?: 'GetEmailNotificationsPreferenceResponse';
  ok: Scalars['Boolean'];
  subscribed: Scalars['Boolean'];
};

export type GetPageDetailsResponse = {
  __typename?: 'GetPageDetailsResponse';
  ok: Scalars['Boolean'];
  page?: Maybe<Page>;
};

export type GetSlackChannelForThreadResponse = {
  __typename?: 'GetSlackChannelForThreadResponse';
  channel?: Maybe<SlackChannel>;
  isThreadShared: Scalars['Boolean'];
  ok: Scalars['Boolean'];
};

export type GetSlackChannelsResponse = {
  __typename?: 'GetSlackChannelsResponse';
  channels: Array<SlackChannel>;
  ok: Scalars['Boolean'];
};

export type GetSlackUserPresenceResponse = {
  __typename?: 'GetSlackUserPresenceResponse';
  ok: Scalars['Boolean'];
  presence: Scalars['String'];
};

export type GetSlackUsersResponse = {
  __typename?: 'GetSlackUsersResponse';
  ok: Scalars['Boolean'];
  users: Array<User>;
};

export type GetThreadResponse = {
  __typename?: 'GetThreadResponse';
  ok: Scalars['Boolean'];
  thread?: Maybe<Thread>;
};

export type InboxUpdated = LastReadMessageUpdated | MessageAdded | MessageDeleted | MessageEdited | ThreadDeleted;

export type InboxUpdatedResponse = {
  __typename?: 'InboxUpdatedResponse';
  ok: Scalars['Boolean'];
  update: InboxUpdated;
};

export enum InviteStatus {
  Expired = 'Expired',
  Sent = 'Sent'
}

export type InviteViaEMail = {
  __typename?: 'InviteViaEMail';
  ok: Scalars['Boolean'];
};

export type InviteViaEMailObject = {
  accountType: Scalars['String'];
  email: Scalars['String'];
};

export type InviteViaSlack = {
  __typename?: 'InviteViaSlack';
  ok: Scalars['Boolean'];
};

export type InviteViaSlackObject = {
  accountType: Scalars['String'];
  slackId: Scalars['String'];
};

export type IsOrgNameAvailableResponse = {
  __typename?: 'IsOrgNameAvailableResponse';
  isAvailable: Scalars['Boolean'];
  ok: Scalars['Boolean'];
};

export type IsThreadSubscribedResponse = {
  __typename?: 'IsThreadSubscribedResponse';
  ok: Scalars['Boolean'];
  subscribed: Scalars['Boolean'];
};

export type JiraInput = {
  baseUrl: Scalars['String'];
  issueID: Scalars['String'];
};

export type JiraTask = {
  __typename?: 'JiraTask';
  issueID: Scalars['String'];
  url: Scalars['String'];
};

export type JiraTokens = {
  __typename?: 'JiraTokens';
  accessToken: Scalars['String'];
  expiresIn: Scalars['Int'];
  ok: Scalars['Boolean'];
  refreshToken: Scalars['String'];
};

export type LastReadMessageUpdated = {
  __typename?: 'LastReadMessageUpdated';
  lastReadMessageID: Scalars['String'];
  threadID: Scalars['String'];
};

export type LinearInput = {
  issueID: Scalars['String'];
  organizationURLKey: Scalars['String'];
};

export type LinearTask = {
  __typename?: 'LinearTask';
  issueID: Scalars['String'];
  url: Scalars['String'];
};

export type LinearToken = {
  __typename?: 'LinearToken';
  accessToken: Scalars['String'];
  expiresIn: Scalars['Int'];
  ok: Scalars['Boolean'];
};

export type MarkMessageAsReadResponse = {
  __typename?: 'MarkMessageAsReadResponse';
  ok: Scalars['Boolean'];
};

export type Message = ActionMessage | UserMessage;

export type MessageAdded = {
  __typename?: 'MessageAdded';
  message: Message;
};

export type MessageDeleted = {
  __typename?: 'MessageDeleted';
  messageID: Scalars['String'];
  threadID: Scalars['String'];
};

export type MessageEdited = {
  __typename?: 'MessageEdited';
  editedMessage: Message;
};

export type MessageEmojiReactionUpdated = {
  __typename?: 'MessageEmojiReactionUpdated';
  action: MessageEmojiReactionUpdatedAction;
  emoji: Scalars['String'];
  messageID: Scalars['String'];
  reactedBy: User;
  threadID: Scalars['String'];
};

export enum MessageEmojiReactionUpdatedAction {
  Added = 'Added',
  Removed = 'Removed'
}

export enum MessageSource {
  Email = 'Email',
  SidePanel = 'SidePanel',
  Slack = 'Slack'
}

export type Mutation = {
  __typename?: 'Mutation';
  alterUserAccountType?: Maybe<AlterUserAccountType>;
  companyOnboardingSlackCode: CompanyOnboardingSlackCode;
  createCordSessionToken?: Maybe<CreateCordSessionToken>;
  createMessageInThread: CreateMessageInThreadResponse;
  createThreadWithMessage: CreateThreadWithMessageResponse;
  deleteMessage: DeleteMessageResponse;
  disconnectSlack: DisconnectSlack;
  editMessage: EditMessageResponse;
  getAsanaCodeChallenge: AsanaCodeChallenge;
  getAsanaTokens: AsanaTokens;
  getJiraTokens: JiraTokens;
  getLinearToken: LinearToken;
  inviteViaEmail: InviteViaEMail;
  inviteViaSlack: InviteViaSlack;
  markMessageAsRead: MarkMessageAsReadResponse;
  onboardOrganization: OnboardOrganizationResponse;
  onboardUser: OnboardUserResponse;
  putPage: PutPageResponse;
  reactivateUser?: Maybe<ReactivateUser>;
  refreshAsanaTokens: AsanaTokens;
  refreshJiraTokens: JiraTokens;
  removeUser?: Maybe<RemoveUser>;
  setSignUpDomainWhitelist?: Maybe<SetSignUpDomainWhitelist>;
  shareThreadToEmail: ShareThreadToEmailResponse;
  shareThreadToSlack: ShareThreadToSlackResponse;
  undoDeleteMessage: UndoDeleteMessageResponse;
  updateEmailNotificationsPreference: UpdateEmailNotificationsPreferenceResponse;
  updateMessageEmojiReaction: UpdateMessageEmojiReactionResponse;
  updateOrgInfo: UpdateOrgInfoResponse;
  updateThreadResolvedStatus: UpdateThreadResolvedStatus;
  updateThreadSubscription: UpdateThreadSubscriptionResponse;
  updateUserInfo: UpdateUserInfoResponse;
  uploadFile: UploadFileResponse;
};


export type MutationAlterUserAccountTypeArgs = {
  accountType: Scalars['String'];
  userId: Scalars['Int'];
};


export type MutationCompanyOnboardingSlackCodeArgs = {
  code: Scalars['String'];
  redirectUri: Scalars['String'];
};


export type MutationCreateMessageInThreadArgs = {
  annotations?: InputMaybe<Array<AnnotationInput>>;
  asana?: InputMaybe<AsanaInput>;
  attachments?: InputMaybe<Array<FileAttachmentInput>>;
  jira?: InputMaybe<JiraInput>;
  linear?: InputMaybe<LinearInput>;
  text?: InputMaybe<Scalars['String']>;
  threadID: Scalars['String'];
};


export type MutationCreateThreadWithMessageArgs = {
  annotations?: InputMaybe<Array<AnnotationInput>>;
  asana?: InputMaybe<AsanaInput>;
  attachments?: InputMaybe<Array<FileAttachmentInput>>;
  jira?: InputMaybe<JiraInput>;
  linear?: InputMaybe<LinearInput>;
  pageID: Scalars['String'];
  text?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteMessageArgs = {
  messageID: Scalars['String'];
  threadID: Scalars['String'];
};


export type MutationEditMessageArgs = {
  messageID: Scalars['String'];
  text: Scalars['String'];
  threadID: Scalars['String'];
};


export type MutationGetAsanaTokensArgs = {
  authCode: Scalars['String'];
  redirectURL: Scalars['String'];
};


export type MutationGetJiraTokensArgs = {
  authCode: Scalars['String'];
  redirectURL: Scalars['String'];
};


export type MutationGetLinearTokenArgs = {
  authCode: Scalars['String'];
  redirectURL: Scalars['String'];
};


export type MutationInviteViaEmailArgs = {
  users: Array<InviteViaEMailObject>;
};


export type MutationInviteViaSlackArgs = {
  users: Array<InviteViaSlackObject>;
};


export type MutationMarkMessageAsReadArgs = {
  messageID: Scalars['String'];
  threadID: Scalars['String'];
};


export type MutationOnboardOrganizationArgs = {
  orgName: Scalars['String'];
};


export type MutationOnboardUserArgs = {
  inviteToken?: InputMaybe<Scalars['String']>;
};


export type MutationPutPageArgs = {
  id: Scalars['String'];
  imageB64: Scalars['String'];
  title: Scalars['String'];
  url: Scalars['String'];
};


export type MutationReactivateUserArgs = {
  userUid: Scalars['String'];
};


export type MutationRefreshAsanaTokensArgs = {
  refreshToken: Scalars['String'];
};


export type MutationRefreshJiraTokensArgs = {
  refreshToken: Scalars['String'];
};


export type MutationRemoveUserArgs = {
  userUid: Scalars['String'];
};


export type MutationSetSignUpDomainWhitelistArgs = {
  domains?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationShareThreadToEmailArgs = {
  email: Scalars['String'];
  threadID: Scalars['String'];
};


export type MutationShareThreadToSlackArgs = {
  channelID: Scalars['String'];
  threadID: Scalars['String'];
};


export type MutationUndoDeleteMessageArgs = {
  messageID: Scalars['String'];
  threadID: Scalars['String'];
};


export type MutationUpdateEmailNotificationsPreferenceArgs = {
  receiveEmails: Scalars['Boolean'];
};


export type MutationUpdateMessageEmojiReactionArgs = {
  action: UpdateReactionAction;
  emoji: Scalars['String'];
  messageID: Scalars['String'];
  threadID: Scalars['String'];
};


export type MutationUpdateOrgInfoArgs = {
  autoJoinDomain: Scalars['String'];
  orgSize: Scalars['String'];
};


export type MutationUpdateThreadResolvedStatusArgs = {
  resolved: Scalars['Boolean'];
  threadID: Scalars['String'];
};


export type MutationUpdateThreadSubscriptionArgs = {
  subscribed: Scalars['Boolean'];
  threadID: Scalars['String'];
};


export type MutationUpdateUserInfoArgs = {
  role: Scalars['String'];
  team: Scalars['String'];
};


export type MutationUploadFileArgs = {
  file: Scalars['Upload'];
};

export type OnboardOrganizationResponse = {
  __typename?: 'OnboardOrganizationResponse';
  errOrgExistsAlready: Scalars['Boolean'];
  ok: Scalars['Boolean'];
};

export enum OnboardUserFailureReason {
  InviteTokenExpired = 'INVITE_TOKEN_EXPIRED',
  InviteTokenInvalid = 'INVITE_TOKEN_INVALID',
  None = 'NONE',
  NoInviteTokenAndCannotAutoJoin = 'NO_INVITE_TOKEN_AND_CANNOT_AUTO_JOIN',
  UserExistsAlready = 'USER_EXISTS_ALREADY'
}

export type OnboardUserResponse = {
  __typename?: 'OnboardUserResponse';
  failureReason: OnboardUserFailureReason;
  ok: Scalars['Boolean'];
};

export type Page = {
  __typename?: 'Page';
  id: Scalars['String'];
  imageB64: Scalars['String'];
  title: Scalars['String'];
  url: Scalars['String'];
};

export type PageUpdated = MessageAdded | MessageDeleted | MessageEdited | ThreadDeleted;

export type PageUpdatedResponse = {
  __typename?: 'PageUpdatedResponse';
  ok: Scalars['Boolean'];
  update: PageUpdated;
};

export enum ParadimeIntegrationState {
  Activating = 'ACTIVATING',
  Active = 'ACTIVE',
  Available = 'AVAILABLE'
}

export type PlainText = {
  __typename?: 'PlainText';
  isEdited: Scalars['Boolean'];
  text: Scalars['String'];
};

export type PutPageResponse = {
  __typename?: 'PutPageResponse';
  ok: Scalars['Boolean'];
};

export type Query = {
  __typename?: 'Query';
  appIdentity?: Maybe<AppIdentity>;
  getEmailNotificationsPreference: GetEmailNotificationsPreferenceResponse;
  getMessagesInThread: Array<Message>;
  getPageDetails: GetPageDetailsResponse;
  getSlackChannelForThread: GetSlackChannelForThreadResponse;
  getSlackChannels: GetSlackChannelsResponse;
  getSlackUserPresence: GetSlackUserPresenceResponse;
  getSlackUsers: GetSlackUsersResponse;
  getThread: GetThreadResponse;
  getThreadsInInbox: Array<Thread>;
  getThreadsOnCurrentPage?: Maybe<Array<Thread>>;
  isOrgNameAvailable: IsOrgNameAvailableResponse;
  isSlackTeamIDKnown: Scalars['Boolean'];
  isThreadSubscribed: IsThreadSubscribedResponse;
  listTeam: GqlListTeam;
  organisationIntegrationList: GqlIntegrationList;
  signUpDomainWhitelist: GqlAutomaticSignUpDomainWhitelist;
  slackSearch?: Maybe<SlackSearchUsersResult>;
  userIdentity?: Maybe<UserIdentity>;
};


export type QueryGetMessagesInThreadArgs = {
  threadID: Scalars['String'];
};


export type QueryGetPageDetailsArgs = {
  pageID: Scalars['String'];
};


export type QueryGetSlackChannelForThreadArgs = {
  threadID: Scalars['String'];
};


export type QueryGetSlackUserPresenceArgs = {
  userID: Scalars['String'];
};


export type QueryGetThreadArgs = {
  threadID: Scalars['String'];
};


export type QueryGetThreadsOnCurrentPageArgs = {
  filter: ThreadsFilter;
  pageID: Scalars['String'];
};


export type QueryIsOrgNameAvailableArgs = {
  orgName: Scalars['String'];
};


export type QueryIsSlackTeamIdKnownArgs = {
  slackTeamID: Scalars['String'];
};


export type QueryIsThreadSubscribedArgs = {
  threadID: Scalars['String'];
};


export type QuerySlackSearchArgs = {
  query: Scalars['String'];
};

export type ReactivateUser = {
  __typename?: 'ReactivateUser';
  ok: Scalars['Boolean'];
};

export type RemoveUser = {
  __typename?: 'RemoveUser';
  ok: Scalars['Boolean'];
};

export type SetSignUpDomainWhitelist = {
  __typename?: 'SetSignUpDomainWhitelist';
  ok: Scalars['Boolean'];
};

export type ShareThreadToEmailResponse = {
  __typename?: 'ShareThreadToEmailResponse';
  ok: Scalars['Boolean'];
};

export type ShareThreadToSlackResponse = {
  __typename?: 'ShareThreadToSlackResponse';
  ok: Scalars['Boolean'];
};

export type SlackAvatars = {
  __typename?: 'SlackAvatars';
  image24: Scalars['String'];
  image32: Scalars['String'];
  image48: Scalars['String'];
  image72: Scalars['String'];
  image192: Scalars['String'];
  original: Scalars['String'];
};

export type SlackChannel = {
  __typename?: 'SlackChannel';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type SlackSearchUsersResult = {
  __typename?: 'SlackSearchUsersResult';
  ok: Scalars['Boolean'];
  users: Array<GqlSlackUser>;
};

export type Subscription = {
  __typename?: 'Subscription';
  inboxUpdated: InboxUpdatedResponse;
  pageUpdated: PageUpdatedResponse;
  threadUpdated: ThreadUpdatedResponse;
};


export type SubscriptionPageUpdatedArgs = {
  pageID: Scalars['String'];
};


export type SubscriptionThreadUpdatedArgs = {
  threadID: Scalars['String'];
};

export type Task = AsanaTask | JiraTask | LinearTask;

export type Thread = {
  __typename?: 'Thread';
  id: Scalars['String'];
  isSharedToSlackChannel: Scalars['Boolean'];
  lastReadMessageID: Scalars['String'];
  latestMessage: Message;
  pageID: Scalars['String'];
  resolved: Scalars['Boolean'];
};

export type ThreadDeleted = {
  __typename?: 'ThreadDeleted';
  threadID: Scalars['String'];
};

export type ThreadSharedToSlack = {
  __typename?: 'ThreadSharedToSlack';
  threadID: Scalars['String'];
};

export type ThreadUpdated = MessageEmojiReactionUpdated | ThreadSharedToSlack;

export type ThreadUpdatedResponse = {
  __typename?: 'ThreadUpdatedResponse';
  ok: Scalars['Boolean'];
  update: ThreadUpdated;
};

export type ThreadsFilter = {
  includeResolved: Scalars['Boolean'];
  includeUnresolved: Scalars['Boolean'];
};

export type UndoDeleteMessageResponse = {
  __typename?: 'UndoDeleteMessageResponse';
  ok: Scalars['Boolean'];
};

export type UpdateEmailNotificationsPreferenceResponse = {
  __typename?: 'UpdateEmailNotificationsPreferenceResponse';
  ok: Scalars['Boolean'];
};

export type UpdateMessageEmojiReactionResponse = {
  __typename?: 'UpdateMessageEmojiReactionResponse';
  ok: Scalars['Boolean'];
};

export type UpdateOrgInfoResponse = {
  __typename?: 'UpdateOrgInfoResponse';
  ok: Scalars['Boolean'];
};

export enum UpdateReactionAction {
  Add = 'ADD',
  Remove = 'REMOVE'
}

export type UpdateThreadResolvedStatus = {
  __typename?: 'UpdateThreadResolvedStatus';
  ok: Scalars['Boolean'];
};

export type UpdateThreadSubscriptionResponse = {
  __typename?: 'UpdateThreadSubscriptionResponse';
  ok: Scalars['Boolean'];
};

export type UpdateUserInfoResponse = {
  __typename?: 'UpdateUserInfoResponse';
  ok: Scalars['Boolean'];
};

export type UploadFileResponse = {
  __typename?: 'UploadFileResponse';
  fileID: Scalars['String'];
  ok: Scalars['Boolean'];
};

export type User = {
  __typename?: 'User';
  avatars: SlackAvatars;
  email: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  realName: Scalars['String'];
  statusEmoji: Scalars['String'];
  statusText: Scalars['String'];
};

export type UserIdentity = {
  __typename?: 'UserIdentity';
  accessLevels: Array<Scalars['String']>;
  avatarUrl?: Maybe<Scalars['String']>;
  bearerToken?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  companyRegion: Scalars['String'];
  companyToken: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  intercomHmac?: Maybe<Scalars['String']>;
  inviteLevels: Array<Scalars['String']>;
  isFirstAdmin: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  team?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
};

export type UserMessage = {
  __typename?: 'UserMessage';
  annotations: Array<Annotation>;
  attachments: Array<Attachment>;
  creator: User;
  id: Scalars['String'];
  mentionedUsers: Array<User>;
  reactions?: Maybe<Array<EmojiReaction>>;
  source: MessageSource;
  task?: Maybe<Task>;
  text?: Maybe<PlainText>;
  threadID: Scalars['String'];
  timestamp: Scalars['Int'];
};

export type IsOrgNameAvailableQueryVariables = Exact<{
  orgName: Scalars['String'];
}>;


export type IsOrgNameAvailableQuery = { __typename?: 'Query', isOrgNameAvailable: { __typename?: 'IsOrgNameAvailableResponse', ok: boolean, isAvailable: boolean } };

export type OnboardOrganizationMutationVariables = Exact<{
  orgName: Scalars['String'];
}>;


export type OnboardOrganizationMutation = { __typename?: 'Mutation', onboardOrganization: { __typename?: 'OnboardOrganizationResponse', ok: boolean, errOrgExistsAlready: boolean } };

export type UpdateUserInfoMutationVariables = Exact<{
  role: Scalars['String'];
  team: Scalars['String'];
}>;


export type UpdateUserInfoMutation = { __typename?: 'Mutation', updateUserInfo: { __typename?: 'UpdateUserInfoResponse', ok: boolean } };

export type UpdateOrgInfoMutationVariables = Exact<{
  orgSize: Scalars['String'];
  autoJoinDomain: Scalars['String'];
}>;


export type UpdateOrgInfoMutation = { __typename?: 'Mutation', updateOrgInfo: { __typename?: 'UpdateOrgInfoResponse', ok: boolean } };

export type MagnetarUserIdentityQueryVariables = Exact<{ [key: string]: never; }>;


export type MagnetarUserIdentityQuery = { __typename?: 'Query', userIdentity?: { __typename?: 'UserIdentity', uid?: string | null, name?: string | null, email?: string | null, avatarUrl?: string | null, role?: string | null, team?: string | null, companyName?: string | null, companyToken: string, companyRegion: string, accessLevels: Array<string>, inviteLevels: Array<string>, isFirstAdmin: boolean, intercomHmac?: string | null, bearerToken?: string | null } | null };

export type OnboardUserMutationVariables = Exact<{
  inviteToken?: InputMaybe<Scalars['String']>;
}>;


export type OnboardUserMutation = { __typename?: 'Mutation', onboardUser: { __typename?: 'OnboardUserResponse', ok: boolean, failureReason: OnboardUserFailureReason } };


export const IsOrgNameAvailableDocument = gql`
    query isOrgNameAvailable($orgName: String!) {
  isOrgNameAvailable(orgName: $orgName) {
    ok
    isAvailable
  }
}
    `;

/**
 * __useIsOrgNameAvailableQuery__
 *
 * To run a query within a React component, call `useIsOrgNameAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsOrgNameAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsOrgNameAvailableQuery({
 *   variables: {
 *      orgName: // value for 'orgName'
 *   },
 * });
 */
export function useIsOrgNameAvailableQuery(baseOptions: Apollo.QueryHookOptions<IsOrgNameAvailableQuery, IsOrgNameAvailableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsOrgNameAvailableQuery, IsOrgNameAvailableQueryVariables>(IsOrgNameAvailableDocument, options);
      }
export function useIsOrgNameAvailableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsOrgNameAvailableQuery, IsOrgNameAvailableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsOrgNameAvailableQuery, IsOrgNameAvailableQueryVariables>(IsOrgNameAvailableDocument, options);
        }
export type IsOrgNameAvailableQueryHookResult = ReturnType<typeof useIsOrgNameAvailableQuery>;
export type IsOrgNameAvailableLazyQueryHookResult = ReturnType<typeof useIsOrgNameAvailableLazyQuery>;
export type IsOrgNameAvailableQueryResult = Apollo.QueryResult<IsOrgNameAvailableQuery, IsOrgNameAvailableQueryVariables>;
export const OnboardOrganizationDocument = gql`
    mutation onboardOrganization($orgName: String!) {
  onboardOrganization(orgName: $orgName) {
    ok
    errOrgExistsAlready
  }
}
    `;
export type OnboardOrganizationMutationFn = Apollo.MutationFunction<OnboardOrganizationMutation, OnboardOrganizationMutationVariables>;

/**
 * __useOnboardOrganizationMutation__
 *
 * To run a mutation, you first call `useOnboardOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnboardOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onboardOrganizationMutation, { data, loading, error }] = useOnboardOrganizationMutation({
 *   variables: {
 *      orgName: // value for 'orgName'
 *   },
 * });
 */
export function useOnboardOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<OnboardOrganizationMutation, OnboardOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OnboardOrganizationMutation, OnboardOrganizationMutationVariables>(OnboardOrganizationDocument, options);
      }
export type OnboardOrganizationMutationHookResult = ReturnType<typeof useOnboardOrganizationMutation>;
export type OnboardOrganizationMutationResult = Apollo.MutationResult<OnboardOrganizationMutation>;
export type OnboardOrganizationMutationOptions = Apollo.BaseMutationOptions<OnboardOrganizationMutation, OnboardOrganizationMutationVariables>;
export const UpdateUserInfoDocument = gql`
    mutation updateUserInfo($role: String!, $team: String!) {
  updateUserInfo(role: $role, team: $team) {
    ok
  }
}
    `;
export type UpdateUserInfoMutationFn = Apollo.MutationFunction<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>;

/**
 * __useUpdateUserInfoMutation__
 *
 * To run a mutation, you first call `useUpdateUserInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserInfoMutation, { data, loading, error }] = useUpdateUserInfoMutation({
 *   variables: {
 *      role: // value for 'role'
 *      team: // value for 'team'
 *   },
 * });
 */
export function useUpdateUserInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>(UpdateUserInfoDocument, options);
      }
export type UpdateUserInfoMutationHookResult = ReturnType<typeof useUpdateUserInfoMutation>;
export type UpdateUserInfoMutationResult = Apollo.MutationResult<UpdateUserInfoMutation>;
export type UpdateUserInfoMutationOptions = Apollo.BaseMutationOptions<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>;
export const UpdateOrgInfoDocument = gql`
    mutation updateOrgInfo($orgSize: String!, $autoJoinDomain: String!) {
  updateOrgInfo(orgSize: $orgSize, autoJoinDomain: $autoJoinDomain) {
    ok
  }
}
    `;
export type UpdateOrgInfoMutationFn = Apollo.MutationFunction<UpdateOrgInfoMutation, UpdateOrgInfoMutationVariables>;

/**
 * __useUpdateOrgInfoMutation__
 *
 * To run a mutation, you first call `useUpdateOrgInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgInfoMutation, { data, loading, error }] = useUpdateOrgInfoMutation({
 *   variables: {
 *      orgSize: // value for 'orgSize'
 *      autoJoinDomain: // value for 'autoJoinDomain'
 *   },
 * });
 */
export function useUpdateOrgInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrgInfoMutation, UpdateOrgInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrgInfoMutation, UpdateOrgInfoMutationVariables>(UpdateOrgInfoDocument, options);
      }
export type UpdateOrgInfoMutationHookResult = ReturnType<typeof useUpdateOrgInfoMutation>;
export type UpdateOrgInfoMutationResult = Apollo.MutationResult<UpdateOrgInfoMutation>;
export type UpdateOrgInfoMutationOptions = Apollo.BaseMutationOptions<UpdateOrgInfoMutation, UpdateOrgInfoMutationVariables>;
export const MagnetarUserIdentityDocument = gql`
    query magnetarUserIdentity {
  userIdentity {
    uid
    name
    email
    avatarUrl
    role
    team
    companyName
    companyToken
    companyRegion
    accessLevels
    inviteLevels
    isFirstAdmin
    intercomHmac
    bearerToken
  }
}
    `;

/**
 * __useMagnetarUserIdentityQuery__
 *
 * To run a query within a React component, call `useMagnetarUserIdentityQuery` and pass it any options that fit your needs.
 * When your component renders, `useMagnetarUserIdentityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMagnetarUserIdentityQuery({
 *   variables: {
 *   },
 * });
 */
export function useMagnetarUserIdentityQuery(baseOptions?: Apollo.QueryHookOptions<MagnetarUserIdentityQuery, MagnetarUserIdentityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MagnetarUserIdentityQuery, MagnetarUserIdentityQueryVariables>(MagnetarUserIdentityDocument, options);
      }
export function useMagnetarUserIdentityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MagnetarUserIdentityQuery, MagnetarUserIdentityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MagnetarUserIdentityQuery, MagnetarUserIdentityQueryVariables>(MagnetarUserIdentityDocument, options);
        }
export type MagnetarUserIdentityQueryHookResult = ReturnType<typeof useMagnetarUserIdentityQuery>;
export type MagnetarUserIdentityLazyQueryHookResult = ReturnType<typeof useMagnetarUserIdentityLazyQuery>;
export type MagnetarUserIdentityQueryResult = Apollo.QueryResult<MagnetarUserIdentityQuery, MagnetarUserIdentityQueryVariables>;
export const OnboardUserDocument = gql`
    mutation onboardUser($inviteToken: String) {
  onboardUser(inviteToken: $inviteToken) {
    ok
    failureReason
  }
}
    `;
export type OnboardUserMutationFn = Apollo.MutationFunction<OnboardUserMutation, OnboardUserMutationVariables>;

/**
 * __useOnboardUserMutation__
 *
 * To run a mutation, you first call `useOnboardUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnboardUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onboardUserMutation, { data, loading, error }] = useOnboardUserMutation({
 *   variables: {
 *      inviteToken: // value for 'inviteToken'
 *   },
 * });
 */
export function useOnboardUserMutation(baseOptions?: Apollo.MutationHookOptions<OnboardUserMutation, OnboardUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OnboardUserMutation, OnboardUserMutationVariables>(OnboardUserDocument, options);
      }
export type OnboardUserMutationHookResult = ReturnType<typeof useOnboardUserMutation>;
export type OnboardUserMutationResult = Apollo.MutationResult<OnboardUserMutation>;
export type OnboardUserMutationOptions = Apollo.BaseMutationOptions<OnboardUserMutation, OnboardUserMutationVariables>;