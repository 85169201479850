import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ONBOARDING_PAGE, OnboardingRoutePrefix } from '..';

const CreateNewCompany = () => {
  const history = useHistory();

  // ?origin=app OR ?origin=chrome-extension
  const { search } = useLocation();

  // Main-app company
  history.push(`/${OnboardingRoutePrefix}/${ONBOARDING_PAGE.BASIC_INFO}${search}`);
  return <div />;
};

export default CreateNewCompany;
