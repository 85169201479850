import React, {
  FC,
  useEffect,
  useState,
} from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import Chips from '@paradime-io/pragma-ui-kit/lib/components/Chips';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { useReactFlow } from 'reactflow';
import { AppLocalStorageKeys } from '@paradime/common/lib/common/localStorageKeys';
import { ParadimeTheme } from '@paradime/common/lib/common/genericTypes';
import InputWithIcon from './InputWithIcon';
import { lineageStore, appStore } from '../../../stores';
import { LineageHelp } from '../../Common/AppHelp/help';
import { openHelpHub } from '../../Common/AppHelp/utils';

export interface CustomNodeDepthsProps {
  platformArea: 'editor' | 'catalog',
  onHelpClick?: () => void,
  cleanState?: boolean,
}

const CustomNodeDepths: FC<CustomNodeDepthsProps> = (
  { onHelpClick, platformArea, cleanState },
) => {
  const customNodeDepthFromStore = lineageStore((s) => s.customNodeDepth);
  const setCustomNodeDepth = lineageStore((s) => s.setCustomNodeDepth);
  const setEditorLineagePanelMaximized = lineageStore((s) => s.setEditorLineagePanelMaximized);
  const editorLineagePanelMaximzed = lineageStore((s) => s.editorLineagePanelMaximized);
  const setShowCatalogLineageFullscreen = appStore((s) => s.setShowCatalogLineageFullscreen);
  const showCatalogLineageFullscreen = appStore((s) => s.showCatalogLineageFullscreen);

  const darkMode = ((window.localStorage.getItem(AppLocalStorageKeys.THEME) as ParadimeTheme)
    || ParadimeTheme.LIGHT) === ParadimeTheme.DARK;
  const {
    fitView,
  } = useReactFlow();

  const [upstreamNodeDepth, setUpstreamNodeDepth] = useState('1');
  const [downstreamNodeDepth, setDownstreamNodeDepth] = useState('1');
  const [filePath, setFilePath] = useState('');

  useEffect(() => {
    setTimeout(fitView, 1);
  }, [editorLineagePanelMaximzed, showCatalogLineageFullscreen]);

  window.addEventListener('message', (e) => {
    if (e.data.type === 'lineage-file-path') {
      setFilePath(e.data.filePath);
    }
  });

  useEffect(() => {
    if (filePath) {
      setCustomNodeDepth({
        upstream: '1',
        downstream: '1',
      });
    }
  }, [filePath]);

  useEffect(() => {
    setUpstreamNodeDepth(customNodeDepthFromStore.upstream || '1');
    setDownstreamNodeDepth(customNodeDepthFromStore.downstream || '1');
  }, [customNodeDepthFromStore]);

  const handleUpdateLineageClick = () => {
    const payload = {
      upstream: upstreamNodeDepth,
      downstream: downstreamNodeDepth,
    };

    // For the lineage panel in the editor
    window.postMessage({
      type: 'CHANGE_LINEAGE_NODE_DEPTH',
      payload,
    }, '*');

    setCustomNodeDepth(payload);
  };

  const handleEditorLineageFullscreeenToggle = () => {
    setEditorLineagePanelMaximized(!editorLineagePanelMaximzed);
    window.postMessage({
      type: 'lineage-toggle-fullscreen',
      payload: {
        fullscreen: editorLineagePanelMaximzed,
        platformArea,
      },
    }, '*');
  };

  const handleCatalogLineageFullscreenToggle = () => {
    setShowCatalogLineageFullscreen(!showCatalogLineageFullscreen);
  };

  const editorStyles = {
    padding: '16px',
    width: '100%',
  };

  const catalogStyles = {
    right: '16px',
    top: '16px',
  };

  const platformSpecificStyles = platformArea === 'editor' ? editorStyles : catalogStyles;

  return (
    <div
      style={{
        position: 'absolute',
        zIndex: 1000,
        backgroundColor: darkMode ? 'var(--dark)' : 'var(--grey0)',
        borderBottom: `1px solid ${darkMode ? 'var(--grey60)' : 'var(--grey5)'}`,
        ...platformSpecificStyles,
      }}
    >
      <AutoLayout
        direction="horizontal"
        padding="none"
        horizontalGap="dense"
        distribution="space-between"
      >
        <div>
          {cleanState === false && (
            <Chips
              tag="Commit changes to update lineage"
              color="warning_alt"
              view={darkMode ? 'dark-mode' : 'smooth'}
              type="dense"
              icon="git-branch"
              interactive={false}
              style={{ marginTop: '6px' }}
            />
          )}
        </div>
        <AutoLayout
          direction="horizontal"
          padding="none"
          horizontalGap="dense"
          style={{ alignItems: 'start' }}
        >
          <InputWithIcon
            icon="arrow-up"
            value={upstreamNodeDepth}
            caption="upstream"
            onValueChange={setUpstreamNodeDepth}
            onEnterPressed={handleUpdateLineageClick}
          />
          <InputWithIcon
            icon="arrow-down"
            value={downstreamNodeDepth}
            caption="downstream"
            onValueChange={setDownstreamNodeDepth}
            onEnterPressed={handleUpdateLineageClick}
          />
          <DefaultButton
            type="standard"
            view="smooth"
            color="default"
            text="Update"
            dense
            onClick={handleUpdateLineageClick}
            eventContext={platformArea === 'editor' ? Contexts.EDITOR : Contexts.LINEAGE}
            eventObject="updateLineage"
            eventAction={Actions.CLICKED}
            style={{ fontSize: 'var(--font_size_caption)' }}
          />
          {
          (platformArea === 'editor') && (
            <DefaultButton
              type="standard"
              view="smooth"
              color="default"
              icon={editorLineagePanelMaximzed ? 'cross' : 'fullscreen'}
              dense
              onClick={() => handleEditorLineageFullscreeenToggle()}
              eventContext={Contexts.EDITOR}
              eventObject="toggleFullScreen"
              eventAction={Actions.CLICKED}
              eventProperties={{ state: editorLineagePanelMaximzed ? 'minimising' : 'maximising' }}
              style={{ padding: '0px 20px 0px 18px' }}
            />
          )
        }
          {
          (platformArea === 'catalog') && (
            <DefaultButton
              type="standard"
              view="smooth"
              color="default"
              icon={showCatalogLineageFullscreen ? 'cross' : 'fullscreen'}
              dense
              onClick={() => handleCatalogLineageFullscreenToggle()}
              eventContext={Contexts.CATALOG}
              eventObject="toggleFullScreen"
              eventAction={Actions.CLICKED}
              eventProperties={{ state: showCatalogLineageFullscreen ? 'minimising' : 'maximising' }}
              style={{ padding: '0px 20px 0px 18px' }}
            />
          )
        }
          <Chips
            color="primary_alt"
            round={false}
            tag="Need help?"
            type="dense"
            view="smooth"
            onClick={() => {
              if (onHelpClick) onHelpClick();

              openHelpHub(
                { articleId: LineageHelp.CHANGE_NODE_DEPTH },
              );
            }}
          />
        </AutoLayout>
      </AutoLayout>
    </div>
  );
};

export default CustomNodeDepths;
