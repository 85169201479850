import { GraphicTypes } from '@paradime-io/pragma-ui-kit/lib/components/List';
import { colorType } from '@paradime-io/pragma-ui-kit/lib/components/styles/styleTypes';
import { IconName } from '@blueprintjs/core';
import { formatDateTimeToLocalString } from '../../utilis';
import { catalogueDetailsCardProps, catalogueDetailsCardReturnType } from '../types';
import { formatSyncFrequency } from './utils';

const getChipColourAndIcon = (status?: string | null): { color: colorType, icon?: IconName } => {
  switch (status?.toLowerCase()) {
    case 'successful':
      return {
        color: 'success',
        icon: 'tick-circle',
      };
    case 'failure':
    case 'failure_with_task':
      return {
        color: 'danger',
        icon: 'warning-sign',
      };
    case 'rescheduled':
      return {
        color: 'default',
        icon: 'time',
      };
    default:
      return {
        color: 'default',
        icon: undefined,
      };
  }
};

export const catalogueDetailsCard = ({
  resultItem,
}: catalogueDetailsCardProps): catalogueDetailsCardReturnType => {
  switch (resultItem.__typename) {
    case 'GQLCatalogueFivetranConnectorItem':
      return {
        components: [
          [
            {
              type: 'textWithHeader',
              header: 'type',
              text: 'Connector',
              icon: { iconName: 'group-objects', color: 'var(--violet70)' },
            },
            {
              type: 'textWithHeader',
              header: 'Source',
              text: resultItem?.name || '-',
              image: resultItem.iconUrl,
            },
          ],
          [
            {
              type: 'chips',
              header: 'Status',
              chips: [{
                tag: resultItem.syncStatus || '-',
                ...getChipColourAndIcon(resultItem.syncStatus),
                label: '',
              }],
            },
          ],
          [
            {
              type: 'chips',
              header: 'Sync frequency',
              chips: [{
                tag: formatSyncFrequency(resultItem.syncFrequency),
                color: 'default',
                label: '',
                icon: 'refresh',
              }],
            },
            {
              type: 'textWithHeader',
              header: 'Last sync',
              text: formatDateTimeToLocalString({ dateTimeString: resultItem.lastSync }),
            },
          ],
          [
            {
              type: 'list',
              title: 'Owner',
              subtitle: `${resultItem.owner.givenName} ${resultItem.owner.familyName}` || '-',
              graphic: {
                type: 'image' as GraphicTypes.IMAGE,
                image: resultItem.owner.picture || `https://via.placeholder.com/32/ededf0/?text=${resultItem.owner.givenName.slice(0, 1) || 'X'}`,
              },
            },
          ],
        ],
      };

    default:
      return { components: [] };
  }
};
