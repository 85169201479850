import React, { FC } from 'react';
import { CreateWorkspaceRoutes, createWorkspaceRoutePrefix } from '.';
import OnboardingGettingStartedPage from './OnboardingGettingStarted';

const GettingStarted: FC = () => (
  <OnboardingGettingStartedPage
    enforceFirstAdminView
    customOnCompletionUrl={`/${createWorkspaceRoutePrefix}/${CreateWorkspaceRoutes.WORKSPACE_SETTINGS}`}
  />
);

export default GettingStarted;
