import { catalogueSearchResultItemProps, catalogueSearchResultItemReturnType } from '../types';
import { fivetranSourceLogo } from '../Fivetran';
import { formatSyncFrequency } from './utils';

export const catalogueSearchResultItem = ({
  resultItem,
}: catalogueSearchResultItemProps): catalogueSearchResultItemReturnType => {
  switch (resultItem.__typename) {
    case 'GQLCatalogueFivetranConnectorItem':
      return (
        [
          {
            type: 'list',
            iconName: 'group-objects',
            title: resultItem?.connectorName || '-',
            subtitle: 'Connector',
            rightImage: fivetranSourceLogo,
            secondRightImage: resultItem.iconUrl,
          },
          {
            type: 'description',
            description: resultItem.description!,
          },
          { type: 'divider' },
          [
            {
              leftComponents: [
                {
                  type: 'textWithIcon',
                  text: formatSyncFrequency(resultItem.syncFrequency) || '-',
                  icon: 'refresh',
                },
              ],
            },
          ],
        ]
      );

    default:
      return [];
  }
};
