import { isLocalHost } from '../../../../utilis/common';

const getLogRocketAppId = () => {
  const suffix = (
    window.location.hostname.includes('.dev')
    || window.location.hostname.includes('.demo')
    || window.location.hostname.includes('localhost')
  ) ? '-dev' : '';
  return `nyyvas/paradime-frontend${suffix}`;
};

const LogRocketOpts = (apiHost: string) => (
  {
    dom: {
      textSanitizer: false,
      inputSanitizer: false,
    },
    rootHostName: isLocalHost ? 'localhost' : 'paradime.io',
    mergeIframes: true,
    childDomains: [`https://${apiHost}`, 'https://localhost:3010'],
    network: {
      requestSanitizer: (request: any) => {
        // if the url contains 'delete'
        if (request.url.toLowerCase().indexOf('delete') !== -1) {
          // scrub out the body
          request.body = null;
        }

        // if the url contains 'ignore'
        if (request.url.toLowerCase().indexOf('ignore') !== -1) {
          // ignore the request response pair
          return null;
        }

        // scrub header value from request
        if (request.headers.authorization) {
          request.headers.authorization = '';
        }
        // scrub header pair from request
        request.headers.authorization = null;

        // scrub cookie value from request
        if (request.cookie) {
          if (request.cookie['X-THEIA-AUTHENTICATION']) {
            request.cookie['X-THEIA-AUTHENTICATION'] = '';
          }
          request.cookie['X-THEIA-AUTHENTICATION'] = null;
        }

        // make sure you return the modified request
        return request;
      },
      responseSanitizer: (response: any) => {
        if (response.headers['x-secret']) {
          // removes all response data
          return null;
        }

        return response;
      },
      browser: {
        urlSanitizer: (url: any) => {
          let sanitizedUrl = url;

          // redact the path following /ssn/ from the URL
          sanitizedUrl = sanitizedUrl.replace(/\/ssn\/([^/]*)/i, '/ssn/**redacted**');

          // redact the value of the query parameter secret_key
          sanitizedUrl = sanitizedUrl.replace(/secret_key=([^&]*)/, 'secret_key=**redacted**');

          // make sure you return the sanitized URL string
          return sanitizedUrl;
        },
      },
    },
  }
);

export { LogRocketOpts, getLogRocketAppId };
