import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { ToastMessage as PrimeReactToastProps } from 'primereact/toast';
import { Icon, IconName } from '@blueprintjs/core';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import { colorType } from '@paradime-io/pragma-ui-kit/lib/components/styles/styleTypes';
import { TriggerToastProps } from './alerts';
import { alertStore } from '../stores';

const ToastContainer = styled.div<{ color: string}>`
  background: ${(props) => `var(--${props.color}0)`};
  border: solid ${(props) => `var(--${props.color}80)`};
  border-width: 0 0 0 6px;

  margin: 0 0 1rem 0;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;

  padding: 8px 16px;
`;

const ClickableIcon = styled(Icon)`
  &:hover {
    cursor: pointer;
  }
`;

export interface ToastMessageProps {
  type: PrimeReactToastProps['severity'],
  header: TriggerToastProps['header'],
  message?: TriggerToastProps['message'],
}

const ToastMessage: FC<ToastMessageProps> = ({ header, message, type }) => {
  const { toaster } = alertStore.getState();

  const getTypeDetails = (): { intentName: colorType, colorName: string, icon: IconName } => {
    switch (type) {
      case 'success':
        return {
          intentName: 'success',
          colorName: 'green',
          icon: 'tick-circle',
        };
      case 'warn':
        return {
          intentName: 'warning',
          colorName: 'yellow',
          icon: 'warning-sign',
        };
      case 'error':
        return {
          intentName: 'danger',
          colorName: 'red',
          icon: 'error',
        };
      default:
        return {
          intentName: 'default',
          colorName: 'grey',
          icon: 'info-sign',
        };
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => toaster.current?.clear(), 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <ToastContainer color={getTypeDetails().colorName}>
      <AutoLayout
        direction="vertical"
        padding="none"
        verticalGap="very-dense"
        distribution="packed"
        alignment="top-center"
      >
        <AutoLayout
          direction="horizontal"
          distribution="space-between"
          padding="none"
        >
          <AutoLayout
            alignment="center"
            direction="horizontal"
            distribution="packed"
            padding="none"
            horizontalGap="dense"
          >
            <Icon icon={getTypeDetails().icon} size={20} color={`var(--${getTypeDetails().colorName}80)`} />
            <Typography
              tagName="span"
              type="body-bold-small"
              color={getTypeDetails().intentName as colorType}
              colorStep="70"
              style={{ overflowWrap: 'break-word', maxWidth: '268px', marginRight: '24px' }}
            >
              {header}
            </Typography>
          </AutoLayout>
          <ClickableIcon
            icon="cross"
            size={16}
            color={`var(--${getTypeDetails().colorName}70)`}
            onClick={() => toaster?.current?.clear()}
          />
        </AutoLayout>
        {
          message
          && (
            <Typography
              tagName="span"
              type="caption"
              colorStep="60"
              style={{ overflowWrap: 'break-word', maxWidth: '312px', marginRight: '16px' }}
            >
              {message}
            </Typography>
          )
        }
      </AutoLayout>
    </ToastContainer>
  );
};

export default ToastMessage;
