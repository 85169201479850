const data = [
  ['LookerLookNode', 'Look'],
  ['LookerViewNode', 'View'],
  ['LookerModelNode', 'Model'],
  ['LookerExploreNode', 'Explore'],
  ['LookerDashboardNode', 'Dashboard'],
  ['LookerScheduleNode', 'Schedule'],
];

export const lineageFilterNodes = () => ({
  title: 'LOOKER NODES',
  items: data.map((item) => ({ label: item, checked: true })),
});
