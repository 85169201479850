import { OrganisationStorageKeys } from '@paradime/common/lib/common/localStorageKeys';
import { graphQLStore, paradimeOrganisationType } from '../../stores';

/* eslint-disable camelcase */
export interface apiOrganisationData {
  name?: string | null;
  location?: string | null;
  auth0_api_domain?: string | null;
  auth0_client_id?: string | null;
  auth0_endpoint_domain?: string | null;
  auth0_organisation?: string | null;
  control_plane_url?: string | null;
}
/* eslint-enable camelcase */

const MaybeOrganisationTypeKeys = [
  'auth0EndpointDomain',
  'auth0ApiDomain',
  'auth0ClientId',
  'auth0Organisation',
  'controlPlaneUrl',
  'name',
  'location',
];

export interface OrganisationData {
  name: string;
  location: LocationType;
  auth0ApiDomain: string;
  auth0ClientId: string;
  auth0EndpointDomain: string;
  auth0Organisation: string;
  controlPlaneUrl: string;
}

export type ErrorMessage = 'invalid' | 'internal' | 'not_found' | 'existing';

export interface OrganisationReturnType {
  hasError: boolean,
  errorMessage?: ErrorMessage,
}

const hasValidJWTTokenForAuth0Tenant = ({
  validParadimeOrganisation: {
    auth0ApiDomain,
    auth0ClientId,
  },
}: {
  validParadimeOrganisation: paradimeOrganisationType
}) => {
  // JWT token via code in auth success
  const { pathname } = new URL(window.location.href);
  const isAuthSuccess = pathname === '/auth-success';
  if (isAuthSuccess) {
    return true;
  }

  // JWT token in local storage saved by the Auth0 react library
  const authLocalStorageKey = `@@auth0spajs@@::${auth0ClientId}::https://${auth0ApiDomain}/api/v2/::openid profile email offline_access`;
  const auth0LocalStorage = window.localStorage.getItem(authLocalStorageKey);
  const secondsSinceEpoch = Math.round((new Date()).getTime() / 1000);
  const auth0LocalStorageJSON = JSON.parse(auth0LocalStorage || '{}');
  const auth0ExpiresAt = auth0LocalStorageJSON.expiresAt;
  return auth0ExpiresAt !== undefined && auth0ExpiresAt > secondsSinceEpoch;
};

function isValidOrganisation(parsedJson: any) {
  return MaybeOrganisationTypeKeys.every(
    (key) => Object.prototype.hasOwnProperty.call(parsedJson, key) && typeof parsedJson[key] === 'string' && parsedJson[key] !== '',
  );
}

export type LocationType = 'eu' | 'us' | 'ap';

const useOrganisation = () => {
  const clusterConfigFromStore = graphQLStore((state) => state.clusterConfig);
  const setParadimeOrganisation = graphQLStore((state) => state.setParadimeOrganisation);

  async function fetchOrganisation(organisationName: string): Promise<{
    hasError: boolean;
    paradimeOrganisation?: OrganisationData
    errorMessage?: ErrorMessage;
  }> {
    if (clusterConfigFromStore?.organisationResolver) {
      const uri = clusterConfigFromStore?.organisationResolver + organisationName;
      const response = await fetch(uri);
      if (response.ok) {
        const {
          name,
          location,
          auth0_api_domain: auth0ApiDomain,
          auth0_client_id: auth0ClientId,
          auth0_endpoint_domain: auth0EndpointDomain,
          auth0_organisation: auth0Organisation,
          control_plane_url: controlPlaneUrl,
        }: apiOrganisationData = (await response.json())?.result;
        if (
          name
        && location
        && auth0ClientId
        && auth0ApiDomain
        && auth0EndpointDomain
        && auth0Organisation
        && controlPlaneUrl
        ) {
          const paradimeOrganisation: OrganisationData = {
            name,
            location: location as LocationType,
            auth0Organisation,
            auth0ApiDomain,
            auth0ClientId,
            auth0EndpointDomain,
            controlPlaneUrl,
          };

          return {
            hasError: false,
            paradimeOrganisation,
            errorMessage: undefined,
          };
        }
        console.error(`incomplete paradime organisation response: ${await response.json()}`);
        return {
          hasError: true,
          paradimeOrganisation: undefined,
          errorMessage: 'invalid',
        };
      }
      switch (response.status) {
        case 404:
          return {
            paradimeOrganisation: undefined,
            hasError: false,
            errorMessage: 'not_found',
          };
        default:
          console.error(`return code: ${response.status}`);
          return {
            hasError: true,
            paradimeOrganisation: undefined,
            errorMessage: 'invalid',
          };
      }
    }
    console.error(`cannot find organisationResolver in ${clusterConfigFromStore}`);
    return {
      hasError: true,
      errorMessage: 'internal',
    };
  }
  const addOrganisationToLocalStorage = (newOrg: OrganisationData) => {
    if (!isValidOrganisation(newOrg)) {
      return;
    }

    const seenOrganisationsAsString = window.localStorage.getItem(
      OrganisationStorageKeys.SEEN_ORGANISATIONS,
    );
    let seenOrganisations: OrganisationData[] = [];
    if (seenOrganisationsAsString) {
      seenOrganisations = JSON.parse(seenOrganisationsAsString);
    }

    // Check if organisation with same name already exists
    const orgIndex = seenOrganisations.findIndex((org) => org.name === newOrg.name);
    if (orgIndex !== -1) {
      // Replace existing organisation with the new one
      seenOrganisations[orgIndex] = newOrg;
    } else {
      // Add new organisation
      seenOrganisations.push(newOrg);
    }

    // Save the updated list back to local storage
    window.localStorage.setItem(
      OrganisationStorageKeys.SEEN_ORGANISATIONS,
      JSON.stringify(seenOrganisations),
    );
  };

  const set = async (organisationName: string): Promise<OrganisationReturnType> => {
    const {
      hasError, errorMessage, paradimeOrganisation,
    } = await fetchOrganisation(organisationName);
    if (paradimeOrganisation) {
      setParadimeOrganisation(paradimeOrganisation);
      addOrganisationToLocalStorage(paradimeOrganisation);

      console.debug(`paradimeOrganisation: ${JSON.stringify(paradimeOrganisation)}`);
      window.localStorage.setItem(
        OrganisationStorageKeys.SELECTED_ORGANISATION,
        JSON.stringify(paradimeOrganisation),
      );
    }
    return { hasError, errorMessage };
  };

  const list = async () => {
    // if the URL contains `organisationName`, add it to the list
    const urlParams = new URLSearchParams(window.location.search);
    const organisationName = urlParams.get('organisationName');
    if (organisationName) {
      const { paradimeOrganisation } = await fetchOrganisation(organisationName);
      if (paradimeOrganisation) {
        addOrganisationToLocalStorage(paradimeOrganisation);
        return [paradimeOrganisation];
      }
    }

    const seenOrganisationsAsString = window.localStorage.getItem(
      OrganisationStorageKeys.SEEN_ORGANISATIONS,
    );
    const seenOrganisations: OrganisationData[] = [];
    if (seenOrganisationsAsString) {
      const parsedJson: any = JSON.parse(seenOrganisationsAsString);
      if (Array.isArray(parsedJson)) {
        parsedJson.forEach((organisation) => {
          if (isValidOrganisation(organisation)) {
            seenOrganisations.push(organisation);
          }
        });
      }
    }
    return seenOrganisations;
  };

  const create = async (
    organisationName: string,
    requestedLocation: LocationType,
  ): Promise<OrganisationReturnType> => {
    // First, check if this org name already exists
    const { paradimeOrganisation: existingParadimeOrg } = await fetchOrganisation(organisationName);

    if (existingParadimeOrg) {
      return {
        hasError: true,
        errorMessage: 'existing',
      };
    }

    if (clusterConfigFromStore?.organisationResolver) {
      const uri = clusterConfigFromStore?.organisationResolver + organisationName;
      const data = {
        location: requestedLocation,
      };
      const response = await fetch(uri, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        const {
          name,
          location,
          auth0_api_domain: auth0ApiDomain,
          auth0_client_id: auth0ClientId,
          auth0_endpoint_domain: auth0EndpointDomain,
          auth0_organisation: auth0Organisation,
          control_plane_url: controlPlaneUrl,
        }: apiOrganisationData = (await response.json())?.result;
        if (
          name
          && location
          && auth0ClientId
          && auth0ApiDomain
          && auth0EndpointDomain
          && auth0Organisation
          && controlPlaneUrl
        ) {
          const paradimeOrganisation: OrganisationData = {
            name,
            location: location as LocationType,
            auth0Organisation,
            auth0ApiDomain,
            auth0ClientId,
            auth0EndpointDomain,
            controlPlaneUrl,
          };
          setParadimeOrganisation(paradimeOrganisation);
          addOrganisationToLocalStorage(paradimeOrganisation);

          console.debug(`created paradimeOrganisation: ${paradimeOrganisation}`);
          window.localStorage.setItem(
            OrganisationStorageKeys.SELECTED_ORGANISATION,
            JSON.stringify(paradimeOrganisation),
          );

          return {
            hasError: false,
            errorMessage: undefined,
          };
        }

        console.error(`incomplete paradime organisation response: ${await response.json()}`);
        return {
          hasError: true,
          errorMessage: 'invalid',
        };
      }

      console.error(`return code: ${response.status}`);
      return {
        hasError: true,
        errorMessage: 'invalid',
      };
    }

    console.error(`cannot find organisationResolver in ${clusterConfigFromStore}`);
    return {
      hasError: true,
      errorMessage: 'internal',
    };
  };

  const tryLoadingFromCache = () => {
    const selectedOrganisation = window.localStorage.getItem(
      OrganisationStorageKeys.SELECTED_ORGANISATION,
    );
    if (selectedOrganisation) {
      const parsedJson: any = JSON.parse(selectedOrganisation);
      if (isValidOrganisation(parsedJson)) {
        const validParadimeOrganisation: paradimeOrganisationType = parsedJson;
        if (hasValidJWTTokenForAuth0Tenant({ validParadimeOrganisation })) {
          setParadimeOrganisation(validParadimeOrganisation);
        }
      }
    }
  };
  const clear = () => {
    window.localStorage.setItem(
      OrganisationStorageKeys.SELECTED_ORGANISATION,
      '',
    );
    setParadimeOrganisation(undefined);
  };

  const setCurrentParadimeOrganisationInLocalStorage = (paradimeOrganisation: OrganisationData) => {
    window.localStorage.setItem(
      OrganisationStorageKeys.SELECTED_ORGANISATION,
      JSON.stringify(paradimeOrganisation),
    );
  };

  return {
    create,
    set,
    tryLoadingFromCache,
    list,
    clear,
    setCurrentParadimeOrganisationInLocalStorage,
  };
};

export default useOrganisation;
