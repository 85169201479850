import { useEffect, useState } from 'react';
import {
  useIsCompanyActiveLazyQuery,
  IsCompanyActiveQuery,
} from '../../../../client/generated/control-plane';
import { companyEndpoints, companyStore, userAuthStore } from '../../../../stores';

export interface waitForCompanyToBeActiveProps {
  companyOnboardingToken: string,
  supervisingControlPlaneUrl: string,
}

interface CheckContinueConditionsProps {
  dataToCheck?: IsCompanyActiveQuery,
}

interface reCheckIfCompanyIsActiveProps {
  withWait: boolean,
}

const companyTokenLSKey = 'paradime:onboardingCompanyToken';
const controlPlaneLSKey = 'paradime:supervisingControlPlane';

export const useWaitForCompanyToBeActive = () => {
  const [isStarting, setIsStarting] = useState(false);
  const [hasStartedSuccessfully, setHasStartedSuccessfully] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [activeCompanyData, setActiveCompanyData] = useState<IsCompanyActiveQuery>();
  const [onboardingToken, setOnboardingToken] = useState('');
  const [controlPlaneUrl, setControlPlaneUrl] = useState('');
  const [checkIsLoading, setCheckIsLoading] = useState(false);

  const setUserHasNoCompany = userAuthStore((s) => s.setUserHasNoCompany);
  const fireOnboardingUserCompanyAssigned = companyStore(
    (s) => s.fireOnboardingUserCompanyAssigned,
  );

  const [checkActiveState] = useIsCompanyActiveLazyQuery({
    onCompleted: (returnedData) => {
      setActiveCompanyData(returnedData);
      checkContinueConditions({
        dataToCheck: returnedData,
      });
    },
  });

  const reCheckIfCompanyIsActive = async ({ withWait }: reCheckIfCompanyIsActiveProps) => {
    setCheckIsLoading(true);
    if (withWait) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }

    checkActiveState({
      variables: {
        token: onboardingToken,
      },
      context: {
        customEndpoint: controlPlaneUrl,
        randomNumberToAvoidCaching: Math.random(),
      },
      fetchPolicy: 'no-cache',
    });
    setCheckIsLoading(false);
  };

  useEffect(() => {
    if (hasStartedSuccessfully && activeCompanyData) {
      // Once the company has started successfully, we don't need the localStorage data anymore
      window.localStorage.removeItem(companyTokenLSKey);
      window.localStorage.removeItem(controlPlaneLSKey);
      setUserHasNoCompany(false);
      fireOnboardingUserCompanyAssigned(activeCompanyData.isCompanyActive as companyEndpoints);
    }

    if (!hasStartedSuccessfully && !checkIsLoading) {
      if (!onboardingToken || !controlPlaneUrl) {
        // If the page has refreshed and we have lost the onboardingToken or the controlPlaneURL,
        // then retrieve them from localStorage
        const tokenFromLS = window.localStorage.getItem(companyTokenLSKey);
        const controlPlaneFromLS = window.localStorage.getItem(controlPlaneLSKey);

        if (tokenFromLS) setOnboardingToken(tokenFromLS);
        if (controlPlaneFromLS) setControlPlaneUrl(controlPlaneFromLS);
      }

      if (onboardingToken && controlPlaneUrl) {
        // If the company has not started, wait one second and check again.
        // This could continue infinitely
        reCheckIfCompanyIsActive({ withWait: true });
      }
    }
  }, [hasStartedSuccessfully, activeCompanyData, onboardingToken, controlPlaneUrl, checkIsLoading]);

  const checkContinueConditions = ({
    dataToCheck,
  }: CheckContinueConditionsProps) => {
    if (dataToCheck?.isCompanyActive?.ok) {
      const {
        isStarting: isStartingFromEndpoint,
        isStarted: isStartedFromEndpoint,
        progress,
      } = dataToCheck.isCompanyActive;

      setIsStarting(isStartingFromEndpoint);
      setHasStartedSuccessfully(isStartedFromEndpoint && progress === 1);
      setHasError(!isStartingFromEndpoint && !isStartedFromEndpoint);
    }

    return {
      isStarting,
      hasStartedSuccessfully,
      hasError,
      activeCompanyData,
    };
  };

  const waitForCompanyToBeActive = ({
    companyOnboardingToken,
    supervisingControlPlaneUrl,
  }: waitForCompanyToBeActiveProps) => {
    setOnboardingToken(companyOnboardingToken);
    setControlPlaneUrl(supervisingControlPlaneUrl);

    // Save these important values to localStorage, just in case...
    window.localStorage.setItem(companyTokenLSKey, companyOnboardingToken);
    window.localStorage.setItem(controlPlaneLSKey, supervisingControlPlaneUrl);

    reCheckIfCompanyIsActive({ withWait: false });
  };

  return {
    waitForCompanyToBeActive,
    isStarting,
    hasStartedSuccessfully,
    hasError,
    activeCompanyData,
    isWaitingForCompanyToStart: (
      !!window.localStorage.getItem(companyTokenLSKey)
        && !!window.localStorage.getItem(controlPlaneLSKey)
    ),
  };
};
