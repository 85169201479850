import create from 'zustand';
import { combine } from 'zustand/middleware';

export const warehouseStore = create(
  combine(
    {
      warehouseData: {} as { [key: string]: any },
    },
    (set) => ({
      updateWarehouseData: (newData: any) => set((s) => (
        { warehouseData: { ...s.warehouseData, ...newData } })),
      setWarehouseData: (newData: any) => set(() => (
        { warehouseData: { ...newData } })),
    }),
  ),
);
