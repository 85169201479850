import { catalogueSimpleTypeProps } from '../types';

export const catalogueSimpleType = ({
  resultItem,
}: catalogueSimpleTypeProps) => {
  switch (resultItem.__typename) {
    case 'GQLCatalogueLookerDashboardItem':
      return {
        simpleType: 'dashboard',
        compoundType: 'Looker dashboard',
      };
    case 'GQLCatalogueLookerExploreItem':
      return {
        simpleType: 'explore',
        compoundType: 'Looker explore',
      };
    case 'GQLCatalogueLookerLookItem':
      return {
        simpleType: 'look',
        compoundType: 'Looker look',
      };
    default:
      return {
        simpleType: '',
        compoundType: '',
      };
  }
};
