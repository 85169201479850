import { useEffect, useState } from 'react';
import { SlackSearchQuery, useCompanyOnboardingSlackCodeMutation, useOrganisationIntegrationListQuery } from '../../client/generated/service';
import { useGetSlackUsers } from './useGetSlackUsers';

export const useOnboardSlack = (
  querySlackUsersCallbackfn: (e: SlackSearchQuery) => void, // eslint-disable-line
) => {
  const [loading, setLoading] = useState(false);
  const [localSlackOnboarded, setLocalSlackOnboarded] = useState(false);

  const [
    onboardSlack,
    { data: onboardSlackData, loading: loadingSlackOnboard },
  ] = useCompanyOnboardingSlackCodeMutation();

  const {
    data: slackIntegrationData,
  } = useOrganisationIntegrationListQuery({ fetchPolicy: 'cache-and-network' });

  const {
    loadingSlackUsers,
    querySlackUsers,
  } = useGetSlackUsers(querySlackUsersCallbackfn);

  useEffect(() => {
    if (slackIntegrationData) {
      setLocalSlackOnboarded(slackIntegrationData.organisationIntegrationList.slack === 'active');
    }

    return () => {
      if (slackIntegrationData) {
        setLocalSlackOnboarded(slackIntegrationData?.organisationIntegrationList.slack === 'active');
      }
    };
  }, [slackIntegrationData]);// eslint-disable-line

  useEffect(() => {
    if (onboardSlackData?.companyOnboardingSlackCode?.ok) {
      querySlackUsers();
      setLocalSlackOnboarded(true);
    }
  }, [onboardSlackData]);// eslint-disable-line

  useEffect(() => {
    setLoading(loadingSlackOnboard || loadingSlackUsers);
  }, [loadingSlackOnboard, loadingSlackUsers]);// eslint-disable-line

  return {
    onboardSlack,
    loading,
    localSlackOnboarded,
    slackIntegrationData,
    querySlackUsers,
  };
};
