import { IconName } from '@blueprintjs/core';
import { catalogueSearchResultItemProps } from '../types';
import dbt from '../../components/Catalog/images/dbt.svg';

export const catalogueSearchResultItem = ({
  resultItem,
}: catalogueSearchResultItemProps) => {
  switch (resultItem.__typename) {
    case 'GQLCatalogueDBTExposureItem':
      return (
        [
          {
            type: 'list' as const,
            iconName: 'new-grid-item' as IconName,
            title: resultItem.name,
            subtitle: 'Exposure',
            rightImage: dbt,
          },
          {
            type: 'description' as const,
            description: resultItem.description!,
          },
          { type: 'divider' as const },
          [
            {
              leftComponents: [
                {
                  type: 'textWithHeader' as const,
                  text: resultItem.type || '-',
                  header: 'Exposure Type',
                },
                {
                  type: 'textWithHeader' as const,
                  text: resultItem.maturity || '-',
                  header: 'Maturity',
                },
              ],
              rightComponents: [
                {
                  type: 'tags' as const,
                  tags: resultItem.tags,
                },
              ],
            },
          ],
        ]
      );

    default:
      return [];
  }
};
