import React, {
  FC, useState,
} from 'react';
import { Icon } from '@blueprintjs/core';
import Input from '@paradime-io/pragma-ui-kit/lib/components/DefaultInput';
import { Menu } from '@paradime-io/pragma-ui-kit/lib/components/Menu';
import MenuDivider from '@paradime-io/pragma-ui-kit/lib/components/MenuDivider';
import MenuItem from '@paradime-io/pragma-ui-kit/lib/components/MenuItem';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { colorType } from '@paradime-io/pragma-ui-kit/lib/components/styles/styleTypes';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';

export interface BranchDropDownContentProps {
  type: 'def' | 'dense',
  view: 'smooth' | 'outlined' | 'raised' | 'filled',
  color: colorType,
  expose: (e: [string,string]) => void, // eslint-disable-line
  currentBranch: string,
  branches: Map<string, string>
}

const BranchDropDownContent: FC<BranchDropDownContentProps> = ({
  type,
  view,
  color,
  expose,
  currentBranch,
  branches,
}) => {
  const [filter, setFilter] = useState('');
  return (
    <div
      style={{
        width: 217,
      }}
    >
      <Menu type={type} view={view} color={color} style={{ maxHeight: 340, minHeight: 340, overflow: 'auto' }}>
        <div
          style={{
            top: 0, padding: '8px 8px 0px', background: 'var(--white)', zIndex: 10,
          }}
          ref={(node) => node && node.style.setProperty('position', 'sticky', 'important')}
        >
          <Input
            type="text"
            view="outlined"
            placeholder="Find a branch"
            full
            dense
            color="default"
            style={{
              boxShadow: 'none',
              marginTop: '2px',
            }}
            expose={(e) => setFilter(e.target.value)}
          />
          <li style={{ marginLeft: '0px', marginRight: '0px', marginBottom: '-10px' }}>
            <MenuDivider
              color={color}
              type="dense"
              view="filled"
            />
          </li>
        </div>
        {[...branches.entries()]
          .filter(([branch]) => (filter ? branch.includes(filter) : true))
          .map(([branch, commitHash]) => (
            <MenuItem
              data-testid="branchItem"
              key={`${branch}-item`}
              type="dense"
              text={<Typography tagName="span" type="caption">{branch}</Typography>}
              labelElement={branch === currentBranch && <Icon color="var(--violet70)" icon="selection" iconSize={16} />}
              onItemClick={() => {
                expose([branch, commitHash]);
              }}
              eventContext={Contexts.LINEAGE}
              eventObject="branchListItem"
              eventAction={Actions.CLICKED}
            />
          ))}
      </Menu>
    </div>
  );
};

export default BranchDropDownContent;
