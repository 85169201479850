/* eslint-disable no-await-in-loop */
/* eslint-disable no-useless-escape */
import React, {
  FC,
} from 'react';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import tickCircle from './images/tick-circle.svg';

export interface IntegrationSuccessProps {
    text: string
}
const IntegrationSuccess: FC<IntegrationSuccessProps> = ({
  text,
}) => (
  <>
    <img style={{ margin: 'auto' }} src={tickCircle} width="100px" alt="tick" />
    <Typography font="inter" type="h4" tagName="span" style={{ textAlign: 'center' }}>
      {text}
    </Typography>
    <Typography font="inter" type="body" style={{ textAlign: 'center' }}>
      You can close this tab.
    </Typography>
  </>
);

export default IntegrationSuccess;
