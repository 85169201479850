import React, { FC, useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import copy from 'copy-to-clipboard';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';

export interface CodeSnippetCopyProps {
  codeSnippet: string,
  language: string,
  wrapLongLines?: boolean,
}

const CodeSnippetCopy:FC<CodeSnippetCopyProps> = ({
  codeSnippet,
  language,
  wrapLongLines,
}) => {
  const [codeCopyButtonText, setCodeCopyButtonText] = useState<string>();

  return (
    <AutoLayout
      direction="vertical"
      padding="none"
      distribution="packed"
      verticalGap="none"
    >
      <DefaultButton
        type="standard"
        view="smooth"
        color="default"
        icon={codeCopyButtonText ? undefined : 'duplicate'}
        text={codeCopyButtonText}
        dense
        eventContext={Contexts.LOGS}
        eventObject="copyCode"
        eventAction={Actions.CLICKED}
        onClick={() => {
          copy(codeSnippet);
          setCodeCopyButtonText('Copied!');
          setTimeout(() => {
            setCodeCopyButtonText(undefined);
          }, 1000);
        }}
        style={{
          width: 'fit-content',
          justifySelf: 'flex-end',
          position: 'relative',
          top: '48px',
          right: '12px',
        }}
      />
      <SyntaxHighlighter
        language={language}
        style={oneDark}
        showLineNumbers
        wrapLongLines={wrapLongLines}
        customStyle={{
          fontSize: 'var(--font_size_caption)',
        }}
      >
        {codeSnippet}
      </SyntaxHighlighter>
    </AutoLayout>
  );
};

export default CodeSnippetCopy;
