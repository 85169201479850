import { Dispatch, SetStateAction } from 'react';
import { WarehouseFormInputType } from '../../warehouseFactory/types';
import { ConnectionType } from './CommonConfig';
import { MetadataWarehouseHelp } from '../../../../Common/AppHelp/help';

export const formDistribution = (showEditButton: boolean) => (
  showEditButton
    ? ("'P P P P P P' "
      + "'j j j j j b' ")
    : ("'P P P P P P' "
        + "'j j j j j j' ")
);

export const initialValues = {
  connectionName: '_dummy_',
  datasetName: '_dummy_',
  location: '_dummy_',
  serviceAccountJson: '',
  targetName: '_dummy_',
  threads: 1,
  connectionType: ConnectionType.SERVICEACCOUNT,
};

export const formFields = (
  showEditButton: boolean,
  isEditingServiceAccountJson: boolean,
  setIsEditingServiceAccountJson: Dispatch<SetStateAction<boolean>>,
) => [
  {
    title: 'Connection Settings',
    gridArea: 'P',
    type: 'title',
    helpArticleId: MetadataWarehouseHelp.BIG_QUERY,
  },
  {
    id: 'text-bq-meta-service-account-json',
    name: 'serviceAccountJson',
    label: 'Service Account JSON',
    type: WarehouseFormInputType.FILE,
    firstUserOnly: true,
    gridArea: 'j',
    additionalUserPlaceholder: 'paradime-bigquery.json',
    placeHolder: 'Choose a *.json file ...',
    color: 'primary',
    adminOnly: true,
    isPrivate: true,
    disabled: showEditButton ? !isEditingServiceAccountJson : false,
    isSecretField: true,
    isEditingSecretField: isEditingServiceAccountJson,
  },
  ...(showEditButton
    ? [{
      name: 'editClientSecretButton',
      text: isEditingServiceAccountJson ? 'Cancel' : 'Edit',
      type: WarehouseFormInputType.BUTTON,
      gridArea: 'b',
      onClick: () => setIsEditingServiceAccountJson((prev) => !prev),
      adminOnly: true,
    }]
    : []
  ),
];
