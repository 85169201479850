import React, { CSSProperties, FC, useState } from 'react';
import styled from 'styled-components';
import { IconName } from '@blueprintjs/core';
import { Dialog } from 'primereact/dialog';
import ReactPlayer from 'react-player';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { buttonViewType, colorType } from '@paradime-io/pragma-ui-kit/lib/components/styles/styleTypes';

export const DialogCloseButton = styled(DefaultButton)`
  & > span[class*="icon"] {
    color: var(--grey50) !important;
  }

  &:active {
    background: none !important;
  }

  &:hover {
    background: none !important;
    color: var(--grey100);
  }
`;

const VideoPlayerWrapper = styled.div`
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid var(--grey5);
`;

type InAppVideoItemType = {
  title: string,
  description: string,
  videoUrl?: string,
}

interface InAppVideoItemProps {
  icon?: IconName,
  buttonColor?: colorType,
  buttonView?: buttonViewType,
  tutorial: InAppVideoItemType,
  style?: CSSProperties,
}

const InAppVideoButton: FC<InAppVideoItemProps> = ({
  icon,
  buttonColor,
  buttonView,
  tutorial,
  style,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const DialogHeader = () => (
    <AutoLayout
      direction="horizontal"
      padding="none"
      distribution="space-between"
      style={{
        paddingBottom: '16px',
        borderBottom: '1px solid var(--grey5)',
      }}
    >
      <Typography type="h6" color="default" tagName="span">
        {tutorial.title}
      </Typography>
      <DialogCloseButton
        dense
        view="flat"
        icon="cross"
        eventContext={Contexts.RADAR}
        eventObject="closeInAppTutorialDialogBox"
        eventAction={Actions.CLICKED}
        onClick={() => setIsVisible(false)}
        style={{
          background: 'none',
        }}
      />
    </AutoLayout>
  );

  return (
    <>
      <DefaultButton
        type="standard"
        view={buttonView || 'smooth'}
        color={buttonColor || 'primary_alt'}
        icon={icon || 'mobile-video'}
        dense
        eventContext={Contexts.APP}
        eventObject="viewTutorial"
        eventAction={Actions.CLICKED}
        onClick={() => setIsVisible(true)}
        style={style}
      />
      <Dialog
        header={<DialogHeader />}
        headerStyle={{
          paddingBottom: '8px',
        }}
        visible={isVisible}
        closeOnEscape
        dismissableMask
        closable={false}
        position="top-right"
        resizable
        style={{ width: '600px', height: 'auto' }}
        onHide={() => setIsVisible(false)}
      >
        <AutoLayout
          direction="vertical"
          distribution="packed"
          verticalGap="dense"
          padding="none"
          style={{
            height: '100%',
            width: '100%',
            overflow: 'auto',
            paddingTop: '8px',
            paddingRight: '16px',
          }}
        >
          <Typography tagName="span" type="caption" colorStep="50">
            {tutorial.description}
          </Typography>
          {
            tutorial.videoUrl && (
              <VideoPlayerWrapper>
                <ReactPlayer
                  url={tutorial.videoUrl}
                  controls
                  width="100%"
                  height="300px"
                  w
                />
              </VideoPlayerWrapper>
            )
          }
        </AutoLayout>
      </Dialog>
    </>
  );
};

export default InAppVideoButton;
