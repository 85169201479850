import { useEffect, FC } from 'react';

enum AuthMessage {
  'AUTH_SUCCESS' = 'bigquery-auth-success',
  'AUTH_FAIL' = 'bigquery-auth-failed',
}

const OAuthCallback: FC = () => {
  const { search } = window.location;

  const communicateAuthStatus = (messageType: AuthMessage) => {
    const { parent, opener } = window;
    opener?.postMessage({ type: messageType }, '*');
    setTimeout(parent.close, 100);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(search);

    const isOkResponse = searchParams.get('ok')?.toLocaleLowerCase() === 'true';

    if (isOkResponse) {
      communicateAuthStatus(AuthMessage.AUTH_SUCCESS);
    } else {
      communicateAuthStatus(AuthMessage.AUTH_FAIL);
    }
  }, []);

  return null;
};

export default OAuthCallback;
