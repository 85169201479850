import React, { FC } from 'react';
import { Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import UpgradeTemplate from './UpgradeTemplate';
import { userAuthStore } from '../../../stores';
import { userHasEditorAdminAccess } from '../../../utilis/PermissionsService';
import CodeIDEImage from './images/CodeIDE.svg';

const CodeIDEUpgrade: FC = () => {
  const { currentUser: { accessLevel } } = userAuthStore.getState();
  const isAdmin = userHasEditorAdminAccess(accessLevel);

  const upgradePlanName = 'Code IDE';

  return (
    <div style={{ width: '80%', margin: 'auto', marginTop: '60px' }}>
      <UpgradeTemplate
        isAdmin={isAdmin}
        upgradePlanName={upgradePlanName}
        header="AI-powered dbt™ development"
        bodyText="Secure, serverless cloud IDE to build dbt™ models 50-83% faster with built-in data apps, easy git and AI co-pilot."
        buttonClickEventContext={Contexts.EDITOR}
        featureText={upgradePlanName}
        docsUrl="https://docs.paradime.io/app-help/code-ide/get-started"
        upgradeImage={CodeIDEImage}
      />
    </div>
  );
};

export default CodeIDEUpgrade;
