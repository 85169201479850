import { useDatabaseHookProps } from '../../utils';
import { useCommonConfig, MotherDuckFormDataType } from './CommonConfig';
import { formDistribution, initialValues, formFields } from './dwhFormFields';

export type { MotherDuckFormDataType };

export const useDatabaseHook = ({
  formData,
  env,
  source,
  isEditable,
  isAdditionalUser,
  isNewConnection,
}: useDatabaseHookProps) => {
  const commonConfigs = useCommonConfig({
    formData,
    env,
    source,
    isEditable,
    isAdditionalUser,
    isNewConnection,
  });

  return {
    ...commonConfigs,
    initialFormData: initialValues,
    formDistribution,
    form: formFields(env),
  };
};
