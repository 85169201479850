import React, { FC } from 'react';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Card from '@paradime-io/pragma-ui-kit/lib/components/Card';
import * as S from './AppHelp.styles';

interface HelpCardProps {
  title: string,
  subtitle?: string,
  url?: string,
  isFeatured: boolean,
}

const HelpCard:FC<HelpCardProps> = ({
  title, subtitle, url, isFeatured,
}) => (
  <S.HelpCardWrapper featured={isFeatured.toString()}>
    <Card
      padding="dense"
      height={isFeatured ? '150px' : '60px'}
      onClick={() => window.open(url, '_blank')}
      raiseOnHover
    >
      <AutoLayout
        direction="horizontal"
        distribution="packed"
        padding="none"
        horizontalGap="dense"
        style={{ gridAutoColumns: 'unset' }}
      >
        <S.HelpCardIcon
          icon="manual"
          size={16}
          featured={isFeatured.toString()}
        />
        <AutoLayout
          direction="vertical"
          distribution="packed"
          padding="none"
          verticalGap="super-ultra-dense"
        >
          <Typography
            tagName="span"
            type="body-bold-small"
            colorStep={isFeatured ? '0' : '80'}
          >
            {title}
          </Typography>
          {
          subtitle && !isFeatured && (
            <Typography tagName="span" type="caption" colorStep="70" singleline>
              {subtitle}
            </Typography>
          )
        }
        </AutoLayout>
      </AutoLayout>
    </Card>
  </S.HelpCardWrapper>
);

export default HelpCard;
