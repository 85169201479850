/* eslint-disable @typescript-eslint/no-unused-vars */
import github from './images/github.svg';
import githubIcon from './images/github-icon.svg';
import bitbucket from './images/bitbucket.svg';
import bitbucketIcon from './images/bitbucket-icon.svg';
import gitlab from './images/gitlab.svg';
import gitlabIcon from './images/gitlab-icon.svg';
import azureDevops from './images/azureDevOps.svg';
import azureDevopsIcon from './images/azureDevOps-icon.svg';
import { GitHelp } from '../AppHelp/help';

const matchUrlParts = (repoRegex: RegExp, repoUrl: string) => {
  const [
    correctLink, ssh, repoUrlHostname, owner, repoName,
  ] = repoUrl.match(repoRegex) || [];

  let hostname = repoUrlHostname;
  let ownerName = owner;
  if (ssh) {
    // Remove the "ssh" prefix
    hostname = repoUrlHostname.replace('ssh.', '');
    // Remove the port number and trailing slash
    ownerName = owner.replace(/^[0-9]*\//, '');
  }

  return {
    correctLink,
    ssh,
    repoUrlHostname: hostname,
    owner: ownerName,
    repoName,
  };
};

const genericRepoRegex = /^(ssh:\/\/){0,1}git@(.+):(.+)\/(.+)\.git$/;

export const RepoDefinitions = new Map([
  [
    'bitbucket',
    {
      repoRegex: genericRepoRegex,
      repoImage: bitbucket,
      repoIcon: bitbucketIcon,
      providerName: 'BitBucket',
      sshInviteCall: 'Deploy Key settings in your account',
      sshUrl: () => 'https://bitbucket.org/account/settings/ssh-keys/',
      repoURLSample: 'git@bitbucket.com:myorganization/myproject.git',
      getRepoLink: (repoRegex: RegExp, repoUrl: string) => {
        const {
          repoUrlHostname, owner, repoName,
        } = matchUrlParts(repoRegex, repoUrl);

        const text = `${owner}/${repoName}`;
        const url = `https://${repoUrlHostname}/${owner}/${repoName}`;

        return {
          text,
          url,
        };
      },
      helpArticleId: GitHelp.BIT_BUCKET,
    },
  ],
  [
    'github',
    {
      repoRegex: genericRepoRegex,
      repoImage: github,
      repoIcon: githubIcon,
      providerName: 'Github',
      sshInviteCall: 'Deploy Key settings in your repository',
      sshUrl: (repoRegex: RegExp, repoUrl: string) => {
        const {
          correctLink, repoUrlHostname, owner, repoName,
        } = matchUrlParts(repoRegex, repoUrl);

        if (!correctLink) return undefined;
        return `https://${repoUrlHostname}/${owner}/${repoName}/settings/keys`;
      },
      repoURLSample: 'git@github.com:myorganization/myproject.git',
      getRepoLink: (repoRegex: RegExp, repoUrl: string) => {
        const {
          repoUrlHostname, owner, repoName,
        } = matchUrlParts(repoRegex, repoUrl);

        const text = `${owner}/${repoName}`;
        const url = `https://${repoUrlHostname}/${owner}/${repoName}`;

        return {
          text,
          url,
        };
      },
      helpArticleId: GitHelp.GITHUB,
    },
  ],
  [
    'gitlab',
    {
      repoRegex: genericRepoRegex,
      repoImage: gitlab,
      repoIcon: gitlabIcon,
      providerName: 'Gitlab',
      sshInviteCall: 'Deploy Key settings in your repository',
      sshUrl: (repoRegex: RegExp, repoUrl: string) => {
        const {
          correctLink, repoUrlHostname, owner, repoName,
        } = matchUrlParts(repoRegex, repoUrl);

        if (!correctLink) return undefined;
        return `https://${repoUrlHostname}/${owner}/${repoName}/-/settings/repository#js-deploy-keys-settings`;
      },
      repoURLSample: 'git@gitlab.com:myorganization/myproject.git',
      getRepoLink: (repoRegex: RegExp, repoUrl: string) => {
        const {
          repoUrlHostname, owner, repoName,
        } = matchUrlParts(repoRegex, repoUrl);

        const text = `${owner}/${repoName}`;
        const url = `https://${repoUrlHostname}/${owner}/${repoName}`;

        return {
          text,
          url,
        };
      },
      helpArticleId: GitHelp.GITLAB,
    },
  ],
  [
    'azureDevOps',
    {
      repoRegex: /^git@(.+):.+\/(.+)\/(.+)\/(.+)$/,
      repoImage: azureDevops,
      repoIcon: azureDevopsIcon,
      providerName: 'Azure DevOps',
      sshInviteCall: 'Deploy Key settings in your account',
      repoURLSample: 'git@ssh.dev.azure.com:version/myorganization/myproject/myrepository.git',
      sshUrl: (repoRegex: RegExp, repoUrl: string) => {
        const [
          correctLink,, owner,,
        ] = repoUrl.match(repoRegex) || [];

        if (!correctLink) return undefined;
        return `https://dev.azure.com/${owner}/_usersSettings/keys?action=edit`;
      },
      getRepoLink: (repoRegex: RegExp, repoUrl: string) => {
        const [,, owner, projectName,
          repoName] = repoUrl.match(repoRegex) || [];
        const text = `${owner}/${repoName}`;
        const url = `https://dev.azure.com/${owner}/${projectName}/_git/${repoName}`;

        return {
          text,
          url,
        };
      },
      helpArticleId: GitHelp.AZURE_DEV_OPS,
    },
  ],
  [
    'unknown',
    {
      repoRegex: /.*/,
      repoImage: github,
      repoIcon: githubIcon,
      providerName: 'unknown',
      sshInviteCall: 'unknown',
      sshUrl: () => 'unknown',
      repoURLSample: 'git@$unkown.com:myorganization/myproject.git',
      getRepoLink: () => ({ text: '', url: '' }),
    },
  ],
]);
