import React, { FC } from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';

import { Typography } from '@paradime-io/pragma-ui-kit';
import TickCircle from './images/TickCircle';
import ParadimeLogo from './images/ParadimeLogo';

const TaskManagerCallback: FC = () => {
  const { opener } = window;
  const location = new URLSearchParams(window.location.search);
  const code = location.get('code');
  const service = location.get('state');

  if (opener && code && service) {
    opener.postMessage({
      type: 'initializeService',
      payload: {
        code,
        service,
      },
    }, '*');
  }

  return (
    <AutoLayout
      padding="expanded"
      direction="vertical"
      verticalGap="expanded"
      width="full"
      alignment="center"
      wrapperStyle={{ height: '100vh' }}
    >
      <ParadimeLogo />
      <TickCircle />
      <AutoLayout
        padding="none"
        direction="vertical"
        alignment="center"
        verticalGap="very-dense"
      >
        <Typography
          font="poppins"
          type="h4"
          color="default"
          colorStep="100"
          style={{ textAlign: 'center' }}
        >
          Jira connected!
        </Typography>
        <Typography
          font="poppins"
          type="body"
          color="default"
          colorStep="60"
          style={{ textAlign: 'center' }}
        >
          You can close this tab.
        </Typography>
      </AutoLayout>
    </AutoLayout>
  );
};

export default TaskManagerCallback;
