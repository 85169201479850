import { useEffect } from 'react';
import { SlackSearchQuery, useSlackSearchLazyQuery } from '../../client/generated/service';

export const useGetSlackUsers = (
  callbackfn: (e: SlackSearchQuery) => void, // eslint-disable-line
) => {
  const [
    querySlackUsers,
    { data: slackUsersData, loading: loadingSlackUsers },
  ] = useSlackSearchLazyQuery({ variables: { query: '' } });

  useEffect(() => {
    if (slackUsersData?.slackSearch?.ok) {
      callbackfn(slackUsersData);
    }
  }, [slackUsersData]);// eslint-disable-line

  return {
    querySlackUsers,
    loadingSlackUsers,
  };
};
