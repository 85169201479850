import React from 'react';
import { IconName } from '@blueprintjs/icons';
import { ToastMessage as PrimeReactToastProps } from 'primereact/toast';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { EventProps } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { ToasterType } from '@paradime-io/pragma-ui-kit/lib/components/Toaster';
import { ToastMessageProps } from '@paradime-io/pragma-ui-kit/lib/components/Toaster/ToastMessage';
import { colorType } from '@paradime-io/pragma-ui-kit/lib/components/styles/styleTypes';
import { alertStore } from '../stores';

interface TriggerAlertProps {
  message?: string | JSX.Element,
  color: colorType,
  title?: string,
  icon?: IconName,
  rightButtonText?: string,
  leftButtonText?: string,
  onRightButtonClick?: Function,
  onLeftButtonClick?: Function,
  showLeftButton?: boolean,
  rightButtonEventData?: EventProps
  leftButtonEventData?: EventProps,
  onClose?: () => void,
  view?: 'smooth' | 'filled' | 'raised'
}

export const triggerAlert = ({ message, color, ...rest }: TriggerAlertProps) => {
  const { openAlert } = alertStore.getState();
  const { closeAlert } = alertStore.getState();

  const messageComponent = () => (
    <Typography
      tagName="span"
      type="body-small"
      color={color || 'danger'}
      colorStep="100"
    >
      {message}
    </Typography>
  );

  openAlert({
    type: 'dense',
    view: 'smooth',
    color,
    icon: 'notifications',
    title: 'Oops! Something is not right',
    children: messageComponent(),
    backdropOpacity: '20',
    showLeftButton: false,
    onClose: () => closeAlert(),
    ...rest,
    onRightButtonClick: () => {
      if (rest.onRightButtonClick) rest.onRightButtonClick();
      closeAlert();
    }, // @ts-ignore
    onLeftButtonClick: () => {
      if (rest.onLeftButtonClick) rest.onLeftButtonClick();
      closeAlert();
    },

  });
};

export interface TriggerToastProps extends ToastMessageProps {
  type: ToasterType,
}

export const BlueprintToPrimeReactToastTypes = {
  warning: 'error',
  neutral: 'secondary',
  positive: 'success',
  attention: 'warn',
};

export const triggerToast = ({
  header, message, type,
}: TriggerToastProps) => {
  const { toaster } = alertStore.getState();

  toaster.current?.show({
    severity: BlueprintToPrimeReactToastTypes[type] as PrimeReactToastProps['severity'],
    summary: header,
    detail: message,
  });
};
