import { useState } from 'react';
import { colorType } from '@paradime-io/pragma-ui-kit/lib/components/styles/styleTypes';
import { useGetScheduleNamesQuery } from '../../../../../client/generated/service';

type BoltCommandType = {
  uuid: string,
  name: string,
  state: string,
  color: colorType,
  owner: string,
  nextRunTime: string,
}

const useGetBoltCommands = () => {
  const [boltCommandsData, setBoltCommandsData] = useState<BoltCommandType[]>();
  const { loading: isLoadingSchedules } = useGetScheduleNamesQuery({
    variables: {
      offset: 0,
      limit: 100,
      showInactive: false,
    },
    onCompleted: (data) => {
      if (data?.getScheduleNames?.ok && data.getScheduleNames.scheduleNames) {
        const commandData = data.getScheduleNames.scheduleNames.map((schedule) => ({
          uuid: schedule?.meta.uuid || '',
          name: schedule?.name || '',
          state: schedule?.lastRunState.text || '',
          color: schedule?.lastRunState.colorType || '',
          owner: schedule?.owner || '',
          nextRunTime: schedule?.nextRun || '',
        })) as BoltCommandType[];
        setBoltCommandsData(commandData);
      }
    },
  });

  return {
    isLoadingBoltCommandsData: isLoadingSchedules,
    boltCommandsData,
  };
};

export { useGetBoltCommands };
