import { Icon, IconName, Spinner } from '@blueprintjs/core';
import { Menu } from '@paradime-io/pragma-ui-kit/lib/components/Menu';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { colorType } from '@paradime-io/pragma-ui-kit/lib/components/styles/styleTypes';
import React, { FC } from 'react';
import MenuDivider from '@paradime-io/pragma-ui-kit/lib/components/MenuDivider';
import MenuItem from '@paradime-io/pragma-ui-kit/lib/components/MenuItem';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { triggerAlert } from '../../../utilis';

interface handleUserActionProps {
  message: string,
  title: string,
  color: colorType,
  eventObjectLeft: string,
  eventObjectRight: string,
  action: Function,
  icon: IconName
}

interface EditModalProps {
  disabled: boolean,
  initialDeleteLoading: boolean,
  initialSetDefaultLoading: boolean,
  updateFunction: () => void,
  deleteFunction: () => void,
  connectionName: string,
  env: string
}

const EditModal: FC<EditModalProps> = ({
  disabled,
  initialDeleteLoading,
  initialSetDefaultLoading,
  deleteFunction,
  updateFunction,
  connectionName,
  env,
}) => {
  const handleUserAction = async (
    {
      title,
      message,
      eventObjectLeft,
      eventObjectRight,
      action,
      color,
      icon,
    }: handleUserActionProps,
  ) => {
    triggerAlert({
      message,
      title,
      color,
      icon,
      leftButtonText: 'Maybe next time',
      leftButtonEventData: {
        eventAction: Actions.CANCELLED,
        eventContext: Contexts.WAREHOUSE,
        eventObject: eventObjectLeft,
      },
      showLeftButton: true,
      rightButtonText: 'Sure, continue!',
      rightButtonEventData: {
        eventAction: Actions.DELETED,
        eventContext: Contexts.WAREHOUSE,
        eventObject: eventObjectRight,
      },
      onRightButtonClick: () => action(),
    });

    /** sometimes, because we have 2 portals open,
     * the z-index gets messed up, this fragment makes
     * sure the portal with the confirmation message
     * is always above
     * */

    await new Promise((resolve) => setTimeout(resolve, 500));

    const toastParent = document.evaluate(
      '//div[contains(@class, "bp4-overlay-scroll-container")][//span[text()="Sure, continue!"]]',
      document,
      null,
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      null,
    ).singleNodeValue as HTMLElement | null;

    if (toastParent) { // @ts-ignore
      toastParent.style.zIndex = '10000';
    }
  };

  return (
    <Menu
      type="dense"
      view="raised"
    >
      <MenuItem
        eventAction={Actions.CLICKED}
        eventContext={Contexts.WAREHOUSE}
        eventObject="connectionSetAsDefault"
        text="Set as default"
        onItemClick={() => handleUserAction(
          {
            title: 'Set default target connection', // @ts-ignore
            message: (
              <Typography type="body-small" tagName="p">
                You are about to set the&nbsp;
                <Typography type="body-bold-small" tagName="b" style={{ display: 'inline-block' }}>
                  {env}
                </Typography>
                &nbsp;connection with the target&nbsp;
                <Typography type="body-bold-small" tagName="b" style={{ display: 'inline-block' }}>
                  {connectionName}
                </Typography>
                &nbsp;as default for all users in your organisation.
                <br />
                <br />
                Are you sure?
              </Typography>
            ),
            eventObjectLeft: 'connectionSetAsDefaultCancelled',
            eventObjectRight: 'connectionSetAsDefaultConfirmed',
            action: updateFunction,
            color: 'default',
            icon: 'info-sign',
          },
        )}
        type="dense"
        disabled={disabled}
        labelElement={initialSetDefaultLoading
          ? <Spinner size={16} />
          : undefined}
      />
      <MenuDivider type="dense" />
      <MenuItem
        eventAction={Actions.CLICKED}
        eventContext={Contexts.WAREHOUSE}
        eventObject="connectionDelete"
        text="Delete connection"
        color="danger"
        onItemClick={() => handleUserAction(
          {
            title: 'Deleting connection', // @ts-ignore
            message: (
              <Typography color="danger" type="body-small" tagName="p">
                You are about to permanently delete the&nbsp;
                <Typography color="danger" type="body-bold-small" tagName="b" style={{ display: 'inline-block' }}>
                  {env}
                </Typography>
                connection with the target&nbsp;
                <Typography color="danger" type="body-bold-small" tagName="b" style={{ display: 'inline-block' }}>
                  {connectionName}
                </Typography>
                &nbsp;for all your users. You can&apos;t undo this.
                <br />
                <br />
                Are you sure?
              </Typography>
            ),
            eventObjectLeft: 'connectionDeleteCancelled',
            eventObjectRight: 'connectionDeleteConfirmed',
            action: deleteFunction,
            color: 'danger',
            icon: 'warning-sign',
          },
        )}
        labelElement={initialDeleteLoading
          ? <Spinner size={16} />
          : <Icon icon="trash" />}
        type="dense" // @ts-ignore
        style={{ color: 'var(--color80)' }}
        disabled={disabled}
      />
    </Menu>
  );
};

export default EditModal;
