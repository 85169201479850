import React, { FC } from 'react';
import PageTemplate from '../Utils/PageTemplate';

const InstallExtension: FC = () => (
  <PageTemplate
    withLogo
    icon={{ name: 'tick-circle' }}
    title="Slack connected!"
    titleTextType="h4"
    subtitle="You can now close this tab"
    subtitleTextType="body"
    withBox={false}
  />
);

export default InstallExtension;
