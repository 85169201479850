import React, { FC } from 'react';
import { Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import UpgradeTemplate from './UpgradeTemplate';
import { userAuthStore } from '../../../stores';
import { userHasLineageAdminAccess } from '../../../utilis/PermissionsService';
import LineageImage from './images/Lineage.svg';

const LineageUpgrade: FC = () => {
  const { currentUser: { accessLevel } } = userAuthStore.getState();
  const isAdmin = userHasLineageAdminAccess(accessLevel);

  const upgradePlanName = 'Bolt plan';

  return (
    <div style={{ width: '80%', margin: 'auto', marginTop: '60px' }}>
      <UpgradeTemplate
        isAdmin={isAdmin}
        upgradePlanName={upgradePlanName}
        header="Enable end-to-end visibility into your data stack"
        bodyText={(
          <>
            See how your data travels from Fivetran to dbt™️, and on to your Looker,
            ThoughtSpot, and Tableau dashboards.
            <br />
            <br />
            Track dbt™️ pipeline updates in real time and identify impacted data consumers.
          </>
          )}
        buttonClickEventContext={Contexts.LINEAGE}
        featureText={upgradePlanName}
        docsUrl="https://docs.paradime.io/app-help/graph-lineage/real-time-data-lineage"
        upgradeImage={LineageImage}
      />
    </div>
  );
};

export default LineageUpgrade;
