import { catalogueSimpleTypeProps } from '../types';

export const catalogueSimpleType = ({
  resultItem,
}: catalogueSimpleTypeProps) => {
  switch (resultItem.__typename) {
    case 'GQLCatalogueDBTExposureItem':
      return {
        simpleType: 'exposure',
        compoundType: 'DBT Exposure',
      };
    default:
      return {
        simpleType: '',
        compoundType: '',
      };
  }
};
