/* eslint-disable no-case-declarations */
import React from 'react';
import { catalogueHeaderButtonsProps, catalogueHeaderButtonsReturnType } from '../types';
import looker from '../../components/Catalog/images/looker.svg';

const getModelLink = (lookerUrl?: string | null, branchName?: string) => {
  if (!lookerUrl) return '#';
  if (!branchName) return '';
  const encodeNodeId = encodeURIComponent(btoa(lookerUrl));
  return `/catalog/search/redirect/external/${encodeURIComponent(branchName)}/${encodeNodeId}`;
};

export const catalogueHeaderButtons = ({
  resultItem,
  isBusinessUser,
}: catalogueHeaderButtonsProps): catalogueHeaderButtonsReturnType => {
  const currentBranchName = resultItem.currentBranch?.name;

  switch (resultItem.__typename) {
    case 'GQLCatalogueLookerDashboardItem':
    case 'GQLCatalogueLookerExploreItem':
    case 'GQLCatalogueLookerLookItem':
      const modelButton = {
        text: (
          <div style={{ display: 'grid', placeItems: 'center', gridAutoFlow: 'column' }}>
            <img src={looker} alt="looker logo" height={16} />
            View in Looker
          </div>
        ),
        link: getModelLink(
          resultItem?.lookerUrl,
          currentBranchName,
        ),
      };

      if (isBusinessUser) {
        return [] as catalogueHeaderButtonsReturnType;
      }
      return [modelButton] as catalogueHeaderButtonsReturnType;
    default:
      return [];
  }
};
