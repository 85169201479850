import { useEffect } from 'react';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { useGenerateCubeEndpointAndTokenMutation } from '../../client/generated/service';
import { graphQLStore } from '../../stores';

const useSetupCubeClient = () => {
  const setCubeClient = graphQLStore((state) => state.setCubeClient);

  const [getEndpointAndToken] = useGenerateCubeEndpointAndTokenMutation({
    onCompleted: ({ generateCubeToken }) => {
      const cubeClient = new ApolloClient({
        uri: generateCubeToken?.endpoint,
        cache: new InMemoryCache(),
        headers: {
          authorization: `Bearer ${generateCubeToken?.token}`,
        },
      });
      setCubeClient(cubeClient);
    },
  });

  useEffect(() => {
    getEndpointAndToken();
  }, []);
};

export { useSetupCubeClient };
