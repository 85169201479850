import styled, { createGlobalStyle } from 'styled-components';

export const CmdKVercelStyle = createGlobalStyle`
  [cmdk-root] {
    max-width: 640px;
    width: 100%;
    padding: 8px;
    background: var(--white);
    border-radius: 12px;
    overflow: hidden;
    font-family: var(--inter);
    border: 1px solid var(--grey5);
    box-shadow: var(--violetShadow4dp);
    transition: transform 100ms ease;
    outline: none;

    position: fixed;
    top: 275px;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1102;

    .dark & {
      background: rgba(22, 22, 22, 0.7);
    }
  }

  [cmdk-input] {
    font-family: var(--inter);
    border: none;
    width: 100%;
    font-size: var(--font_size_body_small);
    padding: 8px 8px 16px 8px;
    outline: none;
    background: var(--white);
    color: var(--grey100);
    border-bottom: 1px solid var(--grey5);
    margin-bottom: 16px;
    border-radius: 0;

    &::placeholder {
      color: var(--grey40);
    }
  }

  [cmdk-vercel-badge] {
    height: 20px;
    background: var(--grey10);
    display: inline-flex;
    align-items: center;
    padding: 0 8px;
    font-size: 12px;
    color: var(--grey100);
    border-radius: 4px;
    margin: 4px 0 4px 4px;
    user-select: none;
    text-transform: capitalize;
    font-weight: 500;
  }

  [cmdk-item] {
    content-visibility: auto;

    cursor: pointer;
    height: 48px;
    border-radius: 8px;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0 16px;
    color: var(--grey100);
    user-select: none;
    will-change: background, color;
    transition: all 150ms ease;
    transition-property: none;

    &[data-selected='true'] {
      background: var(--grey0);
      color: var(--grey80);
    }

    &[data-disabled='true'] {
      color: var(--grey40);
      cursor: not-allowed;
    }

    &:active {
      transition-property: background;
      background: var(--grey5);
    }

    & + [cmdk-item] {
      margin-top: 4px;
    }

    svg {
      width: 18px;
      height: 18px;
    }
  }

  [cmdk-list] {
    height: min(330px, calc(var(--cmdk-list-height)));
    max-height: 400px;
    overflow: auto;
    overscroll-behavior: contain;
    transition: 100ms ease;
    transition-property: height;
  }

  [cmdk-vercel-shortcuts] {
    display: flex;
    margin-left: auto;
    gap: 8px;

    kbd {
      font-family: var(--inter);
      font-size: 12px;
      min-width: 20px;
      padding: 4px;
      height: 20px;
      border-radius: 4px;
      color: var(--grey60);
      background: var(--violet5);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
    }
  }

  [cmdk-separator] {
    height: 1px;
    width: 100%;
    background: var(--grey5);
    margin: 4px 0;
  }

  *:not([hidden]) + [cmdk-group] {
    margin-top: 8px;
  }

  [cmdk-group-heading] {
    user-select: none;
    font-size: 12px;
    color: var(--grey100);
    padding: 0 8px;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }

  [cmdk-empty] {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    white-space: pre-wrap;
    color: var(--grey100);
  }
`;

export const CodeKbd = styled.kbd<{dark: boolean}>`
  font-family: var(--inter);
  font-size: var(--font_size_caption);
  background: ${(props) => (props.dark ? 'var(--violet60)' : 'var(--violet40)')};
  color: ${(props) => (props.dark ? 'var(--grey20)' : 'var(--grey0)')};
  padding: 2px 4px;
  border-radius: 4px;
`;
