import { catalogueDescriptionProps } from '../types';

export const catalogueDescription = ({
  resultItem,
}: catalogueDescriptionProps) => {
  switch (resultItem.__typename) {
    case 'GQLCatalogueDwhTableItem':
    case 'GQLCatalogueMaterialisedDbtItem':
      return resultItem.description || '';
    case 'GQLCatalogueDwhColumnItem':
      return resultItem.table.description || '';
    case 'GQLCatalogueMaterialisedDbtObjectColumnItem':
      return resultItem.dbtItem.description || '';
    default:
      return '';
  }
};
