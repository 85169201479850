import { triggerToast } from '../../utilis/alerts';

const WINDOW_WAS_CLOSED = 'window_was_closed';

export interface openWindowProps {
  url: string,
  target?: string,
  options?: string,
  launchedFrom: string,
}

const useHandleClosableWindow = () => {
  const openWindow = ({
    url,
    target,
    options,
    launchedFrom,
  }: openWindowProps) => {
    const newWindow = window.open(url, target, options);

    const listenForWindowClose = () => {
      if (!newWindow || typeof newWindow.closed === 'undefined') {
        triggerToast({
          header: 'Something went wrong',
          message: 'Looks like you have an ad-blocker installed! Please add app.paradime.io to your safe list',
          type: 'warning',
        });
        clearInterval(timer);
        window.postMessage({ type: WINDOW_WAS_CLOSED, launchedFrom }, '*');
      }

      if (newWindow?.closed) {
        clearInterval(timer);
        window.postMessage({ type: WINDOW_WAS_CLOSED, launchedFrom }, '*');
      }
    };

    const timer = setInterval(listenForWindowClose, 500);
  };

  const handleWindowClose = (onWindowClose: () => void, launchedFrom: string) => {
    const eventHandler = ({ data }: MessageEvent) => {
      const { type, launchedFrom: messageLaunchedFrom } = data;
      if (type === WINDOW_WAS_CLOSED && messageLaunchedFrom === launchedFrom) {
        window.removeEventListener('message', eventHandler);
        onWindowClose();
      }
    };
    window.addEventListener('message', eventHandler, false);
  };

  return { openWindow, handleWindowClose };
};

export { useHandleClosableWindow };
