import { userAuthStore } from './stores';

const getCompanyLevelStorageKey = (key: string) => {
  const { currentUser: { companyToken, workspaceUid } } = userAuthStore.getState();
  return `${key}${companyToken ? `:${companyToken}` : ''}${workspaceUid ? `:${workspaceUid}` : ''}`;
};

type AllowedStorageTypes = Storage | IDBFactory

const isParadimeKey = (key: string) => key.toLocaleLowerCase().startsWith('paradime:');

const isCorrectStorage = (
  context: AllowedStorageTypes,
) => context === window.localStorage || context === window.indexedDB;

const shouldUseCompanyLevelStorage = (
  currentContext: AllowedStorageTypes, key: string,
) => isParadimeKey(key) && isCorrectStorage(currentContext);

const setupCompanyLevelLocalStorage = () => {
  const getItemOriginal = Storage.prototype.getItem;
  const setItemOriginal = Storage.prototype.setItem;
  const removeItemOriginal = Storage.prototype.removeItem;

  Storage.prototype.getItem = function getItemCompanyLevel(key) {
    if (shouldUseCompanyLevelStorage(this, key)) {
      const companyLevelKey = getCompanyLevelStorageKey(key);
      return getItemOriginal.call(this, companyLevelKey) || getItemOriginal.call(this, key);
    }
    return getItemOriginal.call(this, key);
  };

  Storage.prototype.setItem = function setItemCompanyLevel(key, value) {
    if (shouldUseCompanyLevelStorage(this, key)) {
      const companyLevelKey = getCompanyLevelStorageKey(key);
      return setItemOriginal.call(this, companyLevelKey, value);
    }
    return setItemOriginal.call(this, key, value);
  };

  Storage.prototype.removeItem = function removeItemCompanyLevel(key) {
    if (shouldUseCompanyLevelStorage(this, key)) {
      const companyLevelKey = getCompanyLevelStorageKey(key);
      return (removeItemOriginal.call(this, companyLevelKey), removeItemOriginal.call(this, key));
    }
    return removeItemOriginal.call(this, key);
  };
};

const setupCompanyLevelIndexeddb = () => {
  const openOriginal = IDBFactory.prototype.open;

  IDBFactory.prototype.open = function openCompanyLevel(name, version) {
    if (shouldUseCompanyLevelStorage(this, name)) {
      const companyLevelName = getCompanyLevelStorageKey(name);
      return openOriginal.call(this, companyLevelName, version);
    }
    return openOriginal.call(this, name, version);
  };
};

setupCompanyLevelIndexeddb();
setupCompanyLevelLocalStorage();
