import React, { FC } from 'react';
import ZeroState from '@paradime-io/pragma-ui-kit/lib/components/ZeroState';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';

const TimelineRunInProgressZeroState: FC = () => (
  <div style={{ height: '400px' }}>
    <ZeroState
      showProgressAnimation
      text={(
        <AutoLayout
          padding="none"
          direction="vertical"
          verticalGap="ultra-dense"
          alignment="center"
          wrapperStyle={{ justifyContent: 'center' }}
          style={{ width: '300px' }}
        >
          <Typography
            font="inter"
            type="body-bold-small"
            color="default"
            colorStep="100"
            style={{ textAlign: 'center' }}
          >
            Current run is in progress.
          </Typography>
          <Typography
            font="inter"
            type="caption"
            color="default"
            colorStep="70"
            style={{ textAlign: 'center' }}
          >
            Model timelines will be available once the run is complete and there are no errors.
          </Typography>
        </AutoLayout>
        )}
    />
  </div>
);

export default TimelineRunInProgressZeroState;
