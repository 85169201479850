import {
  useState, useEffect,
} from 'react';
import { useLocation } from 'react-router-dom';
import { ParadimeTheme } from '@paradime/common/lib/common/genericTypes';

const useListenForThemeChange = () => {
  const [theme, setTheme] = useState('');
  const location = useLocation();

  useEffect(() => {
    const changeTheme = (e: MessageEvent) => {
      const { data: { type, payload } } = e;

      if (type === 'set-theme') {
        setTheme(payload.theme);
      }
    };
    window.addEventListener('message', changeTheme);

    return () => {
      window.removeEventListener('message', changeTheme, false);
    };
  }, []);

  return theme === ParadimeTheme.DARK && location.pathname === '/editor';
};

export default useListenForThemeChange;
