import React from 'react';
import { colorType } from '@paradime-io/pragma-ui-kit/lib/components/styles/styleTypes';
import { lineageNodeDisplayProps } from '../types';
import DbtLogo from '../../components/Lineage/images/DBT';
import { decodeLogoBase64, translateBEColourToFEColour } from '../utils';

const getNodeColor = (nodeType: string) => {
  switch (nodeType) {
    case 'DBTModelNode':
    case 'DBTSnapshotNode':
      return 'dbt_blue';
    case 'DBTSourceNode':
    case 'DBTSeedNode':
      return 'dbt_green';
    case 'DBTAnalysisNode':
    case 'DBTExposureNode':
      return 'dbt_orange';
    case 'DBTMetricNode':
      return 'primary';
    default:
      return 'dbt_blue';
  }
};

export const lineageNodeDisplay = ({
  nodeType,
  lineageData,
}: lineageNodeDisplayProps) => {
  // Strip 'DBT' and 'Node' to get the type
  // e.g. DBTSeedNode -> Seed
  const nodeTypeName = nodeType.replace('DBT', '').replace('Node', '');

  return ({
    nodePrimaryLogo: decodeLogoBase64(lineageData?.logoBase64) || <DbtLogo />,
    nodeColor: translateBEColourToFEColour(lineageData?.color).variantName as colorType
    || getNodeColor(nodeType) as colorType,
    nodeTypeName,
  });
};
