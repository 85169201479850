import { catalogueSearchResultItem } from './CatalogueSearchResultItem';
import { IntegrationHandler } from '..';
import { catalogueDetailsCard } from './CatalogueDetailsCard';
import { catalogueNameAndIcon } from './CatalogueNameAndIcon';
import { catalogueHeaderButtons } from './CatalogueHeaderButtons';
import { catalogueClassificationData } from './CatalogueClassificationData';
import { catalogueLogo } from './CatalogueLogo';
import { catalogueSimpleType } from './CatalogueSimpleType';
import { catalogueDescription } from './CatalogueDescription';
import { catalogueAssetColumns } from './CatalogueAssetColumns';
import { catalogueAssetItemId } from './CatalogueAssetItemId';
import { catalogueSearchFilters } from './CatalogueSearchFilters';
import { catalogueItemTabsVisibility } from './CatalogueItemTabsVisibility';
import { catalogueTreeIcons } from './CatalogueTreeIcons';
import { catalogueDependenciesTab } from './CatalogueDependenciesTab';
import { catalogueDbtModelTab } from './CatalogueDbtModelTab';

export enum DwhHandlerAcceptedTypes {
  'DWH' = 'DWH',
  'GQLCatalogueMaterialisedDbtObjectColumnItem' = 'Column',
  'GQLCatalogueDwhColumnItem' = 'Column',
  'GQLCatalogueDwhTableItem' = 'Table',
  'GQLCatalogueMaterialisedDbtItem' = 'Table',
  'table' = 'table',
  'dashboard' = 'dashboard',
  'column' = 'column',
  'dwh:bigquery' = 'dwh:bigquery',
  'dwh:redshift' = 'dwh:redshift',
  'dwh:snowflake' = 'dwh:snowflake',
  'dwh:firebolt' = 'dwh:firebolt',
  'paradime-lineage' = 'paradime-lineage',
  'paradime-editor' = 'paradime-editor',
  'paradime-radar' = 'paradime-radar',
  'paradime-catalogue' = 'paradime-catalogue',
}

export enum DwhSubTypes {
  'DWH_TABLES' = 'tables',
  'DWH_COLUMNS' = 'columns',
}

export const DwhIntegrationHandler: IntegrationHandler = ({
  type: DwhHandlerAcceptedTypes.DWH,
  subTypes: Object.values(DwhSubTypes),
  handlers: {
    // lineageNodeRenderer: (data: any) => <div>{data}</div>,
    // catalogueCardRenderer: (data: any) => <div>{data}</div>,
    lineageContextMenu: () => undefined,
    lineageSearchInputDropdown: () => ({
      img: '',
      labelElement: null,
    }),
    lineageFilterNodes: () => ({
      title: '',
      items: [],
    }),
    catalogueSearchResultItem,
    // @ts-ignore - catalogDetailsCard props in Pragma should have "tags: string | Element"
    catalogueDetailsCard,
    catalogueNameAndIcon,
    catalogueHeaderButtons,
    catalogueClassificationData,
    catalogueLogo,
    catalogueSimpleType,
    catalogueDescription,
    lineageNodeDisplay: () => ({
      nodePrimaryLogo: undefined,
      nodeColor: 'default',
      nodeTypeName: '',
    }),
    catalogueAssetColumns,
    catalogueAssetItemId,
    catalogueSearchFilters,
    catalogueItemTabsVisibility,
    catalogueFieldsTab: () => ({
      columnHeadings: [],
      data: [],
    }),
    catalogueDependenciesTab,
    catalogueSchemaTab: () => [{
      tableName: '',
      columnData: [],
    }],
    catalogueDbtModelTab,
    catalogueTreeIcons,
    catalogueShowClassificationsCard: () => true,
  },
});
