export const decodeLogoBase64 = (logoBase64?: string | null) => {
  if (!logoBase64) return '';
  const b64DecodedString = Buffer.from(logoBase64, 'base64').toString();
  return `data:image/svg+xml;utf8,${encodeURIComponent(b64DecodedString)}`;
};

export const translateBEColourToFEColour = (colour?: string | null) => {
  switch (colour) {
    case 'ORANGE':
      return { variantName: 'warning_alt', paradimeVariable: 'var(--orange50)' };
    case 'TEAL':
      return { variantName: 'success_alt', paradimeVariable: 'var(--teal50)' };
    case 'VIOLET':
      return { variantName: 'primary', paradimeVariable: 'var(--violet50)' };
    case 'GREEN':
      return { variantName: 'success', paradimeVariable: 'var(--green50)' };
    case 'MANDY':
      return { variantName: 'danger_alt', paradimeVariable: 'var(--pink50)' };
    // The below are colours we may use in the future, but are not currently defined in pragma
    case 'LEAF':
    case 'CYAN':
    case 'CORAL':
    default:
      return { variantName: undefined, paradimeVariable: undefined };
  }
};
