import { ApolloQueryResult } from '@apollo/client';
import React, {
  FC,
  useEffect,
  useState,
} from 'react';
import { GetScheduleRunQuery } from '../../../../client/generated/service';
import { useScheduleCommandLogsSubscription } from '../../../../client/generated/service-dataEndpoint';
import Command, { CommandProps } from '../CommandLogs';

interface SubscribedCommandProps extends CommandProps {
  refreshData: () => Promise<ApolloQueryResult<GetScheduleRunQuery>>,
}

const SubscribedCommand: FC<SubscribedCommandProps> = (props) => {
  const {
    meta,
    refreshData,
    output: outputFromQuery,
  } = props;

  const [outputFromSubscription, setOutputFromSubscription] = useState<string>();
  const [isFinished, setIsFinished] = useState(false);

  const { data: subscriptionData } = useScheduleCommandLogsSubscription({
    variables: {
      commandID: meta.id,
    },
  });

  useEffect(() => {
    if (subscriptionData?.scheduleCommandLogs.stdout) {
      setOutputFromSubscription(subscriptionData.scheduleCommandLogs.stdout);
    }
    if (subscriptionData?.scheduleCommandLogs.finished !== undefined) {
      setIsFinished(subscriptionData.scheduleCommandLogs.finished);
    }
  }, [subscriptionData]);

  useEffect(() => {
    if (subscriptionData?.scheduleCommandLogs.finished) {
      setTimeout(refreshData, 500);
    }
  }, [subscriptionData]);

  return (
    <Command
      {...props}
      output={outputFromQuery || outputFromSubscription}
      initialIsOpen={Boolean(outputFromSubscription && !isFinished)}
      commandId={meta.id}
    />
  );
};

export default SubscribedCommand;
