import { Dispatch, SetStateAction } from 'react';
import { WarehouseFormInputType } from '../../warehouseFactory/types';
import { WarehouseEnv } from '../../utils';
import { sendResetSecretsMessage } from '../CommonConfig';
import { DevWarehouseHelp, ProdWarehouseHelp } from '../../../../Common/AppHelp/help';

export const formDistribution = (showEditButton: boolean) => (
  showEditButton
    ? ("'P P P P P P P P P P P P' "
      + "'p p p p p p t t t t t t' "
      + "'h h h h h h h h h h h h' "
      + "'D D D D D D D D D D D D' "
      + "'u u u u u o o o o o b b' "
      + "'a a a a e e e e d d d d' "
      + "'s s s s s s c c c c c c' ")
    : ("'P P P P P P' "
      + "'p p p t t t' "
      + "'h h h h h h' "
      + "'D D D D D D' "
      + "'u u u o o o' "
      + "'a a e e d d' "
      + "'s s s c c c' ")
);

export const initialValues = (env: WarehouseEnv) => ({
  accountName: '',
  connectionName: '',
  engineName: '',
  hostName: '',
  database: '',
  username: '',
  password: '',
  targetName: env,
  threads: 1,
});

export const formFields = (
  showEditButton: boolean,
  isEditingUsernamePassword: boolean,
  setIsEditingUsernamePassword: Dispatch<SetStateAction<boolean>>,
  env: WarehouseEnv,
) => [
  {
    title: 'Connection Settings',
    gridArea: 'P',
    type: 'title',
    helpArticleId: (env === WarehouseEnv.PROD)
      ? ProdWarehouseHelp.FIREBOLT
      : DevWarehouseHelp.FIREBOLT,
  },
  {
    id: 'text-fb-profile-name',
    name: 'connectionName',
    label: 'Profile Name',
    type: WarehouseFormInputType.TEXT,
    gridArea: 'p',
    title: 'Profile Name',
    adminOnly: true,
    showHighlight: true,
    highlightDescription: 'You will find this in your <code>dbt_project.yml</code>.<br>Use <code>default</code> if you are getting started with an empty repo.',
  },
  {
    id: 'text-fb-target-name',
    name: 'targetName',
    label: 'Target',
    type: WarehouseFormInputType.TEXT,
    value: env || WarehouseEnv.DEV,
    gridArea: 't',
    adminOnly: true,
  },
  {
    id: 'text-fb-host-name',
    name: 'hostName',
    label: 'Host Name',
    type: WarehouseFormInputType.TEXT,
    gridArea: 'h',
    adminOnly: true,
    isPrivate: true,
  },
  {
    title: (env === WarehouseEnv.PROD) ? 'Production Credentials' : 'Development Credentials',
    gridArea: 'D',
    type: WarehouseFormInputType.TITLE,
  },
  {
    id: 'text-fb-username',
    name: 'username',
    label: 'Client Id',
    type: WarehouseFormInputType.TEXT,
    gridArea: 'u',
    value: '' as any,
    title: (env === WarehouseEnv.PROD) ? 'Production Credentials' : 'Development Credentials' as any,
    isPrivate: true,
    disabled: showEditButton ? !isEditingUsernamePassword : false,
    isSecretField: true,
    isEditingSecretField: isEditingUsernamePassword,
    adminOnly: env !== WarehouseEnv.DEV,
  },
  {
    id: 'text-fb-password',
    name: 'password',
    label: 'Client Secret',
    type: WarehouseFormInputType.PASSWORD,
    gridArea: 'o',
    isPrivate: true,
    disabled: showEditButton ? !isEditingUsernamePassword : false,
    isSecretField: true,
    isEditingSecretField: isEditingUsernamePassword,
    adminOnly: env !== WarehouseEnv.DEV,
  },
  ...(showEditButton
    ? [{
      name: 'editClientSecretButton',
      text: isEditingUsernamePassword ? 'Cancel' : 'Edit',
      type: WarehouseFormInputType.BUTTON,
      gridArea: 'b',
      onClick: () => setIsEditingUsernamePassword((prev) => {
        if (prev) {
          // User has pressed cancel, undo any changes
          sendResetSecretsMessage();
        }
        return !prev;
      }),
    }]
    : []
  ),
  {
    id: 'text-fb-account-name',
    name: 'accountName',
    label: 'Account Name',
    type: WarehouseFormInputType.TEXT,
    gridArea: 'a',
    title: 'Account Name',
  },
  {
    id: 'text-fb-engine-name',
    name: 'engineName',
    label: 'Engine Name',
    type: WarehouseFormInputType.TEXT,
    gridArea: 'e',
  },
  {
    id: 'text-fb-database',
    name: 'database',
    label: 'Database',
    type: WarehouseFormInputType.TEXT,
    gridArea: 'd',
  },
  {
    id: 'text-fb-schema',
    name: 'schema',
    label: 'Schema',
    type: WarehouseFormInputType.TEXT,
    gridArea: 's',
  },
  {
    id: 'text-fb-threads',
    name: 'threads',
    label: 'Threads',
    type: WarehouseFormInputType.NUMBER,
    value: 1,
    additionalUserPlaceholder: 8,
    gridArea: 'c',
  },
];
