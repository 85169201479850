import React, { FC } from 'react';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { Plain } from '../../Common/Libraries/Plain';
import { appStore } from '../../../stores';

enum ButtonLinks {
  GETTING_STARTED = 'https://docs.paradime.io/app-help/guides/paradime-101/getting-started-with-your-paradime-workspace',
  DOCS = 'https://docs.paradime.io',
}

const HomeBottom:FC = () => {
  const setShowAppHelp = appStore((s) => s.setShowAppHelp);

  return (
    <AutoLayout
      direction="vertical"
      distribution="packed"
      padding="dense"
      verticalGap="dense"
      alignment="top-left"
      style={{ background: 'var(--white)', borderTop: '0.5px solid var(--grey40)' }}
      wrapperStyle={{ position: 'relative', bottom: 0, height: 'auto' }}
    >
      <Typography tagName="span" type="body-bold-small" colorStep="100">
        Additional Resources
      </Typography>
      <AutoLayout
        direction="horizontal"
        distribution="space-between"
        padding="none"
        style={{ alignItems: 'center' }}
      >
        <DefaultButton
          type="standard"
          view="flat"
          color="default"
          icon="manual"
          text="Docs"
          dense
          fill
          eventContext={Contexts.APP}
          eventObject="viewProductDocs"
          eventAction={Actions.CLICKED}
          onClick={() => window.open(ButtonLinks.DOCS, '_blank')}
          style={{ fontSize: 'var(--font_size_caption)' }}
        />
        <DefaultButton
          type="standard"
          view="flat"
          color="default"
          icon="flame"
          text="Getting Started"
          dense
          fill
          eventContext={Contexts.APP}
          eventObject="viewGetStartedDocs"
          eventAction={Actions.CLICKED}
          onClick={() => window.open(ButtonLinks.GETTING_STARTED, '_blank')}
          style={{ fontSize: 'var(--font_size_caption)' }}
        />
      </AutoLayout>
      <DefaultButton
        type="standard"
        view="outlined"
        color="default"
        text="🚀 Ask Fabio"
        eventContext={Contexts.APP}
        eventObject="askFabioForHelp"
        eventAction={Actions.CLICKED}
        onClick={() => {
          setShowAppHelp(false);
          Plain.open();
        }}
      />
    </AutoLayout>
  );
};

export default HomeBottom;
