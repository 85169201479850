/* eslint-disable no-undef */
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useLocationState } from '../../utilis';
import { useSetCompanyOnCodeEditorIframe } from './hooks';
import useListenersFromCodeEditor from './hooks/useListenersFromCodeEditor';
import useOpenNodesOnCodeEditor from './hooks/useOpenNodesOnCodeEditor';
import useSetFirstEditor from './hooks/useSetFirstEditor';
import PageTitle, { PageTitles } from '../PageTitle';
import useHandleGithubUserOAuth from './hooks/useHandleGithubUserOAuth';
import { userAuthStore } from '../../stores';
import { userHasEditorAccess } from '../../utilis/PermissionsService';
import CodeIDEUpgrade from '../Common/Upgrade/CodeIDEUpgrade';
import { useWaitForLDToBeReady } from '../hooks/useWaitForLDToBeReady';

const EditorLayout: React.FC = () => {
  const [state] = useLocationState();
  const {
    onboardingToken,
    accountType,
  } = state;
  const { currentUser } = userAuthStore.getState();
  const { pathname } = useLocation();

  const { ldIsReady } = useWaitForLDToBeReady();
  const { flagAppEditor } = useFlags();

  useSetCompanyOnCodeEditorIframe();

  useListenersFromCodeEditor();

  useOpenNodesOnCodeEditor();

  useHandleGithubUserOAuth();

  const { editorUrl } = useSetFirstEditor(onboardingToken, accountType);

  const { accessLevel } = currentUser;

  if (ldIsReady && !flagAppEditor) {
    return <CodeIDEUpgrade />;
  }

  return (
    <div style={{
      width: '100%',
      height: '100%',
    }}
    >
      <PageTitle title={PageTitles.EDITOR} />
      {editorUrl && userHasEditorAccess(accessLevel) && (
        <iframe
          id="codeEditorIframe"
          style={{
            width: '100%',
            height: '100%',
            border: 'none',
            backgroundColor: 'white',
            display: /lineage/.test(pathname) ? 'none' : 'block',
          }}
          src={editorUrl}
          className="w-full h-full"
          title="theia"
          allow="clipboard-write; clipboard-read"
          data-html2canvas-ignore="true"
          allowFullScreen
        />
      )}
    </div>
  );
};

export default EditorLayout;
