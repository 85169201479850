import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import * as S from './Radar.styles';
import { AccountSettingsTab } from '../NavBar';
import {
  userHasRadarAddCostConnectionAccess,
} from '../../utilis/PermissionsService';
import { userAuthStore } from '../../stores';

const CalloutNoCostConnection:FC = () => {
  const history = useHistory();
  const { accessLevel } = userAuthStore((s) => s.currentUser);

  return (
    <S.DashboardCallout
      view="smooth"
      color="default"
      title="Cost warehouse not connected"
      content={(
        <AutoLayout
          direction="vertical"
          padding="none"
          distribution="packed"
          verticalGap="dense"
          style={{ paddingBottom: '8px' }}
        >
          <Typography tagName="span" type="caption">
            Add a cost warehouse connection (currently only Snowflake or Bigquery is supported)
            <br />
            in this workspace to measure, analyse and reduce warehouse spend.
          </Typography>
          <div style={{ display: 'flex', width: 'fit-content', justifySelf: 'flex-start' }}>
            <DefaultButton
              type="standard"
              color="primary"
              view="filled"
              icon="data-connection"
              dense
              text="Add cost connection"
              eventContext={Contexts.RADAR}
              eventObject="addCostConnection"
              eventAction={Actions.CLICKED}
              disabled={!userHasRadarAddCostConnectionAccess(accessLevel)}
              onClick={() => {
                history.push(`/settings/${AccountSettingsTab.CONNECTIONS}`);
              }}
            />
          </div>
        </AutoLayout>
    )}
      dense
    />
  );
};

export default CalloutNoCostConnection;
