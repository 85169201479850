import React from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import errorImage from './images/connection-lost.svg';

const UnhandledErrorPage = () => (
  <>
    <AutoLayout
      direction="vertical"
      padding="expanded"
      verticalGap="normal"
      distribution="packed"
      alignment="top-center"
      width="full"
      style={{ justifyItems: 'center', marginTop: '2rem' }}
    >
      <img src={errorImage} alt="error" />
      <Typography tagName="span" type="h3" font="inter">Uh Oh..</Typography>
      <Typography tagName="span" type="body-large" font="inter" style={{ textAlign: 'center' }}>
        Something went wrong, please contact us at
        {' '}
        <a
          style={{ display: 'inline-block', color: 'var(--deeplink-blue)' }}
          target="_blank"
          rel="noopener noreferrer"
          href="mailto:support@paradime.io"
        >
          support@paradime.io
        </a>
      </Typography>
      <DefaultButton
        style={{
          minWidth: '100px',
          margin: '0 auto 0 auto',
        }}
        view="filled"
        color="primary"
        text="Back Home"
        onClick={() => { window.location.replace('/'); }}
        className="bp4-intent-6"
        eventContext={Contexts.EDITOR}
        eventObject="errorPageReturnHome"
        eventAction={Actions.CLICKED}
      />
    </AutoLayout>
  </>
);

export default UnhandledErrorPage;
