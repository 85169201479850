import { IconName } from '@blueprintjs/core';
import { catalogueSearchResultItemProps, catalogueSearchResultItemReturnType } from '../types';
import { convertNumberToFileSize, formatDateTimeToLocalString } from '../../utilis';
import snowflake from '../../components/Catalog/images/snowflake.svg';
import bigquery from '../../components/Catalog/images/bigquery.svg';
import redshift from '../../components/Catalog/images/redshift.svg';
import firebolt from '../../components/Catalog/images/firebolt.svg';
import dbt from '../../components/Catalog/images/dbt.svg';

export const catalogueSearchResultItem = ({
  resultItem,
}: catalogueSearchResultItemProps): catalogueSearchResultItemReturnType => {
  const dwhLogo = {
    snowflake,
    bigquery,
    redshift,
    firebolt,
    '': dbt, // Default to DBT if there is no dwhType
  };

  switch (resultItem.__typename) {
    case 'GQLCatalogueDwhColumnItem':
    case 'GQLCatalogueMaterialisedDbtObjectColumnItem':
      return (
        [
          {
            type: 'list',
            iconName: 'th-list',
            title: resultItem.columnName || '-',
            subtitle: 'Column',
            rightImage: dwhLogo[resultItem.dwhType as keyof typeof dwhLogo],
          },
          {
            type: 'description',
            description: resultItem.description || ' ',
          },
          { type: 'divider' },
          [
            {
              leftComponents: [
                ...(resultItem?.projectName
                  ? [{
                    type: 'textWithIcon' as 'textWithIcon',
                    text: resultItem.projectName || '-',
                    icon: 'projects' as IconName,
                  }]
                  : []
                ),
                {
                  type: 'textWithIcon',
                  text: resultItem.database || '-',
                  icon: 'database',
                },
                ...(
                  resultItem.dwhType !== 'firebolt'
                    ? [{
                      type: 'textWithIcon' as 'textWithIcon',
                      text: resultItem.schema || '-',
                      icon: 'layout-auto' as IconName,
                    }]
                    : []
                ),
                {
                  type: 'textWithIcon',
                  text: resultItem.tableName || '-',
                  icon: 'th',
                },
              ],
              rightComponents: [
                {
                  type: 'tags',
                  tags: resultItem.tags,
                },
              ],
            },
          ],
        ]
      );

    case 'GQLCatalogueDwhTableItem':
    case 'GQLCatalogueMaterialisedDbtItem':
      return (
        [
          {
            type: 'list',
            iconName: 'th',
            title: resultItem.__typename === 'GQLCatalogueDwhTableItem' ? resultItem.tableName : resultItem.name,
            subtitle: 'Table',
            rightImage: dwhLogo[resultItem.dwhType as keyof typeof dwhLogo],
          },
          {
            type: 'description',
            description: resultItem?.description || ' ',
          },
          { type: 'divider' },
          [
            {
              leftComponents: [
                ...(resultItem?.dbtProjectName
                  ? [{
                    type: 'textWithIcon' as 'textWithIcon',
                    text: resultItem.dbtProjectName || '-',
                    icon: 'projects' as IconName,
                  }]
                  : []
                ),
                {
                  type: 'textWithIcon',
                  text: resultItem.database || '-',
                  icon: 'database',
                },
                ...(
                  resultItem.dwhType !== 'firebolt'
                    ? [{
                      type: 'textWithIcon' as 'textWithIcon',
                      text: resultItem.schema || '-',
                      icon: 'layout-auto' as IconName,
                    }]
                    : []
                ),
                {
                  type: 'textWithHeader',
                  text: resultItem.rowCount > 0 ? resultItem.rowCount.toString() : '-',
                  header: 'Rows',
                },
                {
                  type: 'textWithHeader',
                  text: resultItem.sizeInBytes > 0 ? convertNumberToFileSize(resultItem.sizeInBytes) : '-',
                  header: 'Size',
                },
                {
                  type: 'textWithHeader',
                  text: formatDateTimeToLocalString({ dateTimeString: resultItem.lastModified }),
                  header: 'Last Modified',
                },
              ],
              rightComponents: [
                {
                  type: 'tags',
                  tags: resultItem.tags,
                },
              ],
            },
          ],
        ]
      );

    default:
      return [];
  }
};
