import { AnalyticsSnippet, Context } from '@segment/analytics-next';
import { TrackTelemetryEventMutationFn } from '../../../../client/generated/service-dataEndpoint';
import { pageDefaults } from './PageContext';

const forwardParadimeTelemetry = (
  trackTelemetryEvent: TrackTelemetryEventMutationFn,
): AnalyticsSnippet['track'] => async (...args) => {
  const [event, properties] = args;

  let eventName = '';
  if (typeof event === 'string') eventName = event;
  else if (event.event) eventName = event.event;

  const context = Object.entries(pageDefaults()).map(([key, value]) => ({ key, value }));

  const variables = {
    variables: {
      event: eventName,
      attributes: [],
      rawJSONAttributes: JSON.stringify(properties),
      context,
    },
  };
  await trackTelemetryEvent(variables);

  return {} as Context;
};

export default forwardParadimeTelemetry;
