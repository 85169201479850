import React, { FC, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import {
  StackedBarChart,
  TableChart,
  LineChart,
  GroupedBarChart,
  AreaChart,
} from '@paradime-io/pragma-ui-kit/lib/components/VictoryCharts';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import { useCubeLazyQuery } from '../../../app/hooks/useCubeQuery';
import {
  warehouseNamesForDropdownQuery,
  warehouseNamesForDropdownReturnType,
  formatWarehouseNamesForDropdown,
  snowflakeBalanceQuery,
  snowflakeBalanceReturnType,
  formatSnowflakeBalanceForChart,
  getSnowflakeBalanceXAxisLabels,
  warehouseSpendQuery,
  warehouseSpendReturnType,
  formatWarehouseSpendForChart,
  getWarehouseSpendXAxisLabels,
  dailyCostQuery,
  dailyCostReturnType,
  formatDailyCostForChart,
  getDailyCostXAxisLabels,
  hourlyCostQuery,
  hourlyCostReturnType,
  formatHourlyCostForChart,
  getHourlyCostXAxisLabels,
  userCostQuery,
  userCostReturnType,
  formatUserCostForChart,
  getUserCostXAxisLabels,
  roleCostQuery,
  roleCostReturnType,
  formatRoleCostForChart,
  getRoleCostXAxisLabels,
  costByTypeQuery,
  costByTypeReturnType,
  formatCostByTypeForChart,
  getCostByTypeXAxisLabels,
  prodRunsQuery,
  prodRunsReturnType,
  formatProdRunsForChart,
  getProdRunsXAxisLabels,
  queriesCostQuery,
  queriesCostReturnType,
  queriesCostColumns,
  formatQueriesCostForChart,
  timeoutQueriesQuery,
  timeoutQueriesReturnType,
  timeoutQueriesColumns,
  formatTimeoutQueriesForChart,
} from './SnowflakeCubeQueries';
import FilterRow from '../FilterRow';
import {
  timeframes,
  HalfPageChartSection,
  FullPageChartSection,
  selectAllDropdownOption,
  getFormattedYAxisLabel,
} from '../utils';
import CodeSnippetCopy from '../../Common/Components/CodeSnippetCopy';
import { useGetFiltersFromUrl } from '../hooks/useGetFiltersFromUrl';

const SnowflakeTab: FC = () => {
  const { getInitialFilterValue } = useGetFiltersFromUrl();

  const [timeframe, setTimeframe] = useState(getInitialFilterValue('timeframe', timeframes[2].value)!);
  const [warehouseName, setWarehouseName] = useState(getInitialFilterValue('warehouseName', selectAllDropdownOption.value)!);

  const isSelectAllWarehouses = warehouseName.value === selectAllDropdownOption.value;

  const [
    getWarehouseNamesForDropdown,
    { data: warehouseNamesForDropdown, loading: isLoadingWarehouseNamesForDropdown },
  ] = useCubeLazyQuery<warehouseNamesForDropdownReturnType>({
    query: warehouseNamesForDropdownQuery,
    variables: { timeframe: timeframe.value },
    onCompleted: () => {
      const urlVal = getInitialFilterValue('warehouseName', selectAllDropdownOption.value);
      if (urlVal) setWarehouseName(urlVal);
    },
  });

  const [
    getSnowflakeBalance, {
      data: snowflakeBalanceData,
      loading: isLoadingSnowflakeBalance,
      error: snowflakeBalanceError,
    },
  ] = useCubeLazyQuery<snowflakeBalanceReturnType>({
    query: snowflakeBalanceQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getWarehouseSpend,
    { data: warehouseSpendData, loading: isLoadingWarehouseSpend, error: warehouseSpendError },
  ] = useCubeLazyQuery<warehouseSpendReturnType>({
    query: warehouseSpendQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getDailyCost, { data: dailyCostData, loading: isLoadingDailyCost, error: dailyCostError },
  ] = useCubeLazyQuery<dailyCostReturnType>({
    query: dailyCostQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getHourlyCost, { data: hourlyCostData, loading: isLoadingHourlyCost, error: hourlyCostError },
  ] = useCubeLazyQuery<hourlyCostReturnType>({
    query: hourlyCostQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getUserCost, { data: userCostData, loading: isLoadingUserCost, error: userCostError },
  ] = useCubeLazyQuery<userCostReturnType>({
    query: userCostQuery(isSelectAllWarehouses),
    variables: { timeframe: timeframe.value, warehouseName: warehouseName.value },
  });
  const [
    getRoleCost, { data: roleCostData, loading: isLoadingRoleCost, error: roleCostError },
  ] = useCubeLazyQuery<roleCostReturnType>({
    query: roleCostQuery(isSelectAllWarehouses),
    variables: { timeframe: timeframe.value, warehouseName: warehouseName.value },
  });
  const [
    getCostByType, { data: costByTypeData, loading: isLoadingCostByType, error: costByTypeError },
  ] = useCubeLazyQuery<costByTypeReturnType>({
    query: costByTypeQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getProdRuns, { data: prodRunsData, loading: isLoadingProdRuns, error: prodRunsError },
  ] = useCubeLazyQuery<prodRunsReturnType>({
    query: prodRunsQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getQueriesCost,
    { data: queriesCostData, loading: isLoadingQueriesCost, error: queriesCostError },
  ] = useCubeLazyQuery<queriesCostReturnType>({
    query: queriesCostQuery,
    variables: { timeframe: timeframe.value },
  });
  const [
    getTimeoutQueries,
    { data: timeoutQueriesData, loading: isLoadingTimeoutQueries, error: timeoutQueriesError },
  ] = useCubeLazyQuery<timeoutQueriesReturnType>({
    query: timeoutQueriesQuery(isSelectAllWarehouses),
    variables: { timeframe: timeframe.value, warehouseName: warehouseName.value },
  });

  const refetchAllData = () => {
    getSnowflakeBalance();
    getWarehouseSpend();
    getDailyCost();
    getHourlyCost();
    getUserCost();
    getRoleCost();
    getCostByType();
    getProdRuns();
    getQueriesCost();
    getTimeoutQueries();
  };

  useEffect(() => {
    refetchAllData();
  }, [timeframe, warehouseName]);

  useEffect(() => {
    getWarehouseNamesForDropdown();
  }, [timeframe]);

  return (
    <AutoLayout
      direction="vertical"
      padding="none"
      verticalGap="dense"
      distribution="packed"
    >
      <FilterRow
        dropdowns={[
          {
            value: timeframe,
            options: timeframes,
            onChange: setTimeframe,
            name: 'timeframe',
            title: 'Select date range',
          },
          {
            value: warehouseName,
            options: formatWarehouseNamesForDropdown(warehouseNamesForDropdown),
            onChange: setWarehouseName,
            isLoading: isLoadingWarehouseNamesForDropdown,
            name: 'warehouseName',
            title: 'Select warehouse',
          },
        ]}
      />
      <div style={{
        display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gap: '16px',
      }}
      >
        <HalfPageChartSection>
          <AreaChart
            title="Snowflake daily remaining balance"
            subtitle="Monitor your daily account remaining balance."
            data={formatSnowflakeBalanceForChart(snowflakeBalanceData).data}
            xAxisLabels={getSnowflakeBalanceXAxisLabels(snowflakeBalanceData)}
            xAxisLabelFormatter={(tick) => DateTime.fromISO(tick).toFormat('LLL d')}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            legendLabels={formatSnowflakeBalanceForChart(snowflakeBalanceData).statuses}
            xLabel="Calendar date"
            yLabel="Daily balance in $"
            isLoading={isLoadingSnowflakeBalance}
            hasNoData={snowflakeBalanceError || snowflakeBalanceData?.cube?.length === 0}
            xAxisDomain={[
              0.5,
              getSnowflakeBalanceXAxisLabels(snowflakeBalanceData).length + 1,
            ]}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
            showDetailsButton
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <StackedBarChart
            title="Daily Warehouse spend"
            subtitle="Monitor daily Warehouse spend based on total query cost (Compute + cloud Services costs)."
            data={formatWarehouseSpendForChart(warehouseSpendData).data}
            xAxisLabels={getWarehouseSpendXAxisLabels(warehouseSpendData)}
            xAxisLabelFormatter={(tick) => DateTime.fromISO(tick).toFormat('LLL d')}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            legendLabels={(
              formatWarehouseSpendForChart(warehouseSpendData).warehouseNames as string[]
            )}
            xLabel="Calendar date"
            yLabel="Daily balance in $"
            isLoading={isLoadingWarehouseSpend}
            hasNoData={warehouseSpendError || warehouseSpendData?.cube?.length === 0}
            xAxisDomain={[
              0.5,
              getWarehouseSpendXAxisLabels(warehouseSpendData).length + 1,
            ]}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
            showDetailsButton
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <GroupedBarChart
            title="Average daily cost by type"
            subtitle="Monitor your daily snowflake average costs by service type."
            data={formatDailyCostForChart(dailyCostData)}
            xAxisLabels={getDailyCostXAxisLabels(dailyCostData)}
            xAxisLabelFormatter={(tick) => DateTime.fromISO(tick).toFormat('LLL d')}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            legendLabels={['Snowflake cloud services cost', 'Snowflake compute cost']}
            xLabel="Calendar date"
            yLabel="Average cost $"
            isLoading={isLoadingDailyCost}
            hasNoData={dailyCostError || dailyCostData?.cube?.length === 0}
            xAxisDomain={[
              0.5,
              getDailyCostXAxisLabels(dailyCostData).length + 1,
            ]}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
            showDetailsButton
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <LineChart
            title="Average hourly query cost"
            subtitle="Monitor hourly query costs in your account and identify peak times."
            data={formatHourlyCostForChart(hourlyCostData)}
            xAxisLabels={getHourlyCostXAxisLabels(hourlyCostData)}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            xLabel="Hour of the day"
            yLabel="Avg. query cost $"
            isLoading={isLoadingHourlyCost}
            hasNoData={hourlyCostError || hourlyCostData?.cube?.length === 0}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
            showDetailsButton
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <StackedBarChart
            title="Query spend by user"
            subtitle="Monitor users with most credits spent."
            data={formatUserCostForChart(userCostData)}
            xAxisLabels={getUserCostXAxisLabels(userCostData)}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            xLabel="Snowflake username"
            yLabel="Query cost ($)"
            isLoading={isLoadingUserCost}
            hasNoData={userCostError || userCostData?.cube?.length === 0}
            maxCharsAxisX={6}
            xAxisDomain={[
              0.5,
              getUserCostXAxisLabels(userCostData).length + 1,
            ]}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
            showDetailsButton
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <StackedBarChart
            title="Query spend by role"
            subtitle="Monitor roles and their usage based on query spend."
            data={formatRoleCostForChart(roleCostData)}
            xAxisLabels={getRoleCostXAxisLabels(roleCostData)}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            xLabel="Snowflake role name"
            yLabel="Query cost ($)"
            isLoading={isLoadingRoleCost}
            hasNoData={roleCostError || roleCostData?.cube?.length === 0}
            maxCharsAxisX={6}
            xAxisDomain={[
              0.5,
              getRoleCostXAxisLabels(roleCostData).length + 1,
            ]}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
            showDetailsButton
          />
        </HalfPageChartSection>
        <FullPageChartSection style={{ height: '100%' }}>
          <TableChart
            title="Snowflake Timeout queries"
            subtitle="Identify timed out queries and the occurred Snowflake cost. Use the 'snowflake_query_id' field to explore more in Snowflake Query History UI"
            isLoading={isLoadingTimeoutQueries}
            hasNoData={timeoutQueriesError || timeoutQueriesData?.cube?.length === 0}
            columns={timeoutQueriesColumns}
            data={formatTimeoutQueriesForChart(timeoutQueriesData)}
            rowExpansionTemplate={(row) => (
              <div style={{ height: '80px', width: '100%' }}>
                <CodeSnippetCopy codeSnippet={row.snowflakeErrorMessage} language="json" fixedHeight="80px" />
              </div>
            )}
            rowHasExpander={(data) => data?.snowflakeErrorMessage}
            rowsPerPage={5}
          />
        </FullPageChartSection>
        <HalfPageChartSection>
          <StackedBarChart
            title="dbt™ query cost by type"
            subtitle="Identify how the type of dbt™ nodes executed impacts on your Snowflake costs."
            data={formatCostByTypeForChart(costByTypeData)}
            xAxisLabels={getCostByTypeXAxisLabels(costByTypeData)}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            xLabel="dbt™ resource type"
            yLabel="Total query cost ($)"
            isLoading={isLoadingCostByType}
            hasNoData={costByTypeError || costByTypeData?.cube?.length === 0}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
            xAxisDomain={[
              0.5,
              getCostByTypeXAxisLabels(costByTypeData).length + 1,
            ]}
            showDetailsButton
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <StackedBarChart
            title="Daily dbt™ production runs cost"
            subtitle="Monitor your dbt™ production runs costs"
            data={formatProdRunsForChart(prodRunsData).data}
            xAxisLabels={getProdRunsXAxisLabels(prodRunsData)}
            xAxisLabelFormatter={(tick) => DateTime.fromISO(tick).toFormat('LLL d')}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            legendLabels={(
              formatProdRunsForChart(prodRunsData).scheduleNames as string[]
            )}
            xLabel="Calendar date"
            yLabel="Total query cost $"
            isLoading={isLoadingProdRuns}
            hasNoData={prodRunsError || prodRunsData?.cube?.length === 0}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
            xAxisDomain={[
              0.5,
              getProdRunsXAxisLabels(prodRunsData).length + 1,
            ]}
            showDetailsButton
          />
        </HalfPageChartSection>
        <FullPageChartSection style={{ height: '100%' }}>
          <TableChart
            title="dbt™ queries cost analysis"
            isLoading={isLoadingQueriesCost}
            hasNoData={queriesCostError || queriesCostData?.cube?.length === 0}
            columns={queriesCostColumns}
            data={formatQueriesCostForChart(queriesCostData)}
            rowsPerPage={5}
          />
        </FullPageChartSection>
      </div>
    </AutoLayout>
  );
};

export default SnowflakeTab;
