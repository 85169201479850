import { useState } from 'react';
import { DateTime } from 'luxon';
import {
  useWorkspaceMetadataQuery,
} from '../../../../client/generated/db';
import { genericObject } from '../../../../utilis';

type WorkspaceMetadataItemType = {
  name: string,
  createdDttm: string,
  userCount: number,
  dbtVersion: string,
}

interface WorkspaceMetadata {
  [id: string]: WorkspaceMetadataItemType,
}

const useGetWorkspaceMetadata = () => {
  const [workspaceMetadata, setWorkspaceMetadata] = useState<WorkspaceMetadata>();
  const {
    loading: workspaceMetadataLoading,
  } = useWorkspaceMetadataQuery({
    onCompleted: ({ workspaceMemberships, workspaces, paradimeAppConfigs }) => {
      const userCountByWorkspace: genericObject = {};
      workspaceMemberships?.groupedAggregates?.forEach((workspace) => {
        if (workspace.keys && workspace.keys.length > 0) {
          userCountByWorkspace[workspace.keys[0]] = workspace.distinctCount?.userId as number;
        }
      });

      const dbtVersionByWorkspace: genericObject = {};
      paradimeAppConfigs?.edges.forEach((workspace) => {
        if (workspace.node) {
          dbtVersionByWorkspace[workspace.node.workspaceId] = workspace.node.value;
        }
      });

      if (workspaceMemberships && workspaces && paradimeAppConfigs) {
        const metadata: WorkspaceMetadata = {};
        workspaces?.nodes.forEach((workspace) => {
          metadata[String(workspace.uid)] = {
            name: workspace.name,
            createdDttm: DateTime.fromISO(
              workspace.createdDttm,
            ).toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS),
            userCount: userCountByWorkspace[workspace.id],
            dbtVersion: dbtVersionByWorkspace[workspace.id],
          };
        });
        setWorkspaceMetadata(metadata);
      }
    },
  });

  return {
    workspaceMetadataLoading,
    workspaceMetadata,
  };
};

export { useGetWorkspaceMetadata };
