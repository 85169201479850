import { useEffect } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { appStore } from '../../stores';

export const useWaitForLDToBeReady = () => {
  const ldIsReady = appStore((s) => s.launchDarklyIsReady);
  const setLdIsReady = appStore((s) => s.setlaunchDarklyIsReady);

  const LDClient = useLDClient();

  /*
    LaunchDarkly initially loads with every flag having a value of "false".
    The goal of this hook is to identify if the value is truely "false", or just hasn't settled yet.
    Ideally we would use the "initialized" event for this purpose.
    However, that fires at least 200ms before the feature flag values stabalise.
    So we wait on the flag values to start changing - a sure sign LaunchDarkly is up and ready to go
  */

  LDClient?.on('change', () => {
    setLdIsReady(true);
  });

  useEffect(() => {
    // Escape hatch in case LD stops firing change events again
    const timer = setTimeout(() => setLdIsReady(true), 1000);

    return () => clearTimeout(timer);
  }, []);

  return { ldIsReady };
};
