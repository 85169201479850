import { IconName } from '@blueprintjs/core';
import { catalogueSearchResultItemProps } from '../types';
import looker from '../../components/Catalog/images/looker.svg';

export const catalogueSearchResultItem = ({
  resultItem,
}: catalogueSearchResultItemProps) => {
  switch (resultItem.__typename) {
    case 'GQLCatalogueLookerDashboardItem':
      return (
        [
          {
            type: 'list' as 'list',
            iconName: 'control' as IconName,
            title: resultItem.name,
            subtitle: 'Dashboard',
            rightImage: looker,
          },
          {
            type: 'description' as 'description',
            description: resultItem.description!,
          },
          { type: 'divider' as 'divider' },
          {
            type: 'textWithIcon' as 'textWithIcon',
            text: resultItem.fsName || '-',
            icon: 'box' as IconName,
          },
        ]
      );
    case 'GQLCatalogueLookerExploreItem':
      return (
        [
          {
            type: 'list' as 'list',
            iconName: 'compass' as IconName,
            title: resultItem.name,
            subtitle: 'Explore',
            rightImage: looker,
          },
          {
            type: 'description' as 'description',
            description: resultItem.description!,
          },
          { type: 'divider' as 'divider' },
          [
            {
              rightComponents: [
                {
                  type: 'tags' as 'tags',
                  tags: resultItem.tags.length > 0 ? resultItem.tags : ['-'],
                },
              ],
            },
          ],
        ]
      );
    case 'GQLCatalogueLookerLookItem':
      return (
        [
          {
            type: 'list' as 'list',
            iconName: 'pie-chart' as IconName,
            title: resultItem.name,
            subtitle: 'Look',
            rightImage: looker,
          },
          {
            type: 'description' as 'description',
            description: resultItem.description!,
          },
          { type: 'divider' as 'divider' },
          {
            type: 'textWithIcon' as 'textWithIcon',
            text: resultItem.modelName || '-',
            icon: 'box' as IconName,
          },
        ]
      );

    default:
      return [];
  }
};
