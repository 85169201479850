import { Dispatch, SetStateAction } from 'react';
import { NodeData as NodeDataAttributes } from '@paradime-io/pragma-ui-kit/lib/components/LineageNode/ElementTypes';
import {
  Node as FlowNode,
  getOutgoers,
  getIncomers,
  Edge as FlowEdge,
} from 'reactflow';
import { graphlib } from 'dagre';

export interface CustomNode {
  name?: string,
  nodeType?: string,
  tags?: string[]
}
export type checkType = {
  label: string | string[],
  checked: boolean
}

export type NodeType = FlowNode<NodeDataAttributes>;
export type LinkType = FlowEdge<NodeDataAttributes>;
export type DagreGraphType = graphlib.Graph<NodeDataAttributes>;

// eslint-disable-next-line import/prefer-default-export
export const handleFilterChange = (
  filterSetter: Dispatch<SetStateAction<string[]>>,
  e: checkType[],
) => {
  filterSetter(() => {
    if (e.filter((item) => item.checked).length === e.length) {
      return ['All selected'];
    }
    return [
      e
        .filter((node) => node.checked)
        .map((node) => node.label[0])
        .join(','),
      e
        .filter((node) => node.checked)
        .map((node) => node.label[1])
        .join(','),
    ];
  });
};

export const AllChecked = (
  types: (string | string[])[],
): checkType[] => types.map((check) => ({ label: check, checked: true }));

interface allOutgoingEdgesProps {
  edges?: LinkType[],
  node: FlowNode,
}
export const allOutgoingEdges = ({
  edges,
  node,
}: allOutgoingEdgesProps): FlowEdge[] => {
  if (edges) {
    return edges.filter((element) => element.source === node.id);
  }
  return [];
};

interface highlightEdgesProps {
  edges?: LinkType[],
  edgesToHighlight: FlowEdge[],
}
export const highlightEdges = ({
  edges,
  edgesToHighlight,
}: highlightEdgesProps): FlowEdge[] => {
  if (edges) {
    return edges.map((targetEdge) => {
      if (edgesToHighlight.some((edge) => edge.id === targetEdge.id)) {
        return { ...targetEdge, animated: true };
      }
      return { ...targetEdge, animated: false };
    });
  }
  return [];
};

interface patchLinksToDeletedNodeProps {
  selectedNode: NodeType,
  nodes: NodeType[],
  edges: LinkType[],
}
export const patchLinksToDeletedNode = ({
  selectedNode,
  nodes,
  edges,
}: patchLinksToDeletedNodeProps) => {
  let bufferEdge = edges;

  const incomingNodes = getIncomers(selectedNode, nodes, edges);
  const childNodes = getOutgoers(selectedNode, nodes, edges);

  incomingNodes.forEach(
    (firstNode) => childNodes.forEach(
      (secondNode) => {
        bufferEdge.push({ source: firstNode.id, target: secondNode.id, id: `${firstNode.id}-${secondNode.id}` });
      },
    ),
  );

  // Remove any edges which were connecting to the deleted node
  bufferEdge = bufferEdge.filter(({ source, target }) => (
    selectedNode.id !== source && selectedNode.id !== target
  ));

  // There seems to be cases of duplicated edges within the array, giving strange visualisations
  // Since the ID of an edge is "${sourceId}-${targetId}",
  // duplicated ID's indicate multiple edges between the same pair of nodes
  // We will never want that, so deduplicate on the ID of the edge
  const deDuplicatedEdges = Array.from(
    new Map(bufferEdge.map((item) => [item.id, item])).values(),
  );

  return { edges: deDuplicatedEdges, nodes };
};

interface filterNodesByStringProps {
  nodes?: NodeType[],
  edges?: LinkType[],
  filter: string,
  filterType: 'nodeType' | 'packageName' | 'tags',
}
export const filterNodesByString = ({
  nodes,
  edges,
  filter,
  filterType,
}: filterNodesByStringProps) => {
  if (!nodes || !edges) return { nodes, edges };

  if (filter.includes('All selected')) {
    return { nodes, edges };
  }

  let nodeBuffer = nodes;
  let edgeBuffer = edges;

  nodes.forEach((node) => {
    let attributeToCheck: string;

    const unknownPackageName = 'unknown_package_name';

    switch (filterType) {
      case 'nodeType':
        attributeToCheck = node.data!.nodeType;
        break;
      case 'packageName':
        attributeToCheck = node.data!.packageName || unknownPackageName;
        break;
      case 'tags':
        attributeToCheck = node.data!.tags.toString();
        break;

      default:
        attributeToCheck = node.data!.nodeType;
        break;
    }

    const isPartOfTheFilters = filter
      .toLowerCase()
      .split(',')
      .some((item) => {
        // If the node doesn't have a package name, don't filter it out
        if (attributeToCheck === unknownPackageName) return true;

        return attributeToCheck.toLowerCase().includes(item);
      });

    const isSelected = node.data?.selected;

    if (!isPartOfTheFilters && !isSelected) {
      const { nodes: patchedNodes, edges: patchedEdges } = patchLinksToDeletedNode({
        selectedNode: node,
        nodes: nodeBuffer.filter(({ data: { id } }) => id !== node.data.id),
        edges: edgeBuffer,
      });

      nodeBuffer = patchedNodes;
      edgeBuffer = patchedEdges;
    }
  });

  return { nodes: nodeBuffer, edges: edgeBuffer };
};

interface filterGraphByNodeIdsProps {
  nodes?: NodeType[],
  edges?: LinkType[],
  nodeIds: string[]
}
const filterGraphByNodesIds = ({
  nodes,
  edges,
  nodeIds,
}: filterGraphByNodeIdsProps) => {
  if (!nodes || !edges) return { nodes, edges };

  let nodesBuffer = nodes;
  let edgesBuffer = edges;

  nodes.forEach((node) => {
    if (nodeIds.includes(node.id)) {
      const {
        nodes: patchedNodes,
        edges: patchedEdges,
      } = patchLinksToDeletedNode({ selectedNode: node, nodes, edges });
      // graphBuffer = removeElements([node], graphBuffer);
      nodesBuffer = patchedNodes;
      edgesBuffer = patchedEdges;
    }
  });

  return { nodes: nodesBuffer, edges: edgesBuffer };
};

interface hideNodesProps {
  nodes: NodeType[],
  edges: LinkType[],
  selectedNode: NodeType,
}

const allParents = ({
  nodes,
  edges,
  selectedNode,
}: hideNodesProps): NodeType[] => {
  const thisParents = getIncomers(selectedNode, nodes, edges);
  const thisGrandParents = thisParents.map((child) => (
    allParents({ nodes, edges, selectedNode: child })
  )).flat();

  return [...thisParents, ...thisGrandParents];
};

const allChildren = ({
  nodes,
  edges,
  selectedNode,
}: hideNodesProps): NodeType[] => {
  const thisChildren = getOutgoers(selectedNode, nodes, edges);
  const thisGrandChildren = thisChildren.map((child) => (
    allChildren({ nodes, edges, selectedNode: child })
  )).flat();

  return [...thisChildren, ...thisGrandChildren];
};

export const hideChildren = ({
  nodes,
  edges,
  selectedNode,
}: hideNodesProps) => (
  allChildren({ nodes, edges, selectedNode })
);

export const hideParents = ({
  nodes,
  edges,
  selectedNode,
}: hideNodesProps) => (
  allParents({ nodes, edges, selectedNode })
);

export const hideNode = ({
  nodes,
  edges,
  selectedNode,
}: hideNodesProps) => (
  filterGraphByNodesIds({ nodes, edges, nodeIds: [selectedNode.id] })
);
