import React, { FC, ReactNode } from 'react';
import { ApolloProvider } from '@apollo/client';
import { graphQLStore } from '../stores';

const AppWithApolloProvider: FC<{children: ReactNode}> = ({ children }) => {
  const apolloClient = graphQLStore((state) => state.apolloClient);

  if (apolloClient) {
    return (
      <ApolloProvider client={apolloClient!}>
        {children}
      </ApolloProvider>
    );
  }

  return <>{children}</>;
};

export default AppWithApolloProvider;
