import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {"context":{"operationType":"editor"}} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type AppIdentity = {
  __typename?: 'AppIdentity';
  environment?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Get the editor URL. */
export type EditorHeartbeat = {
  __typename?: 'EditorHeartbeat';
  editorUrl?: Maybe<Scalars['String']>;
  ok: Scalars['Boolean'];
  started: Scalars['Boolean'];
  updateAvailable: Scalars['Boolean'];
};

export type GqlMutation = {
  __typename?: 'GQLMutation';
  /** Get the editor URL. */
  editorHeartbeat?: Maybe<EditorHeartbeat>;
};


export type GqlMutationEditorHeartbeatArgs = {
  hardReset?: InputMaybe<Scalars['Boolean']>;
  updateIfPossible?: InputMaybe<Scalars['Boolean']>;
};

export type GqlQuery = {
  __typename?: 'GQLQuery';
  /** The app identity */
  appIdentity?: Maybe<AppIdentity>;
  /** Your identity */
  userIdentity?: Maybe<UserIdentity>;
};

export type UserIdentity = {
  __typename?: 'UserIdentity';
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type EditorHeartbeatMutationVariables = Exact<{ [key: string]: never; }>;


export type EditorHeartbeatMutation = { __typename?: 'GQLMutation', editorHeartbeat?: { __typename?: 'EditorHeartbeat', ok: boolean, editorUrl?: string | null, started: boolean } | null };

export type RestartEditorMutationVariables = Exact<{ [key: string]: never; }>;


export type RestartEditorMutation = { __typename?: 'GQLMutation', editorHeartbeat?: { __typename?: 'EditorHeartbeat', ok: boolean } | null };


export const EditorHeartbeatDocument = gql`
    mutation editorHeartbeat {
  editorHeartbeat {
    ok
    editorUrl
    started
  }
}
    `;
export type EditorHeartbeatMutationFn = Apollo.MutationFunction<EditorHeartbeatMutation, EditorHeartbeatMutationVariables>;

/**
 * __useEditorHeartbeatMutation__
 *
 * To run a mutation, you first call `useEditorHeartbeatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditorHeartbeatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editorHeartbeatMutation, { data, loading, error }] = useEditorHeartbeatMutation({
 *   variables: {
 *   },
 * });
 */
export function useEditorHeartbeatMutation(baseOptions?: Apollo.MutationHookOptions<EditorHeartbeatMutation, EditorHeartbeatMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditorHeartbeatMutation, EditorHeartbeatMutationVariables>(EditorHeartbeatDocument, options);
      }
export type EditorHeartbeatMutationHookResult = ReturnType<typeof useEditorHeartbeatMutation>;
export type EditorHeartbeatMutationResult = Apollo.MutationResult<EditorHeartbeatMutation>;
export type EditorHeartbeatMutationOptions = Apollo.BaseMutationOptions<EditorHeartbeatMutation, EditorHeartbeatMutationVariables>;
export const RestartEditorDocument = gql`
    mutation restartEditor {
  editorHeartbeat(hardReset: true) {
    ok
  }
}
    `;
export type RestartEditorMutationFn = Apollo.MutationFunction<RestartEditorMutation, RestartEditorMutationVariables>;

/**
 * __useRestartEditorMutation__
 *
 * To run a mutation, you first call `useRestartEditorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestartEditorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restartEditorMutation, { data, loading, error }] = useRestartEditorMutation({
 *   variables: {
 *   },
 * });
 */
export function useRestartEditorMutation(baseOptions?: Apollo.MutationHookOptions<RestartEditorMutation, RestartEditorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RestartEditorMutation, RestartEditorMutationVariables>(RestartEditorDocument, options);
      }
export type RestartEditorMutationHookResult = ReturnType<typeof useRestartEditorMutation>;
export type RestartEditorMutationResult = Apollo.MutationResult<RestartEditorMutation>;
export type RestartEditorMutationOptions = Apollo.BaseMutationOptions<RestartEditorMutation, RestartEditorMutationVariables>;