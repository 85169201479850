/* eslint-disable no-restricted-globals */
/* eslint-disable no-case-declarations */
/* eslint-disable max-len */
import LineageNode from '@paradime-io/pragma-ui-kit/lib/components/LineageNode';
import React, {
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  ReactNode,
} from 'react';
import {
  Position,
  useReactFlow,
} from 'reactflow';
import { Node as DagreNode } from 'dagre';
import { useHistory, useParams } from 'react-router-dom';
import { colorType } from '@paradime-io/pragma-ui-kit/lib/components/styles/styleTypes';
import { Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { NodeData as NodeDataAttributes } from '@paradime-io/pragma-ui-kit/lib/components/LineageNode/ElementTypes';
import { userAuthStore, lineageStore } from '../../stores';
import {
  GetGitBranchInfoQuery,
  useGetGitBranchInfoQuery,
} from '../../client/generated/service';
import {
  useBranchDiffLineageLazyQuery,
  useComputeLineageLazyQuery,
  useSearchLineageLazyQuery,
  ComputeLineageQuery,
  BranchDiffLineageQuery,
  GqlLineageNodeWithParents,
} from '../../client/generated/service-dataEndpoint';
import { createGraphWithNodeName } from './dagre';
import ContextMenu from './LineageContextMenu';
import {
  NodeType,
  DagreGraphType,
  LinkType,
} from './utils';
import { integrationHandlerManager } from '../../integrations';
import { userHasLineageCompareAccess } from '../../utilis/PermissionsService';
import useListWorkspaces from '../Platform/Workspaces/hooks/useListWorkspaces';

export interface lineageParams {
  id: 'home' | 'node' | 'model' | 'compare' | 'node-name',
  param1: string,
  param2: string,
  node2Hash: string,
  node1Hash: string,
}

export type LineageMode = 'click' | 'contextmenu';

export const nodeTypes = {
  custom: LineageNode,
};

export interface initialPositionType {
  x: number,
  y: number,
  zoom: number
}

export const useUpdateGraph = (
  setGraphy: Dispatch<SetStateAction<DagreGraphType | undefined>>,
  lineageData: ComputeLineageQuery | BranchDiffLineageQuery,
  initialPosition: initialPositionType,
) => {
  const { setCenter, project } = useReactFlow();

  useEffect(() => {
    setGraphy(
      createGraphWithNodeName(lineageData),
    );
  }, [lineageData, setGraphy]);

  useEffect(() => {
    setCenter(
      initialPosition.x,
      initialPosition.y,
      { zoom: 1.5 },
    );
  }, [project, initialPosition, setCenter]);
};

const getNodeDisplayProperties = ({
  node,
  lineageData,
}: {
  node: DagreNode<NodeDataAttributes>,
  lineageData?: Omit<GqlLineageNodeWithParents, 'aliasNames'>,
}) => {
  let nodeDisplayProperties = {
    nodePrimaryLogo: <></> as ReactNode,
    nodeColor: 'default' as colorType,
    nodeTypeName: '',
  };
  // Integration API items have an integration name
  // Classic integrations, e.g. dbt, looker, tableau, etc. have a nodeType
  const chosenHandler = integrationHandlerManager(lineageData?.integrationName || node.nodeType);
  if (chosenHandler) {
    nodeDisplayProperties = chosenHandler.handlers.lineageNodeDisplay({
      nodeType: node.nodeType,
      lineageData,
      nodeId: node.id,
    });
  }

  return nodeDisplayProperties;
};

interface parseDagreToFlowProps {
  graphFromDagre: DagreGraphType,
  lineageType: 'search' | 'compare',
  nodeId?: string,
  filePath?: string,
  context: Contexts,
  onlyNeighbors?: boolean,
  lineageData?: ComputeLineageQuery,
}

export const parseDagreToFlow = ({
  graphFromDagre,
  lineageType,
  nodeId,
  filePath,
  context,
  onlyNeighbors,
  lineageData,
}: parseDagreToFlowProps) => {
  let initialPosition = {
    x: 0,
    y: 0,
    zoom: 1,
  };

  const openFileForNode = (id: string) => {
    window.postMessage({
      type: 'node-to-open',
      payload: {
        node: graphFromDagre.node(id).filePath,
      },
    }, '*');
  };

  const openDocsForNode = (id: string, name: string) => {
    window.postMessage({
      type: 'open-documentation-for-node',
      payload: {
        id,
        name,
      },
    }, '*');
  };

  const nodes: NodeType[] = graphFromDagre.nodes()
    .filter((node) => {
      const nodeExists = graphFromDagre.node(node);
      /** if we're not looking for neighbors just check
       * if the node exists in the graph
      */
      if (!onlyNeighbors) return nodeExists;

      /** if we are looking for neighbors, check if the node
       * is a neighbor of the node we are looking for
       */
      const newNode = graphFromDagre.node(node);
      const isNeighborOrMain = newNode.id === nodeId || graphFromDagre.hasEdge(nodeId || '', newNode.id) || graphFromDagre.hasEdge(newNode.id, nodeId || '');
      return isNeighborOrMain && nodeExists;
    })
    .map((node) => {
      const newNode = graphFromDagre.node(node);
      let selected = false;
      if (lineageType === 'search') {
        if (newNode.filePath === filePath || newNode.id === nodeId) {
          selected = true;
          initialPosition = {
            x: newNode.x,
            y: newNode.y,
            zoom: 1,
          };
        }
      } else if (newNode.nodeChanged) {
        selected = true;
        initialPosition = {
          x: newNode.x,
          y: newNode.y,
          zoom: 0.5,
        };
      }

      const nodeFromLineageData = lineageData?.computeLineage?.results.filter(({ nodeId: ldNodeId }) => ldNodeId === newNode.id)?.[0];

      const nodeDisplayProperties = getNodeDisplayProperties({ node: newNode, lineageData: nodeFromLineageData });

      return {
        id: newNode.id,
        type: 'custom',
        position: {
          x: newNode.x,
          y: newNode.y,
        },
        data: {
          ...newNode,
          integrationName: nodeFromLineageData?.integrationName,
          focussed: selected,
          label: newNode.name,
          nodeName: newNode.nodeName,
          tags: [],
          ...((context === Contexts.EDITOR)
            ? {
              onClick: () => openFileForNode(newNode.id),
              mode: 'click',
              ContextMenu: undefined,
            }
            : {
              ContextMenu,
            }),
          ...((context === Contexts.CATALOG) && {
            onClick: () => openDocsForNode(newNode.id, newNode.name),
            mode: 'click',
            ContextMenu: undefined,
          }),
          ...nodeDisplayProperties,
        },
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
      };
    });

  const links = graphFromDagre.edges()
    .filter((edge) => {
      const edgeExists = graphFromDagre.node(edge.v) && graphFromDagre.node(edge.w);
      /** if we're not looking for neighbors just check
       * if the node exists in the graph
      */
      if (!onlyNeighbors) return edgeExists;

      /** if we are looking for neighbors, check if the node
       * is a neighbor of the node we are looking for
       */
      const isNeighborOrMain = [edge.v, edge.w].includes(nodeId || '');
      return isNeighborOrMain && edgeExists;
    })
    .map((edge) => {
      const source = graphFromDagre.node(edge.v).id;
      const target = graphFromDagre.node(edge.w).id;
      return {
        source, target, id: `${source}-${target}`,
      } as LinkType;
    });

  return {
    elements: [...(nodes), ...links],
    nodes,
    links,
    initialPosition,
  };
};

export const useFormatGraphForFlow = (
  lineageData: ComputeLineageQuery | BranchDiffLineageQuery,
  context: Contexts,
  nodeId?: string,
  filePath?: string,
  onlyNeighbors?: boolean,
) => {
  const [nodes, setNodes] = useState<NodeType[]>();
  const [edges, setEdges] = useState<LinkType[]>();
  const [graphFromDagre, setGraphFromDagre] = useState<DagreGraphType>();
  const setInitialNodes = lineageStore((s) => s.setInitialNodes);
  const setInitialEdges = lineageStore((s) => s.setInitialEdges);

  const [initialPosition, setInitialPosition] = useState({
    x: 0,
    y: 0,
    zoom: 1,
  });

  const lineageType = (lineageData as ComputeLineageQuery).computeLineage?.ok ? 'search' : 'compare';

  useUpdateGraph(setGraphFromDagre, lineageData, initialPosition);

  useEffect(() => {
    setGraphFromDagre(
      createGraphWithNodeName(lineageData),
    );
  }, [lineageData]);

  useEffect(() => {
    if (graphFromDagre) {
      const {
        nodes: parsedNodes,
        links,
        initialPosition: IP,
      } = parseDagreToFlow({
        graphFromDagre,
        lineageType,
        nodeId,
        filePath,
        context,
        onlyNeighbors,
        lineageData,
      });
      setInitialPosition(IP);
      setNodes(parsedNodes);
      setEdges(links);
      setInitialNodes(parsedNodes);
      setInitialEdges(links);
    }
  }, [graphFromDagre]); // eslint-disable-line

  return { initialPosition, nodes, edges };
};

export const useSearchLineage = (
  setLineageData: Dispatch<SetStateAction<BranchDiffLineageQuery
    | ComputeLineageQuery | undefined>>,
  branchesData: GetGitBranchInfoQuery | undefined,
  params: lineageParams,
  setIsEmptyComparison?: Dispatch<SetStateAction<boolean>>,
) => {
  const currentBranch = lineageStore((s) => s.currentBranch);
  const nodeNameFromStore = lineageStore((s) => s.nodeName);
  const currentCommitHash = lineageStore((s) => s.currentCommitHash);
  const nodeHumanReadableName = lineageStore((s) => s.nodeHumanReadableName);
  const setNodeHumanReadableName = lineageStore((s) => s.setNodeHumanReadableName);
  const setLineageLoading = lineageStore((s) => s.setLineageLoading);
  const setDbtPackageNames = lineageStore((s) => s.setDbtPackageNames);
  const { currentWorkspace } = useListWorkspaces();
  const history = useHistory();

  const [
    searchLineage,
    { data: searchData },
  ] = useSearchLineageLazyQuery();
  const [
    computeLineage,
    { data: computeData },
  ] = useComputeLineageLazyQuery();

  const [queryDiff, { data: diffData, loading }] = useBranchDiffLineageLazyQuery();

  useEffect(() => {
    setLineageLoading(loading);
  }, [loading, setLineageLoading]);

  useEffect(() => {
    if (computeData?.computeLineage?.ok && !/home/.test(history.location.pathname)) {
      setLineageData(computeData);
      setDbtPackageNames(computeData.computeLineage.summary.dbtPackageNames);
    }
  }, [computeData]); //eslint-disable-line

  useEffect(() => {
    const showToastInfo = () => {
      setNodeHumanReadableName('');
    };

    if (nodeHumanReadableName && currentCommitHash) {
      showToastInfo();
    }
  }, [nodeHumanReadableName, currentBranch, setNodeHumanReadableName, currentCommitHash, currentWorkspace]);

  useEffect(() => {
    if (currentCommitHash && nodeNameFromStore) {
      computeLineage({
        variables: {
          commitHash: currentCommitHash,
          nodeName: nodeNameFromStore,
        },
      });
    }
  }, [currentCommitHash, nodeNameFromStore, computeLineage]);

  useEffect(() => {
    if (searchData?.searchLineage?.ok && currentCommitHash) {
      const { nodeName, canonicalName: { name } } = searchData.searchLineage.results[0] || { nodeName: '', canonicalName: { name: '' } };
      computeLineage({
        variables: {
          commitHash: currentCommitHash,
          nodeName,
        },
      });
      setNodeHumanReadableName(name);
    }
  }, [searchData, currentCommitHash]);

  useEffect(() => {
    if (diffData?.branchDiffLineage?.ok) {
      if (diffData.branchDiffLineage.results.length === 0 && setIsEmptyComparison) {
        setIsEmptyComparison(true);
      }
      setLineageData(diffData);
    }
  }, [diffData, currentWorkspace]);

  return {
    queryDiff,
    searchLineage,
  };
};

export const useSetBranches = (
  currentPathname: string,
  setIsEmptyComparison?: Dispatch<SetStateAction<boolean>>,
) => {
  const setCurrentBranch = lineageStore((s) => s.setCurrentBranch);
  const setCurrentCommitHash = lineageStore((s) => s.setCurrentCommitHash);
  const setNodeNameInStore = lineageStore((s) => s.setNodeName);
  const nodeIdFromStore = lineageStore((s) => s.nodeId);
  const setNodeIdFromStore = lineageStore((s) => s.setNodeId);
  const params = useParams<lineageParams>();
  const history = useHistory();

  const [filePath, setFilePath] = useState('');
  const [lineageData, setLineageData] = useState<ComputeLineageQuery | BranchDiffLineageQuery>();

  const { data: branchesData } = useGetGitBranchInfoQuery();

  const { queryDiff, searchLineage } = useSearchLineage(setLineageData, branchesData, params, setIsEmptyComparison);

  useEffect(() => {
    if (branchesData) {
      const currentBranchInfo = branchesData
        .getGitBranchInfo!.branches!.find((branch) => branch.commitHash === params.node1Hash);

      setCurrentBranch(
        currentBranchInfo?.name
        || branchesData.getGitBranchInfo?.remoteMainBranch?.name!,
      );

      setCurrentCommitHash(
        currentBranchInfo?.commitHash
        || branchesData.getGitBranchInfo?.remoteMainBranch?.commitHash!,
      );

      switch (params.id) {
        case 'node-name':
          setNodeNameInStore(decodeURIComponent(params.node2Hash));
          break;
        case 'model':
          setNodeIdFromStore('');
          setFilePath(atob(decodeURIComponent(params.node2Hash)));
          searchLineage({
            variables: {
              commitHash: params.node1Hash,
              filePath: atob(decodeURIComponent(params.node2Hash)),
            },
          });
          break;
        case 'node':
          setFilePath('');
          setNodeIdFromStore(atob(decodeURIComponent(params.node2Hash)));
          searchLineage({
            variables: {
              commitHash: params.node1Hash,
              nodeId: atob(decodeURIComponent(params.node2Hash)),
            },
          });
          break;
        case 'compare':
          const { currentUser } = userAuthStore.getState();
          if (!userHasLineageCompareAccess(currentUser.accessLevel)) {
            history.push('/catalog/search');
          } else {
            setFilePath('');
            setNodeIdFromStore('');

            const currentCompareFromBranchInfo = branchesData
              .getGitBranchInfo!.branches!.find((branch) => branch.commitHash === params.node1Hash);
            const currentCompareToBranchInfo = branchesData
              .getGitBranchInfo!.branches!.find((branch) => branch.commitHash === params.node2Hash);

            setCurrentBranch(currentCompareFromBranchInfo?.name || '');
            setCurrentCommitHash(currentCompareToBranchInfo?.commitHash || '');
            queryDiff({
              variables: {
                leftCommitHash: params.node1Hash,
                rightCommitHash: params.node2Hash,
              },
            });
          }
          break;
        default:
          setFilePath('');
          setNodeIdFromStore('');
          setLineageData(undefined);
          break;
      }
    }
  }, [currentPathname, branchesData]); // eslint-disable-line

  return {
    filePath,
    nodeId: nodeIdFromStore,
    lineageData,
  };
};
