import { catalogueSimpleTypeProps } from '../types';

export const catalogueSimpleType = ({
  resultItem,
}: catalogueSimpleTypeProps) => {
  switch (resultItem.__typename) {
    case 'GQLCatalogueIntegrationDatasourceItem':
    case 'GQLCatalogueIntegrationDashboardItem':
    case 'GQLCatalogueIntegrationChartItem':
      return {
        simpleType: resultItem.itemTypeLabel,
        compoundType: '',
      };
    default:
      return {
        simpleType: '',
        compoundType: '',
      };
  }
};
