import { createContext, useState } from 'react';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { graphQLStore } from '../../../../stores';

export const StripeContext = createContext({
  stripeInstance: new Promise((resolve) => resolve(null)) as Promise<Stripe | null>,
});

export const useSetupStripe = () => {
  const [stripeInstance, setStripeInstance] = useState<
    Promise<Stripe | null>
  >(new Promise((resolve) => resolve(null)));

  const clusterConfig = graphQLStore((state) => state.clusterConfig);
  const publishableKey = clusterConfig?.stripePublishableKey;

  const setupStripe = () => {
    if (publishableKey) {
      const stripePromise = loadStripe(publishableKey);
      setStripeInstance(stripePromise);
    }
  };

  const initialContextValue = {
    stripeInstance,
  };

  return { initialContextValue, setupStripe };
};
