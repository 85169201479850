import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { AccountSettingsTab } from '../NavBar';
import AccordionInlineChecklist from './AccordionInlineChecklist';
import { ChecklistItemType } from './AccordionInlineChecklist/ChecklistItem';

export enum RadarSetupSteps {
  SETUP_PROD_WAREHOUSE = 'setup-prod-warehouse-connection',
  SETUP_BOLT_SCHEDULE = 'create-bolt-schedule',
  SETUP_DBTCLOUD_INTEGRATION = 'setup-dbt-cloud-integration',
  SETUP_GITHUB = 'connect-to-github',
  SETUP_SNOWFLAKE_COST_CONNECTION = 'setup-snowflake-cost-connection',
  SETUP_BIGQUERY_COST_CONNECTION = 'setup-bigquery-cost-connection',
  SETUP_LOOKER_CONNECTION = 'setup-looker-connection',
  DIVIDER_OR = 'divider-or',
}

const setupCost = [
  {
    type: 'card' as ChecklistItemType,
    id: RadarSetupSteps.SETUP_SNOWFLAKE_COST_CONNECTION,
    title: 'Setup Snowflake',
    subtitle: 'Cut Snowflake spend and monitor usage across all accounts. This is a required step if you are using Snowflake.',
    primaryButtonText: 'Setup cost connection',
    secondaryButtonText: 'Read docs',
  },
  {
    type: 'divider' as ChecklistItemType,
    id: RadarSetupSteps.DIVIDER_OR,
    title: 'Or',
  },
  {
    type: 'card' as ChecklistItemType,
    id: RadarSetupSteps.SETUP_BIGQUERY_COST_CONNECTION,
    title: 'Setup BigQuery',
    subtitle: 'Monitor and reduce BigQuery cost and usage. This is a required step if you are using BigQuery.',
    primaryButtonText: 'Setup cost connection',
    secondaryButtonText: 'Read docs',
  },
];

const setupWork = [
  {
    type: 'card' as ChecklistItemType,
    id: RadarSetupSteps.SETUP_GITHUB,
    title: 'Connect Github',
    subtitle: 'Add Paradime Github app to dbt repo to increase team performance.',
    primaryButtonText: 'Connect to Github',
    secondaryButtonText: 'Read docs',
  },
];

const setupDbt = [
  {
    type: 'card' as ChecklistItemType,
    id: RadarSetupSteps.SETUP_BOLT_SCHEDULE,
    title: 'Setup Bolt Schedules',
    subtitle: 'Create and run a Bolt schedules in this workspace.',
    primaryButtonText: 'Add new schedule',
    secondaryButtonText: 'Read docs',
  },
  {
    type: 'divider' as ChecklistItemType,
    id: RadarSetupSteps.DIVIDER_OR,
    title: 'Or',
  },
  {
    type: 'card' as ChecklistItemType,
    id: RadarSetupSteps.SETUP_DBTCLOUD_INTEGRATION,
    title: 'Setup dbt Cloud™ Integration ',
    subtitle: 'Import and monitor logs from dbt Cloud™',
    primaryButtonText: 'Setup Integraton',
    secondaryButtonText: 'Read docs',
  },
];

const setupDashboard = [
  {
    type: 'card' as ChecklistItemType,
    id: RadarSetupSteps.SETUP_LOOKER_CONNECTION,
    title: 'Setup Looker',
    subtitle: 'Monitor and reduce cost from Looker usage.',
  },
];

const Setup:FC = () => {
  const history = useHistory();

  const handleButtonClick = (step: RadarSetupSteps, isPrimaryButton: boolean) => {
    switch (step) {
      case RadarSetupSteps.SETUP_BOLT_SCHEDULE:
        if (isPrimaryButton) {
          history.push('/bolt/template-schedules');
          break;
        }

        window.open('https://docs.paradime.io/app-help/documentation/bolt/creating-schedules', '_blank');
        break;
      case RadarSetupSteps.SETUP_DBTCLOUD_INTEGRATION:
        if (isPrimaryButton) {
          history.push(
            `/settings/${AccountSettingsTab.INTEGRATIONS}`,
            { highlightId: 'dbt-cloud-integration' },
          );
          break;
        }

        window.open('https://docs.paradime.io/app-help/guides/dbt-cloud-tm-importer#id-2-connect-paradime-to-dbt-cloud', '_blank');
        break;
      case RadarSetupSteps.SETUP_GITHUB:
        if (isPrimaryButton) {
          history.push(
            `/settings/${AccountSettingsTab.INTEGRATIONS}`,
            { highlightId: 'github-integration' },
          );
          break;
        }

        window.open('https://docs.paradime.io/app-help/documentation/integrations/ci-cd/github', '_blank');
        break;
      case RadarSetupSteps.SETUP_SNOWFLAKE_COST_CONNECTION:
        if (isPrimaryButton) {
          history.push(`/settings/${AccountSettingsTab.CONNECTIONS}`);
          break;
        }

        window.open('https://docs.paradime.io/app-help/documentation/settings/connections/cost-connection/snowflake-connection', '_blank');
        break;
      case RadarSetupSteps.SETUP_BIGQUERY_COST_CONNECTION:
        if (isPrimaryButton) {
          history.push(`/settings/${AccountSettingsTab.CONNECTIONS}`, { highlightId: 'connection-radar' });
          break;
        }

        window.open('https://docs.paradime.io/app-help/documentation/settings/connections/cost-connection/bigquery-cost-connection', '_blank');
        break;
      default:
        break;
    }
  };

  return (
    <AutoLayout
      direction="vertical"
      padding="none"
      distribution="packed"
      alignment="top-left"
      verticalGap="expanded"
    >
      <AutoLayout
        direction="vertical"
        padding="none"
        distribution="packed"
        alignment="top-left"
        verticalGap="very-dense"
      >
        <Typography type="body-bold-small" tagName="span">
          Warehouse Cost
        </Typography>
        <AccordionInlineChecklist
          steps={setupCost}
          onClick={handleButtonClick}
        />
      </AutoLayout>
      <AutoLayout
        direction="vertical"
        padding="none"
        distribution="packed"
        alignment="top-left"
        verticalGap="very-dense"
      >
        <Typography type="body-bold-small" tagName="span">
          dbt™ Monitoring
        </Typography>
        <AccordionInlineChecklist
          steps={setupDbt}
          onClick={handleButtonClick}
        />
      </AutoLayout>
      <AutoLayout
        direction="vertical"
        padding="none"
        distribution="packed"
        alignment="top-left"
        verticalGap="very-dense"
      >
        <Typography type="body-bold-small" tagName="span">
          Team Performance
        </Typography>
        <AccordionInlineChecklist
          steps={setupWork}
          onClick={handleButtonClick}
        />
      </AutoLayout>
      <AutoLayout
        direction="vertical"
        padding="none"
        distribution="packed"
        alignment="top-left"
        verticalGap="very-dense"
      >
        <Typography type="body-bold-small" tagName="span">
          Dashboard Monitoring
        </Typography>
        <AccordionInlineChecklist
          steps={setupDashboard}
          onClick={handleButtonClick}
        />
      </AutoLayout>
    </AutoLayout>
  );
};

export default Setup;
