export enum Teams {
    FINANCE = 'Finance',
    DATA = 'Data',
    SALES_SUCCESS = 'Sales / Success',
    MARKETING = 'Marketing',
    PRODUCT_ENGINEERING = 'Product / Engineering',
    CUSTOMER_SERVICE_SUPPORT = 'Customer Support / Service ',
    OTHER = 'Other'
}
export type AllRoles = DATA_TEAM_ROLES
    | FINANCE_TEAM_ROLES
    | SALES_SUCCESS_TEAM_ROLES
    | MARKETING_TEAM_ROLES
    | PRODUCT_ENGINEERING_ROLES
    | CUSTOMER_SERVICE_SUPPORT_ROLES
    | OTHER_ROLES;

export type RoleTypes = typeof DATA_TEAM_ROLES
    | typeof FINANCE_TEAM_ROLES
    | typeof SALES_SUCCESS_TEAM_ROLES
    | typeof MARKETING_TEAM_ROLES
    | typeof PRODUCT_ENGINEERING_ROLES
    | typeof CUSTOMER_SERVICE_SUPPORT_ROLES
    | typeof OTHER_ROLES;

export enum DATA_TEAM_ROLES {
    ANALYTICS_ENGINEER = 'Analytics Engineer',
    PRODUCT_DATA_ANALYST = 'Product / Data Analyst',
    DATA_ENGINEER = 'Data Engineer',
    PRODUCT_MANAGER = 'Product Manager',
    DATA_SCIENTIST = 'Data Scientist',
    DATA_LEADER = 'Data Leader (Head / Director / VP / C-suite)',
    OTHER = 'Other'
}

export enum FINANCE_TEAM_ROLES {
    CREDIT_ANALYST = 'Credit Analyst',
    INVESTMENT_ANALYST = 'Investment Analyst',
    ACCOUNT_MANAGER = 'Account Manager',
    PRODUCT_MANAGER = 'Product Manager',
    FINANCE_LEADER = 'Finance Leader (Head / Director / VP / C-suite)',
    OTHER = 'Other'
}

export enum SALES_SUCCESS_TEAM_ROLES {
    CUSTOMER_SUCCESS = 'Customer Success',
    SALES_OPERATION = 'Sales Operation',
    SALES_REP = 'Sales Rep',
    SALES_MANAGER = 'Sales Manager',
    SALES_LEADER = 'Sales Leader (Head / Director / VP / C-suite)',
    OTHER = 'Other'
}

export enum MARKETING_TEAM_ROLES {
    MARKETING_OPERATION = 'Marketing Operations',
    PRODUCT_MARKETING = 'Product Marketing',
    DEMAND_GENERATION = 'Demand Generation',
    MARKETING_MANAGER = 'Marketing Manager',
    MARKETING_LEADER = 'Marketing Leader (Head / Director / VP / C-suite)',
    OTHER = 'Other'
}

export enum PRODUCT_ENGINEERING_ROLES {
    ENGINEER = 'Engineer',
    RESEARCHER = 'Researcher',
    DESIGNER = 'Designer',
    PRODUCT_MANAGER = 'Product Manager',
    PRODUCT_ENGINEERING_LEADER = 'Product / Engineering Leader (Head / Director / VP / C-suite)',
    OTHER = 'Other'
}
export enum CUSTOMER_SERVICE_SUPPORT_ROLES {
    SUPPORT_OPERATIONS = 'Support Operations',
    SUPPORT_REP = 'Support Rep',
    SUPPORT_MANAGER = 'Support Manager',
    SUPPORT_LEADER = 'Support Leader (Head / Director / VP / C-Suit)',
    OTHER = 'Other'
}

export enum OTHER_ROLES {
    DATA = 'Data',
    IT = 'IT',
    LEGAL = 'Legal',
    FINANCE = 'Finance',
    LEADER = 'Leader (Head / Director / VP / C-Suit)',
    OTHER = 'Other'
}

export const mapTeamsToRoleObject: Record<Teams, RoleTypes> = {
  [Teams.DATA]: DATA_TEAM_ROLES,
  [Teams.FINANCE]: FINANCE_TEAM_ROLES,
  [Teams.SALES_SUCCESS]: SALES_SUCCESS_TEAM_ROLES,
  [Teams.MARKETING]: MARKETING_TEAM_ROLES,
  [Teams.PRODUCT_ENGINEERING]: PRODUCT_ENGINEERING_ROLES,
  [Teams.CUSTOMER_SERVICE_SUPPORT]: CUSTOMER_SERVICE_SUPPORT_ROLES,
  [Teams.OTHER]: OTHER_ROLES,
};
