import React, {
  createContext,
  FC, useEffect, useMemo, useState, Dispatch, SetStateAction,
} from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Icon } from '@blueprintjs/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import BoltDetailsCard from '@paradime-io/pragma-ui-kit/lib/components/BoltDetailsCard';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Breadcrumbs from '@paradime-io/pragma-ui-kit/lib/components/Breadcrumbs';
import CodeBox from '@paradime-io/pragma-ui-kit/lib/components/CodeBox';
import Chips from '@paradime-io/pragma-ui-kit/lib/components/Chips';
import Loader from '@paradime-io/pragma-ui-kit/lib/components/Loader';
import { DateTime } from 'luxon';
import { colorType } from '@paradime-io/pragma-ui-kit/lib/components/styles/styleTypes';
import Callout from '@paradime-io/pragma-ui-kit/lib/components/Callout';
import { DefaultButton } from '@paradime-io/pragma-ui-kit';
import Tooltip from '@paradime-io/pragma-ui-kit/lib/components/Tooltip';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import {
  formatDateTimeToLocalString, getHumanReadableCron, triggerToast,
} from '../../../utilis';
import { GetScheduleRunQuery, useGetScheduleRunQuery, GqlParadimeAccountType } from '../../../client/generated/service';
import { RunErrorType, GlobalErrorType } from './ScheduleList';
import Errors, { ErrorLevel } from './Errors';
import LogsAndFreshness from './LogsAndFreshness';
import useCancelRunButton from './CancelRunButton';
import { DEFAULT_REFRESH_INTERVAL } from './constants';
import DeferredCallout from './DeferredCallout';
import { userAuthStore } from '../../../stores';
import { BoltHelp } from '../../Common/AppHelp/help';
import NotFoundZeroState from './NotFoundZeroState';
import { userHasBoltCancelScheduleAccess } from '../../../utilis/PermissionsService';
import BoltSchedulesUpgrade from '../../Common/Upgrade/BoltSchedulesUpgrade';
import CreateTicket from './CreateTicket';
import { useWaitForLDToBeReady } from '../../hooks/useWaitForLDToBeReady';
import Insights from './Insights';
import { getOnCompletionRunInfoText, defaultDescription } from './utils';
import RunViewArtifactSQL from './RunViewArtifactSQL';
import SLAExceededIcon from './SLAExceededIcon';

export type Info = NonNullable<GetScheduleRunQuery['getScheduleRun']>['info'];
type Meta = NonNullable<GetScheduleRunQuery['getScheduleRun']>['meta'];
interface Params { scheduleId: string, runUuid: string, runId: string }

export const RunningContext = createContext({
  isRunning: false,
  showArtifactSql: false,
  setShowArtifactSql: undefined as unknown as Dispatch<SetStateAction<boolean>>,
});

const Run: FC = () => {
  const { runUuid, scheduleId: scheduleIdFromParam, runId: runIdFromParam } = useParams<Params>();
  const runIdFromParamInt = Number(runIdFromParam);
  const isRunIdRoute = Boolean(runIdFromParamInt);

  const { ldIsReady } = useWaitForLDToBeReady();
  const { flagAppBolt, flagMergeIntegrations } = useFlags();

  const [info, setInfo] = useState<Info>();
  const [meta, setMeta] = useState<Meta>();
  const [error, setError] = useState<RunErrorType | null>();
  const [globalError, setGlobalError] = useState<GlobalErrorType | null>();
  const [runId, setRunId] = useState<number | undefined>();
  const [shouldFetchAnalytics, setShouldFetchAnalytics] = useState(false);
  const [scheduleIdFromRunId, setScheduleIdFromRunId] = useState<string | null>();
  const [pullRequest, setPullRequest] = useState<{id: string, url: string}>();
  const [runNotFound, setRunNotFound] = useState(false);

  const [showArtifactSql, setShowArtifactSql] = useState(false);

  const { currentUser } = userAuthStore();
  const { accessLevel } = currentUser as { accessLevel: GqlParadimeAccountType };

  const isRoleWhoCanCancelRun = userHasBoltCancelScheduleAccess(accessLevel);

  const history = useHistory();

  const {
    data: runData,
    error: runError,
    refetch: refetchRun,
  } = useGetScheduleRunQuery({
    variables: {
      scheduleNameUuid: scheduleIdFromParam || '',
      scheduleRunUuid: runUuid || '',
      scheduleRunId: Number(runIdFromParam),
    },
  });

  const isRunning = useMemo(() => runData?.getScheduleRun?.commands?.some(
    (command) => command?.state.text === 'Running',
  ), [runData]);

  /** pull every 5 seconds if there isn't an endDttm */
  useEffect(() => {
    if (runData?.getScheduleRun?.ok) {
      if (!runData.getScheduleRun.info.endDttm) {
        setTimeout(refetchRun, DEFAULT_REFRESH_INTERVAL * 1000);
      }
    }
  }, [runData]);

  const {
    CancelRunDialog,
    CancelButton,
  } = useCancelRunButton({
    refetchRunFunction: refetchRun,
  });

  const cancelCalloutConfig: { [key: string]: { title: string, content: string }} = {
    USER: {
      title: 'Cancelled by user',
      content: `This run was manually cancelled by ${info?.cancelledByEmail}`,
    },
    CI: {
      title: 'Cancelled by Turbo CI',
      content: 'This run was cancelled as a results of a new commit being pushed against the Pull Request',
    },
    API: {
      title: 'Cancelled by Paradime API',
      content: 'This run was cancelled using the Paradime API',
    },
    default: {
      title: 'This run was cancelled',
      content: '',
    },
  };

  useEffect(() => {
    if (runError) {
      triggerToast({
        type: 'warning',
        header: 'Error fetching run',
        message: runError.message,
      });
    }
  }, [runError]);

  useEffect(() => {
    if (
      runData?.getScheduleRun?.info
      && runData.getScheduleRun.commands
    ) {
      setRunNotFound(false);

      setInfo(runData.getScheduleRun.info);
      setMeta(runData.getScheduleRun.meta);
      setError(runData.getScheduleRun.errorDetails);
      setGlobalError(runData.getScheduleRun.globalErrorDetails);
      setRunId(runData.getScheduleRun.meta.id);
      setShouldFetchAnalytics(runData.getScheduleRun.shouldRequestRunAnalysis);

      if (
        runData.getScheduleRun.meta?.pullRequestId
        && runData.getScheduleRun.meta?.pullRequestUrl
      ) {
        setPullRequest({
          id: runData.getScheduleRun.meta.pullRequestId,
          url: runData.getScheduleRun.meta.pullRequestUrl,
        });
      }

      if (isRunIdRoute) {
        setScheduleIdFromRunId(runData.getScheduleRun.meta.scheduleNameUUID);
      }
    }

    if (runData && runData.getScheduleRun === null) {
      setRunNotFound(true);
    }
  }, [runData]);

  if (ldIsReady && !flagAppBolt) {
    return <BoltSchedulesUpgrade />;
  }

  if (runNotFound) {
    return <NotFoundZeroState />;
  }

  if (
    info === undefined
    || meta === undefined
  ) {
    return <Loader fit />;
  }

  let minutes = 0;
  let seconds = 0;

  if (info.endDttm && info.startDttm) {
    const startDateTime = DateTime.fromISO(info.startDttm);
    const endDateTime = DateTime.fromISO(info.endDttm);

    ({ minutes, seconds } = endDateTime.diff(startDateTime).shiftTo('minutes', 'seconds'));
  }

  return (
    <>
      <AutoLayout
        direction="vertical"
        padding="none"
        distribution="packed"
        alignment="top-left"
        verticalGap="normal"
        wrapperWidth="full"
      >
        {CancelRunDialog}
        <AutoLayout
          direction="horizontal"
          padding="none"
          distribution="space-between"
          style={{ alignItems: 'center' }}
        >
          <Breadcrumbs items={[
            {
              text: 'Schedules',
              onClick: () => history.push('/bolt'),
            },
            {
              text: (
                <Tooltip content={meta.scheduleNameName} targetTagName="div">
                  <div style={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    maxWidth: '40vw',
                    whiteSpace: 'nowrap',
                  }}
                  >
                    {meta.scheduleNameName}
                  </div>
                </Tooltip>
              ),
              onClick: () => history.push(`/bolt/${isRunIdRoute ? scheduleIdFromRunId : scheduleIdFromParam}`),
            },
            {
              text: `Run ID: ${runId}`,
              disabled: true,
            },
          ]}
          />
          <Chips
            color="primary_alt"
            round={false}
            style={{ margin: '4px', verticalAlign: 'bottom', justifySelf: 'end' }}
            tag="Get help with the UI"
            type="dense"
            view="smooth"
            onClick={() => (
              window.CommandBar.openHelpHub(
                { articleId: BoltHelp.BOLT_HELP_UI },
              )
            )}
          />
        </AutoLayout>
        {
        (info.cancelledByEmail || info.cancelledByActor) && (
          <Callout
            color="warning"
            title={cancelCalloutConfig[info?.cancelledByActor || 'default'].title}
            content={cancelCalloutConfig[info?.cancelledByActor || 'default'].content}
            icon="warning-sign"
            type="dense"
            view="smooth"
            dense
          />
        )
      }
        <AutoLayout
          direction="vertical"
          padding="none"
          distribution="packed"
          verticalGap="very-dense"
        >
          <AutoLayout
            padding="none"
            direction="horizontal"
            distribution="space-between"
          >
            <AutoLayout
              direction="horizontal"
              padding="none"
              distribution="packed"
              horizontalGap="ultra-dense"
            >
              {
              (runData?.getScheduleRun?.meta.pullRequestId) && (
                <Icon size={12} icon="git-branch" color="var(--grey50)" />
              )
            }
              <Typography type="h6" style={{ maxWidth: '700px' }}>{info.name}</Typography>
            </AutoLayout>
            {isRunning
            && isRoleWhoCanCancelRun
            && <CancelButton ID={meta.id} scheduleName={meta.scheduleNameName} />}
          </AutoLayout>
          <Typography type="caption" colorStep="50">
            {runData?.getScheduleName?.description || defaultDescription}
          </Typography>
        </AutoLayout>
        <Errors globalError={globalError} error={error} errorLevel={ErrorLevel.SCHEDULE_RUN} />
        <BoltDetailsCard
          tabIndex={-1}
          components={[
            {
              type: 'row',
              colsMaxWidth: 160,
              items: [
                {
                  type: 'column',
                  showDivider: true,
                  maxWidth: 80,
                  items: [
                    {
                      reactNode: (
                        <AutoLayout
                          direction="horizontal"
                          padding="none"
                          distribution="packed"
                          horizontalGap="very-dense"
                        >
                          <Chips
                            type="dense"
                            tag={info.state.text}
                            color={info.state.colorType as colorType}
                          />
                          <SLAExceededIcon
                            slaWasExceeded={false} // TODO - Need BE update to tell us this overran
                            slaSeconds={runData?.getScheduleName?.slaSeconds}
                          />
                        </AutoLayout>
                      ),
                      type: 'reactNode',
                      vAlign: 'center',
                    },
                  ],
                },
                {
                  type: 'column',
                  items: [
                    {
                      header: 'Run Duration',
                      text: `${Math.floor(minutes)} minutes ${Math.floor(seconds)} seconds`,
                      type: 'textWithHeader',
                      textStyle: 'caption',
                    },
                    {
                      header: 'Owner',
                      text: info.owner || '-',
                      type: 'textWithHeader',
                      textStyle: 'caption',
                      singleLine: true,
                      withTooltip: true,
                      tooltipConfig: {
                        content: info.owner || '-',
                      },
                    },
                  ],
                },
                {
                  type: 'column',
                  items: [
                    {
                      header: 'Triggered by',
                      text: info.parentScheduleRunId && info.scheduleTrigger ? (
                        <>
                          <a href={`bolt/run_id/${info.parentScheduleRunId}`} target="__blank">
                            {info.scheduleTrigger.scheduleName}
                            {' '}
                            {`#${info.parentScheduleRunId}`}
                          </a>
                        </>
                      ) : `${info.actor} ${info.actorEmail ? `(${info.actorEmail})` : ''}`,
                      type: 'textWithHeader',
                      textStyle: 'caption',
                      singleLine: true,
                      withTooltip: true,
                      tooltipConfig: {
                        content: info.parentScheduleRunId && info.scheduleTrigger
                          ? `${info.scheduleTrigger.scheduleName} #${info.parentScheduleRunId}`
                          : `${info.actor} ${info.actorEmail ? `(${info.actorEmail})` : ''}`,
                      },
                    },
                    {
                      header: 'Notify',
                      text: info.toNotify?.filter((v) => v !== '').join(', ') || '-',
                      type: 'textWithHeader',
                      textStyle: 'caption',
                      singleLine: true,
                      withTooltip: true,
                      tooltipConfig: {
                        content: info.toNotify?.filter((v) => v !== '').join(', ') || '-',
                      },
                    },
                  ],
                },
                {
                  type: 'column',
                  items: [
                    {
                      header: 'Branch and commit',
                      text: (
                        <>
                          {info.branch || '-'}
                          {' '}
                          <a href={info.commit.url || '#'} target="__blank">
                            #
                            {info.commit.hash?.substring(0, 5)}
                          </a>
                        </>
                      ),
                      type: 'textWithHeader',
                      textStyle: 'caption',
                    },
                    {
                      header: 'Status',
                      text: runData?.getScheduleName?.suspended ? 'Paused' : 'Active',
                      type: 'textWithHeader',
                      textStyle: 'caption',
                    },
                  ],
                },
                {
                  type: 'column',
                  items: [
                    {
                      header: 'Pull Request',
                      text: pullRequest
                        ? (
                          <a href={pullRequest.url} target="__blank">
                            PR #
                            {pullRequest.id}
                          </a>
                        ) : '-',
                      type: 'textWithHeader',
                      textStyle: 'caption',
                    },
                    {
                      header: 'Deferred',
                      text: info.deferredDetails ? (
                        <AutoLayout
                          direction="vertical"
                          distribution="packed"
                          padding="none"
                          verticalGap="super-ultra-dense"
                        >
                          <Typography tagName="span" type="caption">
                            from:
                            {' '}
                            <Link
                              to={`/bolt/${info.deferredDetails.scheduleNameUuid}`}
                              style={{ color: 'var(--deeplink-blue)' }}
                              target="_blank"
                            >
                              {info.deferredDetails.scheduleName}
                            </Link>
                          </Typography>
                          <Typography tagName="span" type="caption">
                            id:
                            {' '}
                            <Link
                              to={`/bolt/run_id/${info.deferredDetails.scheduleRunId}`}
                              style={{ color: 'var(--deeplink-blue)' }}
                              target="_blank"
                            >
                              #
                              {info.deferredDetails.scheduleRunId}
                            </Link>
                          </Typography>
                        </AutoLayout>
                      ) : '-',
                      type: 'textWithHeader',
                      textStyle: 'caption',
                    },
                  ],
                },
                {
                  type: 'column',
                  items: [
                    {
                      header: 'SLA',
                      text: runData?.getScheduleName?.slaSeconds && runData?.getScheduleName?.slaSeconds !== 0 ? `${runData.getScheduleName.slaSeconds / 60} minutes` : 'Not active',
                      type: 'textWithHeader',
                      textStyle: 'caption',
                    },
                  ],
                },
              ],
            },
          ]}
        />
        <AutoLayout
          direction="horizontal"
          padding="none"
          distribution="space-between"
          style={{ alignItems: 'center', marginTop: '40px' }}
        >
          <Typography type="h6" tagName="span">Schedule run details</Typography>
          <AutoLayout
            direction="horizontal"
            padding="none"
            distribution="packed"
            horizontalGap="very-dense"
            style={{ alignItems: 'center' }}
          >
            {
            pullRequest && (
              <DefaultButton
                type="standard"
                view="outlined"
                color="default"
                icon="git-pull"
                dense
                text={`Go to Pull Request #${pullRequest.id}`}
                onClick={() => window.open(pullRequest.url, '_blank')}
                eventContext={Contexts.BOLT}
                eventObject="openPullRequest"
                eventAction={Actions.CLICKED}
              />
            )
          }
            {flagMergeIntegrations && <CreateTicket runId={runId} runData={runData} />}
          </AutoLayout>
        </AutoLayout>
        <BoltDetailsCard
          tabIndex={-1}
          components={[
            {
              type: 'row',
              colsMaxWidth: 240,
              items: [
                {
                  type: 'column',
                  items: [
                    {
                      header: 'Last run',
                      text: formatDateTimeToLocalString({ dateTimeString: info.startDttm }),
                      type: 'textWithHeader',
                      textStyle: 'caption',
                    },
                  ],
                },
                {
                  type: 'column',
                  items: [
                    {
                      header: 'Completed',
                      text: formatDateTimeToLocalString({
                        dateTimeString: info.endDttm,
                      }),
                      type: 'textWithHeader',
                      textStyle: 'caption',
                    },
                  ],
                },
                {
                  type: 'column',
                  items: [
                    info.scheduleTrigger
                      ? {
                        type: 'textWithHeader',
                        header: 'On run completion configuration',
                        showDivider: true,
                        text: getOnCompletionRunInfoText(
                          info.scheduleTrigger,
                        ),
                        textStyle: 'caption',
                        singleLine: true,
                        withTooltip: true,
                        tooltipConfig: {
                          content: getOnCompletionRunInfoText(
                            info.scheduleTrigger,
                          ),
                        },
                      } : {
                        type: 'textWithHeader',
                        header: 'Cron configuration',
                        showDivider: true,
                        text: info.schedule ? getHumanReadableCron(info.schedule) : '-',
                        textStyle: 'caption',
                        singleLine: true,
                        withTooltip: true,
                        tooltipConfig: {
                          content: info.schedule ? getHumanReadableCron(info.schedule) : '-',
                        },
                      },
                  ],
                },
              ],
            },
            {
              type: 'row',
              showDivider: true,
              items: [
                {
                  type: 'column',
                  items: [
                    {
                      fill: true,
                      header: 'Commands',
                      text: (
                        <CodeBox
                          backgroundColor="var(--grey0)"
                          colorStep="60"
                          lines={info.commands?.map((l) => `${l}`) || []}
                          maxHeight={500}
                        />
                      ),
                      type: 'textWithHeader',
                    },
                  ],
                },
              ],
            },
          ]}
          slim
          style={{
            alignItems: 'stretch',
            justifyContent: 'stretch',
          }}
        />
        {info.deferredDetails && <DeferredCallout deferredDetails={info.deferredDetails} />}
        {runId && (
        <Insights
          runId={runId}
          isRunning={isRunning}
          shouldFetchAnalytics={shouldFetchAnalytics}
          endDttm={info.endDttm}
          runState={info.state.text}
          runData={runData}
        />
        )}
        <RunningContext.Provider
          value={{
            isRunning: isRunning || false,
            showArtifactSql,
            setShowArtifactSql,
          }}
        >
          <LogsAndFreshness
            runData={runData}
            refreshData={refetchRun}
          />
        </RunningContext.Provider>
      </AutoLayout>
      <RunViewArtifactSQL
        isVisible={showArtifactSql}
        onHide={() => setShowArtifactSql(false)}
      />
    </>
  );
};

export default Run;
