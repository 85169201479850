import { IconName } from '@blueprintjs/core';
import { catalogueNameAndIconProps } from '../types';

export const catalogueNameAndIcon = ({
  resultItem,
}: catalogueNameAndIconProps) => {
  switch (resultItem.__typename) {
    case 'GQLCatalogueIntegrationDatasourceItem':
    case 'GQLCatalogueIntegrationDashboardItem':
    case 'GQLCatalogueIntegrationChartItem':
      return { icon: resultItem.itemTypeIconName as IconName, name: resultItem?.name || '-' };
    default:
      return { icon: 'error' as IconName, name: 'error' };
  }
};
