import React, { FC } from 'react';
import { Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import UpgradeTemplate from './UpgradeTemplate';
import { userAuthStore } from '../../../stores';
import { userHasAccountSettingsTeamsAdminAccess } from '../../../utilis/PermissionsService';
import AuditLogsImage from './images/AuditLogs.svg';

const AuditLogsUpgrade: FC = () => {
  const { currentUser: { accessLevel } } = userAuthStore.getState();
  const isAdmin = userHasAccountSettingsTeamsAdminAccess(accessLevel);

  const upgradePlanName = 'Security add-on';

  return (
    <UpgradeTemplate
      isAdmin={isAdmin}
      upgradePlanName={upgradePlanName}
      header="Unlock Audit Logs"
      bodyText={(
        <>
          Upgrade to view Audit logs or programatically extract Audit logs using the Paradime API.
          <br />
          <br />
          Access logs of all administrative actions performed in your account like user, warehouse,
          integration and bolt schedules management.
        </>
        )}
      buttonClickEventContext={Contexts.ACCOUNT_SETTINGS}
      featureText={upgradePlanName}
      docsUrl="https://docs.paradime.io/app-help/app-settings/audit-logs"
      upgradeImage={AuditLogsImage}
      needsSupportToEnable
    />
  );
};

export default AuditLogsUpgrade;
