import React, {
  FC, Dispatch, SetStateAction,
} from 'react';
import Checkbox from '@paradime-io/pragma-ui-kit/lib/components/CheckBox';
import { Menu } from '@paradime-io/pragma-ui-kit/lib/components/Menu';
import MenuDivider from '@paradime-io/pragma-ui-kit/lib/components/MenuDivider';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { handleFilterChange, checkType } from '../utils';

export interface PackageFilterProps {
  packageChecks: checkType[],
  setPackageChecks: Dispatch<SetStateAction<checkType[]>>,
  setPackagesFilter: Dispatch<React.SetStateAction<string[]>>,
  expose?: (nodes: checkType[]) => void // eslint-disable-line
}

const PackageFilter: FC<PackageFilterProps> = ({
  packageChecks,
  setPackageChecks,
  setPackagesFilter,
}) => {
  const [type, view, color] = ['dense', 'raised', 'default'] as any[];

  const handleClick = (label: string) => {
    setPackageChecks((current) => current.map((item) => {
      if (item.label[0] === label) {
        return { ...item, checked: !item.checked };
      }
      return item;
    }));
  };

  const submitText = () => {
    const packagesNodesActive = packageChecks.filter((item) => item.checked).length;
    const typePluralized = packagesNodesActive === 1 ? 'type' : 'types';
    return `select ${packagesNodesActive} node ${typePluralized}`;
  };

  return (
    <div style={{ maxWidth: 300 }}>
      <Menu type={type} view={view} color={color}>
        <MenuDivider
          color={color}
          title="PACKAGES"
          type="dense"
          view="filled"
        />
        {packageChecks.map((item) => (
          <Checkbox
            key={`${item.label[0]}-checkbox`}
            label={item.label[0] as string}
            view="smooth"
            fill
            type="dense"
            style={{
              color: 'var(--grey100)',
              margin: '2px 16px',
            }}
            onChange={() => handleClick(item.label[0])}
            checked={item.checked}
            eventContext={Contexts.LINEAGE}
            eventObject="packageFilterCheckbox"
            eventAction={Actions.CLICKED}
            eventProperties={{ filterItem: item.label[0] }}
          />
        ))}

        <MenuDivider
          color="default"
          type="def"
          view="smooth"
        />
        <li
          style={{
            paddingBottom: 16,
            display: 'grid',
            placeItems: 'center',
            height: 'unset',
          }}
        >
          <DefaultButton
            text={submitText()}
            dense
            color="primary"
            view="filled"
            fill
            style={{
              width: 'calc(100% - 32px)',
            }}
            onClick={() => {
              handleFilterChange(
                setPackagesFilter,
                packageChecks,
              );

              // close dropdown after user selects a filter
              const resetButton = document.querySelector('[data-testid="filterDropdown"]') as HTMLButtonElement;
              resetButton?.click();
            }}
            eventContext={Contexts.LINEAGE}
            eventObject="packages"
            eventAction={Actions.FILTERED}
          />
        </li>
      </Menu>
    </div>
  );
};

export default PackageFilter;
