import { useHistory } from 'react-router-dom';
import { CommandItemProps } from '../CommandItem';
import { RadarTab } from '../../../../../components/Radar/config';

const useGetRadarCommands = () => {
  const history = useHistory();

  const radarCommands = [
    {
      icon: 'stopwatch',
      children: 'Schedules',
      nested: false,
      onSelect: () => history.push(`/radar/${RadarTab.SCHEDULES}`),
    },
    {
      icon: 'one-to-many',
      children: 'Models',
      nested: false,
      onSelect: () => history.push(`/radar/${RadarTab.MODELS}`),
    },
    {
      icon: 'layout-grid',
      children: 'Sources',
      nested: false,
      onSelect: () => history.push(`/radar/${RadarTab.SOURCES}`),
    },
    {
      icon: 'doughnut-chart',
      children: 'Tests',
      nested: false,
      onSelect: () => history.push(`/radar/${RadarTab.TESTS}`),
    },
    {
      icon: 'form',
      children: 'Work',
      nested: false,
      onSelect: () => history.push(`/radar/${RadarTab.WORK}`),
    },
    {
      icon: 'dollar',
      children: 'Costs',
      nested: false,
      onSelect: () => history.push(`/radar/${RadarTab.COST}`),
    },
    {
      icon: 'timeline-bar-chart',
      children: 'Looker',
      nested: false,
      onSelect: () => history.push(`/radar/${RadarTab.BI_LOOKER}`),
    },
    {
      icon: 'pulse',
      children: 'Data Alerts',
      nested: false,
      onSelect: () => history.push(`/radar/${RadarTab.ALERTS}`),
    },
  ] as CommandItemProps[];

  return {
    radarCommands,
  };
};

export { useGetRadarCommands };
