import React from 'react';
import { catalogueTreeIconsProps } from '../types';
import BigQueryLogo from '../../components/Catalog/images/bigquery.svg';
import RedshiftLogo from '../../components/Catalog/images/redshift.svg';
import SnowflakeLogo from '../../components/Catalog/images/snowflake.svg';
import FireboltLogo from '../../components/Catalog/images/firebolt.svg';

export const catalogueTreeIcons = ({ type }: catalogueTreeIconsProps) => {
  switch (type) {
    case 'table':
      return 'th';
    case 'dashboard':
      return 'control';
    case 'column':
      return 'th-list';
    case 'dwh:bigquery':
      return <img src={BigQueryLogo} alt="" />;
    case 'dwh:redshift':
      return <img src={RedshiftLogo} alt="" />;
    case 'dwh:snowflake':
      return <img src={SnowflakeLogo} alt="" />;
    case 'dwh:firebolt':
      return <img src={FireboltLogo} alt="" />;
    default:
      return 'database';
  }
};
