import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import PageTitle, { PageTitles } from '../../PageTitle';
import ConnectIntegrationsButtons from './ConnectIntegrationsButtons';
import NotificationCard from './NotificationCard';
import { userHasAccountSettingsNotificationsEditAccess } from '../../../utilis/PermissionsService';
import { companyStore, userAuthStore } from '../../../stores';
import { useCheckConnectedIntegration } from './useCheckConnectedIntegration';
import { RadarTab } from '../../Radar/config';
import { useHighlightPageSection } from '../../Common/Driverjs/useHighlightPageSection';

export enum ConnectedNotificatonIntegration {
  SLACK = 'Slack',
  TEAMS = 'Microsoft Teams',
  NONE = 'none',
}

export enum NotificationType {
  BOLT = 'bolt',
  RADAR = 'radar',
}

const Notifications: FC = () => {
  const history = useHistory();
  const {
    connectedIntegration,
    isLoadingConnectedIntegrations,
  } = useCheckConnectedIntegration();

  const { accessLevel } = userAuthStore((s) => s.currentUser);
  const companyPlan = companyStore((s) => s.companyPlan);

  const hasMatchingPricePlan = (planToMatch: string) => (
    companyPlan?.planNames.some((plan) => plan === planToMatch) || false
  );

  useHighlightPageSection({ pageIsLoaded: true });

  const BoltSystemAlertsDescription = () => (
    <AutoLayout
      direction="vertical"
      distribution="packed"
      padding="none"
      verticalGap="dense"
      alignment="top-left"
    >
      <Typography type="body-small" colorStep="50">
        Setup destination for the following System Alerts in Paradime Bolt:
      </Typography>
      <AutoLayout distribution="packed" padding="none" verticalGap="ultra-dense" alignment="top-left">
        <Typography type="body-bold-small" colorStep="70">
          1. Can&apos;t clone repo (🔴 Critical)
        </Typography>
        <Typography type="caption" colorStep="50">
          Triggered when Bolt cannot clone your repo because your
          git provider like Github or Gitlab isn&apos;t available.
        </Typography>
      </AutoLayout>
      <AutoLayout distribution="packed" padding="none" verticalGap="ultra-dense" alignment="top-left">
        <Typography type="body-bold-small" colorStep="70">
          2. Schedule run Out-of-Memory (🟠 Error)
        </Typography>
        <Typography type="caption" colorStep="50">
          Triggered when a Bolt schedule runs out of memory
          because you have loaded too much data in memory.
          E.g. inside a dbt™ macro.
        </Typography>
      </AutoLayout>
      <AutoLayout distribution="packed" padding="none" verticalGap="ultra-dense" alignment="top-left">
        <Typography type="body-bold-small" colorStep="70">
          3. Schedule Time-out (⚠️ Warning)
        </Typography>
        <Typography type="caption" colorStep="50">
          Triggered when your warehouse has run out of resources or isn&apos;t available
          and a Bolt schedule has run for more than 24 hours.
        </Typography>
      </AutoLayout>
      <AutoLayout distribution="packed" padding="none" verticalGap="ultra-dense" alignment="top-left">
        <Typography type="body-bold-small" colorStep="70">
          4. Can&apos;t read paradime_schedules.yaml (⚠️ Warning)
        </Typography>
        <Typography type="caption" colorStep="50">
          Triggered when there is an error in paradime_schedules.yaml and Bolt cannot read the file.
        </Typography>
      </AutoLayout>
    </AutoLayout>
  );

  return (
    <AutoLayout
      direction="vertical"
      padding="none"
      verticalGap="normal"
      distribution="packed"
      alignment="top-left"
    >
      <PageTitle title={PageTitles.NOTIFICATIONS} />
      <div style={{ borderBottom: '1px solid var(--grey5)', width: '100%' }}>
        <Typography type="h6">
          Alert Settings
        </Typography>
      </div>
      <ConnectIntegrationsButtons
        hasConnectedIntegration={connectedIntegration !== ConnectedNotificatonIntegration.NONE}
        isLoadingConnectedIntegrations={isLoadingConnectedIntegrations}
      />
      <AutoLayout
        direction="horizontal"
        distribution="packed"
        padding="none"
        horizontalGap="very-dense"
        style={{
          gridTemplateColumns: '1fr 1fr',
        }}
      >
        <NotificationCard
          icon="lightning"
          title="Bolt System Alerts"
          description={BoltSystemAlertsDescription()}
          notificationType={NotificationType.BOLT}
          canEdit={userHasAccountSettingsNotificationsEditAccess(accessLevel)}
          hasAccess={hasMatchingPricePlan('bolt_schedules')}
          connectedIntegration={connectedIntegration}
          isLoadingConnectedIntegrations={isLoadingConnectedIntegrations}
          cta={{
            icon: 'lightning',
            text: 'Bolt',
            eventObject: 'gotoBolt',
            onClick: () => history.push('/bolt'),
          }}
        />
        <NotificationCard
          icon="pulse"
          title="Radar Data Alerts"
          description="Setup destination for Data Alerts in Paradime Radar."
          notificationType={NotificationType.RADAR}
          canEdit={userHasAccountSettingsNotificationsEditAccess(accessLevel)}
          hasAccess={hasMatchingPricePlan('radar')}
          connectedIntegration={connectedIntegration}
          isLoadingConnectedIntegrations={isLoadingConnectedIntegrations}
          cta={{
            icon: 'pulse',
            text: 'Data Alerts',
            eventObject: 'gotoDataAlerts',
            onClick: () => history.push(`/radar/${RadarTab.ALERTS}`),
          }}
        />
      </AutoLayout>
    </AutoLayout>
  );
};

export default Notifications;
