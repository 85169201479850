import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Callout from '@paradime-io/pragma-ui-kit/lib/components/Callout';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import React, { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import {
  useLocationState, useUpdateLocationState,
} from '../../../utilis';
import {
  SlackSearchQuery,
  useInviteViaEmailMutation,
  useInviteViaSlackMutation,
  GqlParadimeAccountType,
} from '../../../client/generated/service';
import * as S from './InvitationStates.styles';
import User from './User';
import Selector from './Selector';
import slackLogo from './images/slackLogo.svg';
import { companyStore, userAuthStore, appStore } from '../../../stores';

export type slackUser = NonNullable<SlackSearchQuery['slackSearch']>['users'][number]

interface InviteRolesProps {
  onClick: Function
}

export type genericUser = {
  email?: string,
  accountType: string,
  slackId?: string
  image?: string
  name?: string
} & slackUser

const InviteRoles: FC<InviteRolesProps> = ({ onClick }) => {
  const updateLocationState = useUpdateLocationState();
  const [state] = useLocationState();
  const history = useHistory();
  const {
    inviteTitle,
    emails,
    slackUsers,
    showSlack,
  } = state;

  const [invitesSent, setInviteSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const { isExtensionOnlyCompany } = companyStore.getState();
  const { setShowInviteUser } = appStore.getState();
  const { currentUser } = userAuthStore.getState();

  const { inviteLevels, isSandboxWorkspace } = currentUser;

  const defaultAccessLevel = () => {
    if (isSandboxWorkspace) return GqlParadimeAccountType.SandboxUser;
    if (isExtensionOnlyCompany) return GqlParadimeAccountType.ExtensionBusiness;
    return GqlParadimeAccountType.Business;
  };

  const formatSlackUsers = (slackUserList: slackUser[]) => (
    slackUserList.map((user: slackUser) => ({
      accountType: defaultAccessLevel(),
      image: user.profileImage32 || '',
      name: user.profileDisplayNameNormalized,
      ...user,
    } as genericUser))
  );

  const genericUsers = (): genericUser[] => {
    if (emails && emails.length > 0) {
      return emails.map((email: string) => ({
        email, accountType: defaultAccessLevel(),
      }));
    }
    return formatSlackUsers(slackUsers);
  };

  const [users, setUsers] = useState(genericUsers());
  const [, forceUpdate] = useState(0);

  // email, types[e.target.value], slackId, name, image
  const updateUser = (
    email: string,
    accountType: string,
    slackId: string = '',
  ) => {
    setUsers((current) => current.map(
      (item) => {
        const modifiedUser = { ...item, accountType };

        if (email && item.email === email) { return modifiedUser; }

        if (slackId && item.slackId === slackId) { return modifiedUser; }

        return item;
      },
    ));
  };

  const [
    sendEmailInvitation,
    { data: emailInviteData },
  ] = useInviteViaEmailMutation({
    variables: {
      users: users.map((user) => ({
        accountType: user.accountType.toUpperCase(), email: user.email!,
      })),
    },
  });
  const [
    sendSlackInvitation,
    { data: slackInviteData },
  ] = useInviteViaSlackMutation({
    variables: {
      users: users.map((user) => ({
        accountType: user.accountType.toUpperCase(), slackId: user.slackId!,
      })),
    },
  });

  useEffect(() => {
    if (emailInviteData?.inviteViaEmail?.ok
      || slackInviteData?.inviteViaSlack?.ok) {
      setInviteSent(true);
      setLoading(false);
    }
  }, [emailInviteData, slackInviteData]);

  const labelOnClick = {
    text: invitesSent ? 'Done' : 'Send invitations',
    onClick: invitesSent
      ? () => {
        updateLocationState({ onboardingToken: undefined });
        setShowInviteUser(false);
        history.replace(window.location.pathname, {
          ...state,
          onboardingToken: undefined,
          accountType: undefined,
          firstTimeLoading: undefined,
        });
        onClick((current: number) => current + 1);
        window.postMessage({ type: 'newUserInvited' }, '*');
      }
      : async () => {
        setLoading(true);
        const inviteFunction: Function = users[0].slackId
          ? sendSlackInvitation : sendEmailInvitation;
        inviteFunction();
      },
  };

  useEffect(() => () => {
    updateLocationState({ showSlack: false });
  }, []); // eslint-disable-line

  const setEmails = (newUsers: genericUser[]) => {
    updateLocationState({ showSlack: false });
    setUsers(newUsers);
    forceUpdate(Math.random());
  };

  return (
    <S.InviteRoles>
      {(showSlack && slackUsers)
        ? (
          <Selector
            inviteTitle={inviteTitle}
            users={formatSlackUsers(slackUsers)}
            setUsers={setEmails}
          />
        )
        : (
          <AutoLayout
            direction="vertical"
            padding="expanded"
            distribution="packed"
            verticalGap="very-dense"
            alignment="top-center"
            style={{ paddingTop: '2rem' }}
          >
            <Typography
              tagName="span"
              type="h4"
              color="default"
              colorStep="100"
              style={{ textAlign: 'center', margin: '0 auto 1rem auto' }}
            >
              {inviteTitle}
              {
                inviteTitle === 'Invite your team from Slack'
                && <img style={{ marginLeft: '8px' }} src={slackLogo} alt="slack" />
              }
            </Typography>
            <Callout
              title="User licence"
              content="By default we assign a Business User licence to new users. Use the dropdown to upgrade users license."
              color="primary"
              view="outlined"
              icon="info-sign"
              dense
            />
            <AutoLayout
              direction="vertical"
              padding="very-dense"
              verticalGap="very-dense"
              distribution="packed"
              alignment="top-center"
              style={{ padding: 0 }}
            >
              <AutoLayout
                alignment="center"
                direction="horizontal"
                distribution="space-between"
                height="max"
                padding="very-dense"
              >
                <Typography tagName="span" type="body-bold" color="default" colorStep="100">
                  User
                </Typography>
                <Typography tagName="span" type="body-bold" color="default" colorStep="100">
                  Role
                </Typography>
              </AutoLayout>
              {users && users.map((user) => (
                <User
                  inviteLevels={inviteLevels}
                  key={`user-${user.email || user.slackId}`}
                  updateUser={updateUser}
                  user={user}
                />
              ))}
            </AutoLayout>
            {invitesSent && (
              <Callout
                icon="info-sign"
                title="You’re done!"
                content="The selected users have been successfully invited to your Paradime workspace."
                color="success"
                view="smooth"
                dense
              />
            )}
            <DefaultButton
              {...labelOnClick}
              className="bp4-intent-6"
              loading={loading}
              color="primary"
              view="filled"
              type="standard"
              style={{
                margin: '1rem auto 0 auto',
                minWidth: '100px',
              }}
              eventContext={Contexts.MODAL}
              eventObject={invitesSent ? 'inviteUsersFlow' : 'userInvitations'}
              eventAction={invitesSent ? Actions.COMPLETED : Actions.SENT}
              eventProperties={invitesSent
                ? {
                  location: 'invite_users',
                }
                : {
                  location: 'onboarding',
                  type: users[0].slackId ? 'slack' : 'email',
                  count: users.length,
                }}
            />
          </AutoLayout>
        )}
    </S.InviteRoles>

  );
};

export default InviteRoles;
