import React, { FC } from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { Icon } from '@blueprintjs/core';

export interface ZeroStateSwitchingWorkspaceProps {
  workspaceName: string,
}

const ZeroStateSwitchingWorkspace: FC<ZeroStateSwitchingWorkspaceProps> = ({ workspaceName }) => (
  <AutoLayout
    direction="vertical"
    distribution="packed"
    alignment="center"
    style={{ marginTop: '200px' }}
  >
    <Icon
      icon="key-tab"
      color="var(--icon)"
      size={72}
      style={{ margin: 'auto' }}
    />
    <Typography
      font="inter"
      type="h4"
      color="default"
      colorStep="100"
      style={{ textAlign: 'center' }}
    >
      Switching workspaces
    </Typography>
    <Typography
      font="inter"
      type="body-bold"
      color="default"
      colorStep="100"
      style={{ textAlign: 'center' }}
    >
      We&apos;re redirecting you to
      {' '}
      <b>{workspaceName || 'that workspace'}</b>
      . This will only take a few seconds.
    </Typography>
  </AutoLayout>
);

export default ZeroStateSwitchingWorkspace;
