import React, { FC, ReactNode } from 'react';
import NavBar from '../../NavBar';
import * as S from './AppPage.styles';

export interface AppPageProps {
  children: ReactNode,
  isNavBarVisible?: boolean,
  currentApp?: string,
}

const AppPage:FC<AppPageProps> = ({
  children,
  isNavBarVisible,
  currentApp,
}) => (
  <S.NavbarWrapper>
    {isNavBarVisible
        && <NavBar currentApp={currentApp || ''} />}
    <S.Body>{children}</S.Body>
  </S.NavbarWrapper>
);

export default AppPage;
