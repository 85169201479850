import React, { FC, useState, useEffect } from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import BigButton from '@paradime-io/pragma-ui-kit/lib/components/BigButton';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import Dots from '@paradime-io/pragma-ui-kit/lib/components/Dots';
import { useHistory } from 'react-router-dom';
import { companyStore, RepoProviderType } from '../../../stores';
import azureDevOps from './images/azureDevOps.svg';
import bitbucket from './images/bitbucket.svg';
import github from './images/github.svg';
import gitlab from './images/gitlab.svg';
import { ONBOARDING_PAGE, OnboardingRoutePrefix } from '../../Onboarding';

export interface ConnectRepoProps {
  connectRepoNextStepUrl?: string,
}

const ConnectRepo: FC<ConnectRepoProps> = (props) => {
  const { connectRepoNextStepUrl } = props;

  const [currentRepoProvider, setCurrentRepoProvider] = useState<RepoProviderType>();
  const setRepoProvider = companyStore((s) => s.setRepoProvider);
  const history = useHistory();

  useEffect(() => {
    setRepoProvider(currentRepoProvider);
  }, [currentRepoProvider]);

  const checkSelected = ((name: RepoProviderType) => ({
    selected: currentRepoProvider === name,
    onClick: () => setCurrentRepoProvider(name),
  }));

  const handleClick = () => {
    let url = `/${OnboardingRoutePrefix}/${ONBOARDING_PAGE.CHOOSE_REPO_PROVIDER}`;
    if (connectRepoNextStepUrl) {
      url = connectRepoNextStepUrl;
    }
    history.push(url);
  };

  return (
    <>
      <AutoLayout
        direction="vertical"
        padding="expanded"
        verticalGap="very-dense"
        distribution="packed"
        alignment="top-center"
        style={{ paddingTop: '2rem' }}
      >
        <Typography
          tagName="span"
          type="h4"
          color="default"
          colorStep="100"
          style={{ textAlign: 'center', margin: '0 auto 1rem auto' }}
        >
          Connect your dbt repository
        </Typography>
        <AutoLayout
          direction="vertical"
          padding="none"
          verticalGap="dense"
          distribution="packed"
          alignment="top-center"
        >
          <Typography tagName="span" type="body-bold" color="default" colorStep="100">
            Choose your git provider
          </Typography>
          <AutoLayout
            alignment="center"
            direction="vertical"
            distribution="packed"
            padding="none"
            verticalGap="dense"
          >
            <BigButton image={azureDevOps} fill {...checkSelected('azureDevOps')} />
            <BigButton image={bitbucket} fill {...checkSelected('bitbucket')} />
            <BigButton image={github} fill {...checkSelected('github')} />
            <BigButton image={gitlab} fill {...checkSelected('gitlab')} />
          </AutoLayout>
          <DefaultButton
            data-testid="continue"
            fill={false}
            color="primary"
            view="filled"
            type="standard"
            disabled={currentRepoProvider === undefined}
            className="bp4-intent-6"
            style={{
              margin: '1rem auto 0 auto',
              minWidth: '100px',
            }}
            text="Next"
            onClick={handleClick}
            eventContext={Contexts.ONBOARDING}
            eventObject="connectRepoNext"
            eventAction={Actions.CLICKED}
          />
        </AutoLayout>
      </AutoLayout>
      <Dots selected={2} total={3} />
    </>
  );
};

export default ConnectRepo;
