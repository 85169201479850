import React, { FC } from 'react';

const Looker: FC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="14" height="14" rx="7" fill="white" />
    <path d="M8.04731 3.9375C7.78009 3.93762 7.5347 4.08239 7.4091 4.31403C7.28351 4.54567 7.29817 4.82643 7.44723 5.04424L7.75621 4.74123C7.74599 4.71101 7.74084 4.67935 7.74095 4.64751C7.74095 4.48205 7.87753 4.34793 8.04601 4.34793C8.21448 4.34793 8.35106 4.48205 8.35106 4.64751C8.35106 4.81296 8.21448 4.94709 8.04601 4.94709C8.01372 4.94716 7.98164 4.9421 7.951 4.93211L7.64247 5.23511C7.8994 5.40625 8.23891 5.39718 8.486 5.21259C8.73309 5.02799 8.83259 4.70908 8.73321 4.42024C8.63383 4.13139 8.35777 3.9371 8.04731 3.9375Z" fill="#34A853" />
    <path d="M7.76144 6.00674C7.76191 5.7669 7.68282 5.53344 7.53613 5.34167L7.13521 5.73498C7.26059 5.9602 7.21547 6.24014 7.02539 6.41631L7.24328 6.93929C7.56613 6.7351 7.76129 6.38386 7.76144 6.00674Z" fill="#FBBC04" />
    <path d="M6.63993 6.57114H6.62947C6.38355 6.57116 6.16482 6.41766 6.08518 6.18917C6.00554 5.96068 6.0826 5.70775 6.27695 5.55977C6.47129 5.41179 6.73993 5.4015 6.94542 5.53417L7.34242 5.14428C6.95638 4.83693 6.41122 4.81322 5.99903 5.08586C5.58684 5.3585 5.4043 5.86353 5.54921 6.33037C5.69413 6.79721 6.13251 7.11635 6.62947 7.11681C6.70615 7.11684 6.78264 7.10939 6.85782 7.09455L6.63993 6.57114Z" fill="#EA4335" />
    <path d="M8.06362 7.02356C7.8127 7.02331 7.56309 7.05921 7.32278 7.13013L7.64047 7.89234C7.77876 7.85869 7.92071 7.84173 8.06318 7.84184C8.88873 7.84208 9.59915 8.41495 9.76002 9.21014C9.92089 10.0053 9.48741 10.8014 8.72466 11.1115C7.96191 11.4217 7.0823 11.1595 6.62372 10.4854C6.16514 9.81122 6.25529 8.91283 6.83906 8.33957C6.96087 8.22032 7.09993 8.11937 7.25175 8.03999L6.93754 7.27992C5.76634 7.84322 5.21181 9.1798 5.64865 10.3865C6.08548 11.5933 7.37467 12.2862 8.64512 11.997C9.91557 11.7079 10.763 10.5287 10.6148 9.25628C10.4666 7.98385 9.36992 7.02307 8.0658 7.02313L8.06362 7.02356Z" fill="#4285F4" />
    <rect x="1" y="1" width="14" height="14" rx="7" stroke="#4285F4" strokeWidth="2" />
  </svg>
);

export default Looker;
