import { useState } from 'react';
import { genericObject } from '../../../utilis';
import {
  useBillingDetailsQuery,
} from '../../../client/generated/service';

export interface CustomerAddress {
  line1?: string,
  line2?: string,
  city?: string,
  state?: string,
  postal_code?: string, // eslint-disable-line camelcase
  country?: string,
}

export interface BillingItem {
  name?: string,
  description?: string,
  billingInterval?: string,
  customerEmail?: string,
  customerAddress?: CustomerAddress,
  currentBillEndTimestamp?: number,
  status?: string,
}

const useGetBillingData = () => {
  const [billingData, setBillingData] = useState<BillingItem[]>([]);
  const [isError, setIsError] = useState(false);

  const { loading: isLoadingBillingData } = useBillingDetailsQuery({
    onCompleted: ({ billingDetails }) => {
      if (billingDetails?.ok) {
        const parsedData = JSON.parse(
          Buffer.from(
            billingDetails?.subscriptionsDataB64, 'base64',
          ).toString(),
        );

        // n.b. in dev, Stripe subscriptions get deleted every 3 months
        if (parsedData.length === 0) {
          setBillingData([]);
          setIsError(true);
        } else {
          const billingItems: BillingItem[] = parsedData.map((item: genericObject) => ({
            name: item.items.data[0].price.product.name,
            description: item.items.data[0].price.product.description,
            billingInterval: item.items.data[0].plan.interval,
            customerEmail: item.customer.email,
            customerAddress: item.customer.address,
            currentBillEndTimestamp: item.current_period_end,
            status: item.status,
          }));
          setBillingData(billingItems);
          setIsError(false);
        }
      }

      if (billingDetails?.failureReason) {
        setIsError(true);
      }
    },
  });

  return {
    isError,
    isLoadingBillingData,
    billingData,
    setIsError,
  };
};

export { useGetBillingData };
