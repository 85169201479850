import styled from 'styled-components';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';

const Invitations = styled.div<{ hover?: boolean }>`
  font-family: 'Inter', sans-serif;

  .slack {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
      height: 30px;
    }
  }

  .emails {
    background: var(--white);
    border: 1px solid var(--grey30);
    border-radius: var(--s4);
    width: 100%;
    height: 142px;
    overflow-y: auto;
    padding: 16px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;

    input {
      &:focus {
        outline: none;
      }
    }
  }

  .exit-button {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    margin: 0;

    input,
    button {
      max-width: 154px;
      margin: 0 auto;
      margin-bottom: 1rem;
    }

    .later {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 114%;
      color: var(--grey50);

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const InviteRoles = styled.div`
  overflow-y: auto;
  flex-direction: column;
  display: flex;

  input[type="button"] {
    max-width: 155px;
    margin: 0 auto;
    display: block;
  }

  div[type="success"] {
    margin: 1rem 0;
  }

  .selector {
    border: 1px solid var(--grey20);
    border-radius: var(--s8);
    padding: 1rem;
    overflow-y: auto;
    height: 333px;

    label[for="search"] {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 1rem;
      position: sticky;
      z-index: 10;
      top: 0;
      background: var(--white);

      fieldset {
        position: relative;
        margin-top: 0.5rem;

        input {
          padding: 8px 8px 8px 37px;
        }

        img {
          position: absolute;
          left: 9px;
          top: 50%;
          transform: translate(0, -50%);
        }
      }
    }

    .list {
      display: grid;
      grid-template-columns: 1fr;
      border: none !important;
      place-items: center;
      grid-gap: 8px;

      .userRow {
        position: relative;
        width: 100%;
        img {
          border-radius: 100%;
          height: 26px;
          position: absolute;
          right: 8px;
          top: 2px;
        }
      }

      .left {
        margin-right: auto;
      }

      .right {
        margin-left: auto;
      }

    }
  }
`;

const SlackButton = styled(DefaultButton)`
  justify-content: flex-start;
  padding-left: 0;
  text-transform: none;

  &:hover {
    background: none !important;
  }
`;

export {
  Invitations,
  InviteRoles,
  SlackButton,
};
