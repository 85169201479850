const getBoltStatusColors = (state: string) => {
  switch (state) {
    case 'Success':
      return {
        foreground: '#1f8b4c',
        background: '#d3eedf',
      };
    case 'Error':
      return {
        foreground: '#db3c30',
        background: '#fdddda',
      };
    case 'Running':
      return {
        foreground: '#4a4b57',
        background: '#ededf0',
      };
    case 'Failed':
      return {
        foreground: '#db3c30',
        background: '#fdddda',
      };
    default:
      return {
        foreground: '#4a4b57',
        background: '#ededf0',
      };
  }
};

const BoltScheduleNameStatusHTML = (
  lastRunStatus: string,
  owner: string,
  nextRunAt: string,
) => (
  `
  <div style="min-height: 24px; display: flex; align-items: center; width: 550px;
  padding: 4px 0; font-size: 10px; justify-content: space-between; font-family: Inter; font-weight: 400;">
      <div style="display: flex;">
        <div style="display: flex; align-items: center; height: 15px;
            border-right: 1px solid #333333; min-width: 155px;
            padding-right: 12px; margin-right: 12px;
            padding-right: 12px; margin-right: 12px;">
            <p style="padding: 0px; margin: 0px;">Last run status: </p>
            <span style="min-width: 24px;
              background-color: ${getBoltStatusColors(lastRunStatus).background}; 
              padding: 4px 5px 4px 5px; border-radius: 4px; font-size: 10px; 
              color: ${getBoltStatusColors(lastRunStatus).foreground};
              margin: 0 5px;"
            >
              ${lastRunStatus}
            </span>
        </div>
        <div style="display: flex; align-items: center; height: 15px;">
            <p style="padding: 0px; margin: 0px;">Owner: </p>
            <span style="min-width: 24px;
              padding: 4px 5px 4px 5px; font-size: 10px; font-weight: 500; 
              color: #19191d;"
            >
              ${owner}
            </span>
        </div>
      </div>
      <div style="display: flex; align-items: center; height: 15px;">
          <p style="padding: 0px; margin: 0px;">Next run at: </p>
          <span style="min-width: 24px;
            padding: 4px 5px 4px 5px; font-size: 10px; font-weight: 500; 
            color: #19191d;"
          >
            ${nextRunAt}
          </span>
      </div>
  </div>
  `
);

export default BoltScheduleNameStatusHTML;
