/* eslint-disable no-undef */
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useSetCompanyOnCodeEditorIframe } from './hooks';
import useListenersFromCodeEditor from './hooks/useListenersFromCodeEditor';
import useOpenNodesOnCodeEditor from './hooks/useOpenNodesOnCodeEditor';
import { EditorContext } from './hooks/useSetFirstEditor';
import PageTitle, { PageTitles } from '../PageTitle';
import useHandleGithubUserOAuth from './hooks/useHandleGithubUserOAuth';
import { userAuthStore } from '../../stores';
import { userHasEditorAccess } from '../../utilis/PermissionsService';
import CodeIDEUpgrade from '../Common/Upgrade/CodeIDEUpgrade';
import { useWaitForLDToBeReady } from '../hooks/useWaitForLDToBeReady';
import LoadingScreen from './LoadingScreen';

const EditorLayout: React.FC = () => {
  const { currentUser } = userAuthStore.getState();
  const { pathname } = useLocation();

  const [loaderHasFinished, setLoaderHasFinished] = useState(false);

  const {
    url: editorUrl,
    showEditor,
    setShowEditor,
    isReady,
  } = useContext(EditorContext);

  const { ldIsReady } = useWaitForLDToBeReady();
  const { flagAppEditor } = useFlags();

  useSetCompanyOnCodeEditorIframe();

  useListenersFromCodeEditor();

  useOpenNodesOnCodeEditor();

  useHandleGithubUserOAuth();

  useEffect(() => {
    setShowEditor(true);
  }, []);

  const { accessLevel } = currentUser;

  if (ldIsReady && !flagAppEditor) {
    return <CodeIDEUpgrade />;
  }

  if (!showEditor || !loaderHasFinished) {
    return (
      <>
        <LoadingScreen
          onLoadingCompleted={() => setLoaderHasFinished(true)}
          editorIsReady={isReady}
        />
      </>
    );
  }

  return (
    <div style={{
      width: '100%',
      height: '100%',
    }}
    >
      <PageTitle title={PageTitles.EDITOR} />
      {editorUrl && userHasEditorAccess(accessLevel) && loaderHasFinished && (
        <iframe
          id="codeEditorIframe"
          style={{
            width: '100%',
            height: '100%',
            border: 'none',
            backgroundColor: 'white',
            display: /lineage/.test(pathname) ? 'none' : 'block',
          }}
          src={editorUrl}
          className="w-full h-full"
          title="theia"
          allow="clipboard-write; clipboard-read"
          data-html2canvas-ignore="true"
          allowFullScreen
        />
      )}
    </div>
  );
};

export default EditorLayout;
