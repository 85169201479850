import { useAuth0 } from '@auth0/auth0-react';
import { companyStore, graphQLStore, userAuthStore } from '../stores';

export type userIdentifyingPropertiesType = { [key: string]: string | undefined };

export const useGetUserIdentifyingProperties = () => {
  const { user } = useAuth0();

  const paradimeOrganisationFromStore = graphQLStore((state) => state.paradimeOrganisation);
  const companyName = paradimeOrganisationFromStore?.name || '-';
  const companyRegion = companyStore((state) => state.companyRegion);
  const workspaceToken = graphQLStore((s) => s.workspaceToken);
  const companyToken = graphQLStore((s) => s.companyToken);
  const storedUser = userAuthStore((s) => s.currentUser);

  const getEmailDomain = (email: string) => (
    email.substring(email.lastIndexOf('@') + 1)
  );

  return {
    companyToken,
    workspaceToken,
    username: user?.name || storedUser?.name,
    userEmail: user?.email || storedUser?.email,
    avatarUrl: user?.picture || storedUser.avatarUrl,
    emailDomain: getEmailDomain(user?.email || storedUser?.email || 'unknown'),
    companyName,
    companyRegion,
    backendUserId: storedUser?.uid || 'unknown',
    type: storedUser?.accessLevel || 'unknown',
  };
};
