import { Dispatch, SetStateAction } from 'react';

type RegionType = {
  name: string,
  region: string,
};

export const locationMultiRegion = [
  {
    name: 'EU',
    region: 'EU',
  },
  {
    name: 'US',
    region: 'US',
  },
];

export const locationUS = [
  {
    name: 'Columbus, Ohio',
    region: 'us-east5',
  },
  {
    name: 'Dallas',
    region: 'us-south1',
  },
  {
    name: 'Iowa',
    region: 'us-central1',
  },
  {
    name: 'Las Vegas',
    region: 'us-west4',
  },
  {
    name: 'Los Angeles',
    region: 'us-west2',
  },
  {
    name: 'Montreal',
    region: 'northamerica-northeast1',
  },
  {
    name: 'Northern Virginia',
    region: 'us-east4',
  },
  {
    name: 'Oregon',
    region: 'us-west1',
  },
  {
    name: 'Salt Lake City',
    region: 'us-west3',
  },
  {
    name: 'Sao Paulo',
    region: 'southamerica-east1',
  },
  {
    name: 'Santiago',
    region: 'southamerica-west1',
  },
  {
    name: 'South Carolina',
    region: 'us-east1',
  },
  {
    name: 'Toronto',
    region: 'northamerica-northeast2',
  },
];

export const locationEU = [
  {
    name: 'Belgium',
    region: 'europe-west1',
  },
  {
    name: 'Berlin',
    region: 'europe-west10',
  },
  {
    name: 'Finland',
    region: 'europe-north1',
  },
  {
    name: 'Frankfurt',
    region: 'europe-west3',
  },
  {
    name: 'London',
    region: 'europe-west2',
  },
  {
    name: 'Madrid',
    region: 'europe-southwest1',
  },
  {
    name: 'Milan',
    region: 'europe-west8',
  },
  {
    name: 'Netherlands',
    region: 'europe-west4',
  },
  {
    name: 'Paris',
    region: 'europe-west9',
  },
  {
    name: 'Turin',
    region: 'europe-west12',
  },
  {
    name: 'Warsaw',
    region: 'europe-central2',
  },
  {
    name: 'Zurich',
    region: 'europe-west6',
  },
];

export const locationAPAC = [
  {
    name: 'Delhi',
    region: 'asia-south2',
  },
  {
    name: 'Hong Kong',
    region: 'asia-east2',
  },
  {
    name: 'Jakarta',
    region: 'asia-southeast2',
  },
  {
    name: 'Melbourne',
    region: 'australia-southeast2',
  },
  {
    name: 'Mumbai',
    region: 'asia-south1',
  },
  {
    name: 'Osaka',
    region: 'asia-northeast2',
  },
  {
    name: 'Seoul',
    region: 'asia-northeast3',
  },
  {
    name: 'Singapore',
    region: 'asia-southeast1',
  },
  {
    name: 'Sydney',
    region: 'australia-southeast1',
  },
  {
    name: 'Taiwan',
    region: 'asia-east1',
  },
  {
    name: 'Tokyo',
    region: 'asia-northeast1',
  },
];

export const locationMENA = [
  {
    name: 'Dammam',
    region: 'me-central2',
  },
  {
    name: 'Doha',
    region: 'me-central1',
  },
  {
    name: 'Tel Aviv',
    region: 'me-west1',
  },
  {
    name: 'Johannesburg',
    region: 'africa-south1',
  },
];

export const generateRegionsDropdownItems = (
  setSelection: Dispatch<SetStateAction<string>>,
) => {
  const getDropdownItems = (regionList: RegionType[]) => (
    regionList.map((k) => ({
      text: k.region,
      type: 'item',
      label: k.name,
      onClick: () => setSelection(k.region),
    })));

  const items = [
    ...getDropdownItems(locationMultiRegion),
    { type: 'divider' },
    ...getDropdownItems(locationUS),
    { type: 'divider' },
    ...getDropdownItems(locationEU),
    { type: 'divider' },
    ...getDropdownItems(locationAPAC),
    { type: 'divider' },
    ...getDropdownItems(locationMENA),
  ];

  return items;
};
