import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { EnvName } from '../../../../../client/service.graphql';
import {
  useDwhCheckRedshiftLazyQuery,
  useSetupDwhRedshiftMutation,
  useCompanyOnboardingRedshiftMutation,
  useCheckProfileAddRedshiftLazyQuery,
  useProfileAddRedshiftMutation,
  useSetupDwhProductionRedshiftMutation,
  RedshiftConnectionType,
} from '../../../../../client/generated/service';
import useOnboardIfChecked from '../hooks/useOnboardIfChecked';
import { WarehouseEnv } from '../../utils';
import {
  getButtonDefinition,
  BUTTON_STATE_TEXT,
  onSubmitButtonClickProps,
  getInitialButtonText,
  useCommonConfigProps,
  isEditButtonShown,
} from '../CommonConfig';
import { WareHouseModalSource } from '../..';
import { checkNoCasesLeft } from '../../../../../utilis';

export interface RedshiftFormDataType {
  connectionName: string,
  connectionType: RedshiftConnectionType,
  hostName: string,
  port: string,
  authMethod: AuthMethod,
  profile?: string,
  database: string,
  username: string,
  password: string,
  schema: string,
  targetName: string,
  threads: number,
}

// The string which gets displayed in the dropdown
export enum AuthMethod {
  'USERNAME_PASSWORD' = 'Username & Password',
  'IAM' = 'IAM',
}

export const getDefaultEmptyAuthValues = (
  authType: AuthMethod,
  formData?: RedshiftFormDataType,
): RedshiftFormDataType | undefined => {
  if (!formData || formData === undefined) return undefined;

  switch (authType) {
    case AuthMethod.USERNAME_PASSWORD:
      return {
        ...formData,
        authMethod: AuthMethod.USERNAME_PASSWORD,
        connectionType: RedshiftConnectionType.Hostname,
      };
    case AuthMethod.IAM:
      return {
        ...formData,
        authMethod: AuthMethod.IAM,
        connectionType: RedshiftConnectionType.Profile,
      };
    default:
      checkNoCasesLeft(authType);
      return undefined;
  }
};

export const useCommonConfig = ({
  formData,
  env,
  source,
  isEditable,
  isAdditionalUser,
  isNewConnection,
}: useCommonConfigProps) => {
  const [onboarded, setOnboarded] = useState<boolean>();
  const [errorMessage, setErrorMessage] = useState('');
  const [currentButtonText, setCurrentButtonText] = useState(
    getInitialButtonText({
      source,
      isEditable,
      isAdditionalUser,
    }),
  );
  const [checkInProgress, setCheckInProgress] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isEditingUsernamePassword, setIsEditingUsernamePassword] = useState(
    !isEditButtonShown({ source, isNewConnection, env }),
  );

  const getAuthMethodDropdownValue = () => {
    if ((formData as RedshiftFormDataType)?.connectionType === RedshiftConnectionType.Profile) {
      return AuthMethod.IAM;
    }
    return AuthMethod.USERNAME_PASSWORD;
  };

  const [selectedAuthMethod, setSelectedAuthMethod] = useState(
    getAuthMethodDropdownValue(),
  );

  const history = useHistory();

  const { checkQueryFunction: checkOnboardRedshift } = useOnboardIfChecked({
    checkQuery: useDwhCheckRedshiftLazyQuery, // @ts-ignore - "password" may or may not be undefined
    triggerQuery: useCompanyOnboardingRedshiftMutation,
    setErrorMessage,
    setOnboarded,
    variables: formData as { [key: string]: any },
    setIsChecked,
    setCheckInProgress,
  });

  const { checkQueryFunction: checkUpdateRedshift } = useOnboardIfChecked({
    checkQuery: useDwhCheckRedshiftLazyQuery,
    triggerQuery: useSetupDwhRedshiftMutation,
    setErrorMessage,
    setOnboarded,
    variables: formData as { [key: string]: any },
    setIsChecked,
    setCheckInProgress,
  });

  const { checkQueryFunction: checkAddRedshift } = useOnboardIfChecked({
    checkQuery: useCheckProfileAddRedshiftLazyQuery,
    triggerQuery: useProfileAddRedshiftMutation,
    setErrorMessage,
    setOnboarded,
    variables: formData as { [key: string]: any },
    setIsChecked,
    setCheckInProgress,
  });

  const { checkQueryFunction: onboardProductionRedshift } = useOnboardIfChecked(
    {
      checkQuery: useDwhCheckRedshiftLazyQuery, // @ts-ignore
      triggerQuery: useSetupDwhProductionRedshiftMutation,
      setErrorMessage,
      setOnboarded,
      variables: { ...formData, productionEnvName: EnvName.PRODUCTION },
      setIsChecked,
      setCheckInProgress,
    },
  );

  const testTheConnection = (
    variables: onSubmitButtonClickProps['formData'],
    credentialId: onSubmitButtonClickProps['credentialId'],
  ) => {
    if (isAdditionalUser) {
      checkAddRedshift({
        variables: {
          ...variables,
          credentialId: credentialId!,
        },
      });
    } else {
      switch (env) {
        case WarehouseEnv.DEV:
          if (source === WareHouseModalSource.ACCOUNT_SETTINGS) {
            checkUpdateRedshift({ variables });
          } else {
            checkOnboardRedshift({ variables });
          }
          break;
        case WarehouseEnv.PROD:
          onboardProductionRedshift({
            variables: {
              ...variables,
              productionEnvName: EnvName.PRODUCTION,
            },
          });
          break;

        default:
          break;
      }
    }
  };

  const submitButtonDetails = getButtonDefinition({
    state: currentButtonText,
    isAdditionalUser,
    testTheConnection,
    setCurrentButtonText,
    env,
    source,
    isEditable,
    history,
  });

  useEffect(() => {
    if (isChecked && !checkInProgress) {
      if (onboarded) {
        if (submitButtonDetails?.nextState) {
          setCurrentButtonText(submitButtonDetails.nextState.success);
        }
      } else {
        setCurrentButtonText(
          submitButtonDetails?.nextState?.failure || ('' as BUTTON_STATE_TEXT),
        );
      }
    }
  }, [onboarded, submitButtonDetails, checkInProgress]);

  return {
    onboarded,
    selectedAuthMethod,
    setSelectedAuthMethod,
    errorMessage,
    submitButtonText: submitButtonDetails?.submitButtonText,
    onSubmitButtonClick: submitButtonDetails?.onSubmitButtonClick,
    dataIsBeingChecked: checkInProgress,
    showEditButton: isEditButtonShown({ source, isNewConnection, env }),
    isEditingUsernamePassword,
    setIsEditingUsernamePassword,
  };
};
