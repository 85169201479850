import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ZeroState from '@paradime-io/pragma-ui-kit/lib/components/ZeroState';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { useSelectParadimeOrg } from '../../SynqOnboarding/CreateNewCompany/useSelectParadimeOrg';

const ErrorOrgAlreadyExists: FC = () => {
  const history = useHistory();
  const { search, state } = useLocation<{ orgName: string }>();
  const setParadimeOrg = useSelectParadimeOrg();

  const handleClick = (type: 'join' | 'retry') => {
    if (type === 'join') {
      setParadimeOrg(state.orgName);
    } else {
      history.push(`/enter-organisation${search}`);
    }
  };

  return (
    <div style={{ width: '100vw', height: '100vh', textAlign: 'center' }}>
      <ZeroState
        useParadimeIcon
        icon={{ iconName: 'disable', iconColor: 'var(--red80)' }}
        text={(
          <>
            <Typography
              font="inter"
              type="h4"
              color="default"
              colorStep="100"
            >
              This organization already exists
            </Typography>
            <Typography
              font="inter"
              type="body"
              color="default"
              colorStep="100"
            >
              Do you want to join your team instead?
            </Typography>
          </>
        )}
        buttons={[
          {
            onClick: () => handleClick('join'),
            text: 'Join team',
            type: 'standard',
            view: 'simple',
            color: 'primary_alt',
            eventContext: Contexts.ONBOARDING,
            eventObject: 'orgExistsJoinTeammates',
            eventAction: Actions.CLICKED,
          },
          {
            onClick: () => handleClick('retry'),
            text: 'Try another organization',
            type: 'standard',
            view: 'simple',
            color: 'primary',
            eventContext: Contexts.ONBOARDING,
            eventObject: 'orgExistsTryAgain',
            eventAction: Actions.CLICKED,
          },
        ]}
      />
    </div>
  );
};

export default ErrorOrgAlreadyExists;
