export const convertNumberToFileSize = (bytes: number) => {
  const sizes = ['B', 'Kb', 'Mb', 'Gb', 'Tb'];

  let total = bytes;
  let i = 0;

  while (total >= 1024) {
    total /= 1024;
    i += 1;
  }

  return `${total.toFixed(2)} ${sizes[i]}`;
};

export const toBase64 = (file: File) => new Promise<string>((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result as string);
  reader.onerror = (error) => reject(error);
});

export const randomFromArray = <T, >(array: T[]): T => array[
  Math.floor(Math.random() * array.length)
];

export const times = (iterations: number) => Array(iterations).fill('');

const isFloat = (num: number) => Number(num) === num && num % 1 !== 0;

export const fixedIfFloat = (num: number, max?: number) => {
  if (isFloat(num)) {
    return num.toFixed(max || 2);
  }
  return num.toString();
};

export const toFixedDecimals = (num: number, maxDecimals: number) => Number
  .parseFloat(num.toFixed(maxDecimals));

export const formatToAccountingCurrency = (amount: number, currency: string) => (
  new Intl.NumberFormat(
    'en-US',
    {
      style: 'currency',
      currency,
      currencySign: 'standard',
    },
  ).format(amount)
);
