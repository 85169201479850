import { ScheduleRunCommandArtifactType, useGetCommandArtifactsQuery } from '../../../client/generated/service';

const useGetDataForArtifactType = (
  commandId: number,
  artifactType: ScheduleRunCommandArtifactType,
) => {
  const { data, loading } = useGetCommandArtifactsQuery({
    variables: {
      artifactType,
      scheduleCommandId: commandId,
    },
  });

  return { data, loading };
};

export default useGetDataForArtifactType;
