import { catalogueSearchFiltersProps } from '../types';
import { LookerSubTypes } from '.';

export const catalogueSearchFilters = ({
  checkFilterIsSelected,
}: catalogueSearchFiltersProps) => ({
  includeLookerDashboards: checkFilterIsSelected(LookerSubTypes.LOOKER_DASHBOARDS),
  includeLookerExplores: checkFilterIsSelected(LookerSubTypes.LOOKER_EXPLORES),
  includeLookerLooks: checkFilterIsSelected(LookerSubTypes.LOOKER_LOOKS),
});
