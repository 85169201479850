import React, { FC } from 'react';
import { Icon } from '@blueprintjs/core';
import { useHistory } from 'react-router-dom';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import { Actions, EventContextsType } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { NotificationType } from '.';
import { useGetAlertNotifications } from './useGetAlertNotifications';
import { useCheckConnectedIntegration } from './useCheckConnectedIntegration';
import { useGetEmailNotifications } from './useGetEmailNotifications';

export interface CalloutSetupAlertsProps {
  notificationType: NotificationType,
  eventContext: EventContextsType,
}

const CalloutSetupAlerts: FC<CalloutSetupAlertsProps> = ({
  notificationType,
  eventContext,
}) => {
  const history = useHistory();

  const {
    connectedIntegration,
    isLoadingConnectedIntegrations,
  } = useCheckConnectedIntegration();

  const {
    selectedChannelName,
    isLoadingChannelName,
  } = useGetAlertNotifications({ connectedIntegration, notificationType });

  const {
    emailIsEnabled,
    isLoadingEmailNotifications,
  } = useGetEmailNotifications({
    notificationType,
  });

  const getCopy = () => {
    switch (notificationType) {
      case NotificationType.BOLT:
        return {
          title: 'Bolt System Alerts',
          subtitle: 'Set up notifications for repository access, memory usage, timeouts, and configuration errors',
        };
      case NotificationType.RADAR:
        return {
          title: 'Radar Data Alerts',
          subtitle: 'Set up notifications to detect cost spikes and anomalies in your data warehouse',
        };
      default:
        return {
          title: '',
          subtitle: '',
        };
    }
  };

  if (isLoadingConnectedIntegrations || isLoadingChannelName || isLoadingEmailNotifications) {
    return null;
  }

  if (selectedChannelName.value.length > 0 || emailIsEnabled) return null;

  return (
    <AutoLayout
      direction="horizontal"
      padding="none"
      distribution="space-between"
      style={{
        background: 'linear-gradient(90deg, #EEF2FF, #FAF5FF)',
        border: '1px solid var(--violet10)',
        borderRadius: '8px',
        padding: '16px',
      }}
    >
      <AutoLayout
        direction="horizontal"
        padding="none"
        distribution="packed"
        horizontalGap="very-dense"
      >
        <Icon icon="notifications" size={20} color="var(--violet70)" />
        <AutoLayout
          direction="vertical"
          padding="none"
          distribution="packed"
          verticalGap="none"
        >
          <Typography type="body-bold-small">
            {getCopy().title}
          </Typography>
          <Typography type="caption" colorStep="50">
            {getCopy().subtitle}
          </Typography>
        </AutoLayout>
      </AutoLayout>

      <DefaultButton
        type="standard"
        view="outlined"
        color="primary"
        dense
        text="Configure alerts"
        rightIcon="chevron-right"
        onClick={() => {
          history.push('/settings/notifications', { highlightId: notificationType });
        }}
        eventContext={eventContext}
        eventObject="setupAlerts"
        eventAction={Actions.CLICKED}
        eventProperties={{ notificationType }}
      />
    </AutoLayout>
  );
};

export default CalloutSetupAlerts;
