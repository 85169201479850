import React, { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import TextWithTooltip from '@paradime-io/pragma-ui-kit/lib/components/TextWithTooltip';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import Dropdown from '@paradime-io/pragma-ui-kit/lib/components/Dropdown';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { MenuTemplate } from '@paradime-io/pragma-ui-kit/lib/components/Menu';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import Dots from '@paradime-io/pragma-ui-kit/lib/components/Dots';
import { graphQLStore } from '../../../../stores';
import {
  useSetDbtVersionMutation,
} from '../../../../client/generated/service';
import { createWorkspaceRoutePrefix, CreateWorkspaceRoutes } from '.';

const WorkspaceSettings: FC = () => {
  const { clusterConfig } = graphQLStore.getState();
  const { supportedDbtVersions } = clusterConfig!;

  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [currentDbtVersion, setCurrentDbtVersion] = useState(supportedDbtVersions[0]);
  const [dbtVersionIsSet, setDbtVersionIsSet] = useState(false);

  const [setDbtVersion, {
    loading: setDbtVersionIsLoading,
  }] = useSetDbtVersionMutation({
    onCompleted: (data) => {
      if (data?.setDbtVersion?.ok) {
        setDbtVersionIsSet(true);
      } else {
        // Error
      }
    },
  });

  useEffect(() => {
    setLoading(setDbtVersionIsLoading);
  }, [setDbtVersionIsLoading]);

  const handleClick = () => {
    setDbtVersion({
      variables: {
        dbtVersion: currentDbtVersion,
      },
    });
  };

  useEffect(() => {
    if (dbtVersionIsSet) {
      // All data has successfully been saved
      history.push(`/${createWorkspaceRoutePrefix}/${CreateWorkspaceRoutes.CONNECT_REPO}`);
    }
  }, [dbtVersionIsSet]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'start',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <AutoLayout
        direction="vertical"
        padding="expanded"
        verticalGap="very-dense"
        distribution="packed"
        alignment="top-center"
        style={{ paddingTop: '2rem' }}
      >
        <Typography
          tagName="span"
          type="h4"
          color="default"
          colorStep="100"
          style={{ textAlign: 'center', margin: '0 auto 1rem auto' }}
        >
          Workspace Settings
        </Typography>
        <TextWithTooltip
          value="Select the dbt version for your workspace"
          info="Choose the dbt core version for your workspace."
          type="body-bold"
        />
        <Dropdown
          disabled={loading}
          label="dbt-core version"
          content={(
            <MenuTemplate
              items={supportedDbtVersions.map((version) => ({
                text: version,
                type: 'item',
                onClick: () => setCurrentDbtVersion(version),
              }))}
              eventContext={Contexts.PLATFORM}
            />
          )}
          view={loading ? 'smooth' : 'raised'}
          fill
          height={200}
          text={currentDbtVersion}
          color="default"
        />
        <DefaultButton
          fill={false}
          color="primary"
          onClick={handleClick}
          text="Continue"
          view="filled"
          type="standard"
          disabled={loading}
          loading={loading}
          className="bp4-intent-6"
          style={{
            margin: '1rem auto 0 auto',
          }}
          eventContext={Contexts.PLATFORM}
          eventObject="workspaceSettings"
          eventAction={Actions.FILLED}
          eventProperties={{
            location: 'createWorkspace',
            dbt_version: currentDbtVersion,
          }}
        />
      </AutoLayout>
      <Dots selected={1} total={3} />
    </div>
  );
};

export default WorkspaceSettings;
