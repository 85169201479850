import styled, { css } from 'styled-components';
import { PanelLayout } from './layoutConstants';

const getleftPanelWidth = (isPanelOpen: boolean) => (
  isPanelOpen ? PanelLayout.LEFT_PANEL_EXPANDED : PanelLayout.LEFT_PANEL_COLLAPSED
);

const Page = styled.div<{isLeftPanelOpen: boolean}>`
  display: grid;

  grid-template-columns: ${(props) => {
    const leftPanelWidth = getleftPanelWidth(props.isLeftPanelOpen);

    return `
      ${leftPanelWidth}px
      calc(100vw - ${leftPanelWidth}px)
    `;
  }};

  grid-template-areas: "s b";
  width: 100%;
  height: 100%;
`;

const NavbarWrapper = styled.div`
  display: grid;
  background-color: var(--white);
  grid-template-rows: min-content auto;

  grid-template-columns: 100vw;

  height: 100vh;
  width: 100vw;

  grid-template-areas:
    "n n"
    "b c";
`;

const LeftSidebar = styled.div`
  grid-area: s;
  z-index: 12;
`;

const Body = styled.div<{ backgroundColor?: string }>`
  grid-area: b;
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : 'var(--grey0)')};
  overflow: auto;
  height: 100%;
`;

const SynqPanel = styled.div`
  height: 100%;

  & > div#overlay-synq {
    justify-content: center;
    width: 100%;
  }

  & > div#overlay-synq div#synq-div {
    height: calc(100vh - 80px) !important;
  }

  & > div#overlay-synq div#synq-div > div {
    width: 352px;
    padding: 0 8px;
  }
`;

const FullScreenWrapper = styled.div<{ showFullScreen?: boolean, normalHeight?: number }>`
  ${(props) => (props.showFullScreen
    ? css`
      position: absolute;
      top: 56px;
      left: 0;
      width: 100vw;
      z-index: 999;
      height: calc(100vh - 56px);
      min-height: calc(100vh - 56px);
      max-height: calc(100vh - 56px);
      background-color: var(--white);
    `
    : css`
      height: ${props.normalHeight}px;
    `)
}
`;

export {
  Page,
  NavbarWrapper,
  LeftSidebar,
  Body,
  SynqPanel,
  FullScreenWrapper,
};
