import React from 'react';
import PageTemplate from '../Utils/PageTemplate';
import ConnectRepo from '../../../Common/ConnectRepo';
import { ONBOARDING_PAGE, OnboardingRoutePrefix } from '../..';

const ChooseRepoProvider = () => (
  <PageTemplate>
    <ConnectRepo
      connectRepoNextStepUrl={`/${OnboardingRoutePrefix}/${ONBOARDING_PAGE.CONNECT_REPO}`}
    />
  </PageTemplate>
);

export default ChooseRepoProvider;
