import { useEffect, useState } from 'react';
import { userAuthStore } from '../../../stores';
import {
  useGetMergeTicketLazyQuery,
  useGetMergeTicketCollectionsLazyQuery,
  useGetMergeTicketUsersLazyQuery,
  useCreateMergeTicketMutation,
  MergeUserDetails,
  MergeCollectionDetails,
} from '../../../client/generated/service';

type TicketUserType = Omit<MergeUserDetails, '__typename'>;
type TicketCollectionType = Omit<MergeCollectionDetails, '__typename'>;

const useManageTickets = (onTicketCreateSuccess?: () => void) => {
  const currentUser = userAuthStore((s) => s.currentUser);
  const [errorDetails, setErrorDetails] = useState<string>();
  const [ticketUrl, setTicketUrl] = useState<string>();
  const [viewTicketUrl, setViewTicketUrl] = useState<string>();
  const [ticketUsers, setTicketUsers] = useState<TicketUserType[]>([]);
  const [ticketCreator, setTicketCreator] = useState<TicketUserType>();
  const [ticketCollections, setTicketCollections] = useState<TicketCollectionType[]>([]);
  const [isIntegrationConnected, setIsIntegrationConnected] = useState(false);
  const [integrationName, setIntegrationName] = useState<string>();
  const [isLoadingCreateTicketFormData, setIsLoadingCreateTicketFormData] = useState(false);
  const [isLoadingViewTicketData, setIsLoadingViewTicketData] = useState(false);

  const [getTicket, { loading: isLoadingTicketData }] = useGetMergeTicketLazyQuery({
    onCompleted: (data) => {
      if (data.getMergeTicket?.mergeTicketUrl) {
        setViewTicketUrl(data.getMergeTicket.mergeTicketUrl);
      }

      if (data.getMergeTicket?.integrationIsConnected) {
        setIsIntegrationConnected(true);
      }

      if (data.getMergeTicket?.integrationName) {
        setIntegrationName(data.getMergeTicket.integrationName);
      }
    },
  });

  const [getTicketUsers, { loading: isLoadingTicketUsers }] = useGetMergeTicketUsersLazyQuery({
    onCompleted: (data) => {
      if (data.getMergeTicketUsers?.users) {
        setTicketUsers(data.getMergeTicketUsers.users);
        const creator = data.getMergeTicketUsers.users.filter(
          (user) => user.email === currentUser.email,
        );
        if (creator) {
          setTicketCreator(creator[0]);
        }
      }
    },
  });

  const [
    getTicketCollections, { loading: isLoadingTicketCollections },
  ] = useGetMergeTicketCollectionsLazyQuery({
    onCompleted: (data) => {
      if (data.getMergeTicketCollections?.collections) {
        setTicketCollections(data.getMergeTicketCollections.collections);
      }
    },
  });

  const [createTicket, { loading: isTicketCreating }] = useCreateMergeTicketMutation({
    onCompleted: (data) => {
      if (data.createMergeTicket?.ok && data.createMergeTicket.ticketUrl) {
        setTicketUrl(data.createMergeTicket.ticketUrl);
        if (onTicketCreateSuccess) onTicketCreateSuccess();
      }

      if (data.createMergeTicket?.errorLog) {
        setErrorDetails(data.createMergeTicket.errorLog);
      }
    },
  });

  useEffect(() => {
    setIsLoadingCreateTicketFormData(isLoadingTicketCollections && isLoadingTicketUsers);
  }, [isLoadingTicketCollections, isLoadingTicketUsers]);

  useEffect(() => {
    setIsLoadingViewTicketData(
      isLoadingTicketCollections || isLoadingTicketUsers || isLoadingTicketData,
    );
  }, [isLoadingTicketCollections, isLoadingTicketUsers, isLoadingTicketData]);

  return {
    viewTicketUrl,
    setViewTicketUrl,
    integrationName,
    isIntegrationConnected,
    ticketUsers,
    ticketCollections,
    ticketCreator,
    ticketUrl,
    errorDetails,
    isLoadingCreateTicketFormData,
    isLoadingViewTicketData,
    isLoadingTicketData,
    isTicketCreating,
    getTicket,
    getTicketUsers,
    getTicketCollections,
    createTicket,
  };
};

export { useManageTickets };
