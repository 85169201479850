import styled, { createGlobalStyle } from 'styled-components';
import { Icon } from '@blueprintjs/core';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';

export const CodeTypography = styled(Typography)`
& > code {
  font-family: 'Courier New', Courier, monospace;
  background-color: var(--grey5);
  color: var(--grey100);
  font-weight: 500;
  padding: 2px 4px;
  border-radius: 4px;
}
`;

export const DialogCodeBoxWrapper = styled.div`
  height: 100%;
  width: 100%;
  & > div > div {
      height: 100%;
    }
`;

export const TemplateGridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 8px;
  column-gap: 8px;
`;

export const TemplateCardWrapper = styled.div`
  border-radius: 8px;
  border: 1px solid var(--grey10);
  background: var(--white);
  transition: background-color 0.2s ease-out;

  &:hover {
    border: 1px solid #453F9C;
    background-color: var(--violet5);
    box-shadow: var(--shadowViolet8dp);
  }
`;

export const TemplateLogoWrapper = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  padding: 8px;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--grey10);
`;

export const TemplateLearnMoreIcon = styled(Icon)`
  padding: 12px;
  &:hover {
    cursor: pointer;
  }
`;

export const HelpCardStyle = createGlobalStyle`
  .webhook-connect .wmde-markdown > h3 > a {
    display: none;
  }
`;

export const CustomNewScheduleWrapper = styled.div`
  border-radius: 8px;
  cursor: pointer;
  background: linear-gradient(161deg, #034545 53.7%, #33A9A9 112.47%);

  &:hover {
    /* Shadow Dark / 8dp */
    box-shadow: 0px 4px 8px 0px rgba(58, 58, 68, 0.16), 0px 8px 16px 0px rgba(90, 91, 106, 0.16);
  }
`;
