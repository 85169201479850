import React, { FC } from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { userAuthStore } from '../../../stores';
import ButtonsRow from './ButtonsRow';
import trialExpired from './images/trialExpired.svg';

const TrialExpired: FC = () => {
  const { currentUser: { name } } = userAuthStore.getState();
  const usersName = name?.split(' ')?.[0];

  return (
    <AutoLayout
      direction="vertical"
      padding="none"
      verticalGap="expanded"
      distribution="packed"
      style={{ maxWidth: '470px' }}
    >
      <AutoLayout
        direction="vertical"
        padding="none"
        verticalGap="very-dense"
        distribution="packed"
      >
        <img src={trialExpired} alt="Your trial has expired" style={{ margin: 'auto' }} />
        <Typography
          font="inter"
          type="h5"
          style={{ textAlign: 'center' }}
        >
          {`${usersName ? `${usersName}, we` : 'We'} hope you enjoyed your trial`}
        </Typography>
        <Typography
          font="inter"
          type="body-bold-small"
          color="default"
          colorStep="50"
          style={{ textAlign: 'center' }}
        >
          Your 14-day free trial has ended. We hope you&apos;ve enjoyed Paradime.
          It&apos;s time to select your plan and continue your journey with us.
        </Typography>
      </AutoLayout>
      <ButtonsRow />
    </AutoLayout>
  );
};

export default TrialExpired;
