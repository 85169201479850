import { useCommonConfig, SnowflakeFormDataType } from './CommonConfig';
import { formDistribution, initialValues, formFields } from './costsFormFields';
import { useDatabaseHookProps } from '../../utils';

export type { SnowflakeFormDataType };

export const useDatabaseHook = ({
  formData,
  env,
  source,
  isEditable,
  isAdditionalUser,
  isNewConnection,
}: useDatabaseHookProps) => {
  const commonConfigs = useCommonConfig({
    formData,
    env,
    source,
    isEditable,
    isAdditionalUser,
    isNewConnection,
  });

  return {
    ...commonConfigs,
    initialFormData: initialValues,
    formDistribution: formDistribution(commonConfigs.selectedAuthMethod),
    form: (
      formFields(
        commonConfigs.selectedAuthMethod,
        commonConfigs.setSelectedAuthMethod,
      )
    ),
  };
};
