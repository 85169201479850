import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Plain } from '../../Plain';
import { CommandItemProps } from '../CommandItem';
import { triggerToast } from '../../../../../utilis';
import { useRestartEditorMutation } from '../../../../../client/generated/editor';

const useGetTopLevelCommands = () => {
  const history = useHistory();
  const [restartEditor] = useRestartEditorMutation();
  const [pages, setPages] = useState<string[]>([]);

  const handleRestartEditor = () => (
    restartEditor().then((ok) => {
      if (ok) {
        triggerToast({
          header: 'Restarting the editor',
          type: 'positive',
          message: 'We are about to restart the Code IDE - this will take a few seconds. Hang tight!',
        });
        setTimeout(() => {
          history.push('/home');
          window.location.reload();
        }, 3000);
      } else {
        triggerToast({
          header: 'Restarting the editor',
          type: 'attention',
          message: 'We couldn\'t restart the Code IDE. Please try again or contact support.',
        });
      }
    })
  );

  const topLevelCommands = [
    {
      icon: 'code',
      children: 'Open Code IDE',
      shortcut: {
        key: 'c',
        metaKey: true,
        shiftKey: true,
      },
      nested: false,
      onSelect: () => history.push('/editor'),
    },
    {
      icon: 'reset',
      children: 'Restart Code IDE',
      nested: false,
      onSelect: () => handleRestartEditor(),
    },
    {
      icon: 'lightning',
      children: 'Open Bolt Schedules',
      shortcut: {
        key: 'b',
        metaKey: true,
      },
      nested: false,
      onSelect: () => history.push('/bolt'),
    },
    {
      icon: 'flash',
      children: 'View Bolt Schedule',
      nested: true,
      onSelect: () => setPages([...pages, 'bolt']),
    },
    {
      icon: 'dashboard',
      children: 'Open Radar',
      nested: true,
      onSelect: () => setPages([...pages, 'radar']),
    },
    {
      icon: 'lightning',
      children: 'Open Data Catalog',
      nested: false,
      onSelect: () => history.push('/catalog/search'),
    },
    {
      icon: 'cog',
      children: 'Open Settings',
      nested: true,
      onSelect: () => setPages([...pages, 'settings']),
    },
    {
      icon: 'help',
      children: 'Open In-App Chat',
      nested: false,
      onSelect: () => Plain.open(),
    },
  ] as CommandItemProps[];

  return {
    topLevelCommands,
    pages,
    setPages,
  };
};

export { useGetTopLevelCommands };
