import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';

const ConfirmRequestAccess: FC = () => {
  const { state } = useLocation<{videoUrl: string}>();
  const videoUrl = state?.videoUrl;

  return (
    <AutoLayout
      direction="horizontal"
      distribution="space-between"
      padding="none"
    >
      <AutoLayout
        direction="vertical"
        alignment="top-left"
        padding="dense"
        distribution="space-between"
        style={{
          maxWidth: '350px',
          paddingBottom: '0',
          alignItems: 'flex-end',
        }}
      >
        <AutoLayout
          direction="vertical"
          alignment="top-left"
          verticalGap="none"
          padding="none"
          distribution="packed"
          style={{
            rowGap: '64px',
          }}
        >
          <Typography tagName="span" type="h6" color="default">✨ Access Request</Typography>
          <Typography tagName="span" type="body" color="default" colorStep="50">
            We have sent your request to an admin at your company for review.&nbsp;
            Meanwhile, check out our interactive demo or to learn more, ping us.
          </Typography>
        </AutoLayout>
        <DefaultButton
          view="filled"
          type="standard"
          color="primary"
          text="Speak with us"
          onClick={() => window.open('https://cal.com/kaustav/paradime-support', '_blank')}
          eventContext={Contexts.PLATFORM}
          eventObject="contactUpgradeParadime"
          eventAction={Actions.CLICKED}
          style={{ width: '200px' }}
        />
      </AutoLayout>
      <AutoLayout
        direction="vertical"
        alignment="top-left"
        verticalGap="none"
        padding="none"
        distribution="packed"
        style={{
          rowGap: '64px',
          minWidth: '600px',
        }}
      >
        {
          videoUrl && (
            <div
              style={{
                position: 'relative',
                paddingBottom: 'calc(66.66666666666666% + 41px)',
                height: '0',
                width: '100%',
                flex: '1 1 auto',
                display: 'flex',
              }}
            >
              <iframe
                src={videoUrl}
                frameBorder="0"
                loading="lazy"
                allowFullScreen
                style={{
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  width: '100%',
                  height: '100%',
                  colorScheme: 'light',
                }}
                title="Paradime | Product confirm access arcade video"
              />
            </div>
          )
        }
      </AutoLayout>
    </AutoLayout>
  );
};

export default ConfirmRequestAccess;
