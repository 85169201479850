import React from 'react';
import { colorType } from '@paradime-io/pragma-ui-kit/lib/components/styles/styleTypes';
import { lineageNodeDisplayProps } from '../types';
import { decodeLogoBase64, translateBEColourToFEColour } from '../utils';

export const lineageNodeDisplay = ({ lineageData }: lineageNodeDisplayProps) => ({
  nodePrimaryLogo: (
    <img
      src={decodeLogoBase64(lineageData?.logoBase64)}
      alt="source logo"
      width={16}
      height={16}
      style={{
        backgroundColor: 'var(--grey0)',
        borderRadius: '50%',
        border: '2px solid var(--grey90)',
      }}
    />
  ),
  nodeColor: translateBEColourToFEColour(lineageData?.color).variantName as colorType || 'success' as colorType,
  nodeTypeName: lineageData?.nodeTypeLabel || '',
});
