import React from 'react';
import { catalogueTreeIconsProps } from '../types';
import LookerLogo from '../../components/Catalog/images/looker.svg';

export const catalogueTreeIcons = ({ type }: catalogueTreeIconsProps) => {
  switch (type) {
    case 'looker':
      return <img src={LookerLogo} alt="" />;
    case 'schema':
      return 'layout-auto';
    case 'dashboards':
    case 'explores':
    case 'looks':
      return 'folder-close';
    case 'explore':
      return 'compass';
    case 'look':
      return 'pie-chart';
    case 'dashboard':
      return 'control';
    default:
      return 'database';
  }
};
