import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { SHA256, enc } from 'crypto-js';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useIntercom } from 'react-use-intercom';
import { isDevEnvironment, genericObject } from '../../utilis';
import { CommandBarInit, CommandBarRouterInit } from '../../components/Common/Libraries/CommandBar';
import { useGetProfileQuery } from '../../client/generated/service';
import { companyStore, userAuthStore, appStore } from '../../stores';
import { useRestartEditorMutation } from '../../client/generated/editor';

const useSetUpCommandBar = (user?: genericObject) => {
  // Get the LaunchDarkly Feature Flags
  const featureFlags = useFlags();
  const { showNewMessage } = useIntercom();
  const [restartEditor] = useRestartEditorMutation();

  const history = useHistory();
  const isExtensionOnlyCompany = companyStore((state) => state.isExtensionOnlyCompany);
  const currentUser = userAuthStore((s) => s.currentUser);
  const setUser = userAuthStore((s) => s.setUser);
  const { commandBarInitialized, setCommandBarInitialized } = appStore.getState();

  const { data: profileData } = useGetProfileQuery({
    onCompleted: ({ userIdentity }) => {
      if (userIdentity?.userInformation) {
        setUser({ ...currentUser, ...userIdentity.userInformation });
      }
    },
  });

  useEffect(() => {
    if (user?.email && !commandBarInitialized) {
      const userId = SHA256(user.email || '').toString(enc.Hex);
      const emailDomain = user.email.substring(user.email.lastIndexOf('@') + 1);
      CommandBarInit({
        id: isDevEnvironment ? 'demotrooper@paradime.io' : userId,
        userAttributes: {
          type: user.accessLevel,
          emailDomain,
          companyName: profileData?.userIdentity?.userInformation?.companyName || 'anonymous',
        },
        history,
        showNewMessage,
        featureFlags,
        isExtensionOnlyCompany,
        restartEditor: () => (
          restartEditor()
            .then(({ data: heartbeatData }) => heartbeatData?.editorHeartbeat?.ok || false)
        ),
      });
      CommandBarRouterInit(history);
      setCommandBarInitialized(true);
    }
  }, [user]);
};

export default useSetUpCommandBar;
