import { useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useHistory } from 'react-router-dom';

export const useFeatureFlagRedirects = () => {
  const history = useHistory();

  const { flagAppAccountBlocked } = useFlags();

  const { pathname } = window.location;

  useEffect(() => {
    if (flagAppAccountBlocked && pathname !== '/') {
      history.push('/account-blocked');
    }
  }, [flagAppAccountBlocked]);
};
