import React, { Dispatch, FC, SetStateAction } from 'react';
import { useParams } from 'react-router-dom';
import InAppVideoButton from '@paradime/common/lib/common/helpers/InAppVideoButton';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Chips from '@paradime-io/pragma-ui-kit/lib/components/Chips';
import { GqlParadimeAccountType } from '../../client/generated/service';
import { DashboardTabIds } from './config';
import { openHelpHub } from '../Common/AppHelp/utils';

export interface DashboardHeaderProps {
  isEditMode: boolean,
  setIsEditMode: Dispatch<SetStateAction<boolean>>,
  title: string,
  subtitle?: string,
  helpArticleId?: string,
  accessLevel: GqlParadimeAccountType,
  customDashboardsIsEnabled: boolean,
  hasPaywall: boolean,
  showHeaderButton?: boolean,
  videoUrl?: string,
  tabSpecificVideoUrls?: { [tabId in DashboardTabIds]?: string },
}

const DashboardHeader:FC<DashboardHeaderProps> = ({
  title,
  subtitle,
  helpArticleId,
  videoUrl,
  tabSpecificVideoUrls,
}) => {
  const { tabId } = useParams<{tabId: DashboardTabIds}>();

  const tutorialUrl = videoUrl || tabSpecificVideoUrls?.[tabId];

  return (
    <AutoLayout
      direction="vertical"
      padding="none"
      verticalGap="ultra-dense"
    >
      <AutoLayout
        direction="horizontal"
        padding="none"
        distribution="space-between"
        style={{ alignItems: 'center' }}
      >
        <AutoLayout
          direction="horizontal"
          padding="none"
          distribution="packed"
          horizontalGap="very-dense"
        >
          <Typography type="h6">{title}</Typography>
          {helpArticleId && (
          <Chips
            color="primary_alt"
            round={false}
            style={{ margin: '4px', verticalAlign: 'bottom', justifySelf: 'end' }}
            tag="Learn more"
            type="dense"
            view="smooth"
            onClick={() => (
              openHelpHub({ articleId: helpArticleId })
            )}
          />
          )}
        </AutoLayout>
        <AutoLayout
          direction="horizontal"
          padding="none"
          distribution="packed"
          horizontalGap="very-dense"
        >
          {tutorialUrl && (
            <InAppVideoButton
              tutorial={{
                title: 'Radar tutorial',
                description: 'The best tutorial that there is on Radar',
                videoUrl: tutorialUrl,
              }}
            />
          )}
        </AutoLayout>
      </AutoLayout>
      {subtitle && (
        <Typography type="caption" colorStep="50">
          {subtitle}
        </Typography>
      )}
    </AutoLayout>
  );
};

export default DashboardHeader;
