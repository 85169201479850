import React from 'react';
import PageTemplate from '../../Onboarding/SynqOnboarding/Utils/PageTemplate';

const SlackSuccess = () => {
  const { opener } = window;
  const location = new URL(window.location.href);
  const code = location.searchParams.get('code');

  if (opener) {
    opener.postMessage({
      code,
    }, '*');
  }

  return (
    <PageTemplate
      withLogo
      icon={{ name: 'tick-circle' }}
      title="Slack connected!"
      titleTextType="h4"
      subtitle="You can close this tab"
      subtitleTextType="body"
      withBox={false}
      containerStyles={{ width: '100vw' }}
      contentTopPadding="150px"
    />
  );
};

export default SlackSuccess;
