import create from 'zustand';
import { combine } from 'zustand/middleware';
import { CompanyOnboardingMutation } from '../client/generated/control-plane';
import { GqlAvailableWorkspace, StripeAccountStatus } from '../client/generated/service';

export interface companyEndpoints {
  dataGraphqlUrl?: string,
  dbGraphqlUrl?: string
  editorGraphqlUrl?: string,
  serviceGraphqlUrl?: string,
  token: string
}

export type companyType = companyEndpoints & {
  controlPlaneUrl?: string,
  jwtToken?: string,
} | undefined

type companyOnboardingType = NonNullable<CompanyOnboardingMutation['companyOnboarding']>

export type RepoProviderType = 'bitbucket' | 'github' | 'gitlab' | 'azureDevOps' | undefined;

type CompanyPlanType = {
  accountStatus: StripeAccountStatus,
  planNames: string[],
  trialEndDateSeconds?: number | null,
  daysLeftInTrialFormattedString: string | null,
  isTrial: boolean,
  isActive: boolean,
  hasPricingPlan: boolean,
  trialHasExpired: boolean,
  userHasCancelled: boolean,
};

export type HomeConfigCardType = {
  repoProvider: RepoProviderType,
  gitRepositoryUri: string,
  dbtVersion: string,
  latestDbtVersionAvailable: string,
  isProdWarehouseConnected: boolean,
  isDevWarehouseConnected: boolean,
  devWarehouseType: string,
}

export const companyStore = create(
  combine(
    {
      currentTeamSize: '1-5',
      needsRegister: true,
      initialized: false,
      repoProvider: undefined as RepoProviderType,
      homeConfigCardData: undefined as HomeConfigCardType | undefined,
      warehouseData: {} as { [key: string]: any },
      onboardingUserCompanyAssigned: undefined as undefined | companyEndpoints,
      addNewUserToCompany: undefined as undefined | string,
      additionalUserTokens: undefined as undefined | { token: string, userToken: string },
      onboardingToken: '',
      companyOnboardingData: {} as companyOnboardingType,
      isDwhSetupComplete: undefined as undefined | boolean,
      isGitSetupComplete: undefined as undefined | boolean,
      isBasicSetupComplete: undefined as undefined | boolean,
      snowflakeCallbackUrl: '',
      defaultBranchName: undefined as string | undefined,
      bigqueryCallbackUrl: '',
      companyRegion: '',
      isExtensionOnlyCompany: undefined as boolean | undefined,
      isFirstAdmin: undefined as boolean | undefined,
      availableAutoJoinWorkspaces: [] as GqlAvailableWorkspace[],
      needsStripe: false,
      companyPlan: undefined as undefined | CompanyPlanType,
      isDevWarehouseConnected: undefined as boolean | undefined,
      isProdWarehouseConnected: undefined as boolean | undefined,
      isCostWarehouseConnected: undefined as boolean | undefined,
      devWarehouseType: '',
      prodWarehouseType: '',
      costWarehouseType: '',
      companyCreationInProgress: false,
    },
    (set) => ({
      setCompanyOnboardingData: (
        newData: companyOnboardingType,
      ) => set(() => ({ companyOnboardingData: newData })),
      updateCompanyOnboardingData: (
        newData: companyOnboardingType,
      ) => set((s) => ({ companyOnboardingData: { ...s, ...newData } })),
      setOnboardingToken: (newToken: string) => set(() => ({ onboardingToken: newToken })),
      setCurrentTeamSize: (newTeamSize: string) => set(() => ({ currentTeamSize: newTeamSize })),
      setNeedsRegister: (newStatus: boolean) => set(() => ({ needsRegister: newStatus })),
      setInitialised: (newStatus: boolean) => set(() => ({ initialized: newStatus })),
      setRepoProvider: (newRepoProvider: RepoProviderType) => set(() => (
        { repoProvider: newRepoProvider })),
      setHomeConfigCardData: (
        homeConfigCardData: HomeConfigCardType,
      ) => set(() => ({ homeConfigCardData })),
      fireOnboardingUserCompanyAssigned: (endpoints?: companyEndpoints) => set(() => (
        { onboardingUserCompanyAssigned: endpoints })),
      setAddNewUserToCompany: (companyToken: string) => set(() => ({
        addNewUserToCompany: companyToken,
      })),
      setAdditionalUserTokens: (tokens: { token: string, userToken: string }) => set(() => ({
        additionalUserTokens: tokens,
      })),
      setIsDwhSetupComplete: (newState?: boolean) => set(() => ({ isDwhSetupComplete: newState })),
      setIsGitSetupComplete: (newState?: boolean) => set(() => ({ isGitSetupComplete: newState })),
      setIsBasicSetupComplete: (newState?: boolean) => set(
        () => ({ isBasicSetupComplete: newState }),
      ),
      setSnowflakeCallbackUrl: (callbackUrl: string) => set(() => ({
        snowflakeCallbackUrl: callbackUrl,
      })),
      setDefaultBranch: (defaultBranchName: string) => set(() => ({ defaultBranchName })),
      setBigqueryCallbackUrl: (callbackUrl: string) => set(() => ({
        bigqueryCallbackUrl: callbackUrl,
      })),
      setCompanyRegion: (region: string) => set(() => ({ companyRegion: region })),
      setIsExtensionOnlyCompany: (isExtensionOnlyCompany: boolean) => set(() => ({
        isExtensionOnlyCompany,
      })),
      setIsFirstAdmin: (isFirstAdmin: boolean) => set(() => ({ isFirstAdmin })),
      setAvailableAutoJoinWorkspaces: (workspaces: GqlAvailableWorkspace[]) => set(
        () => ({ availableAutoJoinWorkspaces: workspaces }),
      ),
      setNeedsStripe: (needsStripe: boolean) => set(() => ({ needsStripe })),
      setCompanyPlan: (plan: CompanyPlanType) => set(() => ({ companyPlan: plan })),
      setIsDevWarehouseConnected: (isDevWarehouseConnected: boolean) => (
        set(() => ({ isDevWarehouseConnected }))
      ),
      setIsProdWarehouseConnected: (isProdWarehouseConnected: boolean) => (
        set(() => ({ isProdWarehouseConnected }))
      ),
      setIsCostWarehouseConnected: (isCostWarehouseConnected: boolean) => (
        set(() => ({ isCostWarehouseConnected }))
      ),
      setDevWarehouseType: (devWarehouseType: string) => (
        set(() => ({ devWarehouseType }))
      ),
      setProdWarehouseType: (prodWarehouseType: string) => (
        set(() => ({ prodWarehouseType }))
      ),
      setCostWarehouseType: (costWarehouseType: string) => (
        set(() => ({ costWarehouseType }))
      ),
      setCompanyCreationInProgress: (isInProgress: boolean | null | undefined) => (
        set(() => ({ companyCreationInProgress: isInProgress || false }))
      ),
    }),
  ),
);
