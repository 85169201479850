// from: @segment/analytics-next/src/plugins/page-enrichment/index.ts

interface PageDefault {
  path: string;
  referrer: string;
  search: string;
  title: string;
  url: string;
}

const canonical = (): string => {
  const tags = document.getElementsByTagName('link');
  // eslint-disable-next-line no-restricted-syntax
  for (const tag of tags as any) {
    if (tag.getAttribute('rel') === 'canonical') {
      const href = tag.getAttribute('href');
      if (href) return href;
    }
  }
  return '';
};

const canonicalPath = (): string => {
  const canon = canonical();
  if (!canon) {
    return window.location.pathname;
  }

  const a = document.createElement('a');
  a.href = canon;
  return a.pathname.startsWith('/') ? a.pathname : `/${a.pathname}`;
};

const canonicalUrl = (search: string = ''): string => {
  const canon = canonical();
  if (canon) {
    return canon.includes('?') ? canon : `${canon}${search}`;
  }
  const url = window.location.href;
  const hashIndex = url.indexOf('#');
  return hashIndex === -1 ? url : url.slice(0, hashIndex);
};

const blankIfLocalhost = (url: string): string => (url.includes('localhost') ? '' : url);

export const pageDefaults = (): PageDefault => ({
  path: canonicalPath(),
  referrer: document.referrer,
  search: document.location.search,
  title: document.title,
  url: blankIfLocalhost(canonicalUrl(document.location.search)),
});
