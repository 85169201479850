import React, { FC, useState } from 'react';
import Button from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import CancelRunDialog, { CancelDialogRunInfo } from './CancelRunDialog';
import { useCancelRunMutation, useGetScheduleNameLazyQuery, useGetScheduleRunQuery } from '../../../client/generated/service';

type getScheduleNamesFunction = ReturnType<typeof useGetScheduleNameLazyQuery>[0];
type refetchRunFunction = ReturnType<typeof useGetScheduleRunQuery>['refetch']

interface CancelButtonProps {
  ID?: number,
  scheduleName?: string,
}

interface useCancelRunButtonProps {
  getScheduleNames?: getScheduleNamesFunction,
  setTimeUntilRefresh?: (time: number) => void,
  DEFAULT_REFRESH_INTERVAL?: number,
  refetchRunFunction?: refetchRunFunction,
}

interface useCancelRunButtonReturn {
  CancelRunDialog: JSX.Element,
  CancelButton: FC<CancelButtonProps>,
  onCancelClick: (props: CancelButtonProps) => void,
}

type useCancelRunButtonFunction = (props?: useCancelRunButtonProps) => useCancelRunButtonReturn;

const useCancelRunButton: useCancelRunButtonFunction = ({
  getScheduleNames,
  setTimeUntilRefresh,
  DEFAULT_REFRESH_INTERVAL,
  refetchRunFunction,
} = {}) => {
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const [runInfoToCancel, setRunInfoToCancel] = useState<CancelDialogRunInfo | null>(null);
  const [cancelRunLoading, setCancelRunLoading] = useState(false);

  const [cancelRun] = useCancelRunMutation();

  const handleCancelRunDialogOpen = (runInfo: CancelDialogRunInfo) => {
    setRunInfoToCancel(runInfo);
    setIsCancelDialogOpen(true);
  };

  const handleCancelRun = async (runID: number) => {
    setCancelRunLoading(true);
    await cancelRun({
      variables: {
        scheduleRunId: runID,
      },
    });
    setIsCancelDialogOpen(false);
    setCancelRunLoading(false);
    setRunInfoToCancel(null);
    if (refetchRunFunction) refetchRunFunction();
    if (getScheduleNames) getScheduleNames();
    if (setTimeUntilRefresh && DEFAULT_REFRESH_INTERVAL) {
      setTimeUntilRefresh(DEFAULT_REFRESH_INTERVAL);
    }
  };

  const handleCancelRunDialogClose = () => {
    setRunInfoToCancel(null);
    setIsCancelDialogOpen(false);
  };

  return {
    CancelRunDialog: (
      <CancelRunDialog
        isOpen={isCancelDialogOpen}
        handleCancelRun={handleCancelRun}
        runInfo={runInfoToCancel}
        onClose={handleCancelRunDialogClose}
        cancelRunLoading={cancelRunLoading}
      />
    ),
    CancelButton: ({ ID, scheduleName }) => (
      <Button
        color="danger"
        type="standard"
        view="outlined"
        dense
        text="Cancel Run"
        icon="cross"
        eventContext={Contexts.BOLT}
        eventObject="openCanceScheduleRunDialog"
        eventAction={Actions.OPENED}
        onClick={() => handleCancelRunDialogOpen({
          ID,
          scheduleName,
        })}
      />
    ),
    onCancelClick: ({ ID, scheduleName }: CancelButtonProps) => handleCancelRunDialogOpen({
      ID,
      scheduleName,
    }),
  };
};

export default useCancelRunButton;
