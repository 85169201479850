import { DateTime } from 'luxon';

export const formatCurrency = (amount: number, useCompactNotation?: boolean) => {
  const formatter = Intl.NumberFormat('en', {
    style: 'currency',
    currency: 'USD',
    // @ts-ignore - "notation" seems to be a fairly new feature
    notation: (useCompactNotation && amount > 1000) ? 'compact' : 'standard',
    maximumFractionDigits: (useCompactNotation && amount < 1000) ? 0 : undefined,
    minimumFractionDigits: (useCompactNotation && amount < 1000) ? 0 : undefined,
  });

  return (
    formatter.format(amount)
  );
};

export const getFirstDayOfWeek = (year: number, weekNumber: number) => (
  DateTime.fromISO(`${year}-W${weekNumber.toString().padStart(2, '0')}-1`)
);

export interface listDatesProps {
  daysAgo: number,
  groupByWeek?: boolean,
  dateFormatterString?: string,
}

export const listDates = ({
  daysAgo,
  groupByWeek,
  dateFormatterString = 'yyyy-LL-dd',
}: listDatesProps) => {
  const now = DateTime.now();
  const emptyArray = Array.from({ length: daysAgo }, (val, index) => daysAgo - index);
  const dates = emptyArray.map((daysBack) => (
    now
      .minus({ days: daysBack })
      .toFormat(dateFormatterString)
  ));

  if (groupByWeek) {
    // Get a list of all the week numbers (also the year - will need that later)
    const weeks = dates.map((date) => {
      const { weekNumber, year } = DateTime.fromFormat(date, dateFormatterString);
      return { weekNumber, year };
    });

    // Deduplicate the list so that each week number only appears once
    const deduped = weeks.filter((w1, index, array) => {
      const indexOfFirstItem = array.findIndex((w2) => (w2.weekNumber === w1.weekNumber));
      return indexOfFirstItem === index;
    });

    // Fetch the date of the Monday from each week in the list
    const weekStartDates = deduped.map(({ weekNumber, year }) => (
      getFirstDayOfWeek(year, weekNumber).toFormat(dateFormatterString)
    ));

    return weekStartDates;
  }

  return dates;
};
