/* eslint-disable no-nested-ternary */
import React, { FC, useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { useHistory } from 'react-router-dom';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Spinner from '@paradime-io/pragma-ui-kit/lib/components/Spinner';
import TicketForm from './TicketForm';
import { AccountSettingsTab } from '../../../NavBar';
import { userHasAccountSettingsIntegrationsUpdateAccess } from '../../../../utilis/PermissionsService';
import { GetScheduleRunQuery } from '../../../../client/generated/service';
import jira from '../../../AccountSettings/Integrations/images/jira.svg';
import asana from '../../../AccountSettings/Integrations/images/asana.svg';
import linear from '../../../AccountSettings/Integrations/images/linear.png';
import useGetTicketData from '../../hooks/useGetTicketData';

interface CreateTicketProps {
  runId?: number
  runData?: GetScheduleRunQuery,
}

const CreateTicket:FC<CreateTicketProps> = ({
  runId,
  runData,
}) => {
  const history = useHistory();
  const [isTicketFormVisible, setIsTicketFormVisible] = useState(false);
  const {
    integrationName,
    viewTicketUrl,
    setViewTicketUrl,
    ticketTitle,
    ticketDescription,
    ticketButtonState,
    setTicketButtonState,
  } = useGetTicketData(runId, runData);

  const getIntegrationIcon = () => {
    if (integrationName) {
      switch (integrationName) {
        case 'jira':
          return jira;
        case 'asana':
          return asana;
        case 'linear':
          return linear;
        default:
          return jira;
      }
    }

    return undefined;
  };

  const TicketButtonReactNode = () => {
    switch (ticketButtonState) {
      case 'loading':
        return (
          <Spinner width={16} height={16} thin />
        );
      case 'not-connected':
        return (
          <DefaultButton
            eventContext={Contexts.BOLT}
            eventObject="connectTicketIntegrationButton"
            eventAction={Actions.CLICKED}
            icon="intersection"
            dense
            disabled={!userHasAccountSettingsIntegrationsUpdateAccess}
            view="filled"
            text="Connect Ticket Integration"
            color="primary"
            onClick={() => {
              history.push(
                `/account-settings/${AccountSettingsTab.INTEGRATIONS}`,
                { highlightId: 'ticketing-integration' },
              );
            }}
          />
        );
      case 'view-ticket':
      case 'create-ticket':
        return (
          <DefaultButton
            eventContext={Contexts.BOLT}
            eventObject={viewTicketUrl ? 'viewTicketButton' : 'createTicketButton'}
            eventAction={Actions.CLICKED}
            icon={<img src={getIntegrationIcon()} width={16} alt="jira" />}
            dense
            view="outlined"
            text={viewTicketUrl ? 'View ticket' : 'Create ticket'}
            color="default"
            // disabled={!runId}
            onClick={() => {
              if (viewTicketUrl) {
                window.open(viewTicketUrl, '_blank');
              } else {
                setIsTicketFormVisible(true);
              }
            }}
            style={{ backgroundColor: 'var(--white)' }}
          />
        );
      default:
        return (
          <Spinner width={16} height={16} thin />
        );
    }
  };

  return (
    <>
      {TicketButtonReactNode()}
      <Sidebar
        visible={isTicketFormVisible}
        position="right"
        onHide={() => setIsTicketFormVisible(false)}
        style={{ width: '600px' }}
        header={(
          <AutoLayout
            direction="horizontal"
            distribution="packed"
            horizontalGap="very-dense"
            padding="none"
            style={{ alignItems: 'center' }}
          >
            <img src={jira} alt="jira" width={16} />
            <Typography type="h6" color="default" tagName="span">
              Create ticket
            </Typography>
          </AutoLayout>
        )}
        dismissable={false}
      >
        <TicketForm
          runId={runId}
          title={ticketTitle}
          description={ticketDescription}
          viewTicketUrl={viewTicketUrl}
          setViewTicketUrl={setViewTicketUrl}
          setTicketFormVisible={setIsTicketFormVisible}
          onSuccess={() => setTicketButtonState('view-ticket')}
          onCancel={() => setIsTicketFormVisible(false)}
        />
      </Sidebar>
    </>
  );
};

export default CreateTicket;
