import { useEffect, useState } from 'react';
import { GetScheduleRunQuery } from '../../../client/generated/service';
import { formatDateTimeToLocalString } from '../../../utilis';
import { graphQLStore } from '../../../stores';
import useListWorkspaces from '../../Platform/Workspaces/hooks/useListWorkspaces';
import { useManageTickets } from './useManageTickets';

type TicketButtonState = 'loading' | 'not-connected' | 'view-ticket' | 'create-ticket';

const useGetTicketData = (runId?: number, runData?: GetScheduleRunQuery) => {
  const { clusterConfig } = graphQLStore.getState();
  const { currentWorkspace } = useListWorkspaces();

  const [ticketTitle, setTicketTitle] = useState('');
  const [ticketDescription, setTicketDescription] = useState('');
  const [ticketButtonState, setTicketButtonState] = useState<TicketButtonState>();
  const {
    getTicket,
    isLoadingTicketData,
    viewTicketUrl,
    setViewTicketUrl,
    isIntegrationConnected,
    integrationName,
  } = useManageTickets();

  useEffect(() => {
    getTicket({
      variables: {
        ticketContext: 'bolt',
        ticketContextId: runId?.toString() || '',
      },
    });
  }, []);

  useEffect(() => {
    if (runData?.getScheduleRun && clusterConfig && runId && currentWorkspace) {
      const { info } = runData.getScheduleRun;
      const startDttm = formatDateTimeToLocalString({ dateTimeString: info.startDttm });
      const endDttm = formatDateTimeToLocalString({ dateTimeString: info.endDttm });
      const runCommands = info.commands?.reduce((c) => `- ${c}\n`, '');
      const tempDescription = `The schedule:
Name: ${info.name}
Last status: ${info.state.text}  @  ${endDttm}
View logs in Paradime @: https://${clusterConfig.appHost}/bolt/run_id/${runId}

Schedule Commands
${runCommands}

Schedule details:
- RunID: ${runId}
- Executed by: ${info.actorEmail}
- Run started @:  ${startDttm}
- Run ended @: ${endDttm}

Environment details 
- Branch name: ${info.branch}
- Commit hash: ${info.commit.hash}

Workspace details: 
- Workspace Name: ${currentWorkspace.name}
- Owner: ${info.owner}
      `;
      setTicketTitle(`Bolt Production run for: ${info.name}, status: ${info.state.text}`);
      setTicketDescription(tempDescription);
    }
  }, [runId, runData, clusterConfig, currentWorkspace]);

  useEffect(() => {
    const buttonState = () => {
      if (isLoadingTicketData) {
        return 'loading';
      }

      if (!isIntegrationConnected) {
        return 'not-connected';
      }

      if (viewTicketUrl) {
        return 'view-ticket';
      }

      return 'create-ticket';
    };

    setTicketButtonState(buttonState());
  }, [isIntegrationConnected, isLoadingTicketData, viewTicketUrl]);

  return {
    ticketTitle,
    ticketDescription,
    viewTicketUrl,
    setViewTicketUrl,
    integrationName,
    isLoadingTicketData,
    ticketButtonState,
    setTicketButtonState,
  };
};

export default useGetTicketData;
