export namespace Environment {
  export const DEVELOPMENT = 'development';
  export const PRODUCTION = 'production';
}

export const environments = [Environment.DEVELOPMENT, Environment.PRODUCTION] as const;

/**
   * @IGNORE_AUTO_SIGN_UP_FLOW ignores check for auto sign up flow, and lets you create
   * a new company even if you are detected as able to auto signup in a current company
   * @ALLOW_NEW_COMPANY_AFTER_ALREADY_ONBOARDED allows you to create a new company after
   * you have already onboarded in a current company
   */

export namespace SnowFlakeCostCategory {
  export const CURRENT_MONTH_BILLABLE_BYTES = 'CURRENT_MONTH_BILLABLE_BYTES';
  export const CURRENT_MONTH_CREDIT_USAGE = 'CURRENT_MONTH_CREDIT_USAGE';
  export const CURRENT_MONTH_QUERY_COUNT = 'CURRENT_MONTH_QUERY_COUNT';
  export const CURRENT_MONTH_TOTAL_ROWS_LOADED = 'CURRENT_MONTH_TOTAL_ROWS_LOADED';
  export const WEEKLY_CREDIT_USAGE_BY_WAREHOUSE = 'WEEKLY_CREDIT_USAGE_BY_WAREHOUSE';
  export const WEEKLY_QUERY_AVG_EXECUTION_TIME_BY_USER = 'WEEKLY_QUERY_AVG_EXECUTION_TIME_BY_USER';
  export const WEEKLY_QUERY_COUNT = 'WEEKLY_QUERY_COUNT';
  export const WEEKLY_STORAGE_GROWTH_BY_DATABASE = 'WEEKLY_STORAGE_GROWTH_BY_DATABASE';
}

export const snowflakeCostCategories = [
  SnowFlakeCostCategory.CURRENT_MONTH_BILLABLE_BYTES,
  SnowFlakeCostCategory.CURRENT_MONTH_CREDIT_USAGE,
  SnowFlakeCostCategory.CURRENT_MONTH_QUERY_COUNT,
  SnowFlakeCostCategory.CURRENT_MONTH_TOTAL_ROWS_LOADED,
  SnowFlakeCostCategory.WEEKLY_CREDIT_USAGE_BY_WAREHOUSE,
  SnowFlakeCostCategory.WEEKLY_QUERY_AVG_EXECUTION_TIME_BY_USER,
  SnowFlakeCostCategory.WEEKLY_QUERY_COUNT,
  SnowFlakeCostCategory.WEEKLY_STORAGE_GROWTH_BY_DATABASE,
] as const;
