import React, {
  FC, useState, useEffect,
} from 'react';
import { Command } from 'cmdk';
import CommandItem from './CommandItem';
import { userAuthStore, appStore } from '../../../../stores';
import { useGetTopLevelCommands } from './hooks/useGetTopLevelCommands';
import { useGetSettingsCommands } from './hooks/useGetSettingsCommands';
import { useGetRadarCommands } from './hooks/useGetRadarCommands';
import * as S from './CmdK.styles';
import { useGetBoltCommands } from './hooks/useGetBoltCommands';
import BoltScheduleItem from './BoltScheduleItem';
import { useKeyboardShortcuts } from './hooks/useKeyboardShortcuts';

const CommandMenu:FC = () => {
  const { currentUser: { accessLevel } } = userAuthStore.getState();
  const showCommandBar = appStore((s) => s.showCommandBar);
  const setShowCommandBar = appStore((s) => s.setShowCommandBar);
  const [search, setSearch] = useState('');

  const { topLevelCommands, pages, setPages } = useGetTopLevelCommands();
  const { settingsCommands } = useGetSettingsCommands(accessLevel);
  const { radarCommands } = useGetRadarCommands();
  const { boltCommandsData, isLoadingBoltCommandsData } = useGetBoltCommands();

  // register keyboard shortcuts
  useKeyboardShortcuts([...topLevelCommands, ...settingsCommands, ...radarCommands]);

  const page = pages[pages.length - 1];

  // Toggle the menu when ⌘K is pressed
  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setShowCommandBar(!showCommandBar);
      }
    };

    document.addEventListener('keydown', down);
    return () => document.removeEventListener('keydown', down);
  }, []);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape' || (e.key === 'Backspace' && !search)) {
      e.preventDefault();
      setPages((p) => p.slice(0, -1));
    }
  };

  return (
    <Command.Dialog
      open={showCommandBar}
      onOpenChange={setShowCommandBar}
      onKeyDown={handleKeyDown}
      label="Global Command Menu"
      title="Global Search"
      loop
    >
      <S.CmdKVercelStyle />
      <Command.Input
        value={search}
        onValueChange={setSearch}
        placeholder="Search for anything ..."
      />
      <Command.List>
        <Command.Empty>No results found.</Command.Empty>

        {!page && (
        <>
          <Command.Group heading="Actions">
            {
                  topLevelCommands.map((command) => (
                    <CommandItem
                      key={`command-${command.children}`}
                      icon={command.icon}
                      shortcut={command.shortcut}
                      onSelect={command.onSelect}
                      nested={command.nested}
                    >
                      {command.children}
                    </CommandItem>
                  ))
                }
          </Command.Group>
        </>
        )}

        {page === 'settings' && (
        <>
          {
                settingsCommands.map((command) => (
                  <CommandItem
                    key={`command-${command.children}`}
                    icon={command.icon}
                    shortcut={command.shortcut}
                    onSelect={command.onSelect}
                  >
                    {command.children}
                  </CommandItem>
                ))
              }
        </>
        )}

        {page === 'radar' && (
        <>
          {
                radarCommands.map((command) => (
                  <CommandItem
                    key={`command-${command.children}`}
                    icon={command.icon}
                    shortcut={command.shortcut}
                    onSelect={command.onSelect}
                  >
                    {command.children}
                  </CommandItem>
                ))
              }
        </>
        )}

        {page === 'bolt' && (
        <>
          {
                isLoadingBoltCommandsData ? (
                  <Command.Loading>Fetching schedules …</Command.Loading>
                ) : (
                  boltCommandsData?.map((data) => (
                    <BoltScheduleItem
                      key={`command-schedule-${data.name}`}
                      uuid={data.uuid}
                      name={data.name}
                      state={data.state}
                      color={data.color}
                      owner={data.owner}
                      nextRunTime={data.nextRunTime}
                    />
                  ))
                )
              }
        </>
        )}

      </Command.List>
    </Command.Dialog>
  );
};

export default CommandMenu;
