import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Spinner from '@paradime-io/pragma-ui-kit/lib/components/Spinner';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import Chips from '@paradime-io/pragma-ui-kit/lib/components/Chips';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import BookDemo from '../../Common/Libraries/Cal';
import { CreateWorkspaceRoutes, createWorkspaceRoutePrefix } from './CreateWorkspace';
import { userHasPlatformWorkspaceCreateAccess } from '../../../utilis/PermissionsService';
import { userAuthStore } from '../../../stores';
import { openHelpHub } from '../../Common/AppHelp/utils';

export interface WorkspaceHeaderProps {
  isLoading?: boolean,
  hasPricingPlan?: boolean,
  helpArticleId?: string,
}

const WorkspaceHeader:FC<WorkspaceHeaderProps> = ({
  isLoading,
  hasPricingPlan,
  helpArticleId,
}) => {
  const history = useHistory();

  const { accessLevel } = userAuthStore((s) => s.currentUser);

  const handleButtonClick = () => {
    handleAddWorkspaceClick();
  };

  const handleAddWorkspaceClick = () => {
    history.push(`/${createWorkspaceRoutePrefix}/${CreateWorkspaceRoutes.ENTER_WORKSPACE_NAME}`);
  };

  return (
    <div style={{ borderBottom: '1px solid var(--grey5)', width: '100%' }}>
      <AutoLayout
        direction="horizontal"
        alignment="center"
        padding="very-dense"
        distribution="space-between"
        style={{ padding: '0 8px 8px 8px', alignItems: 'center' }}
      >
        <AutoLayout
          direction="horizontal"
          padding="none"
          horizontalGap="very-dense"
          distribution="packed"
        >
          <Typography
            style={{ placeSelf: 'flex-start', padding: '5px 0', margin: 0 }}
            type="h6"
            tagName="span"
          >
            Workspaces
          </Typography>
          {
            helpArticleId && (
              <Chips
                color="primary_alt"
                round={false}
                style={{ margin: '4px', verticalAlign: 'bottom' }}
                tag="Learn more"
                type="dense"
                view="smooth"
                onClick={() => openHelpHub({ articleId: helpArticleId })}
              />
            )
          }
        </AutoLayout>
        <AutoLayout
          direction="horizontal"
          distribution="packed"
          padding="none"
          horizontalGap="very-dense"
          style={{ alignItems: 'center' }}
        >
          {
            (isLoading) ? (
              <Spinner width={20} height={20} thin />
            ) : (
              <>
                {
                  hasPricingPlan && (
                    <BookDemo
                      cta="Speak with us"
                      bookingLink="kaustav/paradime-support"
                      embedType="popup"
                    />
                  )
                }
                {userHasPlatformWorkspaceCreateAccess(accessLevel) && (
                  <DefaultButton
                    type="standard"
                    view="smooth"
                    dense
                    color="primary"
                    text="New workspace"
                    icon="cube-add"
                    onClick={handleButtonClick}
                    eventContext={Contexts.PLATFORM}
                    eventObject="addNewWorkspace"
                    eventAction={Actions.CLICKED}
                  />
                )}
              </>
            )
          }
        </AutoLayout>
      </AutoLayout>
    </div>
  );
};

export default WorkspaceHeader;
