import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import PageTitle, { PageTitles } from '../../PageTitle';
import PricingPlanScreen from './PricingPlanScreen';
import ConfirmRequestAccess from './ConfirmRequestAccess';
import BookDemo from '../../Common/Libraries/Cal';
import { PlatformTab } from '../../Platform';
import { userAuthStore } from '../../../stores';

const Plans: FC = () => {
  const { accessLevel } = userAuthStore((state) => state.currentUser);

  return (
    <>
      <PageTitle title={PageTitles.PLANS} />
      <AutoLayout
        direction="vertical"
        padding="none"
        verticalGap="none"
        distribution="packed"
        alignment="top-left"
        width="extra-large"
        style={{ rowGap: '64px' }}
      >
        <AutoLayout
          direction="horizontal"
          alignment="center"
          padding="very-dense"
          distribution="space-between"
          style={{
            padding: '0 8px 8px 8px', alignItems: 'center', borderBottom: '1px solid var(--grey5)',
          }}
        >
          <Typography
            font="inter"
            type="h6"
          >
            All Plans
          </Typography>
          <AutoLayout
            direction="horizontal"
            padding="none"
            distribution="packed"
            horizontalGap="very-dense"
          >
            <Typography
              font="inter"
              type="caption"
              color="default"
              colorStep="50"
            >
              Need help with plans?
            </Typography>
            <BookDemo
              cta="Speak with us"
              bookingLink="kaustav/paradime-support"
              embedType="popup"
            />
          </AutoLayout>
        </AutoLayout>
        <Switch>
          <Route exact path={`/platform/${PlatformTab.PLANS}`}>
            <PricingPlanScreen
              accessLevel={accessLevel}
            />
          </Route>
          <Route exact path={`/platform/${PlatformTab.PLANS}/request-access`}>
            <ConfirmRequestAccess />
          </Route>
        </Switch>

      </AutoLayout>
    </>
  );
};

export default Plans;
