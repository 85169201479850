import React, {
  FC, useCallback, useContext, useState,
} from 'react';
import DataTable, { ColumnType, ColumnProps } from '@paradime-io/pragma-ui-kit/lib/components/PrimeReactDataTable';
import Spinner from '@paradime-io/pragma-ui-kit/lib/components/Spinner';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import Card from '@paradime-io/pragma-ui-kit/lib/components/Card';
import useGetDataForArtifactType from '../../hooks/useGetDataForArtifactType';
import { ScheduleRunCommandArtifactType } from '../../../../client/generated/service';
import useDownloadArtifact from '../../hooks/useDownloadArtifact';
import useViewArtifact from '../../hooks/useViewArtifact';
import { RunningContext } from '../Run';
import TabsRadio, { TabData } from '../TabsRadio';
import * as S from '../Scheduler.styles';

const tabsData: TabData[] = [
  {
    id: ScheduleRunCommandArtifactType.RunSql,
    title: 'Run SQL',
  },
  {
    id: ScheduleRunCommandArtifactType.CompiledSql,
    title: 'Compiled SQL',
  },
  {
    id: ScheduleRunCommandArtifactType.Other,
    title: 'Other',
  },
];

export interface ArtifactsTableProps {
  commandId: number,
  initialTab: ScheduleRunCommandArtifactType,
}

const ArtifactsTable:FC<ArtifactsTableProps> = ({
  commandId,
  initialTab,
}) => {
  const { isRunning } = useContext(RunningContext);
  const [currentTabId, setCurrentTabId] = useState<ScheduleRunCommandArtifactType>(initialTab);
  const { data, loading } = useGetDataForArtifactType(commandId, currentTabId);
  const { downloadArtifact } = useDownloadArtifact();
  const { viewArtifactSql } = useViewArtifact();

  const getColumns = () => ([
    {
      field: 'filePath',
      header: 'Files',
      sortable: true,
    },
    {
      field: 'fileAction',
      header: (currentTabId && currentTabId === ScheduleRunCommandArtifactType.Other) ? 'Download' : 'View',
      type: ColumnType.BUTTON,
      style: { width: '200px' },
    }] as ColumnProps[]
  );

  const getData = useCallback(() => {
    const result = data
      ?.getCommandArtifacts
      ?.artifacts?.map((artifact) => {
        const isSQL = artifact?.path
          ? /\.(sql)$/.test(artifact?.path) : false;

        return ({
          filePath: artifact?.path,
          fileAction: {
            text: isSQL ? 'View' : 'Download',
            onClick: () => {
              if (artifact) {
                if (isSQL) {
                  viewArtifactSql(artifact.id, artifact.path);
                } else {
                  downloadArtifact(artifact.id);
                }
              }
            },
            fill: true,
            dense: true,
            color: 'primary',
          },
        });
      });

    return result;
  }, [data]);

  const tableData = getData();

  if (loading) {
    return (
      <Card
        style={{
          minHeight: '360px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Spinner width={32} height={32} thin />
      </Card>
    );
  }

  return (
    <Card style={{ marginTop: '4px' }}>
      {isRunning ? (
        <AutoLayout
          alignment="center"
          padding="dense"
          style={{ textAlign: 'center' }}
        >
          <Typography type="body-small" tagName="span">
            Artifacts will be available after this run is complete.
          </Typography>
        </AutoLayout>
      ) : (
        <S.ArtifactsDataTableWrapper>
          <DataTable
            columns={getColumns()}
            data={tableData || []}
            usePagination={false}
            showRowCheckboxes={false}
            style={{ minWidth: '50rem' }}
            scrollHeight="360px"
            tableHeader={(
              <AutoLayout
                direction="horizontal"
                distribution="space-between"
                padding="none"
                style={{ justifyContent: 'flex-end' }}
              >
                <TabsRadio
                  data={tabsData}
                  currentTabId={currentTabId}
                  exposeOnChange={(newCurrentTabId) => setCurrentTabId(
                      newCurrentTabId?.toString() as ScheduleRunCommandArtifactType,
                  )}
                />
              </AutoLayout>
              )}
          />
        </S.ArtifactsDataTableWrapper>
      )}
    </Card>
  );
};

export default ArtifactsTable;
