import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import PageTemplate from '../Utils/PageTemplate';
import { SynqOnboardingRoutePrefix, SYNQ_ONBOARDING_PAGE } from '..';
import { useSelectParadimeOrg } from './useSelectParadimeOrg';

const ErrorOrgAlreadyExists: FC = () => {
  const history = useHistory();
  const { search, state } = useLocation<{ orgName: string }>();
  const setParadimeOrg = useSelectParadimeOrg();

  const handleClick = (type: 'join' | 'retry') => {
    let path = `${SynqOnboardingRoutePrefix}/${SYNQ_ONBOARDING_PAGE.ENTER_ORG}`;

    if (type === 'join') {
      path = `${SynqOnboardingRoutePrefix}/${SYNQ_ONBOARDING_PAGE.JOIN_EXISTING}`;
      setParadimeOrg(state.orgName);
    }

    const fullUrl = `/${path}${search}`;
    history.push(fullUrl);
  };

  return (
    <PageTemplate
      withLogo
      icon={{ name: 'disable', color: 'var(--red80)' }}
      title="This organization already exists"
      titleTextType="h4"
      subtitle="Do you want to join your team instead?"
      subtitleTextType="body"
      withBox={false}
      boxOrientation="horizontal"
      customComponent={(
        <AutoLayout
          direction="horizontal"
          horizontalGap="expanded"
          padding="expanded"
          alignment="center"
        >
          <DefaultButton
            type="standard"
            view="simple"
            color="primary_alt"
            text="Join team"
            fill={false}
            onClick={() => handleClick('join')}
            eventContext={Contexts.ONBOARDING}
            eventObject="orgExistsJoinTeammates"
            eventAction={Actions.CLICKED}
            style={{ minWidth: '250px' }}
          />
          <DefaultButton
            type="standard"
            view="simple"
            color="primary"
            text="Try another organization"
            fill={false}
            onClick={() => handleClick('retry')}
            eventContext={Contexts.ONBOARDING}
            eventObject="orgExistsTryAgain"
            eventAction={Actions.CLICKED}
            style={{ minWidth: '250px' }}
          />
        </AutoLayout>
      )}
    />
  );
};

export default ErrorOrgAlreadyExists;
