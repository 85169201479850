import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Loader from '@paradime-io/pragma-ui-kit/lib/components/Loader';
import { SynqOnboardingRoutePrefix, SYNQ_ONBOARDING_PAGE } from '../SynqOnboarding';
import { companyStore, graphQLStore } from '../../../stores';
import { useOnboardUserMutation, OnboardUserFailureReason } from '../../../client/generated/magnetar';
import ErrorAlreadyOnboarded from './ErrorAlreadyOnboarded';
import { ONBOARDING_PAGE, OnboardingRoutePrefix } from '..';

const JoinExistingCompany = () => {
  const [userAlreadyOnboardedError, setUserAlreadyOnboardedError] = useState(false);

  const history = useHistory();

  const { setAddNewUserToCompany } = companyStore.getState();
  const { isExtensionOnlyCompany } = companyStore.getState();
  const { apolloClientIsAuthorized } = graphQLStore.getState();

  const { location: { href } } = window;
  const url = new URL(href);
  const inviteToken = url.searchParams.get('user_token') || '';

  const [onboardNewUser] = useOnboardUserMutation({
    onCompleted: ({ onboardUser }) => {
      const { ok, failureReason } = onboardUser;
      if (ok) {
        // This is stupid, has to go ASAP
        // Needed to make the company-specific apollo client build in appWithClusterConfig
        setAddNewUserToCompany(inviteToken || 'placeholder');

        history.push(`/${SynqOnboardingRoutePrefix}/${SYNQ_ONBOARDING_PAGE.BASIC_INFO}`);
        return;
      }

      switch (failureReason) {
        case OnboardUserFailureReason.InviteTokenExpired:
        case OnboardUserFailureReason.InviteTokenInvalid:
          history.push(`/${SynqOnboardingRoutePrefix}/${SYNQ_ONBOARDING_PAGE.ERROR_EXPIRED_LINK}`);
          break;
        case OnboardUserFailureReason.UserExistsAlready:
          setUserAlreadyOnboardedError(true);
          console.error('You are onboarded already');
          break;
        default:
          history.push(`/${SynqOnboardingRoutePrefix}/${SYNQ_ONBOARDING_PAGE.ERROR_NO_AUTO_JOIN}`);
          break;
      }
    },
  });

  useEffect(() => {
    // Wait for a company-specific apollo client
    if (apolloClientIsAuthorized) {
      onboardNewUser({ variables: { inviteToken } });
    }
  }, [apolloClientIsAuthorized]);

  if (isExtensionOnlyCompany === undefined) {
    return <Loader />;
  }

  if (!isExtensionOnlyCompany) {
    // Main-app company
    history.push(`/${OnboardingRoutePrefix}/${ONBOARDING_PAGE.BASIC_INFO}`);
    return <div />;
  }

  if (userAlreadyOnboardedError) {
    return <ErrorAlreadyOnboarded />;
  }

  return <div />;
};

export default JoinExistingCompany;
