import styled, { createGlobalStyle, css } from 'styled-components';
import { Icon } from '@blueprintjs/core';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';

export const InAppHelpDialogStyle = createGlobalStyle`
  .in-app-help-dialog.p-dialog {
    max-height: 96%;
    background: var(--white);
    box-shadow: var(--violetShadow8dp);
    margin: 8px 16px;
    padding: 0;
  }
  
  .in-app-help-dialog .p-dialog-header {
    background: none;
    padding: 16px;
  }

  .in-app-help-dialog .p-dialog-content {
    background: none;
    padding: 0;
  }

  .in-app-help-content h1 {
    font-size: var(--font_size_h6);
    color: var(--grey100);
  }

  .in-app-help-content h3,
  .in-app-help-content h2 {
    font-size: var(--font_size_body_bold);
    color: var(--grey100);
  }

  .in-app-help-content p {
    font-size: var(--font_size_body_small);
    color: var(--grey100);
  }

  .in-app-help-content p a {
    font-size: var(--font_size_body_small);
    color: var(--violet60);
  }

  .in-app-help-content iframe {
    border-radius: 8px;
  }

  .in-app-help-content img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }

  .in-app-help-content div[class*="grid-area"] > img {
    margin-bottom: 8px;
  }
  
  .in-app-help-content a.flex-row {
    border: 0.5px solid var(--grey40);
    padding: 16px;
    display: flex;
    border-radius: 8px;
    margin-bottom: 8px;
    color: var(--grey100);
    font-size: var(--font_size_body_small);
    text-decoration: none;
  }

  .in-app-help-content pre {
    font-size: 12px;
    color: var(--grey40);
    background: var(--dark);
    padding: 18px;
    border-radius: 8px;
    overflow-x: auto;
    margin-top: 0;
  }

  .in-app-help-content a > code {
    font-family: var(--roboto-mono);
    color: var(--violet60);
    background: var(--violet5);
    padding: 4px;
    border-radius: 4px;
  }

  .in-app-help-content div.inline-grid {
    display: inline-grid;
    row-gap: 16px;
    column-gap: 16px;
    padding-bottom: 16px;
  }

  .in-app-help-content div.inline-grid > a.grid {
    color: var(--grey100);
  }

  .in-app-help-content div.inline-grid > a.grid:hover {
    text-decoration: none;
  }

  .in-app-help-content div.inline-grid > a.grid > div.grid {
    padding: 16px;
    border: 0.5px solid var(--grey40);
    border-radius: 8px;
  }

  .in-app-help-content div[class*="codeblock"] {
    display: grid;
    grid-template-areas: "a b c"
      "d d d";
    row-gap: 8px;
  }

  .in-app-help-content div[class*="codeblock"] > [class*="grid-area:1/1"] {
    grid-area: a;
    font-size: var(--font_size_caption);
    color: var(--grey100);
  }

  .in-app-help-content div[class*="codeblock"] > button[class*="grid-area:2/1"] {
    grid-area: c;
    font-size: var(--font_size_caption);
    background: var(--dark);
    color: var(--grey40);
    padding: 4px;
    border-radius: 4px;
    border: 0;
  }

  .in-app-help-content div[class*="codeblock"] > pre[class*="grid-area:2/1"] {
    grid-area: d;
  }
`;

export const DialogCloseButton = styled(DefaultButton)`
  background: none !important;

  & > span[class*="icon"] {
    color: var(--grey50) !important;
  }

  &:active {
    background: none !important;
  }

  &:hover {
    background: none !important;
    color: var(--grey100);
  }
`;

export const HelpCardWrapper = styled.div<{featured: string}>`
  cursor: default;
  & > div {
    align-content: ${(props) => ((props.featured === 'true') ? 'flex-start' : 'center')};
    border: 0.5px solid var(--grey30);
    max-width: ${(props) => ((props.featured === 'true') ? '202px' : 'auto')};
    word-wrap: break-word;
    background: ${(props) => ((props.featured === 'true') ? 'linear-gradient(144deg, #272188 56.45%, #827BE6 96.49%)' : 'var(--white)')};
  }

  &:hover {
    cursor: pointer;
  }
`;

export const HelpCardIcon = styled(Icon)<{featured: string}>`
${(props) => ((props.featured === 'true')
  && css`
    padding: 8px;
    border-radius: 50%;
    background: var(--grey0);
`)}

& svg {
  fill: ${(props) => ((props.featured === 'true') ? 'var(--grey100)' : 'var(--grey50)')};
}
`;
