import { catalogueLogoProps } from '../types';
import bigquery from '../../components/Catalog/images/bigquery.svg';
import redshift from '../../components/Catalog/images/redshift.svg';
import snowflake from '../../components/Catalog/images/snowflake.svg';
import firebolt from '../../components/Catalog/images/firebolt.svg';

export const catalogueLogo = ({
  resultItem,
}: catalogueLogoProps) => {
  const DwhLogo = {
    bigquery,
    snowflake,
    redshift,
    firebolt,
  };

  type dwhNameType = keyof typeof DwhLogo;

  switch (resultItem.__typename) {
    case 'GQLCatalogueMaterialisedDbtObjectColumnItem':
    case 'GQLCatalogueDwhColumnItem':
    case 'GQLCatalogueDwhTableItem':
    case 'GQLCatalogueMaterialisedDbtItem':
      return { firstImage: DwhLogo[resultItem.dwhType as dwhNameType] };
    default:
      return undefined;
  }
};
