/* eslint-disable no-unused-vars */
import {
  useEffect, Dispatch, SetStateAction, useState,
} from 'react';

type apolloFunction<T> = () => [
  ({ variables }: { variables: T}) => void,
  {
    data?: any
  }
]

export interface postConnectionCheckTestProps {
  ok: boolean,
  credentialId?: string,
  __typename: string,
}

export interface useOnboardIfCheckedProps <T> {
  checkQuery: apolloFunction<T>,
  triggerQuery: apolloFunction<T>,
  setErrorMessage: Dispatch<SetStateAction<string>>,
  setOnboarded: Dispatch<SetStateAction<boolean | undefined>>,
  variables: { [key: string]: any },
  setIsChecked?: Dispatch<SetStateAction<boolean>>,
  setCheckInProgress?: Dispatch<SetStateAction<boolean>> | false,
  postConnectionCheckTest?: (triggerData: postConnectionCheckTestProps) => void,
}

const useTriggerIfOk = <T>({
  checkQuery,
  triggerQuery,
  setErrorMessage,
  setOnboarded,
  setIsChecked,
  setCheckInProgress,
  postConnectionCheckTest,
}: useOnboardIfCheckedProps<T>) => {
  const [variables, setVariables] = useState<{ variables: T }>({ variables: {} as T });

  const [
    checkQueryFunction,
    { data: checkQueryData },
  ] = checkQuery();

  const [
    triggerFunction,
    { data: triggerData },
  ] = triggerQuery();

  useEffect(() => {
    if (checkQueryData) {
      const keys = Object.keys(checkQueryData);
      const [responseKey] = keys.filter((key) => !key.includes('__'));
      const isOk: boolean = checkQueryData[responseKey].ok;

      if (setIsChecked) setIsChecked(true);

      if (isOk) {
        triggerFunction(variables);
      } else {
        setErrorMessage(checkQueryData[responseKey].error || '');
        setOnboarded(false);
        if (setCheckInProgress) setCheckInProgress(false);
      }
    }
  }, [checkQueryData]);

  useEffect(() => {
    if (triggerData) {
      const keys = Object.keys(triggerData);
      const [responseKey] = keys.filter((key) => !key.includes('__'));
      if (triggerData[responseKey]?.ok) {
        if (postConnectionCheckTest) {
          postConnectionCheckTest(triggerData[responseKey]);

          if (setCheckInProgress) {
            // Show the loading spinner for 5 seconds
            setTimeout(() => setCheckInProgress(false), 5000);
          }
        } else {
          setOnboarded(true);
          if (setCheckInProgress) setCheckInProgress(false);
        }
      } else {
        const errorMessage = triggerData[responseKey]?.error || "Something went wrong here and it's on us. The team has been notified.";
        setErrorMessage(errorMessage);
        setOnboarded(false);
        if (setCheckInProgress) setCheckInProgress(false);
      }
    }
  }, [triggerData]);

  return {
    checkQueryFunction: (props: any) => {
      setVariables(props);
      if (setCheckInProgress) setCheckInProgress(true);
      checkQueryFunction(props);
    },
  };
};

export default useTriggerIfOk;
