import React, { FC } from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';

interface LogoCloudProps {
  logos: {
    row1: string[],
    row2: string[],
  },
}

const LogoCloud:FC<LogoCloudProps> = ({
  logos,
}) => (
  <AutoLayout
    direction="vertical"
    distribution="packed"
    padding="none"
    verticalGap="dense"
    alignment="top-center"
  >
    <AutoLayout
      direction="horizontal"
      distribution="packed"
      padding="none"
      horizontalGap="dense"
      alignment="top-left"
      style={{ alignItems: 'center', justifyContent: 'center' }}
    >
      {logos.row1.map((logo) => (<img src={logo} alt="customer logo" />))}
    </AutoLayout>
    <AutoLayout
      direction="horizontal"
      distribution="packed"
      padding="none"
      horizontalGap="dense"
      alignment="top-left"
      style={{ alignItems: 'center', justifyContent: 'center' }}
    >
      {logos.row2.map((logo) => (<img src={logo} alt="customer logo" />))}
    </AutoLayout>
  </AutoLayout>
);

export default LogoCloud;
