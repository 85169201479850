import React, { FC, useState, CSSProperties } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'; // @ts-ignore
import virtualizedRenderer from 'react-syntax-highlighter-virtualized-renderer';
import AutoSizer from 'react-virtualized-auto-sizer';
import copy from 'copy-to-clipboard';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';

export interface CodeSnippetCopyProps {
  codeSnippet: string,
  language: string,
  wrapLongLines?: boolean,
  fixedHeight?: CSSProperties['height'],
  useVirtualisedRendering?: boolean,
}

const CodeSnippetCopy:FC<CodeSnippetCopyProps> = ({
  codeSnippet,
  language,
  wrapLongLines,
  fixedHeight,
  useVirtualisedRendering = false,
}) => {
  const [codeCopyButtonText, setCodeCopyButtonText] = useState<string>();

  return (
    <AutoLayout
      direction="vertical"
      padding="none"
      distribution="packed"
      verticalGap="none"
      style={useVirtualisedRendering ? { height: '100%' } : undefined}
    >
      <DefaultButton
        type="standard"
        view="smooth"
        color="default"
        icon={codeCopyButtonText ? undefined : 'duplicate'}
        text={codeCopyButtonText}
        dense
        eventContext={Contexts.LOGS}
        eventObject="copyCode"
        eventAction={Actions.CLICKED}
        onClick={() => {
          copy(codeSnippet);
          setCodeCopyButtonText('Copied!');
          setTimeout(() => {
            setCodeCopyButtonText(undefined);
          }, 1000);
        }}
        style={{
          width: 'fit-content',
          justifySelf: 'flex-end',
          position: 'relative',
          top: '48px',
          right: '20px',
          zIndex: 2,
        }}
      />
      {useVirtualisedRendering
        ? (
          <div style={{ width: '100%', height: fixedHeight }}>
            <AutoSizer style={{ width: '100%', height: '100%' }}>
              {/* eslint-disable-next-line react/no-unused-prop-types */}
              {({ height, width }: { height?: number, width?: number }) => (
                <SyntaxHighlighter
                  language={language}
                  style={oneDark}
                  height={height}
                  width={width}
                  showLineNumbers
                  showInlineLineNumbers
                  wrapLongLines={wrapLongLines}
                  renderer={virtualizedRenderer({ rowHeight: 20, overscanRowCount: 99999 })}
                  customStyle={{
                    fontSize: 'var(--font_size_caption)',
                    height: fixedHeight,
                  }}
                >
                  {codeSnippet}
                </SyntaxHighlighter>
              )}
            </AutoSizer>
          </div>
        ) : (
          <SyntaxHighlighter
            language={language}
            style={oneDark}
            showLineNumbers
            wrapLongLines={wrapLongLines}
            customStyle={{
              fontSize: 'var(--font_size_caption)',
            }}
          >
            {codeSnippet}
          </SyntaxHighlighter>
        )}
    </AutoLayout>
  );
};

export default CodeSnippetCopy;
