import React, { FC, useState } from 'react';
import { Icon } from '@blueprintjs/core';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import Chips from '@paradime-io/pragma-ui-kit/lib/components/Chips';
import MultiLineInput from '@paradime-io/pragma-ui-kit/lib/components/MultiLineInput';
import styled from 'styled-components';
import * as S from './warehouses.styles';
import { DevWarehouseHelp } from '../../AppHelp/help';

export interface OptionalConfigProps {
  configValue?: string,
  onChangeConfig?: (newVal: string) => void,
  isDisabled?: boolean,
}

export const CodeMultiLineInput = styled(MultiLineInput)`
  font-family: 'Roboto Mono', monospace;
`;

const OptionalConfig: FC<OptionalConfigProps> = ({
  configValue,
  onChangeConfig,
  isDisabled,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <S.OptionalConfigAccordion>
      <AutoLayout
        direction="horizontal"
        distribution="space-between"
        onClick={() => setIsExpanded(((expanded) => !expanded))}
        style={{ padding: '12px 8px', cursor: 'pointer' }}
      >
        <AutoLayout
          direction="horizontal"
          padding="none"
          distribution="packed"
          horizontalGap="very-dense"
        >
          <Icon icon={isExpanded ? 'chevron-up' : 'chevron-down'} size={16} color="var(--grey60)" />
          <Typography type="body-small">Optional connection attributes</Typography>
        </AutoLayout>
        <Chips
          color="primary_alt"
          round={false}
          style={{ margin: '4px', verticalAlign: 'bottom' }}
          tag="Learn more"
          type="dense"
          view="smooth"
          onClick={() => (
            window.CommandBar.openHelpHub(
              { articleId: DevWarehouseHelp.CUSTOM_CONFIG },
            )
          )}
        />
      </AutoLayout>

      {isExpanded && (
        <AutoLayout
          direction="vertical"
          padding="none"
          distribution="packed"
          verticalGap="very-dense"
          style={{ padding: '12px' }}
        >
          <Typography type="caption" colorStep="60">
            Specify custom profile attributes such as timeout
            or retries to fine-tune this connection.
          </Typography>
          <CodeMultiLineInput
            view="outlined"
            color="default"
            placeholder="Enter profile connection attributes"
            dense
            value={configValue}
            maxLines={5}
            minLines={5}
            exposeOnChange={onChangeConfig}
            disabled={isDisabled}
            sensitive
          />
        </AutoLayout>
      )}
    </S.OptionalConfigAccordion>
  );
};

export default OptionalConfig;
