import React, { FC } from 'react';
import { Icon } from '@blueprintjs/core';
import { Dropdown } from 'primereact/dropdown';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import DefaultInput from '@paradime-io/pragma-ui-kit/lib/components/DefaultInput';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import Chips from '@paradime-io/pragma-ui-kit/lib/components/Chips';
import { key } from '../../../utilis';

export interface DropdownOption {
  label: string | { title: string, subtitle: string },
  value: string,
}

export interface NotificationDropdownProps {
  value: DropdownOption,
  options?: DropdownOption[],
  onChange: (newVal: DropdownOption) => void,
  isLoading?: boolean,
  isDisabled?: boolean,
  title?: string,
  onRefresh?: () => void,
  placeholder?: string,
  showClearAllButton?: boolean,
  showFilter?: boolean,
}

const NotificationDropdown: FC<NotificationDropdownProps> = ({
  title,
  onRefresh,
  ...rest
}) => {
  const getFilterElement = ({
    value, options, onChange, isLoading, placeholder, isDisabled, showClearAllButton, showFilter,
  }: NotificationDropdownProps) => {
    if (isLoading) {
      return (
        <DefaultButton
          loading
          dense
          fill
          eventContext={Contexts.RADAR}
          eventObject=""
          eventAction={Actions.CLICKED}
        />
      );
    }

    if (isDisabled) {
      return (
        <DefaultInput
          type="text"
          disabled
          dense
          full
          color="default"
          placeholder={placeholder}
          value={typeof value.label === 'string' ? value.label : value.label.title}
          rightElement={<Icon icon="lock" color="var(--icon)" />}
        />
      );
    }

    const optionsHaveSubtitles = options?.some(
      ({ label }) => typeof label === 'object',
    );

    return (
      <Dropdown
        key={key(options?.[0]?.value)}
        value={value.value}
        options={options}
        onChange={(e) => {
          // The "clear all" option
          if (e.value === undefined) onChange({ label: '', value: '' });

          const selectedOption = options?.find(({ value: val }) => val === e.value);
          if (selectedOption) {
            onChange(selectedOption);
          }
        }}
        optionLabel="label"
        placeholder={placeholder}
        showClear={showClearAllButton}
        panelClassName={optionsHaveSubtitles ? 'dropdown-panel-with-text-subtitle-items' : undefined}
        filter={showFilter}
        itemTemplate={(option) => (
          typeof option.label === 'object'
            ? (
              <AutoLayout
                direction="vertical"
                padding="none"
                distribution="packed"
                verticalGap="very-dense"
              >
                <Typography type="body-small">{option.label.title}</Typography>
                <Typography type="caption" colorStep="50">{option.label.subtitle}</Typography>
              </AutoLayout>
            )
            : <Typography type="body-small">{option.label}</Typography>
        )}
        valueTemplate={(option, props) => {
          if (option) {
            return (
              typeof option.label === 'object'
                ? option.label?.title
                : option.label
            );
          }

          return <span>{props.placeholder}</span>;
        }}
      />
    );
  };

  return (
    <AutoLayout
      direction="vertical"
      distribution="packed"
      padding="none"
      verticalGap="very-dense"
      alignment="top-left"
    >
      <AutoLayout
        direction="horizontal"
        distribution="space-between"
        padding="none"
      >
        {title && (
          <Typography type="caption">
            {title}
          </Typography>
        )}
        {onRefresh && !rest.isDisabled && (
          <Chips
            type="dense"
            view="smooth"
            icon="refresh"
            tag="Refresh"
            color="default"
            onClick={onRefresh}
          />
        )}
      </AutoLayout>
      {getFilterElement({ ...rest })}
    </AutoLayout>
  );
};

export default NotificationDropdown;
