import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory, useLocation } from 'react-router-dom';
import { userAuthStore, graphQLStore } from '../../stores';
import useOrganisation from './useOrganisation';
import { ONBOARDING_PAGE, OnboardingRoutePrefix } from '../Onboarding';

const useNonAuthMemory = () => {
  const [hasTriedCache, setHasTriedCache] = useState(false);

  const setGoBackTo = userAuthStore((s) => s.setGoBackTo);
  const paradimeOrganisation = graphQLStore((state) => state.paradimeOrganisation);
  const setParadimeOrganisation = graphQLStore((state) => state.setParadimeOrganisation);
  const jwtToken = graphQLStore((state) => state.jwtToken);
  const goBackTo = userAuthStore((s) => s.goBackTo);

  const { user } = useAuth0();
  const location = useLocation();
  const history = useHistory();
  const { tryLoadingFromCache } = useOrganisation();

  const ignorePaths = [
    '/',
    '/signup',
    '/link-github',
    '/blocked',
    '/not-on-our-watch',
    '/enter-organisation',
    `/${OnboardingRoutePrefix}/${ONBOARDING_PAGE.ERROR_ORG_ALREADY_EXISTS}`,
  ];

  /** check if the auth localStorage variable has been set
   * if it has been set, we need to check that the expireAt entry (type: seconds since epoch)
   * is in the future so we don't get caught out with an expired refresh token
   */
  const isJwtTokenStillValid = () => {
    const auth0ApiDomain = paradimeOrganisation?.auth0ApiDomain || '';
    const auth0ClientId = paradimeOrganisation?.auth0ClientId || '';

    const authLocalStorageKey = `@@auth0spajs@@::${auth0ClientId}::https://${auth0ApiDomain}/api/v2/::openid profile email offline_access`;
    const auth0LocalStorage = window.localStorage.getItem(authLocalStorageKey);

    const searchParams = new URLSearchParams(window.location.search);
    const redirectedFromAuth0 = searchParams.get('code') || searchParams.get('error');

    const secondsSinceEpoch = Math.round((new Date()).getTime() / 1000);
    const auth0LocalStorageJSON = JSON.parse(auth0LocalStorage || '{}');
    const auth0ExpiresAt = auth0LocalStorageJSON.expiresAt;
    const isAuthenticated = redirectedFromAuth0
      || (auth0ExpiresAt !== undefined && auth0ExpiresAt > secondsSinceEpoch);

    return isAuthenticated;
  };

  useEffect(() => {
    if (paradimeOrganisation === undefined && !hasTriedCache) {
      tryLoadingFromCache();
      setHasTriedCache(true);
      return;
    }

    const isAuthenticated = isJwtTokenStillValid();
    const ignored = ignorePaths.includes(location.pathname);

    if (!isAuthenticated && !ignored && !goBackTo) {
      const { pathname: currenPath, search } = new URL(window.location.href);
      const completePath = currenPath + search;
      console.info('non auth path', completePath); // eslint-disable-line
      setGoBackTo(completePath);
      history.push(`/${window.location.search}`);
    }
  }, [user, location, paradimeOrganisation, hasTriedCache]);

  // Check the JWT expiry time every 10 minutes
  useEffect(() => {
    const intervalId = setInterval(() => {
      const isAuthenticated = isJwtTokenStillValid();

      if (!isAuthenticated) {
        console.info('JWT token has expired, pushing to the login page'); // eslint-disable-line no-console
        setParadimeOrganisation(undefined);
        history.push('/');
      }
    }, 600000);

    return () => clearInterval(intervalId);
  }, [jwtToken]);

  return null;
};

export default useNonAuthMemory;
