import React, { FC } from 'react';
import Dialog from '@paradime-io/pragma-ui-kit/lib/components/Dialog';
import { alertStore } from '../../../stores';

const Alerts: FC = () => {
  const currentAlert = alertStore((state) => state.currentAlert);

  return currentAlert
    ? <Dialog {...currentAlert} />
    : null;
};

export default Alerts;
