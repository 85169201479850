/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import { useLocation } from 'react-router-dom';
import { ApolloError } from '@apollo/client';
import 'regenerator-runtime';
import cronstrue from 'cronstrue';

export const randomNumber = (max: number) => String(Math.floor(Math.random() * max));
/**
 * Generates a random id given a name
 * @param name String
 */
export const key = (name?: string) => `${randomNumber(9999).padStart(4, '0')}-${name}`;

type stateProps = {
  [name: string]: any
}

export const useUpdateLocationState = () => {
  const location = useLocation<stateProps>();
  return (newState: { [name: string]: any }) => {
    location.state = {
      ...location.state,
      ...newState,
    };
  };
};

export const useLocationState = () => {
  const location = useLocation<stateProps>();
  return [location?.state || {}];
};

export const getEnvironment = () => {
  const location = window.location.hostname;
  if (location.indexOf('localhost') > -1) {
    return 'localhost';
  }
  if (location.indexOf('.dev.') > -1) {
    return 'development';
  }
  if (location.indexOf('.demo.') > -1) {
    return 'demo';
  }
  return 'production';
};

export const getGreeting = () => {
  const currentTime = new Date().getHours();
  if (currentTime >= 0 && currentTime < 12) {
    return 'Good morning';
  }
  if (currentTime >= 12 && currentTime < 17) {
    return 'Good afternoon';
  }
  return 'Good evening';
};

export const onError = (e: ApolloError) => console.debug('error', e, 'codeArea: Platform-common'); // eslint-disable-line

export const capitalize = (str: string) => {
  const lower = str.toLowerCase();
  return str.charAt(0).toUpperCase() + lower.slice(1);
};

export const postMessage = <T, >(type: string, payload?: T, target?: any) => {
  (target || window).postMessage({ type, payload }, '*');
};

export type genericObject = { [key: string]: any };

export const checkNoCasesLeft = (element: never) => {
  console.error(`unexpected case: ${element}`); // eslint-disable-line
};

const { hostname } = window.location;
export const isLocalHost = Boolean(
  window.location.hostname === 'localhost'
    // [::1] is the IPv6 localhost address.
    || window.location.hostname === '[::1]'
    // 127.0.0.0/8 are considered localhost for IPv4.
    || window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
    ),
);
const isAppDev = hostname.startsWith('app.dev');
const isDemoDev = hostname.startsWith('app.demo');
export const isDevEnvironment = isLocalHost || isAppDev || isDemoDev;

export const readJSONFromLocalStorage = (k: string) => {
  const value = window.localStorage.getItem(k);
  if (value) {
    return JSON.parse(value);
  }
  return undefined;
};

export const getHumanReadableCron = (schedule?: string | null) => {
  if (!schedule) return '-';

  const nonStandardSpecValues: Record<string, string> = {
    '@hourly': 'At minute 0, every hour',
    '@daily': 'At 00:00, every day',
    '@weekly': 'At 00:00 on Sunday, every week',
    '@monthly': 'At 00:00 on first day of month, every month',
    '@annually': 'At 00:00 on first day of January, every year',
    '@yearly': 'At 00:00 on first day of January, every year',
  };

  if (schedule === 'OFF') {
    return schedule;
  }
  if (schedule.startsWith('@')) {
    return `${nonStandardSpecValues[schedule]} (UTC)` || schedule;
  }

  let message = '';

  try {
    message = cronstrue.toString(schedule);
  } catch (e) {
    message = schedule;
  }

  return `${message} (UTC)`;
};

export const isSQL = (fileName?: string | null) => {
  if (!fileName) {
    return false;
  }

  return /.*\.sql/.test(fileName);
};

const toSnakeCase = (str: string) => (
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
);

export const convertKeysToSnakeCase = (obj: { [key: string]: any }) => {
  const newObject: { [key: string]: any } = Array.isArray(obj) ? [] : {};
  Object.keys(obj).forEach((k) => {
    const newKey = toSnakeCase(k);
    if (obj[k] !== null && typeof obj[k] === 'object') {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      newObject[newKey] = convertKeysToSnakeCase(obj[k]);
    } else {
      newObject[newKey] = obj[k];
    }
  });
  return newObject;
};

const isNotEmpty = (value?: any) => (
  value !== null && value !== undefined
);

export const createYamlBoltSchedulesObject = (obj: { [key: string]: any }) => ({
  name: obj.name,
  commands: obj.commands,
  schedule: obj.schedule,
  owner_email: obj.owner,
  environment: 'production',
  ...obj.gitBranch ? {
    git_branch: obj.gitBranch,
  } : {},
  ...obj.description ? {
    description: obj.description,
  } : {},
  ...obj.slaSeconds ? {
    sla_minutes: obj.slaSeconds / 60,
  } : {},
  ...obj.notifications ? {
    notifications: {
      ...obj.notifications.emailNotifications && obj.notifications.emailNotifications.length > 0 ? {
        emails: obj.notifications.emailNotifications.map((
          ({ channel, events }: { channel: string; events: string[] }) => (
            { address: channel, events }
          )
        )),
      } : {},
      ...obj.notifications.slackNotifications && obj.notifications.slackNotifications.length > 0 ? {
        slack_channels: obj.notifications.slackNotifications.map((
          ({ channel, events }: { channel: string; events: string[] }) => ({ channel, events }))),
      } : {},
      ...obj.notifications.teamsNotifications && obj.notifications.teamsNotifications.length > 0 ? {
        microsoft_teams: obj.notifications.teamsNotifications.map((
          ({ channel, events }: { channel: string; events: string[] }) => ({ channel, events }))),
      } : {},
    },
  } : {},
  ...obj.suspended ? {
    suspended: obj.suspended,
  } : {},
  ...obj.triggerOnMerge ? {
    trigger_on_merge: obj.triggerOnMerge,
  } : {},
  ...obj.turboCi
    && obj.turboCi?.deferredScheduleName
    && isNotEmpty(obj.turboCi?.successfulRunOnly) ? {
      turbo_ci: {
        enabled: true,
        deferred_schedule_name: obj.turboCi?.deferredScheduleName,
        successful_run_only: obj.turboCi?.successfulRunOnly,
      },
    } : {},
  ...obj.deferredSchedule
    && obj.deferredSchedule?.deferredScheduleName
    && isNotEmpty(obj.deferredSchedule?.successfulRunOnly)
    ? {
      deferred_schedule: {
        enabled: true,
        deferred_schedule_name: obj.deferredSchedule?.deferredScheduleName,
        successful_run_only: obj.deferredSchedule?.successfulRunOnly,
      },
    } : {},
  ...(
    obj.scheduleTrigger
      && obj.scheduleTrigger.scheduleName
      && obj.scheduleTrigger.workspaceName
      && obj.scheduleTrigger.triggerOn
  ) ? {
      schedule_trigger: {
        enabled: true,
        schedule_name: obj.scheduleTrigger.scheduleName,
        workspace_name: obj.scheduleTrigger.workspaceName,
        trigger_on: obj.scheduleTrigger.triggerOn,
      },
    } : {},
});
