import { NotificationType } from '.';
import {
  useSetAlertNotificationsMutation,
  KnockAlertChannelId,
} from '../../../client/generated/service';
import { triggerToast } from '../../../utilis';

export interface useSetEmailNotificationsProps {
  onCompleted?: () => void,
  notificationType: NotificationType,
}

export const useSetEmailNotifications = ({
  onCompleted,
  notificationType,
}: useSetEmailNotificationsProps) => {
  const [setAlertNotifications] = useSetAlertNotificationsMutation({
    onCompleted: (data) => {
      if (data.setAlertNotifications?.ok) {
        triggerToast({ header: 'Changes to Bolt system alerts saved', type: 'positive' });
      } else {
        triggerToast({ header: 'Unable to save changes to Bolt system alerts', type: 'neutral' });
      }

      if (onCompleted) onCompleted();
    },
  });

  const saveEmailNotifications = (isEnabled: boolean) => {
    let channelId: KnockAlertChannelId | null = null;

    switch (notificationType) {
      case NotificationType.BOLT:
        channelId = KnockAlertChannelId.EmailBoltAlert;
        break;
      case NotificationType.RADAR:
        channelId = KnockAlertChannelId.EmailDataAlert;
        break;
      default:
        break;
    }

    if (channelId) {
      setAlertNotifications({
        variables: {
          alertChannel: channelId,
          msTeamsChannelNames: [],
          slackChannelIds: [],
          slackUserIds: [],
          emailsEnabled: isEnabled,
        },
      });
    }
  };

  return {
    saveEmailNotifications,
  };
};
