import styled from 'styled-components';

export const AnnouncementBox = styled.div`
  code {
    background-color: rgba(237, 233, 254, 0.5);
    padding: 0.125rem 0.25rem;
    border-radius: 0.25rem;
    color: #453F9C;
    font-family: ui-monospace, monospace;
  }
`;

export const GlowBackgroundContainer = styled.div`
  @keyframes pulse {
    0%, 100% { opacity: 1; }
    50% { opacity: 0.8; }
  }
  @keyframes grain {
    0%, 100% { transform: translate(0, 0) }
    10% { transform: translate(-5%, -5%) }
    20% { transform: translate(-10%, 5%) }
    30% { transform: translate(5%, -10%) }
    40% { transform: translate(-5%, 15%) }
    50% { transform: translate(-10%, 5%) }
    60% { transform: translate(15%, 0%) }
    70% { transform: translate(0%, 10%) }
    80% { transform: translate(-15%, 0%) }
    90% { transform: translate(10%, 5%) }
  }

  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #fafafa;
  perspective: 1000px;

  .primary-glow {
    position: absolute;
    width: 500px;
    height: 500px;
    pointer-events: none;
    transform: translate(-50%, -50%);
    background: radial-gradient(circle, rgba(99, 102, 241, 0.2) 0%, rgba(99, 102, 241, 0.1) 40%, transparent 70%);
    border-radius: 50%;
    mix-blend-mode: multiply;
    filter: blur(30px) contrast(150%);
    animation: pulse 4s ease-in-out infinite;
  }

  .secondary-glow {
    width: 300px;
    height: 300px;
    background: radial-gradient(circle, rgba(223, 222, 248, 0.3) 0%, rgba(223, 222, 248, 0.15) 40%, transparent 70%);
    filter: blur(20px) contrast(120%);
    transform: translate(calc(-50% + ${Math.sin(Date.now() * 0.001) * 50}px), calc(-50% + ${Math.cos(Date.now() * 0.001) * 50}px));
  }
`;

export const Grain = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0.2;
  mix-blend-mode: overlay;
  background: url("data:image/svg+xml,${encodeURIComponent(`
    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
      <filter id="grain">
        <feTurbulence type="fractalNoise" baseFrequency="0.85" numOctaves="4" stitchTiles="stitch"/>
      </filter>
      <rect width="100%" height="100%" filter="url(#grain)" opacity="0.25"/>
    </svg>
  `)}");
  animation: grain 8s steps(10) infinite;
`;
