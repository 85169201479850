import { searchNodes } from '../../../../client/service-dataEndpoint.graphql';
import { getGitBranchInfo } from '../../../../client/service.graphql';
import { graphQLStore } from '../../../../stores';
import commandBarCallbacks from './callbacks';

const getRemoteMainBranch = async () => {
  const { apolloClient } = graphQLStore.getState();
  const gitBranchInfoQueryResponse = await apolloClient?.query({
    query: getGitBranchInfo,
    context: {
      operationType: 'service',
    },
  });

  return gitBranchInfoQueryResponse?.data?.getGitBranchInfo?.remoteMainBranch;
};

const getBranches = async () => {
  const { apolloClient } = graphQLStore.getState();
  const gitBranchInfoQueryResponse = await apolloClient?.query({
    query: getGitBranchInfo,
    context: {
      operationType: 'service',
    },
  });

  return gitBranchInfoQueryResponse?.data?.getGitBranchInfo?.branches;
};

const getLineageNodes = async (query: string) => {
  const { apolloClient } = graphQLStore.getState();
  const { commitHash } = await getRemoteMainBranch();
  window.CommandBar.addMetadata('remoteMainBranchCommitHash', commitHash);

  const searchNodesQueryResponse = await apolloClient?.query({
    query: searchNodes,
    variables: {
      query,
      commitHash,
    },
    context: {
      operationType: 'service-dataEndpoint',
    },
  });

  return searchNodesQueryResponse?.data?.searchLineage?.results || [];
};

const openLineageNodeCallback = ({ branch, node }: any, context: { history: any; }) => {
  const { history } = context;
  window.analytics.track('lineage:nodes_searched');
  history.push(`/lineage/node/${branch.commitHash}/${encodeURIComponent(btoa(node.nodeId))}`);
};

const addLineageCommands = () => {
  // Open Lineage Node
  window.CommandBar.addRecords('branches', [], {
    onInputChange: getBranches,
    labelKey: 'name',
  });
  window.CommandBar.addRecords('lineage_nodes', [], {
    onInputChange: getLineageNodes,
    labelKey: 'canonicalName.name',
  });
  window.CommandBar.addCallback(
    commandBarCallbacks.OPEN_LINEAGE_NODE,
    openLineageNodeCallback,
  );
};

export default addLineageCommands;
