import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import RadarPage from '../RadarPage';
import SnowflakeAlerts from './SnowflakeAlerts';
import BigQueryAlerts from './BigQueryAlerts';
import { DashboardTabIds } from '../config';
import CalloutSetupAlerts from '../../AccountSettings/Notifications/CalloutSetupAlerts';
import { NotificationType } from '../../AccountSettings/Notifications';

const DataAlerts: FC = () => {
  const { tabId } = useParams<{tabId: DashboardTabIds}>();

  return (
    <>
      <CalloutSetupAlerts notificationType={NotificationType.RADAR} eventContext={Contexts.RADAR} />
      <RadarPage>
        {tabId === DashboardTabIds.SNOWFLAKE_ALERTS && <SnowflakeAlerts />}
        {tabId === DashboardTabIds.BIGQUERY_ALERTS && <BigQueryAlerts />}
      </RadarPage>
    </>
  );
};

export default DataAlerts;
