import Checkbox from '@paradime-io/pragma-ui-kit/lib/components/CheckBox';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import React, {
  Dispatch, FC, FormEvent, SetStateAction, useState,
} from 'react';
import { slackUser } from './InviteRoles';

interface SlackUserProps {
  user: slackUser,
  initialState: boolean,
  setSelected: Dispatch<SetStateAction<slackUser[]>>
}

const SlackUser: FC<SlackUserProps> = ({ user, initialState, setSelected }) => {
  const { profileRealNameNormalized: name, profileImage32: image } = user;

  const [currentState, setCurrentState] = useState(initialState);

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    setCurrentState(e.currentTarget.checked);
    if (e.currentTarget.checked) {
      const newUserSelected = {
        ...user,
      };
      setSelected((current) => [...current, newUserSelected]);
    } else {
      setSelected((current) => current.filter((item) => item.slackId !== user.slackId));
    }
  };

  return (
    <div className="userRow" data-testid="slackUser">
      <Checkbox
        checked={currentState}
        onChange={handleChange}
        label={name}
        fill
        view="flat"
        color="default"
        type="dense"
        eventContext={Contexts.MODAL}
        eventObject="selectSlackUser"
        eventAction={Actions.SELECTED}
      />
      <img src={image || `https://via.placeholder.com/32x32/726ae3?text=${(name || 'X')[0].toUpperCase()}`} alt="user profile" className="right" />
    </div>
  );
};

export default SlackUser;
