import { isSQL } from '../../utilis';
import { catalogueItemTabsVisibilityProps } from '../types';

export const catalogueItemTabsVisibility = ({
  resultItem,
}: catalogueItemTabsVisibilityProps) => {
  const showModelTab = () => {
    switch (resultItem?.__typename) {
      case 'GQLCatalogueDwhTableItem':
      case 'GQLCatalogueMaterialisedDbtItem':
      case 'GQLCatalogueDwhColumnItem':
      case 'GQLCatalogueMaterialisedDbtObjectColumnItem':
        return isSQL(resultItem.dbtModelPath);

      default:
        return false;
    }
  };

  return {
    overview: true,
    fields: false,
    metrics: false,
    columns: true,
    dependencies: true,
    recordings: false,
    schema: false,
    model: showModelTab(),
  };
};
