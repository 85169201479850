import { graphQLStore } from '../../../../stores';
import useOrganisation from '../../../hooks/useOrganisation';

export const useSelectParadimeOrg = () => {
  const clusterConfigFromStore = graphQLStore((state) => state.clusterConfig);
  const setParadimeOrganisation = graphQLStore((state) => state.setParadimeOrganisation);

  const { setCurrentParadimeOrganisationInLocalStorage } = useOrganisation();

  const selectParadimeOrg = (orgName: string) => {
    const paradimeOrganisation = {
      name: orgName,
      auth0Organisation: clusterConfigFromStore!.auth0OrgIdForOnboarding,
      auth0ApiDomain: clusterConfigFromStore!.auth0ApiDomain,
      auth0ClientId: clusterConfigFromStore!.auth0ClientId,
      auth0EndpointDomain: clusterConfigFromStore!.auth0EndpointDomain,
      controlPlaneUrl: clusterConfigFromStore!.controlPlaneUrl,
    };

    setParadimeOrganisation(paradimeOrganisation);
    setCurrentParadimeOrganisationInLocalStorage({ ...paradimeOrganisation, location: 'eu' });
  };

  return selectParadimeOrg;
};
