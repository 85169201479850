import { useEffect } from 'react';
import { CommandItemProps, ShortcutKey } from '../CommandItem';
import { appStore } from '../../../../../stores';

export const matchesShortcut = (
  event: KeyboardEvent, shortcut: ShortcutKey,
): boolean => event.key.toLowerCase() === shortcut.key.toLowerCase()
    && !!shortcut.metaKey === (event.metaKey || event.ctrlKey)
    && !!shortcut.ctrlKey === event.ctrlKey
    && !!shortcut.shiftKey === event.shiftKey
    && !!shortcut.altKey === event.altKey;

export const useKeyboardShortcuts = (
  commands: CommandItemProps[],
) => {
  const setShowCommandBar = appStore((s) => s.setShowCommandBar);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      commands.some((command) => {
        if (command.shortcut && matchesShortcut(event, command.shortcut)) {
          event.preventDefault();
          if (command.nested) {
            setShowCommandBar(true);
          }
          if (command.onSelect) command.onSelect();
          return true;
        }
        return false;
      });
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [commands, setShowCommandBar]);
};
