import { userType } from '@paradime-io/pragma-ui-kit/lib/components/LineagePopupChat';
import { Node, Edge } from 'reactflow';
import create from 'zustand';
import { combine } from 'zustand/middleware';
import { LinkType, NodeType } from '../components/Lineage/utils';

export interface nodeToOpenType {
  node: string,
  branch: string
}

export interface lineageChatDataType {
  commitHash: string,
  nodeId: string,
  slackUserIds: string[],
  users: userType[],
  time: string,
  type: string,
  title: string,
  subtitle: string
}

export const lineageStore = create(
  combine(
    {
      currentBranchInEditor: '' as string,
      currentBranch: '' as string,
      nodeName: '' as string,
      nodeId: '' as string,
      nodeHumanReadableName: '' as string,
      currentCommitHash: undefined as string | undefined,
      compareFromCommitHash: undefined as string | undefined,
      compareToCommitHash: undefined as string | undefined,
      filePath: undefined as string | undefined,
      nodeToOpen: undefined as nodeToOpenType | undefined,
      lineageLoading: false,
      chatOpen: false,
      lineageChatData: undefined as lineageChatDataType | undefined,
      initialNodes: [] as NodeType[],
      initialEdges: [] as LinkType[],
      lineageNodes: undefined as undefined | Node[],
      lineageEdges: undefined as undefined | Edge[],
      edgesToUpdate: [] as Edge[],
      customNodeDepth: {
        upstream: '1',
        downstream: '1',
      },
      editorLineagePanelMaximized: false,
      dbtPackageNames: [] as string[][],
    },
    (set) => ({
      setInitialNodes: (initialNodes: NodeType[]) => set(() => ({ initialNodes })),
      setInitialEdges: (initialEdges: LinkType[]) => set(() => ({ initialEdges })),
      setNodeHumanReadableName: (newNodeHumanReadableName: string) => set(() => ({
        nodeHumanReadableName: newNodeHumanReadableName,
      })),
      setNodeId: (newNodeId: string) => set(() => ({
        nodeId: newNodeId,
      })),
      setNodeName: (newNodeName: string) => set(() => ({
        nodeName: newNodeName,
      })),
      setChatOpenStatus: (newState: boolean) => set(() => ({
        chatOpen: newState,
      })),
      setLineageChatData: (data: lineageChatDataType) => set(() => ({
        lineageChatData: data,
      })),
      setLineageLoading: (newState: boolean) => set(() => ({
        lineageLoading: newState,
      })),
      setCurrentCommitHash: (newCommitHash: string) => set(() => ({
        currentCommitHash: newCommitHash,
      })),
      setCurrentBranch: (newBranch: string) => set(() => ({
        currentBranch: newBranch,
      })),
      setCurrentBranchInEditor: (newBranch: string) => set(() => ({
        currentBranchInEditor: newBranch,
      })),
      setCompareToCommitHash: (newCommitHash: string) => set(() => ({
        compareToCommitHash: newCommitHash,
      })),
      setCompareFromCommitHash: (newCommitHash: string) => set(() => ({
        compareFromCommitHash: newCommitHash,
      })),
      setFilePath: (newFilePath: string) => set(() => ({
        filePath: newFilePath,
      })),
      setNodeToOpen: (NodeName: nodeToOpenType) => set(() => ({
        nodeToOpen: NodeName,
      })),
      clearNodeToOpen: () => set(() => ({
        nodeToOpen: undefined,
      })),
      setLineageNodes: (lineageNodes?: Node[]) => set(() => ({ lineageNodes })),
      setLineageEdges: (lineageEdges?: Edge[]) => set(() => ({ lineageEdges })),
      setEdgesToUpdate: (edgesToUpdate: Edge[]) => set(() => ({ edgesToUpdate })),
      setCustomNodeDepth: ({
        upstream,
        downstream,
      }: { upstream: string, downstream: string }) => set(() => (
        { customNodeDepth: { upstream, downstream } }
      )),
      setEditorLineagePanelMaximized: (
        isFullscreen: boolean,
      ) => set(() => ({ editorLineagePanelMaximized: isFullscreen })),
      setDbtPackageNames: (dbtPackageNames: string[]) => set(() => {
        const transformedPackageNames = dbtPackageNames.map(
          (packageName) => ([packageName, packageName]),
        );
        return { dbtPackageNames: transformedPackageNames };
      }),
    }),
  ),
);
