import { useEffect, useState } from 'react';
import { graphQLStore } from '../../stores';
import { useGetJwtToken } from '../../utilis';

export const useUpdateJwtToken = () => {
  const { getToken } = useGetJwtToken();
  const setJwtToken = graphQLStore((state) => state.setJwtToken);

  const updateJwtToken = async () => {
    const idToken = await getToken();
    setJwtToken(idToken);
  };

  return {
    updateJwtToken,
  };
};

const useUpdateJwtTokenPeriodically = () => {
  const [initialTokenIntervalId, setInitialTokenIntervalId] = useState<NodeJS.Timeout>();

  const jwtToken = graphQLStore((state) => state.jwtToken);

  const { updateJwtToken } = useUpdateJwtToken();

  useEffect(() => {
    // eslint-disable-next-line no-undef
    let updateTokenPeriodically: NodeJS.Timeout | undefined;

    const reloadJwtTokenInterval = async () => {
      await updateJwtToken();

      updateTokenPeriodically = setInterval(updateJwtToken, 20000);
    };

    reloadJwtTokenInterval();

    return () => {
      if (updateTokenPeriodically) clearInterval(updateTokenPeriodically);
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!jwtToken && !initialTokenIntervalId) {
      // While the very first JWT token hasn't been set, retry every second
      const intervalId = setInterval(updateJwtToken, 1000);
      setInitialTokenIntervalId(intervalId);
    } else if (jwtToken && initialTokenIntervalId) {
      clearInterval(initialTokenIntervalId);
    }

    return () => {
      if (initialTokenIntervalId) clearInterval(initialTokenIntervalId);
    };
  }, [jwtToken]);
};

export default useUpdateJwtTokenPeriodically;
