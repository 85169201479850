import { useEffect, useState } from 'react';

interface NavigatorParadime extends Navigator {
  connection: NetworkInformation,
  mozConnection: NetworkInformation,
  webkitConnection: NetworkInformation,
}

type ConnectionType =
  | 'bluetooth'
  | 'cellular'
  | 'ethernet'
  | 'mixed'
  | 'none'
  | 'other'
  | 'unknown'
  | 'wifi';

// http://wicg.github.io/netinfo/#effectiveconnectiontype-enum
type EffectiveConnectionType = '2g' | '3g' | '4g' | 'slow-2g';

// http://wicg.github.io/netinfo/#dom-megabit
type Megabit = number;

// http://wicg.github.io/netinfo/#dom-millisecond
type Millisecond = number;

export interface NetworkInformation extends EventTarget {
  rtt?: Millisecond,
  type: ConnectionType,
  saveData?: boolean,
  downlink?: Megabit,
  downlinkMax?: Megabit,
  effectiveType?: EffectiveConnectionType,
  since?: string,
  online?: boolean,
}

const paradimeNavigator = navigator as unknown as NavigatorParadime;

const getNetworkConnection = () => (
  paradimeNavigator.connection
  || paradimeNavigator.mozConnection
  || paradimeNavigator.webkitConnection
  || null
);

const getNetworkConnectionInfo = () => {
  const connection = getNetworkConnection();
  if (!connection) {
    return {};
  }

  return {
    rtt: connection.rtt,
    type: connection.type,
    saveData: connection.saveData,
    downlink: connection.downlink,
    downlinkMax: connection.downlinkMax,
    effectiveType: connection.effectiveType,
  } as NetworkInformation;
};

const useNetworkStatus = () => {
  const [state, setState] = useState<NetworkInformation>({
    since: undefined,
    online: navigator.onLine,
    ...getNetworkConnectionInfo(),
    addEventListener: () => {},
    removeEventListener: () => {},
    dispatchEvent: () => false,
    type: 'unknown',
  });

  useEffect(() => {
    const handleOnline = () => {
      setState((prevState) => ({
        ...prevState,
        online: true,
        since: new Date().toString(),
      }));
    };

    const handleOffline = () => {
      setState((prevState) => ({
        ...prevState,
        online: false,
        since: new Date().toString(),
      }));
    };

    const handleConnectionChange = () => {
      setState((prevState) => ({
        ...prevState,
        ...getNetworkConnectionInfo(),
      }));
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    const connection = getNetworkConnection();
    if (connection?.addEventListener) connection.addEventListener('change', handleConnectionChange);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
      if (connection?.removeEventListener) connection.removeEventListener('change', handleConnectionChange);
    };
  }, []);

  return state;
};

export { useNetworkStatus };
