import { useGetPublicResourceUrlLazyQuery } from '../../../client/generated/service';

const useDownloadArtifact = () => {
  const [download] = useGetPublicResourceUrlLazyQuery();

  const downloadArtifact = (commandResourceId: number) => {
    download({
      variables: {
        scheduleCommandResourceId: commandResourceId,
      },
      onCompleted: (data) => {
        if (data.getPublicResourceUrl?.url) {
          window.open(data.getPublicResourceUrl?.url);
        }
      },
    });
  };

  return {
    downloadArtifact,
  };
};

export default useDownloadArtifact;
