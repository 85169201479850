import { useEffect, useState } from 'react';
import useListWorkspaces from './useListWorkspaces';
import useSetWorkspace from './useSetWorkspace';

const useAutoSwitchWorkspaces = () => {
  const url = new URL(window.location.href);
  const desiredWorkspaceId = url.searchParams.get('workspaceUid');

  const [newWorkspaceName, setNewWorkspaceName] = useState('');

  const { currentWorkspace, joinedWorkspacesList } = useListWorkspaces();
  const { setCurrentWorkspace, loading: isLoadingWorkspaceSwitch } = useSetWorkspace({
    onCompleted: () => {
      window.location.reload();
    },
  });

  // Workspace ID should be 16 alphanumeric characters
  const isValidWorkspaceId = (id: string | null) => (
    new RegExp('([a-zA-Z0-9]){16}').test(id || '')
  );

  useEffect(() => {
    if (isValidWorkspaceId(currentWorkspace?.uid)
      && isValidWorkspaceId(desiredWorkspaceId)
      && currentWorkspace?.uid !== desiredWorkspaceId) {
      // eslint-disable-next-line no-console
      console.info(`Auto-switching workspace from ${currentWorkspace.uid} to ${desiredWorkspaceId}`);
      setCurrentWorkspace(desiredWorkspaceId as string);

      const desiredWorkspace = joinedWorkspacesList.filter(
        ({ uid }) => uid === desiredWorkspaceId,
      )[0];
      setNewWorkspaceName(desiredWorkspace.name);
    }
  }, [url, currentWorkspace]);

  return { isLoadingWorkspaceSwitch, newWorkspaceName };
};

export default useAutoSwitchWorkspaces;
