import React, { FC } from 'react';
import PageTemplate from '../Utils/PageTemplate';

const ErrorExpiredInviteLink: FC = () => (
  <PageTemplate
    withLogo
    icon={{ name: 'disable', color: 'var(--red80)' }}
    title="Time's up for this invite link ..."
    titleTextType="h4"
    subtitle="Hi, your invite link has expired. Ask your Paradime admin to re-send you a new invite!"
    subtitleTextType="body"
    withBox={false}
  />
);

export default ErrorExpiredInviteLink;
