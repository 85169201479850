import styled from 'styled-components';
import Dropdown from '@paradime-io/pragma-ui-kit/lib/components/Dropdown';
import DefaultInput from '@paradime-io/pragma-ui-kit/lib/components/DefaultInput';
import MultiLineInput from '@paradime-io/pragma-ui-kit/lib/components/MultiLineInput';

export const WareHouseFactory = styled.form<{formDistribution: string}>`
  display: grid;
  grid-auto-flow: row;
  width: 100%;
  grid-auto-columns: 1fr;
  gap: 10px;
  grid-template-areas: ${(props) => props.formDistribution};
`;

export const DropdownWithOverflow = styled(Dropdown)`
span.input {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}
`;

export const DefaultInputWithoutErrorIcon = styled(DefaultInput)`
  span.bp4-input-action {
    display: none;
  }
`;

export const FileErrorMessageDisplayer = styled.div`
  font-size: var(--font_size_caption);
  letter-spacing: var(--letter_spacing_caption);
  font-weight: var(--font_weight_caption);
  animation: shake-horizontal 2s linear both;
  color: var(--red60);

  @keyframes shake-horizontal {
    0% { transform: translateX(0) }
    5% { transform: translateX(5px) }
    10% { transform: translateX(-5px) }
    15% { transform: translateX(5px) }
    20% { transform: translateX(-5px) }
    25% { transform: translateX(5px) }
    30% { transform: translateX(-5px) }
    35% { transform: translateX(5px) }
    40% { transform: translateX(-5px) }
    45% { transform: translateX(5px) }
    50% { transform: translateX(-5px) }
    55% { transform: translateX(5px) }
    60% { transform: translateX(-5px) }
    65% { transform: translateX(5px) }
    70% { transform: translateX(-5px) }
    75% { transform: translateX(5px) }
    80% { transform: translateX(-5px) }
    85% { transform: translateX(5px) }
    90% { transform: translateX(-5px) }
    95% { transform: translateX(5px) }
    100% { transform: translateX(0) }
   }
`;

export const RaisedMultiLineInput = styled(MultiLineInput)`
  box-shadow: var(--shadow2dp) !important;
  background: var(--white) !important;
  color: var(--color100) !important;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) !important;
  border: none !important;

  &::before {
    border: none !important;
  }
`;

export const OptionalConfigAccordion = styled.div`
  border-radius: 8px;
  background-color: var(--grey0);

  &:hover {
    background-color: var(--grey5);
  }
`;
