import React, { FC } from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import ButtonsRow from './ButtonsRow';
import noSubscription from './images/noSubscription.svg';

const NoSubscription: FC = () => (
  <AutoLayout
    direction="vertical"
    padding="none"
    verticalGap="expanded"
    distribution="packed"
    style={{ maxWidth: '470px' }}
  >
    <AutoLayout
      direction="vertical"
      padding="none"
      verticalGap="very-dense"
      distribution="packed"
    >
      <img src={noSubscription} alt="You do not have a subscription" style={{ margin: 'auto' }} />
      <Typography
        font="inter"
        type="h5"
        style={{ textAlign: 'center' }}
      >
        Reactivate your subscription
      </Typography>
      <Typography
        font="inter"
        type="body-small"
        color="default"
        colorStep="100"
        style={{ textAlign: 'center' }}
      >
        To continue using Paradime, you must upgrade to one of the Paradime plans.
      </Typography>
    </AutoLayout>
    <ButtonsRow />
  </AutoLayout>
);

export default NoSubscription;
