import React, { useEffect, useRef } from 'react';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { alertStore, graphQLStore, userAuthStore } from '../../stores';
import { SlackSearchQuery } from '../../client/generated/service';
import { useOnboardSlack } from './useOnboardSlack';
import { userHasSlackAdminAccess } from '../../utilis/PermissionsService';

const useCheckIfSlackIsOnboarded = (
  querySlackUsersCallbackfn: (slackUsersData: SlackSearchQuery) => void, // eslint-disable-line
) => {
  const currentCode = useRef('');

  const clusterConfig = graphQLStore((s) => s.clusterConfig);

  const { location: { host } } = window;
  const openAlert = alertStore((s) => s.openAlert);
  const closeAlert = alertStore((s) => s.closeAlert);
  const { currentUser } = userAuthStore.getState();

  const { accessLevel } = currentUser;

  const base = 'https://slack.com/oauth/v2/authorize?';
  const scopes = [
    'channels:read',
    'chat:write',
    'chat:write.public',
    'groups:read',
    'users:read',
    'users:read.email',
  ];
  const scope = `scope=${scopes.join(',')}`;
  const clientId = `&client_id=${clusterConfig?.slackId}`;
  const redirectUri = `&redirect_uri=https://${host}/auth/slack-success`;
  const slackUrl = base + scope + clientId + redirectUri;

  const {
    loading,
    localSlackOnboarded,
    onboardSlack,
    slackIntegrationData,
    querySlackUsers,
  } = useOnboardSlack(querySlackUsersCallbackfn);

  const checkIfSlackIsOnboarded = () => {
    if (slackIntegrationData?.organisationIntegrationList.slack === 'available'
      && !localSlackOnboarded) {
      if (userHasSlackAdminAccess(accessLevel)) {
        window.open(slackUrl);
      } else {
        const message = (
          <Typography tagName="span" type="body-small" color="danger" colorStep="100">
            Slack is not yet connected to your Paradime workspace.
            Reach out to your workspace admin to set Slack up.
          </Typography>
        );
        openAlert({
          isOpen: true,
          icon: 'error',
          type: 'dense',
          view: 'raised',
          color: 'danger',
          title: 'Error connecting to Slack',
          children: message,
          backdropOpacity: '20',
          showRightButton: false,
          showLeftButton: false, /* istanbul ignore next-line */
          onClose: () => closeAlert(),
        });
      }
    } else {
      querySlackUsers();
    }
  };

  const onboardSlackWithoutChecking = () => (
    window.open(slackUrl)
  );

  useEffect(() => {
    const handleMessage = async (e: any) => {
      const { data: { code } } = e;
      if (code && !currentCode.current) {
        currentCode.current = code;
        onboardSlack({
          variables: {
            code: currentCode.current,
            redirectUri: `https://${host}/auth/slack-success`,
          },
        });
      }
    };
    window.addEventListener('message', handleMessage);

    return () => window.removeEventListener('message', handleMessage, false);
  }, []); // eslint-disable-line

  return {
    loading,
    checkIfSlackIsOnboarded,
    onboardSlackWithoutChecking,
    localSlackOnboarded,
    slackUrl,
  };
};

export default useCheckIfSlackIsOnboarded;
