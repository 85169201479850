import { IntegrationHandler } from '..';
import { lineageFilterNodes } from './LineageFilterNodes';
import { lineageContextMenu } from './LineageContextMenu';
import { lineageNodeDisplay } from './LineageNodeDisplay';
import { lineageSearchInputDropdown } from './LineageSearchInputDropdown';
import { catalogueSearchResultItem } from './CatalogueSearchResultItem';
import { catalogueNameAndIcon } from './CatalogueNameAndIcon';
import { catalogueDetailsCard } from './CatalogueDetailsCard';
import { catalogueSimpleType } from './CatalogueSimpleType';
import { catalogueDescription } from './CatalogueDescription';
import { catalogueItemTabsVisibility } from './CatalogueItemTabsVisibility';
import { catalogueLogo } from './CatalogueLogo';
import { catalogueHeaderButtons } from './CatalogueHeaderButtons';
import { catalogueDependenciesTab } from './CatalogueDependenciesTab';
import { catalogueTreeIcons } from './CatalogueTreeIcons';
import { catalogueFieldsTab } from './CatalogueFieldsTab';

export enum IntegrationAPIItemHandlerAcceptedTypes {
  'INTEGRATION_API_ITEM' = 'integrationAPIItem',
  'GQLCatalogueIntegrationDatasourceItem' = 'GQLCatalogueIntegrationDatasourceItem',
  'GQLCatalogueIntegrationDashboardItem' = 'GQLCatalogueIntegrationDashboardItem',
  'GQLCatalogueIntegrationChartItem' = 'GQLCatalogueIntegrationChartItem',
  'POWER_BI' = 'power_bi',
  'THOUGHTSPOT' = 'thoughtSpot',
  'SIGMA' = 'Sigma',
}

export enum IntegrationAPIItemSubTypes {
}

export const IntegrationAPIItemIntegrationHandler: IntegrationHandler = ({
  type: IntegrationAPIItemHandlerAcceptedTypes.INTEGRATION_API_ITEM,
  subTypes: [],
  handlers: {
    lineageContextMenu,
    lineageSearchInputDropdown,
    lineageFilterNodes,
    catalogueSearchResultItem,
    catalogueDetailsCard,
    catalogueNameAndIcon,
    catalogueHeaderButtons,
    catalogueClassificationData: () => ({
      keyList: [],
      tableClassificationOptions: [],
    }),
    catalogueLogo,
    catalogueSimpleType,
    catalogueDescription,
    lineageNodeDisplay,
    catalogueAssetColumns: () => [],
    catalogueAssetItemId: () => 'error',
    catalogueSearchFilters: () => undefined,
    catalogueItemTabsVisibility,
    catalogueFieldsTab,
    catalogueDependenciesTab,
    catalogueSchemaTab: () => [{
      tableName: '',
      columnData: [],
    }],
    catalogueDbtModelTab: () => undefined,
    catalogueTreeIcons,
    catalogueShowClassificationsCard: () => false,
  },
});
