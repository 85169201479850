/* eslint-disable no-useless-escape */
import React, {
  FC,
  ChangeEvent,
  ClipboardEvent,
  KeyboardEvent,
  MouseEvent,
  useState,
  useEffect,
} from 'react';
import { Icon } from '@blueprintjs/core';
import Chips from '@paradime-io/pragma-ui-kit/lib/components/Chips';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import DefaultInput from '@paradime-io/pragma-ui-kit/lib/components/DefaultInput';
import { func } from 'prop-types';
import { useHistory } from 'react-router-dom';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Divider from '@paradime-io/pragma-ui-kit/lib/components/Divider';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import {
  SlackSearchQuery,
} from '../../../client/generated/service';
import { useLocationState, useUpdateLocationState } from '../../../utilis';
import { appStore } from '../../../stores';
import * as S from './InvitationStates.styles';
import useCheckIfSlackIsOnboarded from '../../hooks/useCheckIfSlackIsOnboarded';

interface InvitationsProps {
  onClick: Function,
}

const Invitations: FC<InvitationsProps> = ({
  onClick,
}) => {
  const setShowInviteUser = appStore((s) => s.setShowInviteUser);
  const [state] = useLocationState();
  const updateLocationState = useUpdateLocationState();
  const [emails, setEmails] = useState([] as string[]);
  const [value, setValue] = useState('');
  const history = useHistory();

  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]+$/;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let newEmail = '';
    const { target: { value: newVal } } = e;
    setValue(newVal);
    if (newVal.includes(',')) [newEmail] = newVal.split(',');
    const validEmail = emailRegex.test(newEmail);
    if (validEmail) {
      setEmails([...emails, newEmail]);
      setValue('');
    }
  };

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    const { clipboardData } = e;
    const email = clipboardData.getData('Text');

    const validEmail = emailRegex.test(email);
    if (validEmail) {
      setEmails([...emails, email]);
      setTimeout(() => setValue(''), 50);
    }
  };

  const handleEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const email = e.currentTarget.value;

      const validEmail = emailRegex.test(email);
      if (validEmail) {
        setEmails([...emails, email]);
        setTimeout(() => setValue(''), 50);
      }
    }
  };

  const handleRemove = (e: MouseEvent<HTMLButtonElement>) => {
    if (e.currentTarget.parentElement?.dataset.email) {
      const currentEmail = e.currentTarget.parentElement.dataset.email;
      const emailBuffer = emails.filter((email) => email !== currentEmail);
      setEmails([...emailBuffer]);
    }
  };

  useEffect(() => {
    updateLocationState({ emails });
  }, [emails]); // eslint-disable-line

  const handleNextSlide = () => {
    updateLocationState({
      inviteTitle: 'Invite your team using Email',
    });
    onClick((current: number) => current + 1);
  };

  const querySlackUsersCallbackfn = (slackUsersData: SlackSearchQuery) => {
    updateLocationState({
      slackUsers: slackUsersData?.slackSearch?.users,
      inviteTitle: 'Invite your team from Slack',
      showSlack: true,
    });
    onClick((current: number) => current + 1);
  };

  const {
    checkIfSlackIsOnboarded,
    loading,
  } = useCheckIfSlackIsOnboarded(querySlackUsersCallbackfn);

  return (
    <S.Invitations>
      <AutoLayout
        direction="vertical"
        padding="expanded"
        distribution="packed"
        verticalGap="normal"
        alignment="top-center"
        style={{ paddingTop: '2rem' }}
      >
        <Typography
          tagName="span"
          type="h4"
          color="default"
          colorStep="100"
          style={{ textAlign: 'center', display: 'block' }}
        >
          Invite team members
        </Typography>
        <S.SlackButton
          type="standard"
          view="flat"
          integrationIcon="slack"
          text="Invite from Slack"
          onClick={checkIfSlackIsOnboarded}
          eventContext={Contexts.APP}
          eventObject="inviteUsingSlack"
          eventAction={Actions.CLICKED}
        />
        <Divider style={{ margin: 0 }} text=" Or " textBackgroundColor="var(--white)" />
        <div>
          <label htmlFor="emailInput">
            <AutoLayout
              direction="horizontal"
              padding="none"
              horizontalGap="very-dense"
              distribution="packed"
            >
              <Icon icon="envelope" color="var(--icon)" size={24} />
              <Typography
                tagName="span"
                type="body-bold-small"
                color="default"
                colorStep="70"
              >
                Invite using Email
              </Typography>
            </AutoLayout>
            <div className="emails">
              {emails.map((email) => (
                <Chips
                  color="primary"
                  removable
                  round
                  style={{ margin: '4px', verticalAlign: 'bottom' }}
                  tag={email}
                  key={`key-invited-${email}`}
                  data-email={email}
                  type="dense"
                  view="smooth"
                  onRemove={handleRemove}
                  data-testid="chip"
                />
              ))}
              <DefaultInput
                data-testid="emailInput"
                sensitive
                color="default"
                placeholder="Add email and press comma or enter"
                dense
                type="text"
                value={value}
                view="outlined"
                id="emailInput"
                name="emailInput"
                onChange={handleChange}
                onPaste={handlePaste} // @ts-ignore
                onKeyPress={handleEnter}
                expose={handleChange}
                autocomplete="off"
                data-lpignore="true"
                data-form-type="other"
                style={{ boxShadow: 'none', width: '300px' }}
              />
            </div>
          </label>
        </div>
        <div className="exit-button">
          <DefaultButton
            data-testid="continueButton"
            text="Continue"
            disabled={emails.length === 0}
            onClick={handleNextSlide}
            color="primary"
            type="standard"
            className="bp4-intent-6"
            loading={loading}
            eventContext={Contexts.MODAL}
            eventObject="inviteUsers"
            eventAction={Actions.STARTED}
            eventProperties={{
              location: 'onboarding',
              type: 'email',
            }}
          />
          <button
            data-testid="remindMeLaterButton"
            style={{
              border: 'none',
              background: 'none',
            }}
            className="later"
            onClick={() => {
              updateLocationState({ onboardingToken: undefined });
              window.analytics.track('modal:inviteUsers_cancelled', {
                location: 'onboarding',
              });
              history.replace(window.location.pathname, {
                ...state,
                onboardingToken: undefined,
                accountType: undefined,
                firstTimeLoading: undefined,
              });
              setShowInviteUser(false);
              onClick((current: number) => current + 2);
            }}
          >
            Remind me later
          </button>
        </div>
      </AutoLayout>
    </S.Invitations>
  );
};

Invitations.propTypes = {
  onClick: func.isRequired,
};

export default Invitations;
