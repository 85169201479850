import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import PageTemplate from '../Onboarding/SynqOnboarding/Utils/PageTemplate';
import OrganisationSelector from './ParadimeOrganisations/OrganisationSelector';
import LandingPageButtons from './LandingPageButtons';
import { graphQLStore } from '../../stores';
import GetStarted from './ParadimeOrganisations/GetStarted';
import StartLandingPage from './ParadimeOrganisations/StartLandingPage';
import * as S from './Home.styles';

const JoinOrCreate: FC = () => {
  const history = useHistory();

  const { location: { href } } = window;
  const url = new URL(href);
  const isFromParadimeWebsite = ['chrome-extension', 'main-app'].includes(url.searchParams.get('target') || '');
  const orgNameFromUrl = url.searchParams.get('organisationName');

  const paradimeOrganisationFromStore = graphQLStore((state) => state.paradimeOrganisation);

  const getBoxContent = () => {
    if (isFromParadimeWebsite) return (<LandingPageButtons />);
    if (orgNameFromUrl) return (<GetStarted />);

    return (<OrganisationSelector />);
  };

  useEffect(() => {
    // User is already authenticated
    if (paradimeOrganisationFromStore) {
      const { searchParams } = new URL(href);
      searchParams.delete('organisationName'); // delete it, we don't need it anymore
      let redirectUrl = searchParams.toString() ? `/home?${searchParams.toString()}` : '/home';

      const isLoggingInToTheExtension = url.searchParams.get('origin') === 'chrome-extension';
      if (isLoggingInToTheExtension) {
        redirectUrl = '/extension-login-success';
      }
      history.push(redirectUrl);
    }
  }, []);

  const PageTitle = () => (
    <Typography tagName="span" type="h5" colorStep="0">
      The
      {' '}
      <S.Accent>AI-powered</S.Accent>
      {' '}
      platform to accelerate analytics and slash warehouse spend
    </Typography>
  );

  return (
    <PageTemplate
      childComponent={(
        <StartLandingPage
          title={PageTitle()}
        />
      )}
      withLogo
      withBox
      logoLight
      boxOrientation="vertical"
      boxWidth="476px"
      useThickerBorder={false}
      pageBackgroundColour="#021033"
      boxContents={getBoxContent()}
    />
  );
};

export default JoinOrCreate;
