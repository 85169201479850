import { IconName } from '@blueprintjs/icons';
import { catalogueSearchResultItemProps, catalogueSearchResultItemReturnType } from '../types';
import { decodeLogoBase64 } from '../utils';
import { GqlCatalogueIntegrationItemExtraAttribute } from '../../client/generated/service-dataEndpoint';

interface extraAttributesReturnType {
  type: 'textWithIcon',
  text: string,
  icon: IconName,
}

const mapExtraAttributes = (
  extraAttributes?: GqlCatalogueIntegrationItemExtraAttribute[] | null,
): extraAttributesReturnType[] => {
  if (!extraAttributes) return [];

  const mappedItems = extraAttributes?.map(({ name, iconName, value }) => {
    if (value && iconName) {
      return {
        type: 'textWithIcon',
        text: value,
        icon: iconName,
      };
    }
    if (value && name) {
      return {
        type: 'textWithHeader',
        header: name,
        text: value,
      };
    }
    return null;
  });

  return mappedItems.filter((item) => item !== null) as extraAttributesReturnType[];
};

export const catalogueSearchResultItem = ({
  resultItem,
}: catalogueSearchResultItemProps): catalogueSearchResultItemReturnType => {
  switch (resultItem.__typename) {
    case 'GQLCatalogueIntegrationDatasourceItem':
    case 'GQLCatalogueIntegrationDashboardItem':
    case 'GQLCatalogueIntegrationChartItem':
      return (
        [
          {
            type: 'list',
            iconName: resultItem.itemTypeIconName as IconName,
            title: resultItem?.name || '-',
            subtitle: resultItem.itemType.split(':')?.[2] || '',
            rightImage: decodeLogoBase64(resultItem.integrationLogoBase64),
          },
          {
            type: 'description',
            description: resultItem?.description || '',
          },
          { type: 'divider' },
          [
            {
              leftComponents: mapExtraAttributes(resultItem?.extraAttributes),
              rightComponents: [
                {
                  type: 'tags',
                  tags: resultItem.tags,
                },
              ],
            },
          ],
        ]
      );

    default:
      return [];
  }
};
