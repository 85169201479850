import React, { FC } from 'react';
import MenuItem from '@paradime-io/pragma-ui-kit/lib/components/MenuItem';
import MenuDivider from '@paradime-io/pragma-ui-kit/lib/components/MenuDivider';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { lineageContextMenuProps } from '../types';
import { TableauHandlerAcceptedTypes } from '.';
import { userHasLineageContextMenuAccess } from '../../utilis/PermissionsService';

export const lineageContextMenu: FC<lineageContextMenuProps> = ({
  activeNode,
  accessLevel,
  onViewDocumentationClick,
}) => {
  const isNodeOpenDisabled = (
    activeNode.data?.url === null
    || !userHasLineageContextMenuAccess(accessLevel)
  );

  const viewDocumentationNodes = [
    TableauHandlerAcceptedTypes.TableauDatasourceNode,
    TableauHandlerAcceptedTypes.TableauWorksheetNode,
    TableauHandlerAcceptedTypes.TableauDashboardNode,
  ];

  return (
    <>
      <MenuItem
        color="default"
        text="Create a new thread"
        type="dense"
        view="smooth"
        disabled
        eventContext={Contexts.LINEAGE}
        eventObject="createNewThread"
        eventAction={Actions.CLICKED}
      />
      <MenuDivider />
      <MenuItem
        color="default"
        text="Open in Tableau"
        type="dense"
        view="raised"
        onItemClick={isNodeOpenDisabled
          ? undefined
          : () => window.open(activeNode.data?.url)}
        disabled={isNodeOpenDisabled}
        eventContext={Contexts.LINEAGE}
        eventObject="openNodeInTableau"
        eventAction={Actions.OPENED}
        eventProperties={{ type: activeNode.data?.nodeType }}
      />
      {activeNode.data?.nodeType && viewDocumentationNodes.includes(
        activeNode.data?.nodeType as TableauHandlerAcceptedTypes,
      ) ? (
        <MenuItem
          color="default"
          text="View documentation"
          type="dense"
          view="smooth"
          onItemClick={onViewDocumentationClick}
          eventContext={Contexts.LINEAGE}
          eventObject="viewDocumentation"
          eventAction={Actions.VIEWED}
        />
        ) : null}
    </>
  );
};
