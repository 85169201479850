import React, { FC } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import PageTitle, { PageTitles } from '../PageTitle';
import BasicInfo from './MainApp/OnboardingSteps/BasicInfo';
import GettingStarted from './MainApp/OnboardingSteps/GettingStarted';
import WorkspaceSettings from './MainApp/OnboardingSteps/WorkspaceSettings';
import ChooseRepoProvider from './MainApp/OnboardingSteps/ChooseRepoProvider';
import ConnectRepo from './MainApp/OnboardingSteps/ConnectRepo';
import ConnectWarehouse from './MainApp/OnboardingSteps/ConnectWarehouse';
import StripePaymentPage from './MainApp/CreateNewCompany/StripePaymentPage';
import ErrorOrgAlreadyExists from './MainApp/CreateNewCompany/ErrorOrgAlreadyExists';

export const OnboardingRoutePrefix = 'onboarding';

export enum ONBOARDING_PAGE {
  'BASIC_INFO' = 'basic-info',
  'GETTING_STARTED' = 'getting-started',
  'WORKSPACE_SETTINGS' = 'workspace-settings',
  'CHOOSE_REPO_PROVIDER' = 'choose-repo-provider',
  'CONNECT_REPO' = 'connect-repo',
  'CONNECT_WAREHOUSE' = 'connect-warehouse',
  'STRIPE_PAYMENT' = 'stripe-payment',
  'ERROR_ORG_ALREADY_EXISTS' = 'org-name-already-taken',
}

const onboardingPagesLookup: {
  [key in ONBOARDING_PAGE]: FC
} = {
  [ONBOARDING_PAGE.BASIC_INFO]: BasicInfo,
  [ONBOARDING_PAGE.GETTING_STARTED]: GettingStarted,
  [ONBOARDING_PAGE.WORKSPACE_SETTINGS]: WorkspaceSettings,
  [ONBOARDING_PAGE.CHOOSE_REPO_PROVIDER]: ChooseRepoProvider,
  [ONBOARDING_PAGE.CONNECT_REPO]: ConnectRepo,
  [ONBOARDING_PAGE.CONNECT_WAREHOUSE]: ConnectWarehouse,
  [ONBOARDING_PAGE.STRIPE_PAYMENT]: StripePaymentPage,
  [ONBOARDING_PAGE.ERROR_ORG_ALREADY_EXISTS]: ErrorOrgAlreadyExists,
};

const Onboarding: FC = () => {
  const { pageRef } = useParams<{ pageRef: ONBOARDING_PAGE }>();

  return (
    <>
      <PageTitle title={PageTitles.ONBOARDING} />
      <Switch>
        <Route
          exact
          path={`/${OnboardingRoutePrefix}/${pageRef}`}
          component={onboardingPagesLookup[pageRef]}
        />
      </Switch>
    </>
  );
};

export default Onboarding;
