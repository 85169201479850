import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import List, { GraphicTypes } from '@paradime-io/pragma-ui-kit/lib/components/List';
import React, { FC, useMemo, useState } from 'react';

import { genericUser } from './InviteRoles';
import { GqlParadimeAccountType } from '../../../client/generated/service';
import { RoleDetailDropdown } from './RoleDetailDropdown';
import { useGetRolesDropdownData } from '../../hooks/useGetRolesDropdownData';

interface UserProps {
  updateUser: Function,
  prefilled?: boolean,
  inviteLevels: GqlParadimeAccountType[],
  user: genericUser
}

const User: FC<UserProps> = ({
  updateUser, inviteLevels, user,
}) => {
  const {
    email, slackId, profileRealNameNormalized, profileImage32, accountType,
  } = user;

  const initialDropdownValue = useGetRolesDropdownData(
    [accountType as GqlParadimeAccountType],
  )[0].text;
  const [selection, setSelection] = useState(
    initialDropdownValue,
  );

  const handleClick = (newAccountType: GqlParadimeAccountType) => {
    setSelection(newAccountType);
    updateUser(
      email,
      newAccountType.toUpperCase().split(' ').join('_'),
      slackId,
    );
  };

  const userImage = useMemo(() => profileImage32
    || `https://via.placeholder.com/32?text=${(
      profileRealNameNormalized
      || email
      || ''
    ).slice(0, 1)}`, [profileImage32, profileRealNameNormalized, email]);

  const getUserName = () => profileRealNameNormalized || email || '';

  return (
    <AutoLayout
      data-testid="user"
      alignment="center"
      direction="horizontal"
      distribution="space-between"
      height="max"
      padding="very-dense"
      style={{ alignItems: 'center' }}
    >
      <List
        graphic={{
          type: GraphicTypes.IMAGE,
          image: userImage,
          circle: true,
        }}
        title={getUserName()}
        noPadding
        view="dense"
      />
      <RoleDetailDropdown
        inviteLevels={inviteLevels}
        selectedValue={selection}
        onChange={handleClick}
      />
    </AutoLayout>
  );
};

export default User;
