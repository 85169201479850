import { useContext, useEffect, useState } from 'react';
import { PaymentMethod } from '@stripe/stripe-js';
import { StripeContext } from './useSetupStripe';
import { useUpdateStripePlanMutation } from '../../../../client/generated/service';

function getPaymentMethodId(paymentMethod: string | PaymentMethod | null | undefined): string {
  if (typeof paymentMethod === 'string') {
    return paymentMethod;
  } if (typeof paymentMethod === 'object' && paymentMethod !== null && 'id' in paymentMethod) {
    return paymentMethod.id;
  }
  return 'unknown';
}

export const useCheckStripePaymentOutcome = () => {
  const { stripeInstance } = useContext(StripeContext);

  const [outcome, setOutcome] = useState<string>();
  const [isPositiveOutcome, setIsPositiveOutcome] = useState<boolean>();
  const [isChecking, setIsChecking] = useState(false);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const newPriceId = urlSearchParams.get('price_id');

  const [updateStripePlan] = useUpdateStripePlanMutation();

  useEffect(() => {
    if (!stripeInstance) {
      return;
    }

    const clientSecret = urlSearchParams.get('setup_intent_client_secret');

    if (!clientSecret) {
      return;
    }

    if (!isChecking && !outcome) {
      setIsChecking(true);

      stripeInstance.then((stripe) => {
        stripe?.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
          const paymentMethodId = getPaymentMethodId(setupIntent?.payment_method);
          switch (setupIntent?.status) {
            case 'succeeded':
              updateStripePlan({
                variables: {
                  newPriceId: newPriceId || 'unknown',
                  paymentMethodId,
                },
              }).then(({ data }) => {
                if (data?.updateStripePlan?.ok) {
                  setOutcome('Payment succeeded!');
                  setIsPositiveOutcome(true);
                } else {
                  setOutcome('Something went wrong.');
                  setIsPositiveOutcome(false);
                }

                setIsChecking(false);
              });
              break;
            case 'processing':
              setOutcome('Your payment is processing.');
              setIsPositiveOutcome(false);
              setIsChecking(false);
              break;
            case 'requires_payment_method':
              setOutcome('Your payment was not successful, please try again.');
              setIsPositiveOutcome(false);
              setIsChecking(false);
              break;
            default:
              setOutcome('Something went wrong.');
              setIsPositiveOutcome(false);
              setIsChecking(false);
              break;
          }
        });
      });
    }
  }, [stripeInstance]);

  return { paymentOutcome: outcome, isPositiveOutcome, isChecking };
};
