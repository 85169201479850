import React, { FC } from 'react';
import {
  ReactFlowProvider,
  Node,
  Edge,
  Controls,
  Background,
  BackgroundVariant,
} from 'reactflow';
import * as S from './DAG.styles';

export interface CommandsFlowProps {
  nodes?:Node[],
  edges?: Edge[],
  nodeType: any,
}

const CommandsFlow: FC<CommandsFlowProps> = ({
  nodes,
  edges,
  nodeType,
}) => (
  <>
    {
      nodes && edges && (
        <ReactFlowProvider>
          <S.FlowWrapper id="flow-wrapper">
            <S.ReactFlowNoAttribution
              data-testid="lineage"
              defaultNodes={nodes}
              defaultEdges={edges}
              nodeTypes={nodeType}
              maxZoom={6}
              minZoom={0.1}
              defaultViewport={{
                x: 250,
                y: 50,
                zoom: 1,
              }}
              fitView={nodes.length > 1}
              panOnScroll={false}
              zoomOnScroll={false}
              preventScrolling={false}
            >
              <Background
                variant={BackgroundVariant.Dots}
                size={1}
                style={{ backgroundColor: 'var(--white)' }}
              />
              <Controls
                position="bottom-right"
                showInteractive={false}
              />
            </S.ReactFlowNoAttribution>
          </S.FlowWrapper>
        </ReactFlowProvider>
      )
    }
  </>
);

export default CommandsFlow;
