import React, { FC, useEffect, useState } from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import Card from '@paradime-io/pragma-ui-kit/lib/components/Card';
import useOrganisation, { OrganisationData } from '../../hooks/useOrganisation';
import KnownOrganisations from './KnownOrganisations';

const GetStarted: FC = () => {
  const [knownOrganisations, setKnownOrganisations] = useState<
    OrganisationData[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);

  const { list, set } = useOrganisation();

  useEffect(() => {
    setIsLoading(true);

    list().then((response) => {
      setIsLoading(false);
      setKnownOrganisations(response);
    });
  }, []);

  return (
    <AutoLayout
      direction="vertical"
      width="full"
      padding="normal"
      distribution="packed"
      horizontalGap="dense"
      alignment="center"
      style={{ marginTop: '100px' }}
    >
      <Typography
        tagName="span"
        type="h4"
        style={{ textAlign: 'center' }}
        font="inter"
      >
        Get Started
      </Typography>
      <Typography
        tagName="span"
        type="body-small"
        colorStep="60"
        style={{ textAlign: 'center' }}
      >
        We are delighted to have you onboard.
        Click below to get started.
      </Typography>
      <KnownOrganisations
        showLoader={isLoading}
        knownOrganisations={knownOrganisations}
        onSelectOrganisation={set}
      />
      {!isLoading && knownOrganisations.length === 0 && (
        <Card raiseOnHover={false}>
          <Typography
            font="inter"
            type="body-bold"
            color="default"
            colorStep="100"
          >
            Oops, we don&apos;t recognise that organization name!
          </Typography>
          <Typography
            font="inter"
            type="caption"
            color="default"
            colorStep="50"
          >
            Please check with the person who invited you, or contact us at support@paradime.io
          </Typography>
        </Card>
      )}
    </AutoLayout>
  );
};

export default GetStarted;
