import { Dispatch, SetStateAction } from 'react';
import { DropdownOnChangeProps, WarehouseFormInputType } from '../../warehouseFactory/types';
import { WarehouseEnv } from '../../utils';
import { sendResetSecretsMessage } from '../CommonConfig';
import { DevWarehouseHelp, ProdWarehouseHelp } from '../../../../Common/AppHelp/help';
import { AuthMethod, RedshiftFormDataType, getDefaultEmptyAuthValues } from './CommonConfig';
import { initialFormDataType } from '../../useGetInitialWarehouseData';
import { checkNoCasesLeft } from '../../../../../utilis';
import { RedshiftConnectionType } from '../../../../../client/generated/service';

export interface formDistributionProps {
  env: WarehouseEnv,
  selectedAuthMethod: AuthMethod,
  showEditButton: boolean,
}

export const formDistribution = ({
  env,
  selectedAuthMethod,
  showEditButton,
}: formDistributionProps) => {
  if (env === WarehouseEnv.DEV) {
    switch (selectedAuthMethod) {
      case AuthMethod.USERNAME_PASSWORD:
        if (showEditButton) {
          return ("'P P P P P P' "
          + "'p p p t t t' "
          + "'h h h n n n' "
          + "'A A A A A A' "
          + "'D D D D D D' "
          + "'u u w w b b' "
          + "'d d d d d d' "
          + "'s s s o o o' ");
        }
        return ("'P P P P P P' "
        + "'p p p t t t' "
        + "'h h h n n n' "
        + "'A A A A A A' "
        + "'D D D D D D' "
        + "'u u u w w w' "
        + "'d d d d d d' "
        + "'s s s o o o' ");
      case AuthMethod.IAM:
        if (showEditButton) {
          return ("'P P P P P P' "
          + "'p p p t t t' "
          + "'A A A A A A' "
          + "'D D D D D D' "
          + "'c c c c c c' "
          + "'u u w w b b' "
          + "'s s s o o o' ");
        }
        return ("'P P P P P P' "
        + "'p p p t t t' "
        + "'A A A A A A' "
        + "'D D D D D D' "
        + "'c c c c c c' "
        + "'u u u w w w' "
        + "'s s s o o o' ");
      default:
        checkNoCasesLeft(selectedAuthMethod);
        break;
    }
  }

  return (
    showEditButton
      ? ("'P P P P P P' "
        + "'p p p t t t' "
        + "'h h h n n n' "
        + "'D D D D D D' "
        + "'u u w w b b' "
        + "'d d d d d d' "
        + "'s s s o o o' ")
      : ("'P P P P P P' "
        + "'p p p t t t' "
        + "'h h h n n n' "
        + "'D D D D D D' "
        + "'u u u w w w' "
        + "'d d d d d d' "
        + "'s s s o o o' ")
  );
};

export const initialValues = (env: WarehouseEnv) => ({
  connectionName: '',
  connectionType: RedshiftConnectionType.Hostname,
  profile: '',
  hostName: '',
  port: '',
  database: '',
  username: '',
  password: '',
  schema: '',
  targetName: env,
  threads: 8,
});

export const formFields = (
  selectedAuthMethod: AuthMethod,
  setSelectedAuthMethod: Dispatch<SetStateAction<AuthMethod>>,
  showEditButton: boolean,
  isEditingUsernamePassword: boolean,
  setIsEditingUsernamePassword: Dispatch<SetStateAction<boolean>>,
  env: WarehouseEnv,
) => {
  const authMethodDropdown = [{
    id: 'dropdown-rd-auth-method',
    name: 'authMethod',
    label: 'Auth Method',
    gridArea: 'A',
    type: 'dropdown',
    menuItems: [
      {
        text: AuthMethod.USERNAME_PASSWORD,
        type: 'item',
        onClick: () => setSelectedAuthMethod(AuthMethod.USERNAME_PASSWORD),
      },
      {
        text: AuthMethod.IAM,
        type: 'item',
        onClick: () => setSelectedAuthMethod(AuthMethod.IAM),
      },
    ],
    text: selectedAuthMethod,
    additionalUserPlaceholder: selectedAuthMethod,
    adminOnly: true,
    onChange: ({
      setFormData,
      selectedItem,
    }: DropdownOnChangeProps) => {
      setFormData((currentFormData) => ({
        ...currentFormData,
        ...getDefaultEmptyAuthValues(
          selectedItem as AuthMethod,
          currentFormData as RedshiftFormDataType | undefined,
        ) as initialFormDataType,
      }));
    },
  }];

  return [
    {
      title: 'Connection Settings',
      gridArea: 'P',
      type: 'title',
      helpArticleId: (env === WarehouseEnv.PROD)
        ? ProdWarehouseHelp.REDSHIFT
        : DevWarehouseHelp.REDSHIFT,
    },
    {
      id: 'text-rd-profile-name',
      name: 'connectionName',
      label: 'Profile Name',
      type: WarehouseFormInputType.TEXT,
      gridArea: 'p',
      title: 'Profile Name' as any,
      adminOnly: true,
      showHighlight: true,
      highlightDescription: 'You will find this in your <code>dbt_project.yml</code>.<br>Use <code>default</code> if you are getting started with an empty repo.',
    },
    {
      id: 'text-rd-target-name',
      name: 'targetName',
      label: 'Target',
      type: WarehouseFormInputType.TEXT,
      value: env || WarehouseEnv.DEV,
      gridArea: 't',
      adminOnly: true,
    },
    ...(selectedAuthMethod === AuthMethod.USERNAME_PASSWORD
      ? [{
        id: 'text-rd-host-name',
        name: 'hostName',
        label: 'Host Name',
        type: WarehouseFormInputType.TEXT,
        gridArea: 'h',
        adminOnly: true,
        isPrivate: true,
      }]
      : []),
    ...(selectedAuthMethod === AuthMethod.USERNAME_PASSWORD
      ? [{
        id: 'text-rd-port',
        name: 'port',
        label: 'Port',
        type: WarehouseFormInputType.TEXT,
        gridArea: 'n',
        adminOnly: true,
      }]
      : []),
    ...(env === WarehouseEnv.DEV ? authMethodDropdown : []),
    {
      title: (env === WarehouseEnv.PROD) ? 'Production Credentials' : 'Development Credentials',
      gridArea: 'D',
      type: WarehouseFormInputType.TITLE,
    },
    ...(selectedAuthMethod === AuthMethod.IAM
      ? [{
        id: 'text-rd-profile-config',
        name: 'profile',
        label: 'Profile configuration',
        type: WarehouseFormInputType.MULTI_LINE_INPUT,
        gridArea: 'c',
        minLines: 10,
        maxLines: 10,
      }]
      : []),
    {
      id: 'text-rd-username',
      name: 'username',
      label: selectedAuthMethod === AuthMethod.USERNAME_PASSWORD ? 'User Name' : 'User Email',
      type: WarehouseFormInputType.TEXT,
      gridArea: 'u',
      title: (env === WarehouseEnv.PROD) ? 'Production Credentials' : 'Development Credentials' as any,
      isPrivate: true,
      disabled: showEditButton ? !isEditingUsernamePassword : false,
      isSecretField: true,
      isEditingSecretField: isEditingUsernamePassword,
      adminOnly: env !== WarehouseEnv.DEV,
    },
    {
      id: 'text-rd-password',
      name: 'password',
      label: 'Password',
      type: WarehouseFormInputType.PASSWORD,
      gridArea: 'w',
      isPrivate: true,
      disabled: showEditButton ? !isEditingUsernamePassword : false,
      isSecretField: true,
      isEditingSecretField: isEditingUsernamePassword,
      adminOnly: env !== WarehouseEnv.DEV,
    },
    ...(showEditButton
      ? [{
        name: 'editClientSecretButton',
        text: isEditingUsernamePassword ? 'Cancel' : 'Edit',
        type: WarehouseFormInputType.BUTTON,
        gridArea: 'b',
        onClick: () => setIsEditingUsernamePassword((prev) => {
          if (prev) {
            // User has pressed cancel, undo any changes
            sendResetSecretsMessage();
          }
          return !prev;
        }),
      }]
      : []
    ),
    ...(selectedAuthMethod === AuthMethod.USERNAME_PASSWORD
      ? [{
        id: 'text-rd-database',
        name: 'database',
        label: 'Database',
        type: WarehouseFormInputType.TEXT,
        gridArea: 'd',
      }]
      : []),
    {
      id: 'text-rd-schema',
      name: 'schema',
      label: 'Schema',
      type: WarehouseFormInputType.TEXT,
      gridArea: 's',
    },
    {
      id: 'text-rd-threads',
      name: 'threads',
      label: 'Threads',
      type: WarehouseFormInputType.NUMBER,
      gridArea: 'o',
    },
  ];
};
