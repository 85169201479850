import React from 'react';
import { catalogueTreeIconsProps } from '../types';
import DBTLogo from '../../components/Catalog/images/dbt.svg';

export const catalogueTreeIcons = ({ type }: catalogueTreeIconsProps) => {
  switch (type) {
    case 'dbt':
      return <img src={DBTLogo} alt="" />;
    case 'exposures':
    case 'exposureType':
    case 'models':
    case 'modelType':
    case 'seeds':
    case 'seedType':
    case 'sources':
    case 'sourceType':
    case 'snapshots':
    case 'snapshotsType':
      return 'folder-close';
    case 'exposure':
      return 'new-grid-item';

    default:
      return 'search-around';
  }
};
