import React from 'react';
// import { lineageSearchInputProps } from '../types';
import SearchMenuLabel from '../../components/Lineage/HomePage/SearchMenuLabel';
import FivetranLogo from '../../components/Lineage/HomePage/images/Fivetran.svg';
// import { LineageNodeTypeTranslations } from '.';

export const lineageSearchInputDropdown = () => {
  const labelElement = (
    <SearchMenuLabel
      text="Connector"
      color="var(--violet70)"
    />
  );

  return {
    img: FivetranLogo,
    labelElement,
  };
};
