import React from 'react';
import { IntegrationHandler } from '..';
import { lineageContextMenu } from './LineageContextMenu';
import { lineageFilterNodes } from './LineageFilterNodes';
import anotherLookerLogo from '../../components/Lineage/HomePage/images/lookerLogo.svg';
import SearchMenuLabel from '../../components/Lineage/HomePage/SearchMenuLabel';
import { catalogueSearchResultItem } from './CatalogueSearchResultItem';
import { catalogueDetailsCard } from './CatalogueDetailsCard';
import { catalogueNameAndIcon } from './CatalogueNameAndIcon';
import { catalogueHeaderButtons } from './CatalogueHeaderButtons';
import { catalogueLogo } from './CatalogueLogo';
import { catalogueSimpleType } from './CatalogueSimpleType';
import { catalogueDescription } from './CatalogueDescription';
import { lineageNodeDisplay } from './LineageNodeDisplay';
import { catalogueSearchFilters } from './CatalogueSearchFilters';
import { catalogueItemTabsVisibility } from './CatalogueItemTabsVisibility';
import { catalogueFieldsTab } from './CatalogueFieldsTab';
import { catalogueDependenciesTab } from './CatalogueDependenciesTab';
import { catalogueTreeIcons } from './CatalogueTreeIcons';

export enum LookerHandlerAcceptedTypes {
  'LOOKER' = 'LOOKER',
  'LookerLookNode' = 'Look',
  'LookerViewNode' = 'View',
  'LookerModelNode' = 'Model',
  'LookerExploreNode' = 'Explore',
  'LookerDashboardNode' = 'Dashboard',
  'LookerScheduleNode' = 'Schedule',
  'GQLCatalogueLookerDashboardItem' = 'Dashboard',
  'GQLCatalogueLookerExploreItem' = 'Explore',
  'GQLCatalogueLookerLookItem' = 'Look',
  'looker' = 'looker',
  'schema' = 'schema',
  'dashboards' = 'dashboards',
  'looks' = 'looks',
  'explore' = 'explore',
  'look' = 'look',
  'explores' = 'explores',
}

export enum LookerSubTypes {
  'LOOKER_DASHBOARDS' = 'dashboards',
  'LOOKER_EXPLORES' = 'explores',
  'LOOKER_LOOKS' = 'looks',
}

export const LookerIntegrationHandler: IntegrationHandler = ({
  type: LookerHandlerAcceptedTypes.LOOKER,
  subTypes: Object.values(LookerSubTypes),
  handlers: {
    // lineageNodeRenderer: (data: any) => <div>{data}</div>,
    // catalogueCardRenderer: (data: any) => <div>{data}</div>,
    lineageContextMenu,
    lineageSearchInputDropdown: ({ nodeType }) => {
      // Strip 'Looker' and 'Node' to get the type
      // e.g. LookerViewNode -> View
      const text = nodeType.replace('Looker', '').replace('Node', '');

      const labelElement = (
        <SearchMenuLabel
          text={text}
          color="var(--indigo50)"
        />
      );

      return {
        img: anotherLookerLogo,
        labelElement,
      };
    },
    lineageFilterNodes,
    catalogueSearchResultItem,
    catalogueDetailsCard,
    catalogueNameAndIcon,
    catalogueHeaderButtons,
    catalogueClassificationData: () => ({
      keyList: [],
      tableClassificationOptions: [],
    }),
    catalogueLogo,
    catalogueSimpleType,
    catalogueDescription,
    lineageNodeDisplay,
    catalogueAssetColumns: () => [],
    catalogueAssetItemId: () => 'error',
    catalogueSearchFilters,
    catalogueItemTabsVisibility,
    catalogueFieldsTab,
    catalogueDependenciesTab,
    catalogueSchemaTab: () => [{
      tableName: '',
      columnData: [],
    }],
    catalogueDbtModelTab: () => undefined,
    catalogueTreeIcons,
    catalogueShowClassificationsCard: () => false,
  },
});
