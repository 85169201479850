/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { FC, ReactElement } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import chevron from '../images/chevron.svg';
import paradimeLogo from '../../../../images/paradime-logo-with-text.svg';

export interface PageTemplateProps {
  children: ReactElement,
  routeToPreviousStep?: string,
  title?: string,
}

export const FakeModal = styled.div`
  background: white;
  border-radius: 8px;
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  position: relative;
  width: 100%;
  box-shadow: var(--greyShadow24dp);
  min-height: 504px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 728px;
`;

const BackChevron = styled.img`
  position: absolute;
  bottom: 16px;
  left: 16px;
  cursor: pointer;
  transition: transform 300ms ease-in-out;

  &:hover {
    transform: scale(1.3);
    filter: saturate(30);
  }
`;

const PageTemplate: FC<PageTemplateProps> = ({
  children,
  routeToPreviousStep,
  title,
}) => {
  const history = useHistory();

  const handleBackClick = () => {
    history.push(routeToPreviousStep!);
  };

  return (
    <AutoLayout
      direction="vertical"
      verticalGap="dense"
      padding="none"
      alignment="center"
      distribution="space-between"
      style={{
        width: '100vw',
        height: '100vh',
        backgroundColor: 'var(--grey0)',
      }}
    >
      <img src={paradimeLogo} alt="paradime" />
      <AutoLayout
        direction="vertical"
        verticalGap="dense"
        distribution="packed"
        alignment="center"
        padding="none"
        style={{
          height: 'calc(100vh - 76px)',
          display: 'flex',
        }}
      >
        <FakeModal>
          {/* <DecoratedText type="h2" value="where people meet data" /> */}
          {title && (
            <Typography
              font="inter"
              type="h4"
              color="default"
              colorStep="100"
            >
              {title}
            </Typography>
          )}
          {children}
          {routeToPreviousStep && (
            <BackChevron
              src={chevron}
              alt="chevron-back"
              onClick={handleBackClick}
            />
          )}
        </FakeModal>
      </AutoLayout>
    </AutoLayout>
  );
};

export default PageTemplate;
