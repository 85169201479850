/* eslint-disable no-case-declarations */

import { catalogueHeaderButtonsProps, catalogueHeaderButtonsReturnType } from '../types';

const getExposureLink = (exposureUrl?: string | null, branchName?: string) => {
  if (!exposureUrl) return '#';
  if (!branchName) return '';
  const encodeNodeId = encodeURIComponent(btoa(exposureUrl));
  return `/catalog/search/redirect/external/${encodeURIComponent(branchName)}/${encodeNodeId}`;
};

export const catalogueHeaderButtons = ({
  resultItem,
  isBusinessUser,
}: catalogueHeaderButtonsProps): catalogueHeaderButtonsReturnType => {
  const currentBranchName = resultItem.currentBranch?.name;

  switch (resultItem.__typename) {
    case 'GQLCatalogueDBTExposureItem':
      const exposureLink = getExposureLink(resultItem.url, currentBranchName);

      const exposureButton = {
        icon: 'new-grid-item',
        text: 'View Exposure',
        link: exposureLink,
      };

      if (isBusinessUser) {
        return [] as catalogueHeaderButtonsReturnType;
      }
      return [exposureButton] as catalogueHeaderButtonsReturnType;
    default:
      return [];
  }
};
