import React from 'react';
import { catalogueTreeIconsProps } from '../types';
import { decodeLogoBase64 } from '../utils';

export const catalogueTreeIcons = ({ icon, logoBase64 }: catalogueTreeIconsProps) => {
  if (logoBase64) {
    return <img src={decodeLogoBase64(logoBase64)} alt="" />;
  }

  if (icon?.includes('fivetran.com')) {
    // It's a URL to an image
    return <img src={icon} alt="" />;
  }

  // Blueprint icon names
  return icon || 'database';
};
