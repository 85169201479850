import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  GqlWorkspace,
  useListWorkspacesLazyQuery,
} from '../../../../client/generated/service';
import { graphQLStore, userAuthStore } from '../../../../stores';

export interface WorkspaceWithJoinedFlag extends GqlWorkspace {
  isJoined: boolean,
}

interface formatWorkspacesProps {
  workspaces: GqlWorkspace[],
  isJoined: boolean,
}
export const formatWorkspaces = ({
  workspaces,
  isJoined,
}: formatWorkspacesProps) => (
  workspaces
    .filter(({ isSetupComplete, canSetupBeCompleted }) => (
      // Either the workspace is setup,
      // Or the workspace is not setup, but this user can finish the setup.
      // Don't show incomplete workspaces this user can't complete
      isSetupComplete || (!isSetupComplete && canSetupBeCompleted)
    ))
    .map((workspace) => ({ ...workspace, isJoined }))
);

const handleIncompleteWorkspaceOnboardings = (isIncomplete: boolean, history: any) => {
  const currentPath = window.location.pathname;
  if (isIncomplete && currentPath.includes('onboarding')) {
    // Redirect the user into the create-workspace flow because the navbar will be visible there
    // allowing the user to escape if they give up on the onboarding
    const newPath = currentPath.replace('onboarding', 'create-workspace');
    history.push(newPath);
  }
};

const useListWorkspaces = () => {
  const [allWorkspacesList, setAllWorkspacesList] = useState<WorkspaceWithJoinedFlag[]>([]);
  const [joinedWorkspacesList, setJoinedWorkspacesList] = useState<GqlWorkspace[]>([]);
  const [maxWorkspacesCount, setMaxWorkspacesCount] = useState(1);
  const [workspacesAvailableToJoinList, setWorkspacesAvailableToJoinList] = useState<
    GqlWorkspace[]
  >([]);
  const [canCreateWorkspaces, setCanCreateWorkspaces] = useState(false);

  const { setWorkspaceToken, setIsIncompleteWorkspace } = graphQLStore.getState();
  const { currentUser: { accessLevel } } = userAuthStore.getState();

  const history = useHistory();

  const [refreshWorkspacesList, { loading: isLoadingWorkspacesData }] = useListWorkspacesLazyQuery({
    onCompleted: ({ listWorkspaces }) => {
      if (listWorkspaces?.ok && listWorkspaces?.workspaces?.length > 0) {
        const {
          workspaces,
          workspacesAvailableForAutoJoin,
          maxWorkspaceCount,
          canCreateWorkspace,
        } = listWorkspaces;

        const defaultWorkspace = workspaces.filter(({ isDefault }) => isDefault)[0];
        setWorkspaceToken(defaultWorkspace.uid);

        const isIncomplete = !defaultWorkspace.isSetupComplete;
        setIsIncompleteWorkspace(isIncomplete);
        handleIncompleteWorkspaceOnboardings(isIncomplete, history);

        const formattedJoinedWorkspaces = formatWorkspaces({ workspaces, isJoined: true });
        setJoinedWorkspacesList(formattedJoinedWorkspaces);

        const formattedAvailableWorkspaces = formatWorkspaces({
          workspaces: workspacesAvailableForAutoJoin, isJoined: false,
        });
        setWorkspacesAvailableToJoinList(formattedAvailableWorkspaces);

        setAllWorkspacesList([...formattedJoinedWorkspaces, ...formattedAvailableWorkspaces]);

        setMaxWorkspacesCount(maxWorkspaceCount || 1);
        setCanCreateWorkspaces(canCreateWorkspace || false);
      } else {
        // Some error state
        setCanCreateWorkspaces(false);
      }
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    refreshWorkspacesList();
  }, [accessLevel]);

  return {
    isLoadingWorkspacesData,
    allWorkspacesList,
    joinedWorkspacesList,
    workspacesAvailableToJoinList,
    refreshWorkspacesList,
    currentWorkspace: joinedWorkspacesList.filter(({ isDefault }) => isDefault)[0],
    maxWorkspacesCount,
    canCreateWorkspaces,
  };
};

export default useListWorkspaces;
