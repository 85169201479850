import React, { FC, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import {
  StackedBarChart,
  SingleValueChart,
  TableChart,
  LineChart,
  ScatterChart,
} from '@paradime-io/pragma-ui-kit/lib/components/VictoryCharts';
import { useCubeLazyQuery } from '../../../app/hooks/useCubeQuery';
import {
  modelNamesForDropdownQuery,
  modelNamesForDropdownReturnType,
  formatModelNamesForDropdown,
  successModelDurationQuery,
  successModelDurationReturnType,
  formatSuccessModelDurationForChart,
  getSuccessModelDurationXAxisLabels,
  modelDailyCountQuery,
  modelDailyCountReturnType,
  formatModelDailyCountForChart,
  getModelDailyCountXAxisLabels,
  averageModelDurationQuery,
  averageModelDurationReturnType,
  formatAverageModelDurationForChart,
  getAverageModelDurationXAxisLabels,
  latestModelRunQuery,
  latestModelRunReturnType,
  latestModelRunColumns,
  formatLatestModelRunForChart,
  completedModelCountQuery,
  completedModelCountReturnType,
  succeededModelCountQuery,
  succeededModelCountReturnType,
  erroredModelCountQuery,
  erroredModelCountReturnType,
  modelSuccessRateQuery,
  modelSuccessRateReturnType,
} from './DetailsCubeQueries';
import {
  timeframes,
  HalfPageChartSection,
  FullPageChartSection,
  QuarterPageChartSection,
  getSingleValueChartString,
  getFormattedYAxisLabel,
  singleValueChartHeight,
} from '../utils';
import FilterRow from '../FilterRow';
import { useGetFiltersFromUrl } from '../hooks/useGetFiltersFromUrl';
import CodeSnippetCopy from '../../Common/Components/CodeSnippetCopy';

const DetailsTab: FC = () => {
  const { getInitialFilterValue } = useGetFiltersFromUrl();

  const [timeframe, setTimeframe] = useState(getInitialFilterValue('timeframe', timeframes[2].value)!);
  const [modelName, setModelName] = useState(getInitialFilterValue('modelName', ' ')!);

  const [
    getModelNamesForDropdown,
    { data: modelNamesForDropdown, loading: isLoadingModelNamesForDropdown },
  ] = useCubeLazyQuery<modelNamesForDropdownReturnType>({
    query: modelNamesForDropdownQuery,
    variables: { timeframe: timeframe.value },
    onCompleted: ({ data }) => {
      const firstOption = data.cube?.[0]?.modelRunStatistics?.modelName;
      const urlVal = getInitialFilterValue('modelName', firstOption);
      if (urlVal) setModelName(urlVal);
    },
  });

  const [
    getSuccessModelDuration, {
      data: successModelDurationData,
      loading: isLoadingSuccessModelDuration,
      error: successModelDurationError,
    },
  ] = useCubeLazyQuery<successModelDurationReturnType>({
    query: successModelDurationQuery,
    variables: { timeframe: timeframe.value, modelName: modelName.value },
  });
  const [
    getModelDailyCount,
    { data: modelDailyCountData, loading: isLoadingModelDailyCount, error: modelDailyCountError },
  ] = useCubeLazyQuery<modelDailyCountReturnType>({
    query: modelDailyCountQuery,
    variables: { timeframe: timeframe.value, modelName: modelName.value },
  });
  const [
    getAverageModelDuration, {
      data: averageModelDurationData,
      loading: isLoadingAverageModelDuration,
      error: averageModelDurationError,
    },
  ] = useCubeLazyQuery<averageModelDurationReturnType>({
    query: averageModelDurationQuery,
    variables: { timeframe: timeframe.value, modelName: modelName.value },
  });
  const [
    getLatestModelRun,
    { data: latestModelRunData, loading: isLoadingLatestModelRun, error: latestModelRunError },
  ] = useCubeLazyQuery<latestModelRunReturnType>({
    query: latestModelRunQuery,
    variables: { timeframe: timeframe.value, modelName: modelName.value },
  });
  const [
    getCompletedModelCount, {
      data: completedModelCountData,
      loading: isLoadingCompletedModelCount,
      error: completedModelCountError,
    },
  ] = useCubeLazyQuery<completedModelCountReturnType>({
    query: completedModelCountQuery,
    variables: { timeframe: timeframe.value, modelName: modelName.value },
  });
  const [
    getSuceededModelCount, {
      data: suceededModelCountData,
      loading: isLoadingSuceededModelCount,
      error: suceededModelCountError,
    },
  ] = useCubeLazyQuery<succeededModelCountReturnType>({
    query: succeededModelCountQuery,
    variables: { timeframe: timeframe.value, modelName: modelName.value },
  });
  const [
    getErroredModelCount, {
      data: erroredModelCountData,
      loading: isLoadingErroredModelCount,
      error: erroredModelCountError,
    },
  ] = useCubeLazyQuery<erroredModelCountReturnType>({
    query: erroredModelCountQuery,
    variables: { timeframe: timeframe.value, modelName: modelName.value },
  });
  const [
    getModelSuccessRate, {
      data: modelSuccessRateData,
      loading: isLoadingModelSuccessRate,
      error: modelSuccessRateError,
    },
  ] = useCubeLazyQuery<modelSuccessRateReturnType>({
    query: modelSuccessRateQuery,
    variables: { timeframe: timeframe.value, modelName: modelName.value },
  });

  const refetchAllData = () => {
    getSuccessModelDuration();
    getModelDailyCount();
    getAverageModelDuration();
    getLatestModelRun();
    getCompletedModelCount();
    getSuceededModelCount();
    getErroredModelCount();
    getModelSuccessRate();
  };

  useEffect(() => {
    if (modelName.value !== ' ') {
      refetchAllData();
    }
  }, [timeframe, modelName]);

  useEffect(() => {
    getModelNamesForDropdown();
  }, [timeframe]);

  return (
    <AutoLayout
      direction="vertical"
      padding="none"
      verticalGap="dense"
      distribution="packed"
    >
      <FilterRow
        dropdowns={[
          {
            value: timeframe,
            options: timeframes,
            onChange: setTimeframe,
            name: 'timeframe',
            title: 'Select date range',
          },
          {
            value: modelName,
            options: formatModelNamesForDropdown(modelNamesForDropdown),
            onChange: setModelName,
            isLoading: isLoadingModelNamesForDropdown,
            name: 'modelName',
            title: 'Select a model',
          },
        ]}
      />
      <div style={{
        display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gap: '16px',
      }}
      >
        <QuarterPageChartSection height={singleValueChartHeight}>
          <SingleValueChart
            title="Count Completed Model Invocations"
            value={(
              getSingleValueChartString(
                completedModelCountData?.cube?.[0]
                  ?.modelRunCountByStatus?.totalRuns || 0,
              )
            )}
            isLoading={isLoadingCompletedModelCount}
            hasNoData={completedModelCountError || completedModelCountData?.cube?.length === 0}
          />
        </QuarterPageChartSection>
        <QuarterPageChartSection height={singleValueChartHeight}>
          <SingleValueChart
            title="Count Success Model Invocations"
            value={(
              getSingleValueChartString(
                suceededModelCountData?.cube?.[0]
                  ?.modelRunCountByStatus?.totalRuns || 0,
              )
            )}
            isLoading={isLoadingSuceededModelCount}
            hasNoData={suceededModelCountError || suceededModelCountData?.cube?.length === 0}
          />
        </QuarterPageChartSection>
        <QuarterPageChartSection height={singleValueChartHeight}>
          <SingleValueChart
            title="Count Errored Model Invocations"
            value={(
              getSingleValueChartString(
                erroredModelCountData?.cube?.[0]
                  ?.modelRunCountByStatus?.totalRuns || 0,
              )
            )}
            isLoading={isLoadingErroredModelCount}
            hasNoData={erroredModelCountError || erroredModelCountData?.cube?.length === 0}
          />
        </QuarterPageChartSection>
        <QuarterPageChartSection height={singleValueChartHeight}>
          <SingleValueChart
            title="Model Success Rate"
            value={(
              getSingleValueChartString(
                modelSuccessRateData?.cube?.[0]
                  ?.modelSuccessRate?.successRate || 0,
                'percent',
              )
            )}
            isLoading={isLoadingModelSuccessRate}
            hasNoData={modelSuccessRateError || modelSuccessRateData?.cube?.length === 0}
          />
        </QuarterPageChartSection>
        <HalfPageChartSection>
          <ScatterChart
            title="Succeeded model run duration"
            data={formatSuccessModelDurationForChart(successModelDurationData)}
            xAxisLabels={getSuccessModelDurationXAxisLabels(successModelDurationData)}
            xAxisLabelFormatter={(tick) => DateTime.fromISO(tick).toFormat('LLL d')}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            xLabel="Run completed at timestamp"
            yLabel="Run duration (minutes)"
            isLoading={isLoadingSuccessModelDuration}
            hasNoData={successModelDurationError || successModelDurationData?.cube?.length === 0}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
            showDetailsButton
          />
        </HalfPageChartSection>
        <HalfPageChartSection>
          <StackedBarChart
            title="Daily count of model invocations"
            data={formatModelDailyCountForChart(modelDailyCountData).data}
            xAxisLabels={getModelDailyCountXAxisLabels(modelDailyCountData)}
            xAxisLabelFormatter={(tick) => DateTime.fromISO(tick).toFormat('LLL d')}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            xLabel="Calendar date"
            yLabel="Count model executions"
            legendLabels={formatModelDailyCountForChart(modelDailyCountData).statuses}
            customColourScale={formatModelDailyCountForChart(modelDailyCountData).colours}
            isLoading={isLoadingModelDailyCount}
            hasNoData={modelDailyCountError || modelDailyCountData?.cube?.length === 0}
            xAxisDomain={[0.5, getModelDailyCountXAxisLabels(modelDailyCountData).length + 1]}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
            showDetailsButton
          />
        </HalfPageChartSection>
        <FullPageChartSection>
          <LineChart
            title="Average execution time for succeeded model by hour the day"
            data={formatAverageModelDurationForChart(averageModelDurationData)}
            xAxisLabels={getAverageModelDurationXAxisLabels(averageModelDurationData)}
            yAxisLabelFormatter={(tick) => getFormattedYAxisLabel(tick)}
            xLabel="Schedule run hour window"
            yLabel="Average model run time (minutes)"
            isLoading={isLoadingAverageModelDuration}
            hasNoData={averageModelDurationError || averageModelDurationData?.cube?.length === 0}
            tooltipLabelFormatter={{
              yValueFormatter: (tick) => getFormattedYAxisLabel(tick),
            }}
            showDetailsButton
          />
        </FullPageChartSection>
        <FullPageChartSection style={{ height: '100%' }}>
          <TableChart
            title="Schedule latest runs executing selected model"
            isLoading={isLoadingLatestModelRun}
            hasNoData={latestModelRunError || latestModelRunData?.cube?.length === 0}
            columns={latestModelRunColumns}
            data={formatLatestModelRunForChart(latestModelRunData)}
            rowExpansionTemplate={(row) => (
              <div style={{ height: '80px', width: '100%' }}>
                <CodeSnippetCopy codeSnippet={row.boltScheduleNameCommandsJson} language="json" fixedHeight="80px" />
              </div>
            )}
            rowHasExpander={(data) => data?.boltScheduleNameCommandsJson}
            rowsPerPage={5}
          />
        </FullPageChartSection>
      </div>
    </AutoLayout>
  );
};

export default DetailsTab;
