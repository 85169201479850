import React, { FC } from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Radio from '@paradime-io/pragma-ui-kit/lib/components/Radio';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { BillingFrequencyOptions, Prices } from '@paradime-io/pragma-ui-kit/lib/components/PricingPlan';
import { divideAnnualPriceByUserPerMonth, divideMonthlyPriceByUserPerMonth } from './utils';

export interface BillingOptionsProps {
  isAnnual: boolean,
  prices: Prices,
  numberOfUsers: number,
  onChange?: (isAnnual: boolean, priceId: string) => void,
  isPricedPerUser?: boolean,
}

const BillingOptions: FC<BillingOptionsProps> = ({
  isAnnual,
  prices,
  onChange,
  isPricedPerUser,
}) => {
  const getSubtitle = (amountString: string) => {
    const unitString = isPricedPerUser ? 'per user' : '';
    return `${amountString} ${unitString}/month`;
  };

  return (
    <AutoLayout
      direction="vertical"
      padding="none"
      distribution="packed"
      verticalGap="dense"
    >
      <Typography
        font="inter"
        type="body-bold"
      >
        Billing Options
      </Typography>
      <AutoLayout
        direction="horizontal"
        padding="none"
        distribution="space-between"
      >
        <Radio
          type="dense"
          view="flat" // @ts-ignore - label expects a string
          label={(
            <AutoLayout
              direction="vertical"
              padding="none"
              distribution="packed"
              verticalGap="none"
            >
              <Typography
                font="inter"
                type="body-bold-small"
              >
                Pay annually
              </Typography>
              <Typography
                font="inter"
                type="caption"
                colorStep="60"
              >
                {getSubtitle(divideAnnualPriceByUserPerMonth({
                  prices,
                }))}
              </Typography>
            </AutoLayout>
          )}
          checked={isAnnual}
          onChange={() => {
            if (onChange) onChange(true, prices[BillingFrequencyOptions.YEARLY]?.id || 'unknown');
          }}
        />
        <Radio
          type="dense"
          view="flat" // @ts-ignore - label expects a string
          label={(
            <AutoLayout
              direction="vertical"
              padding="none"
              distribution="packed"
              verticalGap="none"
            >
              <Typography
                font="inter"
                type="body-bold-small"
              >
                Pay monthly
              </Typography>
              <Typography
                font="inter"
                type="caption"
                colorStep="60"
              >
                {getSubtitle(divideMonthlyPriceByUserPerMonth({
                  prices,
                }))}
              </Typography>
            </AutoLayout>
          )}
          checked={!isAnnual}
          onChange={() => {
            if (onChange) onChange(false, prices[BillingFrequencyOptions.MONTHLY]?.id || 'unknown');
          }}
        />
      </AutoLayout>
    </AutoLayout>
  );
};

export default BillingOptions;
