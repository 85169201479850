import styled from 'styled-components';
import { MiniMap as MiniMapSource } from 'reactflow';

const MiniMap = styled(MiniMapSource)`
  position: relative;
  border-radius: 4px;
  background: ${(props) => (props.theme.darkMode ? 'var(--dark)' : 'var(--white)')};
  width: 100%;
  transition: opacity 300ms ease-in-out;
  right: 0px;
  bottom: -5px;
  margin: 0;

  svg {
    margin: auto;
    display: block;
  }

  // The purple box which represents the viewport
  .react-flow__minimap-mask{
    stroke: var(--violet60);
    fill: transparent;
    stroke-width: 10px;
    clip-path: polygon(1% 1%,99% 1%,99% 99%,1% 99%);
  }
`;

export { MiniMap };
