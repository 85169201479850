import React, { FC } from 'react';
import { Icon, IconName } from '@blueprintjs/core';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';

export interface OrgNameWithiconProps {
  iconName?: IconName,
  orgName: string,
}

const OrgNameWithIcon: FC<OrgNameWithiconProps> = ({
  iconName = 'office',
  orgName,
}) => (
  <AutoLayout
    direction="horizontal"
    padding="normal"
    distribution="packed"
    horizontalGap="dense"
    style={{ justifyContent: 'center' }}
  >
    <Icon icon={iconName as IconName} iconSize={32} color="var(--violet80)" />
    <Typography
      font="inter"
      type="h6"
    >
      {orgName}
    </Typography>
  </AutoLayout>
);

export default OrgNameWithIcon;
