import React, { FC } from 'react';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import { Divider } from '@paradime-io/pragma-ui-kit';
import CustomerTestimonial from './CustomerTestimonial';
import LogoCloud from './LogoCloud';
import avatar1 from '../images/avatar-1.jpeg';
import avatar2 from '../images/avatar-2.jpeg';
import avatar3 from '../images/avatar-3.jpeg';
import avatar4 from '../images/avatar-4.jpeg';
import benlabs from '../images/benlabs.svg';
import bitgo from '../images/bitgo.svg';
import brilliant from '../images/brilliant.svg';
import capitalontap from '../images/capitalontap.svg';
import lacework from '../images/lacework.svg';
import tide from '../images/tide.svg';
import zego from '../images/zego.svg';
import spotlight from '../images/spotlight.png';

const feedbacks = [
  {
    avatar: avatar1,
    testimonial: 'The one-stop-shop for all things Analytics Engineering for us – development, scheduling, and monitoring!',
    avatarName: 'Abhijith Mandapaka',
    avatarTitle: 'Senior Analytics Engineer, Marketing, Tide',
  },
  {
    avatar: avatar2,
    testimonial: 'As a dbt core user, I really liked being be able to see the query results, data lineage and catalog in the same screen in Paradime.',
    avatarName: 'Isin Pesch',
    avatarTitle: 'Senior Data Analytics Engineer, Deel',
  },
  {
    avatar: avatar3,
    testimonial: 'The creation of data-mesh for effective DBT modelling & SQL coding is super duper easy on Paradime',
    avatarName: 'Sourabh Jain',
    avatarTitle: 'Analytics Engineer - III, Tide',
  },
  {
    avatar: avatar4,
    testimonial: "I love the UI's developer feel. The support is also the best part. I have the skillset to use DBT Core, but the ease of Paradime makes it worth every dollar.",
    avatarName: 'Jay Rosenthal',
    avatarTitle: 'Director Data Science & Engineering, Motive',
  },
];

interface StartLandingPageProps {
  title: string | JSX.Element,
}

const StartLandingPage:FC<StartLandingPageProps> = ({
  title,
}) => (
  <div style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
    <div style={{ position: 'absolute', top: 0 }}>
      <img src={spotlight} width={636} alt="spotlight" />
    </div>
    <AutoLayout
      direction="vertical"
      distribution="packed"
      padding="none"
      verticalGap="dense"
      style={{ justifyItems: 'center', width: '75%' }}
      wrapperStyle={{ justifyContent: 'center', position: 'relative', top: '100px' }}
    >
      <Typography
        tagName="span"
        type="h5"
        colorStep="0"
        style={{
          wordWrap: 'break-word',
          overflow: 'hidden',
          textAlign: 'center',
        }}
      >
        {title}
      </Typography>
      <Divider size={1} color="primary" colorStep="30" style={{ width: '75%' }} />
      <AutoLayout
        direction="vertical"
        padding="none"
        distribution="space-between"
        alignment="top-left"
        wrapperStyle={{ height: '400px' }}
      >
        <LogoCloud
          logos={{
            row1: [
              benlabs,
              bitgo,
              lacework,
              tide,
            ],
            row2: [
              zego,
              capitalontap,
              brilliant,
            ],
          }}
        />
        <CustomerTestimonial feedbacks={feedbacks} />
      </AutoLayout>
    </AutoLayout>
  </div>
);

export default StartLandingPage;
