import React, { FC } from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Dropdown from '@paradime-io/pragma-ui-kit/lib/components/Dropdown';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { MenuTemplate } from '@paradime-io/pragma-ui-kit/lib/components/Menu';
import { Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { DropdownItem } from '.';

export interface HeaderProps {
  selectedWorkspace: DropdownItem,
  workspaceList: DropdownItem[],
  onWorkspaceChanged: (newWorkspace: DropdownItem) => void,
  selectedTimeFrame: DropdownItem,
  timeFrameList: DropdownItem[],
  onTimeFrameChanged: (newWorkspace: DropdownItem) => void,
  loading: boolean,
}

const Header: FC<HeaderProps> = ({
  selectedWorkspace,
  workspaceList,
  onWorkspaceChanged,
  selectedTimeFrame,
  timeFrameList,
  onTimeFrameChanged,
  loading,
}) => (
  <AutoLayout
    direction="horizontal"
    padding="none"
    distribution="space-between"
    style={{ marginRight: '16px' }}
  >
    <AutoLayout
      direction="vertical"
      padding="none"
      verticalGap="none"
      distribution="packed"
    >
      <Typography type="h5">Your Paradime ROI</Typography>
      <Typography type="body-bold-small" colorStep="50">
        Track cost savings and productivity gains from  Paradime.
      </Typography>
    </AutoLayout>

    <AutoLayout
      direction="horizontal"
      padding="none"
      horizontalGap="dense"
      distribution="packed"
    >
      <Dropdown
        view="outlined"
        color="default"
        dense
        label="Workspace"
        text={selectedWorkspace.label}
        disabled={loading}
        content={(
          <MenuTemplate
            view="outlined"
            dense
            items={workspaceList
              .map((workspaceItem) => ({
                text: workspaceItem.label,
                type: 'item',
                onClick: () => onWorkspaceChanged(workspaceItem),
              }))}
            eventContext={Contexts.PLATFORM}
            style={{
              maxHeight: '250px',
              overflowY: 'auto',
            }}
          />
        )}
        style={{ width: '165px' }}
      />
      <Dropdown
        view="outlined"
        color="default"
        dense
        label="Time frame"
        text={selectedTimeFrame.label}
        disabled={loading}
        content={(
          <MenuTemplate
            view="outlined"
            dense
            items={timeFrameList
              .map((timeFrameItem) => ({
                text: timeFrameItem.label,
                type: 'item',
                onClick: () => onTimeFrameChanged(timeFrameItem),
              }))}
            eventContext={Contexts.PLATFORM}
            style={{
              maxHeight: '250px',
              overflowY: 'auto',
            }}
          />
        )}
        style={{ width: '165px' }}
      />
    </AutoLayout>
  </AutoLayout>
);

export default Header;
