import { TextWithHeaderProps } from '@paradime-io/pragma-ui-kit/lib/components/DetailsCardComponent';
import { IconName } from '@blueprintjs/icons';
import { catalogueDetailsCardProps, catalogueDetailsCardReturnType } from '../types';
import { formatDateTimeToLocalString } from '../../utilis';

interface extraAttributesType {
  name: string,
  value: string,
  iconName: string,
}
const mapExtraAttributes = (extraAttributes: extraAttributesType[]) => {
  let stack: TextWithHeaderProps[] = [];
  const finalOutput: TextWithHeaderProps[][] = [];
  extraAttributes.forEach(({ name, value, iconName }) => {
    if (!value) return;
    stack.push({
      type: 'textWithHeader',
      header: name,
      text: value,
      icon: { iconName: iconName as IconName || undefined },
    });

    if (stack.length >= 2) {
      finalOutput.push(stack);
      stack = [];
    }
  });
  // For the final element if there is an odd number
  if (stack.length) finalOutput.push(stack);
  return finalOutput;
};

const getFieldIfExists = ({
  type = 'textWithHeader',
  header,
  text,
  icon,
}: Partial<TextWithHeaderProps>) => {
  if (text) {
    return [{
      type,
      header,
      text,
      icon,
      havePlaceholder: false,
    }];
  }

  return [];
};

const getTagsIfExists = (tags?: string[]) => {
  if (tags?.length) {
    return [{
      type: 'tags' as const,
      header: 'tags',
      tags,
    }];
  }

  return [];
};

export const catalogueDetailsCard = ({
  resultItem,
}: catalogueDetailsCardProps): catalogueDetailsCardReturnType => {
  switch (resultItem.__typename) {
    case 'GQLCatalogueIntegrationDatasourceItem':
      return {
        components: [
          [
            {
              type: 'textWithHeader',
              header: 'type',
              text: resultItem?.itemTypeLabel || 'Datasource',
              icon: { iconName: 'compass', color: 'var(--violet70)' },
            },
            ...getFieldIfExists({ text: resultItem?.databaseName, icon: { iconName: 'box' } }),
            ...getFieldIfExists({ text: resultItem?.tableName, icon: { iconName: 'database' } }),
            ...getFieldIfExists({ text: resultItem?.schemaName, icon: { iconName: 'layout-auto' } }),
          ],
          [
            ...getFieldIfExists({
              header: 'Created on',
              text: formatDateTimeToLocalString({ dateTimeString: resultItem?.createdAtTimestamp }),
            }),
            ...getFieldIfExists({ header: 'Last modified', text: undefined }),
          ],
          ...mapExtraAttributes(resultItem?.extraAttributes || []),
          [...getTagsIfExists(resultItem?.tags)],
        ],
      };
    case 'GQLCatalogueIntegrationDashboardItem':
      return {
        components: [
          [
            {
              type: 'textWithHeader',
              header: 'type',
              text: resultItem?.itemTypeLabel || 'Dashboard',
              icon: { iconName: 'control', color: 'var(--violet70)' },
            },
          ],
          [
            ...getFieldIfExists({
              header: 'Created on',
              text: formatDateTimeToLocalString({
                dateTimeString: resultItem?.createdAtTimestamp,
              }),
            }),
            ...getFieldIfExists({
              header: 'Last modified',
              text: formatDateTimeToLocalString({
                dateTimeString: resultItem?.lastModifiedAt,
              }),
            }),
          ],
          ...mapExtraAttributes(resultItem?.extraAttributes || []),
          [...getTagsIfExists(resultItem?.tags)],
        ],
      };
    case 'GQLCatalogueIntegrationChartItem':
      return {
        components: [
          [
            {
              type: 'textWithHeader',
              header: 'type',
              text: resultItem?.itemTypeLabel || 'Chart',
              icon: { iconName: resultItem?.itemTypeIconName as IconName || 'pie-chart', color: 'var(--violet70)' },
            },
          ],
          [
            {
              type: 'textWithHeader',
              header: 'Created on',
              text: formatDateTimeToLocalString({
                dateTimeString: resultItem?.createdAtTimestamp,
                placeholder: '-',
              }),
            },
            {
              type: 'textWithHeader',
              header: 'Last modified',
              text: formatDateTimeToLocalString({
                dateTimeString: resultItem?.lastModifiedAt,
                placeholder: '-',
              }),
            },
          ],
          ...mapExtraAttributes(resultItem?.extraAttributes || []),
          [...getTagsIfExists(resultItem?.tags)],
        ],
      };

    default:
      return { components: [] };
  }
};
