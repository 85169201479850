import create from 'zustand';
import { combine } from 'zustand/middleware';
import { RunResultsTabId } from '../components/Bolt/Scheduler/LogsAndFreshness';

export const boltStore = create(
  combine(
    {
      sqlResourceId: undefined as number | undefined,
      sqlResourcePath: undefined as string | undefined,
      runResultsTabId: RunResultsTabId.LOGS as string | undefined,
      expandCommandId: undefined as number | undefined,
    },
    (set) => ({
      setSqlResourceId: (sqlResourceId: number) => set(() => ({ sqlResourceId })),
      setSqlResourcePath: (sqlResourcePath: string) => set(() => ({ sqlResourcePath })),
      clearSqlResourceId: () => set(() => ({
        sqlResourceId: undefined,
      })),
      setRunResultsTabId: (runResultsTabId: string | undefined) => set(() => ({ runResultsTabId })),
      setExpandCommandId: (expandCommandId: number | undefined) => set(() => ({ expandCommandId })),
    }),
  ),
);
