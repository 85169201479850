import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import create from 'zustand';
import { combine } from 'zustand/middleware';
import { companyType } from './companyStore';
import { Hasura } from '../client/generated/service';

export type HasuraType = Omit<Hasura, '__typename'>;

export interface clusterConfigType {
  apiHost: string, // TODO: remove?
  appHost: string,
  lambdaHost: string,
  organisationResolver: string,
  controlPlaneUrl: string, // TODO: remove?
  magnetarUrl: string, // TODO: remove?
  loomApiKey: string,
  segmentKey: string,
  launchDarklyKey: string,
  supportedDbtVersions: string[],
  auth0EndpointDomain: string, // TODO: remove?
  auth0ApiDomain: string, // TODO: remove?
  auth0ClientId: string, // TODO: remove?
  auth0OrgIdForOnboarding: string,
  googleSheetsAuthClientId: string,
  asanaClientId: string,
  jiraClientId: string,
  jiraCallbackOrigin: string,
  linearClientId: string,
  slackId: string,
  chromeExtensionId: string,
  desktopAppPath: string,
  theiaSegmentKey: string,
  paradimeApiRegions: string[], // TODO: remove?
  stripePricingTable: string,
  stripePublishableKey: string,
  version: string,
}

export interface paradimeOrganisationType {
  auth0EndpointDomain: string,
  auth0ApiDomain: string,
  auth0ClientId: string,
  auth0Organisation: string,
  controlPlaneUrl: string,
  name: string,
}

export const graphQLStore = create(
  combine(
    {
      apolloClient: undefined as ApolloClient<NormalizedCacheObject> | undefined,
      apolloClientIsAuthorized: false,
      apolloClientIsOnlyForControlPlane: false,
      clusterConfig: undefined as clusterConfigType | undefined,
      paradimeOrganisation: undefined as paradimeOrganisationType | undefined,
      jwtToken: undefined as string | undefined,
      companyUrls: undefined as companyType,
      companyToken: undefined as string | undefined,
      workspaceToken: undefined as string | undefined,
      isIncompleteWorkspace: false,
      hasuraClient: undefined as ApolloClient<NormalizedCacheObject> | undefined,
      cubeClient: undefined as ApolloClient<NormalizedCacheObject> | undefined,
    },
    (set) => ({
      setApolloClient: (
        newClient: ApolloClient<NormalizedCacheObject>,
        isAuthorized: boolean,
        controlPlaneOnly: boolean,
      ) => set(() => ({
        apolloClient: newClient,
        apolloClientIsAuthorized: isAuthorized,
        apolloClientIsOnlyForControlPlane: controlPlaneOnly,
      })),
      setClusterConfig: (newConfig: clusterConfigType) => set(() => ({ clusterConfig: newConfig })),
      setParadimeOrganisation: (
        newParadimeOrganisation: paradimeOrganisationType | undefined,
      ) => set(() => ({
        paradimeOrganisation: newParadimeOrganisation,
      })),
      setJwtToken: (newJwtToken: string) => set(() => ({ jwtToken: newJwtToken })),
      setCompanyUrls: (newCompanyUrls: companyType) => set(() => {
        if (!newCompanyUrls) return { companyUrls: undefined, companyToken: undefined };
        return { companyUrls: newCompanyUrls, companyToken: newCompanyUrls.token };
      }),
      setWorkspaceToken: (workspaceToken: string) => set(() => ({ workspaceToken })),
      setHasuraClient: (
        newClient: ApolloClient<NormalizedCacheObject>,
      ) => set(() => ({ hasuraClient: newClient })),
      setIsIncompleteWorkspace: (isIncompleteWorkspace: boolean) => (
        set(() => ({ isIncompleteWorkspace }))
      ),
      setCubeClient: (cubeClient: ApolloClient<NormalizedCacheObject>) => set(
        () => ({ cubeClient }),
      ),
    }),
  ),
);
