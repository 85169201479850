import React from 'react';
import infotab from './images/infotab.png';
import recording from './images/recordings.png';
import pinExtension from './images/pin-extension.png';
import collaboration from './images/collaboration.png';
import complete from './images/complete.png';

const Features = [
  {
    graphic: infotab,
    title: 'Info',
    slug: 'info',
    description: (
      <>
        Open the extension and view info about your Looker dashboards,
        looks and explores at a glance without opening a new tab or application.
      </>
    ),
  },
  {
    graphic: recording,
    title: 'Recordings',
    slug: 'recordings',
    description: (
      <>
        View, record and share How-to-use videos of any Looker asset
        i.e. dashboard, looks and explores.
        <br />
        <br />
        Work in async, distribute knowledge efficiently and
        reduce time lost in meetings or training sessions.
      </>
    ),
  },
  {
    graphic: collaboration,
    title: 'Collaboration',
    slug: 'collaboration',
    description: (
      <>
        Pin-point the data context, collaborate in real-time with bi-directional sync with Slack,
        create a JIRA task - all without leaving Looker or your BI tool.
        <br />
        <br />
        Direct people back to questions already answered without having to repeat yourself.
      </>
    ),
  },
  {
    graphic: pinExtension,
    title: 'Pin to browser',
    slug: 'pin-to-browser',
    description: (
      <>
        Pin the Paradime extension to your browser for quick access
        to powerful collaboration features.
        <br />
        <br />
        In one click, view docs about a dashboard or explore,
        watch explainer videos and connect effortlessly with your team.
      </>
    ),
  },
];

const EndScreen = {
  graphic: complete,
  title: 'You are all set',
  slug: 'completed',
  description: (
    <>
      Congratulations 🎉.
      <br />
      <br />
      The Paradime extension is now successfully installed.
      Try using it on any Looker dashboard or explore and
      unlock powerful data collaboration features.
      <br />
      <br />
      You can now close this browser tab.
    </>
  ),
};

export { Features, EndScreen };
