import React, { FC } from 'react';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';

export interface SearchMenuLabelProps {
  text: string,
  color: string
}

const SearchMenuLabel: FC<SearchMenuLabelProps> = ({ text, color }) => (
  <span
    style={{
      background: color,
      height: '14px',
      borderRadius: '4px',
      display: 'inline-grid',
      placeItems: 'center',
      padding: '1px 4px',
    }}
  >
    <Typography type="caption-small" color="default" colorStep="0">{text}</Typography>
  </span>
);

export default SearchMenuLabel;
