import {
  catalogueAssetColumnsProps,
  catalogueAssetColumnsReturnType,
  catalogueAssetItemIdProps,
  catalogueAssetItemIdReturnType,
  catalogueClassificationDataProps,
  catalogueClassificationDataReturnType,
  catalogueDescriptionProps,
  catalogueDescriptionReturnType,
  catalogueDetailsCardProps,
  catalogueDetailsCardReturnType,
  catalogueFieldsTabProps,
  catalogueFieldsTabReturnType,
  catalogueItemTabsVisibilityProps,
  catalogueItemTabsVisibilityReturnType,
  catalogueHeaderButtonsProps,
  catalogueHeaderButtonsReturnType,
  catalogueLogoProps,
  catalogueLogoReturnType,
  catalogueNameAndIconProps,
  catalogueNameAndIconReturnType,
  catalogueSearchFiltersProps,
  catalogueSearchFiltersReturnType,
  catalogueSearchResultItemProps,
  catalogueSearchResultItemReturnType,
  catalogueSimpleTypeProps,
  catalogueSimpleTypeReturnType,
  lineageContextMenuProps,
  lineageContextMenuReturnType,
  lineageFilterNodeProps,
  lineageFilterNodeReturnType,
  lineageNodeDisplayProps,
  lineageNodeDisplayReturnType,
  lineageSearchInputProps,
  lineageSearchInputReturnType,
  catalogueTreeIconsProps,
  catalogueTreeIconsReturnType,
  catalogueSchemaTabProps,
  catalogueSchemaTabReturnType,
  catalogueDependenciesTabProps,
  catalogueDependenciesTabReturnType,
  catalogueDbtModelTabProps,
  catalogueDbtModelTabReturnType,
  catalogueShowClassificationsCardProps,
  catalogueShowClassificationsCardReturnType,
} from './types';
import { LookerIntegrationHandler, LookerHandlerAcceptedTypes, LookerSubTypes } from './Looker';
import { DbtIntegrationHandler, DbtHandlerAcceptedTypes, DbtSubTypes } from './Dbt';
import { DwhIntegrationHandler, DwhHandlerAcceptedTypes, DwhSubTypes } from './Dwh';
import { TableauIntegrationHandler, TableauHandlerAcceptedTypes, TableauSubTypes } from './Tableau';
import { FivetranIntegrationHandler, FivetranHandlerAcceptedTypes, FivetranSubTypes } from './Fivetran';
import { HightouchIntegrationHandler, HightouchHandlerAcceptedTypes, HightouchSubTypes } from './Hightouch';
import { IntegrationAPIItemIntegrationHandler, IntegrationAPIItemHandlerAcceptedTypes, IntegrationAPIItemSubTypes } from './IntegrationAPIItems';

export enum allowedHandlers {
  // lineageNodeRenderer = 'lineageNodeRenderer',
  // catalogueCardRenderer = 'catalogueCardRenderer',
  radarTreeSignals = 'radarTreeSignals',
  lineageContextMenu = 'lineageContextMenu',
  lineageSearchInputDropdown = 'lineageSearchInputDropdown',
  lineageFilterNodes = 'lineageFilterNodes',
  catalogueSearchResultItem = 'catalogueSearchResultItem',
  catalogueDetailsCard = 'catalogueDetailsCard',
  catalogueNameAndIcon = 'catalogueNameAndIcon',
  catalogueHeaderButtons = 'catalogueHeaderButtons',
  catalogueClassificationData = 'catalogueClassificationData',
  catalogueLogo = 'catalogueLogo',
  catalogueSimpleType = 'catalogueSimpleType',
  catalogueDescription = 'catalogueDescription',
  lineageNodeDisplay = 'lineageNodeDisplay',
  catalogueAssetColumns = 'catalogueAssetColumns',
  catalogueAssetItemId = 'catalogueAssetItemId',
  catalogueSearchFilters = 'catalogueSearchFilters',
  catalogueItemTabsVisibility = 'catalogueItemTabsVisibility',
  catalogueFieldsTab = 'catalogueFieldsTab',
  catalogueDependenciesTab = 'catalogueDependenciesTab',
  catalogueSchemaTab = 'catalogueSchemaTab',
  catalogueDbtModelTab = 'catalogueDbtModelTab',
  catalogueTreeIcons = 'catalogueTreeIcons',
  catalogueShowClassificationsCard = 'catalogueShowClassificationsCard',
}

export const IntegrationTypes = {
  ...LookerHandlerAcceptedTypes,
  ...DbtHandlerAcceptedTypes,
  ...DwhHandlerAcceptedTypes,
  ...TableauHandlerAcceptedTypes,
  ...FivetranHandlerAcceptedTypes,
  ...HightouchHandlerAcceptedTypes,
  ...IntegrationAPIItemHandlerAcceptedTypes,
};
export type IntegrationAcceptedTypes = LookerHandlerAcceptedTypes
  | DbtHandlerAcceptedTypes
  | DwhHandlerAcceptedTypes
  | TableauHandlerAcceptedTypes
  | FivetranHandlerAcceptedTypes
  | HightouchHandlerAcceptedTypes
  | IntegrationAPIItemHandlerAcceptedTypes;

export const IntegrationSubTypes = {
  ...LookerSubTypes,
  ...DwhSubTypes,
  ...DbtSubTypes,
  ...TableauSubTypes,
  ...FivetranSubTypes,
  ...HightouchSubTypes,
  ...IntegrationAPIItemSubTypes,
};
export type IntegrationTypeSubTypes = LookerSubTypes
  | DwhSubTypes
  | DbtSubTypes
  | TableauSubTypes
  | FivetranSubTypes
  | HightouchSubTypes
  | IntegrationAPIItemSubTypes;

export type GetIntegrationHandlerType = (typ: string) => IntegrationHandler | null;

export interface IntegrationHandler {
  type: IntegrationAcceptedTypes,
  subTypes: IntegrationTypeSubTypes[],
  handlers: {
    [allowedHandlers.lineageContextMenu]:
      (props: lineageContextMenuProps) => lineageContextMenuReturnType,
    [allowedHandlers.lineageSearchInputDropdown]:
      (props: lineageSearchInputProps) => lineageSearchInputReturnType,
    [allowedHandlers.lineageFilterNodes]:
      (props: lineageFilterNodeProps) => lineageFilterNodeReturnType,
    [allowedHandlers.catalogueSearchResultItem]:
      (props: catalogueSearchResultItemProps) => catalogueSearchResultItemReturnType,
    [allowedHandlers.catalogueDetailsCard]:
      (props: catalogueDetailsCardProps) => catalogueDetailsCardReturnType,
    [allowedHandlers.catalogueNameAndIcon]:
      (props: catalogueNameAndIconProps) => catalogueNameAndIconReturnType,
    [allowedHandlers.catalogueHeaderButtons]:
      (props: catalogueHeaderButtonsProps) => catalogueHeaderButtonsReturnType,
    [allowedHandlers.catalogueClassificationData]:
      (props: catalogueClassificationDataProps) => catalogueClassificationDataReturnType,
    [allowedHandlers.catalogueLogo]:
      (props: catalogueLogoProps) => catalogueLogoReturnType,
    [allowedHandlers.catalogueSimpleType]:
      (props: catalogueSimpleTypeProps) => catalogueSimpleTypeReturnType,
    [allowedHandlers.catalogueDescription]:
      (props: catalogueDescriptionProps) => catalogueDescriptionReturnType,
    [allowedHandlers.lineageNodeDisplay]:
      (props: lineageNodeDisplayProps) => lineageNodeDisplayReturnType,
    [allowedHandlers.catalogueAssetColumns]:
      (props: catalogueAssetColumnsProps) => catalogueAssetColumnsReturnType,
    [allowedHandlers.catalogueAssetItemId]:
      (props: catalogueAssetItemIdProps) => catalogueAssetItemIdReturnType,
    [allowedHandlers.catalogueSearchFilters]:
      (props: catalogueSearchFiltersProps) => catalogueSearchFiltersReturnType,
    [allowedHandlers.catalogueItemTabsVisibility]:
      (props: catalogueItemTabsVisibilityProps) => catalogueItemTabsVisibilityReturnType,
    [allowedHandlers.catalogueFieldsTab]:
      (props: catalogueFieldsTabProps) => catalogueFieldsTabReturnType,
    [allowedHandlers.catalogueDependenciesTab]:
      (props: catalogueDependenciesTabProps) => catalogueDependenciesTabReturnType,
    [allowedHandlers.catalogueSchemaTab]:
      (props: catalogueSchemaTabProps) => catalogueSchemaTabReturnType[],
    [allowedHandlers.catalogueTreeIcons]:
      (props: catalogueTreeIconsProps) => catalogueTreeIconsReturnType,
    [allowedHandlers.catalogueDbtModelTab]:
      (props: catalogueDbtModelTabProps) => catalogueDbtModelTabReturnType,
    [allowedHandlers.catalogueShowClassificationsCard]:
      (props: catalogueShowClassificationsCardProps) => catalogueShowClassificationsCardReturnType
  },
}

export const getAllRegisteredIntegrationHandlers = () => {
  const registeredIntegrationHandlers: {
    [key: string]: IntegrationHandler
  } = {};

  Object.keys(LookerHandlerAcceptedTypes).forEach((typ: string) => {
    registeredIntegrationHandlers[typ] = LookerIntegrationHandler;
  });
  Object.keys(DbtHandlerAcceptedTypes).forEach((typ: string) => {
    registeredIntegrationHandlers[typ] = DbtIntegrationHandler;
  });
  Object.keys(DwhHandlerAcceptedTypes).forEach((typ: string) => {
    registeredIntegrationHandlers[typ] = DwhIntegrationHandler;
  });
  Object.keys(TableauHandlerAcceptedTypes).forEach((typ: string) => {
    registeredIntegrationHandlers[typ] = TableauIntegrationHandler;
  });
  Object.keys(FivetranHandlerAcceptedTypes).forEach((typ: string) => {
    registeredIntegrationHandlers[typ] = FivetranIntegrationHandler;
  });
  Object.keys(HightouchHandlerAcceptedTypes).forEach((typ: string) => {
    registeredIntegrationHandlers[typ] = HightouchIntegrationHandler;
  });
  Object.keys(IntegrationAPIItemHandlerAcceptedTypes).forEach((typ: string) => {
    registeredIntegrationHandlers[typ] = IntegrationAPIItemIntegrationHandler;
  });

  return registeredIntegrationHandlers;
};

export const integrationHandlerManager = (type: string) => {
  const handlers = getAllRegisteredIntegrationHandlers();

  const selectedHandler = handlers[type];
  const defaultHandler = IntegrationAPIItemIntegrationHandler;

  return selectedHandler || defaultHandler;
};
