import React, { FC, useEffect, useState } from 'react';
import Timeline from 'react-visjs-timeline';
import Card from '@paradime-io/pragma-ui-kit/lib/components/Card';
import Spinner from '@paradime-io/pragma-ui-kit/lib/components/Spinner';
import { AutoLayout, Typography } from '@paradime-io/pragma-ui-kit';
import TimelineRunCompleteZeroState from './TimelineRunCompleteZeroState';
import TimelineRunInProgressZeroState from './TimelineRunInProgressZeroState';
import { TimelineView as TimelineViewType } from '../../../hooks/useGetTimelineData';
import { genericObject } from '../../../../../utilis';
import * as S from '../../Scheduler.styles';

export interface TimelineViewProps {
  timelineView?: TimelineViewType,
  isLoading: boolean,
  deadlinePassed: boolean,
  isRunning?: boolean,
  endDttm?: string | null,
  runState: string,
}

const TimelineView:FC<TimelineViewProps> = ({
  timelineView,
  isLoading,
  deadlinePassed,
  isRunning,
  runState,
}) => {
  const [options, setOptions] = useState<genericObject>();

  useEffect(() => {
    if (timelineView) {
      setOptions({
        stack: true,
        horizontalScroll: true,
        verticalScroll: true,
        zoomKey: 'ctrlKey',
        start: timelineView.data?.start,
        end: timelineView.data?.end,
        editable: false,
        margin: {
          item: 0, // minimal margin between items
          axis: 5, // minimal margin between items and the axis
        },
        orientation: 'top',
        showTooltips: true,
      });
    }
  }, [timelineView]);

  const getTimelineElement = () => {
    if (isRunning) {
      return (<TimelineRunInProgressZeroState />);
    }

    if (!isRunning && runState !== 'Success') {
      return (<TimelineRunCompleteZeroState error />);
    }

    if (isLoading && !deadlinePassed) {
      return (
        <AutoLayout
          direction="vertical"
          padding="none"
          distribution="packed"
          verticalGap="dense"
        >
          <Spinner width={20} height={20} thin />
          <Typography tagName="span" type="caption" style={{ justifySelf: 'center' }}>
            Processing timeline data for this run.
          </Typography>
        </AutoLayout>
      );
    }

    if (timelineView?.data?.items.length === 0 && deadlinePassed) {
      return (<TimelineRunCompleteZeroState error={false} />);
    }

    return (
      <S.TimelineWrapper>
        <Timeline
          options={options}
          items={timelineView?.data?.items}
          groups={timelineView?.data?.threads}
        />
      </S.TimelineWrapper>
    );
  };

  return (
    <Card
      tabIndex={-1}
      padding="very-dense"
      style={{ padding: '0 0 10px 0', backgroundColor: 'var(--grey0)', minHeight: '400px' }}
    >
      <AutoLayout
        direction="vertical"
        padding="none"
        distribution="packed"
        verticalGap="none"
      >
        <Typography
          tagName="span"
          type="overline-small"
          style={{ padding: '10px 8px 10px 8px', borderBottom: '1px solid var(--grey30)' }}
        >
          Timeline
        </Typography>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'var(--white)',
          minHeight: '300px',
        }}
        >
          {getTimelineElement()}
        </div>
      </AutoLayout>
    </Card>
  );
};

export default TimelineView;
