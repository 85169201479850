/* eslint-disable */
import React, { useEffect, FC } from 'react';
import { useHistory } from 'react-router-dom';

const PageAnalytics: FC = () => {
  const history = useHistory();

  // call analytics page on location change
  useEffect(() => history.listen((location) => {
    window.analytics?.page();
  }), [history]);

  return (<div />);
};

export default PageAnalytics;
