import { catalogueDependenciesTabProps } from '../types';

export const catalogueDependenciesTab = ({
  resultItem,
}: catalogueDependenciesTabProps) => {
  switch (resultItem?.__typename) {
    case 'GQLCatalogueFivetranConnectorItem':
      return {
        sources: [],
        nodeId: resultItem?.lineageRef?.nodeId,
        hideSourcesCard: true,
      };
    default:
      return { sources: [] };
  }
};
