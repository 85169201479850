/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-unused-expressions */
import React, {
  FC, useEffect, useMemo,
} from 'react';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { AppLocalStorageKeys } from '@paradime/common/lib/common/localStorageKeys';
import { graphQLStore, clusterConfigType } from '../stores';
import AppWithClusterConfig from './AppWithClusterConfig';
import { StripeContext, useSetupStripe } from '../components/Common/Libraries/Stripe/useSetupStripe';
import { isDevEnvironment } from '../utilis/common';
import { useGetUserIdentifyingProperties } from '../utilis/useGetUserIdentifyingProperties';
import { userHasAccessLevelSet } from '../utilis/PermissionsService';

const App: FC = () => {
  const { location: { host, protocol } } = window;
  const setClusterConfig = graphQLStore((state) => state.setClusterConfig);

  const { initialContextValue, setupStripe } = useSetupStripe();
  const {
    username,
    userEmail,
    type,
    emailDomain,
    companyName,
    backendUserId,
  } = useGetUserIdentifyingProperties();

  const canInitLaunchDarkly = (
    !!window.localStorage.getItem(AppLocalStorageKeys.LAUNCHDARKLY)
    && userHasAccessLevelSet(type)
  );

  useEffect(() => {
    const setupApp = async () => {
      // Fetch cluster-config.
      const response = await fetch(`${protocol}//${host}/cluster-config.json`);
      const clusterConfig: clusterConfigType = await response.json();
      setClusterConfig(clusterConfig);
      console.debug('cluster config is now set'); // eslint-disable-line
    };
    setupApp();
  }, []);

  const clusterConfigFromStore = graphQLStore((state) => state.clusterConfig);

  useEffect(() => {
    if (clusterConfigFromStore?.launchDarklyKey) {
      window.localStorage.setItem(
        AppLocalStorageKeys.LAUNCHDARKLY,
        clusterConfigFromStore.launchDarklyKey,
      );
    }

    setupStripe();
  }, [clusterConfigFromStore]);

  const ClusterConfigWithLDWrapped = useMemo(() => withLDProvider({
    clientSideID: window.localStorage.getItem(AppLocalStorageKeys.LAUNCHDARKLY)!,
    context: {
      key: isDevEnvironment ? 'demotrooper@paradime.io' : backendUserId,
      custom: {
        type,
        emailDomain,
        companyName,
      },
      name: username,
      email: userEmail,
      privateAttributeNames: ['name', 'email'],
    },
  })(AppWithClusterConfig), [canInitLaunchDarkly]);

  return (
    <StripeContext.Provider value={initialContextValue}>
      {canInitLaunchDarkly
        ? <ClusterConfigWithLDWrapped />
        : <AppWithClusterConfig />}
    </StripeContext.Provider>
  );
};

export default App;
