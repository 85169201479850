import { IntegrationHandler } from '..';
import { lineageFilterNodes } from './LineageFilterNodes';
import { lineageSearchInputDropdown } from './LineageSearchInputDropdown';
import { lineageNodeDisplay } from './LineageNodeDisplay';
import { lineageContextMenu } from './LineageContextMenu';
import { catalogueSearchFilters } from './CatalogueSearchFilters';
import { catalogueSearchResultItem } from './CatalogueSearchResultItem';
import { catalogueNameAndIcon } from './CatalogueNameAndIcon';
import { catalogueDetailsCard } from './CatalogueDetailsCard';
import { catalogueSimpleType } from './CatalogueSimpleType';
import { catalogueDescription } from './CatalogueDescription';
import { catalogueItemTabsVisibility } from './CatalogueItemTabsVisibility';
import { catalogueLogo } from './CatalogueLogo';
import { catalogueHeaderButtons } from './CatalogueHeaderButtons';
import { catalogueSchemaTab } from './CatalogueSchemaTab';
import { catalogueDependenciesTab } from './CatalogueDependenciesTab';
import { catalogueTreeIcons } from './CatalogueTreeIcons';
// import { radarTreeSignals } from './RadarTreeSignals';
// import { radarNoConnectionZeroState } from './RadarNoConnectionZeroState';
// import { radarHeaderIcon } from './RadarHeaderIcon';
// import { radarActionButtonLogo } from './RadarActionButtonLogo';

export enum FivetranHandlerAcceptedTypes {
  'FIVETRAN' = 'FIVETRAN',
  'FivetranWorksheetNode' = 'FivetranWorksheetNode',
  'FivetranDashboardNode' = 'FivetranDashboardNode',
  'FivetranDatasourceNode' = 'FivetranDatasourceNode',
  'FivetranFlowNode' = 'FivetranFlowNode',
  'GQLCatalogueFivetranConnectorItem' = 'GQLCatalogueFivetranConnectorItem',
  'fivetran' = 'fivetran',
  'connector' = 'connector',
  'FivetranConnectorNode' = 'FivetranConnectorNode',
}

export enum FivetranSubTypes {
  'FIVETRAN_CONNECTOR' = 'Connector',
}

export enum LineageNodeTypeTranslations {
  'FivetranConnectorNode' = 'Fivetran',
}

export const fivetranSourceLogo = 'https://fivetran.com/integrations/fivetran_log/core/resources/fivetran.svg';

export const FivetranIntegrationHandler: IntegrationHandler = ({
  type: FivetranHandlerAcceptedTypes.FIVETRAN,
  subTypes: Object.values(FivetranSubTypes),
  handlers: {
    lineageContextMenu,
    lineageSearchInputDropdown,
    lineageFilterNodes,
    catalogueSearchResultItem,
    catalogueDetailsCard,
    catalogueNameAndIcon,
    catalogueHeaderButtons,
    catalogueClassificationData: () => ({
      keyList: [],
      tableClassificationOptions: [],
    }),
    catalogueLogo,
    catalogueSimpleType,
    catalogueDescription,
    lineageNodeDisplay,
    catalogueAssetColumns: () => [],
    catalogueAssetItemId: () => 'error',
    catalogueSearchFilters,
    catalogueItemTabsVisibility,
    catalogueFieldsTab: () => ({ columnHeadings: [], data: [] }),
    catalogueDependenciesTab,
    catalogueSchemaTab,
    catalogueDbtModelTab: () => undefined,
    catalogueTreeIcons,
    catalogueShowClassificationsCard: () => true,
  },
});
