import React, {
  Dispatch, FC, SetStateAction, useCallback,
} from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { colorType } from '@paradime-io/pragma-ui-kit/lib/components/styles/styleTypes';
import { IconName } from '@blueprintjs/icons';
import * as S from '../Scheduler.styles';

export interface PreviousNextButtonProps {
  /** the array containing a list of numbers to cycle through */
  selectionArray: number[],
  color: colorType,
  text: string | JSX.Element,
  icon?: IconName,
  currentSelection: number,
  setCurrentSelection: Dispatch<SetStateAction<number>>,
  setPreviousSelection: Dispatch<SetStateAction<number>>,
  onSelectionChanged?: () => void,
}

const PreviousNextButton:FC<PreviousNextButtonProps> = ({
  selectionArray,
  color,
  text,
  icon,
  currentSelection,
  setCurrentSelection,
  setPreviousSelection,
  onSelectionChanged,
}) => {
  const handleGotoNext = useCallback(() => {
    // returns the index of the first item in selection array greater than current selection
    const nextSelectionItemIndex = selectionArray.findIndex(
      (arrayItem) => arrayItem > currentSelection,
    );
    setPreviousSelection(currentSelection);

    if (nextSelectionItemIndex !== -1) {
      // set current selection to the next item in the selection array
      setCurrentSelection(selectionArray[nextSelectionItemIndex]);
    } else if (selectionArray.length > 0) {
      // we could find the next item and array has items
      // i.e. we have reached end of the array,
      // lets set back to first item.
      setCurrentSelection(selectionArray[0]);
    }

    if (onSelectionChanged) onSelectionChanged();
  }, [currentSelection, setCurrentSelection, selectionArray]);

  const handleGotoPrevious = useCallback(() => {
    // returns the index of the first item in selection array before the current selection item
    const previousSelectionItemIndex = selectionArray.findIndex(
      (index) => index >= currentSelection,
    ) - 1;
    setPreviousSelection(currentSelection);

    if (previousSelectionItemIndex > -1) {
      // set current selection to the previous item in the selection array
      setCurrentSelection(selectionArray[previousSelectionItemIndex]);
    } else if (selectionArray.length > 0) {
      // we have reached the start of the selection array,
      // we now need to go till the end
      setCurrentSelection(selectionArray[selectionArray.length - 1]);
    }

    if (onSelectionChanged) onSelectionChanged();
  }, [currentSelection, setCurrentSelection, selectionArray]);

  return (
    <S.PreviousNextButton>
      <AutoLayout
        direction="horizontal"
        distribution="packed"
        padding="none"
        horizontalGap="none"
      >
        <DefaultButton
          id="button-static"
          icon={icon}
          text={text}
          eventAction={Actions.CLICKED}
          eventContext={Contexts.BOLT}
          eventObject="consoleError"
          color={color}
          view="smooth"
          disabled
          dense
        />
        <DefaultButton
          id="button-next"
          icon="chevron-down"
          eventAction={Actions.CLICKED}
          eventContext={Contexts.BOLT}
          eventObject="nextError"
          color={color}
          view="smooth"
          dense
          onClick={() => handleGotoNext()}
        />
        <DefaultButton
          id="button-previous"
          icon="chevron-up"
          eventAction={Actions.CLICKED}
          eventContext={Contexts.BOLT}
          eventObject="previousError"
          color={color}
          view="smooth"
          dense
          onClick={() => handleGotoPrevious()}
        />
      </AutoLayout>
    </S.PreviousNextButton>
  );
};

export default PreviousNextButton;
