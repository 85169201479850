import { AnalyticsSnippet, Context } from '@segment/analytics-next';
import { ignoredEvents, ignoredEventProperties } from './ignoredEventList';
import { userIdentifyingPropertiesType } from '../../../../utilis/useGetUserIdentifyingProperties';

export type trackArgs = Parameters<AnalyticsSnippet['track']>

const getIgnoredTrackEvents = (
  originalTrack: AnalyticsSnippet['track'],
  userIdentifyingProperties: userIdentifyingPropertiesType,
): AnalyticsSnippet['track'] => async (...args) => {
  const [event, properties] = args;

  let isIgnoredEvent = false;

  if (typeof event === 'string') isIgnoredEvent = ignoredEvents.includes(event);
  else if (event.event) isIgnoredEvent = ignoredEvents.includes(event.event);

  let hasIgnoredProperty = false;

  if (properties && typeof properties !== 'function') {
    hasIgnoredProperty = Object
      .entries(properties)
      .some(([key, value]) => ignoredEventProperties
        .some(([ignoredKey, ignoredValue]) => key === ignoredKey && value === ignoredValue));
  }

  if (isIgnoredEvent || hasIgnoredProperty) return {} as Context;

  return originalTrack(event, { ...userIdentifyingProperties, ...properties });
};

export default getIgnoredTrackEvents;
