import { catalogueAssetItemIdProps } from '../types';

export const catalogueAssetItemId = ({
  resultItem,
}: catalogueAssetItemIdProps) => {
  switch (resultItem.__typename) {
    case 'GQLCatalogueDBTExposureItem':
      return resultItem.itemId;
    default:
      return 'error';
  }
};
