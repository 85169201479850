import { catalogueLogoProps } from '../types';
import { decodeLogoBase64 } from '../utils';

export const catalogueLogo = ({ resultItem }: catalogueLogoProps) => {
  switch (resultItem.__typename) {
    case 'GQLCatalogueIntegrationChartItem':
    case 'GQLCatalogueIntegrationDashboardItem':
    case 'GQLCatalogueIntegrationDatasourceItem':
      return {
        firstImage: decodeLogoBase64(resultItem?.integrationLogoBase64),
      };
    default:
      return { firstImage: '' };
  }
};
