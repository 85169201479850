/* eslint-disable no-param-reassign */
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { EnvName } from '../../../../../client/service.graphql';
import {
  useDwhCheckExtensionLazyQuery,
  useSetupDwhExtensionMutation,
  useCompanyOnboardingExtensionMutation,
  useCheckProfileAddExtensionLazyQuery,
  useProfileAddExtensionMutation,
  useSetupDwhProductionExtensionMutation,
} from '../../../../../client/generated/service';
import useOnboardIfChecked from '../hooks/useOnboardIfChecked';
import { WarehouseEnv } from '../../utils';
import {
  getButtonDefinition,
  BUTTON_STATE_TEXT,
  onSubmitButtonClickProps,
  getInitialButtonText,
  useCommonConfigProps,
  isEditButtonShown,
} from '.';
import { WareHouseModalSource } from '../..';

export interface GenericWarehouseFormDataType {
  connectionName: string,
  path: string,
  redactedProfile: string,
  datasetName: string,
  threads: number,
}

interface extraProps {
  warehouseType: string,
}

export const useCommonConfig = ({
  formData,
  env,
  source,
  isEditable,
  isAdditionalUser,
  isNewConnection,
  warehouseType,
}: useCommonConfigProps & extraProps) => {
  const [onboarded, setOnboarded] = useState<boolean>();
  const [errorMessage, setErrorMessage] = useState('');
  const [currentButtonText, setCurrentButtonText] = useState(
    getInitialButtonText({
      source,
      isEditable,
      isAdditionalUser,
    }),
  );
  const [checkInProgress, setCheckInProgress] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const history = useHistory();

  const { checkQueryFunction: checkOnboardWarehouse } = useOnboardIfChecked({
    checkQuery: useDwhCheckExtensionLazyQuery, // @ts-ignore
    triggerQuery: useCompanyOnboardingExtensionMutation,
    setErrorMessage,
    setOnboarded,
    variables: formData as { [key: string]: any },
    setIsChecked,
    setCheckInProgress,
  });

  const { checkQueryFunction: checkUpdateWarehouse } = useOnboardIfChecked({
    checkQuery: useDwhCheckExtensionLazyQuery,
    triggerQuery: useSetupDwhExtensionMutation,
    setErrorMessage,
    setOnboarded,
    variables: formData as { [key: string]: any },
    setIsChecked,
    setCheckInProgress,
  });

  const { checkQueryFunction: checkAddWarehouse } = useOnboardIfChecked({
    checkQuery: useCheckProfileAddExtensionLazyQuery,
    triggerQuery: useProfileAddExtensionMutation,
    setErrorMessage,
    setOnboarded,
    variables: formData as { [key: string]: any },
    setIsChecked,
    setCheckInProgress,
  });

  const { checkQueryFunction: onboardProductionWarehouse } = useOnboardIfChecked({
    checkQuery: useDwhCheckExtensionLazyQuery, // @ts-ignore
    triggerQuery: useSetupDwhProductionExtensionMutation,
    setErrorMessage,
    setOnboarded,
    variables: { ...formData, productionEnvName: EnvName.PRODUCTION },
    setIsChecked,
    setCheckInProgress,
  });

  const testTheConnection = (
    vars: onSubmitButtonClickProps['formData'],
    credentialId: onSubmitButtonClickProps['credentialId'],
  ) => {
    const variables = {
      ...vars,
      dbtType: warehouseType,
      profile: (vars as unknown as GenericWarehouseFormDataType).redactedProfile,
    };

    if (isAdditionalUser) {
      checkAddWarehouse({
        variables: {
          ...variables,
          credentialId: credentialId!,
        },
      });
    } else {
      switch (env) {
        case WarehouseEnv.DEV:
          if (source === WareHouseModalSource.ACCOUNT_SETTINGS) {
            checkUpdateWarehouse({ variables });
          } else {
            checkOnboardWarehouse({ variables });
          }
          break;
        case WarehouseEnv.PROD:
          onboardProductionWarehouse({
            variables: {
              ...variables,
              productionEnvName: EnvName.PRODUCTION,
            },
          });
          break;
        default:
          break;
      }
    }
  };

  const submitButtonDetails = getButtonDefinition({
    state: currentButtonText,
    isAdditionalUser,
    testTheConnection,
    setCurrentButtonText,
    env,
    source,
    isEditable,
    history,
  });

  useEffect(() => {
    if (isChecked && !checkInProgress) {
      if (onboarded) {
        if (submitButtonDetails?.nextState) {
          setCurrentButtonText(submitButtonDetails.nextState.success);
        }
      } else {
        setCurrentButtonText(
          submitButtonDetails?.nextState?.failure || ('' as BUTTON_STATE_TEXT),
        );
      }
    }
  }, [onboarded, submitButtonDetails, checkInProgress]);

  return {
    onboarded,
    errorMessage,
    submitButtonText: submitButtonDetails?.submitButtonText,
    onSubmitButtonClick: submitButtonDetails?.onSubmitButtonClick,
    dataIsBeingChecked: checkInProgress,
    showEditButton: isEditButtonShown({ source, isNewConnection, env }),
  };
};
