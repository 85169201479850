import React, { FC, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IconName } from '@blueprintjs/icons';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import InsightCard from './InsightCard';
import { CopyItem } from '../hooks/useCopyManager';

const ZeroStateCard: FC<CopyItem & { loading: boolean }> = ({
  title,
  iconName,
  description,
  ctaUrl,
  loading,
}) => {
  const [isScrollable, setIsScrollable] = useState(false);

  const cardContentRef = useRef<HTMLDivElement>(null);

  const history = useHistory();

  return (
    <InsightCard
      key={title}
      childrenRef={cardContentRef}
      isLoading={loading}
      title={title || ''}
      titleFontType="body-bold"
      titleColorStep="80"
      cardBackgroundColor="var(--white)"
      borderColor="var(--grey10)"
      icon={iconName as IconName}
      iconColor="var(--violet60)"
      iconBackgroundColor="var(--violet5)"
      childrenHeight="105px"
    >
      <AutoLayout
        ref={cardContentRef}
        direction="vertical"
        padding="none"
        verticalGap="dense"
        distribution="packed"
        alignment="top"
        style={{ padding: '1px' }} // So the button border doesn't get cut off
      >
        <div
          onMouseEnter={() => {
            const contentIsTooLong = (
              (cardContentRef?.current?.offsetHeight || 1)
              <= (cardContentRef?.current?.scrollHeight || 2)
            );
            setIsScrollable(contentIsTooLong);
          }}
          onMouseLeave={() => setIsScrollable(false)}
          style={{
            width: '100%',
            height: ctaUrl ? '58px' : '100px',
            overflowY: isScrollable ? 'auto' : 'hidden',
            overflowX: 'hidden',
            paddingRight: isScrollable ? 0 : '6px',
          }}
        >
          {description && <Typography type="body-small" colorStep="50">{description}</Typography>}
        </div>
        {ctaUrl && (
          <DefaultButton
            type="standard"
            view="smooth"
            color="primary"
            dense
            text="Try it"
            rightIcon="chevron-right"
            onClick={() => history.push(ctaUrl)}
            eventContext={Contexts.HOME}
            eventObject="tryUnlockAiSuggestion"
            eventAction={Actions.CLICKED}
            eventProperties={{ action: title }}
            style={{
              width: 'fit-content',
              justifySelf: 'flex-end',
            }}
          />
        )}
      </AutoLayout>
    </InsightCard>
  );
};

export default ZeroStateCard;
