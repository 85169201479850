import React, {
  Dispatch, FC, SetStateAction, useEffect, useState,
} from 'react';
import ErrorCallout from '@paradime-io/pragma-ui-kit/lib/components/ErrorCallout';
import Callout from '@paradime-io/pragma-ui-kit/lib/components/Callout';
import MDEditor from '@paradime-io/pragma-ui-kit/lib/components/MDEditor';
import MultiLineInput from '@paradime-io/pragma-ui-kit/lib/components/MultiLineInput';
import DefaultInput from '@paradime-io/pragma-ui-kit/lib/components/DefaultInput';
import Spinner from '@paradime-io/pragma-ui-kit/lib/components/Spinner';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import Dropdown from '@paradime-io/pragma-ui-kit/lib/components/Dropdown';
import { MenuTemplate } from '@paradime-io/pragma-ui-kit/lib/components/Menu';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { DefaultButton } from '@paradime-io/pragma-ui-kit';
import { useManageTickets } from '../../hooks/useManageTickets';

export interface TicketFormProps {
  viewTicketUrl?: string,
  setViewTicketUrl: Dispatch<SetStateAction<string | undefined>>,
  setTicketFormVisible: Dispatch<SetStateAction<boolean>>,
  onSuccess: () => void,
  onCancel: () => void,
  runId?: number,
  title: string,
  description: string,
}

const TicketForm:FC<TicketFormProps> = ({
  viewTicketUrl,
  setViewTicketUrl,
  setTicketFormVisible,
  onSuccess,
  onCancel,
  runId,
  title,
  description,
}) => {
  const [ticketTitle, setTicketTitle] = useState(title);
  const [ticketProject, setTicketProject] = useState('');
  const [ticketProjectId, setTicketProjectId] = useState('');
  const [ticketAssignee, setTicketAssignee] = useState('');
  const [ticketAssigneeId, setTicketAssigneeId] = useState('');
  const [ticketDescription, setTicketDescription] = useState(description);
  const [isTicketValid, setIsTicketValid] = useState(false);
  const [isTicketCreated, setIsTicketCreated] = useState(false);

  const {
    ticketUsers,
    ticketCollections,
    ticketCreator,
    ticketUrl,
    errorDetails,
    getTicketCollections,
    getTicketUsers,
    createTicket,
    isTicketCreating,
    isLoadingCreateTicketFormData,
  } = useManageTickets(onSuccess);

  const handleCreateTicket = () => {
    createTicket({
      variables: {
        title: ticketTitle,
        ticketType: 'incident',
        ticketContext: 'bolt',
        ticketContextId: runId?.toString() || '',
        creator: ticketCreator?.id || '',
        collection: ticketProjectId,
        assignees: [ticketAssigneeId],
        description: ticketDescription,
      },
    });
  };

  useEffect(() => {
    getTicketUsers();
    getTicketCollections();
  }, []);

  useEffect(() => {
    if (ticketUrl) {
      setViewTicketUrl(ticketUrl);
      setIsTicketCreated(true);
    }
  }, [ticketUrl]);

  useEffect(() => {
    if (
      runId
      && ticketTitle.length > 0
      && ticketProject.length > 0
      && ticketAssignee.length > 0
      && ticketDescription.length > 0
      && ticketCreator
    ) {
      setIsTicketValid(true);
    }
  }, [
    ticketTitle, ticketProject, ticketAssignee, ticketDescription, runId,
  ]);

  if (isLoadingCreateTicketFormData || !runId || !ticketTitle || !ticketDescription) {
    return (<Spinner width={32} height={32} thin />);
  }

  return (
    <AutoLayout
      direction="vertical"
      distribution="packed"
      verticalGap="dense"
      padding="none"
      alignment="top-left"
      style={{ paddingTop: '32px', paddingBottom: '64px' }}
    >
      <AutoLayout
        direction="vertical"
        distribution="packed"
        verticalGap="very-dense"
        alignment="top-left"
        padding="none"
      >
        <Typography tagName="span" type="caption">
          Required fields are marked with *
        </Typography>
        <DefaultInput
          autoFocus
          color="default"
          value={ticketTitle}
          disabled={isTicketCreated}
          dense
          full
          label="Ticket name *"
          placeholder="Enter the ticket name"
          type="text"
          view="outlined"
          dataName="ticketName"
          errorHelperText="Schedule name already exists"
          expose={(e) => setTicketTitle(e.target.value.trim())}
        />
        <Dropdown
          view="outlined"
          text={ticketProject}
          label="Project *"
          disabled={isTicketCreated}
          content={(
            <MenuTemplate
              dense
              items={
                ticketCollections?.map((collection) => ({
                  text: collection.name || '',
                  type: 'item',
                  onClick: () => {
                    setTicketProject(collection.name || '');
                    setTicketProjectId(collection.id || '');
                  },
                }))
              }
              eventContext={Contexts.BOLT}
            />
          )}
          fill
          color="default"
          dense
          height={300}
        />
        <Dropdown
          view="outlined"
          text={ticketAssignee}
          label="Assignee"
          disabled={isTicketCreated}
          content={(
            <MenuTemplate
              dense
              items={
                ticketUsers?.map((user) => ({
                  text: user.user || '',
                  type: 'item',
                  onClick: () => {
                    setTicketAssignee(user.user || '');
                    setTicketAssigneeId(user.id || '');
                  },
                }))
              }
              eventContext={Contexts.BOLT}
            />
          )}
          fill
          color="default"
          dense
          height={300}
        />
        <AutoLayout
          direction="vertical"
          padding="none"
          distribution="packed"
          verticalGap="ultra-dense"
          alignment="top-left"
        >
          <Typography tagName="span" type="caption" colorStep="50">
            Description *
          </Typography>
          {
            viewTicketUrl ? (
              <MultiLineInput
                view="outlined"
                color="default"
                label="Commands"
                placeholder="Add commands in separate lines"
                dense
                maxLines={50}
                minLines={3}
                disabled
                value={ticketDescription}
              />
            ) : (
              <MDEditor
                height={300}
                value={ticketDescription}
                preview="edit"
                onChange={(value) => setTicketDescription(value || '')}
                style={{ minHeight: '300px' }}
              />
            )
          }
        </AutoLayout>
      </AutoLayout>
      {
        errorDetails && (
          <ErrorCallout
            title="Error creating ticket"
            color="danger"
            errorDetails={{
              humanErrorMessage: 'There was an error creating the ticket. Please try again.',
              rawErrorMessage: errorDetails,
            }}
            eventContext={Contexts.BOLT}
          />
        )
      }
      {
        ticketUrl && (
          <Callout
            view="smooth"
            icon="tick-circle"
            title="Ticket created"
            color="success"
            dense
            content={(
              <Typography
                tagName="span"
                type="body-small"
                color="success"
              >
                Successfully created issue ticket for this run.
                <br />
                View the ticket at ⬇️
                {' '}
                <br />
                <a href={viewTicketUrl} target="_blank" rel="noreferrer">{ticketUrl}</a>
              </Typography>
            )}
          />
        )
      }
      <AutoLayout
        direction="horizontal"
        padding="none"
        distribution="space-between"
        style={{ alignItems: 'center' }}
      >
        <DefaultButton
          type="standard"
          view="outlined"
          color="default"
          dense
          disabled={isTicketCreating || isTicketCreated}
          text="Cancel"
          eventContext={Contexts.BOLT}
          eventObject="cancelAddNewTicket"
          eventAction={Actions.CLICKED}
          onClick={onCancel}
        />
        <DefaultButton
          type="standard"
          view="filled"
          color="primary"
          dense
          disabled={!isTicketValid}
          text={viewTicketUrl ? 'Done' : 'Create Ticket'}
          eventContext={Contexts.BOLT}
          eventObject={viewTicketUrl ? 'closeTicketForm' : 'createNewTicket'}
          eventAction={Actions.CLICKED}
          onClick={() => {
            if (viewTicketUrl) {
              setTicketFormVisible(false);
            } else {
              handleCreateTicket();
            }
          }}
          loading={isTicketCreating}
        />
      </AutoLayout>
    </AutoLayout>
  );
};

export default TicketForm;
