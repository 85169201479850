import React from 'react';
import { Loader } from '@paradime-io/pragma-ui-kit';
import PageTemplate from '../Utils/PageTemplate';
import ConnectWarehouse, { WareHouseModalSource } from '../../../Common/Warehouses';
import {
  devProdWarehouseOptions,
  useGetDevProdWarehousesButtons,
  WarehouseEnv,
} from '../../../Common/Warehouses/utils';
import { userAuthStore } from '../../../../stores';
import { userHasAccessLevelSet } from '../../../../utilis/PermissionsService';

const ChooseRepoProvider = () => {
  const { currentUser: { accessLevel } } = userAuthStore.getState();

  const bigButtons = useGetDevProdWarehousesButtons();

  if (!userHasAccessLevelSet(accessLevel)) return <Loader />;

  return (
    <PageTemplate>
      <ConnectWarehouse
        env={WarehouseEnv.DEV}
        bigButtons={bigButtons}
        warehouseOptions={devProdWarehouseOptions}
        source={WareHouseModalSource.ONBOARDING}
        accessLevel={accessLevel}
      />
    </PageTemplate>
  );
};

export default ChooseRepoProvider;
