import React from 'react';
import { colorType } from '@paradime-io/pragma-ui-kit/lib/components/styles/styleTypes';
import { lineageNodeDisplayProps } from '../types';
import TableauLogo from '../../components/Lineage/images/Tableau';
import { LineageNodeTypeTranslations } from '.';
import { decodeLogoBase64, translateBEColourToFEColour } from '../utils';

const getNodeColor = (nodeType: string) => {
  switch (nodeType) {
    case 'TableauDatasourceNode':
      return 'warning_alt';
    case 'TableauWorksheetNode':
      return 'success_alt';
    case 'TableauDashboardNode':
      return 'primary';
    default:
      return 'primary';
  }
};

export const lineageNodeDisplay = ({
  nodeType,
  lineageData,
}: lineageNodeDisplayProps) => ({
  nodePrimaryLogo: decodeLogoBase64(lineageData?.logoBase64) || <TableauLogo />,
  nodeColor: translateBEColourToFEColour(lineageData?.color).variantName as colorType
    || getNodeColor(nodeType) as colorType,
  nodeTypeName: LineageNodeTypeTranslations[nodeType as keyof typeof LineageNodeTypeTranslations],
});
