import React, {
  FC,
  FormEvent,
  useEffect,
  useState,
} from 'react';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Callout from '@paradime-io/pragma-ui-kit/lib/components/Callout';
import { BillingFrequencyOptions } from '@paradime-io/pragma-ui-kit/lib/components/PricingPlan';
import SubscriptionOverview, { SubscriptionOverviewItem } from '@paradime-io/pragma-ui-kit/lib/components/SubscriptionOverview';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { PurchaseSummaryType } from './PurchaseSummary';
import BillingOptions, { BillingOptionsProps } from './BillingOptions';
import { PricingProduct } from '../../../Platform/Plans/hooks/usePricingPlans';
import { useUpcomingInvoicePlanChangeLazyQuery } from '../../../../client/generated/service';
import { formatToAccountingCurrency } from '../../../../utilis';

export interface CheckoutFormProps extends Omit<BillingOptionsProps, 'isAnnual'> {
  redirectUrl: string,
  purchaseSummary?: PurchaseSummaryType[],
  selectedPlan?: PricingProduct,
}

interface SavedInvoice {
  invoiceItemsList: SubscriptionOverviewItem[],
  grandTotal: number,
}

interface SavedInvoices {
  [BillingFrequencyOptions.MONTHLY]?: SavedInvoice,
  [BillingFrequencyOptions.YEARLY]?: SavedInvoice,
}

const CheckoutForm: FC<CheckoutFormProps> = ({
  redirectUrl,
  prices,
  numberOfUsers,
  selectedPlan,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPriceId, setSelectedPriceId] = useState(prices[BillingFrequencyOptions.YEARLY]?.id || 'unknown');
  const [isBilledAnnually, setIsBilledAnnually] = useState(true);
  const [invoiceItemsList, setInvoiceItemsList] = useState<SubscriptionOverviewItem[]>([]);
  const [grandTotal, setGrandTotal] = useState<number>(-1);
  const [savedInvoices, setSavedInvoices] = useState<SavedInvoices>();

  // eslint-disable-next-line camelcase
  const return_url = `${redirectUrl}?plan_name=${selectedPlan?.name}&price_id=${selectedPriceId}`;

  const billingFrequencyKey = isBilledAnnually
    ? BillingFrequencyOptions.YEARLY
    : BillingFrequencyOptions.MONTHLY;

  const [
    getUpcomingInvoicePlanChange, { loading: isLoadingInvoiceData },
  ] = useUpcomingInvoicePlanChangeLazyQuery({
    onCompleted: (data) => {
      if (data?.upcomingInvoicePlanChange?.ok) {
        const { upcomingInvoicePlanChangeData } = data.upcomingInvoicePlanChange;

        const unitLabel = upcomingInvoicePlanChangeData?.unitLabel;

        const invoiceLinesData = upcomingInvoicePlanChangeData?.invoiceLinesData;
        if ((invoiceLinesData || []).length > 0) {
          const lines = invoiceLinesData!.map((line) => {
            const description = line?.description || '';
            const quantity = line?.quantity || 1;
            const recurringInterval = line?.interval;
            const isAnnualRecurrence = recurringInterval === 'year';
            const amount = (line?.amount !== null && line?.amount !== undefined)
              ? line.amount / 100
              : -1;
            const amountPerUser = amount / quantity;

            return {
              description,
              quantity,
              recurringInterval,
              isAnnualRecurrence,
              amount,
              amountPerUser,
            };
          });

          const invoiceItems = lines.map((line) => ({
            type: 'text' as const,
            title: line.description,
            subtitle: `Qty ${line.quantity}, Billed ${line.isAnnualRecurrence ? 'yearly' : 'monthly'}`,
            price: formatToAccountingCurrency(line.amount, 'USD'),
            priceSubtitle: `${formatToAccountingCurrency(line.amountPerUser, 'USD')} ${unitLabel ? `per ${unitLabel}` : ''}/${line.recurringInterval}`,
          }));

          const overallTotal = (upcomingInvoicePlanChangeData?.amountDue !== null
            && upcomingInvoicePlanChangeData?.amountDue !== undefined)
            ? upcomingInvoicePlanChangeData.amountDue / 100
            : -1;
          setGrandTotal(overallTotal);

          const itemList = [
            ...invoiceItems,
            { type: 'divider' as const },
            {
              type: 'text' as const,
              title: 'Total due today',
              price: formatToAccountingCurrency(overallTotal, 'USD'),
            },
          ];

          setInvoiceItemsList(itemList);

          setSavedInvoices((prev) => ({
            ...prev,
            [billingFrequencyKey]: { invoiceItemsList: itemList, grandTotal: overallTotal },
          }));
        }
      }
    },
  });

  useEffect(() => {
    if (Object.keys(savedInvoices?.[billingFrequencyKey] || {}).length > 0) {
      setInvoiceItemsList(savedInvoices![billingFrequencyKey]!.invoiceItemsList);
      setGrandTotal(savedInvoices![billingFrequencyKey]!.grandTotal);
    } else {
      getUpcomingInvoicePlanChange({
        variables: {
          newPriceId: selectedPriceId,
        },
      });
    }
  }, [selectedPriceId]);

  const handleSubmit = async (e: FormEvent<HTMLElement>) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
    } else {
      setMessage('An unexpected error occurred.');
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: 'tabs' as const,
    paymentMethodOrder: ['card', 'apple_pay', 'google_pay'],
  };

  return (
    <AutoLayout
      direction="vertical"
      distribution="space-between"
      padding="none"
      verticalGap="none"
      style={{
        gridTemplateRows: `auto ${message ? '98px' : ''} 40px`,
        alignItems: 'start',
      }}
    >
      <AutoLayout
        direction="vertical"
        distribution="packed"
        padding="none"
        verticalGap="normal"
        alignment="top"
      >
        <BillingOptions
          prices={prices}
          numberOfUsers={numberOfUsers}
          onChange={(isAnnual, priceId) => {
            setIsBilledAnnually(isAnnual);
            setSelectedPriceId(priceId);
          }}
          isAnnual={isBilledAnnually}
          isPricedPerUser={selectedPlan?.pricedPerUser}
        />
        <SubscriptionOverview
          totalPrice={`${formatToAccountingCurrency(grandTotal, 'USD')} per ${isBilledAnnually ? 'year' : 'month'}`}
          isCollapsible
          startClosed
          entries={invoiceItemsList}
          isLoading={isLoadingInvoiceData}
        />
        <PaymentElement id="payment-element" options={paymentElementOptions} />
      </AutoLayout>
      <Callout
        title="Invalid information"
        icon="info-sign"
        content={message}
        color="danger"
        view="smooth"
        dense
        isVisible={!!message}
        maxHeight="40px"
      />
      <AutoLayout
        direction="vertical"
        padding="none"
        verticalGap="ultra-dense"
        distribution="packed"
      >
        <DefaultButton
          type="standard"
          view="filled"
          color="primary"
          disabled={!stripe || !elements}
          loading={isLoading}
          text="Upgrade"
          onClick={handleSubmit}
          eventContext={Contexts.APP}
          eventObject="confirmAndUpgrade"
          eventAction={Actions.CLICKED}
        />
        <Typography font="inter" type="caption" color="default" colorStep="50">
          By continuing, you agree to the
          {' '}
          <a
            href="https://paradime.io/terms-of-service"
            target="_blank"
            rel="noreferrer"
            style={{
              textDecoration: 'underline',
            }}
          >
            Paradime Terms and Conditions.
          </a>
        </Typography>
      </AutoLayout>
    </AutoLayout>
  );
};

export default CheckoutForm;
