import React from 'react';
import { lineageSearchInputProps } from '../types';
import SearchMenuLabel from '../../components/Lineage/HomePage/SearchMenuLabel';
import TableauLogo from '../../components/Lineage/HomePage/images/TableauLogo.svg';
import { LineageNodeTypeTranslations } from '.';

export const lineageSearchInputDropdown = ({
  nodeType,
}: lineageSearchInputProps) => {
  const labelElement = (
    <SearchMenuLabel
      text={LineageNodeTypeTranslations[nodeType as keyof typeof LineageNodeTypeTranslations]}
      color="var(--orange50)"
    />
  );

  return {
    img: TableauLogo,
    labelElement,
  };
};
