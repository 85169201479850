import { GraphicTypes } from '@paradime-io/pragma-ui-kit/lib/components/List';
import { formatDateTimeToLocalString } from '../../utilis';
import { catalogueDetailsCardProps, catalogueDetailsCardReturnType } from '../types';
import {
  checkIfArrayIsEmpty,
} from '../../components/Catalog/SearchRoutes/ResultTransformations';

export const catalogueDetailsCard = ({
  resultItem,
}: catalogueDetailsCardProps): catalogueDetailsCardReturnType => {
  switch (resultItem.__typename) {
    case 'GQLCatalogueTableauWorksheetItem':
      return {
        components: [
          [
            {
              type: 'textWithHeader',
              header: 'type',
              text: 'Worksheet',
              icon: { iconName: 'pie-chart', color: 'var(--violet70)' },
            },
          ],
          [
            {
              type: 'textWithHeader',
              header: 'created at',
              text: formatDateTimeToLocalString({ dateTimeString: resultItem.worksheetCreatedAt }),
            },
            {
              type: 'textWithHeader',
              header: 'updated at',
              text: formatDateTimeToLocalString({ dateTimeString: resultItem.worksheetUpdatedAt }),
            },
          ],
          [
            {
              type: 'textWithHeader',
              header: 'project name',
              text: resultItem.workbook.projectName || '-',
            },
            {
              type: 'textWithHeader',
              header: 'site',
              text: resultItem.workbook?.siteName || '-',
            },
          ],
          [
            {
              type: 'textWithHeader',
              header: 'workbook',
              text: resultItem.workbook.name || '-',
            },
            {
              type: 'list',
              title: 'workbook owner',
              subtitle: resultItem.workbook.ownerName || '-',
              graphic: {
                type: 'image' as GraphicTypes.IMAGE,
                image: /* resultItem.fsOwnerPicture && */ `https://via.placeholder.com/32/ededf0/?text=${resultItem.workbook.ownerName.slice(0, 1) || 'X'}`,
              },
            },
          ],
          [
            {
              type: 'tags',
              header: 'tags',
              tags: checkIfArrayIsEmpty({ attribute: resultItem.tags }),
            },
          ],
        ],
      };
    case 'GQLCatalogueTableauDashboardItem':
      return {
        components: [
          [
            {
              type: 'textWithHeader',
              header: 'type',
              text: 'Dashboard',
              icon: { iconName: 'control', color: 'var(--violet70)' },
            },
          ],
          [
            {
              type: 'textWithHeader',
              header: 'created at',
              text: formatDateTimeToLocalString({ dateTimeString: resultItem.dashboardCreatedAt }),
            },
            {
              type: 'textWithHeader',
              header: 'updated at',
              text: formatDateTimeToLocalString({ dateTimeString: resultItem.dashboardUpdatedAt }),
            },
          ],
          [
            {
              type: 'textWithHeader',
              header: 'project name',
              text: resultItem.workbook.projectName || '-',
            },
            {
              type: 'textWithHeader',
              header: 'site',
              text: resultItem.workbook?.siteName || '-',
            },
          ],
          [
            {
              type: 'textWithHeader',
              header: 'workbook',
              text: resultItem.workbook.name || '-',
            },
            {
              type: 'list',
              title: 'workbook owner',
              subtitle: resultItem.workbook.ownerName || '-',
              graphic: {
                type: 'image' as GraphicTypes.IMAGE,
                image: /* resultItem.fsOwnerPicture && */ `https://via.placeholder.com/32/ededf0/?text=${resultItem.workbook.ownerName.slice(0, 1) || 'X'}`,
              },
            },
          ],
          [
            {
              type: 'tags',
              header: 'tags',
              tags: checkIfArrayIsEmpty({ attribute: resultItem.tags }),
            },
          ],
        ],
      };
    case 'GQLCatalogueTableauDatasourceItem':
      return {
        components: [
          [
            {
              type: 'textWithHeader',
              header: 'type',
              text: 'Data Source',
              icon: { iconName: 'compass', color: 'var(--violet70)' },
            },
          ],
          [
            {
              type: 'textWithHeader',
              header: 'created at',
              text: formatDateTimeToLocalString({ dateTimeString: resultItem.datasourceCreatedAt }),
            },
            {
              type: 'textWithHeader',
              header: 'updated at',
              text: formatDateTimeToLocalString({ dateTimeString: resultItem.datasourceUpdatedAt }),
            },
          ],
          [
            {
              type: 'chips',
              header: 'Has Extracts',
              chips: [{
                tag: resultItem?.hasExtracts?.toString() || '-',
                color: 'default',
                label: '',
              }],
            },
            {
              type: 'textWithHeader',
              header: 'Extract last updated at',
              text: formatDateTimeToLocalString({
                dateTimeString: resultItem.extractLastUpdateTime,
              }),
            },
          ],
          [
            {
              type: 'chips',
              header: 'Contains custom SQL',
              chips: [{
                tag: resultItem?.hasCustomSQL?.toString() || '-',
                color: 'default',
                label: '',
              }],
            },
            {
              type: 'textWithHeader',
              header: 'site',
              text: resultItem.downstreamWorkbooks[0]?.siteName || '-',
            },
          ],
        ],
      };
    default:
      return { components: [] };
  }
};
