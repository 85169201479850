import { catalogueDescriptionProps } from '../types';

export const catalogueDescription = ({
  resultItem,
}: catalogueDescriptionProps) => {
  switch (resultItem.__typename) {
    case 'GQLCatalogueLookerDashboardItem':
    case 'GQLCatalogueLookerExploreItem':
    case 'GQLCatalogueLookerLookItem':
      return resultItem.description || '';
    default:
      return '';
  }
};
