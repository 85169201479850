/* eslint-disable no-underscore-dangle */
import React, { FC } from 'react';
import CatalogTableCard from '@paradime-io/pragma-ui-kit/lib/components/CatalogTableCard';
import { Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { ColumnHeader, DataRow } from '@paradime-io/pragma-ui-kit/lib/components/Table/Table';
import Card from '@paradime-io/pragma-ui-kit/lib/components/Card';
import CatalogCardHeader from '@paradime-io/pragma-ui-kit/lib/components/CatalogCardHeader';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import { Typography } from '@paradime-io/pragma-ui-kit';
import { ResultWithCommit } from '..';
import { integrationHandlerManager } from '../../../../../integrations';

export interface FieldsProps {
  datum: ResultWithCommit,
  link: string,
  cardTitle: string,
  assetType: string,
  cardType: string,
}

// Table gets unhappy if passed a null value
export const replaceNullWithEmptyString = (value: null | string | undefined) => (
  (value === null) || (value === undefined) ? '' : value
);

export const replaceBooleanWithString = (value?: boolean | null) => {
  if (value === true) return 'TRUE';
  if (value === false) return 'FALSE';
  return '-';
};

const Fields: FC<FieldsProps> = ({
  datum,
  link,
  cardTitle,
  assetType,
  cardType,
}) => {
  let columnHeadings: ColumnHeader[] = [];
  let data: DataRow[] = [];

  const chosenHandler = integrationHandlerManager(datum.__typename);
  if (chosenHandler) {
    const {
      columnHeadings: headingsFromHandler,
      data: dataFromHandler,
    } = chosenHandler.handlers.catalogueFieldsTab({
      resultItem: datum,
    });

    columnHeadings = headingsFromHandler;
    data = dataFromHandler;
  }

  if (data.length === 0) {
    return (
      <Card tabIndex={-1}>
        <AutoLayout
          direction="vertical"
          distribution="packed"
          padding="none"
          verticalGap="very-dense"
        >
          <CatalogCardHeader
            label={cardTitle}
            link={link}
            editMode={false}
            isEditing={false}
            onEdit={() => undefined}
            onCancel={() => undefined}
            loading={false}
            disabled={false}
            eventContext={Contexts.CATALOG}
          />
          <Typography
            font="inter"
            type="body-small"
            color="default"
            colorStep="100"
          >
            {`This ${assetType} does not have any ${cardType}`}
          </Typography>
        </AutoLayout>
      </Card>
    );
  }

  return (
    <CatalogTableCard
      tabIndex={-1}
      label={cardTitle}
      link={link}
      editMode={false}
      orderByColumn={{ id: 'type', order: 'desc' }}
      columnHeader={columnHeadings}
      data={data}
      classificationTagInitialOptions={[]}
      eventContext={Contexts.CATALOG}
    />
  );
};

export default Fields;
