import { useEffect, useState } from 'react';
import { useGetKnockUserLazyQuery, KnockUser } from '../../../../client/generated/service';
import { userAuthStore } from '../../../../stores';

export type KnockUserType = Omit<KnockUser, '__typename'>;

const useGetKnockUser = () => {
  const [knockUser, setKnockUser] = useState<KnockUserType>();

  const { currentUser: { accessLevel } } = userAuthStore.getState();

  const [getKnockUser, { loading: loadingKnockUser }] = useGetKnockUserLazyQuery({
    onCompleted: (data) => {
      if (data.getKnockUser) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { __typename, ...restData } = data.getKnockUser;
        setKnockUser(restData);
      }
    },
  });

  useEffect(() => {
    getKnockUser();
  }, [accessLevel]);

  return {
    loadingKnockUser,
    knockUser,
  };
};

export { useGetKnockUser };
