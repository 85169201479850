import { useEffect } from 'react';
import { companyStore, userAuthStore } from '../stores';
import { GetWarehouseConnectionStatusesQuery, useGetWarehouseConnectionStatusesLazyQuery } from '../client/generated/service';

const useGetWarehouseConnectionStatus = () => {
  const {
    setIsDevWarehouseConnected,
    setIsProdWarehouseConnected,
    setIsCostWarehouseConnected,
    setDevWarehouseType,
    setProdWarehouseType,
    setCostWarehouseType,
  } = companyStore.getState();

  const { currentUser: { accessLevel } } = userAuthStore.getState();

  const getDevWarehouseDetails = (data: GetWarehouseConnectionStatusesQuery) => {
    if (data.getOrganisationDbConfigs.ok) {
      const completeDefaultDevWarehouse = data.getOrganisationDbConfigs.configurations.filter(
        (dev) => dev.isDefault,
      );
      const incompleteDefaultDevWarehouse = data.getOrganisationDbConfigs.incomplete.filter(
        (dev) => dev.isDefault,
      );
      const isDevConnected = completeDefaultDevWarehouse.length > 0;
      setIsDevWarehouseConnected(isDevConnected);

      const defaultDevWarehouseType = isDevConnected
        ? completeDefaultDevWarehouse[0].configuration?.databaseType
        : incompleteDefaultDevWarehouse[0].configuration?.databaseType;
      if (defaultDevWarehouseType) {
        setDevWarehouseType(defaultDevWarehouseType);
      }
    }
  };

  const getProdWarehouseDetails = (data: GetWarehouseConnectionStatusesQuery) => {
    if (data.getDwhProductionConfigs.ok) {
      const isProdConnected = data.getDwhProductionConfigs.configurations.length > 0;
      setIsProdWarehouseConnected(isProdConnected);

      const defaultProdWarehouseType = data.getDwhProductionConfigs
        .configurations?.[0]?.configuration?.databaseType;
      if (defaultProdWarehouseType) {
        setProdWarehouseType(defaultProdWarehouseType);
      }
    }
  };

  const getCostWarehouseDetails = (data: GetWarehouseConnectionStatusesQuery) => {
    if (data.getDwhCostConfig.databaseType) {
      const isCostConnected = data.getDwhCostConfig.databaseType !== 'unknown';
      setIsCostWarehouseConnected(isCostConnected);

      if (isCostConnected) {
        setCostWarehouseType(data.getDwhCostConfig.databaseType);
      }
    }
  };

  const [getWarehouseConnectionStatuses] = useGetWarehouseConnectionStatusesLazyQuery({
    onCompleted: (data) => {
      getDevWarehouseDetails(data);
      getProdWarehouseDetails(data);
      getCostWarehouseDetails(data);
    },
  });

  useEffect(() => {
    getWarehouseConnectionStatuses();
  }, [accessLevel]);
};

export default useGetWarehouseConnectionStatus;
