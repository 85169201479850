import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import LineageControlButton from '@paradime-io/pragma-ui-kit/lib/components/LineageControlButton';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import React, {
  FC,
  useEffect,
  useRef,
  useState,
  useMemo,
  SetStateAction,
  Dispatch,
} from 'react';
import { Chips, DefaultButton, Typography } from '@paradime-io/pragma-ui-kit';
import { useHistory, useParams } from 'react-router-dom';
import {
  GetGitBranchInfoQuery,
  GqlParadimeAccountType,
} from '../../../client/generated/service';
import Search from './Search';
import Compare from './Compare';
import { LineageHelp } from '../../Common/AppHelp/help';
import emptyComparisonImage from './images/emptyComparison.svg';
import { lineageParams } from '../hooks';
import { userHasLineageCompareAccess, userHasLineageSearchAccess } from '../../../utilis/PermissionsService';

type currentOptions = 'none' | 'search' | 'compare';

export interface HomepageProps {
  initialTab: currentOptions,
  branchesData: GetGitBranchInfoQuery,
  currentBranch: string,
  setCurrentBranch: (newBranch: string) => void, // eslint-disable-line
  accessLevel: GqlParadimeAccountType,
  isOpenedFromCodeEditor?: boolean,
  isEmptyComparison?: boolean,
  setIsEmptyComparison?: Dispatch<SetStateAction<boolean>>
}

const Homepage: FC<HomepageProps> = ({
  initialTab,
  branchesData,
  setCurrentBranch,
  currentBranch,
  accessLevel,
  isOpenedFromCodeEditor,
  isEmptyComparison,
  setIsEmptyComparison,
}) => {
  const [selection, setSelection] = useState<currentOptions>(isEmptyComparison ? 'compare' : initialTab);
  const parentRef = useRef<HTMLDivElement>(null);
  const [branches, setBranches] = useState<Map<string, string>>();

  const history = useHistory();

  useEffect(() => {
    if (branchesData?.getGitBranchInfo?.ok && branchesData?.getGitBranchInfo?.branches) {
      const mapCurrentBranches = branchesData
        .getGitBranchInfo
        .branches
        .map((branch) => [branch.name, branch.commitHash] as const);

      setBranches(new Map(mapCurrentBranches));
    }
  }, [branchesData]);

  const params = useParams<lineageParams>();

  const [leftBranch, rightBranch] = useMemo(() => {
    if (!branchesData?.getGitBranchInfo?.branches) return ['', ''];

    const { branches: b } = branchesData?.getGitBranchInfo;
    const rb = b.find((branch) => branch.commitHash === params.node2Hash)?.name || '';
    const lb = b.find((branch) => branch.commitHash === params.node1Hash)?.name || '';

    return [
      lb, rb,
    ];
  }, [branchesData, params]);

  if (rightBranch) { setCurrentBranch(rightBranch); }

  const buttonTypeANDaction = (name: currentOptions) => {
    if (selection === name) {
      return {
        action: () => setSelection('none'),
        buttonType: 'default' as 'simple' | 'default' | 'raised',
        text: name,
      };
    }
    return {
      action: () => {
        setSelection(name);
      },
      buttonType: 'default' as 'simple' | 'default' | 'raised',
    };
  };

  return !accessLevel
    ? null
    : (
      <AutoLayout
        padding="none"
        direction="vertical"
        verticalGap="ultra-dense"
        style={{
          width: 663,
          height: 53,
          position: 'absolute',
          left: '50%',
          top: isEmptyComparison ? '15%' : '25%',
          transform: 'translate(-50%,-25%)',
        }}
      >
        <Chips
          color="primary_alt"
          round={false}
          style={{
            margin: '4px', verticalAlign: 'bottom', justifySelf: 'end',
          }}
          tag={(selection === 'compare') ? 'How to compare lineage?' : 'How to search lineage?'}
          type="dense"
          view="smooth"
          onClick={() => ((selection === 'compare')
            ? window.CommandBar.openHelpHub({ articleId: LineageHelp.COMPARE_LINEAGE })
            : window.CommandBar.openHelpHub({ articleId: LineageHelp.SEARCH_LINEAGE })
          )}
        />
        {isEmptyComparison && (
          <AutoLayout
            padding="none"
            direction="vertical"
          >
            <img
              src={emptyComparisonImage}
              alt="empty comparison"
              style={{ margin: '0 auto' }}
            />
            <div>
              <Typography
                colorStep="100"
                color="default"
                type="body-bold-small"
                style={{ textAlign: 'center' }}
              >
                <Typography type="body-bold-small" color="default" colorStep="100" tagName="span">
                  There isn&apos;t anything to compare
                </Typography>
                ,
                <Typography color="primary" colorStep="70" tagName="span" type="body-small">
                  &nbsp;
                  {leftBranch}
                  &nbsp;
                </Typography>
                and
                <Typography color="primary" colorStep="70" tagName="span" type="body-small">
                  &nbsp;
                  {rightBranch}
                  &nbsp;
                </Typography>
                <Typography type="body-bold-small" color="default" colorStep="100" tagName="span">
                  are identical.
                </Typography>
              </Typography>
              <Typography
                type="body-small"
                style={{ textAlign: 'center' }}
              >
                Try select a different branch names to to see what’s changed,
                alternatively search the lineage for a specific dbt model or BI asset.
              </Typography>
            </div>
          </AutoLayout>
        )}
        <div
          data-testid="homeControl"
          ref={parentRef}
          style={{
            background: 'var(--white)',
            boxShadow: 'var(--greyShadow2dp)',
            borderRadius: 8,
          }}
        >
          <AutoLayout
            direction="horizontal"
            padding="very-dense"
            style={{
              paddingRight: '16px',
              alignItems: 'center',
            }}
            distribution="space-between"
            alignment="left"
          >
            {['search', 'compare', 'none'].includes(selection)
              && (
                <LineageControlButton
                  text="Search"
                  icon="search"
                  selected={selection === 'search'}
                  {...buttonTypeANDaction('search')}
                  eventContext={Contexts.LINEAGE}
                  eventObject="searchControlButton"
                  eventAction={Actions.SEARCHED}
                />
              )}
            {
              userHasLineageCompareAccess(accessLevel)
              && ['compare', 'search', 'none'].includes(selection)
              && (
                <LineageControlButton
                  text="Compare"
                  icon="changes"
                  selected={selection === 'compare'}
                  {...buttonTypeANDaction('compare')}
                  eventContext={Contexts.LINEAGE}
                  eventObject="compareControlButton"
                  eventAction={Actions.COMPARED}
                />
              )
            }
            <AutoLayout
              direction="horizontal"
              padding="none"
            >
              {['search', 'none'].includes(selection) && (
                <Search
                  initialBranch={branchesData.getGitBranchInfo?.remoteMainBranch?.name!}
                  branches={branches!}
                  position="bottom"
                  inputWidth={userHasLineageSearchAccess(accessLevel)
                    ? 341
                    : 551}
                  dropdownWidth={122}
                  dropDownContentPosition="bottom"
                  currentBranch={currentBranch}
                  setCurrentBranch={setCurrentBranch}
                  accessLevel={accessLevel}
                />
              )}
              {selection === 'compare'
                && branchesData.getGitBranchInfo?.remoteMainBranch?.name
                && branches
                && (
                  <Compare
                    initialLeftBranch={
                      leftBranch || branchesData.getGitBranchInfo.remoteMainBranch.name
                    }
                    branches={branches}
                    initialRightBranch={branchesData.getGitBranchInfo?.branches![0].name || ''}
                    dropDownWidth={221}
                    dropDownContentPosition="bottom"
                    setCurrentBranch={setCurrentBranch}
                    setIsEmptyComparison={setIsEmptyComparison}
                  />
                )}
            </AutoLayout>
          </AutoLayout>
        </div>
        {isOpenedFromCodeEditor && (
          <DefaultButton
            text="Back to the Editor"
            eventAction={Actions.CLICKED}
            eventContext={Contexts.LINEAGE}
            eventObject="backToEditorButton"
            onClick={() => history.goBack()}
            color="primary"
            style={{
              width: 187,
              margin: '0 auto',
            }}
          />
        )}
      </AutoLayout>

    );
};

export default Homepage;
