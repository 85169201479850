import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useLineageChangeListener = (
  resetAllFilters: Function,
) => {
  const location = useLocation();

  useEffect(() => {
    resetAllFilters();
  }, [location]);
};

export default useLineageChangeListener;
