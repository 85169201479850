import { useEffect } from 'react';
import { lineageStore } from '../../../stores';

const useResetGraph = (
  resetFilters: Function,
) => {
  const resetGraph = () => {
    const {
      initialEdges, initialNodes, setLineageNodes, setLineageEdges,
    } = lineageStore.getState();
    setLineageNodes(initialNodes);
    setLineageEdges(initialEdges);
  };

  const completeReset = () => {
    resetGraph();
    resetFilters();
  };

  useEffect(() => {
    const resetToInitialState = (e: MessageEvent) => {
      const { data: { type } } = e;
      if (type === 'reset-graph') {
        completeReset();
      }
    };
    window.addEventListener('message', resetToInitialState);

    return () => {
      window.removeEventListener('message', resetToInitialState, false);
    };
  }, []);

  const reset = () => window.postMessage({ type: 'reset-graph' }, '*');

  return {
    reset,
  };
};

export default useResetGraph;
