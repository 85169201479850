import React, { FC } from 'react';
import * as S from './MiniMap.styles';

export interface MiniMapProps {
  position?: 'bottom-center' | 'bottom-right',
}

const translateNodeColour = (colourString: string) => {
  switch (colourString) {
    case 'dbt_blue':
    case 'dbt_green':
    case 'dbt_orange':
      return `var(--${colourString.replace('_', '-')}60)`;
    case 'primary':
      return 'var(--violet60)';
    case 'warning_alt':
      return 'var(--orange50)';
    case 'success_alt':
      return 'var(--teal50)';
    case 'danger_alt':
      return 'var(--pink50)';
    case 'success':
    default:
      return 'var(--green50)';
  }
};

const MiniMap: FC<MiniMapProps> = ({
  position = 'bottom-right',
}) => (
  <div style={{
    position: 'absolute',
    right: position === 'bottom-right' ? 50 : '50%',
    bottom: 14,
    transform: position === 'bottom-center' ? 'translateX(50%)' : undefined,
    zIndex: 1000,
  }}
  >
    <S.MiniMap
      nodeColor={(node) => translateNodeColour(node.data.nodeColor)}
      zoomable
      pannable
    />
  </div>
);

export default MiniMap;
