import { useEffect, useState } from 'react';
import { DropdownOption } from './NotificationDropdown';
import { useGetSlackChannelsAndUsersLazyQuery } from '../../../client/generated/service-dataEndpoint';
import { useGetMsTeamsWebhooksLazyQuery } from '../../../client/generated/service';
import { ConnectedNotificatonIntegration } from '.';

export interface useGetIntegrationsChannelListProps {
  connectedIntegration: ConnectedNotificatonIntegration,
}

export const useGetIntegrationsChannelList = ({
  connectedIntegration,
}: useGetIntegrationsChannelListProps) => {
  const [
    integrationDropdownOptions, setIntegrationDropdownOptions,
  ] = useState<DropdownOption[]>([]);

  const [
    getSlackChannels,
    { loading: isLoadingSlackChannels },
  ] = useGetSlackChannelsAndUsersLazyQuery({
    onCompleted: (data) => {
      const dropdownOptions: DropdownOption[] = [];
      if (data.getSlackChannels.channels) {
        const formattedData = data.getSlackChannels.channels.map(({ name }) => ({
          label: `#${name}`,
          value: name,
        }));
        dropdownOptions.push(...formattedData);
      }
      if (data.getSlackUsers.users) {
        const formattedData = data.getSlackUsers.users.map(({ name }) => ({
          label: `@${name}`,
          value: name,
        }));
        dropdownOptions.push(...formattedData);
      }

      setIntegrationDropdownOptions(dropdownOptions);
    },
  });

  const [getTeamsChannels, { loading: isLoadingTeamsChannels }] = useGetMsTeamsWebhooksLazyQuery({
    onCompleted: (data) => {
      const formattedData = (data.getMsTeamsWebhooks?.webhookNames || []).map((channelName) => ({
        label: channelName,
        value: channelName,
      }));
      setIntegrationDropdownOptions(formattedData);
    },
  });

  useEffect(() => {
    switch (connectedIntegration) {
      case ConnectedNotificatonIntegration.SLACK:
        getSlackChannels();
        break;
      case ConnectedNotificatonIntegration.TEAMS:
        getTeamsChannels();
        break;
      default:
        break;
    }
  }, [connectedIntegration]);

  const refreshChannelsList = () => {
    switch (connectedIntegration) {
      case ConnectedNotificatonIntegration.SLACK:
        getSlackChannels({ variables: { refresh: true } });
        break;
      case ConnectedNotificatonIntegration.TEAMS:
        getTeamsChannels();
        break;
      default:
        break;
    }
  };

  return {
    channelsList: integrationDropdownOptions
      .sort((a, b) => {
        if (a.label === b.label) return 0;
        return a.label < b.label ? -1 : 1;
      }),
    isLoadingChannelsList: isLoadingSlackChannels || isLoadingTeamsChannels,
    refreshChannelsList,
  };
};
