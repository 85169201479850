import React, {
  FC, useState, useRef,
} from 'react';
import {
  KnockProvider,
  KnockFeedProvider,
  NotificationIconButton,
  NotificationFeedPopover,
} from '@knocklabs/react';
import { FeedStoreState, Feed } from '@knocklabs/client';
import '@knocklabs/react/dist/index.css';
import Spinner from '@paradime-io/pragma-ui-kit/lib/components/Spinner';
import { useGetKnockUser } from './useGetKnockUser';

interface KnockFeedProps {
  darkMode: boolean,
}

const KnockFeed:FC<KnockFeedProps> = ({ darkMode }) => {
  const { knockUser, loadingKnockUser } = useGetKnockUser();
  const [isVisible, setIsVisible] = useState(false);
  const notificationButtonRef = useRef(null);

  const onOpen = ({ store, feedClient }: { store: FeedStoreState, feedClient: Feed}) => {
    const unReadItems = store.items.filter((item) => !item.read_at);

    if (unReadItems.length > 0) {
      feedClient.markAsRead(unReadItems);
    }
  };

  if (loadingKnockUser || !knockUser) {
    return (<Spinner width={16} height={16} thin />);
  }

  return (
    <KnockProvider
      apiKey={knockUser.publicApiKey}
      userId={knockUser.userId}
      userToken={knockUser.userToken}
    >
      <KnockFeedProvider
        feedId={knockUser.channelId}
        colorMode={darkMode ? 'dark' : 'light'}
        defaultFeedOptions={{
          tenant: knockUser.tenantId,
        }}
      >
        <>
          <NotificationIconButton
            ref={notificationButtonRef}
            onClick={() => setIsVisible(!isVisible)}
            badgeCountType="unread"
          />
          <NotificationFeedPopover
            buttonRef={notificationButtonRef}
            isVisible={isVisible}
            onOpen={onOpen}
            onClose={() => setIsVisible(false)}
          />
        </>
      </KnockFeedProvider>
    </KnockProvider>
  );
};

export default KnockFeed;
