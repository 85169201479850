import React, { ChangeEvent, FC, useState } from 'react';
import { Icon, IconName } from '@blueprintjs/core';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import DefaultInput from '@paradime-io/pragma-ui-kit/lib/components/DefaultInput';
import { AppLocalStorageKeys } from '@paradime/common/lib/common/localStorageKeys';
import { ParadimeTheme } from '@paradime/common/lib/common/genericTypes';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';

export interface InputWithIconProps {
  icon: IconName,
  value: string,
  caption: string,
  onValueChange: (newVal: string) => void,
  onEnterPressed: () => void,
}

const InputWithIcon: FC<InputWithIconProps> = ({
  icon,
  value,
  caption,
  onValueChange,
  onEnterPressed,
}) => {
  const [errorText, setErrorText] = useState('');

  const showAllIndicator = '+';

  const darkMode = ((window.localStorage.getItem(AppLocalStorageKeys.THEME) as ParadimeTheme)
    || ParadimeTheme.LIGHT) === ParadimeTheme.DARK;

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setErrorText('');
    if (newValue === showAllIndicator) {
      onValueChange(showAllIndicator);
      return;
    }

    if (newValue === '+') {
      onValueChange('+');
      return;
    }

    // Annoying we can't use defaultInput built-in validation checks
    // because string !== number and the types are all over the place
    const parsedValue = Number(newValue);
    if (!Number.isNaN(parsedValue)) {
      onValueChange(newValue);
    } else {
      setErrorText('Invalid!');
    }
  };

  return (
    <AutoLayout
      direction="horizontal"
      padding="none"
      horizontalGap="ultra-dense"
      style={{ alignItems: 'start' }}
    >
      <Icon
        icon={icon}
        color={darkMode ? 'var(--grey40)' : 'var(--grey60)'}
        size={16}
        style={{ padding: '5px 0 5px 0' }}
      />
      <AutoLayout
        direction="vertical"
        padding="none"
        distribution="packed"
        verticalGap="ultra-dense"
      >
        <DefaultInput
          type="text"
          view="outlined"
          color="default"
          dense
          value={value}
          expose={handleInputChange}
          onKeyUp={(k) => {
            if (k.code === 'Enter') {
              onEnterPressed();
            }
          }}
          error={!!errorText}
          errorHelperText={errorText}
          style={{ maxWidth: '60px', height: '26px' }}
        />
        <Typography
          tagName="span"
          type="caption-small"
          color="dbt_blue"
          colorStep="30"
        >
          {caption}
        </Typography>
      </AutoLayout>
    </AutoLayout>
  );
};

export default InputWithIcon;
