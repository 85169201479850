/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-unused-expressions */
import React, { FC, ReactNode, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { ErrorBoundaryProps } from '@sentry/react/dist/errorboundary';
import Loader from '@paradime-io/pragma-ui-kit/lib/components/Loader';
import { checkNoCasesLeft } from '@paradime/common/lib/common/helpers';
import theme from '../theme';
import {
  companyStore, graphQLStore, userAuthStore,
} from '../stores';
import createClient from '../client';
import {
  CompanyIdentity,
  CompanyIdentityDocument,
  UserIdentity,
  UserIdentityDocument,
} from '../client/generated/control-plane';
import { GqlParadimeAccountType } from '../client/generated/service';
import Alerts from '../components/Common/Alerts';
import UnhandledError from '../components/Common/Error/UnhandledError';
import AppWithAuth0Provider from './AppWithAuth0Provider';
import useOrganisation from '../components/hooks/useOrganisation';
import AppWithApolloProvider from './AppWithApolloProvider';
import AppWithSwitch from './AppWithSwitch';

const SentryErrorBoundary = Sentry.ErrorBoundary as unknown as FC<ErrorBoundaryProps & {
  children?: ReactNode
}>;

const showFallbackErrorPage = () => <UnhandledError />;

interface CompanyArgsType {
  bigqueryCallbackUrl: string | null;
  controlPlaneUrl: string,
  dataGraphqlUrl: string,
  dbGraphqlUrl: string,
  editorGraphqlUrl: string,
  region: string;
  serviceGraphqlUrl: string,
  snowflakeCallbackUrl: string | null,
  token: string,
  dinoAiAgentStreamUrl: string,
}

const convertCompanyMetadataToArgs = (
  company?: CompanyIdentity | null, controlPlaneUrl?: string | null,
): CompanyArgsType | null => {
  const requiredFields = [
    'dataGraphqlUrl',
    'dbGraphqlUrl',
    'editorGraphqlUrl',
    'region',
    'serviceGraphqlUrl',
    'token',
  ];
  if (
    controlPlaneUrl
    && company
    // Check all of the keys are present, and they are not null
    && requiredFields.every((key) => (
      key in company && company[key as keyof CompanyIdentity] !== null
    ))
  ) {
    return {
      controlPlaneUrl,
      bigqueryCallbackUrl: company.bigqueryCallbackUrl || null,
      dataGraphqlUrl: company.dataGraphqlUrl!,
      dbGraphqlUrl: company.dbGraphqlUrl!,
      editorGraphqlUrl: company.editorGraphqlUrl!,
      region: company.region!,
      serviceGraphqlUrl: company.serviceGraphqlUrl!,
      snowflakeCallbackUrl: company.snowflakeCallbackUrl || null,
      token: company.token!,
      dinoAiAgentStreamUrl: company.dinoaiAgentStreamUrl || '',
    };
  }

  // Extension-only companies
  if (controlPlaneUrl && company?.token === 'magnetar') {
    return {
      controlPlaneUrl,
      bigqueryCallbackUrl: null,
      dataGraphqlUrl: '',
      dbGraphqlUrl: '',
      editorGraphqlUrl: '',
      region: '',
      serviceGraphqlUrl: '',
      snowflakeCallbackUrl: null,
      token: company.token,
      dinoAiAgentStreamUrl: '',
    };
  }

  return null;
};

const createControlPlaneClient = (controlPlaneUrl: string, jwtToken?: string) => new ApolloClient({
  uri: controlPlaneUrl,
  cache: new InMemoryCache(),
  headers: {
    authorization: jwtToken ? `Bearer ${jwtToken}` : '',
  },
});

enum State {
  UNKNOWN,
  WAITING_FOR_CLUSTER_CONFIG,
  WAITING_FOR_PARADIME_ORGANISATION,
  CREATING_APOLLO_CLIENT,
  WAITING_FOR_AUTHENTICATION,
  CONNECTING_TO_COMPANY,
  CONNECTING_TO_COMPANY_GIVEN_BY_URL,
  ONBOARDING_IN_ASSIGNED_COMPANY,
  COMPLETE,
  FAILED_FINDING_COMPANY,
}

const AppWithClusterConfig: FC = () => {
  // App state
  const onboardingUserInAssignedCompany = companyStore(
    (state) => state.onboardingUserCompanyAssigned,
  );
  const fireOnboardingUserInAssignedCompany = companyStore(
    (state) => state.fireOnboardingUserCompanyAssigned,
  );
  const setSnowflakeCallbackUrl = companyStore((state) => state.setSnowflakeCallbackUrl);
  const setBigqueryCallbackUrl = companyStore((state) => state.setBigqueryCallbackUrl);
  const setCompanyRegion = companyStore((state) => state.setCompanyRegion);
  const setIsExtensionOnlyCompany = companyStore((state) => state.setIsExtensionOnlyCompany);
  const setCompanyCreationInProgress = companyStore((state) => state.setCompanyCreationInProgress);
  const setApolloClient = graphQLStore((state) => state.setApolloClient);
  const apolloClient = graphQLStore((state) => state.apolloClient);
  const apolloClientIsOnlyForControlPlane = graphQLStore(
    (state) => state.apolloClientIsOnlyForControlPlane,
  );
  const jwtToken = graphQLStore((state) => state.jwtToken);
  const setCompanyUrls = graphQLStore((state) => state.setCompanyUrls);
  const setCompanyToken = userAuthStore((s) => s.setCompanyToken);
  const clusterConfigFromStore = graphQLStore((state) => state.clusterConfig);
  const companyUrlsFromStore = graphQLStore((state) => state.companyUrls);
  const paradimeOrganisationFromStore = graphQLStore((state) => state.paradimeOrganisation);
  const userHasNoCompany = userAuthStore((s) => s.userHasNoCompany);
  const setUserHasNoCompany = userAuthStore((s) => s.setUserHasNoCompany);
  const currentUser = userAuthStore((s) => s.currentUser);
  const setCurrentUser = userAuthStore((s) => s.setUser);

  const { tryLoadingFromCache } = useOrganisation();

  const { location: { href } } = window;
  const url = new URL(href);
  const companyTokenGivenByURL = url.searchParams.get('company');

  function getState(): State {
    // TODO: document this properly
    if (userHasNoCompany) {
      return State.FAILED_FINDING_COMPANY;
    }
    if (!clusterConfigFromStore) {
      return State.WAITING_FOR_CLUSTER_CONFIG;
    }
    if (!paradimeOrganisationFromStore) {
      return State.WAITING_FOR_PARADIME_ORGANISATION;
    }
    if (!apolloClient) {
      return State.CREATING_APOLLO_CLIENT;
    }
    if (!jwtToken) {
      return State.WAITING_FOR_AUTHENTICATION;
    }
    if (companyUrlsFromStore && apolloClientIsOnlyForControlPlane) {
      return State.COMPLETE;
    }
    if (onboardingUserInAssignedCompany) {
      return State.ONBOARDING_IN_ASSIGNED_COMPANY;
    }
    if (companyTokenGivenByURL) {
      return State.CONNECTING_TO_COMPANY_GIVEN_BY_URL;
    }
    return State.CONNECTING_TO_COMPANY;
  }

  const currentState = getState();

  // Set up Apollo client with the correct routes for the companies.
  useEffect(() => {
    function setCompany(company: CompanyArgsType) {
      setSnowflakeCallbackUrl(company.snowflakeCallbackUrl || '');
      setBigqueryCallbackUrl(company.bigqueryCallbackUrl || '');
      setCompanyRegion(company.region);
      setCompanyToken(company.token);
      setIsExtensionOnlyCompany(company.token === 'magnetar');
      setCompanyUrls({
        dataGraphqlUrl: company.dataGraphqlUrl,
        dbGraphqlUrl: company.dbGraphqlUrl,
        editorGraphqlUrl: company.editorGraphqlUrl,
        serviceGraphqlUrl: company.serviceGraphqlUrl,
        controlPlaneUrl: company.controlPlaneUrl,
        token: company.token,
        jwtToken,
        dinoAiAgentStreamUrl: company.dinoAiAgentStreamUrl,
      });
      const { magnetarUrl } = clusterConfigFromStore!;
      const companyClient = createClient({
        editorGraphqlUrl: company.editorGraphqlUrl,
        serviceGraphqlUrl: company.serviceGraphqlUrl,
        controlPlaneUrl: company.controlPlaneUrl,
        dataGraphqlUrl: company.dataGraphqlUrl,
        dbGraphqlUrl: company.dbGraphqlUrl,
        magnetarUrl,
        jwtToken: jwtToken || '',
      });
      setApolloClient(companyClient, Boolean(jwtToken), true);
      console.debug('using company-specific apollo client');// eslint-disable-line
    }

    console.debug(`state change to ${State[currentState]}`);
    switch (currentState) {
      case State.UNKNOWN: {
        break;
      }
      case State.COMPLETE: {
        break;
      }
      case State.FAILED_FINDING_COMPANY: {
        break;
      }
      case State.WAITING_FOR_CLUSTER_CONFIG: {
        break;
      }
      case State.WAITING_FOR_PARADIME_ORGANISATION: {
        tryLoadingFromCache();
        break;
      }
      case State.CREATING_APOLLO_CLIENT: {
        const controlPlaneUrl = paradimeOrganisationFromStore?.controlPlaneUrl;
        setApolloClient(createControlPlaneClient(controlPlaneUrl!, undefined), false, false);
        console.debug('using control plane-only apollo client without authentication');// eslint-disable-line
        break;
      }
      case State.WAITING_FOR_AUTHENTICATION: {
        break;
      }
      case State.CONNECTING_TO_COMPANY: {
        console.debug('getting identity from server');// eslint-disable-line

        const controlPlaneUrl = paradimeOrganisationFromStore?.controlPlaneUrl;
        const controlPlaneClient = createControlPlaneClient(controlPlaneUrl!, jwtToken);
        setApolloClient(controlPlaneClient, Boolean(jwtToken), false);

        controlPlaneClient.query<{
            userIdentity: UserIdentity
          }>({ query: UserIdentityDocument }).then(
            (myIdentity) => {
              const { company, name, email } = myIdentity.data.userIdentity;

              setCurrentUser({
                ...currentUser,
                name: name || currentUser.name,
                email: email || currentUser.email,
                accessLevel: GqlParadimeAccountType.Unknown,
              });

              setCompanyCreationInProgress(company?.creationInProgress);

              const companyArgs = convertCompanyMetadataToArgs(company, controlPlaneUrl);
              if (companyArgs) {
                setCompany(companyArgs);
              } else {
                console.warn(`failed logging in to company since ${JSON.stringify(company)} doesn't have the relevant data`);// eslint-disable-line
                setUserHasNoCompany(true);
              }
            },
          ).catch((reason) => {
            console.warn(`failed logging in to company due to reason=${reason}`);// eslint-disable-line
            setUserHasNoCompany(true);
          });

        break;
      }
      case State.CONNECTING_TO_COMPANY_GIVEN_BY_URL: {
        const controlPlaneUrl = paradimeOrganisationFromStore?.controlPlaneUrl;
        const controlPlaneClient = createControlPlaneClient(controlPlaneUrl!, jwtToken);
        controlPlaneClient.query<{
            companyIdentity: CompanyIdentity
          }>({ query: CompanyIdentityDocument, variables: { token: companyTokenGivenByURL } }).then(
            (cIdentity) => {
              const company = cIdentity.data.companyIdentity;
              const companyArgs = convertCompanyMetadataToArgs(company, controlPlaneUrl);
              if (companyArgs) {
                setCompany(companyArgs);
              } else {
                console.warn(`failed logging in to company ${companyTokenGivenByURL} since ${JSON.stringify(company)} doesn't have the relevant data`);// eslint-disable-line
                setUserHasNoCompany(true);
              }
            },
          ).catch((reason) => {
            console.warn(`failed logging in to company due to reason=${reason}`);// eslint-disable-line
            setUserHasNoCompany(true);
          });

        break;
      }
      case State.ONBOARDING_IN_ASSIGNED_COMPANY: {
        const controlPlaneUrl = paradimeOrganisationFromStore?.controlPlaneUrl;
        setCompanyUrls({
          ...onboardingUserInAssignedCompany!,
          jwtToken,
          controlPlaneUrl,
        });
        fireOnboardingUserInAssignedCompany(undefined);
        break;
      }
      default:
        checkNoCasesLeft(currentState);
        break;
    }
  }, [currentState]);

  let innerElement: JSX.Element = (<Loader />);
  if ([
    State.COMPLETE,
    State.FAILED_FINDING_COMPANY, // this can mean that the user is onboarding a new company
    State.WAITING_FOR_AUTHENTICATION, // let AppWithAuth0Provider.tsx handle this
    State.WAITING_FOR_PARADIME_ORGANISATION,
  ].includes(currentState)) {
    innerElement = (
      <AppWithApolloProvider>
        <AppWithAuth0Provider
          userHasCompany={currentState !== State.FAILED_FINDING_COMPANY}
        >
          <AppWithSwitch
            userHasCompany={currentState !== State.FAILED_FINDING_COMPANY}
            userHasOrganisation={currentState !== State.WAITING_FOR_PARADIME_ORGANISATION}
          />
        </AppWithAuth0Provider>
      </AppWithApolloProvider>
    );
  }

  return (
    <>
      <SentryErrorBoundary fallback={showFallbackErrorPage}>
        <Router>
          <Alerts />
          <ThemeProvider theme={theme}>
            {innerElement}
          </ThemeProvider>
        </Router>
      </SentryErrorBoundary>
    </>
  );
};

export default AppWithClusterConfig;
