import React from 'react';
import paradimeLogo from '../../components/Lineage/HomePage/images/paradimeLogo.svg';
import SearchMenuLabel from '../../components/Lineage/HomePage/SearchMenuLabel';
import { lineageSearchInputProps } from '../types';

export const lineageSearchInputDropdown = ({ nodeType }: lineageSearchInputProps) => {
  // Strip 'DBT' and 'Node' to get the type
  // e.g. DBTSeedNode -> Seed
  const text = nodeType.replace('DBT', '').replace('Node', '');

  let labelElement: JSX.Element | null = null;
  if (nodeType.includes('Source') || nodeType.includes('Exposure')) {
    labelElement = (
      <SearchMenuLabel
        text={text}
        color="var(--teal50)"
      />
    );
  }

  return {
    img: paradimeLogo,
    labelElement,
  };
};
