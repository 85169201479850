import React, { FC, ReactNode } from 'react';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { graphQLStore, userAuthStore } from '../stores';
import AuthStateManager from './AuthStateManager';

interface AppWithAuth0ProviderProps {
  userHasCompany: boolean,
  children: ReactNode,
}

const AppWithAuth0Provider: FC<AppWithAuth0ProviderProps> = ({ userHasCompany, children }) => {
  // App state
  const paradimeOrganisationFromStore = graphQLStore((state) => state.paradimeOrganisation);
  const organisationName = paradimeOrganisationFromStore?.name || '-';
  const setGoBackTo = userAuthStore((s) => s.setGoBackTo);

  const onRedirectCallback = (appState: AppState) => {
    if (appState.returnTo) {
      setGoBackTo(appState.returnTo);
    }
  };

  if (!paradimeOrganisationFromStore) { // note: this might happen during logout
    return <>{children}</>;
  }

  return (
    <Auth0Provider
      domain={paradimeOrganisationFromStore!.auth0EndpointDomain}
      clientId={paradimeOrganisationFromStore!.auth0ClientId}
      organization={paradimeOrganisationFromStore!.auth0Organisation}
      onRedirectCallback={onRedirectCallback}
      audience={`https://${paradimeOrganisationFromStore!.auth0ApiDomain}/api/v2/`}
      cacheLocation="localstorage"
      scope="openid profile email"
      useRefreshTokens
      skipRedirectCallback={['/auth/task-manager-success'].includes(window.location.pathname)}
      paradimeOrganization={organisationName}
    >
      <AuthStateManager userHasCompany={userHasCompany}>
        {children}
      </AuthStateManager>
    </Auth0Provider>
  );
};

export default AppWithAuth0Provider;
