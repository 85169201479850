import { colorType } from '@paradime-io/pragma-ui-kit/lib/components/styles/styleTypes';
import React from 'react';
import { lineageNodeDisplayProps } from '../types';
import HightouchLogo from '../../components/Lineage/images/Hightouch';
import { decodeLogoBase64, translateBEColourToFEColour } from '../utils';

const getNodeColor = (nodeType: string) => {
  switch (nodeType) {
    case 'HightouchSyncNode':
      return 'default';
    case 'HightouchModelNode':
      return 'primary_alt';
    default:
      return 'default';
  }
};

export const lineageNodeDisplay = ({ nodeType, lineageData }: lineageNodeDisplayProps) => {
  // Strip 'Hightouch' and 'Node' to get the type
  // e.g. HightouchViewNode -> View
  const nodeTypeName = nodeType.replace('Hightouch', '').replace('Node', '');

  return ({
    nodePrimaryLogo: decodeLogoBase64(lineageData?.logoBase64) || <HightouchLogo />,
    nodeColor: translateBEColourToFEColour(lineageData?.color).variantName as colorType
      || getNodeColor(nodeType) as colorType,
    nodeTypeName,
  });
};
