import styled from 'styled-components';

const ConnectWarehouse = styled.div`
display: flex;
flex-direction: column;

[class^="badge_container__"] {
  display: block;
}
`;

export { ConnectWarehouse };
