import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { EnvName } from '../../../../../client/service.graphql';
import {
  useDwhCheckDatabricksLazyQuery,
  useSetupDwhDatabricksMutation,
  useCompanyOnboardingDatabricksMutation,
  useCheckProfileAddDatabricksLazyQuery,
  useProfileAddDatabricksMutation,
  useSetupDwhProductionDatabricksMutation,
  // useSetupDwhMetadataDatabricksMutation,
} from '../../../../../client/generated/service';
import useOnboardIfChecked from '../hooks/useOnboardIfChecked';
import { WarehouseEnv } from '../../../../Common/Warehouses/utils';
import {
  getButtonDefinition,
  BUTTON_STATE_TEXT,
  onSubmitButtonClickProps,
  getInitialButtonText,
  useCommonConfigProps,
  isEditButtonShown,
} from '../CommonConfig';
import { WareHouseModalSource } from '../..';

export interface DatabricksFormDataType {
  connectionName: string,
  targetName: string,
  host: string,
  httpPath: string,
  hasUnityCatalog: boolean,
  catalog: string,
  token: string,
  schema: string,
  threads: number,
}

export const useCommonConfig = ({
  formData,
  env,
  source,
  isEditable,
  isAdditionalUser,
  isNewConnection,
}: useCommonConfigProps) => {
  const [onboarded, setOnboarded] = useState<boolean>();
  const [errorMessage, setErrorMessage] = useState('');
  const [currentButtonText, setCurrentButtonText] = useState(
    getInitialButtonText({
      source,
      isEditable,
      isAdditionalUser,
    }),
  );
  const [checkInProgress, setCheckInProgress] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isEditingToken, setIsEditingToken] = useState(
    isAdditionalUser
      ? true
      : !isEditButtonShown({ source, isNewConnection, env }),
  );

  const history = useHistory();

  const { checkQueryFunction: checkOnboardDatabricks } = useOnboardIfChecked({
    checkQuery: useDwhCheckDatabricksLazyQuery, // @ts-ignore
    triggerQuery: useCompanyOnboardingDatabricksMutation,
    setErrorMessage,
    setOnboarded,
    variables: formData as { [key: string]: any },
    setIsChecked,
    setCheckInProgress,
  });

  const { checkQueryFunction: checkUpdateDatabricks } = useOnboardIfChecked({
    checkQuery: useDwhCheckDatabricksLazyQuery,
    triggerQuery: useSetupDwhDatabricksMutation,
    setErrorMessage,
    setOnboarded,
    variables: formData as { [key: string]: any },
    setIsChecked,
    setCheckInProgress,
  });

  const { checkQueryFunction: checkAddDatabricks } = useOnboardIfChecked({
    checkQuery: useCheckProfileAddDatabricksLazyQuery,
    triggerQuery: useProfileAddDatabricksMutation,
    setErrorMessage,
    setOnboarded,
    variables: formData as { [key: string]: any },
    setIsChecked,
    setCheckInProgress,
  });

  const { checkQueryFunction: onboardProductionDatabricks } = useOnboardIfChecked(
    {
      checkQuery: useDwhCheckDatabricksLazyQuery, // @ts-ignore
      triggerQuery: useSetupDwhProductionDatabricksMutation,
      setErrorMessage,
      setOnboarded,
      variables: { ...formData, productionEnvName: EnvName.PRODUCTION },
      setIsChecked,
      setCheckInProgress,
    },
  );

  const testTheConnection = (
    variables: onSubmitButtonClickProps['formData'],
    credentialId: onSubmitButtonClickProps['credentialId'],
  ) => {
    if (isAdditionalUser) {
      checkAddDatabricks({
        variables: {
          ...variables,
          credentialId: credentialId!,
        },
      });
    } else {
      switch (env) {
        case WarehouseEnv.DEV:
          if (source === WareHouseModalSource.ACCOUNT_SETTINGS) {
            checkUpdateDatabricks({ variables });
          } else {
            checkOnboardDatabricks({ variables });
          }
          break;
        case WarehouseEnv.PROD:
          onboardProductionDatabricks({
            variables: {
              ...variables,
              productionEnvName: EnvName.PRODUCTION,
            },
          });
          break;
        default:
          break;
      }
    }
  };

  const submitButtonDetails = getButtonDefinition({
    state: currentButtonText,
    isAdditionalUser,
    testTheConnection,
    setCurrentButtonText,
    env,
    source,
    isEditable,
    history,
  });

  useEffect(() => {
    if (isChecked && !checkInProgress) {
      if (onboarded) {
        if (submitButtonDetails?.nextState) {
          setCurrentButtonText(submitButtonDetails.nextState.success);
        }
      } else {
        setCurrentButtonText(
          submitButtonDetails?.nextState?.failure || ('' as BUTTON_STATE_TEXT),
        );
      }
    }
  }, [onboarded, submitButtonDetails, checkInProgress]);

  return {
    onboarded,
    errorMessage,
    submitButtonText: submitButtonDetails?.submitButtonText,
    onSubmitButtonClick: submitButtonDetails?.onSubmitButtonClick,
    dataIsBeingChecked: checkInProgress,
    showEditButton: isEditButtonShown({ source, isNewConnection, env }),
    isEditingToken,
    setIsEditingToken,
  };
};
