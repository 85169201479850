import React, { FC } from 'react';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { useHistory } from 'react-router-dom';
import onboardingStartSteps from '../../images/onboarding-start-steps.svg';
import * as S from './GettingStarted.styles';
import PageTemplate from '../Utils/PageTemplate';
import { companyStore, userAuthStore } from '../../../../stores';
import { ONBOARDING_PAGE, OnboardingRoutePrefix } from '../..';

export interface GettingStartedProps {
  enforceFirstAdminView?: boolean,
  customOnCompletionUrl?: string,
}

const enum StepText {
  'CHOOSE_DBT' = 'Choose the dbt version for your environment.',
  'CONNECT_REPO' = 'Connect to your dbt repository to build models.',
  'CONNECT_DWH' = 'Connect to your data warehouse to run data models.'
}

const GettingStarted: FC<GettingStartedProps> = ({
  enforceFirstAdminView,
  customOnCompletionUrl,
}) => {
  const history = useHistory();

  const { currentUser: { isFirstAdmin } } = userAuthStore.getState();
  const { isGitSetupComplete } = companyStore.getState();

  const url = new URL(window.location.href);
  const onboardingToken = url.searchParams.get('onboarding_token');
  const isCompanyCreation = !!onboardingToken;

  const items = () => {
    if (isFirstAdmin || enforceFirstAdminView) {
      if (!isGitSetupComplete) {
        return [
          StepText.CHOOSE_DBT,
          StepText.CONNECT_REPO,
          StepText.CONNECT_DWH,
        ];
      }
      return [StepText.CONNECT_REPO, StepText.CONNECT_DWH];
    }
    return [StepText.CONNECT_DWH];
  };

  const handleClick = () => {
    if (customOnCompletionUrl) {
      history.push(customOnCompletionUrl);
      return;
    }

    const firstAdminView = isFirstAdmin || enforceFirstAdminView;
    if (firstAdminView && !isGitSetupComplete) {
      if (isCompanyCreation) {
        // A normal bottoms-up onboarding => Need to spin up the company
        history.push(`/${OnboardingRoutePrefix}/${ONBOARDING_PAGE.WORKSPACE_SETTINGS}${window.location.search}`);
      } else {
        // Initialised from retool app => Company already exists
        history.push(`/${OnboardingRoutePrefix}/${ONBOARDING_PAGE.CHOOSE_REPO_PROVIDER}${window.location.search}`);
      }
    } else {
      history.push(`/${OnboardingRoutePrefix}/${ONBOARDING_PAGE.CONNECT_WAREHOUSE}`);
    }
  };

  return (
    <PageTemplate>
      <AutoLayout
        direction="vertical"
        padding="expanded"
        verticalGap="very-dense"
        distribution="packed"
        alignment="top-center"
        style={{ paddingTop: '2rem' }}
      >
        <Typography
          tagName="span"
          type="h4"
          color="default"
          colorStep="100"
          style={{ textAlign: 'center', margin: '0 auto 2rem auto' }}
        >
          Let&apos;s get started
          {' '}
          <span role="img" aria-label="rocket">🚀</span>
        </Typography>
        <img src={onboardingStartSteps} style={{ width: '374px', margin: '0 auto' }} alt="onboarding-start" />
        <S.OnboardingStepsList>
          {items().map(
            (item, i) => (
              <S.OnboardingStepsListItem data-testid="listItem" num={i + 1} key={item}>
                {item}
              </S.OnboardingStepsListItem>
            ),
          )}
        </S.OnboardingStepsList>
        <DefaultButton
          fill={false}
          color="primary"
          onClick={handleClick}
          text="Continue"
          view="filled"
          type="standard"
          style={{
            margin: '0 auto',
            backgroundColor: 'var(--color60)',
            color: 'var(--white)',
          }}
          data-testid="continueButton"
          eventContext={Contexts.MODAL}
          eventObject="getStarted"
          eventAction={Actions.CLICKED}
          eventProperties={{ location: 'onboarding' }}
        />
      </AutoLayout>
    </PageTemplate>
  );
};

export default GettingStarted;
