import create from 'zustand';
import { combine } from 'zustand/middleware';
import { GqlUserInformation, GqlParadimeAccountType } from '../client/generated/service';

const emptyUserObject = {
  uid: '',
  name: '',
  email: '',
  avatarUrl: '',
  role: '',
  team: '',
  companyName: '',
  companyToken: '',
  companyRegion: '',
  accessLevels: [GqlParadimeAccountType.Unknown],
  accessLevel: GqlParadimeAccountType.Unknown,
  inviteLevels: [GqlParadimeAccountType.Unknown],
  isFirstAdmin: false,
  isSandboxWorkspace: false,
  plainHmac: '',
  workspaceUid: '',
  workspaceName: '',
  hasura: {
    endpoint: '',
    token: '',
  },
} as GqlUserInformation;

export const userAuthStore = create(
  combine(
    {
      currentUser: emptyUserObject,
      goBackTo: undefined as undefined | string,
      userHasNoCompany: undefined as undefined | boolean,
      userHasRole: undefined as undefined | boolean,
      companyToken: undefined as undefined | string,
      isSignup: undefined as undefined | boolean,
      userWantsToJoinCompany: undefined as undefined | boolean,
    },
    (set) => ({
      setGoBackTo: (newState: undefined | string) => set(() => ({ goBackTo: newState })),
      setUser: (newUser: GqlUserInformation) => set(() => ({
        currentUser: newUser,
      })),
      setUserHasNoCompany: (newState: boolean) => set(() => ({ userHasNoCompany: newState })),
      setUserHasRole: (newState: boolean) => set(() => ({ userHasRole: newState })),
      setCompanyToken: (newState: string) => set(() => ({ companyToken: newState })),
      setIsSignup: (newState: boolean) => set(() => ({ isSignup: newState })),
      setUserWantsToJoinCompany: (userWantsToJoinCompany: boolean) => set(
        () => ({ userWantsToJoinCompany }),
      ),
    }),
  ),
);
