/* eslint-disable no-param-reassign */
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { EnvName } from '../../../../../client/service.graphql';
import {
  useDwhCheckDuckdbLazyQuery,
  useSetupDwhDuckdbMutation,
  useCompanyOnboardingDuckdbMutation,
  useCheckProfileAddDuckdbLazyQuery,
  useProfileAddDuckdbMutation,
  useSetupDwhProductionDuckdbMutation,
  DuckdbConnectionType,
} from '../../../../../client/generated/service';
import useOnboardIfChecked from '../hooks/useOnboardIfChecked';
import { WarehouseEnv } from '../../utils';
import {
  getButtonDefinition,
  BUTTON_STATE_TEXT,
  onSubmitButtonClickProps,
  getInitialButtonText,
  useCommonConfigProps,
  isEditButtonShown,
} from '../CommonConfig';
import { WareHouseModalSource } from '../..';

export interface DuckDbFormDataType {
  connectionName: string,
  path: string,
  profileSetup: string,
  datasetName: string,
  threads: number,
  redactedProfile: string,
}

export const useCommonConfig = ({
  formData,
  env,
  source,
  isEditable,
  isAdditionalUser,
  isNewConnection,
}: useCommonConfigProps) => {
  const [onboarded, setOnboarded] = useState<boolean>();
  const [errorMessage, setErrorMessage] = useState('');
  const [currentButtonText, setCurrentButtonText] = useState(
    getInitialButtonText({
      source,
      isEditable,
      isAdditionalUser,
    }),
  );
  const [checkInProgress, setCheckInProgress] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const history = useHistory();

  const { checkQueryFunction: checkOnboardDuckDb } = useOnboardIfChecked({
    checkQuery: useDwhCheckDuckdbLazyQuery, // @ts-ignore
    triggerQuery: useCompanyOnboardingDuckdbMutation,
    setErrorMessage,
    setOnboarded,
    variables: formData as { [key: string]: any },
    setIsChecked,
    setCheckInProgress,
  });

  const { checkQueryFunction: checkUpdateDuckDb } = useOnboardIfChecked({
    checkQuery: useDwhCheckDuckdbLazyQuery,
    triggerQuery: useSetupDwhDuckdbMutation,
    setErrorMessage,
    setOnboarded,
    variables: formData as { [key: string]: any },
    setIsChecked,
    setCheckInProgress,
  });

  const { checkQueryFunction: checkAddDuckDb } = useOnboardIfChecked({
    checkQuery: useCheckProfileAddDuckdbLazyQuery,
    triggerQuery: useProfileAddDuckdbMutation,
    setErrorMessage,
    setOnboarded,
    variables: formData as { [key: string]: any },
    setIsChecked,
    setCheckInProgress,
  });

  const { checkQueryFunction: onboardProductionDuckDb } = useOnboardIfChecked(
    {
      checkQuery: useDwhCheckDuckdbLazyQuery, // @ts-ignore
      triggerQuery: useSetupDwhProductionDuckdbMutation,
      setErrorMessage,
      setOnboarded,
      variables: { ...formData, productionEnvName: EnvName.PRODUCTION },
      setIsChecked,
      setCheckInProgress,
    },
  );

  const testTheConnection = (
    vars: onSubmitButtonClickProps['formData'],
    credentialId: onSubmitButtonClickProps['credentialId'],
  ) => {
    const variables = {
      ...vars,
      profile: (vars as unknown as DuckDbFormDataType).redactedProfile,
      connectionType: DuckdbConnectionType.Duckdb,
    };

    if (isAdditionalUser) {
      checkAddDuckDb({
        variables: {
          ...variables,
          credentialId: credentialId!,
        },
      });
    } else {
      switch (env) {
        case WarehouseEnv.DEV:
          if (source === WareHouseModalSource.ACCOUNT_SETTINGS) {
            checkUpdateDuckDb({ variables });
          } else {
            checkOnboardDuckDb({ variables });
          }
          break;
        case WarehouseEnv.PROD:
          onboardProductionDuckDb({
            variables: {
              ...variables,
              productionEnvName: EnvName.PRODUCTION,
            },
          });
          break;
        default:
          break;
      }
    }
  };

  const submitButtonDetails = getButtonDefinition({
    state: currentButtonText,
    isAdditionalUser,
    testTheConnection,
    setCurrentButtonText,
    env,
    source,
    isEditable,
    history,
  });

  useEffect(() => {
    if (isChecked && !checkInProgress) {
      if (onboarded) {
        if (submitButtonDetails?.nextState) {
          setCurrentButtonText(submitButtonDetails.nextState.success);
        }
      } else {
        setCurrentButtonText(
          submitButtonDetails?.nextState?.failure || ('' as BUTTON_STATE_TEXT),
        );
      }
    }
  }, [onboarded, submitButtonDetails, checkInProgress]);

  return {
    onboarded,
    errorMessage,
    submitButtonText: submitButtonDetails?.submitButtonText,
    onSubmitButtonClick: submitButtonDetails?.onSubmitButtonClick,
    dataIsBeingChecked: checkInProgress,
    showEditButton: isEditButtonShown({ source, isNewConnection, env }),
  };
};
