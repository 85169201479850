import { catalogueSearchResultItemProps, catalogueSearchResultItemReturnType } from '../types';
import tableau from '../../components/Catalog/images/tableau.svg';

export const catalogueSearchResultItem = ({
  resultItem,
}: catalogueSearchResultItemProps): catalogueSearchResultItemReturnType => {
  switch (resultItem.__typename) {
    case 'GQLCatalogueTableauWorksheetItem':
      return (
        [
          {
            type: 'list',
            iconName: 'pie-chart',
            title: resultItem.name,
            subtitle: 'Worksheet',
            rightImage: tableau,
          },
          {
            type: 'description',
            description: resultItem.description!,
          },
          { type: 'divider' },
          [
            {
              leftComponents: [
                {
                  type: 'textWithIcon',
                  text: resultItem?.workbook?.projectName || '-',
                  icon: 'box',
                },
              ],
              rightComponents: [
                {
                  type: 'tags',
                  tags: resultItem.tags.length > 0 ? resultItem.tags : ['-'],
                },
              ],
            },
          ],
        ]
      );
    case 'GQLCatalogueTableauDashboardItem':
      return (
        [
          {
            type: 'list',
            iconName: 'control',
            title: resultItem.name,
            subtitle: 'Dashboard',
            rightImage: tableau,
          },
          {
            type: 'description',
            description: resultItem.description!,
          },
          { type: 'divider' },
          [
            {
              leftComponents: [
                {
                  type: 'textWithIcon',
                  text: resultItem?.workbook?.projectName || '-',
                  icon: 'box',
                },
              ],
              rightComponents: [
                {
                  type: 'tags',
                  tags: resultItem.tags.length > 0 ? resultItem.tags : ['-'],
                },
              ],
            },
          ],
        ]
      );
    case 'GQLCatalogueTableauDatasourceItem':
      return (
        [
          {
            type: 'list',
            iconName: 'compass',
            title: resultItem.name,
            subtitle: 'Data Source',
            rightImage: tableau,
          },
          {
            type: 'description',
            description: resultItem.description!,
          },
          { type: 'divider' },
          [
            {
              leftComponents: [
                {
                  type: 'textWithIcon',
                  text: resultItem?.downstreamWorkbooks[0]?.projectName || '-',
                  icon: 'box',
                },
              ],
              rightComponents: [
                {
                  type: 'tags',
                  tags: resultItem.tags.length > 0 ? resultItem.tags : ['-'],
                },
              ],
            },
          ],
        ]
      );

    default:
      return [];
  }
};
