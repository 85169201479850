import { IntegrationHandler } from '..';
import { lineageFilterNodes } from './LineageFilterNodes';
import { lineageSearchInputDropdown } from './LineageSearchInputDropdown';
import { lineageNodeDisplay } from './LineageNodeDisplay';
import { lineageContextMenu } from './LineageContextMenu';
import { catalogueSearchFilters } from './CatalogueSearchFilters';
import { catalogueSearchResultItem } from './CatalogueSearchResultItem';
import { catalogueNameAndIcon } from './CatalogueNameAndIcon';
import { catalogueDetailsCard } from './CatalogueDetailsCard';
import { catalogueSimpleType } from './CatalogueSimpleType';
import { catalogueDescription } from './CatalogueDescription';
import { catalogueItemTabsVisibility } from './CatalogueItemTabsVisibility';
import { catalogueFieldsTab } from './CatalogueFieldsTab';
import { catalogueDependenciesTab } from './CatalogueDependenciesTab';
import { catalogueLogo } from './CatalogueLogo';
import { catalogueHeaderButtons } from './CatalogueHeaderButtons';
import { catalogueTreeIcons } from './CatalogueTreeIcons';

export enum TableauHandlerAcceptedTypes {
  'TABLEAU' = 'TABLEAU',
  'TableauWorksheetNode' = 'TableauWorksheetNode',
  'TableauDashboardNode' = 'TableauDashboardNode',
  'TableauDatasourceNode' = 'TableauDatasourceNode',
  'TableauFlowNode' = 'TableauFlowNode',
  'GQLCatalogueTableauWorksheetItem' = 'GQLCatalogueTableauWorksheetItem',
  'GQLCatalogueTableauDashboardItem' = 'GQLCatalogueTableauDashboardItem',
  'GQLCatalogueTableauDatasourceItem' = 'GQLCatalogueTableauDatasourceItem',
  'tableau' = 'tableau',
  'dashboard' = 'dashboard',
  'dashboards' = 'dashboards',
  'datasources' = 'datasources',
  'worksheets' = 'worksheets',
  'datasource' = 'datasource',
  'worksheet' = 'worksheet',
}

export enum TableauSubTypes {
  'TABLEAU_DATASOURCES' = 'Data sources',
  'TABLEAU_WORKSHEETS' = 'Worksheets',
  'TABLEAU_DASHBOARDS' = 'Dashboards',
  'TABLEAU_FLOWS' = 'Flows',
}

export enum LineageNodeTypeTranslations {
  'TableauDatasourceNode' = 'Data Source',
  'TableauWorksheetNode' = 'Worksheet',
  'TableauDashboardNode' = 'Dashboard',
  'TableauFlowNode' = 'Flow',
}

export const TableauIntegrationHandler: IntegrationHandler = ({
  type: TableauHandlerAcceptedTypes.TABLEAU,
  subTypes: Object.values(TableauSubTypes),
  handlers: {
    // lineageNodeRenderer: (data: any) => <div>{data}</div>,
    // catalogueCardRenderer: (data: any) => <div>{data}</div>,
    lineageContextMenu,
    lineageSearchInputDropdown,
    lineageFilterNodes,
    catalogueSearchResultItem,
    catalogueDetailsCard,
    catalogueNameAndIcon,
    catalogueHeaderButtons,
    catalogueClassificationData: () => ({
      keyList: [],
      tableClassificationOptions: [],
    }),
    catalogueLogo,
    catalogueSimpleType,
    catalogueDescription,
    lineageNodeDisplay,
    catalogueAssetColumns: () => [],
    catalogueAssetItemId: () => 'error',
    catalogueSearchFilters,
    catalogueItemTabsVisibility,
    catalogueFieldsTab,
    catalogueDependenciesTab,
    catalogueSchemaTab: () => [{
      tableName: '',
      columnData: [],
    }],
    catalogueDbtModelTab: () => undefined,
    catalogueTreeIcons,
    catalogueShowClassificationsCard: () => true,
  },
});
