import React, { FC } from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import TextWithTooltip from '@paradime-io/pragma-ui-kit/lib/components/TextWithTooltip';
import { formatCurrency } from './utils';
import * as S from './CostOptimisations.styles';
import { optimisationAggregateItem } from './useGetAggregatedOptimisationData';

export interface SavingsSummaryProps {
  aggregatedOptimisationData: optimisationAggregateItem[],
}

export interface SummaryCardProps {
  text: string,
  tooltip: string,
  value: string,
}

const SummaryCard: FC<SummaryCardProps> = ({
  text, tooltip, value,
}) => (
  <S.Card>
    <AutoLayout
      direction="vertical"
      verticalGap="ultra-dense"
      padding="very-dense"
      distribution="packed"
    >
      <TextWithTooltip
        value={text}
        info={tooltip}
        type="body-small"
        dark
      />
      <Typography
        font="inter"
        type="h6"
        color="default"
        colorStep="100"
      >
        {value}
      </Typography>
    </AutoLayout>
  </S.Card>
);

const SavingsSummary: FC<SavingsSummaryProps> = ({
  aggregatedOptimisationData,
}) => {
  const estimatedSpend = aggregatedOptimisationData
    .reduce((currentTotal, { estimatedCurrentAnnualSpend }) => (
      currentTotal + Number(estimatedCurrentAnnualSpend)
    ), 0);

  const availableSavings = aggregatedOptimisationData
    .reduce((currentTotal, { allOptimisationsPotentialAnnualSavings }) => (
      currentTotal + Number(allOptimisationsPotentialAnnualSavings)
    ), 0);

  const appliedSavings = aggregatedOptimisationData
    .reduce((currentTotal, { allOptimisationsSavingsTillDate }) => (
      currentTotal + Number(allOptimisationsSavingsTillDate)
    ), 0);

  return (
    <AutoLayout
      direction="horizontal"
      horizontalGap="dense"
      padding="none"
      distribution="packed"
      style={{
        gridTemplateColumns: '1fr 1fr 1fr',
        backgroundColor: 'var(--grey0)',
        borderRadius: '4px',
      }}
    >
      <SummaryCard
        text="Estimated Annual Spend"
        tooltip="The estimated amount you will spend in the next 12 months"
        value={formatCurrency(estimatedSpend)}
      />
      <SummaryCard
        text="Estimated Annual Savings"
        tooltip="The amount you could save if all optimizations were applied"
        value={formatCurrency(availableSavings)}
      />
      <SummaryCard
        text="Actual Savings To Date"
        tooltip="The amount you have saved since turning on one or more optimizations"
        value={formatCurrency(appliedSavings)}
      />
    </AutoLayout>
  );
};

export default SavingsSummary;
