import {
  createContext,
  useContext,
  useEffect,
  useRef,
} from 'react';
import * as Sentry from '@sentry/react';
import { useHistory } from 'react-router-dom';
import { userAuthStore } from '../../../stores';
import { useEditorHeartbeatMutation } from '../../../client/generated/editor';
import { userHasEditorAccess } from '../../../utilis/PermissionsService';

export const EditorContext = createContext({
  url: '',
  setUrl: (url: string) => {}, // eslint-disable-line @typescript-eslint/no-unused-vars
  isReady: false,
  setIsReady: (ready: boolean) => {}, // eslint-disable-line @typescript-eslint/no-unused-vars
  showEditor: false,
  setShowEditor: (show: boolean) => {}, // eslint-disable-line @typescript-eslint/no-unused-vars
});

const useSetFirstEditor = () => {
  const timesEditorReturnedFalse = useRef(0);

  const history = useHistory();

  const { accessLevel } = userAuthStore((s) => s.currentUser);

  const {
    url: editorUrl, setUrl: setEditorUrl,
    isReady, setIsReady,
    showEditor,
  } = useContext(EditorContext);

  const [
    askForEditor,
    { data: editorData },
  ] = useEditorHeartbeatMutation();

  useEffect(() => {
    if (editorData?.editorHeartbeat?.started === true) {
      setEditorUrl(editorData.editorHeartbeat.editorUrl || '');
      setIsReady(true);
    } else if (editorData?.editorHeartbeat?.started === false) {
      setIsReady(false);
      setEditorUrl('');
      const waitAndUpdateCounter = async () => {
        timesEditorReturnedFalse.current += 1;
        console.info(timesEditorReturnedFalse.current, 'Waiting for editor...'); // eslint-disable-line no-console

        await new Promise((resolve) => setTimeout(resolve, 1000));

        if (timesEditorReturnedFalse.current < 1800) {
          askForEditor();
        } else {
          console.error('Editor not found after 1800 times'); // eslint-disable-line no-console
          Sentry.captureMessage('Editor not found after 1800 times');
          history.push('/error');
        }
      };

      waitAndUpdateCounter();
    }
  }, [editorData]); // eslint-disable-line

  useEffect(() => {
    const isLocalHost = Boolean(process.env.REACT_APP_DEV_EDITOR_URL);

    if (showEditor) {
      if (isLocalHost) {
        setIsReady(true);
        setEditorUrl(process.env.REACT_APP_DEV_EDITOR_URL!);
      } else if (userHasEditorAccess(accessLevel!)) {
        askForEditor();
      }
    }
  }, [showEditor]);

  return {
    editorUrl,
    setEditorUrl,
    isReady,
  };
};

export default useSetFirstEditor;
