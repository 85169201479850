import { useAuth0 } from '@auth0/auth0-react';
import { SHA256, enc } from 'crypto-js';
import { useEffect, useRef } from 'react';
import * as Sentry from '@sentry/react';
import LogRocket from 'logrocket';
import { AnalyticsBrowser } from '@segment/analytics-next';
import setupLogRocketReact from 'logrocket-react';
import { isLocalHost } from '../../utilis/common';
import { userAuthStore, graphQLStore } from '../../stores';
import { useGetProfileLazyQuery } from '../../client/generated/service';
import { useUpdateJwtToken } from './useUpdateJwtTokenPeriodically';
import getIgnoredTrackEvents from '../../components/Common/Libraries/Segment/getIgnoredTrackEvents';
import { getLogRocketAppId, LogRocketOpts } from '../../components/Common/Libraries/LogRocket';
import { useGetUserIdentifyingProperties } from '../../utilis/useGetUserIdentifyingProperties';
import { userHasAccessLevelSet } from '../../utilis/PermissionsService';
import forwardParadimeTelemetry from '../../components/Common/Libraries/Segment/forwardParadimeTelemetry';
import { useTrackTelemetryEventMutation } from '../../client/generated/service-dataEndpoint';

const useSetupUserFromAuth0 = (
  userHasCompany: boolean,
) => {
  // Global state
  const {
    user: Auth0User,
  } = useAuth0();
  const { updateJwtToken } = useUpdateJwtToken();

  // App state
  const paradimeOrganisationFromStore = graphQLStore((state) => state.paradimeOrganisation);
  const setUserWantsToJoinCompany = userAuthStore((s) => s.setUserWantsToJoinCompany);
  const clusterConfigFromStore = graphQLStore((state) => state.clusterConfig);
  const { companyToken } = graphQLStore.getState();

  /** this stored user is set after the apollo client is initialized */
  const storedUser = userAuthStore((s) => s.currentUser);
  const analyticsSet = useRef(false);
  const [getUserProfile, { data: userProfileData }] = useGetProfileLazyQuery({
    onCompleted: ({ userIdentity }) => {
      if (userIdentity === null) {
        setUserWantsToJoinCompany(true);
      }
    },
  });

  const userIdentifyingProperties = useGetUserIdentifyingProperties();
  const [trackTelemetryEvent] = useTrackTelemetryEventMutation();

  // cookie widget
  const cookieWidget = document.getElementById('CookiebotWidget');

  useEffect(() => {
    if (userHasCompany) {
      getUserProfile();
    }
  }, [userHasCompany]);

  useEffect(() => {
    const handleMessage = (e: MessageEvent) => {
      const { data: { type, payload } } = e;
      if (type === 'sentry-capture-exception') {
        Sentry.captureException(JSON.stringify(payload));
      }
    };
    window.addEventListener('message', handleMessage);

    return () => window.removeEventListener('message', handleMessage);
  }, []);

  useEffect(() => {
    // hide the cookiebot widget
    if (cookieWidget) {
      cookieWidget.style.display = 'none';
    }
  }, [cookieWidget]);

  useEffect(() => {
    const setupUser = async () => {
      /** when the auth0 client is ready update the jwt to start the refresh tokens */
      await updateJwtToken();

      /**
       * if the user is authenticated and the storedUser is set,
       * inform sentry and setup analytics
       * only set the analytics once
       */
      analyticsSet.current = true;

      // make the logged in user known to segment and make the identify call
      // this call is made everytime user is logged in
      const { segmentKey } = clusterConfigFromStore!;
      if (!window.analytics) {
        const originalAnalytics = AnalyticsBrowser.load({ writeKey: segmentKey });

        const track = getIgnoredTrackEvents(
          forwardParadimeTelemetry(
            trackTelemetryEvent,
          ),
          userIdentifyingProperties,
        );

        /** this is already set but ts hasn't picked it up yet
         * so I manually set it here to avoid the error
         * this time I check if it's already set, because this effect
         * runs multiple times, and we only want to set analytics once
         */
        // @ts-ignore
        window.analytics = {
          ...originalAnalytics,
          track,
        };
      }
      const userId = SHA256(storedUser.email || '').toString(enc.Hex);
      const {
        username,
        userEmail,
        type,
        emailDomain,
        companyName,
      } = userIdentifyingProperties;

      window.analytics.identify(
        userId,
        {
          name: username,
          email: userEmail,
          type,
          emailDomain,
          companyName,
        },
        {
          integrations: {
            Intercom: {
              user_hash: storedUser?.intercomHmac || 'unknown',
            },
          },
        },
      );

      // set the user in LogRocket
      if (!isLocalHost) {
        LogRocket.init(
          getLogRocketAppId(),
          LogRocketOpts(clusterConfigFromStore?.apiHost || 'api.dev.paradime.io'),
        );
        setupLogRocketReact(LogRocket);

        LogRocket.getSessionURL((sessionURL: string) => {
          Sentry.configureScope((scope) => {
            scope.setExtra('sessionURL', sessionURL);
          });

          // send LogRocket session url to Segment
          window.analytics.track('LogRocket', {
            sessionURL,
          });
        });

        LogRocket.identify(userId || 'unknown', {
          name: username,
          email: userEmail,
          type,
          emailDomain,
          companyName,
        });
      }

      if (userProfileData?.userIdentity) {
        const { setUser } = userAuthStore.getState();
        setUser({ ...storedUser, ...userProfileData.userIdentity?.userInformation });
      }

      // set the user in sentry
      Sentry.setUser({ id: userId });
    };

    if (
      userHasCompany
      && paradimeOrganisationFromStore?.auth0EndpointDomain
      && userHasAccessLevelSet(storedUser.accessLevel)
      && !analyticsSet.current
      && companyToken
      && clusterConfigFromStore
    ) {
      setupUser();
    }
  }, [
    Auth0User,
    userHasCompany,
    paradimeOrganisationFromStore,
    storedUser,
    analyticsSet,
    companyToken,
    clusterConfigFromStore,
  ]);
};

export default useSetupUserFromAuth0;
