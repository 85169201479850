export enum Locations {
  HOME = 'home',
  EDITOR = 'editor',
  SETTINGS = 'settings',
  WORKSPACES = 'workspaces',
  BOLt = 'bolt',
  RADAR = 'radar',
  CATALOG = 'catalog',
}

type RecommendedHelpType = {
  title: string,
  url: string,
  isFeatured: boolean,
};

export const recommendations: RecommendedHelpType[] = [
  {
    title: 'Paradime 101',
    url: 'https://docs.paradime.io/app-help/guides/paradime-101',
    isFeatured: true,
  },
  {
    title: 'Migrate from DBT Cloud™',
    url: 'https://docs.paradime.io/app-help/guides/dbt-cloud-tm-importer',
    isFeatured: true,
  },
  {
    title: 'DinoAI',
    url: 'https://docs.paradime.io/app-help/documentation/code-ide/dino-ai',
    isFeatured: false,
  },
  {
    title: 'Users Settings',
    url: 'https://docs.paradime.io/app-help/documentation/settings/users',
    isFeatured: false,
  },
  {
    title: 'Connections Settings',
    url: 'https://docs.paradime.io/app-help/documentation/settings/connections',
    isFeatured: false,
  },
  {
    title: 'Getting Started with the Code IDE',
    url: 'https://docs.paradime.io/app-help/guides/paradime-101/getting-started-with-the-paradime-ide',
    isFeatured: false,
  },
  {
    title: 'Running dbt™ in Production with Bolt',
    url: 'https://docs.paradime.io/app-help/guides/paradime-101/running-dbt-in-production-with-bolt',
    isFeatured: false,
  },
  {
    title: 'Getting Started with Your Paradime Workspace',
    url: 'https://docs.paradime.io/app-help/guides/paradime-101/getting-started-with-your-paradime-workspace',
    isFeatured: false,
  },
  {
    title: 'Integrations',
    url: 'https://docs.paradime.io/app-help/documentation/integrations',
    isFeatured: false,
  },
  {
    title: 'CI/CD with Bolt',
    url: 'https://docs.paradime.io/app-help/documentation/bolt/ci-cd',
    isFeatured: false,
  },
  {
    title: 'Webhooks',
    url: 'https://docs.paradime.io/app-help/developers/webhooks',
    isFeatured: false,
  },
  {
    title: 'Python SDK',
    url: 'https://docs.paradime.io/app-help/developers/python-sdk',
    isFeatured: false,
  },
];
