import { IconName } from '@blueprintjs/icons';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Chips from '@paradime-io/pragma-ui-kit/lib/components/Chips';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import React, { FC } from 'react';

export interface ConnectionsHeaderProps {
  title: string,
  showButton: boolean,
  eventContext: Contexts,
  buttonText: string,
  buttonIcon?: IconName,
  onClick?: () => void,
  helpText?: string,
  onHelpClick?: () => void,
}

const ConnectionsHeader: FC<ConnectionsHeaderProps> = ({
  title,
  showButton,
  eventContext,
  buttonText,
  buttonIcon,
  onClick,
  helpText,
  onHelpClick,
}) => (
  <div style={{ borderBottom: '1px solid var(--grey5)', width: '100%' }}>
    <AutoLayout
      direction="horizontal"
      alignment="center"
      padding="very-dense"
      distribution="space-between"
      style={{ padding: '0 8px 8px 8px', alignItems: 'center' }}
    >
      <AutoLayout
        direction="horizontal"
        padding="none"
        horizontalGap="very-dense"
        distribution="packed"
      >
        <Typography
          style={{ placeSelf: 'flex-start', padding: '5px 0', margin: 0 }}
          type="h6"
          tagName="span"
        >
          {title}
        </Typography>
        {
          helpText && (
            <Chips
              color="primary_alt"
              round={false}
              style={{ margin: '4px', verticalAlign: 'bottom' }}
              tag={helpText}
              type="dense"
              view="smooth"
              onClick={onHelpClick}
            />
          )
        }
      </AutoLayout>
      {
        showButton && (
          <DefaultButton
            color="primary"
            type="standard"
            view="smooth"
            dense
            icon={buttonIcon}
            text={buttonText}
            onClick={onClick}
            eventContext={eventContext}
            eventObject="addNewWarehouseConnection"
            eventAction={Actions.CLICKED}
            eventProperties={{ area: title }}
          />
        )
      }
    </AutoLayout>
  </div>
);

export default ConnectionsHeader;
