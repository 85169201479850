import React, {
  FC, useContext, useEffect, useState,
} from 'react';
import { Elements } from '@stripe/react-stripe-js';
import Spinner from '@paradime-io/pragma-ui-kit/lib/components/Spinner';
import { StripeContext } from './useSetupStripe';
import CheckoutForm, { CheckoutFormProps } from './CheckoutForm';
import { useCreateStripePaymentIntentMutation } from '../../../../client/generated/service';

export interface StripeEmbeddedPaymentElementProps extends CheckoutFormProps {
}

const StripeEmbeddedPaymentElement: FC<StripeEmbeddedPaymentElementProps> = (props) => {
  const [clientSecret, setClientSecret] = useState('');

  const { stripeInstance } = useContext(StripeContext);

  const [createPaymentIntent] = useCreateStripePaymentIntentMutation({
    onCompleted: (data) => {
      if (data.createStripeSetupIntent?.ok) {
        setClientSecret(data.createStripeSetupIntent.clientSecret);
      }
    },
  });

  useEffect(() => {
    createPaymentIntent();
  }, []);

  const appearance = {
    theme: 'stripe' as const,
    variables: {
      colorPrimary: '#726ae3', // var(--violet60)
      colorBackground: '#ffffff',
      colorText: '#19191d', // var(--grey100)
      colorDanger: '#f44336', // var(--red60)
      fontFamily: 'Inter, sans-serif', // var(--inter)
      spacingUnit: '2px',
      borderRadius: '4px',
    },
  };
  const options = {
    clientSecret,
    appearance,
  };

  if (!clientSecret) return <Spinner />;

  return (
    <Elements options={options} stripe={stripeInstance}>
      <CheckoutForm {...props} />
    </Elements>
  );
};

export default StripeEmbeddedPaymentElement;
