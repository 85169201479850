import { useEffect } from 'react';
import { CatalogueEnvironment } from '../../../client/generated/service-dataEndpoint';
import { lineageStore } from '../../../stores';
import FileIgnoredToast from '../toasts/FileIgnoredToast';
import NewThreadFailedToast from '../toasts/NewThreadFailedToast';
import PullRequestFailedToast from '../toasts/PullRequestFailedToast';
import { triggerToast, TriggerToastProps } from '../../../utilis';

const Toasts: { [key: string]: TriggerToastProps } = {
  NewThreadFailed: NewThreadFailedToast(),
  FileIgnoredToast: FileIgnoredToast(),
  PullRequestFailed: PullRequestFailedToast(),
};

const useListenersFromCodeEditor = () => {
  const setCurrentBranchInEditor = lineageStore((s) => s.setCurrentBranchInEditor);

  useEffect(() => {
    const handleEventsFromCodeEditor = (e: MessageEvent) => {
      const { data: { type, payload } } = e;

      if (type === 'compare-branches') {
        setCurrentBranchInEditor(payload.currentBranchName);
        window.open(`/lineage/compare/${payload.currentCommitHash}/${payload.compareCommithash}`, '_blank');
      }

      if (type === 'open-lineage') {
        setCurrentBranchInEditor(payload.currentBranchName);
        window.open(`/lineage/model/${payload.commitHash}/${encodeURIComponent(btoa(payload.filePath))}`, '_blank');
      }

      if (type === 'open-toast') {
        if (payload?.header && payload?.message && payload?.type) {
          triggerToast({
            header: payload.header,
            message: payload.message,
            type: payload.type,
          });
        } else {
          triggerToast(Toasts[payload]);
        }
      }

      if (type === 'open-catalog-on-new-tab') {
        const { currentBranch, catalogId, defaultBranch } = payload;
        const areCurrentAndDefaultEqual = defaultBranch === currentBranch;
        const targetEnv = areCurrentAndDefaultEqual
          ? CatalogueEnvironment.Production
          : CatalogueEnvironment.Development;
        window.open(`/catalog/search/${encodeURIComponent(currentBranch)}/${encodeURIComponent(catalogId)}?environment=${targetEnv}`, '_blank');
      }

      if (type === 'enable-defer-to-prod') {
        window.open('/bolt');
      }

      if (type === 'open-commandbar') {
        window.CommandBar.open();
      }

      if (type === 'open-helphub-article') {
        window.CommandBar.openHelpHub({ articleId: payload.articleId });
      }
    };

    window.addEventListener('message', handleEventsFromCodeEditor);

    return () => {
      window.removeEventListener('message', handleEventsFromCodeEditor, false);
    };
  }, []); //eslint-disable-line
};

export default useListenersFromCodeEditor;
