import React, { FC } from 'react';
import ZeroState from '@paradime-io/pragma-ui-kit/lib/components/ZeroState';
import {
  AutoLayout,
  DefaultButton,
  Typography,
} from '@paradime-io/pragma-ui-kit';
import { useHistory } from 'react-router-dom';
import {
  Actions,
  Contexts,
} from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { useAuth0 } from '@auth0/auth0-react';
import useOrganisation from '../../../../components/hooks/useOrganisation';

export interface ErrorAutoJoinProps {
  userFirstName?: string;
}
const ErrorNoAutoJoin: FC<ErrorAutoJoinProps> = ({ userFirstName }) => {
  const history = useHistory();

  const { clear: clearOrganisation } = useOrganisation();
  const { logout } = useAuth0();
  return (
    <AutoLayout
      direction="vertical"
      padding="none"
      distribution="space-between"
      style={{ height: '100vh', width: '100vw' }}
    >
      <ZeroState
        icon={{ iconName: 'disable', iconColor: 'var(--red80)', iconSize: 70 }}
        text={(
          <AutoLayout direction="vertical" padding="none" verticalGap="dense">
            <Typography
              type="h3"
              color="default"
              colorStep="100"
              font="inter"
              style={{ textAlign: 'center' }}
            >
              Auto sign-up is not enabled...
            </Typography>
            <Typography
              type="body-bold-large"
              color="default"
              colorStep="100"
              font="inter"
              style={{ textAlign: 'center', width: '740px', margin: 'auto' }}
            >
              {`Hi ${
                userFirstName || 'there'
              }, we are excited you want to join Paradime. Please request an invite
            link from your Paradime admin to get started.`}
            </Typography>
            <DefaultButton
              type="standard"
              view="filled"
              text="Take me back!"
              color="primary"
              eventContext={Contexts.ONBOARDING}
              eventObject="noCompanyBackToStart"
              eventAction={Actions.CLICKED}
              onClick={() => {
                clearOrganisation();
                logout();
                history.push('/');
              }}
              style={{ width: '150px', margin: 'auto' }}
            />
          </AutoLayout>
        )}
      />
    </AutoLayout>
  );
};

export default ErrorNoAutoJoin;
