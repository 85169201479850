import { useEffect, useState } from 'react';
import { NavBarProps } from '@paradime-io/pragma-ui-kit/lib/components/AppNavbar';
import { useHistory } from 'react-router-dom';
import useCheckPermissions from './useCheckPermissions';
import { useNetworkStatus } from './useNetworkStatus';
import { companyStore } from '../../../stores';

export const useGetAppStatus = () => {
  const [appStatus, setAppStatus] = useState<NavBarProps['appStatus']>();

  const history = useHistory();

  const companyPlan = companyStore((s) => s.companyPlan);
  const isTrial = companyPlan?.isTrial;

  const { clipboardState } = useCheckPermissions();
  const { online } = useNetworkStatus();

  useEffect(() => {
    if (!online) {
      setAppStatus({
        icon: 'offline',
        color: 'danger',
        message: "You're offline!!!",
      });
    } else {
      setAppStatus(undefined);
    }
  }, [online]);

  useEffect(() => {
    if (clipboardState && clipboardState !== 'granted') {
      setAppStatus({
        icon: 'clipboard',
        color: 'warning_alt',
        message: 'Enable Clipboard for cut, copy and paste',
        onClick: () => navigator.clipboard.readText(),
      });
    }

    if (clipboardState === 'granted') {
      setAppStatus(undefined);
    }
  }, [clipboardState]);

  useEffect(() => {
    if (isTrial && companyPlan?.daysLeftInTrialFormattedString) {
      setAppStatus({
        message: `Trial ends ${companyPlan.daysLeftInTrialFormattedString}`,
        icon: 'rocket-slant',
        view: 'gradient-primary',
        onClick: () => history.push('/platform/plans'),
      });
    } else {
      setAppStatus(undefined);
    }
  }, [isTrial]);

  return { appStatus };
};
