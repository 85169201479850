/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import React, { FC, ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import paradimeLogo from '../../images/paradime-logo-with-text.svg';

export interface IntegrationStatusPageProps {
    content: ReactNode
}

const IntegrationStatusPage: FC<IntegrationStatusPageProps> = (
  { content },
) => {
  const history = useHistory();
  return (
    <AutoLayout
      direction="vertical"
      verticalGap="dense"
      padding="none"
      alignment="center"
      distribution="space-between"
      style={{
        width: '100vw',
        height: '100vh',
        backgroundColor: 'var(--grey0)',
      }}
    >
      <img src={paradimeLogo} alt="paradime" onClick={() => history.push('/home')} />
      <AutoLayout
        direction="vertical"
        verticalGap="dense"
        distribution="packed"
        alignment="center"
        padding="none"
        style={{
          height: 'calc(100vh - 76px)',
          display: 'flex',
        }}
      >
        <AutoLayout
          padding="none"
          verticalGap="dense"
          distribution="packed"
          direction="vertical"
          alignment="center"
        >
          {content}
        </AutoLayout>
      </AutoLayout>
    </AutoLayout>
  );
};

export default IntegrationStatusPage;
