import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import ZeroState from '@paradime-io/pragma-ui-kit/lib/components/ZeroState';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { graphQLStore } from '../../../stores';
import ZeroStateImagePublicEmail from './images/zero-state-business-email-login-image.svg';
import ZeroStateImageCompetitor from './images/zero-state-competitor-email-login-image.svg';

export enum BlockedLoginType {
  PUBLIC_EMAIL = 'public-email',
  COMPETITOR = 'competitor',
}

const BlockedLogin: FC = () => {
  const clusterConfigFromStore = graphQLStore((state) => state.clusterConfig);
  const searchParams = new URLSearchParams(window.location.search);
  const state = searchParams.get('state');
  const { pathname } = useLocation();
  const blockType = (pathname === '/blocked') ? BlockedLoginType.PUBLIC_EMAIL : BlockedLoginType.COMPETITOR;

  const handleButtonClick = () => {
    const url = `https://${clusterConfigFromStore?.auth0EndpointDomain}/continue?state=${state}`;
    window.localStorage.clear();
    window.sessionStorage.clear();
    window.open(url, '_blank');
  };

  return (
    <AutoLayout
      direction="vertical"
      padding="none"
      distribution="space-between"
      style={{ height: '100vh', width: '100vw' }}
    >
      <ZeroState
        useParadimeIcon
        direction="vertical"
        image={
          (blockType === BlockedLoginType.PUBLIC_EMAIL)
            ? ZeroStateImagePublicEmail
            : ZeroStateImageCompetitor
        }
        text={
          (blockType === BlockedLoginType.PUBLIC_EMAIL)
            ? "We'd love to get you in. But to do so, please use a business email to login to Paradime 🙏"
            : 'Making moves? Not on our watch 😝'
        }
        buttons={[
          {
            text: 'Try again',
            color: 'primary',
            view: 'filled',
            type: 'standard',
            disabled: !(state),
            eventContext: Contexts.APP,
            eventObject: (blockType === BlockedLoginType.PUBLIC_EMAIL) ? 'businessEmailTryLoginAgain' : 'competitorEmailRedirect',
            eventAction: Actions.CLICKED,
            onClick: () => handleButtonClick(),
          },
        ]}
      />
    </AutoLayout>
  );
};

export default BlockedLogin;
