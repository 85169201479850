import { WarehouseFormInputType } from '../../warehouseFactory/types';
import { WarehouseEnv } from '../../utils';

export const formDistribution = (
  "'P P P P P P' "
  + "'p p p t t t' "
  + "'D D D D D D' "
  + "'a a a a a a' "
  + "'d d d d d d' "
  + "'s s s h h h' "
);

export const initialValues = {
  connectionName: '',
  targetName: '',
  profile: '',
  schema: '',
  threads: 8,
};

export interface genericFormFieldsProps {
  env: WarehouseEnv,
  prodHelpArticle: string,
  devHelpArticle: string,
}

export const formFields = ({
  env,
  prodHelpArticle,
  devHelpArticle,
}: genericFormFieldsProps) => [
  {
    title: 'Connection Settings',
    gridArea: 'P',
    type: 'title',
    helpArticleId: (
      env === WarehouseEnv.PROD
        ? prodHelpArticle
        : devHelpArticle
    ),
  },
  {
    name: 'connectionName',
    label: 'Profile Name',
    type: WarehouseFormInputType.TEXT,
    gridArea: 'p',
    title: 'Profile Name',
    adminOnly: true,
  },
  {
    name: 'targetName',
    label: 'Target',
    type: WarehouseFormInputType.TEXT,
    value: env || WarehouseEnv.DEV,
    gridArea: 't',
    adminOnly: true,
  },
  {
    title: (env === WarehouseEnv.PROD) ? 'Production Credentials' : 'Development Credentials',
    gridArea: 'D',
    type: WarehouseFormInputType.TITLE,
  },
  {
    name: 'redactedProfile',
    label: 'Profile configuration',
    type: WarehouseFormInputType.MULTI_LINE_INPUT,
    gridArea: 'd',
    minLines: 10,
    maxLines: 10,
  },
  {
    name: 'schema',
    label: 'Schema',
    type: WarehouseFormInputType.TEXT,
    firstUserOnly: false,
    gridArea: 's',
    title: (env === WarehouseEnv.PROD) ? 'Production Credentials' : 'Development Credentials' as any,
  },
  {
    name: 'threads',
    label: 'Threads',
    type: WarehouseFormInputType.NUMBER,
    value: 8,
    additionalUserPlaceholder: 8,
    gridArea: 'h',
  },
];
