import React, { FC } from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { useCopyManager, useCopyManagerProps } from '../hooks/useCopyManager';
import ZeroStateCard from './ZeroStateCard';

const ZeroStateUnlockAI: FC<useCopyManagerProps> = ({
  contentType,
  pageUrl,
}) => {
  const { copy, loading } = useCopyManager({ contentType, pageUrl });

  return (
    <div
      style={{
        background: 'linear-gradient(90deg, #EEF2FF, #FAF5FF)',
        border: '1px solid #E0E7FF',
        borderRadius: '8px',
        padding: '24px',
        width: '100%',
        height: '100%',
      }}
    >
      <AutoLayout
        direction="vertical"
        padding="none"
        verticalGap="dense"
        distribution="packed"
        alignment="center"
      >
        <AutoLayout
          direction="vertical"
          padding="none"
          verticalGap="none"
          distribution="packed"
        >
          <Typography
            type="h6"
            style={{ textAlign: 'center' }}
          >
            Unlock the Power of AI-Driven Development
          </Typography>
          <Typography
            type="body-bold-small"
            colorStep="50"
            style={{ textAlign: 'center' }}
          >
            Choose from our suite of AI-powered features to streamline
            your workflow and boost productivity
          </Typography>
        </AutoLayout>
        <AutoLayout
          direction="horizontal"
          padding="none"
          horizontalGap="dense"
          distribution="packed"
          style={{ gridTemplateColumns: 'repeat(3, 1fr)' }}
        >
          {copy.map((copyItem) => (
            <ZeroStateCard {...copyItem} loading={loading} />
          ))}
        </AutoLayout>
      </AutoLayout>
    </div>
  );
};

export default ZeroStateUnlockAI;
