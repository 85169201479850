enum commandBarCallbacks {
  OPEN_LINEAGE_NODE = 'open_lineage_node_callback',
  OPEN_BOLT_SCHEDULE = 'open_bolt_schedule_callback',
  ADD_NEW_USER = 'add_new_user_callback',
  ADD_NEW_ENVIRONMENT_VARIABLE = 'add_new_environment_variable_callback',
  OPEN_EDITOR_COMMAND = 'open_editor_command_callback',
  RESTART_EDITOR_COMMAND = 'restart_editor_command_callback',
  TOGGLE_GIT_PANEL = 'toggle_git_panel_callback',
  RUN_DBT_CHECKS = 'run_dbt_checks_callback',
  OPEN_IN_APP_CHAT = 'open_in_app_chat_callback'
}

export default commandBarCallbacks;
