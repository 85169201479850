import React, {
  FC,
  useEffect,
} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import useCheckOnboardingStatusOnce from '../components/Onboarding/hooks/useCheckOnboardingStatusOnce';
import useSetupUserFromAuth0 from './hooks/useSetupUserFromAuth0';
import { useSetupHasuraClient } from './hooks/useSetupHasuraClient';
import { useSetupCubeClient } from './hooks/useSetupCubeClient';
import useHandleSnowflakeOAuth from '../components/Editor/hooks/useHandleSnowflakeOAuth';
import { useOnboardUser } from '../utilis/useOnboardUser';
import { companyStore, userAuthStore } from '../stores';
import { useGetGitCurrentAndMainBranchInfoLazyQuery } from '../client/generated/service';
import useAutoSwitchWorkspaces from '../components/AccountSettings/Workspaces/hooks/useAutoSwitchWorkspaces';
import ZeroStateSwitchingWorkspace from '../components/AccountSettings/Workspaces/ZeroStateSwitchingWorkspace';
import useSetFirstEditor from '../components/Editor/hooks/useSetFirstEditor';
import { userHasAccessLevelSet } from '../utilis/PermissionsService';
import useGetWarehouseConnectionStatus from '../utilis/useGetWarehouseConnectionStatus';
import { useCheckPricingPlan } from './hooks/useCheckPricingPlan';
import { useFeatureFlagRedirects } from './hooks/useFeatureFlagRedirects';
import useSetupUpdateUser from './hooks/useSetupUpdateUser';

export interface GlobalActionsProps {
  userHasCompany: boolean,
}

const GlobalActions: FC<GlobalActionsProps> = ({
  userHasCompany,
}) => {
  const { user } = useAuth0();

  const url = new URL(window.location.href);
  const userToken = url.searchParams.get('user_token');

  const currentUser = userAuthStore((s) => s.currentUser);
  const userWantsToJoinCompany = userAuthStore((s) => s.userWantsToJoinCompany);
  const setUserWantsToJoinCompany = userAuthStore((s) => s.setUserWantsToJoinCompany);
  const needsStripe = companyStore((s) => s.needsStripe);
  const setDefaultBranch = companyStore((s) => s.setDefaultBranch);
  const setUser = userAuthStore((s) => s.setUser);

  const [
    triggerGetGitCurrentAndMainBranchInfoLazyQuery,
    { data: gitBranchesData },
  ] = useGetGitCurrentAndMainBranchInfoLazyQuery();

  const { accessLevel } = currentUser;
  useCheckOnboardingStatusOnce();
  useSetupUserFromAuth0(userHasCompany);
  useSetupUpdateUser();
  useSetupHasuraClient();
  useSetupCubeClient();
  useHandleSnowflakeOAuth(); // TODO: add user is not associated to company
  const { onboardUser } = useOnboardUser();
  const { isLoadingWorkspaceSwitch, newWorkspaceName } = useAutoSwitchWorkspaces();

  useSetFirstEditor();
  useGetWarehouseConnectionStatus();
  useCheckPricingPlan();
  useFeatureFlagRedirects();

  useEffect(() => {
    if (gitBranchesData && userHasCompany) {
      if (gitBranchesData.getGitCurrentAndMainBranchInfo?.mainBranch?.name) {
        setDefaultBranch(gitBranchesData.getGitCurrentAndMainBranchInfo?.mainBranch?.name);
      }
    }
  }, [user, accessLevel, gitBranchesData, userHasCompany]);

  useEffect(() => {
    const hasNewAccessLevel = userHasAccessLevelSet(accessLevel);
    const hasNoAccessLevelSetYet = !currentUser.accessLevel
      || !userHasAccessLevelSet(accessLevel);

    if (hasNewAccessLevel && user && hasNoAccessLevelSetYet) {
      setUser({ ...user, ...currentUser, accessLevel });
    }
  }, [accessLevel, user, setUser, currentUser]);

  useEffect(() => {
    if (userHasCompany) {
      triggerGetGitCurrentAndMainBranchInfoLazyQuery();
    }

    if (!userHasCompany && !needsStripe) {
      console.info('User has no company; trying to onboard'); // eslint-disable-line no-console
      onboardUser();
    }

    // userWantsToJoinCompany => userProfile returned `null`
    // userToken => invite URL
    if (userWantsToJoinCompany || userToken) {
      setUserWantsToJoinCompany(false);
      // eslint-disable-next-line no-console
      console.info(`User wants to join company; trying to onboard. ${userToken ? `userToken=${userToken}` : ''}`);
      onboardUser();
    }
  }, [userHasCompany, needsStripe, userWantsToJoinCompany]);

  if (isLoadingWorkspaceSwitch) {
    return (
      <ZeroStateSwitchingWorkspace
        workspaceName={newWorkspaceName}
      />
    );
  }

  return null;
};

export default GlobalActions;
