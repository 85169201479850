import { useEffect, useState } from 'react';

// eslint-disable-next-line no-undef
type ParaPermissionName = PermissionName | 'clipboard-write' | 'clipboard-read';

interface ParaPermissionDescriptor {
  name: ParaPermissionName;
}

interface ParaPermissions {
  /** [MDN Reference](https://developer.mozilla.org/docs/Web/API/Permissions/query) */
  query(permissionDesc: ParaPermissionDescriptor): Promise<PermissionStatus>;
}

interface NavigatorParadime extends Navigator {
  permissions: ParaPermissions,
}

const paradimePermissionsNavigator = navigator as unknown as NavigatorParadime;

const useCheckPermissions = () => {
  // eslint-disable-next-line no-undef
  const [clipboardState, setClipboardState] = useState<PermissionState>();

  const handlePermission = () => {
    paradimePermissionsNavigator.permissions.query({ name: 'clipboard-read' }).then((result) => {
      setClipboardState(result.state);

      // eslint-disable-next-line no-param-reassign
      result.onchange = () => {
        setClipboardState(result.state);
      };
    });
  };

  useEffect(() => {
    handlePermission();
  }, []);

  return {
    clipboardState,
  };
};

export default useCheckPermissions;
