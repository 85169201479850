import React, { useEffect, FC } from 'react';
import { useKnockFeed } from '@knocklabs/react';
import { FeedItemOrItems, FeedItem } from '@knocklabs/client';
import { triggerToast } from '../../../../utilis';

const NotificationToaster: FC = () => {
  const { feedClient } = useKnockFeed();

  const onNotificationsReceived = ({ items }: {items: FeedItemOrItems}) => {
    // Whenever we receive a new notification from our real-time stream, show a toast
    // (note here that we can receive > 1 items in a batch)
    // @ts-ignore
    items.forEach((notification: FeedItem) => {
      if (notification.seen_at === null) {
        triggerToast({
          header: 'New notification',
          type: 'attention',
          message: <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: notification.blocks[0].rendered }}
          />,
        });
      }
    });

    // Optionally, you may want to mark them as "seen" as well
    feedClient.markAsSeen(items);
  };

  useEffect(() => {
    // Receive all real-time notifications on our feed
    feedClient.on('items.received.realtime', onNotificationsReceived);

    // Cleanup
    return () => feedClient.off('items.received.realtime', onNotificationsReceived);
  }, [feedClient]);

  return null;
};

export default NotificationToaster;
