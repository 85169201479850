import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import Callout from '@paradime-io/pragma-ui-kit/lib/components/Callout';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import FeatureSection from '@paradime-io/pragma-ui-kit/lib/components/PlanCard/Sections/FeatureSection';
import { PlatformTab } from '../../../Platform';
import PaymentSuccess from './PaymentSuccess.svg';
import { PricingProduct } from '../../../Platform/Plans/hooks/usePricingPlans';

export interface paymentCompletedProps {
  isPositiveOutcome?: boolean,
  selectedProduct?: PricingProduct,
}

const PaymentCompleted: FC<paymentCompletedProps> = ({
  isPositiveOutcome,
  selectedProduct,
}) => {
  const history = useHistory();

  return (
    <AutoLayout
      direction="vertical"
      distribution="space-between"
      padding="none"
      verticalGap="very-dense"
      style={{
        gridTemplateRows: 'calc(100% - 40px) 40px',
        alignItems: 'start',
      }}
    >
      <AutoLayout
        direction="vertical"
        distribution="packed"
        padding="none"
        verticalGap="very-dense"
        wrapperStyle={{ alignItems: 'start' }}
        style={{ overflow: 'auto', height: '100%', padding: '0 4px 4px 0' }}
      >
        <Callout
          title={isPositiveOutcome
            ? 'Upgrade completed'
            : 'Unable to complete payment'}
          icon={isPositiveOutcome ? 'tick-circle' : 'info-sign'}
          content={isPositiveOutcome
            ? undefined
            : 'We could not complete your payment at this time. Please try again later or contact support for more information.'}
          color={isPositiveOutcome ? 'success' : 'danger'}
          view="smooth"
          dense
          maxHeight="42px"
        />
        {isPositiveOutcome && (
          <>
            <img src={PaymentSuccess} alt="payment was successful" style={{ width: '100%' }} />
            {selectedProduct?.features && (
              <FeatureSection
                title="You now have access to:"
                features={selectedProduct.features}
                seeAllFeaturesLink=""
              />
            )}
          </>
        )}
      </AutoLayout>
      <DefaultButton
        type="standard"
        view="filled"
        color="primary"
        text="Return to plans"
        onClick={() => history.push(`/platform/${PlatformTab.PLANS}`)}
        eventContext={Contexts.APP}
        eventObject="confirmAndUpgrade"
        eventAction={Actions.CLICKED}
      />
    </AutoLayout>
  );
};

export default PaymentCompleted;
