/* eslint-disable no-param-reassign */
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { EnvName } from '../../../../../client/service.graphql';
import {
  useDwhCheckBigqueryLazyQuery,
  useSetupDwhBigqueryMutation,
  useCompanyOnboardingBigqueryMutation,
  useCheckProfileAddBigqueryLazyQuery,
  useProfileAddBigqueryMutation,
  useSetupDwhProductionBigqueryMutation,
  useGetProfileLazyQuery,
  useSetupDwhCostBigqueryMutation,
} from '../../../../../client/generated/service';
import useOnboardIfChecked, { postConnectionCheckTestProps } from '../hooks/useOnboardIfChecked';
import { WarehouseEnv } from '../../../../Common/Warehouses/utils';
import {
  getButtonDefinition,
  BUTTON_STATE_TEXT,
  onSubmitButtonClickProps,
  getInitialButtonText,
  useCommonConfigProps,
  isEditButtonShown,
} from '../CommonConfig';
import { WareHouseModalSource } from '../..';
import { checkNoCasesLeft, useGetJwtToken } from '../../../../../utilis';
import { companyStore } from '../../../../../stores';
import { useHandleClosableWindow } from '../../../../hooks/useHandleClosableWindow';

export interface BigqueryFormDataType {
  connectionName: string,
  datasetName: string,
  location: string,
  serviceAccountJson?: string,
  targetName: string,
  threads: number,
  authMethod: AuthMethod,
  projectId?: string,
  clientId?: string,
  clientSecret?: string,
  hasExecutionProject?: boolean,
  executionProject?: string,
  connectionType: ConnectionType,
  costProjectId?: string,
  costGcsBucketName?: string,
}

// The string which gets displayed in the dropdown
export enum AuthMethod {
  'SERVICEACCOUNT' = 'Service Account',
  'OAUTH' = 'Big Query OAuth',
}
// A string required by the backend
export enum ConnectionType {
  'SERVICEACCOUNT' = 'service_account',
  'OAUTH' = 'oauth',
}

export interface openOAuthTabProps {
  clientId: string,
  credentialId: string,
}

export const getDefaultEmptyAuthValues = (
  authType: AuthMethod,
  formData?: BigqueryFormDataType,
): BigqueryFormDataType | undefined => {
  if (!formData || formData === undefined) return undefined;

  delete formData?.serviceAccountJson;
  delete formData?.projectId;
  delete formData?.clientId;
  delete formData?.clientSecret;

  switch (authType) {
    case AuthMethod.OAUTH:
      return {
        ...formData,
        authMethod: AuthMethod.OAUTH,
        projectId: undefined,
        clientId: undefined,
        clientSecret: undefined,
        connectionType: ConnectionType.OAUTH,
      };
    case AuthMethod.SERVICEACCOUNT:
      return {
        ...formData,
        authMethod: AuthMethod.SERVICEACCOUNT,
        serviceAccountJson: undefined,
        connectionType: ConnectionType.SERVICEACCOUNT,
      };
    default:
      checkNoCasesLeft(authType);
      return undefined;
  }
};

export const useCommonConfig = ({
  formData,
  env,
  source,
  isEditable,
  isAdditionalUser,
  isNewConnection,
}: useCommonConfigProps) => {
  const [onboarded, setOnboarded] = useState<boolean>();
  const [errorMessage, setErrorMessage] = useState('');
  const [currentButtonText, setCurrentButtonText] = useState(
    getInitialButtonText({
      source,
      isEditable,
      isAdditionalUser,
    }),
  );
  const [checkInProgress, setCheckInProgress] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isEditingServiceAccountJson, setIsEditingServiceAccountJson] = useState(
    isAdditionalUser
      ? false
      : !isEditButtonShown({ source, isNewConnection, env }),
  );
  const [isEditingClientSecret, setIsEditingClientSecret] = useState(
    isAdditionalUser
      ? false
      : !isEditButtonShown({ source, isNewConnection, env }),
  );

  const bigqueryCallbackUrl = companyStore((state) => state.bigqueryCallbackUrl);
  const companyRegion = companyStore((state) => state.companyRegion);

  const getAuthMethodDropdownValue = () => {
    if ((formData as BigqueryFormDataType)?.connectionType?.toLowerCase()?.includes('oauth')) {
      return AuthMethod.OAUTH;
    }
    return AuthMethod.SERVICEACCOUNT;
  };

  const [selectedAuthMethod, setSelectedAuthMethod] = useState(
    getAuthMethodDropdownValue(),
  );

  const [selectedDatasetLocation, setSelectedDatasetLocation] = useState(
    (formData as BigqueryFormDataType)?.location || 'EU',
  );

  const history = useHistory();

  const [getUserProfile, { data: userProfileData }] = useGetProfileLazyQuery();

  useEffect(() => {
    getUserProfile();
  }, []);

  const { getToken } = useGetJwtToken();
  const { openWindow } = useHandleClosableWindow();

  const encodeUserIdFromJwtToken = async (jwtToken: string) => {
    const { sub } = JSON.parse(jwtToken);
    const encoder = new TextEncoder();
    const data = encoder.encode(sub);
    const hashArrayBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashArrayBuffer));
    const hash = hashArray
      .map((bytes) => bytes.toString(16).padStart(2, '0'))
      .join('');
    return hash;
  };

  const openOAuthTab = async ({
    clientId,
    credentialId,
  }: openOAuthTabProps) => {
    let {
      location: { host },
    } = window;
    if (
      host.includes('localhost')
      || ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(host[0])
    ) {
      // Overwrite the host URL if it is localhost or an ephemeral environment
      host = 'app.dev.paradime.io';
    }

    const jwtToken = await getToken();
    const jwtChunk = jwtToken.split('.')[1];
    // The jwt token was encoded using modified b64 for url's
    // '+' was replaced with '-' and '/' was repalced with '_'
    // atob() expects a standard b64 string, so undo these changes
    // https://en.wikipedia.org/wiki/Base64#URL_applications
    const jwtWithoutUnderscores = jwtChunk.replace(/_/g, '/');
    const jwtWithoutHyphens = jwtWithoutUnderscores.replace(/-/g, '+');
    const decodedJwtToken = atob(jwtWithoutHyphens);

    const baseUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
    const scopes = 'https://www.googleapis.com/auth/bigquery https://www.googleapis.com/auth/drive.readonly https://www.googleapis.com/auth/devstorage.read_only';

    // const baseUrl = `https://${snowflakeAccount.replace('.privatelink', '')}.snowflakecomputing.com/oauth/authorize`;
    const encodedClientId = encodeURIComponent(clientId);

    const userId = await encodeUserIdFromJwtToken(decodedJwtToken);
    const state = {
      'company-token': userProfileData?.userIdentity?.userInformation?.companyToken,
      'company-region': companyRegion,
      'credential-id': credentialId,
      'user-id': userId,
    };
    if (Object.keys(state).length !== 4) {
      console.error(`BQ: One of credential id, user id, company region or company token is missing: ${JSON.stringify(state)}`); // eslint-disable-line no-console
    }
    const encodedState = btoa(JSON.stringify(state));

    // Need to add prompt=consent in order to get a refresh token every time
    // https://stackoverflow.com/questions/10827920/not-receiving-google-oauth-refresh-token/10857806#10857806
    const oAuthUrl = `${baseUrl}?access_type=offline&prompt=consent&response_type=code&redirect_uri=${bigqueryCallbackUrl}&client_id=${encodedClientId}&scope=${scopes}&state=${encodedState}=`;

    openWindow({
      url: oAuthUrl,
      launchedFrom: 'bigqueryWarehouse',
    });
  };

  const callOpenOAuthTab = ({ credentialId }: postConnectionCheckTestProps) => (
    openOAuthTab({
      clientId: (formData as BigqueryFormDataType).clientId || '',
      credentialId: credentialId!,
    })
  );

  const postConnectionCheckTest = (
    selectedAuthMethod === AuthMethod.OAUTH
      ? callOpenOAuthTab
      : undefined
  );

  const { checkQueryFunction: checkOnboardBigquery } = useOnboardIfChecked({
    checkQuery: useDwhCheckBigqueryLazyQuery, // @ts-ignore
    triggerQuery: useCompanyOnboardingBigqueryMutation,
    setErrorMessage,
    setOnboarded,
    variables: formData as { [key: string]: any },
    setIsChecked,
    setCheckInProgress,
    postConnectionCheckTest,
  });

  const { checkQueryFunction: checkUpdateBigquery } = useOnboardIfChecked({
    checkQuery: useDwhCheckBigqueryLazyQuery,
    triggerQuery: useSetupDwhBigqueryMutation,
    setErrorMessage,
    setOnboarded,
    variables: formData as { [key: string]: any },
    setIsChecked,
    setCheckInProgress,
    postConnectionCheckTest,
  });

  const { checkQueryFunction: checkAddBigquery } = useOnboardIfChecked({
    checkQuery: useCheckProfileAddBigqueryLazyQuery,
    triggerQuery: useProfileAddBigqueryMutation,
    setErrorMessage,
    setOnboarded,
    variables: formData as { [key: string]: any },
    setIsChecked,
    setCheckInProgress,
    postConnectionCheckTest,
  });

  const { checkQueryFunction: onboardProductionBigquery } = useOnboardIfChecked(
    {
      checkQuery: useDwhCheckBigqueryLazyQuery, // @ts-ignore
      triggerQuery: useSetupDwhProductionBigqueryMutation,
      setErrorMessage,
      setOnboarded,
      variables: { ...formData, productionEnvName: EnvName.PRODUCTION },
      setIsChecked,
      setCheckInProgress,
      postConnectionCheckTest,
    },
  );

  const { checkQueryFunction: onboardingCostFunction } = useOnboardIfChecked({
    checkQuery: useDwhCheckBigqueryLazyQuery, // @ts-ignore
    triggerQuery: useSetupDwhCostBigqueryMutation,
    setErrorMessage,
    setOnboarded,
    variables: formData as { [key: string]: any },
    setIsChecked,
    setCheckInProgress,
    postConnectionCheckTest,
  });

  useEffect(() => {
    const handleBQAuthSuccess = (event: MessageEvent) => {
      const { type } = event.data;
      if (type === 'bigquery-auth-success') {
        setOnboarded(true);
      }

      if (type === 'bigquery-auth-failed') {
        setErrorMessage(
          'Paradime was not able to connect to your Bigquery database. Check your database credentials and try again.',
        );
        setOnboarded(false);
      }
    };
    window.addEventListener('message', handleBQAuthSuccess);

    return () => window.removeEventListener('message', handleBQAuthSuccess);
  }, []);

  const testTheConnection = (
    variables: onSubmitButtonClickProps['formData'],
    credentialId: onSubmitButtonClickProps['credentialId'],
  ) => {
    if (isAdditionalUser) {
      checkAddBigquery({
        variables: {
          ...variables,
          credentialId: credentialId!,
        },
      });
    } else {
      switch (env) {
        case WarehouseEnv.DEV:
          if (source === WareHouseModalSource.ACCOUNT_SETTINGS) {
            checkUpdateBigquery({ variables });
          } else {
            checkOnboardBigquery({ variables });
          }
          break;
        case WarehouseEnv.PROD:
          onboardProductionBigquery({
            variables: {
              ...variables,
              productionEnvName: EnvName.PRODUCTION,
            },
          });
          break;
        case WarehouseEnv.COSTS:
          onboardingCostFunction({ variables });
          break;

        default:
          break;
      }
    }
  };

  const submitButtonDetails = getButtonDefinition({
    state: currentButtonText,
    isAdditionalUser,
    testTheConnection,
    setCurrentButtonText,
    env,
    source,
    isEditable,
    history,
  });

  useEffect(() => {
    if (isChecked && !checkInProgress) {
      if (onboarded) {
        if (submitButtonDetails?.nextState) {
          setCurrentButtonText(submitButtonDetails.nextState.success);
        }
      } else {
        setCurrentButtonText(
          submitButtonDetails?.nextState?.failure || ('' as BUTTON_STATE_TEXT),
        );
      }
    }
  }, [onboarded, submitButtonDetails, checkInProgress]);

  return {
    onboarded,
    selectedAuthMethod,
    setSelectedAuthMethod,
    selectedDatasetLocation,
    setSelectedDatasetLocation,
    errorMessage,
    submitButtonText: submitButtonDetails?.submitButtonText,
    onSubmitButtonClick: submitButtonDetails?.onSubmitButtonClick,
    dataIsBeingChecked: checkInProgress,
    showEditButton: isEditButtonShown({ source, isNewConnection, env }),
    isEditingServiceAccountJson,
    setIsEditingServiceAccountJson,
    isEditingClientSecret,
    setIsEditingClientSecret,
  };
};
