import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import 'normalize.css/normalize.css';
import '@paradime-io/pragma-ui-kit/lib/components/styles/setproduct.css';
import SentryOpts from './components/Common/Libraries/Sentry/sentry';
import './styles/index.css';
import './setupCompanyLevelStorage';

Sentry.init(SentryOpts);
declare global {
  interface Window { microfrontend?: boolean; }
}

window.microfrontend = window.microfrontend || false;

if (!window.microfrontend) {
  ReactDOM.render(
    <App />,
    document.getElementById('root'),
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
