import { useEffect, useState } from 'react';
import { IntegrationAcceptedTypes, IntegrationTypes } from '.';
import { useOrganisationIntegrationListQuery } from '../client/generated/service';

export const useGetIntegrationsList = () => {
  const [integrationsList, setIntegrationsList] = useState<IntegrationAcceptedTypes[]>([]);

  const { data } = useOrganisationIntegrationListQuery();

  useEffect(() => {
    const typesList: IntegrationAcceptedTypes[] = [];
    if (data) {
      typesList.push(IntegrationTypes.DWH);
      typesList.push(IntegrationTypes.DBT);

      if (data?.organisationIntegrationList.looker === 'active') {
        typesList.push(IntegrationTypes.LOOKER);
      }
      if (data?.organisationIntegrationList.tableau === 'active') {
        typesList.push(IntegrationTypes.TABLEAU);
      }
      if (data?.organisationIntegrationList.fivetran === 'active') {
        typesList.push(IntegrationTypes.FIVETRAN);
      }
      if (data?.organisationIntegrationList.powerBi === 'active') {
        typesList.push(IntegrationTypes.POWER_BI);
      }
      if (data?.organisationIntegrationList.thoughtspot === 'active') {
        typesList.push(IntegrationTypes.THOUGHTSPOT);
      }
      if (data?.organisationIntegrationList.sigma === 'active') {
        typesList.push(IntegrationTypes.SIGMA);
      }

      setIntegrationsList(typesList);
    }
  }, [data]);

  return integrationsList;
};
