import React from 'react';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import { Icon } from '@blueprintjs/core';
import styled from 'styled-components';
import { catalogueSchemaTabProps, catalogueSchemaTabReturnType } from '../types';
import { GqlCatalogueFivetranSchema } from '../../client/generated/service-dataEndpoint';

// For some reason the "iconSize" prop is not working
const ForcedIconSize = styled(Icon)`
  svg {
    width: 16px;
    height: 16px;
  }
`;

export const catalogueSchemaTab = ({
  resultItem,
}: catalogueSchemaTabProps): catalogueSchemaTabReturnType[] => {
  const getKeyType = ({ isPrimaryKey, isForeignKey }: {
    isPrimaryKey: boolean, isForeignKey: boolean,
  }) => {
    if (!isPrimaryKey && !isForeignKey) return '-';
    return (
      <AutoLayout
        direction="horizontal"
        horizontalGap="very-dense"
        padding="none"
      >
        <ForcedIconSize
          icon={isPrimaryKey ? 'key' : 'globe-network'}
          iconSize={16}
        />
        <Typography
          font="inter"
          type="body-small"
          color="default"
          colorStep="100"
        >
          {isPrimaryKey ? 'Primary' : 'Foreign'}
        </Typography>
      </AutoLayout>
    );
  };

  const mapSchemaDataIntoTables = (
    schemaData: GqlCatalogueFivetranSchema[],
  ): catalogueSchemaTabReturnType[] => {
    const tableData: catalogueSchemaTabReturnType[] = [];
    schemaData.forEach((schema) => {
      schema.tables.forEach((table) => {
        tableData.push({
          tableName: table.nameInDestination,
          columnData: table.columns.map(({
            nameInDestination, typeInDestination, isPrimaryKey, isForeignKey,
          }) => ({
            columnName: nameInDestination,
            dataType: typeInDestination || '-',
            keyType: getKeyType({ isPrimaryKey, isForeignKey }),
          })),
        });
      });
    });
    return tableData;
  };

  switch (resultItem?.__typename) {
    case 'GQLCatalogueFivetranConnectorItem':
      return mapSchemaDataIntoTables(resultItem.schemas);
    default:
      return [{
        tableName: '',
        columnData: [],
      }];
  }
};
