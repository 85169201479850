import {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import { companyStore, graphQLStore } from '../../../stores';
import {
  useCheckGitLazyQuery,
  useCompanyOnboardingSetGitUriMutation,
  useRegenerateSshKeyMutation,
} from '../../../client/generated/service';

export const useSetupGit = (
  currentRepoUrl: string,
  setLoading: Dispatch<SetStateAction<boolean>>,
  dbtProjectPath?: string,
) => {
  const [tested, setTested] = useState(false);
  const [connected, setConnected] = useState(true);
  const repoProvider = companyStore((s) => s.repoProvider);

  const [checkGitConnection] = useCheckGitLazyQuery({
    onCompleted: (data) => {
      if (data.checkGitUri?.ok) {
        setGit({
          variables: {
            gitProvider: repoProvider!,
            uri: currentRepoUrl,
            dbtProjectDir: dbtProjectPath,
          },
        });
      } else {
        setConnected(false);
        setLoading(false);
      }
    },
  });

  const [setGit] = useCompanyOnboardingSetGitUriMutation({
    onCompleted: (data) => {
      if (data.companyOnboardingSetGitUri?.ok) {
        setTested(true);
        setConnected(true);
      } else {
        setConnected(false);
      }
      setLoading(false);
    },
  });

  return {
    tested,
    connected,
    checkGitConnection,
  };
};

export const useRegenerateSshKey = (
  repoUrlFromState: string,
  sshPublicKey: string,
  currentRepoUrl: string,
  setLoading: Dispatch<SetStateAction<boolean>>,
  repoRegex: RegExp,
) => {
  const [currentSshKey, setCurrentSshKey] = useState(sshPublicKey);

  const [correctLink] = currentRepoUrl.match(repoRegex) || [];

  const notEmpty = currentRepoUrl.length > 0;
  const readyToConnect = notEmpty && Boolean(correctLink);
  const apolloClient = graphQLStore((s) => s.apolloClient);

  const [
    regenerate,
    { loading: sshLoading, data: sshData },
  ] = useRegenerateSshKeyMutation();

  useEffect(() => {
    if (repoUrlFromState && currentRepoUrl) {
      if (repoUrlFromState !== currentRepoUrl) {
        if (readyToConnect) {
          regenerate();
        }
      }
    }
  }, [currentRepoUrl]);// eslint-disable-line

  useEffect(() => {
    setLoading(sshLoading);
    if (sshData?.regenerateSshKey?.ok) {
      setCurrentSshKey(sshData.regenerateSshKey.publicKey!);
    }
    if (sshLoading) {
      setCurrentSshKey('................................................................................................................................ loading');
    }
  }, [sshData, sshLoading]);// eslint-disable-line

  useEffect(() => {
    // @ts-ignore
    const isControlPlane = apolloClient && apolloClient.link?.options?.uri;
    if (!sshPublicKey && !isControlPlane) regenerate();
  }, [sshPublicKey, apolloClient]);

  return {
    currentSshKey,
    readyToConnect,
    notEmpty,
  };
};
