import { BillingFrequencyOptions, Prices } from '@paradime-io/pragma-ui-kit/lib/components/PricingPlan';

export interface DividePriceByUserByMonthProps {
  prices: Prices,
}

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const divideAnnualPriceByUserPerMonth = ({
  prices,
}: DividePriceByUserByMonthProps) => {
  const annualPrice = prices[BillingFrequencyOptions.YEARLY]?.value;

  if (annualPrice) {
    return currencyFormatter.format(annualPrice / 12);
  }

  return '';
};

export const divideMonthlyPriceByUserPerMonth = ({
  prices,
}: DividePriceByUserByMonthProps) => {
  const monthlyPrice = prices[BillingFrequencyOptions.MONTHLY]?.value;

  if (monthlyPrice) {
    return currencyFormatter.format(monthlyPrice);
  }

  return '';
};
