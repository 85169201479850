import {
  ChangeEvent, Dispatch, MouseEvent, SetStateAction,
} from 'react';
import { CSSProperties } from 'styled-components';
import { colorType } from '@paradime-io/pragma-ui-kit/lib/components/styles/styleTypes';
import { MenuTemplateProps } from '@paradime-io/pragma-ui-kit/lib/components/Menu';
import { initialFormDataType } from '../useGetInitialWarehouseData';

export interface warehouseCommonProps {
  expose: Function
}

export enum WarehouseFormInputType {
  'NUMBER' = 'number',
  'PASSWORD' = 'password',
  'TEXT' = 'text',
  'FILE' = 'file',
  'CHECKBOX' = 'checkbox',
  'TITLE' = 'title',
  'DROPDOWN' = 'dropdown',
  'BUTTON' = 'button',
  'MULTI_LINE_INPUT' = 'multiLineInput',
}

export type WarehouseComponentType = TitleType
  | CheckboxType
  | InputType
  | DropdownType
  | ButtonType
  | MultiLineInputType;

export interface TitleType {
  type: WarehouseFormInputType.TITLE,
  title: string,
  gridArea: string,
  disabled: boolean,
  adminOnly?: boolean,
  name: keyof initialFormDataType,
  isSecretField?: boolean,
  isEditingSecretField?: boolean,
  helpArticleId?: string,
}

export interface CheckboxType {
  type: WarehouseFormInputType.CHECKBOX,
  gridArea: string,
  label: string,
  name: keyof initialFormDataType,
  cancels: string,
  isPrivate: boolean,
  disabled: boolean,
  adminOnly?: boolean,
  isSecretField?: boolean,
  isEditingSecretField?: boolean,
  helperText?: string,
  withTogglingInputField?: boolean,
  togglingInputFieldProps?: InputType,
  fill?: boolean,
  onChange?: (e: ChangeEvent) => {},
  isOptionalField?: boolean,
}

export interface InputType {
  type: WarehouseFormInputType.FILE
    | WarehouseFormInputType.NUMBER
    | WarehouseFormInputType.PASSWORD
    | WarehouseFormInputType.TEXT,
  id?: string,
  label: string,
  name: keyof initialFormDataType,
  cancels: string,
  color?: colorType,
  placeholder: string,
  gridArea: string,
  isPrivate: boolean,
  style: CSSProperties,
  disabled: boolean,
  value?: string,
  adminOnly?: boolean,
  isSecretField?: boolean,
  isEditingSecretField?: boolean,
  isOptionalField?: boolean,
  showHighlight?: boolean,
  highlightDescription?: string,
}

export interface DropdownOnChangeProps {
  setFormData: Dispatch<SetStateAction<initialFormDataType | undefined>>,
  selectedItem: string,
}

export interface DropdownType {
  type: WarehouseFormInputType.DROPDOWN,
  gridArea: string,
  label: string,
  menuItems: MenuTemplateProps['items'],
  name: keyof initialFormDataType,
  cancels: string,
  text: string,
  disabled: boolean,
  adminOnly?: boolean,
  isSecretField?: boolean,
  isEditingSecretField?: boolean,
  onChange?: ({
    setFormData,
    selectedItem,
  }: DropdownOnChangeProps) => {},
  isOptionalField?: boolean,
  maxHeight?: number,
  id?: string,
}

export interface ButtonType {
  type: WarehouseFormInputType.BUTTON,
  gridArea: string,
  text: string,
  onClick: (event: MouseEvent<HTMLElement>) => void,
  adminOnly?: boolean,
  name: keyof initialFormDataType,
  disabled: boolean,
  isSecretField?: boolean,
  isEditingSecretField?: boolean,
  isOptionalField?: boolean,
}

export interface MultiLineInputType {
  type: WarehouseFormInputType.MULTI_LINE_INPUT,
  gridArea: string,
  placeholder: string,
  onChange?: (e: string) => {},
  label: string,
  isPrivate: boolean,
  adminOnly: boolean,
  disabled: boolean,
  isEditingSecretField?: boolean,
  isOptionalField?: boolean,
  name: keyof initialFormDataType,
  isSecretField?: boolean,
  cancels: string,
  minLines?: number,
  maxLines?: number,
}
