import { useState } from 'react';
import { Product, useGetProductsAndPricesQuery } from '../../../../client/generated/service';
import { genericObject } from '../../../../utilis';
import { saveSubscriptionInfoToStore } from '../../../../app/hooks/useCheckPricingPlan';

export interface PricingProduct extends Omit<Product, 'isSandboxProduct'> {
  current: boolean,
}

export const usePricingPlans = () => {
  const [allProducts, setAllProducts] = useState<PricingProduct[]>([]);
  const [justPricingPlans, setJustPricingPlans] = useState<PricingProduct[]>([]);
  const [justAddons, setJustAddons] = useState<PricingProduct[]>([]);
  const [failureReason, setFailureReason] = useState('');

  useGetProductsAndPricesQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ productsAndPrices, customerSubscriptions }) => {
      saveSubscriptionInfoToStore(customerSubscriptions);

      if (productsAndPrices?.ok) {
        setFailureReason('');

        let products = productsAndPrices?.products as PricingProduct[] || [];
        const pricingPlans: PricingProduct[] = [];
        const addOns: PricingProduct[] = [];

        if (customerSubscriptions && customerSubscriptions?.ok) {
          const currentSubscriptionIds = customerSubscriptions.subscriptions?.map(
            ({ planName }) => planName,
          );

          products = products.map((product) => {
            const current = currentSubscriptionIds?.includes(product.planName) || false;
            if (product.isAddOn) {
              addOns.push({ ...product, current });
            } else {
              pricingPlans.push({ ...product, current });
            }

            return { ...product, current };
          });
        }

        setAllProducts(products);
        setJustPricingPlans(pricingPlans);
        setJustAddons(addOns);
      } else {
        setFailureReason("We weren't able to fetch your data right now. Please try again later or contact support.");
      }
    },
    onError: () => {
      setFailureReason("We weren't able to fetch your data right now. Please try again later or contact support.");
    },
  });

  return {
    allProducts,
    justPricingPlans,
    justAddons,
    currentPricingPlan: allProducts.find((o: genericObject) => (o.current)),
    failureReason,
  };
};
