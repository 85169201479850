import React from 'react';
import SearchMenuLabel from '../../components/Lineage/HomePage/SearchMenuLabel';
import { lineageSearchInputProps } from '../types';
import HightouchLogoSvg from './images/HightouchLogoSvg.svg';

export const lineageSearchInputDropdown = ({ nodeType }: lineageSearchInputProps) => {
  // Strip 'Hightouch' and 'Node' to get the type
  // e.g. HightouchViewNode -> View
  const text = nodeType.replace('Hightouch', '').replace('Node', '');

  const labelElement = (
    <SearchMenuLabel
      text={text}
      color="var(--teal70)"
    />
  );

  return {
    img: HightouchLogoSvg,
    labelElement,
  };
};
