import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import OptimizedCodeBox from '@paradime-io/pragma-ui-kit/lib/components/CodeBox/OptimizedCodeBox';
import { useGetPublicResourceUrlQuery } from '../../../../client/generated/service';

type DebugLogsProps = {
  commandResourceId: number
}

const DebugLogs: FC<DebugLogsProps> = ({
  commandResourceId,
}) => {
  const [logLines, setLogLines] = useState<string[]>(['...loading debug logs']);
  const { data, loading } = useGetPublicResourceUrlQuery({
    variables: {
      scheduleCommandResourceId: commandResourceId,
    },
  });
  const loadingText = ['...loading debug logs'];

  const handleDownloadLogs = useCallback(() => {
    if (data?.getPublicResourceUrl?.url) {
      window.open(data?.getPublicResourceUrl?.url);
    }
  }, [data]);

  useEffect(() => {
    if (data?.getPublicResourceUrl?.url) {
      const { url } = data.getPublicResourceUrl;

      fetch(url)
        .then((res) => res.blob())
        .then((blob) => blob.text())
        .then((text) => {
          const lines = text.split('\n');
          setLogLines(lines);
        });
    }
  }, [data]);

  return (
    <OptimizedCodeBox
      maxHeight={512}
      lines={loading ? loadingText : logLines}
      backgroundColor="var(--grey100)"
      colorStep="0"
      onCopyButtonClicked={handleDownloadLogs} // eslint-disable-line no-alert
      scrollToBottom
      copyButtonText="Download Logs"
    />
  );
};

export default DebugLogs;
