import { genericObject } from '@paradime/common/lib/common/genericTypes';
import { init } from 'commandbar';
import { History } from 'history';
import addEditorCommands from './editor';
import addLineageCommands from './lineage';
import addBoltCommands from './bolt';
import addSettingsCommands from './settings';
import commandBarCallbacks from './callbacks';
import addCatalogCommands from './catalog';
import addRadarCommands from './radar';

interface CommandBarInitProps {
  id: string,
  userAttributes: genericObject,
  history: History,
  showNewMessage: () => void,
  featureFlags?: { [key:string]: boolean },
  isExtensionOnlyCompany?: boolean,
  restartEditor: () => Promise<boolean>,
}

const CommandBarInit = ({
  id, userAttributes, history, showNewMessage, featureFlags, isExtensionOnlyCompany, restartEditor,
}: CommandBarInitProps) => {
  init('23e0e6fc');
  window.CommandBar.boot(
    id,
    {
      userAttributes,
      history,
    },
    {
      canOpenEditor: (userAttributes.emailDomain === 'paradime.io'),
    },
  );

  if (!isExtensionOnlyCompany) {
    // Only main-app users get access to most of the commands
    addEditorCommands({ restartEditor, history });
    addLineageCommands();
    addBoltCommands();
    addCatalogCommands(featureFlags);
    addRadarCommands(featureFlags);
  }

  // Some settings are suitable for extension-only users
  addSettingsCommands(showNewMessage, featureFlags, isExtensionOnlyCompany);
};

const CommandBarRouterInit = (history: History) => {
  const CommandBarRouterFunc = (newUrl: string) => history.push(newUrl);
  window.CommandBar.addRouter(CommandBarRouterFunc);
};

export { CommandBarInit, CommandBarRouterInit, commandBarCallbacks };
