import React, { FC } from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import Divider from '@paradime-io/pragma-ui-kit/lib/components/Divider';

export interface BillingItemRowProps {
  left: string,
  right?: string,
}

const BillingItemRow: FC<BillingItemRowProps> = ({
  left,
  right,
}) => (
  <AutoLayout
    direction="vertical"
    distribution="packed"
    alignment="top-left"
    padding="none"
    verticalGap="dense"
  >
    <AutoLayout
      direction="horizontal"
      distribution="space-between"
      alignment="center"
      padding="none"
      style={{ gridTemplateColumns: '1fr 1fr' }}
    >
      <Typography
        type="body-small"
        tagName="span"
        color="default"
        colorStep="100"
      >
        {left}
      </Typography>
      <Typography
        type="body-small"
        tagName="span"
        color="default"
        colorStep="40"
        style={{ textAlign: 'right' }}
      >
        {right || '-'}
      </Typography>
    </AutoLayout>
    <Divider color="default" colorStep="5" />
  </AutoLayout>
);

export default BillingItemRow;
