import React, {
  ChangeEvent, FC, useEffect, useState,
} from 'react';
import { driver } from 'driver.js';
import 'driver.js/dist/driver.css';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import BigButton from '@paradime-io/pragma-ui-kit/lib/components/BigButton';
import Input from '@paradime-io/pragma-ui-kit/lib/components/DefaultInput';
import Callout from '@paradime-io/pragma-ui-kit/lib/components/Callout';
import MultilineTextCopy from '@paradime-io/pragma-ui-kit/lib/components/MultilineTextCopy';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import Dots from '@paradime-io/pragma-ui-kit/lib/components/Dots';
import Divider from '@paradime-io/pragma-ui-kit/lib/components/Divider';
import { useHistory } from 'react-router-dom';
import { useLocationState, useUpdateLocationState } from '../../../utilis';
import { RepoDefinitions } from './Definitions';
import { companyStore } from '../../../stores';
import { useSetupGit, useRegenerateSshKey } from './hooks';
import { ONBOARDING_PAGE, OnboardingRoutePrefix } from '../../Onboarding';
import githubRepoHelperAVIF from './images/github-kvRMy6rwtCJ3U8VjVWqD.avifs';

interface RepoProviderProps {
  repoProviderNextStepUrl?: string,
  noRepoChosenUrl?: string,
}

const RepoProvider: FC<RepoProviderProps> = ({ repoProviderNextStepUrl, noRepoChosenUrl }) => {
  const [state] = useLocationState();
  const updateLocationState = useUpdateLocationState();
  const companyOnboardingData = companyStore((s) => s.companyOnboardingData);
  const { repoUrl: repoUrlFromState } = state;

  const { sshPublicKey } = companyOnboardingData!;

  const repoProvider = companyStore((s) => s.repoProvider);

  const [currentRepoUrl, setCurrentRepoUrl] = useState<string>(repoUrlFromState || '');

  const {
    repoIcon,
    repoImage,
    repoRegex,
    providerName,
    sshUrl,
    sshInviteCall,
    getRepoLink,
    helpArticleId,
  } = RepoDefinitions.get(repoProvider || 'unknown')!;

  const driverObj = driver({
    popoverClass: 'paradime-driver-theme',
    stagePadding: 0,
    onDestroyed: () => {
      const el = document?.activeElement as HTMLElement | undefined;
      el?.blur();
    },
  });

  useEffect(() => {
    if (!repoProvider) {
      let url = `/${OnboardingRoutePrefix}/${ONBOARDING_PAGE.CONNECT_REPO}`;
      if (noRepoChosenUrl) {
        url = noRepoChosenUrl;
      }
      history.push(url);
    }
  }, []);

  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const {
    currentSshKey,
    readyToConnect,
  } = useRegenerateSshKey(repoUrlFromState, sshPublicKey!, currentRepoUrl, setLoading, repoRegex);

  useEffect(() => {
    if (readyToConnect && repoProvider === 'github') {
      driverObj.destroy();
    }
  }, [readyToConnect]);

  const {
    checkGitConnection,
    connected,
    tested,
  } = useSetupGit(currentRepoUrl, setLoading);

  const testGitConnection = () => {
    setLoading(true);
    checkGitConnection({
      variables: {
        gitProvider: repoProvider!,
        uri: currentRepoUrl,
      },
    });
  };

  const continueToNextModalStep = () => {
    const { setIsGitSetupComplete } = companyStore.getState();
    setIsGitSetupComplete(true);
    updateLocationState({ repoUrl: currentRepoUrl });

    let url = `/${OnboardingRoutePrefix}/${ONBOARDING_PAGE.CONNECT_WAREHOUSE}`;
    if (repoProviderNextStepUrl) {
      url = repoProviderNextStepUrl;
    }
    history.push(url);
  };

  const labelOnClick = () => {
    if (tested) {
      return {
        onClick: continueToNextModalStep,
        text: 'Continue',
      };
    }
    if (connected) {
      return {
        text: 'Continue',
        onClick: testGitConnection,
      };
    }
    return {
      text: 'Try again',
      onClick: testGitConnection,
    };
  };

  return (
    <>
      <AutoLayout
        direction="vertical"
        padding="expanded"
        verticalGap="very-dense"
        distribution="packed"
        alignment="top-center"
        style={{ paddingTop: '2rem' }}
      >
        <Typography
          tagName="span"
          type="h4"
          color="default"
          colorStep="100"
          style={{ textAlign: 'center', margin: '0 auto 1rem auto' }}
        >
          Connect your dbt repository
        </Typography>
        <BigButton image={repoImage} selected fill />
        <Divider />
        <div style={{ display: 'flex' }}>
          <Typography
            font="inter"
            type="body-bold"
            color="default"
            colorStep="100"
          >
            Connect your dbt repository to Paradime using SSH
          </Typography>
          {helpArticleId && (
            <DefaultButton
              type="standard"
              view="smooth"
              color="primary_alt"
              text="More info"
              rightIcon="help"
              dense
              onClick={() => {
                window.CommandBar.openHelpHub({
                  articleId: helpArticleId,
                });
              }}
              eventContext={Contexts.ONBOARDING}
              eventObject="openGitHelp"
              eventAction={Actions.CLICKED}
              eventProperties={{ providerName }}
              style={{ marginLeft: 'auto' }}
            />
          )}
        </div>
        <Input
          id="git-repo-uri"
          data-testid="urlInput"
          type="text"
          label="Repository URI"
          view="outlined"
          color="default"
          value={currentRepoUrl}
          full
          sensitive
          success={readyToConnect}
          expose={(e: ChangeEvent<HTMLInputElement>) => setCurrentRepoUrl(e.target.value)}
          disabled={loading || tested}
          onFocus={() => {
            if (repoProvider === 'github') {
              driverObj.highlight({
                element: document.getElementById('git-repo-uri')!,
                popover: {
                  description: `<img src=${githubRepoHelperAVIF} style='height: 225px; width: 300px;' /><span style='font-size: 15px; display: block; margin-top: 10px; text-align: center;'>Copy the Git SSH url from your repo.</span>`,
                  align: 'center',
                  side: 'left',
                },
              });
            }
          }}
          onBlur={() => {
            if (repoProvider === 'github') driverObj.destroy();
          }}
          data-private
        />
        {
          readyToConnect && (
            <AutoLayout
              direction="vertical"
              padding="expanded"
              verticalGap="very-dense"
              distribution="packed"
              alignment="top-center"
              style={{ padding: '8px 0 0 0' }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                data-testid="readyToConnect"
              >
                <Typography
                  tagName="span"
                  type="body-small"
                  color="default"
                  colorStep="100"
                  style={{ display: 'flex', flexDirection: 'row' }}
                >
                  <img style={{ marginRight: '8px' }} src={repoIcon} alt={`${repoProvider} icon`} />
                  You are connecting to the repository:
                  {' '}
                  <a
                    style={{ color: 'var(--deeplink-blue)' }}
                    target="_blank"
                    rel="noreferrer"
                    href={getRepoLink(repoRegex, currentRepoUrl).url}
                  >
                    {getRepoLink(repoRegex, currentRepoUrl).text}
                  </a>
                </Typography>
              </div>
              <div style={{
                display: 'flex',
                alignItems: 'center',
              }}
              >
                <Typography tagName="span" type="body-small" color="default" colorStep="100">
                  Visit the
                  {' '}
                  <a
                    style={{ color: 'var(--deeplink-blue)' }}
                    target="_blank"
                    rel="noreferrer"
                    href={sshUrl(repoRegex, currentRepoUrl)}
                  >
                    {sshInviteCall}
                  </a>
                  {' '}
                  and add the SSH key from below.
                </Typography>
              </div>
              <MultilineTextCopy
                sensitive
                title="Deploy Key"
                text={currentSshKey}
                color="default"
                colorStep="40"
                eventContext={Contexts.ONBOARDING}
                data-private
                maxLines={3}
              />
              {!tested && !connected
                && (
                  <Callout
                    data-testid="noWriteAccess"
                    color="danger"
                    icon="warning-sign"
                    title="Oops! Something is not right"
                    content="The deploy key does not have write access. Make sure to check allow write access."
                    view="smooth"
                    dense
                  />
                )}
              {tested && connected
                && (
                  <Callout
                    data-testid="you're done"
                    color="success"
                    icon="info-sign"
                    title="You’re done!"
                    content="Git has now been set up and you are ready to commit and push your code."
                    view="smooth"
                    dense
                  />
                )}
            </AutoLayout>
          )
        }
        <DefaultButton
          data-testid="continueButton"
          {...labelOnClick()}
          fill={false}
          color="primary"
          view="filled"
          type="standard"
          loading={loading}
          className="bp4-intent-6"
          style={{
            margin: '1rem auto 0 auto',
            minWidth: '100px',
          }}
          eventContext={Contexts.MODAL}
          eventObject="gitRepo"
          eventAction={Actions.CONNECTED}
          eventProperties={{
            location: window.location.pathname?.split('/')?.filter((part) => part !== '')?.[0],
            type: repoProvider,
            buttonText: labelOnClick().text,
          }}
        />
      </AutoLayout>
      <Dots selected={2} total={3} />
    </>
  );
};

export default RepoProvider;
