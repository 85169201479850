const data = [
  ['DBTModelNode', 'Model'],
  ['DBTSeedNode', 'Seed'],
  ['DBTTestNode', 'Test'],
  ['DBTSnapshotNode', 'Snapshot'],
  ['DBTSourceNode', 'Source'],
  ['DBTAnalysisNode', 'Analysis'],
  ['DBTExposureNode', 'Exposure'],
];

export const lineageFilterNodes = () => ({
  title: 'DBT NODES',
  items: data.map((item) => ({ label: item, checked: true })),
});
