import { useEffect, useState } from 'react';
import {
  Position, Edge, Node, MarkerType,
} from 'reactflow';
import { colorType } from '@paradime-io/pragma-ui-kit/lib/components/styles/styleTypes';
import { IconName } from '@blueprintjs/icons';
import { CommandType } from '../Scheduler/Insights';
import { RunResultsTabId } from '../Scheduler/LogsAndFreshness';
import { boltStore } from '../../../stores';

const enum PositionConstants {
  NODE_XSTART = 0,
  NODE_YSTART = 50,
  NODE_XSTEP = 300,
  NODE_YSTEP = 0,
  EDGE_MARKER_WIDTH = 15,
  EDGE_MARKER_HEIGHT = 15,
  EDGE_MARKER_COLOR = '#19191d',
  EDGE_STROKE_WIDTH = 2,
  INITIAL_COMMANDS_TO_SKIP = 2,
}

export type CommandNodeType = {
  id: string,
  command: string,
  nodeType: string,
  color: colorType,
  icon: IconName,
}

const useGetCommandNodes = (commands?: (CommandType | null)[] | null) => {
  const { setRunResultsTabId, setExpandCommandId } = boltStore.getState();
  const [isLoading, setIsLoading] = useState(false);
  const [nodes, setNodes] = useState<Node[]>();
  const [edges, setEdges] = useState<Edge[]>();

  const getNodeIcon = (color?: colorType): IconName => {
    switch (color) {
      case 'success':
        return 'tick-circle';
      case 'danger':
        return 'error';
      default:
        return 'info-sign';
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (commands && commands.length > 0) {
      const commandNodes = commands.slice(PositionConstants.INITIAL_COMMANDS_TO_SKIP)
        .map((c, index) => ({
          id: c?.meta.uuid,
          type: 'CommandNode',
          position: {
            x: PositionConstants.NODE_XSTART + index * PositionConstants.NODE_XSTEP,
            y: PositionConstants.NODE_YSTART + index * PositionConstants.NODE_YSTEP,
          },
          data: {
            nodeName: c?.command,
            nodeColor: c?.state.colorType as colorType,
            icon: getNodeIcon(c?.state.colorType as colorType),
            iconColor: '',
            x: 25,
            y: 55,
            focussed: false,
            selected: false,
            onClick: () => {
              setRunResultsTabId(RunResultsTabId.LOGS);
              setExpandCommandId(c?.meta.id);
            },
            targetHandlePosition: Position.Left,
            sourceHandlePosition: Position.Right,
          },
        } as Node));
      setNodes(commandNodes);

      const commandEdges = commands.slice(PositionConstants.INITIAL_COMMANDS_TO_SKIP, -1)
        .map((c, index) => ({
          id: index.toString(),
          source: c?.meta.uuid,
          target: commands.slice(PositionConstants.INITIAL_COMMANDS_TO_SKIP)[index + 1]?.meta.uuid,
          style: {
            strokeWidth: PositionConstants.EDGE_STROKE_WIDTH,
          },
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: PositionConstants.EDGE_MARKER_WIDTH,
            height: PositionConstants.EDGE_MARKER_HEIGHT,
            color: PositionConstants.EDGE_MARKER_COLOR,
          },
          animated: true,
        } as Edge));
      setEdges(commandEdges);

      setIsLoading(false);
    }
  }, [commands]);

  return {
    isLoading,
    nodes,
    edges,
  };
};

export { useGetCommandNodes };
