import React, {
  FC,
  useState,
  useEffect,
} from 'react';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import TabsDefault from '@paradime-io/pragma-ui-kit/lib/components/TabsDefault';
import { Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import Card from '@paradime-io/pragma-ui-kit/lib/components/Card';
import { ApolloQueryResult } from '@apollo/client';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import { key } from '../../../utilis';
import { CommandProps } from './CommandLogs';
import SourceFreshnessCard, { SourceFreshnessCardProps } from './SourceFreshnessCard';
import { GetScheduleRunQuery } from '../../../client/generated/service';
import { boltStore } from '../../../stores';
import Artifacts from './Artifacts';
import SubscribedCommand from './CommandLogs/SubscribedCommand';
import CancelledCommand from './CommandLogs/CancelledCommand';

type NonNullCommand = NonNullable<CommandProps>

export interface LogsAndFreshnessProps {
  runData?: GetScheduleRunQuery,
  refreshData: () => Promise<ApolloQueryResult<GetScheduleRunQuery>>,
}

export enum RunResultsTabId {
  'LOGS' = 'logs',
  'SOURCE_FRESHNESS' = 'sourceFreshness',
  'ARTIFACTS' = 'artifacts'
}

const tabsData = [
  { id: RunResultsTabId.LOGS, title: 'Run logs', visible: true },
  { id: RunResultsTabId.SOURCE_FRESHNESS, title: 'Source Freshness', visible: true },
  { id: RunResultsTabId.ARTIFACTS, title: 'Artifacts', visible: true },
];

const LogsAndFreshness: FC<LogsAndFreshnessProps> = ({
  runData,
  refreshData,
}) => {
  const runResultsTabId = boltStore((s) => s.runResultsTabId);
  const setRunResultsTabId = boltStore((s) => s.setRunResultsTabId);

  const [commands, setCommands] = useState<NonNullCommand[]>();
  const [freshnessSources, setFreshnessSources] = useState<(SourceFreshnessCardProps | null)[]>();

  useEffect(() => {
    if (
      runData?.getScheduleRun?.commands
      && runData.getScheduleRun.freshnessSources
      && runData.getScheduleRun.meta
    ) {
      setCommands(runData.getScheduleRun.commands.filter(Boolean) as NonNullCommand[]);
      setFreshnessSources(runData.getScheduleRun.freshnessSources);
    }
  }, [runData]);

  const selectCommand = (command: NonNullCommand) => {
    if (!command) return [];

    if (command.state.text === 'Cancelled') {
      return (
        (
          <CancelledCommand
            key={command.meta.uuid}
            {...command}
          />
        )
      );
    }

    return (
      <SubscribedCommand
        key={command.meta.uuid}
        refreshData={refreshData}
        {...command}
      />
    );
  };

  return (
    <AutoLayout
      direction="vertical"
      padding="none"
      distribution="packed"
      verticalGap="dense"
      alignment="top-left"
      style={{ marginTop: '40px', marginBottom: '40px' }}
    >
      <Typography type="h6" tagName="span">Logs and Artifacts</Typography>
      <TabsDefault
        type="dense"
        view="flat"
        color="default"
        fill={false}
        data={tabsData}
        eventContext={Contexts.BOLT}
        currentTabId={runResultsTabId}
        exposeOnChange={(newTabId) => setRunResultsTabId(newTabId?.toString())}
      />
      {runResultsTabId === RunResultsTabId.LOGS && (
        commands && commands?.length > 0 ? (
          commands.map(selectCommand)
        ) : (null)
      )}
      {runResultsTabId === RunResultsTabId.SOURCE_FRESHNESS && (
        freshnessSources && freshnessSources.length > 0 ? (
          freshnessSources.map((source) => (
            source
              ? (
                <SourceFreshnessCard
                  key={key(`${source.sourceName}-${source.countStatus.text}`)}
                  {...source}
                />
              ) : []
          ))
        ) : (
          <Card tabIndex={-1}>
            <Typography
              font="inter"
              type="body-small"
              color="default"
              colorStep="100"
            >
              This run does not have any source freshness command configured.
            </Typography>
          </Card>
        )
      )}
      {
        runResultsTabId === RunResultsTabId.ARTIFACTS && (
          commands && commands?.length > 0 && (
            <Artifacts commands={commands} />
          )
        )
      }
    </AutoLayout>
  );
};

export default LogsAndFreshness;
