/* eslint-disable no-await-in-loop */
/* eslint-disable no-undef */
import { useState, useEffect } from 'react';
import { graphQLStore } from '../../stores';
import { useGetJwtToken } from '../../utilis';
import { useEditorHeartbeatMutation } from '../../client/generated/editor';

export const useSetCompanyOnCodeEditorIframe = () => {
  const [firstCompanyAlreadySent, setFirstCompanyAlreadySent] = useState(false);
  const [heartBeat] = useEditorHeartbeatMutation();
  const apolloClient = graphQLStore((s) => s.apolloClient);
  const { getToken } = useGetJwtToken();

  useEffect(() => {
    const sendCompanyToCodeEditorIframe = async () => {
      const jwtToken = await getToken();

      if (!(apolloClient?.link as any).options?.uri) { heartBeat(); }

      const codeEditorIframe = document.getElementById('codeEditorIframe') as HTMLIFrameElement;
      const { companyUrls, clusterConfig } = graphQLStore.getState();
      codeEditorIframe?.contentWindow?.postMessage({
        type: 'company-values',
        payload: {
          ...companyUrls,
          jwtToken,
          segmentKey: clusterConfig?.segmentKey,
          origin: window.location.origin,
        },
      }, '*');
    };

    const codeEditorIframeRequiresCompany = (e: MessageEvent) => {
      const { data: { type } } = e;
      if (type === 'require-company') {
        sendCompanyToCodeEditorIframe();
        setFirstCompanyAlreadySent(true);
        window.removeEventListener('message', codeEditorIframeRequiresCompany, false);
      }
    };
    window.addEventListener('message', codeEditorIframeRequiresCompany);

    let sendCompanyToCodeEditorIframeInterval: NodeJS.Timeout;

    if (firstCompanyAlreadySent) {
      sendCompanyToCodeEditorIframeInterval = setInterval(() => {
        sendCompanyToCodeEditorIframe();
      }, 20000);
    }

    return () => {
      window.removeEventListener('message', codeEditorIframeRequiresCompany, false);
      clearInterval(sendCompanyToCodeEditorIframeInterval);
    };
  }, [firstCompanyAlreadySent]);
};
