import { genericObject } from '@paradime/common/lib/common/genericTypes';

const addCatalogCommands = (featureFlags?: genericObject) => {
  // open catalog
  if (featureFlags?.flagAppCatalog) {
    window.CommandBar.addCommand({
      name: 'open_catalog_home',
      text: 'View data catalog',
      template: {
        type: 'link',
        value: '/catalog/search',
        operation: 'router',
      },
      category: 'App',
      explanation: 'Search your data docs',
      tags: ['dbt', 'data', 'catalog', 'assets', 'docs'],
    });
  }
};

export default addCatalogCommands;
