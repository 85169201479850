import { useContext } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TabData } from '@paradime-io/pragma-ui-kit/lib/components/TabsDefault';
import { RadarHelp } from '../Common/AppHelp/help';
import upgradeSchedules from './images/schedules.svg';
import upgradeModels from './images/models.svg';
import upgradeTests from './images/tests.svg';
import upgradeSources from './images/sources.svg';
import upgradeWork from './images/teamwork.svg';
import upgradeCost from './images/costs.svg';
import { RadarWorkspaceContext } from '.';

export enum RadarTab {
  SETUP = 'setup',
  SCHEDULES = 'schedules',
  MODELS = 'models',
  SOURCES = 'sources',
  TESTS = 'tests',
  WORK = 'work',
  COST = 'cost',
  BI_LOOKER = 'bi-looker',
  ALERTS = 'alerts',
}

export const getRadarPagesConfig = () => ({
  [RadarTab.SETUP]: {
    title: 'Quick setup',
    showHeaderButton: false,
  },
  [RadarTab.SCHEDULES]: {
    title: 'Schedules',
    helpArticleId: RadarHelp.SCHEDULES,
    showHeaderButton: false,
    videoUrl: 'https://youtu.be/R-icGowm-k8',
  },
  [RadarTab.MODELS]: {
    title: 'Models',
    helpArticleId: RadarHelp.MODELS,
    showHeaderButton: false,
    videoUrl: 'https://youtu.be/n8X2tx6DlEc',
  },
  [RadarTab.TESTS]: {
    title: 'Tests',
    helpArticleId: RadarHelp.TESTS,
    showHeaderButton: false,
    videoUrl: 'https://youtu.be/QHxD2wwzPyU',
  },
  [RadarTab.SOURCES]: {
    title: 'Sources',
    helpArticleId: RadarHelp.SOURCES,
    showHeaderButton: false,
    videoUrl: 'https://youtu.be/YXhP1obVy8Y',
  },
  [RadarTab.WORK]: {
    title: 'Team work',
    helpArticleId: RadarHelp.WORK,
    showHeaderButton: false,
    videoUrl: 'https://youtu.be/PgvCvtQp5Qg',
  },
  [RadarTab.COST]: {
    title: 'Costs',
    helpArticleId: RadarHelp.COST,
    showHeaderButton: false,
    tabSpecificVideoUrls: {
      [DashboardTabIds.COST_OPTIMISATIONS]: 'https://youtu.be/DDqb7MD-igc',
      [DashboardTabIds.SNOWFLAKE]: 'https://youtu.be/DVBmreZ905Y',
      [DashboardTabIds.BIGQUERY]: 'https://youtu.be/zEzmeHzROAM',
    },
  },
  [RadarTab.BI_LOOKER]: {
    title: 'Looker',
    helpArticleId: RadarHelp.COST,
    showHeaderButton: false,
    videoUrl: 'https://youtu.be/RRDKb0Vsa5Q',
  },
  [RadarTab.ALERTS]: {
    title: 'Data Alerts',
    helpArticleId: RadarHelp.COST,
    showHeaderButton: false,
    videoUrl: 'https://youtu.be/-nwHyq6323c',
  },
});

export const supportedCostWarehouses = [
  'snowflake',
  'bigquery',
];

export const upgradeImagePaths = {
  [RadarTab.SETUP]: {},
  [RadarTab.SCHEDULES]: {
    path: upgradeSchedules,
  },
  [RadarTab.MODELS]: {
    path: upgradeModels,
  },
  [RadarTab.TESTS]: {
    path: upgradeTests,
  },
  [RadarTab.SOURCES]: {
    path: upgradeSources,
  },
  [RadarTab.WORK]: {
    path: upgradeWork,
  },
  [RadarTab.COST]: {
    path: upgradeCost,
  },
  [RadarTab.BI_LOOKER]: {
    path: upgradeCost,
  },
};

export enum DashboardTabIds {
  OVERVIEW = 'overview',
  DETAILED = 'detailed',
  EXPLORES = 'explores',
  DASHBOARDS = 'dashboards',
  COST_OPTIMISATIONS = 'cost-optimisations',
  PULL_REQUESTS = 'pull-requests',
  COMMITS = 'commits',
  SNOWFLAKE = 'snowflake',
  BIGQUERY = 'bigquery',
  SNOWFLAKE_ALERTS = 'snowflake-alerts',
  BIGQUERY_ALERTS = 'bigquery-alerts',
}

export type RadarDashboardTabsType = Exclude<RadarTab, RadarTab.SETUP>;

type RadarTabsType = {[key in RadarTab]: TabData[]}

const overviewTab = { id: DashboardTabIds.OVERVIEW, title: 'Overview', visible: true };
const detailedTab = { id: DashboardTabIds.DETAILED, title: 'Detailed', visible: true };

export const useGetRadarTabsData = (): RadarTabsType => {
  const { flagRadarCostOptimisations } = useFlags();
  const { costWarehouseType } = useContext(RadarWorkspaceContext);

  const isSnowflakeWarehouse = costWarehouseType === 'snowflake';
  const isBigqueryWarehouse = costWarehouseType === 'bigquery';

  return ({
    [RadarTab.SCHEDULES]: [overviewTab, detailedTab],
    [RadarTab.MODELS]: [overviewTab, detailedTab],
    [RadarTab.SOURCES]: [overviewTab, detailedTab],
    [RadarTab.TESTS]: [overviewTab, detailedTab],
    [RadarTab.WORK]: [
      { id: DashboardTabIds.PULL_REQUESTS, title: 'Pull Requests', visible: true },
      { id: DashboardTabIds.COMMITS, title: 'Commits', visible: true },
    ],
    [RadarTab.COST]: [
      { id: DashboardTabIds.SNOWFLAKE, title: 'Monitoring', visible: isSnowflakeWarehouse },
      { id: DashboardTabIds.BIGQUERY, title: 'Overview', visible: isBigqueryWarehouse },
      { id: DashboardTabIds.COST_OPTIMISATIONS, title: 'Optimizations', visible: flagRadarCostOptimisations && isSnowflakeWarehouse },
      { id: DashboardTabIds.DETAILED, title: 'Details', visible: flagRadarCostOptimisations && isBigqueryWarehouse },
    ],
    [RadarTab.BI_LOOKER]: [
      {
        id: DashboardTabIds.EXPLORES, title: 'Explores', visible: true,
      },
      {
        id: DashboardTabIds.DASHBOARDS, title: 'Dashboards', visible: true,
      },
    ],
    [RadarTab.ALERTS]: [
      { id: DashboardTabIds.SNOWFLAKE_ALERTS, title: 'Snowflake', visible: true },
      { id: DashboardTabIds.BIGQUERY_ALERTS, title: 'BigQuery', visible: true },
    ],
    [RadarTab.SETUP]: [],
  });
};
