import React, { FC } from 'react';
import { Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import UpgradeTemplate from './UpgradeTemplate';
import { userAuthStore } from '../../../stores';
import { userHasBoltAdminAccess } from '../../../utilis/PermissionsService';
import BoltSchedulesImage from './images/BoltSchedules.svg';

const BoltSchedulesUpgrade: FC = () => {
  const { currentUser: { accessLevel } } = userAuthStore.getState();
  const isAdmin = userHasBoltAdminAccess(accessLevel);

  const upgradePlanName = 'Bolt plan';

  return (
    <div style={{ width: '80%', margin: 'auto', marginTop: '60px' }}>
      <UpgradeTemplate
        isAdmin={isAdmin}
        upgradePlanName={upgradePlanName}
        header="Ship dbt™ pipelines in seconds"
        bodyText="Run no-code dbt™ jobs, notify stakeholders, and sync any app without engineering resources."
        buttonClickEventContext={Contexts.BOLT}
        featureText={upgradePlanName}
        docsUrl="https://docs.paradime.io/app-help/bolt-scheduler/running-dbt-tm-in-production"
        upgradeImage={BoltSchedulesImage}
      />
    </div>
  );
};

export default BoltSchedulesUpgrade;
