import styled from 'styled-components';

export const timeframes = [
  { label: 'Today', value: 'today' },
  { label: 'Yesterday', value: 'yesterday' },
  { label: 'Last 7 days', value: 'from 7 days ago to now' },
  { label: 'Last 30 days', value: 'from 30 days ago to now' },
];

export const longerTimeframes = [
  { label: 'This week', value: 'this week' },
  { label: 'Last week', value: 'last week' },
  { label: 'This month', value: 'this month' },
  { label: 'Last month', value: 'last month' },
  { label: 'This quarter', value: 'this quarter' },
  { label: 'Last quarter', value: 'last quarter' },
];

export const selectAllDropdownOption = { label: 'All', value: 'all' };

export const testStatuses = ['pass', 'fail', 'error', 'warn'];
export const runStatuses = ['success', 'error', 'skipped'];
export const scheduleStatuses = ['Success', 'Error'];
export const freshnessStatuses = ['pass', 'error'];

// Graphql gets unhappy if you pass a variable, and then don't use it.
// When "selecting all", the variable is not used.
// So, when "selecting all", don't pass in the variable
export const getAllVars = (isSelectAll: boolean, variableName: string) => (
  isSelectAll ? '' : `, $${variableName}: String`
);

export const getAllWhere = (isSelectAll: boolean, variableName: string) => (
  isSelectAll ? '{set: true}' : `{equals: $${variableName}}`
);

export const getStatusColour = (status: string) => {
  switch (status.toLowerCase()) {
    case 'success':
    case 'pass':
      return 'var(--green50)';
    case 'failed':
    case 'fail':
      return 'var(--red50)';
    case 'error':
    case 'runtime error':
      return 'var(--red30)';
    case 'cancelled':
    case 'canceled':
    case 'warn':
    case 'skipped':
      return 'var(--yellow50)';
    default:
      return 'var(--grey50)';
  }
};

export const getTableChips = (status: string, count?: number) => {
  const tag = `${count ? `${count} ` : ''}${status}`;

  switch (status.toLowerCase()) {
    case 'success':
    case 'pass':
      return { tag, color: 'success' };
    case 'failed':
    case 'error':
    case 'fail':
    case 'runtime error':
      return { tag, color: 'danger' };
    case 'cancelled':
    case 'canceled':
    case 'warn':
    case 'skipped':
      return { tag, color: 'warning' };
    default:
      return { tag, color: 'default' };
  }
};

export const singleValueChartHeight = '160px';

export const HalfPageChartSection = styled.div<{ height?: string }>`
  width: calc(50% - 16px); // Column gap is 16px
  min-width: 200px;
  height: ${(props) => (props.height ? props.height : '320px')};

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const QuarterPageChartSection = styled.div<{ height?: string }>`
  width: calc(25% - 16px); // Column gap is 16px
  min-width: 200px;
  height: ${(props) => (props.height ? props.height : '320px')};

  @media (max-width: 1400px) {
    width: 48%;
  }

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const FullPageChartSection = styled.div<{ height?: string }>`
  width: calc(100% - 16px); // There's no column gap here, but to keep it level with the others...
  min-width: 200px;
  height: ${(props) => (props.height ? props.height : '320px')};
`;

export const getSingleValueChartString = (value: number, style?: any) => {
  const formatter = Intl.NumberFormat(
    'en',
    {
      style: style || 'decimal',
      // @ts-ignore - "notation" seems to be a fairly new feature
      notation: value > 10000 ? 'compact' : 'standard',
      useGrouping: true,
      maximumSignificantDigits: 4,
    },
  );
  return formatter.format(value);
};

export const getFormattedYAxisLabel = (value: number, notation?: any, style?: any) => {
  const formatterGreaterThanOne = Intl.NumberFormat(
    'end',
    {
      style: style || 'decimal',
      // @ts-ignore - "notation" seems to be a fairly new feature
      notation: notation || 'compact',
      useGrouping: true,
      maximumSignificantDigits: 4,
    },
  );

  const formatterLessThanOne = Intl.NumberFormat(
    'end',
    {
      style: style || 'decimal',
      // @ts-ignore - "notation" seems to be a fairly new feature
      notation: notation || 'compact',
      useGrouping: true,
      minimumFractionDigits: value < 1.0 ? 2 : 0,
      maximumFractionDigits: value < 1.0 ? 2 : 0,
    },
  );

  if (value > 1.0) {
    return formatterGreaterThanOne.format(value);
  }
  return formatterLessThanOne.format(value);
};
