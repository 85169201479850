import React, {
  FC, useState, Context, useContext,
} from 'react';
import { Icon } from '@blueprintjs/core';
import { ThemeContext } from 'styled-components';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Card from '@paradime-io/pragma-ui-kit/lib/components/Card';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { RadarSetupSteps } from '../Setup';
import { AccordionInlineChecklistProps } from '.';

export type ChecklistItemType = 'card' | 'divider';

export interface ChecklistItemProps {
  type: ChecklistItemType,
  id: RadarSetupSteps,
  title: string,
  subtitle?: string,
  primaryButtonText?: string,
  secondaryButtonText?: string,
}

const ChecklistItem: FC<ChecklistItemProps & { onClick: AccordionInlineChecklistProps['onClick'] }> = ({
  id,
  title,
  subtitle,
  primaryButtonText,
  secondaryButtonText,
  onClick,
}) => {
  const theme = useContext(ThemeContext as Context<any>);
  const darkMode = theme?.darkMode;
  const [isOpen, setIsOpen] = useState(false);

  const toggleChecklistStep = () => {
    setIsOpen((o) => (!o));
  };

  return (
    <Card
      style={{
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
      }}
      onClick={toggleChecklistStep}
      onEnterPressed={toggleChecklistStep}
      onEscapePressed={toggleChecklistStep}
      raiseOnHover
    >
      <AutoLayout
        direction="vertical"
        padding="none"
        verticalGap="very-dense"
        distribution="packed"
      >
        <AutoLayout
          direction="horizontal"
          padding="none"
          distribution="space-between"
          style={{ gridAutoColumns: 'unset' }}
        >
          <Typography tagName="span" type="body-bold-small" color="default" colorStep={darkMode ? '10' : '100'}>
            {title}
          </Typography>
          <div style={{ alignSelf: 'center' }}>
            <Icon
              icon={isOpen ? 'chevron-up' : 'chevron-down'}
              color="var(--icon)"
              size={24}
            />
          </div>
        </AutoLayout>
        {isOpen && (
          <>
            <Typography type="caption">{subtitle}</Typography>
            <AutoLayout
              direction="horizontal"
              padding="none"
              horizontalGap="very-dense"
              distribution="packed"
            >
              {secondaryButtonText && (
                <DefaultButton
                  type="standard"
                  view="outlined"
                  dense
                  color="primary"
                  text={secondaryButtonText}
                  onClick={() => onClick(id, false)}
                  eventContext={Contexts.BOLT}
                  eventObject={secondaryButtonText.replace(' ', '-')}
                  eventAction={Actions.CLICKED}
                />
              )}
              {primaryButtonText && (
                <DefaultButton
                  type="standard"
                  view="smooth"
                  dense
                  color="primary"
                  text={primaryButtonText}
                  onClick={() => onClick(id, true)}
                  eventContext={Contexts.BOLT}
                  eventObject={primaryButtonText.replace(' ', '-')}
                  eventAction={Actions.CLICKED}
                />
              )}
            </AutoLayout>
          </>
        )}
      </AutoLayout>
    </Card>
  );
};

export default ChecklistItem;
