import React, { FC } from 'react';
import ErrorCallout, { ErrorCalloutProps } from '@paradime-io/pragma-ui-kit/lib/components/ErrorCallout';
import { Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { colorType } from '@paradime-io/pragma-ui-kit/lib/components/styles/styleTypes';
import { GlobalErrorType, RunErrorType } from './ScheduleList';

export enum ErrorLevel {
  SCHEDULE_NAME = 'Schedule Name',
  SCHEDULE_RUN = 'Schedule Run',
  GLOBAL = 'Global',
}

interface ErrorsProps {
  error?: RunErrorType | null,
  globalError?: GlobalErrorType | null,
  errorLevel?: ErrorLevel
}

const Errors: FC<ErrorsProps> = ({ error, globalError, errorLevel }) => {
  if (!error && !globalError) return null;

  const errors: ErrorCalloutProps['errors'] = [];
  if (globalError) errors.push(globalError);
  if (error) errors.push(error);

  return (
    <ErrorCallout
      color={(globalError?.errorMessageSeverity?.colorType
        || error?.errorMessageSeverity.colorType) as colorType}
      title={globalError?.errorMessageTitle || error?.errorMessageTitle || `${errorLevel} Error`}
      errors={errors}
      eventContext={Contexts.BOLT}
      variant="button"
      buttonIcon="warning-sign"
      headerIcon="warning-sign"
      customButtonProps={{
        style: { width: '30px', height: '30px' },
      }}
      badgeValue={errors.length.toString()}
    />
  );
};

export default Errors;
