import React, { ChangeEvent, FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import DefaultInput from '@paradime-io/pragma-ui-kit/lib/components/DefaultInput';
import { Contexts, Actions } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { Callout } from '@paradime-io/pragma-ui-kit';
import { useCreateWorkspaceMutation } from '../../../../client/generated/service';
import AutoJoinCheckbox from '../../../Onboarding/AutoJoinCheckbox';
import { createWorkspaceRoutePrefix, CreateWorkspaceRoutes } from '.';
import { useCheckValidation, ValidationRules } from '../../../../utilis/useCheckValidation';

const EnterOrgName: FC = () => {
  const [workspaceName, setWorkspaceName] = useState('');
  const [validationError, setValidationError] = useState('');
  const [isAutoJoinEnabled, setIsAutoJoinEnabled] = useState(true);
  const [errorCannotCreateWorkspace, setErrorCannotCreateWorkspace] = useState(false);

  const history = useHistory();
  const { checkValidation } = useCheckValidation();

  const [createWorkspace] = useCreateWorkspaceMutation({
    onCompleted: (data) => {
      if (data.createWorkspace?.ok) {
        history.push(`/${createWorkspaceRoutePrefix}/${CreateWorkspaceRoutes.GETTING_STARTED}`);
      } else {
        setErrorCannotCreateWorkspace(true);
      }
    },
  });

  const handleWorkspaceNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { target: { value } } = e;

    const { hasError, errorMessages } = checkValidation({
      value,
      rules: [
        {
          rule: ValidationRules.NO_SPACES,
          message: 'Please enter a valid workspace name. Spaces are not allowed',
        },
        {
          rule: ValidationRules.ONLY_ALPHANUMERIC_HYPHEN_UNDERSCORE,
          message: 'Please enter a valid workspace name. Valid characters include A-z, 0-9, and (_-)',
        },
      ],
    });

    setValidationError(errorMessages[0] || '');
    if (!hasError) setWorkspaceName(value);
  };

  const handleCreateWorkspaceClick = () => {
    setErrorCannotCreateWorkspace(false);

    if (workspaceName.length > 0 && validationError.length === 0) {
      createWorkspace({
        variables: {
          workspaceName,
          isAutoJoinEnabled,
        },
      });
      return;
    }

    if (workspaceName.length === 0) {
      // Pressed the button without entering a workspace name
      setValidationError('Please enter a valid workspace name. Valid characters include A-z, 0-9, and (_-)');
    }
  };

  return (
    <AutoLayout
      direction="vertical"
      verticalGap="dense"
      distribution="packed"
    >
      <Typography
        font="inter"
        type="h4"
        color="default"
        colorStep="100"
        style={{ textAlign: 'center' }}
      >
        New Workspace
      </Typography>
      <DefaultInput
        type="text"
        autoFocus
        full
        sensitive
        label="Name"
        view="outlined"
        dataName="userName"
        value={workspaceName}
        expose={handleWorkspaceNameChange}
        onKeyPress={(e) => {
          if (e.code === 'Enter') {
            e.preventDefault();
            handleCreateWorkspaceClick();
          }
        }}
        color="default"
        data-testid="workspaceName"
        error={validationError.length > 0}
        errorHelperText={validationError}
      />
      <AutoJoinCheckbox
        onClick={setIsAutoJoinEnabled}
      />
      {errorCannotCreateWorkspace && (
        <Callout
          icon="warning-sign"
          view="smooth"
          color="danger"
          title="Oops! Something is not right."
          content="Sorry, we were unable to create a workspace. Please try again later or contact support if the issue persists."
          dense
        />
      )}
      <DefaultButton
        fill={false}
        color="primary"
        onClick={handleCreateWorkspaceClick}
        text="Create Workspace"
        view="filled"
        type="standard"
        className="bp4-intent-6"
        style={{
          margin: '1rem auto 0 auto',
        }}
        eventContext={Contexts.PLATFORM}
        eventObject="enterWorkspaceName"
        eventAction={Actions.CLICKED}
        eventProperties={{
          location: 'create-workspace',
          workspace_name: workspaceName,
          is_auto_join_enabled: isAutoJoinEnabled,
        }}
      />
      <Typography
        font="inter"
        type="button"
        color="default"
        colorStep="70"
        onClick={() => history.push('/workspaces')}
        style={{ textAlign: 'center', cursor: 'pointer' }}
      >
        Cancel
      </Typography>
    </AutoLayout>
  );
};

export default EnterOrgName;
