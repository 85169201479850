import { catalogueDescriptionProps } from '../types';

export const catalogueDescription = ({
  resultItem,
}: catalogueDescriptionProps) => {
  switch (resultItem.__typename) {
    case 'GQLCatalogueTableauDashboardItem':
    case 'GQLCatalogueTableauDatasourceItem':
    case 'GQLCatalogueTableauWorksheetItem':
      return resultItem.description || '';
    default:
      return '';
  }
};
