import { useEffect, useState } from 'react';
import { useSearchNodesLazyQuery } from '../../../client/generated/service-dataEndpoint';

const TYPING_DEBOUNCE_TIMEOUT_IN_MS = 250;

const useSearchNodesManager = (commitHash: string) => {
  const [query, setQuery] = useState('');

  const [searchNodes, { data: nodesData, loading: nodesDataLoading }] = useSearchNodesLazyQuery();

  useEffect(() => {
    const typing = setTimeout(() => {
      if (commitHash && query) {
        searchNodes({
          variables: {
            commitHash,
            query,
          },
        });
      }
    }, TYPING_DEBOUNCE_TIMEOUT_IN_MS);

    return () => clearTimeout(typing);
  }, [query, commitHash]);

  const hasDataSuccessfullyLoaded = !nodesDataLoading && nodesData?.searchLineage?.ok;

  return {
    handleDelayedQuery: (delayedQuery: string) => setQuery(delayedQuery),
    clearQuery: () => setQuery(''),
    query,
    hasDataSuccessfullyLoaded,
    currentNodes: hasDataSuccessfullyLoaded ? nodesData?.searchLineage?.results : undefined,
    hasNoMatches: hasDataSuccessfullyLoaded && (nodesData?.searchLineage?.results?.length === 0),
  };
};

export default useSearchNodesManager;
