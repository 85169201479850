import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import LineageControlButton from '@paradime-io/pragma-ui-kit/lib/components/LineageControlButton';
import Chips from '@paradime-io/pragma-ui-kit/lib/components/Chips';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { IconName } from '@blueprintjs/core';
import {
  GetGitBranchInfoQuery,
  useOrganisationIntegrationListQuery,
  GqlParadimeAccountType,
} from '../../client/generated/service';
import {
  BranchDiffLineageQuery,
  ComputeLineageQuery,
} from '../../client/generated/service-dataEndpoint';
import Compare from './HomePage/Compare';
import Search from './HomePage/Search';
import ControllerDropdownReset from './HomePage/ControllerDropdownReset';
import NodeFilter from './HomePage/NodeFilter';
import PackageFilter from './HomePage/PackageFilter';
import TagsFilter from './HomePage/TagsFilter';
import { LineageHelp } from '../Common/AppHelp/help';
import { AllChecked, checkType } from '../Lineage/utils';
import useResetGraph from './hooks/useResetGraph';
import useLineageChangeListener from './hooks/useLineageChangeListener';
import { lineageFilterNodeReturnType } from '../../integrations/types';
import { IntegrationAcceptedTypes, integrationHandlerManager } from '../../integrations';
import { userHasLineageCompareAccess, userHasLineageSearchAccess } from '../../utilis/PermissionsService';
import { openHelpHub } from '../Common/AppHelp/utils';

type currentOptions = 'none' | 'search' | 'compare' | 'nodes' | 'packages' | 'tags';

export interface CanvasControlProps {
  initialTab: currentOptions,
  branchesData: GetGitBranchInfoQuery,
  initialLookerStatus: boolean,
  initialPackageChecks: checkType[],
  setCurrentBranch: (newBranch: string) => void, // eslint-disable-line
  packagesFilter: string[],
  setPackagesFilter: Dispatch<SetStateAction<string[]>>,
  setTagsFilter: Dispatch<SetStateAction<string[]>>,
  nodesFilter: string[],
  tagsFilter: string[],
  setNodesFilter: Dispatch<SetStateAction<string[]>>,
  currentBranch: string,
  lineageData: ComputeLineageQuery | BranchDiffLineageQuery,
  accessLevel: GqlParadimeAccountType,
  integrationsList: IntegrationAcceptedTypes[],
}

const CanvasControl: FC<CanvasControlProps> = ({
  initialTab,
  branchesData,
  setCurrentBranch,
  currentBranch,
  initialLookerStatus,
  nodesFilter,
  tagsFilter,
  setNodesFilter,
  initialPackageChecks,
  packagesFilter,
  setPackagesFilter,
  setTagsFilter,
  lineageData,
  accessLevel,
  integrationsList,
}) => {
  const [selection, setSelection] = useState<currentOptions>(initialTab);
  const [hovered, setHovered] = useState<currentOptions>('none');
  const parentRef = useRef<HTMLDivElement>(null);
  const [branches, setBranches] = useState<Map<string, string>>();
  const [currentLookerStatus, setCurrentLookerStatus] = useState(initialLookerStatus);
  const [packageChecks, setPackageChecks] = useState(initialPackageChecks);
  const [tagsChecks, setTagsChecks] = useState<checkType[]>([]);
  const [initialTagsChecks, setInitialTagsChecks] = useState<checkType[]>([]);
  const { data: integrationsData } = useOrganisationIntegrationListQuery();

  const resetAllFilters = () => {
    resetTagsChecks();
    resetPackagesChecks();
    resetNodesChecks();
  };

  const { reset } = useResetGraph(resetAllFilters);

  useLineageChangeListener(resetAllFilters);

  const getDropdownMenuItems = () => {
    const menuItems: lineageFilterNodeReturnType['items'] = [];
    const menuItemsWithTitles: lineageFilterNodeReturnType[] = [];

    integrationsList.forEach((integration) => {
      const chosenIntegrationHandler = integrationHandlerManager(integration);
      if (chosenIntegrationHandler) {
        const integrationMenuItem = chosenIntegrationHandler.handlers.lineageFilterNodes({
          integration,
        });

        if (integrationMenuItem.items.length > 0) {
          menuItems.push(...integrationMenuItem.items);
          menuItemsWithTitles.push(integrationMenuItem);
        }
      }
    });
    return { menuItems, menuItemsWithTitles };
  };

  const [initialNodeChecks, setInitialNodeChecks] = useState<lineageFilterNodeReturnType[]>([]);
  const [nodeTypeChecks, setNodeTypeChecks] = useState<
  lineageFilterNodeReturnType[]
  >([]);

  useEffect(() => {
    setInitialNodeChecks(getDropdownMenuItems().menuItemsWithTitles);
  }, []);

  useEffect(() => {
    setNodeTypeChecks(initialNodeChecks);
  }, [initialNodeChecks]);

  useEffect(() => {
    const tags = () => {
      if ((lineageData as ComputeLineageQuery).computeLineage?.ok) {
        return (lineageData as ComputeLineageQuery)
          .computeLineage!.results.map((result) => result.tags || [])
          .flat();
      }
      return (lineageData as BranchDiffLineageQuery)
        .branchDiffLineage!.results.map((result) => result.left?.tags || result.right?.tags || [])
        .flat();
    };

    const formatTags = [...new Set(tags())].map((tag) => [tag, tag]);

    const currentTags = AllChecked(formatTags);
    setTagsChecks(currentTags);
    setInitialTagsChecks(currentTags);
  }, []);

  useEffect(() => {
    setCurrentLookerStatus(integrationsData?.organisationIntegrationList.looker === 'active');
  }, [integrationsData]);

  const resetNodesChecks = () => {
    setNodeTypeChecks(initialNodeChecks);
    setNodesFilter(['All selected']);
  };

  const resetPackagesChecks = () => {
    setPackageChecks(initialPackageChecks);
    setPackagesFilter(['All selected']);
  };

  const resetTagsChecks = () => {
    setTagsChecks(initialTagsChecks);
    setTagsFilter(['All selected']);
  };

  useEffect(() => {
    if (branchesData?.getGitBranchInfo?.ok) {
      const mapCurrentBranches: [string, string][] = branchesData
        .getGitBranchInfo
        .branches!.map((branch) => [branch.name, branch.commitHash]);

      setBranches(new Map(mapCurrentBranches));
    }
  }, [branchesData]);

  const buttonTypeANDaction = (name: currentOptions) => {
    if (selection === name) {
      if (hovered === name) {
        return {
          action: () => setSelection('none'),
          buttonType: 'simple' as 'simple' | 'default' | 'raised',
          text: 'go back',
          icon: 'undo' as IconName,
          selected: false,
        };
      }
      return {
        action: () => setSelection('none'),
        buttonType: 'default' as 'simple' | 'default' | 'raised',
        text: name,
      };
    }
    return {
      action: () => {
        setSelection(name);
        setHovered(name);
      },
      buttonType: 'default' as 'simple' | 'default' | 'raised',
    };
  };

  const width = useMemo(() => {
    if (selection === 'none') {
      return userHasLineageCompareAccess(accessLevel)
        ? 473
        : 394;
    }

    return 490;
  }, [selection, accessLevel]);

  return (
    <AutoLayout
      direction="vertical"
      padding="none"
      distribution="packed"
      verticalGap="very-dense"
    >
      <Chips
        color="primary_alt"
        round={false}
        style={{
          margin: '4px',
          verticalAlign: 'bottom',
          justifySelf: 'end',
          position: 'absolute',
          bottom: '68px',
          left: '16px',
          zIndex: 1000,
        }}
        tag={(selection === 'compare') ? 'How to compare lineage?' : 'How to search, compare and filter lineage'}
        type="dense"
        view="smooth"
        onClick={() => ((selection === 'compare')
          ? openHelpHub({ articleId: LineageHelp.COMPARE_LINEAGE })
          : openHelpHub({ articleId: LineageHelp.SEARCH_LINEAGE })
        )}
      />
      <div
        data-testid="control"
        ref={parentRef}
        style={{
          background: 'var(--white)',
          height: '53px',
          boxShadow: 'var(--greyShadow2dp)',
          borderRadius: '8px',
          position: 'absolute',
          bottom: '16px',
          left: '16px',
          width,
          zIndex: 1000,
        }}
      >
        <AutoLayout
          direction="horizontal"
          padding="very-dense"
          style={{
            paddingRight: '16px',
            alignItems: 'center',
          }}
          distribution="packed"
          alignment="left"
        >
          {['search', 'none'].includes(selection)
            && (
              <LineageControlButton
                text="Search"
                icon="search"
                selected={selection === 'search'}
                {...buttonTypeANDaction('search')}
                onHover={(ishovered) => setHovered(ishovered ? 'search' : 'none')}
                eventContext={Contexts.LINEAGE}
                eventObject="searchControlButton"
                eventAction={Actions.SEARCHED}
              />
            )}
          {userHasLineageCompareAccess(accessLevel)
            && ['compare', 'none'].includes(selection)
            && (
              <LineageControlButton
                text="Compare"
                icon="changes"
                selected={selection === 'compare'}
                {...buttonTypeANDaction('compare')}
                onHover={(ishovered) => setHovered(ishovered ? 'compare' : 'none')}
                eventContext={Contexts.LINEAGE}
                eventObject="compareControlButton"
                eventAction={Actions.COMPARED}
              />
            )}
          {['nodes', 'none'].includes(selection)
            && (
              <LineageControlButton
                text="Nodes"
                icon="data-lineage"
                selected={selection === 'nodes'}
                {...buttonTypeANDaction('nodes')}
                onHover={(ishovered) => setHovered(ishovered ? 'nodes' : 'none')}
                eventContext={Contexts.LINEAGE}
                eventObject="nodesControlButton"
                eventAction={Actions.CLICKED}
              />
            )}
          {['packages', 'none'].includes(selection)
            && (
              <LineageControlButton
                text="Packages"
                icon="cube"
                selected={selection === 'packages'}
                {...buttonTypeANDaction('packages')}
                onHover={(ishovered) => setHovered(ishovered ? 'packages' : 'none')}
                eventContext={Contexts.LINEAGE}
                eventObject="packagesControlButton"
                eventAction={Actions.CLICKED}
              />
            )}
          {['tags', 'none'].includes(selection)
            && (
              <LineageControlButton
                disabled
                text="Tags"
                icon="tag"
                selected={selection === 'tags'}
                {...buttonTypeANDaction('tags')}
                onHover={(ishovered) => setHovered(ishovered ? 'tags' : 'none')}
                eventContext={Contexts.LINEAGE}
                eventObject="tagsControlButton"
                eventAction={Actions.CLICKED}
              />
            )}
          {
            selection === 'none'
            && (
              <LineageControlButton
                text="Reset"
                icon="reset"
                action={reset}
                buttonType="raised"
                eventContext={Contexts.LINEAGE}
                eventObject="resetControlButton"
                eventAction={Actions.CLICKED}
              />
            )
          }
          {selection === 'search' && (
            <Search
              initialBranch={branchesData.getGitBranchInfo?.remoteMainBranch?.name!}
              branches={branches!}
              position="top"
              inputWidth={userHasLineageSearchAccess(accessLevel)
                ? 248
                : 385}
              dropdownWidth={122}
              dropDownContentPosition="top"
              currentBranch={currentBranch}
              setCurrentBranch={setCurrentBranch}
              accessLevel={accessLevel}
            />
          )}
          {selection === 'compare' && (
            <Compare
              branches={branches!}
              initialLeftBranch={branchesData?.getGitBranchInfo?.remoteMainBranch?.name || ''}
              initialRightBranch={branchesData.getGitBranchInfo?.branches![0].name || ''}
              dropDownContentPosition="top"
              setCurrentBranch={setCurrentBranch}
            />
          )}
          {
            selection === 'nodes' && (
              <ControllerDropdownReset
                currentDropdDown={nodesFilter}
                resetChecks={resetNodesChecks}
                filterComponent={(
                  <NodeFilter
                    nodeTypeChecks={nodeTypeChecks}
                    setNodeTypeChecks={setNodeTypeChecks}
                    lookerStatus={currentLookerStatus}
                    setNodesFilter={setNodesFilter}
                  />
                )}
              />
            )
          }
          {
            selection === 'packages' && (
              <ControllerDropdownReset
                currentDropdDown={packagesFilter}
                resetChecks={resetPackagesChecks}
                filterComponent={(
                  <PackageFilter
                    packageChecks={packageChecks}
                    setPackageChecks={setPackageChecks}
                    setPackagesFilter={setPackagesFilter}
                  />
                )}
              />
            )
          }
          {
            selection === 'tags' && (
              <ControllerDropdownReset
                currentDropdDown={tagsFilter}
                resetChecks={resetTagsChecks}
                filterComponent={(
                  <TagsFilter
                    setTagsChecks={setTagsChecks}
                    setTagsFilter={setTagsFilter}
                    tagsChecks={tagsChecks}
                  />
                )}
              />
            )
          }
        </AutoLayout>
      </div>
    </AutoLayout>
  );
};

export default CanvasControl;
