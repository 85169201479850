import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { graphQLStore, userAuthStore } from '../stores';

export const useGetJwtToken = () => {
  const {
    getIdTokenClaims, getAccessTokenSilently, user,
  } = useAuth0();
  const currentUser = userAuthStore((s) => s.currentUser);

  const getToken = async () => {
    const { paradimeOrganisation } = graphQLStore.getState();

    const options = {
      audience: `https://${paradimeOrganisation?.auth0ApiDomain}/api/v2/`,
      scope: 'openid profile email',
      paradimeOrganization: paradimeOrganisation?.name || '',
    };

    if (user?.email || currentUser?.email) {
      // this call refreshes the token if needed
      await getAccessTokenSilently({ authorizationParams: options });
    }

    const idTokenClaims = await getIdTokenClaims(options);

    // eslint-disable-next-line no-underscore-dangle
    const newToken = idTokenClaims?.__raw;

    if (newToken) return (newToken);

    return '';
  };

  return {
    getToken,
  };
};

export const getLocalAuth0 = () => {
  const { paradimeOrganisation } = graphQLStore.getState();

  const auth0ApiDomain = paradimeOrganisation?.auth0ApiDomain || '';
  const auth0ClientId = paradimeOrganisation?.auth0ClientId || '';

  const authLocalStorageKey = `@@auth0spajs@@::${auth0ClientId}::https://${auth0ApiDomain}/api/v2/::openid profile email offline_access`;
  const auth0LocalStorage = window.localStorage.getItem(authLocalStorageKey);

  return {
    authLocalStorageKey,
    auth0LocalStorage,
  };
};

type postLoginToChromeExtensionArgs = {
  skipWindowClose?: boolean,
}
export const usePostLoginToChromeExtension = (args?: postLoginToChromeExtensionArgs) => {
  const { skipWindowClose } = args || {};
  const { auth0LocalStorage, authLocalStorageKey } = getLocalAuth0();
  const parsedLocalAuth0 = JSON.parse(auth0LocalStorage || '{}');
  const { body } = parsedLocalAuth0;
  const { decodedToken } = body || {};
  const { user } = decodedToken || {};
  const { email, name } = user || {};

  const { companyToken } = graphQLStore.getState();

  const paradimeOrganisationFromStore = graphQLStore((state) => state.paradimeOrganisation);

  useEffect(() => {
    window.postMessage({
      type: 'EXTENSION_LOGIN_SUCCESS',
      payload: {
        key: authLocalStorageKey,
        value: auth0LocalStorage,
        skipWindowClose,
        selectedParadimeOrganisation: JSON.stringify(paradimeOrganisationFromStore),
        properties: {
          email,
          name,
          companyToken,
        },
      },
    }, '*');
  }, []);
};
