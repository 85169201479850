import React, {
  FC, useState, useEffect,
} from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import TextWithTooltip from '@paradime-io/pragma-ui-kit/lib/components/TextWithTooltip';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import { useHistory } from 'react-router-dom';
import Dropdown from '@paradime-io/pragma-ui-kit/lib/components/Dropdown';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import Checkbox from '@paradime-io/pragma-ui-kit/lib/components/CheckBox';
import { MenuTemplate } from '@paradime-io/pragma-ui-kit/lib/components/Menu';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import Dots from '@paradime-io/pragma-ui-kit/lib/components/Dots';
import { triggerAlert } from '../../../../utilis';
import {
  alertStore,
  graphQLStore,
  companyStore,
} from '../../../../stores';
import {
  useCompanyOnboardingMutation,
  CompanyOnboardingMutationVariables,
} from '../../../../client/generated/control-plane';
import PageTemplate from '../Utils/PageTemplate';
import { useWaitForCompanyToBeActive } from '../hooks/useWaitForCompanyToBeActive';
import { ONBOARDING_PAGE, OnboardingRoutePrefix } from '../..';

const WorkspaceSettings: FC = () => {
  const clusterConfig = graphQLStore((s) => s.clusterConfig);
  const { supportedDbtVersions } = clusterConfig!;

  const [loading, setLoading] = useState(false);
  const setCompanyOnboardingData = companyStore((s) => s.setCompanyOnboardingData);
  const setSnowflakeCallbackUrl = companyStore((state) => state.setSnowflakeCallbackUrl);
  const setBigqueryCallbackUrl = companyStore((state) => state.setBigqueryCallbackUrl);
  const setCompanyRegion = companyStore((state) => state.setCompanyRegion);

  const history = useHistory();
  const closeAlert = alertStore((state) => state.closeAlert);

  const [dbtVersion, setDbtVersion] = useState(clusterConfig!.supportedDbtVersions[0]);
  const [dbtPartialParsing, setDbtPartialParsing] = useState(true);
  const [couldNotOnboardCompany, setCouldNotOnboardCompany] = useState(false);

  const url = new URL(window.location.href);
  const onboardingToken = url.searchParams.get('onboarding_token');

  const {
    waitForCompanyToBeActive,
    hasStartedSuccessfully,
    hasError,
    activeCompanyData,
    isWaitingForCompanyToStart,
  } = useWaitForCompanyToBeActive();

  const [
    createCompany,
    { data: companyData },
  ] = useCompanyOnboardingMutation();

  const saveAndContinue = () => {
    history.push(`/${OnboardingRoutePrefix}/${ONBOARDING_PAGE.CONNECT_REPO}`);
  };

  useEffect(() => {
    setLoading((isLoading) => isLoading || isWaitingForCompanyToStart);
  }, [isWaitingForCompanyToStart, loading]);

  useEffect(() => {
    if (hasStartedSuccessfully) {
      setSnowflakeCallbackUrl(activeCompanyData?.isCompanyActive?.snowflakeCallbackUrl || '');
      setBigqueryCallbackUrl(activeCompanyData?.isCompanyActive?.bigqueryCallbackUrl || '');
      setCompanyRegion(activeCompanyData?.isCompanyActive?.region || '');
      saveAndContinue();
    }
  }, [hasStartedSuccessfully]);

  /** trigger a toast if the company fails to start */
  useEffect(() => {
    if (hasError || couldNotOnboardCompany) {
      setLoading(false);

      triggerAlert({
        color: 'danger',
        message: 'Something when wrong, please contact your Paradime admin.',
        title: 'Removing repository connection',
        rightButtonText: 'Oops!',
        showLeftButton: false,
        onRightButtonClick: () => {
          closeAlert();
        },
        rightButtonEventData: {
          eventAction: Actions.VIEWED,
          eventContext: Contexts.ONBOARDING,
          eventObject: 'companyFailedToStart',
        },
      });
    }
  }, [hasError, couldNotOnboardCompany]);

  useEffect(() => {
    if (companyData) {
      if (companyData?.companyOnboarding?.ok) {
        setCompanyOnboardingData(companyData.companyOnboarding);

        waitForCompanyToBeActive({
          companyOnboardingToken: companyData.companyOnboarding.token!,
          supervisingControlPlaneUrl: companyData.companyOnboarding.supervisingControlPlaneUrl!,
        });
      } else {
        setCouldNotOnboardCompany(true);
        setLoading(false);
      }
    }
  }, [companyData]);

  const requestDeployKey = async () => {
    setLoading(true);
    createCompany({
      variables: {
        dbtVersion,
        onboardingToken,
        dbtPartialParsing,
        region: '',
      } as CompanyOnboardingMutationVariables,
    });
  };

  return (
    <PageTemplate>
      <>
        <AutoLayout
          direction="vertical"
          padding="expanded"
          verticalGap="very-dense"
          distribution="packed"
          alignment="top-center"
          style={{ paddingTop: '2rem' }}
        >
          <Typography
            tagName="span"
            type="h4"
            color="default"
            colorStep="100"
            style={{ textAlign: 'center', margin: '0 auto 1rem auto' }}
          >
            Workspace Settings
          </Typography>
          <TextWithTooltip
            value="Select the dbt version for your workspace"
            info="Choose the dbt core version for your workspace."
            type="body-bold"
          />
          <Dropdown
            disabled={loading}
            label="dbt-core version"
            content={(
              <MenuTemplate
                items={supportedDbtVersions.map((version) => ({
                  text: version,
                  type: 'item',
                  onClick: () => setDbtVersion(version),
                }))}
                eventContext={Contexts.MODAL}
              />
            )}
            view={loading ? 'smooth' : 'raised'}
            fill
            height={200}
            text={dbtVersion}
            color="default"
          />
          <Checkbox
            checked={dbtPartialParsing}
            onChange={(e) => setDbtPartialParsing(e.currentTarget.checked)}
            color="default"
            label="Enable partial parsing"
            view="flat"
            type="dense"
            disabled={loading}
            eventContext={Contexts.MODAL}
            eventObject="enablePartialParsing"
            eventAction={Actions.CLICKED}
          />
          <DefaultButton
            fill={false}
            color="primary"
            onClick={requestDeployKey}
            text="Continue"
            view="filled"
            type="standard"
            disabled={loading}
            loading={loading}
            className="bp4-intent-6"
            style={{
              margin: '1rem auto 0 auto',
            }}
            eventContext={Contexts.MODAL}
            eventObject="workspaceSettings"
            eventAction={Actions.FILLED}
            eventProperties={{
              location: 'onboarding',
              dbt_version: dbtVersion,
            }}
          />
        </AutoLayout>
        <Dots selected={1} total={3} />
      </>
    </PageTemplate>
  );
};

export default WorkspaceSettings;
