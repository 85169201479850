import React, { FC, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import Checkbox from '@paradime-io/pragma-ui-kit/lib/components/CheckBox';
import { Contexts, Actions } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { userAuthStore } from '../../stores';

export interface AutoJoinCheckboxProps {
  isDisabled?: boolean,
  onClick?: (newValue: boolean) => void,
}

const AutoJoinCheckbox: FC<AutoJoinCheckboxProps> = ({
  isDisabled,
  onClick,
}) => {
  const [currentAutoJoinStatus, setCurrentAutoJoinStatus] = useState(true);

  const { user } = useAuth0();
  const userEmailFromAuth0 = user?.email;

  const currentUser = userAuthStore((s) => s.currentUser);
  const userEmailFromStore = currentUser.email;

  // Preferentially use the auth0 data first
  // But if it is not there, use the email from the store as a backup
  const userDomain = (userEmailFromAuth0 || userEmailFromStore)?.split('@')[1] || '';

  return (
    <AutoLayout
      direction="horizontal"
      distribution="packed"
      horizontalGap="very-dense"
      padding="none"
    >
      <Checkbox
        view="flat"
        color="default"
        eventContext={Contexts.ONBOARDING}
        eventObject="autoJoinDomain"
        eventAction={Actions.CLICKED}
        data-testid="autoJoinStatus"
        checked={currentAutoJoinStatus}
        onChange={(e) => {
          const { currentTarget: { checked } } = e;
          setCurrentAutoJoinStatus(checked);
          if (onClick) onClick(checked);
        }}
        disabled={isDisabled}
      />
      <Typography type="body-bold-small">
        Let other people sign up with their verified
        {' '}
        <b>{userDomain}</b>
        {' '}
        email address
      </Typography>
    </AutoLayout>
  );
};

export default AutoJoinCheckbox;
