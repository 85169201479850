import create from 'zustand';
import { combine } from 'zustand/middleware';
import { ChangelogItemType } from '../components/Home/hooks/useGetChangelog';

export const homeStore = create(
  combine(
    {
      changelog: undefined as ChangelogItemType[] | undefined,
      modelsCount: undefined as string | number | undefined,
      testsCount: undefined as string | number | undefined,
      macrosCount: undefined as string | number | undefined,
      schedulesCount: undefined as string | number | undefined,
    },
    (set) => ({
      setChangelog: (changelog: ChangelogItemType[]) => set(() => ({ changelog })),
      setModelsCount: (modelsCount: string | number) => set(() => ({ modelsCount })),
      setTestsCount: (testsCount: string | number) => set(() => ({ testsCount })),
      setMacrosCount: (macrosCount: string | number) => set(() => ({ macrosCount })),
      setSchedulesCount: (schedulesCount: string | number) => set(() => ({ schedulesCount })),
    }),
  ),
);
