import React, {
  FC,
  useEffect,
  useState,
  DetailedHTMLProps,
  HTMLAttributes,
} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Loader } from '@paradime-io/pragma-ui-kit';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import { useGetStripeClientReferenceIdMutation } from '../../../../client/generated/control-plane';
import { graphQLStore, userAuthStore } from '../../../../stores';
import paradimeLogo from '../../../../images/paradime-logo-with-text.svg';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

const StripePaymentPage: FC = () => {
  const { user } = useAuth0();
  const currentUser = userAuthStore((s) => s.currentUser);
  const paradimeOrganisationFromStore = graphQLStore((state) => state.paradimeOrganisation);
  const clusterConfigFromStore = graphQLStore((state) => state.clusterConfig);

  const [clientReferenceId, setClientreferenceId] = useState<string>();

  const [getStripeClientId] = useGetStripeClientReferenceIdMutation({
    onCompleted: ({ getStripeClientReferenceId }) => {
      if (getStripeClientReferenceId?.ok) {
        setClientreferenceId(getStripeClientReferenceId.stripeClientReferenceId);
      } else { // eslint-disable-next-line no-console
        console.error('Failed to get stripe client id:', getStripeClientReferenceId?.errorString);
      }
    },
  });

  const pricingTableId = clusterConfigFromStore!.stripePricingTable;
  const publishableKey = clusterConfigFromStore!.stripePublishableKey;

  useEffect(() => {
    if (paradimeOrganisationFromStore?.name) {
      getStripeClientId({
        variables: {
          orgName: paradimeOrganisationFromStore.name,
        },
      });
    }
  }, [paradimeOrganisationFromStore]);

  if (clientReferenceId) {
    return (
      <AutoLayout
        direction="vertical"
        verticalGap="dense"
        padding="none"
        alignment="center"
        distribution="space-between"
        style={{
          width: '100vw',
          height: '100vh',
        }}
      >
        <img src={paradimeLogo} alt="paradime" />
        <AutoLayout
          direction="vertical"
          verticalGap="dense"
          distribution="packed"
          alignment="center"
          padding="none"
        >
          <div style={{ width: '100%', height: 'calc(100vh - 76px)' }}>
            <stripe-pricing-table
              pricing-table-id={pricingTableId}
              publishable-key={publishableKey}
              customer-email={user?.email || currentUser?.email}
              client-reference-id={clientReferenceId}
            />
          </div>
        </AutoLayout>
      </AutoLayout>
    );
  }

  return <Loader />;
};

export default StripePaymentPage;
