import { Duration } from 'luxon';

export const transformSecondsToHoursMinutes = (timeSaved: number) => {
  if (timeSaved === 0) return '0m';
  if (timeSaved < 60) return '< 1m';

  const duration = Duration.fromObject({ seconds: timeSaved });
  const { hours, minutes } = duration.shiftTo('hours', 'minutes').toObject();

  const formattedHrs = hours && hours > 0 ? `${Math.floor(hours)}h ` : '';
  const formattedMins = minutes && minutes > 0 ? `${Math.floor(minutes)}m` : '';

  return `${formattedHrs}${formattedMins}`;
};

export const chartColourScale = [
  'var(--violet50)', 'var(--green50)', 'var(--yellow50)', 'var(--red50)',
  'var(--indigo50)', 'var(--teal50)', 'var(--orange50)', 'var(--pink50)',
];
