export const lineageFilterNodes = () => ({
  title: 'HIGHTOUCH NODES',
  items: [
    {
      label: ['HightouchModelNode', 'Model'],
      checked: true,
    },
    {
      label: ['HightouchSyncNode', 'Sync'],
      checked: true,
    },
  ],
});
