/* eslint-disable consistent-return */
/* eslint-disable no-case-declarations */
import React, {
  Dispatch,
  SetStateAction,
} from 'react';
import * as H from 'history';
import { initialFormDataType } from '../../useGetInitialWarehouseData';
import { WareHouseModalSource } from '../..';
import { WarehouseEnv } from '../../utils';
import { companyStore } from '../../../../../stores';
import { postMessage } from '../../../../../utilis';

export interface useCommonConfigProps {
  formData?: initialFormDataType,
  env: WarehouseEnv,
  source: WareHouseModalSource,
  isEditable: boolean,
  isAdditionalUser: boolean,
  isNewConnection: boolean,
}

export enum BUTTON_STATE_TEXT {
  'TEST_CONNECTION' = 'Test Connection',
  'AUTHORIZE_CONNECTION' = 'Authorize Connection',
  'TRY_AGAIN' = 'Try again',
  'EDIT_CREDENTIALS' = 'Edit Credentials',
  'NEXT' = 'Next'
}

export interface onSubmitButtonClickProps {
  formData: initialFormDataType,
  setIsEditable: React.Dispatch<React.SetStateAction<boolean>>,
  closeModal: () => void,
  credentialId?: string,
}

export type onSubmitButtonClickType = ({
  formData,
  setIsEditable,
  closeModal,
  credentialId,
}: onSubmitButtonClickProps) => void;

export interface getButtonDefinitionProps {
  state: BUTTON_STATE_TEXT,
  isAdditionalUser?: boolean,
  testTheConnection: (
    variables: onSubmitButtonClickProps['formData'],
    credentialId: onSubmitButtonClickProps['credentialId'],
  ) => void,
  setCurrentButtonText: Dispatch<SetStateAction<BUTTON_STATE_TEXT>>,
  env: WarehouseEnv,
  source: WareHouseModalSource,
  isEditable: boolean,
  history: H.History,
}

export interface buttonDefinitionProps {
  onSubmitButtonClick: onSubmitButtonClickType,
}

export interface buttonDefinitionReturnType {
  submitButtonText: BUTTON_STATE_TEXT,
  onSubmitButtonClick: onSubmitButtonClickType,
  nextState?: { success: BUTTON_STATE_TEXT, failure: BUTTON_STATE_TEXT },
}

const makeFormEditable = (
  setIsEditable: onSubmitButtonClickProps['setIsEditable'],
  setCurrentButtonText: getButtonDefinitionProps['setCurrentButtonText'],
  nextStateSuccess: BUTTON_STATE_TEXT,
) => {
  setIsEditable(true);
  setCurrentButtonText(nextStateSuccess);
};

const closeTheForm = (
  closeModal: onSubmitButtonClickProps['closeModal'],
  env: WarehouseEnv,
  setCurrentButtonText: getButtonDefinitionProps['setCurrentButtonText'],
  source: WareHouseModalSource,
  isEditable: boolean,
  isAdditionalUser?: boolean,
) => {
  postMessage('reload-connections', env);
  closeModal();
  setCurrentButtonText(
    getInitialButtonText({
      source,
      isEditable,
      isAdditionalUser,
    }),
  );
};

const completeOnboarding = (history: getButtonDefinitionProps['history'], withRefresh: boolean) => {
  const { setIsDwhSetupComplete } = companyStore.getState();
  setIsDwhSetupComplete(true);
  history.push('/home');
  if (withRefresh) window.location.reload();
};

export const getButtonDefinition = ({
  state,
  isAdditionalUser,
  testTheConnection,
  setCurrentButtonText,
  env,
  source,
  isEditable,
  history,
}: getButtonDefinitionProps) => {
  switch (state) {
    case BUTTON_STATE_TEXT.AUTHORIZE_CONNECTION:
      return {
        submitButtonText: BUTTON_STATE_TEXT.AUTHORIZE_CONNECTION,
        onSubmitButtonClick: ({
          formData: formDataForOnClickFunction,
          credentialId,
        }: onSubmitButtonClickProps) => (
          testTheConnection(formDataForOnClickFunction, credentialId)
        ),
        nextState: {
          success: BUTTON_STATE_TEXT.NEXT,
          failure: BUTTON_STATE_TEXT.TRY_AGAIN,
        },
      };
    case BUTTON_STATE_TEXT.EDIT_CREDENTIALS:
      const nextButtonState = isAdditionalUser
        ? BUTTON_STATE_TEXT.AUTHORIZE_CONNECTION
        : BUTTON_STATE_TEXT.TEST_CONNECTION;

      return {
        submitButtonText: BUTTON_STATE_TEXT.EDIT_CREDENTIALS,
        onSubmitButtonClick: ({
          setIsEditable,
        }: onSubmitButtonClickProps) => (
          makeFormEditable(
            setIsEditable,
            setCurrentButtonText,
            nextButtonState,
          )
        ),
        nextState: {
          success: nextButtonState,
          failure: nextButtonState,
        },
      };
    case BUTTON_STATE_TEXT.NEXT:
      return {
        submitButtonText: BUTTON_STATE_TEXT.NEXT,
        onSubmitButtonClick: ({
          closeModal,
        }: onSubmitButtonClickProps) => {
          if (source === WareHouseModalSource.ACCOUNT_SETTINGS) {
            return closeTheForm(
              closeModal,
              env,
              setCurrentButtonText,
              source,
              isEditable,
              isAdditionalUser,
            );
          }
          return completeOnboarding(history, source === WareHouseModalSource.CREATE_WORKSPACE);
        },
      };
    case BUTTON_STATE_TEXT.TEST_CONNECTION:
    case BUTTON_STATE_TEXT.TRY_AGAIN:
      return {
        submitButtonText: state,
        onSubmitButtonClick: ({
          formData: formDataForOnClickFunction,
          credentialId,
        }: onSubmitButtonClickProps) => (
          testTheConnection(formDataForOnClickFunction, credentialId)
        ),
        nextState: {
          success: BUTTON_STATE_TEXT.NEXT,
          failure: BUTTON_STATE_TEXT.TRY_AGAIN,
        },
      };
    default:
      break;
  }
};

export interface getInitialButtonTextProps {
  source: WareHouseModalSource,
  isEditable?: boolean,
  isAdditionalUser?: boolean,
}

export const getInitialButtonText = ({
  source,
  isEditable,
  isAdditionalUser,
}: getInitialButtonTextProps) => {
  if (source === WareHouseModalSource.ACCOUNT_SETTINGS && !isEditable) {
    return BUTTON_STATE_TEXT.NEXT;
  }
  if (isAdditionalUser) {
    return BUTTON_STATE_TEXT.AUTHORIZE_CONNECTION;
  }
  return BUTTON_STATE_TEXT.TEST_CONNECTION;
};

export interface isEditButtonShownProps {
  source: WareHouseModalSource,
  isNewConnection?: boolean,
  env: WarehouseEnv,
}

export const isEditButtonShown = ({
  source,
  isNewConnection,
  env,
}: isEditButtonShownProps) => {
  if (env !== WarehouseEnv.DEV) return false;
  if (source === WareHouseModalSource.ACCOUNT_SETTINGS && !isNewConnection) return true;
  return false;
};

export const sendResetSecretsMessage = () => window.postMessage('RESET_SECRETS', '*');

export interface ResetSpecificFieldMessageProps {
  fieldName: string,
  useInitialValue: boolean,
  customValue?: string,
}
export const sendResetSpecificFieldMessage = ({
  fieldName,
  useInitialValue,
  customValue,
}: ResetSpecificFieldMessageProps) => window.postMessage({
  type: 'RESET_SPECIFIC_FIELD',
  fieldName,
  useInitialValue,
  customValue,
}, '*');
