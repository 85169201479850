import { useDatabaseHookProps } from '../../utils';
import { useCommonConfig, GenericWarehouseFormDataType } from '../CommonConfig/GenericWarehouseConfig';
import { formDistribution, initialValues, formFields } from '../CommonConfig/GenericFormFieldsWithoutSchema';
import { DevWarehouseHelp, ProdWarehouseHelp } from '../../../AppHelp/help';

export type { GenericWarehouseFormDataType as DremioFormDataType };

export const useDatabaseHook = ({
  formData,
  env,
  source,
  isEditable,
  isAdditionalUser,
  isNewConnection,
}: useDatabaseHookProps) => {
  const commonConfigs = useCommonConfig({
    formData,
    env,
    source,
    isEditable,
    isAdditionalUser,
    isNewConnection,
    warehouseType: 'dremio',
  });

  return {
    ...commonConfigs,
    initialFormData: initialValues,
    formDistribution,
    form: formFields({
      env,
      prodHelpArticle: ProdWarehouseHelp.DREMIO,
      devHelpArticle: DevWarehouseHelp.DREMIO,
    }),
  };
};
