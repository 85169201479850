/* eslint-disable no-case-declarations */
import React, { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { driver } from 'driver.js';
import 'driver.js/dist/driver.css';
import Checkbox from '@paradime-io/pragma-ui-kit/lib/components/CheckBox';
import { MenuTemplate } from '@paradime-io/pragma-ui-kit/lib/components/Menu';
import { Actions, Contexts, EventContextsType } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import DefaultButton from '@paradime-io/pragma-ui-kit/lib/components/DefaultButton';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import MultiLineInput from '@paradime-io/pragma-ui-kit/lib/components/MultiLineInput';
import { checkNoCasesLeft } from '../../../../utilis';
import { InputType, WarehouseComponentType, WarehouseFormInputType } from './types';
import { toBase64 } from '../utils';
import { onComponentChangeProps } from '../warehouseFactory';
import { DefaultInputWithoutErrorIcon, DropdownWithOverflow, FileErrorMessageDisplayer } from './warehouses.styles';
import { initialFormDataType } from '../useGetInitialWarehouseData';
import { openHelpHub } from '../../AppHelp/utils';

export interface GetWarehouseComponentProps {
  element: WarehouseComponentType,
  eventContext: EventContextsType,
  onComponentChange: ({ value, name, cancels }: onComponentChangeProps) => void,
  loading: boolean,
  onFileUpdate: ({ value, name, cancels }: onComponentChangeProps) => void,
  fieldValue: string | number | boolean | string[],
  disabled?: boolean,
  checkboxIsChecked: boolean,
  validationErrors: {
    name: string, validationMessage: string,
  }[],
  formData?: initialFormDataType,
  setFormData: Dispatch<SetStateAction<initialFormDataType | undefined>>,
}

interface handleInputFieldChangeProps {
  type: InputType['type'],
  e: ChangeEvent<HTMLInputElement>,
  name: string,
}

interface GetInputFieldProps {
  element: InputType,
  onComponentChange: GetWarehouseComponentProps['onComponentChange'],
  loading: GetWarehouseComponentProps['loading'],
  onFileUpdate: GetWarehouseComponentProps['onFileUpdate'],
  fieldValue: GetWarehouseComponentProps['fieldValue'],
  disabled: GetWarehouseComponentProps['disabled'],
  validationErrors: GetWarehouseComponentProps['validationErrors'],
}

const useGetWarehouseComponents = (warehouseName?: string) => {
  const getInputField = ({
    element,
    onComponentChange,
    loading,
    onFileUpdate,
    fieldValue,
    disabled,
    validationErrors,
  }: GetInputFieldProps) => {
    const handleInputFieldChange = async ({
      type,
      e,
      name,
    }: handleInputFieldChangeProps) => {
      switch (type) {
        case WarehouseFormInputType.FILE:
          const file = (e.target.files || [])[0];
          const jsonBase64 = await toBase64(file);
          const [, onlyData] = jsonBase64.split('64,');
          onFileUpdate({ value: onlyData, name });
          break;
        case WarehouseFormInputType.NUMBER:
          const { target: { value: numberVal } } = e;
          onComponentChange({ value: +numberVal, name, cancels: element.cancels });
          break;
        default:
          const { target: { value } } = e;
          onComponentChange({ value, name, cancels: element.cancels });
          break;
      }
    };

    const driverObj = driver({
      popoverClass: 'paradime-driver-theme',
      stagePadding: 0,
      onDestroyed: () => {
        const el = document?.activeElement as HTMLElement | undefined;
        el?.blur();
      },
    });

    const validationInfo = validationErrors.filter(({ name }) => (
      name === element.name
    ));

    return (
      <div style={{ gridArea: element.gridArea }}>
        <DefaultInputWithoutErrorIcon
          data-form-type="other"
          data-lpignore
          data-1p-ignore
          id={element.id}
          key={`title${element.label}`}
          type={element.type}
          view="outlined"
          full
          sensitive
          label={element.label}
          expose={(e) => handleInputFieldChange({
            type: element.type,
            e,
            name: element.name,
          })}
          color={element.color || 'default'}
          buttonText="Upload"
          placeholder={element.placeholder}
          value={fieldValue?.toString()}
          disabled={disabled || loading}
          // gridArea={element.gridArea}
          data-private={element.isPrivate}
          style={element.style}
          errorHelperText={validationInfo[0]?.validationMessage}
          error={validationInfo.length > 0}
          errorIcon={undefined}
          dense
          onFocus={() => {
            if (element.showHighlight) {
              driverObj.highlight({
                element: document.getElementById(element.id || '') as HTMLElement,
                popover: {
                  description: element.highlightDescription,
                  align: 'center',
                  side: 'bottom',
                },
              });
            }
          }}
        />
        {element.type === WarehouseFormInputType.FILE && (
          <FileErrorMessageDisplayer>
            {validationInfo[0]?.validationMessage}
          </FileErrorMessageDisplayer>
        )}
      </div>
    );
  };

  const getWarehouseComponents = ({
    element,
    eventContext,
    onComponentChange,
    loading,
    onFileUpdate,
    fieldValue,
    disabled,
    checkboxIsChecked,
    validationErrors,
    setFormData,
  }: GetWarehouseComponentProps) => {
    switch (element.type) {
      case WarehouseFormInputType.TITLE:
        return (
          <div style={{ gridArea: element.gridArea, display: 'flex' }}>
            <Typography
              font="inter"
              type="body-bold"
              color="default"
              colorStep="100"
            >
              {element.title}
            </Typography>
            {element.helpArticleId && (
              <DefaultButton
                type="standard"
                view="smooth"
                color="primary_alt"
                text="More info"
                rightIcon="help"
                dense
                onClick={() => {
                  openHelpHub({
                    articleId: element.helpArticleId,
                  });
                }}
                eventContext={Contexts.ONBOARDING}
                eventObject="openWarehouseHelp"
                eventAction={Actions.CLICKED}
                eventProperties={{ warehouseName }}
                style={{ marginLeft: 'auto' }}
              />
            )}
          </div>
        );

      case WarehouseFormInputType.CHECKBOX:
        return (
          <>
            <div
              style={{
                gridArea: element.gridArea,
                display: 'grid',
                placeItems: 'center',
              }}
              key={`warehouseTitle${element.label}`}
            >
              <Checkbox
                checked={checkboxIsChecked}
                // @ts-ignore - Mismatch between changeEvents/formElements
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const { target: { checked } } = e;
                  if (element?.onChange) element.onChange(e);
                  onComponentChange({
                    value: checked,
                    name: element.name,
                    cancels: element.cancels,
                  });
                }}
                type="dense"
                disabled={disabled || loading}
                label={element.label}
                eventContext={eventContext}
                eventObject="warehouseCheckbox"
                eventAction={Actions.CLICKED}
                eventProperties={{ name: element.label }}
                data-private={element.isPrivate}
                helperText={element?.helperText}
                fill={element?.fill}
              />
            </div>
            {element?.withTogglingInputField
              && element?.togglingInputFieldProps
              && checkboxIsChecked
              && (
                getInputField({
                  element: element.togglingInputFieldProps,
                  onComponentChange,
                  loading,
                  onFileUpdate,
                  fieldValue: Array.isArray(fieldValue) ? fieldValue[1] : fieldValue,
                  disabled,
                  validationErrors,
                })
              )}
          </>
        );

      case WarehouseFormInputType.FILE:
      case WarehouseFormInputType.NUMBER:
      case WarehouseFormInputType.PASSWORD:
      case WarehouseFormInputType.TEXT:
        return getInputField({
          element,
          onComponentChange,
          loading,
          onFileUpdate,
          fieldValue,
          disabled,
          validationErrors,
        });

      case WarehouseFormInputType.DROPDOWN:
        return (
          <div
            style={{
              gridArea: element.gridArea,
              display: 'grid',
              placeItems: 'center',
            }}
            key={`warehouseDropdown${element.label}`}
          >
            <DropdownWithOverflow
              view="outlined"
              color="default"
              position="bottom"
              label={element.label}
              fill
              dense
              height={element.maxHeight}
              content={(
                <MenuTemplate
                  dense
                  eventContext={eventContext}
                  items={element.menuItems?.map(
                    ({ onClick, ...menuItem }) => ({
                      ...menuItem,
                      onClick: (e) => {
                        if (element?.onChange) {
                          element.onChange({
                            setFormData,
                            selectedItem: menuItem.text,
                          });
                        }
                        onComponentChange({
                          value: menuItem.text,
                          name: element.name,
                          cancels: element.cancels,
                        });

                        if (onClick) {
                          onClick(e);
                        }
                      },
                    }),
                  ) || []}
                />
              )}
              text={element.text}
              disabled={disabled || loading}
              id={element.id}
            />
          </div>
        );
      case WarehouseFormInputType.BUTTON:
        return (
          <DefaultButton
            type="standard"
            view="outlined"
            color="default"
            dense
            text={element.text}
            onClick={element.onClick}
            disabled={disabled}
            eventContext={eventContext}
            eventObject="editWarehouseConnectionSecret"
            eventAction={Actions.CLICKED}
            style={{ gridArea: element.gridArea, marginTop: '22px' }}
          />
        );
      case WarehouseFormInputType.MULTI_LINE_INPUT:
        const validationInfo = validationErrors.filter(({ name }) => (
          name === element.name
        ));

        return (
          <div style={{ gridArea: element.gridArea, width: '100%' }}>
            <Typography
              font="inter"
              type="caption"
              color="default"
              colorStep="50"
            >
              {element.label}
            </Typography>
            <div data-private={element.isPrivate}>
              <MultiLineInput
                view="outlined"
                color="default"
                placeholder={element.placeholder}
                dense
                value={fieldValue?.toString()}
                maxLines={element?.maxLines || 3}
                minLines={element?.minLines || 1}
                exposeOnChange={(value: string) => {
                  if (element?.onChange) {
                    element.onChange(value);
                  }
                  onComponentChange({
                    value,
                    name: element.name,
                    cancels: element.cancels,
                  });
                }}
                disabled={disabled || loading}
                hasError={validationInfo?.length > 0}
                errorHelperText={validationInfo[0]?.validationMessage}
                sensitive={element.isPrivate}
              />
            </div>
          </div>
        );
      default:
        checkNoCasesLeft(element);
        return null;
    }
  };

  return { getWarehouseComponents };
};

export default useGetWarehouseComponents;
