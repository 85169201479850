import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import { GqlParadimeAccountType } from '../../../client/generated/service';
import { useGetRolesDropdownData } from '../../hooks/useGetRolesDropdownData';

interface RoleDetailDropdownProps {
  inviteLevels: GqlParadimeAccountType[],
  selectedValue: string,
  onChange: any,
}

export const RoleDetailDropdown = (
  { inviteLevels, selectedValue, onChange } : RoleDetailDropdownProps,
) => {
  const valueField = 'text';

  return (
    <Dropdown
      value={selectedValue}
      options={useGetRolesDropdownData(inviteLevels)}
      onChange={(e) => onChange(e.value)}
      panelClassName="dropdown-panel-with-text-subtitle-items"
      optionLabel={valueField}
      optionValue={valueField}
      placeholder="Select a value"
      itemTemplate={(item) => (
        <AutoLayout
          direction="vertical"
          distribution="packed"
          padding="none"
          verticalGap="very-dense"
          style={{
            marginBottom: '16px',
            width: '300px',
            whiteSpace: 'normal',
          }}
        >
          <AutoLayout
            direction="horizontal"
            distribution="packed"
            padding="none"
            horizontalGap="very-dense"
          >
            <Typography
              type="body-small"
              color="default"
            >
              {item.text}
            </Typography>
          </AutoLayout>

          <Typography
            type="caption"
            color="default"
            colorStep="50"
          >
            {item.subtitle}
          </Typography>
        </AutoLayout>
      )}
    />
  );
};
