import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import Divider from '@paradime-io/pragma-ui-kit/lib/components/Divider';
import { Actions, Contexts } from '@paradime-io/pragma-ui-kit/lib/components/Events';
import { SYNQ_ONBOARDING_PAGE, SynqOnboardingRoutePrefix } from '../Onboarding/SynqOnboarding';
import * as S from './Home.styles';

const JoinOrCreate: FC = () => {
  const history = useHistory();

  const { location: { href } } = window;
  const url = new URL(href);
  const isTargettingChromeExtension = url.searchParams.get('target') === 'chrome-extension';

  const handleClick = (type: 'join' | 'create') => {
    url.searchParams.set('is_signup', (type === 'create').toString());

    let nextPageUrl = '/enter-organisation';
    if (isTargettingChromeExtension && type === 'create') {
      nextPageUrl = `/${SynqOnboardingRoutePrefix}/${SYNQ_ONBOARDING_PAGE.ENTER_ORG}`;
    }
    history.push(`${nextPageUrl}?${url.searchParams.toString()}`);
  };

  return (
    <AutoLayout
      direction="vertical"
      width="full"
      padding="none"
      distribution="packed"
      verticalGap="normal"
      alignment="center"
      style={{
        paddingTop: '96px',
        paddingLeft: '52px',
        paddingRight: '52px',
      }}
    >
      <Typography tagName="span" type="h5" style={{ textAlign: 'center' }}>
        Welcome to Paradime
      </Typography>
      <AutoLayout
        direction="vertical"
        verticalGap="dense"
        padding="none"
        style={{
          justifyItems: 'center',
        }}
      >
        <Typography tagName="span" type="body-small" colorStep="50">
          Start your 14 day free trial and explore with pre-loaded demo data 🚀
        </Typography>
        <S.PrimaryLandingPageButton
          type="standard"
          view="filled"
          color="primary_alt"
          text="Create free account"
          fill
          onClick={() => handleClick('create')}
          eventContext={Contexts.ONBOARDING}
          eventObject="createNewCopmany"
          eventAction={Actions.CLICKED}
        />
        <Typography tagName="span" type="body-small" colorStep="50">
          No credit card required
        </Typography>
      </AutoLayout>
      <Divider text=" Or " textBackgroundColor="var(--white)" />
      <AutoLayout
        direction="vertical"
        verticalGap="dense"
        padding="none"
        alignment="center"
      >
        <Typography tagName="span" type="body-small" colorStep="50">
          Team already on Paradime?
        </Typography>
        <S.SecondaryLandingPageButton
          type="standard"
          view="outlined"
          color="default"
          text="Join your team"
          fill
          onClick={() => handleClick('join')}
          eventContext={Contexts.ONBOARDING}
          eventObject="createNewCopmany"
          eventAction={Actions.CLICKED}
        />
      </AutoLayout>
    </AutoLayout>
  );
};

export default JoinOrCreate;
