import React from 'react';
import { Icon, IconName } from '@blueprintjs/core';
import { catalogueDetailsCardProps } from '../types';
import {
  CatalogAssetTypes,
  checkIfArrayIsEmpty,
  checkIfStringIsEmpty,
  calculateTestLevel,
} from '../../components/Catalog/SearchRoutes/ResultTransformations';
import { convertNumberToFileSize, formatDateTimeToLocalString } from '../../utilis';

export const catalogueDetailsCard = ({
  resultItem,
}: catalogueDetailsCardProps) => {
  switch (resultItem.__typename) {
    case 'GQLCatalogueDwhColumnItem':
      return {
        components: [
          [
            {
              type: 'textWithHeader' as 'textWithHeader',
              header: 'type',
              text: CatalogAssetTypes.TABLE,
              icon: { iconName: 'th' as IconName, color: 'var(--violet70)' },
            },
            {
              type: 'textWithHeader' as 'textWithHeader',
              text: resultItem.database,
              havePlaceholder: false,
              icon: { iconName: 'database' as IconName, color: 'var(--icon)', size: 16 },
            },
            ...(
              resultItem.dwhType !== 'firebolt'
                ? [{
                  type: 'textWithHeader' as 'textWithHeader',
                  text: resultItem.schema,
                  havePlaceholder: false,
                  icon: { iconName: 'layout-auto' as IconName, color: 'var(--icon)', size: 16 },
                }]
                : []
            ),
          ],
          [
            {
              type: 'textWithHeader' as 'textWithHeader',
              header: 'created on',
              text: formatDateTimeToLocalString({ dateTimeString: resultItem.table.createdAt }),
            },
            {
              type: 'textWithHeader' as 'textWithHeader',
              header: 'last modified',
              text: formatDateTimeToLocalString({ dateTimeString: resultItem.table.lastModified }),
            },
          ],
          [
            {
              type: 'textWithHeader' as 'textWithHeader',
              header: 'materialization',
              text: checkIfStringIsEmpty({
                attribute: resultItem.table.materialisation,
              }),
            },
            {
              type: 'doubleTextWithHeader' as 'doubleTextWithHeader',
              first: {
                header: 'rows',
                text: resultItem.table.rowCount > 0 ? resultItem.table.rowCount.toString() : '-',
              },
              second: {
                header: 'size',
                text: resultItem.table.sizeInBytes > 0 ? convertNumberToFileSize(resultItem.table.sizeInBytes) : '-',
              },
            },
          ],
          [
            {
              type: 'textWithHeader' as 'textWithHeader',
              header: 'Project',
              text: checkIfStringIsEmpty({
                attribute: resultItem.projectName,
              }),
            },
            {
              type: 'tags' as 'tags',
              header: 'tags',
              tags: checkIfArrayIsEmpty({ attribute: resultItem.tags }),
            },
          ],
          [
            {
              type: 'chips' as 'chips',
              header: 'coverage',
              chips: [
                {
                  label: 'Test',
                  color: calculateTestLevel(resultItem.table.testsCoverage),
                  tag: `${(resultItem.table.testsCoverage * 100).toFixed(2)}%`,
                },
                {
                  label: 'Documentation',
                  color: calculateTestLevel(resultItem.table.documentationCoverage),
                  tag: `${(resultItem.table.documentationCoverage * 100).toFixed(2)}%`,
                },
              ],
            },
          ],
          [
            ...(
              resultItem.dwhType === 'firebolt'
                ? [{
                  type: 'chips' as 'chips',
                  header: 'Primary Index',
                  chips: [{
                    color: 'default',
                    label: '',
                    tag: (
                      <span>
                        <Icon icon="th-list" iconSize={14} />
                        {` ${resultItem.table.primaryIndex || '-'}`}
                      </span>
                    ),
                  }],
                }]
                : []
            ),
          ],
        ],
      };
    case 'GQLCatalogueMaterialisedDbtObjectColumnItem':
      return {
        components: [
          [
            {
              type: 'textWithHeader' as 'textWithHeader',
              header: 'type',
              text: CatalogAssetTypes.TABLE,
              icon: { iconName: 'th' as IconName, color: 'var(--violet70)' },
            },
            {
              type: 'textWithHeader' as 'textWithHeader',
              text: resultItem.database,
              havePlaceholder: false,
              icon: { iconName: 'database' as IconName, color: 'var(--icon)', size: 16 },
            },
            ...(
              resultItem.dwhType !== 'firebolt'
                ? [{
                  type: 'textWithHeader' as 'textWithHeader',
                  text: resultItem.schema,
                  havePlaceholder: false,
                  icon: { iconName: 'layout-auto' as IconName, color: 'var(--icon)', size: 16 },
                }]
                : []
            ),
          ],
          [
            {
              type: 'textWithHeader' as 'textWithHeader',
              header: 'created on',
              text: formatDateTimeToLocalString({ dateTimeString: resultItem.dbtItem.createdAt }),
            },
            {
              type: 'textWithHeader' as 'textWithHeader',
              header: 'last modified',
              text: formatDateTimeToLocalString({
                dateTimeString: resultItem.dbtItem.lastModified,
              }),
            },
          ],
          [
            {
              type: 'textWithHeader' as 'textWithHeader',
              header: 'materialization',
              text: checkIfStringIsEmpty({
                attribute: resultItem.dbtItem.materialisation,
              }),
            },
            {
              type: 'doubleTextWithHeader' as 'doubleTextWithHeader',
              first: {
                header: 'rows',
                text: resultItem.dbtItem.rowCount > 0 ? resultItem.dbtItem.rowCount.toString() : '-',
              },
              second: {
                header: 'size',
                text: resultItem.dbtItem.sizeInBytes > 0 ? convertNumberToFileSize(resultItem.dbtItem.sizeInBytes) : '-',
              },
            },
          ],
          [
            {
              type: 'textWithHeader' as 'textWithHeader',
              header: 'Project',
              text: checkIfStringIsEmpty({
                attribute: resultItem.projectName,
              }),
            },
            {
              type: 'tags' as 'tags',
              header: 'tags',
              tags: checkIfArrayIsEmpty({ attribute: resultItem.tags }),
            },
          ],
          [
            {
              type: 'chips' as 'chips',
              header: 'coverage',
              chips: [
                {
                  label: 'Test',
                  color: calculateTestLevel(resultItem.dbtItem.testsCoverage),
                  tag: `${(resultItem.dbtItem.testsCoverage * 100).toFixed(2)}%`,
                },
                {
                  label: 'Documentation',
                  color: calculateTestLevel(resultItem.dbtItem.documentationCoverage),
                  tag: `${(resultItem.dbtItem.documentationCoverage * 100).toFixed(2)}%`,
                },
              ],
            },
          ],
          [
            ...(
              resultItem.dwhType === 'firebolt'
                ? [{
                  type: 'chips' as 'chips',
                  header: 'Primary Index',
                  chips: [{
                    color: 'default',
                    label: '',
                    tag: (
                      <span>
                        <Icon icon="th-list" iconSize={14} />
                        {` ${resultItem.dbtItem.primaryIndex || '-'}`}
                      </span>
                    ),
                  }],
                }]
                : []
            ),
          ],
        ],
      };

    case 'GQLCatalogueDwhTableItem':
      return {
        components: [
          [
            {
              type: 'textWithHeader' as 'textWithHeader',
              header: 'type',
              text: CatalogAssetTypes.TABLE,
              icon: { iconName: 'th' as IconName, color: 'var(--violet70)' },
            },
            {
              type: 'textWithHeader' as 'textWithHeader',
              text: resultItem.database,
              havePlaceholder: false,
              icon: { iconName: 'database' as IconName, color: 'var(--icon)', size: 16 },
            },
            ...(
              resultItem.dwhType !== 'firebolt'
                ? [{
                  type: 'textWithHeader' as 'textWithHeader',
                  text: resultItem.schema,
                  havePlaceholder: false,
                  icon: { iconName: 'layout-auto' as IconName, color: 'var(--icon)', size: 16 },
                }]
                : []
            ),
          ],
          [
            {
              type: 'textWithHeader' as 'textWithHeader',
              header: 'created on',
              text: formatDateTimeToLocalString({ dateTimeString: resultItem.createdAt }),
            },
            {
              type: 'textWithHeader' as 'textWithHeader',
              header: 'last modified',
              text: formatDateTimeToLocalString({ dateTimeString: resultItem.lastModified }),
            },
          ],
          [
            {
              type: 'textWithHeader' as 'textWithHeader',
              header: 'materialization',
              text: checkIfStringIsEmpty({
                attribute: resultItem.materialisation,
              }),
            },
            {
              type: 'doubleTextWithHeader' as 'doubleTextWithHeader',
              first: {
                header: 'rows',
                text: resultItem.rowCount > 0 ? resultItem.rowCount.toString() : '-',
              },
              second: {
                header: 'size',
                text: resultItem.sizeInBytes > 0 ? convertNumberToFileSize(resultItem.sizeInBytes) : '-',
              },
            },
          ],
          [
            {
              type: 'textWithHeader' as 'textWithHeader',
              header: 'Project',
              text: checkIfStringIsEmpty({
                attribute: resultItem.dbtProjectName,
              }),
            },
            {
              type: 'tags' as 'tags',
              header: 'tags',
              tags: checkIfArrayIsEmpty({ attribute: resultItem.tags }),
            },
          ],
          [
            {
              type: 'chips' as 'chips',
              header: 'coverage',
              chips: [
                {
                  label: 'Test',
                  color: calculateTestLevel(resultItem.testsCoverage),
                  tag: `${(resultItem.testsCoverage * 100).toFixed(2)}%`,
                },
                {
                  label: 'Documentation',
                  color: calculateTestLevel(resultItem.documentationCoverage),
                  tag: `${(resultItem.documentationCoverage * 100).toFixed(2)}%`,
                },
              ],
            },
          ],
          [
            ...(
              resultItem.dwhType === 'firebolt'
                ? [{
                  type: 'chips' as 'chips',
                  header: 'Primary Index',
                  chips: [{
                    color: 'default',
                    label: '',
                    tag: (
                      <span>
                        <Icon icon="th-list" iconSize={14} />
                        {` ${resultItem.primaryIndex || '-'}`}
                      </span>
                    ),
                  }],
                }]
                : []
            ),
          ],
        ],
      };
    case 'GQLCatalogueMaterialisedDbtItem':
      return {
        components: [
          [
            {
              type: 'textWithHeader' as 'textWithHeader',
              header: 'type',
              text: CatalogAssetTypes.TABLE,
              icon: { iconName: 'th' as IconName, color: 'var(--violet70)' },
            },
            {
              type: 'textWithHeader' as 'textWithHeader',
              text: resultItem.database || '-',
              havePlaceholder: false,
              icon: { iconName: 'database' as IconName, color: 'var(--icon)', size: 16 },
            },
            ...(
              resultItem.dwhType !== 'firebolt'
                ? [{
                  type: 'textWithHeader' as 'textWithHeader',
                  text: resultItem.schema || '-',
                  havePlaceholder: false,
                  icon: { iconName: 'layout-auto' as IconName, color: 'var(--icon)', size: 16 },
                }]
                : []
            ),
          ],
          [
            {
              type: 'textWithHeader' as 'textWithHeader',
              header: 'created on',
              text: formatDateTimeToLocalString({ dateTimeString: resultItem.createdAt }),
            },
            {
              type: 'textWithHeader' as 'textWithHeader',
              header: 'last modified',
              text: formatDateTimeToLocalString({ dateTimeString: resultItem.lastModified }),
            },
          ],
          [
            {
              type: 'textWithHeader' as 'textWithHeader',
              header: 'materialization',
              text: checkIfStringIsEmpty({
                attribute: resultItem.materialisation,
              }),
            },
            {
              type: 'doubleTextWithHeader' as 'doubleTextWithHeader',
              first: {
                header: 'rows',
                text: resultItem.rowCount > 0 ? resultItem.rowCount.toString() : '-',
              },
              second: {
                header: 'size',
                text: resultItem.sizeInBytes > 0 ? convertNumberToFileSize(resultItem.sizeInBytes) : '-',
              },
            },
          ],
          [
            {
              type: 'textWithHeader' as 'textWithHeader',
              header: 'Project',
              text: checkIfStringIsEmpty({
                attribute: resultItem.dbtProjectName,
              }),
            },
            {
              type: 'tags' as 'tags',
              header: 'tags',
              tags: checkIfArrayIsEmpty({ attribute: resultItem.tags }),
            },
          ],
          [
            {
              type: 'chips' as 'chips',
              header: 'coverage',
              chips: [
                {
                  label: 'Test',
                  color: calculateTestLevel(resultItem.testsCoverage),
                  tag: `${(resultItem.testsCoverage * 100).toFixed(2)}%`,
                },
                {
                  label: 'Documentation',
                  color: calculateTestLevel(resultItem.documentationCoverage),
                  tag: `${(resultItem.documentationCoverage * 100).toFixed(2)}%`,
                },
              ],
            },
          ],
          [
            ...(
              resultItem.dwhType === 'firebolt'
                ? [{
                  type: 'chips' as 'chips',
                  header: 'Primary Index',
                  chips: [{
                    color: 'default',
                    label: '',
                    tag: (
                      <span>
                        <Icon icon="th-list" iconSize={14} />
                        {` ${resultItem.primaryIndex || '-'}`}
                      </span>
                    ),
                  }],
                }]
                : []
            ),
          ],
        ],
      };
    default:
      return { components: [] };
  }
};
