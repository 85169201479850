import BoltDetailsCard from '@paradime-io/pragma-ui-kit/lib/components/BoltDetailsCard';
import React, { FC, useState } from 'react';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import Card from '@paradime-io/pragma-ui-kit/lib/components/Card';
import { ScheduleRunCommandArtifactType } from '../../../../client/generated/service';
import { CommandProps } from '../CommandLogs';
import ArtifactsTable from './ArtifactsTable';

type NonNullCommand = NonNullable<CommandProps>

export interface ArtifactsProps {
  commands: NonNullCommand[]
}

const Command: FC<NonNullCommand> = ({
  command,
  meta,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const setInitialArtifactsTab = () => {
    const cli = command.trimStart().split(' ')[0];
    switch (cli) {
      case 'python':
        return ScheduleRunCommandArtifactType.Other;
      case 'dbt':
        return ScheduleRunCommandArtifactType.RunSql;
      case 'edr':
        return ScheduleRunCommandArtifactType.Other;
      default:
        return ScheduleRunCommandArtifactType.Other;
    }
  };

  return (
    <BoltDetailsCard
      raiseOnHover
      rightIcon={isOpen ? 'double-chevron-up' : 'double-chevron-down'}
      rightIconSize={24}
      onClick={() => setIsOpen((o) => !o)}
      isOpen={isOpen}
      components={[
        {
          type: 'row',
          items: [
            {
              type: 'column',
              items: [
                {
                  type: 'reactNode',
                  reactNode: (
                    <Typography
                      type="body-small"
                      tagName="span"
                      color="default"
                      colorStep="100"
                    >
                      {command}
                    </Typography>
                  ),
                },
              ],
            },
          ],
        },
      ]}
    >
      <ArtifactsTable commandId={meta.id} initialTab={setInitialArtifactsTab()} />
    </BoltDetailsCard>
  );
};

enum ExcludedCmds {
  GIT_CLONE = 'git clone',
  DBT_DEPS = 'dbt deps'
}

const Artifacts: FC<ArtifactsProps> = ({
  commands,
}) => {
  const excludedCommands = [ExcludedCmds.GIT_CLONE, ExcludedCmds.DBT_DEPS];
  const isExludedCommand = (commandString: string) => (
    excludedCommands.some((command) => (
      commandString.includes(command)
    )));
  const commandsToShow = commands.filter((command) => (
    !isExludedCommand(command.command)
  ));

  if (commandsToShow.length === 0) {
    return (
      <Card tabIndex={-1}>
        <Typography tagName="span" type="body-small" color="default" colorStep="100">
          There are no artefacts available for this run.
        </Typography>
      </Card>
    );
  }

  return (
    <>
      {
        commandsToShow.map((command) => (
          <Command key={command.meta.id} {...command} />
        ))
    }
    </>
  );
};

export default Artifacts;
