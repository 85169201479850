import { useDatabaseHookProps } from '../../utils';
import { useCommonConfig, RedshiftFormDataType, AuthMethod } from './CommonConfig';
import { formDistribution, initialValues, formFields } from './dwhFormFields';

export type { RedshiftFormDataType };

export const useDatabaseHook = ({
  formData,
  env,
  source,
  isEditable,
  isAdditionalUser,
  isNewConnection,
}: useDatabaseHookProps) => {
  const commonConfigs = useCommonConfig({
    formData,
    env,
    source,
    isEditable,
    isAdditionalUser,
    isNewConnection,
  });

  return {
    ...commonConfigs,
    initialFormData: initialValues(env),
    formDistribution: formDistribution({
      env,
      selectedAuthMethod: commonConfigs.selectedAuthMethod,
      showEditButton: commonConfigs.showEditButton,
    }),
    form: formFields(
      commonConfigs.selectedAuthMethod,
      commonConfigs.setSelectedAuthMethod,
      commonConfigs.showEditButton,
      commonConfigs.isEditingUsernamePassword,
      commonConfigs.setIsEditingUsernamePassword,
      env,
    ),
    showOptionalConfig: commonConfigs.selectedAuthMethod === AuthMethod.USERNAME_PASSWORD,
  };
};
