import React, { FC } from 'react';
import IntegrationStatusPage from '../../../utilis/components/IntegrationStatusPage';
import { FakeModal } from '../../Onboarding/MainApp/Utils/PageTemplate';
import OrganisationSelector from './OrganisationSelector';

const EnterOrg: FC = () => (
  <IntegrationStatusPage
    content={(
      <FakeModal>
        <OrganisationSelector
          style={{
            width: '100%',
          }}
        />
      </FakeModal>
    )}
  />
);

export default EnterOrg;
