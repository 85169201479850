import { GraphicTypes } from '@paradime-io/pragma-ui-kit/lib/components/List';
import { IconName } from '@blueprintjs/core';
import { catalogueDetailsCardProps } from '../types';
import {
  CatalogAssetTypes,
  checkIfArrayIsEmpty,
} from '../../components/Catalog/SearchRoutes/ResultTransformations';

export const catalogueDetailsCard = ({
  resultItem,
}: catalogueDetailsCardProps) => {
  switch (resultItem.__typename) {
    case 'GQLCatalogueDBTExposureItem':
      return {
        components: [
          [
            {
              type: 'textWithHeader' as const,
              header: 'type',
              text: CatalogAssetTypes.EXPLORE,
              icon: { iconName: 'new-grid-item' as IconName, color: 'var(--violet70)' },
            },
          ],
          [
            {
              type: 'textWithHeader' as const,
              header: 'Exposure Type',
              text: resultItem.type || '-',
            },
            {
              type: 'textWithHeader' as const,
              header: 'maturity',
              text: resultItem.maturity || '-',
            },
          ],
          [
            {
              type: 'list' as const,
              title: 'Owner',
              subtitle: resultItem.ownerName || '-',
              graphic: {
                type: 'image' as GraphicTypes.IMAGE,
                image: `https://via.placeholder.com/32/ededf0/?text=${resultItem.ownerName?.slice(0, 1) || 'X'}`,
              },
            },
            {
              type: 'textWithHeader' as const,
              header: 'Owner Email',
              text: resultItem.ownerEmail || '-',
            },
          ],
          [
            {
              type: 'tags' as const,
              header: 'tags',
              tags: checkIfArrayIsEmpty({ attribute: resultItem.tags }),
            },
          ],
        ],
      };
    default:
      return { components: [] };
  }
};
