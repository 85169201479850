import React, { ReactNode } from 'react';
import { Command } from 'cmdk';
import { Icon, IconName } from '@blueprintjs/core';
import { checkIsMac } from '@paradime-io/pragma-ui-kit';
import { appStore } from '../../../../stores';

export interface ShortcutKey {
  key: string;
  metaKey?: boolean;
  ctrlKey?: boolean;
  shiftKey?: boolean;
  altKey?: boolean;
}

export interface CommandItemProps {
  icon?: IconName,
  children: ReactNode,
  shortcut?: ShortcutKey,
  nested?: boolean,
  onSelect?: () => void,
}

const CommandItem = ({
  icon,
  children,
  shortcut,
  nested,
  onSelect,
}:CommandItemProps) => {
  const setShowCommandBar = appStore((s) => s.setShowCommandBar);
  const isMac = checkIsMac();

  const parseShortcut = (): string[] => {
    const parts: string[] = [];

    if (shortcut) {
      if (shortcut.metaKey) parts.push(isMac ? '⌘' : 'Ctrl');
      if (shortcut.ctrlKey) parts.push('⌃');
      if (shortcut.shiftKey) parts.push('⇧');
      if (shortcut.altKey) parts.push(isMac ? '⌥' : 'Alt');
      parts.push(shortcut.key.toUpperCase());
    }

    return parts;
  };

  return (
    <Command.Item
      onSelect={() => {
        if (onSelect) onSelect();
        if (!nested) setShowCommandBar(false);
      }}
    >
      {icon && <Icon icon={icon} size={12} color="var(--grey40)" />}
      {children}
      {shortcut && (
        <div cmdk-vercel-shortcuts="">
          {parseShortcut().map((key) => <kbd key={key}>{key}</kbd>)}
        </div>
      )}
    </Command.Item>
  );
};

export default CommandItem;
