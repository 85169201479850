import { useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import { useHasuraLazyQuery } from '../../app/hooks/useHasuraQuery';

export interface CopyItem {
  ctaUrl: string | null,
  contentType: string,
  description: string | null,
  iconName: string | null,
  pageUrl: string,
  title: string | null,
}

interface CopyManagerQueryResult { // eslint-disable-next-line camelcase
  paradime_frontend_copy_manager: CopyItem[],
}

const CopyManagerQuery = gql`
  query paradime_frontend_copy_manager($contentType: String, $pageUrl: String) {
    paradime_frontend_copy_manager(
      args: {content_type: $contentType, page_url: $pageUrl},
      order_by: {sort_id: asc},
    ) {
      ctaUrl: cta_url
      contentType: content_type
      description
      iconName: icon_name
      pageUrl: page_url
      title
    }
  }
`;

export interface useCopyManagerProps {
  contentType?: string,
  pageUrl?: string,
}

export const useCopyManager = ({ contentType, pageUrl }: useCopyManagerProps) => {
  const [copy, setCopy] = useState<CopyItem[]>([]);

  const [getCopy, { loading /* , error */ }] = useHasuraLazyQuery<CopyManagerQueryResult>({
    query: CopyManagerQuery,
    onCompleted: ({ data }) => {
      if (data?.paradime_frontend_copy_manager?.length > 0) {
        setCopy(data.paradime_frontend_copy_manager);
      }
    },
  });

  useEffect(() => {
    if (contentType && pageUrl) {
      getCopy({ contentType, pageUrl });
    }
  }, [contentType, pageUrl]);

  return {
    getCopy,
    loading,
    // error,
    copy,
  };
};
