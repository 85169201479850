import React, { FC, useState } from 'react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import { Route, Switch } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PageTitle, { PageTitles } from '../PageTitle';
import * as S from '../Common/Layouts/AppPage.styles';
import Sidebar from './Sidebar';
import Workspaces from './Workspaces';
import Billing from './Billing';
import Plans from './Plans';
import { userAuthStore } from '../../stores';
import AuditLogs from './AuditLogs';
import Insights from './Insights';
import {
  userHasPlatformAuditLogsAccess,
  userHasPlatformBillingUpdateAccess,
  userHasPlatformInsightsAccess,
  userHasPlatformPlansUpdateAccess,
} from '../../utilis/PermissionsService';

export enum PlatformTab {
  WORKSPACES = 'workspaces',
  PLANS = 'plans',
  BILLING = 'billing',
  SETTINGS = 'settings',
  AUDIT_LOGS = 'audit-logs',
  INSIGHTS = 'insights',
}

const Platform:FC = () => {
  const { currentUser: { accessLevel } } = userAuthStore.getState();
  const [isLeftPanelOpen, setIsLeftPanelOpen] = useState(false);

  const { flagRoiUserInsights } = useFlags();

  return (
    <S.Page
      isLeftPanelOpen={isLeftPanelOpen}
    >
      <PageTitle title={PageTitles.BOLT} />
      <S.LeftSidebar>
        <Sidebar
          isLeftPanelOpen={isLeftPanelOpen}
          setIsLeftPanelOpen={setIsLeftPanelOpen}
        />
      </S.LeftSidebar>
      <S.Body
        backgroundColor="var(--white)"
      >
        <AutoLayout
          direction="vertical"
          padding="expanded"
          horizontalGap="dense"
          distribution="packed"
          alignment="top-left"
          width="full"
        >
          <Switch>
            <Route exact path={`/platform/${PlatformTab.WORKSPACES}`} component={Workspaces} />
            <>
              {userHasPlatformBillingUpdateAccess(accessLevel) && <Route exact path={`/platform/${PlatformTab.BILLING}`} component={Billing} />}
              {userHasPlatformPlansUpdateAccess(accessLevel) && <Route path={`/platform/${PlatformTab.PLANS}`} component={Plans} />}
              {userHasPlatformAuditLogsAccess(accessLevel) && <Route exact path={`/platform/${PlatformTab.AUDIT_LOGS}`} component={AuditLogs} />}
              {userHasPlatformInsightsAccess(accessLevel) && flagRoiUserInsights && <Route exact path={`/platform/${PlatformTab.INSIGHTS}`} component={Insights} />}
            </>
          </Switch>
        </AutoLayout>
      </S.Body>
    </S.Page>
  );
};

export default Platform;
