import React, { FC, useEffect, useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import Spinner from '@paradime-io/pragma-ui-kit/lib/components/Spinner';
import CodeSnippetCopy from '../../Common/Components/CodeSnippetCopy';
import { useGetPublicResourceUrlLazyQuery } from '../../../client/generated/service';
import { boltStore } from '../../../stores';

export interface RunViewArtifactSQLProps {
  isVisible: boolean,
  onHide: () => void,
}

const RunViewArtifactSQL:FC<RunViewArtifactSQLProps> = ({
  isVisible,
  onHide,
}) => {
  const { sqlResourceId, sqlResourcePath } = boltStore.getState();
  const [sql, setSql] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [getPublicResource] = useGetPublicResourceUrlLazyQuery({
    onCompleted: (data) => {
      if (data.getPublicResourceUrl?.url) {
        const resourceUrl = data.getPublicResourceUrl.url;
        fetch(resourceUrl)
          .then((response) => response.blob())
          .then((sqlBlob) => sqlBlob.text())
          .then((sqlText) => {
            setHasError(false);
            setSql(sqlText);
            setIsLoading(false);
          })
          .catch(() => {
            setHasError(true);
            setIsLoading(false);
          });
      } else {
        setHasError(true);
        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    if (sqlResourceId && sqlResourcePath && isVisible) {
      setIsLoading(true);
      getPublicResource({
        variables: {
          scheduleCommandResourceId: sqlResourceId,
        },
      });
    }
  }, [sqlResourceId, sqlResourcePath, isVisible]);

  const getSidebarContent = () => {
    if (isLoading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '128px' }}>
          <Spinner width={32} height={32} thin />
        </div>
      );
    }

    if (hasError) {
      return (
        <AutoLayout
          direction="vertical"
          distribution="packed"
          padding="dense"
          verticalGap="very-dense"
          style={{ marginTop: '200px' }}
        >
          <Typography tagName="span" type="body" style={{ textAlign: 'center' }}>
            Woops, we couldn&apos;t find that resource!
          </Typography>
          <Typography tagName="span" type="body-bold-small" style={{ overflowWrap: 'break-word', textAlign: 'center' }}>
            {sqlResourcePath}
          </Typography>
        </AutoLayout>
      );
    }

    if (sql) {
      return (
        <AutoLayout
          direction="vertical"
          distribution="packed"
          padding="none"
          verticalGap="very-dense"
        >
          <Typography tagName="span" type="body-small" style={{ marginTop: '24px' }}>
            Showing the SQL for:
          </Typography>
          <Typography tagName="span" type="body-bold-small" style={{ overflowWrap: 'break-word' }}>
            {sqlResourcePath}
          </Typography>
          <Typography tagName="span" type="body-small">
            You can copy and run this against your warehouse or in the Code IDE scratchpad.
          </Typography>
          <CodeSnippetCopy codeSnippet={sql} language="sql" />
        </AutoLayout>
      );
    }

    return null;
  };

  return (
    <Sidebar
      visible={isVisible}
      position="right"
      onHide={() => {
        setSql(undefined);
        setIsLoading(false);
        onHide();
      }}
      style={{ width: '600px' }}
      header={() => (
        <Typography type="h6" color="default" tagName="span">
          View SQL
        </Typography>
      )}
    >
      <AutoLayout
        direction="vertical"
        distribution="packed"
        padding="none"
        verticalGap="dense"
        alignment="top-left"
        style={{ borderTop: '1px solid var(--grey5)' }}
      >
        {getSidebarContent()}
      </AutoLayout>
    </Sidebar>
  );
};

export default RunViewArtifactSQL;
