import React, { FC } from 'react';
import AnimatedDino from '@paradime-io/pragma-ui-kit/lib/components/AnimatedDino';
import { Markdown } from '@paradime-io/pragma-ui-kit/lib/components/MDEditor';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import * as S from '../Scheduler.styles';

export interface CommandAiSummaryProps {
  isLoading: boolean,
  summary?: string,
}

const CommandAiSummary:FC<CommandAiSummaryProps> = ({
  isLoading,
  summary,
}) => (
  <S.AiSummaryWrapper>
    {
      isLoading ? (
        <AnimatedDino color="var(--violet5)" size={3} />
      ) : (
        <Typography tagName="span" type="body-small" colorStep="10" font="robotoMono">
          <Markdown source={summary} darkMode withTransparentBackground />
        </Typography>
      )
    }
  </S.AiSummaryWrapper>
);

export default CommandAiSummary;
