import { catalogueDescriptionProps } from '../types';

export const catalogueDescription = ({
  resultItem,
}: catalogueDescriptionProps) => {
  switch (resultItem?.__typename) {
    case 'GQLCatalogueIntegrationDatasourceItem':
    case 'GQLCatalogueIntegrationDashboardItem':
    case 'GQLCatalogueIntegrationChartItem':
      return resultItem?.description || '';
    default:
      return '';
  }
};
