import React, { FC, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import ZeroState from '@paradime-io/pragma-ui-kit/lib/components/ZeroState';
import useOrganisation from '../../hooks/useOrganisation';
import ZeroStateImageCompetitor from './images/zero-state-competitor-email-login-image.svg';

export enum BlockedLoginType {
  PUBLIC_EMAIL = 'public-email',
  COMPETITOR = 'competitor',
}

const AppBlockedLogout: FC = () => {
  const [timeleft, setTimeleft] = useState(5);
  const { logout } = useAuth0();
  const { clear: clearOrganisation } = useOrganisation();

  const handleLogout = () => {
    clearOrganisation();
    logout();
  };

  const downloadTimer = setInterval(() => {
    if (timeleft <= 0) {
      clearInterval(downloadTimer);
      handleLogout();
    }
    setTimeleft((time) => time - 1);
  }, 1000);

  return (
    <AutoLayout
      direction="vertical"
      padding="none"
      distribution="space-between"
      style={{ height: '100vh', width: '100vw' }}
    >
      <ZeroState
        useParadimeIcon={false}
        direction="vertical"
        image={ZeroStateImageCompetitor}
        text={(
          <>
            <Typography tagName="span" type="h6" style={{ justifySelf: 'center' }}>
              Making moves? Not on our watch 😝.
            </Typography>
            <Typography tagName="span" type="body-bold-small" style={{ justifySelf: 'center' }}>
              Logging you out in
              {' '}
              {timeleft}
              {' '}
              seconds.
            </Typography>
          </>
        )}
      />
    </AutoLayout>
  );
};

export default AppBlockedLogout;
