const data = [
  ['TableauDataSourceNode', 'Data Source'],
  ['TableauWorksheetNode', 'Worksheet'],
  ['TableauDashboardNode', 'Dashboard'],
  ['TableauFlowNode', 'Flow'],
];

export const lineageFilterNodes = () => ({
  title: 'TABLEAU NODES',
  items: data.map((item) => ({ label: item, checked: true })),
});
