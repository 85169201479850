/**
 * this are the only flags I didn't add `paradime:` at the beginning of the key
 * because we actually type this ones directly in the terminal
 */

export enum LocalStorageFlags {
  'IGNORE_AUTO_SIGN_UP_FLOW' = 'ignoreAutoSignUpFlow',
  'ALLOW_NEW_COMPANY_AFTER_ALREADY_ONBOARDED' = 'allowNewCompanyAfterAlreadyOnboarded',
  'IGNORE_ONBOARDING_STATUS_CHECK' = 'ignoreOnboardingCheck',
}

export enum CatalogLocalStorageKeys {
  ENVIRONMENT = 'paradime:CatalogEnvironment',
  SELECTED_DROPDOWN_FILTERS = 'paradime:CatalogDropdownFilters',
}

// note: the keys here start with the prefix global since keys starting with paradime
//       are stored prefixed with the company token. but this here is really global,
//       so we need another prefix, see setupCompanyLevelStorage.ts for more details.
export enum OrganisationStorageKeys {
  SELECTED_ORGANISATION = 'global:paradime:organisation:selected',
  SEEN_ORGANISATIONS = 'global:paradime:organisation:seen',
  CURRENT_COMPANY_TOKEN = 'global:paradime:current:companyToken',
  CURRENT_WORKSPACE_TOKEN = 'global:paradime:current:workspaceToken',
}

export enum AppLocalStorageKeys {
  LAUNCHDARKLY = 'paradime:ld',
  THEME = 'paradime:Theme',
  CODE_EDITOR_READY = 'paradime:CodeEditorReady',
  USER_GITHUB_OAUTH_POPUP_CLOSED = 'paradime:UserGithubOauthPopupClosed',
  USER_GITHUB_OAUTH_RECONNECTED = 'paradime:githubOauthReconnected',
}

export enum PreferencesLocalStorageKeys {
  GIT_TYPE = 'paradime:git-type',
  FORCE_THEIA_CLEANUP = 'force-theia-cleanup',
}
