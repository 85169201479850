import React, { FC } from 'react';
import Typography from '@paradime-io/pragma-ui-kit/lib/components/Typography';
import AutoLayout from '@paradime-io/pragma-ui-kit/lib/components/AutoLayout';
import { usePostLoginToChromeExtension } from '../../../utilis';
import paradime from './images/paradime.svg';

const ExtensionOnboardingSuccess: FC = () => {
  usePostLoginToChromeExtension({ skipWindowClose: true });

  return (
    <AutoLayout
      padding="expanded"
      direction="vertical"
      verticalGap="expanded"
      width="full"
      alignment="center"
      style={{ marginTop: '200px' }}
    >
      <img src={paradime} alt="paradime logo" style={{ margin: 'auto' }} />
      <AutoLayout
        padding="none"
        direction="vertical"
        alignment="center"
        verticalGap="very-dense"
      >
        <Typography
          font="inter"
          type="h4"
          color="default"
          colorStep="100"
          style={{ textAlign: 'center' }}
        >
          You&apos;re logged in!
        </Typography>
        <Typography
          font="inter"
          type="body"
          color="default"
          colorStep="60"
          style={{ textAlign: 'center' }}
        >
          You can close this tab and start using the Paradime Chrome Extension.
        </Typography>
      </AutoLayout>
    </AutoLayout>
  );
};

export default ExtensionOnboardingSuccess;
